import React, { useEffect, useRef, useState } from "react";
import { Table } from "reactstrap";
import { ShowNamDaiVan, TangCanArr } from "./convert";
import TableThangDaiVan from "./TableThangDaiVan";
import ReactTooltip from "react-tooltip";
const DaiVanClone = (props) => {
  const {
    yearDaiVan,
    thienCanNgay,
    soTuoi,
    excelLichvanNien,
    dungThan,
    checkDungThan,
  } = props;

  const [daiVanData, setDataContent] = useState([]);

  const [tangCanDaiVanArr, settangCanDaiVanArr] = useState([]);
  const [objectDaiVanData, setObjectDaiVan] = useState([]);
  const [showThang, setShowThang] = useState(false);
  const [yearTable, setYearTable] = useState();
  const [thienCanYear, setThienCanYear] = useState();

  const handleResult = () => {
    let objectDaivan = [];
    let currentYear = new Date().getFullYear();
    const DaiVanYear = ShowNamDaiVan(yearDaiVan, thienCanNgay, soTuoi);

    for (let index = 0; index < DaiVanYear.length - 1; index++) {
      let temp = DaiVanYear[index + 1];
      let item = DaiVanYear[index];
      if (item.year === currentYear) {
        objectDaivan = item;
      } else {
        if (item.year < temp.year && temp.year <= currentYear) {
          item = temp;
          if (item?.year <= currentYear && item?.year > currentYear - 10) {
            objectDaivan = item;
          }
        }
      }
    }
    const arrTangCanDaiVan = TangCanArr(DaiVanYear, thienCanNgay);

    settangCanDaiVanArr(arrTangCanDaiVan);
    setObjectDaiVan(objectDaivan);
    setDataContent(DaiVanYear);
  };

  useEffect(() => {
    handleResult();
  }, [yearDaiVan]);

  const renderToolTip = () => {
    return (
      <ReactTooltip
        id="con-giap"
        getContent={(dataTip) => (
          <div>
            <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
            <p style={{ color: "blue" }}>
              Xin vui lòng{" "}
              <span
                className="click-span"
                onClick={() => {
                  if (dataTip === "Giáp") {
                    window.open(
                      "https://www.youtube.com/watch?v=N9E8dt6mtHc&t=31s"
                    );
                  } else if (dataTip === "Ất") {
                    window.open("https://www.youtube.com/watch?v=phqBCvqlLVk");
                  } else if (dataTip === "Bính") {
                    window.open("https://www.youtube.com/watch?v=MWg_sgJxcto");
                  } else if (dataTip === "Đinh") {
                    window.open("https://www.youtube.com/watch?v=-QB6i5bWwxE");
                  } else if (dataTip === "Mậu") {
                    window.open("https://www.youtube.com/watch?v=qs5jw6rGNBI");
                  } else if (dataTip === "Kỷ") {
                    window.open("https://www.youtube.com/watch?v=7Q3ivslkQk4");
                  } else if (dataTip === "Canh") {
                    window.open("https://www.youtube.com/watch?v=cNZGmL6mpAI");
                  } else if (dataTip === "Tân") {
                    window.open("https://www.youtube.com/watch?v=xR1EIu_J6oo");
                  } else if (dataTip === "Nhâm") {
                    window.open("https://www.youtube.com/watch?v=SUdNG6Zuoow");
                  } else if (dataTip === "Quý") {
                    window.open(
                      "https://www.youtube.com/watch?v=thOn-UpGob8&t=8s"
                    );
                  }
                }}
              >
                Click
              </span>{" "}
              vào đây để tìm hiểu thêm
            </p>
          </div>
        )}
        effect="solid"
        delayHide={500}
        delayShow={500}
        delayUpdate={500}
        place="top"
        border={true}
        type="light"
      />
    );
  };

  return (
    <div>
      <div style={{ pageBreakBefore: "always" }}></div>
      <div
        id="table_dv"
        style={{
          width: "1440px",
          margin: "0 auto",
          zIndex: "1000",
          position: "relative",
        }}
      >
        <Table
          hover
          bordered
          style={{
            textAlign: "center",
            marginTop: "35px",
          }}
        >
          <tbody>
            <tr className="fz__16">
              {daiVanData?.map((x) => {
                return (
                  <td
                    id={objectDaiVanData.year === x.year ? "bg_hang1" : ""}
                    onClick={() => {
                      setYearTable(x.year);
                      setShowThang(true);
                      setThienCanYear(x?.thienCan);
                    }}
                    style={{ width: "145px", cursor: "pointer" }}
                    className={
                      objectDaiVanData.year === x.year
                        ? "border__1px background_"
                        : "border__1px"
                    }
                  >
                    {x.soNgay} ({x.year})
                  </td>
                );
              })}

              <th
                id="bg__age"
                className="bg__ clr__white fz__15 fw__b"
                style={{ width: "58px" }}
              >
                Tuổi
              </th>
            </tr>

            <tr style={{ textAlign: "center" }}>
              {daiVanData.map((x) => {
                return (
                  <td
                    id={objectDaiVanData.year === x.year ? "bg_hang2" : ""}
                    onClick={() => {
                      setYearTable(x.year);
                      setShowThang(true);
                      setThienCanYear(x?.thienCan);
                    }}
                    style={{ fontWeight: "bold", cursor: "pointer" }}
                    className={
                      objectDaiVanData.year === x.year
                        ? "fz__22 clr__ border__1px background_"
                        : "fz__22 clr__ border__1px"
                    }
                  >
                    <div data-for="con-giap" data-tip={x.thienCan}>
                      <sup className="fz__15" style={{ paddingRight: "4px" }}>
                        {x.thapThan?.sinhKhac}
                      </sup>
                      {x.thienCan}
                    </div>
                  </td>
                );
              })}

              <th
                id="bg__lnntc"
                rowSpan="2"
                className="bg__ clr__white fz__15 fw__b"
                data-for="thien-can"
                data-tip="Thiên Can"
              >
                Thiên Can
              </th>
            </tr>

            <tr style={{ textAlign: "center" }}>
              {daiVanData.map((x) => {
                return (
                  <th
                    onClick={() => {
                      setYearTable(x.year);
                      setShowThang(true);
                      setThienCanYear(x?.thienCan);
                    }}
                    style={{ fontWeight: "normal", cursor: "pointer" }}
                    colspan="1"
                    className={
                      dungThan.includes(x.nguHanhTC.split(" ")[1]) &&
                      checkDungThan
                        ? "fz__15 border__1px background"
                        : objectDaiVanData.year === x.year
                        ? "fz__15 border__1px background_"
                        : dungThan.includes(x.nguHanhTC.split(" ")[1]) &&
                          objectDaiVanData.year === x.year
                        ? "fz__15 border__1px background"
                        : "fz__15 border__1px"
                    }
                    id={
                      dungThan.includes(x.nguHanhTC.split(" ")[1]) &&
                      checkDungThan
                        ? "dungThan"
                        : objectDaiVanData.year === x.year
                        ? "bg_hang3"
                        : dungThan.includes(x.nguHanhTC.split(" ")[1]) &&
                          objectDaiVanData.year === x.year
                        ? "dungThan"
                        : ""
                    }
                  >
                    {x.nguHanhTC}
                  </th>
                );
              })}
            </tr>

            <tr>
              {daiVanData.map((x) => (
                <td
                  id={objectDaiVanData.year === x.year ? "bg_hang4" : ""}
                  onClick={() => {
                    setYearTable(x.year);
                    setShowThang(true);
                    setThienCanYear(x?.thienCan);
                  }}
                  style={{ fontWeight: "bold", cursor: "pointer" }}
                  className={
                    objectDaiVanData.year === x.year
                      ? "fz__22 clr__ border__1px background_"
                      : "fz__22 clr__ border__1px"
                  }
                >
                  {x.diaChi}
                </td>
              ))}

              <th
                id="bg__lnndc"
                rowSpan="1"
                className="bg__ clr__white fz__15 fw__b"
                data-for="dia-chi"
                data-tip="Địa Chi"
              >
                Địa Chi
              </th>
            </tr>

            <tr className="fz__10">
              {tangCanDaiVanArr?.map((val) => {
                return (
                  <td
                    id={
                      dungThan.includes(
                        val?.TangCan[0]?.nguHanh.split(" ")[1]
                      ) && checkDungThan
                        ? "dungThan"
                        : objectDaiVanData.year === val.year
                        ? "bg_hang5"
                        : ""
                    }
                    onClick={() => {
                      setYearTable(val.year);
                      setShowThang(true);
                      setThienCanYear(val?.thienCan);
                    }}
                    style={{
                      verticalAlign: "middle",
                      padding: "0",
                      cursor: "pointer",
                    }}
                    className={
                      dungThan.includes(
                        val?.TangCan[0]?.nguHanh.split(" ")[1]
                      ) && checkDungThan
                        ? "border__1px background"
                        : objectDaiVanData.year === val.year
                        ? "border__1px background_"
                        : "border__1px"
                    }
                  >
                    {val.TangCan[1]?.name ? (
                      <td
                        onClick={() => {
                          setYearTable(val.year);
                          setShowThang(true);
                          setThienCanYear(val?.thienCan);
                        }}
                        id={
                          dungThan.includes(
                            val?.TangCan[0]?.nguHanh.split(" ")[1]
                          ) && checkDungThan
                            ? "dungThan"
                            : val?.year <= new Date().getFullYear() &&
                              val?.year > new Date().getFullYear() - 10
                            ? "none_bg"
                            : "none"
                        }
                        style={{
                          border: "none",
                        }}
                      >
                        <div>
                          <sup>{val?.ThapThan2?.sinhKhac}</sup>

                          {val?.TangCan[1]?.name}
                        </div>
                        <div>
                          <div>{val?.TangCan[1]?.nguHanh}</div>
                        </div>
                      </td>
                    ) : (
                      ""
                    )}

                    {val?.TangCan[1]?.name && val?.TangCan[2]?.nguHanh ? (
                      <th
                        id={
                          dungThan.includes(
                            val?.TangCan[0]?.nguHanh.split(" ")[1]
                          ) && checkDungThan
                            ? "dungThan"
                            : val?.year <= new Date().getFullYear() &&
                              val?.year > new Date().getFullYear() - 10
                            ? "none3_bg"
                            : "none3"
                        }
                        onClick={() => {
                          setYearTable(val.year);
                          setShowThang(true);
                          setThienCanYear(val?.thienCan);
                        }}
                        style={{
                          borderLeft: "1px solid black",
                          borderRight: "1px solid black",
                          borderTop: "none",
                          borderBottom: "none",
                        }}
                      >
                        <div>
                          <sup>{val?.ThapThan1.sinhKhac}</sup>
                          {val?.TangCan[0]?.name}
                        </div>
                        <div>
                          <div>{val?.TangCan[0]?.nguHanh}</div>
                        </div>
                      </th>
                    ) : val?.TangCan[2]?.name ? (
                      <th
                        id={
                          dungThan.includes(
                            val?.TangCan[0]?.nguHanh.split(" ")[1]
                          ) && checkDungThan
                            ? "dungThan"
                            : val?.year <= new Date().getFullYear() &&
                              val?.year > new Date().getFullYear() - 10
                            ? "none_bg4"
                            : "none4"
                        }
                        onClick={() => {
                          setYearTable(val.year);
                          setShowThang(true);
                          setThienCanYear(val?.thienCan);
                        }}
                        style={{
                          borderLeft: "none",
                          borderRight: "1px solid black",
                          borderTop: "none",
                          borderBottom: "none",
                        }}
                      >
                        <div>
                          <sup>{val?.ThapThan1.sinhKhac}</sup>
                          {val?.TangCan[0]?.name}
                        </div>
                        <div>
                          <div>{val?.TangCan[0]?.nguHanh}</div>
                        </div>
                      </th>
                    ) : (
                      <th
                        id={
                          dungThan.includes(
                            val?.TangCan[0]?.nguHanh.split(" ")[1]
                          ) && checkDungThan
                            ? "dungThan"
                            : val?.year <= new Date().getFullYear() &&
                              val?.year > new Date().getFullYear() - 10
                            ? "none2_bg"
                            : "none2"
                        }
                        onClick={() => {
                          setYearTable(val.year);
                          setShowThang(true);
                          setThienCanYear(val?.thienCan);
                        }}
                        style={{
                          border: "none",
                          display: "inline-block",
                          margin: 0,
                        }}
                      >
                        <div>
                          <sup>{val?.ThapThan1.sinhKhac}</sup>
                          {val?.TangCan[0]?.name}
                        </div>
                        <div>
                          <div>{val?.TangCan[0]?.nguHanh}</div>
                        </div>
                      </th>
                    )}

                    {val?.TangCan[2]?.name && val?.TangCan[1]?.name ? (
                      <td
                        id={
                          dungThan.includes(
                            val?.TangCan[0]?.nguHanh.split(" ")[1]
                          ) && checkDungThan
                            ? "dungThan"
                            : val?.year <= new Date().getFullYear() &&
                              val?.year > new Date().getFullYear() - 10
                            ? "none2_bg"
                            : "none2"
                        }
                        style={{
                          border: "none",
                          padding: "0 5px",
                        }}
                        onClick={() => {
                          setYearTable(val.year);
                          setShowThang(true);
                          setThienCanYear(val?.thienCan);
                        }}
                      >
                        <div>
                          <sup>{val?.ThapThan3?.sinhKhac}</sup>

                          {val?.TangCan[2]?.name}
                        </div>
                        <div>
                          <div>{val?.TangCan[2]?.nguHanh}</div>
                        </div>
                      </td>
                    ) : (
                      <td
                        id={
                          dungThan.includes(
                            val?.TangCan[0]?.nguHanh.split(" ")[1]
                          ) && checkDungThan
                            ? "dungThan"
                            : val?.year <= new Date().getFullYear() &&
                              val?.year > new Date().getFullYear() - 10
                            ? "none2_bg"
                            : "none2"
                        }
                        onClick={() => {
                          setYearTable(val.year);
                          setShowThang(true);
                          setThienCanYear(val?.thienCan);
                        }}
                        style={{
                          border: "none",
                          padding: "0 23px",
                        }}
                      >
                        <div>
                          <sup>{val?.ThapThan3?.sinhKhac}</sup>

                          {val?.TangCan[2]?.name}
                        </div>
                        <div>
                          <div>{val?.TangCan[2]?.nguHanh}</div>
                        </div>
                      </td>
                    )}
                  </td>
                );
              })}

              <th id="bg__lnntcc" className="bg__ clr__white fz__15 fw__b">
                Tàng Can
              </th>
            </tr>
          </tbody>
        </Table>
      </div>
      {showThang && checkDungThan && (
        <TableThangDaiVan
          excelLichvanNien={excelLichvanNien}
          year={yearTable}
          thienCanYear={thienCanYear}
          thienCanNgay={thienCanNgay}
          dungThan={dungThan}
          checkDungThan={checkDungThan}
        />
      )}
      {renderToolTip()}
      <ReactTooltip
        id="thien-can"
        getContent={(dataTip) => (
          <div>
            <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
            <p style={{ color: "blue" }}>
              Xin vui lòng
              <span
                className="click-span"
                onClick={() => {
                  window.open(
                    "https://www.phongthuytuongminh.com/thien-can-dia-chi-la-gi-thien-can-dia-chi-co-y-nghia-quan-trong-nhu-the-nao.html"
                  );
                }}
              >
                {" "}
                Click{" "}
              </span>
              vào đây để tìm hiểu thêm
            </p>
          </div>
        )}
        effect="solid"
        delayHide={500}
        delayShow={500}
        delayUpdate={500}
        place="top"
        border={true}
        type="light"
      />
      <ReactTooltip
        id="dia-chi"
        getContent={(dataTip) => (
          <div>
            <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
            <p style={{ color: "blue" }}>
              Xin vui lòng
              <span
                className="click-span"
                onClick={() => {
                  window.open(
                    "https://www.phongthuytuongminh.com/vi/thien-can-dia-chi-la-gi-thien-can-dia-chi-co-y-nghia-quan-trong-nhu-the-nao.html"
                  );
                }}
              >
                {" "}
                Click{" "}
              </span>
              vào đây để tìm hiểu thêm
            </p>
          </div>
        )}
        effect="solid"
        delayHide={500}
        delayShow={500}
        delayUpdate={500}
        place="top"
        border={true}
        type="light"
      />
    </div>
  );
};

export default DaiVanClone;
