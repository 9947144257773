import React from "react";

const LoiMoDau = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "50%", margin: "0 auto" }}>
        <h1 style={{ textAlign: "center", fontWeight: "bold" }}>Lời Mở Đầu</h1>
      </div>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Xin chào quý bạn đọc,
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tiếp nối trong chuỗi chuyên đề về Bát Tự, chúng tôi xin cho ra mắt tập
        tiếp theo của Bát Tự Kinh Điển Chuyên Đề - Tập 5: Tìm Dụng Thần theo
        Tháng Sinh.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Toàn bộ những phần kiến thức này được dựa trên những sách vở cổ điển,
        nhất là từ hai tác giả là Từ Lạc Ngộ và Vĩ Thiên Lý.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Trong đó việc tìm dụng thần, hỉ thần cho 10 can ngày được tính toán dựa
        trên yếu tó quan trọng nhất trong lá số đó là Nguyệt Lệnh.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nguyệt Lệnh hay còn gọi là Đề Cương đóng vai trò quyết định về sức mạnh
        của ngũ hành của Thập Thiên Can trong lá số. 
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Vốn dĩ Nguyệt Lệnh (Địa chi Tháng) đại diện cho sức mạnh của 1 trong 4
        Mùa Xuân Hạ Thu Đông nên có khả năng chi phối rất lớn đến độ mạnh yếu
        của Can Ngày và tất cả những ngũ hành khác.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngoài ra trong tác phẩm còn đề cập rất nhiều đến Dụng Thần Điều Hậu và
        Thông Quan để đảm bảo những yếu tố sinh tồn căn bản của 1 lá số Bát Tự.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu giúp chúng ta cân bằng độ ẩm độ, rét buốt hay nóng
        bức của lá số còn Dụng Thần Thông Quan giúp chúng ta tránh những sự xung
        đột trong bản thân nội tại của một lá số.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Hi vọng bạn đọc có thể tìm ra được những nhân tố ngũ hành cần thiết
        trong việc phân tích một lá số bất kỳ dựa trên gợi ý về Nguyệt Lệnh từ 2
        Đại Sư Mệnh Lý hàng đầu là Từ Lạc Ngộ và Vĩ Thiên Lý. 
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Chúc cho bạn đọc ngày càng thành công trong việc luận đoán lá số Bát Tự
        Tứ Trụ,
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Vì cộng đồng Bát Tự ngày một phát triển
      </p>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Master Nguyễn Thành Phương
      </p>
    </div>
  );
};

export default LoiMoDau;
