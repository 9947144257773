export const TanThangTi = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        className="p1"
        style={{
          margin: "6px 0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          <strong>Ngày sinh Tân trong tháng Tý</strong>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Khái Quát:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì nếu có hoặc không có Quý Thủy
          hiện lên ở Thiên Can thì cũng xem như hình thành Thực Thần Cục.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Dụng Thần Điều Hậu:
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì dụng thần điều hậu là Bính Hỏa,
          Mậu Thổ, Nhâm Thủy và Giáp Mộc.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Bính Hỏa là dụng thần không thể thiếu vì nó giúp tạo ra hơi ấm và giúp
          xua đuổi băng giá mùa đông.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Miễn là Bính Hỏa xuất hiện trong lá số Bát Tự thì những dụng thần khác
          sẽ được dùng để đảm bảo ngày sinh Tân Kim được cân bằng và hữu tình.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Luận Giải:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Trong tháng này thì Kim và Thủy đều lạnh giá.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Vì Kim bị Thủy làm cho suy yếu, Hỏa nên được dùng để sưởi ấm và đảm
          bảo Tân Kim được cân bằng.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Kim, là Tỷ Kiếp của Tân hỗ trợ tăng cường cho Tân Kim mạnh trong tháng
          Tý.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Mộc mạnh sẽ hỗ trợ cho Hỏa Khí giúp Hỏa tăng cường cháy và là dụng
          thần của Tân Kim.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Mặc dù Thổ là Ấn Tinh của ngày sinh Tân Kim nhưng có khả năng làm
          nhiễm bẩn Thủy. Do đó Thổ không nên xuất hiện quá nhiều.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Lưu Ý:</strong>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Bính Hỏa là dụng thần số 1 của ngày sinh này. Hỏa là dụng thần điều
            hậu trong tháng mùa đông và càng quan trọng hơn là vì Tân Kim là Âm
            nên là kim loại mềm dẻo.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu được thì nên tránh nhiều Quý Thủy.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu Bính Hỏa là dụng thần thì phải được thông căn ở Địa Chi. Điều
            này bởi vì Bính Hỏa nếu trôi trên Thiên Can thì thiếu sức mạnh thật
            sự để đem đến hơi ấm cho ngày sinh Tân Kim trong khi Đinh Hỏa lại
            không thể thay thế cho Bính Hỏa.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Dụng Thần cho ngày sinh Tân Kim trong tháng mùa đông nên sử dụng như
            là thông quan để điều hậu. Đó là lý do vì sao thiếu vắng Bính Hỏa sẽ
            là bất lợi cho ngày sinh Tân Kim. Nếu thiếu Bính Hỏa thì người này
            sẽ thiếu hạnh phúc hay thành tích.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu được thì Quý Thủy cũng nên cần tránh xuất hiện ở Thiên Can. Nếu
            có Nhâm Thủy tàng can ở Địa Chi thì không vấn đề gì.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu có Bính Hỏa và Nhâm Thủy cùng xuất hiện thì ngày sinh Tân Kim sẽ
            có danh tiếng và tài lộc. Điều này bởi vì Bính Hỏa và Nhâm Thủy phụ
            thuộc lẫn nhau.<span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Nhâm Thủy và Mậu Thổ cùng xuất hiện ở Thiên Can thì ngày sinh
            Tân Kim chỉ có cuộc sống trung bình.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Địa Chi hình thành Thủy Cục với Nhâm Thủy lộ Thiên Can nhưng
            không có Mậu Thổ thì ngày sinh này sẽ cô đơn và thậm chí khó khăn để
            có thể tiến bộ.
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Cách Cục:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Chính Quan và Thương Quan:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu Mậu Thổ không xuất hiện ở Thiên Can thì người này có cuộc sống
          thịnh vượng.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lý tưởng nếu sinh vào ban ngày và có Bính ở Địa Chi.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu Mậu Thổ xuất hiện ở Thiên Can.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>
                Nếu lá số có Thương Quan:
                <span className="Apple-converted-space">&nbsp;</span>
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Địa Chi hình thành Thủy Cục thì nếu thiếu Bính Hỏa người này sẽ nghèo
          khó bất kể họ học thức hay tài năng bao nhiêu.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu Bính Hỏa ở Thiên Can.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu thiếu Bính Hỏa.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Tóm Tắt:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Bính Hỏa và Nhâm Thủy là dụng thần ưu tiên.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Không nên xuất hiện Quý Thuy ở Thiên Can.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Địa chi tạo thành Thủy Cục thì Thiên Can Canh Kim và Tân Kim có
            thể tạo thành Nhuận Hạ Cục. Bính Hỏa không nên có mặt trong lá số,
            nếu không sẽ biến thành lá số kém cỏi.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu hình thành Nhuận Hạ Cục thì nên trải qua đại vận Mộc và không
            nên bước vào vận Hỏa. Vận Thủy cũng được.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Bính Hỏa giúp cho lá số ấm và thủy ấm áp.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu có 2 Tân Kim Thiên Can hợp với Bính Hỏa trong lá số Bát Tự của
            nữ giới thì khi người này kết hôn không có duyên phận nhiều với
            chồng.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu Nhâm Thủy có mặt nhưng thiếu Bính Hỏa thì ngày sinh này sẽ cô
            đơn thậm chí sống như ẩn sĩ cô đơn. Nếu xuất hiện nhiều Bính Hỏa
            nhưng thiếu Nhâm Thủy thì ngày sinh này sẽ xuất sắc về tài chính và
            kinh doanh.<span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu thiếu Bính Hỏa thì người này sẽ thiếu hạnh phúc trong đời.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
    </div>
  );
};

export const TanThangSuu = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        className="p1"
        style={{
          margin: "6px 0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          <strong>Ngày sinh Tân trong tháng Sửu</strong>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Khái Quát:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì nếu có Kỷ Thổ hiện lên ở Thiên
          Can thì xem là hình thành cục Thiên Ấn.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Quý Thủy hiện lên ở Thiên Can thì hình thành cục Thực Thần.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng
          có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy
          ý.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Dụng Thần Điều Hậu:
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì dụng thần điều hậu là Bính Hỏa,
          Nhâm Thủy Mậu Thổ và Kỷ Thổ.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu xếp hạng thì Bính Hỏa là ưu tiên, sau đến Nhâm Thủy, Mậu Thổ và Kỷ
          Thổ.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Trong mọi tình huống thì Bính Hỏa không nên thiếu vắng trong lá số.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Luận Giải:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Sửu là một trong tứ mộ khố chứa Kim Khí. Do đó Tân Kim sẽ thông căn
          Kim khí ở đây.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Thời tiết lạnh lẽo và bầu trời cũng như mặt đất đều rét buốt.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Do đó cần có Hỏa để sưởi ấm ngày sinh này. Vì Mộc sinh ra Hỏa, không
          nên thiếu Mộc. Mộc và Hỏa là dụng thần không thể thiếu.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Mặc dù Sửu chứa Kim nhưng Mộc vẫn yếu trong tháng mùa đông. Chỉ có Mộc
          ấm áp mới có thể tạo ra Hỏa và có ích cho Tân Kim.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu được thì ngày sinh Tân Kim nên tránh gặp thêm Kim và Thủy Khí.
          Điều này bởi vì Thủy sẽ khắc chế Hỏa Khí vốn đang rất cần và sẽ khiến
          cho lá số lạnh giá thêm.{" "}
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Lưu Ý:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Bính Hỏa là dụng thần ưu tiên.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu Bính Hỏa xuất hiện ở Thiên Can nhưng không có Nhâm Thủy thì ngày
            sinh này sẽ thịnh vượng và giàu có mặc dù có thể không có quyền lực
            và danh tiếng.<span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nên tránh Quý Thủy nếu được.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu Nhâm Thủy xuất hiện ở Thiên Can nhưng không có Bính Hỏa thì ngày
            sinh này sẽ nghèo bất kể họ học thức bao nhiêu.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nhâm Thủy và Bính Hỏa vốn cần có nhau. Không thể có cái này mà không
            có cái kia.<span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Cách Cục:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Chính Quan và Thương Quan:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu Mậu Thổ không xuất hiện ở thiên Can thì ngày sinh này sẽ thịnh
          vượng.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu có Nhâm Thủy và thiếu Kỷ Thổ.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số sẽ xấu nếu Mậu Thổ Ấn Tinh lộ Thiên Can.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Thương Quan:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Địa chi tạo thành Thủy cục và không có Bính Hỏa trong lá số. Người này
          có thể nghèo khổ bất kể họ thông thái đến đâu.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Bính Hỏa nên xuất hiện ở Thiên Can thì tốt.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu không có Bính Hỏa.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Tóm Tắt:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Quý Thủy không nên xuất hiện ở Thiên Can. Bởi vì Quý Thủy đại diện
            cho tuyết sẽ chôn vùi Tân Kim.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu có Nhâm Thủy nhưng không có Bính Hỏa thì ngày sinh này sẽ chịu
            cô đơn. Nếu Bính Hỏa hiện diện nhiều nhưng thiếu Nhâm Thủy thì ngày
            sinh này sẽ có tài kinh doanh giỏi giang.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu Nhâm Thủy thiếu nhưng có Bính Hỏa thì sẽ có nhiều niềm vui trong
            đời. Tuy nhiên mức độ thành công lại chỉ ở tầm trung. Tài năng không
            được chú ý.<span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
    </div>
  );
};

export const TanThangDan = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        className="p1"
        style={{
          margin: "6px 0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          <strong>Ngày sinh Tân trong tháng Dần</strong>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Khái Quát:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì nếu có Giáp Mộc hiện lên ở
          Thiên Can thì xem là hình thành cục Chính Tài.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Bính Hỏa hiện lên ở Thiên Can thì hình thành cục Chính Quan.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Mậu Thổ hiện lên ở Thiên Can thì hình thành cục Chính Ấn.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng
          có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy
          ý.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Dụng Thần Điều Hậu:
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì dụng thần điều hậu là Kỷ Thổ,
          Nhâm Thủy và Canh Kim.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi ngày sinh Tân Kim là yếu thì Kỷ Thổ nên là nguồn để sinh ra và hỗ
          trợ Tân Kim.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          Nếu ngày sinh Tân Kim muốn thịnh vượng trong đời thì cần phải dựa vào
          sự hiện diện và sức mạnh của Nhâm Thủy trong lá số Bát Tự.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Do đó, Nhâm Thủy và Kỷ Thổ nên được dùng như là dụng thần ưu tiên và
          Canh Kim đóng vai trò hỗ trợ sẽ giúp Tân Kim sẽ hữu tinh và có độ cân
          bằng.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Luận Giải:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Trong tháng này thì Mộc và Hỏa Khí đóng vai trò quan trọng so với Thổ
          và Kim khí.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Theo lẽ tự nhiên khi Mộc khí vượng thì Kim khí sẽ yếu. Do đó, khi ngày
          sinh Tân Kim gặp nhiều Tài Tinh trong lá số Bát Tự thì sẽ có thể nói
          rằng ngày sinh Tân Kim sẽ yếu là tất yếu.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Thổ nên được sử dụng để hỗ trợ Kim. Chỉ khi có Thổ , Giáp Mộc Tài Tinh
          và Hỏa Quan Tinh có thể dùng để cân bằng ngày sinh Tân Kim.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Tuy nhiên, không thích hợp nếu Tân Kim gặp nhiều Thủy. Điều này bởi vì
          Kim khí sẽ rò rỉ và bị yếu đi và do đó Mộc khí sẽ được sinh ra và tăng
          trưởng. Nhiều Thủy làm ô nhiễm Thổ. Tân Kim mất đi sự duyên dáng.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Ngày sinh Tân Kim vào giờ Thìn của tháng Dần sẽ thấy rằng Hỏa, sáng rõ
          sẽ hỗ trợ cho Thổ vượng. Trong mọi hoàn cảnh thì sẽ dễ hình thành Hỏa
          Cục có liên quan đến Dần và Ngọ.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Kịch bản lý tưởng nhất là Thổ ướt ẩm sẽ giúp cho Kim khí vượng.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Lưu Ý:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Kỷ Thổ và Nhâm Thủy là dụng thần lý tưởng.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Sẽ tốt nhất nếu Địa Chi không tạo thành Hỏa Cục.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Tân Kim không phù hợp cho ngày sinh Canh Kim mạnh. Tân Kim, vốn là
            Âm Kim, có thể được biểu thị bởi vàng kim hoàn, do đó có bản chất
            bóng bẩy và thanh lịch. Gặp thêm Hỏa sẽ chỉ kìm hãm sắc đẹp.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Hỏa vượng không nên được dùng để làm suy yếu Tân Kim, thay vào đó
            thì Thủy nên là lựa chọn tốt để làm sạch và ẩm Tân Kim. Đó là lý do
            vì sao Nhâm Thủy là lựa chọn tốt như là dụng thần, đối với ngày sinh
            Tân Kim trong tháng này.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Kỷ Thổ có thể được dùng để sinh và hỗ trợ Tân Kim. Mậu Thổ và Canh
            Kim sẽ không nên dùng làm dụng thần. Nó sẽ tốt cho Kỷ Thổ và Nhâm
            Thủy nếu không hiện diện bên cạnh nhau trong lá số Bát Tự.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Mộc Khí vượng trong tháng Dần. Trong khi Tân Kim thiếu năng lực hay
            khả năng để kiểm soát Mộc, chỉ có Kỷ Thổ mới nên được dùng để sinh
            ra Tân Kim.
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Cách Cục:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Thiên Ấn và Thương Quan:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi có Tỷ Kiếp xuất hiện ở lá số Bát Tự thì ngày sinh Tân Kim sẽ vượng
          trong đời.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          Sẽ lý tưởng nếu Kỷ Thổ là dụng thần nên xuất hiện ở tàng can của Sửu
          hay Mùi.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          Nếu có Kỷ Thổ hay Nhâm Thủy vắng mặt trong lá số Bát Tự, ngày sinh này
          sẽ không thể đạt thành công hay thịnh vượng, đúng với năng lực thật sự
          của họ.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>
                Nếu lá số có Chính Quan và Thất Sát:
                <span className="Apple-converted-space">&nbsp;</span>
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Địa Chi tạo thành Hỏa Cục, không có Canh Kim xuất hiện ở Thiên Can
          thì ngày sinh Tân Kim sẽ chỉ có cuộc sống trung bình.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Canh Kim và Nhâm Thủy cùng hiện trong lá số Bát Tự thì sẽ có cuộc sống
          hạnh phúc, dễ dàng.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Ngày sinh Tân Kim không thông căn ở tàng can thì không tốt. Cũng như
          nếu Hỏa xuất hiện ở vị trí trong lá số Bát Tự thì bất lợi cho Tân.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Thương Quan và Thực Thần:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có nhiều Thủy Khí trong Địa Chi và Thủy Khí cũng xuất hiện nhiều ở
          Thiên Can thì nếu thiếu Chính Quan Bính Hỏa thì ngày sinh này sẽ vất
          vả khi còn trẻ. Về già sẽ ổn.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Bính Hỏa, vốn là Chính Quan, xuất hiện ở Thiên Can là tốt.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu Bính Hỏa sinh vào ban đêm hay bị Quý Thủy che phủ.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Tóm Tắt:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Kỷ Thổ và Nhâm Thủy là dụng thần lý tưởng.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Giáp Mộc không nên xuất hiện ở Thiên Can.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Giáp Mộc và Ất Mộc tạo thành Tài Cục với ngày sinh Tân Kim trong
            tháng Dần. Cục này sẽ vô dụng nhưng ngày sinh này sẽ không thịnh
            vượng trong đời.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Sẽ tốt hơn nếu thấy Chính Quan xuất hiện.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Địa Chi tạo thành Hỏa Cục Thất Sát, thì sẽ bất lợi cho ngày sinh
            Tân Kim. Nếu Canh Kim và Nhâm Thủy xuất hiện trong lá số thì người
            này sẽ có cuộc đời thuận buồm xuôi gió. Nếu gặp đại vận phù hợp thì
            họ có thể đạt thịnh vượng. Ngũ hành quan trọng nhất là Nhâm Thủy.
          </span>
        </li>
      </ul>
    </div>
  );
};

export const TanThangMao = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        className="p1"
        style={{
          margin: "6px 0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          <strong>Ngày sinh Tân trong tháng Mão</strong>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Khái Quát:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì hình thành cục Thiên Tài dù có
          Ất Mộc lộ Thiên Can hay không.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Dụng Thần Điều Hậu:
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì dụng thần điều hậu là Nhâm Thủy
          và Giáp Mộc.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi ngày sinh Tân Kim yếu thì Kỷ Thổ nên được dùng làm nguồn để sinh
          và củng cố Tân Kim.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu ngày sinh này muốn vượng thì cần dựa nhiều vào sức mạnh của Nhâm
          Thủy. Nhâm giúp cho Tân Kim thể hiện mức độ tốt nhất.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Do đó, Nhâm Thủy và Kỷ Thổ nên được dùng làm dụng thần trong khi Canh
          Kim nên đóng vai trò ủng hộ, giúp cho Tân Kim cân bằng và hữu tình.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Luận Giải:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Mão biểu thị ngũ hành mạnh nhất trong tháng mùa xuân, Mộc Khí ở
          mức vượng nên Tân Kim sẽ yếu.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Do đó, Thổ và Kim là dụng thần lý tưởng nhất cho ngày sinh Tân Kim
          trong tháng Mão.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Bởi vì Mộc Khí vượng nhất vào mùa Xuân, nên nó sẽ khắc chế và kiểm
          soát Thổ Khí. Điều này có nghĩa là Ấn Tinh của ngày sinh Tân Kim bị
          kiểm soát và suy yếu.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Sẽ tốt nhất nếu ngày sinh Tân Kim tránh gặp thêm Mộc và Thủy trong lá
          số. Bởi vì những hành này sẽ làm tổn hại Thổ, cũng như Kim.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Lưu Ý:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Sẽ có lợi nếu ngày sinh Tân Kim có cả Nhâm Thủy và Giáp Mộc lộ trong
            lá số.<span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu được thì ngày sinh Tân Kim cần tránh gặp Mậu Thổ và Kỷ Thổ cùng
            lúc. Quá nhiều Thổ chôn vùi Kim.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Hỏa và Thổ cùng xuất hiện trong lá số cũng như thông căn, 2 Nhâm
            Thủy sẽ cần xuất hiện ở Thiên Can, để hình thành bất kỳ cục nào trên
            trung bình hoặc tốt.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nhâm Thủy Thương Quan và Giáp Mộc Chính Tài là những sự lựa chọn tốt
            nhất để hoàn thiện Tân Kim. Sự vắng mặt của một trong hai hành hoặc
            cả hai thì sẽ khiến cho lá số Tân Kim này chỉ trung bình.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nhiều hành Thổ là không tốt. Sẽ có cuộc đời nhiều trì hoãn và trở
            ngại.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Căn gốc của Tân Kim là Dậu đứng bên cạnh chi tháng có thể gây xui
            xẻo vào trung niên.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Cách Cục:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Thương Quan và Chính Tài:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu Nhâm Thủy lộ trên lá số Bát Tự thì ngày sinh Tân Kim sẽ hưởng cuộc
          sống thịnh vượng.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu Nhâm Thủy và Giáp Mộc ở Thiên Can.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu thiếu Giáp Mộc ở Thiên Can và có Mậu Thổ ở Thiên Can thì là lá số
          xấu.
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>
                Nếu lá số có Thương Quan:
                <span className="Apple-converted-space">&nbsp;</span>
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu Nhâm Thủy hình thành cục Thương Quan vượng với ngày sinh Tân Kim
          và Địa Chi tạo thành cục Kim và Thủy – cuộc sống nhiều rắc rối và
          phiền muộn.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu có Mậu Thổ để kiểm soát Thủy.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu có Quý Thủy ở Thiên Can.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số Chính Quan Thất Sát:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Địa Chi tương ứng tạo thành Hỏa Cục. Lá số này sẽ kém. Ngay cả
          sự hiện diện của Mậu Thổ và Kỷ Thổ sẽ không giúp cải thiện tình hình.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Thiên Can gặp hai hay nhiều Nhâm Thủy thì cả hai đều phải xuất
          hiện ở lá số Bát Tự thì lá số mới cân bằng và tích cực.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu không có Nhâm Thủy xuất hiện ở Thiên Can, ngày sinh Tân
          Kim sẽ dễ bị sức khỏe xấu và bệnh tật cả đời.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Tóm Tắt:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nhâm Thủy là dụng thần ưu tiên.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Sẽ bất lợi nếu ngày sinh này sử dụng Tài, Quan, Ấn làm dụng thần.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi sử dụng Chính Quan thì Nhâm Thủy cần được định vị chiến lược.
            Tương tự, nếu Chính Tài sử dụng, thì Kiếp Tài Canh Kim cần phải định
            vị đúng.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Mậu Thổ hay Kỷ Thổ nên tránh đi vì cả hai sẽ hình thành Ấn Tinh quá
            độ chôn vùi Tân Kim.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Quá nhiều Thổ sẽ biểu thị ngày sinh này là người thiếu sức sống.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
    </div>
  );
};

export const TanThangThin = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        className="p1"
        style={{
          margin: "6px 0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          <strong>Ngày sinh Tân trong tháng Thìn</strong>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Khái Quát:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì nếu có Mậu Thổ hiện lên ở Thiên
          Can thì xem là hình thành cục Chính Ấn.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Ất Mộc hiện lên ở Thiên Can thì hình thành cục Thất Sát.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Quý Thủy hiện lên ở Thiên Can thì hình thành cục Thực Thần.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng
          có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy
          ý.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Dụng Thần Điều Hậu:
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì dụng thần điều hậu là Nhâm Thủy
          và Giáp Mộc.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Bính Hỏa kết hợp với Tân Kim tạo thành Thủy thì Quý Thủy cần kiểm
          soát Bính Hỏa.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Ngày sinh Tân Kim sẽ thịnh vượng nếu toàn bộ các địa chi Hợi Tý và
          Thân xuất hiện trong lá số Bát Tự.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Tân Kim vượng khi gặp Thủy.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Luận Giải:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Ngày sinh Tân Kim trong tháng Thìn chứa tàng can Mậu Thổ, Ất Mộc và
          Quý Thủy. Thổ Khí vượng trong tháng Thìn. Do đó khi Thổ ướt hỗ trợ Tân
          Kim, trong khi Thủy Khí đồng thời nuôi dưỡng Mộc, ngày sinh Tân Kim sẽ
          thịnh vượng trong đời.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Sẽ tốt hơn nếu sử dụng Thủy và Mộc để đem đến sự cân bằng cho ngày
          sinh Tân Kim thay vì Mộc và Hỏa. Nếu được thì nên tránh Hỏa vì nó làm
          khô và sinh ra thêm Thổ, vốn đã nhiều trong tháng Thìn. Thổ quá dày
          hay cứng sẽ chôn vùi Tân Kim.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Ngày sinh Tân Kim trong tháng Thìn nên tránh gặp thêm Thổ càng nhiều
          càng tốt.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Lưu Ý:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nhâm Thủy và Giáp Mộc nên xuất hiện ở trong lá số.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Mậu Thổ và Kỷ Thổ nên tránh nếu được. Nếu không thì Tân Kim sẽ bị
            chôn vùi.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu cả 4 Địa Chi Thìn Tuất Sửu Mùi xuất hiện mà không có Giáp Mộc
            hiện lên Thiên Can thì lá số này sẽ kém cỏi. Cuộc đời tràn đầy sự
            phàn nàn và trì hoãn.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Địa Chi hé lộ nhiều Hỏa Khí, Nhâm Thủy sẽ là sự lựa chọn tối ưu
            nhất. Và nó cần xuất hiện ở Thiên Can để hiệu quả.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Địa Chi tạo thành Kim và Thủy Cục, thì có khả năng ngày sinh này
            sẽ có sức khỏe kém và bệnh tật cả đời.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Cách Cục:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>
                Nếu lá số có
                <span className="Apple-converted-space">&nbsp; </span>Thương
                Quan và Chính Tài:
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Bính Hỏa không nên hợp với Tân Kim sẽ đem đến thịnh vượng, danh tiếng
          và thỏa mãn.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Bính Hỏa hỗ trợ cho lá số.
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>
                Nếu lá số có Chính Ấn và Thiên Ấn:
                <span className="Apple-converted-space">&nbsp;</span>
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có nhiều Thìn Tuất Sửu Mùi trong Địa Chi và không có Giáp Mộc ở
          Thiên Can thì ngày sinh này sẽ cứng đầu và khó tính.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Sự xuất hiện của Thổ sẽ làm cho Tân Kim hoàn toàn vô dụng. Người này
          sẽ gặp nhiều trở ngại cả đời và bệnh tật kinh niên.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu Giáp Mộc Chính Tài xuất hiện ở Thiên Can.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu có Mậu Thổ xuất hiện ở Thiên Can. Sự hiện diện của Quý
          Thủy sẽ làm nhiễm bẩn Thổ.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Chính Quan Thất Sát:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Địa Chi tạo thành Hỏa Cục Thất Sát và thiếu Nhâm Thủy ở Thiên Can
          thì ngày sinh Tân Kim sẽ đối mặt trở ngại.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nhâm Thủy Thương Quan ở Thiên Can thì lá số tốt.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu có hơn 2 Bính Hỏa tồn tại trong lá số.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Tóm Tắt:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nhâm Thủy là dụng thần tối ưu.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Bính Hỏa không nên xuất hiện ở Thiên Can và hợp với Tân Kim vì cả
            Bính và Tân hợp hóa thành Thủy.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Địa Chi gặp thêm Thủy Thực Thương thì Tài Tinh của sao này sẽ
            đóng vai trò dụng thần ưu tiên.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Nhâm Thủy hay Giáp Mộc đều không xuất hiện ở Thiên Can thì ngày
            sinh Tân Kim sẽ có cuộc sống trung bình.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Nhâm Thủy không xuất hiện ở Thiên Can thì Quý Thủy có thể thay
            thế.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi xuất hiện nhiều Tỷ Kiếp trong lá số Bát Tự thì ngày sinh này sẽ
            không sống thọ.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi có Mậu Thổ và Kỷ Thổ ở Thiên Can thì lá số này kém cỏi.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
    </div>
  );
};

export const TanThangTy = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        className="p1"
        style={{
          margin: "6px 0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          <strong>Ngày sinh Tân trong tháng Tỵ</strong>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Khái Quát:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì nếu có Bính Hỏa hiện lên ở
          Thiên Can thì xem là hình thành cục Chính Quan.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Mậu Thổ hiện lên ở Thiên Can thì hình thành cục Chính Ấn.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng
          có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy
          ý.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Dụng Thần Điều Hậu:
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì dụng thần điều hậu là Giáp Mộc,
          Nhâm Thủy và Quý Thủy.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Ngoài tác dụng làm sạch thì Nhâm Thủy còn có khả năng điều hậu ngày
          sinh Tân Kim và được xem như là dụng thần số 1. Cộng với sự hiện diện
          của Giáp Mộc để kiểm soát Mậu Thổ, có thể hình dung ra Tân Kim sẽ được
          sạch sẽ và nguyên sơ như thế nào trong lá số Bát Tự.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Luận Giải:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Địa Chi Tỵ chứa tàng can Canh Kim, Bính Hỏa và Mậu Thổ.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Hỏa vượng trong tháng mùa hè này và Tân Kim sẽ chịu sự kiểm soát của
          Hỏa.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Do đó, Thủy cần làm ẩm ngày sinh Tân Kim trong tháng Tỵ. Hành Kim ẩn
          trong địa chi Tỵ có thể được dùng như là dụng thần trong hoàn cảnh
          này.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lý tưởng nhất là có Kim để hỗ trợ và tăng cường ngày sinh Tân Kim. Một
          sự lựa chọn khác là Thủy đi kèm với Mộc để tạo sự cân bằng cho ngày
          sinh Tân Kim.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Thủy và Hỏa có thể dùng để biến ngày sinh Tân Kim này thành vượng.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Kim và Thủy là dụng thần chính cho ngày sinh trong tháng Tỵ. Mộc Khí,
          nếu có một ít thì là hỉ thần. Điều này bởi vì nhiều Mộc khí sẽ giúp
          sinh ra quá nhiều Hỏa Khí, và do đó lại quay sang kiểm soát mạnh Tân
          Kim.
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Lưu Ý:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nhâm Thủy và Quý Thủy là dụng thần ưu tiên.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Ngày sinh Tân Kim trong tháng Tỵ cần tránh gặp Bính Hỏa, Mậu Thổ và
            Kỷ Thổ ở Thiên Can nếu được.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu Quý Thủy lộ can nhưng Nhâm Thủy ẩn tàng thì ngày sinh Tân Kim sẽ
            vượng và trở nên giàu có, mặc dù có thể thiếu quyền lực và địa vị.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu Nhâm Thủy và Quý Thủy vắng mặt ở Thiên Can thì ngày sinh này sẽ
            chỉ có cuộc sống trung bình.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu Mậu Thổ và Bính Hỏa xuất hiện ở Thiên Can thì ngày sinh Tân Kim
            sẽ chịu cô đơn trong đời.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Địa Chi hình thành Mộc Cục hay Hỏa Cục thì ngày sinh Tân Kim sẽ
            có cuộc sống trung bình.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Địa Chi tạo thành Kim Cục thì cùng với Nhâm Thủy lộ ra thì ngày
            sinh Tân Kim sẽ hưởng thành công và kết quả tốt.
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Cách Cục:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Chính Tài và Thiên Tài:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Địa Chi bao gồm phần lớn là hành Mộc Tài Tinh cùng với sự hiện
          diện của Tỷ Kiếp lộ trong lá số thì người này sẽ có cuộc sống thịnh
          vượng và xa hoa.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu có Canh Kim xuất hiện ở Thiên Can.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu Tỷ Kiếp không xuất hiện trong lá số xấu.
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>
                Nếu lá số có Chính Quan và Thất Sát:
                <span className="Apple-converted-space">&nbsp;</span>
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu Địa Chi tạo thành Hỏa Cục Thất Sát và Nhâm Thủy Quý Thủy hiện diện
          trong lá số thì người này sẽ hưởng địa vị, quyền lực lớn.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu Nhâm Thủy hiện diện ở Thiên Can.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu Nhâm Thủy và Quý Thủy không xuất hiện ở Thiên Can, thì ngày sinh
          Tân Kim sẽ có lá số tổng thể kém.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Tóm Tắt:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Canh Kim và Tân Kim là dụng thần ưu tiên.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Bính Hỏa nên tránh xuất hiện trong lá số.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi thiếu Thủy trong lá số thì người này có thể nghèo.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Sẽ tốt hơn nếu Địa Chi hình thành Kim Cục cùng với Thủy lộ Thiên Can
            ở trong lá số để đảm bảo cho người Tân Kim có cuộc sống thịnh vượng.
          </span>
        </li>
      </ul>
    </div>
  );
};

export const TanThangNgo = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        class="p1"
        style="margin: 6px 0px; text-align: center; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 18px; line-height: normal; font-family: 'Times New Roman'; color: #00000a;"
      >
        <span style="font-family: arial, helvetica, sans-serif; font-size: 18pt;">
          <strong>Ng&agrave;y sinh T&acirc;n trong th&aacute;ng Ngọ</strong>
        </span>
      </p>
      <ul class="ul1" style="font-size: medium;">
        <li
          class="li2"
          style="margin: 6px 0px; text-align: justify; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: 'Times New Roman';"
        >
          <span style="font-family: arial, helvetica, sans-serif; font-size: 14pt;">
            <strong>Kh&aacute;i Qu&aacute;t:</strong>
          </span>
        </li>
      </ul>
      <p
        class="p2"
        style="margin: 6px 0px; text-align: justify; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: 'Times New Roman';"
      >
        <span style="font-family: arial, helvetica, sans-serif; font-size: 14pt;">
          {" "}
          Đối với người sinh trong th&aacute;ng n&agrave;y th&igrave; nếu
          c&oacute; Đinh Hỏa hiện l&ecirc;n ở Thi&ecirc;n Can th&igrave; xem
          l&agrave; h&igrave;nh th&agrave;nh cục Thất S&aacute;t.
        </span>
      </p>
      <p
        class="p2"
        style="margin: 6px 0px; text-align: justify; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: 'Times New Roman';"
      >
        <span style="font-family: arial, helvetica, sans-serif; font-size: 14pt;">
          {" "}
          Nếu c&oacute; Kỷ Thổ hiện l&ecirc;n ở Thi&ecirc;n Can th&igrave;
          h&igrave;nh th&agrave;nh cục Thi&ecirc;n Ấn.
        </span>
      </p>
      <p
        class="p2"
        style="margin: 6px 0px; text-align: justify; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: 'Times New Roman';"
      >
        <span style="font-family: arial, helvetica, sans-serif; font-size: 14pt;">
          {" "}
          Nếu như kh&ocirc;ng c&oacute; những h&agrave;nh tr&ecirc;n hiện
          l&ecirc;n ở Thi&ecirc;n Can th&igrave; bạn đọc cũng c&oacute; thể
          t&ugrave;y chọn theo ngũ h&agrave;nh vượng nhất trong l&aacute; số
          m&agrave; định cục t&ugrave;y &yacute;.
        </span>
      </p>
      <p
        class="p3"
        style="margin: 6px 0px; text-align: justify; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: 'Times New Roman'; min-height: 15px;"
      >
        &nbsp;
      </p>
      <ul class="ul1" style="font-size: medium;">
        <li
          class="li2"
          style="margin: 6px 0px; text-align: justify; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: 'Times New Roman';"
        >
          <span style="font-family: arial, helvetica, sans-serif; font-size: 14pt;">
            <strong>
              Dụng Thần Điều Hậu:
              <span class="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        class="p2"
        style="margin: 6px 0px; text-align: justify; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: 'Times New Roman';"
      >
        <span style="font-family: arial, helvetica, sans-serif; font-size: 14pt;">
          {" "}
          Đối với người sinh trong th&aacute;ng n&agrave;y th&igrave; dụng thần
          điều hậu l&agrave; Nh&acirc;m Thủy, Kỷ Thổ v&agrave; Qu&yacute; Thủy.
          <span class="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        class="p2"
        style="margin: 6px 0px; text-align: justify; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: 'Times New Roman';"
      >
        <span style="font-family: arial, helvetica, sans-serif; font-size: 14pt;">
          {" "}
          Tuy nh&ecirc;n Kỷ Thổ kh&ocirc;ng đủ để trở th&agrave;nh Dụng Thần nếu
          thiếu Nh&acirc;m Thủy, bởi v&igrave; n&oacute; sẽ thiếu độ ẩm cần
          thiết để đ&oacute;ng vai tr&ograve; Dụng Thần. Tương tự, T&acirc;n Kim
          kh&ocirc;ng th&ecirc; thiếu Kỷ Thổ.
          <span class="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        class="p2"
        style="margin: 6px 0px; text-align: justify; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: 'Times New Roman';"
      >
        <span style="font-family: arial, helvetica, sans-serif; font-size: 14pt;">
          {" "}
          Do đ&oacute;, cả Nh&acirc;m Thủy v&agrave; Kỷ Thổ đều phải c&ugrave;ng
          xuất hiện trong l&aacute; số B&aacute;t Tự, nếu cả hai muốn
          đ&oacute;ng vai tr&ograve; tương ứng Dụng Thần.
          <span class="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        class="p2"
        style="margin: 6px 0px; text-align: justify; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: 'Times New Roman';"
      >
        <span style="font-family: arial, helvetica, sans-serif; font-size: 14pt;">
          {" "}
          Nếu thiếu đi Nh&acirc;m Thủy, Qu&yacute; Thủy c&oacute; thể
          đ&oacute;ng vai tr&ograve; Dụng Thần thay thế.
        </span>
      </p>
      <p
        class="p3"
        style="margin: 6px 0px; text-align: justify; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: 'Times New Roman'; min-height: 15px;"
      >
        &nbsp;
      </p>
      <ul class="ul1" style="font-size: medium;">
        <li
          class="li2"
          style="margin: 6px 0px; text-align: justify; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: 'Times New Roman';"
        >
          <span style="font-family: arial, helvetica, sans-serif; font-size: 14pt;">
            <strong>
              Luận Giải:<span class="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        class="p2"
        style="margin: 6px 0px; text-align: justify; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: 'Times New Roman';"
      >
        <span style="font-family: arial, helvetica, sans-serif; font-size: 14pt;">
          {" "}
          Thổ sẽ kh&ocirc; v&agrave;o giữa m&ugrave;a h&egrave;. V&igrave; lẽ
          đ&oacute;, Thổ kh&ocirc; kh&ocirc;ng thể sinh ra Kim mềm.
        </span>
      </p>
      <p
        class="p2"
        style="margin: 6px 0px; text-align: justify; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: 'Times New Roman';"
      >
        <span style="font-family: arial, helvetica, sans-serif; font-size: 14pt;">
          {" "}
          Tương tự, Thủy gặp Hỏa trong Ngọ sẽ kh&ocirc;ng thể khống chế Hỏa được
          v&agrave; từ đ&oacute; bị kh&ocirc; hạn.
        </span>
      </p>
      <p
        class="p2"
        style="margin: 6px 0px; text-align: justify; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: 'Times New Roman';"
      >
        <span style="font-family: arial, helvetica, sans-serif; font-size: 14pt;">
          {" "}
          Thổ ẩm ướt v&agrave; Thủy cần th&ocirc;ng căn trong ngũ h&agrave;nh
          c&oacute; lợi th&igrave; sẽ l&agrave; sao l&yacute; tưởng cho
          ng&agrave;y sinh T&acirc;n Kim n&agrave;y.
        </span>
      </p>
      <p
        class="p2"
        style="margin: 6px 0px; text-align: justify; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: 'Times New Roman';"
      >
        <span style="font-family: arial, helvetica, sans-serif; font-size: 14pt;">
          {" "}
          Ng&agrave;y sinh T&acirc;n Kim kh&ocirc;ng n&ecirc;n gặp th&ecirc;m
          Mộc Kh&iacute;. Điều n&agrave;y bởi v&igrave; Mộc sẽ l&agrave; nơi
          sinh ra v&agrave; củng cố h&agrave;nh Hỏa, vốn dĩ đ&atilde;
          n&oacute;ng trong m&ugrave;a h&egrave;. V&agrave; Hỏa qu&aacute;
          n&oacute;ng th&igrave; T&acirc;n Kim sẽ bị tan chảy.{" "}
          <span class="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul class="ul1" style="font-size: medium;">
        <li
          class="li2"
          style="margin: 6px 0px; text-align: justify; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: 'Times New Roman';"
        >
          <span style="font-family: arial, helvetica, sans-serif; font-size: 14pt;">
            <strong>Lưu &Yacute;:</strong>
          </span>
        </li>
      </ul>
      <ul class="ul1" style="font-size: medium;">
        <li
          class="li2"
          style="margin: 6px 0px; text-align: justify; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: 'Times New Roman';"
        >
          <span style="font-family: arial, helvetica, sans-serif; font-size: 14pt;">
            Nh&acirc;m Thủy v&agrave; Kỷ Thổ l&agrave; dụng thần l&yacute; tưởng
            (Khi đề cập Kỷ Thổ th&igrave; sẽ l&agrave; Thổ ẩm ướt).
          </span>
        </li>
        <li
          class="li2"
          style="margin: 6px 0px; text-align: justify; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: 'Times New Roman';"
        >
          <span style="font-family: arial, helvetica, sans-serif; font-size: 14pt;">
            N&ecirc;n tr&aacute;nh B&iacute;nh Hỏa v&agrave; Mậu Thổ nếu được
            cũng như tr&aacute;nh h&igrave;nh th&agrave;nh Hỏa Cục.
          </span>
        </li>
        <li
          class="li2"
          style="margin: 6px 0px; text-align: justify; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: 'Times New Roman';"
        >
          <span style="font-family: arial, helvetica, sans-serif; font-size: 14pt;">
            Th&aacute;ng Ngọ chứa Kỷ Thổ t&agrave;ng can trong Kỷ Thổ l&agrave;
            đất ướt. Do đ&oacute; Nh&acirc;m Thủy n&ecirc;n tr&aacute;nh bị
            nhiễm bẩn bợi Kỷ Thổ.
          </span>
        </li>
        <li
          class="li2"
          style="margin: 6px 0px; text-align: justify; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: 'Times New Roman';"
        >
          <span style="font-family: arial, helvetica, sans-serif; font-size: 14pt;">
            Nếu kh&ocirc;ng c&oacute; Nh&acirc;m Thủy, th&igrave; Kỷ Thổ
            kh&ocirc;ng thể ẩm ướt. V&agrave; kh&ocirc;ng c&oacute; Kỷ Thổ
            th&igrave; ng&agrave;y sinh T&acirc;n Kim kh&ocirc;ng thể sinh ra
            v&agrave; tăng cường sức mạnh.
            <span class="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          class="li2"
          style="margin: 6px 0px; text-align: justify; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: 'Times New Roman';"
        >
          <span style="font-family: arial, helvetica, sans-serif; font-size: 14pt;">
            Khi Địa Chi tương ứng h&igrave;nh th&agrave;nh Hỏa Cục, Nh&acirc;m
            Thủy cần xuất hiện ở Thi&ecirc;n Can. Ng&agrave;y sinh T&acirc;n Kim
            trong th&aacute;ng m&ugrave;a h&egrave; n&ecirc;n sử dụng Nh&acirc;m
            Thủy v&agrave; Qu&yacute; Thủy l&agrave;m dụng thần cũng như
            th&ocirc;ng căn ở Địa Chi.
            <span class="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          class="li2"
          style="margin: 6px 0px; text-align: justify; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: 'Times New Roman';"
        >
          <span style="font-family: arial, helvetica, sans-serif; font-size: 14pt;">
            Thổ kh&ocirc; hủy diệt T&acirc;n Kim. Thủy sẽ l&agrave; cứu
            c&aacute;nh.
          </span>
        </li>
      </ul>
      <p
        class="p3"
        style="margin: 6px 0px; text-align: justify; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: 'Times New Roman'; min-height: 15px;"
      >
        &nbsp;
      </p>
      <ul class="ul1" style="font-size: medium;">
        <li
          class="li2"
          style="margin: 6px 0px; text-align: justify; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: 'Times New Roman';"
        >
          <span style="font-family: arial, helvetica, sans-serif; font-size: 14pt;">
            <strong>C&aacute;ch Cục:</strong>
          </span>
        </li>
      </ul>
      <ul class="ul1" style="font-size: medium;">
        <li
          class="li2"
          style="margin: 6px 0px; text-align: justify; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: 'Times New Roman';"
        >
          <span style="font-family: arial, helvetica, sans-serif; font-size: 14pt;">
            <span class="s1" style="text-decoration-line: underline;">
              <strong>
                Nếu l&aacute; số c&oacute; Thương Quan, Thực Thần v&agrave;
                Thi&ecirc;n Ấn:
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        class="p2"
        style="margin: 6px 0px; text-align: justify; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: 'Times New Roman';"
      >
        <span style="font-family: arial, helvetica, sans-serif; font-size: 14pt;">
          {" "}
          Nếu ng&agrave;y sinh T&acirc;n Kim muốn c&oacute; danh tiếng
          th&igrave; phải đồng thời c&oacute; đủ Nh&acirc;m Thủy, Qu&yacute;
          Thủy v&agrave; Kỷ Thổ được xem như l&agrave; Dụng Thần.
          <span class="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        class="p2"
        style="margin: 6px 0px; text-align: justify; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: 'Times New Roman';"
      >
        <span style="font-family: arial, helvetica, sans-serif; font-size: 14pt;">
          {" "}
          Nếu thiếu h&agrave;nh Hỏa ở Thi&ecirc;n Can th&igrave; Mộc v&agrave;
          Thủy phải c&oacute; mặt.
        </span>
      </p>
      <p
        class="p2"
        style="margin: 6px 0px; text-align: justify; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: 'Times New Roman';"
      >
        <span style="font-family: arial, helvetica, sans-serif; font-size: 14pt;">
          {" "}
          L&aacute; số xấu nếu c&oacute; qu&aacute; nhiều Hỏa v&agrave; thiếu
          Thủy trong l&aacute; số. L&aacute; số cũng kh&ocirc;ng tốt nếu như Hỏa
          xuất hiện ở Thi&ecirc;n Can.
          <span class="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul class="ul1" style="font-size: medium;">
        <li
          class="li2"
          style="margin: 6px 0px; text-align: justify; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: 'Times New Roman';"
        >
          <span style="font-family: arial, helvetica, sans-serif; font-size: 14pt;">
            <span class="s1" style="text-decoration-line: underline;">
              <strong>
                Nếu l&aacute; số c&oacute; Ch&iacute;nh Quan Thất S&aacute;t:
                <span class="Apple-converted-space">&nbsp;</span>
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        class="p2"
        style="margin: 6px 0px; text-align: justify; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: 'Times New Roman';"
      >
        <span style="font-family: arial, helvetica, sans-serif; font-size: 14pt;">
          {" "}
          Nếu Địa Chi h&igrave;nh th&agrave;nh Hỏa Cục th&igrave; cả Nh&acirc;m
          Thủy v&agrave; Qu&yacute; Thủy phải được xuất hiện trong l&aacute; số
          B&aacute;t Tự, để gi&uacute;p cho ng&agrave;y sinh n&agrave;y hữu
          t&igrave;nh v&agrave; c&acirc;n bằng.
        </span>
      </p>
      <p
        class="p2"
        style="margin: 6px 0px; text-align: justify; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: 'Times New Roman';"
      >
        <span style="font-family: arial, helvetica, sans-serif; font-size: 14pt;">
          {" "}
          L&aacute; số tốt nếu c&oacute; Thủy ở Thi&ecirc;n Can v&agrave;
          th&ocirc;ng căn.
        </span>
      </p>
      <p
        class="p2"
        style="margin: 6px 0px; text-align: justify; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: 'Times New Roman';"
      >
        <span style="font-family: arial, helvetica, sans-serif; font-size: 14pt;">
          {" "}
          L&aacute; số xấu nếu Thủy thiếu trong Tứ Trụ. Nếu Mậu Thổ xuất hiện ở
          Thi&ecirc;n Can v&agrave; kh&ocirc;ng c&oacute; Thủy trong B&aacute;t
          Tự cũng l&agrave; xấu.
        </span>
      </p>
      <p
        class="p3"
        style="margin: 6px 0px; text-align: justify; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: 'Times New Roman'; min-height: 15px;"
      >
        &nbsp;
      </p>
      <ul class="ul1" style="font-size: medium;">
        <li
          class="li2"
          style="margin: 6px 0px; text-align: justify; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: 'Times New Roman';"
        >
          <span style="font-family: arial, helvetica, sans-serif; font-size: 14pt;">
            <strong>
              T&oacute;m Tắt:<span class="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <ul class="ul1" style="font-size: medium;">
        <li
          class="li2"
          style="margin: 6px 0px; text-align: justify; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: 'Times New Roman';"
        >
          <span style="font-family: arial, helvetica, sans-serif; font-size: 14pt;">
            Nh&acirc;m thủy v&agrave; Kỷ Thổ l&agrave; dụng thần ưu ti&ecirc;n.
          </span>
        </li>
        <li
          class="li2"
          style="margin: 6px 0px; text-align: justify; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: 'Times New Roman';"
        >
          <span style="font-family: arial, helvetica, sans-serif; font-size: 14pt;">
            Địa Chi kh&ocirc;ng n&ecirc;n h&igrave;nh th&agrave;nh Hỏa Cục trong
            l&aacute; số B&aacute;t Tự.
            <span class="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          class="li2"
          style="margin: 6px 0px; text-align: justify; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: 'Times New Roman';"
        >
          <span style="font-family: arial, helvetica, sans-serif; font-size: 14pt;">
            Nếu thiếu Thủy trong l&aacute; số th&igrave; ng&agrave;y sinh
            n&agrave;y sẽ gặp nguy hiểm th&aacute;ch thức khi bước v&agrave;o
            đại vận Thủy.
          </span>
        </li>
        <li
          class="li2"
          style="margin: 6px 0px; text-align: justify; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: 'Times New Roman';"
        >
          <span style="font-family: arial, helvetica, sans-serif; font-size: 14pt;">
            Nếu Địa Chi tạo th&agrave;nh Hỏa Cục h&igrave;nh th&agrave;nh Thất
            S&aacute;t k&egrave;m với sự vắng mặt của Nh&acirc;m Thủy ở
            Thi&ecirc;n Can th&igrave; ngay cả với sự hiện diện của 3 Qu&yacute;
            Thủy ở Thi&ecirc;n Can th&igrave; cũng sẽ kh&ocirc;ng đủ đem lại sự
            c&acirc;n bằng cho ng&agrave;y sinh n&agrave;y.
            <span class="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          class="li2"
          style="margin: 6px 0px; text-align: justify; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: 'Times New Roman';"
        >
          <span style="font-family: arial, helvetica, sans-serif; font-size: 14pt;">
            Khi Nh&acirc;m Thủy lộ ở Thi&ecirc;n Can nhưng kh&ocirc;ng c&oacute;
            Kỷ Thổ Ấn Tinh th&igrave; l&aacute; số n&agrave;y chỉ c&oacute; cuộc
            đời trung b&igrave;nh.
            <span class="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
    </div>
  );
};

export const TanThangMui = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        className="p1"
        style={{
          margin: "6px 0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          <strong>Ngày sinh Tân trong tháng Mùi</strong>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Khái Quát:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì nếu có Kỷ Thổ hiện lên ở Thiên
          Can thì xem là hình thành cục Thiên Ấn.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Đinh Hỏa hiện lên ở Thiên Can thì hình thành cục Thất Sát
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Ất Mộc hiện lên ở Thiên Can thì hình thành cục Thiên Tài.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng
          có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy
          ý.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Dụng Thần Điều Hậu:
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì dụng thần điều hậu là Nhâm
          Thủy, Canh Kim và Giáp Mộc.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nhâm Thủy là dụng thần ưu tiên trong khi Canh Kim đóng vai trò dụng
          thần trung gian.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Mậu Thổ không nên xuất hiện ở Thiên Can. Để giúp đảm bảo ngày sinh này
          được cân bằng thì cần có Giáp Mộc để kiểm soát Mậu Thổ.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Luận Giải:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Hỏa và Thổ khí rất mạnh trong tháng Mùi. Ngoài ra, Địa Chi Mùi vốn là
          một trong Tứ Khố đóng vai trò trữ Mộc Khí.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Thổ sẽ khô. Do đó, Thủy là dụng thần đầu tiên cần để làm ướt Thổ, với
          Kim đóng vai trò sinh ra Thủy. Chỉ khi đó, thì với ngày sinh Tân Kim
          sẽ có thể thịnh vượng.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Mộc Tinh chỉ nên dùng ít khi Hỏa mạnh. Trừ khi đó là Mộc ướt. Điều này
          đơn giản bởi vì khi có nhiều Mộc Khí thì chỉ dẫn đến Hỏa Khí bùng lên.
          Từ đó thì Hỏa khí quá mạnh và khó lòng kiểm soát ngày sinh Tân Kim.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Lưu Ý:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Cần chú ý nhiều đến Nhâm Thủy như là dụng thần với Canh Kim đóng vai
            trò là dụng thần trung gian
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu được thì Đinh Hỏa và Kỷ Thổ không nên xuất hiện ở Thiên Can.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi cả Nhâm Thủy và Canh Kim xuất hiện ở Bát Tự thì ngày sinh Tân
            Kim sẽ hưởng nhiều thành công.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Kỷ Thổ vượng trong tháng Mùi. Từ đó thì Kim sẽ bị bẩn hoặc bị chôn
            vùi dưới nhiều Thổ và dĩ nhiên là không có cơ hội tỏa sáng.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu Đinh Hỏa và Kỷ Thổ xuất hiện ở Thiên Can thì ngày sinh Tân Kim
            sẽ thiếu mục đích sống.
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Cách Cục:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Thương Quan và Kiếp Tài:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Miễn là Mậu Thổ và Kỷ Thổ không xuất hiện ở Thiên Can thì ngày sinh
          này sẽ hưởng cuộc sống thành công về sự nghiệp.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Mậu Thổ xuất hiện ở Thiên Can thì cũng nên xuất hiện Giáp Mộc để
          kiểm soát Mậu Thổ.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu như Kỷ Thổ xuất hiện ở Thiên Can, Ất Mộc ở Thiên Can và
          đứng bên cạnh Canh Kim.
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>
                Nếu lá số có Chính Tài:
                <span className="Apple-converted-space">&nbsp;</span>
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi chỉ có Tài Cục hình thành vẫn cần Nhâm Thủy và Canh Kim như là
          dụng thần để tạo sự cân bằng cho lá số.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Canh Kim nên xuất hiện ở Thiên Can thì sẽ có lá số tốt.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Ngày sinh này sẽ gặp khó khăn nếu muốn thành công và giàu có nếu Canh
          Kim không có trong lá số Bát Tự.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Tóm Tắt:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nhâm Thủy và Canh Kim là dụng thần ưu tiên.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu như Nhâm Thủy không xuất hiện ở Thiên Can thì sẽ khó hình thành
            cục nào tốt trong lá số này.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Cần tránh Kiếp Tài xuất hiện ở Thiên Can nếu như hình thành Tài Cục.
          </span>
        </li>
      </ul>
      <div className="ddict_btn" style={{ top: "8px", left: "506.367px" }}>
        <img src="chrome-extension://bpggmmljdiliancllaapiggllnkbjocb/logo/48.png" />
      </div>
    </div>
  );
};

export const TanThangThan = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        className="p1"
        style={{
          margin: "6px 0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          <strong>Ngày sinh Tân trong tháng Thân</strong>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Khái Quát:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì nếu có Canh Kim hiện lên ở
          Thiên Can thì xem là hình thành cục Dương Nhẫn.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Nhâm Thủy hiện lên ở Thiên Can thì hình thành cục Thương Quan.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Mậu Thổ hiện lên ở Thiên Can thì hình thành cục Chính Ấn.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng
          có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy
          ý.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Dụng Thần Điều Hậu:
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì dụng thần điều hậu là Nhâm
          Thủy, Mậu Thổ và Giáp Mộc.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nhâm Thủy là ưu tiên trong khi Giáp Mộc và Mậu Thổ là dụng thần hỗ
          trợ. Quý Thủy khó lòng đóng vai trò dụng thần.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Luận Giải:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Kim và Thủy Khí vượng trong tháng Thân này. Điều này bởi vì Kim khí ở
          tháng vượng nhất là mùa thu.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Mộc đóng vai trò dụng thần chính cùng với Thủy sinh ra và hỗ trợ
          Mộc thì ngày sinh Tân Kim sẽ sở hữu sự thông minh, tài lộc thịnh vượng
          tương ứng với sự thông minh đó.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Mộc, Thủy và Hỏa nên được chọn làm dụng thần của ngày sinh này. Khi cả
          ba cùng hiện diện trong lá số Bát Tự thì ngày sinh Tân Kim sẽ hưởng
          danh tiếng, địa vị và may mắn.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Lưu Ý:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Canh Kim nếu xuất hiện ở Thiên Can thì tạo thành cục Dương Nhẫn.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu được thì cần tránh Mậu Thổ ở Thiên Can.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Mậu Thổ xuất hiện ở Thiên Can hay thông căn ở Thiên Can thì ngày
            sinh Tân Kim chỉ sống cuộc đời trung bình. Bởi vì Thổ chôn vùi Kim.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Canh Kim xuất hiện ở Thiên Can thì cần có Nhâm Thủy để làm suy
            yếu và bảo vệ Canh Kim không trở nên quá mạnh.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Đinh Hỏa có thể được lựa chọn, nếu thiếu Nhâm Thủy, vì đây là dụng
            thần để rèn Canh Kim. Đây sẽ là cách cục quý.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Có thể chấp nhận Mậu Thổ ở Thiên Can nếu có Thủy Cục cũng xuất hiện
            tại Thiên Can.
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Cách Cục:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Chính Ấn:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu Chính Ấn tạo thành cục với ngày sinh Tân Kim này, cũng như có thể
          kiểm soát Nhâm Thủy thì Giáp Mộc cần xuất hiện ở Thiên Can nếu muốn
          ngay sinh Tân Kim này cân bằng.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu Giáp Mộc xuất hiện ở Thiên Can.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Sẽ xấu nếu không gặp Giáp Mộc ở Thiên Can.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>
                Nếu lá số có Kiếp Tài:
                <span className="Apple-converted-space">&nbsp;</span>
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu Canh Kim xuất hiện ở Thiên Can thì bất lợi cho ngày sinh Tân Kim
          nếu không có Nhâm Thủy ở Thiên Can chờ sẵn.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu có Nhâm Thủy ở Thiên Can.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Xấu nếu thiếu Nhâm Thủy và Đinh Hỏa.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Thương Quan và Thực Thần:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu Địa Chi hình thành Thủy Cục, sự vắng mặt của Mậu Thổ như là Ấn
          Tinh xuất hiện ở Thiên Can sẽ chỉ khiến cho ngày sinh Tân Kim này
          trung bình.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu Mậu Thổ xuất hiện ở Thiên Can.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu có nhiều Thủy ở Thiên Can thì sẽ khó cho Mậu Thổ (nếu
          có) đóng vai trò Ấn Tinh.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu Kỷ Thổ xuất hiện ở Thiên Can và có nhiều Thủy Khí ở Thiên Can thì
          sẽ khó cho Kỷ Thổ đóng vai trò Ấn Tinh.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Tóm Tắt:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nhâm Thủy là dụng thần ưu tiên nhất.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Tuy nhiên Nhâm Thủy chỉ nên sử dụng vừa phải. Nếu có nhiều Nhâm Thủy
            thì cần dùng Mậu Thổ để kiểm soát Nhâm Thủy. Tương tự như khi có
            nhiều Mậu Thổ thì cần Giáp Mộc để kiểm soát Mậu Thổ.{" "}
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu Kỷ Thổ xuất hiện ở Thiên Can và có nhiều Thủy Khí ở Thiên Can
            thì sẽ khó cho Kỷ Thổ đóng vai trò Ấn Tinh.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
    </div>
  );
};

export const TanThangDau = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        className="p1"
        style={{
          margin: "6px 0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          <strong>Ngày sinh Tân trong tháng Dậu</strong>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Khái Quát:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Địa chi Dậu biểu thị vị trí Đế Vượng của Tân Kim dễ hình thành Vượng
          Cục.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Dụng Thần Điều Hậu:
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Dụng Thần Điều Hậu là Nhâm Thủy và Giáp Mộc.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nhâm Thủy được ưu tiên vì tác dụng làm sạch.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Mậu Thổ và Kỷ Thổ xuất hiện trong lá số Bát Tự thì cần có Giáp Mộc
          để kiểm soát Thổ.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Địa Chi hình thành Kim Cục thì Đinh Hỏa có thể được dùng thay cho
          Nhâm Thủy nếu Nhâm Thủy không xuất hiện.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Luận Giải:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Bởi vì Kim vượng trong mùa thu nên ngay cả Mộc vượng cũng sẽ bị suy
          yếu. Do đó để có thể tạo ra và tăng cường Mộc vốn là Tài Tinh của ngày
          sinh này thì Thủy nên được chọn là Dụng Thần. Nếu cả Thủy và Mộc có
          thể dùng để sinh ra và củng cố thì ngày sinh Tân Kim sẽ trở nên giàu
          có.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi gặp Hỏa Khí thì sẽ khắc chế nhiều Kim Khí. Khi đó thì ngày sinh
          Tân Kim này sẽ hưởng danh tiếng và địa vị cao.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Ngày sinh này không nên gặp thêm Kim vì sẽ gặp nhiều sự cạnh tranh để
          thắng Tài Tinh Mộc trong lá số Bát Tự. Khi nhiều Kim khí xuất hiện thì
          người này sẽ không thỏa mãn trong đời.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Thổ xuất hiện nhưng thiếu Mộc, Thổ sẽ dày và do đó sẽ chôn vùi
          Kim.
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Lưu Ý:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nên chú trọng đến Nhâm Thủy là dụng thần.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Mậu Thổ và Kỷ Thổ nên tránh không xuất hiện ở Thiên Can.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Thổ nên được dùng khi Thủy vượng.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Tốt nhất nên tránh Đinh Hỏa ở Thiên Can. Nếu không thì ngày sinh này
            sẽ vất vả để thành công trong đời.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Đinh và Nhâm hợp thành Mộc hay Bính Hỏa và Tân Kim hợp thành
            thủy thì ngày sinh này sẽ có xu hướng kiếm lợi nhuận nhanh chóng mà
            không quan tâm đến hệ quả lâu dài cho hành động của mình. Thiếu tham
            vọng và động lực để tạo ra kết quả bền vững lâu dài trong đời.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Tân Kim xuất hiện nhiều nhưng thiếu Nhâm Thủy thì ngày sinh Tân
            Kim sẽ có tính cách chân thật, đạo đức.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi chỉ có Ất Mộc Thiên Tài hiện diện thì người này có cuộc sống
            trung bình.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Ngày sinh Tân Kim có thể đổ lỗi cho người khác vì thất bại hay sở
            đoản của mình mà không nhìn sâu vào bên trong. Điều này bởi vì Ất
            Mộc thiếu sức mạnh để làm kiểm soát Thổ. Tương tự, sự hiện diện này
            có thể chỉ hời hợt bởi vì Ất Mộc không thể đóng vai trò thật sự.
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Cách Cục:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Thương Quan Thiên Tài:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Nhâm Thủy sinh cho Tài Tinh thì ngày sinh này có sự nghiệp thành
          công.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Mậu Thổ xuất hiện ở Thiên Can thì ngày sinh Tân Kim sẽ có thể sẽ
          gặp thất bại nhiều hơn.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu như: thiếu Kỷ Thổ ở Thiên Can, có Bính Hỏa trong lá số.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu như Mậu Thổ Ấn Tinh xuất hiện ở Thiên Can.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>
                Nếu lá số có Thương Quan và Tỷ Kiên:
                <span className="Apple-converted-space">&nbsp;</span>
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Kim và Thủy cùng xuất hiện thì ngày sinh này sẽ thịnh vượng.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Điều này còn tùy vào việc Canh Kim có xuất hiện hay không và có xâm
          nhập vào Thiên Can hay không.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu có ngày sinh Tân Kim mạnh, khi thiếu Nhâm Thủy, miễn là
          Đinh Hỏa được xem như là dụng thần.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu như Canh Kim xuất hiện ở Thiên Can. Nhiều hành Thổ xuất
          hiện ở Thiên Can và Địa Chi cũng gây hại cho lá số.
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Chính Ấn Thiến Ấn:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nêu có nhiều Ấn Tinh trong lá số thì ngày sinh này sẽ tương tự như
          người không thể tự vệ vì không có vũ khí.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu có Giáp Mộc hiện diện và thông căn để kiểm soát Thổ.
          Giáp Mộc cũng sẽ giúp hợp Kỷ Thổ.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu có quá nhiều Mậu Thổ Kỷ Thổ ở Thiên Can.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Tóm Tắt:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nhâm Thủy là dụng thần lý tưởng nhất.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Ngày sinh Tân Kim nên tránh gặp Kỷ Thổ nếu được.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Sẽ có lợi nếu Tân Kim gặp Chính Tài Giáp Mộc thay vì Thiên Tài Ất
            Mộc.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Chính Quan và Thất Sát là lựa chọn thứ hai khi xét về dụng thần. Tuy
            nhiên, nếu thiếu Thủy tốt thì dùng Hỏa cũng được.
          </span>
        </li>
      </ul>
    </div>
  );
};

export const TanThangTuat = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        className="p1"
        style={{
          margin: "6px 0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          <strong>Ngày sinh Tân Kim trong tháng Tuất</strong>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Khái Quát:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì nếu có Mậu Thổ hiện lên ở Thiên
          Can thì xem là hình thành cục Chính Ấn.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Đinh Hỏa hiện lên ở Thiên Can thì hình thành cục Thất Sát.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Tân Kim hiện lên ở Thiên Can thì hình thành cục Tỷ Kiên.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng
          có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy
          ý.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Dụng Thần Điều Hậu:
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì dụng thần điều hậu là Nhâm Thủy
          và Giáp Mộc.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Hỏa và Thổ Khí được xem là bệnh tật hay ngũ hành bất lợi trong tháng
          này. Do đó, Thủy và Mộc được xem là dụng thần bổ cứu để giảm thiểu
          những rắc rối do kỵ thần Hỏa và Thổ.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nhâm Thủy giúp rửa sạch Tân Kim.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Luận Giải:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Trong tháng này thì Hỏa và Thổ xuất hiện rất nhiều.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Vì Thổ dày nặng trong tháng này do đó cần phải tránh gặp thêm ngũ hành
          Thổ và Kim vì không giúp ích gì. Thổ nặng chỉ chôn vùi Kim.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Thủy có thể sử dụng như là dụng thần chính, kèm với Mộc là dụng thần
          phụ để làm suy yếu Tân Kim đến một mức độ.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Mộc thông căn ở Ấn Thủy sẽ là lý tưởng. Điều này sẽ giúp cho Tân Kim
          vượng và tránh giòn. Người Tân Kim sẽ giàu có.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Không nên gặp nhiều Hỏa Khí vì Hỏa sẽ sinh ra Thổ khiến cho trở nên
          khô cứng.
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Lưu Ý:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Giáp Mộc là dụng thần chính với Nhâm Thủy là dụng thần phụ.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi được thì Kỷ Thổ không nên xuất phát ở Thiên Can.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi chỉ có Mậu Thổ xuất hiện ở Thiên Can thì ngày sinh này chỉ trung
            bình. Khi cả Mậu Thổ và Kỷ Thổ cùng xuất hiện thì ngày sinh này
            thiếu định hướng trong cuộc sống.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Địa Chi tạo thành Thủy Cục và cả Nhâm Thủy và Giáp Mộc xuất hiện
            ở Thiên Can thì ngày sinh này sẽ hưởng thịnh vượng.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Giáp Mộc thiếu vắng trong tháng này thì người này có thể vất vả
            mới được thành công hoặc công nhận.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Cách Cục:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Thương Quan và Chính Tài:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Nhâm Thủy lộ Thiên Can trong khi Địa Chi tạo thành Thủy Cục thì
          ngày sinh này sẽ thành công trong đời.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu thiếu Kỷ Thổ vì sẽ không gây bẩn Thủy. Kỷ Thổ chỉ nên
          xuất hiện ở Địa Chi.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu Kỷ Thổ xuất hiện ở Thiên Can hoặc đứng bên cạnh Giáp Mộc
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>
                Nếu lá số có Chính Quan:
                <span className="Apple-converted-space">&nbsp;</span>
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi có Thìn ở trụ giờ thì ngày sinh này sẽ thịnh vượng.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Sẽ lý tưởng nếu ngày sinh này trong tháng Nhâm Thìn.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu như Kỷ Thổ xuất hiện ở Thiên Can chôn vùi Kim.
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Thực Thần:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Quý Thủy Thực Thần tạo thành cách cục với ngày sinh Tân Kim. Mặc dù
          Quý Thủy có thể thay thế Nhâm Thủy Thương Quan nhưng người này vẫn khó
          thành công trong đời.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu thiếu Kỷ Thổ và Bính Hỏa.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu có Mậu Thổ Ấn Tinh ở Thiên Can.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Tóm Tắt:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nhâm Thủy và Giáp Mộc là dụng thần ưu tiên.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Hỏa và Thổ được xem là nhiễm bệnh hay bất lợi cho ngày sinh này
            trong tháng Tuất, trong khi Thủy và Mộc là dụng thần chữa bệnh để
            khắc chế các tác động bất lợi của Hỏa và Thổ.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
      <div
        className="ddict_div"
        style={{ top: "34px", maxWidth: "150px", left: "309.844px" }}
      >
        <img
          className="ddict_audio"
          src="chrome-extension://bpggmmljdiliancllaapiggllnkbjocb/img/audio.png"
        />
        <p className="ddict_sentence">Kim</p>
      </div>
    </div>
  );
};

export const TanThangHoi = () => {
    return (
        <div 
        style={{
            textAlign: "left",
            position: "relative",
            zIndex: "999",
            marginLeft: "1rem",
          }}
        >
        <p className="p1" style={{margin: '6px 0px', textAlign: 'center', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '18px', lineHeight: 'normal', fontFamily: '"Times New Roman"', color: '#00000a'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '18pt'}}><strong>Ngày sinh Tân trong tháng Hợi</strong></span></p>
        <ul className="ul1" style={{fontSize: 'medium'}}>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}><strong>Khái Quát:</strong></span></li>
        </ul>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Đối với người sinh trong tháng này thì nếu có Nhâm Thủy hiện lên ở Thiên Can thì xem là hình thành cục Thương Quan.</span></p>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Nếu có Giáp Mộc hiện lên ở Thiên Can thì hình thành cục Chính Tài.</span></p>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy ý.</span></p>
        <p className="p3" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"', minHeight: '15px'}}>&nbsp;</p>
        <ul className="ul1" style={{fontSize: 'medium'}}>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}><strong>Dụng Thần Điều Hậu:<span className="Apple-converted-space">&nbsp;</span></strong></span></li>
        </ul>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Đối với người sinh trong tháng này thì dụng thần điều hậu là Nhâm Thủy và Bính Hỏa.<span className="Apple-converted-space">&nbsp;</span></span></p>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Nhâm Thủy là dụng thần số 1 còn Bính Hỏa là dụng thần thứ hai.</span></p>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Thủy sạch sẽ là dụng thần giúp ngày sinh này đạt danh tiếng. Bính Hỏa và Nhâm Thủy cũng sẽ giúp vai trò tương ứng với Bính Hỏa giúp Tân Kim được tỏa sáng.<span className="Apple-converted-space">&nbsp;</span></span></p>
        <p className="p3" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"', minHeight: '15px'}}>&nbsp;</p>
        <ul className="ul1" style={{fontSize: 'medium'}}>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}><strong>Luận Giải:<span className="Apple-converted-space">&nbsp;</span></strong></span></li>
        </ul>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Khí Tân Kim khá lạnh lẽo cùng với Thổ Khí yếu trong tháng này. Cần hơi ấm để có thể tồn tại.</span></p>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Sự xuất hiện của Hỏa giúp sưởi ấm Thủy vốn dĩ lạnh lẽo trong mùa đông trong khi Thổ ấm có thể giúp sinh ra Kim.</span></p>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Cần lưu ý rằng khi không có Hỏa, Thổ không thể được sưởi ấm để làm dụng thần. Hỏa là dụng thần không thể thiếu đối với ngày sinh Tân Kim vì Kim, Thổ và Mộc cần được sưởi ấm và trở nên hữu dụng để làm dụng thần.<span className="Apple-converted-space">&nbsp;</span></span></p>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Rõ ràng Thổ ấm nóng là lựa chọn dụng thần lý tưởng cho ngày Tân Kim sinh trong tháng Hợi. Điều này bởi vì Thổ có năng lực sinh ra Kim và giữ Thủy trong tầm kiểm soát.<span className="Apple-converted-space">&nbsp;</span></span></p>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Ngoài ra, Kim cũng có thể giúp cho người sinh ngày Tân Kim nếu sinh vào giờ có Hỏa và Thổ mạnh vì sẽ giúp củng cố tài vận của người này.<span className="Apple-converted-space">&nbsp;</span></span></p>
        <ul className="ul1" style={{fontSize: 'medium'}}>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}><strong>Lưu Ý:</strong></span></li>
        </ul>
        <ul className="ul1" style={{fontSize: 'medium'}}>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}>Nhâm Thủy là dụng thần chính trong khi Bính Hỏa là dung thần thứ hai. Nhâm giúp Tân tỏa sáng trong khi Bính Hỏa giúp nó lấp lánh.</span></li>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}>Sẽ tốt hơn là tránh Mậu Thổ trong lá số Bát tự vì Mậu Thổ chôn vùi Kim.</span></li>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}>Khi có cả Nhâm Thủy và Bính Hỏa cùng xuất hiện như là dụng thần thì không nên ở bên cạnh nhau trong lá số Bát Tự.<span className="Apple-converted-space">&nbsp;</span></span></li>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}>Môi trường trong lá số này khá lạnh vì là mùa đông. Vì Tân Kim có xu hướng mềm, do đó nên được bảo vệ chống lại thời tiết mùa đông rét buốc.</span></li>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}>Khi Bính Hỏa không lộ ở Thiên Can nhưng xuất hiện Nhâm Thủy thì ngày sinh Tân Kim này sẽ giàu có đặc biệt nếu họ có tài trong kinh doanh.</span></li>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}>Nếu Giáp Mộc xuất hiện nhiều nhưng thiếu Mậu Thổ thì ngày sinh này sẽ sở hữu bản tính chân thật bất kể người này giàu có bao nhiêu.</span></li>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}>Khi Nhâm Thủy và Quý Thủy tạo thành cục tương ứng và cũng lộ ở Thiên Can thì ngày sinh này sẽ có xu hướng lang thang vô định mà không có định hướng cuộc đời.</span></li>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}>Khi thiếu Bính Hỏa trong lá số thì ngày sinh Tân Kim sẽ có cuộc đời trung bình. Nếu không có Bính Hỏa thì không có hạnh phúc.<span className="Apple-converted-space">&nbsp;</span></span></li>
        </ul>
        <p className="p3" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"', minHeight: '15px'}}>&nbsp;</p>
        <ul className="ul1" style={{fontSize: 'medium'}}>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}><strong>Cách Cục:</strong></span></li>
        </ul>
        <ul className="ul1" style={{fontSize: 'medium'}}>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}><span className="s1" style={{textDecorationLine: 'underline'}}><strong>Nếu lá số có Thương Quan và Chính Quan:</strong></span></span></li>
        </ul>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Khi Nhâm Thủy gặp một sao Chính Quan và cùng lộ trên Thiên Can thì ngày sinh Tân Kim sẽ hưởng thành công lớn trong sự nghiệp.<span className="Apple-converted-space">&nbsp;</span></span></p>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Đây là tình huống đặc biệt mà khi cả hai sao đối nghịch lại gặp nhau vui vẻ.</span></p>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Điều cần thiết là Địa Chi có Bính Hỏa và Nhâm ở Thiên Can vì sẽ không khắc và không hợp lại với nhau.</span></p>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Lá số xấu nếu có Đinh Hỏa bên cạnh Nhâm Thủy. Xấu nếu có Tân Kim bên cạnh Bính Hỏa.<span className="Apple-converted-space">&nbsp;</span></span></p>
        <ul className="ul1" style={{fontSize: 'medium'}}>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}><span className="s1" style={{textDecorationLine: 'underline'}}><strong>Nếu lá số có Chính Ấn:<span className="Apple-converted-space">&nbsp;</span></strong></span></span></li>
        </ul>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Khi không có Bính Hỏa trong lá số thì ngày sinh Tân Kim sẽ sở hữu tính nghệ sĩ nhưng có thể bị cảm xúc bất ổn.<span className="Apple-converted-space">&nbsp;</span></span></p>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Nếu có Đinh để sinh ra Thổ thì lá số tốt. Lá số xấu nếu có Quý bên cạnh Mậu Thổ.<span className="Apple-converted-space">&nbsp;</span></span></p>
        <ul className="ul1" style={{fontSize: 'medium'}}>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}><span className="s1" style={{textDecorationLine: 'underline'}}><strong>Nếu lá số có Thương Quan:</strong></span></span></li>
        </ul>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Khi chỉ có Nhâm Thủy xuất hiện và không có Bính Hỏa thì tốt hơn là không nên có Mậu Thổ ở Thiên Can.</span></p>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Lá số tốt nếu thiếu Mậu Thổ ở Thiên Can. Lá số xấu nếu như Mậu Thổ Chính Ấn xuất hiện ở Thiên Can.<span className="Apple-converted-space">&nbsp;</span></span></p>
        <p className="p3" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"', minHeight: '15px'}}>&nbsp;</p>
        <ul className="ul1" style={{fontSize: 'medium'}}>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}><strong>Tóm Tắt:<span className="Apple-converted-space">&nbsp;</span></strong></span></li>
        </ul>
        <ul className="ul1" style={{fontSize: 'medium'}}>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}>Nhâm Thủy và Bính Hỏa là dụng thần cân thiết.<span className="Apple-converted-space">&nbsp;</span></span></li>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}>Nhâm Thủy tạo thành Thương Quan rất mạnh cùng với Tân Kim sinh trong tháng Hợi.</span></li>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}>Miễn là ngày sinh này có Nhâm Thủy sạch thì được xem là có dụng thần. Ngoài ra, Mậu và Kỷ Thổ không nên xuất hiện để làm bẩn Thủy.<span className="Apple-converted-space">&nbsp;</span></span></li>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}>Khi thiếu Bính Hỏa trong lá số thì ngày sinh này sẽ vất vả mới đạt thành công và hạnh phúc.<span className="Apple-converted-space">&nbsp;</span></span></li>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}>Lá số tốt nếu thiếu Mậu Thổ ở Thiên Can. Lá số xấu nếu như Mậu Thổ Chính Ấn xuất hiện ở Thiên Can.<span className="Apple-converted-space">&nbsp;</span></span></li>
        </ul>
      </div>
    )
}