export const MauThin = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Mậu Thìn là người tính cách độc lập cao, đơn độc, không
        quan tâm xem xét quan điểm người khác, không chấp nhận ý kiến trái
        chiều, không thích bị ràng buộc bởi luật lệ, quy tắc hay lề thói thông
        thường, có động lực cao và thường chủ động, tự tin cao, có sức quyến rũ,
        giỏi giao tiếp, thích làm từ thiện. Dễ bị tiểu nhân phá vì quá thành
        công. Nhìn chung, đời sống giàu có và sang quý do công đức nhiều.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Thìn có vẻ ngoài bình an và trầm mặc như ngọn núi xanh tươi.
        Mậu Thìn là những con người dễ thương nhất và bạn muốn có họ trong số
        bạn bè của mình. Họ thân thiện, hiền lành, thông minh và thích học
        thuật. Họ thích triết lý và phù hợp với việc học hành cao và sống một
        đời sống năng động và cân bằng. Người Mậu Thìn là người được yêu mến
        nhất.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Vì họ có nhiều tài năng, những người Mậu Thìn có thể nắm được vị trí
        lãnh đạo cực tốt với kỹ năng quản trị và sự duyên dáng của họ. Họ rất
        thích hợp trở thành doanh nhân, tiếp thị, hay quản lý hành chính vì họ
        giỏi trong việc lên kế hoạch và thực thi chiến lược. Họ cũng duyên dáng,
        giao thiệp giỏi và thích gần gũi với những người giống họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Thìn quyết tâm, siêng năng, kiên trì và bền bỉ nên họ dễ đạt
        được bất kỳ mục tiêu nào trong đầu. Họ có thể vừa cứng đầu vừa có trái
        tim mềm yếu khi họ ở bên cạnh người mình yêu thương. Người Mậu Thìn rất
        độc lập và họ không bị ảnh hưởng bởi gia đình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Thìn rất chú trọng đến mục tiêu và có định hướng rõ ràng tuy
        nhiên thỉnh thoảng có thể cũng bị lạc hướng. Tuy nhiên, họ luôn có động
        lực để theo đuổi mọi thứ. Họ có xu hướng thiên về thành công vật chất
        cũng như tài lộc 42 và danh tiếng. Bởi vì điều này mà họ thường làm việc
        vất vả để đạt được vị trí mà họ ao ước như là có nhiều quyền lực, sức
        ảnh hưởng, tiền bạc và danh tiếng. Họ cũng làm cho người khác cảm thấy
        thoải mái dễ chịu với khiếu hài hước và kỹ năng tâm lý. Sự nhạy cảm của
        chọ cũng khiến cho người khác cảm thấy ấm áp và thấu hiểu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Thìn luôn nhận thức rõ mọi việc và phản ứng nhanh sau khi phân
        tích cẩn thận. Đây là tính cách ít thấy ở một người giúp cho họ luôn
        đáng ngưỡng mộ, tôn trọng, mạnh mẽ, rộng lượng và tử tế. Họ là những nhà
        lãnh đạo bẩm sinh với trực giác giúp cho họ kiểm soát môi trường xung
        quanh. Họ cũng có thể sử dụng năng lực này để đạt thành công thay vì tỏ
        ra thao túng, bất chấp thủ đoạn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có khả năng chịu đựng và xoa dịu mọi nỗ đau trên đường để đạt được
        chiến thắng mà họ muốn. Họ thực dụng và có kỹ năng phân tích tốt. Họ cần
        có sự an toàn về vật chất trước khi ra quyết định quan trọng. Do đó,
        người Mậu Thìn không hẳn luôn hài lòng với chính họ và thích theo đuổi
        sự tự phát triển và khám phá bản thân. Sự hài hước của họ, giúp cho họ
        khác quan cũng như có góc nhìn cân bằng. Người Mậu Thìn có năng lực để
        vượt qua mọi trở ngại bởi vì họ có khả năng vạch chiến lược hiệu quả,
        lên kế hoạch và thực thi với quyết tâm lớn để đạt thành công.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Thìn rất nhạy cảm, trực giác, sáng tạo và tưởng tượng tốt. Họ
        có thể sống trong thế giới mộng tưởng và thường có tầm nhìn tốt. Họ cũng
        khá trực giác và triết lý. Họ có nhân duyên với tâm linh và các môn tâm
        linh. Điều này giúp cho họ hiểu con người sâu sắc và có khả năng cải
        thiện kỹ năng giao tiếp. Triết học và tâm linh cũng giúp họ có định
        hướng rõ ràng để nâng cấp bản thân cũng như gợi cảm hứng cho người khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        An toàn về mặt tài chính rất quan trọng đối với Mậu Thìn và họ dành phần
        lớn thời gian để cố gắng xây dựng các mối quan hệ cũng như có cách tiếp
        cận tích cực đối với vật chất để có thể đạt được thành công vật chất và
        ổn định tài chính. Mặc dù họ chú trọng đến tài chính, người Mậu Thìn
        thích gắn bó với con người và chú trọng sâu sắc đến quan hệ tình cảm. Họ
        thường bình tĩnh và tập trung giữ hoà hợp tình cảm trong mối quan hệ
        cũng như tránh tranh cãi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người Mậu Thìn thích khơi gợi và gây cảm hứng cho người khác. Họ
        cảm thấy thoả mãn nhất khi được truyền dạy kiến thức và kỹ năng của họ
        cho người khác để giúp đỡ họ. Họ khá mạo hiểm và sâu sắc. Người Mậu Thìn
        có thể nhanh chóng nhìn ra cơ hội cũng như là người toàn diện nhất trong
        số những người Mậu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Những Đặc Tính Của Người Mậu:</b> thân thiện, thông minh, đa tài, cân
        bằng , kiên trì, lãnh đạo, hệ thống, bất an, cứng đầu, nói thẳng.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Ngiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Quyết tâm và siêng năng, người Mậu Thìn sẽ thành công trong mọi sự
        nghiệp mà họ chọn theo đuổi. Họ có khả năng tư duy tốt và luôn tìm cách
        khám phá những lĩnh vực để có thể mở rộng tầm hiểu biết. Người Mậu Thìn
        sống trực giác và triết lý, cũng như có xu hướng tiếp cận mọi thứ dưới
        góc độ tâm linh và đầu óc cởi mở. Họ khách quan trong cách suy nghĩ.
        Những người này khá sáng tạo cũng như biết cách làm vui lòng những người
        xung quanh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ho từ bi và suy nghĩ thấu đáo, những tính chất sẽ giúp cho họ có nhiều
        người ngưỡng mộ. Họ thích gây cảm hứng và truyền động lực cho người khác
        để có một cuộc sống tốt hơn. Họ thích trò chuyện về nhiều chủ đề và tìm
        kiếm sự kích thích về mặt tư duy. Họ khéo léo, độc lập và kiểm soát. Đây
        là những tính cách giúp họ trở thành lãnh đạo giỏi vì họ không lạm dụng
        quyền lực ép người khác. Họ là những người có bản tính thích nhân đạo.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có kỹ năng giao tiếp tốt và được hỗ trợ bởi học vấn cao khiến cho
        người Mậu Thìn khá thông minh, trực giác và có óc tổ chức. Họ thích quan
        sát, có khiếu ăn nói và dám nói. Họ có kỹ năng phân tích tốt và là người
        nhân đạo. Họ làm việc vất vả cộng với cả khả năng thiên bẩm về kinh
        doanh giúp họ thành công. Mặc dù họ là những người độc lập, họ vẫn đánh
        gía cao tầm quan trọng của làm việc nhóm và hợp tác để đạt được mục tiêu
        chung. Người Mậu Thìn có óc tổ chức và làm việc có phương pháp, tuy
        nhiên đôi khi họ thiếu một mục tiêu có ý nghĩa thật sự.{" "}
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Công Việc:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Thìn là những lãnh đạo bẩm sinh với khiếu kinh doanh, họ là
        những doanh nhân xuất sắc với kỹ năng quản lý. Họ thích thể hiện bản
        thân một cách kịch tính nên có thể có sự nghiệp tốt trong ngành giải
        trí. Họ có kỹ năng giao tiếp xuất sắc, học thuật tốt do đó dễ thành công
        trong lĩnh vực giáo dục, quan hệ công chúng, truyền thông hay xuất bản.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Với kỹ năng giao tiếp và đầu óc tư duy triết lý, người Mậu Thìn có thể
        chọn con đường như giáo viên, cố vấn, bác sĩ, chính trị gia, những nhà
        cải cách xã hội hay hoạt động xã hội. Kinh doanh và chính trị là hai
        nghề nghiệp thành công nhất cho người Mậu Thìn, có thể dẫn họ đến tài
        lộc và thịnh vượng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bởi vì họ có khả năng chữa lành, họ có thể làm trong ngành y hoặc chữa
        bệnh. Người Mậu Thìn rất sáng tạo và có động lực khám phá. Những phẩm
        chất này giúp họ thành công trong sự nghiệp nghệ thuật, nhiếp ảnh và thể
        thao.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Những Đặc Tính Trong Công Việc - Sự Nghiệp:</b> khéo léo, thông minh,
        thực dụng, sáng tạo, thân thiện, trực giác, hợp tác, suy nghĩ thấu đáo,
        nhận thức cao, quá nhạy cảm, nghi ngờ, bất an, lo lắng, hấp tấp, cứng
        đầu.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Quan Hệ - Gia Đình:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Thìn thích thể hiện bản thân nhưng họ cũng có muốn có tình cảm
        trong hôn nhân hoà hợp. Họ rất áp đặt nhưng đôi khi cũng nhường nhịn
        người bạn đời. Khi tình yêu ở một mức độ trung bình, các mối quan hệ bên
        ngoài sẽ khiến cho họ rối rắm và tiến thoái lưỡng nan. Phụ nữ ngày Mậu
        Thìn cần phải chú ý trước khi phát ngôn kẻo họ dễ rơi vào rắc rối do
        hiểu lầm. Mặc dù vậy, họ là những người tử tế, ấm áp và dễ có hôn nhân
        viên mãn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người này sẽ tràn đầy tình yêu thương đến mức không nói nên lời.
        Họ bị cuốn hút vào những người có thể cho họ sự khơi gợi về tư duy. Họ
        có bản tính nhạy cảm và suy nghĩ sâu sắc giúp họ thấu hiểu cảm xúc và
        nhu cầu của bạn đời. Họ có thể thích chơi những trò chơi tâm trí để thao
        túng người yêu, tuy nhiên nếu khi họ đã xác định được người bạn đời của
        mình, họ có thể là một trong những người ấm áp, quan tâm và yêu thương
        nhất.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Thìn có suy nghĩ sâu sắc và biết rõ về tình yêu và cảm xúc. Họ
        thích ở bên cạnh những người có tư duy sâu sắc giống họ. Họ khá cứng đầu
        và 45 dễ rơi vào tranh cãi với người khác về những chuyện vặt vãnh. Tuy
        nhiên, họ cũng luôn khách quan và có góc nhìn cân bằng trong mọi tình
        huống.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Thìn thân thiện, dễ gần cũng như biết cách thu hút người khác
        thông qua sự duyên dáng của mình. Họ là những nhà tâm lý học bẩm sinh
        biết cách làm cho quan hệ tình cảm vui vẻ bằng cách đọc vị tâm lý người
        khác. Những người này thích giao tiếp với đủ mọi loại người. Họ thích
        học hỏi với nhiều người khác nhau để giúp cho họ có tư duy đa chiều.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc cho bản tính thích vận động, người Mậu Thìn nên ổn định cuộc sống
        bằng cách có một gia đình, điều này sẽ tốt hơn cho họ. Họ có thể trở nên
        mềm mỏng và ấm áp, cũng như tỏ ra chung thuỷ với người mình yêu. Họ là
        những người bạn đời tốt, giúp đỡ người kia lúc khó khăn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đàn ông Mậu Thìn sẽ có hôn nhân tốt bao gồm nhiều tài lộc, thịnh vượng
        và may mắn. Phụ nữ Mậu Thìn thì hôn nhân không tốt vì Mộ ở Quan. Nếu ngũ
        hành Mộc rơi vào Không Vong ở những đại vận trẻ tuổi, có khả năng cao
        người chồng sẽ mất sớm sau khi kết hôn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Những Đặc Tính Trong Tình Yêu - Quan Hệ:</b> thân thiện, đồng cảm,
        thể hiện, tranh cãi, áp đặt.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mậu toạ trên Tài Tinh, Quan Tinh và Tỷ Kiên Tinh trong khi lại thông
        căn. Ở Địa Chi, Tỷ Kiên và Tài Tinh sẽ biến đổi hành Hoả thành Ấn Tinh
        sau đó lại biến thành Quan Tinh. Ấn Tinh và Quan Tinh tương sinh cho
        nhau là dấu hiệu những người này sẽ có Quý Nhân đến giúp. Mậu và Quý có
        thể hợp và hoá thành Hoả sau đó sinh cho Thìn và Thổ trong lá số. Hành
        Hoả cho phép hình thành khí tốt cho người này.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Thìn rất đa tài và thông minh cao. Họ sở hữu nhiều phẩm chất
        tốt. Họ năng động và mạo hiểm, thích những thử thách mới cũng như có khả
        năng phân tích, tổ chức, học tập và thích nghi vào mọi tình huống.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Mậu Thìn có năng lực rất lớn nhưng không phải lúc nào họ
        cũng có thể thấy được. Họ cảm thấy bất an bên trong và họ không có khả
        năng 46 thoả mãn với thành tích của mình, những điều này buộc họ luôn
        học tập, cải thiện bản thân. Chính sự bất ổn này có thể kéo lùi họ lại.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù họ có thể chọn làm việc một mình, người Mậu Thìn nhận thức rất rõ
        tầm quan trọng của việc hợp tác. Họ đánh giá cao cơ hội để mở rộng nhận
        thức thông qua tương tác với nhiều người có xuất phát điểm và quan điểm
        khác nhau. Họ nhạy cảm và dễ bị tổn thương bởi sự chỉ trích. Họ có thể
        bị mắc kẹt giữa tham vọng muốn dẫn đầu và ước muốn là một phần của tập
        thể.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Xét về quan hệ xã giao thì người Mậu Thìn duyên dáng và dễ thương. Họ có
        trực giác và nhận thức cao, họ cũng khéo trong ăn nói. Họ là người triết
        lý và ăn nói khéo léo và dễ tạo sự thân thiện với những người khác nhau.
        Người Mậu Thìn có thể tỏ ra cứng đầu nhưng thường thì họ suy nghĩ sâu
        sắc và thực tế cũng như có cái nhìn cân bằng và khả năng tạo sự hoà hợp
        trong mọi tập thể mà họ ở trong.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trong Bát Tự, Mậu Thìn đại diện cho ngọn núi mùa xâun có dòng suối chảy
        bên cạnh. Về mặt hình ảnh thì dòng nước có thể tỏ ra ít nhưng sẽ kéo dài
        vì là dòng chảy ổn định. Do đó, nước được chứa trong Tài Khố đối với
        người Mậu Thìn và có khả năng người Mậu Thìn giàu có.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi Tuất xung với Thìn trong lá số thì đây là dấu hiệu trụ ngày này sẽ
        mất đi Khí. Hiệu ứng của việc này biểu thị người Mậu Thìn có thể bị chấn
        thương, tai nạn hoặc kiện tụng trong đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thìn là một phần của Tốn Quái, là sao Văn Khúc biểu thị bản chất triết
        lý của Mậu Thìn. Người này có thể tỏ ra thông minh và có xu hướng theo
        đuổi học thuật. Họ cũng tỏ ra giàu có, hầu như chắc chắ chắn hơn người
        Mậu Tuất bởi vì Thìn là Tài Khố.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tính cách của cung Phu Thê trong lá số Bát Tự biểu thị người này sẽ có
        thể có hôn nhân tốt và người vợ hoặc chồng rất ủng hộ họ. Tuy nhiên, trụ
        Mậu Thìn cũng là sao Cô Thần biểu thị họ luôn có cảm giác cô đơn cả đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ất Mộc nếu có xuất hiện trong al1 số biểu thị rằng Tuất xung với Thìn.
        Điều này biểu thị sự dễ tổn thương, đặc biệt là khi Dậu hiện diện trong
        lá số. Nếu Ất ẩn trong tàng Can sẽ không bị Dậu khắc và ngược lại Dậu sẽ
        gắn với địa chi 47 Thìn, khiến cho Mộc và Địa Chi phát triển cùng nhau.
        Cục này dễ biểu thị ảnh hưởng tốt lên hôn nhân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sự hiện diện của Kim, Mộc, Thuỷ cũng như Thân, Tý trong lá số là dấu
        hiệu tích cực trong khi nhiều Thổ và Hoả cũng như Dần, Ngọ, Tuất được
        xem là ít may mắn hơn. Ngũ hành nào là phù hợp hay không còn phù thuộc
        vào độ mạnh và yếu của lá số, là các vị trí của Đế Vượng hay Suy trong
        Vòng Trường Sinh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Thìn sinh vào mùa Xuân và Hạ có khả năng đạt thành công tự
        thân mà không nhờ sự giúp đỡ từ bên ngoài, trong khi sinh trong mùa Đông
        và Thu sẽ phải làm việc vất vả để đạt mục tiêu. Người sinh ban ngày thì
        dễ gặp may mắn với tài lộc, sức khoẻ và thuận lợi. trong khi sinh ban
        đêm thì sẽ thành công nếu làm việc vất vả. Nếu sinh vào tháng Mão thì
        cuộc đời nhiều tai hoạ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu bạn cần một người bạn luôn giang tay giúp đỡ thì đó chính là người
        Mậu Thìn. Núi chồng lên trên núi, không thể có gì bền bỉ hơn thế. Kèm
        với nước suối chảy (Quý) và thảm cỏ xanh (Ất), Mậu Thìn trở thành người
        bạn hộ vệ lý tưởng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mậu toạ trên Tỷ Kiếp, điều này khiến cho họ trở thành người thích hoạt
        động xã hội cũng như quan tâm đến lợi ích cộng đồng. Đối với họ, bạn bè
        là tất cả. Cũng như một chiến binh luôn “Vì mọi người”, người Mậu Thìn
        chứng tỏ lòng chung thuỷ, sự trung thành và hỗ trợ cho cộng đồng. Bạn có
        thể dựa vào họ, họ luôn giữ bí mật của bạn, họ cũng se bảo vệ bạn hết
        mình. Thổ là ngũ hành năng lượng, cung cấp và làm cho mọi thứ xảy ra dù
        khởi đầu ít ỏi đến đâu. Họ có thể không giúp đỡ được về mặt tiền bạc
        nhưng họ sẽ dành cho bạn thời gian.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Được kẹp bởi Quý Thuỷ và Ất Mộc, người này sống trực giác và linh hoạt.
        Họ biết cách lấy lòng bạn với cử chỉ và hành vi dịu dàng và thuyết phục.
        Đối với cả nam hay nữ Mậu Thìn thì hôn nhân đều tốt vì đối với nam thì
        toạ Chính Tài trong khi nữ thì toạ Chính Quan. Có Chính Tài và Chính
        Quan hiện diện, họ thực tế và tuân thủ pháp luật. Họ có trách nhiệm phục
        vụ và luôn tỏ ra trung thành.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thìn hấp dẫn Dậu vì nằm trong Lục Hợp. Điều này thêm Tân, Thương Quan
        vào trong bức tranh tổng quan. Nói chung, họ không thích bỏ qua điều gì
        mà muốn tất cả đều phải phát triển hơn, nếu không được như ý thì họ sẽ
        lên tiếng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi cần thì họ sẽ là nhà vô địch trong đòi hỏi sự công bằng. Đối với nữ
        giới sinh ngày Mậu Thìn thì có 1 ít vấn đề bởi vì họ có thể muốn điều
        chỉnh người đàn ông của mình khi không được như ý.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thìn khắc Tuất. Điều này biểu thị sự ngược đời vì Mậu Tỷ Kiếp, Tân
        Thương Quan và Đinh Chính Ấn có liên quan. Trong khi xung thông thường
        thì họ trở nên xa lạ, im lặng và tách rời. Bạn bè rất thân trước đây
        cũng sẽ trở nên không còn quan trọng, tiếng nói không quá quan trọng và
        kiến thức cũng bị bỏ đi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mậu Thìn có thể là người bạn sẵn lòng chiến đấu kiên cường vì bạn.
      </p>
    </div>
  );
};

export const MauDan = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Mậu Dần là người xem trọng các giá trị hào nhoáng bề
        ngoài hơn là sâu sắc bên trong, thích mạo hiểm, thiếu trực giác, dễ bị
        lừa, có động lực cao làm mọi việc, tính cách rất mạnh mẽ, nam tính (ngay
        cả nếu như là nữ giới), có thể nắm giữ vị trí và chức quyền, thích quyền
        lực và không thích nhận chỉ đạo từ người khác. Nhìn chung, cuộc sống
        không thiếu vật chất, tiền bạc, đặc biệt thường có tài lộc bất ngờ, sẽ
        giàu có hơn nếu như có trụ Ất Mão trong tứ trụ. Có địa vị xã hội cao,
        nếu trong thời cổ đại, có thể là quý tộc như công chúa, thái tử..
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Rừng cây mọc trên núi cao nơi cây có thể vươn cao mà không bị cản trở.
        Đất cao như là người bảo hộ và chăm sóc cho hệ sinh thái mong manh của
        họ. Họ thường cảm thấy cô đơn và đôi khi tuyệt vọng bởi cảm nhận của
        người xung quanh nhưng họ luôn có nghĩa vụ trách nhiệm.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mậu, đại diện cho Dương Thổ cho thấy hình ảnh của ngọn núi lớn. Họ biểu
        thị tính cách của sự ổn định và đáng tin cậy. Bạn thường ít khi thấy Mậu
        di chuyển khỏi vị trí của nó. Nhiều người gọi người Mậu Thổ là cứng đầu,
        nhưng cùng với thời gian, bạn sẽ thấy họ chỉ tạm thời phản ứng chậm.
        Người Mậu Thổ rất kiên nhẫn, bạn sẽ không thấy họ vội vàng, họ luôn chờ
        cho mọi thứ vào đúng việc, đúng chỗ. Họ luôn là người giữ bí mật tốt vì
        Thổ có khả năng chứa tất cả những ngũ hành khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Dần được đại diện bởi hình ảnh núi cao xanh tươi. Người Mậu
        Dần được xem là trụ ngày đẹp nhất trong 60 Hoa Giáp. Người Mậu Dần
        thường cao, hấp dẫn và luôn toả ra một bầu không khí bí hiểm xung quanh
        người này.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi lớn lên, người Mậu Dần ít khi nhận được sự giúp đỡ và quan tâm từ
        cha mẹ, có thể dẫn đến họ sử dụng sự thông minh để tạo ra tuổi thơ ấu
        tốt hơn cho bản thân họ. Khi họ lớn tuổi hơn, những người này sẽ hạnh
        phúc và thịnh vượng. Hầu như mọi người Mậu Dần rất khoẻ mạnh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nói chung, người Mậu Dần vươn lên giành sự xuất sắc trong cuộc sống. Họ
        tỏ ra điềm tĩnh trên bề mặt nhưng những người này đều rất thông minh và
        quyền lực. Họ có tính cách cứng rắn và sống chậm rãi, điều này giúp cho
        họ được hưởng lợi vì sẽ giúp họ vượt hơn người khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngược lại với tính cách năng động và duyên dáng, người này đôi khi cảm
        thấy bất an. Họ có xu hướng rút khỏi đời sống xã hội và tự cô lập chính
        mình. Họ cần có những thời gian một mình, cũng như họ cần trải qua thời
        gian để ngẫm lại những sai lầm của mình và kết nối với bản chất bên
        trong.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Dần thường có xu hướng đi theo các hoạt động nhân đạo, tìm
        chính nghĩa để đấu tranh. Họ rất hợp tác và giúp đỡ tập thể cũng như
        luôn khuyến khích, động viên người khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Dần thường có khiếu viết lách hay ăn nói, cũng như có khả năng
        gợi cảm hứng hay giải trí cho người khác. Họ có tính cách nhạy cảm và
        tưởng tượng giúp họ có đam mê sự tự do. Họ dễ trở nên đam mê trong những
        dự án họ hứng thú, cho nên họ có thể thành công nếu họ tìm được hoạt
        động gì đó mà họ thích.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người này rất rộng lượng khi họ cảm thấy tích cực, nhưng họ cũng
        dễ trở nên giận dữ khi họ cảm thấy tiêu cực. Người Mậu Dần rất nhạy cảm
        về cảm xúc, và thường dễ lo lắng, đặc biệt là về vấn đề tiền bạc. Điều
        này cũng có thể làm cho họ lo lắng và căng thẳng về vấn đề tài chính cá
        nhân và lãng phí năng lượng quý giá. Họ cần phải cân bằng giữa nhu cầu
        cảm xúc và tài chính. Người Mậu Dần thường có khẩu vị sang trọng và họ
        thích sống trong xa hoa. Do đó, đi nước ngoài sẽ là một cơ hội tốt cho
        họ, vì có nhiều cơ hội để phát triển tài lộc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi những người Mậu Dần lên kế hoạch hành động, họ ngay lập tức sẽ trở
        nên kiên trì, sống có mục đích. Họ có đủ quyết âm để vượt qua mọi thử
        thách trong cuộc sống. Họ có trí tưởng tượng và ý tưởng thông minh hoà
        hợp với nhau. Tất cả người Mậu Dần hầu như đều giỏi giao tiếp và có
        khiếu khôi hài. Họ luôn tìm cách để tránh sự nhàm chán. Tuy nhiên, nếu
        họ tỏ ra không kiên nhẫn thì họ có thể đánh mất sự tập trung và dễ hành
        động mà không có kế hoạch đúng đắn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Những Nét Đặc Tính của Người Mậu Dần:</b> thông minh, đam mê, thích
        xã giao, độc lập, thích vật chất, thực tế, cuốn hút, giỏi tưởng tượng,
        hấp dẫn.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Dần thường tham vọng và sở hữu năng lực lãnh đạo tốt. Họ cần
        có càng nhiều sự tự do càng tốt trong công việc nếu không sẽ dễ trở nên
        khó chịu. Kỹ năng giao tiếp thường giúp họ có một công việc tốt. Bởi vì
        họ là người đa tài, họ có xu hướng làm nhiều nghề trước khi quyết định
        ổn định với một công việc sau cùng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Dần có xu hướng tập trung vào chi tiết và thường là những
        người hoàn mỹ. Trí óc sắc bén và thực dụng giúp họ định hình công việc
        tốt. Họ cần phải tránh việc than phiền vì có thể khiến bản thân quá lo
        lắng. Những người Mậu Dần thường làm việc vất vả và nhận được phần
        thưởng xứng đáng cho công việc của họ, chứng minh rằng họ rất tận tâm và
        trung thành. Suy nghĩ thực tế của họ cộng với khát khao tri thức thường
        dẫn đến đến những vị trí cao hơn trong lĩnh vực họ chọn theo đuổi. Họ có
        năng lực điều hành bẩm sinh giúp họ có thể thành công khi nắm giữ vị trí
        quyền lực.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Công Việc:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Vì người Mậu Dần thích kiểm soát mọi tình huống, họ dễ thành công ở vị
        trí quản lý. Tri thức của họ dễ phục vụ tốt cho công việc trong lĩnh vực
        gíáo dục, luật pháp, viết lách, chính trị hay khoa học. Kiên nhẫn là vũ
        khí bí mật của những người này vì họ luôn muốn tỏ ra thụ động trong sự
        nghiệp. Sẽ có lợi cho học nếu đợi người khác chủ động tiếp cận họ để xin
        lời khuyên tư vấn hay dịch vụ. Đó là lý do vì sao những ngành nghề như
        tiếp thị hay bán hàng không phù hợp cho người này. Nếu họ có cơ hội đi
        nước ngoài thì đảm bảo sẽ có tương lai sự nghiệp hứa hẹn. Vì người Mậu
        Dần thường đơn độc và do đó việc tạo dựng mạng lưới kết nối quan hệ
        trong công việc là một việc khó cho họ. Người Mậu Dần có khả năng cảm
        thông tự nhiên, điều có thể giúp ích cho họ trong công việc trị liệu và
        tư vấn nơi họ có thể giúp đỡ người khác. Nhiều người Mậu Dần cũng dễ
        thành công trong lĩnh vực thể thao do sự yêu thích vui chơi của họ. Khám
        phá và tiên phong có thể gợi cảm hứng cho bản tính yêu thích mạo hiểm
        trong những con người này. Nếu người Mậu Dần có sao Thực Thương, người
        này sẽ có bản tính nghệ thuật bẩm sinh và họ có xu hướng thích âm nhạc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có khả năng tưởng tượng tốt và gíac quan nhạy bén có thể giúp họ
        trong các công việc như nhiếp ảnh, truyền thông, âm nhạc, vũ đạo hay
        hình ảnh. Họ có thể có cảm hứng làm việc trong những ngành nghề đòi hỏi
        sử dụng khả năng trí óc tốt, cũng như triết học hay nghiên cứu. Một số
        người thậm chí thích chọn làm việc trong lĩnh vực khoa học hoặc y học
        tuỳ theo năng lực kỹ thuật hay phân tích. Các hoạt động từ thiện hay
        chăm sóc người khác cũng thường được người Mậu Dần chọn vì họ cũng có
        khuynh hướng thích nhân đạo.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những Nét Đặc Tính Của Người Mậu Dần Trong Công Việc: sáng tạo, thông
        minh, kỹ năng lãnh đạo tốt, giỏi giao tiếp con người, siêng năng, thực
        dụng, đa tài, dễ buồn chán, áp đặt, quá cảm xúc, bất ổn.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tình Yêu:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người này nói chung giỏi trong mối quan hệ bởi vì bản tính ấm áp
        và cuốn hút của họ và họ giỏi kỹ năng giao tiếp với con người. Đàn ông
        Mậu Dần thường dễ kết hôn với phụ nữ để gia tăng quyền lực hoặc địa vị
        xã hội. Quyền lực rất có sức hấp dẫn đối với những người đàn ông này
        nhưng họ nên tránh tranh giành quyền lực với người bạn đời. Phụ nữ Mậu
        Dần thường dễ thu hút nhiều đàn ông, tạo ra nhiều rắc rối trong các
        quyết định liên quan đến mối quan hệ tình cảm. Những người Mậu Dần
        thường yêu thích tự do, do đó họ thích độc lập trong khi cũng vẫn tìm
        kiếm mối quan hệ lý tưởng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Mậu Dần thường duyên dáng và hấp dẫn với cá tính nồng
        nhiêt. Họ không ngần ngại hi sinh vì người họ yêu. Tuy nhiên, những
        người này có xu hướng lý tưởng hoá và cá tính ngẫu hứng, khiến cho thích
        thử nghiệm với nhiều mối quan hệ khác nhau.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Cũng như cá tính của họ, mối quan hệ của họ đôi khi được bao phủ bởi hào
        quang bí ẩn. Điều này biểu thị họ dễ dính vào những mối quan hệ bí mật.
        Họ thích ở bên cạnh những người có thể khơi gợi trí óc của họ, cũng như
        họ cần người bạn đời mà họ có thể chia sẻ sự quan tâm cũng như giữ cho
        tâm trí họ năng động. Họ có thể bị cuốn hút bởi người sáng tạo giống như
        họ. Họ khao khát tri thức và điều này có thể khiến họ chọn bạn đời thông
        minh hơn họ.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Mối Quan Hệ:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người Mậu Dần thường hướng ngoại và thân thiện cũng như yêu thích
        giao tiếp xã hội và thích tạo ấn tượng tốt. Họ có sự nồng nhiệt trong
        giao tiếp với người khác, điều này là do tính cách quyến rũ và hấp dẫn
        của họ. Những người xung quanh dễ bị thu hút bởi các cá tính trên của
        người Mậu Dần, và khiến cho người Mậu Dần dễ nằm trong danh sách bạn bè
        thân của nhiều người.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ thích ở bên cạnh những người thực tế và lạc quan, người có thể cho họ
        lời khuyên quý giá hoặc giúp họ giải quyết khó khăn. Bởi vì bản tính có
        khiếu hài hước, người Mậu Dần thích giải trí và vui vẻ. Họ thường thích
        giao tiếp với những người có thể khơi gợi suy nghĩ của họ cũng như cho
        phép họ thể hiện sự sáng tạo. Người Mậu Dần dễ nảy ra nhiều sáng kiến và
        ý tưởng sáng tạo khi đi dã ngoại và điều này cũng giúp tiếp thêm năng
        lượng và nguồn cảm hứng cho họ.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Gia Đình:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Xét về mối quan hệ gia đình, người nữ giới Mậu Dần thường làm việc vất
        vả để đảm bảo được môi trường sống thoải mái và vui vẻ. Người Mậu Dần
        đôi khi có xu hướng tỏ ra kiểm soát hoặc áp đặt, điêu này có thể gây ra
        căng thẳng trong mối quan hệ của họ. Bởi vì họ là những người ngẫu hứng,
        họ muốn người vợ hay chồng có thể kích thích mình về mặt trí óc. Họ muốn
        dành thời gian ý nghĩa trong việc hình thành mối quan hệ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Vận may trong hôn nhân của họ chỉ ở mức trung bình và họ thường có đời
        sống hôn nhân bình an. Họ thường có con muộn. Nam giới Mậu Dần có thể
        không thấy nhiều duyên phận trong cung Phu Thê và họ có thể cuối cùng cô
        đơn bởi vì không có Thuỷ trong cung Phu Thê. Ngược lại, phụ nữ Mậu Dần
        thường dễ có cuộc hôn nhân tốt vì có Quan Tinh tại trụ ngày.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đặc Tính: bảo vệ, yêu thương, áp đặt, duyên dáng, lý tưởng, vui đùa,
        nhạy cảm, từ bi, bất ổn.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trụ ngày Mậu Thổ toạ Trường Sinh nên thường là dấu hiệu của người may
        mắn và thịnh vượng. Tuy nhiên, nếu người này sinh vào mùa Xuân hoặc có
        nhiều Thất Sát trong lá số thì ít may mắn hơn hoặc có khả năng họ sẽ đối
        mặt với nhiều khó khăn vất vả suốt cả đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Dần thường đa cảm với tính cách hai mặt. Họ có thể tỏ ra khá
        tự tin và quyền lực nhưng họ cũng khá nhạy cảm và dễ bị tổn thương. Họ
        có thể phải vất vả trong việc kiểm soát tâm trạng liên tục thay đổi. Họ
        liên tục phải tìm kiếm sự cân bằng về cảm xúc. Có khả năng rằng điều này
        chỉ đến sau khi họ có nhiều kinh nghiệm sống hơn. Nếu họ học được cách
        tin tưởng vào trực giác của mình thì họ dễ cảm thấy thành công và hạnh
        phúc dễ dàng hơn. Điều này bởi vì Mậu Thổ toạ trên ẩn tàng Thiên Ấn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Dần rất giàu sự tưởng tượng và có đam mê to lớn trong việc tự
        sáng tạo. Họ có tinh thần trách nhiệm cao trong việc đòi hỏi sự cân bằng
        ổn định. Nếu họ có thể cân bằng được mọi khía cạnh và tìm cách áp dụng
        được cảm hứng của họ vào thực tế, họ sẽ dễ thành công.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Dần rất dễ trở thành những nhà lãnh đạo gợi cảm hứng có trí
        tuệ trực giác tốt. Điều này đặc biệt đúng nếu như có Bính Hoả lộ trên
        Thiên Can ở lá số Bát Tự. Nếu họ có thể sử dụng kỹ năng tốt, họ có thể
        dự đoán trước khó khăn và hành động nhanh chóng để tìm ra giải pháp.
        Thành công về vật chất rất quan trọng với họ và họ cần tránh để cho xu
        hướng vật chất trở nên chủ đạo trong cuộc sống của họ. Rào cản lớn nhất
        đối với thành công của người Mậu Dần thường chính là sự tự tin cũng như
        cảm giác bất an của họ khiến cho họ dễ nghi ngờ năng lực bản thân và họ
        dễ bị tổn thương và bị chỉ trích.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngược lại họ cũng dễ cảm thấy mất kiên nhẫn và hay chỉ trích đồng
        nghiệp. Điều này bởi vì Tỷ Kiên ẩn tàng bên dưới địa chi Dần.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mọi người xung quanh đều nhìn Mậu Dần như là người duyên dáng và dễ
        thương và thường không thấy được sự dễ tổn thương họ che giấu bên dưới.
        Những người này có nhu cầu cần sự tốt bụng và cảm giác an ninh họ chú
        trọng trong tình yêu mái ấm và gia đình. Họ rất nhạy cảm và thường là
        nguồn động viên tinh thần cho những người thân yêu. Họ có thể cần phải
        tránh trở nên kiểm soát quá mức do lo sợ thay đổi hay ham muốn bảo vệ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trụ Mậu Dần được đại diện bởi Cấn Quái Mậu Thổ núi, biểu thị trụ ngày
        vững chắc, to lớn và ổn định. Vì có núi thì sẽ luôn toả ra nguồn sinh
        khí bất tận, những người Mậu Dần sẽ luôn có năng lực phát triển tâm linh
        vô tận cũng như phát triển bản thân mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Lá số Bát Tự này rất tích cực nếu trong lá số của họ có kèm với Thất
        Sát, Dương Nhẫn, Tài Tinh và Thực Thần xuất hiện cùng lúc trong lá số.
        Mặc khác, lá số sẽ không bằng nếu như trụ ngày Mậu Dần gặp Hình, Xung,
        Phá hay Hại trong lá số. Nếu gặp Thân cũng biểu thị xấu cho Mậu Dần vì
        Địa Chi này phá huỷ trụ ngày.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mậu gặp Trường Sinh ở Dần và Mậu Dần được xem là toạ Thất Sát. Điều này
        biểu thị người này sẽ rất ganh đua ham thích quyền lực và danh tiếng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Dần sinh trong mùa Xuân và Hè sẽ được tưởng thưởng cho công
        sức làm việc vất vả bằng danh tiếng và tài lộc. Người sinh trong tháng
        mùa Thu và Đông thì ít may mắn hơn, đặc biệt là xét về mặt tài chính.
        Thường bất lợi cho người Mậu Dần sinh vào tháng Mùi, vì họ phải vất vả
        mới đạt được thành công trong đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mậu toạ trên Dần có Giáp Thất Sát, Mậu Tỷ Kiếp và Bính Thiên Ấn. Bởi vì
        họ thường có vẻ ngoài bất động, sự hiện diện của Thất Sát sẽ giúo cho
        thực hiện mọi thứ. Người Mậu Dần rất cứng rắn với quyết định của mình và
        họ thường xây dựng trên những quyết định lâu dài. Họ thường rất giỏi
        trong các công việc cộng tác vì Tỷ Kiếp hỗ trợ họ trong những mục tiêu
        chung trong bất kỳ dự án nào mà họ tham gia. Mặc dù Mậu có vẻ chậm, sự
        xuất hiện của Thiên Ấn đem đến cho Mậu Dần một trực giác bén nhạy. Người
        Mậu Dần có thể thích nghi làm được tất cả mọi thứ bằng cách chiếm được
        lòng tin của người khác, họ có trực giác bén nhạy về môi trường xung
        quanh họ và họ biết rõ những gì đang xảy ra.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Dần thu hút Hợi trong Lục Hợp đem đến Nhâm (Thiên Tài) và Giáp Thất Sát.
        Xét về mặt mối quan hệ tình cảm thì cả nam và nữ đều tốt mặc dù cung Phu
        Thê biểu thị người chồng hay vợ thì không. Mối quan hệ tình cảm của họ
        hơn kiểu bạn trai bạn gái, hứng thú nhưng không bền. Điều này cũng có
        nghĩa là họ không thích gắn bó theo kiểu quan hệ truyền thống, họ sẽ bỏ
        ra đi nếu cảm thấy mọi việc không ổn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Dần xua đuổi Thân trong Lục Xung từ chối Canh Thực Thần, Nhâm Thiên Tài
        và Mậu Tỷ Kiên. Bởi vì sao Chính Tài, Chính Quan không liên quan, mối
        quan hệ tình cảm khá trung lập nhưng người nam sẽ cảm thấy khó chịu với
        kiểu quan hệ tạm thời là điều mà Mậu sẽ không thích, ý nghĩ phải thay
        đổi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhìn chung, chúng ta sẽ thấy rằng người Mậu Dần khá trung lập khi xét về
        mặt tài lộc vì họ thu hút Thiên Tài và cũng từ chối Thiên Tài. Tiền có
        thể không phải là động lực chính của họ. Họ có cảm giác mạnh mẽ về trách
        nhiệm do Thất Sát chủ về thực thi công việc. Họ không muốn nhận lãnh
        trách nhiệm một mình mà sẽ xây dựng hệ thống mối quan hệ của họ dựa trên
        niềm tin đơn thuần. Họ sẽ là những người quản lý tài chính tốt trong một
        tổ chức tài chính lớn.
      </p>
    </div>
  );
};

export const MauTy = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Mậu Tý có tính cạnh tranh cao, luôn hiếu thắng, tính
        toán, ích kỷ, thông minh, có tính cách hơi trẻ con, nói năng không khéo
        léo, coi thường ý kiến người khác, hấp dẫn và được người khác giới yêu
        mến, dễ ghen tuông, có vận quý nhân tốt. Hôn nhân tốt nhưng không hoà
        hợp vợ/chồng. Kiếm sống bằng sức lao động của mình, có thể thành công từ
        trung niên trở đi.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngọn núi trong sương mù là nơi xuất phát nhiều huyền thoại, có nhiều kho
        báu và trí tuệ ẩn giáu bên trong. Người Mậu Tý cho bạn cảm giác không
        tiếp cận được nhưng vẫn có hi vọng chờ đợi.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mậu Thổ là một trong số các ngũ hành khó khăn. Họ thiếu khả năng trốn
        tránh và bước tránh. Họ có phong cách chấp nhận và đối đầu, bảo vệ nền
        tảng. Thổ gắn liền với sự cứng đầu và khó tưởng tượng. Một kỹ năng mạnh
        mà họ sở hữu là giữ vững nền tảng và có thể cất giấu mọi thứ bên trong.
        Thổ chứa mọi hành, nó có thể tiêu thụ và giữ bên trong. Họ là những
        người nắm giữ bí mật và toả ra một độ tin cậy cao trong số tất cả các
        hành.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Tý là những người bí ẩn, cũng như những ngọn núi bị bao phủ
        trong mây mù. Người Mậu Tý ít khi nói lên suy nghĩ và quan điểm chân
        thực trong đầu mình. Những người này là những chiến binh và kẻ sống sót
        sau cùng, họ không bao giờ dễ dàng từ bỏ mà không tranh đấu. Sự kiên
        trì, kiên nhẫn, quyết tâm là một phần trong tính cách của họ và đó là
        những gì giúp cho những người có ý chí mạnh mẽ này luôn thành công.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Hầu hết người Mậu Tý có ngoại hình hấp dẫn, tuy nhiên họ hay tỏ ra rụt
        rè hay tỏ vẻ không thoải mái trên gương mặt vì họ thường phải tự vực dậy
        sự tự tin của bản thân. Do đó, hầu như người Mậu Tý thường phải đấu
        tranh nội tâm. Vì lẽ này, họ thường xuyên có nội tâm mâu thuẫn và bất
        an.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc cho sự bất an của họ, thì người Mậu Tý là những người đáng tin cậy
        và có thể tin tưởng được. Họ quan tâm về cách nhìn đánh giá của người
        xung quanh mình và sẵn lòng làm mọi thứ để đạt được và duy trì danh
        tiếng tốt. Họ rất gần gũi gia đình và tử tế với bạn bè, tuy nhiên có
        nhiều lúc, họ không thể tìm được sự giúp đỡ khi cần thiết.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Hầu hết mọi người thường xem họ như là những người can đảm thích sống
        đời sống năng động. Những người này không chịu được sự lười nhác và họ
        thường xuyên làm việc chăm chỉ cũng như học hành siêng năng. Họ có tham
        vọng cao và có khả năng tập trung vào một mục tiêu nhất định mà không để
        cho những thứ khác làm xao lãng mình. Mặc dù điều này có thể giúp cho họ
        thành công, đôi khi họ cũng tham vọng nhiều nên người xung quanh đánh
        giá là cứng đầu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Về mặt tích cực, người Mậu Tý là những người giỏi giải quyết khó khăn vì
        họ biết cách để vượt qua khủng hoảng và rắc rối. Họ rất thông minh và đa
        tài trong nghệ thuật và thủ công, cũng như lĩnh vực học thuật.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Kiến thức và tài năng của họ khiến cho họ cư xử theo cách thực tế, dù là
        trong suy nghĩ hay hành động. Tuy nhiên, những người này cũng đôi khi
        khá nhạy cảm về mặt cảm xúc bên trong, và điều này có thể ngăn họ hoàn
        thành ước mơ hay mục tiêu của mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bởi vì cảm xúc có thể trở thành trở ngại lớn của họ, những người này nên
        giữ bình tĩnh. Họ nên học cách chú trọng đến bản thân mình để họ có thể
        thư giãn và trầm tĩnh mỗi khi họ bắt đầu cảm thấy bất an. Tương tự, nếu
        họ cảm thấy không thoả mãn trong cuộc sống, họ có thể giải toả bằng cách
        đi chơi du lịch hoặc kết nối tâm linh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Tý nhận thức rất rõ về những gì họ cần làm và cố gắng để tạo
        ra bầu không khí bình an và vui vẻ ở bất cứ nơi nào họ sinh sống. Điều
        này giúp cho họ bình an trong trí óc, bởi vì họ sẽ cảm thấy sự thư giãn
        nếu hoàn thành tất cả trách nhiệm. Trách nhiệm của họ có thể bao gồm
        việc cho người khác lời khuyên, bảo vệ người khác, hay thậm chí là làm
        việc nhà. Bởi vì những người này có quan điểm mạnh mẽ, họ có thể đấu
        tranh vì chính nghĩa và họ xem đó là trách nhiệm của bản thân. Người Mậu
        Tý thường bị ngăn cản bởi những thử thách đòi hỏi họ phải trở nên khiêm
        tốn và không vì bản thân, khiến bản thân phải hi sinh. Nếu họ quyết định
        đấu tranh vì chính nghĩa, họ thường đấu tranh với niềm hứng khởi lớn
        nhất.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người Mậu Tý thường đứng rất vững và họ sẵn lòng đấu tranh nếu họ
        cảm nhận đó là điều đúng đắn. Người Mậu Tý là những lãnh đạo giỏi giang
        và quan tâm người khác nhưng bản tính dẫn dắt và cảm giác bất an của họ
        có thể ngăn cản họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Nét Tính Cách Của Người Mậu Tý:</b> bí ẩn, thực dụng, tham
        vọng, ý chí mạnh mẽ, sáng tạo, thông minh, nhanh nhạy, tự hào, chân
        thật, đáng tin cậy, bất an, nghĩa khí và can đảm.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Tý có thể thành công trong mọi công việc. Sự thông minh, sáng
        tạo, quyết tâm của họ giúp cho họ không bao giờ thiếu cơ hội phát triển
        sự nghiệp. Họ luôn tin vào việc tiến lên phía trước và rất tham vọng,
        cho phép người Mậu Tý thành công dù làm ở bất kỳ vị trí công việc nào.
        Kỹ năng lên kế hoạch và sắp xếp của họ khiến cho họ trở thành lãnh đạo
        mạnh mẽ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ thích làm một mình khi kinh doanh, khi hợp tác hay làm việc nhóm thì
        có thể làm cho họ thành công và hiệu quả hơn. Điều này bởi vì người Mậu
        Tý sở hữu kỹ năng quản lý con người xuất sắc và kỹ năng quản trị nhóm
        tốt. Một khi họ là 1 thành viên của nhóm, thì họ luôn trung thành và gắn
        bó với nhóm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Mậu Tý có xu hướng trở nên giàu có và hầu hết đều có
        cuộc sống dư dả. Xét về mặt sự nghiệp, họ may mắn và thành công trong sự
        nghiệp. Bản tính huyền bí của họ dễ thu hút người khác vì nhiều người
        thích người Mậu Tý, điều này có thể khiến cho họ trở nên thành công
        trong kinh doanh. Không may, họ thường thiếu kiên nhẫn, và có thể là bất
        lợi lớn cho họ trong kinh doanh.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Công Việc:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Tý sở hữu trí thông minh cao và đầu óc sáng tạo, do đó họ cần
        phải tận dụng khả năng này của mình trong sự nghiệp. Họ thường bị lôi
        cuốn vào những nghề nghiệp như viết lách, giáo dục, luật nơi họ cần
        thường xuyên sử dụng trí óc. Đây là những vị trí công việc mà họ cảm
        thấy thoả mãn nhất và họ có xu hướng thành công trong các công việc
        trên.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Kỹ năng lãnh đạo của họ đóng vai trò lớn trong thành công sự nghiệp của
        họ. Tính cách của họ giúp cho họ dễ thành công trong ngành âm nhạc và
        giải trí. Thành công trong những lĩnh vực này giúp cho họ trở nên phấn
        khích và cung cấp nguồn năng lượng cần thiết để họ khai phá tài năng. Họ
        cũng có thể kiếm được rất nhiều tiền trong ngành quảng cáo, bán hàng và
        tiếp thị vì họ cũng có thể sử dụng kỹ năng giao tiếp tốt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Đặc Tính Trong Công Việc:</b> Siêng năng, động lực lớn, tài năng,
        thông minh, sáng tạo, thực dụng, kỹ năng giao tiếp tốt, sắp xếp giỏi,
        trực giác, ngoan cố, tự cao, không phải là người làm việc nhóm tốt,
        chuyên quyền, thiếu tầm nhìn.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Hôn Nhân - Gia Đình - Quan Hệ:{" "}
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Tý sở hữu trí thông minh cao và đầu óc sáng tạo, do đó họ cần
        phải tận dụng khả năng này của mình trong sự nghiệp. Họ thường bị lôi
        cuốn vào những nghề nghiệp như viết lách, giáo dục, luật nơi họ cần
        thường xuyên sử dụng trí óc. Đây là những vị trí công việc mà họ cảm
        thấy thoả mãn nhất và họ có xu hướng thành công trong các công việc
        trên.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Tý rất độc đáo và có xu hướng bị thu hút đến với người giống
        họ. Họ thường thích những người đặc biệt có những sở thích khác nhau.
        Không khó để họ yêu nhưng họ cảm thấy khó khăn để bày tỏ tình cảm. Họ
        rất ít nói và thích giữ bí mật. Có thể vì họ thiếu sự tự tin, vì họ sợ
        rằng người kia không đón nhận tình cảm của họ. Kỹ năng lãnh đạo của họ
        đôi khi thể hiện trong cả đời sống tình yêu, vì họ rất muốn bảo vệ người
        mình yêu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ không dễ gắn bó với ai, nhưng một khi họ gắn bó thì thường họ sẽ
        thích người có tính cách cởi mở và độc đáo. Vì người Mậu Tý bất an, họ
        nên tìm người bạn đời thích vui vẻ và năng động. Họ thường tìm kiếm
        người thú vị và hay suy nghĩ, và họ cũng dễ dàng cảm thấy buồn chán. Một
        người phối ngẫu sẵn lòng đi vòng quanh thế giới với họ sẽ là một sự lựa
        chọn tốt, vì những người này luôn hướng ngoại và thích đi lại.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ cũng cần ở bên cạnh những người thông minh vì họ thích được thách
        thức. Họ rất cần tìm người bạn đời có tri thức. Loại người bạn đời phù
        hợp với họ là người không kiểm soát mối quan hệ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Tý giỏi giao tiếp, có nhiều bạn bè và mối quan hệ. Điều này
        bởi vì họ dễ cuốn hút người khác vì bản tính thân thiện. Với sự duyên
        dáng và quyến rũ của họ, người này thường có đời sống xã giao tốt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ cảm thấy thoải mái khi ở bên cạnh những người thông minh và sáng tạo
        kích thích tri thức của họ. Mặc dù họ mạnh mẽ và độc lập, họ thường tỏ
        thái độ ấm áp đối với bạn bè của mình. Ngoài ra, họ cũng rất thích bảo
        vệ, quan tâm người xung quanh mình. Họ có bản chất quan tâm những người
        yêu thương mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ thường có con cái quan tâm và kính trọng cha mẹ. Người Mậu Tý có con
        sớm ngay sau khi kết hôn thì được xem là tốt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Kết hôn muộn là sự lựa chọn tốt cho người Mậu Tý, vì nó cũng tạo thêm cơ
        hội tạo ra nhiều tài lộc trong tương lai. Cuộc hôn nhân của họ thường
        thành công bởi vì họ là người chân thật và chung thuỷ với vợ hay chồng.
        Một trong những nét tiêu cực của người Mậu Tý là họ có xu hướng hoang
        tưởng về việc vợ hay chồng bỏ họ ra đi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Đặc Tính Trong Tình Yêu và Mối Quan Hệ:</b> Bảo vệ người thân yêu,
        hướng ngoại, cuốn hút những người đặc biệt, ấm áp, chung thuỷ, chân
        thật, duyên dáng, thích ở gần người thông minh.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Tý nằm trong Lục Tú Nhật, biểu thị người thông minh và duyên
        dáng. Tý biểu thị Chính Tài, người nam giới Mậu Tý có cơ hội 31lấy được
        vợ giàu có hoặc có kỹ năng quản lý tiền bạc thông minh. Vì can và chi là
        Mậu Quý hợp để tạo thành Hoả do đó người này thường sang quý và tinh tế.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Tý thường chân thật, thực tế và có tầm nhìn rõ ràng. Họ đáng
        tin cậy và siêng năng và thường thực tế. Họ có kỹ năng quản lý xuất sắc
        và thường là người làm việc hiệu quả, kỹ năng lãnh đạo xuất sắc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Tý có đầu óc sáng tạo và thường suy nghĩ nhanh nhạy. Họ có thể
        sử dụng trực giác để xem bản chất của vấn đề. Điều này giúp họ có kỹ
        năng giải quyết vấn đề xuất sắc và họ có thể nhìn ra được giải pháp rất
        đơn gỉan cho những vấn đề rất phức tạp. Người Mậu Tý rât ganh đua và có
        động lực lớn. Họ có khả năng xây dựng sự nghiệp thành công nhưng họ cũng
        nên dựa vào sự giúp đỡ và hỗ trợ từ những người xung quanh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Tý rất tôn trọng đồng nghiệp. Họ rất tự cao. Tuy nhiên họ cũng
        có kỹ năng xã giao xuất sắc do đó không bao giờ thiếu bạn bè và những
        người ngưỡng mộ. Họ có sự duyên dáng, họ cũng thường có những đời sống
        xã hội năng động và bạn bè sẽ nghĩ về họ là người rất văn hoá.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Tý khó có thể nói rõ và diễn đạt cảm xúc của mình mà họ chỉ có
        thể biêu đạt thông qua hành động. Họ tử tế và quan tâm và sẽ chủ đông
        bảo vệ tài lộc của những người xung quanh. Họ thường bảo vệ và ủng hộ
        anh em, người thân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Tý không phải là ngày sinh mạnh hay cân bằng. Do đó, nên hợp
        với Quan, Thực Thần và Ấn trong lá số thì người này sẽ “chì” hơn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Tý nếu gặp thêm ngũ hành Hoả, Thổ, Ngọ, Tuất và Dần trong lá
        số thì sẽ giúp bảo vệ cho họ. Điều này sẽ giúp cho lá số thành công về
        tài chính và sự nghiệp. Không tốt cho lá số nếu trụ Mậu Tý gặp Quan Tinh
        hay Thất Sát Tinh hay có Thân, Tý, Thìn và Mão ở trong lá số sẽ làm cho
        trụ ngày bị suy yếu và xung. Người này sẽ đối mặt với nhiều thách thức
        trong cuộc sống.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Tý sinh trong tháng mùa Xuân và Hè sẽ có hạnh phúc và thành
        công, họ sẽ có nhiều may mắn và sẽ có nhiều may mắn cũng như ít khó
        khăn. Người nào sinh vào mùa Thu và Đông thì sẽ có sức khoẻ tốt và sống
        thọ. Không giống như những ngày sinh khác, người sinh ngày Mậu Tý dù
        sinh ban ngày hay ban đêm thì cũng nhiều may mắn. Nếu sinh trong tháng
        Mùi thì tốt, những người này sẽ đạt được thành công lớn trong đời sống
        và ít khi gặp khó khăn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mậu toạ trên 1 hành duy nhất là Tý Quý Thuỷ toả ra năng lượng của Chính
        Tài. Chính Tài là chủ về tài năng. Trụ ngày này thể hiện rằng người này
        sẽ rất siêng năng và thực tế. Cuộc sống của họ sẽ có 1 mục tiêu duy nhất
        là tiến lên. Hãy tưởng tượng như một ngọn núi vốn dĩ đã khó tiếp cận,
        giờ lại được bao phủ bởi một lớp sương mù dày. Hãy có bao giờ mong đợi
        tìm được kho báu cất giấu? Quý là hành không thể bị tấn công cũng như có
        sức mạnh ở lại cao. Điều này diễn dịch thành họ siêng năng không ngừng
        nghỉ và bị thúc đẩy bởi tài lộc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tý hấp dẫn Sửu thông qua Lục Hợp. Năng lượng của Kiếp Tài Kỷ Thổ, Chính
        Tài Quý Thuỷ và Thương Quan ẩn giấu bên trong. Tất cả năng lượng gây xấu
        cho tình cảm của cả nam và nữ. Kiếp Tài phá hỏng hôn nhân của nam giới
        trong khi Thương Quan xấu cho nữ giới. Người này có thể khá kín kẽ và có
        quan điêm tình cảm bí mật. Họ muốn bạn trở thành người đáng tin cậy
        nhưng họ cũng muốn thâm nhập vào những bí mật được bảo vệ của bạn. Đối
        với nam giới thì có vấn đề về tài lộc, bạn là người giàu, người giàu
        thường thu hút phụ nữ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tý xung Ngọ Đinh Hoả. Năng lượng của Chính Ấn Đinh Hoả cũng như Kiếp Tài
        Kỷ Thổ bị xua đuổi. Bạn bị ám ảnh với bí mật của mình đến độ gần như bị
        xua đuổi. Yếu tố bù đắp lại cho tình cảm của nam giới là bí mật của bạn
        quan trọng hơn là lựa chọn phụ nữ. Về cơ bản bạn sẽ ổn nếu người bạn đời
        thuộc tuýp người biết giữ bí mật.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Tý không phải là người dễ hé môi. Họ giàu có và bí mật. Họ có
        thất cả mọi phương tiện và thời gian để lên phương án bảo vệ bản thân.
        Họ thường là người dàn dựng phía sau. Yếu tố bù đắp lại là họ không dữ
        dội và mục đích không phải là hại ai. Tốt nhất là tránh cản đường họ.
        Mậu Tý là một trong những ngày sinh đáng giữ làm bạn nhất.
      </p>
    </div>
  );
};

export const MauTuat = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Mậu Tuất rất duyên dáng và cuốn hút, có tính cách áp đặt
        gia trưởng, có khả năng quản lý mối quan hệ xã giao tốt, có năng lực,
        giỏi kinh doanh, mắt sắc bén tìm kiếm lợi nhuận, nhưng thiếu khoan dung
        đối với người khác, đa nghi. Chỉ nếu khi người này có thể hoàn toàn tin
        tưởng cấp dưới của mình thì việc kinh doanh của họ sẽ phát triển, người
        này tỏ vẻ rộng lượng nhưng thực chất là đầy toan tính. Nếu là nữ giới,
        thì rất xinh đẹp.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mậu Tuất cũng như là một ngọn núi lửa chờ giây phút đúng của mình để
        bùng nổ (có thể cũng là giây phút sai dành cho những người xung quanh)
        để làm công việc tốt nhất của một ngọn núi lửa. Họ có thể sẵn lòng chờ
        đợi hàng thế kỷ và đột nhiên tuôn trào toàn bộ sự mạnh mẽ can đảm mà
        không báo trước. Đó là những gì mà người Mậu Tuất hay thể hiện.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mậu Tuất là hình ảnh như là ngọn núi đá, sắc bén, cứng chắc. Điều này rõ
        ràng nhất biểu thị tính cách thẳng thắn của người Mậu Tuất. Họ thẳng
        thắn và ác nghiệt, biểu thị họ rất phù hợp với những việc khó khăn như
        đứng đầu một tổ chức hay quan chức quân đội. Điều này có thể khiến họ có
        thể trở thành nhà độc tài. Nhưng bên dưới vẻ ngoài sắt lạnh, họ khá vị
        tha, bình an và độc lập. Họ thích những kỹ năng giao tiếp tự nhiên và
        thích mối quan hệ tình cảm hoà hợp. Người Mậu Tuất bẩm sinh sáng tạo và
        thích nghệ thuật, cái đẹp và âm nhạc. Họ cũng có xu hướng thiên về cuộc
        sống phong cách và xa xỉ. Họ có thể trông hời hợt, nhưng chỉ là cố gắng
        bảo vệ bản thân khỏi bị tổn thương.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Tuất là người có động lực thúc đẩy và có quyết tâm trong đời.
        Họ tham vọng nhưng cũng tình cảm, sở hữu ham muốn mãnh liệt để tiến bộ
        và ổn định về vật chất. Ham muốn ổn định và thành công biểu thị họ có
        thể chấp nhân và thân thiện. Sức mạnh cuốn hút và trực giác giúp họ thấu
        hiểu mọi hoàn cảnh nhanh chóng. Họ nhạy cảm với lời chỉ trích, vốn cỏ
        thể tránh được băng cách hình thành quan điểm lý trí hơn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người này thích đi xa để tìm lại bản ngã thật sự. Họ không ngừng
        cảm thấy bất mãn với hoàn cảnh hiện tại. Họ có ý chí mạnh mẽ và quyết
        tâm cũng như có tính thực tế, thông minh sâu sắc và ham muốn được công
        nhận. Những người xung quanh bị cuốn hút bởi sự tự tin và chắc chắn của
        người Mậu Tuất. Những người này có xu hướng có đời sống xã hội năng
        động.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thành công là gần như chắc chắn nếu họ sẵn lòng nỗ lực, lên kế hoạch và
        chiến lược. Người Mậu Tuất lạc quan và trọng danh dự. Sự nhiệt thành của
        họ có thể bị cản trở bởi sự nghi ngờ bản thân và nghi ngờ người khác. Họ
        có thể không ngừng suy nghĩ và dễ dàng cảm thấy buồn chán nếu không được
        gợi cảm hứng. Sự giác ngộ về tâm linh sẽ giúp cho họ mở rộng chân trời
        tri thức và cũng như ham muốn tôn giáo hoặc đạo đức. Họ tưởng tượng và
        có đầu óc phân tích, cũng như cần có triết lý để duy trì nhu cầu có đời
        sống độc lập. Họ trân trọng tình yêu và cảm xúc cũng như các kỹ năng
        giao tiếp giúp họ thành công với người khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Tuất tự cao, tỉnh táo và đánh gía cao trí tuệ đến từ các trải
        nghiệm thực tế. Họ đôi khi có thể đa nghi, do đó họ cần niềm tin vào bản
        thân mình. Họ có thể cảm thấy bất mãn và nóng giận và có cảm giác thất
        vọng, nóng giận và buồn bã. Người Mậu Tuất có bản năng đánh giá tình
        huống và con người nhanh chóng. Họ sở hữu ham muốn mạnh mẽ ở mức độ sâu
        sắc, có thể dẫn lối cho các hành động vị tha vì người khác. Nhân cách
        của họ có thể giúp cho cuộc sống tốt đẹp. Họ cần cẩn trọng tránh không
        để nhu cầu an ninh vật chất lấn át ham muốn trải nghiệm những gì sáng
        tạo.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nói chung với một vẻ ngoài lạc quan và thân thiện, những người này sở
        hữu trí tưởng tượng cao, khả năng nổi bật giữa đám đông. Mặc dù họ cảm
        thấy nhu cầu liên quan đến tiền bạc mạnh mẽ, người Mậu Tuất có thể chú
        trọng đặc biệt đến thể hiện tình yêu và thoả mãn những giấc mơ lớn của
        họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người này thực tế, nhưng cũng thể hiện trực giác và khả năng ngoại
        cảm, có thể trợ giúp cho ham muốn và sự tự nhận thức của họ. Họ có đầu
        óc đầy những ý tưởng sáng tạo, có thể giúp họ sử dụng để thúc đẩy các
        mối quan tâm sáng tạo. Họ thường xuyên tìm kiếm kiến thức và những ham
        muốn này giúp giữ cho họ tươi trẻ và nhiệt huyết.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Tuất, thường có thời trẻ bất ổn với thua lỗ về tài chính. Cuộc
        sống trung niên nhiều ham muốn, ghen tuông và tham lam. Cả đời họ nhiều
        thách thức và điều này khiến cho họ sắt đá và dữ dội, cũng như ngọn núi
        đá. Họ thường thông minh nhưng thiếu kiên nhẫn với những người xung
        quanh. Phụ nữ Mậu Tuất thường có vận trung bình nếu nhận con nuôi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Những Đặc Tính Của Người Mậu Tuất:</b> tham vọng, thân thiện, hoà
        nhã, sáng tạo, nhạy cảm, thực tế, lạc quan.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Tuất giỏi kinh doanh và có tài lãnh đạo. Siêng năng trong công
        việc giúp họ có thành công đáng kể. Họ tài năng và thông minh cộng với
        kỹ năng quản lý xuất sắc. Năng lực cảm xúc giúp cho họ rộng lượng, tử tế
        và hấp dẫn cuốn hút. Tham vọng lớn, sự nhạy cảm, can đảm và khả năng
        phản hồi nhanh có thể giúp họ đạt thành tích ngoại hạng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngoài việc sống tình cảm, người Mậu Tuất luôn muốn tìm môi trường làm
        việc hoà hợp. Sự đa tài dễ khiến họ trở thành những người học hỏi nhanh
        chóng và thích nghi trong nhiều hoàn cảnh. Cảm âm của họ khá tốt và nhạy
        cam giúp họ dễ có sự nghiệp trong ngành âm nhạc và ca múa, sức khoẻ cũng
        như trị liệu. Những người này nhạy cảm, có độ nhận thức cao, lý trí,
        trực giác và sử dụng trí óc của họ để phân tích tình huống. Họ rộng rãi
        và tử tế, điều này giúp cho họ có thể tạo ra môi trường làm việc thân
        thiện cho nhiều người. Họ khá quyết tâm và có thể đạt được bất kỳ mục
        tiêu nào họ chú tâm. Nhu cầu tiến bộ liên tục đảm bảo họ luôn tiếp tục
        cải thiện thành tích và chất lượng của bất kỳ lĩnh vực nào họ đặt chân
        vào.{" "}
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Công Việc:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những công việc chán ngắt, lặp đi lặp lại không phù hợp với người Mậu
        Tuất. Họ cần công việc thử thách trí tưởng tượng. Sự tham vọng và tính
        cạnh tranh thúc đẩy họ tiến đến những vị trí quyền lực. Những công việc
        hành nghề tự do như cố vấn, chuyên gia...có thể rất tốt với họ. Người
        Mậu Tuất phù hợp với nghề nghiệp trong ngành thương mại như chuyên gia
        đàm phán, môi giới, cố vấn tài chính, bởi vì năng lực cam kết, sự can
        đảm, sự hào hứng và khả năng thực thi điều hành.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khả năng lãnh đạo giúp họ trở thành những nhà điều hành, quản lý hay
        doanh nhân giỏi. Họ cũng rất có khả năng diễn xuất nên phù hợp với ngành
        kịch nghệ hay giải trí.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có khả năng làm việc chăm chỉ, điều hành và khao khát trong kinh
        doanh và tiếp thị. Kỹ năng giao tiếp con người giúp họ có sự nghiệp tốt
        trong lĩnh vực hành chính công và quan hệ kết nối. Ngân hàng, bảo hiểm,
        thị trường chứng khoán, truyền thông và ngành xuất bản cũng là những lựa
        chọn tốt cho người này.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có xu hướng thích tham gia vào những công việc vì con người như lĩnh
        vực sức khoẻ, từ thiện hay cải cách xã hội. Họ là những người suy nghĩ
        sáng tạo, giải quyết khó khăn và sẽ phù hợp với con đường giáo dục,
        triết lý và viết lách. Kỹ năng giao tiếp tốt cho tương lai trong ngành
        chính trị và các lĩnh vực khá tương tự đòi hỏi mức độ tương tác với con
        người.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Trong Công Việc - Sự Nghiệp:</b> cạnh tranh, thực tế,
        làm việc chăm chỉ, tham vọng, sáng tạo, giao tiếp, kiêu căng, thái độ kẻ
        cả, ích kỷ, bất an, thiếu tự tin, mất kiên nhẫn, cảm thấy thấp kém.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Hôn Nhân - Quan Hệ - Gia Đình:{" "}
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thẳng thắn và chân thật, người sinh ngày Mậu Tuất đặc biệt quan tâm đến
        người bạn đời có óc thực tế, sự nhạy cảm và tưởng tượng. Họ cần tự do
        trong mối quan hệ vì họ đánh giá cao nó. Họ có thể bị ảnh hưởng từ sự
        thiếu quyết đoán hay lo lắng về những vấn đề tình cảm và có thể cảm thấy
        quá nhiều sự lựa chọn trong hành trình đi tìm tình yêu lý tưởng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Tuất đòi hỏi bạn đời cần phải tương xứng với họ về thái độ
        chăm chỉ và tính chủ động. Họ thường ấm áp và quan tâm, mặc dù họ cũng
        có thể đôi khi hống hách và thích tranh cãi. Họ có xu hướng vội vã lao
        vào mối quan hệ và điều này khiến họ không chắc chắn về những cam kết
        tình cảm lâu dài. Trí óc thực tế của họ nên được sử dụng trong việc tìm
        kiếm bạn đời cẩn thận, nếu không họ sẽ mất hứng thú hay cảm thấy bị kiềm
        hãm trói buộc.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người Mậu Tuất cần phải cân bằng giữa khía cạnh nghiêm túc với cá
        tính lãng mạn. Họ tốt với bạn đời nhưng cần cẩn trọng tránh trở nên áp
        đặt hay hống hách. Đam mê của họ có thể tạo nên sự cuốn hút mạnh mẽ hay
        thậm chí tình yêu sét đánh. Khi yêu, họ sẽ rất có trách nhiệm và có xu
        hướng hi sinh. Một khi đã cam kết trong tình cảm, họ có xu hướng chung
        thuỷ lâu dài với người bạn đời.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Tuất thường thân thiện và tốt bụng cũng như thích gặp gỡ người
        mới. Họ bị cuốn hút bởi những người có thể thể hiện sự quyết tâm và
        quyền lực, vì họ bị cuốn hút đi theo hướng này trong đời. Họ hi vọng một
        ngày nào đó mình cũng sẽ thành công như những người trên.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bản tính thân thiện giúp họ có nhiều cơ hội xã giao và làm quen. Hầu hết
        mọi cơ hội của họ có là thông qua mối quan hệ. Họ tìm kiếm những người
        có thể kích thích họ về mặt tư duy và giúp họ có khoảng thời gian thư
        giãn. Những người này tỏ ra hiếu khách và thích tạo niềm vui cho người
        khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Tuất có một đời sống bận rộn và dễ đem theo gia đình trong các
        hoạt động của họ. Tuy nhiên, họ có xu hướng áp đặt và thậm chí ích kỷ
        với người thân yêu, điều này gây ra sự rạn nứt trong gia đình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người Mậu Tuất nên kết hôn muộn, mặc dù có thể sợi dây liên kết
        quan hệ tình cảm vợ chồng cũng sẽ yếu. Phụ nữ ngày Mậu Tuất dễ đối mặt
        rắc rối trong hôn nhân. Họ thường có xu hướng lấy người có quyền lực.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Trong Hôn Nhân - Quan Hệ:</b> ấm áp, thân thiện, hi
        sinh, cá tính mạnh, chung thuỷ, quyến rũ, thiếu quyết đoán về tình cảm.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mậu Tuất toạ Mộ Khố và được thông căn. Điều này biểu thị Thổ khô rằng
        người này sẽ có một cuộc sống trên trung bình. Sự hiện diện của Khôi
        Cương trong lá số biểu thị rằng người này sẽ thông minh, học thức và
        quyết tâm. Có khả năng rằng mặc dù người Mậu Tuất có thể rất chính trực,
        họ có thể gây sự công kích nếu họ quá thẳng thắn trong giao tiếp với
        người khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Tuất rất tham vọng với đam mê để thành công và đạt được những
        gì bền vững. Họ có ham muốn được chiếm lĩnh sân khấu trung tâm và thu
        hút những người khác chú ý đến mình. Họ là người theo chủ nghĩa hoàn
        hảo, người Mậu Tuất sẽ không nghỉ ngơi cho đến khi họ cảm thấy thỏa mãn
        rằng họ đã thực sự làm hết khả năng của mình. Họ đa tài và có thể thành
        công trong bất kỳ lĩnh vực nào mà họ tham gia với tư cách đội nhóm. Họ
        thường được trực giác dẫn đường trong khi họ cũng rất khách quan và thực
        tế, người Mậu Tuất nhanh chóng nắm bắt được những chi tiết tinh tế. Họ
        giỏi trong suy nghĩ rõ ràng và có khả năng giải quyết khó khăn thực tế,
        họ cũng có thể đạt được kết luận logic nhanh chóng hơn người.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tuy nhiên, họ cũng thiếu tự tin bởi vì sự cầu toàn của họ nghĩa rằng họ
        không bao giờ biết thoả mãn. Họ luôn bị bắt buộc thay đổi cuộc sống và
        cải thiện bản thân. Họ luôn có một mục tiêu mới để đạt được và mục tiêu
        mới đề đề ra sau đó. Họ thường dễ bị tổn thương bởi những lời chỉ trích
        vì họ cũng thường xuyên thích chỉ trích người khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Tuất là người rất tin tưởng vào học hỏi bằng trải nghiệm. Họ
        cũng thường phân tích các hành động và cảm xúc của mình để học hỏi từ
        sai lầm. Quan điểm hướng nội có thể khiến cho họ nhìn có vẻ tự học hỏi
        cũng như tin tưởng vào việc học hỏi từ kinh nghiệm của người khác và họ
        trân trọng cơ hội để chia sẻ ý tưởng. Họ rất xã giao và thích nghi tốt
        với nhiều nhóm khác nhau, biết cách hoà nhập với người ở nhiều giai tầng
        xã hội. Họ học hỏi từ mỗi cuộc gặp gỡ mới và hình thành nên kỹ năng giao
        tiếp và ngoại giao.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Tuất suy nghĩ nhiều và nhạy cảm. Họ hiểu biết cảm xúc con
        người và có nhận thức cũng như từ bi, thấu hiểu sâu sắc. Họ đánh giá cao
        sự hoà hợp và thường bị bắt buộc trở thành người giảng hoà. Những tài
        năng này có thể giúp họ trở thành lãnh đạo tự nhiên, mặc dù họ cũng đôi
        khi cần phải kiềm chế tránh trở nên quá chân thật hay thẳng thắn trong
        giao tiếp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù người Mậu Tuất tỏ vẻ rất tự tin và mạnh mẽ, họ cũng ẩn giấu mặt
        mềm mỏng hơn. Họ có động lực phát triển bản thân có thể khiến họ căng
        thẳng và lo âu. Họ có nhu cầu cần sự ổn định và rất ủng hộ người thân
        yêu, cũng như có thể rất phụ thuộc. Họ thường lạc quan, ctuy nhiễn cũng
        ó thể dễ dàng bị cảm xúc bất ổn vì khá nhạy cảm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trụ ngày Mậu Tuất được biểu thị bằng hình ảnh của quặng mỏ trong núi nơi
        có sao Khôi Cương Tinh ẩn giấu. Điều này biểu thị một người rộng lượng
        và tử tế. Trong lá số Bát Tự, trụ ngày Mậu Tuất bị kiểm soát và ủng hộ
        bởi Thất Sát, Dương Nhẫn, Tài Tinh và Thực Thần. Điều này bởi thị lá số
        có lợi và khoẻ mạnh, và do đó những người này có tương lai tài chính và
        sự nghiệp tốt đẹp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu có Quý trong lá số, người Mậu Tuất sẽ giàu có và sang quý tuy nhiên
        nếu có quá nhiều Thuỷ trên Thiên Can, có thể gây ra cục Bối Thuỷ Trận
        (xấu). Điều này sẽ càng tệ hơn nếu trụ này xung với Thìn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mậu Thổ là trụ của sự ổn định, nhưng ẩn bên dưới là sao Bệnh Phù đem đến
        tác dụng xấu của Tuất. Mậu Tuất sẽ may mắn nếu có Mộc xuất hiện. Tuy
        nhiên, trụ này lại sinh ra hành Mộc, vốn dĩ không phải là điều tốt vì
        quá nhiều Mộc sẽ biểu thị mối quan hệ xấu, đặc biệt với nữ sinh ngày Mậu
        Tuất với chồng của họ. Điều này bởi vì Mộc (đại diện cho người chồng)
        không thể kiểm soát hay yêu thương đúng cách với người Mậu Tuất.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tuất phù hợp với Dần nhưng không hợp với Ngọ. Trụ này không nên gặp
        nhiều Mão, Thân, Thìn, Dậu và Tý trong Đại Vận bởi vì điều này biểu thị
        không tốt cho mối quan hệ tình cảm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Tuất sinh vào tháng mùa Xuân và Hè sẽ thất bại khi còn trẻ và
        điều này sẽ thúc đẩy họ trên con đường thành công hơn. Người sinh mùa
        Thu và Đông sẽ đối mặt nhiều khó khăn trong việc thừa kế, thậm chí không
        nhận được tài sản thừa kế. Tháng Thìn xấu cho người sinh ngày Mậu Tuất,
        nếu họ sinh tháng này thì phải đối mặt với những cuộc chiến liên tục để
        thành công trong đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mậu Thổ là hành của niềm tin. Họ đòi hỏi sự tin tưởng bởi vì họ là hành
        già nhất trên trái đất. Họ có khả năng độc đáo trong việc che giấu mọi
        thứ. Họ cũng là hành duy nhất có thể chấp nhận chứa tất cả những hành
        khác bên trong họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu có Quý trong lá số, người Mậu Tuất sẽ giàu có và sang quý tuy nhiên
        nếu có quá nhiều Thuỷ trên Thiên Can, có thể gây ra cục Bối Thuỷ Trận
        (xấu). Điều này sẽ càng tệ hơn nếu trụ này xung với Thìn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có siêu năng lực của việc giữ và chia sẻ mọi thứ. Đây có lẽ là hành
        thông thái nhất vì họ chỉ thực hiện công việc của bảo vệ kiến thức và
        không ngần ngại chia sẻ lại nó với người khác. Hầu như họ chỉ lẳng lặng
        quan sát mọi thứ được gợi mở, không bao giờ tham gia vào bất kỳ kịch bản
        nào và do đó luôn giữ được sự sinh tồn dài lâu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mậu toạ Tuất là một trong các Địa Chi Thổ khắc nghiệt nhất. Tuất mang
        theo Mậu Thổ Tỷ Kiên là chính khí trong khi bị kẹp bởi Đinh Hoả Chính Ấn
        và T6n Kim Thương Quan. Người Mậu Tuất thường trọng danh dự với anh em
        bạn bè. Họ là những người tuân thủ theo nhóm, tập thể và rât ít khi
        chống đối lại những gì tập thể suy nghĩ. Họ muốn trở thành hành khách
        trên hành trình, muốn được học hỏi từ người khác và muốn người khác có
        những ký ức tốt về mình. Khi họ được nhờ giúp đỡ thì họ đôi khi có thể
        mở lòng và chia sẻ kiến thức dồi giàu của họ và trở thành nguồn sáng soi
        đường. Điều này chỉ xảy ra nếu như rất cần thiết vì nếu như không có sự
        lựa chọn nào khác. Cũng như núi lửa, thông thường một vài sự phá hoại
        chấn động sẽ làm nứt và phun trào núi lửa tuy nhiên sau đó thì sẽ là một
        môi trường đất đai màu mỡ hơn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tuất sẽ hợp với Mão đem đến năng lượng của Chính Quan Ất Mộc. Đối với nữ
        giới thì điều này gọi là sao người chồng. Thông thường thì Chính Quan
        cũng đại diện cho quyền lực quan chức và quý. Phụ nữ có thể cẩm thấy kỳ
        lạ là mặc dù cho người chồng yêu quý mình nhưng bên trong bạn vẫn cảm
        thấy bất đồng. Nhưng là một thành viên trong gia đình, bạn vẫn sẽ tiếp
        tục đóng vai trò của mình để tránh đối đầu trực tiếp. Trong quá khứ thì
        người phụ nữ có thể nín nhịn, nhưng ngày nay, hãy coi chừng những đợt
        bùng nổ cảm xúc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tuất xung vớ Thìn xua đuổi Tỷ Kiên Mậu Thổ là chính khí và phụ khí của
        Chính Tài Quý Thuỷ cũng như Chính Quan Ất Mộc. Ở đây bạn có thể thấy sự
        tương tác bao gồm cả Chính Quan và Chính Tài. Bản tính kín kẽ thích nắm
        giữ thông tin của họ tạo nên một bức màn sương che mù. Niềm tin khó lòng
        được xây dựng dựa trên mối quan hệ mà người bạn đời ồn ào muốn đào sâu
        hiểu rõ người Mậu Tuất có suy nghĩ gì. Khi điều này xảy ra, người Mậu
        Tuất sẽ có xu hướng muốn bảo vệ và giữ những bí mật quý giá của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Tuất là những nhà sử gia và có thể được xem là những người bảo
        vệ bí mật của một tổ chức hội kín. Bắt họ phải nói ra hoặc thể hiện ra
        có thể rất khó nhưng một khi họ đã chịu bung mở (và bạn sống sót qua
        được cơn bùng nổ) thì bạn sẽ có một vùng đất tươi tốt để gieo trồng
        trong tương lai. Họ có thể là những người bạn rất tốt có thể chia sẻ bí
        mật và có những cuộc nói chuyện tri thức. Hãy yên tâm rằng bất cứ gì bạn
        nói ra sẽ được họ giấu kín.
      </p>
    </div>
  );
};

export const MauThan = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Mậu Thân lười nhác, lè phè, dựa dẫm vào người khác, nhạy
        cảm trong cảm xúc, hơi kỳ quặc, thiếu kiên nhẫn, có tài năng nghệ thuật,
        sẵn sàng học hỏi, không tiết lộ cảm xúc thật vì sợ bị tổn thương, giỏi
        trong quản lý mối quan hệ giao tiếp. Có nhân duyên lấy vợ/chồng đức hạnh
        nhưng người bạn đời sức khoẻ kém. Từ trung niên, có nhân duyên với con
        cái. Nếu là nữ, có thể giúp đỡ chồng nhưng khó làm chồng hài lòng. Tài
        lộc bất ngờ.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mẹ đất chứa đựng nhiều bí mật chưa hé lộ trong lòng. Thông thường khó
        lòng có thể lấy những vật chất từ trong lòng đất ra mà không đào xới.
        Mậu Thân là một trụ ngày phù hợp trong việc đào xới những gì che giấu
        hoặc tài năng ẩn giấu. Cũng như một cỗ máy đào trong khu vực khai thác
        mỏ, nó cuối cùng cũng sẽ tìm được điều mà nó tìm kiếm...chỉ là vấn đề
        thời gian.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mậu Thổ là hành lâu đời nhất như trái đất vì nó xuất hiện trước tất cả
        các hành khác. Ngay cả đến tận ngày hôm nay, thổ vẫn đóng vai trò che
        giấu tất cả mọi hành khác. Mộc đóng vai trò như là năng lượng hoá thạch,
        hoả như nham thạch, kim loại như quặng mỏ và nước như các hồ chứa. Không
        có gì mà đất không gìn giữ và chính bản chất của nó khiến cho nó trở
        thành ứng viên số 1 cho việc giữ bí mật. Đất là dấu hiệu của niềm tin vì
        bạn sẽ không bao giờ nhìn thấy một ngọn núi nào rời bỏ vị trí của nó.
        Bất cứ lúc nào bạn quay trở về, ngọn núi luôn sẽ hiện diện ở đó.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mậu Thân có hình ảnh như là núi đá có lõi giàu khoáng sản. Điều này biểu
        thị một người có tài năng ẩn giấu. Trong số tất cả những người có ngày
        sinh Mậu thì Mậu Thân là có nhiều suối nguồn tài năng nhất. Họ khá thông
        minh và cố gắng để đạt được kết quả tốt nhất trong mọi hoạt động. Họ
        khao khát học hỏi, khám phá, phân tích và mở ra thế giới cơ hội cho
        người Mậu Thân. Họ có sự duyên dáng bẩm sinh và có cá tính cuốn hút
        nhiều người hâm mộ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bản tính thích xã giao của họ được yêu thích và trân trọng bởi bạn bè,
        gia đình và người quen. Ngoài bản năng nghệ sĩ, họ cũng giỏi võ thuật và
        thể thao. Khá dễ để kết luận rằng với tất cả những tài năng trên và lịch
        trình cuộc sống bận rộn, người Mậu Thân khá mất kiên nhẫn và có tâm
        trạng thất thường; nhưng không hẳn như vậy. Họ khá bản năng và trực giác
        vì nếu biết cách khai thác những điểm này thì họ dễ dàng giải quyết vấn
        đề. Sự thấu hiểu mọi hoàn cảnh và con người cũng giúp ích trong việc đơn
        giản hoá các hoàn cảnh phức tạp. Họ đa tài, sáng tạo, hào hứng, sáng
        tạo, chung thuỷ, từ bi và nhân thức rõ tâm linh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi xét về khả năng nhận thức, người Mậu Thân thường kém hơn những người
        Mậu khác, vì quẻ Khôn, tuy nhiên điều này không ngăn cản họ phát triển
        mạnh mẽ hơn trong sự nghiệp. Họ là người chính nghĩa, biết tôn trọng
        tình bằng hữu và đánh gía cao tất cả giá trị của cuộc sống. Họ thường có
        danh tiếng tốt bởi vì bản tính rộng lượng, nhạy cảm và thấu hiểu. Người
        Mậu Thân làm việc vất vả và kiên trì để đạt thành công trong sự nghiệp
        và kinh doanh. Họ sẽ gặt hái may mắn nếu họ dấn thân vào chính trị hay
        khởi nghiệp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ khá lập dị và không bình thường, điều này có thể giúp họ quản trị
        việc kinh doanh của bản thân mình. Trong sự nghiệp kinh doanh của họ, họ
        khá năng động và không ngừng gia tăng các mối quan hệ giao tiếp xã hội.
        Họ làm việc vất vả vì sự thành công và có xu hướng hợp tác với nhiều
        người để cùng đạt mục tiêu. Đầu óc lý trí và giàu sự sáng tạo giúp họ có
        khả năng theo đuổi tham vọng lớn và dẫn bước họ đến thành công trong
        đời. Tuy nhiên, người Mậu Thìn có vấn đề sức khoẻ và nó sẽ gây ảnh hưởng
        đến đời sống của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Thìn có cá tính vui vẻ và sự duyên dáng bẩm sinh, khiến dễ
        cuốn hút những người xung quanh ở bất cứ nơi nào họ đến. Họ đa tài, có
        nhiều kiến thức, tự tin và dễ thuyết phục. Họ rất thông minh với nhận
        thức tốt, giúp họ có thể nắm bắt được ý tưởng dễ dàng áp dụng vào cuộc
        sống. Họ có xu hướng cảm xúc cao với tâm trạng trồi sụt liên tục. Điều
        này dẫn đến việc cãi vã trong mối quan hệ hay thậm chí sự thất vọng.
        Cuối cùng, họ luôn có khả năng đền bù cho những gì không vui bằng sự
        duyên dáng của mình đem đến sự hoà hợp trong mối quan hệ tình cảm. Họ có
        khả năng cao tạo động lực và gây ảnh hưởng lên người khác với cá tính
        cuốn hút của mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Thân thích đi lại và phiêu lưu, điều làm cho họ thích thay đổi
        mỗi phút giây. Điều này sẽ tạo nên tính thiếu kiên nhẫn, bất ổn và tâm
        trạng thất thường và sẽ ảnh hưởng tiêu cực đến cuộc sống của họ. Tuy
        nhiên, khi những người này tìm được niềm cảm hứng, họ sẽ tìm được sự
        hứng khởi để khám phá và bắt đầu những hoạt động mới. Người Mậu Thân vốn
        dĩ tài năng, họ thích vui chơi, giải trí và khá hào hứng trong suốt cả
        đời - luôn tươi trẻ và tâm chân thành. Sự sáng tạo sẽ xuất hiện kèm với
        những phẩm chất sẽ thúc đẩy họ theo đuổi các mục tiêu nghệ thuật trong
        sự nghiệp và cả thói quen.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Của Người Mậu Thân:</b> rộng lượng, thông minh, thích
        quảng giao, nhiệt tình, trực giác, mạo hiểm, từ bi, bất an, đa tài, tâm
        trạng.{" "}
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Thân khá tham vọng, dám nghĩ dám làm, thông minh và thích suy
        nghĩ lớn. Điều này khiến họ có thể đạt được bất cứ điều gì mà họ đặt hết
        tâm trí vào, và họ cũng rất quyết tâm đạt mục tiêu. Họ có giá trị cao cả
        và sẵn sàng đấu tranh vì công bằng xã hội. Họ là người thích cải cách xã
        hội và nhân đạo. Họ có bản tính lãnh đạo bên trong kết hợp với kỹ năng
        con người tuyệt vời như sự đồng cảm, khiến cho họ hiểu con người và làm
        việc tốt trong môi trường hợp tác. Tuy nhiên, họ có thể đối mặt mâu
        thuẫn bên trong khi ra quyết định giữa tham vọng cá nhân và lý tưởng
        nhân văn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sáng tạo giúp họ trưởng thành ở mọi giai đoạn cuộc đời, thúc đẩy họ liên
        tục tìm kiếm cách để thể hiện ý tưởng bản thân. Họ có đam mê mạo hiểm,
        khao khát trải nghiệm những thứ mới mẻ trong đời. Người Mậu Thân là một
        cá nhân giỏi cũng như giỏi hoà nhập trong tập thể. Họ có thể thành công
        trong bất kỳ nỗ lực gì dù có hay không được giúp đỡ. Họ khơi dậy đam mê
        từ tập thể, và có thể thích giao tiếp với người trí thức.{" "}
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Công Việc:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sinh ra vốn có kỹ năng giao tiếp bẩm sinh, người Mậu Thân thành công
        trong lĩnh vực quan hệ công chúng, giải trí, chính trị, tâm lý học, giáo
        dục hay bất kỳ lĩnh vực nào cần giao tiếp với con người hàng ngày. Họ sẽ
        giỏi trong các vị trí quản lý vì sở hữu khả năng quản lý tốt. Những
        người này rất sáng tạo và nên cân nhắc tham gia kịch nghệ, âm nhạc hay
        các hình thức nghệ thuật khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Thân có thể tiến vào lĩnh vực tài chính vì họ giỏi quản lý
        tiền cho người khác, cá tính quyến rũ của họ cho phép họ có thể đàm phán
        và là gương mặt thân thiện mà người khác tin tưởng. Họ có kỹ năng phân
        tích xuất sắc mà họ có thể sử dụng nó cho mọi công việc. Vì thấu hiểu
        sâu sắc giá trị, họ có bước voà lĩnh vực pháp luật hay hành chính công
        để gíup đỡ cộng đồng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Những Đặc Tính Trong Công Việc - Sự Nghiệp:</b> truyền cảm hứng,
        thích quảng giao, nhân văn, rộng lượng, đa tài, thấu hiểu, lý tưởng,
        khách quan, tâm trạng, thất thường, phụ thuộc, định kiến, quá nhạy cảm,
        phi thực tế, hướng nội, áp đặt.
      </p>

      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Quan Hệ - Gia Đình:{" "}
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Thân tìm kiếm sự ổn định bền vững trong mối quan hệ và bị cuốn
        hút bởi những người có chung sở thích tri thức. Họ có cá tính mạnh và sẽ
        hợp với người bạn đời mà không bị khó chịu bởi cá tính mạnh của mình.
        Những người này rất lãng mạn và có nhiều tình cảm muốn biểu lộ đến bạn
        đời. Họ lý tưởng hoá mọi việc và vì lý do này nên họ mong đợi nhiều
        trong tình yêu. Phụ nữ Mậu Thân dễ bị cuốn hút bởi đàn ông mạo hiểm. Đàn
        ông Mậu Thân ngược lại bị cuốn hút bởi phụ nữ có sức ảnh hưởng, chính
        thông qua những phụ nữ này mà họ đạt được địa vị xã hội cao hơn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhìn chung, người Mậu Thân có xu hướng tìm kiếm mối quan hệ ý nghĩa và
        thường giới hạn cảm xúc bản thân vì sợ bị tổn thương về tình cảm. Người
        Mậu Thân sẽ có mối quan hệ tình cảm lâu dài mặc cho cảm xúc trồi sụt,
        bởi vì họ luôn cố gắng giữ sự hoà hợp trong tình cảm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Thân có kỹ năng mềm rất tốt và có thể làm thân với bất kỳ dạng
        người nào, điều này cho phép họ có thể hiểu được khái niệm tình yêu và
        cảm thông. Họ bị lôi cuốn vào những mối quan hệ cho họ sự bình an và an
        toàn. Thông thường những người ở xung quanh Mậu Thân cũng giống như họ -
        siêng năng, tham vọng và mạo hiểm. Họ gia trưởng và đánh giá cao sự
        chung thuỷ, bởi vì điều này nên họ không áp đặt. Vì bản tính rộng lượng
        và chịu trách nhiệm, người Mậu Thân nhận được nhiều sự ngưỡng mộ và kính
        trọng từ bạn bè.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Thân bị cuốn hút đến những người có sức ảnh hưởng nhưng cần
        cẩn trọng về cảm xúc của riêng họ. Họ có thể bị lợi dụng về cảm xúc bởi
        những người có cá tính mạnh hơn. Nếu họ tránh phán xét mọi việc dựa trên
        quan điểm vật chất và phát triển sự linh hoạt trong đời sống để thấu
        hiểu người khác, họ sẽ nhận được nhiều sự ngưỡng mộ và tình cảm hơn.
        Nhìn chung, người Mậu Thân trải qua nhiều tâm trạng trồi sụt có thể ảnh
        hưởng đến mối quan hệ. Tuy nhiên, họ luôn cố gắng đem đến sự hoà hợp và
        cố gắng làm vui lòng người thân yêu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Thân khá hiện đại và chọn lối tiếp xúc phá cách trong giao
        tiếp. Họ nỗ lực để giữ mối quan hệ với tất cả bạn bè và sẵn lòng bất cứ
        gì cho người mà họ quan tâm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Không ngạc nhiên khi người Mậu Thân thường được xem là người bạn đời tận
        tâm và phụ huynh yêu thương, vì họ có tính cách ấm áp và quan tâm. Người
        Mậu Thân giữ gia đình trong tim họ và điều này khiến họ thích bảo vệ
        người mình yêu thương.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nữ giới Mậu Thân có thể khá giàu có và vận may của cô ấy sẽ đem đến
        nhiều may mắn và hạnh phúc cho gia đình. Con cái cô ấy sẽ chịu nhiều tai
        hoạ hay kiện tụng trong đời. Cũng có thể nữ giới Mậu Thân sẽ dính đến
        chuyện ngoại tình rắc rối mà họ cần học cách tránh né.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Trong Tình Yêu - Hôn Nhân:</b> thích quảng giao, từ
        bi, yêu thương, bất an, phi chính thống, sống cảm xúc
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Thân sống có lý tưởng, năng động và thích mạo hiêm. Họ bị lôi
        cuốn bởi đi lại và thích gặp gỡ người mới và có nhiều trải nghiệm mới.
        Họ đa tài, sáng tạo và có tài năng đặc biệt hiếm có. Họ thường không
        ngừng nghỉ, và cần được kích thích thật sự và có cảm hứng để áp dụng vào
        bản thân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Thân được công nhận về tài năng giao tiếp. Họ suy nghĩ thấu
        đáo, tử tế và hiểu con người. Họ có trực giác bẩm sinh có thể cuốn hút
        họ theo đuổi tâm linh và giúp họ có khả năng cao để liên kết với những
        người xung quanh và tạo bầu không khí thân thiện ở bất kỳ nơi nào họ
        đến.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thích giao tiếp với con người, người Mậu Thân thường thích tham gia vào
        những hoạt động tập thể và thường đạt được thành công sự nghiệp thông
        qua hợp tác. Họ muốn được công nhận vì tài năng đặc biệt và đóng góp của
        riêng mình. Họ cũng rất độc lập trong suy nghĩ và quan điểm mà họ không
        ngần ngại biểu lộ. Sự nhạy cảm của họ, biểu thị bất kể họ nói thẳng thắn
        đến đâu thì họ luôn có khả năng diễn đạt suy nghĩ mà khán giả của họ
        luôn cảm thấy cuốn hút.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Có bản tính quảng giao và khiếu khôi hài, người Mậu Thân thường rất nổi
        tiếng và có nhiều bạn bè. Họ là người thân thiện và thích giao tiếp xã
        hội. Họ cũng sống rất tình cảm tuy nhiên đằng sau vẻ ngoài hướng ngoại,
        họ cũng có những cảm xúc không dễ giãi bày. Đôi khi, họ có thể trở nên
        lặng im và có thể che giấu nỗi lo âu mà chỉ tiết lộ cho những người rất
        thân cận. Họ cần tìm sự cân bằng giữa bộ mặt xã hội và bản tính lặng lẽ,
        tình cảm hơn bên trong.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Thân có thể hình dung như là một ngọn núi đẹp đẽ. Trụ này được
        xem là rất tốt, đặc biệt nếu nó gặp thêm hành Thuỷ, Kim và Mộc trong lá
        số. Dựa trên hình tượng, núi là quặng mỏ khoáng sản mà chưa được khai
        phá. Tuy nhiên, muốn khai thác được tài sản này đòi hỏi nguồn lực và
        thiết bị mà được biểu thị ở hành Kim. Thân là sao Trường Sinh của hành
        Thuỷ. Nước cũng là tài tinh của Mậu Thổ. Do đó, trụ ngày này có tiềm
        năng tài lộc lớn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu hành Kim, như Canh Kim, lộ ở Thiên Can, thì Thổ cứng trong Mậu Thân
        biểu thị có thể được khai phá. Điều này cũng là dấu hiệu rằng người Mậu
        Thân dễ bị tổn thương về cảm xúc và bất ổn. Thường dễ thấy người Mậu
        Thân lo lắng quá độ. Nếu nhiều Kim lộ trong lá số Bát Tự thì có thể
        người Mậu Thân sẽ bị người xung quanh lợi dụng. Nếu hành Kim bị khai
        thác (xung hoặc hợp), thì người Mậu Thân sẽ có đời sống căng thẳng và vô
        vị.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trường Sinh của Thuỷ trong lá số Mậu Thân biểu thị nhiều tài lộc (đại
        diện bởi hành Thuỷ) trong cuộc sống. Sự xuất hiện của cả Thuỷ và Kim
        trong lá số biểu thị người Mậu Thân có tính nghệ sĩ và bày tỏ cảm xúc,
        bởi vì họ sẽ toả sáng trong lĩnh vực giải trí, kịch nghệ,..{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thân là Khôn Quái. Biểu thị Mậu Thân sẽ nhỏ bé hơn những người Mậu khác,
        họ có làn da sáng màu hơn nếu có nhiều Hoả trong lá số vì nó sẽ làm khô
        Thổ.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mậu Thân sinh trong mùa Xuân và Hè sẽ dễ trở thành doanh nhân thành công
        và người sinh trong mùa Thu và Đông dễ đối mặt ít thách thức hơn và có
        đời sống dễ chịu. Sinh vào ban ngày thì sẽ hưởng nhiều tài lộc lớn do
        lao động, trong khi người sinh vào ban đêm thì sẽ đạt thành công tự thân
        và không cần dựa vào sự giúp đỡ từ bên ngoài. Nếu sinh vào tháng Thìn
        thì có thể vất vả mới đạt mục tiêu và phải nỗ lực nhiều hơn người khác
        để tiến bộ và được công nhận.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mậu toạ trên Thân mang theo Canh Kim Thực Thần, Mậu Thổ Tỷ Kiên và Nhâm
        Thiên Tài. Về bản chất là họ rất thân thiện và muốn đồng điệu với tất cả
        những người xung quanh. Họ có quan điểm về tương lai chiến lược do Thiên
        Tài bên trong họ. Thêm vào đó, Thực Thần cho họ khả năng để sáng tạo ra
        các phương pháp để xâm nhập vào lòng đất cứng. Họ thường khá thân thiện
        và cùng lúc lại nghĩ ra những phương pháp tốt nhất có thể để đánh giá
        tiềm năng tương lai. Người này rất giỏi trong việc kèm cặp huấn luyện.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mối quan hệ tình cảm dưới góc độ lãng mạn không phải là lý tưởng vì có
        sao Tỷ Kiên bên dưới cung Phu Thê. Họ thường dễ tiếp cận nhưng khó cam
        kết tình cảm vì lo sợ làm hỏng tình bạn mông manh. Sự xuất hiện của
        Thiên Tài đối với nam cũng đồng nghĩa việc họ giữ các lựa chọn mở. Cánh
        cửa không bao giờ đóng hay thậm chí không có cả cửa.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thân hấp dẫn Tỵ mời gọi năng lượng của Bính Hoả Thiên Ấn, Mậu Thổ Tỷ
        Kiên và Canh Kim Thực Thần. Sự kết hợp này đem đến giây phút vui vẻ và
        kết nối mơ mộng. Sẽ thích hợp hơn cho nữ giới như là đối tượng hấp dẫn
        theo đuổi vì cá tính vô lo biểu hiện thời gian vui vẻ và dễ chịu. Sự
        thiếu vắng Chính Quan và Chính Tài cũng đã gợi ý rằng mối quan hệ không
        kéo dài.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thân xua đuổi Dần mang theo năng lượng của Giáp Mộc Thất Sát, Mậu Thổ Tỷ
        Kiên và Bính Hoả Thiên Ấn. Chúng ta có thể thấy một ít tin tích cực cho
        nữ giới vì cả sao bạn trai Thất Sát và Tỷ Kiên biểu thị rằng khi người
        này quen nam giới nào thì cũng sẽ không tìm kiếm đối tượng nào khác tuy
        nhiên cũng không có nghĩa rằng người này thật sự yêu say đắm bạn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trụ này cũng liên quan đến khám phá tài năng tương lai của bạn và tìm
        được cách đồng điệu để những tài năng này lộ ra, cũng giống như cách
        huấn luyện kèm cặp. Họ có sự kiên nhẫn để chờ đợi phút giây phù hợp để
        khiến bạn thay đổi và nghĩ ra cách thông minh sáng tạo để khiến bạn chấp
        nhận sự thay đổi.
      </p>
    </div>
  );
};

export const MauNgo = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Mậu Ngọ tập trung tâm trí sự nghiệp, xem sự nghiệp là
        trọng tâm trong cuộc đời, có chủ kiến mạnh mẽ, là người cầu toàn, trọng
        danh dự, hứa là giữ lời, đánh giá cao thành tích và sự công nhận, chung
        thuỷ trong tình yêu, có vẻ ngoài gây ấn tượng, hấp dẫn người khác giới,
        tính cách mạnh mẽ nhưng nhân từ, có niềm tin tôn giáo.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Điều xảy ra khi mảnh đất dưới chân bạn bị rung chuyển? Thường khá khó để
        biết những gì xảy ra ở dưới lòng đất. Một khi các tầng lớp địa chất có
        sự dịch chuyển thì không có gì bạn làm được để chống đối lại. Mậu Ngọ là
        một người có vẻ ngoài bình an nhưng bên trong lại xây dựng sức mạnh để
        chuẩn bị cho sự dịch chuyển.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mậu Thổ là hành biểu thị sự bền vững muôn đời. Đó là hành cổ nhất trên
        trái đất và sẽ ở lại lâu nhất trong khi những hành khác có thể biến mất.
        Họ có thể rất giỏi trong việc tự bảo vệ hiện trạng vì họ hiểu từ trải
        nghiệm rằng về lâu dài thì bất kỳ sự thay đổi nào cũng không có vấn đề
        lớn. Họ đứng vững trên nền tảng của mình và chờ đợi mọi thứ qua đi. Điều
        họ có là sự kiên nhẫn và cứng đầu để lờ đi mọi thứ xung quanh mình. Một
        thứ bạn có thể chắc chắn là sự đáng tin cậy, bất kể bạn đã bỏ họ đi bao
        xa hay bao lâu thì nếu bạn quyết định quay trở về, chắc chắn bạn vẫn sẽ
        thấy họ đứng thẳng như một ngọn núi, cũng vẫn ở chỗ cũ như trước đây.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Hình ảnh của Mậu Ngọ là ngọn núi lửa. Do đó không ngạc nhiên khi người
        Mậu Ngọ khá ngoan cố và nóng tính, những tính cách có thể dễ dẫn đến cãi
        nhau ngay cả từ những chuyện nhỏ nhặt nhất. Họ thích ở bên cạnh những
        người khác vì điều này sẽ giúp họ nâng cao kỹ năng phân tích con người.
        Họ không ích kỷ, vì Mậu Ngọ có thể hi sinh ham muốn của bản thân để hoàn
        thành những thứ khác tốt đẹp hơn. Họ hiền hoà và có thể tập trung vào
        những ai đang cần giúp đỡ. Họ có thể là người cầu toàn và khá kỷ luật
        trong sự nghiệp. Đây là những phẩm chất cua một lãnh đạo, bao gồm cả
        việc gieo nỗi sợ hãi cho đối thủ và cấp dưới.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Ngọ có khả năng thành công thông qua trực giác nhạy bén. Họ là
        sự kết hợp giữa một cá nhân hiền hoà và mạnh mẽ cùng sự quyết tâm để
        thành công trong đời. Họ có kiến thức và biết cách ứng dụng vào thực tế.
        Vì họ có thể tự viết nên số mệnh của mình, họ ít phụ thuộc vào gia đình.
        Họ có có năng lực đặc biệt và muốn kiểm soát mọi thứ trong cuộc đời
        mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thiếu niên Mậu Ngọ sẽ phải đối mặt nhiều thách thức đến khi sau tuổi
        trung niên. Do đó họ sẽ cần nỗ lực để đạt kết quả tốt. Tuổi già có thể
        khá vui vẻ với nhiều con cháu và đời sống bình an. Người Mậu Ngọ là
        những cá nhân quyết tâm sẵn sàng đối mặt với thách thức để thành công.
        Họ cũng có động lực hướng đến kinh doanh và có ham muốm phát tài. Những
        người này có tài năng xuất sắc nhưng thiếu óc tổ chức. Nếu họ biết học
        hỏi cách tổ chức và phát triển đường hướng đúng đắn, họ có thể rất thành
        công. Do đó, họ cần học hỏi để phát huy năng lực tự thân và phát huy tối
        đa tiềm năng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ bất an về tình cảm và người xung quanh và do đó họ có tính cách áp
        đặt. Tuy nhiên, khi họ sẵn sàng kết hôn, họ có thể cảm thấy hoà hợp và
        tìm được bình an trong các mối quan hệ. Bởi vì điều này, họ cũng có thể
        khá tình cảm và tìm kiếm tình bạn thật sự. Họ không tin tưởng ai dễ dàng
        nhưng khi họ thật sự tin, họ là người bạn chung thuỷ và quan tâm nhất.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Của Người Mậu Ngọ:</b> linh cảm, thích xã giao, chung
        thuỷ, nóng tính, độc tài, nhạy cảm, quyết tâm, bất an.{" "}
      </p>

      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Ngọ có sự nhiệt tình và tính cách hướng ngoại cùng khả năng
        nắm bắt bất kỳ nhiệm vụ nào và hoàn thành hiệu quả. Họ có kiến thức và
        kỹ năng thông qua việc học tập. Những người này thường xuyên nâng cao kỹ
        năng phân tích của mình, cũng như họ thích gắn bó với những người có thể
        kích thích trí óc của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ cũng sở hữu phẩm chất lãnh đạo vốn là một phần trong bản năng doanh
        nhân. Họ là người chăm chỉ, quyết tâm đạt được mọi thứ mà họ để tâm. Họ
        thích làm cho người khác vui. Người Mậu Ngọ luôn tìm cách thu thập tài
        lộc, và điều này sẽ là trọng tâm trong mọi chiến lược phát triển sự
        nghiệp. Vì họ liên tục cần Thuỷ (đại diện cho tiền bạc), họ có thể là
        người có đầu óc kinh doanh hoặc là người rất tham lam. Họ quyết tâm kiếm
        tiền bất kể hậu quả. Điều này càng rõ ràng trong cách họ quản lý tài
        chính và tiền bạc vì họ có xu hướng lao vào các hoạt động phi pháp để
        kiếm tiền.{" "}
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Công Việc:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Ngọ là người có đầu óc kiếm tiền và là doanh nhân xuất sắc. Họ
        có kỹ năng lãnh đạo, kỹ năng quản trị và phân tích doanh nghiệp khi họ
        xây dựng nên một tổ chức và dẫn hướng đội nhóm của mình hướng đến mục
        tiêu cụ thể.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ thích thể hiện bản thân do đó phù hợp cho sự nghiệp trong ngành quan
        hệ công chúng, truyền thông hay giải trí. Bởi vì họ khá kiểm soát, họ sẽ
        không thích làm ở vị trí phục tùng. Họ sẽ khá hơn nếu nắm vị trí quản
        lý, điều hành, giáo viên, giảng viên, chính trị gia hay phát ngôn viên
        vì có khả năng gây ảnh hưởng lên người khác. Họ cũng có thể tham gia vào
        lĩnh vực máy tính, hay dịch vụ hành chính công để sử dụng kiến thức và
        kỹ năng to lớn. Họ cũng phù hợp với lĩnh vực tâm lý học hay nghiên cứu.
        Điều này cũng dẫn dắt họ đến lĩnh vực hành chính nhà nước nơi họ có thể
        sử dụng kỹ năng giao tiếp rộng và vì một lý tưởng tốt đẹp hơn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Trong Sự Nghiệp - Công Việc:</b> đa tài, siêng năng,
        hoà hợp, phân tích, lãnh đạo, sáng tạo, quyết tâm, mất kiên nhẫn, tranh
        cãi, áp đặt, quá nhạy cảm, bất an, cứng đầu, không bao dung.
      </p>

      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Hôn Nhân - Quan Hệ - Gia Đình:{" "}
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Ngọ khá năng động và tìm kiếm người bạn đời thích hợp về mặt
        tinh thần. Họ cũng khá nhạy cảm và cũng có vấn đề về niềm tin cùng trái
        tim bất an khó đoán. Họ khá kịch tính trong tình yêu cùng đam mê mạnh mẽ
        trong việc gìn giữ quan hệ tình cảm hoà hợp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đàn ông Mậu Ngọ thu hút được phụ nữ quyền lực và độc lập. Những người
        này sẽ khó có thể lập gia đình ổn định hay tin tưởng bất kỳ ai trong
        tình cảm vì họ dễ nghi ngờ động cơ. Khi họ quyết định thành gia lập thất
        cùng với người yêu, họ có thể là người bạn đáng tin cậy và chăm sóc cũng
        như là người yêu chung thuỷ và luôn ủng hộ. Họ nên dành thời gian trước
        khi bước vào một mối quan hệ để tránh vỡ mộng khi người khác không sống
        như ý họ muốn.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Ngọ khá duyên dáng và có nhiều người theo đuổi. Họ tìm kiếm
        bạn bè là những người giàu kiến thức và có thể khơi gợi trí óc của họ.
        Họ cảm thấy bất an trong tình cảm và không dễ dàng tin tưởng người khác.
        Họ bị cuốn hút bởi cá tính mạnh để cho thể chia sẻ định hướng cuộc đời.
        Họ đánh giá cao tình bạn và là người đem lại niềm vui cho tập thể những
        người hâm mộ họ.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Ngọ luôn cố gắng gìn giữ sự hoà hợp. Tuy nhiên, đôi khi sự bất
        an dẫn họ đến cãi vã và rắc rối. Một khi họ kiên nhẫn, họ có thể học
        được cách chống lại hoàn cảnh khó khăn. Các mối quan hệ xã hội khá quan
        trọng cho người Mậu Ngọ vì họ sử dụng nó để phát triển kỹ năng để phân
        tích con người và vượt qua nỗi bất an.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Ngọ thường không có hôn nhân hay gia đạo suôn sẻ đối với cả
        nam hay nữ. Mối quan hệ giữa vợ/chồng Mậu Ngọ thường không hoà hợp vì họ
        có xu hướng cãi vã và cứng đầu. Họ khá cứng đầu, điều này ngăn cản hôn
        nhân không may mắn. Sự ngoan cố của người Mậu Ngọ gây bất hoà trong gia
        đạo.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sự bất hoà này có thể dẫn đến rắc rối cho con người về sau. Điều nay bao
        gồm cả kiện tụng hay rắc rối pháp lý và nó có thể gây cản trở sự tiến
        thân trong tương lai. Họ cần phải đảm bảo họ không trở thành người phụ
        huynh cố chấp vì không có lợi trong mọi mặt cuộc sống.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những Đặc Tính Trong Tình Yêu - Quan Hệ: đánh gía cao tình bạn, bất an
        trong tình cảm, kiểm soát mọi việc, quan tâm và yêu thương, nhạy cảm,
        rất thích xã giao.
      </p>

      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mậu Ngọ toạ Dương Nhẫn và Đế Vượng trong vòng Trường Sinh. Điều này cũng
        là dấu hiệu người Mậu Ngọ có thể gặp nguy cơ tai nạn, chấn thương và tai
        hoạ. Đặc biệt đúng nếu có sự hiện diện Tý trong lá số. Tương tự, nếu Mậu
        Thổ thường khô trong lá số thì có khả năng họ mất kiên nhẫn, dễ nóng và
        vội vã.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Ngọ có thể xem như ngọn núi lửa hùng vĩ. Họ có kỹ năng và đa
        tài trong việc chinh phục gần như mọi thứ mà mình muốn, nhưng cuộc đời
        họ thường là đấu tranh trong việc kiểm soát bản tính cực đoan mà nếu
        thiếu nó có thể khiến cho khó đạt thành công trong công danh hay hạnh
        ph1uc cá nhân.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người này rất áp đặt. Họ có tài năng và sự tự tin cũng như có cá
        tính độc lập và dựa vào bản thân, biểu thị họ rất thích chọn con đường
        khởi nghiệp. Họ có đầu óc khôn ngoan và chú ý đến mọi chi tiết, họ có
        nhận thức, trực giác và sáng tạo. Họ có sự sâu sắc trong suy nghĩ và
        tưởng tượng. Họ có kỹ năng kinh doanh xuất sắc và không bao giờ nghi ngờ
        bản thân và trực giác mà ngược lại rất quyết đoán và quyết tâm.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có thể rất tự hào và kiểm soát. Họ khó phân quyền và hoạt động như
        một phần đội nhóm. Người Mậu Ngọ có thể làm việc không hiệu quả, thiếu
        sự chuẩn bị và không có óc tổ chức. Họ cần phải tự tạo kỷ luật và cân
        bằng để có thê đạt được tiềm năng tối đa của mình. Giáo dục có thể giúp
        họ bằng cách huấn luyện đầu óc và thói quen làm việc và dạy họ cách tin
        tưởng vào sự nhận xét của người khác. Học cao giúp trang bị cho họ kiến
        thức để kết hợp với tài năng xuất chúng, tạo ra thành trì với vũ khí là
        kiến thức.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Ngọ duyên dáng và khả ái. Họ có tính cách sâu sắc và lý lẽ
        cũng như trực giác giúp họ có thể liên kết với người xung quanh. Họ cũng
        có thể dao động tâm trạng do sự bất an bên trong. Họ có thể tỏ ra hống
        hách và khó đoán và phải cố gắng để tìm sự bình an bên trong có khả năng
        ảnh hưởng đến mối quan hệ tình cảm cũng như lên sự nghiệp.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Như đã đề cập trước đây, trụ ngày Mậu Ngọ như ngọn núi lửa: nóng, bất ổn
        và khó đoán. Tuy vậy, nếu trụ này toạ Dương Nhẫn thì có dấu hiệu của
        thịnh vượng. Nếu không gặp Xung hay Hình, thì họ thường thành công và
        hạnh phúc trong đời. Nếu có thì dễ xui vì có xu hướng kích động tính nổi
        nóng và thiếu kiên nhẫn của người Mậu Ngọ .{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thất Sát Tinh có thể được dùng để hỗ trợ Dương Nhẫn vì nó đủ mạnh và
        tương đối ổn định. Ngoài ra nếu trụ này yếu thì nên gặp Ấn hay Thực
        Thương để may mắn hơn. Lá số Bát Tự chỉ tốt nếu Hợi xuất hiện trong đó
        và sự kết hợp giữa Mậu và Quý (cơn mưa lửa) là khó.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Ngọ sẽ có khao khát kiếm tiền nhiều (đại diện bởi Thuỷ). Nếu
        Thuỷ khô hạn, thì sẽ xung với với Tý trong lá số. Đây là dấu hiệu người
        Mậu Ngọ phải vất vả mới giành được thành công tài chính.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Phụ nữ Mậu Ngọ có khả năng dung hoà và giải quyết được những vấn đề
        không tốt về hôn nhân. Người chồng lúc này sẽ được đại diện bởi hành Mộc
        trong lá số sẽ thấy rằng họ sẽ hi sinh và rất khó để có thể kiểm soát.
        Vì Mậu Ngọ tựa như ngọn núi lửa, họ thường cứng rắn và nóng tính. Có vẻ
        như hành Mộc (người chồng) sẽ không thể kiểm soát hay thuần hoá được
        Mậu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sẽ tốt cho lá số nếu chứa nhiều hành Thổ vì điều này sẽ giúp cho nhiệt
        của ngọn núi lửa sẽ phân tán và được chia đều. Sự cuốn hút của Dương
        Nhẫn (Kiếp Tài) là tốt vì biểu thị đời sống xã hội năng động. Người Mậu
        Ngọ nên tránh quan tâm nhiều về tôn giáo vì sẽ tạo ra Ấn Tinh rắc rối có
        thể cản bước họ trong thế giới đời thực.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thực Thần và Thất Sát phải được dùng chugn với nhau để kiềm chế ảnh
        hưởng mạnh mẽ của Thất Sát. Dương Nhẫn, cần phải dùng Ấn Tinh để ủng hộ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Mậu Ngọ sinh vào mùa Xuân và Hạ sẽ quyết tâm và tham vọng trong
        đời khi có một chính nghĩa. Người sinh vào mùa Thu và Đông sẽ không sợ
        hãi phải chống lại vất vả trong đời và sẽ luôn biết cách vượt qua trở
        ngại. Nếu Mậu Ngọ sinh vào ban ngày thì sẽ nhận được sự nể trọng và địa
        vị xã hội cao bất kể xuất thân của họ. Nếu sinh vào ban đêm thì con cái
        là người chính trực và giàu có. Nếu sinh tháng Thìn thì người Mậu Ngọ sẽ
        không may mắn vì khó vượt qua trở ngại để đạt thành công.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mậu toạ Ngọ mang theo khí chính là Đinh Hoả Chính Ấn và Kỷ Thổ Kiếp Tài.
        Ấn là sao hồi phục sức khoẻ và nó biểu thị khả năng quay trở lại nếu như
        nếu họ gặp trở ngại. Đinh Hoả đại diện cho hi vọng và niềm vui và do đó
        họ thường là những người thích học với lượng kiến thức ẩn chứa khổng lồ.
        Kiếp Tài đối với lá số nam giới biểu thị vấn đề về tình cảm vì sao này
        xua đuổi sao biểu thị người vợ Chính Tài đi xa. Họ có nhu cầu ganh đua
        và nổi bật.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngọ hợp với Mùi đem theo khí của Kỷ Thổ Kiếp Tài, Đinh Hoả Chính Ấn và
        Ất Mộc Chính Quan. Sự xuất hiện của Chính Quan đối với nữ giới biểu thị
        người chồng trong khi đối với nam giới thì Kiếp Tài biểu thị ly thân với
        người vợ. Về phần nam giới thì vấn đề biểu lộ bản thân họ có thể góp
        phần vào khả năng giữ lại tình cảm trong khi nữ giới thì lại tốt do khả
        năng giấu giếm mọi thứ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngọ xung với Tý mang theo hành duy nhất là Quý Thuỷ Chính Tài. Điều này
        cũng giống như là điềm xui cho quan hệ tình cảm vốn đã xấu của người nam
        vì trụ này xua đuổi người vợ đi xa. Đối với người nặng lòng với quá khứ,
        người này sẽ sợ việc cam kết tình cảm. Từ chối tài tinh cũng sẽ biểu thị
        xu hướng từ chối việc tích luỹ tài sản. Họ thuộc dạng tri thức và vấn đề
        ham muốn nhiều tiền chưa hẳn đã hấp dẫn với họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Có một mức độ cứng đầu trong trụ ngày này. Họ có khả năng giỏi để học
        tập và quyết định giữ kiến thức bí mật và cất giấu nó. Hầu hết mọi người
        ngày sinh Thổ đều là bạn bè tốt bởi vì họ lắng nghe tốt và giữ bí mật.{" "}
      </p>
    </div>
  );
};
