import React, { useEffect, useState } from "react";
import { Button, Input, InputGroup, Label, Table } from "reactstrap";
import * as XLSX from "xlsx";
import Const from "../components/Const";
import { DragSwitch } from "react-dragswitch";
import TieuNhiQuanSat from "./TieuNhiQuanSat";
import SunCalc from "suncalc";
import moment from "moment-timezone";
import { VscTriangleLeft, VscTriangleRight } from "react-icons/vsc";
import SweetAlert from "react-bootstrap-sweetalert";
import "../scss/tieunhiquansat.scss";
import { calendar } from "../components/calendar";
import {
  DayToCanChi,
  DongTayTuTrach,
  getNamAp,
  getNamtietKhi,
  getQue,
  handleYearTietKhi,
  HourToCanChi,
  KhoiCuong,
  KhongVong,
  KhongVong2,
  MonthToCanChi,
  Next,
  nguHanh,
  NguyetChi,
  Prev,
  solarTime,
  soNgayDaiVan,
  TangCan,
  TangCanArr,
  ThanSatDiaChi,
  ThanSatDiaChiArr,
  ThanSatThienCan,
  ThanSatThienCanArr,
  ThapThan,
  ThapThanArr,
  ThienXa,
  ThienY,
  TruongSinh,
  YearToCan,
  YearToChi,
  ThaiCung,
  MenhCung,
} from "../components/convert";

import {
  convertTieuNhiMonth,
  convertTieuNhiYear,
  convertTuTru,
  checkNguHanh,
} from "../components/convertTieuNhi";

function PageTieuNhiQuanSat({ tietKhiDate = [], tietKhiInYear = [] }) {
  const noLogin = true;

  const [excelLichvanNien, setExcelLichvanNien] = useState([]);

  const [dateData, setDateData] = useState(Const.initDateData);
  const [city, setCity] = useState(Const.city);
  const [noiSinh, setNoiSinh] = useState();
  const [show, setShow] = useState(false);

  const [name, setName] = useState("");
  const [gender, setGender] = useState("Nam");
  const [hour, setHour] = useState("00");
  const [minute, setminute] = useState("00");
  const [day, setDay] = useState("01");
  const [month, setMonth] = useState("0");
  const [year, setYear] = useState(new Date().getFullYear());
  const [checkSelected, setCheckSelected] = useState(false);
  const [checked, setChecked] = useState(false);

  const [kinhDo, setKinhDo] = useState();
  const [viDo, setViDo] = useState();

  const [checkMinute, setCheckMinute] = useState(true);

  const [checkHour, setCheckHour] = useState(true);
  const [alerError, setAlerError] = useState(false);

  const [alerError2, setAlerError2] = useState(false);

  const [monthTieuNhi, setMonthTieuNhi] = useState();
  const [yearTieuNhi, setYearTieuNhi] = useState();
  const [tuTru, serTuTru] = useState();
  const [nguHanhTieuNhi, setNguHanhTieuNhi] = useState();

  const [canDay, setCanDay] = useState();
  const [chiDay, setChiDay] = useState();
  const [chiMonth, setChiMonth] = useState();
  const [canMonth, setCanMonth] = useState();
  const [chiHour, setChiHour] = useState();
  const [canHour, setCanHour] = useState();
  const [thienCanYear, setThienCanYear] = useState();
  const [chiYear, setChiYear] = useState();
  const [gioSolar, setgioSolar] = useState("");
  const [dayShow, setDayShow] = useState("01");
  const [monthShow, setMonthShow] = useState("01");
  const [yearShow, setYearShow] = useState(new Date().getFullYear());
  const [reload, setReload] = useState(0);
  const [solarphut, setSolarphut] = useState("");
  const [thapThanHour, setthapThanHour] = useState("");
  const [thapThanMonth, setthapThanMonth] = useState("");
  const [thapThanYear, setthapThanYear] = useState("");

  const [thapThanHour1, setthapThanHour1] = useState("");
  const [thapThanHour2, setthapThanHour2] = useState("");
  const [thapThanHour3, setthapThanHour3] = useState("");

  const [DiachiYear, setDiachiYear] = useState("");
  const [napAmYear, setnapAmYear] = useState("");
  const [thienCanHour, setThienCanHour] = useState("");
  const [diaChiHour, setdiaChiHour] = useState("");
  const [napAmHour, setnapAmHour] = useState("");
  const [thienCanDay, setthienCanDay] = useState("");
  const [diaChiDay, setdiaChiDay] = useState("");
  const [napAmDay, setnapAmDay] = useState("");
  const [thienCanMonth, setthienCanMonth] = useState("");
  const [diaChiMonth, setdiaChiMonth] = useState("");
  const [napAmMonth, setnapAmMonth] = useState("");

  const [nguHanhHour, setnguHanhHour] = useState("");
  const [nguHanhDay, setnguHanhDay] = useState("");
  const [nguHanhMonth, setnguHanhMonth] = useState("");
  const [nguHanhYear, setnguHanhYear] = useState("");

  const [tangCanNguHanhHour, settangCanNguHanhHour] = useState("");
  const [tangCanNguHanhHour2, settangCanNguHanHourHour2] = useState("");
  const [tangCanNguHanhHour3, settangCanNguHanHourHour3] = useState("");
  const [tangCanNguHanhHour4, settangCanNguHanHourHour4] = useState("");
  const [tangCanNguHanhHour5, settangCanNguHanHourHour5] = useState("");
  const [tangCanNguHanhHour6, settangCanNguHanHourHour6] = useState("");

  const [tangCanNguHanhDay, settangCanNguHanhDay] = useState("");
  const [tangCanNguHanhDay2, settangCanNguHanhDay2] = useState("");
  const [tangCanNguHanhDay3, settangCanNguHanhDay3] = useState("");
  const [tangCanNguHanhDay4, settangCanNguHanhDay4] = useState("");
  const [tangCanNguHanhDay5, settangCanNguHanhDay5] = useState("");
  const [tangCanNguHanhDay6, settangCanNguHanhDay6] = useState("");

  const [tangCanMonth, settangCanMonth] = useState("");
  const [tangCanMonth2, settangCanMonth2] = useState("");
  const [tangCanMonth3, settangCanMonth3] = useState("");
  const [tangCanMonth4, settangCanMonth4] = useState("");
  const [tangCanMonth5, settangCanMonth5] = useState("");
  const [tangCanMonth6, settangCanMonth6] = useState("");

  const [tangCanYear, settangCanYear] = useState("");
  const [tangCanYear2, settangCanYear2] = useState("");
  const [tangCanYear3, settangCanYear3] = useState("");
  const [tangCanYear4, settangCanYear4] = useState("");
  const [tangCanYear5, settangCanYear5] = useState("");
  const [tangCanYear6, settangCanYear6] = useState("");

  const [thapThanDay1, setthapThanDay1] = useState("");
  const [thapThanDay2, setthapThanDay2] = useState("");
  const [thapThanDay3, setthapThanDay3] = useState("");

  const [thapThanMonth1, setthapThanMonth1] = useState("");
  const [thapThanMonth2, setthapThanMonth2] = useState("");
  const [thapThanMonth3, setthapThanMonth3] = useState("");

  const [thapThanYear1, setthapThanYear1] = useState("");
  const [thapThanYear2, setthapThanYear2] = useState("");
  const [thapThanYear3, setthapThanYear3] = useState("");

  const [checkNapAm, setcheckNapAm] = useState(true);
  const [checkTruongSinh, setcheckTruongSinh] = useState(true);
  const [checkThanSat, setcheckThanSat] = useState(true);

  const [truongSinhHour, settruongSinhHour] = useState("");
  const [truongSinhDay, settruongSinhDay] = useState("");
  const [truongSinhMonth, settruongSinhMonth] = useState("");
  const [truongSinhYear, settruongSinhYear] = useState("");

  const [thanSatDiachiHours, setthanSatDiachiHours] = useState("");
  const [thanSatDiaChiDay, setthanSatDiaChiDay] = useState("");
  const [thanSatDiachiMonth, setthanSatDiachiMonth] = useState("");
  const [thanSatDiaChiYear, setthanSatDiaChiYear] = useState("");

  const [thanSatThienCanHour, setthanSatThienCanHour] = useState("");
  const [thanSatThienCanDay, setthanSatThienCanDay] = useState("");
  const [thanSatThienCanMonth, setthanSatThienCanMonth] = useState("");
  const [thanSatThienCanYear, setthanSatThienCanYear] = useState("");

  const [KhongVongHour, setKhongVongHour] = useState();
  const [KhongVongMonth, setKhongVongMonth] = useState();
  const [KhongVongYear, setKhongVongYear] = useState();

  const [khoiCuongDay, setKhoiCuongDay] = useState("");
  const [thienXa, setThienXa] = useState("");
  const [TSDiaChiArr, setTSDiaChiArr] = useState([]);
  const [TSThienCanArr, setTSThienCanArr] = useState([]);
  useEffect(() => {
    if (reload) {
      handleResult();
    }
  }, [reload]);

  useEffect(() => {
    loadExcel();
  }, []);

  const handlePrevHour = () => {
    let hours = +hour;
    let days = +day;
    let months = +month;
    let years = year;
    let dayInMonth;

    hours -= 2;

    if (hours < 0) {
      if (hours == 24) {
        hours = 0;
      }
      hours = 22;
      days -= 1;
      if (days == 0) {
        months -= 1;

        dayInMonth = moment(`${years}-${months + 1}`, "YYYY-MM").daysInMonth(); // 29
        days = dayInMonth;

        if (months == -1) {
          months = 11;
          years -= 1;
          dayInMonth = moment(
            `${years}-${months + 1}`,
            "YYYY-MM"
          ).daysInMonth(); // 29
          days = dayInMonth;
        }
      }
    }

    let dayz = moment(
      `${years}-${months + 1}-${days} ${hours}:${minute}`,
      "YYYY-MM-DD HH:mm"
    ); // 29

    dayz = dayz.format("D");

    setgioSolar(hours);
    setDayShow(dayz);
    setMonthShow(months);
    setYearShow(years);
    setMonth(months);
    setYear(years);

    setHour(hours);
    setDay(days);
    setReload(reload + 1);
  };
  const handleNextHour = async () => {
    //  await loadExcel();

    let hours = +hour;
    let days = +day;
    let months = +month;
    let years = year;

    let curent = moment(`${years}-${months}`, "YYYY-MM").daysInMonth(); // 29

    hours += 2;

    if (hours == 25) {
      hours = 1;
      days += 1;
      if (days > curent) {
        days = 1;
        months += 1;
        if (months > 11) {
          months = 0;
          years += 1;
        }
      }
    } else if (hours == 24) {
      hours = 0;
      days += 1;
      if (days > curent) {
        days = 1;
        months += 1;
        if (months > 11) {
          months = 0;
          years += 1;
        }
      }
    }
    setMonth(months);
    setYear(years);
    let dayz = moment(`${years}-${months}-${days}`, "YYYY-MM-DD"); // 29
    let montz = dayz.format("M");
    dayz = dayz.format("DD");

    setgioSolar(hours);
    setDayShow(dayz);
    setMonthShow(montz);
    setYearShow(years);

    setHour(hours);
    setDay(days);
    setReload(reload + 1);
  };

  const handlePrevDay = async () => {
    //  await loadExcel();

    let days = +day;
    let months = +month;
    let years = year;
    days = days - 1;

    if (days == 0) {
      months = months - 1;
      if (months == -1) {
        months = 11;
        years = years - 1;
      }

      let dayInMonth = moment(
        `${years}-${months + 1}`,
        "YYYY-MM"
      ).daysInMonth(); // 29

      days = dayInMonth;
    }

    let dayz = moment(`${year}-${months}-${days}`, "YYYY-MM-DD");
    dayz = dayz.format("D");

    setMonthShow(months);
    setDayShow(dayz);
    setMonth(months);
    setYearShow(years);

    setDay(days);
    setMonth(months);
    setYear(years);
    setReload(reload + 1);
  };

  const handleNextDay = async () => {
    //  await loadExcel();

    let days = +day;
    let months = +month;
    let years = year;

    let dayInMonth = moment(`${year}-${months + 1}`, "YYYY-MM").daysInMonth(); // 29

    days = days + 1;

    if (days > dayInMonth) {
      days = 1;
      months = months + 1;
      if (months == 12) {
        months = 0;
        years = years + 1;
      }
    }
    let dayz = moment(`${year}-${months + 1}-${days}`, "YYYY-MM-DD");
    dayz = dayz.format("D");

    setMonthShow(months);
    setDayShow(dayz);
    setMonth(months);
    setYearShow(years);

    setDay(days);
    setMonth(months);
    setYear(years);
    setReload(reload + 1);
  };
  const handlePrevYear = async () => {
    // await loadExcel();

    let years = +year;
    years = years - 1;

    setYearShow(years);

    setYear(years);
    setReload(reload + 1);
  };

  const handleNextYear = async () => {
    //  await loadExcel();

    let years = +year;
    years = years + 1;
    if (years > 2040) {
      return;
    }
    setYearShow(years);

    setYear(years);
    setReload(reload + 1);
  };
  const handlePrevMonth = async () => {
    //  await loadExcel();

    let months = +month;
    months = months - 1;

    let years = +year;

    if (months == -1) {
      months = 11;
      years = +year - 1;
      setYear(years);
      setYearShow(years);
    }
    let montz = moment(`${years}-${months}`, "YYYY-MM");
    montz = montz.format("M");
    setMonthShow(montz);

    setMonth(months);
    setReload(reload + 1);
  };

  const handleNextMonth = async () => {
    //  await loadExcel();

    let months = +month;
    months = months + 1;

    // months = months + 1;

    let years = +year;

    if (months == 12) {
      months = 0;
      years = +year + 1;
      setYear(years);
      setYearShow(years);
    }
    let montz = moment(`${years}-${months}`, "YYYY-MM");
    montz = montz.format("M");
    setMonthShow(montz);

    setMonth(months);
    setReload(reload + 1);
  };

  const onChangeHour = (value) => {
    setHour(value);
    if (value != "null") setCheckMinute(false);
    else setCheckMinute(true);
  };

  const handleSelect = (item) => {
    if (item === "Không chọn nơi sinh") {
      setCheckSelected(false);
    } else {
      const obj = city.filter((x) => x.name === item);
      setKinhDo(obj[0]?.kinhdo);
      setViDo(obj[0]?.vido);
      setNoiSinh(obj[0]?.name);
      setCheckSelected(true);
    }
  };

  const setAlerErrors = () => {
    setAlerError(true);
  };

  const namSinh = () => {
    const items = [];

    for (let i = 2040; i >= 0; --i) {
      if (i === 2023) items.push(<option selected>{i}</option>);
      else items.push(<option>{i}</option>);
    }

    return items;
  };

  async function loadExcel() {
    await fetch("hitek_lich_van_nien_update.xlsx")
      .then((response) => {
        return response.arrayBuffer();
      })
      .then((ab) => {
        const wb = XLSX.read(ab, { type: "array" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
        setExcelLichvanNien(data);
      })
      .catch((error) => {
        alert("Có lỗi xảy ra khi setup dữ liệu: " + error.toString());
        console.log(error);
      });
  }

  const getTietKhiAllYear = (timeYear) => {
    const year = timeYear.getFullYear();
    const distance = timeYear?.getFullYear() - 1800;
    let thangLe = distance * 42 + 120;
    let thangChan = distance * 42 + 122;

    if (year <= 1800 && year >= 1583) {
      let ngayGioDauThang1 = Const.tietKhi_1583_1800_thangLe[0]
        .split(" - ")[0]
        .split(" ");

      let ngayGioDauThang3 = Const.tietKhi_1583_1800_thangLe[1]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang5 = Const.tietKhi_1583_1800_thangLe[2]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang7 = Const.tietKhi_1583_1800_thangLe[3]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang9 = Const.tietKhi_1583_1800_thangLe[4]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang11 = Const.tietKhi_1583_1800_thangLe[5]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang2 = Const.tietKhi_1583_1800_thangChan[0]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang4 = Const.tietKhi_1583_1800_thangChan[1]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang6 = Const.tietKhi_1583_1800_thangChan[2]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang8 = Const.tietKhi_1583_1800_thangChan[3]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang10 = Const.tietKhi_1583_1800_thangChan[4]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang12 = Const.tietKhi_1583_1800_thangChan[5]
        .split(" - ")[0]
        .split(" ");

      const ngayDauThangMot = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang1[0].split("/")[1]) - 1,
        Number(ngayGioDauThang1[0].split("/")[0]),
        Number(ngayGioDauThang1[1].split(":")[0]),
        Number(ngayGioDauThang1[1].split(":")[1])
      );

      const ngayDauThangHai = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang2[0].split("/")[1]) - 1,
        Number(ngayGioDauThang2[0].split("/")[0]),
        Number(ngayGioDauThang2[1].split(":")[0]),
        Number(ngayGioDauThang2[1].split(":")[1])
      );
      const ngayDauThangBa = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang3[0].split("/")[1]) - 1,
        Number(ngayGioDauThang3[0].split("/")[0]),
        Number(ngayGioDauThang3[1].split(":")[0]),
        Number(ngayGioDauThang3[1].split(":")[1])
      );
      const ngayDauThangTu = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang4[0].split("/")[1]) - 1,
        Number(ngayGioDauThang4[0].split("/")[0]),
        Number(ngayGioDauThang4[1].split(":")[0]),
        Number(ngayGioDauThang4[1].split(":")[1])
      );
      const ngayDauThangNam = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang5[0].split("/")[1]) - 1,
        Number(ngayGioDauThang5[0].split("/")[0]),
        Number(ngayGioDauThang5[1].split(":")[0]),
        Number(ngayGioDauThang5[1].split(":")[1])
      );
      const ngayDauThangSau = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang6[0].split("/")[1]) - 1,
        Number(ngayGioDauThang6[0].split("/")[0]),
        Number(ngayGioDauThang6[1].split(":")[0]),
        Number(ngayGioDauThang6[1].split(":")[1])
      );
      const ngayDauThangBay = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang7[0].split("/")[1]) - 1,
        Number(ngayGioDauThang7[0].split("/")[0]),
        Number(ngayGioDauThang7[1].split(":")[0]),
        Number(ngayGioDauThang7[1].split(":")[1])
      );
      const ngayDauThangTam = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang8[0].split("/")[1]) - 1,
        Number(ngayGioDauThang8[0].split("/")[0]),
        Number(ngayGioDauThang8[1].split(":")[0]),
        Number(ngayGioDauThang8[1].split(":")[1])
      );
      const ngayDauThangChin = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang9[0].split("/")[1]) - 1,
        Number(ngayGioDauThang9[0].split("/")[0]),
        Number(ngayGioDauThang9[1].split(":")[0]),
        Number(ngayGioDauThang9[1].split(":")[1])
      );
      const ngayDauThangMuoi = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang10[0].split("/")[1]) - 1,
        Number(ngayGioDauThang10[0].split("/")[0]),
        Number(ngayGioDauThang10[1].split(":")[0]),
        Number(ngayGioDauThang10[1].split(":")[1])
      );
      const ngayDauThangMuoiMot = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang11[0].split("/")[1]) - 1,
        Number(ngayGioDauThang11[0].split("/")[0]),
        Number(ngayGioDauThang11[1].split(":")[0]),
        Number(ngayGioDauThang11[1].split(":")[1])
      );
      const ngayDauThangMuoiHai = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang12[0].split("/")[1]) - 1,
        Number(ngayGioDauThang12[0].split("/")[0]),
        Number(ngayGioDauThang12[1].split(":")[0]),
        Number(ngayGioDauThang12[1].split(":")[1])
      );
      let lichTietKhi = [];
      lichTietKhi.push(
        ngayDauThangMot,
        ngayDauThangHai,
        ngayDauThangBa,
        ngayDauThangTu,
        ngayDauThangNam,
        ngayDauThangSau,
        ngayDauThangBay,
        ngayDauThangTam,
        ngayDauThangChin,
        ngayDauThangMuoi,
        ngayDauThangMuoiMot,
        ngayDauThangMuoiHai
      );
      tietKhiDate = lichTietKhi;
    } else if (year >= 0 && year <= 1582) {
      let ngayGioDauThang1 = Const.tietKhi_0_1582_thangLe[0]
        .split(" - ")[0]
        .split(" ");

      let ngayGioDauThang3 = Const.tietKhi_0_1582_thangLe[1]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang5 = Const.tietKhi_0_1582_thangLe[2]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang7 = Const.tietKhi_0_1582_thangLe[3]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang9 = Const.tietKhi_0_1582_thangLe[4]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang11 = Const.tietKhi_0_1582_thangLe[5]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang2 = Const.tietKhi_0_1582_thangChan[0]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang4 = Const.tietKhi_0_1582_thangChan[1]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang6 = Const.tietKhi_0_1582_thangChan[2]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang8 = Const.tietKhi_0_1582_thangChan[3]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang10 = Const.tietKhi_0_1582_thangChan[4]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang12 = Const.tietKhi_0_1582_thangChan[5]
        .split(" - ")[0]
        .split(" ");

      const ngayDauThangMot = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang1[0].split("/")[1]) - 1,
        Number(ngayGioDauThang1[0].split("/")[0]),
        Number(ngayGioDauThang1[1].split(":")[0]),
        Number(ngayGioDauThang1[1].split(":")[1])
      );

      const ngayDauThangHai = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang2[0].split("/")[1]) - 1,
        Number(ngayGioDauThang2[0].split("/")[0]),
        Number(ngayGioDauThang2[1].split(":")[0]),
        Number(ngayGioDauThang2[1].split(":")[1])
      );
      const ngayDauThangBa = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang3[0].split("/")[1]) - 1,
        Number(ngayGioDauThang3[0].split("/")[0]),
        Number(ngayGioDauThang3[1].split(":")[0]),
        Number(ngayGioDauThang3[1].split(":")[1])
      );
      const ngayDauThangTu = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang4[0].split("/")[1]) - 1,
        Number(ngayGioDauThang4[0].split("/")[0]),
        Number(ngayGioDauThang4[1].split(":")[0]),
        Number(ngayGioDauThang4[1].split(":")[1])
      );
      const ngayDauThangNam = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang5[0].split("/")[1]) - 1,
        Number(ngayGioDauThang5[0].split("/")[0]),
        Number(ngayGioDauThang5[1].split(":")[0]),
        Number(ngayGioDauThang5[1].split(":")[1])
      );
      const ngayDauThangSau = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang6[0].split("/")[1]) - 1,
        Number(ngayGioDauThang6[0].split("/")[0]),
        Number(ngayGioDauThang6[1].split(":")[0]),
        Number(ngayGioDauThang6[1].split(":")[1])
      );
      const ngayDauThangBay = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang7[0].split("/")[1]) - 1,
        Number(ngayGioDauThang7[0].split("/")[0]),
        Number(ngayGioDauThang7[1].split(":")[0]),
        Number(ngayGioDauThang7[1].split(":")[1])
      );
      const ngayDauThangTam = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang8[0].split("/")[1]) - 1,
        Number(ngayGioDauThang8[0].split("/")[0]),
        Number(ngayGioDauThang8[1].split(":")[0]),
        Number(ngayGioDauThang8[1].split(":")[1])
      );
      const ngayDauThangChin = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang9[0].split("/")[1]) - 1,
        Number(ngayGioDauThang9[0].split("/")[0]),
        Number(ngayGioDauThang9[1].split(":")[0]),
        Number(ngayGioDauThang9[1].split(":")[1])
      );
      const ngayDauThangMuoi = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang10[0].split("/")[1]) - 1,
        Number(ngayGioDauThang10[0].split("/")[0]),
        Number(ngayGioDauThang10[1].split(":")[0]),
        Number(ngayGioDauThang10[1].split(":")[1])
      );
      const ngayDauThangMuoiMot = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang11[0].split("/")[1]) - 1,
        Number(ngayGioDauThang11[0].split("/")[0]),
        Number(ngayGioDauThang11[1].split(":")[0]),
        Number(ngayGioDauThang11[1].split(":")[1])
      );
      const ngayDauThangMuoiHai = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang12[0].split("/")[1]) - 1,
        Number(ngayGioDauThang12[0].split("/")[0]),
        Number(ngayGioDauThang12[1].split(":")[0]),
        Number(ngayGioDauThang12[1].split(":")[1])
      );
      let lichTietKhi = [];
      lichTietKhi.push(
        ngayDauThangMot,
        ngayDauThangHai,
        ngayDauThangBa,
        ngayDauThangTu,
        ngayDauThangNam,
        ngayDauThangSau,
        ngayDauThangBay,
        ngayDauThangTam,
        ngayDauThangChin,
        ngayDauThangMuoi,
        ngayDauThangMuoiMot,
        ngayDauThangMuoiHai
      );
      tietKhiDate = lichTietKhi;
    } else {
      let ngayGioDauThang1 = excelLichvanNien[thangLe][2]
        .split(" - ")[0]
        .split(" ");

      let ngayGioDauThang3 = excelLichvanNien[thangLe][6]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang5 = excelLichvanNien[thangLe][10]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang7 = excelLichvanNien[thangLe][14]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang9 = excelLichvanNien[thangLe][18]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang11 = excelLichvanNien[thangLe][22]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang2 = excelLichvanNien[thangChan][2]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang4 = excelLichvanNien[thangChan][6]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang6 = excelLichvanNien[thangChan][10]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang8 = excelLichvanNien[thangChan][14]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang10 = excelLichvanNien[thangChan][18]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang12 = excelLichvanNien[thangChan][22]
        .split(" - ")[0]
        .split(" ");

      const ngayDauThangMot = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang1[0].split("/")[1]) - 1,
        Number(ngayGioDauThang1[0].split("/")[0]),
        Number(ngayGioDauThang1[1].split(":")[0]),
        Number(ngayGioDauThang1[1].split(":")[1])
      );

      const ngayDauThangHai = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang2[0].split("/")[1]) - 1,
        Number(ngayGioDauThang2[0].split("/")[0]),
        Number(ngayGioDauThang2[1].split(":")[0]),
        Number(ngayGioDauThang2[1].split(":")[1])
      );
      const ngayDauThangBa = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang3[0].split("/")[1]) - 1,
        Number(ngayGioDauThang3[0].split("/")[0]),
        Number(ngayGioDauThang3[1].split(":")[0]),
        Number(ngayGioDauThang3[1].split(":")[1])
      );
      const ngayDauThangTu = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang4[0].split("/")[1]) - 1,
        Number(ngayGioDauThang4[0].split("/")[0]),
        Number(ngayGioDauThang4[1].split(":")[0]),
        Number(ngayGioDauThang4[1].split(":")[1])
      );
      const ngayDauThangNam = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang5[0].split("/")[1]) - 1,
        Number(ngayGioDauThang5[0].split("/")[0]),
        Number(ngayGioDauThang5[1].split(":")[0]),
        Number(ngayGioDauThang5[1].split(":")[1])
      );
      const ngayDauThangSau = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang6[0].split("/")[1]) - 1,
        Number(ngayGioDauThang6[0].split("/")[0]),
        Number(ngayGioDauThang6[1].split(":")[0]),
        Number(ngayGioDauThang6[1].split(":")[1])
      );
      const ngayDauThangBay = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang7[0].split("/")[1]) - 1,
        Number(ngayGioDauThang7[0].split("/")[0]),
        Number(ngayGioDauThang7[1].split(":")[0]),
        Number(ngayGioDauThang7[1].split(":")[1])
      );
      const ngayDauThangTam = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang8[0].split("/")[1]) - 1,
        Number(ngayGioDauThang8[0].split("/")[0]),
        Number(ngayGioDauThang8[1].split(":")[0]),
        Number(ngayGioDauThang8[1].split(":")[1])
      );
      const ngayDauThangChin = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang9[0].split("/")[1]) - 1,
        Number(ngayGioDauThang9[0].split("/")[0]),
        Number(ngayGioDauThang9[1].split(":")[0]),
        Number(ngayGioDauThang9[1].split(":")[1])
      );
      const ngayDauThangMuoi = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang10[0].split("/")[1]) - 1,
        Number(ngayGioDauThang10[0].split("/")[0]),
        Number(ngayGioDauThang10[1].split(":")[0]),
        Number(ngayGioDauThang10[1].split(":")[1])
      );
      const ngayDauThangMuoiMot = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang11[0].split("/")[1]) - 1,
        Number(ngayGioDauThang11[0].split("/")[0]),
        Number(ngayGioDauThang11[1].split(":")[0]),
        Number(ngayGioDauThang11[1].split(":")[1])
      );
      const ngayDauThangMuoiHai = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang12[0].split("/")[1]) - 1,
        Number(ngayGioDauThang12[0].split("/")[0]),
        Number(ngayGioDauThang12[1].split(":")[0]),
        Number(ngayGioDauThang12[1].split(":")[1])
      );
      let lichTietKhi = [];
      lichTietKhi.push(
        ngayDauThangMot,
        ngayDauThangHai,
        ngayDauThangBa,
        ngayDauThangTu,
        ngayDauThangNam,
        ngayDauThangSau,
        ngayDauThangBay,
        ngayDauThangTam,
        ngayDauThangChin,
        ngayDauThangMuoi,
        ngayDauThangMuoiMot,
        ngayDauThangMuoiHai
      );
      tietKhiDate = lichTietKhi;
    }
  };

  const get24TietKhiOfYear = (tempTime) => {
    const tempDateTime = new Date(tempTime);

    const year = tempDateTime.getFullYear();

    const distance = tempDateTime?.getFullYear() - 1800;

    const dongNgayDauThangLe = distance * 42 + 120;

    const dongNgayCuoiThangLe = distance * 42 + 121;

    const dongNgayDauThangChan = distance * 42 + 122;

    const dongNgayCuoiThangChan = distance * 42 + 123;

    if (year <= 1800 && year >= 1583) {
      const cuoiThangLe = Const.tietKhi_1583_1800_cuoiThangLe;

      const dauThangChan = Const.tietKhi_1583_1800_thangChan;

      const dauThangLe = Const.tietKhi_1583_1800_thangLe;

      const cuoiThangChan = Const.tietKhi_1583_1800_cuoiThangChan;

      tietKhiInYear = [
        dauThangLe[0]?.split(" - "),
        cuoiThangLe[0]?.split(" - "),
        dauThangChan[0]?.split(" - "),
        cuoiThangChan[0]?.split(" - "),
        dauThangLe[1]?.split(" - "),
        cuoiThangLe[1]?.split(" - "),
        dauThangChan[1]?.split(" - "),
        cuoiThangChan[1]?.split(" - "),
        dauThangLe[2]?.split(" - "),
        cuoiThangLe[2]?.split(" - "),
        dauThangChan[2]?.split(" - "),
        cuoiThangChan[2]?.split(" - "),
        dauThangLe[3]?.split(" - "),
        cuoiThangLe[3]?.split(" - "),
        dauThangChan[3]?.split(" - "),
        cuoiThangChan[3]?.split(" - "),
        dauThangLe[4]?.split(" - "),
        cuoiThangLe[4]?.split(" - "),
        dauThangChan[4]?.split(" - "),
        cuoiThangChan[4]?.split(" - "),
        dauThangLe[5]?.split(" - "),
        cuoiThangLe[5]?.split(" - "),
        dauThangChan[5]?.split(" - "),
        cuoiThangChan[5]?.split(" - "),
      ];
    } else if (year >= 0 && year <= 1582) {
      const cuoiThangLe = Const.tietKhi_0_1582_cuoiThangLe;

      const dauThangChan = Const.tietKhi_0_1582_thangChan;

      const dauThangLe = Const.tietKhi_0_1582_thangLe;

      const cuoiThangChan = Const.tietKhi_0_1582_cuoiThangChan;

      tietKhiInYear = [
        dauThangLe[0]?.split(" - "),
        cuoiThangLe[0]?.split(" - "),
        dauThangChan[0]?.split(" - "),
        cuoiThangChan[0]?.split(" - "),
        dauThangLe[1]?.split(" - "),
        cuoiThangLe[1]?.split(" - "),
        dauThangChan[1]?.split(" - "),
        cuoiThangChan[1]?.split(" - "),
        dauThangLe[2]?.split(" - "),
        cuoiThangLe[2]?.split(" - "),
        dauThangChan[2]?.split(" - "),
        cuoiThangChan[2]?.split(" - "),
        dauThangLe[3]?.split(" - "),
        cuoiThangLe[3]?.split(" - "),
        dauThangChan[3]?.split(" - "),
        cuoiThangChan[3]?.split(" - "),
        dauThangLe[4]?.split(" - "),
        cuoiThangLe[4]?.split(" - "),
        dauThangChan[4]?.split(" - "),
        cuoiThangChan[4]?.split(" - "),
        dauThangLe[5]?.split(" - "),
        cuoiThangLe[5]?.split(" - "),
        dauThangChan[5]?.split(" - "),
        cuoiThangChan[5]?.split(" - "),
      ];
    } else {
      const cuoiThangLe = excelLichvanNien[dongNgayCuoiThangLe];

      const dauThangChan = excelLichvanNien[dongNgayDauThangChan];

      const dauThangLe = excelLichvanNien[dongNgayDauThangLe];

      const cuoiThangChan = excelLichvanNien[dongNgayCuoiThangChan];

      tietKhiInYear = [
        dauThangLe[1]?.split(" - "),
        cuoiThangLe[1]?.split(" - "),
        dauThangChan[1]?.split(" - "),
        cuoiThangChan[1]?.split(" - "),
        dauThangLe[5]?.split(" - "),
        cuoiThangLe[5]?.split(" - "),
        dauThangChan[5]?.split(" - "),
        cuoiThangChan[5]?.split(" - "),
        dauThangLe[9]?.split(" - "),
        cuoiThangLe[9]?.split(" - "),
        dauThangChan[9]?.split(" - "),
        cuoiThangChan[9]?.split(" - "),
        dauThangLe[13]?.split(" - "),
        cuoiThangLe[13]?.split(" - "),
        dauThangChan[13]?.split(" - "),
        cuoiThangChan[13]?.split(" - "),
        dauThangLe[17]?.split(" - "),
        cuoiThangLe[17]?.split(" - "),
        dauThangChan[17]?.split(" - "),
        cuoiThangChan[17]?.split(" - "),
        dauThangLe[21]?.split(" - "),
        cuoiThangLe[21]?.split(" - "),
        dauThangChan[21]?.split(" - "),
        cuoiThangChan[21]?.split(" - "),
      ];
    }
  };

  const getCorrecTietKhi = (tempTime) => {
    let day = tempTime.getDate();
    let month = tempTime.getMonth();
    let year =
      tempTime < tietKhiDate[1]
        ? tempTime.getFullYear() - 1
        : tempTime.getFullYear();

    switch (tempTime.getMonth()) {
      // tháng 1 dương lịch nếu sau ngày đầu tháng 1 thì là tháng 12 năm ngoái
      //input ngày 1 tháng 1 2020
      // out put ngày 1 tháng 12 2019
      case 0:
        if (
          tempTime.getTime() === tietKhiDate[0].getTime() &&
          month === tietKhiDate[0].getMonth()
        ) {
          month = month;
        } else if (tempTime < tietKhiDate[0]) {
          month = 11;
        }
        break;
      case 1:
        // trước ngày lập xuân thì là tháng 12 của năm ngoái, ngược lại là tháng 1 năm sau
        if (
          tempTime.getTime() === tietKhiDate[1].getTime() &&
          month === tietKhiDate[1].getMonth()
        ) {
          month = month;
        } else if (tempTime < tietKhiDate[1]) {
          month = month - 1;
        }
        break;
      case 2:
        if (
          tempTime.getTime() === tietKhiDate[2].getTime() &&
          month === tietKhiDate[2].getMonth()
        ) {
          month = month;
        } else if (tempTime < tietKhiDate[2]) {
          month = month - 1;
        }
        break;
      case 3:
        if (
          tempTime.getTime() === tietKhiDate[3].getTime() &&
          month === tietKhiDate[3].getMonth()
        ) {
          month = month;
        } else if (tempTime < tietKhiDate[3]) {
          month = month - 1;
        }
        break;
      case 4:
        if (
          tempTime.getTime() === tietKhiDate[4].getTime() &&
          month === tietKhiDate[4].getMonth()
        ) {
          month = month;
        } else if (tempTime < tietKhiDate[4]) {
          month = month - 1;
        }
        break;
      case 5:
        if (
          tempTime.getTime() === tietKhiDate[5].getTime() &&
          month === tietKhiDate[5].getMonth()
        ) {
          month = month;
        } else if (tempTime < tietKhiDate[5]) {
          month = month - 1;
        }
        break;
      case 6:
        if (
          tempTime.getTime() === tietKhiDate[6].getTime() &&
          month === tietKhiDate[6].getMonth()
        ) {
          month = month;
        } else if (tempTime < tietKhiDate[6]) {
          month = month - 1;
        }
        break;
      case 7:
        if (
          tempTime.getTime() === tietKhiDate[7].getTime() &&
          month === tietKhiDate[7].getMonth()
        ) {
          month = month;
        } else if (tempTime < tietKhiDate[7]) {
          month = month - 1;
        }
        break;
      case 8:
        if (
          tempTime.getTime() === tietKhiDate[8].getTime() &&
          month === tietKhiDate[8].getMonth()
        ) {
          month = month;
        } else if (tempTime < tietKhiDate[8]) {
          month = month - 1;
        }
        break;
      case 9:
        if (
          tempTime.getTime() === tietKhiDate[9].getTime() &&
          month === tietKhiDate[9].getMonth()
        ) {
          month = month;
        } else if (tempTime < tietKhiDate[9]) {
          month = month - 1;
        }
        break;
      case 10:
        if (
          tempTime.getTime() === tietKhiDate[10].getTime() &&
          month === tietKhiDate[10].getMonth()
        ) {
          month = month;
        } else if (tempTime < tietKhiDate[10]) {
          month = month - 1;
        }
        break;
      case 11:
        if (
          tempTime.getTime() === tietKhiDate[11].getTime() &&
          month === tietKhiDate[11].getMonth()
        ) {
          month = month;
        } else if (tempTime < tietKhiDate[11]) {
          month = month - 1;
        }
        break;
      default:
        break;
    }
    return new Date(year, month, day);
  };

  const handleResult = async () => {
    try {
      if (
        !JSON.parse(localStorage.getItem("profile"))?.isActive ||
        JSON.parse(localStorage.getItem("profile"))?.isActive
      ) {
        let tempTime;

        if (hour != "null") {
          tempTime = new Date(
            year + "",
            month + "",
            day + "",
            hour + "",
            minute + "",
            "00"
          );
          setCheckHour(false);
        } else {
          tempTime = new Date(
            year + "",
            month + "",
            day + "",
            0 + "",
            minute + "",
            "00"
          );

          setCheckHour(true);
        }

        if (year >= 0 && year <= 100) {
          tempTime.setFullYear(+year + 1000, month, day, hour, minute);
        }

        if (excelLichvanNien) {
          getTietKhiAllYear(tempTime);
          get24TietKhiOfYear(tempTime);

          const dateTietKhi = getCorrecTietKhi(tempTime);
          const calendar_ = calendar.solar2lunar(+year, +month + 1, +day);

          // const yearExcel = getYearExcel(dateTietKhi);
          const canYear = YearToCan(dateTietKhi.getFullYear()); //

          const chiYear = YearToChi(dateTietKhi.getFullYear());
          const canChiDay = DayToCanChi(tempTime.toDateString());
          const canChiHour = DayToCanChi(tempTime);
          const canChiMonth = MonthToCanChi(
            canYear?.name,
            dateTietKhi.getMonth()
          ); //

          let chiHour = null;
          let canDay = null;
          let queNgay = null;
          let chiNgay = "";
          let chiMonthLunar = "";
          let suntime;

          if (checkSelected) {
            console.log("vao day1");
            let times = SunCalc.getTimes(tempTime, viDo, kinhDo); // tg mat troi`
            suntime = solarTime(times.solarNoon, tempTime);

            if (suntime.hour >= 0 && suntime.hour <= 9) {
              let solargio = `0${suntime.hour}`;
              setgioSolar(solargio);
            } else {
              setgioSolar(suntime.hour);
            }

            if (suntime.minutes >= 0 && suntime.minutes <= 9) {
              let solarphut = `0${suntime.minutes}`;
              setSolarphut(solarphut);
            } else {
              setSolarphut(suntime.minutes);
            }
          } else {
            if (hour != "null") setgioSolar(hour);
            else setgioSolar("00");
            setSolarphut(minute);
          }

          const initData = dateData.map((item) => {
            if (item.header === "Năm") {
              item.canChi = canYear?.name;
              item.diaChi = chiYear?.name;

              item.que = getQue(canYear?.name, chiYear?.name);
            } else if (item.header === "Tháng") {
              //tách chuỗi name trong canChiMonth
              let tempCan = canChiMonth.name.split(" ");

              item.canChi = tempCan[0];
              item.diaChi = tempCan[1];
              item.qua = getQue(tempCan[0], tempCan[1]).tenque;
              item.que = getQue(tempCan[0], tempCan[1]);
              chiMonthLunar = tempCan[1];
            } else if (item.header === "Ngày") {
              let tempCanDay = canChiDay.name.split(" ");
              item.canChi = tempCanDay[0];
              item.diaChi = tempCanDay[1];
              // let chiDate = Const.ChiArr.find((v) => v.name === tempCanDay[1]);
              // let canDate = Const.CanArr.find((v) => v.name === tempCanDay[0]);

              item.qua = getQue(tempCanDay[0], tempCanDay[1]);
              item.que = getQue(tempCanDay[0], tempCanDay[1]);
              queNgay = item.que;
              chiNgay = tempCanDay[1];
            } else if (item.header === "Giờ") {
              let tempCanDay = canChiDay.name.split(" ");
              let tempCanChiHour;
              if (checkSelected) {
                tempCanChiHour = HourToCanChi(
                  tempCanDay[0],
                  suntime.hour
                ).name.split(" ");
              } else {
                tempCanChiHour = HourToCanChi(
                  tempCanDay[0],
                  tempTime.getHours()
                ).name.split(" ");
              }

              item.canChi = tempCanChiHour[0];
              item.diaChi = tempCanChiHour[1];
              item.qua = getQue(tempCanChiHour[0], tempCanChiHour[1]).tenque;
              item.que = getQue(tempCanChiHour[0], tempCanChiHour[1]);
              chiHour = tempCanChiHour;
              canDay = tempCanDay[0];
            }

            return item;
          });

          setDateData(initData); // data
          const tieuNhiMonth = convertTieuNhiMonth(
            dateData[0]?.diaChi,
            calendar_?.lMonth
          );

          const tieuNhiYear = convertTieuNhiYear(
            dateData[0]?.diaChi,
            dateData[3]?.diaChi,
            dateData[3]?.canChi
          );
          const tieuNhiTuTru = convertTuTru(
            dateData[0]?.diaChi,
            dateData[3]?.diaChi,
            calendar_?.lMonth
          );

          setMonthTieuNhi(tieuNhiMonth);
          setYearTieuNhi(tieuNhiYear);
          serTuTru(tieuNhiTuTru);
          // can ngày
          setCanDay(dateData[1]?.canChi);

          // chi ngày
          setChiDay(dateData[1]?.diaChi);

          // chi tháng
          setChiMonth(dateData[2]?.diaChi);
          setCanMonth(dateData[2]?.canChi);
          // can năm
          // chi nam
          setChiYear(dateData[3]?.diaChi);

          // chi giờ
          setChiHour(dateData[0]?.diaChi);
          setCanHour(dateData[0]?.canChi);

          setThienCanYear(canYear?.name);
          setDiachiYear(chiYear?.name);

          const khongVongH = KhongVong2(
            dateData[1].canChi + " " + dateData[1].diaChi,
            dateData[0].diaChi
          );
          const khongVongM = KhongVong2(
            dateData[1].canChi + " " + dateData[1].diaChi,
            dateData[2].diaChi
          );
          const khongVongY = KhongVong2(
            dateData[1].canChi + " " + dateData[1].diaChi,
            dateData[3].diaChi
          );

          setKhongVongHour(khongVongH);
          setKhongVongMonth(khongVongM);
          setKhongVongYear(khongVongY);

          setThienCanHour(dateData[0].canChi);
          setdiaChiHour(dateData[0].diaChi); // dia chi gio
          setnapAmHour(dateData[0].que.napAm);

          setthienCanDay(dateData[1].canChi); //thien can ngay
          setdiaChiDay(dateData[1].diaChi); // dia chi ngay
          setnapAmDay(dateData[1].que.napAm);

          const tsTCArr = ThanSatThienCanArr(dateData[1].canChi);
          setTSThienCanArr(tsTCArr);

          const nguHanhTCHour = nguHanh(dateData[1].canChi, dateData[0].canChi);

          const nguHanhTCDay = nguHanh(dateData[1].canChi, dateData[1].canChi);
          const nguHanhTCMonth = nguHanh(
            dateData[1].canChi,
            dateData[2].canChi
          );
          const nguHanhTCYear = nguHanh(dateData[1].canChi, dateData[3].canChi);
          const nguHanhTieuNhi = checkNguHanh(
            dateData[0]?.canChi,
            dateData[1]?.canChi,
            dateData[2].canChi,
            dateData[3]?.canChi,
            dateData[0].diaChi,
            dateData[1]?.diaChi,
            dateData[2]?.diaChi,
            dateData[3]?.diaChi
          );

          setNguHanhTieuNhi(nguHanhTieuNhi);
          setnguHanhHour(nguHanhTCHour);
          setnguHanhDay(nguHanhTCDay);
          setnguHanhMonth(nguHanhTCMonth);
          setnguHanhYear(nguHanhTCYear);

          const thapThanHourResult = ThapThan(
            dateData[1]?.canChi,
            dateData[0]?.canChi
          );
          const thapThanMonthResult = ThapThan(
            dateData[1]?.canChi,
            dateData[2]?.canChi
          );
          const thapThanYearResult = ThapThan(
            dateData[1]?.canChi,
            dateData[3]?.canChi
          );

          const resultTruongSinhHour = TruongSinh(
            dateData[1].canChi,
            dateData[0].diaChi
          );
          const resultTruongSinhDay = TruongSinh(
            dateData[1].canChi,
            dateData[1].diaChi
          );
          const resultTruongSinhMonth = TruongSinh(
            dateData[1].canChi,
            dateData[2].diaChi
          );
          const resultTruongSinhYear = TruongSinh(
            dateData[1].canChi,
            chiYear.name
          );

          const TSArr = ThanSatDiaChiArr(dateData[1].diaChi);

          setTSDiaChiArr(TSArr);

          settruongSinhHour(resultTruongSinhHour);
          settruongSinhDay(resultTruongSinhDay);
          settruongSinhMonth(resultTruongSinhMonth);
          settruongSinhYear(resultTruongSinhYear);

          const thanSatHourArr = ThanSatDiaChi(
            dateData[1].diaChi,
            dateData[0].diaChi
          );
          const thanSatDayArr = ThanSatDiaChi(
            dateData[1].diaChi,
            dateData[1].diaChi
          );
          const thanSatMonthArr = ThanSatDiaChi(
            dateData[1].diaChi,
            dateData[2].diaChi
          );
          const thanSatYearArr = ThanSatDiaChi(
            dateData[1].diaChi,
            chiYear.name
          );

          setthanSatDiachiHours(thanSatHourArr);
          setthanSatDiaChiDay(thanSatDayArr);
          setthanSatDiachiMonth(thanSatMonthArr);
          setthanSatDiaChiYear(thanSatYearArr);

          const thanSatHourCan = ThanSatThienCan(
            dateData[1].canChi,
            dateData[0].canChi
          );
          const thanSatDayCan = ThanSatThienCan(
            dateData[1].canChi,
            dateData[1].canChi
          );
          const thanSatMonthCan = ThanSatThienCan(
            dateData[1].canChi,
            dateData[2].canChi
          );
          const thanSatYearCan = ThanSatThienCan(dateData[1].canChi, canYear);

          setthanSatThienCanHour(thanSatHourCan);
          setthanSatThienCanDay(thanSatDayCan);
          setthanSatThienCanMonth(thanSatMonthCan);
          setthanSatThienCanYear(thanSatYearCan);

          setthapThanHour(thapThanHourResult?.sinhKhac);
          setthapThanMonth(thapThanMonthResult?.sinhKhac);
          setthapThanYear(thapThanYearResult?.sinhKhac);

          setthienCanMonth(dateData[2].canChi);
          setdiaChiMonth(dateData[2].diaChi);
          setnapAmMonth(dateData[2].que.napAm);

          setnapAmYear(dateData[3].que.napAm);

          const TangCanHourArr = TangCan(dateData[0]?.diaChi);
          const TangCanDayArr = TangCan(dateData[1]?.diaChi);
          const TangCanMonthArr = TangCan(dateData[2]?.diaChi);

          const TangCanYearArr = TangCan(dateData[3]?.diaChi);

          const thapThanHour1 = ThapThan(
            dateData[1].canChi,
            TangCanHourArr[0]?.name
          );
          const thapThanHour2 = ThapThan(
            dateData[1].canChi,
            TangCanHourArr[1]?.name
          );
          const thapThanHour3 = ThapThan(
            dateData[1].canChi,
            TangCanHourArr[2]?.name
          );

          const thapThanDay1 = ThapThan(
            dateData[1].canChi,
            TangCanDayArr[0]?.name
          );
          const thapThanDay2 = ThapThan(
            dateData[1].canChi,
            TangCanDayArr[1]?.name
          );
          const thapThanDay3 = ThapThan(
            dateData[1].canChi,
            TangCanDayArr[2]?.name
          );

          const thapThanMonth1 = ThapThan(
            dateData[1].canChi,
            TangCanMonthArr[0]?.name
          );
          const thapThanMonth2 = ThapThan(
            dateData[1].canChi,
            TangCanMonthArr[1]?.name
          );
          const thapThanMonth3 = ThapThan(
            dateData[1].canChi,
            TangCanMonthArr[2]?.name
          );

          const thapThanYear1 = ThapThan(
            dateData[1].canChi,
            TangCanYearArr[0]?.name
          );
          const thapThanYear2 = ThapThan(
            dateData[1].canChi,
            TangCanYearArr[1]?.name
          );
          const thapThanYear3 = ThapThan(
            dateData[1].canChi,
            TangCanYearArr[2]?.name
          );

          setthapThanHour1(thapThanHour1?.sinhKhac);
          setthapThanHour2(thapThanHour2?.sinhKhac);
          setthapThanHour3(thapThanHour3?.sinhKhac);

          setthapThanDay1(thapThanDay1?.sinhKhac);
          setthapThanDay2(thapThanDay2?.sinhKhac);
          setthapThanDay3(thapThanDay3?.sinhKhac);

          setthapThanMonth1(thapThanMonth1?.sinhKhac);
          setthapThanMonth2(thapThanMonth2?.sinhKhac);
          setthapThanMonth3(thapThanMonth3?.sinhKhac);

          setthapThanYear1(thapThanYear1?.sinhKhac);
          setthapThanYear2(thapThanYear2?.sinhKhac);
          setthapThanYear3(thapThanYear3?.sinhKhac);

          settangCanNguHanhHour(TangCanHourArr[0]?.name);
          settangCanNguHanHourHour4(TangCanHourArr[0]?.nguHanh);
          settangCanNguHanHourHour2(TangCanHourArr[1]?.name);
          settangCanNguHanHourHour5(TangCanHourArr[1]?.nguHanh);
          settangCanNguHanHourHour3(TangCanHourArr[2]?.name);
          settangCanNguHanHourHour6(TangCanHourArr[2]?.nguHanh);

          settangCanNguHanhDay(TangCanDayArr[0]?.name);
          settangCanNguHanhDay4(TangCanDayArr[0]?.nguHanh);
          settangCanNguHanhDay2(TangCanDayArr[1]?.name);
          settangCanNguHanhDay5(TangCanDayArr[1]?.nguHanh);
          settangCanNguHanhDay3(TangCanDayArr[2]?.name);
          settangCanNguHanhDay6(TangCanDayArr[2]?.nguHanh);

          settangCanMonth(TangCanMonthArr[0]?.name);
          settangCanMonth4(TangCanMonthArr[0]?.nguHanh);
          settangCanMonth2(TangCanMonthArr[1]?.name);
          settangCanMonth5(TangCanMonthArr[1]?.nguHanh);
          settangCanMonth3(TangCanMonthArr[2]?.name);
          settangCanMonth6(TangCanMonthArr[2]?.nguHanh);

          settangCanYear(TangCanYearArr[0]?.name);
          settangCanYear4(TangCanYearArr[0]?.nguHanh);
          settangCanYear2(TangCanYearArr[1]?.name);
          settangCanYear5(TangCanYearArr[1]?.nguHanh);
          settangCanYear3(TangCanYearArr[2]?.name);
          settangCanYear6(TangCanYearArr[2]?.nguHanh);
          setShow(true);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      let timeShow = moment(`${+year}-${+month + 1}-${+day}`, "YYYY-MM-DD"); // 29
      let months = timeShow.format("MM");
      let days = timeShow.format("DD");

      setDayShow(days);
      setMonthShow(months);
      setYearShow(year);
    }
  };

  useEffect(() => {
    loadExcel();
  }, []);

  return (
    <div>
      <div className="input__group hide-to-print">
        <div className="flexx__">
          <InputGroup>
            {" "}
            <Label style={{ width: "100px" }}>Họ và tên: </Label>{" "}
            <Input onChange={(e) => setName(e.target.value)} />
          </InputGroup>

          <InputGroup style={{ maxWidth: "20%", paddingLeft: "35px" }}>
            {" "}
            <Label style={{ width: "85px" }}>Giới tính: </Label>{" "}
            <Input
              onChange={(e) => setGender(e.target.value)}
              style={{ maxWidth: "100px" }}
              type="select"
            >
              {" "}
              <option>Nam</option>
              <option>Nữ</option>
            </Input>
          </InputGroup>

          <InputGroup>
            <Label style={{ width: "85px" }}>Giờ sinh: </Label>{" "}
            <Input
              className={""}
              onChange={(e) => {
                onChangeHour(e.target.value);
              }}
              style={{ maxWidth: "100px", marginRight: "10px" }}
              type="select"
            >
              {/* <option value={"null"}>Không chọn giờ sinh</option> */}
              <option>00</option>
              <option>01</option>
              <option>02</option>
              <option>03</option>
              <option>04</option>
              <option>05</option>
              <option>06</option>
              <option>07</option>
              <option>08</option>
              <option>09</option>
              <option>10</option>
              <option>11</option>
              <option>12</option>
              <option>13</option>
              <option>14</option>
              <option>15</option>
              <option>16</option>
              <option>17</option>
              <option>18</option>
              <option>19</option>
              <option>20</option>
              <option>21</option>
              <option>22</option>
              <option>23</option>
            </Input>
            <Input
              onChange={(e) => setminute(e.target.value)}
              style={{ maxWidth: "100px", marginLeft: "10px" }}
              type="select"
            >
              <option>00</option>
              <option>01</option>
              <option>02</option>
              <option>03</option>
              <option>04</option>
              <option>05</option>
              <option>06</option>
              <option>07</option>
              <option>08</option>
              <option>09</option>
              <option>10</option>
              <option>11</option>
              <option>12</option>
              <option>13</option>
              <option>14</option>
              <option>15</option>
              <option>16</option>
              <option>17</option>
              <option>18</option>
              <option>19</option>
              <option>20</option>
              <option>21</option>
              <option>22</option>
              <option>23</option>
              <option>24</option>
              <option>25</option>
              <option>26</option>
              <option>27</option>
              <option>28</option>
              <option>29</option>
              <option>30</option>
              <option>31</option>
              <option>32</option>
              <option>33</option>
              <option>34</option>
              <option>35</option>
              <option>36</option>
              <option>37</option>
              <option>38</option>
              <option>39</option>
              <option>40</option>
              <option>41</option>
              <option>42</option>
              <option>43</option>
              <option>44</option>
              <option>45</option>
              <option>46</option>
              <option>47</option>
              <option>48</option>
              <option>49</option>
              <option>50</option>
              <option>51</option>
              <option>52</option>
              <option>53</option>
              <option>54</option>
              <option>55</option>
              <option>56</option>
              <option>57</option>
              <option>58</option>
              <option>59</option>
            </Input>
          </InputGroup>
        </div>

        <div className="flexx__">
          <InputGroup style={{ marginTop: "25px", marginLeft: "1px" }}>
            <Label style={{ width: "100px" }}>Ngày sinh: </Label>
            <Input
              onChange={(e) => setDay(e.target.value)}
              style={{ maxWidth: "100px" }}
              type="select"
            >
              <option>01</option>
              <option>02</option>
              <option>03</option>
              <option>04</option>
              <option>05</option>
              <option>06</option>
              <option>07</option>
              <option>08</option>
              <option>09</option>
              <option>10</option>
              <option>11</option>
              <option>12</option>
              <option>13</option>
              <option>14</option>
              <option>15</option>
              <option>16</option>
              <option>17</option>
              <option>18</option>
              <option>19</option>
              <option>20</option>
              <option>21</option>
              <option>22</option>
              <option>23</option>
              <option>24</option>
              <option>25</option>
              <option>26</option>
              <option>27</option>
              <option>28</option>
              <option>29</option>
              <option>30</option>
              <option>31</option>
            </Input>
          </InputGroup>

          <InputGroup
            style={{ maxWidth: "20%", marginTop: "25px", paddingLeft: "35px" }}
          >
            <Label style={{ paddingRight: "35px", width: "85px" }}>
              Tháng:{" "}
            </Label>
            <Input
              onChange={(e) => setMonth(e.target.value)}
              style={{ maxWidth: "100px" }}
              type="select"
            >
              <option value={0}>01</option>
              <option value={1}>02</option>
              <option value={2}>03</option>
              <option value={3}>04</option>
              <option value={4}>05</option>
              <option value={5}>06</option>
              <option value={6}>07</option>
              <option value={7}>08</option>
              <option value={8}>09</option>
              <option value={9}>10</option>
              <option value={10}>11</option>
              <option value={11}>12</option>
            </Input>
          </InputGroup>

          <InputGroup style={{ maxWidth: "30%", marginTop: "25px" }}>
            <Label style={{ width: "85px" }}>Năm: </Label>
            <Input
              onChange={(e) => setYear(e.target.value)}
              style={{ maxWidth: "100px" }}
              type="select"
            >
              {namSinh()}
            </Input>
          </InputGroup>
        </div>
        <div
          className="flexx__"
          style={{ justifyContent: "flex-start", marginLeft: "148px" }}
        >
          <InputGroup style={{ marginTop: "25px", maxWidth: "20%" }}>
            {checked ? (
              <>
                {" "}
                <Label style={{ width: "100px" }}>Kinh Độ:</Label>
                <Input
                  style={{ width: "100px" }}
                  type="number"
                  onChange={(e) => setKinhDo(e.target.value)}
                ></Input>{" "}
              </>
            ) : (
              <>
                {" "}
                <Label style={{ width: "100px", paddingRigh: "0" }}>
                  Nơi sinh:
                </Label>
                <Input
                  type="select"
                  onChange={(e) => {
                    handleSelect(e.target.value);
                  }}
                >
                  <option>Không chọn nơi sinh</option>
                  {city?.map((x) => (
                    <option>{x?.name}</option>
                  ))}
                </Input>{" "}
              </>
            )}
          </InputGroup>

          <InputGroup
            style={{
              marginTop: "25px",
              maxWidth: "15%",
              justifyContent: "space-around",
            }}
          >
            Chọn chi tiết:
            <DragSwitch
              className="switch__"
              checked={checked}
              onChange={() => {
                setChecked(!checked);
              }}
            />
          </InputGroup>
        </div>
      </div>

      <div className="button__result hide-to-print">
        {" "}
        <Button
          disabled={!excelLichvanNien || excelLichvanNien.length === 0}
          onClick={handleResult}
          color="primary"
        >
          Xem Tiểu nhi quan sát
        </Button>
      </div>
      <div className="title__print">CÔNG TY TNHH TƯỜNG MINH PHONG THỦY</div>
      <div className="sub__title">
        Địa chỉ: Lầu 4 & 6 - Smart Building - WinHome - 793/49/1 Trần Xuân Soạn,
        Tân Hưng, Quận 7, TP. Hồ Chí Minh
      </div>
      <hr className="hr__print" />
      <div className="title__battu">Phân tích Tiểu Nhi Quan Sát</div>
      <div id="info__printtt">
        <div id="info__print">
          Họ và tên: <span className="fw__bold"> {name}</span> | Ngày giờ sinh:{" "}
          <span className="fw__bold">
            {" "}
            {hour != "null"
              ? `${hour}:${minute} ${day}-${monthShow}-${year}`
              : `00:${minute} ${day}-${monthShow}-${year}`}
          </span>
        </div>
        <div id="info__printt">
          Giới tính: <span className="fw__bold"> {gender}</span> | Nơi sinh:{" "}
          <span className="fw__bold"> {noiSinh}</span>
        </div>
      </div>

      {show && noLogin ? (
        <>
          <div
            style={{ width: "1440px", margin: "0 auto" }}
            id="box__"
            className="box__right margin__print margin_thansat"
          >
            <Table
              style={{ width: "50%", margin: "0 auto 1rem auto" }}
              bordered
              hover
            >
              <thead>
                {checkHour ? (
                  " "
                ) : (
                  <th
                    className="hide__border__td"
                    style={{ border: "1px solid transparent" }}
                    colspan="3"
                  >
                    <div
                      className="bg__"
                      style={{
                        marginBottom: "9px",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <button
                        className="hide-to-print"
                        onClick={handlePrevHour}
                        style={{
                          width: "50%",
                          border: "none",
                          borderRight: "1px solid white",
                          background: "transparent",
                        }}
                      >
                        <VscTriangleLeft style={{ color: "white" }} />
                      </button>
                      <button
                        className="hide-to-print"
                        onClick={handleNextHour}
                        style={{
                          width: "50%",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <VscTriangleRight style={{ color: "white" }} />
                      </button>
                    </div>
                  </th>
                )}

                <th
                  className="hide__border__td"
                  style={{ border: "1px solid transparent" }}
                  colspan="3"
                >
                  <div
                    className="bg__"
                    style={{
                      marginBottom: "9px",
                      borderRadius: "8px",
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <button
                      className="hide-to-print"
                      onClick={handlePrevDay}
                      style={{
                        width: "50%",
                        border: "none",
                        borderRight: "1px solid white",
                        background: "transparent",
                      }}
                    >
                      <VscTriangleLeft style={{ color: "white" }} />
                    </button>
                    <button
                      className="hide-to-print"
                      onClick={handleNextDay}
                      style={{
                        width: "50%",
                        border: "none",
                        background: "transparent",
                      }}
                    >
                      <VscTriangleRight style={{ color: "white" }} />
                    </button>
                  </div>
                </th>

                <th
                  className="hide__border__td"
                  style={{ border: "1px solid transparent" }}
                  colspan="3"
                >
                  <div
                    className="bg__"
                    style={{
                      marginBottom: "9px",
                      borderRadius: "8px",
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <button
                      className="hide-to-print"
                      onClick={handlePrevMonth}
                      style={{
                        width: "50%",
                        border: "none",
                        borderRight: "1px solid white",
                        background: "transparent",
                      }}
                    >
                      <VscTriangleLeft style={{ color: "white" }} />
                    </button>
                    <button
                      className="hide-to-print"
                      onClick={handleNextMonth}
                      style={{
                        width: "50%",
                        border: "none",
                        background: "transparent",
                      }}
                    >
                      <VscTriangleRight style={{ color: "white" }} />
                    </button>
                  </div>
                </th>

                <th
                  className="hide__border__td"
                  style={{ border: "1px solid transparent" }}
                  colspan="3"
                >
                  <div
                    className="bg__"
                    style={{
                      marginBottom: "9px",
                      borderRadius: "8px",
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <button
                      className="hide-to-print"
                      onClick={handlePrevYear}
                      style={{
                        width: "50%",
                        border: "none",
                        borderRight: "1px solid white",
                        background: "transparent",
                      }}
                    >
                      <VscTriangleLeft style={{ color: "white" }} />
                    </button>
                    <button
                      className="hide-to-print"
                      onClick={handleNextYear}
                      style={{
                        width: "50%",
                        border: "none",
                        background: "transparent",
                      }}
                    >
                      <VscTriangleRight style={{ color: "white" }} />
                    </button>
                  </div>
                </th>
              </thead>

              <tbody>
                <tr
                  className="clr__print"
                  style={{
                    textAlign: "center",
                    fontSize: "22px",
                    color: "white",
                  }}
                >
                  {checkHour ? (
                    ""
                  ) : (
                    <th
                      id="bg__h"
                      colspan="3"
                      style={{
                        width: "150px",
                        border: "3px solid #000000",
                        background: "#1B61A6",
                        fontWeight: "normal",
                        height: "64px",
                      }}
                    >
                      {gioSolar} : {solarphut}
                    </th>
                  )}
                  <th
                    id="bg__d"
                    colspan="3"
                    style={{
                      width: "150px",
                      border: "3px solid #000000",
                      background: "#1B61A6",
                      fontWeight: "normal",
                    }}
                  >
                    {" "}
                    {dayShow}
                  </th>
                  <th
                    id="bg__m"
                    colspan="3"
                    style={{
                      width: "150px",
                      border: "3px solid #000000",
                      background: "#1B61A6",
                      fontWeight: "normal",
                    }}
                  >
                    {" "}
                    {monthShow}
                  </th>
                  <th
                    id="bg__y"
                    colspan="3"
                    style={{
                      width: "150px",
                      border: "3px solid #000000",
                      background: "#1B61A6",
                      fontWeight: "normal",
                    }}
                  >
                    {" "}
                    {yearShow}
                  </th>
                  <th
                    id="bg__a"
                    colspan="3"
                    style={{
                      width: "58px",
                      border: "3px solid #000000",
                      background: "#1B61A6",
                    }}
                  >
                    {" "}
                  </th>
                </tr>

                <tr style={{ textAlign: "center" }}>
                  {checkHour ? (
                    ""
                  ) : (
                    <th colspan="3" className="fz__25 clr__ border__">
                      <sup className="fz__15">{thapThanHour}</sup>
                      {thienCanHour}
                    </th>
                  )}

                  <th
                    id="bg__tcd"
                    style={{ background: "#B2CFEA" }}
                    colspan="3"
                    className="fz__25 border__"
                  >
                    {thienCanDay}
                  </th>
                  <th colspan="3" className="fz__25 clr__ border__">
                    <sup className="fz__15">{thapThanMonth}</sup>
                    {thienCanMonth}
                  </th>

                  <th colspan="3" className="fz__25 clr__ border__">
                    <sup className="fz__15">{thapThanYear}</sup>
                    {thienCanYear}
                  </th>

                  <th
                    id="bg__tc"
                    rowSpan="2"
                    style={{ width: "58px", border: "3px solid #000000" }}
                    className="bg__ clr__white clr__print"
                  >
                    Thiên Can
                  </th>
                </tr>
                <tr style={{ textAlign: "center", fontSize: "18px" }}>
                  {checkHour ? (
                    ""
                  ) : (
                    <th
                      style={{ fontWeight: "normal" }}
                      colspan="3"
                      className="border__"
                    >
                      {nguHanhHour}
                    </th>
                  )}
                  <th
                    id="bg__nhd"
                    style={{ fontWeight: "normal", background: "#B2CFEA" }}
                    colspan="3"
                    className="border__"
                  >
                    {nguHanhDay}
                  </th>
                  <th
                    style={{ fontWeight: "normal" }}
                    colspan="3"
                    className="border__"
                  >
                    {nguHanhMonth}
                  </th>
                  <th
                    style={{ fontWeight: "normal" }}
                    colspan="3"
                    className="border__"
                  >
                    {nguHanhYear}
                  </th>
                </tr>
                {/* dia chi  */}
                <tr style={{ textAlign: "center" }}>
                  {checkHour ? (
                    ""
                  ) : (
                    <th colspan="3" className="fz__25 clr__ border__">
                      {diaChiHour}
                    </th>
                  )}

                  <th
                    id="bg__dcd"
                    style={{ background: "#B2CFEA" }}
                    colspan="3"
                    className="fz__25 border__"
                  >
                    {diaChiDay}
                  </th>
                  <th colspan="3" className="fz__25 clr__ border__">
                    {diaChiMonth}
                  </th>

                  <th colspan="3" className="fz__25 clr__ border__">
                    {DiachiYear}
                  </th>

                  <th
                    id="bg__dc"
                    rowspan="1"
                    style={{ width: "58px", border: "3px solid #000000" }}
                    className="bg__ clr__white clr__print"
                  >
                    Địa Chi
                  </th>
                </tr>

                {/** tang can gio`*/}

                <tr className="fz__18" style={{ textAlign: "center" }}>
                  {checkHour ? (
                    ""
                  ) : tangCanNguHanhHour2 ? (
                    <td
                      className="border__"
                      colspan="1"
                      style={{
                        verticalAlign: "middle",
                        padding: "0",
                        fontSize: "14px",
                      }}
                    >
                      <div>
                        <sup>{thapThanHour2}</sup>
                        {tangCanNguHanhHour2}
                      </div>
                      <div
                        classNamestyle={{
                          justifyContent: "center",
                          width: "47px",
                        }}
                      >
                        {tangCanNguHanhHour5}
                      </div>
                    </td>
                  ) : (
                    ""
                  )}

                  {checkHour ? (
                    ""
                  ) : tangCanNguHanhHour2 && tangCanNguHanhHour3 ? (
                    <th
                      id="tcbox"
                      className="border__"
                      colspan="1"
                      style={{
                        verticalAlign: "middle",
                        padding: "0",
                        fontSize: "14px",
                      }}
                    >
                      <div>
                        <sup>{thapThanHour1}</sup>
                        {tangCanNguHanhHour}
                      </div>
                      <div style={{ justifyContent: "center", width: "47px" }}>
                        {tangCanNguHanhHour4}
                      </div>
                    </th>
                  ) : tangCanNguHanhHour3 ? (
                    <th
                      id="tcbox"
                      className="border__"
                      colspan="2"
                      style={{ verticalAlign: "middle", padding: "0" }}
                    >
                      <div>
                        <sup>{thapThanHour1}</sup>
                        {tangCanNguHanhHour}
                      </div>
                      <div style={{ justifyContent: "center" }}>
                        {tangCanNguHanhHour4}
                      </div>
                    </th>
                  ) : (
                    <th
                      id="tcbox"
                      className="border__"
                      colspan="3"
                      style={{ verticalAlign: "middle", padding: "0" }}
                    >
                      <div>
                        <sup>{thapThanHour1}</sup>
                        {tangCanNguHanhHour}
                      </div>
                      <div style={{ justifyContent: "center" }}>
                        {tangCanNguHanhHour4}
                      </div>
                    </th>
                  )}

                  {checkHour ? (
                    ""
                  ) : tangCanNguHanhHour3 && tangCanNguHanhHour2 ? (
                    <td
                      className="border__"
                      colspan="1"
                      style={{
                        verticalAlign: "middle",
                        padding: "0",
                        fontSize: "14px",
                      }}
                    >
                      <div>
                        <sup>{thapThanHour3}</sup>
                        {tangCanNguHanhHour3}
                      </div>
                      <div style={{ justifyContent: "center" }}>
                        {tangCanNguHanhHour6}
                      </div>
                    </td>
                  ) : tangCanNguHanhHour3 && tangCanNguHanhHour ? (
                    <td
                      className="border__"
                      colspan="1"
                      style={{
                        verticalAlign: "middle",
                        padding: "0",
                        fontSize: "16px",
                      }}
                    >
                      <div>
                        <sup>{thapThanHour3}</sup>
                        {tangCanNguHanhHour3}
                      </div>
                      <div style={{ justifyContent: "center" }}>
                        {tangCanNguHanhHour6}
                      </div>
                    </td>
                  ) : (
                    ""
                  )}

                  {/** tang can gio`*/}

                  {/** tang can ngay`*/}

                  {tangCanNguHanhDay2 ? (
                    <td
                      id="bg__tcd"
                      style={{
                        background: "#B2CFEA",
                        verticalAlign: "middle",
                        padding: "0",
                        fontSize: "14px",
                      }}
                      className="border__"
                    >
                      <div>
                        <sup>{thapThanDay2}</sup>
                        {tangCanNguHanhDay2}
                      </div>
                      <div style={{ justifyContent: "center", width: "47px" }}>
                        {tangCanNguHanhDay5}
                      </div>
                    </td>
                  ) : (
                    ""
                  )}

                  {tangCanNguHanhDay2 && tangCanNguHanhDay3 ? (
                    <th
                      id="bg__tcd2"
                      colspan="1"
                      style={{
                        border: "none",
                        background: "#B2CFEA",
                        verticalAlign: "middle",
                        padding: "0",
                        fontSize: "14px",
                      }}
                    >
                      <div>
                        <sup>{thapThanDay1}</sup>
                        {tangCanNguHanhDay}
                      </div>
                      <div style={{ justifyContent: "center", width: "47px" }}>
                        {tangCanNguHanhDay4}
                      </div>
                    </th>
                  ) : tangCanNguHanhDay3 ? (
                    <th
                      id="bg__tcd2"
                      style={{
                        background: "#B2CFEA",
                        verticalAlign: "middle",
                        padding: "0",
                        fontSize: "16px",
                      }}
                      colspan="2"
                      className="border__"
                    >
                      <div>
                        <sup>{thapThanDay1}</sup>
                        {tangCanNguHanhDay}
                      </div>
                      <div style={{ justifyContent: "center" }}>
                        {tangCanNguHanhDay4}
                      </div>
                    </th>
                  ) : (
                    <th
                      id="bg__tcd2"
                      style={{ background: "#B2CFEA" }}
                      colspan="3"
                      className="border__"
                    >
                      <div>
                        <sup>{thapThanDay1}</sup>
                        {tangCanNguHanhDay}
                      </div>
                      <div style={{ justifyContent: "center" }}>
                        {tangCanNguHanhDay4}
                      </div>
                    </th>
                  )}

                  {tangCanNguHanhDay3 && tangCanNguHanhDay2 ? (
                    <td
                      id="bg__tcd3"
                      colspan="1"
                      style={{
                        background: "#B2CFEA",
                        verticalAlign: "middle",
                        padding: "0",
                        fontSize: "14px",
                      }}
                      className="border__"
                    >
                      <div>
                        <sup>{thapThanDay3}</sup>
                        {tangCanNguHanhDay3}
                      </div>
                      <div style={{ justifyContent: "center" }}>
                        {tangCanNguHanhDay6}
                      </div>
                    </td>
                  ) : tangCanNguHanhDay3 && tangCanNguHanhDay ? (
                    <td
                      id="bg__tcd3"
                      colspan="1"
                      style={{
                        background: "#B2CFEA",
                        verticalAlign: "middle",
                        padding: "0",
                        fontSize: "16px",
                      }}
                      className="border__"
                    >
                      <div>
                        <sup>{thapThanDay3}</sup>
                        {tangCanNguHanhDay3}
                      </div>
                      <div style={{ justifyContent: "center" }}>
                        {tangCanNguHanhDay6}
                      </div>
                    </td>
                  ) : (
                    ""
                  )}

                  {/** tang can ngay``*/}

                  {/** tang can thang*/}
                  {tangCanMonth2 ? (
                    <td
                      className="border__"
                      style={{
                        verticalAlign: "middle",
                        padding: "0",
                        fontSize: "14px",
                      }}
                    >
                      <div>
                        <sup>{thapThanMonth2}</sup>
                        {tangCanMonth2}
                      </div>
                      <div style={{ justifyContent: "center", width: "47px" }}>
                        {tangCanMonth5}
                      </div>
                    </td>
                  ) : (
                    ""
                  )}

                  {tangCanMonth2 && tangCanMonth3 ? (
                    <th
                      colspan="1"
                      style={{
                        border: "none",
                        verticalAlign: "middle",
                        padding: "0",
                        fontSize: "14px",
                      }}
                    >
                      <div>
                        <sup>{thapThanMonth1}</sup>
                        {tangCanMonth}
                      </div>
                      <div style={{ justifyContent: "center", width: "47px" }}>
                        {tangCanMonth4}
                      </div>
                    </th>
                  ) : tangCanMonth3 ? (
                    <th
                      colspan="2"
                      className="border__"
                      style={{
                        verticalAlign: "middle",
                        padding: "0",
                        fontSize: "16px",
                      }}
                    >
                      <div>
                        <sup>{thapThanMonth1}</sup>
                        {tangCanMonth}
                      </div>
                      <div style={{ justifyContent: "center" }}>
                        {tangCanMonth4}
                      </div>
                    </th>
                  ) : (
                    <th colspan="3" className="border__">
                      <div>
                        <sup>{thapThanMonth1}</sup>
                        {tangCanMonth}
                      </div>
                      <div style={{ justifyContent: "center" }}>
                        {tangCanMonth4}
                      </div>
                    </th>
                  )}

                  {tangCanMonth3 && tangCanMonth2 ? (
                    <td
                      className="border__"
                      style={{
                        verticalAlign: "middle",
                        padding: "0",
                        fontSize: "14px",
                      }}
                    >
                      <div>
                        <sup>{thapThanMonth3}</sup>
                        {tangCanMonth3}
                      </div>
                      <div style={{ justifyContent: "center", width: "47px" }}>
                        {tangCanMonth6}
                      </div>
                    </td>
                  ) : tangCanMonth3 && tangCanMonth ? (
                    <td
                      className="border__"
                      style={{
                        verticalAlign: "middle",
                        padding: "0",
                        fontSize: "16px",
                      }}
                    >
                      <div>
                        <sup>{thapThanMonth3}</sup>
                        {tangCanMonth3}
                      </div>
                      <div style={{ justifyContent: "center", width: "47px" }}>
                        {tangCanMonth6}
                      </div>
                    </td>
                  ) : (
                    ""
                  )}
                  {/** tang can nam*/}

                  {tangCanYear2 ? (
                    <td
                      className="border__"
                      style={{
                        verticalAlign: "middle",
                        padding: "0",
                        fontSize: "14px",
                      }}
                    >
                      <div>
                        <sup>{thapThanYear2}</sup>
                        {tangCanYear2}
                      </div>
                      <div style={{ justifyContent: "center", width: "47px" }}>
                        {tangCanYear5}
                      </div>
                    </td>
                  ) : (
                    ""
                  )}

                  {tangCanYear2 && tangCanYear3 ? (
                    <th
                      colspan="1"
                      className="border__"
                      style={{
                        verticalAlign: "middle",
                        padding: "0",
                        fontSize: "14px",
                      }}
                    >
                      <div>
                        <sup>{thapThanYear1}</sup>
                        {tangCanYear}
                      </div>
                      <div style={{ justifyContent: "center", width: "47px" }}>
                        {tangCanYear4}
                      </div>
                    </th>
                  ) : tangCanYear3 ? (
                    <th
                      colspan="2"
                      className="border__"
                      style={{
                        verticalAlign: "middle",
                        padding: "0",
                        fontSize: "16px",
                      }}
                    >
                      <div>
                        <sup>{thapThanYear1}</sup>
                        {tangCanYear}
                      </div>
                      <div style={{ justifyContent: "center" }}>
                        {tangCanYear4}
                      </div>
                    </th>
                  ) : (
                    <th colspan="3" className="border__">
                      <div>
                        <sup>{thapThanYear1}</sup>
                        {tangCanYear}
                      </div>
                      <div style={{ justifyContent: "center" }}>
                        {tangCanYear4}
                      </div>
                    </th>
                  )}

                  {tangCanYear3 && tangCanYear2 ? (
                    <td
                      className="border__"
                      style={{
                        verticalAlign: "middle",
                        padding: "0",
                        fontSize: "14px",
                      }}
                    >
                      <div>
                        <sup>{thapThanYear3}</sup>
                        {tangCanYear3}
                      </div>
                      <div style={{ justifyContent: "center", width: "47px" }}>
                        {tangCanYear6}
                      </div>
                    </td>
                  ) : tangCanYear3 && tangCanYear ? (
                    <td
                      className="border__"
                      style={{
                        verticalAlign: "middle",
                        padding: "0",
                        fontSize: "16px",
                      }}
                    >
                      <div>
                        <sup>{thapThanYear3}</sup>
                        {tangCanYear3}
                      </div>
                      <div style={{ justifyContent: "center" }}>
                        {tangCanYear6}
                      </div>
                    </td>
                  ) : (
                    ""
                  )}

                  {/** tang can nam*/}

                  <th
                    id="bg__tcc"
                    style={{ width: "58px", border: "3px solid #000000" }}
                    className="bg__ clr__white clr__print"
                  >
                    Tàng Can
                  </th>
                </tr>

                {/** nap am*/}
                {checkNapAm ? (
                  <tr style={{ textAlign: "center" }}>
                    {checkHour ? (
                      ""
                    ) : (
                      <th id="nabox" colspan="3" className="fz__18 border__">
                        {napAmHour}
                      </th>
                    )}
                    <th
                      id="bg__nad"
                      style={{ background: "#B2CFEA" }}
                      colspan="3"
                      className="fz__18 border__"
                    >
                      {napAmDay}
                    </th>
                    <th colspan="3" className="fz__18 border__">
                      {napAmMonth}
                    </th>
                    <th colspan="3" className="fz__18 border__">
                      {napAmYear}
                    </th>
                    <th
                      id="bg__na"
                      style={{ width: "58px", border: "3px solid #000000" }}
                      className="bg__ clr__white clr__print"
                    >
                      Nạp Âm
                    </th>
                  </tr>
                ) : (
                  ""
                )}

                {/** nap am*/}

                {/** truong sinh*/}
                {checkTruongSinh ? (
                  <tr style={{ textAlign: "center" }}>
                    {checkHour ? (
                      ""
                    ) : (
                      <th id="boxtss" colspan="3" className="fz__18 border__">
                        {truongSinhHour}
                      </th>
                    )}
                    <th
                      id="bg__tsd"
                      style={{ background: "#B2CFEA" }}
                      colspan="3"
                      className="fz__18 border__"
                    >
                      {truongSinhDay}
                    </th>
                    <th colspan="3" className="fz__18 border__">
                      {truongSinhMonth}
                    </th>
                    <th colspan="3" className="fz__18 border__">
                      {truongSinhYear}
                    </th>
                    <th
                      id="bg__ts"
                      style={{ width: "58px", border: "3px solid #000000" }}
                      className="bg__ clr__white clr__print"
                    >
                      Trường Sinh
                    </th>
                  </tr>
                ) : (
                  ""
                )}

                {/** truong sinh*/}

                {/** than sat*/}
                {checkThanSat ? (
                  <tr style={{ textAlign: "center" }}>
                    {checkHour ? (
                      ""
                    ) : (
                      <th
                        id="tsbox"
                        colspan="3"
                        className="fz__18 border__ text__wrap"
                      >
                        {thanSatDiachiHours} {thanSatThienCanHour}{" "}
                        {(thanSatDiachiHours && KhongVongHour) ||
                        (thanSatThienCanHour && KhongVongHour)
                          ? `, ${KhongVongHour}`
                          : `${KhongVongHour}`}
                      </th>
                    )}
                    <th
                      id="bg__tssd"
                      style={{ background: "#B2CFEA" }}
                      colspan="3"
                      className="fz__18 border__ text__wrap"
                    >
                      {(khoiCuongDay && thanSatDiaChiDay) ||
                      (thanSatThienCanDay && thanSatDiaChiDay) ||
                      (thienXa && thanSatDiaChiDay)
                        ? `${thanSatDiaChiDay}, `
                        : thanSatDiaChiDay}{" "}
                      {thanSatThienCanDay} {khoiCuongDay} {thienXa}
                    </th>
                    <th colspan="3" className="fz__18 border__ text__wrap">
                      {(thanSatThienCanMonth && thanSatDiachiMonth) ||
                      (KhongVongMonth && thanSatDiachiMonth)
                        ? `${thanSatDiachiMonth}, `
                        : thanSatDiachiMonth}{" "}
                      {thanSatThienCanMonth} {KhongVongMonth}
                    </th>
                    <th colspan="3" className="fz__18 border__ text__wrap">
                      {(thanSatThienCanYear && thanSatDiaChiYear) ||
                      (KhongVongYear && thanSatDiaChiYear)
                        ? `${thanSatDiaChiYear}, `
                        : thanSatDiaChiYear}{" "}
                      {thanSatThienCanYear} {KhongVongYear}
                    </th>
                    <th
                      id="bg__tss"
                      style={{ width: "58px", border: "3px solid #000000" }}
                      className="bg__ clr__white clr__print"
                    >
                      Tiểu nhị quan sát
                    </th>
                  </tr>
                ) : (
                  ""
                )}

                {/** than sat*/}
              </tbody>
            </Table>
          </div>

          <TieuNhiQuanSat
            monthTieuNhi={monthTieuNhi}
            yearTieuNhi={yearTieuNhi}
            chiHour={chiHour}
            tuTru={tuTru}
            nguHanhTieuNhi={nguHanhTieuNhi}
          />
        </>
      ) : (
        <div
          className="hide-to-print"
          style={{
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold",
            minHeight: "500px",
            paddingTop: "125px",
          }}
        >
          {!excelLichvanNien || excelLichvanNien.length === 0
            ? "Đang setup dữ liệu..."
            : "Vui lòng nhập thông tin và xem tiểu nhi quan sát"}
        </div>
      )}
      {alerError ? (
        <SweetAlert
          type={"warning"}
          title={"Vui lòng đăng nhập để sử dụng website"}
          onConfirm={() => setAlerError(false)}
        />
      ) : (
        ""
      )}

      {alerError2 ? (
        <SweetAlert
          type={"warning"}
          title={"Tài khoản của bạn đã hết hạn"}
          onConfirm={() => setAlerError2(false)}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default PageTieuNhiQuanSat;
