
import LoiMoDau from "./LuanDungThanTheoThang/LoiMoDau";
import { GiapThangTi, GiapThangSuu, GiapThangDan, GiapThangMao, GiapThangThin, GiapThangTy, GiapThangNgo, GiapThangMui, GiapThangThan, GiapThangDau, GiapThangTuat, GiapThangHoi } from "./LuanDungThanTheoThang/Giap"

import { AtThangTi, AtThangSuu, AtThangDan, AtThangMao, AtThangThin, AtThangTy, AtThangNgo, AtThangMui, AtThangThan, AtThangDau, AtThangTuat, AtThangHoi } from "./LuanDungThanTheoThang/At"

import { BinhThangTi, BinhThangSuu, BinhThangDan, BinhThangMao, BinhThangThin, BinhThangTy, BinhThangNgo, BinhThangMui, BinhThangThan, BinhThangDau, BinhThangTuat, BinhThangHoi } from "./LuanDungThanTheoThang/Binh"

import { DinhThangTi, DinhThangSuu, DinhThangDan, DinhThangMao, DinhThangThin, DinhThangTy, DinhThangNgo, DinhThangMui, DinhThangThan, DinhThangDau, DinhThangTuat, DinhThangHoi } from "./LuanDungThanTheoThang/Dinh"

import { MauThangTi, MauThangSuu, MauThangDan, MauThangMao, MauThangThin, MauThangTy, MauThangNgo, MauThangMui, MauThangThan, MauThangDau, MauThangTuat, MauThangHoi } from "./LuanDungThanTheoThang/Mau"

import { KyThangTi, KyThangSuu, KyThangDan, KyThangMao, KyThangThin, KyThangTy, KyThangNgo, KyThangMui, KyThangThan, KyThangDau, KyThangTuat, KyThangHoi } from "./LuanDungThanTheoThang/Ky"

import { CanhThangTi, CanhThangSuu, CanhThangDan, CanhThangMao, CanhThangThin, CanhThangTy, CanhThangNgo, CanhThangMui, CanhThangThan, CanhThangDau, CanhThangTuat, CanhThangHoi } from "./LuanDungThanTheoThang/Canh"

import { TanThangTi, TanThangSuu, TanThangDan, TanThangMao, TanThangThin, TanThangTy, TanThangNgo, TanThangMui, TanThangThan, TanThangDau, TanThangTuat, TanThangHoi } from "./LuanDungThanTheoThang/Tan"

import { NhamThangTi, NhamThangSuu, NhamThangDan, NhamThangMao, NhamThangThin, NhamThangTy, NhamThangNgo, NhamThangMui, NhamThangThan, NhamThangDau, NhamThangTuat, NhamThangHoi } from "./LuanDungThanTheoThang/Nham"

import { QuyThangTi, QuyThangSuu, QuyThangDan, QuyThangMao, QuyThangThin, QuyThangTy, QuyThangNgo, QuyThangMui, QuyThangThan, QuyThangDau, QuyThangTuat, QuyThangHoi } from "./LuanDungThanTheoThang/Quy"

import { BoSungHanhTho } from "./LuanDungThanTheoThang/BoSungHanhTho";
import { BoSungHanhKim } from "./LuanDungThanTheoThang/BoSungHanhKim";
import { BoSungHanhMoc } from "./LuanDungThanTheoThang/BoSungHanhMoc";
import { BoSungHanhThuy } from "./LuanDungThanTheoThang/BoSungHanhThuy";
import { BoSungHanhHoa } from "./LuanDungThanTheoThang/BoSungHanhHoa";


export const LuanDungThan = (title) => {


    switch (title) {
        case "Lời mở đầu":
            return LoiMoDau;
        case "Giáp Tháng Tý":
            return GiapThangTi
        case "Giáp Tháng Sửu":
            return GiapThangSuu
        case "Giáp Tháng Dần":
            return GiapThangDan
        case "Giáp Tháng Mão":
            return GiapThangMao
        case "Giáp Tháng Thìn":
            return GiapThangThin
        case "Giáp Tháng Tỵ":
            return GiapThangTy
        case "Giáp Tháng Ngọ":
            return GiapThangNgo
        case "Giáp Tháng Mùi":
            return GiapThangMui
        case "Giáp Tháng Thân":
            return GiapThangThan
        case "Giáp Tháng Dậu":
            return GiapThangDau
        case "Giáp Tháng Tuất":
            return GiapThangTuat
        case "Giáp Tháng Hợi":
            return GiapThangHoi
        case "Ất Tháng Tý":
            return AtThangTi
        case "Ất Tháng Sửu":
            return AtThangSuu
        case "Ất Tháng Dần":
            return AtThangDan
        case "Ất Tháng Mão":
            return AtThangMao
        case "Ất Tháng Thìn":
            return AtThangThin
        case "Ất Tháng Tỵ":
            return AtThangTy
        case "Ất Tháng Ngọ":
            return AtThangNgo
        case "Ất Tháng Mùi":
            return AtThangMui
        case "Ất Tháng Thân":
            return AtThangThan
        case "Ất Tháng Dậu":
            return AtThangDau
        case "Ất Tháng Tuất":
            return AtThangTuat
        case "Ất Tháng Hợi":
            return AtThangHoi
        case "Bính Tháng Tý":
            return BinhThangTi
        case "Bính Tháng Sửu":
            return BinhThangSuu
        case "Bính Tháng Dần":
            return BinhThangDan
        case "Bính Tháng Mão":
            return BinhThangMao
        case "Bính Tháng Thìn":
            return BinhThangThin
        case "Bính Tháng Tỵ":
            return BinhThangTy
        case "Bính Tháng Ngọ":
            return BinhThangNgo
        case "Bính Tháng Mùi":
            return BinhThangMui
        case "Bính Tháng Thân":
            return BinhThangThan
        case "Bính Tháng Dậu":
            return BinhThangDau
        case "Bính Tháng Tuất":
            return BinhThangTuat
        case "Bính Tháng Hợi":
            return BinhThangHoi
        case "Đinh Tháng Tý":
            return DinhThangTi
        case "Đinh Tháng Sửu":
            return DinhThangSuu
        case "Đinh Tháng Dần":
            return DinhThangDan
        case "Đinh Tháng Mão":
            return DinhThangMao
        case "Đinh Tháng Thìn":
            return DinhThangThin
        case "Đinh Tháng Tỵ":
            return DinhThangTy
        case "Đinh Tháng Ngọ":
            return DinhThangNgo
        case "Đinh Tháng Mùi":
            return DinhThangMui
        case "Đinh Tháng Thân":
            return DinhThangThan
        case "Đinh Tháng Dậu":
            return DinhThangDau
        case "Đinh Tháng Tuất":
            return DinhThangTuat
        case "Đinh Tháng Hợi":
            return DinhThangHoi
        case "Mậu Tháng Tý":
            return MauThangTi
        case "Mậu Tháng Sửu":
            return MauThangSuu
        case "Mậu Tháng Dần":
            return MauThangDan
        case "Mậu Tháng Mão":
            return MauThangMao
        case "Mậu Tháng Thìn":
            return MauThangThin
        case "Mậu Tháng Tỵ":
            return MauThangTy
        case "Mậu Tháng Ngọ":
            return MauThangNgo
        case "Mậu Tháng Mùi":
            return MauThangMui
        case "Mậu Tháng Thân":
            return MauThangThan
        case "Mậu Tháng Dậu":
            return MauThangDau
        case "Mậu Tháng Tuất":
            return MauThangTuat
        case "Mậu Tháng Hợi":
            return MauThangHoi
        case "Kỷ Tháng Tý":
            return KyThangTi
        case "Kỷ Tháng Sửu":
            return KyThangSuu
        case "Kỷ Tháng Dần":
            return KyThangDan
        case "Kỷ Tháng Mão":
            return KyThangMao
        case "Kỷ Tháng Thìn":
            return KyThangThin
        case "Kỷ Tháng Tỵ":
            return KyThangTy
        case "Kỷ Tháng Ngọ":
            return KyThangNgo
        case "Kỷ Tháng Mùi":
            return KyThangMui
        case "Kỷ Tháng Thân":
            return KyThangThan
        case "Kỷ Tháng Dậu":
            return KyThangDau
        case "Kỷ Tháng Tuất":
            return KyThangTuat
        case "Kỷ Tháng Hợi":
            return KyThangHoi
        case "Canh Tháng Tý":
            return CanhThangTi
        case "Canh Tháng Sửu":
            return CanhThangSuu
        case "Canh Tháng Dần":
            return CanhThangDan
        case "Canh Tháng Mão":
            return CanhThangMao
        case "Canh Tháng Thìn":
            return CanhThangThin
        case "Canh Tháng Tỵ":
            return CanhThangTy
        case "Canh Tháng Ngọ":
            return CanhThangNgo
        case "Canh Tháng Mùi":
            return CanhThangMui
        case "Canh Tháng Thân":
            return CanhThangThan
        case "Canh Tháng Dậu":
            return CanhThangDau
        case "Canh Tháng Tuất":
            return CanhThangTuat
        case "Canh Tháng Hợi":
            return CanhThangHoi
        case "Tân Tháng Tý":
            return TanThangTi
        case "Tân Tháng Sửu":
            return TanThangSuu
        case "Tân Tháng Dần":
            return TanThangDan
        case "Tân Tháng Mão":
            return TanThangMao
        case "Tân Tháng Thìn":
            return TanThangThin
        case "Tân Tháng Tỵ":
            return TanThangTy
        case "Tân Tháng Ngọ":
            return TanThangNgo
        case "Tân Tháng Mùi":
            return TanThangMui
        case "Tân Tháng Thân":
            return TanThangThan
        case "Tân Tháng Dậu":
            return TanThangDau
        case "Tân Tháng Tuất":
            return TanThangTuat
        case "Tân Tháng Hợi":
            return TanThangHoi
        case "Nhâm Tháng Tý":
            return NhamThangTi
        case "Nhâm Tháng Sửu":
            return NhamThangSuu
        case "Nhâm Tháng Dần":
            return NhamThangDan
        case "Nhâm Tháng Mão":
            return NhamThangMao
        case "Nhâm Tháng Thìn":
            return NhamThangThin
        case "Nhâm Tháng Tỵ":
            return NhamThangTy
        case "Nhâm Tháng Ngọ":
            return NhamThangNgo
        case "Nhâm Tháng Mùi":
            return NhamThangMui
        case "Nhâm Tháng Thân":
            return NhamThangThan
        case "Nhâm Tháng Dậu":
            return NhamThangDau
        case "Nhâm Tháng Tuất":
            return NhamThangTuat
        case "Nhâm Tháng Hợi":
            return NhamThangHoi
        case "Quý Tháng Tý":
            return QuyThangTi
        case "Quý Tháng Sửu":
            return QuyThangSuu
        case "Quý Tháng Dần":
            return QuyThangDan
        case "Quý Tháng Mão":
            return QuyThangMao
        case "Quý Tháng Thìn":
            return QuyThangThin
        case "Quý Tháng Tỵ":
            return QuyThangTy
        case "Quý Tháng Ngọ":
            return QuyThangNgo
        case "Quý Tháng Mùi":
            return QuyThangMui
        case "Quý Tháng Thân":
            return QuyThangThan
        case "Quý Tháng Dậu":
            return QuyThangDau
        case "Quý Tháng Tuất":
            return QuyThangTuat
        case "Quý Tháng Hợi":
            return QuyThangHoi
        case "Bổ sung hành kim":
            return BoSungHanhKim
        case "Bổ sung hành mộc":
            return BoSungHanhMoc
        case "Bổ sung hành thủy":
            return BoSungHanhThuy
        case "Bổ sung hành hỏa":
            return BoSungHanhHoa
        case "Bổ sung hành thổ":
            return BoSungHanhTho
        default:
            break;
    }
};
