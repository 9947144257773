import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Collapse,
  CardBody,
  Card,
} from "reactstrap";
import { BsList } from "react-icons/bs";
import "../scss/thansat.scss";
import logo from "../assets/logo.png";
import { ThanSatList } from "../components/renderThanSat"

const ModalPopup = (props) => {

  const { className, open, close, value } = props;

  const [isOpen, setIsOpen] = useState(true);
  const [title, setTitle] = useState();
  const [text, setText] = useState();

  const handleClick = (e) => {
     
    let value = e.currentTarget.textContent;
    const content = ThanSatList(value)
    setText(content)
    setTitle(`${value}`)
  }
  const toggle = () => close(false);
  useEffect(() => {
    let content = ThanSatList(value)
    setText(content)
    setTitle(`${value}`)
    
  }, [value])

  return (
    <div>
      <Modal toggle={toggle} size="lg" isOpen={open} className={className}>
        <ModalHeader
          style={{
            background: "#1B61A6",
            color: "#fff",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button onClick={() => setIsOpen(!isOpen)} className="icon-modal">
            <BsList />
          </button>
          <b>DIỄN GIẢI THẦN SÁT</b>
        </ModalHeader>
        <ModalBody style={{ display: "flex" }}>
          <Collapse isOpen={isOpen}>
            <Card>
              <CardBody>
                <p className="chiday" onClick={handleClick}>Âm Sai Dương Thác</p>
                <p className="chiday" onClick={handleClick}>Bạch Hổ</p>
                <p className="chiday" onClick={handleClick}>Bản An</p>
                <p className="chiday" onClick={handleClick}>Bạo Bại</p>
                <p className="chiday" onClick={handleClick}>Bát Tọa</p>
                <p className="chiday" onClick={handleClick}>Bệnh Phù</p>
                <p className="chiday" onClick={handleClick}>Cách Giác</p>
                <p className="chiday" onClick={handleClick}>Can Lộc</p>
                <p className="chiday" onClick={handleClick}>Câu Giảo</p>
                <p className="chiday" onClick={handleClick}>Chỉ Bối</p>
                <p className="chiday" onClick={handleClick}>Chiến lược gia (Đa Mưu)</p>
                <p className="chiday" onClick={handleClick}>Cô Loan</p>
                <p className="chiday" onClick={handleClick}>Cô Thần Quả Tú</p>
                <p className="chiday" onClick={handleClick}>Dịch Mã</p>
                <p className="chiday" onClick={handleClick}>Dương Nhẫn</p>
                <p className="chiday" onClick={handleClick}>Đào Hoa</p>
                <p className="chiday" onClick={handleClick}>Địa Giải</p>
                <p className="chiday" onClick={handleClick}>Điếu Khách</p>
                <p className="chiday" onClick={handleClick}>Đức Thần</p>
                <p className="chiday" onClick={handleClick}>Giải Thần / Thiên Giải</p>
                <p className="chiday" onClick={handleClick}>Hàm Trì</p>
                <p className="chiday" onClick={handleClick}>Hoa Cái</p>
                <p className="chiday" onClick={handleClick}>Học Đường</p>
                <p className="chiday" onClick={handleClick}>Học Sĩ</p>
                <p className="chiday" onClick={handleClick}>Hối Khí</p>
                <p className="chiday" onClick={handleClick}>Hồng Diễm</p>
                <p className="chiday" onClick={handleClick}>Hồng Loan</p>
                <p className="chiday" onClick={handleClick}>Huyết Nhẫn / Huyết Đao</p>
                <p className="chiday" onClick={handleClick}>Khôi Cương</p>
                <p className="chiday" onClick={handleClick}>Không Vong</p>
                <p className="chiday" onClick={handleClick}>Kiếm Phong</p>
                <p className="chiday" onClick={handleClick}>Kiếp Sát</p>
                <p className="chiday" onClick={handleClick}>Kim Dư</p>
                <p className="chiday" onClick={handleClick}>Kim Quỹ</p>
                <p className="chiday" onClick={handleClick}>Lan Can</p>
                <p className="chiday" onClick={handleClick}>Lộc Thần</p>
                <p className="chiday" onClick={handleClick}>Long Đức / Long Đức Quý Nhân</p>
                <p className="chiday" onClick={handleClick}>Lục Ách</p>
                <p className="chiday" onClick={handleClick}>Lục Hợp Quý</p>
                <p className="chiday" onClick={handleClick}>Lục Tú</p>
                <p className="chiday" onClick={handleClick}>Lưu Hạ / Lưu Hà</p>
                <p className="chiday" onClick={handleClick}>Mộ Khố</p>
                <p className="chiday" onClick={handleClick}>Mộc Dục</p>
                <p className="chiday" onClick={handleClick}>Ngọc Đường</p>
                <p className="chiday" onClick={handleClick}>Ngọc Môn</p>
                <p className="chiday" onClick={handleClick}>Ngũ Phú</p>
                <p className="chiday" onClick={handleClick}>Ngũ Quỷ</p>
                <p className="chiday" onClick={handleClick}>Nguyệt Không</p>
                <p className="chiday" onClick={handleClick}>Nguyên Thần</p>
                <p className="chiday" onClick={handleClick}>Nguyệt Đức Hợp</p>
                <p className="chiday" onClick={handleClick}>Nguyệt Đức Quý Nhân</p>
                <p className="chiday" onClick={handleClick}>Nguyệt Phá</p>
                <p className="chiday" onClick={handleClick}>Nhật Đức</p>
                <p className="chiday" onClick={handleClick}>Nhật Phá / Phá Nhật</p>
                <p className="chiday" onClick={handleClick}>Nhật Quý</p>
                <p className="chiday" onClick={handleClick}>Phá Toái / Đại Hao</p>
                <p className="chiday" onClick={handleClick}>Phi Đao/ Phi Nhẫn/ Phi Nhận</p>
                <p className="chiday" onClick={handleClick}>Phi Ma</p>
                <p className="chiday" onClick={handleClick}>Phù Trầm</p>
                <p className="chiday" onClick={handleClick}>Phúc Đức</p>
                <p className="chiday" onClick={handleClick}>Phục Ngâm</p>
                <p className="chiday" onClick={handleClick}>Phục Thi</p>
                <p className="chiday" onClick={handleClick}>Phúc Tinh Quý Nhân</p>
                <p className="chiday" onClick={handleClick}>Quan Phù</p>
                <p className="chiday" onClick={handleClick}>Quán Tố</p>
                <p className="chiday" onClick={handleClick}>Quốc Ấn Quý Nhân</p>
                <p className="chiday" onClick={handleClick}>Quý Hợp Quý Nhân</p>
                <p className="chiday" onClick={handleClick}>Quý Thực</p>
                <p className="chiday" onClick={handleClick}>Quyển Thiệt</p>
                <p className="chiday" onClick={handleClick}>Tai Sát</p>
                <p className="chiday" onClick={handleClick}>Tam Đài</p>
                <p className="chiday" onClick={handleClick}>Tam Kỳ Quý Nhân</p>
                <p className="chiday" onClick={handleClick}>Tam Sát</p>
                <p className="chiday" onClick={handleClick}>Tang Môn</p>
                <p className="chiday" onClick={handleClick}>Tam Đài Quý</p>
                <p className="chiday" onClick={handleClick}>Thái Âm</p>
                <p className="chiday" onClick={handleClick}>Thái Cực Quý Nhân</p>
                <p className="chiday" onClick={handleClick}>Thái Dương</p>
                <p className="chiday" onClick={handleClick}>Thái Tuế</p>
                <p className="chiday" onClick={handleClick}>Thập Ác Đại Bại</p>
                <p className="chiday" onClick={handleClick}>Thập Linh</p>
                <p className="chiday" onClick={handleClick}>Thiên Ách</p>
                <p className="chiday" onClick={handleClick}>Thiên Ất Quý Nhân</p>
                <p className="chiday" onClick={handleClick}>Thiên Cẩu</p>
                <p className="chiday" onClick={handleClick}>Thiên Cương</p>
                <p className="chiday" onClick={handleClick}>Thiên Đức Hợp</p>
                <p className="chiday" onClick={handleClick}>Thiên Đức Quý Nhân</p>
                <p className="chiday" onClick={handleClick}>Thiên Hỷ</p>
                <p className="chiday" onClick={handleClick}>Thiên Khốc</p>
                <p className="chiday" onClick={handleClick}>Thiên Không</p>
                <p className="chiday" onClick={handleClick}>Thiên La Địa Võng</p>
                <p className="chiday" onClick={handleClick}>Thiên Sát</p>
                <p className="chiday" onClick={handleClick}>Thiên Tài</p>
                <p className="chiday" onClick={handleClick}>Thiên Xá / Thiên Thứ</p>
                <p className="chiday" onClick={handleClick}>Thiên Y</p>
                <p className="chiday" onClick={handleClick}>Thiết Tảo</p>
                <p className="chiday" onClick={handleClick}>Thoái Thần</p>
                <p className="chiday" onClick={handleClick}>Tiến Thần</p>
                <p className="chiday" onClick={handleClick}>Tiểu Hao</p>
                <p className="chiday" onClick={handleClick}>Tuế Hình</p>
                <p className="chiday" onClick={handleClick}>Tuế Phá</p>
                <p className="chiday" onClick={handleClick}>Tuế Sát</p>
                <p className="chiday" onClick={handleClick}>Tử Phù</p>
                <p className="chiday" onClick={handleClick}>Tử Vi</p>
                <p className="chiday" onClick={handleClick}>Tức Thần</p>
                <p className="chiday" onClick={handleClick}>Tướng Tinh</p>
                <p className="chiday" onClick={handleClick}>Văn Xương</p>
                <p className="chiday" onClick={handleClick}>Vong Thần</p>
              
              
               
               
              </CardBody>
            </Card>
          </Collapse>
          {isOpen ?
          <div className={isOpen ? "content-modal-show" : "content-modal"}>
            <div className="watermark-hoagiap">
              <img src={logo} />
            </div>
          
            <h3 style={{textTransform:'uppercase'}}>{title}</h3>
            <div style={{maxHeight: '700px', overflowY: 'scroll'}}>
            {text}
            

            </div>
          </div>
          
          
          
          : ""}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => close(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalPopup;
