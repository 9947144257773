export const converCanNgay = (chiMonth) => {
    let object = {}
    switch (chiMonth) {
        case 'Giáp':
            return object = {
                TAQN: 'Mùi Sửu',
                HamTri: 'Tý',
                VanXuong: 'Tỵ',
                LocThan: 'Mão',
                DuongNhan: 'Mão',
                HongDiem: 'Thân Ngọ',
                HocDuong: 'Hợi',
                LuuHa: 'Dậu',
                PhiDao: 'Dậu',
                ThienTai: 'Mậu',
                KimDu: 'Thìn',
                QuyThuc: 'Bính Thìn Bính Dần',
                QuyHop: 'Kỷ Sửu Kỷ Mùi',
                LucHopQuy: 'Giáp Tý Giáp Ngọ',
                TCQN: 'Tý Ngọ',
                QAQN: 'Tuất',
                MoKho: 'Mùi'
            }
            break;
        case 'Ất':
            return object = {
                TAQN: 'Thân Tý',
                HamTri: 'Tý',
                VanXuong: 'Ngọ',
                LocThan: 'Dần',
                DuongNhan: 'Dần',
                HongDiem: 'Thân Ngọ',
                HocDuong: 'Ngọ',
                LuuHa: 'Tuất',
                PhiDao: 'Tuất',
                ThienTai: 'Kỷ',
                KimDu: 'Tỵ',
                QuyThuc: 'Đinh Hợi Đinh Dậu',
                QuyHop: 'Canh Tý Canh Thân',
                LucHopQuy: 'Tân Tỵ Tân Sửu',
                TCQN: 'Tý Ngọ',
                QAQN: 'Hợi',
                MoKho: 'Tuất'
            }
        case 'Bính':
            return object = {
                TAQN: 'Dậu Hợi',
                HamTri: 'Mão',
                VanXuong: 'Thân',
                LocThan: 'Tỵ',
                DuongNhan: 'Ngọ',
                HongDiem: 'Dần',
                HocDuong: 'Dần',
                LuuHa: 'Mùi',
                PhiDao: 'Tý',
                ThienTai: 'Mậu',
                KimDu: 'Mùi',
                QuyThuc: 'Mậu Tý Mậu Ngọ',
                QuyHop: 'Tân Dậu Tân Hợi',
                LucHopQuy: 'Bính Dần Bính Thìn',
                TCQN: 'Mão Dậu',
                QAQN: 'Sửu',
                MoKho: 'Tuất'
            }
        case 'Đinh':
            return object = {
                TAQN: 'Dậu Hợi',
                HamTri: 'Mão',
                VanXuong: 'Dậu',
                LocThan: 'Ngọ',
                DuongNhan: 'Tỵ',
                HongDiem: 'Mùi',
                HocDuong: 'Dậu',
                LuuHa: 'Thân',
                PhiDao: 'Sửu',
                ThienTai: 'Tân',
                KimDu: 'Thân',
                QuyThuc: 'Kỷ Tỵ Kỷ Sửu',
                QuyHop: ' ',
                LucHopQuy: ' ',
                TCQN: 'Mão Dậu',
                QAQN: 'Dần',
                MoKho: 'Sửu'
            }
        case 'Mậu':
            return object = {
                TAQN: 'Sửu Mùi',
                HamTri: ' ',
                VanXuong: 'Thân',
                LocThan: 'Tỵ',
                DuongNhan: 'Ngọ',
                HongDiem: 'Thìn',
                HocDuong: 'Dần',
                LuuHa: 'Tỵ',
                PhiDao: 'Tý',
                ThienTai: 'Nhâm',
                KimDu: 'Mùi',
                QuyThuc: 'Canh Tý Canh Ngọ',
                QuyHop: 'Quý Sưu Quý Mùi',
                LucHopQuy: 'Mậu Tý Mậu Ngọ',
                TCQN: 'Thìn Tuất Sửu Mùi',
                QAQN: 'Sửu',
                MoKho: 'Tuất'
            }
        case 'Kỷ':
            return object = {
                TAQN: 'Thân Tý',
                HamTri: ' ',
                VanXuong: 'Dậu',
                LocThan: 'Ngọ',
                DuongNhan: 'Tỵ',
                HongDiem: 'Thìn',
                HocDuong: 'Dậu',
                LuuHa: 'Ngọ',
                PhiDao: 'Sửu',
                ThienTai: 'Quý',
                KimDu: 'Thân',
                QuyThuc: 'Tân Hợi Tân Mùi',
                QuyHop: 'Giáp Tý Giáp Thân',
                LucHopQuy: 'Kỷ Sửu Kỷ Tỵ',
                TCQN: 'Thìn Tuất Sửu Mùi',
                QAQN: 'Dần',
                MoKho: 'Sửu'
            }
        case 'Canh':
            return object = {
                TAQN: 'Sửu Mùi',
                HamTri: 'Ngọ',
                VanXuong: 'Hợi',
                LocThan: 'Thân',
                DuongNhan: 'Dậu',
                HongDiem: 'Tuất',
                HocDuong: 'Tỵ',
                LuuHa: 'Thìn',
                PhiDao: 'Mão',
                ThienTai: 'Giáp',
                KimDu: 'Tuất',
                QuyThuc: 'Nhâm Tuất Nhâm Thân',
                QuyHop: 'Ất Sửu Ất Mùi',
                LucHopQuy: 'Canh Tý Canh Ngọ',
                TCQN: 'Dần Hợi',
                QAQN: 'Thìn',
                MoKho: 'Sửu'
            }
        case 'Tân':
            return object = {
                TAQN: 'Ngọ Dần',
                HamTri: 'Ngọ',
                VanXuong: 'Tý',
                LocThan: 'Dậu',
                DuongNhan: 'Thân',
                HongDiem: 'Dậu',
                HocDuong: 'Tý',
                LuuHa: 'Mão',
                PhiDao: 'Thìn',
                ThienTai: 'Ất',
                KimDu: 'Hợi',
                QuyThuc: 'Quý Tỵ Quý Mão',
                QuyHop: 'Bính Ngọ Bính Dần',
                LucHopQuy: 'Tân Hợi Tân Mùi',
                TCQN: 'Dần Hợi',
                QAQN: 'Tỵ',
                MoKho: 'Thìn'
            }
        case 'Nhâm':
            return object = {
                TAQN: 'Tỵ Mão',
                HamTri: 'Dậu',
                VanXuong: 'Dần',
                LocThan: 'Hợi',
                DuongNhan: 'Tý',
                HongDiem: 'Tý',
                HocDuong: 'Thân',
                LuuHa: 'Dần',
                PhiDao: 'Ngọ',
                ThienTai: 'Bính',
                KimDu: 'Sửu',
                QuyThuc: 'Giáp Tý Giáp Ngọ',
                QuyHop: 'Đinh Mão Đinh Tỵ ',
                LucHopQuy: 'Nhâm Thân',
                TCQN: 'Tỵ Thân',
                QAQN: 'Mùi',
                MoKho: 'Thìn'
            }
        case 'Quý':
            return object = {
                TAQN: 'Tỵ Mão',
                HamTri: 'Dậu',
                VanXuong: 'Mão',
                LocThan: 'Tý',
                DuongNhan: 'Hợi',
                HongDiem: 'Thân',
                HocDuong: 'Mão',
                LuuHa: 'Hợi',
                PhiDao: 'Mùi',
                ThienTai: 'Đinh',
                KimDu: 'Dần',
                QuyThuc: `Ất Sửu Ất Tỵ`,
                QuyHop: ' ',
                LucHopQuy: ' ',
                TCQN: 'Tỵ Thân',
                QAQN: 'Thân',
                MoKho: 'Mùi'
            }
        default:
            break;
    }
}

export const converChiDay = (chiDay) => {

    let object = {}

    switch (chiDay) {
        case 'Tý':
            return object = {
                HoaCai: 'Thìn',
                TuongTinh: 'Tý',
                DichMa: 'Dần',
                DaoHoa: 'Dậu',
                HuyetNhan: 'Tuất',
                CoThan: 'Dần',
                QuaTu: 'Tuất',
                TangMon: 'Dần',
                KiepSat: 'Tỵ',
                CachGiao: 'Dần',
                VongThan: 'Hợi',
                NguQuy: 'Thìn',
                NguyetDucHop: 'Đinh',
                NguyetPha: 'Ngọ',
                NhatPha: 'Ngọ',
                ThienDucHop: 'Thân',
                NgocMon: 'Dậu'
            }
        case 'Sửu':
            return object = {
                HoaCai: 'Sửu',
                TuongTinh: 'Dậu',
                DichMa: 'Hợi',
                DaoHoa: 'Ngọ',
                HuyetNhan: 'Dậu',
                CoThan: 'Dần',
                QuaTu: 'Tuất',
                TangMon: 'Mão',
                KiepSat: 'Ngọ',
                CachGiao: 'Mão',
                VongThan: 'Thân',
                NguQuy: 'Tỵ',
                NguyetDucHop: 'Ất',
                NguyetPha: 'Mùi',
                NhatPha: 'Mùi',
                ThienDucHop: 'Ất',
                NgocMon: 'Ngọ'

            }
        case 'Dần':
            return object = {
                HoaCai: 'Tuất',
                TuongTinh: 'Ngọ',
                DichMa: 'Thân',
                DaoHoa: 'Mão',
                HuyetNhan: 'Thân',
                CoThan: 'Tỵ',
                QuaTu: 'Sửu',
                TangMon: 'Thìn',
                KiepSat: 'Hợi',
                CachGiao: 'Thìn',
                VongThan: 'Tỵ',
                NguQuy: 'Ngọ',
                NguyetDucHop: 'Tân',
                NguyetPha: 'Thân',
                NhatPha: 'Thân',
                ThienDucHop: 'Nhâm',
                NgocMon: 'Mão'

            }
        case 'Mão':
            return object = {
                HoaCai: 'Mùi',
                TuongTinh: 'Mão',
                DichMa: 'Tỵ',
                DaoHoa: 'Tý',
                HuyetNhan: 'Mùi',
                CoThan: 'Tỵ',
                QuaTu: 'Sửu',
                TangMon: 'Tỵ',
                KiepSat: 'Thân',
                CachGiao: 'Tỵ',
                VongThan: 'Dần',
                NguQuy: 'Mùi',
                NguyetDucHop: 'Kỷ',
                NguyetPha: 'Dậu',
                NhatPha: 'Dậu',
                ThienDucHop: 'Tỵ',
                NgocMon: 'Tý'

            }
        case 'Thìn':
            return object = {
                HoaCai: 'Thìn',
                TuongTinh: 'Tý',
                DichMa: 'Dần',
                DaoHoa: 'Dậu',
                HuyetNhan: 'Ngọ',
                CoThan: 'Tỵ',
                QuaTu: 'Sửu',
                TangMon: 'Ngọ',
                KiepSat: 'Tỵ',
                CachGiao: 'Ngọ',
                VongThan: 'Hợi',
                NguQuy: 'Thân',
                NguyetDucHop: 'Đinh',
                NguyetPha: 'Tuất',
                NhatPha: 'Tuất',
                ThienDucHop: 'Đinh',
                NgocMon: 'Dậu'

            }
        case 'Tỵ':
            return object = {
                HoaCai: 'Sửu',
                TuongTinh: 'Dậu',
                DichMa: 'Hợi',
                DaoHoa: 'Ngọ',
                HuyetNhan: 'Tỵ',
                CoThan: 'Thân',
                QuaTu: 'Thìn',
                TangMon: 'Mùi',
                KiepSat: 'Dần',
                CachGiao: 'Mùi',
                VongThan: 'Thân',
                NguQuy: 'Dậu',
                NguyetDucHop: 'Ất',
                NguyetPha: 'Hợi',
                NhatPha: 'Hợi',
                ThienDucHop: 'Bính',
                NgocMon: 'Ngọ'

            }
        case 'Ngọ':
            return object = {
                HoaCai: 'Tuất',
                TuongTinh: 'Ngọ',
                DichMa: 'Thân',
                DaoHoa: 'Mão',
                HuyetNhan: 'Thìn',
                CoThan: 'Thân',
                QuaTu: 'Thìn',
                TangMon: 'Thân',
                KiepSat: 'Hợi',
                CachGiao: 'Thân',
                VongThan: 'Tỵ',
                NguQuy: 'Tuất',
                NguyetDucHop: 'Tân',
                NguyetPha: 'Tý',
                NhatPha: 'Tý',
                ThienDucHop: 'Dần',
                NgocMon: 'Mão'

            }
        case 'Mùi':
            return object = {
                HoaCai: 'Mùi',
                TuongTinh: 'Mão',
                DichMa: 'Tỵ',
                DaoHoa: 'Tý',
                HuyetNhan: 'Mão',
                CoThan: 'Thân',
                QuaTu: 'Thìn',
                TangMon: 'Dậu',
                KiepSat: 'Thân',
                CachGiao: 'Dậu',
                VongThan: 'Dần',
                NguQuy: 'Hợi',
                NguyetDucHop: 'Kỷ',
                NguyetPha: 'Sửu',
                NhatPha: 'Sửu',
                ThienDucHop: 'Kỷ',
                NgocMon: 'Tý'

            }
        case 'Thân':
            return object = {
                HoaCai: 'Thìn',
                TuongTinh: 'Tỵ',
                DichMa: 'Dần',
                DaoHoa: 'Dậu',
                HuyetNhan: 'Dần',
                CoThan: 'Hợi',
                QuaTu: 'Mùi',
                TangMon: 'Tuất',
                KiepSat: 'Tỵ',
                CachGiao: 'Tuất',
                VongThan: 'Hợi',
                NguQuy: 'Tý',
                NguyetDucHop: 'Đinh',
                NguyetPha: 'Dần',
                NhatPha: 'Dần',
                ThienDucHop: 'Tuất',
                NgocMon: 'Dậu'

            }
        case 'Dậu':
            return object = {
                HoaCai: 'Sửu',
                TuongTinh: 'Dậu',
                DichMa: 'Hợi',
                DaoHoa: 'Ngọ',
                HuyetNhan: 'Sửu',
                CoThan: 'Hợi',
                QuaTu: 'Mùi',
                TangMon: 'Hợi',
                KiepSat: 'Dần',
                CachGiao: 'Hợi',
                VongThan: 'Hợi',
                NguQuy: 'Sửu',
                NguyetDucHop: 'Ất',
                NguyetPha: 'Mão',
                NhatPha: 'Mão',
                ThienDucHop: 'Hợi',
                NgocMon: 'Ngọ'

            }
        case 'Tuất':
            return object = {
                HoaCai: 'Tuất',
                TuongTinh: 'Ngọ',
                DichMa: 'Thân',
                DaoHoa: 'Mão',
                HuyetNhan: 'Tý',
                CoThan: 'Hợi',
                QuaTu: 'Mùi',
                TangMon: 'Tý',
                KiepSat: 'Hợi',
                CachGiao: 'Tý',
                VongThan: 'Hợi',
                NguQuy: 'Dần',
                NguyetDucHop: 'Tân',
                NguyetPha: 'Thìn',
                NhatPha: 'Thìn',
                ThienDucHop: 'Tân',
                NgocMon: 'Mão'

            }
        case 'Hợi':
            return object = {
                HoaCai: 'Mùi',
                TuongTinh: 'Mão',
                DichMa: 'Tỵ',
                DaoHoa: 'Tý',
                HuyetNhan: 'Hợi',
                CoThan: 'Dần',
                QuaTu: 'Tuất',
                TangMon: 'Sửu',
                KiepSat: 'Thân',
                CachGiao: 'Sửu',
                VongThan: 'Dần',
                NguQuy: 'Mão',
                NguyetDucHop: 'Kỷ',
                NguyetPha: 'Tỵ',
                NhatPha: 'Tỵ',
                ThienDucHop: 'Canh',
                NgocMon: 'Tý'

            }

        default:
            break;
    }
}

export const convertChiMonth = (chiMonth) => {
    let object = {}

    switch (chiMonth) {
        case 'Tý':
            return object = {
                TDQN: 'Tốn',
                NDQN: 'Nhâm',
                NguyetKhong: 'Bính',
                NguPhu: 'Tỵ',

                ThienThu: 'Giáp Tý',
                DichMa: 'Dần',
                ThienXa: 'Giáp Tý'
            }
        case 'Sửu':
            return object = {
                TDQN: 'Canh',
                NDQN: 'Canh',
                NguyetKhong: 'Giáp',
                NguPhu: 'Thân',

                ThienThu: 'Giáp Tý',
                DichMa: 'Hợi',
                ThienXa: 'Giáp Tý'
            }
        case 'Dần':
            return object = {
                TDQN: 'Đinh',
                NDQN: 'Bính',
                NguyetKhong: 'Nhâm',
                NguPhu: 'Hợi',

                ThienThu: 'Mậu Dần',
                DichMa: 'Thân',
                ThienXa: 'Mậu Dần'
            }
        case 'Mão':
            return object = {
                TDQN: 'Khôn',
                NDQN: 'Giáp',
                NguyetKhong: 'Canh',
                NguPhu: 'Dần',

                ThienThu: 'Mậu Dần',
                DichMa: 'Tỵ',
                ThienXa: 'Mậu Dần'
            }
        case 'Thìn':
            return object = {
                TDQN: 'Nhâm',
                NDQN: 'Nhâm',
                NguyetKhong: 'Bính',
                NguPhu: 'Tỵ',

                ThienThu: 'Mậu Dần',
                DichMa: 'Dần',
                ThienXa: 'Mậu Dần'
            }
        case 'Tỵ':
            return object = {
                TDQN: 'Tân',
                NDQN: 'Canh',
                NguyetKhong: 'Giáp',
                NguPhu: 'Thân',

                ThienThu: 'Giáp Ngọ',
                DichMa: 'Hợi',
                ThienXa: 'Giáp Ngọ'
            }
        case 'Ngọ':
            return object = {
                TDQN: 'Càn',
                NDQN: 'Bính',
                NguyetKhong: 'Nhâm',
                NguPhu: 'Hợi',

                ThienThu: 'Giáp Ngọ',
                DichMa: 'Thân',
                ThienXa: 'Giáp Ngọ'
            }
        case 'Mùi':
            return object = {
                TDQN: 'Giáp',
                NDQN: 'Giáp',
                NguyetKhong: 'Canh',
                NguPhu: 'Dần',

                ThienThu: 'Giáp Ngọ',
                DichMa: 'Tỵ',
                ThienXa: 'Giáp Ngọ'
            }
        case 'Thân':
            return object = {
                TDQN: 'Quý',
                NDQN: 'Nhâm',
                NguyetKhong: 'Bính',
                NguPhu: 'Tỵ',

                ThienThu: 'Mậu Thân',
                DichMa: 'Dần',
                ThienXa: 'Mậu Thân'
            }
        case 'Dậu':
            return object = {
                TDQN: 'Cấn',
                NDQN: 'Canh',
                NguyetKhong: 'Giáp',
                NguPhu: 'Thân',

                ThienThu: 'Mậu Thân',
                DichMa: 'Hợi',
                ThienXa: 'Mậu Thân'
            }
        case 'Tuất':
            return object = {
                TDQN: 'Bính',
                NDQN: 'Bính',
                NguyetKhong: 'Nhâm',
                NguPhu: 'Hợi',

                ThienThu: 'Mậu Thân',
                DichMa: 'Thân',
                ThienXa: 'Mậu Thân'
            }
        case 'Hợi':
            return object = {
                TDQN: 'Ất',
                NDQN: 'Giáp',
                NguyetKhong: 'Canh',
                NguPhu: 'Dần',

                ThienThu: 'Giáp Tý',
                DichMa: 'Tỵ',
                ThienXa: 'Giáp Tý'
            }

        default:
            break;
    }
}

export const convertCanYear = (canYear) => {
    let object = {}

    switch (canYear) {
        case 'Giáp':
            return object = {
                TCQN: 'Tý Ngọ',
                QAQN: 'Tuất',
                DT: 'Dần',
                CL: 'Dần',
                DN: 'Mão',
                HD1: 'Hợi',
                HS: 'Hợi',
                HD2: 'Thân Ngọ',
                KD: 'Thìn',
                LT: 'Dần',
                LH1: 'Dậu',
                LH2: 'Dậu',
                MD: 'Tý',
                ND: 'Sửu',
                PN1: 'Dậu',
                PN2: 'Dậu',
                PTQ: 'Dần Tý',
                TT: 'Mậu',
                VX: 'Tỵ'
            }
                ;
        case 'Ất':
            return object = {
                TCQN: 'Tý Ngọ',
                QAQN: 'Hợi',
                DT: 'Thân',
                CL: 'Mão',
                DN: 'Dần',
                HD1: 'Ngọ',
                HS: 'Ngọ',
                HD2: 'Thân Ngọ',
                KD: 'Tỵ',
                LT: 'Mão',
                LH1: 'Tuất',
                LH2: 'Tuất',
                MD: 'Tỵ',
                ND: 'Tý',
                PN1: 'Tuất',
                PN2: 'Tuất',
                PTQ: 'Mão Sửu',
                TT: 'Kỷ',
                VX: 'Ngọ'
            }
                ;
        case 'Bính':
            return object = {
                TCQN: 'Mão Dậu',
                QAQN: 'Sửu',
                DT: 'Tỵ',
                CL: 'Tỵ',
                DN: 'Ngọ',
                HD1: 'Dần',
                HS: 'Dần',
                HD2: 'Dần',
                KD: 'Mùi',
                LT: 'Tỵ',
                LH1: 'Mùi',
                LH2: 'Mùi',
                MD: 'Mão',
                ND: 'Hợi',
                PN1: 'Tý',
                PN2: 'Tý',
                PTQ: 'Dần Tý',
                TT: 'Mậu',
                VX: 'Thân'
            }
                ;
        case 'Đinh':
            return object = {
                TCQN: 'Mão Dậu',
                QAQN: 'Dần',
                DT: 'Hợi',
                CL: 'Ngọ',
                DN: 'Tỵ',
                HD1: 'Dậu',
                HS: 'Dậu',
                HD2: 'Mùi',
                KD: 'Thân',
                LT: 'Ngọ',
                LH1: 'Thân',
                LH2: 'Thân',
                MD: 'Thân',
                ND: 'Dậu',
                PN1: 'Sửu',
                PN2: 'Sửu',
                PTQ: 'Hợi',
                TT: 'Tân',
                VX: 'Dậu'
            }
                ;
        case 'Mậu':
            return object = {
                TCQN: 'Thìn Tuất Sửu Mùi',
                QAQN: 'Sửu',
                DT: 'Tỵ',
                CL: 'Tỵ',
                DN: 'Ngọ',
                HD1: 'Dần',
                HS: 'Dần',
                HD2: 'Thìn',
                KD: 'Mùi',
                LT: 'Tỵ',
                LH1: 'Tỵ',
                LH2: 'Tỵ',
                MD: 'Mão',
                ND: 'Mùi',
                PN1: 'Tý',
                PN2: 'Tý',
                PTQ: 'Thân',
                TT: 'Nhâm',
                VX: 'Thân'
            }
                ;
        case 'Kỷ':
            return object = {
                TCQN: 'Thìn Tuất Sửu Mùi',
                QAQN: 'Dần',
                DT: 'Dần',
                CL: 'Ngọ',
                DN: 'Tỵ',
                HD1: 'Dậu',
                HS: 'Dậu',
                HD2: 'Thìn',
                KD: 'Thân',
                LT: 'Ngọ',
                LH1: 'Ngọ',
                LH2: 'Ngọ',
                MD: 'Thân',
                ND: 'Thân',
                PN1: 'Sửu',
                PN2: 'Sửu',
                PTQ: 'Mùi',
                TT: 'Quý',
                VX: 'Dậu'
            }
                ;
        case 'Canh':
            return object = {
                TCQN: 'Dần Hợi',
                QAQN: 'Thìn',
                DT: 'Thân',
                CL: 'Thân',
                DN: 'Dậu',
                HD1: 'Tỵ',
                HS: 'Tỵ',
                HD2: 'Tuất',
                KD: 'Tuất',
                LT: 'Thân',
                LH1: 'Thìn',
                LH2: 'Thìn',
                MD: 'Ngọ',
                ND: 'Mùi',
                PN1: 'Mão',
                PN2: 'Mão',
                PTQ: 'Ngọ',
                TT: 'Giáp',
                VX: 'Hợi'
            }
                ;
        case 'Tân':
            return object = {
                TCQN: 'Dần Hợi',
                QAQN: 'Tỵ',
                DT: 'Tỵ',
                CL: 'Dậu',
                DN: 'Thân',
                HD1: 'Tý',
                HS: 'Tý',
                HD2: 'Dậu',
                KD: 'Hợi',
                LT: 'Dậu',
                LH1: 'Mão',
                LH2: 'Mão',
                MD: 'Hợi',
                ND: 'Ngọ',
                PN1: 'Thìn',
                PN2: 'Thìn',
                PTQ: 'Tỵ',
                TT: 'Ất',
                VX: 'Tý'
            }
                ;
        case 'Nhâm':
            return object = {
                TCQN: 'Tỵ Thân',
                QAQN: 'Mùi',
                DT: 'Hợi',
                CL: 'Hợi',
                DN: 'Tý',
                HD1: 'Thân',
                HS: 'Thân',
                HD2: 'Tý',
                KD: 'Sửu',
                LT: 'Hợi',
                LH1: 'Hợi',
                LH2: 'Dần',
                MD: 'Dậu',
                ND: 'Tỵ',
                PN1: 'Ngọ',
                PN2: 'Ngọ',
                PTQ: 'Thìn',
                TT: 'Bính',
                VX: 'Dần'
            }
                ;
        case 'Quý':
            return object = {
                TCQN: 'Tỵ Thân',
                QAQN: 'Thân',
                DT: 'Tỵ',
                CL: 'Tý',
                DN: 'Hợi',
                HD1: 'Mão',
                HS: 'Mão',
                HD2: 'Thân',
                KD: 'Dần',
                LT: 'Tý',
                LH1: 'Dần',
                LH2: 'Hợi',
                MD: 'Dần',
                ND: 'Mão',
                PN1: 'Mùi',
                PN2: 'Mùi',
                PTQ: 'Mão Sửu',
                TT: 'Đinh',
                VX: 'Mão'
            }
                ;


        default:
            break;
    }

}

export const converNienChi = (chiYear) => {
    let object = {}

    switch (chiYear) {
        case 'Tý':
            return object = {
                LongDuc: 'Mùi',
                KimQuy: 'Tý',
                HongLoan: 'Mão',
                ThienCau: 'Tuất',
                CauGiao: 'Mão',
                TuePha: 'Ngọ',
                DaiHao: 'Ngọ',
                NguQuy: 'Thìn',
                DaoHoa: 'Dậu',
                HuyetNhan: 'Tuất',
                ThienCuong: 'Thìn',
                TueHinh: 'Mão',
                TueSat: 'Mùi',
                TaiSat: 'Ngọ',
                BenhPhu: 'Hợi',
                PhucDuc: 'Dậu',
                BachHo: 'Thân',
                QuanPhu: 'Thìn',
                ThaiAm: 'Mão',
                ThaiDuong: 'Sửu',
                ThaiTue: 'Tý',
                CoThan: 'Dần',
                QuaTu: 'Tuất',
                DichMa: 'Dần',
                TangMon: 'Dần',
                DuongNam: "Mùi",
                AmNam: "Tỵ",
                DieuKhach: 'Tuất',
                BanAn: 'Sửu',
                BaoBai: 'Mùi',
                BatToa: 'Tuất',
                CachGiao: 'Dần',
                ChiBoi: 'Thân',
                DiaGiai: 'Mùi',
                GiaiThan: 'Tuất',
                HamTri: 'Dậu',
                HoiKhi: 'Sửu',
                KiemPhong: 'Tý',
                KiepSat: "Tỵ",
                LanCan: 'Ngọ',
                LucAch: 'Mão',
                NguyetKhong: 'Bính',
                NguyetDucHop: 'Đinh',
                NguyetDuc: 'Nhâm',
                NguyetPha: 'Ngọ',
                PhiMa: 'Dậu',
                PhuTram: 'Tuất',
                PhucNgam: 'Tý',
                PhucThi: 'Tý',
                PTQN: 'Dậu',
                QuanTo: 'Mão',
                QuyenThiet: 'Dậu',
                TamDai: 'Thìn',
                TamSat: 'Tỵ Ngọ Mùi',
                ThienAch: 'Mùi',
                ThienDucHop: 'Thân',
                ThienHy: 'Dần',
                ThienKhoc: 'Ngọ',
                ThienKhong: 'Sửu',
                ThienSat: 'Mùi',
                ThienThu: 'Giáp Tý',
                ThienY: 'Sửu',
                TieuHao: 'Tỵ',
                TuPhu: 'Tỵ',
                Tuvi: 'Mùi',
                TucThan: 'Mão',
                TuongTinh: 'Tý',
                VongThan: 'Hợi',
                ThietTao: 'Tý',
            }
        case 'Sửu':
            return object = {
                LongDuc: 'Thân',
                KimQuy: 'Dậu',
                HongLoan: 'Dần',
                ThienCau: 'Hợi',
                CauGiao: 'Thìn',
                TuePha: 'Mùi',
                DaiHao: 'Mùi',
                NguQuy: 'Tỵ',
                DaoHoa: 'Ngọ',
                HuyetNhan: 'Dậu',
                ThienCuong: 'Mão',
                TueHinh: 'Tuất',

                TueSat: 'Thìn',
                TaiSat: 'Mão',
                BenhPhu: 'Tý',
                PhucDuc: 'Tuất',
                BachHo: 'Dậu',
                QuanPhu: 'Tỵ',
                ThaiAm: 'Thìn',
                ThaiDuong: 'Dần',
                ThaiTue: 'Sửu',
                CoThan: 'Dần',
                QuaTu: 'Tuất',
                DichMa: 'Hợi',
                TangMon: 'Mão',
                DuongNam: "Thân",
                AmNam: "Ngọ",
                DieuKhach: 'Hợi',
                BanAn: 'Tuất',
                BaoBai: 'Thân',
                BatToa: 'Hợi',
                CachGiao: 'Mão',
                ChiBoi: 'Tỵ',
                DiaGiai: 'Mùi',
                GiaiThan: 'Dậu',
                HamTri: 'Ngọ',
                HoiKhi: 'Dần',
                KiemPhong: 'Sửu',
                KiepSat: "Ngọ",
                LanCan: 'Mùi',
                LucAch: 'Tý',
                NguyetKhong: 'Giáp',
                NguyetDucHop: 'Ất',
                NguyetDuc: 'Canh',
                NguyetPha: 'Mùi',
                PhiMa: 'Tuất',
                PhuTram: 'Dậu',
                PhucNgam: 'Sửu',
                PhucThi: 'Sửu',
                PTQN: 'Tuất',
                QuanTo: 'Thìn',
                QuyenThiet: 'Tuất',
                TamDai: 'Tỵ',
                TamSat: 'Dần Mão Thìn',
                ThienAch: 'Thân',
                ThienDucHop: 'Ất',
                ThienHy: 'Thân',
                ThienKhoc: 'Tỵ',
                ThienKhong: 'Dần',
                ThienSat: 'Thìn',
                ThienThu: 'Giáp Tý',
                ThienY: 'Dần',
                TieuHao: 'Ngọ',
                TuPhu: 'Ngọ',
                Tuvi: 'Thân',
                TucThan: 'Tý',
                TuongTinh: 'Dậu',
                VongThan: 'Thân',
                ThietTao: 'Dậu',
            }
        case 'Dần':
            return object = {
                LongDuc: 'Dậu',
                KimQuy: 'Ngọ',
                HongLoan: 'Sửu',
                ThienCau: 'Tý',
                CauGiao: 'Tỵ',
                TuePha: 'Thân',
                DaiHao: 'Thân',
                NguQuy: 'Ngọ',
                DaoHoa: 'Mão',
                HuyetNhan: 'Thân',
                ThienCuong: 'Dần',
                TueHinh: 'Tỵ',

                TueSat: 'Sửu',
                TaiSat: 'Tý',
                BenhPhu: 'Sửu',
                PhucDuc: 'Hợi',
                BachHo: 'Tuất',
                QuanPhu: 'Ngọ',
                ThaiAm: 'Tỵ',
                ThaiDuong: 'Mão',
                ThaiTue: 'Dần',
                CoThan: 'Tỵ',
                QuaTu: 'Sửu',
                DichMa: 'Thân',
                TangMon: 'Thìn',

                DuongNam: "Dậu",
                AmNam: "Mùi",

                DieuKhach: 'Tý',
                BanAn: 'Mùi',
                BaoBai: 'Dậu',
                BatToa: 'Tý',
                CachGiao: 'Thìn',
                ChiBoi: 'Dần',
                DiaGiai: 'Thân',
                GiaiThan: 'Thân',
                HamTri: 'Mão',
                HoiKhi: 'Mão',
                KiemPhong: 'Dần',
                KiepSat: "Hợi",
                LanCan: 'Thân',
                LucAch: 'Dậu',
                NguyetKhong: 'Nhâm',
                NguyetDucHop: 'Tân',
                NguyetDuc: 'Bính',
                NguyetPha: 'Thân',
                PhiMa: 'Hợi',
                PhuTram: 'Thân',
                PhucNgam: 'Dần',
                PhucThi: 'Dần',
                PTQN: 'Hợi',
                QuanTo: 'Tỵ',
                QuyenThiet: 'Hợi',
                TamDai: 'Ngọ',
                TamSat: 'Hợi Tý Sửu',
                ThienAch: 'Dậu',
                ThienDucHop: 'Nhâm',
                ThienHy: 'Mùi',
                ThienKhoc: 'Thìn',
                ThienKhong: 'Mão',
                ThienSat: 'Sửu',
                ThienThu: 'Mậu Dần',
                ThienY: 'Mão',
                TieuHao: 'Mùi',
                TuPhu: 'Mùi',
                Tuvi: 'Dậu',
                TucThan: 'Dậu',
                TuongTinh: 'Ngọ',
                VongThan: 'Tỵ',
                ThietTao: 'Mùi',
            }
        case 'Mão':
            return object = {
                LongDuc: 'Tuất',
                KimQuy: 'Mão',
                HongLoan: 'Tý',
                ThienCau: 'Sửu',
                CauGiao: 'Ngọ',
                TuePha: 'Dậu',
                DaiHao: 'Dậu',
                NguQuy: 'Mùi',
                DaoHoa: 'Tý',
                HuyetNhan: 'Mùi',
                ThienCuong: 'Sửu',
                TueHinh: 'Tý',

                TueSat: 'Tuất',
                TaiSat: 'Dậu',
                BenhPhu: 'Dần',
                PhucDuc: 'Tý',
                BachHo: 'Hợi',
                QuanPhu: 'Mùi',
                ThaiAm: 'Ngọ',
                ThaiDuong: 'Thìn',
                ThaiTue: 'Mão',
                CoThan: 'Tỵ',
                QuaTu: 'Sửu',
                DichMa: 'Tỵ',
                TangMon: 'Tỵ',

                DuongNam: "Tuất",
                AmNam: "Thân",

                DieuKhach: 'Sửu',
                BanAn: 'Thìn',
                BaoBai: 'Tuất',
                BatToa: 'Sửu',
                CachGiao: 'Tỵ',
                ChiBoi: 'Hợi',
                DiaGiai: 'Thân',
                GiaiThan: 'Mùi',
                HamTri: 'Tý',
                HoiKhi: 'Thìn',
                KiemPhong: 'Mão',
                KiepSat: "Thân",
                LanCan: 'Dậu',
                LucAch: 'Ngọ',
                NguyetKhong: 'Canh',
                NguyetDucHop: 'Kỷ',
                NguyetDuc: 'Giáp',
                NguyetPha: 'Dậu',
                PhiMa: 'Tý',
                PhuTram: 'Mùi',
                PhucNgam: 'Mão',
                PhucThi: 'Mão',
                PTQN: 'Tý',
                QuanTo: 'Ngọ',
                QuyenThiet: 'Tý',
                TamDai: 'Mùi',
                TamSat: 'Thân Dậu Tuất',
                ThienAch: 'Tuất',
                ThienDucHop: 'Tỵ',
                ThienHy: 'Ngọ',
                ThienKhoc: 'Mão',
                ThienKhong: 'Thìn',
                ThienSat: 'Tuất',
                ThienThu: 'Mậu Dần',
                ThienY: 'Thìn',
                TieuHao: 'Thân',
                TuPhu: 'Thân',
                Tuvi: 'Tuất',
                TucThan: 'Ngọ',
                TuongTinh: 'Mão',
                VongThan: 'Dần',
                ThietTao: 'Thân',
            }
        case 'Thìn':
            return object = {
                LongDuc: 'Hợi',
                KimQuy: 'Tý',
                HongLoan: 'Hợi',
                ThienCau: 'Dần',
                CauGiao: 'Mùi',
                TuePha: 'Tuất',
                DaiHao: 'Tuất',
                NguQuy: 'Thân',
                DaoHoa: 'Dậu',
                HuyetNhan: 'Ngọ',
                ThienCuong: 'Tý',
                TueHinh: 'Thìn',

                TueSat: 'Mùi',
                TaiSat: 'Ngọ',
                BenhPhu: 'Mão',
                PhucDuc: 'Sửu',
                BachHo: 'Tý',
                QuanPhu: 'Thân',
                ThaiAm: 'Mùi',
                ThaiDuong: 'Tỵ',
                ThaiTue: 'Thìn',
                CoThan: 'Tỵ',
                QuaTu: 'Sửu',
                DichMa: 'Dần',
                TangMon: 'Ngọ',

                DuongNam: "Hợi",
                AmNam: "Dậu",

                DieuKhach: 'Dần',
                BanAn: 'Sửu',
                BaoBai: 'Hợi',
                BatToa: 'Dần',
                CachGiao: 'Ngọ',
                ChiBoi: 'Thân',
                DiaGiai: 'Dậu',
                GiaiThan: 'Ngọ',
                HamTri: 'Dậu',
                HoiKhi: 'Tỵ',
                KiemPhong: 'Thìn',
                KiepSat: "Tỵ",
                LanCan: 'Tuất',
                LucAch: 'Mão',
                NguyetKhong: 'Bính',
                NguyetDucHop: 'Đinh',
                NguyetDuc: 'Nhâm',
                NguyetPha: 'Tuất',
                PhiMa: 'Sửu',
                PhuTram: 'Ngọ',
                PhucNgam: 'Thìn',
                PhucThi: 'Thìn',
                PTQN: 'Sửu',
                QuanTo: 'Mùi',
                QuyenThiet: 'Sửu',
                TamDai: 'Thân',
                TamSat: 'Tỵ Ngọ Mùi',
                ThienAch: 'Hợi',
                ThienDucHop: 'Đinh',
                ThienHy: 'Tỵ',
                ThienKhoc: 'Dần',
                ThienKhong: 'Tỵ',
                ThienSat: 'Mùi',
                ThienThu: 'Mậu Dần',
                ThienY: 'Tỵ',
                TieuHao: 'Dậu',
                TuPhu: 'Dậu',
                Tuvi: 'Hợi',
                TucThan: 'Mão',
                TuongTinh: 'Tý',
                VongThan: 'Hợi',
                ThietTao: 'Tý',
            }
        case 'Tỵ':
            return object = {
                LongDuc: 'Tý',
                KimQuy: 'Dậu',
                HongLoan: 'Tuất',
                ThienCau: 'Mão',
                CauGiao: 'Thân',
                TuePha: 'Hợi',
                DaiHao: 'Hợi',
                NguQuy: 'Dậu',
                DaoHoa: 'Ngọ',
                HuyetNhan: 'Tý',
                ThienCuong: 'Hợi',
                TueHinh: 'Thân',

                TueSat: 'Thìn',
                TaiSat: 'Mão',
                BenhPhu: 'Thìn',
                PhucDuc: 'Dần',
                BachHo: 'Sửu',
                QuanPhu: 'Dậu',
                ThaiAm: 'Thân',
                ThaiDuong: 'Ngọ',
                ThaiTue: 'Tỵ',
                CoThan: 'Thân',
                QuaTu: 'Thìn',
                DichMa: 'Hợi',
                TangMon: 'Mùi',

                DuongNam: "Tý",
                AmNam: "Tuất",

                DieuKhach: 'Mão',
                BanAn: 'Tuất',
                BaoBai: 'Tý',
                BatToa: 'Mão',
                CachGiao: 'Mùi',
                ChiBoi: 'Tỵ',
                DiaGiai: 'Dậu',
                GiaiThan: 'Tỵ',
                HamTri: 'Ngọ',
                HoiKhi: 'Ngọ',
                KiemPhong: 'Tỵ',
                KiepSat: "Dần",
                LanCan: 'Hợi',
                LucAch: 'Tý',
                NguyetKhong: 'Giáp',
                NguyetDucHop: 'Ất',
                NguyetDuc: 'Canh',
                NguyetPha: 'Hợi',
                PhiMa: 'Dần',
                PhuTram: 'Tỵ',
                PhucNgam: 'Tỵ',
                PhucThi: 'Tỵ',
                PTQN: 'Dần',
                QuanTo: 'Thân',
                QuyenThiet: 'Dần',
                TamDai: 'Dậu',
                TamSat: 'Dần Mão Thìn',
                ThienAch: 'Tý',
                ThienDucHop: 'Bính',
                ThienHy: 'Thìn',
                ThienKhoc: 'Sửu',
                ThienKhong: 'Ngọ',
                ThienSat: 'Thìn',
                ThienThu: 'Giáp Ngọ',
                ThienY: 'Ngọ',
                TieuHao: 'Tuất',
                TuPhu: 'Tuất',
                Tuvi: 'Tý',
                TucThan: 'Tý',
                TuongTinh: 'Dậu',
                VongThan: 'Thân',
                ThietTao: 'Dậu',
            }
        case 'Ngọ':
            return object = {
                LongDuc: 'Sửu',
                KimQuy: 'Ngọ',
                HongLoan: 'Dậu',
                ThienCau: 'Thìn',
                CauGiao: 'Dậu',
                TuePha: 'Tý',
                DaiHao: 'Tý',
                NguQuy: 'Tuất',
                DaoHoa: 'Mão',
                HuyetNhan: 'Thìn',
                ThienCuong: 'Tuất',
                TueHinh: 'Ngọ',

                TueSat: 'Sửu',
                TaiSat: 'Tý',
                BenhPhu: 'Tỵ',
                PhucDuc: 'Mão',
                BachHo: 'Dần',
                QuanPhu: 'Tuất',
                ThaiAm: 'Dậu',
                ThaiDuong: 'Mùi',
                ThaiTue: 'Ngọ',
                CoThan: 'Thân',
                QuaTu: 'Thìn',
                DichMa: 'Thân',
                TangMon: 'Thân',

                DuongNam: "Sửu",
                AmNam: "Hợi",

                DieuKhach: 'Thìn',
                BanAn: 'Mùi',
                BaoBai: 'Sửu',
                BatToa: 'Thìn',
                CachGiao: 'Thân',
                ChiBoi: 'Dần',
                DiaGiai: 'Tuất',
                GiaiThan: 'Thìn',
                HamTri: 'Mão',
                HoiKhi: 'Mùi',
                KiemPhong: 'Ngọ',
                KiepSat: "Hợi",
                LanCan: 'Tý',
                LucAch: 'Dậu',
                NguyetKhong: 'Nhâm',
                NguyetDucHop: 'Tân',
                NguyetDuc: 'Bính',
                NguyetPha: 'Tý',
                PhiMa: 'Mão',
                PhuTram: 'Thìn',
                PhucNgam: 'Ngọ',
                PhucThi: 'Ngọ',
                PTQN: 'Mão',
                QuanTo: 'Dậu',
                QuyenThiet: 'Mão',
                TamDai: 'Tuất',
                TamSat: 'Hợi Tý Sửu',
                ThienAch: 'Sửu',
                ThienDucHop: 'Dần',
                ThienHy: 'Mão',
                ThienKhoc: 'Tý',
                ThienKhong: 'Mùi',
                ThienSat: 'Sửu',
                ThienThu: 'Giáp Ngọ',
                ThienY: 'Mùi',
                TieuHao: 'Hợi',
                TuPhu: 'Hợi',
                Tuvi: 'Sửu',
                TucThan: 'Dậu',
                TuongTinh: 'Ngọ',
                VongThan: 'Tỵ',
                ThietTao: 'Mùi',
            }
        case 'Mùi':
            return object = {
                LongDuc: 'Dần',
                KimQuy: 'Mão',
                HongLoan: 'Thân',
                ThienCau: 'Tỵ',
                CauGiao: 'Tuất',
                TuePha: 'Sửu',
                DaiHao: 'Sửu',
                NguQuy: 'Hợi',
                DaoHoa: 'Tý',
                HuyetNhan: 'Mão',
                ThienCuong: 'Dậu',
                TueHinh: 'Sửu',

                TueSat: 'Tuất',
                TaiSat: 'Dậu',
                BenhPhu: 'Ngọ',
                PhucDuc: 'Thìn',
                BachHo: 'Mão',
                QuanPhu: 'Hợi',
                ThaiAm: 'Tuất',
                ThaiDuong: 'Thân',
                ThaiTue: 'Mùi',
                CoThan: 'Thân',
                QuaTu: 'Thìn',
                DichMa: 'Tỵ',
                TangMon: 'Dậu',

                DuongNam: "Dần",
                AmNam: "Tý",

                DieuKhach: 'Tỵ',
                BanAn: 'Thìn',
                BaoBai: 'Dần',
                BatToa: 'Tỵ',
                CachGiao: 'Dậu',
                ChiBoi: 'Hợi',
                DiaGiai: 'Tuất',
                GiaiThan: 'Mão',
                HamTri: 'Tý',
                HoiKhi: 'Thân',
                KiemPhong: 'Mùi',
                KiepSat: "Thân",
                LanCan: 'Sửu',
                LucAch: 'Ngọ',
                NguyetKhong: 'Canh',
                NguyetDucHop: 'Kỷ',
                NguyetDuc: 'Giáp',
                NguyetPha: 'Sửu',
                PhiMa: 'Thìn',
                PhuTram: 'Mão',
                PhucNgam: 'Mùi',
                PhucThi: 'Mùi',
                PTQN: 'Thìn',
                QuanTo: 'Tuất',
                QuyenThiet: 'Thìn',
                TamDai: 'Hợi',
                TamSat: 'Thân Dậu Tuất',
                ThienAch: 'Dần',
                ThienDucHop: 'Kỷ',
                ThienHy: 'Dần',
                ThienKhoc: 'Hợi',
                ThienKhong: 'Thân',
                ThienSat: 'Tuất',
                ThienThu: 'Giáp Ngọ',
                ThienY: 'Thân',
                TieuHao: 'Tý',
                TuPhu: 'Tý',
                Tuvi: 'Dần',
                TucThan: 'Ngọ',
                TuongTinh: 'Mão',
                VongThan: 'Dần',
                ThietTao: 'Thân',
            }
        case 'Thân':
            return object = {
                LongDuc: 'Mão',
                KimQuy: 'Tý',
                HongLoan: 'Mùi',
                ThienCau: 'Ngọ',
                CauGiao: 'Hợi',
                TuePha: 'Dần',
                DaiHao: 'Dần',
                NguQuy: 'Tý',
                DaoHoa: 'Dậu',
                HuyetNhan: 'Dần',
                ThienCuong: 'Thân',
                TueHinh: 'Dần',

                TueSat: 'Mùi',
                TaiSat: 'Ngọ',
                BenhPhu: 'Mùi',
                PhucDuc: 'Tỵ',
                BachHo: 'Thìn',
                QuanPhu: 'Tý',
                ThaiAm: 'Hợi',
                ThaiDuong: 'Dậu',
                ThaiTue: 'Thân',
                CoThan: 'Hợi',
                QuaTu: 'Mùi',
                DichMa: 'Dần',
                TangMon: 'Tuất',

                DuongNam: "Mão",
                AmNam: "Sửu",

                DieuKhach: 'Ngọ',
                BanAn: 'Sửu',
                BaoBai: 'Mão',
                BatToa: 'Ngọ',
                CachGiao: 'Tuất',
                ChiBoi: 'Thân',
                DiaGiai: 'Hợi',
                GiaiThan: 'Dần',
                HamTri: 'Dậu',
                HoiKhi: 'Dậu',
                KiemPhong: 'Thân',
                KiepSat: "Tỵ",
                LanCan: 'Dần',
                LucAch: 'Mão',
                NguyetKhong: 'Bính',
                NguyetDucHop: 'Đinh',
                NguyetDuc: 'Nhâm',
                NguyetPha: 'Dần',
                PhiMa: 'Tỵ',
                PhuTram: 'Dần',
                PhucNgam: 'Thân',
                PhucThi: 'Thân',
                PTQN: 'Tỵ',
                QuanTo: 'Hợi',
                QuyenThiet: 'Tỵ',
                TamDai: 'Tý',
                TamSat: 'Tỵ Ngọ Mùi',
                ThienAch: 'Mão',
                ThienDucHop: 'Tuất',
                ThienHy: 'Sửu',
                ThienKhoc: 'Tuất',
                ThienKhong: 'Dậu',
                ThienSat: 'Mùi',
                ThienThu: 'Mậu Thân',
                ThienY: 'Dậu',
                TieuHao: 'Sửu',
                TuPhu: 'Sửu',
                Tuvi: 'Mão',
                TucThan: 'Mão',
                TuongTinh: 'Tý',
                VongThan: 'Hợi',
                ThietTao: 'Tý',
            }
        case 'Dậu':
            return object = {
                LongDuc: 'Thìn',
                KimQuy: 'Dậu',
                HongLoan: 'Ngọ',
                ThienCau: 'Mùi',
                CauGiao: 'Tý',
                TuePha: 'Mão',
                DaiHao: 'Mão',
                NguQuy: 'Sửu',
                DaoHoa: 'Ngọ',
                HuyetNhan: 'Sửu',
                ThienCuong: 'Mùi',
                TueHinh: 'Dậu',

                TueSat: 'Thìn',
                TaiSat: 'Mão',
                BenhPhu: 'Thân',
                PhucDuc: 'Ngọ',
                BachHo: 'Tỵ',
                QuanPhu: 'Sửu',
                ThaiAm: 'Tý',
                ThaiDuong: 'Tuất',
                ThaiTue: 'Dậu',
                CoThan: 'Hợi',
                QuaTu: 'Mùi',
                DichMa: 'Hợi',
                TangMon: 'Hợi',

                DuongNam: "Thìn",
                AmNam: "Dần",

                DieuKhach: 'Mùi',
                BanAn: 'Tuất',
                BaoBai: 'Thìn',
                BatToa: 'Mùi',
                CachGiao: 'Hợi',
                ChiBoi: 'Tỵ',
                DiaGiai: 'Hợi',
                GiaiThan: 'Sửu',
                HamTri: 'Ngọ',
                HoiKhi: 'Tuất',
                KiemPhong: 'Dậu',
                KiepSat: "Dần",
                LanCan: 'Mão',
                LucAch: 'Tý',
                NguyetKhong: 'Giáp',
                NguyetDucHop: 'Ất',
                NguyetDuc: 'Canh',
                NguyetPha: 'Mão',
                PhiMa: 'Ngọ',
                PhuTram: 'Sửu',
                PhucNgam: 'Dậu',
                PhucThi: 'Dậu',
                PTQN: 'Ngọ',
                QuanTo: 'Tý',
                QuyenThiet: 'Ngọ',
                TamDai: 'Sửu',
                TamSat: 'Dần Mão Thìn',
                ThienAch: 'Thìn',
                ThienDucHop: 'Hợi',
                ThienHy: 'Tý',
                ThienKhoc: 'Dần',
                ThienKhong: 'Tuất',
                ThienSat: 'Thìn',
                ThienThu: 'Mậu Thân',
                ThienY: 'Tuất',
                TieuHao: 'Dần',
                TuPhu: 'Dần',
                Tuvi: 'Thìn',
                TucThan: 'Tý',
                TuongTinh: 'Dậu',
                VongThan: 'Hợi',
                ThietTao: 'Dậu',
            }
        case 'Tuất':
            return object = {
                LongDuc: 'Tỵ',
                KimQuy: 'Ngọ',
                HongLoan: 'Tỵ',
                ThienCau: 'Thân',
                CauGiao: 'Sửu',
                TuePha: 'Thìn',
                DaiHao: 'Thìn',
                NguQuy: 'Dần',
                DaoHoa: 'Mão',
                HuyetNhan: 'Tý',
                ThienCuong: 'Ngọ',
                TueHinh: 'Mùi',

                TueSat: 'Sửu',
                TaiSat: 'Tý',
                BenhPhu: 'Dậu',
                PhucDuc: 'Mùi',
                BachHo: 'Ngọ',
                QuanPhu: 'Dần',
                ThaiAm: 'Sửu',
                ThaiDuong: 'Hợi',
                ThaiTue: 'Tuất',
                CoThan: 'Hợi',
                QuaTu: 'Mùi',
                DichMa: 'Thân',
                TangMon: 'Tý',

                DuongNam: "Tỵ",
                AmNam: "Mão",

                DieuKhach: 'Thân',
                BanAn: 'Mùi',
                BaoBai: 'Tỵ',
                BatToa: 'Thân',
                CachGiao: 'Tý',
                ChiBoi: 'Dần',
                DiaGiai: 'Ngọ',
                GiaiThan: 'Tý',
                HamTri: 'Mão',
                HoiKhi: 'Hợi',
                KiemPhong: 'Tuất',
                KiepSat: "Hợi",
                LanCan: 'Thìn',
                LucAch: 'Dậu',
                NguyetKhong: 'Nhâm',
                NguyetDucHop: 'Tân',
                NguyetDuc: 'Bính',
                NguyetPha: 'Thìn',
                PhiMa: 'Mùi',
                PhuTram: 'Tý',
                PhucNgam: 'Tuất',
                PhucThi: 'Tuất',
                PTQN: 'Mùi',
                QuanTo: 'Sửu',
                QuyenThiet: 'Mùi',
                TamDai: 'Dần',
                TamSat: 'Hợi Tý Sửu',
                ThienAch: 'Tỵ',
                ThienDucHop: 'Tân',
                ThienHy: 'Hợi',
                ThienKhoc: 'Thân',
                ThienKhong: 'Hợi',
                ThienSat: 'Sửu',
                ThienThu: 'Mậu Thân',
                ThienY: 'Hợi',
                TieuHao: 'Mão',
                TuPhu: 'Mão',
                Tuvi: 'Tỵ   ',
                TucThan: 'Dậu',
                TuongTinh: 'Ngọ',
                VongThan: 'Hợi',
                ThietTao: 'Mùi',
            }
        case 'Hợi':
            return object = {
                LongDuc: 'Ngọ',
                KimQuy: 'Mão',
                HongLoan: 'Thìn',
                ThienCau: 'Dậu',
                CauGiao: 'Dần',
                TuePha: 'Tỵ',
                DaiHao: 'Tỵ',
                NguQuy: 'Mão',
                DaoHoa: 'Tý',
                HuyetNhan: 'Hợi',
                ThienCuong: 'Tỵ',
                TueHinh: 'Hợi',

                TueSat: 'Tuất',
                TaiSat: 'Dậu',
                BenhPhu: 'Tuất',
                PhucDuc: 'Thân',
                BachHo: 'Mùi',
                QuanPhu: 'Mão',
                ThaiAm: 'Dần',
                ThaiDuong: 'Tý',
                ThaiTue: 'Hợi',
                CoThan: 'Dần',
                QuaTu: 'Tuất',
                DichMa: 'Tỵ',
                TangMon: 'Sửu',

                DuongNam: "Ngọ",
                AmNam: "Thìn",

                DieuKhach: 'Dậu',
                BanAn: 'Thìn',
                BaoBai: 'Ngọ',
                BatToa: 'Dậu',
                CachGiao: 'Sửu',
                ChiBoi: 'Hợi',
                DiaGiai: 'Ngọ',
                GiaiThan: 'Hợi',
                HamTri: 'Tý',
                HoiKhi: 'Tý',
                KiemPhong: 'Hợi',
                KiepSat: "Thân",
                LanCan: 'Tỵ',
                LucAch: 'Ngọ',
                NguyetKhong: 'Canh',
                NguyetDucHop: 'Kỷ',
                NguyetDuc: 'Giáp',
                NguyetPha: 'Tỵ',
                PhiMa: 'Thân',
                PhuTram: 'Hợi',
                PhucNgam: 'Hợi',
                PhucThi: 'Hợi',
                PTQN: 'Thân',
                QuanTo: 'Dần',
                QuyenThiet: 'Thân',
                TamDai: 'Mão',
                TamSat: 'Thân Dậu Tuất',
                ThienAch: 'Ngọ',
                ThienDucHop: 'Canh',
                ThienHy: 'Tuất',
                ThienKhoc: 'Mùi',
                ThienKhong: 'Tý',
                ThienSat: 'Tuất',
                ThienThu: 'Giáp Tý',
                ThienY: 'Tý',
                TieuHao: 'Thìn',
                TuPhu: 'Thìn',
                Tuvi: 'Ngọ',
                TucThan: 'Ngọ',
                TuongTinh: 'Mão',
                VongThan: 'Dần',
                ThietTao: 'Thân',
            }
        default:
            break;
    }
}

export const nguyenThan = (chiYear) => {
    let object = {}
    switch (chiYear) {
        case 'Tý':
            return object = {
                DuongNam: 'Mùi',
                AmNam: 'Tỵ'
            }
        case 'Sửu':
            return object = {
                DuongNam: 'Thân',
                AmNam: 'Ngọ',
            }
        case 'Dần':
            return object = {
                DuongNam: 'Dậu',
                AmNam: 'Mùi',
            }
        case 'Mão':
            return object = {
                DuongNam: 'Tuất',
                AmNam: 'Thân',
            }
        case 'Thìn':
            return object = {
                DuongNam: 'Hợi',
                AmNam: 'Dậu',
            }
        case 'Tỵ':
            return object = {
                DuongNam: 'Tý',
                AmNam: 'Tuất',
            }
        case 'Ngọ':
            return object = {
                DuongNam: 'Sửu',
                AmNam: 'Hợi',
            }
        case 'Mùi':
            return object = {
                DuongNam: 'Dần',
                AmNam: 'Tý',
            }
        case 'Thân':
            return object = {
                DuongNam: 'Mão',
                AmNam: 'Sửu',
            }
        case 'Dậu':
            return object = {
                DuongNam: 'Thìn',
                AmNam: 'Dần',
            }
        case 'Tuất':
            return object = {
                DuongNam: 'Tỵ',
                AmNam: 'Mão',
            }
        case 'Hợi':
            return object = {
                DuongNam: 'Ngọ',
                AmNam: 'Thìn',
            }


        default:
            break;
    }
}

export const convertChiHour = (chiHour) => {
    let object = {}

    switch (chiHour) {
        case 'Tý':
            return object = {
                NguyetDucHop: 'Đinh',
                NguyetPha: 'Ngọ',
                ThienDucHop: 'Thân',
                NgocMon: 'Dậu'
            }
        case 'Sửu':
            return object = {
                NguyetDucHop: 'Ất',
                NguyetPha: 'Mùi',
                ThienDucHop: 'Ất',
                NgocMon: 'Ngọ'
            }
        case 'Dần':
            return object = {
                NguyetDucHop: 'Tân',
                NguyetPha: 'Thân',
                ThienDucHop: 'Nhâm',
                NgocMon: 'Mão'
            }
        case 'Mão':
            return object = {
                NguyetDucHop: 'Kỷ',
                NguyetPha: 'Dậu',
                ThienDucHop: 'Tỵ',
                NgocMon: 'Tý'
            }
        case 'Thìn':
            return object = {
                NguyetDucHop: 'Đinh',
                NguyetPha: 'Tuất',
                ThienDucHop: 'Đinh',
                NgocMon: 'Dậu'
            }
        case 'Tỵ':
            return object = {
                NguyetDucHop: 'Ất',
                NguyetPha: 'Hợi',
                ThienDucHop: 'Bính',
                NgocMon: 'Ngọ'
            }
        case 'Ngọ':
            return object = {
                NguyetDucHop: 'Tân',
                NguyetPha: 'Tý',
                ThienDucHop: 'Dần',
                NgocMon: 'Mão'
            }
        case 'Mùi':
            return object = {
                NguyetDucHop: 'Kỷ',
                NguyetPha: 'Sửu',
                ThienDucHop: 'Kỷ',
                NgocMon: 'Tý'
            }
        case 'Thân':
            return object = {
                NguyetDucHop: 'Đinh',
                NguyetPha: 'Dần',
                ThienDucHop: 'Tuất',
                NgocMon: 'Dậu'
            }
        case 'Hợi':
            return object = {
                NguyetDucHop: 'Kỷ',
                NguyetPha: 'Tỵ',
                ThienDucHop: 'Canh',
                NgocMon: 'Tý'
            }
        case 'Dậu':
            return object = {
                NguyetDucHop: 'Ất',
                NguyetPha: 'Mão',
                ThienDucHop: 'Hợi',
                NgocMon: 'Ngọ'
            }
        case 'Tuất':
            return object = {
                NguyetDucHop: 'Tân',
                NguyetPha: 'Thìn',
                ThienDucHop: 'Tân',
                NgocMon: 'Mão'
            }


        default:
            break;
    }
}

export const ThanSatDay = () => {
    let object = {}

    object = {
        row_one: {
            LucTu: 'Mậu Tý',
            DaMuu: 'Mậu Tý',
            ThapLinh: 'Giáp Thìn',
            HongDiem: 'Bính Dần',
            AmSaiDuongThac: 'Tân Mão',
            KhoiCuong: 'Mậu Thìn',
            CoLoan: 'Giáp Dần',
            NhatDuc: 'Giáp Dần',
            NhatQuy: 'Đinh Dậu',
            TamDaiQuy: 'Dần Thìn Thân',
            ThapAcDaiBai: 'Giáp Thìn',
            ThoaiThan: 'Đinh Sửu',
            TienThan: 'Giáp Tý'
        },
        row_two: {
            LucTu: 'Kỷ Sửu',
            DaMuu: 'Tân Tỵ',
            ThapLinh: 'Ất Hợi',
            HongDiem: 'Đinh Mùi',
            AmSaiDuongThac: 'Nhâm Thìn',
            KhoiCuong: 'Mậu Tuất',
            CoLoan: 'Ất Tỵ',
            NhatDuc: 'Bính Thìn',
            NhatQuy: 'Đinh Hợi',
            TamDaiQuy: 'Mão Tỵ Ngọ',
            ThapAcDaiBai: 'Ất Tỵ',
            ThoaiThan: 'Đinh Mùi',
            TienThan: 'Giáp Ngọ'
        },
        row_three: {
            LucTu: 'Tân Tỵ',
            DaMuu: '',
            ThapLinh: 'Bính Thìn',
            HongDiem: 'Quý Mùi',
            AmSaiDuongThac: 'Quý Tỵ',
            KhoiCuong: 'Canh Thìn',
            CoLoan: 'Bính Ngọ',
            NhatDuc: 'Mậu Thìn',
            NhatQuy: 'Quý Mão',
            TamDaiQuy: 'Hợi Tý Dậu',
            ThapAcDaiBai: 'Bính Thân',
            ThoaiThan: 'Nhâm Thìn',
            TienThan: 'Kỷ Mão'
        },
        row_four: {
            LucTu: 'Đinh Mùi',
            DaMuu: '',
            ThapLinh: 'Đinh Dậu',
            HongDiem: 'Tân Mùi',
            AmSaiDuongThac: 'Bính Ngọ',
            KhoiCuong: 'Canh Tuất',
            CoLoan: 'Đinh Tỵ',
            NhatDuc: 'Canh Thìn',
            NhatQuy: 'Quý Tỵ',
            TamDaiQuy: '',
            ThapAcDaiBai: 'Đinh Hợi',
            ThoaiThan: 'Nhâm Tuất',
            TienThan: 'Kỷ Dậu'
        },
        row_five: {
            LucTu: 'Bính Ngọ',
            DaMuu: '',
            ThapLinh: 'Mậu Ngọ',
            HongDiem: 'Ất Mùi',
            AmSaiDuongThac: 'Đinh Mùi',
            KhoiCuong: 'Nhâm Thìn',
            CoLoan: 'Mậu Ngọ',
            NhatDuc: 'Nhâm Tuất',
            NhatQuy: '',
            TamDaiQuy: '',
            ThapAcDaiBai: 'Mậu Tuất',
            ThoaiThan: '',
            TienThan: ''
        },
        row_six: {
            LucTu: 'Kỷ Mùi',
            DaMuu: '',
            ThapLinh: 'Canh Tuất',
            HongDiem: 'Giáp Tuất',
            AmSaiDuongThac: 'Mậu Thân',
            KhoiCuong: 'Nhâm Tuất',
            CoLoan: 'Mậu Thân',
            NhatDuc: '',
            NhatQuy: '',
            TamDaiQuy: '',
            ThapAcDaiBai: 'Kỷ Sửu',
            ThoaiThan: '',
            TienThan: ''
        },
        row_seven: {
            LucTu: '',
            DaMuu: '',
            ThapLinh: 'Canh Dần',
            HongDiem: 'Canh Tuất',
            AmSaiDuongThac: 'Tân Dậu',
            KhoiCuong: '',
            CoLoan: 'Tân Hợi',
            NhatDuc: '',
            NhatQuy: '',
            TamDaiQuy: '',
            ThapAcDaiBai: 'Canh Thìn',
            ThoaiThan: '',
            TienThan: ''
        },
        row_eight: {
            LucTu: '',
            DaMuu: '',
            ThapLinh: 'Tân Hợi',
            HongDiem: '',
            AmSaiDuongThac: 'Nhâm Tuất',
            KhoiCuong: '',
            CoLoan: 'Nhâm Tý',
            NhatDuc: '',
            NhatQuy: '',
            TamDaiQuy: '',
            ThapAcDaiBai: 'Tân Tỵ',
            ThoaiThan: '',
            TienThan: ''
        },
        row_nine: {
            LucTu: '',
            DaMuu: '',
            ThapLinh: 'Nhâm Dần',
            HongDiem: '',
            AmSaiDuongThac: 'Quý Hợi',
            KhoiCuong: '',
            CoLoan: 'Quý Tỵ',
            NhatDuc: '',
            NhatQuy: '',
            TamDaiQuy: '',
            ThapAcDaiBai: 'Nhâm Thân',
            ThoaiThan: '',
            TienThan: ''
        },
        row_ten: {
            LucTu: '',
            DaMuu: '',
            ThapLinh: 'Quý Mùi',
            HongDiem: '',
            AmSaiDuongThac: 'Bính Tý',
            KhoiCuong: '',
            CoLoan: '',
            NhatDuc: '',
            NhatQuy: '',
            TamDaiQuy: '',
            ThapAcDaiBai: 'Quý Hợi',
            ThoaiThan: '',
            TienThan: ''
        },
        row_eleven: {
            LucTu: '',
            DaMuu: '',
            ThapLinh: '',
            HongDiem: '',
            AmSaiDuongThac: 'Mậu Dần',
            KhoiCuong: '',
            CoLoan: '',
            NhatDuc: '',
            NhatQuy: '',
            TamDaiQuy: '',
            ThapAcDaiBai: '',
            ThoaiThan: '',
            TienThan: ''
        },
        row_twel: {
            LucTu: '',
            DaMuu: '',
            ThapLinh: '',
            HongDiem: '',
            AmSaiDuongThac: 'Đinh Sửu',
            KhoiCuong: '',
            CoLoan: '',
            NhatDuc: '',
            NhatQuy: '',
            TamDaiQuy: '',
            ThapAcDaiBai: '',
            ThoaiThan: '',
            TienThan: ''
        },
    }
    return object;
}