export const MauTho = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "70%", margin: "0 auto" }}>
        <h1 style={{ textAlign: "center", color: "brown", fontWeight: "bold" }}>
          Chương V: Mậu Thổ <br /> Ngày Sinh Mậu Thổ
        </h1>
      </div>

      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Mậu Thổ</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b>• Sinh Vào Mùa Xuân</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Dần: 05/02 – 05/03
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Bính Hỏa, Quý Thủy, Giấp Mộc
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Mão: 06/03 – 04/04
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Bính Hỏa, Quý Thủy, Giấp Mộc
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Thìn: 05/04 – 05/05
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Bính Hỏa, Quý Thủy, Giấp Mộc
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b>• Sinh Vào Mùa Hạ</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Tỵ: 06/05 – 05/06
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Bính Hỏa, Quý Thủy, Giấp Mộc C
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Ngọ: 06/06 – 07/07
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Bính Hỏa, Nhâm thủy, Giấp Mộc
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Mùi: 08/07 – 07/08
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Quý Thủy, Bính Hỏa, Giấp Mộc
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b>• Sinh Vào Mùa Thu</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Thân: 08/08 – 07/09
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Bính Hỏa, Quý Thủy, Giấp Mộc
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Dậu: 08/09 – 08/10
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Bính Hỏa, Quý Thủy
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Tuất: 09/10 – 07/11
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Bính Hỏa, Quý Thủy, Giấp Mộc
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b>• Sinh Vào Mùa Đông</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Hợi: 08/11 – 07/12
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Bính Hỏa, Mậu Thổ, Giấp Mộc
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Tý: 08/12 – 05/01
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Bính Hỏa, Mậu Thổ, Giấp Mộc
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Sửu: 06/01 – 03/02
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Bính Hỏa, Mậu Thổ, Giấp Mộc
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Lưu ý: Các bạn cần lưu ý những dụng thần điều hậu phía trên chỉ mang
        tính chất tham khảo. Nếu cần biết thêm về các dụng thần điều hậu, cũng
        như các dụng thần khác trong lá số bát tự bạn cần tham khảo hoặc tư vấn
        từ các chuyên gia.
      </p>

      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Hình Tượng</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Mậu Thổ là Dương Thổ, biểu tượng là ngọn núi. Không có ngày sinh này
        vững chắc và đáng tin cậy hơn Mậu Thổ. Người ngày sinh Mậu Thổ có thể
        xem như là ngọn núi đá to lớn, hùng vĩ. Họ rất cứng chắc và có tâm tính
        ổn định, không bị ảnh hưởng từ bên ngoài.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Bởi vì điều này, người Mậu Thổ thường đáng tin cậy và người ta có thể
        dựa vào. Họ không thuộc tuýp người sáng nắng chiều mưa. Những người xung
        quanh thường có cảm giác an toàn khi làm việc với người Mậu Thổ bởi vì
        bạn biết rằng một ngọn núi sẽ không dời đổi vào ngày mai. Vì lẽ đó,
        nhiều người thường xem người Mậu Thổ là người mà họ có thể dựa vào.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tuy nhiên, cũng như một ngọn núi cần phải bùng nổ từ bên trong trước khi
        nó dịch chuyển, người Mậu Thổ thường cần phải trải qua một biến cố sốc
        trước khi hành động. Họ thường tổ ra quá thoải mái và đôi khi lười do đó
        họ chỉ phát hiện được tài năng của mình khi bị ép buộc. Thói quen để
        công việc làm sau là một trở ngại lớn cho người Mậu Thổ.
      </p>

      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Tính Cách</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Mậu Thổ thuộc tuýp người kín tiếng nhất và bạn không ngại việc tâm
        sự bí mật với người Mậu Thổ vì họ sẽ không tiết lộ cho người khác nghe.
        Người Mậu Thổ thường sống hướng nội và không dễ dàng chia sẻ hay bộc lộ
        tình cảm hoặc suy nghĩ. Sự thật là họ chỉ tiết lộ những bí mật thầm kín
        nhất của họ cho bạn thân nhất. Người Mậu Thổ, cũng như một ngọn núi hùng
        vĩ, họ thường có khiến cho người xung quanh có cảm giác bí ẩn về họ. Do
        đó người xung quanh thường cảm thấy họ khá hấp dẫn và có sức hút mặc dù
        người xung quanh cũng không biết họ bị cuốn hút bởi điều gì.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Hành Thổ gắn nhiều với sự suy tư do đó người Mậu Thổ thường dành nhiều
        thời gian để suy ngẫm, suy nghĩ tới lui nhiều lần một điều nào đó. Do
        đó, đôi khi họ thường ít khi nói ra điều gì nảy ra ngay trong đầu họ.
        Đôi khi, điều này gây khó chịu cho những người xung quanh nếu họ thật sự
        muốn tìm hiểu người Mậu Thổ. Nhưng người Mậu Thổ giỏi giữ bí mật và họ
        sẽ luôn có bí mật của riêng mình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tuy nhiên, khi họ ở cạnh người đủ gần gũi hoặc đáng tin cậy, người Mậu
        thổ có thể tiết lộ mặt kín đáo của mình. Họ có thể vui nhộn, thích đùa
        và vui vẻ. Hầu như họ chỉ cần một chút một hai ly bia hoặc một lời khen
        ngợi tinh tế để tiết lộ những mặt thân thiện của mình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Mậu Thổ cần chuẩn bị rất kỹ trước khi họ bắt đầu làm điều gì. Cũng
        như đá, sỏi hay núi đồi mất nhiều thời gian để hình thành, người Mậu Thổ
        cần chuẩn bị rất nhiều trước khi cho ra kết quả cuối cùng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Điều này đôi khi là xấu, đặc biệt là nếu xét kỹ ra thì người Mậu Thổ
        thường trễ nãi hay trì hoãn. Họ là người thích trì hoãn nhất trong số
        các nhật can. Họ thường thiếu động lực thực hiện những gì cần làm. Nói
        chung, người Mậu Thổ tự tạo ra tài năng của họ. Họ chỉ cần là chính
        mình, và không giống Kỷ Thổ, không cần có người nào giúp họ để trở nên
        thành công
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Bởi vì điều này, người Mậu Thổ có thể đôi khi cứng đầu, không có gì lay
        chuyển được họ khi họ đã ra quyết định. Tương tự, khi họ bắt đầu một thứ
        gì với đam mê mãnh liệt, không gì ngăn được hành động và quyết tâm của
        họ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Mậu Thổ không tách biệt lý trí và cảm xúc. Vì lẽ đó, một sự tính
        toán cân nhắc của họ đều có tính đến yếu tố cảm xúc chứ không chỉ là từ
        góc độ lý trí. Cảm xúc và suy nghĩ gắn chặt với nhau và không dễ để tách
        biệt rõ ràng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu xét đến những thứ to lớn trong đời, người Mậu Thổ thà lắng nghe trái
        tim của mình hơn là bộ não, và đôi khi chú ý nhiều đến cảm xúc hơn. Một
        sự cân bằng lý tưởng giữa lý trí và cảm xúc là tốt hơn hết, tuy nhiên
        người Mậu Thổ có xu hướng tin cảm giác và trực giác của mình nhiều hơn
        là tiến trình suy nghĩ logic.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Điều này đôi khi có thể xấu, bởi vì cùng với xu hướng suy nghĩ quá
        nhiều, người Mậu Thổ có xu hướng đi lòng vòng, không bao giờ rút ra được
        kết luận cuối cùng. Họ thường xem xét mọi quan điểm và suy tư không
        ngừng về ảnh hưởng của nhiều yếu tố khác nhau. Điều này khiến cho họ
        càng trì hoãn nhiều hơn vì họ không thể nào kết luận được. Người Mậu Thổ
        cũng thường là những người theo chủ nghĩa hoàn mỹ, do đó họ có xu hướng
        dành nhiều thời gian để hoàn thiện một điều gì đó.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Mậu Thổ có xu hướng suy nghĩ nhiều về đạo đức. Điều này là dựa
        trên định nghĩa và diễn giải của riêng họ về nguyên tắc đạo đức. Câu hỏi
        đầu tiên nảy ra trong đầu họ luôn là “Điều này là đúng hay sai?”. Họ
        thuộc tuýp người đáng tin cậy nhất và họ chú trọng rất nhiều vào tính
        chính trực và đạo đức sạch sẽ. Cảm thấy đúng đắn và làm đúng luôn phải
        hoà quyện vào nhau.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ cố gắng bảo vệ tiêu chuẩn về đạo đức và không phải thuộc tuýp người
        tính toán lạnh lùng mà luôn cố gắng đảm bảo mọi quyết định đều là đúng
        đắn về đạo đức. Bởi vì họ thường nhấn mạnh đến nguyên tắc và chỉ ra
        những quyết định không làm hại đến ai, cũng như họ sẽ luôn nghĩ 2 lần
        trước khi thúc đẩy điều gì.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Điều này vừa là thế mạnh vừa là điểm yếu của người Mậu Thổ vì tính chính
        trực của họ khiến người khác dễ đặt niềm tin. Họ cũng luôn được người
        khác tin cậy. Nhưng vì người ngày sinh Mậu Thổ luôn chú ý về việc làm
        những gì đúng đắn, họ thường phạm sai lầm về việc lần lữa trước khi
        quyết định. Do đó về sau họ thường dễ quyết định sai lầm khi không còn
        nhiều thời gian và phải vội vã.
      </p>

      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Mối Quan Hệ/ Tình Cảm</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Mối quan hệ là rất quan trọng đối với người Mậu Thổ vì họ có xu hướng ra
        quyết định nào hoà hợp với người khác hơn là vì điều gì khác. Nếu một ai
        đó không vui, thay vì làm cho người đó thay đổi suy nghĩ, người Mậu Thổ
        sẽ cố gắng thay đổi quan điểm của bản thân mình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Điều này sẽ khiến cho nhiều người yêu quý và đặt niềm tin vào người Mậu
        Thổ. Người xung quanh có thể thấy rằng có thể tin tưởng người Mậu Thổ
        vào bất cứ việc gì. Nhưng điều này cũng đi kèm với rủi ro rằng khi quá
        chú trọng đến nỗi lo lắng và cảm giác của người khác, người Mậu Thổ sẽ
        quen mất bản thân mình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ có thể lợi dụng bình luận, cảm xúc hay suy nghĩ của người khác để gợi
        cảm hứng tích cực cho mình. Người Mậu Thổ sẽ gặt được nhiều lời ích khi
        thảo luận và chia sẻ ý tưởng với người khác, vì bạn bè sẽ giúp kéo người
        Mậu Thổ lại suy nghĩ đúng đắn, tránh việc suy tư đó quá nhiều dẫn đến
        lạc hướng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Mậu Thổ đặc biệt chú trọng nhiều đến cảm xúc. Điều này cũng sẽ ảnh
        hưởng đến kỹ năng lên kế hoạch của họ, vì họ có thể thường xuyên để cho
        cảm xúc lấn át khả năng lên kế hoạch và tổ chức. Ngay cả nếu như họ biết
        logic và dữ kiện sự thật, họ có thể để cho cảm xúc lấn át.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Bởi vì điều này, mặc dù họ có tiếng tăm về trì hoãn, người Mậu Thổ
        thường đánh giá vội vã khi có liên quan đến yếu tố cảm xúc. Hầu như họ
        thường có xu hướng bỏ qua mọi thông tin nếu họ có cảm xúc về điều gì đó
        rất mạnh mẽ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Rất khó để người Mậu Thổ bỏ qua cảm xúc trước khi ra bất kỳ kế hoạhc
        này. Họ có xu hướng xem lý lẽ như là nhân tố quan trọng xếp thứ hai, mà
        không quan tâm việc kết hợp giữa cảm xúc và lý trí sẽ tốt hơn nhiều.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Mối quan hệ tình cảm gia đình rất quan trọng đối với người Mậu Thổ. Họ
        chú trọng bảo vệ người thân yêu của mình. Người Mậu Thổ thích quan hệ
        vững chắc với cha mẹ và anh em, đặc biệt nếu đó là người nữ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngay cả nếu những phần khác trong cuộc đời họ không suôn sẻ, người Mậu
        Thổ cảm thấy mối quan hệ tình cảm tốt với thành viên gia đình sẽ cho họ
        sự can đảm và sức mạnh họ cần để theo đuổi mục tiêu và tham vọng. Nếu họ
        có các mối quan hệ hạnh phúc và lành mạnh trong gia đình từ lúc nhỏ thì
        dù cho họ xuất thân là giàu hay nghèo, người Mậu Thổ cũng sẽ trưởng
        thành trở thành những người hoàn thiện. Điều ngược lại cũng đúng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh ngày Mậu Thổ trưởng thành thì ngoài gia đình nền tảng
        họ được nuôi dạy thì việc xây dựng gia đình nhỏ cũng rất quan trọng đối
        với cảm nhận hạnh phúc của họ. Do đó, họ rất cần gặp được người phù hợp
        để yêu và bắt đầu mối quan hệ tình cảm bền vững vì điều này sẽ ảnh hưởng
        đến sự tự tôn của họ rất lớn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu người Mậu Thổ có khó khăn trong cuộc sống, phần lớn họ sẽ cần đến
        mối quan hệ tình thân để giúp họ vượt qua trở ngại. Họ không thật sự
        quan tâm đến địa vị hay sự giàu có, nhưng họ thật sự thèm muốn quan hệ
        tình cảm dài lâu, bền vững để giúp họ trưởng thành và trở nên người tốt
        hơn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu họ kết hôn và xây dựng gia đình, con cái sẽ trở thành mục tiêu số 1
        của họ. Người Mậu Thổ sẽ trở thành những bậc cha mẹ tận tâm và nếu họ có
        mối quan hệ tình cảm chặt chẽ và hạnh phúc với con cái của mình, họ có
        cảm tưởng mình sẽ có thể chinh phục thế giới. Do đó, người Mậu Thổ luôn
        muốn gắn bó bản thân mình với thành viên gia đình và bạn thân.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Mậu Thổ cũng như những ngọn núi thường đơn độc và sỏi đá. Họ
        thường không tìm đến người khác, thay vào đó, mọi người đều tìm đến họ.
        Cũng như vậy, người Mậu Thổ cũng thường là người tạo ra tài năng và khả
        năng của họ, chứ họ không phải được người khác nuôi dưỡng.
      </p>

      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Sự Nghiệp/ Công Việc</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ thường giỏi trong nhiều tình huống phải làm mọi việc một mình, dù cho
        trong công việc hay công việc chuyên môn. Đối với người sinh ngày Mậu
        Thổ, họ không cần gì nhiều mà chỉ cần bản thân. Ngọn núi chỉ cần 1 mình
        nó là đủ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Vì lẽ đó, người sinh ngày Mậu Thổ sẽ có sự tự tin cao nhất khi họ được
        làm việc mà mình thích làm - dù đó là viết lách, diễn xuất hay bất cứ
        gì, miễn là họ cảm thấy họ không phải phụ thuộc vào người khác để hoàn
        thành công việc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Trong công việc, người Mậu Thổ thường toả ra hào quang theo kiểu “Bạn
        phải tìm đến tôi, tôi không tìm đến bạn”. Những bạn bè đồng nghiệp biết
        rõ họ sẽ sẵn sàng bỏ qua yếu tố sĩ diện, nếu người Mậu Thổ giỏi giang.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tuy nhiên, họ có xu hướng gây khó chịu cho người khác vì sự cứng nhắc
        đôi khi là cứng đầu quá đáng. Mặc dù người Mậu Thổ sẽ luôn có lý lẽ bám
        lấy con đường của mình, những người khác sẽ nổi nóng vì nói chuyện với
        người Mậu Thổ y như nói chuyện với đầu gối. Nhưng dù sao thì người Mậu
        Thổ cũng sẽ đạt kết quả mà họ muốn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Mặc dù đôi khi người Mậu Thổ có vẻ rất cứng đầu nhưng họ chỉ cần nhiều
        thời gian để cân nhắc các lựa chọn và cũng như sự độc lập để tự quyết
        định.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Mậu Thổ rất khó thổ lộ suy nghĩ của họ. Thông thường, nếu họ được
        giao công việc, họ sẽ lặng lẽ tìm đến một góc để tự mình hoàn thành công
        việc. Họ ít khi lang thang và buôn chuyện với đồng nghiệp suốt ngày.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Điều này không có nghĩa là họ không thân thiện mà ngược lại vì người Mậu
        Thổ rất chú trọng đến mối quan hệ. Do đó, buộc người Mậu Thổ mở miệng
        nói cũng khó như đào kim cương từ hầm mỏ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Mậu Thổ sẽ chỉ nói những gì cần thiết, không thêm không bớt, không
        buôn chuyện phiếm. Họ thường dễ tiết lộ suy nghĩ và cảm tưởng của mình
        trong những nhóm nhỏ, hay khi nói chuyện riêng lẻ hơn là khi gặp gỡ thật
        sự.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Điều này làm cho người Mậu Thổ được yêu thích nhưng nó cũng dễ dẫn đến
        rắc rối vì người xung quanh sẽ cố lợi dụng họ. Cũng vậy, người Mậu Thổ
        có xu hướng tạo dựng mối quan hệ gần gũi với những người họ làm việc
        chung, nếu thời gian gắn bó đủ dài.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Kết quả là, họ thường cảm thấy khó khăn trong việc chỉ trích, la mắng ai
        đó mà họ gần gũi. Họ gặp khó khăn tách biệt tình cảm cá nhân và mối quan
        hệ công việc, dễ tự mình phải gánh lấy rắc rối khó khăn do người khác
        gây ra. Họ sẽ gặp khó khăn nhiều hơn để ra quyết định, nhất là nếu liên
        quan với đồng nghiệp.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi họ là người lãnh đão hay quản lý con người, người Mậu Thổ thường
        tuân theo quy luật hay nguyên tắc riêng của họ. Họ sẽ tự tin hơn khi
        được hành động theo một cách thức riêng của họ. Ngoài ra, họ cũng sẽ
        không cứng nhắc trong việc áp dụng nguyên tắc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Mậu Thổ thường lắng nghe quan điểm của người khác, và do đó chấp
        nhận những quan điểm và suy nghĩ khác nhau được cấp dưới đề nghị. Họ
        luôn lắng nghe người khác và điều này khiến cho người xung quanh trân
        trọng cách lãnh đạo của họ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Mậu Thổ có xu hướng cân nhắc mọi khả năng, lựa chọn nhưng sẽ có
        quyết định riêng sau cùng. Họ sẽ quyết định làm những gì và không làm
        những gì. Do đó, người xung quanh thường ngạc nhiên vì tưởng rằng Mậu
        Thổ là mềm yếu.
      </p>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Kinh Doanh</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi chuyện liên quan đến kinh doanh, người Mậu Thổ thuộc tuýp người cổ
        điển, họ tin rằng nên quan tâm chăm sóc, cũng như thuộc tên người khách
        hàng. Họ thích làm việc với khách hàng quen thuộc mà họ có xây dựng mối
        quan hệ tốt từ trước đó.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Rắc rối có thể xảy ra vì họ cảm thấy khó có thể bám theo nguyên tắc. Ví
        dụ, nếu khách hàng muốn giảm xin kéo dài công nợ, người Mậu Thổ thường
        sẽ dễ dàng mỉm cười và đồng ý.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Kết quả là, họ thường thua lỗ hơn là có lợi nhuận bởi vì quá tử tế để
        nói Không. Điều này có thể là lỗi nhỏ hoặc có thể gây lỗ đắm thuyền
        trong kinh doanh. Khi kinh doanh nhưng không có lợi nhuận gì thì thật sự
        không có gì vui vẻ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Mậu Thổ giỏi trong những việc kinh doanh đòi hỏi tiếp xúc cá nhân.
        Họ không thích nghĩ về khách hàng trừu tượng hoặc không rõ khách hàng cụ
        thể và thích giữ tương tác thường xuyên.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi có liên quan đến sổ sách và tài khoản, người Mậu Thổ thường thích
        nếu họ không phải xử lý những công việc giấy tờ. Do đó, sẽ tốt hơn nếu
        họ giao cho một người nào đó giải quyết cho họ, vì nếu không giao cho họ
        thì chỉ để lại một đống lộn xộn. Họ không thuộc dạng người giỏi xử lý
        các thoả thuận và thương lượng trong kinh doanh. Bởi vì họ chân thật và
        chú trọng đến giá trị, họ không có khả năng thao túng người khác để đạt
        được điều mình muốn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Mậu Thổ rất chú trọng đến mối quan hệ tình cảm, và do đó không
        ngại ngùng trong việc kết giao bạn bè. Họ không ngại chủ động chìa tay
        ra và xin kết bạn trước.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ giỏi trong việc nói cho người khác biết mình muốn làm bạn với họ và
        những người xung quanh cũng thích họ. Họ không ngần ngại mỉm cười thân
        thiện làm thân trước. Họ rất giỏi trong xử lý mối quan hệ xã giao.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Mậu Thổ thường chú trọng đến nhu cầu của người khác. Do đó, họ
        không ngần ngại chìa tay ra giúp đỡ hay là bờ vai để người khác khóc.
        Những người xung quanh sẽ cảm thấy người Mậu Thổ đáng tin cậy, và họ dễ
        giữ nhiều bí mật.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đôi khi, rất khó cho người Mậu Thổ để đạt điều họ muốn vì họ rất bận rộn
        trong việc thoả mãn yêu cầu của người khác. Nếu người xung quanh họ là
        người độc ác, nông cạn hay ích kỷ, thì họ rất dễ lợi dụng người Mậu Thổ.
        Điều này đặc biệt đúng ở nơi công sở, vì họ sẽ lợi dụng tính tốt của
        người Mậu Thổ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Mậu Thổ không ngần ngại thừa nhận lỗi lầm và xin lỗi. Họ không
        thuộc tuýp người nung nấu thù hận trong nhiều năm, tuy nhiên không có
        nghĩa là người Mậu Thổ sẽ quên đi những người đã phạm lỗi với họ.
      </p>

      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Tài Lộc</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Mậu Thổ dễ nóng giận trong kỹ năng quản lý tiền bạc của họ. Khi
        xét đến kỹ năng quản lý tài chính, họ thường chú trọng đến cảm xúc, do
        đó khi một dự án nào đó phù hợp với tâm trạng của họ, họ sẽ theo đuổi dự
        án đó.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với họ, họ luôn lạc quan và thích các lợi nhuận ngắn hạn và nhanh
        chóng. Do đó, họ thường liên tục thay đổi các kế hoạch tuỳ thuộc vào tâm
        trạng của họ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Vì điều này, người Mậu Thổ có thể đôi khi sẽ vội vã và hấp tấp. Đôi khi
        một thay đổi nhỏ có thể khiến cho họ bất thình lình đổi toàn bộ kế hoạch
        tài chính.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Mậu Thổ thường không thích lắng nghe các lời khuyên tài chính và
        họ thích độc lập và thà tự nghiên cứu và chuẩn bị. Họ có kế hoạch riêng
        và không chịu ai cản đường.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Mậu Thổ không thích thông báo toàn bộ kế hoạch của mình cho thế
        giới biết. Họ thường thuộc tuýp người tự tìm ra cách làm riêng cua mình
        và sẽ giữ kết quả kế hoạch của mình cho riêng mình biết và dù cho kết
        quả là tốt hay xấu. Người Mậu Thổ không thuộc dạng người công bố kế
        hoạch tài chính của mình một cách rộng rãi.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đôi khi, điều này có thể gây hại cho họ nếu họ không chịu nói rõ ra họ
        đang làm gì thì người khác sẽ không thể chỉ ra họ đang sai ở đâu. Nếu họ
        đang lao xuống vực và không ai biết để chỉ ra cho họ, họ sẽ tiếp tục gặp
        nguy cơ lớn.
      </p>
    </div>
  );
};
