import { createSlice } from "@reduxjs/toolkit";


const user = createSlice({
    name: "user",
    initialState: null,
    reducers: {
        login: (state, action) => {
            state = action.payload
            return state;
        },

        loading: (state, action) => {
            state = action.payload
            return state;
        },

    }
})

const { reducer, actions } = user;
export const { login, loading } = actions;
export default reducer;