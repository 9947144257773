export const NhamThuy = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "70%", margin: "0 auto" }}>
        <h1 style={{ textAlign: "center", color: "blue", fontWeight: "bold" }}>
          Chương IX: Nhâm Thủy <br /> Ngày Sinh Nhâm Thủy
        </h1>
      </div>

      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Dụng Thần Điều Hậu</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Sinh Vào Mùa Xuân</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Dần: 05/02 – 05/03
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Bính Hỏa, Canh Kim, Mậu Thổ
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Mão: 06/03 – 04/04
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Canh Kim, Mậu Thổ, Tân Kim
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Thìn: 05/04 – 05/05
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Canh Kim, Giáp Mộc
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Sinh Vào Mùa Hạ</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Tỵ: 06/05 – 05/06
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Quý Thủy, Canh Kim, Nhâm Thủy, Tân Kim
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Ngọ: 06/06 – 07/07
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Quý Thủy, Canh Kim, Tân Kim
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Mùi: 08/07 – 07/08
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Tân Kim, Giáp Mộc
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Sinh Vào Mùa Thu</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Thân: 08/08 – 07/09
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Mậu Thổ, Đinh Hỏa
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Dậu: 08/09 – 08/10
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Canh Kim, Giáp Mộc
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Tuất: 09/10 – 07/11
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Giáp Mộc, Bính Hỏa
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Sinh Vào Mùa Đông</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Hợi: 08/11 – 07/12
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Bính Hỏa, Canh Kim, Mậu Thổ
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Tý: 08/12 – 05/01
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Bính Hỏa, Mậu Thổ
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Sửu: 06/01 – 03/02
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Đinh Hỏa, Bính Hỏa, Giáp Môc
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Lưu ý: Các bạn cần lưu ý những dụng thần điều hậu phía trên chỉ mang
        tính chất tham khảo. Nếu cần biết thêm về các dụng thần điều hậu, cũng
        như các dụng thần khác trong lá số bát tự bạn cần tham khảo hoặc tư vấn
        từ các chuyên gia.
      </p>

      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Hình Tượng</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nhâm Thuỷ là Dương Thuỷ và được tượng trưng bởi tiếng gầm, sự tuôn trào
        của sông suối và nước nằm sâu dưới đại dương và biển. Nó là nước luôn
        chuyển động. Do đó, người Nhâm Thuỷ thường rất năng động và họ không bao
        giờ chịu ngồi yên - họ theo chủ nghĩa dịch chuyển.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ có thể thích nghi rất tốt trong bất kì hoàn cảnh nào. Đưa cho họ một
        vấn đề mới và họ sẽ tìm cách để giải quyết nó. Nhưng họ thường có tính
        cách chú trọng xã giao trong cách họ giải quyết nó vì họ hiếm khi cố
        gắng ép buộc ai đó làm việc gì. Nếu như có trở ngại thì họ sẽ tìm cách
        khác. Luôn có lối đi khác cho người Nhâm Thuỷ. Vì vậy, không ai chê
        trách họ về việc cố gắng đạt được điều họ muốn vì họ thích nghi rất tốt
        và cư xử khéo léo.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Nhâm Thuỷ cực kì thông minh và được biết đến là có thể tiếp nhận
        mọi kiểu thông tin và kiến thức. Họ có khả năng học hỏi khác thường và
        gắn liền với tư duy linh hoạt, nó đã giúp họ có kĩ năng lập luận và tư
        duy tuyệt vời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Theo tự nhiên, họ thông thái và luôn tìm kiếm sự kích thích. Họ tìm kiếm
        nguồn cảm hứng từ mọi thứ xung quanh họ. Cụ thể là hầu hết người Nhâm
        Thuỷ có tư duy sẵn sàng đương đầu với rủi ro. Họ sẽ có thể vận dụng trí
        thông minh để khởi nghiệp và làm rất tốt vì họ rất giỏi tưởng tượng và
        hình thành một viễn cảnh to lớn trong đầu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nước thường rất sâu thẳm và đôi khi rất khó để biết được người Nhâm Thuỷ
        nghĩ gì trong đầu. Nhưng cũng giống như những con sóng có thể bất ngờ
        nổi lên từ bất cứ nơi đâu, họ luôn có những gì ấp ủ thực hiện và không
        bao giờ là đang không làm gì. Người khác có thể trông cậy vào Nhâm Thuỷ
        về việc nghĩ ra những sáng kiến mới và tạo ra nhiều cách giải quyết cho
        những vấn đề bế tắc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Nhâm Thuỷ ít khi là kiểu người ngồi tựa vào tường và lựa chọn
        không nhảy nhót ca hát trong một đêm tiệc. Họ luôn là người tham gia sôi
        nổi trong mọi việc và cũng là kiểu người rất hứng thú với những hoạt
        động xã hội. Họ thích được tiếp xúc với mọi người xung quanh.
      </p>

      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Tính Cách</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Với năng lượng dồi giàu và không giới hạn, họ tràn trề tính thích phiêu
        lưu. Họ phải thử hết mọi thứ ít nhất là một lần! Họ sẽ không bao giờ nói
        không với việc gì. Người Nhâm Thuỷ đề cao sự tự do nhưng tận dụng nó rất
        tốt để luôn mở rộng tầm nhìn của họ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đôi khi, người khác có thể thấy khó để dự đoán Nhâm Thuỷ sẽ làm gì tiếp
        theo. Họ có thể cố ý làm những việc mà họ muốn và sẽ không thích bị kìm
        hãm hoặc sự tự do của họ bị cản trở trong bất cứ trường hợp nào. Giống
        như sự lên xuống của thuỷ triều, họ cũng có thể u sầu và thất thường dẫn
        đến việc thay đổi sự hứng thú và đam mê của họ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Điều bất ngờ đối với những người Nhâm Thuỷ mà thích cuộc sống tự tại, tư
        duy của họ rất có trật tự và cấu trúc. Một số sẽ nói rằng Nhâm Thuỷ rất
        kì cục! Họ muốn mọi thứ phải như thế này và theo trật tự nhất định.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ thích có những suy nghĩ rõ ràng và có hệ thống, không có thói quen
        trở nên lộn xộn hoặc may rủi. Họ sẽ nghiên cứu một vấn đề trên vô số
        khía cạnh và tiếp cận nó theo một góc nhìn mới lạ nhất. Thực tế là việc
        suy nghĩ logic là tài năng và năng khiếu vốn có của họ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Trước khi họ tiếp tục giải quyết một vấn đề hoặc nghĩ là ý tưởng mới, họ
        sẽ tìm hiểu một cách có trật tự ở trong đầu. Họ không thường bị xao lãng
        bởi cảm xúc và sự hối hận hoặc bất cứ vấn đề nào và cực kì lý trí. Nhâm
        Thuỷ cũng có khả năng hình dung ra một viễn cảnh và tập hợp tất cả những
        phần khác nhau của một vấn đề để tạo ra một bức tranh lớn hơn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Nhâm Thuỷ thường suy nghĩ rất nhanh. Họ không có thói quen trầm
        ngâm và cân nhắc quá lâu về những vấn đề hoặc đợi chờ lâu dài. Khi đối
        mặt với tình huống yêu cần giải quyết thông minh, họ sẽ nghiên cứu từng
        bước theo trật tự và đưa ra được đáp án hoặc cách giải quyết – rất
        nhanh.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ sẽ tìm ra được câu trả lời trong khi những người khác vẫn còn suy
        nghĩ về nó! Nếu người khác vẫn còn đang ở mức 5 hoặc 6 của vấn đề thì
        người Nhâm Thuỷ đã đạt đến bước thứ 10! Đó là vì họ có sức mạnh cao độ
        khi tập trung và có thể chú tâm vào nhiệm vụ trong tầm tay.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Một người Nhâm Thuỷ vượt trội sẽ có thể tách rời cảm tính khỏi lý trí.
        Họ không cho phép bản thân bị xao lãng bởi cảm xúc của họ khi họ đang
        cân nhắc về một vấn đề logic. Người Thuỷ yếu hơn hoặc mạnh quá mức sẽ
        khó trong việc tách rời cảm xúc và lí trí khác thường. Ngũ hành Thuỷ là
        tinh tế. Chỉ có nước ‘được cân bằng’ thì mới có thể phản hồi lại, thay
        vì ‘phản xạ lại’ với những tình huống mơ hồ trong cuộc sống.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Trí nhớ của Nhâm Thuỷ có mục đích và chức năng nhất định. Họ sẽ không
        nhớ về màu áo đầu tiên mà họ thích trong lần đầu thấy hoặc là món ăn đầu
        tiên mà mẹ làm cho ăn vào ngày đầu tiên đi học!
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Nhâm Thuỷ tự hào về bản thân nếu biết rằng người khác thấy họ có
        tiềm năng. Sự tự tin của họ có thể dễ dàng bị ảnh hưởng bởi việc họ tin
        vào những gì người khác nghĩ gì về họ. Họ đôi khi có thể là nô lệ theo
        đuổi những xu hướng mới nhất hoặc những gì đang thịnh hành
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Mặc cho sự thông minh vượt bậc của họ, họ thường thiếu sự ổn định trong
        những suy nghĩ kiên định về bản thân họ. Giống như sự chuyển động của
        nước, ý thức của họ về cá nhân không cố định với từng phẩm chất. Họ có
        được sự yên tâm về tính chất của bản thân từ bạn bè và đồng nghiệp của
        họ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Nhâm Thuỷ điển hình thường trở nên khiêm tốn với những người có
        địa vị xã hội hay vị trí công việc cao hơn họ. Điều này có gốc từ hội
        chứng ‘muốn được công nhận’ của họ. Nó thỉnh thoảng là một cú đánh rất
        đau cho người Nhâm Thuỷ khi họ ở vị trí thấp.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Kiểu người đa số của Nhâm Thuỷ không thường ở vị trí thấp quá lâu. Sự
        yêu bản thân của họ sẽ không thể chịu được việc bị bỏ xa hoặc ở vị trí
        thấp hơn người khác – và vì thế họ sẽ đạt được vị trí cao hơn nếu đó là
        những gì họ cảm thấy ‘xứng đáng’. Họ sẽ thường tìm đường để thành công.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Nhâm Thuỷ nhược có thể cảm thấy khuất phục sự hiện diện của những
        người có địa vị cao hơn hoặc có quyền thế cao hơn nhưng họ cũng sẽ thấy
        mạnh hơn những người mà họ cho là thấp kém hơn. Do vậy, họ có thể trở
        thành người sống ẩn, giả vờ ngại tiếp xúc với những sự chú ý hoặc viện
        cớ để không gặp gỡ bạn bè để tránh cảm giác này. Họ có thể hình thành
        tâm lý yếm thế và tránh né thế giới.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Nhâm Thuỷ năng nổ, cường tráng và hoạt bát một cách tự nhiên. Của
        họ không phải sự trầm ngâm và cô lập trong im lặng. Do vậy, sự tự tin
        của họ sẽ chín mùi khi họ có thể làm gì và bắt đầu hành động.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nên khi mọi thứ diễn ra khác với kế hoạch, người Nhâm Thuỷ sẽ hành động
        và cố gắng sửa chữa vấn đề. Họ đơn giản chỉ là cố gắng làm một điều gì
        đó để củng cố sự tự tin của mình. Bị xem là một sự bất lực, thiếu năng
        lực và vô dụng là một trong những nỗi sợ to lớn nhất của Nhâm Thuỷ!
      </p>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Mối Quan Hệ/ Tình Cảm</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu người khác có vẻ như bị ấn tượng với người Nhâm Thuỷ thì họ sẽ thấy
        rất vui. Do vậy, họ thường là những người theo chủ nghĩa hoàn hảo luôn
        muốn trở thành người giỏi nhất! – giỏi nhất trong học tập, công việc,
        nấu nướng, kiếm tiền và tốt nhất về hình thể, cách ăn mặc… và nhiều thứ
        khác nữa!
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ càng làm nhiều việc thì họ càng thấy hạnh phúc và họ càng muốn tiếp
        tục làm nhiều thứ khác trong tương lai. Điều này có thể tạo ra vòng lặp
        năng suất vui vẻ. Do đó, người Nhâm Thuỷ vượng thường không phải trải
        qua sự tự ti trong một khoảng thời gian dài vì lúc họ hành động, thì
        chính hành động l2 thuốc giảm đau để sửa chữa điều đó!
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tại nơi làm việc, người Nhâm Thuỷ là kiểu người hiếm khi ngồi không và
        lãng phí thời gian. Nếu họ muốn ở không hoặc giết thời gian thì đó là
        những gì họ sẽ làm. Nhưng cuộc sống của họ thường mang nhiều ý nghĩa và
        mục đích. Và nếu họ có việc làm, đó thường là công việc mà họ muốn làm!
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ có thể rất chuyên tâm và nghị lực, và cũng rất tham vọng. Điều này
        đôi khi có thể khiến người khác hơi khó chịu hoặc thậm chí khiến người
        khác khó chịu về Nhâm Thuỷ. Hoặc thi thoảng, đồng nghiệp chỉ không biết
        là Nhâm Thuỷ đang nghĩ gì. Vì nét tính cách này, ngược lại, người Nhâm
        Thuỷ thường bị đồng nghiệp hoặc cấp trên đánh giá kiểm điểm. Và hầu hết
        thời gian, người Nhâm Thuỷ không bao giờ để bị ai sai bảo hoặc hướng dẫn
        để làm điều gì đó. Nếu như có việc gì cần phải hoàn thành, họ sẽ thường
        lập tức bắt tay vào hành động trước khi người khác biết.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu họ mất đi sự hứng thú hoặc lòng tin vào công việc của họ, họ sẽ bỏ
        việc ngay lập tức mà không cần cân nhắc là họ có đang có việc khác để
        làm hay chưa. Họ thường nỗ lực vì mục tiêu và nếu họ mất đi khái niệm
        ‘mục tiêu’ đó, họ sẽ dừng làm việc đó.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Kiểu người Nhâm Thuỷ thường được nhiều người để ý trong chỗ làm vì họ
        thường thân thiện với mọi người xung quanh. Với sự dễ gần trong giao
        tiếp, họ luôn hào hứng với cuộc nói chuyện hoặc là tiếng cười – thậm chí
        mặc dù có thể ban đầu họ hơi gây cho người khác sự khó chịu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Mặc dù họ không dễ dàng thân thiết hoặc thân mật với mọi người, họ duy
        trì bầu không khí chung hoà nhã và khéo léo mà khiến nhiều người
        xungquanh thấy dễ chịu. Tính cách xã hội của họ không xuất hiện khi họ
        cần để thành công hoặc nằm ngoài sự an toàn của tiềm thức. Nó chỉ là một
        phần của tính cách mà họ cần đảm bảo.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Nhâm Thuỷ vượng thường không tham gia vào những trò chơi chính trị
        bẩn tại công sở. Họ có những mục tiêu lớn để hướng đến. Vì tư duy lí trí
        là điểm mạnh của họ, họ có thể tách rời những vấn đề trong công việc
        khỏi những vấn đề về cảm xúc. Họ muốn được làm công việc tốt nhất và đủ
        phóng khoáng và độc lập để không phải dựa dẫm hoặc phật ý người mà họ
        làm cho. Người Nhâm Thuỷ trung bình, ngược lại thường dễ bị dính vào mưu
        mô tầm thường hoặc chuyện gì đó kịch tính ở chỗ làm khi họ cố gắng để đề
        nghị giúp đỡ một ai đó.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Trong quan hệ giao tiếp, người Nhâm Thuỷ có thể đôi khi thành công là vì
        quá thật thà. Điều hay là họ có thể làm điều này vô cùng khéo léo và
        giỏi ngoại giao.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Nhâm Thuỷ thường không phải kiểu người thích tán gẫu. Họ thích giữ
        thái độ trung lập với những mối quan hệ bạn bè. Trong những sự hận thù
        và tranh cãi giữa bạn bè, người khác tìm đến người Nhâm Thuỷ vì họ
        thường công tâm, không thiên vị. Là ngũ hành Thuỷ, họ luôn ‘tìm kiếm sự
        thăng bằng’ và cân bằng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nhâm Thuỷ, là yếu tố của Nước, thích giữ sự trong sạch. Điều này nghĩa
        là người Nhâm Thuỷ sẽ tránh xa khỏi những mối quan hệ bạn bè hoặc những
        mối quan hệ rắc rối và độc hại. Họ ghét ‘chuyện kịch tính’ trong những
        mối quan hệ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Có hàng trăm bạn bè trên Facebook là một nỗ lực đáng kể của hầu hết mọi
        người, nhưng Nhâm Thuỷ thường không có vấn đề gì với chuyện này! Yếu tố
        Nước trộn lẫn rất nhanh. Nhâm Thuỷ, là Dương Thuỷ, không cần nỗ lực để
        kết bạn. Tính thân thiện và dễ gần giúp họ thu hút người khác như là
        người khát nước tìm thấy nước để uống.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ thích giao lưu bạn bè khi có sự tụ tập của nhiều người, nhất là hoạt
        động mà mọi người đều có thể tận hưởng – khiêu vũ, chèo xuồng vượt thác,
        hát karaoke – những việc khiến họ thấy vui vẻ. Hơn thế nữa, cuối tuần
        của họ cũng được lấp đầy bằng những kế hoạch đã được lập nên trước đó.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ có trong tay số liên lạc của nhiều người nổi tiếng. Cũng giống như
        đại dương bao la, người Nhâm Thuỷ có mạng lưới bạn bè rộng lớn – rất
        nhiều trong số đó là người nổi tiếng, chính trị gia, người giải trí,
        siêu sao và những loại người khác. Cần nhớ rằng, họ không chỉ kiếm bạn
        để đi chơi – họ thường nghĩ về việc làm cách nào để sử dụng những mối
        quan hệ đó một cách hiệu quả cho những mục đích to lớn như là đam mê
        hoặc mục tiêu kinh doanh của họ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tuy nhiên, vì người Nhâm Thuỷ thường có tính cách đôi, hoặc tính khí
        thất thường (Ngày sinh Thuỷ là người có điểm này nhiều hơn Thiên Can
        khác), họ đôi khi có thể khó có được, khó để hiểu. Vì lí do đó mà số
        lượng người bạn thân thật sự thường rất ít. Cần phải kiên nhẫn, kiên trì
        và thấu hiểu để thâm nhập vào những lớp sâu bên trong của Nhâm Thuỷ!
      </p>

      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Sự Nghiệp/ Công Việc</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Là một người quản lí hoặc người lãnh đạo, phong cách của người Nhâm Thuỷ
        là đúng đắn và chính xác, nhất là khi làm việc có liên quan tới những
        con số. Họ rất tận tâm và thích nắm mọi chuyện trong tầm kiểm soát và
        thường vì vậy sẽ dành ra một lượng thời gian đáng kể để sắp xếp giấy tờ
        hoặc nghiên cứu theo thứ tự.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Cấp dưới của họ thường đề cao điểm này, vì người Nhâm Thuỷ không có thói
        quen may rủi và lộn xộn. Họ sẽ hoàn toàn dán mắt vào những gì mà cấp
        dưới làm và sẽ luôn luôn đảm bảo đúng kế hoạch và theo thời gian tương
        ứng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lãnh đạo Nhâm Thuỷ cũng có ý thức về chi phí và họ rất cẩn thận trong
        giám sát chi phí sản xuất kinh doanh. Họ không muốn có sự lãng phí tài
        nguyên và ngân sách khi không cần thiết. Do đó, họ không dễ dàng chấp
        nhận những biến động bất chợt trong quá trình sản xuất mà để lại hậu quả
        trong sự gia tăng chi phí hoặc thay đổi của nguồn lực – nhân lực hoặc
        các tài nguyên khác!
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người lãnh đạo Nhâm Thuỷ ít khi chuyên quyền và bảo thủ. Họ luôn thấy
        vui và trân trọng việc cấp dưới chia sẻ ý kiến với họ. Họ sẽ cân nhắc
        từng ý kiến kỹ càng và sàng lọc lại trong đầu để xem đó là ý kiến tốt
        hay tồi.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nhưng điều đó không có nghĩa họ làm theo mọi thứ mà người khác nói một
        cách không có mục đích. Họ tôn trọng những ý kiến khác nhau và vì họ có
        tư duy tỉ mỉ và logic – họ sẽ đưa ra từng ý kiến cùng với sự nghiên cứu
        kĩ lưỡng và sự cân nhắc như nhau trước khi bổ sung những đề nghị mới.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Để giải quyết những vấn đề hoặc khó khăn gì, họ sẽ thường tổ chức cuộc
        họp để những ý kiến của mọi người đều có thể được ghi nhận. Họ mong mọi
        người sẽ chịu trách nhiệm với những lỗi sai – và người Nhâm Thuỷ cũng sẽ
        là những người chịu trách nhiệm cho những lỗi của bản thân.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tuy nhiên, đối với những người Nhâm Thuỷ nhược, anh ấy hoặc cô ấy sẽ
        thường làm theo ý kiến của người khác để can thiệp vào sự đánh giá của
        họ. Do đó điều này sẽ gây ra sự phẫn nộ, tranh cãi và kịch tính ở chỗ
        làm. Do đó, có sự khác biệt rõ rệt giữa một người Nhâm Thuỷ vượng và một
        người Nhâm Thuỷ nhược xét về khả năng lãnh đạo.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Nhâm Thuỷ, hơn bất kỳ Thiên Can nào khác, thường rất khôn khéo
        trong kinh doanh. Tri thức của họ về thường rất phù hợp với hoạt động
        kinh doanh và điển hình là, rất nhiều người Nhâm Thuỷ tốt đã thành công
        trong vai trò doanh nhân dù cho ngành nghề mà họ theo đuổi là gì.
      </p>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Kinh Doanh</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Nhâm Thuỷ có sở trường trong việc kinh doanh vì họ ước lượng của
        mặt lợi và mặt hại rất nhanh chóng, chính xác bằng phương pháp của họ và
        dám đương đầu với rủi ro. Người Nhâm Thuỷ sáng tạo thường có tầm nhìn
        tốt mà cuối cùng sẽ cho ra được kế hoạch kinh doanh. Họ theo đuổi đam mê
        của mình và thường biến chúng thành công việc kinh doanh. Họ có sở
        trường cho việc mua bán và xúc tiến ý tưởng của họ. Một người Nhâm Thuỷ
        vượng cũng sẽ tự có bản năng kinh doanh tốt.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ cũng thường có sự kết nối tốt – họ có bạn bè đúng đắn và biết đâu là
        nơi để tìm thấy tài nguyên tốt để có thể hỗ trợ họ trong kinh doanh.
        Những gì họ phải làm là tập hợp đủ sự can đảm để XIN được giúp đỡ. (Tất
        nhiên đây là vấn đề khác, vì thậm chí họ có sự kết nối, lòng tự trọng
        thường khiến cho nhiều người Nhâm Thuỷ ngần ngại trong việc cầu xin để
        ĐƯỢC giúp đỡ).
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tất nhiên, người Nhâm Thuỷ nhược, hoặc trong lá số thì hành Thuỷ quá
        mạnh hoặc quá yếu thì là người mà trái ngược rất nhiều với hình tượng
        thật sự của họ. Anh ấy hoặc cô ấy thường không sẵn sàng đối mặt với rủi
        ro, tính toán, phân tính quá mức và thậm chí có thể rất bi quan. Loại
        người Nhâm Thuỷ này thường chỉ có “ước mơ”.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Điển hình là người Nhâm Thuỷ vượng là những người suy nghĩ toàn cảnh. Họ
        không thích chỉ tập trung vào những gì ở trước mắt. Họ tập trung vào nó
        và những gì nó có thể tác động trong tương lai. Tâm trí của họ luôn
        hướng về phía trước để nghĩ về những cơ hội hơn là những gì khó khăn
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ cũng có khả năng tự nhiên để thấy được toàn cảnh. Một chủ kinh doanh
        tốt cần phải có định hướng và tầm nhìn. Và đó là cái Nhâm Thuỷ đem vào
        trong cuộc đàm phán. Họ là những nhà tư tưởng luôn tiến lên, người luôn
        tìm hướng đi để mở rộng và đem sự liều lĩnh kinh doanh đó vào tương lai.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Nhâm Thuỷ thật ra là những người mơ mộng sáng tạo. Họ dành rất
        nhiều thời gian để suy nghĩ và dự tính. Tất nhiên là người Nhâm Tuỷ
        vượng sẽ có thể vận dụng tài năng vốn có này để tạo ra hình mẫu kinh
        doanh thành đạt.
      </p>

      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Tài Lộc</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Nhâm Thuỷ thường có thiên hướng về những con số và logic, là người
        tiêu tiền vừa phải khác với những người tiêu xài phung phí. Do đó, họ
        thường làm tốt ở lĩnh vực tài chính! Họ có bản tính cẩn thận, chính xác
        và sẽ có thể biến những điểm mạnh đó thành khả năng quản lí tài chính
        xuất sắc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Mặc dù họ nhận thức được nhiều kiểu chiến lược đầu tư khác nhau, chướng
        ngại chính đối với hầu hết người Nhâm Thuỷ là bắt đầu từ những bước đầu
        tiên.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nhưng thường là một khi họ đã hiểu được nó, họ sẽ trở nên rất thạo trong
        việc đó rất nhanh! Một khi họ đã tích luỹ tất cả những kiến thức mà họ
        cần, họ sẽ có thể sắp xếp nó theo trật tự, phân tích nó từ nhiều khía
        cạnh khác nhau và lao vào xây dựng cho bản thân một con đường để có được
        sự tự do về tài chính.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Trở nên độc lập và phóng khoáng, người Nhâm Thuỷ thích lắng nghe bản
        thân hơn bất cứ người nào khác khi nói về sự quản lí tài chính. Họ muốn
        tiếp nhận nhiều loại thông tin khác nhau và sàng lọc chúng thông qua bộ
        não của họ trước khi lao vào làm gì đó.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Rất nhiều nhà đầu tư Nhâm Thuỷ là kỵ sĩ cô độc. Họ tự mình phân tích,
        suy nghĩ và lên kế hoạch cho mọi thứ. Dù cho đó là kế hoạch đầu tư phát
        triển, đầu tư giá trị hoặc đầu tư lợi tức, họ làm chủ nó và đưa ra quyết
        định cuối cùng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ thích tự mình nghiên cứu. Họ là những người theo chủ nghĩa hoàn hảo
        theo bản năng và sẽ luôn muốn nghiên cứu số liệu, công ty, cổ phần hoặc
        tài sản mà họ đang để mắt tới trước khi dốc toàn lực vào đầu tư.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nhâm Thuỷ hiếm khi dựa vào ‘những chuyên gia’ để xin lời khuyên về tiền
        bạc. Họ có thể hỏi những người xung quanh về nhiều ý kiến, nhưng cuối
        cùng họ có thể sẽ không làm gì nếu quan điểm đó không giống với quan
        điểm của họ. Điều này đặc biệt đúng đối với người Nhâm Thuỷ yếu khi mà
        họ không thể làm rõ ra quyết định sau khi đã xin rất nhiều lời khuyên từ
        người khác.
      </p>
    </div>
  );
};
