import React, { useState } from "react";
import { Button, Input, InputGroup, Label, Table } from "reactstrap";
import moment from "moment";
import Const from "../components/Const";
import SunCalc from "suncalc";

import {
  DaiVan,
  KetLuanLaSo,
  DaiVan10Nam,
  DaiVanDay,
  DayToCanChi,
  DongTayTuTrach,
  getNamAp,
  getNamtietKhi,
  getQue,
  handleYearTietKhi,
  HourToCanChi,
  KhoiCuong,
  KhongVong,
  ThaiTuc,
  TruGio,
  KhongVong2,
  MonthToCanChi,
  Next,
  nguHanh,
  NguyetChi,
  Prev,
  solarTime,
  soNgayDaiVan,
  TangCan,
  TangCanArr,
  ThanSatDiaChi,
  ThanSatDiaChiArr,
  ThanSatThienCan,
  ThanSatThienCanArr,
  ThapThan,
  ThapThanArr,
  ThienXa,
  ThienY,
  TruongSinh,
  YearToCan,
  YearToChi,
  ThaiCung,
  MenhCung,
} from "../components/convert";
const SoSanh = ({
  excelLichvanNien,
  tietKhiDate = [],
  tietKhiInYear = [],
  checkDungThan,
}) => {
  const [dateData, setDateData] = useState(Const.initDateData);

  const [name, setName] = useState("");
  const [gender, setGender] = useState("Nam");
  const [hour, setHour] = useState("null");
  const [minute, setminute] = useState("00");
  const [day, setDay] = useState("01");
  const [month, setMonth] = useState("0");
  const [year, setYear] = useState(new Date().getFullYear());

  const [checkHour, setCheckHour] = useState(false);
  const [gioSolar, setgioSolar] = useState("");
  const [solarphut, setSolarphut] = useState("");

  const [dayShow, setDayShow] = useState("01");
  const [monthShow, setMonthShow] = useState("01");
  const [yearShow, setYearShow] = useState(new Date().getFullYear());

  const [thapThanHour, setthapThanHour] = useState("");
  const [thapThanMonth, setthapThanMonth] = useState("");
  const [thapThanYear, setthapThanYear] = useState("");

  const [thienCanHour, setThienCanHour] = useState("");
  const [thienCanDay, setthienCanDay] = useState("");
  const [thienCanMonth, setthienCanMonth] = useState("");
  const [thienCanYear, setThienCanYear] = useState("");

  const [diaChiHour, setdiaChiHour] = useState("");
  const [diaChiDay, setdiaChiDay] = useState("");
  const [diaChiMonth, setdiaChiMonth] = useState("");
  const [DiachiYear, setDiachiYear] = useState("");

  const [tangCanNguHanhHour, settangCanNguHanhHour] = useState("");

  const [tangCanNguHanhHour2, settangCanNguHanHourHour2] = useState("");
  const [tangCanNguHanhHour3, settangCanNguHanHourHour3] = useState("");
  const [tangCanNguHanhHour4, settangCanNguHanHourHour4] = useState("");

  const [tangCanNguHanhHour5, settangCanNguHanHourHour5] = useState("");
  const [tangCanNguHanhHour6, settangCanNguHanHourHour6] = useState("");

  const [tangCanNguHanhDay, settangCanNguHanhDay] = useState("");
  const [tangCanNguHanhDay2, settangCanNguHanhDay2] = useState("");
  const [tangCanNguHanhDay3, settangCanNguHanhDay3] = useState("");
  const [tangCanNguHanhDay4, settangCanNguHanhDay4] = useState("");
  const [tangCanNguHanhDay5, settangCanNguHanhDay5] = useState("");
  const [tangCanNguHanhDay6, settangCanNguHanhDay6] = useState("");

  const [tangCanMonth, settangCanMonth] = useState("");
  const [tangCanMonth2, settangCanMonth2] = useState("");
  const [tangCanMonth3, settangCanMonth3] = useState("");
  const [tangCanMonth4, settangCanMonth4] = useState("");
  const [tangCanMonth5, settangCanMonth5] = useState("");
  const [tangCanMonth6, settangCanMonth6] = useState("");

  const [tangCanYear, settangCanYear] = useState("");
  const [tangCanYear2, settangCanYear2] = useState("");
  const [tangCanYear3, settangCanYear3] = useState("");
  const [tangCanYear4, settangCanYear4] = useState("");
  const [tangCanYear5, settangCanYear5] = useState("");
  const [tangCanYear6, settangCanYear6] = useState("");

  const [nguHanhHour, setnguHanhHour] = useState("");
  const [nguHanhDay, setnguHanhDay] = useState("");
  const [nguHanhMonth, setnguHanhMonth] = useState("");
  const [nguHanhYear, setnguHanhYear] = useState("");

  const [dungThan, setDungThan] = useState("");

  const [thapThanHour1, setthapThanHour1] = useState("");
  const [thapThanHour2, setthapThanHour2] = useState("");
  const [thapThanHour3, setthapThanHour3] = useState("");

  const [thapThanDay1, setthapThanDay1] = useState("");
  const [thapThanDay2, setthapThanDay2] = useState("");
  const [thapThanDay3, setthapThanDay3] = useState("");

  const [thapThanMonth1, setthapThanMonth1] = useState("");
  const [thapThanMonth2, setthapThanMonth2] = useState("");
  const [thapThanMonth3, setthapThanMonth3] = useState("");

  const [thapThanYear1, setthapThanYear1] = useState("");
  const [thapThanYear2, setthapThanYear2] = useState("");
  const [thapThanYear3, setthapThanYear3] = useState("");

  const [checkThangDungThan, setCheckThangDungThan] = useState(false);
  const [checkNapAm, setcheckNapAm] = useState(true);

  const [napAmHour, setnapAmHour] = useState("");
  const [napAmDay, setnapAmDay] = useState("");
  const [napAmMonth, setnapAmMonth] = useState("");
  const [napAmYear, setnapAmYear] = useState("");

  const [checkSelected, setCheckSelected] = useState(false);
  const [kinhDo, setKinhDo] = useState();
  const [viDo, setViDo] = useState();
  const [show, setShow] = useState(false);
  const [stateTruGio, setStateTruGio] = useState("");

  const [TSDiaChiArr, setTSDiaChiArr] = useState([]);
  const [TSDiaChiYearArr, seTSDiaChiYearArr] = useState([]);
  const [TSThienCanArr, setTSThienCanArr] = useState([]);
  const [TSThienCanYearArr, setTSThienCanYearArr] = useState([]);

  const [thanSatDiachiHours, setthanSatDiachiHours] = useState("");
  const [thanSatDiaChiDay, setthanSatDiaChiDay] = useState([]);
  const [thanSatDiachiMonth, setthanSatDiachiMonth] = useState("");
  const [thanSatDiaChiYear, setthanSatDiaChiYear] = useState("");

  const [thanSatThienCanHour, setthanSatThienCanHour] = useState("");
  const [thanSatThienCanDay, setthanSatThienCanDay] = useState("");
  const [thanSatThienCanMonth, setthanSatThienCanMonth] = useState("");
  const [thanSatThienCanYear, setthanSatThienCanYear] = useState("");

  const onChangeHour = (value) => {
    setHour(value);
  };

  const namSinh = () => {
    const items = [];

    for (let i = 2040; i >= 0; --i) {
      if (i === 2023) items.push(<option selected>{i}</option>);
      else items.push(<option>{i}</option>);
    }

    return items;
  };

  const getTietKhiAllYear = (timeYear) => {
    const year = timeYear.getFullYear();
    const distance = timeYear?.getFullYear() - 1800;
    let thangLe = distance * 42 + 120;
    let thangChan = distance * 42 + 122;

    if (year <= 1800 && year >= 1583) {
      let ngayGioDauThang1 = Const.tietKhi_1583_1800_thangLe[0]
        .split(" - ")[0]
        .split(" ");

      let ngayGioDauThang3 = Const.tietKhi_1583_1800_thangLe[1]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang5 = Const.tietKhi_1583_1800_thangLe[2]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang7 = Const.tietKhi_1583_1800_thangLe[3]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang9 = Const.tietKhi_1583_1800_thangLe[4]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang11 = Const.tietKhi_1583_1800_thangLe[5]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang2 = Const.tietKhi_1583_1800_thangChan[0]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang4 = Const.tietKhi_1583_1800_thangChan[1]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang6 = Const.tietKhi_1583_1800_thangChan[2]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang8 = Const.tietKhi_1583_1800_thangChan[3]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang10 = Const.tietKhi_1583_1800_thangChan[4]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang12 = Const.tietKhi_1583_1800_thangChan[5]
        .split(" - ")[0]
        .split(" ");

      const ngayDauThangMot = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang1[0].split("/")[1]) - 1,
        Number(ngayGioDauThang1[0].split("/")[0]),
        Number(ngayGioDauThang1[1].split(":")[0]),
        Number(ngayGioDauThang1[1].split(":")[1])
      );

      const ngayDauThangHai = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang2[0].split("/")[1]) - 1,
        Number(ngayGioDauThang2[0].split("/")[0]),
        Number(ngayGioDauThang2[1].split(":")[0]),
        Number(ngayGioDauThang2[1].split(":")[1])
      );
      const ngayDauThangBa = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang3[0].split("/")[1]) - 1,
        Number(ngayGioDauThang3[0].split("/")[0]),
        Number(ngayGioDauThang3[1].split(":")[0]),
        Number(ngayGioDauThang3[1].split(":")[1])
      );
      const ngayDauThangTu = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang4[0].split("/")[1]) - 1,
        Number(ngayGioDauThang4[0].split("/")[0]),
        Number(ngayGioDauThang4[1].split(":")[0]),
        Number(ngayGioDauThang4[1].split(":")[1])
      );
      const ngayDauThangNam = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang5[0].split("/")[1]) - 1,
        Number(ngayGioDauThang5[0].split("/")[0]),
        Number(ngayGioDauThang5[1].split(":")[0]),
        Number(ngayGioDauThang5[1].split(":")[1])
      );
      const ngayDauThangSau = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang6[0].split("/")[1]) - 1,
        Number(ngayGioDauThang6[0].split("/")[0]),
        Number(ngayGioDauThang6[1].split(":")[0]),
        Number(ngayGioDauThang6[1].split(":")[1])
      );
      const ngayDauThangBay = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang7[0].split("/")[1]) - 1,
        Number(ngayGioDauThang7[0].split("/")[0]),
        Number(ngayGioDauThang7[1].split(":")[0]),
        Number(ngayGioDauThang7[1].split(":")[1])
      );
      const ngayDauThangTam = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang8[0].split("/")[1]) - 1,
        Number(ngayGioDauThang8[0].split("/")[0]),
        Number(ngayGioDauThang8[1].split(":")[0]),
        Number(ngayGioDauThang8[1].split(":")[1])
      );
      const ngayDauThangChin = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang9[0].split("/")[1]) - 1,
        Number(ngayGioDauThang9[0].split("/")[0]),
        Number(ngayGioDauThang9[1].split(":")[0]),
        Number(ngayGioDauThang9[1].split(":")[1])
      );
      const ngayDauThangMuoi = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang10[0].split("/")[1]) - 1,
        Number(ngayGioDauThang10[0].split("/")[0]),
        Number(ngayGioDauThang10[1].split(":")[0]),
        Number(ngayGioDauThang10[1].split(":")[1])
      );
      const ngayDauThangMuoiMot = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang11[0].split("/")[1]) - 1,
        Number(ngayGioDauThang11[0].split("/")[0]),
        Number(ngayGioDauThang11[1].split(":")[0]),
        Number(ngayGioDauThang11[1].split(":")[1])
      );
      const ngayDauThangMuoiHai = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang12[0].split("/")[1]) - 1,
        Number(ngayGioDauThang12[0].split("/")[0]),
        Number(ngayGioDauThang12[1].split(":")[0]),
        Number(ngayGioDauThang12[1].split(":")[1])
      );
      let lichTietKhi = [];
      lichTietKhi.push(
        ngayDauThangMot,
        ngayDauThangHai,
        ngayDauThangBa,
        ngayDauThangTu,
        ngayDauThangNam,
        ngayDauThangSau,
        ngayDauThangBay,
        ngayDauThangTam,
        ngayDauThangChin,
        ngayDauThangMuoi,
        ngayDauThangMuoiMot,
        ngayDauThangMuoiHai
      );
      tietKhiDate = lichTietKhi;
    } else if (year >= 0 && year <= 1582) {
      let ngayGioDauThang1 = Const.tietKhi_0_1582_thangLe[0]
        .split(" - ")[0]
        .split(" ");

      let ngayGioDauThang3 = Const.tietKhi_0_1582_thangLe[1]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang5 = Const.tietKhi_0_1582_thangLe[2]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang7 = Const.tietKhi_0_1582_thangLe[3]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang9 = Const.tietKhi_0_1582_thangLe[4]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang11 = Const.tietKhi_0_1582_thangLe[5]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang2 = Const.tietKhi_0_1582_thangChan[0]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang4 = Const.tietKhi_0_1582_thangChan[1]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang6 = Const.tietKhi_0_1582_thangChan[2]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang8 = Const.tietKhi_0_1582_thangChan[3]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang10 = Const.tietKhi_0_1582_thangChan[4]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang12 = Const.tietKhi_0_1582_thangChan[5]
        .split(" - ")[0]
        .split(" ");

      const ngayDauThangMot = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang1[0].split("/")[1]) - 1,
        Number(ngayGioDauThang1[0].split("/")[0]),
        Number(ngayGioDauThang1[1].split(":")[0]),
        Number(ngayGioDauThang1[1].split(":")[1])
      );

      const ngayDauThangHai = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang2[0].split("/")[1]) - 1,
        Number(ngayGioDauThang2[0].split("/")[0]),
        Number(ngayGioDauThang2[1].split(":")[0]),
        Number(ngayGioDauThang2[1].split(":")[1])
      );
      const ngayDauThangBa = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang3[0].split("/")[1]) - 1,
        Number(ngayGioDauThang3[0].split("/")[0]),
        Number(ngayGioDauThang3[1].split(":")[0]),
        Number(ngayGioDauThang3[1].split(":")[1])
      );
      const ngayDauThangTu = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang4[0].split("/")[1]) - 1,
        Number(ngayGioDauThang4[0].split("/")[0]),
        Number(ngayGioDauThang4[1].split(":")[0]),
        Number(ngayGioDauThang4[1].split(":")[1])
      );
      const ngayDauThangNam = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang5[0].split("/")[1]) - 1,
        Number(ngayGioDauThang5[0].split("/")[0]),
        Number(ngayGioDauThang5[1].split(":")[0]),
        Number(ngayGioDauThang5[1].split(":")[1])
      );
      const ngayDauThangSau = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang6[0].split("/")[1]) - 1,
        Number(ngayGioDauThang6[0].split("/")[0]),
        Number(ngayGioDauThang6[1].split(":")[0]),
        Number(ngayGioDauThang6[1].split(":")[1])
      );
      const ngayDauThangBay = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang7[0].split("/")[1]) - 1,
        Number(ngayGioDauThang7[0].split("/")[0]),
        Number(ngayGioDauThang7[1].split(":")[0]),
        Number(ngayGioDauThang7[1].split(":")[1])
      );
      const ngayDauThangTam = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang8[0].split("/")[1]) - 1,
        Number(ngayGioDauThang8[0].split("/")[0]),
        Number(ngayGioDauThang8[1].split(":")[0]),
        Number(ngayGioDauThang8[1].split(":")[1])
      );
      const ngayDauThangChin = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang9[0].split("/")[1]) - 1,
        Number(ngayGioDauThang9[0].split("/")[0]),
        Number(ngayGioDauThang9[1].split(":")[0]),
        Number(ngayGioDauThang9[1].split(":")[1])
      );
      const ngayDauThangMuoi = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang10[0].split("/")[1]) - 1,
        Number(ngayGioDauThang10[0].split("/")[0]),
        Number(ngayGioDauThang10[1].split(":")[0]),
        Number(ngayGioDauThang10[1].split(":")[1])
      );
      const ngayDauThangMuoiMot = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang11[0].split("/")[1]) - 1,
        Number(ngayGioDauThang11[0].split("/")[0]),
        Number(ngayGioDauThang11[1].split(":")[0]),
        Number(ngayGioDauThang11[1].split(":")[1])
      );
      const ngayDauThangMuoiHai = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang12[0].split("/")[1]) - 1,
        Number(ngayGioDauThang12[0].split("/")[0]),
        Number(ngayGioDauThang12[1].split(":")[0]),
        Number(ngayGioDauThang12[1].split(":")[1])
      );
      let lichTietKhi = [];
      lichTietKhi.push(
        ngayDauThangMot,
        ngayDauThangHai,
        ngayDauThangBa,
        ngayDauThangTu,
        ngayDauThangNam,
        ngayDauThangSau,
        ngayDauThangBay,
        ngayDauThangTam,
        ngayDauThangChin,
        ngayDauThangMuoi,
        ngayDauThangMuoiMot,
        ngayDauThangMuoiHai
      );
      tietKhiDate = lichTietKhi;
    } else {
      let ngayGioDauThang1 = excelLichvanNien[thangLe][2]
        .split(" - ")[0]
        .split(" ");

      let ngayGioDauThang3 = excelLichvanNien[thangLe][6]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang5 = excelLichvanNien[thangLe][10]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang7 = excelLichvanNien[thangLe][14]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang9 = excelLichvanNien[thangLe][18]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang11 = excelLichvanNien[thangLe][22]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang2 = excelLichvanNien[thangChan][2]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang4 = excelLichvanNien[thangChan][6]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang6 = excelLichvanNien[thangChan][10]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang8 = excelLichvanNien[thangChan][14]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang10 = excelLichvanNien[thangChan][18]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang12 = excelLichvanNien[thangChan][22]
        .split(" - ")[0]
        .split(" ");

      const ngayDauThangMot = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang1[0].split("/")[1]) - 1,
        Number(ngayGioDauThang1[0].split("/")[0]),
        Number(ngayGioDauThang1[1].split(":")[0]),
        Number(ngayGioDauThang1[1].split(":")[1])
      );

      const ngayDauThangHai = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang2[0].split("/")[1]) - 1,
        Number(ngayGioDauThang2[0].split("/")[0]),
        Number(ngayGioDauThang2[1].split(":")[0]),
        Number(ngayGioDauThang2[1].split(":")[1])
      );
      const ngayDauThangBa = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang3[0].split("/")[1]) - 1,
        Number(ngayGioDauThang3[0].split("/")[0]),
        Number(ngayGioDauThang3[1].split(":")[0]),
        Number(ngayGioDauThang3[1].split(":")[1])
      );
      const ngayDauThangTu = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang4[0].split("/")[1]) - 1,
        Number(ngayGioDauThang4[0].split("/")[0]),
        Number(ngayGioDauThang4[1].split(":")[0]),
        Number(ngayGioDauThang4[1].split(":")[1])
      );
      const ngayDauThangNam = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang5[0].split("/")[1]) - 1,
        Number(ngayGioDauThang5[0].split("/")[0]),
        Number(ngayGioDauThang5[1].split(":")[0]),
        Number(ngayGioDauThang5[1].split(":")[1])
      );
      const ngayDauThangSau = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang6[0].split("/")[1]) - 1,
        Number(ngayGioDauThang6[0].split("/")[0]),
        Number(ngayGioDauThang6[1].split(":")[0]),
        Number(ngayGioDauThang6[1].split(":")[1])
      );
      const ngayDauThangBay = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang7[0].split("/")[1]) - 1,
        Number(ngayGioDauThang7[0].split("/")[0]),
        Number(ngayGioDauThang7[1].split(":")[0]),
        Number(ngayGioDauThang7[1].split(":")[1])
      );
      const ngayDauThangTam = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang8[0].split("/")[1]) - 1,
        Number(ngayGioDauThang8[0].split("/")[0]),
        Number(ngayGioDauThang8[1].split(":")[0]),
        Number(ngayGioDauThang8[1].split(":")[1])
      );
      const ngayDauThangChin = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang9[0].split("/")[1]) - 1,
        Number(ngayGioDauThang9[0].split("/")[0]),
        Number(ngayGioDauThang9[1].split(":")[0]),
        Number(ngayGioDauThang9[1].split(":")[1])
      );
      const ngayDauThangMuoi = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang10[0].split("/")[1]) - 1,
        Number(ngayGioDauThang10[0].split("/")[0]),
        Number(ngayGioDauThang10[1].split(":")[0]),
        Number(ngayGioDauThang10[1].split(":")[1])
      );
      const ngayDauThangMuoiMot = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang11[0].split("/")[1]) - 1,
        Number(ngayGioDauThang11[0].split("/")[0]),
        Number(ngayGioDauThang11[1].split(":")[0]),
        Number(ngayGioDauThang11[1].split(":")[1])
      );
      const ngayDauThangMuoiHai = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang12[0].split("/")[1]) - 1,
        Number(ngayGioDauThang12[0].split("/")[0]),
        Number(ngayGioDauThang12[1].split(":")[0]),
        Number(ngayGioDauThang12[1].split(":")[1])
      );
      let lichTietKhi = [];
      lichTietKhi.push(
        ngayDauThangMot,
        ngayDauThangHai,
        ngayDauThangBa,
        ngayDauThangTu,
        ngayDauThangNam,
        ngayDauThangSau,
        ngayDauThangBay,
        ngayDauThangTam,
        ngayDauThangChin,
        ngayDauThangMuoi,
        ngayDauThangMuoiMot,
        ngayDauThangMuoiHai
      );
      tietKhiDate = lichTietKhi;
    }
  };

  const get24TietKhiOfYear = (tempTime) => {
    const tempDateTime = new Date(tempTime);

    const year = tempDateTime.getFullYear();

    const distance = tempDateTime?.getFullYear() - 1800;

    const dongNgayDauThangLe = distance * 42 + 120;

    const dongNgayCuoiThangLe = distance * 42 + 121;

    const dongNgayDauThangChan = distance * 42 + 122;

    const dongNgayCuoiThangChan = distance * 42 + 123;

    if (year <= 1800 && year >= 1583) {
      const cuoiThangLe = Const.tietKhi_1583_1800_cuoiThangLe;

      const dauThangChan = Const.tietKhi_1583_1800_thangChan;

      const dauThangLe = Const.tietKhi_1583_1800_thangLe;

      const cuoiThangChan = Const.tietKhi_1583_1800_cuoiThangChan;

      tietKhiInYear = [
        dauThangLe[0]?.split(" - "),
        cuoiThangLe[0]?.split(" - "),
        dauThangChan[0]?.split(" - "),
        cuoiThangChan[0]?.split(" - "),
        dauThangLe[1]?.split(" - "),
        cuoiThangLe[1]?.split(" - "),
        dauThangChan[1]?.split(" - "),
        cuoiThangChan[1]?.split(" - "),
        dauThangLe[2]?.split(" - "),
        cuoiThangLe[2]?.split(" - "),
        dauThangChan[2]?.split(" - "),
        cuoiThangChan[2]?.split(" - "),
        dauThangLe[3]?.split(" - "),
        cuoiThangLe[3]?.split(" - "),
        dauThangChan[3]?.split(" - "),
        cuoiThangChan[3]?.split(" - "),
        dauThangLe[4]?.split(" - "),
        cuoiThangLe[4]?.split(" - "),
        dauThangChan[4]?.split(" - "),
        cuoiThangChan[4]?.split(" - "),
        dauThangLe[5]?.split(" - "),
        cuoiThangLe[5]?.split(" - "),
        dauThangChan[5]?.split(" - "),
        cuoiThangChan[5]?.split(" - "),
      ];
    } else if (year >= 0 && year <= 1582) {
      const cuoiThangLe = Const.tietKhi_0_1582_cuoiThangLe;

      const dauThangChan = Const.tietKhi_0_1582_thangChan;

      const dauThangLe = Const.tietKhi_0_1582_thangLe;

      const cuoiThangChan = Const.tietKhi_0_1582_cuoiThangChan;

      tietKhiInYear = [
        dauThangLe[0]?.split(" - "),
        cuoiThangLe[0]?.split(" - "),
        dauThangChan[0]?.split(" - "),
        cuoiThangChan[0]?.split(" - "),
        dauThangLe[1]?.split(" - "),
        cuoiThangLe[1]?.split(" - "),
        dauThangChan[1]?.split(" - "),
        cuoiThangChan[1]?.split(" - "),
        dauThangLe[2]?.split(" - "),
        cuoiThangLe[2]?.split(" - "),
        dauThangChan[2]?.split(" - "),
        cuoiThangChan[2]?.split(" - "),
        dauThangLe[3]?.split(" - "),
        cuoiThangLe[3]?.split(" - "),
        dauThangChan[3]?.split(" - "),
        cuoiThangChan[3]?.split(" - "),
        dauThangLe[4]?.split(" - "),
        cuoiThangLe[4]?.split(" - "),
        dauThangChan[4]?.split(" - "),
        cuoiThangChan[4]?.split(" - "),
        dauThangLe[5]?.split(" - "),
        cuoiThangLe[5]?.split(" - "),
        dauThangChan[5]?.split(" - "),
        cuoiThangChan[5]?.split(" - "),
      ];
    } else {
      const cuoiThangLe = excelLichvanNien[dongNgayCuoiThangLe];

      const dauThangChan = excelLichvanNien[dongNgayDauThangChan];

      const dauThangLe = excelLichvanNien[dongNgayDauThangLe];

      const cuoiThangChan = excelLichvanNien[dongNgayCuoiThangChan];

      tietKhiInYear = [
        dauThangLe[1]?.split(" - "),
        cuoiThangLe[1]?.split(" - "),
        dauThangChan[1]?.split(" - "),
        cuoiThangChan[1]?.split(" - "),
        dauThangLe[5]?.split(" - "),
        cuoiThangLe[5]?.split(" - "),
        dauThangChan[5]?.split(" - "),
        cuoiThangChan[5]?.split(" - "),
        dauThangLe[9]?.split(" - "),
        cuoiThangLe[9]?.split(" - "),
        dauThangChan[9]?.split(" - "),
        cuoiThangChan[9]?.split(" - "),
        dauThangLe[13]?.split(" - "),
        cuoiThangLe[13]?.split(" - "),
        dauThangChan[13]?.split(" - "),
        cuoiThangChan[13]?.split(" - "),
        dauThangLe[17]?.split(" - "),
        cuoiThangLe[17]?.split(" - "),
        dauThangChan[17]?.split(" - "),
        cuoiThangChan[17]?.split(" - "),
        dauThangLe[21]?.split(" - "),
        cuoiThangLe[21]?.split(" - "),
        dauThangChan[21]?.split(" - "),
        cuoiThangChan[21]?.split(" - "),
      ];
    }
  };

  const getCorrecTietKhi = (tempTime) => {
    let day = tempTime.getDate();
    let month = tempTime.getMonth();
    let year =
      tempTime < tietKhiDate[1]
        ? tempTime.getFullYear() - 1
        : tempTime.getFullYear();

    switch (tempTime.getMonth()) {
      // tháng 1 dương lịch nếu sau ngày đầu tháng 1 thì là tháng 12 năm ngoái
      //input ngày 1 tháng 1 2020
      // out put ngày 1 tháng 12 2019
      case 0:
        if (
          tempTime.getTime() === tietKhiDate[0].getTime() &&
          month === tietKhiDate[0].getMonth()
        ) {
          month = month;
        } else if (tempTime < tietKhiDate[0]) {
          month = 11;
        }
        break;
      case 1:
        // trước ngày lập xuân thì là tháng 12 của năm ngoái, ngược lại là tháng 1 năm sau
        if (
          tempTime.getTime() === tietKhiDate[1].getTime() &&
          month === tietKhiDate[1].getMonth()
        ) {
          month = month;
        } else if (tempTime < tietKhiDate[1]) {
          month = month - 1;
        }
        break;
      case 2:
        if (
          tempTime.getTime() === tietKhiDate[2].getTime() &&
          month === tietKhiDate[2].getMonth()
        ) {
          month = month;
        } else if (tempTime < tietKhiDate[2]) {
          month = month - 1;
        }
        break;
      case 3:
        if (
          tempTime.getTime() === tietKhiDate[3].getTime() &&
          month === tietKhiDate[3].getMonth()
        ) {
          month = month;
        } else if (tempTime < tietKhiDate[3]) {
          month = month - 1;
        }
        break;
      case 4:
        if (
          tempTime.getTime() === tietKhiDate[4].getTime() &&
          month === tietKhiDate[4].getMonth()
        ) {
          month = month;
        } else if (tempTime < tietKhiDate[4]) {
          month = month - 1;
        }
        break;
      case 5:
        if (
          tempTime.getTime() === tietKhiDate[5].getTime() &&
          month === tietKhiDate[5].getMonth()
        ) {
          month = month;
        } else if (tempTime < tietKhiDate[5]) {
          month = month - 1;
        }
        break;
      case 6:
        if (
          tempTime.getTime() === tietKhiDate[6].getTime() &&
          month === tietKhiDate[6].getMonth()
        ) {
          month = month;
        } else if (tempTime < tietKhiDate[6]) {
          month = month - 1;
        }
        break;
      case 7:
        if (
          tempTime.getTime() === tietKhiDate[7].getTime() &&
          month === tietKhiDate[7].getMonth()
        ) {
          month = month;
        } else if (tempTime < tietKhiDate[7]) {
          month = month - 1;
        }
        break;
      case 8:
        if (
          tempTime.getTime() === tietKhiDate[8].getTime() &&
          month === tietKhiDate[8].getMonth()
        ) {
          month = month;
        } else if (tempTime < tietKhiDate[8]) {
          month = month - 1;
        }
        break;
      case 9:
        if (
          tempTime.getTime() === tietKhiDate[9].getTime() &&
          month === tietKhiDate[9].getMonth()
        ) {
          month = month;
        } else if (tempTime < tietKhiDate[9]) {
          month = month - 1;
        }
        break;
      case 10:
        if (
          tempTime.getTime() === tietKhiDate[10].getTime() &&
          month === tietKhiDate[10].getMonth()
        ) {
          month = month;
        } else if (tempTime < tietKhiDate[10]) {
          month = month - 1;
        }
        break;
      case 11:
        if (
          tempTime.getTime() === tietKhiDate[11].getTime() &&
          month === tietKhiDate[11].getMonth()
        ) {
          month = month;
        } else if (tempTime < tietKhiDate[11]) {
          month = month - 1;
        }
        break;
      default:
        break;
    }
    return new Date(year, month, day);
  };

  async function handleResult() {
    try {
      if (JSON.parse(localStorage.getItem("profile"))?.isActive) {
        let tempTime;
        let checkYear = false;
        if (hour != "null") {
          tempTime = new Date(
            year + "",
            month + "",
            day + "",
            hour + "",
            minute + "",
            "00"
          );
          setCheckHour(false);
        } else {
          tempTime = new Date(
            year + "",
            month + "",
            day + "",
            0 + "",
            minute + "",
            "00"
          );

          setCheckHour(true);
        }

        if (year >= 0 && year <= 100) {
          tempTime.setFullYear(+year + 1000, month, day, hour, minute);
          checkYear = true;
        } else {
          checkYear = false;
        }

        if (excelLichvanNien) {
          getTietKhiAllYear(tempTime);
          const lichtietKHI = getNamtietKhi(tempTime, excelLichvanNien);
          get24TietKhiOfYear(tempTime);
          const dateTietKhi = getCorrecTietKhi(tempTime);

          // const yearExcel = getYearExcel(dateTietKhi);
          // const luuNienYear = new Date(`${LuuNienState}`);
          const namTietKhi = handleYearTietKhi(
            dateTietKhi.getFullYear(),
            gender
          ); // huong tot huong xau

          const canYear = YearToCan(dateTietKhi.getFullYear()); //

          const chiYear = YearToChi(dateTietKhi.getFullYear());

          const canChiDay = DayToCanChi(tempTime.toDateString());

          const canChiMonth = MonthToCanChi(
            canYear.name,
            dateTietKhi?.getMonth()
          ); //

          const napAmNgay = getNamAp(canChiDay?.name);

          setThienCanYear(canYear?.name);
          setDiachiYear(chiYear?.name);

          let chiHour = null;
          let canDay = null;
          let queNgay = null;
          let chiNgay = "";
          let chiMonthLunar = "";
          let suntime;

          if (checkSelected) {
            let times = SunCalc.getTimes(tempTime, viDo, kinhDo); // tg mat troi`
            suntime = solarTime(times.solarNoon, tempTime);

            if (suntime.hour >= 0 && suntime.hour <= 9) {
              let solargio = `0${suntime.hour}`;
              setgioSolar(solargio);
            } else {
              setgioSolar(suntime.hour);
            }

            if (suntime.minutes >= 0 && suntime.minutes <= 9) {
              let solarphut = `0${suntime.minutes}`;
              setSolarphut(solarphut);
            } else {
              setSolarphut(suntime.minutes);
            }
          } else {
            if (hour != "null") setgioSolar(hour);
            else setgioSolar("00");
            setSolarphut(minute);
          }

          const initData = dateData.map((item) => {
            if (item.header === "Năm") {
              item.canChi = canYear?.name;
              item.diaChi = chiYear?.name;
              item.qua = getQue(canYear?.name, chiYear?.name)?.tenque;
              item.que = getQue(canYear?.name, chiYear?.name);
            } else if (item.header === "Tháng") {
              //tách chuỗi name trong canChiMonth
              let tempCan = canChiMonth?.name.split(" ");

              item.canChi = tempCan[0];
              item.diaChi = tempCan[1];
              item.qua = getQue(tempCan[0], tempCan[1]).tenque;
              item.que = getQue(tempCan[0], tempCan[1]);
              chiMonthLunar = tempCan[1];
            } else if (item.header === "Ngày") {
              let tempCanDay = canChiDay.name.split(" ");
              item.canChi = tempCanDay[0];
              item.diaChi = tempCanDay[1];
              // let chiDate = Const.ChiArr.find((v) => v.name === tempCanDay[1]);
              // let canDate = Const.CanArr.find((v) => v.name === tempCanDay[0]);

              item.qua = getQue(tempCanDay[0], tempCanDay[1]);
              item.que = getQue(tempCanDay[0], tempCanDay[1]);
              queNgay = item.que;
              chiNgay = tempCanDay[1];
            } else if (item.header === "Giờ") {
              let tempCanDay = canChiDay.name.split(" ");
              let tempCanChiHour;
              if (checkSelected) {
                tempCanChiHour = HourToCanChi(
                  tempCanDay[0],
                  suntime.hour
                ).name.split(" ");
              } else {
                tempCanChiHour = HourToCanChi(
                  tempCanDay[0],
                  tempTime.getHours()
                ).name.split(" ");
              }

              item.canChi = tempCanChiHour[0];
              item.diaChi = tempCanChiHour[1];
              item.qua = getQue(tempCanChiHour[0], tempCanChiHour[1]).tenque;
              item.que = getQue(tempCanChiHour[0], tempCanChiHour[1]);
              chiHour = tempCanChiHour;
              canDay = tempCanDay[0];
            }

            return item;
          });

          const truGio = TruGio(dateData[0].canChi, hour, minute);

          setDateData(initData);
          setStateTruGio(truGio);

          setThienCanHour(dateData[0].canChi);
          setdiaChiHour(dateData[0].diaChi); // dia chi gio
          setnapAmHour(dateData[0].que.napAm);

          setthienCanDay(dateData[1].canChi); //thien can ngay
          setdiaChiDay(dateData[1].diaChi); // dia chi ngay
          setnapAmDay(dateData[1].que.napAm);

          const tsTCArr = ThanSatThienCanArr(dateData[1].canChi);
          const tsTCYearArr = ThanSatThienCanArr(dateData[3].canChi);
          setTSThienCanArr(tsTCArr);
          setTSThienCanYearArr(tsTCYearArr);

          const nguHanhTCHour = nguHanh(dateData[1].canChi, dateData[0].canChi);
          const nguHanhTCDay = nguHanh(dateData[1].canChi, dateData[1].canChi);
          const nguHanhTCMonth = nguHanh(
            dateData[1].canChi,
            dateData[2].canChi
          );
          const nguHanhTCYear = nguHanh(dateData[1].canChi, dateData[3].canChi);
          setnguHanhHour(nguHanhTCHour);
          setnguHanhDay(nguHanhTCDay);
          setnguHanhMonth(nguHanhTCMonth);
          setnguHanhYear(nguHanhTCYear);

          const thapThanHourResult = ThapThan(
            dateData[1]?.canChi,
            dateData[0]?.canChi
          );
          const thapThanMonthResult = ThapThan(
            dateData[1]?.canChi,
            dateData[2]?.canChi
          );
          const thapThanYearResult = ThapThan(
            dateData[1]?.canChi,
            dateData[3]?.canChi
          );

          const TSArr = ThanSatDiaChiArr(dateData[1].diaChi);

          const TSYearArr = ThanSatDiaChiArr(dateData[3].diaChi);
          let data = [];
          let dataYear = [];
          data = [...TSArr];
          data = [...data, ...tsTCArr];

          dataYear = [...TSYearArr];
          dataYear = [...dataYear, ...tsTCYearArr];

          const thanSatDayArr = ThanSatDiaChi(
            dateData[1].diaChi,
            dateData[1].diaChi
          );

          const thanSatYearArr = dataYear?.filter((item) => {
            if (item?.giap.includes(dateData[3]?.diaChi)) {
              return item?.name;
            }
          });

          setthanSatDiaChiDay(thanSatDayArr);
          setTSDiaChiArr(TSArr);
          seTSDiaChiYearArr(TSYearArr);
          setthanSatDiaChiYear(thanSatYearArr);

          const thanSatHourArr = ThanSatDiaChi(
            dateData[1].diaChi,
            dateData[0].diaChi
          );

          const thanSatMonthArr = ThanSatDiaChi(
            dateData[1].diaChi,
            dateData[2].diaChi
          );

          setthanSatDiachiHours(thanSatHourArr);
          setthanSatDiachiMonth(thanSatMonthArr);

          const thanSatHourCan = ThanSatThienCan(
            dateData[1].canChi,
            dateData[0].canChi
          );
          const thanSatDayCan = ThanSatThienCan(
            dateData[1].canChi,
            dateData[1].canChi
          );

          const thanSatMonthCan = ThanSatThienCan(
            dateData[1].canChi,
            dateData[2].canChi
          );
          const thanSatYearCan = ThanSatThienCan(dateData[1].canChi, canYear);

          setthanSatThienCanHour(thanSatHourCan);
          setthanSatThienCanDay(thanSatDayCan);
          setthanSatThienCanMonth(thanSatMonthCan);
          setthanSatThienCanYear(thanSatYearCan);

          setthapThanHour(thapThanHourResult?.sinhKhac);
          setthapThanMonth(thapThanMonthResult?.sinhKhac);
          setthapThanYear(thapThanYearResult?.sinhKhac);

          setthienCanMonth(dateData[2].canChi);
          setdiaChiMonth(dateData[2].diaChi);
          setnapAmMonth(dateData[2].que.napAm);

          setnapAmYear(dateData[3]?.que?.napAm);

          const TangCanHourArr = TangCan(dateData[0]?.diaChi);

          const TangCanDayArr = TangCan(dateData[1]?.diaChi);

          const TangCanMonthArr = TangCan(dateData[2]?.diaChi);

          const TangCanYearArr = TangCan(dateData[3]?.diaChi);

          const thapThanHour1 = ThapThan(
            dateData[1].canChi,
            TangCanHourArr[0]?.name
          );
          const thapThanHour2 = ThapThan(
            dateData[1].canChi,
            TangCanHourArr[1]?.name
          );
          const thapThanHour3 = ThapThan(
            dateData[1].canChi,
            TangCanHourArr[2]?.name
          );

          const thapThanDay1 = ThapThan(
            dateData[1].canChi,
            TangCanDayArr[0]?.name
          );
          const thapThanDay2 = ThapThan(
            dateData[1].canChi,
            TangCanDayArr[1]?.name
          );
          const thapThanDay3 = ThapThan(
            dateData[1].canChi,
            TangCanDayArr[2]?.name
          );

          const thapThanMonth1 = ThapThan(
            dateData[1].canChi,
            TangCanMonthArr[0]?.name
          );
          const thapThanMonth2 = ThapThan(
            dateData[1].canChi,
            TangCanMonthArr[1]?.name
          );
          const thapThanMonth3 = ThapThan(
            dateData[1].canChi,
            TangCanMonthArr[2]?.name
          );

          const thapThanYear1 = ThapThan(
            dateData[1].canChi,
            TangCanYearArr[0]?.name
          );
          const thapThanYear2 = ThapThan(
            dateData[1].canChi,
            TangCanYearArr[1]?.name
          );
          const thapThanYear3 = ThapThan(
            dateData[1].canChi,
            TangCanYearArr[2]?.name
          );

          setthapThanHour1(thapThanHour1?.sinhKhac);
          setthapThanHour2(thapThanHour2?.sinhKhac);
          setthapThanHour3(thapThanHour3?.sinhKhac);

          setthapThanDay1(thapThanDay1?.sinhKhac);
          setthapThanDay2(thapThanDay2?.sinhKhac);
          setthapThanDay3(thapThanDay3?.sinhKhac);

          setthapThanMonth1(thapThanMonth1?.sinhKhac);
          setthapThanMonth2(thapThanMonth2?.sinhKhac);
          setthapThanMonth3(thapThanMonth3?.sinhKhac);

          setthapThanYear1(thapThanYear1?.sinhKhac);
          setthapThanYear2(thapThanYear2?.sinhKhac);
          setthapThanYear3(thapThanYear3?.sinhKhac);

          settangCanNguHanhHour(TangCanHourArr[0]?.name);
          settangCanNguHanHourHour4(TangCanHourArr[0]?.nguHanh);
          settangCanNguHanHourHour2(TangCanHourArr[1]?.name);
          settangCanNguHanHourHour5(TangCanHourArr[1]?.nguHanh);
          settangCanNguHanHourHour3(TangCanHourArr[2]?.name);
          settangCanNguHanHourHour6(TangCanHourArr[2]?.nguHanh);

          settangCanNguHanhDay(TangCanDayArr[0]?.name);
          settangCanNguHanhDay4(TangCanDayArr[0]?.nguHanh);
          settangCanNguHanhDay2(TangCanDayArr[1]?.name);
          settangCanNguHanhDay5(TangCanDayArr[1]?.nguHanh);
          settangCanNguHanhDay3(TangCanDayArr[2]?.name);
          settangCanNguHanhDay6(TangCanDayArr[2]?.nguHanh);

          settangCanMonth(TangCanMonthArr[0]?.name);
          settangCanMonth4(TangCanMonthArr[0]?.nguHanh);
          settangCanMonth2(TangCanMonthArr[1]?.name);
          settangCanMonth5(TangCanMonthArr[1]?.nguHanh);
          settangCanMonth3(TangCanMonthArr[2]?.name);
          settangCanMonth6(TangCanMonthArr[2]?.nguHanh);

          settangCanYear(TangCanYearArr[0]?.name);
          settangCanYear4(TangCanYearArr[0]?.nguHanh);
          settangCanYear2(TangCanYearArr[1]?.name);
          settangCanYear5(TangCanYearArr[1]?.nguHanh);
          settangCanYear3(TangCanYearArr[2]?.name);
          settangCanYear6(TangCanYearArr[2]?.nguHanh);

          const ketLuan = KetLuanLaSo(
            TangCanMonthArr[0].nguHanh,
            TangCanYearArr[0].nguHanh,
            TangCanDayArr[0].nguHanh,
            TangCanHourArr[0].nguHanh,
            nguHanhTCYear,
            nguHanhTCMonth,
            nguHanhTCHour,
            nguHanhTCDay,
            dateData[2]?.diaChi,
            dateData[0]?.canChi,
            dateData[2]?.canChi,
            dateData[3]?.canChi
          );
          setDungThan(ketLuan[0].dungThan);

          setShow(true);
        }
      }
    } catch (e) {
      console.log("catch", e);
    } finally {
      let timeShow = moment(`${+year}-${+month + 1}-${+day}`, "YYYY-MM-DD"); // 29
      let months = timeShow.format("MM");
      let days = timeShow.format("DD");
      const dataHour = hour !== "null" ? hour : "00";

      setDayShow(days);
      setMonthShow(months);
      setYearShow(year);
    }
  }

  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ padding: 0 }} className="input__group hide-to-print">
          <div className="flexx__" style={{ justifyContent: "flex-start" }}>
            <InputGroup style={{ flexWrap: "nowrap" }}>
              {" "}
              <Label style={{ minWidth: "100px" }}>Họ và tên: </Label>{" "}
              <Input
                style={{ minWidth: 250 }}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </InputGroup>
          </div>
          <div
            className="flexx__"
            style={{ justifyContent: "flex-start", marginTop: 15 }}
          >
            <InputGroup style={{ flexWrap: "nowrap" }}>
              {" "}
              <Label style={{ minWidth: "100px" }}>Giới tính: </Label>{" "}
              <Input
                onChange={(e) => setGender(e.target.value)}
                style={{ minWidth: "100px" }}
                type="select"
              >
                {" "}
                <option>Nam</option>
                <option>Nữ</option>
              </Input>
            </InputGroup>

            <InputGroup style={{ flexWrap: "nowrap", marginLeft: 40 }}>
              <Label style={{ minWidth: 90 }}>Giờ sinh: </Label>
              <Input
                className={hour === "null" ? "notoption" : ""}
                onChange={(e) => {
                  onChangeHour(e.target.value);
                }}
                style={
                  hour !== "null" ? { minWidth: "100px" } : { minWidth: 200 }
                }
                type="select"
                value={hour}
              >
                <option value={"null"}>Không chọn giờ sinh</option>
                <option>00</option>
                <option>01</option>
                <option>02</option>
                <option>03</option>
                <option>04</option>
                <option>05</option>
                <option>06</option>
                <option>07</option>
                <option>08</option>
                <option>09</option>
                <option>10</option>
                <option>11</option>
                <option>12</option>
                <option>13</option>
                <option>14</option>
                <option>15</option>
                <option>16</option>
                <option>17</option>
                <option>18</option>
                <option>19</option>
                <option>20</option>
                <option>21</option>
                <option>22</option>
                <option>23</option>
              </Input>
              {hour == "null" ? "" : ":"}
              {hour == "null" ? (
                ""
              ) : (
                <Input
                  onChange={(e) => setminute(e.target.value)}
                  style={{ minWidth: "100px", marginLeft: "10px" }}
                  type="select"
                  value={minute}
                >
                  <option>00</option>
                  <option>01</option>
                  <option>02</option>
                  <option>03</option>
                  <option>04</option>
                  <option>05</option>
                  <option>06</option>
                  <option>07</option>
                  <option>08</option>
                  <option>09</option>
                  <option>10</option>
                  <option>11</option>
                  <option>12</option>
                  <option>13</option>
                  <option>14</option>
                  <option>15</option>
                  <option>16</option>
                  <option>17</option>
                  <option>18</option>
                  <option>19</option>
                  <option>20</option>
                  <option>21</option>
                  <option>22</option>
                  <option>23</option>
                  <option>24</option>
                  <option>25</option>
                  <option>26</option>
                  <option>27</option>
                  <option>28</option>
                  <option>29</option>
                  <option>30</option>
                  <option>31</option>
                  <option>32</option>
                  <option>33</option>
                  <option>34</option>
                  <option>35</option>
                  <option>36</option>
                  <option>37</option>
                  <option>38</option>
                  <option>39</option>
                  <option>40</option>
                  <option>41</option>
                  <option>42</option>
                  <option>43</option>
                  <option>44</option>
                  <option>45</option>
                  <option>46</option>
                  <option>47</option>
                  <option>48</option>
                  <option>49</option>
                  <option>50</option>
                  <option>51</option>
                  <option>52</option>
                  <option>53</option>
                  <option>54</option>
                  <option>55</option>
                  <option>56</option>
                  <option>57</option>
                  <option>58</option>
                  <option>59</option>
                </Input>
              )}
            </InputGroup>
          </div>

          <div style={{ justifyContent: "flex-start" }} className="flexx__">
            <InputGroup style={{ marginTop: "15px", flexWrap: "nowrap" }}>
              <Label style={{ minWidth: "100px" }}>Ngày sinh: </Label>
              <Input
                onChange={(e) => setDay(e.target.value)}
                style={{ minWidth: "100px" }}
                type="select"
                value={day}
              >
                <option>01</option>
                <option>02</option>
                <option>03</option>
                <option>04</option>
                <option>05</option>
                <option>06</option>
                <option>07</option>
                <option>08</option>
                <option>09</option>
                <option>10</option>
                <option>11</option>
                <option>12</option>
                <option>13</option>
                <option>14</option>
                <option>15</option>
                <option>16</option>
                <option>17</option>
                <option>18</option>
                <option>19</option>
                <option>20</option>
                <option>21</option>
                <option>22</option>
                <option>23</option>
                <option>24</option>
                <option>25</option>
                <option>26</option>
                <option>27</option>
                <option>28</option>
                <option>29</option>
                <option>30</option>
                <option>31</option>
              </Input>
            </InputGroup>

            <InputGroup
              style={{ marginLeft: 40, marginTop: 15, flexWrap: "nowrap" }}
            >
              <Label style={{ minWidth: 90 }}>Tháng: </Label>
              <Input
                onChange={(e) => setMonth(e.target.value)}
                style={{ minWidth: "80px" }}
                type="select"
                value={month}
              >
                <option value={0}>01</option>
                <option value={1}>02</option>
                <option value={2}>03</option>
                <option value={3}>04</option>
                <option value={4}>05</option>
                <option value={5}>06</option>
                <option value={6}>07</option>
                <option value={7}>08</option>
                <option value={8}>09</option>
                <option value={9}>10</option>
                <option value={10}>11</option>
                <option value={11}>12</option>
              </Input>
            </InputGroup>

            <InputGroup style={{ marginTop: "15px", flexWrap: "nowrap" }}>
              <Label style={{ minWidth: "60px" }}>Năm: </Label>
              <Input
                onChange={(e) => setYear(e.target.value)}
                style={{ minWidth: "100px" }}
                type="select"
                value={year}
              >
                {namSinh()}
              </Input>
            </InputGroup>
          </div>
          <Button
            style={{ marginTop: 20, marginBottom: 20 }}
            onClick={handleResult}
            color="primary"
          >
            So sánh
          </Button>
        </div>
        {show && (
          <Table style={{ height: "auto" }} bordered hover>
            <tbody>
              <tr
                className="clr__print"
                style={{
                  textAlign: "center",
                  fontSize: "22px",
                  color: "white",
                }}
              >
                {checkHour ? (
                  ""
                ) : (
                  <th
                    id="bg__h"
                    colspan="3"
                    style={{
                      width: "150px",
                      border: "3px solid #000000",
                      background: "#1B61A6",
                      fontWeight: "normal",
                      height: "64px",
                    }}
                  >
                    {gioSolar} : {solarphut}
                  </th>
                )}
                <th
                  id="bg__d"
                  colspan="3"
                  style={{
                    width: "150px",
                    border: "3px solid #000000",
                    background: "#1B61A6",
                    fontWeight: "normal",
                  }}
                >
                  {" "}
                  {dayShow}
                </th>
                <th
                  id="bg__m"
                  colspan="3"
                  style={{
                    width: "150px",
                    border: "3px solid #000000",
                    background: "#1B61A6",
                    fontWeight: "normal",
                  }}
                >
                  {" "}
                  {monthShow}
                </th>
                <th
                  id="bg__y"
                  colspan="3"
                  style={{
                    width: "150px",
                    border: "3px solid #000000",
                    background: "#1B61A6",
                    fontWeight: "normal",
                  }}
                >
                  {" "}
                  {yearShow}
                </th>
                <th
                  id="bg__a"
                  colspan="3"
                  style={{
                    width: "58px",
                    border: "3px solid #000000",
                    background: "#1B61A6",
                  }}
                >
                  {" "}
                </th>
              </tr>

              <tr style={{ textAlign: "center" }}>
                {checkHour ? (
                  ""
                ) : (
                  <th colspan="3" className="fz__25 clr__ border__">
                    <sup className="fz__15">{thapThanHour}</sup>
                    {thienCanHour}
                  </th>
                )}

                <th
                  id="bg__tcd"
                  style={{ background: "#B2CFEA", cursor: "pointer" }}
                  colspan="3"
                  className="fz__25 border__"
                  data-for="tinh-cach"
                  data-tip={thienCanDay + " " + nguHanhDay}
                >
                  {thienCanDay}
                </th>
                <th colspan="3" className="fz__25 clr__ border__">
                  <sup className="fz__15">{thapThanMonth}</sup>
                  {thienCanMonth}
                </th>

                <th colspan="3" className="fz__25 clr__ border__">
                  <sup className="fz__15">{thapThanYear}</sup>
                  {thienCanYear}
                </th>

                <th
                  id="bg__tc"
                  rowSpan="2"
                  style={{ width: "58px", border: "3px solid #000000" }}
                  className="bg__ clr__white clr__print"
                >
                  Thiên Can
                </th>
              </tr>
              <tr style={{ textAlign: "center", fontSize: "18px" }}>
                {checkHour ? (
                  ""
                ) : (
                  <th
                    style={{ fontWeight: "normal" }}
                    colspan="3"
                    className={
                      dungThan.includes(nguHanhHour.split(" ")[1]) &&
                      checkDungThan
                        ? "border__ background"
                        : "border__"
                    }
                    id={
                      dungThan.includes(nguHanhHour.split(" ")[1]) &&
                      checkDungThan &&
                      "dungThan"
                    }
                  >
                    {nguHanhHour}
                  </th>
                )}
                <th
                  id="bg__nhd"
                  style={{
                    fontWeight: "normal",
                    background: "#B2CFEA",
                    cursor: "pointer",
                  }}
                  colspan="3"
                  className={"border__"}
                  data-for="ngu-hanh"
                  data-tip={nguHanhDay}
                >
                  {nguHanhDay}
                </th>
                <th
                  className={
                    dungThan.includes(nguHanhMonth.split(" ")[1]) &&
                    checkDungThan
                      ? "border__ background"
                      : "border__"
                  }
                  id={
                    dungThan.includes(nguHanhMonth.split(" ")[1]) &&
                    checkDungThan &&
                    "dungThan"
                  }
                  style={{ fontWeight: "normal" }}
                  colspan="3"
                >
                  {nguHanhMonth}
                </th>
                <th
                  style={{ fontWeight: "normal" }}
                  colspan="3"
                  className={
                    dungThan.includes(nguHanhYear.split(" ")[1]) &&
                    checkDungThan
                      ? "border__ background"
                      : "border__"
                  }
                  id={
                    dungThan.includes(nguHanhYear.split(" ")[1]) &&
                    checkDungThan &&
                    "dungThan"
                  }
                >
                  {nguHanhYear}
                </th>
              </tr>
              {/* dia chi  */}
              <tr style={{ textAlign: "center" }}>
                {checkHour ? (
                  ""
                ) : (
                  <th colspan="3" className="fz__25 clr__ border__">
                    {diaChiHour}
                  </th>
                )}

                <th
                  id="bg__dcd"
                  style={{ background: "#B2CFEA" }}
                  colspan="3"
                  className="fz__25 border__"
                >
                  {diaChiDay}
                </th>
                <th
                  colspan="3"
                  className="fz__25 clr__ border__"
                  style={{ cursor: "pointer" }}
                  data-for="dung-than"
                  data-tip={thienCanDay + " Tháng " + diaChiMonth}
                >
                  {diaChiMonth}
                </th>

                <th colspan="3" className="fz__25 clr__ border__">
                  {DiachiYear}
                </th>

                <th
                  id="bg__dc"
                  rowspan="1"
                  style={{ width: "58px", border: "3px solid #000000" }}
                  className="bg__ clr__white clr__print"
                >
                  Địa Chi
                </th>
              </tr>

              {/** tang can gio`*/}

              <tr className="fz__18" style={{ textAlign: "center" }}>
                {checkHour ? (
                  ""
                ) : tangCanNguHanhHour2 ? (
                  <td
                    className={
                      dungThan.includes(tangCanNguHanhHour4.split(" ")[1]) &&
                      checkDungThan
                        ? "background border__"
                        : "border__"
                    }
                    id={
                      dungThan.includes(tangCanNguHanhHour4.split(" ")[1]) &&
                      checkDungThan &&
                      "dungThan"
                    }
                    colspan="1"
                    style={{
                      verticalAlign: "middle",
                      padding: "0",
                      fontSize: "14px",
                    }}
                  >
                    <div>
                      <sup>{thapThanHour2}</sup>
                      {tangCanNguHanhHour2}
                    </div>
                    <div
                      classNamestyle={{
                        justifyContent: "center",
                        width: "47px",
                      }}
                    >
                      {tangCanNguHanhHour5}
                    </div>
                  </td>
                ) : (
                  ""
                )}

                {checkHour ? (
                  ""
                ) : tangCanNguHanhHour2 && tangCanNguHanhHour3 ? (
                  <th
                    id={
                      dungThan.includes(tangCanNguHanhHour4.split(" ")[1]) &&
                      checkDungThan
                        ? "dungThan"
                        : "tcbox"
                    }
                    className={
                      dungThan.includes(tangCanNguHanhHour4.split(" ")[1]) &&
                      checkDungThan
                        ? "background border__"
                        : "border__"
                    }
                    colspan="1"
                    style={{
                      verticalAlign: "middle",
                      padding: "0",
                      fontSize: "14px",
                    }}
                  >
                    <div>
                      <sup>{thapThanHour1}</sup>
                      {tangCanNguHanhHour}
                    </div>
                    <div style={{ justifyContent: "center", width: "47px" }}>
                      {tangCanNguHanhHour4}
                    </div>
                  </th>
                ) : tangCanNguHanhHour3 ? (
                  <th
                    id={
                      dungThan.includes(tangCanNguHanhHour4.split(" ")[1]) &&
                      checkDungThan
                        ? "dungThan"
                        : "tcbox"
                    }
                    className={
                      dungThan.includes(tangCanNguHanhHour4.split(" ")[1]) &&
                      checkDungThan
                        ? "background border__"
                        : "border__"
                    }
                    colspan="2"
                    style={{ verticalAlign: "middle", padding: "0" }}
                  >
                    <div>
                      <sup>{thapThanHour1}</sup>
                      {tangCanNguHanhHour}
                    </div>
                    <div style={{ justifyContent: "center" }}>
                      {tangCanNguHanhHour4}
                    </div>
                  </th>
                ) : (
                  <th
                    id={
                      dungThan.includes(tangCanNguHanhHour4.split(" ")[1]) &&
                      checkDungThan
                        ? "dungThan"
                        : "tcbox"
                    }
                    className={
                      dungThan.includes(tangCanNguHanhHour4.split(" ")[1]) &&
                      checkDungThan
                        ? "background border__"
                        : "border__"
                    }
                    colspan="3"
                    style={{ verticalAlign: "middle", padding: "0" }}
                  >
                    <div>
                      <sup>{thapThanHour1}</sup>
                      {tangCanNguHanhHour}
                    </div>
                    <div style={{ justifyContent: "center" }}>
                      {tangCanNguHanhHour4}
                    </div>
                  </th>
                )}

                {checkHour ? (
                  ""
                ) : tangCanNguHanhHour3 && tangCanNguHanhHour2 ? (
                  <td
                    id={
                      dungThan.includes(tangCanNguHanhHour4.split(" ")[1]) &&
                      checkDungThan &&
                      "dungThan"
                    }
                    className={
                      dungThan.includes(tangCanNguHanhHour4.split(" ")[1]) &&
                      checkDungThan
                        ? "background border__"
                        : "border__"
                    }
                    colspan="1"
                    style={{
                      verticalAlign: "middle",
                      padding: "0",
                      fontSize: "14px",
                    }}
                  >
                    <div>
                      <sup>{thapThanHour3}</sup>
                      {tangCanNguHanhHour3}
                    </div>
                    <div style={{ justifyContent: "center" }}>
                      {tangCanNguHanhHour6}
                    </div>
                  </td>
                ) : tangCanNguHanhHour3 && tangCanNguHanhHour ? (
                  <td
                    id={
                      dungThan.includes(tangCanNguHanhHour4.split(" ")[1]) &&
                      checkDungThan &&
                      "dungThan"
                    }
                    className={
                      dungThan.includes(tangCanNguHanhHour4.split(" ")[1]) &&
                      checkDungThan
                        ? "background border__"
                        : "border__"
                    }
                    colspan="1"
                    style={{
                      verticalAlign: "middle",
                      padding: "0",
                      fontSize: "16px",
                    }}
                  >
                    <div>
                      <sup>{thapThanHour3}</sup>
                      {tangCanNguHanhHour3}
                    </div>
                    <div style={{ justifyContent: "center" }}>
                      {tangCanNguHanhHour6}
                    </div>
                  </td>
                ) : (
                  ""
                )}

                {/** tang can gio`*/}

                {/** tang can ngay`*/}

                {tangCanNguHanhDay2 ? (
                  <td
                    style={{
                      background: "#B2CFEA",
                      verticalAlign: "middle",
                      padding: "0",
                      fontSize: "14px",
                    }}
                    id={
                      dungThan.includes(tangCanNguHanhDay4.split(" ")[1]) &&
                      checkDungThan
                        ? "dungThan"
                        : "bg__tcd"
                    }
                    className={
                      dungThan.includes(tangCanNguHanhDay4.split(" ")[1]) &&
                      checkDungThan
                        ? "background border__"
                        : "border__"
                    }
                  >
                    <div>
                      <sup>{thapThanDay2}</sup>
                      {tangCanNguHanhDay2}
                    </div>
                    <div style={{ justifyContent: "center", width: "47px" }}>
                      {tangCanNguHanhDay5}
                    </div>
                  </td>
                ) : (
                  ""
                )}

                {tangCanNguHanhDay2 && tangCanNguHanhDay3 ? (
                  <th
                    className={
                      dungThan.includes(tangCanNguHanhDay4.split(" ")[1]) &&
                      checkDungThan
                        ? "background border__"
                        : "border__"
                    }
                    id={
                      dungThan.includes(tangCanNguHanhDay4.split(" ")[1]) &&
                      checkDungThan
                        ? "dungThan"
                        : "bg__tcd2"
                    }
                    colspan="1"
                    style={{
                      border: "none",
                      background: "#B2CFEA",
                      verticalAlign: "middle",
                      padding: "0",
                      fontSize: "14px",
                    }}
                  >
                    <div>
                      <sup>{thapThanDay1}</sup>
                      {tangCanNguHanhDay}
                    </div>
                    <div style={{ justifyContent: "center", width: "47px" }}>
                      {tangCanNguHanhDay4}
                    </div>
                  </th>
                ) : tangCanNguHanhDay3 ? (
                  <th
                    className={
                      dungThan.includes(tangCanNguHanhDay4.split(" ")[1]) &&
                      checkDungThan
                        ? "background border__"
                        : "border__"
                    }
                    id={
                      dungThan.includes(tangCanNguHanhDay4.split(" ")[1]) &&
                      checkDungThan
                        ? "dungThan"
                        : "bg__tcd2"
                    }
                    style={{
                      background: "#B2CFEA",
                      verticalAlign: "middle",
                      padding: "0",
                      fontSize: "16px",
                    }}
                    colspan="2"
                  >
                    <div>
                      <sup>{thapThanDay1}</sup>
                      {tangCanNguHanhDay}
                    </div>
                    <div style={{ justifyContent: "center" }}>
                      {tangCanNguHanhDay4}
                    </div>
                  </th>
                ) : (
                  <th
                    className={
                      dungThan.includes(tangCanNguHanhDay4.split(" ")[1]) &&
                      checkDungThan
                        ? "background border__"
                        : "border__"
                    }
                    id={
                      dungThan.includes(tangCanNguHanhDay4.split(" ")[1]) &&
                      checkDungThan
                        ? "dungThan"
                        : "bg__tcd2"
                    }
                    style={{ background: "#B2CFEA" }}
                    colspan="3"
                  >
                    <div>
                      <sup>{thapThanDay1}</sup>
                      {tangCanNguHanhDay}
                    </div>
                    <div style={{ justifyContent: "center" }}>
                      {tangCanNguHanhDay4}
                    </div>
                  </th>
                )}

                {tangCanNguHanhDay3 && tangCanNguHanhDay2 ? (
                  <td
                    className={
                      dungThan.includes(tangCanNguHanhDay4.split(" ")[1]) &&
                      checkDungThan
                        ? "background border__"
                        : "border__"
                    }
                    id={
                      dungThan.includes(tangCanNguHanhDay4.split(" ")[1]) &&
                      checkDungThan
                        ? "dungThan"
                        : "bg__tcd3"
                    }
                    colspan="1"
                    style={{
                      background: "#B2CFEA",
                      verticalAlign: "middle",
                      padding: "0",
                      fontSize: "14px",
                    }}
                  >
                    <div>
                      <sup>{thapThanDay3}</sup>
                      {tangCanNguHanhDay3}
                    </div>
                    <div style={{ justifyContent: "center" }}>
                      {tangCanNguHanhDay6}
                    </div>
                  </td>
                ) : tangCanNguHanhDay3 && tangCanNguHanhDay ? (
                  <td
                    className={
                      dungThan.includes(tangCanNguHanhDay4.split(" ")[1]) &&
                      checkDungThan
                        ? "background border__"
                        : "border__"
                    }
                    id={
                      dungThan.includes(tangCanNguHanhDay4.split(" ")[1]) &&
                      checkDungThan
                        ? "dungThan"
                        : "bg__tcd3"
                    }
                    colspan="1"
                    style={{
                      background: "#B2CFEA",
                      verticalAlign: "middle",
                      padding: "0",
                      fontSize: "16px",
                    }}
                  >
                    <div>
                      <sup>{thapThanDay3}</sup>
                      {tangCanNguHanhDay3}
                    </div>
                    <div style={{ justifyContent: "center" }}>
                      {tangCanNguHanhDay6}
                    </div>
                  </td>
                ) : (
                  ""
                )}

                {/** tang can ngay``*/}

                {/** tang can thang*/}
                {tangCanMonth2 ? (
                  <td
                    className={
                      dungThan.includes(tangCanMonth4.split(" ")[1]) &&
                      !checkThangDungThan &&
                      checkDungThan
                        ? "background border__"
                        : "border__"
                    }
                    id={
                      dungThan.includes(tangCanMonth4.split(" ")[1]) &&
                      !checkThangDungThan &&
                      checkDungThan &&
                      "dungThan"
                    }
                    style={{
                      verticalAlign: "middle",
                      padding: "0",
                      fontSize: "14px",
                    }}
                  >
                    <div>
                      <sup>{thapThanMonth2}</sup>
                      {tangCanMonth2}
                    </div>
                    <div style={{ justifyContent: "center", width: "47px" }}>
                      {tangCanMonth5}
                    </div>
                  </td>
                ) : (
                  ""
                )}

                {tangCanMonth2 && tangCanMonth3 ? (
                  <th
                    colspan="1"
                    className={
                      dungThan.includes(tangCanMonth4.split(" ")[1]) &&
                      !checkThangDungThan &&
                      checkDungThan
                        ? "background"
                        : ""
                    }
                    id={
                      dungThan.includes(tangCanMonth4.split(" ")[1]) &&
                      !checkThangDungThan &&
                      checkDungThan &&
                      "dungThan"
                    }
                    style={{
                      border: "none",
                      verticalAlign: "middle",
                      padding: "0",
                      fontSize: "14px",
                    }}
                  >
                    <div>
                      <sup>{thapThanMonth1}</sup>
                      {tangCanMonth}
                    </div>
                    <div style={{ justifyContent: "center", width: "47px" }}>
                      {tangCanMonth4}
                    </div>
                  </th>
                ) : tangCanMonth3 ? (
                  <th
                    id={
                      dungThan.includes(tangCanMonth4.split(" ")[1]) &&
                      !checkThangDungThan &&
                      checkDungThan &&
                      "dungThan"
                    }
                    colspan="2"
                    className={
                      dungThan.includes(tangCanMonth4.split(" ")[1]) &&
                      !checkThangDungThan &&
                      checkDungThan
                        ? "background border__"
                        : "border__"
                    }
                    style={{
                      verticalAlign: "middle",
                      padding: "0",
                      fontSize: "16px",
                    }}
                  >
                    <div>
                      <sup>{thapThanMonth1}</sup>
                      {tangCanMonth}
                    </div>
                    <div style={{ justifyContent: "center" }}>
                      {tangCanMonth4}
                    </div>
                  </th>
                ) : (
                  <th
                    id={
                      dungThan.includes(tangCanMonth4.split(" ")[1]) &&
                      !checkThangDungThan &&
                      checkDungThan &&
                      "dungThan"
                    }
                    colspan="3"
                    className={
                      dungThan.includes(tangCanMonth4.split(" ")[1]) &&
                      !checkThangDungThan &&
                      checkDungThan
                        ? "background border__"
                        : "border__"
                    }
                  >
                    <div>
                      <sup>{thapThanMonth1}</sup>
                      {tangCanMonth}
                    </div>
                    <div style={{ justifyContent: "center" }}>
                      {tangCanMonth4}
                    </div>
                  </th>
                )}

                {tangCanMonth3 && tangCanMonth2 ? (
                  <td
                    id={
                      dungThan.includes(tangCanMonth4.split(" ")[1]) &&
                      !checkThangDungThan &&
                      checkDungThan &&
                      "dungThan"
                    }
                    className={
                      dungThan.includes(tangCanMonth4.split(" ")[1]) &&
                      !checkThangDungThan &&
                      checkDungThan
                        ? "background border__"
                        : "border__"
                    }
                    style={{
                      verticalAlign: "middle",
                      padding: "0",
                      fontSize: "14px",
                    }}
                  >
                    <div>
                      <sup>{thapThanMonth3}</sup>
                      {tangCanMonth3}
                    </div>
                    <div style={{ justifyContent: "center", width: "47px" }}>
                      {tangCanMonth6}
                    </div>
                  </td>
                ) : tangCanMonth3 && tangCanMonth ? (
                  <td
                    id={
                      dungThan.includes(tangCanMonth4.split(" ")[1]) &&
                      !checkThangDungThan &&
                      checkDungThan &&
                      "dungThan"
                    }
                    className={
                      dungThan.includes(tangCanMonth4.split(" ")[1]) &&
                      !checkThangDungThan &&
                      checkDungThan
                        ? "background border__"
                        : "border__"
                    }
                    style={{
                      verticalAlign: "middle",
                      padding: "0",
                      fontSize: "16px",
                    }}
                  >
                    <div>
                      <sup>{thapThanMonth3}</sup>
                      {tangCanMonth3}
                    </div>
                    <div style={{ justifyContent: "center", width: "47px" }}>
                      {tangCanMonth6}
                    </div>
                  </td>
                ) : (
                  ""
                )}
                {/** tang can nam*/}

                {tangCanYear2 ? (
                  <td
                    id={
                      dungThan.includes(tangCanYear4.split(" ")[1]) &&
                      checkDungThan &&
                      "dungThan"
                    }
                    className={
                      dungThan.includes(tangCanYear4.split(" ")[1]) &&
                      checkDungThan
                        ? "background border__"
                        : "border__"
                    }
                    style={{
                      verticalAlign: "middle",
                      padding: "0",
                      fontSize: "14px",
                    }}
                  >
                    <div>
                      <sup>{thapThanYear2}</sup>
                      {tangCanYear2}
                    </div>
                    <div style={{ justifyContent: "center", width: "47px" }}>
                      {tangCanYear5}
                    </div>
                  </td>
                ) : (
                  ""
                )}

                {tangCanYear2 && tangCanYear3 ? (
                  <th
                    id={
                      dungThan.includes(tangCanYear4.split(" ")[1]) &&
                      checkDungThan &&
                      "dungThan"
                    }
                    colspan="1"
                    className={
                      dungThan.includes(tangCanYear4.split(" ")[1]) &&
                      checkDungThan
                        ? "background border__"
                        : "border__"
                    }
                    style={{
                      verticalAlign: "middle",
                      padding: "0",
                      fontSize: "14px",
                    }}
                  >
                    <div>
                      <sup>{thapThanYear1}</sup>
                      {tangCanYear}
                    </div>
                    <div style={{ justifyContent: "center", width: "47px" }}>
                      {tangCanYear4}
                    </div>
                  </th>
                ) : tangCanYear3 ? (
                  <th
                    id={
                      dungThan.includes(tangCanYear4.split(" ")[1]) &&
                      checkDungThan &&
                      "dungThan"
                    }
                    colspan="2"
                    className={
                      dungThan.includes(tangCanYear4.split(" ")[1]) &&
                      checkDungThan
                        ? "background border__"
                        : "border__"
                    }
                    style={{
                      verticalAlign: "middle",
                      padding: "0",
                      fontSize: "16px",
                    }}
                  >
                    <div>
                      <sup>{thapThanYear1}</sup>
                      {tangCanYear}
                    </div>
                    <div style={{ justifyContent: "center" }}>
                      {tangCanYear4}
                    </div>
                  </th>
                ) : (
                  <th
                    id={
                      dungThan.includes(tangCanYear4.split(" ")[1]) &&
                      checkDungThan &&
                      "dungThan"
                    }
                    colspan="3"
                    className={
                      dungThan.includes(tangCanYear4.split(" ")[1]) &&
                      checkDungThan
                        ? "background border__"
                        : "border__"
                    }
                  >
                    <div>
                      <sup>{thapThanYear1}</sup>
                      {tangCanYear}
                    </div>
                    <div style={{ justifyContent: "center" }}>
                      {tangCanYear4}
                    </div>
                  </th>
                )}

                {tangCanYear3 && tangCanYear2 ? (
                  <td
                    id={
                      dungThan.includes(tangCanYear4.split(" ")[1]) &&
                      checkDungThan &&
                      "dungThan"
                    }
                    className={
                      dungThan.includes(tangCanYear4.split(" ")[1]) &&
                      checkDungThan
                        ? "background border__"
                        : "border__"
                    }
                    style={{
                      verticalAlign: "middle",
                      padding: "0",
                      fontSize: "14px",
                    }}
                  >
                    <div>
                      <sup>{thapThanYear3}</sup>
                      {tangCanYear3}
                    </div>
                    <div style={{ justifyContent: "center", width: "47px" }}>
                      {tangCanYear6}
                    </div>
                  </td>
                ) : tangCanYear3 && tangCanYear ? (
                  <td
                    id={
                      dungThan.includes(tangCanYear4.split(" ")[1]) &&
                      checkDungThan &&
                      "dungThan"
                    }
                    className={
                      dungThan.includes(tangCanYear4.split(" ")[1]) &&
                      checkDungThan
                        ? "background border__"
                        : "border__"
                    }
                    style={{
                      verticalAlign: "middle",
                      padding: "0",
                      fontSize: "16px",
                    }}
                  >
                    <div>
                      <sup>{thapThanYear3}</sup>
                      {tangCanYear3}
                    </div>
                    <div style={{ justifyContent: "center" }}>
                      {tangCanYear6}
                    </div>
                  </td>
                ) : (
                  ""
                )}

                {/** tang can nam*/}

                <th
                  id="bg__tcc"
                  style={{ width: "58px", border: "3px solid #000000" }}
                  className="bg__ clr__white clr__print"
                >
                  Tàng Can
                </th>
              </tr>

              {/** nap am*/}
              {checkNapAm ? (
                <tr style={{ textAlign: "center" }}>
                  {checkHour ? (
                    ""
                  ) : (
                    <th id="nabox" colspan="3" className="fz__18 border__">
                      {napAmHour}
                    </th>
                  )}
                  <th
                    id="bg__nad"
                    style={{ background: "#B2CFEA" }}
                    colspan="3"
                    className="fz__18 border__"
                  >
                    {napAmDay}
                  </th>
                  <th colspan="3" className="fz__18 border__">
                    {napAmMonth}
                  </th>
                  <th colspan="3" className="fz__18 border__">
                    {napAmYear}
                  </th>
                  <th
                    id="bg__na"
                    style={{ width: "58px", border: "3px solid #000000" }}
                    className="bg__ clr__white clr__print"
                  >
                    Nạp Âm
                  </th>
                </tr>
              ) : (
                ""
              )}

              {/** nap am*/}
            </tbody>
          </Table>
        )}
      </div>
      <div
        style={{
          display: "flex",
        }}
      >
        <div className="fz__18 hide-to-print" style={{ width: "500px" }}>
          Ghi chú: Ngày giờ sinh nhập theo dương lịch. Giờ Tý 23:00-00:59; Sửu
          01:00-02:59...;
          <br />
          <br />
          CA: Chính Ấn; TA: Thiên Ấn; CQ: Chính Quan; TS: Thất Sát; TK: Tỷ Kiên;
          KT: Kiếp Tài; CT: Chính Tài; TT: Thiên Tài; TQ: Thương Quan; TH: Thực
          Thần
        </div>
        <div style={{ width: "500px" }} className="fz__18 hide-to-print"></div>
      </div>
    </div>
  );
};

export default SoSanh;
