export const AtSuu = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Ất Sửu: người có vận đào hoa vượng, rất hấp dẫn người
        khác giới, tính cách ôn hoà dễ tính, giỏi giao tiếp, có trực giác tốt,
        có khả năng dự đoán xu hướng, kỹ năng quản lý tài chính tốt, dễ tha thứ
        và có kỹ năng chiến lược tốt. Tự tin vào năng lực bản thân, quyết tâm và
        kiên trì thay vì dựa vào may mắn. Với nữ giới, thường ít khi kết hôn 1
        lần. Nam giới thường dễ được vợ giúp đỡ trong sự nghiệp.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ất Sửu là bông hoa nhỏ bé vượt qua mùa đông khắc nghiệt, là những mầm
        chồi đầu tiên nhú lên, sẵn lòng mạo hiểm đối mặt khó khăn, chống lại
        thời tiết rét buốt. Ất Sửu là hi vọng trong một khung cảnh hoang mạc,
        ngay cả trong điều kiện khó khăn, Ất Sửu cũng sẽ là người sống sót và họ
        luôn sẵn sàng vươn cao. Điều này cũng biểu thị tính nhẫn nhịn của người
        Ất Sửu.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Ất Sửu thường duyên dáng, nhiều năng lượng và giỏi
        thuyết phục. Họ thích ánh đèn lấp lánh và có thể được xem là linh hồn
        của đám đông. Họ có xu hướng đạt độ thấu hiểu sâu sắc và dễ có cái nhìn
        toàn cảnh. Họ có thái độ sống thẳng thắn và sẵn sàng mắc sai lầm, chịu
        phạt nhiều lần.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tiền bạc và danh vọng rất quan trọng đối với họ và động lực của họ có
        thể giúp họ cam kết theo đuổi điều gì đó dài lâu và họ sẵn sàng trả gía.
        Họ sẽ được tưởng thưởng bởi may mắn và lối sống sung túc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Một trong những thế mạnh của họ là có thể nhìn nhận mọi thứ theo cách
        riêng của mình. Họ rất trật tự, và thích làm việc theo dự án do đó rất
        dễ thu hút những người khác. Kỹ năng lãnh đạo mạnh mẽ giúp họ thăng tiến
        trong sự nghiệp và đạt được thành tích tuyệt vời. Dù cho họ tham gia ào
        bất kỳ việc gì như nắm vai trò quan trọng trong một dự án cộng đồng hay
        dẫn đầu hoạt động trong một tổ chức, họ luôn sẵn lòng nắm vai trò lãnh
        đạo và chiếm trọn sự chú ý của tất cả mọi người.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Để đảm bảo người Ất Sửu sẽ luôn đạt được thành tích tuyệt vời, họ cần
        phải làm những gì gợi cảm hứng. Họ sẽ cố gắng hết sức trong mọi tình
        huống mà họ có thể là chính mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có sự kiềm chế bản thân và quyết tâm giúp chiến thắng mọi trở ngại,
        dù cho khó khăn đến đâu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi có tuổi hơn, người Ất Sửu thường sử dụng sự khôn ngoan và tinh ranh
        của mình cho những mục đích tốt. Họ lạc quan, trong tim luôn tươi trẻ,
        và sẵn sàng đón nhận mọi thử thách để đưa sự nghiệp lên một tầm cao.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Ất Sửu đôi khi có thể không phải là điểm tựa. Đôi khi họ không
        theo dõi công việc và hoàn thành các lời hứa đúng, đó là lý do tại sao
        họ rất cần một nguồn cảm hứng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ cũng có thể có vấn đề về đặt niềm tin. Họ thường không muốn chi xài
        tiền mà thay vào đó là giữ tiền. Do đó, họ có thể không phải là người
        đối tác hợp tác kinh doanh lý tưởng nhất.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đôi khi họ cũng tỏ ra áp đặt và đòi hỏi, một phần là vì họ có nguồn năng
        lượng cao. Điều quan trọng là không nên quá đòi hỏi về việc nắm hết
        quyền hành kiểm soát mọi việc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nói chung, họ không phải luôn ở tình trạng sức khoẻ tốt nhất. Họ thường
        tỏ ra gặp nhiều vấn đề về sức khoẻ trong suốt cuộc đời. May mắn thay, vì
        kỹ năng quản lý tiền bạc hiệu quả, họ thường có thể chăm lo cho sức khoẻ
        của mình được.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người nữ Ất Sửu cần phải nhận thức rằng họ dễ có nguy cơ sẩy thai, con
        chết trẻ. Ngoài ra nếu quá nhiều hành Hoả trong lá số thì người này cũng
        sẽ gặp nhiều vấn đề sức khoẻ nói chung.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người này có thể bị trầm cảm. Điều này đặc biệt đúng nếu có quá
        nhiều hành Thuỷ. Để chống lại điều này, họ phải học cách tập trung sự
        chú ý đến những mặt tốt và may mắn của mình trong cuộc sống.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Ất Sửu có thể đôi khi thích đi đường tắt hay những lựa chọn dễ
        dàng. Họ muốn được thành công ngay lập tức thì cần phải giữ kỷ luật bản
        thân cao hơn. Họ phải tìm ra mục tiêu hay chân lý nào gợi cảm hứng cho
        họ. Một khi họ tìm ra được thì họ sẽ thấy mọi việc rất dễ dàng trong
        việc tập trung vào đó. Lúc này thì việc giữ kỹ luật bản thân không còn
        là điều quá khó.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Để đạt được thành công hơn, họ cần phải nâng cao trực giác. Sức mạnh, ý
        chí, quyết tâm thì không đủ để đạt thành công. Họ cần phải cân bằng với
        cả trực giác bẩm sinh trời cho của mình.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Những Nét Tính Cách Tổng Quát:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Năng động, giỏi thuyết phục, có óc tổ chức, khôn ngoan, động lực, thích
        được chú ý, giỏi về tiền bạc.
      </p>

      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ở bên dưới vẻ ngoài thân thiện và đáng tin cậy thì người Ất Sửu là người
        rất tham vọng. Họ có kỹ năng tổ chức tốt và giỏi về kỹ thuật cùng với
        quyết tâm để đạt địa vị và tầm ảnh hưởng cao.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù họ rất may mắn về tiền bạc, họ không đặt trọng tâm vào việc kiếm
        tiền khi lựa chọn nghề nghiệp. Thay vào đó, họ chú trọng đến khía cạnh
        con người, nhân văn hơn. Đây là lý do họ làm việc với con người rất tốt
        và là những người giải quyết khó khăn xuất sắc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thông thường, họ dễ bị cuốn hút vào vị trí công việc trong những tổ chức
        kinh doanh lớn, công ty quốc tế và truyền thông. Họ có tố chức thần kinh
        tốt cho những cuộc tranh luận và có thể nên tham gia chính trị.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ phải cẩn trọng, để tránh rủi ro và rơi vào những kế hoạch làm giàu
        nhanh chóng. Họ nên dùng kỹ năng phân tích kinh doanh khôn ngoan của
        mình. Miễn là họ luôn cố gắng thì chắc chắn họ sẽ giỏi trong mọi lĩnh
        vực mà họ lựa chọn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ giỏi trong âm nhạc, nghệ thuật, viết lách và hội hoạ. Nếu họ không
        quan tâm về nghệ thuật, họ có thể cân nhắc sự nghiệp trong ngành nghề
        thể thao.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đừng quên rằng, họ có tính cách duyên dáng. Tính cách này của họ khiến
        cho họ phù hợp với sự nghiệp trong ngành nghề giao tiếp công chúng, tiếp
        thị và kinh doanh. Người có kỹ năng nói chuyện tốt có thể làm trong lĩnh
        vực pháp lý hoặc giao tiếp.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Một vài người có thể cảm thấy sử dụng khả năng lãnh đạo trực giác và kỹ
        năng quản trị tốt trong những lựa chọn nghề nghiệp như kinh doanh và
        chính trị.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu bản tính tự nhiên cảm thông của họ lấn át thì họ thích các công việc
        liên quan đến tư vấn. Ngoài ra, họ có thể thích đấu tranh vì chính nghĩa
        như các hội đoàn, tổ chức từ thiện. Họ thích trở thành chiến binh đấu
        tranh cho hoà bình.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ cũng thích tham gia vào lĩnh vực sức khoẻ. Người Ất Sửu có mong muốn
        giúp đỡ và chăm sóc người khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đối với họ thì việc lựa chọn sự nghiệp là rất quan trọng và họ cần phải
        lưu ý hai điều. Thứ nhất là họ là không bao giờ chọn một vị trí công
        việc phải phục tùng người khác. Ngay cả khi là họ diễn viên, họ muốn một
        ngày nào đó sẽ trở thành đạo diễn. Do đó, sự lựa chọn nghề nghiệp lý
        tưởng của họ là độc lập và bản thân tự cáng đáng. Thứ hai, họ phải đảm
        bảo đó phải là sự nghiệp gợi cảm hứng cho họ. Nghề nghiệp đó phải nhiều
        vai trò, công việc đa dạng trong nhiều môi trường khác nhau.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tính Cách Sự Nghiệp:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trí thức, thích nghi, thấu hiểu, khả năng lãnh đạo, không nhất quán, quá
        tự tin, trì hoãn, thích nắm quyên.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Mối Quan Hệ - Gia Đình
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đối với người Ất Sửu không gì quan trọng hơn việc có một người bạn đời
        gắn bó dài lâu, trọn đời. Người bạn đời của họ phải tham vọng, và luôn
        thông minh để theo kịp và thoả mãn họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có thể trở nên nghiêm túc cam kết vào một mối quan hệ tình cảm quá
        sớm, dẫn đến kết thúc mối quan hệ. Những trường hợp này sẽ khiến họ hao
        tổn rất nhiều về tiền bạc và tình cảm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc cho bản tính yêu thương và cho đi, họ đôi khi cũng trở nên bất ổn và
        thiếu quyết đoán. Trong những thời gian này, họ có những nỗi lo lắng
        không cần thiết và trở nên thất vọng về tình cảm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Ất Sửu không nên vội vàng tham gia vào mối quan hệ tình cảm nghiêm
        túc nào.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Xét về tình bạn, người Ất Sửu rất duyên dáng, cuốn hút và dễ thương. Khi
        họ làm việc với người khác để hướng đến một mục tiêu, họ sẽ có tính thu
        hút và thích giúp đỡ. Họ yêu thích tương tác với người khác và là người
        mà những người khác luôn tìm đến để được khuyến khích, hỗ trợ về mặt
        tinh thần. Mục tiêu là chinh phục và duy trì mối quan hệ hoà hợp.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bởi vì họ có tính thực dụng, họ thường được xem như là cố vấn cho lời
        khuyên rất tốt. Họ tôn trọng những người bạn nào có tri thức và có thể
        chia sẻ kiến thức với họ. Họ trân trọng tình bạn giúp kích thích tri
        thức.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Ất Sửu hiểu rằng cần có thời gian để thiết lập mối quan
        hệ cá nhân bền vững. Tuy nhiên, đôi khi họ cảm thấy mất hứng thú với một
        người bạn nào đó và dễ dàng ngừng lại, không giao tiếp nữa.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù họ có thể tỏ ra hỗ trợ, giúp đỡ, họ có xu hướng trở nên áp đặt.
        Để duy trì tình bạn, họ cần phải chống lại xu hướng này của bản thân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Xét về mặt gia đình, những người này rất tử tế và tốt bụng. Họ cần tình
        yêu và kích thích về cảm xúc, tuy nhiên, họ hay tỏ ra nghi ngờ và thiếu
        quyết đoán đối với những người gần gũi họ nhất.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Một khi họ quyết định gắn chặt cuộc đời mình với một ai, họ sẵn lòng hi
        sinh vì người đó. Người Ất Sửu cần phải nhớ không được để cho năng lượng
        mạnh mẽ của họ biến thành sự áp đặt lên người thân yêu.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Những Đặc Tính Của Người Ất Sửu Trong Tình Cảm:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Quan tâm, xã giao, cho đi, trung thành, chung thuỷ.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ất toạ Kỷ Thổ ẩn tàng Thiên Tài. Sự thông căn hỗ trợ trực tiếp này biểu
        thị cho bản tính cơ hội, khi mọi người xung quanh chưa kịp thấy giá trị
        thì Ất Sửu đã nhìn thấy cơ hội bị ẩn giấu. Họ rất giỏi trong việc nhìn
        ra điểm mạnh của người khác và biến điều này thành lợi thế lớn của họ.
        Nếu một ai đó chưa tìm ra được sở trường và giá trị của bản thân mình,
        hãy đến gặp người Ất Sửu, họ sẽ ngạc nhiên vì nhận thức của họ về mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Quý Thuỷ là Thiên Tài biểu thị trực giác sinh tồn. Họ nhanh chóng hành
        động, luôn có động lực sống mạnh mẽ, sẵn lòng theo đuổi các ước mơ trong
        đời. Họ học hỏi mọi lúc, mọi nơi từ mọi nguồn, không cần phải là môi
        trường lớp học.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Xét về tổng thể, Ất Sửu là trụ ngày sinh tồn mạnh mẽ. Bạn sẽ để ý rằng
        họ có khả năng nhận ra được cơ hội nhỏ nhất để tiến lên trong đời. Đôi
        khi người khác sẽ nghĩ rằng họ là người cơ hội hoặc thậm chí ích kỷ
        nhưng bạn cũng cần xét bối cảnh của Sửu là nhà tù khắc nghiệt giam hãm
        Ất Mộc nên cần phải hiểu rằng họ không có thời gian “chơi đẹp” và thậm
        chí là phải tỏ ra mạnh mẽ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tỷ và Sửu tạo thành Lục Hợp. Điều này sẽ thu hút thêm Thiên Ấn, tri thức
        không chính thống và thêm mạnh mẽ. Điều này cũng có thể gây ra sự suy
        nghĩ lệch lạc bên trong họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sửu và Mùi tạo thành xung tách rời Kỷ (Thiên Tài), Ất (Tỷ Kiên) và Đinh
        (Thực Thần) ra khỏi Mùi. Tài không bị diệt trong xung đột này tuy nhiên
        Tỷ Kiên sẽ bị mất. Các ý tưởng sáng tạo của họ sẽ bị kéo lùi, hãm lại
        bởi những yêu cầu học hỏi không cần thiết của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đối với cả nam và nữ, nếu thiếu đi hành Thê Tài, thì Chính Quan (chồng)
        và Chính Tài (vợ) biểu thị rằng mối quan hệ sẽ ngừng lại. Họ bận rộn với
        việc bắt 19 kịp mọi thứ mà họ đã bỏ sót. Sự hiện diện của Thất Sát (bạn
        trai) và Thiên Tài (bạn gái), biểu thị rằng họ muốn có mọi thứ trong đời
        mà không bị ràng buộc trách nhiệm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thành công không có gì khó cho Ất Sửu bởi vì họ toạ trên Tài, Quan và
        Ấn. Thiên Tài sinh cho Thất Sát và nuôi dưỡng Thiên Ấn cuối cùng sinh
        cho bản thân (Ất). Điều này tạo ra một luồng chảy năng lượng tương sinh
        rất mạnh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bính (Thương Quan) rất quan trọng đối với lá số này, họ cần có hạnh phúc
        trong đời. Họ có nhu càu chia sẻ niềm vui và nói với người khác về thành
        tích của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Ất Sửu cũng là một trong Lục Tú biểu thị người này duyên dáng,
        xinh đẹp và thông minh. Sửu được xem là Kim Khố. Người Ất Sửu không
        thông căn tại địa chi, và điều này biểu thị người này sẽ không thể kiểm
        soát được Thất Sát. Cũng biểu thị họ có xu hướng trở nên cứng đầu hoặc
        quá tự cao hay thích làm việc dựa trên cảm tính mà không chấp nhận lời
        khuyên từ người khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Ất Sửu có xu hướng trở nên khá cứng rắn. Nữ giới Ất Sửu có thể
        gánh vác trách nhiệm nặng nề hơn đồng nghiệp. Họ có xu hướng tỏ ra thông
        minh và tri thức và họ có cũng có thể dựa vào điều này để đạt thành công
        trong đời. Họ thực dụng và có kiến thức và có khả năng thực hiện các
        công việc chi tiết đòi hỏi kỹ năng hay độ chính xác. Họ sở hữu tầm nhìn
        sâu sắc và thường có nhiều tiềm năng hơn chúng ta nghĩ. Đó là do hiệu
        ứng của Thiên Ấn ẩn tàng làm suy yếu Thất Sát Tinh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Ất Sửu rất thích xã giao và họ có khả năng độc đáo hoà nhập vào
        tập thể. Do đó, họ sở hữu những kỹ năng ngoại giao tốt và giỏi trong
        việc tạo ra bầu không khí hoà hợp trong 1 tập thể. Họ tinh tế, nhạy cảm
        và có khả năng thuyết phục và gây ảnh hưởng đến người khác. Họ cũng có
        khả năng ăn nói lưu loát.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Ất Sửu có thể tỏ ra tự tin, đôi khi quá tự cao, nên họ cũng cần
        phải kiểm soát thái độ của mình. Họ giấu bản tính sôi sục và đam mê của
        mình sau một vẻ ngoài hời hợt và họ chỉ chia sẻ bí mật của mình với
        những bạn bè rất thân hoặc người mà họ tin cẩn. Họ có nhận thức trực
        giác rằng họ cần phải thực tế dù cho thỉnh thoảng có mơ mộng bay cao.
        Phía sau vẻ tự vin và độc lập mà họ tỏ ra bên ngoài chính là cảm giác
        bất an và họ dễ bị tổn thương và quá phụ thuộc vào quan điểm của người
        khác. Họ đôi khi cần phải tìm sự cân bằng giữa hai thái cực trong chính
        tính cách của mình.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu ngày sinh Ất Sửu gặp Tỵ và Dậu trong đại vận thì biểu thị đó là
        khoảng thời gian bất ổn của người này. Thất Sát có thể trở nên quá mạnh,
        làm lật gốc rễ của Ất Mộc và có thể khiến cho họ đối mặt nhiều thử thách
        trong cuộc sống đặc biệt liên quan đến sự nghiệp và tài chính. Cách cục
        này được gọi là Tiệt Căn hay là “cắt đứt gốc rễ”.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Cục Hình gián tiếp sẽ xảy ra nếu có thêm 1 Dậu xuất hiện trong lá số Bát
        Tự Điều này cũng có thể biểu thị người Ất Sửu sẽ trở thành một người
        sống sót qua nhiều trận chiến ác liệt khi còn trẻ và họ sẽ phải đối mặt
        nhiều gian nan trong đời.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sẽ tốt hơn nếu trụ ngày sinh Ất Sửu gặp nhiều Hoả và Thổ trong lá số và
        cả Thuỷ. Điều này sẽ biểu thị đây là lá số ổn và người này sẽ sống cuộc
        đời tràn đầy may mắn và cơ hội. Ngoài ra, nếu gặp trụ Mậu Dần trong lá
        số thì đây là dấu hiệu của thịnh vượng. Nếu có sự hiện diện của Bính hoả
        và Mậu thổ, thì người này sẽ theo đuổi chủ nghĩa hoàn mỹ trong đó luôn
        tự thúc đẩy tài năng của họ. Tuy nhiên nếu lá số có nhiều hành Thuỷ hay
        hoàn toàn không có hành Thuỷ, thì là dấu hiệu người này sẽ ít gặp may
        mắn. Họ có thể gặp tai nạn hay bị chấn thương hoặc hao tổn tài lộc -
        cũng tương tự nếu lá số có Hình hoặc Xung.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu Ất Sửu gặp Giáp mộc và Canh kim, biểu thị sẽ có Xung và Hình trong
        lá số. Ở đây biểu thị rằng người này sẽ không tìm được cơ hội trong đời
        và họ phải vất vả để đạt được tiềm năng cao nhất vì tài năng của họ
        không được trọng dụng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu người này sinh vào mùa Xuân, họ sẽ gặp may mắn về tài chính và có
        lối sống khá thoải mái. Nếu ngày sinh rơi vào tháng mùa Hè thì họ sẽ khá
        thông minh và giỏi phán đoán giúp xây dựng sự nghiệp tốt. Họ cũng là
        người có thành tích trong lĩnh vực tài chính, công nghiệp hay thương
        mại.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh vào mùa Thu thường có sức khoẻ tốt và sống thọ. Người sinh
        vào mùa Đông sẽ gặp khó khăn trong các quyết định đầu tư tài chính và sẽ
        gặp nhiều khó khăn hơn những người sinh vào các mùa khác.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh vào tháng Tuất sẽ phải đối mặt nhiều vất vả và đa phần đều có
        liên quan đến gia đạo. Nếu gặp nhiều hành Kim thì người này sẽ phải vất
        vả mới đạt được mục tiêu và rất khó khăn mới thành công.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu người Ất Sửu sinh vào ban ngày thì họ có thể có nhiều cơ hội, họ
        cũng nên bỏ đi sự sợ hãi và tính toán rủi ro để theo đuổi giấc mơ của
        mình. Người sinh vào ban đêm thì không may mắn. Họ sẽ tìm thấy cơ hội dễ
        dàng và cần phải đấu tranh để vươn lên hàng đầu.
      </p>
    </div>
  );
};

export const AtHoi = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Ất Hợi bảo thủ, ít nói, ăn nói nhỏ nhẹ, lý trí, đầu óc
        nhanh nhạy, trưởng thành, dễ nóng, thích cuộc sống đơn giản. Thích tự
        do, không thích làm việc quá mức giới hạn hay khả năng, thường bỏ lỡ cơ
        hội tốt, tuân thủ luật lệ, không thích bị người khác can thiệp hay ràng
        buộc. Dễ có hôn nhân xấu, rắc rối, đặc biệt phải cẩn trọng về vấn đề
        tình cảm, nhất là với đối với nữ giới sinh ngày Ất Hợi. Thường dễ mệt
        mỏi và sức khoẻ xấu.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Hoa mọc trong nước như hoa sen, súng là đại diện cho Ất Hợi. Những cây
        này là hoa xinh đẹp và thu hút sự chú ý quan tâm nhưng gốc rễ của nó bị
        che giấu bên dưới bề mặt. Cũng như gốc rễ của những cây này, người Ất
        Hợi không biết được những tài năng hay tiềm năng nào của họ bị ẩn giấu
        bên dưới. Ất Hợi cũng như những hoa trôi dạt trên bề mặt sóng nước, vốn
        dĩ nhiều rủi ro nhưng vẫn có thể vượt qua mọi bất an. Họ có năng lực
        không bị giới hạn.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Ất Hợi dịu dàng, khiêm tốn, chân thành và chân thật. Họ được biết
        đến vì sự thông minh, nhạy cảm và tinh thần khởi nghiệp. Vì họ thông
        minh và có khả năng sở hữu năng lực lớn, hầu như họ không biết những nét
        tính cách bị ẩn giấu đó. Thông qua nền tảng học vấn bài bản, họ có thể
        tận dụng những nét tích cực của mình và sống một cuộc đời vĩ đại.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ là những thiên tài sáng tạo. Họ có thể tích luỹ được tài lộc không
        ngừng nghỉ. Họ có khả năng phát hiện ra cơ hội và nắm bắt nó nhanh nhất.
        Người Ất Hợi có nhiều may mắn có thể giúp cho họ nhận được nhiều may mắn
        ẩn giấu bên dưới. Họ rất trách nhiệm với túi tiền của họ và nhận thức
        rất rõ nhu cầu và mong muốn không bị nợ nần.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những trải nghiệm mới kích thích người Ất Hợi. Họ yêu thích khám phá và
        điều này giúp họ vượt lên trên thời đại. Nhu cầu mạo hiểm của họ được
        thoả mãn thông qua việc đi lại. Họ hào hứng để đi bất kỳ đâu và sẽ sẵn
        sàng lên kế hoạch đi lại.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Điều họ nghĩ đến về bản thân mình rất quan trọng bởi vì khao khát muốn
        gây ấn tượng với người khác. Họ nhìn chung rất khoẻ mạnh và có xu hướng
        lôi kéo đến mọi điều tốt nhất. Họ có sự duyên dáng bẩm sinh và do đó có
        thể hấp dẫn người khác. Sự đồng cảm của họ cho phép họ lôi cuốn mọi
        người đến với họ và giúp cho họ có thể làm công việc nhà điều phối tốt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Không dễ để cho những người này có thể khám phá ra tài năng thật của họ.
        Điều này lý giải vì sao, hầu như họ dành phần lớn cuộc đời để có thể tìm
        ra bản thân mình. Một phần trong họ nắm giữ toàn bộ tài năng bao gồm cả
        phong cách làm việc khoa học và logic.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thời tuổi thơ của họ có thể nhiều sóng gió, khi trưởng thành thì đỡ hơn.
        Mặc dù họ có thể không nhận được thừa kế từ gia đình, họ sẽ có tài lộc
        tốt cho đến khi đạt độ tuổi trung niên.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Một thử thách khác đối với người Ất Hợi là sự thiếu kiên nhẫn. Họ nên
        tạo dựng thói quen kiềm chế sẽ giúp họ khắc phục thái độ hấp tấp. Họ có
        thể tập trung khi họ tìm được một dự án hay chính nghĩa mà họ cảm thấy
        đáng. Sự thông minh và đa tài của họ đóng góp phần lớn cho sự thành công
        to lớn của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Dù cho đầu óc của họ sắc bén, họ vẫn cần phải rèn luyện thông qua việc
        học hành chính thống hoặc phi chính thống. Một đầu óc qua rèn luyện có
        thể giúp cho họ có thể khai thác tài năng tối đa. Nó cũng giúp tập trung
        sự chú ý của họ vào mục tiêu tích cực hơn là cảm thấy nóng giận và thất
        vọng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Của Người Ất Hợi:</b> chân thật, sắc bén, giáo dục,
        khám phá, sáng tạo, tâm linh, nhạy cảm.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Ất Hợi sẽ làm được nhiều việc rất xuất sắc Họ có những kỹ năng học
        tập hoàn hảo giúp cho họ khả năng có thể thích nghi vào mọi công việc dễ
        dàng. Khả năng của họ chuyên về thực dụng bởi vì người Ất Hợi thường có
        nhiều trải nghiệm với nhiều loại nghề nghiệp khác nhau. Điều này giúp
        thoả mãn tính thích mạo hiểm của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Để tránh sự buồn chán và bất ổn, người Ất Hợi sẽ làm tốt nhất trong
        những lĩnh vực nghề nghiệp đòi hỏi phải đi lại. Họ cũng giỏi trong những
        nghề đòi hỏi công việc đa dạng. Họ khá giỏi trong việc nắm giữ vị trí
        đòi hỏi sự nghiên cứu tận tâm và nỗ lực độc lập.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bất kể họ chọn nghề nghiệp gì, họ sẽ luôn có ham muốn mạnh mẽ biểu lộ ý
        tưởng của mình thông qua trí tưởng tượng sáng tạo mạnh mẽ. Họ cảm thấy
        khó khăn trong việc thăng chức nhưng không nên cảm thấy tuyệt vọng. Theo
        thời gian họ sẽ tìm được công việc phù hợp nhất với kỹ năng của mình.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Công Việc:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Ất Hợi thường đặc biệt thành công trong kinh doanh và có thể leo
        lên vị trí quản lý. Kỹ năng quản trị và tổ chức của họ cho họ lợi thế
        lớn trong thế giới thương mại. Ngay cả với thành công cao, điều này cũng
        sẽ không thoả mãn nhu cầu tự do và độc lập của họ. Trong những tình
        huống như vậy, họ có thể thích tự khởi nghiệp kinh doanh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bởi vì đặc tính thích nhân đạo, họ thường sở hữu khả năng thấu hiểu
        người khác. Người Ất Hợi nên tìm sự nghiệp trong ngành tư vấn, giáo dục
        hay hoạt động xã hội.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhiều người Ất Hợi có khao khát kiến thức mà chỉ có thể thoả mãn khi
        bước vào lĩnh vực triết học, tôn giáo, luật hay chính trị. Nếu họ muốn
        sử dụng kiến thức một cách sáng tạo, họ nên cân nhắc trở thành một nhà
        văn, nhà báo hay sử gia.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Một mặt khác của khả năng sáng tạo là nên tham gia vào lĩnh vực thiết
        kế, đặc biệt là thiết kế nhà.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những Đặc Tính Trong Sự Nghiệp - Công Việc: sáng tạo, đa tài, thông
        minh, kỹ năng tổ chức, không thoả. hiệp, thích tìm lỗi, ngoan cố, thiếu
        tầm nhìn.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Hôn Nhân - Gia Đình:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Ất Hợi đầy sự bất mãn khi nói đến vấn đề tình yêu. Bởi vì điều
        này, họ có thể buộc phải tìm ra những trải nghiệm mới mẻ và hứng thú
        hơn. Những trải nghiệm này có thể ở dưới dạng các mối quan hệ ngắn hạn
        hay tình cảm bí mật
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Một khi bức màn bất mãn được mở ra, họ thường mong đợi trao trái tim cho
        một người đồng cảm. Đột nhiên, một mối aun hệ gần gũi trở nên rất quan
        trọng đối với họ. Người Ất hợi cần phải thận trọng không được cho phép
        sự khao khát yêu đương biến thành sự luỵ tình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi nhu cầu trở nên quá mức, họ có xu hướng đánh đổi an toàn thay cho
        tình yêu và hạnh phúc. Họ có thể chọn sự lựa chọn an toàn thứ hai thay
        vì thật sự tìm người thấu hiểu tâm hồn mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bởi vì họ thể hiện cảm xúc thật sự thông qua sự quan tâm và rộng lượng,
        họ có thể tránh sự phụ thuộc bằng cách tỏ ra không quan tâm. Phương pháp
        này sẽ giúp cho họ hình thành một cái nhìn cân bằng về tình yêu và mối
        quan hệ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Có nhiều bài học dành cho người Ất Hợi trong việc xây dựng mối quan hệ.
        Tình yêu và tình bạn là rất quan trọng với họ. Một khi họ đã thiết lập
        mối quan hệ kết nối, họ thường ủng hộ, yêu thương và từ bi. Thông
        thường, họ sử dụng trực giác và giỏi trong việc đánh giá tính cách người
        khác. Họ sẽ có xu hướng gắn bó với những người có trí tưởng tượng cao có
        thể giúp họ cảm hứng và sự khuyến khích động viên.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Ất Hợi đa phần rất năng động và là một thành tố quan trọng trong
        bất kỳ tập thể nào. Họ có sự duyên dáng cuốn hút khiến cho bất kỳ ai
        cũng muốn kết bạn. Họ không ngần ngại chứng tỏ khả năng giao thiệp tốt
        và tỏ ra hợp tác, yêu thích vui vẻ và là người bạn rất sáng tạo.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đối lập với cá tính hướng ngoại, họ khao khát sự riêng tư và cảm thấy
        không thoải mái nếu một ai đó xâm phạm vào sự riêng tư của họ. Điều này
        có thể hiểu là sự rụt rè nội tâm. Hành động tốt nhất của họ là thái độ
        cứng nhắc, đặc biệt là 12đối với bạn bè thân. Họ nên học cách biểu lộ cá
        tính cua mình bằng cách nói ra họ thật sự nghĩ và cảm nhận ra sao.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người xung quanh sẽ bị thu hút bởi sự nhạy cảm và khéo léo của họ,
        tuy nhiên, họ cũng dễ bị lợi dụng. Để tránh điều này, họ nên phát triển
        sự thẳng thắn trong giai đoạn đầu tiên khi kết bạn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có tình cảm thân mật và gần gũi. Những người Ất Hợi thường trải
        nghiệm quan hệ tình cảm tốt đối với bạn đời. Nữ giới sinh ngày Ất Hợi
        thuờng đem may mắn đến cho chồng và họ cũng trải nghiệm hôn nhân hạnh
        phúc, cùng trải qua những năm tháng vàng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Ất Hợi có nhu cầu an toàn, cũng là lý do vì sao họ xem mái ấm là
        ngôi đền thiêng liêng. Chỉ ở tại nhà, họ mới có thể biểu lộ cảm xúc thật
        sự thông qua sự rộng lượng và đồng cảm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người phụ nữ Ất Hợi thường xuyên di chuyển vì lý do công việc sẽ cho
        người chồng cơ hội để ngoại tình khi đi xa. Mỉa mai thay, họ sẽ không
        phát hiện ra.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sự ghen tuông có thể khiến cho người Ất Hợi muốn bảo vệ sự riêng tư cua
        đời sống gia đình. Nếu bạn bè bình thường hoặc những người quen muốn
        vượt qua giới hạn để tìm hiểu về gia đình họ, họ sẽ tỏ ra khó chịu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những Đặc Tính Trong Tình Cảm: chung thuỷ, đáng tin cậy, thích xã giao,
        đánh giá tính cách tốt, quan tâm, rộng lượng.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trong lá số Bát Tự, trụ Ất Hợi toạ ở Tử trong vòng 12 Trường Sinh. Đây
        không phải là biểu thị cái chết mà biểu thị một người khá xa lạ, những
        người này tỏ ra rất cảm xúc hoặc khá kỳ quặc, trong nhiều trường hợp cực
        đoan, có thể ám chỉ bất thường về mặt thể chất. May mắn, người Ất Hợi
        cũng được hỗ trợ bởi cả Chính Ấn và Kiếp Tài. Người Ất Hợi có xu hướng
        có thể đạt thành công cao vì họ dám, tham vọng và mạnh mẽ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Ất Hợi đa tài và sáng tạo. Họ yêu thích các trải nghiệm mới và có
        thể học hỏi những điều mới mẻ không ngừng nghỉ. Họ đặc biệt thông minh,
        có thể 13biểu lộ bản thân thông qua việc theo đuổi tri thức nhưng họ
        không thích những cách học thông qua thực hành.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ hay tỏ ra trên bề mặt một dáng vẻ chuyên nghiệp và có khả năng thích
        nghi cao. Họ có khả năng khai thác mọi tình huống ở mức độ cao nhất.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Ất Hợi năng động và hướng ngoại cũng như yêu thích gặp gỡ người
        mới và họ vốn có nhiều mối quan tâm khiến cho họ trơ thành người bạn khá
        thú vị. Họ có bản tính không ngơi nghỉ và thường thích đi du lịch. Người
        Ất Hợi thường sáng tạo, họ độc lập và thích đi theo trực giác. Họ có thể
        cảm thấy nghi ngờ bản thân đôi lúc và thường tìm kiếm những ý kiến của
        người khác, tuy nhiên họ cần phải học cách dựa vào chính mình nếu muốn
        đạt đến tiềm năng cao nhất của mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Có dấu hiệu của sự cô đơn trong trụ ngày này và biểu thị người này có
        thể phải đánh đổi điều họ muốn đạt được với việc theo đuổi tài lộc. Tuy
        nhiên, người Ất Hợi thường rất may mắn trong hôn nhân. Có dấu hiệu tốt
        rằng cả hai giới tính sẽ có thể hợp với người kia và dễ tìm được hạnh
        phúc và ổn định. Nữ giới Ất Hợi thường xinh đẹp và sẽ không thiếu người
        theo đuổi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Lá số Bát Tự của người này chứng tỏ họ khoẻ mạnh nếu Ất Hợi gặp nhiều
        hành Mộc. Tương tự nếu có sự xuất hiện của Mão, Mùi, Dần và Hợi thì biểu
        thị danh tiếng, may mắn và hạnh phúc. Không may, điều ngược lại cũng
        đúng nếu trụ này gặp nhiều Kỷ Thổ và Thân, Dậu và Tý trong lá số. Có khả
        năng gây ảnh hưởng đến nghề nghiệp và tài lộc của người này.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu trụ ngày Ất Hợi gặp Mộc yếu và có Xung trong Hợi ở lá số, có thể có
        lợi cho người này. Điều này biểu thị họ sẽ đối mặt nhiều thách thức và
        có thể phải xa rời người thân yêu. Cũng là dấu hiệu của thua lỗ tài
        chính. Tuy nhiên, nếu có Kiếp Tài hiện diện trong Can của lá số, cơ hội
        có thể xuất hiện vào lúc đen tối nhất, hi vọng sẽ trỗi dậy và Ất Hợi nên
        kiên trì ngay cả khi hi vọng của họ tưởng chừng đã cạn kiệt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu người Ất Hợi sinh vào mùa Xuân hay Hè thì là dấu hiệu tốt họ sẽ giàu
        có. Họ cũng có tài năng về đầu tư và có thể thành côn trong sự nghiệp
        tài chính.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu họ sinh vào mùa Thu hay Đông thì nếu họ làm việc vất vả, sự nghiệp
        sẽ rộng mở và họ có thể thành công mà chỉ gặp ít trở ngại. Người sinh
        vào tháng Sửu sẽ có thể đối mặt nhiều thách thức trong đời và họ khó
        được bạn bè hỗ trợ. Họ sẽ gặp vất vả nếu muốn đạt được mục tiêu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh vào ban ngày thì có thể thành công trong sự nghiệp và có tài
        chính vững vàng. Người sinh vào ban đêm thì gặp may với tài lộc và có
        thể là doanh nhân thành công.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Ất Hợi là người sinh tồn bền bỉ nhất. Họ sống sót và phát triển
        tại bất kỳ môi trường nào. Họ luôn biết cách sống nhẹ nhàng, đi vòng và
        vượt qua trở ngại. Họ tỏ ra dịu dàng dù gặp bất kỳ tình huống nào sóng
        gió.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Toạ Hợi bao gồm Chính Ấn Nhâm. Là bậc thầy của nghệ thuật sinh tồn, họ
        rất giỏi trong việc thu nhặt kiến thức. Họ chấp nhận sự giúp đỡ từ bất
        kỳ đâu, dưới bất kỳ hình thức nào. Họ không kén chọn. Để khai thác tối
        đa phụ khí Kiếp Tài Giáp, họ cũng là những tay chơi rất công bằng trong
        đội nhóm, tất cả vì chiến thắng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tuy nhiên, đừng xem họ là con rối vô hại có thể điều khiển được. Nhu cầu
        sinh tồn của họ mạnh mẽ đến độ hô sẽ không hi sinh bạn. Là Dịch Mã duy
        nhất mà không có đất, Hợi sở hữu một sự đe doạ to lớn đối với các hành
        khác. Ất có thể di chuyển từ nơi này sang nơi khác mà không cần quay đầu
        nhìn lại. Nếu bạn từng giúp đỡ Ất Hợi thì sau đó người này sẽ nhẹ nhàng
        rời đi, thậm chí không thèm chào từ biệt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Hợi thu hút Dần và qua đó cuốn hút Kiếp Tài Giáp, Thương Quan Bính và
        Chính Tài Mậu. Xét về tình cảm thì cả nam và nữ thì không tốt thì đều có
        Kiếp Tài và Thương Quan xua đuổi các sao về tình cảm. Đàn ông sẽ tốt hơn
        vì có Chính Tài hiện diện ở Địa Chi. Nhìn chung thì sự dịch chuyển dễ
        dàng của họ gây ra nhiều cảm giác bất ổn cho vợ hay chồng. Chừng nào thu
        nhập còn tốt họ sẽ ở lại, tiền bạc sẽ níu giữ họ tại một điểm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Hợi xung đuổi Tỵ mang theo Thương Quan Bính, Chính Quan Canh và Chính
        Tài Mậu. Tài, Quan và Thực Thương có vẻ như rất xa xôi với họ vì họ sẽ
        bỏ đi. Cả người vợ và người chồng tức Chính Tài và Chính Quan đều bị đẩy
        đi xa.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ là những người rất dễ thương nhưng khá cô đơn và bất an. Do môi
        trường xung quanh mà họ lớn lên, họ phải dịch chuyển thường xuyên. Hầu
        hết là có cha làm nghề ngoại giao nên phải nhận nhiệm vụ ở các nơi sau
        vài năm. Họ hình thành khả năng buông xuôi mọi mối quan hệ và để cho
        sóng biển kéo họ đi đến bờ bến khác.
      </p>
    </div>
  );
};

export const AtDau = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Ất Dậu là người chú trọng vào hành động hơn là lời nói,
        thích che giấu cảm xúc và suy nghĩ bên trong, có kỹ năng tồn tại và
        thích nghi tốt, thường cứng đầu, có xu hướng ám ảnh với những gì người
        này yêu thích, đạt thành tích trong sự nghiệp. Dễ bị chấn thương nghiêm
        trọng hay tàn tật, cần phải tránh các hoạt động rủi ro và nguy hiểm cao.
        Hôn nhân xấu, dễ ly hôn và trải qua nhiều lần kết hôn. Nhìn chung cuộc
        sống không thiếu thốn vật chất.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tinh tế, duyên dáng và nguy hiểm....đều được gói gọi trong con người
        này. Khi tình thế là bắt buộc, bạn có thể dựa vào Ất Dậu để trả thù ngọt
        ngào. Đừng nhầm lẫn họ là người tâm thần phân liệt khi hành động theo
        nhiều cách khác nhau để đạt mục đích.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ất Mộc là hành của sự tinh tế. Người Ất luôn biết rằng họ mềm mại và yếu
        đuối và do đó họ sử dụng các kỹ năng giao tiếp nhóm rất giỏi. Kết nối
        với nhiều người là những gì họ giỏi, và giúp họ khác biệt cũng như dây
        leo có thể leo bám lên bất cứ thứ gì. Mục đích chính của họ dĩ nhiên là
        chỉ để sống sót. Họ rất chú trọng đến quyền lực và thành công, họ cũng
        biết rất rõ quanh họ ai là người thành công. Bạn có thể thấy cách họ đối
        xử khác nhau với người có địa vị khác nhau.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Hình tượng của Ất Dậu là chậu hoa có đựng trong bình gốm sứ nhiều hoa
        văn trang trí đẹp đẽ. Đây là biểu thị người Ất Dậu yêu thích ánh đèn sân
        khấu và muốn được mọi người xung quanh chú ý.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nói chung, người Ất Dậu thẳng thắn, nhân từ và lạc quan. Quan điểm của
        họ là mạnh mẽ và không thích ngồi không. Người Ất Dậu nhận ra và trân
        trọng sức mạnh của tri thức và thường được đánh giá là thông minh, thân
        thiện, khôn ngoan và duyên dáng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Ất Dậu thường thông minh bẩm sinh, giúp cho họ có nhận thức trí óc
        hơn người. Chính sự nhận thức dẫn họ đến sự hình thành những ý tưởng
        sáng tạo đi trước thời đại. Sự thông minh của họ thường đóng vai trò
        quyết định trong việc củng cố kỹ năng lãnh đạo và phụ nữ ngày sinh Ất
        Dậu có đời sống thịnh vượng và mạnh mẽ. Họ để dành tiền tiết kiệm và
        dùng nó để gia tăng tài lộc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhìn chung, người Ất Dậu chọn cách không lo lắng về cuộc đời. Họ tự tạo
        nên thành công của riêng mình và không dễ bị qua mặt. Họ được trời phú
        cho khả năng bảo hộ về tài chính. Họ luôn luôn tự tin về tiền bạc vì đối
        với họ tiền đi rồi sẽ lại đến. Vì có sự độc lập và yêu tự do, họ khá
        thẳng thắn và đốt cháy hết mình. Họ muốn mọi thứ diễn ra theo cách vĩ
        đại.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ thông thái và duyên dáng cũng như dễ trở thành nghệ sĩ bẩm sinh. Ai
        yêu thích họ sẽ cũng thích những nét tính cách trên. Trong tình bạn thì
        họ luôn đặt mục tiêu cuối cùng là bình an và hạnh phúc. Họ có thể thu
        hút nhiều bạn bè do sự duyên dáng, hào phóng và trung thành.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Vì là người tồn tại trong thế giới khốc liệt, họ thường bị những người
        xung quanh đánh giá là không tử tế và cứng nhắc. Họ có thể bộc lộ vẻ
        ngoài dữ dội hay thậm chí là tàn độc. Họ thường không ngần ngại đứng lên
        bảo vệ nguyên tắc của mình. Họ cần tỏ ra thận trọng trong các hành động
        để tránh cho sự cứng đầu và không linh hoạt cản trở tài năng và thành
        công của mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Ất Dậu sinh ra vốn dĩ có nhiều vấn đề sức khoẻ nhỏ và dễ có hệ
        miễn dịch và tiêu hoá kém. Họ thường có nhiều vấn đề lo lắng về cân
        nặng, đặc biệt vùng eo.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi về già, người Ất Dậu có xu hướng đi theo con đường tâm linh, chọn
        học hỏi tôn giáo hoặc tâm linh. Với sự hiện diện của Tỵ thì họ có thể
        trở thành bậc thầy về tâm linh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngoài nỗ lực thì họ cũng cần phải phát triển kỷ luật bản thân để thể
        hiện ra hết khả năng của họ khi còn trẻ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những Đặc Tính Của Ngày Sinh Ất Dậu: thẳng thắn, bộc trực, thông minh,
        lạc quan, may mắn, lãnh đạo, thực dụng, tâm linh.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Không bao giờ thiếu cơ hội nghề nghiệp cho người Ất Dậu vì họ khá thông
        minh, trực giác và có trí tưởng tượng sáng tạo cao. Người Ất Dậu sở hữu
        khả năng nhìn xa rất quý cho việc phát triển sự nghiệp. Họ có trực giác
        vững chắc giúp họ làm việc tốt hơn theo dự án. Một khi họ đã chắc chắn,
        họ sẽ nhảy vào và biến mọi thứ thành hiện thực.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thái độ tích cực và cá tính xởi lởi giúp họ dễ thành công trong đối nhân
        xử thế. Những lĩnh vực trên sẽ giúp họ có nhiều cơ hội để được thăng
        tiến đến những vị trí nằm quyền hành trách nhiệm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngoài ra, khả năng tư duy chiến lược và quản trị sẽ là tài sản rất tốt
        xét trong mọi khía cạnh sự nghiệp của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc cho bản tính siêng năng và tham vọng bên trong, họ cần phải được tạo
        động lực để có thể thể hiện ở mức tốt nhất. Sự đa tài giúp họ kỹ năng để
        thích nghi vào các tình huống nhanh chóng và dễ dàng. Họ cần phải tìm
        một công việc mà không đòi hỏi họ phải gắn với những quy trình lặp đi
        lặp lại chán ngắt. Họ cần một nghề nghiệp có thể cho họ tầm nhìn phát
        triển và sự tự do đi lại.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Là một người nhạy cảm, môi trường làm việc thân thiện hoàn toàn quan
        trọng đối với họ. Nếu họ gặp khó khăn trong việc giao tiếp với cấp trên
        hay đồng nghiệp, họ sẽ có xu hướng bỏ việc và tìm một công việc khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những công việc có thể tận dụng. trí thông minh sắc bén của họ là tốt
        nhất, ví như tài chính, giáo dục, nghiên cứu khoa học, viết lách, tiếp
        thị, học thuật và chính trị giúp thoả mãn ham muốn cải cách xã hội của
        họ. Được thừa hưởng tài năng bẩm sinh của việc sử dụng ngôn từ trong nói
        và viết, người Ất Dậu cũng là những ứng viên xuất sắc cho sự nghiệp
        giảng dạy hay thuyết trình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhu cầu thoả mãn khát khao thể hiện của họ có thể được đáp ứng bởi nghề
        nghiệp trong ngành truyền thông và giải trí. Họ cũng có thể tham gia vào
        nghệ thuật, âm nhạc hay triết học và huyền học. Họ có cặp mắt nhìn màu
        sắc và hình thể tốt nên hợp cho sự nghiệp trong nghệ thuật và thiết kế.
        Tương tự, cảm nhận về giai điệu và nhạy cảm giúp họ dễ hướng đến nhảy
        múa.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bản tính thích giao tiếp và cá tính xởi lởi giúp họ phù hợp cho ngành
        giao tiếp công chúng. Họ cũng sẽ thành công trong thế giới thương mại vì
        họ có thể dùng sự duyên dáng thuyết phục trong lĩnh vực bán hàng, tiếp
        thị hay đàm phán.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Ất Dậu là người nhạy cảm yêu thích sự hài hoà trong môi trường.
        Điều này có thể khiến họ dễ trở thành người giảng hoà.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Trong Công Việc - Sự Nghiệp:</b> sáng tạo, quyết tâm,
        khéo léo, truyền cảm hứng, thiếu tự tin, đa nghi, thiếu thực tế, thiếu
        tập trung, khó khăn.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Hôn Nhân - Quan Hệ - Gia Đình:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Ất Dậu có một nhu cầu bỏng cháy thích sự đa dạng do đó rất khó cho
        họ chung thuỷ với một người. Điều này không có nghĩa là họ không bao giờ
        cam kết hứa hẹn, nhưng chỉ có nghĩa là họ cần phải lắng đọng và tìm ra
        được người đúng đắn phù hợp nhất.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Lý tưởng là họ cần một người bạn đời không chỉ gíup cho tăng cường tri
        thức mà cũng chân thành và thẳng thắn. Bởi vì họ ngẫu hứng và lý tưởng,
        bất kỳ mối quan hệ nào cũng cần một sự hứng khởi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Có những lúc họ tỏ ra bất cần hay hời hợt là lúc họ che đậy sự sợ hãi bị
        bỏ rơi. Họ cần phải cẩn trọng. Nỗi lo sợ này có thể khiến họ giữ mối
        quan hệ với người bạn đời không phù hợp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ cần phải thận trọng không được tỏ ra quá hời hợt hay độc lập. Người
        bạn đời có thể cho rằng họ không cần tình cảm. Họ thường đấu tranh giữa
        tự tin hoàn toàn và nghi ngờ bản thân. Do đó họ rất cần yêu người nào có
        thể thấu hiểu họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tất cả mọi tình bạn đều có gía trị với người Ất Dậu. Họ được xem như
        thông minh, thân thiện, nhanh trí và duyên dáng. Được xem như là những
        nghệ sĩ bẩm sinh, họ có thể dùng trí tuệ và sự duyên dáng để làm cho
        người khác cười. Điều này đặc biệt đúng khi họ ở cùng với những người
        mình yêu. Bình an và hoà hợp là mục tiêu của họ trong mọi tình bạn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bởi vì sự thông minh sắc sảo, họ thu hút nhiều bạn bè tìm đến họ xin lời
        khuyên. Họ luôn luôn chân thành và thẳng thắn. Họ có nhu cầu tỏ ra thành
        thật trong đối nhân xử thế.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Ất Dậu rất ủng hộ gia đình và thích cho họ những lời khuyên thực
        tế. Họ yêu thích việc làm cho người khác cười bằng cách sử dụng sự nhanh
        trí bẩm sinh của mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Hôn nhân của người phụ nữ sinh ngày Ất Dậu rất viên mãn. Chồng của người
        này sẽ dành nhiều thời gian bên gia đình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù họ luôn chú trọng đến sự chân thành trong các mối quan hệ, đôi
        khi họ bị đánh gía là quá thẳng thắn. Điều này có thể được sửa chữa bằng
        cách kiểm soát ham muốn độc tài và áp đặt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Trong Tình Yêu - Quan Hệ:</b> quan tâm, dịu dàng, cho
        đi, hoà họp, đồng thuận.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thành công của người Ất Dậu chủ yếu dựa vào mức độ cảm hứng. Nếu họ
        không có cảm hứng thì họ sẽ cảm thấy thiếu mục tiêu trong đời và vốn dĩ
        là người mộng mơ bẩm sinh, họ dễ trôi dạt. Họ yêu thích các ý tưởng mới
        và khám phá mới đặc biệt liên quan đến công nghệ và có thể giúp ích cho
        họ trong việc sử dụng sự sáng tạo để đạt được một thứ gì đó đặc biệt và
        độc đáo. Người Ất Dậu có thể khá lạc quan, quyết tâm và thậm chí truyền
        cảm hứng nhưng họ cần phải học cách kiên trì khi đối mặt trở ngại và
        thách thức. Họ có thể trở nên giỏi trong việc xử lý thách thức khó khăn
        nếu họ sinh vào mùa Xuân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Ất Dậu sống tình cảm và họ có thể phải cố gắng kiểm soát các thái
        cực trong tâm trạng của mình. Họ có thói hay lo và che giấu đằng sau lớp
        áo giáp tâm lý. Có thể bất lợi cho người Ất Dậu vì toạ Thất Sát biểu thị
        rằng họ có xu hướng tự tàn phá mình hay khi nỗ lực để bảo vệ tránh bị
        tổn thương, họ sẽ trở nên ti tiện, tiểu nhân và thiếu kiên nhẫn. Điều
        này đặc biệt đúng nếu người này sinh vào tháng Thổ vì nó cũng sẽ biểu
        thị sức khoẻ thể chất yếu. Nếu người Ất Dậu có thể học hỏi cách để tin
        tưởng tiếng nói bên trong mình và mở lòng với người khác, họ sẽ tìm được
        cảm giác bình an lớn hơn. Họ cũng sẽ có lợi nếu tìm ra được cách để phục
        vụ và giúp đỡ người khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Phụ nữ Ất Dậu thường rất đẹp và lãng mạn, bạn có thể thấy họ rất dễ động
        lòng và dễ cưới rất sớm. Họ được khuyên không nên ra những quyết định
        vội vàng mà sau này phải hối hận cả đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ất Dậu toạ trên Thất Sát có thể rất nguy hiểm cho Ất. Tuy vậy, sự kết
        hợp này có thể thật sự giúp cho trụ ngày sinh bởi vì Ất Dậu có khả năng
        tận dụng tốt năng lượng cua Thất Sát. Điều này cũng sẽ tốt nếu trong trụ
        có thêm Bính Hoả và Thìn Thổ. Khi có đầy đủ như trên thì trụ ngày Ất Dậu
        sẽ vươn cao. Cách cục tốt đẹp này sẽ cho phép người Ất Dậu mạnh mẽ, kiên
        trì và quyết tâm đủ để theo đuổi mục tiêu. Nó cũng sẽ đem đến cho người
        này ham muốn quyền lực có thể khuyến khích họ tiến lên các vị trí quản
        lý.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi trụ ngày xung với sao Tỷ Kiên trong lá số, đó là dấu hiệu ít may
        mắn. Điều này có thể là chỉ báo người này có thể trở nên ích kỷ và không
        nhân từ, chỉ làm mọi việc dựa trên lợi ích của riêng mình. Nếu trụ này
        gặp Thìn trong lá số thì tuy nhiên ảnh hưởng tiêu cực sẽ rõ ràng và
        người này có khả năng chính nghĩa. Người này sẽ được những người xung
        quanh yêu thương vì lòng nhân từ và có bản tính thích giúp đỡ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Vì trụ ngày này không có căn, cũng như hình ảnh đại diện của họ, người
        Ất Dậu cũng có thể khá dao động và thiếu động lực. Họ có thể vất vả
        trong việc giữ vững lập trường và dao động theo ý kiến số đông. Điều này
        càng củng cố thêm nếu trụ ngày này có thêm Dậu trong lá số vì nó tạo ra
        Tự Hình, trong trường hợp này người này sẽ có xu hướng thiếu quyết đoán,
        muốn tránh ra quyết định khi có thể và trở nên rối trí khi có quá nhiều
        sự lựa chọn được đưa ra. Họ sẽ phải làm việc vất vả để học hỏi và tìm
        chỗ đứng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sẽ tốt nếu như trụ này gặp Quan Tinh hay Tài Tinh ở Thiên Can, vì tạo ra
        quan hệ tương sinh trong trụ ngày. Điều này biểu thị một khi họ đặt mục
        tiêu thì họ sẽ không dừng lại mà để nó trôi đi. Điều này cũng cho họ cơ
        hội mạnh mẽ để thành công trong sự nghiệp miễn là họ không có Hình, Xung
        hay Phá ở Địa Chi. Nếu bạn có thể tránh được cách cục này thì bạn có thể
        rất giàu có và sẽ có xu hướng thành công về tài chính.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Lá số này sẽ biểu thị ít my mắn nếu trong lá số có sự xuất hiện của Mậu
        Thổ tiêu cực hay Hợi trong đại vận. Nếu xuất hiện cách cục này thì Mậu
        Thổ sẽ huỷ diệt Dậu trong chi ngày, trong khi Hợi sẽ hình thành Hình.
        Điều này cũng sẽ có xu hướng tiêu diệt những tính chất tốt của trụ ngày
        và làm hỏng cơ hội thành công của người này. Họ có xu hướng đối mặt
        nhiều thử thách cả bên trong và bên ngoài đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tốt hơn cho người Ất Dậu nếu gặp Thìn trong trụ giờ vì biểu thị thịnh
        vượng và sự nghiệp tốt. Nó cũng biểu thị công việc vất vả của người này
        sẽ được chú ý và trân trọng. Trụ này không nên gặp nhiều Mậu Thổ hay Hợi
        vì cách cục này sẽ tạo ra hung cục xem như Kiếp Tài Phá. Cục này đa phần
        biểu thị kết quả tiêu cực do nhiều quyết định sai lầm, không hợp thời.
        Những người này được khuyên là nên dành thời gian cho những quyết định
        quan trọng và đảm bảo rằng họ chuẩn bị đầy đủ trước khi có những cam kết
        trọng đại trong đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Ất Dậu sinh vào mùa Xuân và Hạ có xu hướng tỏ ra vội vã, khó chịu
        và thiếu kiên nhẫn. Họ có thể nhanh chóng giận dữ và có xu hướng xài
        hoang phí khi cuộc đời không như ý. Sinh vào mùa Thu và Đông thì sẽ sở
        hữu khả năng kinh doanh tốt và đầu óc sắc bén. Họ cũng luôn nghĩ đến
        những kế hoạch mới và có thể rất thành công trong việc điều hành doanh
        nghiệp của mình. Vận xui có thể đến nếu họ sinh vào tháng Tuất vì họ
        phải vất vả để đạt được kết quả bất kể vất vả ra sao nhưng họ vẫn được
        khuyên nên bền chí và không sợ hãi khi cần phải xin nhờ sự giúp đỡ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sinh vào ban ngày thì người này sẽ có khả năng có đường đời suôn sẻ và
        dễ dàng. Sinh vào ban đêm thì sẽ phải tìm sức mạnh bên trong đế đối phó
        nhiều trở ngại. Họ cũng rất quyết tâm và sẵn sàng chiến đấu để đạt mục
        tiêu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ất toạ trên Dậu mang theo năng lượng duy nhất của Tân Kim Thất Sát. Họ
        là người rất chú tâm đến hành động và được thúc đẩy bởi 1 mục tiêu duy
        nhất để chiến thắng. Sự gắn bó của họ bao gồm cả sự chính xác của kim
        loại và nhanh nhạy của gỗ mềm. Họ biết rằng họ không thể chịu đựng được
        sự lãng phí vì thất bại sẽ khiến họ không thể sống sót. Họ rèn luyện kỹ
        năng hành động chính xác, để trông yếu đuối và khiêm tốn trong khi chờ
        đợi khoảng khắc phù hợp để săn con mồi trong một cú vờn nhanh chóng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Dậu cuốn hút Thìn mang theo năng lượng của Chính Tài Mậu Thổ, phụ khí
        của Tỷ Kiếp Ất Mộc và Thiên Ấn Quý Thuỷ. Sự thân thiện và duyên dáng
        khiến cho họ có vẻ ngoài khó cưỡng lại mà bất cứ người phụ nữ nào bị lôi
        kéo. Đối với nữ giới sinh Ât Dậu thì họ thiếu Chính Quan, sự ổn định
        trong tình cảm có thể là vấn đề nhưng đừng bị nhầm lẫn, họ bản thân đã
        luôn nóng bỏng, có sức hấp dẫn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Dậu xua đuổi Mão có một Tỷ Kiên Ất Mộc. Vì họ có lối sống chiến lược,
        những người xung quanh sẽ cảm thấy họ đang bị lợi dụng và từ từ nhận ra
        họ chỉ là công cụ. Người thông minh hơn sẽ dần dần tránh xa họ trong khi
        người kém thông minh sẽ hi sinh vì họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhìn chung, người Ất Dậu là một con bướm. Sự ham muốn thành công của họ
        rất mạnh và được củng cố bằng những sự hi sinh họ sẵn lòng để đánh đổi
        lấy thành công. Tuy nhiên họ cũng không phải dạng người có âm mưu tàn
        độc mà ngược lại có thể là bạn tốt nếu bạn biết cách đối nhân xử thế.
        Bạn muốn được quan tâm? Chỉ cần đứng bên cạnh họ và tỏ ra gắn kết nhưng
        nhớ rằng chỉ phá vỡ mối quan hệ một khi bạn có được điều mình muốn. Tỏ
        ra dễ thương, chịu hi sinh mất mát một chút và bạn sẽ lấy được lòng của
        họ.
      </p>
    </div>
  );
};

export const AtMui = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Ất Mùi có đầu óc nghệ sĩ, lãng mạn, không cưỡng lại được
        cái đẹp, không ngần ngại thể hiện ra cảm xúc và suy nghĩ thật sự dù có
        làm phật ý người khác. Mặc dù người này có tính cách cứng đầu, ngoan cố,
        họ thiếu quyết tâm và kiên trì để đạt thành công tột đỉnh. Người
        vợ/chồng thường có xu hướng ghen tuông nếu người này có quan hệ tình cảm
        với người khác giới tốt. Quan hệ tình cảm với cha mẹ không tốt, không
        hiếu thuận. Nam giới thường giàu tham vọng. Nữ giới thì sinh con giỏi
        giang.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ất Mùi cũng như bụi của núi lửa, đám cháy rừng, trông như rời rạc nhưng
        bên dưới lớp tàng tro thì lại là phân bón giúp cho đất tốt và phát triển
        sự sống.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ất Mộc được xem là hành kiên trì bền bỉ. Họ có khả năng cao sinh tồn do
        đặc tính không cứng nhắc. Họ đòi hỏi rất ít và gần như có thể sống trên
        mọi bề mặt nếu được cung cấp đủ ánh nắng và nước. Họ có khả năng nhận
        bất cứ gì thải đi và biến nó thành lợi thế. Hành này thắng thế Canh Kim
        dữ dội, là hành mềm mại và yếu đuối biết cách lấy lòng người khác, kể cả
        những người sắt lạnh nhất.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ất Mùi tượng trưng cho cây cỏ mọi tại vùng đất khô hạn hay sa mạc. Hình
        ảnh này phản ánh lá số Bát Tự của một người sinh tồn tốt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Ất Mùi giỏi trong việc kết nối và trò chuyện cùng người khác. Tài
        giao tiếp của họ với người khác cho phép họ phán xét mọi thứ một cách
        chính xác. Mặc dù họ có khả năng để trở nên quảng giao, họ khá nhạy cảm
        và thích sự đơn độc. Lý do họ thích sự đơn độc là vì họ muốn được độc
        lập, không phụ thuộc ai. Quý nhân thường ở xung quanh họ vì trái tim và
        cử chỉ lịch thiệp của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bởi vì họ có xu hướng trở nên thông minh và khôn khéo, họ thích giữ bản
        thân mình luôn bận rộng và cập nhật kiến thức. Họ có khả năng phát triển
        đầu óc và trực giác bản thân. Họ thích làm việc một mình để đạt được sự
        phát triển về tâm linh và tinh thần, đó cũng là lý do họ hay tỏ ra mình
        là người tự dựa vào bản thân. Người Ất Mùi thường có năng khiếu về tâm
        linh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Hãy cho người Ất Mùi một mục tiêu hay một lý tưởng, họ sẽ trở thành
        những người hết mình. Ngay cả nếu họ cảm nhận được trở ngại, sự chịu
        đựng của họ sẽ khiến cho kiên trì. Chính vào những thời khắc này, những
        người xung quanh sẽ nhận ra năng lực của họ to lớn như thế nào. Một khi
        được đốt cháy, họ có thể chinh phục được nhiều thử thách vĩ đại.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Ất Mùi hạnh phúc nhất khi được hưởng lối sống xa hoa và thượng
        lưu. Họ là người sẵn lòng làm việc vất vả để có thể sống hưởng thụ.
        Không ngạc nhiên khi chính ý chí mạnh mẽ và sự quyết tâm của người Ất
        Mùi giúp họ có thể đạt được nhiều vật chất. Nhưng để có thể trải nghiệm
        sự thoả mãn tối đa, họ cần phải cân bằng với trực giác của mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi họ lớn tuổi hơn, công việc đóng vai trò quan trọng hơn. Sự nghiệp
        của họ sẽ trở nên nổi bật hơn và họ sẽ sử dụng sự nghiệp để giúp định
        hình bản thân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thiếu sáng tạo và gặp khó khăn khi tìm dòng tài lộc ổn định là những vấn
        đề mà người Ất Mùi có thể gặp phải. Họ thường không phải là người thích
        hợp cho các công việc phân tích chi tiết và lên chiến lược.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Ất Mùi có xu hướng tự cao và kiểm soát. Nhu cầu kiểm soát có thể
        khiến cho họ đòi hỏi và thích phê bình người khác. Họ nên nỗ lực để quản
        trị bản thân vì những người khác đánh giá điều này là hơi thô lỗ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Cảm thấy tội lỗi vì đôi khi hơi phản ứng thái quá, người Ất Mùi cần phải
        học cách kiểm soát phản ứng. Điều này cũng sẽ làm cho họ trở nên hiệu
        quả hơn và cũng giúp họ tránh những sự giận dữ không cần thiết và lo
        lắng, đặc biệt liên quan đến tiền bạc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đối với phụ nữ sinh ngày này, họ có thể bị sẩy thai. Lưu ý cần thăm khám
        sức khoẻ thường xuyên ngay khi vừa cấn thai.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Việc hưởng thụ quá nhiều đôi khi có thể cản trở họ hình thành kỷ luật
        bản thân cần thiết để có thể đạt tiềm năng bản thân lớn nhất.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bị lấn át bởi tư duy phân cực, tâm trạng của họ có thể đi từ mức hưng
        phấn sang mức tuyệt vọng nhanh chóng. Khi tâm trạng cảm xúc trồi sụt lên
        xuống, họ có thể đi từ từ bi sang mức giận dữ. Một trong những dấu hiệu
        tiêu cực là thái độ sống của họ quá nghiêm túc. Họ có thể trở nên dễ
        dàng trầm cảm và không thoả mãn, nên họ cần phải ngăn bản thân không tự
        huỷ hoại mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Của Người Ất Mùi:</b> thích xã giao, quan hệ rộng,
        kín đáo, tâm linh, dựa vào bản thân, ngoại hình đẹp, thích từ thiện.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Ất Mùi có khả năng lãnh đạo ẩn giấu. Nếu họ kết hợp điều này với
        tính siêng năng vốn có, họ sẽ phát hiện ra họ có đủ những tố chất để đạt
        thành công trong bất kỳ vị trí hay sự nghiệp nào.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Cần nhớ rằng họ giỏi trong việc kết nối nên họ sẽ xuất sắc trong sự
        nghiệp đòi hỏi kỹ năng giao tiếp tốt. Họ giỏi ra những quyết định quan
        trọng, điều có thể dẫn họ đến sự nghiệp kinh doanh thành công. Ngoài ra,
        họ sở hữu sự cân bằng giữa đồng cảm và trực giác, cũng như tỏ ra mạnh mẽ
        và độc tài. Điều này khiến cho họ thành công trong bất kỳ vị trí nào đòi
        hỏi biết cách đối nhân xử thế.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Ất Mùi có động lực cao và tham vọng. Họ sở hữu năng lực tinh thần
        có thể giúp cho họ tranh luận tốt. Họ không gặp khó khăn trong việc
        thắng các cuộc tranh luận.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ho khao khát kiểm soát nắm quyền, điều này thúc đẩy họ có thể đạt đến
        các vị trí quản trị và nắm quyền. Họ cũng có thể thành công xuất sắc khi
        tự làm chủ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Ất Mùi luôn thích tham gia vào việc kinh doanh. Họ luôn
        có nhu cầu thường xuyên bận rộn và do đó, kết thúc là làm việc quá vất
        vả. Người Ất Mùi phải học cách để kiềm chế không quá hài lòng với thành
        công và ngủ quên trên chiến thắng.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Công Việc:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sự nghiệp lý tưởng của người Ất Mùi là sử dụng lợi thế sức mạnh tâm
        linh. Họ sẽ là những người thầy tâm linh tốt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ giỏi khả năng tranh luận va thích kiểm soát. Kỹ năng này dẫn họ đến
        sự nghiệp thành công trong lĩnh vực chính trị hoặc luật pháp. Nếu họ
        quyết định tham gia vào thế giới kinh doanh, họ sẽ leo lên đến đỉnh do
        kỹ năng kinh doanh sẵn có bẩm sinh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ cũng có sẵn máu nghệ sĩ nên có thể tham gia vào lĩnh vực kịch nghệ
        hay giải trí. Cá tinh và ham thích thể hiện bản thân có thể dẫn họ đến
        những lĩnh vự như vũ đạo, âm nhạc và nghệ thuật. Họ cũng có thể trở
        thành đạo diễn, nhà sáng tác hay vũ sư vì họ thích kiểm soát.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bản tính thích quảng giao giúp họ có lợi thế trong giao tiếp. Các lĩnh
        vực bao gồm viết lách, bán hàng, xuất bản hay thậm chí giảng dạy sẽ phù
        hợp với họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bản tính nhân đạo của người Ất Mùi giúp họ có khả năng tham gia vào các
        công việc từ thiện. Họ nên tham gia vào các công việc giúp đỡ những
        người kém may mắn. Nếu họ đem thêm sự đam mê của họ thì họ thật sự có
        thể thay đổi thế giới.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Của Người Ất Mùi:</b> khả năng lãnh đạo giỏi, siêng
        năng, thẳng thắn, năng động, mất kiên nhẫn, không có óc tổ chức, kiêu
        ngạo, quá nhạy cảm.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Gia Đình - Quan Hệ:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Ất Mùi là những người lãng mạn tuyệt đối khao khát mối quan hệ
        thân mật. Trái tim của họ cần tình yêu và đam mê. Bởi vì họ cần được đảm
        bảo an toàn, họ khá cẩn thận và thấu hiểu. Họ có giác quan tốt nghĩa là
        họ dễ dàng cảm nhận những ngời xung quanh cảm nhận hay suy nghĩ ra sao.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đừng mong đợi họ sẽ ngay lập tức gắn bó về tình cảm. Họ phải cần có thời
        gian để thiết lập nên mối quan hệ tình cảm thật sự lâu dài. Mặc dù có
        thể mất thời gian để họ cân nhắc mối quan hệ nghiêm túc, một khi họ
        chung thuỷ, họ là đối tượng rất rộng lượng và tốt bụng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người Ất Mùi cần phải đảm bảo rằng mối quan hệ tình cảm của họ
        không bị bế tắc. Nếu bị bế tắc, họ sẽ cảm thấy khó bày tỏ cảm xúc sâu
        thẳm nhất.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Ất Mùi quan tâm và thích bảo vệ nên họ là những người bạn mạnh mẽ
        và trung thành. Họ gây ấn tượng sâu sắc với bạn bè là người bản tính
        rộng lượng và từ bi. Họ thích bạn bè ít nhưng gần gũi và chung thuỷ hơn
        là nhiều bạn bè.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Lòng trung thành và niềm tin là những gì có thể thống trị mối quan hệ
        tình bạn nghiêm túc và những cảm xúc mâu thuẫn có thể khiến họ tỏ ra đòi
        hỏi. Để giữ những mối quan hệ lành mạnh, họ cần phải tránh đa nghi và
        nghi ngờ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù họ rộng lượng và thân thiện, họ có thể tỏ ra tự hào hay tự cao.
        Cần thiết là họ phải học cách tỏ ra ít đòi hỏi hay tránh áp đặt ý chí
        chủ quan của mình lên người khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những nhân tố như an ninh và ổn định tài chính đóng vai trò quan trọng
        trong việc lựa chọn bạn đời lâu dài. Người Ất Mùi thường sẽ thích có hôn
        nhân hoà hợp và vui vẻ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù họ tôn trọng và cảm thông với người yêu thương, người Ất Mùi vẫn
        thích tự ra quyết định của riêng mình. Họ không luôn đáp lại yêu cầu của
        bạn đời. Thường họ sẽ tỏ ra hống hách trong nỗ lực kiểm soát quyết định
        của người thân yêu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Của Người Ất Mùi:</b> thích xã giao, nhạy cảm, chung
        thuỷ, tin cậy, yêu thương.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Ất Mùi thông căn và toạ Khố. Bởi vì Tài Tinh ở trong Khố, đây là
        dấu hiệu cho thấy những người này sẽ giàu có. Tuy nhiên vì tài lộc chưa
        chắc mua được hạnh phúc và dù họ sống một cuộc sống xa xỉ, sâu thẳm bên
        trong họ cảm thấy trống rỗng và khao khát nhiều hơn. Chỉ có thông qua
        trải nghiệm tâm linh mới có thể lấp đầy khoảng trống này.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu Đại Vận và Lưu Niên xung với Mộ thì sẽ là dấu hiệu của thịnh vượng
        và thành công về tài chính. Với khiếu kinh doanh và khả năng lên kế
        hoạch kỹ lưỡng, ngời Ất Mùi có thể gia tăng thành công gấp đôi. Người Ất
        Mùi từ bi và là những người mộng mơ lý tưởng. Họ có cá tính vui vẻ, yêu
        thương con người sâu sắc và có thể rất cuốn hút. Những người này rất
        nhạy cảm, họ có thể dễ dàng bị ảnh hưởng bởi áp lực đồng nghiệp và
        thường những bước chân đầu tiên trong cuộc đời họ nên là tự khám phá ra
        bản ngã của mình. Một phần của quá trình này là học cách để tin tưởng
        vào trực giác mạnh mẽ của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Ất Mùi có thể thúc đẩy học hỏi và tiến bộ cả về tri thức hay tâm
        linh. Họ có cảm xúc rất mạnh mẽ thúc đẩy họ tìm sáng tạo như là lối
        thoát.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Ất Mùi được xem là có lá số Bát Tự thuận lợi nếu trong lá số có
        thêm Dần, Hợi và Giáp. Những thành phần này sẽ đóng vai trò quan trọng
        trong phát triển bản thân. Đây sẽ là dấu hiệu của danh vọng và thành
        công. Ngoài ra, hành Thổ và Hoả trong đại vận cũng sẽ giúp đem lại may
        mắn và nghĩa là dễ tiến bộ trong sự nghiệp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu họ sinh trong mùa Xuân và Hè, người Ất Mùi trông đợi thành công nếu
        họ tự kinh doanh riêng. Những người này sẽ có thể làm việc chăm chỉ,
        chấp nhận thử thách với quyết tâm và sẽ trở nên thành công. Nếu sinh vào
        mùa Thu và Đông, họ sẽ có cơ hội thành công lớn hơn nữa vì họ sẽ có gíac
        quan kinh doanh sắc bén. Người sinh vào tháng Sửu sẽ khó thành công và
        có thể mắc nhiều vấn đề về sức khoẻ trong đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu họ sinh vào ban ngày thì người Ất Mùi sẽ cảm thấy thiếu động lực để
        đạt thành công. Do đó họ cần phải tự khích lệ bản thân nhiều hơn. Nếu họ
        sinh vào ban đêm thì họ có thể có gia đình hạnh phúc, ấm áp và có nhiều
        con cái.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mùi mang theo 3 khí là Kỷ Thổ Thiên Tài là chính khí và kẹp bởi Đinh Hoả
        Thực Thần và Tất Mộc Tỷ Kiên. Những người sinh trụ ngày này thường có xu
        hướng tự nhiên nhìn tới phía trước. Đây cũng là một trong những nguyên
        nhân vì sao những tai hoạ trong khoảng khắc không bao giờ làm họ sợ hãi
        vì họ luôn nhìn ra cơ hội dành cho họ. Là thổ mềm Kỷ, họ có xu hướng
        luôn luôn nuôi dưỡng và không đối đầu. Bạn có thể nói rằng họ có một
        trái tim quan tâm khi xét về vấn đề con người và mối quan hệ tình cảm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thực Thần đem đến khả năng tưởng tượng và sáng tạo, họ thường lờ đi
        những gì ngay trước mặt họ và ngay lập tức tạo ra một thế giới thần tiên
        nơi họ có thể sử dụng phép thuật. Đinh Hoả là hành của sự hi vọng và
        điều này lại tái khẳng định khả ănng của họ để khiến cho người khác cảm
        thấy vẫn có tương lai giữa vô vàn xui xẻo. Trong lúc hình thành tâm lý,
        Ất Mộc là Tỷ Kiếp đem đến thêm sự tự tin và an ủi cho họ. Họ biết cách
        tìm đường đến trái tim bạn và với sự giúp đỡ của một tương lai tốt hơn
        và đầu óc sáng rõ, không khó để họ có thể bán được giấc mơ lý tưởng của
        họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mùi kéo theo Ngọ trong Lục Hợp và mang theo năng lượng của Thực Thần
        Đinh Hoả và giới thiệu thêm Thiên Tài Kỷ Thổ. Bởi vì cả Chính Tài và
        Chính Quan đều thiếu, có thể kết luận rằng người này sẽ không chú trọng
        hôn nhân. Sự quan tâm của họ thật sự là cản trợ đối với quan hệ tình
        cảm, vì bạn có một người bạn đời chuyên bỏ bạn đi cứu nhân độ thế.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mùi xung với Sửu mang theo năng lượng của Thiên Tài Kỷ Thổ và Thiên Ấn
        Quý Thuỷ, Thất Sát Tân Kim. Ở đây bạn có thể thấy Thiên Tài và Thất Sát
        cùng bị đẩy lùi đi. Họ quyết tâm bằng mọi giá để giữ tình trạng độc thân
        để họ có thể giúp nhiều người hơn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ất Mùi thật sự là khác biệt với trọng tâm của họ là hồi sinh người khác
        sau một sự cố buồn. Sự sẵn lòng đặt nhu cầu của họ bên dưới nhu cầu của
        người khác đáng được khen ngợi. Họ là những ứng viên rất tốt để điều
        hành các tổ chức từ thiện và giỏi trong việc khiến1 người khác tin rằng
        tương lai có thể trở nên tốt đẹp hơn.
      </p>
    </div>
  );
};

export const AtTy = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Ất Tỵ: thường có vẻ ngoài hấp dẫn, cử chỉ dáng đi đều
        toát ra thần thái duyên dáng, giỏi thể hiện ưu điểm, giỏi quản lý giao
        tiếp, là thiên tài trong lĩnh vực quản lý kinh doanh, giỏi tài ăn nói,
        hiểu tâm lý và cảm xúc con người, có xu hướng trở thành nhà từ thiện hay
        chi tiêu hào phóng. Nếu thiếu hành Thuỷ ở thiên can, người này dễ chết
        trong vận Kim. Sức khoẻ kém.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhiều người hay kêu gọi sự quan tâm nhưng cũng có những người mà bạn cần
        hết sức quan tâm. Ong bắp cày là một sinh vật mà bạn khó lòng mà không
        để ý. Chúng thường ồn ào và đi theo bầy mỗi khi muốn tấn công. Ất Tỵ là
        trụ ngày có thể yêu cầu sự quan tâm và bạn có thể cần phải quyết định
        thật nhanh cần phải làm gì ngay khi thấy một con ong bắp cày.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ất đại diện cho Âm Mộc biểu thị là hành sinh tồn linh hoạt. Nó sẽ phát
        triển trong mọi môi trường và phát triền tốt khi nào điều kiện còn phù
        hợp. Chúng ta thường có thể miêu tả họ như những người giỏi kết nối quan
        hệ xã hội và hành giúp họ làm bạn với cả những hành dữ dội nhất như
        Canh. Họ thường tỏ ra dễ thương và sang trọng. Họ không phải tuýp người
        tìm kiếm sự chú ý mà thuộc dạng “cún con dễ thương” bày tỏ sự dễ tổn
        thương đến mức mà bạn chỉ muốn bảo vệ nó. Cần lưu ý rằng họ sẽ bẻ cong
        và thao túng người khác vì mục đích sinh tồn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Hình ảnh của Ất Tỵ là hoa mọc dầy trên những vùng đất cao như đồi núi.
        Đây là lay do vì sao Ất Tỵ được xem như là cao nhât trong số những ngày
        sinh Ất khác. Họ lạc quan, hướng ngoại, siêu việt và thích khoe. Được
        ban cho khả năng lạc quan, họ là những công dân có trách nhiệm và nắm
        quyền lãnh đạo và gây ảnh hưởng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ là những người cầu toàn, có độ tâp trung cao và điều này cho phép họ
        có thể nhanh chóng hiểu được những khái niệm và phương pháp mới mẻ. Họ
        có khả năng để trình bày những góc nhìn mới mẻ khác thường và giá trị
        thực tế.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đối với người sinh ngày này, vấn đề tài chính chiếm trọng sự quan tâm
        của họ. Có lẽ điều này là hợp lý vì họ có suy nghĩ lý trí về kinh doanh.
        Là người đa tài và thích cạnh tranh, họ có khả năng để thương mại hoá
        tài năng của họ để kiếm tiền. Khi họ cho phép trực giác dẫn dắt, họ có
        thể cảm tấhy những thành công lớn hơn về mặt kinh doanh.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Dễ dàng hơn cho họ để thể hiện cảm xúc và ham muốn mạnh mẽ của họ. Họ là
        những người thể hiện sự từ bi cao độ và sẵn lòng hỗ trợ người khác. Hoà
        hợp và an toàn là quan trọng đối với họ và họ xem nhà và gia đình là
        những điều rất quan trọng.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có thể phát triển tốt nếu như có thể thai nghén những mục tiêu rõ
        ràng. Kỷ luật và quản trị bản thân rất cần thiết trong việc đem đến tài
        năng tốt nhất của họ.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Vận may không ủng hộ họ khi còn trẻ. Họ có thể gặp khó khăn nhưng khi
        lớn tuổi hơn sẽ may mắn hơn.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc cho họ có năng khiếu về tài chính, họ thường lo lắng và sợ hãi về
        tiền bạc. Điều này có thể cản trở khả năng giải quyết khó khăn và tìm
        giải pháp dễ dàng. Trong những khi nghi ngờ, họ có thể cố gắng nhìn vào
        mặt sáng của vấn đề, tìm kiếm những sáng kiến gợi cảm hứng và cải thiện
        kỹ năng giao tiếp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Ất Tỵ thường chịu đựng một khối lượng căng thẳng khác thường khi
        họ quá chú tâm đến tham vọng của mình. Họ có xu hướng xét đoán mọi thứ
        quá nghiêm trọng, đây cũng là do họ nhạy cảm và có nhiều ham muốn mạnh
        mẽ. Chính sự căng thẳng này cũng dễ khiến họ sụp đổ vào lúc họ không thể
        chịu đựng được.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Chính xu hướng tự nhiên trở nên cứng đầu và óc đa nghi có thể vượt qua
        bằng cách xây dựng niềm tin và cho phép những thứ ngẫu nhiên xảy ra
        trong đời. Họ nên cân nhắc việc thiền định thường xuyên để gia tăng thêm
        nhận thức và giác quan.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Những Đặc Tính Của Người Ất Tỵ:</b> lạc quan, hướng ngoại, tài năng,
        quyết đoán, cầu toàn, khôn ngoan về tài chính.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Ất Tỵ thường có dòng suối nguồn tuôn chảy các ý tưởng sáng tạo mà
        họ sẵn lòng áp dụng cho bất kỳ ngành nghề nào họ tham gia. Họ có trí
        thức, suy nghĩ thực tế và kỹ năng giao tiếp giúp họ thành công trong
        lĩnh vực họ chọn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có sự thấu hiểu con người, điều này giúp cho họ nên học ngành tâm lý.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ cũng rất giỏi trong việc quản lý các dự án lớn, nhất là xoay quanh
        chính trị hay pháp luật. Họ sở hữu khả năng điều hành bẩm sinh và thấu
        hiểu thế giới kinh doanh. Một khi những người khác hiểu được điều này,
        họ sẽ ủng hộ và ngưỡng mộ họ.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Công Việc:</h4>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có tài năng trong sự nghiệp viết lách, giảng dạy, nghiên cứu hay công
        nghệ thông tin. Người Ất Tỵ sẽ thấy những công việc này thoả mãn và gia
        tăng được kỹ năng trí óc của họ. Họ có cơ hội lớn trong lĩnh vực viết
        lách và giao tiếp bởi vì những ý tưởng mang đầy chất cá nhân của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhiều người Ất Tỵ chọn cách sử dụng óc phân tích trong lĩnh vực hoa học,
        nghiên cứu hay y học. Không chỉ bị giới hạn trong những lĩnh vực đó, họ
        sẽ trở thành những ứng viên giỏi trong lĩnh vực triết học, tôn giáo hay
        huyền học.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Chính trị hay giải trí sẽ thu hút họ vì họ thích những gì mang kịch
        tính. Họ cũng thừa hưởng kỹ năng tổ chức tốt giúp họ có thể thành công
        trong kinh doanh hay các vị trí quyền lực. Nếu họ chọn trở thành một nhà
        quản lý, điều hành, tài năng của họ sẽ được đặt đúng chỗ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khả năng đánh giá và thấu hiểu bản chất con người khiến cho họ dễ xuất
        sắc trong lĩnh vực tâm lý, cố vấn hay thậm chí trị liệu. Họ sẽ thấy
        những ngành nghề trên khá kích thích và mang tính tưởng thưởng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người này cũng sẽ có xu hướng đi theo chính nghĩa nhân đạo và
        thích các cuộc cải cách xã hội, công việc từ thiện hay các ngành nghề
        quan tâm chăm sóc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Trong Sự Nghiệp - Công Việc:</b> óc tổ chức tốt, kỷ
        luật bản thân, siêng năng, nhiệt tình, có trách nhiệm, trì hoãn, áp đặt,
        nổi loạn, cứng nhắc.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Quan Hệ - Gia Đình:{" "}
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Ất Tỵ nên cẩn trọng trong chọn lựa bạn đời. Nhạy cảm và từ bi,
        người Ất Tỵ có nhiều cung bậc cảm xúc. Họ khao khát tình yêu, đam mê, sự
        ổn định và yên bình. Họ chân thật đến mức thường dễ bị thiệt thòi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mức độ chân thật khi bắt đầu mối quan hệ tình cảm thường cần liên tục
        được nuôi dưỡng để đảm bảo mối quan hệ tình cảm bền vững. Một khi thiết
        lập được mối quan hệ tình cảm, họ nhận thấy điều quan trọng là giữ được
        sự cân bằng giữa hi sinh và độc lập.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù họ có khao khát tình yêu và cảm xúc, điều này không ngăn cản ham
        muốn tìm có thể đem đến cho họ sự kích thích. Bởi vì điều này, hô không
        nên để cho mối quan hệ rơi vào vô vị.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi gặp tình huống mới hay các sự kiện không mong đợi có thể khiến họ
        bất an. Nếu họ dấn quá sâu vào một mối quan hệ khi còn trẻ, họ có thể
        dần dần mất cảm hứng tình cảm khi thời gian trôi qua. Sẽ là thông thái
        nếu tiến chậm trong đường tình cảm và cho phép tình yêu hình thành qua
        thời gian.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Ất Tỵ sở hữu tính cách thích giao tiếp. Tính cách này thu hút
        những người xung quanh và thường là lý do vì sao họ hay được bạn bè tìm
        đến. Làm cho người khác vui cũng là niềm vui của họ, vì họ thích quảng
        giao.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ thường dồi giàu cảm xúc khi ở bên cạnh những người họ thật sự quan
        tâm vì họ thường gắn bó với những người họ ngưỡng mộ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ đôi khi khá kín kẽ và bí mật, có thể gây ra những tín hiệu hiểu lầm
        về sự lạnh lùng hời hợt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Ất Tỵ cần tình cảm nhiều và an toàn. Họ chân thành và có khả năng
        cải thiện mối quan hệ với những người gần gũi họ nhất. Có thể được mô tả
        như ngọn núi vững mạnh, họ là những người có thể hỗ trợ các thành viên
        trong nhà đang cần được giúp đỡ nhất.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người nữ giới Ất Tỵ có xu hướng mạnh mẽ và thành công và có xu hướng kết
        hôn với một người nam giới ít áp đặt. Người chồng, phải chấp nhận điều
        này thì mối quan hệ vợ chồng mới ổn. Nam giới Ất Tỵ có xu hướng sợ vợ và
        thường bị vợ kiểm soát.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người sinh ngày Ất Tỵ có thể khám phá ra rằng người gần gũi họ
        nhất sẽ yêu thương họ hơn nếu họ kiên nhẫn hơn trong mối quan hệ. Sự mất
        kiên nhẫn có thể đến từ việc tâm trạng của họ khá dao động.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Trong Tình Yêu - Quan Hệ:</b> hấp dẫn, yêu thương,
        cảm xúc, chân thật, hướng ngoại, ủng hộ, mất kiên nhẫn.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trong nhiều trường hợp, nỗ lực của người Ất Tỵ sẽ có lợi cho nhiều người
        hơn là bản thân họ bởi vì sự xuất hiện của hành Hoả trong Tỵ Địa Chi.
        Điều này làm suy yếu Ất Mộc trong lá số Bát Tự. Có vẻ như họ vẫn sẽ hành
        động vì lợi ích của mình mặc cho những người xung quanh có nghĩa là họ
        hoàn toàn vì cộng đồng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Ất Tỵ thường thấy gia tăng may mắn nếu trụ ngày toạ trên Thương
        Quan, Chính Tài hay Chính Quan. Điều này biểu thị họ sẽ gặp nhiều cơ hội
        đầu tư có thể đem đến an toàn tài chính nếu họ thông thái và biết cách
        khai thác kiến thức của mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Ất Tỵ có khả năng đạt được nhiều thành công. Họ năng động, quyết
        tâm và thực tế. Họ cũng có nhận thức, trực giác và sáng tạo nên thích
        giải quyết các vấn đề và cũng như tìm đến cội nguồn của bí ẩn, họ có đầu
        óc sắc bén và có thể phản hồi nhanh chóng bất kỳ tình huống nào. Người
        Ất Tỵ yêu thích chiến thắng và có thể là động lực chính phía sau chính
        nghĩa của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Ất Tỵ đánh giá cao sự an toàn. Họ có thể có xu hướng lo lắng về
        tài chính và an toàn sự nghiệp bất kể là lo lắng có thật hay tưởng
        tượng. Họ làm việc chăm chỉ để tạo ra nền tảng vững chắc cho gia đình và
        nam giới Ất Tỵ sẽ chọn kết hôn muộn. Người Ất Tỵ trân trọng điềm tĩnh và
        cân bằng trong mọi khía cạnh cuộc sống. Họ có bản tính dễ chịu và thường
        chân thực và chung thuỷ cùng với lòng yêu công lý.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Ất Tỵ có thể thiếu kỷ luật, đặc biệt sẽ càng ứng hơn nếu Thương
        Quan gặp Chính Quan ở Địa Chi trong lá số Bát Tự. Vì họ bất ổn và sống
        lý tưởng, điều này có thể đồng nghĩa họ sẽ thường xuyên có cảm giác bất
        mãn thôi thúc họ tìm kiếm sự thay đổi. Điều này có thể là điều tốt nếu
        họ có thể sử dụng nó để nuôi dưỡng tham vọng nhưng cũng có thể khiến họ
        khá nổi loạn. Họ thường từ bỏ hoàn toàn con đường đã chọn cũng như lãng
        phí khả năng của mình dùng cho những kế hoạch bất khả thi. Không tốt cho
        người Ất Tỵ nếu tham gia quân sự vì cách cục này biểu thị sự thiếu kỷ
        luật có thể dẫn họ đến việc trở nên khá bạo lực.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu gặp thêm hành Kim hay Thuỷ cùng với Kỷ Thổ trong lá số thì là dấu
        hiệu may mắn. Điều này cũng sẽ giúp cho người này có thể tập trung kỹ
        năng và khiến cho họ thành công, đặc biệt nếu họ chọn con đường học
        thuật. Điều này sẽ càng có lợi hơn nếu trụ ngày này gặp Bính Hoả trong
        lá số.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trụ này phải không bị Hình hay Xung bởi các hành kỵ thần ở các trụ khác.
        Nếu điều này xảy ra thì có thể trụ ngày này sẽ bị suy yếu và có thể dẫn
        đến tài năng của họ bị suy giảm. Họ sẽ khó tìm được sự trợ giúp từ những
        người khác và sẽ phải làm việc vất vả và phải dựa vào chính mình để đạt
        mục tiêu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Lá số này sẽ không lành mạnh nếu trụ ngày này gặp quá nhiều hành Mộc.
        Điều này là dấu hiệu người này sẽ phải làm việc vất ả để thắng lợi trong
        đời và có khả năng nó sẽ ảnh hưởng đến óc phán đoán, khiến cho họ khó
        lòng lựa chọn đúng đắn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu một trụ Ất Tỵ khác xuất hiện trong đại vận của lá số sinh ngày Ất
        Tỵ, thì có khả năng họ sẽ nhận được thăng chức. Sự tiến bộ trong sự
        nghiệp cũng có nghĩa là họ sẽ nhận được phúc lợi lớn hơn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Ất Tỵ sinh vào mùa Xuân và Hè rất quyết tâm và kiên trì. Có vẻ như
        họ sẽ chịu nhiều vất vả trong đời và điều này sẽ dạy cho họ sức mạnh và
        cá tính. Nếu người Ất Tỵ sinh vào mùa Thu và Đông thì họ sẽ có thể tự
        tạo lập con đường và ít khi phải hỏi nhờ sự giúp đỡ từ người khác. Người
        Ất Tỵ sinh trong tháng Ngọ sẽ cảm thấy họ phải làm việc để vượt qua trở
        ngại và khó khăn ở bất kỳ nơi đâu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh vào ban ngày sẽ có thể tự tạo nên vận may cho mình. Họ sẽ độc
        lập hơn những người sinh ngày Ất Tỵ khác và sẽ kiên trì và cứng cỏi, làm
        việc không biết mệt mỏi để đạt thành công. Vận may sẽ đến cho người sinh
        vào ban đêm. Họ sẽ có năng lực về kinh doanh và tài chính giúp cho họ
        thịnh vượng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ất toạ ngay trên Thương Quan Bính, là nơi của mọi ham muốn sự chú ý xuất
        phát. Bính là hoả mặt trời có khả năng độc nhất đảo ngược chiều tương
        sinh của Ất Mộc, nó cho họ khả năng tỏ ra dễ thích nghi. Họ rất giỏi ăn
        nói và biết cách sử dụng giọng nói để đạt được điều mình muốn. Trụ này
        kèm với một Chính Tài Mậu, khiến cho họ nhạy cảm với cơ hội và kiên trì
        trong việc theo đuổi. Họ sẽ thấy các góc độ phương hướng khác nhau và
        tìm cách tận dụng tối đa lợi thế của mình để đạt mục tiêu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Cuối cùng, chúng ta sẽ có Chính Quan Canh bị chèn ép hoàn toàn bởi
        Thương Quan. Vào thời xưa, đây sẽ là người nổi loạn phát ngôn sự bất mãn
        với chính quyền. Hoàn toàn hỗn loạn khi làm việc với họ vì họ có xu
        hướng không tuân thủ theo các nguyên tắc thông thường.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tỵ hợp với Thân thu hút Canh Chính Quan, Nhâm Chính Ấn và Ngọ Chính Tài.
        Đối với cả nam và nữ thì Chính Tài Chính Quan thu hút vào trong đời,
        nhưng vì Thương Quan đánh đuổi Chính Quan, mối quan hệ tình cảm gây rối
        rắm cho nữ giới Ất Tỵ trong khi nam giới trở nên mạnh mẽ hơn trong hôn
        nhân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tỵ xung với Hợi xua đuổi Nhâm Chính Ấn và Giáp Tỷ Kiếp. Điều này dĩ
        nhiên là tốt cho quan hệ tình cảm của nam giới vì sao cạnh tranh bị xua
        đuổi đi. Không tốt cho nữ giới vì sao Chính Ấn vốn dĩ khắc chế Thương
        Quan cũng bị trừ đi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nói chung, khi nhìn vào trụ ngày Ất Tỵ thì bạn sẽ thấy sự ồn ào và phi
        chính thống. Họ thích bẻ cong quy luật và đánh đu. Họ giỏi biến bản thân
        mình thành đối tượng được công chúng yêu thích và không ngần ngại sử
        dụng tài sản tốt nhất để bản thân được bảo vệ một cách tinh tế. Luật sư
        có thể là hình tượng tốt để mô tả họ. Nếu bạn không chú tâm đến họ, họ
        có thể gây ra những việc mà bạn thật sự không muốn phải đối mặt.
      </p>
    </div>
  );
};

export const AtMao = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Ất Mão: rất rõ ràng yêu và ghét, khi yêu thì dễ ghen
        tuông, dễ ích kỷ trong tình yêu, có xu hướng yêu đơn phương, khi còn nhỏ
        trong nhà dễ gặp nhiều chuyện rắc rối, có xu hướng tiết kiệm các khoản
        tiền bí mật. Hôn nhân không hạnh phúc vì bất mãn với bạn đời. Nếu có các
        trụ còn lại là Mậu Dần thì người này giàu có và sang quý. Nam giới Ất
        Mão, nếu giàu có thì sẽ đem lại cái xấu cho vợ, nếu nghèo thì vợ sống
        thọ.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi bạn thích một bông hoa, bạn ngắt nó nhưng khi bạn yêu hoa thì bạn sẽ
        muốn để yên nó. Một vài người khá mỏng manh khi họ một mình nhưng mạnh
        mẽ khi số lượng tăng lên. Ất Mão là dạng người mà cảm thấy thảoi mái khi
        ở bên cạnh đồng chí. Đừng cố ức hiếp họ khi họ kết thành một tập thể.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ất Mộc là biểu tượng của hoa dịu dàng và cỏ đẹp. Hành này là một trong
        những hành thân thiện nhất. Người mang hành này thường được thừa hưởng
        khả năng cảm nhận và điều chỉnh. Họ thường là người không đe doạ và bạn
        sẽ cảm thấy thoải mái khi ở cạnh họ tựa như người thân trong nhà. Tài
        sản lớn nhất của họ là khả năng để hoà lẫn vào môi trường của bạn, bất
        kể là ở một môi trường bình dân hay sạng chảnh, họ đều biết cách ăn mặc
        và hành xử phù hợp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ất Mão đại diện cho đất cỏ dại, biểu thị Ất Mão. Cũng như hình tượng cỏ
        cao dễ dàng bị lung lay trước gió, người Ất Mão được biết đến là người
        dễ bị ảnh hưởng bởi người khác bởi tính cách dễ lung lạc.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người có ngày sinh Ất Mão thường thông minh, nhạy bén, tham vọng,
        tử tế, chung thuỷ và ưa nhìn. Họ rất phức tạp và là bí ẩn đối với những
        người muốn tìm hiểu họ.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Phút trước họ có thể lịch sự và tử tế, phút sau họ ngay lập tức yêu sách
        và căng thẳng mà không tỏ ra tiếc nuối.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có khả năng xây dựng sự thành công to lớn cho bản thân. Điều này một
        phần vì những quý nhân xung quanh họ. Bởi vì họ trung thành, họ thường
        có khả năng thu hút những người đáng quý có thể thường xuyên giúp đỡ và
        ủng hộ họ. Người Ất Mão sẽ sử dụng bạn bè vì mục đích của mình.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù họ có thể thu hút nhiều sức mạnh từ những người xung quanh, họ
        hoàn toàn có khả năng tồn tại một mình. Họ vốn gìau năng lượng và kiến
        thức, người có trụ ngày này thường dễ dàng biến sự sáng tạo thành việc
        kinh doanh có lợi nhuận.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Một trong những phẩm chất chính yếu của người Ất Mão là sự nhiệt tình.
        Nếu họ có thể tìm ra được một ý tưởng mà họ tin, họ sẽ nhiệt tình đi
        theo nó. Họ thích quyền lực và nếu sắp xếp khéo léo, đây sẽ là nguồn
        động lực thúc đẩy cho thành công cuối cùng của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Rộng lượng và không ích kỷ là những từ mô tả họ tốt nhất khi họ gặp may
        mắn. Họ sẵn lòng cho đi sự giàu có thành công về mặt tài chính và kinh
        doanh của mình. An toàn là một phần rất quan trọng của họ và thường bị
        đánh giá là theo chủ nghĩa vật chất. Chịu đựng tài chính kém không phù
        hợp với họ, điều này giải thích vì sao họ thỉnh thoảng sống xa hoa. Để
        chống lại cơn chi tiêu ngẫu hứng này, họ nên phát triển quan điểm nhân
        văn của mình. Điều này sẽ cho phép họ ít đắm chìm vào những rắc rối của
        mình mà thư giãn hơn về mặt tài chính.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Cuộc sống đơn điệu không dành cho họ. Họ ghét quy trình và sẽ cố gắng
        tránh sự trói buộc. Gặp gỡ nhiều người mới, du lịch và đa dạng hoá là
        những gì họ muốn trong đời. Họ yêu thích mạo hiểm và tự do và sẽ gặp gỡ
        nhiều thử thách cũng như cơ hội mới. Nhanh chóng trở nên bất mãn với
        hiện trạng, người Ất Mão có thể tin tưởng rằng mọi thứ có thể chinh phục
        ngay lập tức.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đôi khi, họ có thể ham muốn từ bỏ nhanh chóng, đặc biệt khi bị căng
        thẳng. Họ có thể vượt qua điều này bằng cách lên các kế hoạch tốt hơn
        trước khi nhận dự án. Thách thức lớn nhất mà người Ất Mão phải đối mặt
        là tìm cách cân bằng giữa lý tưởng và vật chất. Một khi đã ổn định, họ
        không còn nhiều vấn đề liên quan đến sự không chắc chắn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Để biết rõ hơn về thế mạnh và yếu, họ sẽ sẵn lòng tin tưởng vào linh
        cảm. Họ chắc chắn có tài trong tầm nhìn và dự đoán, tuy nhiên, điều này
        còn cần được củng cố thông qua sử dụng trực giác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đôi khi họ phá vỡ lời hứa, bởi vì họ không thực hiện được đúng trách
        nhiệm cần thiết. Họ có thể cải thiện trong những lĩnh vực nếu họ tỏ ra
        kiên nhẫn và sẵn lòng theo đuổi mọi thứ tới cùng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ cũng là kẻ thù lớn nhất của chính mình khi muốn đạt thành công. Vô số
        sở thích và tài năng có thể dẫn đến sự xao nhãng vì thiếu tập trung vào
        duy nhất một thứ cụ thể. Cách tiếp cận quyết đoán và thực tế sẽ cải
        thiện khởi đầu trì hoãn và giúp họ trên con đường thành công.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Của Người Ất Mão:</b> nhạy bén, rộng lượng, tử tế,
        linh cảm, phức tạp, lý tưởng, chung thuỷ.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Ất Mão luôn chăm chỉ và trung thành với bất kỳ lĩnh vực nghề
        nghiệp nào họ lựa chọn. Họ siêng năng, đáng dựa vào và có sự kiêu hãnh
        trong công việc. Bởi vì họ thật sự cố gắng, họ sẽ thành công trong mọi
        việc họ làm. Tham vọng của họ giúp họ tập trung sự chú ý vào cách thức
        kiếm tiền và thành công trong sự nghiệp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có thể thành công trong lĩnh vực nghệ thuật, âm nhạc, văn chương và
        sáng tạo. Việc họ không thích các vị trí phục tùng hay quy trình có thể
        thúc đẩy họ khám phá công việc trong các lĩnh vực quản lý hay doanh
        nghiệp. Sự tưởng tượng của họ sẽ giúp họ thành công cao trong con đường
        sự nghiệp đòi hỏi xây dựng hình ảnh. Họ sở hữu tài năng bẩm sinh để tiến
        hành các cải cách có thể dẫn họ đến công việc trong các tổ chức nghiên
        cứu xã hội.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Môi trường làm việc thân thiện rất cần thiết đối với những người này.
        Điều quan trọng là họ cần gặp gỡ cấp trên hoặc đồng nghiệp, nếu không,
        họ có thể tìm kiếm một nơi làm việc mới. Khả năng điều hành và lãnh đạo
        biểu thị họ sẽ giỏi nhất ở các vị trí đòi hỏi trách nhiệm quản lý. Sự
        độc lập tại các vị trí này đồng hành với tinh thần yêu tự do của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Có nhiều ví dụ cho thấy họ sẽ làm việc cho ai đó không nhận ra hết tiềm
        năng của họ. Trong trường hợp đó, họ vân sẽ trung thành tin tưởng vào
        tài năng vượt trội và khả năng bẩm sinh của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Ất Mão sẽ có quãng thời gian khó khăn trong việc đánh giá đúng
        năng lực của mình. Một khi họ đã xác định được, họ sẽ không bao giờ chấp
        nhận công việc dưới khả năng của mình. Họ thích chọn công việc có thể
        phát huy tiềm năng của mình tối đa thay vì chỉ đơn thuần dựa trên an
        toàn về tài chính.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Công Việc:</h4>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tiềm năng thành công của người Ất Mão cho phép họ trở nên giỏi trong bất
        kỳ lĩnh vực nghề nghiệp nào.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có đôi mắt sắc bén và năng lượng sáng tạo giúp cho họ phù hợp với sự
        nghiệp trong lĩnh vực âm nhạc, nghệ thuật và thiết kế. Xem xét sự nghiệp
        trong lĩnh vực sản xuất trong ngành giải trí là lựa chọn phù hợp với họ,
        đặc biệt vì họ s8a4n lòng làm việc sau ánh hào quang.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tâm lý học, chữa trị và sức khoẻ là những lựa chọn nghề nghiệp lý tưởng
        cho họ. Những đặc tính này cũng giúp họ thành công trong sự nghiệp và
        thúc đẩy họ trong những lĩnh vực có thể trực tiếp giúp đỡ người khác.
        Tầm nhìn của họ giúp họ có thể hiểu thấu người khác và sự duyên dáng ảnh
        hưởng đến sự tin tưởng của họ đối với người khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Điều quan trọng là họ cần sử dụng tốt nhất khả năng tư duy và sáng tạo
        của mình, có thể được sử dụng tốt nhất nếu họ trở thành giáo viên hay
        nhân viên xã hội. Họ có thể muốn bước thêm 1 bước xa hơn và hướng đến
        việc trở thành một nhà nghiên cứu, viết lách học thuật.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Được thừa hưởng sự can đảm và sáng tạo, họ có tài năng trong cải cách xã
        hội và sẽ là người đấu tranh vì tự do xuất sắc.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người thích sự nghiệp trong ngành kinh doanh nên cân nhắc vị trí
        môi giới, nhà ngoại giao hay trọng tài. Họ cũng có thể làm việc như một
        người quan lý tài chính của người khác.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Điều cần chú ý ở đây là họ không nên chấp nhận một công việc dưới khả
        năng của mình. Bất kể họ chọn lựa sự nghiệp gì thì tài chính luôn đóng
        vai trò quan trọng đối với họ. Nếu lợi ích tài chính không đủ lớn, họ sẽ
        đi tìm một công việc mới.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những Đặc Tính Trong Sự Nghiệp - Công Việc: khả năng lãnh đạo, sáng tạo,
        suy nghĩ thấu đáo, chấp nhận, cứng đầu, áp đặt, cái tôi, kiểm soát.{" "}
      </p>

      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Hôn Nhân - Quan Hệ - Gia Đình:{" "}
      </h4>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Ất Mão thường cảm thấy thoải mái với việc lập gia đình nhưng không
        sẵn lòng từ bỏ một người nào khỏi cuộc đời. Khi họ trong một mối quan hệ
        nghiêm túc, họ vẫn có nhu cầu đi xa và mở rộng mối quan hệ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bạn đời lãng mạn lý tưởng dành cho họ là người có năng lực và tầm nhìn
        rộng mở như họ. Lý tưởng nếu họ cũng yêu thích kiến thức và sự thông
        thái.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bất kỳ nguyên nhân nào dẫn đến chia tay trong tình cảm có thể đến từ ham
        muốn trở nên lấn át và kiểm soát.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bản chất của người Ất Mão dễ dính đến chuyện ngoại tình. Họ có thể không
        chủ động tìm kiếm cuộc tình nhưng sẽ không từ chối nếu được mời mọc.
        Ngoài ra, họ không nhìn nhận điều này như là vấn đề mà sẽ đối xử với tất
        cả mọi người như nhau.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Hôn nhân không tốt đối với nữ giới Ất Mão. Nữ Ất Mão nên tránh quan hệ
        với người Tân Dậu, vì sẽ là kết hợp tệ hại. Lựa chọn tốt nhất là kết hôn
        với người đã ly hôn hay kết hôn lần hai.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày này nỗ lực không ngừng để giao tiếp với tất cả mọi loại
        người. Sự duyên dáng, xu hướng thích giao tiếp, và bản tính điềm tĩnh có
        thể giúp họ dễ dàng được chấp nhận và hấp dẫn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thường họ sẽ thể hiện nhu cầu ở chung với những người có quyền lực. Điều
        này biểu thị người nam giới lớn tuổi, như cha, thường để lại dấu ấn về
        tầm nhìn hay hệ thống niềm tin. Trong lúc là người có tư duy độc lập,
        rất có khả ănng họ muốn theo đuổi người có những kỹ năng độc đáo.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ biết cách dành nhiều thời gian và vươn lên trong mọi hoàn cảnh xã
        hội. Giành lấy vị trí sân khấu trung tâm không khó đối với họ, vì họ có
        xu hướng thể hiện bản thân tự do trong một tập thể.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ khá giỏi giao tiếp, nhưng đừng ngạc nhiên nếu họ đôi khi muốn tự cô
        lập bản thân. Lựa chọn này có thể dẫn đến sự cô đơn. Họ nên nhắm đên
        việc tạo dựng sự cân bằng hoà hợp giữa ấm áp và một chút lạnh lùng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có thể thích tranh cãi nếu họ cảm thấy bất an. Điều này sẽ đỡ hơn nếu
        họ kiên nhẫn khi phải đối mặt với những lúc quan hệ trục trặc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Gia đình rất quan trọng đối với người Ất Mão, cũng là lý do tại sao họ
        chung thuỷ và ủng hộ người mình yêu thương. Mặc dù họ yêu gia đình, họ
        thường phải vật lộn với sự thiếu quyết đoán do nỗi lo thất vọng gây ra.
        Khi vấn đề này xảy ra, họ nên bước lùi khỏi quan hệ và nhớ rằng họ xứng
        đáng được hạnh phúc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Cũng như trong mọi mối quan hệ tình cảm, họ cố gắng vì sự bình an và
        hạnh phúc trong gia đình. Đôi khi họ thiếu mục tiêu, đặc biệt là khi họ
        cảm thấy dễ nổi nóng và cãi nhau. Người sinh ngày này cần tập kiên nhẫn
        vì sẽ giúp suôn sẻ khi quan hệ tình cảm gia đình trục trặc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Trong Tình Yêu - Quan Hệ:</b> thích quảng giao, bảo
        vệ, tin tưởng, cân nhắc, hiền hoà, khéo léo.{" "}
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:
      </h4>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trụ Ất Mão được xem là toạ Lộc và thông căn. Điều này biểu thị người Ất
        Mão sẽ có thể đạt được giúp đỡ và ủng hộ bất cứ khi nào họ cần. Bởi vì
        bản chất Âm Mộc họ có xu hướng tử tế và điều này khiến họ nổi tiếng giữa
        những bạn bè. Lá số Bát Tự của họ có thể bất lợi nếu trụ ngày này gặp
        thêm nhiều Tỷ Kiên, nhưng gặp nhiều Ấn Tinh thì lại có lợi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Ất Mão có khao khát mãnh liệt muốn chiến thắng và chinh phục. Họ
        độc lập, cá tính, sáng tạo và can đảm. Họ được thừa hưởng năng lượng vô
        hạn và tinh thần tiên phong giúp họ có thể tự vươn lên và chiến đấu. Trụ
        này mang theo dấu hiệu cho thấy họ có thể giàu có tuy nhiên không có
        nghĩa là chắc chắn thịnh vượng và nếu đi kèm với nhiều hành Mộc trong lá
        số biểu thị họ có thể yếu kém trong việc ra những quyết định tài chính.
        Mặc dù họ muốn, nhưng không phải lúc nào cũng khôn ngoan để khởi nghiệp
        trong kinh doanh tự thân và họ sẽ tốt hơn nếu có đối tác đi cùng.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Ất Mão thông minh và thường có thể phát triển kỹ năng và kiến thức
        của mình thành chuyên môn hoá. Họ đủ thực tế để biết được khi nào nên
        tận dụng ưu thế và có năng lực phán đoán giỏi. Thường họ dễ trở thành
        chuyên gia trong lĩnh vực nào đó.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Ất Mão thích các hoạt động hợp tác đem đến cho họ cơ hội tương tác
        và học hỏi từ người khác. Người Ất Mão toạ Tỷ Kiếp, biểu thị họ duyên
        dáng và khả ái, dễ kết giao với nhiều người. Họ có tài tạo ra môi trường
        thân thiện và hoà hợp và có thể yêu cầu người khác mà không tỏ ra thô lỗ
        hay tiết lộ động cơ của mình quá nhiều.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ẩn sau sự độc lập thì người Ất Mão thường tự nghi ngờ chính mình. Điều
        này đặc biệt đúng nếu có Dậu trong lá số. Họ thường lo lắng về khả năng
        ra quyết định của mình và cũng thường xuyên cần phải lo lắng về việc tỏ
        ra thẳng thắn. Có thể khôn ngoan hơn cho họ nếu học cách dựa vào trực
        giác vì mặc dù họ có linh cảm tốt, họ cũng thường đa nghi về giác quan
        thứ sáu và bỏ qua linh tính của chính mình. Sẽ có lợi cho họ nếu theo
        đuổi con đường tâm linh và có hành Hoả, hành tâm linh, trong lá số sẽ
        giúp đỡ họ trong việc này.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trụ Ất Mão toạ vị trí có lợi hơn nếu được vây quanh bởi Tài Tinh và Quan
        Tinh. Điều này biểu thị người này sẽ tham vọng và theo đuổi nhiều mục
        tiêu với quyết tâm và kiên trì. Điều này cũng gợi ý rằng người này thông
        minh và hơi gian xảo cũng như có tiềm năng để thành công lớn trong đời.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu Thực Thần hay Thương Quan hiện diện, biểu thị người này sáng tạo và
        thích khởi nghiệp. Những sao này biểu thị tiềm năng để trở thành chủ
        doanh nghiệp với ý tưởng độc đáo và kỹ năng kinh doanh khiến cho họ gặt
        hái lợi nhuận. Tuy nhiên, những sao này không nên Xung trong lá số. Nếu
        bị Xung, người Ất Mão sẽ thường xuyên lưỡng lự chần chừ trong đời.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu trụ này gặp Dậu trong lá số, sẽ ít thuận lợi. Điều này có khả năng
        tiêu cực hơn nếu trụ này Hình với Tý hay Hại với Thìn trong lá số. Điều
        này biểu thị Ất Mão sẽ vất vả để tìm được cơ hội trong đời, họ sẽ phải
        làm việc vất vả hơn người và điều này ảnh hưởng tiêu cực đến sức khoẻ.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu gặp thêm Thuỷ, Hoả và Thổ trong lá số Bát Tự, người Ất Mão sẽ có khả
        năng thịnh vượng trong đời và hưởng thành công và vận may với ít trở
        ngại. Họ không bao giờ phải vất vả để tìm cơ hội mới và sẽ thấy sự giúp
        đỡ lúc nào cũng nằm trong tầm tay khi cần.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu trụ ngày này gặp nhiều Canh và Giáp thì người này dễ gặp nhiều khó
        khăn và cãi vã. Người này đối mặt mâu thuẫn nội bộ và được khuyên nên
        tập trung bất kể những khó khăn của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh vào mùa Xuân và Hạ sẽ có thể tìm được sức mạnh tiềm ẩn ngay
        từ khi còn trẻ và sẽ làm việc với sự kiên trì lớn để đạt mục tiêu. Người
        sinh vào mùa Thu và Đông thì có sức khoẻ tốt và sống thọ. Nếu sinh vào
        tháng Tuất, đặc biệt nếu có thêm Kim ở Thiên Can thì đối mặt nhiều thách
        thức trong đời và vất vả nếu muốn thành công.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu sinh vào ban ngày thì sẽ chăm chỉ và siêng năng. Những người này nên
        mạnh mẽ và theo đuổi giấc mơ. Nếu sinh vào ban đêm thì sẽ có giỏi về tài
        chính và tạo ra lợi nhuận. Họ có khả năng kết nối kỹ năng và kiến thức
        để tạo ra tài lộc thành công. Cũng như có nhiều con cái.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ất toạ trên Mão mang theo hành duy nhất của Ất Mộc Tỷ Kiên. Khi họ ở bên
        cạnh bạn bè, sự tự tin của họ dâng cao. Người toạ Tỷ Kiên sẽ thường có
        vấn đề về mối quan hệ tình cảm dài lâu. Điều này là do họ có lối sống
        khá dễ dãi và họ trân trọng “bạn bè” hơn tất cả. Rất dễ gần gũi với họ
        nhưng khó trở nên đặc biệt trong mắt họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người bạn đời, đối với họ cũng chỉ là một trong số những đồng nghiệp ủng
        hộ và người bạn đời sẽ cảm thấy dần dần khó hiểu vì họ đối xử với tất cả
        mọi người đều cùng một mức độ thân mật như nhau.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mão hợp với Tuất mang theo Mậu Thổ Chính Tài, Tân Kim Thất Sát và Đinh
        Hoả Thực Thần. Đối với nam giới, là tốt vì có sự hiện diện của Chính Tài
        và được củng cố hơn nữa bởi Thực Thần thường là 1 sao lãng mạn. Trong
        khi lá số nữ giới thường vẫn xem Thất Sát. Sự kết hợp này biểu thị xu
        hướng dữ dội khi xét về vấn đề tình cảm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mão xung với Dậu mang theo năng lượng của Tân Kim Thất Sát. Đối với nữ
        giới điều này xua đuổi sao chủ về bạn trai. Dễ đến và dễ đi, điêu này
        thật sự là tốt cho những ai muốn biến phụ nữ thành vợ do sự từ chối bạn
        trai cho bạn sự an toàn nhưng bạn sẽ có một người bạn khá khó khăn
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Dịu dàng và đáng yêu khi họ tỏ ra dễ bị tổn thương, nhưng đôi khi điều
        này cũng có phản tác dụng. Phụ nữ có ngày sinh này thường được khuyến
        khích phòng vệ vì bạn sẽ thấy khó có thể xua đuổi được những sự theo
        đuổi không mong muốn. Đi kèm với nhu cầu được chấp nhận, có rủi ro cao
        rằng bạn sẽ bị hái như một bông hoa ở vệ đường. Mão cũng nắm giữ nguy cơ
        của hình vô lễ khi gặp Tý và dễ gây ra tai tiếng về tình dục.
      </p>

      
    </div>
  );
};
