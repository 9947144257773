import { GiapTy, GiapTuat, GiapThan, GiapNgo, GiapThin, GiapDan } from '../components/60hoagiap/Giap'
import { AtSuu, AtHoi, AtDau, AtMui, AtTy, AtMao } from '../components/60hoagiap/At'
import { BinhDan, BinhTy, BinhTuat, BinhThan, BinhNgo, BinhThin } from '../components/60hoagiap/Binh'
import { DinhMao, DinhSuu, DinhHoi, DinhDau, DinhMui, DinhTy } from '../components/60hoagiap/Dinh'
import { MauThin, MauDan, MauTy, MauTuat, MauThan, MauNgo } from '../components/60hoagiap/Mau'
import { KyTy, KyMao, KySuu, KyHoi, KyDau, KyMui } from '../components/60hoagiap/Ky'
import { CanhNgo, CanhThin, CanhDan, CanhTy, CanhTuat, CanhThan } from '../components/60hoagiap/Canh'
import { TanMui, TanTy, TanMao, TanSuu, TanHoi, TanDau } from '../components/60hoagiap/Tan'
import { NhamNgo, NhamThan, NhamThin, NhamDan, NhamTy, NhamTuat } from '../components/60hoagiap/Nham'
import { QuyDau, QuyMui, QuyTy, QuyMao, QuySuu, QuyHoi } from '../components/60hoagiap/Quy'


export const hoaGiap = (canChiDay) => {
  switch (canChiDay) {
    case "Giáp Tý":
      return GiapTy;
    case "Giáp Tuất":
      return GiapTuat;
    case "Giáp Thân":
      return GiapThan;
    case "Giáp Ngọ":
      return GiapNgo;
    case "Giáp Thìn":
      return GiapThin;
    case "Giáp Dần":
      return GiapDan;

    case "Ất Sửu":
      return AtSuu;
    case "Ất Hợi":
      return AtHoi;
    case "Ất Dậu":
      return AtDau;
    case "Ất Mùi":
      return AtMui;
    case "Ất Tỵ":
      return AtTy;
    case "Ất Mão":
      return AtMao;

    case "Bính Dần":
      return BinhDan;
    case "Bính Tý":
      return BinhTy;
    case "Bính Tuất":
      return BinhTuat;
    case "Bính Thân":
      return BinhThan;
    case "Bính Ngọ":
      return BinhNgo;
    case "Bính Thìn":
      return BinhThin;

    case "Đinh Mão":
      return DinhMao;
    case "Đinh Sửu":
      return DinhSuu;
    case "Đinh Hợi":
      return DinhHoi;
    case "Đinh Dậu":
      return DinhDau;
    case "Đinh Mùi":
      return DinhMui;
    case "Đinh Tỵ":
      return DinhTy;

    case "Mậu Thìn":
      return MauThin;
    case "Mậu Dần":
      return MauDan;
    case "Mậu Tý":
      return MauTy;
    case "Mậu Tuất":
      return MauTuat;
    case "Mậu Thân":
      return MauThan;
    case "Mậu Ngọ":
      return MauNgo;

    case "Kỷ Tỵ":
      return KyTy;
    case "Kỷ Mão":
      return KyMao;
    case "Kỷ Sửu":
      return KySuu;
    case "Kỷ Hợi":
      return KyHoi;
    case "Kỷ Dậu":
      return KyDau;
    case "Kỷ Mùi":
      return KyMui;

    case "Canh Ngọ":
      return CanhNgo;
    case "Canh Thìn":
      return CanhThin;
    case "Canh Dần":
      return CanhDan;
    case "Canh Tý":
      return CanhTy;
    case "Canh Tuất":
      return CanhTuat;
    case "Canh Thân":
      return CanhThan;

    case "Tân Mùi":
      return TanMui;
    case "Tân Tỵ":
      return TanTy;
    case "Tân Mão":
      return TanMao;
    case "Tân Sửu":
      return TanSuu;
    case "Tân Hợi":
      return TanHoi;
    case "Tân Dậu":
      return TanDau;

    case "Nhâm Thân":
      return NhamThan;
    case "Nhâm Ngọ":
      return NhamNgo;
    case "Nhâm Thìn":
      return NhamThin;
    case "Nhâm Dần":
      return NhamDan;
    case "Nhâm Tý":
      return NhamTy;
    case "Nhâm Tuất":
      return NhamTuat;

    case "Quý Dậu":
      return QuyDau;
    case "Quý Mùi":
      return QuyMui;
    case "Quý Tỵ":
      return QuyTy;
    case "Quý Mão":
      return QuyMao;
    case "Quý Sửu":
      return QuySuu;
    case "Quý Hợi":
      return QuyHoi;

    default:
      break;
  }
};







