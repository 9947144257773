import {
  AmSaiDuongThac,
  CoLoan,
  CoThanQuaTu,
  DaoHoaMocDuc,
  HamTri,
  HongDiem,
  HongLoan,
  KhongVong,
  NhatPha,
  NgocMon,
  NguyenThan,
  PhuTram,
  PhucNgam,
  ThienHy,
  ThietTao,
  BaoBai,
  CanLoc,
  KimDu,
  KimQuy,
  LocThan,
  NguPhu,
  NgocDuong,
  NguyetPha,
  DaiHao,
  QuyThuc,
  ThapAcDaiBai,
  ThienTai,
  TieuHao,
  TuePha,
  DaMuu,
  DucThan,
  HoaCai,
  HoiKhi,
  LucTu,
  KiepSat
} from "./ListThanSat/11_46ThanSat";

import {
  NguyetKhong,
  NguyetDucQuyNhan,
  NhatDuc,
  NhatQuy,
  PhucDucQuyNhan,
  TamKyQuyNhan,
  ThapLinh,
  ThienCuong,
  ThienDucQuyNhan,
  ThienDucHopQuyNhan,
  ThienKhong,
  VongThan,
} from "./ListThanSat/36-47ThanSat";

import {
  BachHo,
  BenhPhu,
  CachGiac,
  DieuKhach,
  HuyetNhan,
  KiemPhong,
  LucAch,
  LuuHa,
  PhiDao,
  PhiMa,
  PhucThi,
  TaiSat,
  TamSat,
  TangMon,
  ThaiTue,
  ThienAch,
  ThienCau,
  ThienKhoc,
  ThienSat,
  ThienY,
  TueHinh,
  TueSat,
  TuPhu,
  TucThan,
  DichMa,
  DuongNhan,
  HocDuong,
  HocSi,
  KhoiCuong,
  ThoaiThan,
  TienThan,
  TuVi,
  TuongTinh,
  VanXuong,
} from "./ListThanSat/49ThanSat";

import {
  BatToa,
  TamDai,
  ChiBoi,
  GiaiThan,
  DiaGiai,
  NguQuy,
  BanAn,
  CauGiao,
  LanCan,
  QuanPhu,
  QuanTo,
  QuyenThiet,
  LongDucQuyNhan,
  LucHopQuy,
  PhucTinhQuyNhan,
  QuyHopQuyNhan,
  ThaiAm,
  ThaiCucQuyNhan,
  ThaiDuong,
  ThienAtQuyNhan,
  ThienXa,
  QuocAnQuyNhan,
  MoKho,
  NguyetDucHop,
  MocDuc,
  DaoHoa,
  ThienLaDiaVong,
  TamDaiQuy
} from "./ListThanSat/83ThanSat";
export const ThanSatList = (title) => {
  switch (title) {
    case "Âm Sai Dương Thác":
      return AmSaiDuongThac;
    case "Cô Loan":
      return CoLoan;
    case "Cô Thần Quả Tú":
      return CoThanQuaTu;
    case "Đào Hoa Mộc Dục":
      return DaoHoaMocDuc;
    case "Đào Hoa":
      return DaoHoa;
    case "Hàm Trì":
      return HamTri;
    case "Hồng Diễm":
      return HongDiem;
    case "Hồng Loan":
      return HongLoan;
    case "Không Vong":
      return KhongVong;
    case "Nhật Phá / Phá Nhật":
      return NhatPha;
    case "Ngọc Môn":
      return NgocMon;
    case "Nguyên Thần":
      return NguyenThan;
    case "Phù Trầm":
      return PhuTram;
    case "Phục Ngâm":
      return PhucNgam;
    case "Thiên Hỷ":
      return ThienHy;
    case "Thiết Tảo":
      return ThietTao;
    case "Bạo Bại":
      return BaoBai;
    case "Can Lộc":
      return CanLoc;
    case "Kim Dư":
      return KimDu;
    case "Kim Quỹ":
      return KimQuy;
    case "Lộc Thần":
      return LocThan;
    case "Ngọc Đường":
      return NgocDuong;
    case "Ngũ Phú":
      return NguPhu;
    case "Nguyệt Phá":
      return NguyetPha;
    case "Phá Toái / Đại Hao":
      return DaiHao;
    case "Quý Thực":
      return QuyThuc;
    case "Thập Ác Đại Bại":
      return ThapAcDaiBai;
    case "Thiên Tài":
      return ThienTai;
    case "Tiểu Hao":
      return TieuHao;
    case "Tuế Phá":
      return TuePha;
    case "Chiến lược gia (Đa Mưu)":
      return DaMuu;
    case "Đức Thần":
      return DucThan;
    case "Hoa Cái":
      return HoaCai;
    case "Hối Khí":
      return HoiKhi;
    case "Lục Tú":
      return LucTu;
    case "Nguyệt Không":
      return NguyetKhong;
    case "Nhật Đức":
      return NhatDuc;
    case "Nhật Quý":
      return NhatQuy;
    case "Nguyệt Đức Quý Nhân":
      return NguyetDucQuyNhan;
    case "Phúc Đức":
      return PhucDucQuyNhan;
    case "Tam Kỳ Quý Nhân":
      return TamKyQuyNhan;
    case "Thập Linh":
      return ThapLinh;
    case "Thiên Cương":
      return ThienCuong;
    case "Tam Đài Quý":
      return TamDaiQuy;
    case "Thiên Đức Quý Nhân":
      return ThienDucQuyNhan;
    case "Thiên Đức Hợp":
      return ThienDucHopQuyNhan;
    case "Thiên Không":
      return ThienKhong;
    case "Vong Thần":
      return VongThan;
    case "Bạch Hổ":
      return BachHo;
    case "Bệnh Phù":
      return BenhPhu;
    case "Cách Giác":
      return CachGiac;
    case "Điếu Khách":
      return DieuKhach;
    case "Huyết Nhẫn / Huyết Đao":
      return HuyetNhan;
    case "Kiếm Phong":
      return KiemPhong;
    case "Kiếp Sát":
      return KiepSat;
    case "Lục Ách":
      return LucAch;
    case "Lưu Hạ / Lưu Hà":
      return LuuHa;
    case "Phi Đao/ Phi Nhẫn/ Phi Nhận":
      return PhiDao;
    case "Phi Ma":
      return PhiMa;
    case "Phục Thi":
      return PhucThi;
    case "Tai Sát":
      return TaiSat;
    case "Tam Sát":
      return TamSat;
    case "Tang Môn":
      return TangMon;
    case "Thái Tuế":
      return ThaiTue;
    case "Thiên Ách":
      return ThienAch;
    case "Thiên Cẩu":
      return ThienCau;
    case "Thiên Khốc":
      return ThienKhoc;
    case "Thiên Sát":
      return ThienSat;
    case "Thiên Y":
      return ThienY;
    case "Tuế Hình":
      return TueHinh;
    case "Tuế Sát":
      return TueSat;
    case "Tử Phù":
      return TuPhu;
    case "Tức Thần":
      return TucThan;
    case "Dịch Mã":
      return DichMa;
    case "Dương Nhẫn":
      return DuongNhan;
    case "Học Đường":
      return HocDuong;
    case "Học Sĩ":
      return HocSi;
    case "Khôi Cương":
      return KhoiCuong;
    case "Thoái Thần":
      return ThoaiThan;
    case "Tiến Thần":
      return TienThan;
    case "Tử Vi":
      return TuVi;
    case "Tướng Tinh":
      return TuongTinh;
    case "Văn Xương":
      return VanXuong;
    case "Bát Tọa":
      return BatToa;
    case "Tam Đài":
      return TamDai;
    case "Chỉ Bối":
      return ChiBoi;
    case "Địa Giải":
      return DiaGiai;
    case "Giải Thần / Thiên Giải":
      return GiaiThan;
    case "Ngũ Quỷ":
      return NguQuy;
    case "Bản An":
      return BanAn;
    case "Câu Giảo":
      return CauGiao;
    case "Lan Can":
      return LanCan;
    case "Quan Phù":
      return QuanPhu;
    case "Quán Tố":
      return QuanTo;
    case "Quyển Thiệt":
      return QuyenThiet;
    case "Long Đức / Long Đức Quý Nhân":
      return LongDucQuyNhan;
    case "Lục Hợp Quý":
      return LucHopQuy;
    case "Phúc Tinh Quý Nhân":
      return PhucTinhQuyNhan;
    case "Quý Hợp Quý Nhân":
      return QuyHopQuyNhan;
    case "Thái Dương":
      return ThaiDuong;
    case "Thái Âm":
      return ThaiAm;
    case "Thái Cực Quý Nhân":
      return ThaiCucQuyNhan;
    case "Thiên Ất Quý Nhân":
      return ThienAtQuyNhan;
    case "Thiên Xá / Thiên Thứ":
      return ThienXa;
    case "Quốc Ấn Quý Nhân":
      return QuocAnQuyNhan;
    case "Mộ Khố":
      return MoKho;
    case "Mộc Dục":
      return MocDuc;
    case "Nguyệt Đức Hợp":
      return NguyetDucHop;
    case "Thiên La Địa Võng":
      return ThienLaDiaVong;

    default:
      break;
  }
};
