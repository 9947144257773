export const TanKim = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "70%", margin: "0 auto" }}>
        <h1
          style={{ textAlign: "center", color: "#FFD700", fontWeight: "bold" }}
        >
          Chương VII: Tân Kim <br /> Ngày Sinh Tân Kim
        </h1>
      </div>

      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Dụng Thần Điều Hậu</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b>• Sinh Vào Mùa Xuân</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Dần: 05/02 – 05/03
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Kỷ Thổ, Canh Kim, Nhâm Thủy
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Mão: 06/03 – 04/04
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Nhâm Thủy, Giáp Mộc
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Thìn: 05/04 – 05/05
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Nhâm Thủy, Giáp Mộc
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b>• Sinh Vào Mùa Hạ</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Tỵ: 06/05 – 05/06
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Quý Thủy, Nhâm Thủy, Giáp Mộc
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Ngọ: 06/06 – 07/07
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Nhâm Thủy, Quý Thủy, Kỷ Thổ
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Mùi: 08/07 – 07/08
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Nhâm Thủy, Giáp Mộc, Canh Kim
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b>• Sinh Vào Mùa Thu</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Thân: 08/08 – 07/09
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Nhâm Thủy, Giáp Mộc, Mậu Thổ
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Dậu: 08/09 – 08/10
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Nhâm Thủy, Giáp Mộc
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Tuất: 09/10 – 07/11
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Nhâm Thủy, Giáp Mộc
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b>• Sinh Vào Mùa Đông</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Hợi: 08/11 – 07/12
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Bính Hỏa, Nhâm Thủy
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Tý: 08/12 – 05/01
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Đinh Hỏa, Nhâm Thủy, Giáp Mộc, Mậu Thổ
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Sửu: 06/01 – 03/02
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Bính Hỏa, Nhâm Thủy, Mậu Thổ, Kỷ Thổ
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Lưu ý: Các bạn cần lưu ý những dụng thần điều hậu phía trên chỉ mang
        tính chất tham khảo. Nếu cần biết thêm về các dụng thần điều hậu, cũng
        như các dụng thần khác trong lá số bát tự bạn cần tham khảo hoặc tư vấn
        từ các chuyên gia.
      </p>

      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Hình Tượng</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Thanh Lịch, lấp lánh và sáng bóng
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tân Kim, là Âm Kim, hiện thân của những thứ thanh nhã – trang sức lấp
        lánh, bạc sáng bóng và vàng rực rỡ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tân Kim thích sống cuộc sống đẹp đẽ. Họ thích lối sống hào nhoáng và
        tinh tế. Một cuộc sống đáng chú ý với sự giàu có tráng lệ, sự tiến triển
        và thanh lịch là những gì mà Tân Kim hướng đến. Họ thích sự sung sướng
        của cuộc sống và ghét đau đớn và sự đau khổ. Họ trân trọng nghệ thuật
        hoàn mỹ, thưởng thức những món ngon và rượu vang. Họ bị kích thích bởi
        thơ ca, triết học và những câu chuyện trí thức.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Tân Kim thừa hưởng những điều tốt đẹp trong thế giới này và nghĩ
        rằng họ tốt hơn bất cứ ai. Thật ra, điều này có thể được khẳng định mạnh
        mẽ. Tuy nhiên, họ vốn có vấn đề lớn khiến họ nghĩ rằng họ xứng đáng có
        được những điều tốt nhất và vì thế nên có được điều tốt nhất. Họ thích
        tận hưởng những điều tốt đẹp hơn trong cuộc sống và ngắm nhìn những thứ
        đẹp đẽ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ có khẩu vị của giai cấp tư sản và hầu hết thời gian, họ muốn sống
        cuộc sống của “điều ước về sâm panh và giấc mơ về trứng cá muối”. Người
        Tân Kim vốn rất tỉ mỉ và phấn đầu có được cuộc sống chất lượng cao. Họ
        thường chọn bạn và sự giao thiệp rất kỹ càng. Họ thường thích kết giao
        với người có cùng tư tưởng, nếu không thì phải giống về địa vị hoặc giỏi
        hơn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Tân Kim không thích gì hơn ngoài việc được để ý đến. Họ thích sự
        chú ý và thường đủ sáng tạo và tự tin để tìm kiếm và làm chủ nó! Họ
        thích khoe 1 phần sự phù phiếm, dù đó là trí thức của họ hay của cải của
        họ. Người Tân Kim mạnh là những viên kim cương thật sự.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Điều này có nghĩa là người Tân Kim vượng thường có những kĩ năng, tài
        năng, trí tuệ hoặc tài năng ăn nói khéo léo cùng hợp sự táo bạo phô
        trương của họ. Tuy đôi khi họ có thể thành công là nhờ sự khoe khoang
        khoác lác, người Tân Kim đánh giá cao việc kích thích về trí óc và
        thường có những quan điểm thú vị và độc đáo khiến người khác bị thu hút.
        Theo cách nói khác, Tân Kim tốt có bản chất hợp với sự toả sáng hấp dẫn
        của họ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Cách nhanh nhất để được Tân Kim yêu mến là nịnh bợ họ. Sự tâng bốc đó
        hiển nhiên sẽ giúp bạn thành công! Nhưng hãy nhớ rằng họ không phải
        người ngu đần nên những ai hi vọng tạo dựng nên cuộc nói chuyện ngọt
        ngào chỉ đơn thuần để đạt được lợi lộc cho riêng bản thân sẽ nhanh chóng
        bị “sửa lưng” bởi một người Tân Kim nóng giận.
      </p>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Tính Cách</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Tân Kim thường là người hà khắc và tàn nhẫn, có thể khiến người
        khác thấy sợ vì họ lên giọng – LỚN TIẾNG – khi họ giận dữ. Tuy nhiên, đó
        là tình huống điển hình họ cho thấy được bề ngoài đáng sợ nhưng thực
        chất bên trong thì không hề như vậy. Họ nhìn có vẻ đáng sợ nhưng cũng
        rất niềm nở và tốt bụng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ở bên trong, người Tân Kim rất đa cảm và vô cùng trân trọng những mối
        quan hệ của họ. Khi họ gắn bó với ai đó, họ có thể quên mình, chu đáo và
        rất ân cần. Nó là cách mà họ tìm kiếm sự quan tâm và khuynh hướng cố ý
        ban đầu có thể khiến họ bị ghét.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Sẽ không khó để thuyết phục Tân Kim nghe theo quan điểm của bạn. Sự cứng
        rắn có thể không giúp cho những ngày sinh khác thành công, nhưng nó lại
        hiệu quả với người Tân Kim. Khi không còn sự lựa chọn nào khác, họ sẽ
        thường dịu lại và nhượng bộ. Sự cứng rắn của họ không phải là để hạ bệ
        người khác.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Những người khác sẽ bắt đầu ở A và đi qua B để đến được điểm C. Tân Kim
        thì tìm đường từ A đến C mà không gặp trở ngại gì!
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lối suy nghĩ của Tân Kim nói ngắn gọn – là duy nhất và độc đáo. Để tôi
        nói rõ hơn. Họ khai phá ra lối tư duy khác biệt và trong khi những người
        khác vẫn còn đang cố gắng chạy theo cách làm của họ thì họ đã nghĩ sang
        một vấn đề khác! Người Tân Kim luôn luôn có thể được tin tưởng giao cho
        việc suy nghĩ ra những ý tưởng khác lạ. Họ sáng tạo một cách tự nhiên,
        kể cả trong kinh doanh và trong cuộc sống.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ không nhìn thế giới theo những cách giống nhau như những người khác
        và suy nghĩ của họ luôn luôn được thúc đẩy bằng hành động. Họ cố gắng để
        phát triển thêm về tri thức. Họ có thể cực kì sáng tạo và giàu trí tưởng
        tượng. Họ sẽ không bao giờ cho bạn những câu trả lời cũ rích như nhau
        như những người khác hay làm. Bạn có thể đảm bảo là một người Tân Kim sẽ
        luôn luôn khiến bản thân tốt hơn và sẽ cho bạn cách giải quyết xuất sắc!
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tuy nhiên, lúc nào cũng sẽ có mặt tiêu cực cho việc quá sáng tạo. Họ có
        thể trở nên thất thường và gắt gỏng khi mọi chuyện không theo ý họ.
        Thường là họ sẽ thay đổi tâm trạng đột ngột.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Những điều tốt đẹp trong cuộc sống không đến một cách dễ dàng và người
        Tân Kim hiểu rõ điều này. Tuy vậy, mặc cho điều đó, họ thèm muốn tất cả
        những gì họ không thể có được vì nó tượng trung cho những gì mà họ đại
        diện: sự thanh lịch, sự tinh tế và sắc đẹp. Do đó, họ có thể đôi khi sẽ
        tham lam một chút.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Điều này ảnh hưởng đến suy nghĩ của họ nếu họ có ý chí yếu. Tân Kim
        vượng và thông căn sẽ biết điều gì trong tầm với, điều gì không và sẽ
        dựa vào đó để lên kế hoạch. Nhưng đôi khi họ có thể để sự tham lam ăn
        sâu vào suy nghĩ và khả năng trí tuệ, và vì thế hi sinh nguyên tắc của
        riêng họ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Do đó, họ thi thoảng phải cưỡng lại sự cám dỗ về vật chất và lòng tham.
        Người khác có thể nghĩ họ hoàn toàn ích kỷ vì lí do này. Tuy nhiên, nếu
        học có thể kiểm soát được điều này, họ có thể khai thác nó như là điểm
        mạnh cá nhân – trở thành người dám nghĩ dám làm thay vì là người đào mỏ!
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Quan niệm của người Châu Á là giữ thể diện’ hoặc giữ hình tượng tốt đẹp
        là tính cách điển hình của Tân Kim. Điều này đóng vai trò rất lớn trong
        việc họ suy nghĩ và tiếp cận thế giới như thế nào. Vì trọng tâm của họ
        là gìn giữ và duy trì hình ảnh bản thân tốt đẹp, họ có thể đôi khi khá
        nóng tính.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Thông thường, họ đủ khôn khéo để biết đâu là việc nên làm và đâu là việc
        phải làm. Nhưng họ thường không đưa ra quyết định đúng đắn vì khi họ quá
        chú trọng vào việc giữ gìn hình tượng tốt đẹp, mọi sự hợp lý thường sẽ
        bay đi đâu mất và họ sẽ dựa vào bản năng để đưa ra quyết định hoặc đánh
        giá.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ví dụ như, nếu một người mà họ cực kì không thích đưa ra đề nghị - và
        thậm chí nếu đó là một lời đề nghị rất tốt – người Tân Kim cũng sẽ không
        thừa nhận điều đó. Họ thường sẽ chống đối quyết định đó, mặc dù trong
        thâm tâm họ biết rõ điều đó là đúng nhưng họ thà đưa ra quyết định sai
        còn hơn là bị mất mặt trước kẻ thù! Thường thì nếu Tân Kim để cái khuynh
        hướng này lấn át tâm trí của họ, họ sẽ có khả năng bị lạc lối. Ý thức về
        lòng tự trọng và cái tôi của Tân Kim mạnh nhất trong số 10 Thiên Can. Họ
        muốn là người lúc nào cũng đúng nên họ thường mất bạn và thường làm
        người khác tức điên lên vì điều đó.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu không nói về vẻ bề ngoài thì sẽ bàn đến trí óc. sự tự tin của người
        Tân Kim cũng bắt nguồn từ khả năng thể hiện và bày tỏ bản thân họ một
        cách rõ ràng. Những người Tân có cơ thể không cường tráng hay quá đẹp
        thì sẽ bù đắp cho điểm yếu đó bằng kiến thức, trí khôn và những kĩ năng
        đặc biệt (và họ thường bù đắp điều đó rất tốt!).
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Hầu như, tính cách của một người can ngày Tân Kim sẽ muốn phát triển tài
        năng, kĩ năng đặc biệt và khả năng của họ, và họ sẽ muốn chia sẻ điều đó
        cho cả thế giới. Vì họ là những người thích tìm kiếm sự chú ý quan tâm,
        họ muốn được tôn trọng và ngưỡng mộ cho những gì họ có thể làm chứ không
        phải vì vẻ ngoài của họ như thế nào.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nhìn tổng thể, người Tân Kim rất hay tò mò và thích học hỏi. Những người
        kém lịch sự thường hay nói họ người bao đồng! Nhưng người Tân Kim đơn
        thuần được tiếp năng lượng bằng sự khao khát của họ để biết hơn về thế
        giới và những người xung quanh.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ hay học hỏi, đọc sach và làm giàu kiến thức. Người Tân Kim rất giỏi
        về việc sưu tập ‘kiến thức chung’. Tân Kim luôn là người được tìm đến để
        hỏi về gợi ý cho những nhà hàng tốt nhất, loại rượu vang ngon nhất, cửa
        hàng tốt nhất bán phô mai cho những người sàng ăn và nhiều cái khác nữa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Trong mọi cuộc trò chuyện, không có chủ đề nào mà người Tân Kim không
        biết. Họ dường như biết rất nhiều thứ. Thông thường, họ có thể khiến một
        số người bạn ấn tượng bởi sự biết tất tần tật của họ.Và điểm này có thể
        đôi khi làm người khác thấy khó chịu và xa lánh.
      </p>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Mối Quan Hệ/ Tình Cảm</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Mặc cho khả năng tư duy của họ thường rất tốt, người Tân Kim thỉnh
        thoảng gặp khó khăn trong việc tách rời cảm xúc và lý trí. Không vì bất
        cứ chuyện gì, cảm xúc và cảm giác của họ sẽ hiện lên trong lúc họ đang
        suy nghĩ, che mờ lý trí.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi lòng tự tôn của họ bị suy sụp hoặc khi họ giận dữ, ghen tị và lo
        lắng (và ngày sinh Kim thường dễ lo lắng), họ thường đưa ra quyết định
        và đánh giá theo kiểu ngẫu nhiên. Họ thường hấp tấp và bốc đồng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ có trực giác tốt và ‘cảm giác’ rất nhạy về những tình huống và mọi
        người xung quanh họ. Đôi khi, khi họ bị điều khiển bởi cảm xúc và họ
        không thể tiến hành sự việc một cách logic, họ sẽ nhờ bản năng chỉ dẫn –
        mà thường là rất chính xác.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nói đến lời khen ngợi và ngưỡng mộ, người Tân Kim có thể không bao giờ
        thấy đủ! Sự tự tin của họ xuất phát từ việc được người khác ngưỡng mộ.
        Nếu họ ở trong vị trí mà họ thường nhận được nhiều lời khen hoặc nịnh
        hót, sự tự tin của họ thường sẽ rất vững bền.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ cần phải ở gần những người có thể nuôi dưỡng tâm hồn bằng những phản
        hồi tích cực về bản thân họ. Điều này sẽ giúp họ nhận thức về bản thân
        là không thể lay chuyển được. Nếu họ thể hiện tốt trong công việc và sự
        nghiệp, hoặc trong lĩnh vực tài năng mà họ chọn và nhận được sự công
        nhận, họ sẽ cảm thấy không gì có thể hạ gục được họ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Cũng giống vậy, họ không thích bị làm nhục. Người Tân Kim thường ghi nhớ
        những người đã chỉ trích và làm họ bẽ mặt. Mặc cho họ không thể hiện nó
        ra ngoài, kiểu chỉ trích nào cũng thật sự khiến họ thấy tổn thương sâu
        sắc. Đôi khi, những lời chỉ trích nặng nề có thể khiến người Tân Kim yếu
        ớt bắt đầu nghi ngờ về bản thân một cách rất nghiêm trọng và điều đó sẽ
        phá huỷ sự tự tin của họ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khía cạnh to lớn trong sự tự tin người Tân Kim gắn với hình tượng và
        thường nó sẽ được xây dựng theo từng phần trong cách người đó trông như
        thế nào. Không quan tâm là người đó có gương mặt đẹp hay không, nhưng
        hơn hết là hình dáng của họ. Họ sẽ thấy khá hơn khi tràn trề năng lượng
        và cơ thể khoẻ mạnh. Hoặc ít nhất, họ thấy vui với bộ quần áo hoặc phụ
        kiện mà họ đang mặc trên người. Hãy nhớ là, người Tân Kim rất biết cách
        ăn mặc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Tân Kim rất tự phụ. Họ luôn muốn mình thật xinh đẹp!
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Bên cạnh ngoại hình của họ, ‘vỏ bọc’ thương hiệu và danh tiếng cá nhân
        cũng rất quan trọng với Tân Kim. Nó cho thấy Tân Kim là người hiểu rõ
        giá trị bản thân và sẽ làm việc chăm chỉ để họ đạt được vị trí tốt nhất
        mà họ mong muốn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nhưng thường thì khuynh hướng muốn cho người khác thấy được tài năng của
        Tân Kim cũng khá dễ thương. Cũng giống như cách mà đứa bé nói “con, con,
        con!”, bạn tốt của người Tân Kim sẽ biết cách khoan dung cho sự phô
        trường hoặc chiếm hết hào quang của họ, và sẽ hiểu được lí do đằng sau
        những hành động đó của người Tân Kim.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khác với hình tượng can đảm và dữ dằn, người Tân Kim thường có tâm hồn
        rất hiền dịu và hay đa cảm. Họ trân trọng tình bạn và dành cho họ tình
        cảm rất đặc biệt. Họ thường biểu lộ con người khác với bạn thân của họ -
        đó là sự dịu dàng, dễ gần và tốt bụng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Tân Kim rất thông suốt trong việc tìm nhiều cách khác nhau để đạt
        được mục đích cuối cùng. Do đó, họ sẽ thường kết nối với những mối quan
        hệ mà họ thấy được lợi ích cho mục đích nhất định. Đôi khi, điều này có
        thể thành công khi có sự tính toán và thao túng trong đó, nhất là đối
        với mối quan hệ làm ăn.
      </p>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Sự Nghiệp/ Công Việc</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Tân Kim thường được đánh giá cao cho những việc họ có thể làm sẽ
        tiếp thêm động lực để họ làm tốt hơn và đạt được nhiều thành tựu cao
        hơn. Họ vốn rất thông minh nhưng tính khoe khoang không để họ che giấu
        điều đó. Đối với họ, ý thức về giá trị xuất phát từ khả năng phô diễn
        tài năng và khả năng cho người khác thấy.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Vì vậy, họ hầu như là những người hướng ngoại cảm thấy tự tin về bản
        thân khi cho thế giới thấy được tài năng của họ. Càng nhiều người biết
        được thì càng tốt! Viết văn, hát hò, vẽ vời hoặc nhảy nhót – hoặc là
        thiên tài về toán học, những gì họ có thể làm, miễn là người khác thấy
        được tài năng chính là chìa khoá dẫn đến sự tự tin của Tân Kim. Họ không
        phải người thích làm trong thầm lặng cho những điều kiện của bản thân.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Mặc dù người Tân Kim thường có nhiều ý tưởng và bình luận, họ ít khi có
        can đảm để làm được hết TẤT CẢ những gì mà họ nói đến. Thông thường,
        người khác có thể nghĩ rằng người Tân Kim rất giỏi trong việc khoe
        khoang.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Trong số hầu hết những người Tân Kim, điểm yếu chung thường là sự bất
        lực để thực hiện những việc đã lên kế hoạch để làm. Sự quyết tâm của họ
        không được mạnh như lời nói! Họ giỏi nói chứ không giỏi làm lắm.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tại vì Tân Kim là Âm Kim mỏng manh, người khác có thể dựa vào họ để có
        được những suy nghĩ và ý tưởng sắc bén. Nhưng người đó không nên trông
        cậy vào họ nếu muốn công việc đó được hoàn thành. Họ thường rất dễ bị
        phân vân khi có qua nhiều sự lựa chọn ‘quá tốt’ để chọn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi nói đến hoàn cảnh công việc, mặc dù Tân Kim có năng lực và đạo đức
        công việc, họ hiếm khi có thể chịu được nhiều áp lực. Điều này là do bản
        chất Âm Kim của họ. Giống như trang sức lấp lánh, người Tân Kim có giới
        hạn chịu đựng và không thể bị đối xử thô lỗ hoặc bị lệ thuộc vì quá căng
        thẳng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ đôi khi có thể thiếu sự quyết tâm để nhìn xuyên thấu vào vấn đề một
        mình. Khi mọi việc được tiến hành một cách trơn tru, sau đó họ sẽ có thể
        hoàn thành nhiệm vụ một cách đáng ngưỡng mộ và đầy hiệu quả. Nhưng khi
        có bất kì sự hỗn độn và rối loạn xảy ra và họ bị buộc phải giải quyết,
        họ thiếu sức bật để có thể phản kháng lại một cách dễ dàng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Tân Kim không phải là kiểu người làm những việc khó khăn một mình.
        Họ không chiến đấu một mình. Một số trường hợp hay môi trường xung quanh
        khó khăn và những tình huống cực kì căng thẳng có thể khiên người Tân
        Kim thể hiện ra những khả năng yếu kém. Người Tân Kim cần cảm giác rằng
        họ làm chủ tình hình và có thể thể hiện được năng lực tốt nhất của mình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Để Tân Kim nổi trội trong môi trường áp lực, cần phải có một đội ngũ có
        kĩ năng điêu luyện và tài năng đặc biệt. Tân Kim có thể trở thành những
        lãnh đạo giỏi khi biết cách tận dụng điểm mạnh của những thành viên
        khác. Chỉ cần dựa điểm mạnh từ người ủng hộ hoặc đội nhóm của họ thì họ
        có thể làm tốt trong môi trường tập thể căng thẳng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Hầu hết người Tân Kim, dù cho họ là người chỉ đạo hoặc quản lí ở chỗ
        làm, đều có cách tuyệt vời để không phải làm mọi việc một mình họ! Họ có
        cách để trở thành ‘sếp’ và bắt người khác tuân theo mệnh lệnh và làm mọi
        thứ giống như là họ đang giúp đỡ những người đó.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Cách làm này rất phù hợp với người Tân Kim, vì nó sẽ cho họ thời gian để
        tập trung vào những gì họ cần làm. Người Tân Kim mạnh mẽ thường là những
        người của ‘những ý tưởng’, dẫn dắt và điều hành nhóm thay vì tự tay mình
        làm. Nhưng điều này dựa trên sức mạnh của Tân Kim. Người Tân Kim rảnh
        rỗi hoặc yếu ớt sẽ chỉ giao phó công việc cho người khác… và dành thời
        gian còn lại để cập nhật tin tức trên Facebook!
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đôi khi, họ có thể khiến đồng nghiệp thấy khó chịu vì sự huênh hoang và
        hách dịch của họ. Họ không biết được là đôi khi họ cũng nên nhúng tay
        vào những công việc khó khăn – mặc cho họ có thích nó hay không! Do đó,
        người khác đôi khi không sẵn lòng tuân theo mệnh lệnh của Tân Kim.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu là người đứng đầu hoặc quản lí, người Tân Kim là người biết giữ
        chừng mực. Họ không phải là người đi chơi với cấp dưới như là tán gẫu
        giữa giờ ở phòng ăn, đùa giỡn và ngồi uống cà phê cùng. Sẽ không có
        chuyện tụ tập sau giờ làm việc ở quán bar với lãnh đạo Tân Kim. Điều này
        là do rất nhiều người Tân Kim thận trọng một cách khôn ngoan. Họ thường
        bị thu hút bởi những người có địa vị cao hơn hoặc bằng họ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ thích có được cảm giác của quyền lực và có đường ranh giới rõ ràng
        phân chia họ (‘Lãnh đạo’) và cấp dưới (‘Không phải người đứng đầu’).
        Thông thường, họ thích thể hiện vẻ ngoài khó gần khiến người khác hiểu
        nhầm là chảnh hoặc thiếu thân thiện.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Sự lãnh đạo không phải là một sự lựa chọn hoặc là địa vị được chia sẻ.
        Khi một người Tân Kim là người chỉ đạo, họ sẽ là một người lãnh đạo thực
        thụ. Họ sẽ thường muốn cấp dưới nghe lời họ và làm đúng theo những gì họ
        bảo. Nếu họ mắc lỗi, một người lãnh đạo Tân Kim nhược có thể đánh lừa
        cấp dưới của họ và phủi sạch chuyện đó hoàn toàn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Kiểu lãnh đạo của người Tân Kim dựa vào khả năng giao tiếp với người
        khác và truyền đạt yêu cầu của dự án đến với đội nhóm của mình. Họ căn
        cứ vào sự lập luận chặt chẽ, sự việc và số liệu. Người Tân Kim có sự tôn
        trọng rất lớn đối với tính chân thật của sự việc. Họ lưu giữ số lượng
        lớn dữ kiện và kiến thức cho mình họ, những gì mà họ đã thu thập thông
        qua thống kê và nghiên cứu của họ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ có được sự tôn trọng là nhờ khả năng thuyết phục người khác bằng sự
        logic và lập luận. Họ không chỉ làm mọi việc dựa trên uy tín. Họ có thể
        đưa ra những lí do tại sao điều đó phải được hoàn thành và tại sao nó
        cần phải được hoàn thành bằng một cách nhất định nào đó. Người Tân Kim
        thường rất tỉ mỉ. Họ hầu như sẽ luôn làm chuẩn bị cẩn thận và nghiên cứu
        để đảm bảo rằng những gì họ làm phải thật hoàn hảo – hoặc gần như hoàn
        hảo! Đó là lí do tại sao họ luôn chiếm được sự tôn trọng từ cấp dưới của
        họ. Họ hầu như luôn ở trong trạng thái chuẩn bị hết mọi thứ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đôi khi, họ có thể gặp khó khăn để hiểu những lý thuyết hoặc ý tưởng mà
        khác với tầm nhìn của họ. Tuy nhiên, nếu họ thấy được tầm quan trọng
        hoặc sự phù hợp của ý tưởng của ai đó mà họ tôn trọng hoặc quan tâm đến,
        ý tưởng đó sẽ trở thành sự thật. Khi Tân Kim ủng hộ cho ý tưởng nào đó,
        họ sẽ không ngần ngại để đảm bảo rằng họ đang thực hiện nghĩa vụ giúp đỡ
        khi có ai cần sự giúp đỡ từ họ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tại nơi làm việc, nếu mối quan hệ của họ với đồng nghiệp không có kết
        quả tốt đẹp hoặc không có tiến triển gì tốt thì họ cũng không quá lo
        lắng về điều đó. Do đó, họ sẽ xem xét xem giá trị của người đó đối với
        họ như thế nào trước khi họ quyết định đầu tư thời gian và sự cố gắng
        cho mối quan hệ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu đồng nghiệp, sếp hoặc cấp trên quan trọng với công việc của họ và có
        những mối quan hệ trực tiếp hoặc có liên quan thì họ sẽ làm việc chăm
        chỉ để xây dựng mối quan hệ này. Với khả năng nói chuyện trơn tru, họ
        thường thông thạo trong việc thu thập thông tin từ người khác và tận
        dụng nó cho lợi ích của mình!
      </p>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Kinh Doanh</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu người Tân Kim bắt đầu kinh doanh, họ thích khởi đầu bằng việc làm
        những việc của riêng họ. Mặc dù họ có thể trội hơn trong những lĩnh vực
        chuyên ngành vì bản chất tỉ mỉ, theo chủ nghĩa hoàn hảo của họ nên niềm
        vui của họ sẽ xuất phát từ việc kinh doanh mà họ có thể thoải mái thể
        hiện những kỹ năng và ý tưởng sáng tạo. Họ có tư duy và ý tưởng đặc
        biệt, độc nhất và riêng biệt, và họ thích liều lĩnh kinh doanh của họ
        dựa trên điều đó. Bất kì công việc nào giới hạn việc thoải mái trình bày
        những ý tưởng đó sẽ không giúp Tân Kim phát huy một cách tối ưu được.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Chủ doanh nghiệp Tân Kim mạnh mẽ sẽ chọn hướng kinh doanh để phát triển
        đam mê của họ. Tất nhiên là công việc đó bắt buộc phải thoả mãn được
        khát vọng từ đầu của họ là kiếm được nhiều tiền và có được cuộc sống
        chất lượng cao. Tân Kim làm rất tốt trong việc xây dựng thương hiệu,
        buôn bán sản phẩm, định vị và PR thương hiệu trong kinh doanh.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tuy nhiên, người Tân Kim thường tin vào các quy luật và truyền thống của
        đa số các phần và mong những người khác cũng sẽ như vậy. Họ sẽ không hài
        lòng với việc phá luật và chống đối cái luật lệ. Nếu họ có thể tìm thấy
        được lí do hợp lí để thoát ra khỏi chế độ đã được thiết lập để làm những
        việc khác thì Tân mới ủng hộ cho nỗ lực đó của họ. Người Tân Kim cũng
        cho rằng mọi việc nên được thực hiện theo thủ tục và kế hoạch. Nếu người
        Tân Kim nhược chưa phát triển về mặt sáng tạo và trực giác đầy đủ, họ có
        thể bị ám ảnh quá mức về khuôn khổ và sẽ làm mọi việc “theo sách vở”.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Mặc dù họ thích phát triển và chú trọng vào ý tưởng của riêng họ, thay
        vì mua nhượng quyền thương hiệu, người Tân Kim không phải là người thích
        kinh doanh một mình. Họ sẽ thấy tốt hơn nếu họ khởi nghiệp như là một
        người cộng tác hoặc làm việc theo đội ngũ. Đối với họ, đó là hướng đi an
        toàn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Điều này phần lớn dựa trên sự thông minh của họ vì họ muốn khai thác hết
        tài năng của những người tài giỏi nhất. Họ thích là người đóng góp những
        ý tưởng hơn là người thực hiện ý tưởng đó. Nhưng chủ yếu cũng là vì họ
        biết họ không thể tránh những công việc đó! Họ hiển nhiên biết rằng họ
        muốn có thời gian để tận hưởng cuộc sống hơn là tự mình làm hết những
        công việc đó. Sẽ có lợi cho người Tân Kim nếu có những người xung quanh
        phụ họ làm những việc đó.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Mặc dù vậy, họ vẫn thích ngồi ở vị trí lãnh đạo trong công ty. Họ sẽ
        không phủ nhận rằng họ muốn chỉ đạo và quản lí. Đôi khi, mặc dù họ đã có
        người đồng hành có cả sự kiên định và trách nhiệm, họ vẫn sẽ bắt áp đặt
        quyền hành và suy nghĩ của riêng họ cho người đó.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Thậm chí trong những mối quan hệ của họ, người Tân Kim có nhận thức rất
        cao về hình ảnh và cách mà đồng đội nhìn nhận họ như thế nào. Họ thường
        phát triển mối quan hệ và tình bạn dựa trên sự ngưỡng mộ từ những người
        khác. Đôi khi, điều đó sẽ hơi khó có được.
      </p>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Tài Lộc</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Tân Kim thường rất cẩn trọng với tiền bạc. Họ thường không tiêu
        xài một cách phung phí và vốn hay quan trọng hoá khi nhắc đến việc đầu
        tư. Họ sẽ không tham gia vào những cuộc đầu tư ngẫu nhiên mang lại lợi
        nhuận nhất thời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ phân tích, nghiên cứu, dò xét lựa chọn một cách kĩ càng trước khi
        tham gia vào cơ hội đầu cơ nào để kiếm tiền. Đôi khi, điều này sẽ khiến
        họ bỏ lỡ nhiều cơ hội tốt vì họ suy nghĩ quá lâu về việc đó.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Thận trọng là tốt, nhưng đưa ra quyết định quá lâu cũng là một vấn đề
        khác.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ thường không kiên định khi đưa ra quyết định đầu tư quan trọng. Đối
        với nhà đầu tư Tân Kim, dường như tất cả dữ kiện và số liệu đều không
        bao giờ là đủ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi đề cập đến vấn đề về tiền bạc và tài chính, người Tân Kim cũng sẽ là
        nạn nhân về vấn đề hình ảnh bản thân. Đôi khi họ thường thổi phồng những
        câu chuyện đầu tư trước mặt bạn bè.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu họ đã công khai hết kế hoạch của họ rồi thì sẽ khó cho họ để nhận là
        họ sai – cũng là do họ phải giữ hình tượng. Những đòi hỏi về sự đầu tư
        của họ cũng trở nên tồi tệ - họ có thể vẫn không dừng việc đó lại khi
        mọi người vẫn còn nhìn họ. Nhận là mình ‘thua cuộc’ là điều gì đó rất
        khó khăn đối với Tân Kim.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tân Kim thích nói về chiến tích của họ hoặc là sẽ khó như thế nào để đạt
        được điều gì đó – và họ sẽ không thừa nhận là họ thật sự ở trong tình
        huống khó khăn. Do đó, cũng sẽ khó cho họ để gỡ rối bản thân khỏi tình
        huống chính miệng họ thừa nhận một cách công khai.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Rất bất ngờ với những người thích kiểm soát, người Tân Kim ít khi có kế
        hoạch tài chính lâu dài rõ ràng. Họ thiên về sự ngắn hạn – kiếm được
        tiền – hoàn thành mục tiêu hơn là lên kế hoạch lâu dài cho tới lúc họ về
        hưu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ thường để ý đến xu hướng mới nhất. Sẽ là điều khẩn cấp đối với họ để
        biết được chuyện gì đang xảy ra trong chợ. Đôi khi, nếu họ không làm
        theo (hoặc ít nhất là phải biết) những gì người khác đang làm với tiền
        của họ và họ không thể bàn về nó, nó ũng giống như là một họ mất mặt.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Những người Tân Kim rất vượng có tầm nhìn rõ ràng về tương lai. Họ lên
        kế hoạch tiết kiệm phòng khi khó khăn và thậm chí sẵn sàng cho những thế
        hệ con cháu về sau. Người Tân Kim vượng tạo ra và phát huy những giá
        trị. Họ thành thạo với việc đầu tư hơn là mua bán. Họ bảo vệ tương lai
        họ và tài sản của họ đủ để kéo dài lối sống giàu có và sang trọng kể cả
        khi họ không còn làm việc nữa.
      </p>
    </div>
  );
};
