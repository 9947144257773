import nhatpha from "../../assets/thansat/nhatpha.png";
import coloan from "../../assets/thansat/coloan.png";
import ngocmon from "../../assets/thansat/ngocmon.png";
import cothan from "../../assets/thansat/cothan.png";
import daohoa from "../../assets/thansat/daohoa.png";
import nguyenthan from "../../assets/thansat/nguyenthan.png";
import phutram from "../../assets/thansat/phutram.png";
import phucngam from "../../assets/thansat/phucngam.png";
import thienhy from "../../assets/thansat/thienhy.png";
import thiettao from "../../assets/thansat/thiettao.png";
import baobai from "../../assets/thansat/baobai.png";
import canloc from "../../assets/thansat/canloc.png";
import kiepsat from "../../assets/thansat/kiepsat.png";
import kimdu from "../../assets/thansat/kimdu.png";
import kimquy from "../../assets/thansat/kimquy.png";
import locthan from "../../assets/thansat/locthan.png";
import ngocduong from "../../assets/thansat/ngocduong.png";
import nguphu from "../../assets/thansat/nguphu.png";
import nguyetpha from "../../assets/thansat/nguyetpha.png";
import daihao from "../../assets/thansat/daihao.png";
import quythuc from "../../assets/thansat/quythuc.png";
import thapacdaibai from "../../assets/thansat/thapacdaibai.png";
import thientai from "../../assets/thansat/thientai.png";
import tieuhao from "../../assets/thansat/tieuhao.png";
import tuepha from "../../assets/thansat/tuepha.png";
import damuu from "../../assets/thansat/damuu.png";
import ducthan from "../../assets/thansat/ducthan.png";
import hoacai from "../../assets/thansat/hoacai.png";
import hoikhi from "../../assets/thansat/hoikhi.png";
import luctu from "../../assets/thansat/luctu.png";
import amsaiduongthac from "../../assets/thansat/amsaiduongthac.png";
import hamtri from "../../assets/thansat/hamtri.png";
import hongdiem1 from "../../assets/thansat/hongdiem1.png";
import hongdiem2 from "../../assets/thansat/hongdiem2.png";
import hongloan from "../../assets/thansat/hongloan.png";
import khongvong from "../../assets/thansat/khongvong.png";

export const AmSaiDuongThac = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Những người trong lá số có trụ này sẽ gặp rắc rối về tình cảm với
        vợ/chồng, có thể nằm ở trụ năm, tháng, ngày hay giờ. Nam nữ bất hoà.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ảnh hưởng sẽ nặng hơn nếu trụ ngày (Nhật Can) chính là Âm Sai Dương
        Thác, hôn nhân bất lợi, giao tiếp kém.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu có nhiều hơn 1 Âm Sai Dương Thác thì tốt hơn là giữ khoảng cách với
        gia đình vợ hay chồng và tốt nhất là nên kết hôn qua mai mối để giảm
        thiểu rủi ro.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Gồm 12 ngày sinh như sau:{" "}
      </p>
      <img style={{ width: "100%" }} src={amsaiduongthac} />
      <p style={{ textAlign: "left", padding: "1rem 1rem 0 1rem" }}>
        Nam thì sợ Âm Sai, nữ thì sợ Dương Thác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Can Chi là Âm thì gọi là Âm Sai: Đinh Mùi, Đinh Sửu, Tân Sửu, Tân Mão,
        Quý Hợi, Quý Tỵ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Can Chi là Dương thì gọi là Dương Thác: Bính Ngọ, Bính Tý, Mậu Thân, Mậu
        Dần, Nhâm Tuất, Nhâm Thìn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Can Chi tương khắc thì ảnh hưởng khá mạnh: Bính Tý, Mậu Dần, Tân Mão,
        Quý Tỵ, Nhâm Thìn, Nhâm Tuất.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Can Chi tỷ hoà (toạ Dương Nhẫn) thì ảnh hưởng thứ: Bính Ngọ, Tân Dậu,
        Quý Hợi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Can Chi tương sinh thì hiệu ứng khá thấp: Mậu Thân, Đinh Sửu, Đinh Mùi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu Bát Tự có ngày sinh trên thì gọi là Âm Dương Sai Thác Nhật, Địa Chi
        lại có tương xung thì ứng nghiệm rất cao. Nếu Địa Chi không xuất hiện
        xung nhưng xuất hiện hợp thì hiệu ứng xấu bị suy giảm. Nếu kèm với chi
        tháng hoặc chi ngày là Dịch Mã Tinh thì mức độ xấu cao hơn.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trong số 60 ngày sinh thì đã có 12 ngày là Âm Sai Dương Thác, tỷ lệ
        chiếm 1/5, tức trong số 5 người thì sẽ có 1 người sinh vào ngày Âm Sai
        Dương Thác. Theo khảo nghiệm của Chúng tôi thì những người trong lá số
        có trụ ngày là Âm Sai Dương Thác có tỷ lệ nhiễm viêm gan B khá phổ biến
        mà không có thuốc chữa trị hiệu quả.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người có Bát Tự hoả vượng, nhất là ngày sinh Tỵ dễ biến thành bệnh viêm
        gan B.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngoài ra người mệnh Âm Sai Dương Thác còn có đặc điểm:
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Hôn nhân không thuận, dễ phát sinh hiểu lầm hay bất hoà ngoài ý muốn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Nếu là nữ thì gia đình bên chồng lạnh nhạt, nếu là nam thì không hợp
        gia đình bên ngoại.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Đa phần dễ mắc bệnh liên quan tâm linh kỳ lạ, dễ bị duyên âm phá rối
        gây hiểu lầm vợ chồng dẫn đến chia tay, ly hôn.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Mệnh có Âm Sai Dương Thác nếu là người chưa kết hôn thì dễ có khuynh
        hướng đồng tính. Nhiều người có xu hướng muốn sửa đổi giới tính bản
        thân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Mệnh có Âm Sai Dương Thác ngoại giao bên ngoài thì lạnh nhạc, hơi cô
        đơn, xử thế có nhiều khiếm khuyết.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Mệnh có Âm Sai Dương Thác thường không thể kết hôn với người mà mình
        yêu.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Mệnh có Âm Sai Dương Thác thường có cha mẹ kết hôn lần hai hoặc có anh
        em cùng cha khác mẹ hoặc cùng mẹ khác cha.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Theo sách Tam Mệnh Thông Hội nói rằng: “Nếu là nữ giới thì cô quả, gia
        đình bên chồng lạnh nhạt. Nếu là nam thì bên ngoại không có tình cảm,
        tình cảm với gia đình bên vợ cô đơn. Người có Âm Sai Dương Thác thường
        tái hôn, có con riêng, con nuôi, hoặc có con rơi, hoặc có con hoặc anh
        em cùng cha khác mẹ hoặc cùng mẹ khác cha. Có thể kết hôn trong khi có
        tang, bàn chuyện hôn sự lúc nhà có chuyện đau buồn, đối với gia đình bên
        vợ hoặc chồng xem như kẻ thù, không thường xuyên qua lại. “
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thông qua rất nhiều tài liệu khi nhắc đến Nhật Trụ phạm Âm Dương Thác
        thì đều nói vợ chồng bất hoà, có thể ngoại tình hoặc ly hôn do cãi nhau.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu Nhật Trụ gặp Âm Dương Sai Thác, cung vợ chồng lại gặp Dương Nhẫn Sát
        thì luôn luôn bất hoà, dễ bị bệnh phần đường ruột.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tuy nhiên bất kỳ gì thì cũng có khả năng hoá giải. Năm trường hợp như
        sau theo sách vở sẽ giúp hoá giải được Âm Dương Sai Thác:{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Cung Phu Thê tương hợp (tức Nhật Chi đạt Lục Hợp: Tý Sửu Hợp, Dần Hợi
        Hợp, Mão Tuất Hợp, Thân Tỵ Hợp, Ngọ Mùi Hợp) hoá gải mâu thuẫn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Cung Gia Đình Hôn Nhân tương hợp (tức Nguyệt Chi Lục Hợp).
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Phu Thê Cung có Thiên Hợp, Địa Hợp (tức Thiên Can Hợp, Địa Chi Lục
        Hợp).{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Phu Thê Cung, Gia Đình Hôn Nhân Cung xuất hiện Tam Hợp thì hôn nhân ổn
        định.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Bát tự 2 vợ chồng có các cặp kết hợp với nhau, hôn nhân không dễ tan
        vỡ.
      </p>
    </div>
  );
};

export const CoLoan = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Đây là một trong những thần sát rất xấu về hôn nhân được tính dựa trên
        trụ ngày sinh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Có 8 loại Cô Loan (Cô Loan Sát Phú): Giáp Dần, Ất Tỵ, Bính Ngọ, Đinh Tỵ,
        Mậu Ngọ, Mậu Thân, Tân Hợi, Nhâm Tý.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu mệnh phạm Cô Loan thì nam khắc vợ, nữ khắc chồng, hôn nhân bất lợi.
      </p>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Chúng ta có bài ca quyết Cô Loan Sát Phú như sau:
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> {"->"} Kim Trư Há Hữu Lang (Tân Hợi)</b>
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> {"->"} Mộc Hỏa Xà Vô Nan (Ất Tỵ / Đinh Tỵ)</b>
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> {"->"} Thổ Hầu Thường Độc Ngọa (Mậu Thân)</b>
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> {"->"} Mộc Hổ Không Cư Sương (Giáp Dần)</b>
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> {"->"} Xích Hoàng Mã Độc Ác (Bính Ngọ / Mậu Ngọ)</b>
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> {"->"} Hắc Thử Thủ Không Phòng (Nhâm Tý)</b>
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người có ngày sinh này là bất lợi nhất về mặt tình cảm. Nam nữ có dễ
        phạm Đào Hoa, không thoải mãn trong đời sống tình dục.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>VD:</p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Megan Markle là người kết hôn với Hoàng tử Anh là người có ngày sinh
        Giáp Dần. Cô đã từng trải qua một đời chồng và có đời sống hôn nhân
        truân chuyên vất vả.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Elizabeth Taylor là diễn viên rất nổi tiếng tại Hollywood, bà từng lấy
        kết hôn đến 7 lần. Bản thân bà sinh ngày Mậu Ngọ.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tóm tắt những ngày sinh Cô Loan như sau:
      </p>
      <img style={{ width: "100%" }} src={coloan} />
    </div>
  );
};

export const CoThanQuaTu = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Cô Thần - Quả Tú là sao xấu mà không nên thấy trong lá số Bát Tự vì chủ
        yếu gây xấu về mặt hôn nhân, khiến cho mệnh này luôn cảm thấy cô đơn,
        nhất là kết hôn bao nhiêu lần hoặc với ai cũng cảm thấy không hạnh phúc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ hay cảm thấy cô đơn vì phải đi lại thường xuyên như công tác, công
        việc dẫn đến không gần gũi vợ hay chồng. Nó ảnh hưởng đến hôn nhân xấu
        vì thường xuyên phải đi xa công tác. Hai sao này có cùng tính chất, nên
        chúng ta có thể xét chúng chung với nhau.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Chúng biểu thị sự cô đơn, cô lập từ các thành viên gia đình hay cô độc
        suốt đời. Nó còn biểu thị sự suy tư và nhiều người đạt giải Nobel có một
        trong các sao này. Người tu sĩ cũng thường có sao này. Đừng quá lo lắng
        nếu bạn có một trong các sao này trong lá số vì nó còn biểu thị thành
        công về học vấn vì vốn dĩ người cô đơn về mặt tình cảm thường dễ tập
        trung sức lực và tinh thần cho công việc và học tập. Tuy nhiên, nếu bạn
        có 2 hay nhiều hơn các sao này thì dễ bị cô đơn hơn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tóm lại, một vài điều lưu ý quan trọng như sau:
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Sao này biểu thị sự cô đơn, ít được anh em người thân, bạn bè giúp đỡ,
        nhất là những khi cần được giúp đỡ.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Nếu Cô Thần Quả Tú lại chính là Không Vong thì không tốt, càng thêm
        cảm giác cô đơn.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        o Nếu chi ngày là Cô Thần Quả Tú thì không có duyên lấy vợ/chồng và sinh
        con.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        o Nếu Cô Thần Quả Tú ở năm thì nên có thêm bố mẹ nuôi, vì dễ làm hại bố
        mẹ ruột.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        o Đàn ông có Cô Thần, Tài Tinh đều rơi vào Không Vong nghĩa là không kết
        hôn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        o Phụ nữ có Quả Tú, Quan Tinh rơi vào Không Vong, cũng không kết hôn.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tuy vậy, nếu sao không phải là Hỷ hay Dụng Thần thì người này có thể
        phải chịu sự cô đơn hay không có người quan tâm chú ý hoặc cảm thấy bị
        bỏ rơi suốt cả đời. Nếu sao này rất mạnh thì người này luôn cảm thấy cô
        đơn dù được những người thân yêu xung quanh. Tương tự, họ cũng không
        thích ở gần bên cạnh người khác, họ thường cảm giác mình như “chiến binh
        đơn độc” không có khả năng giao tiếp hay kết nối với người khác.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu các sao này không hiển thị trong lá số, nhưng trong đại vận hoặc lưu
        niên (năm) xuất hiện thì khoảng thời gian đó sẽ bị ly hôn, ly thân, di
        cư hay đi xa. Khi hết đại vận hay lưu niên đó thì trở lại bình thường.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>Ví dụ:</p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Người sinh ngày Dần trong tháng Tỵ
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Người sinh ngày Tý hoặc năm Hợi sinh vào tháng Tuất hoặc Dần
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Người sinh ngày Mão, năm Dần gặp đại vận Sửu hoặc Tỵ cũng là có Cô
        Thần Quả Tú{" "}
      </p>
      <img style={{ width: "100%" }} src={cothan} />
    </div>
  );
};

export const DaoHoaMocDuc = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Sao Đào Hoa này còn được gọi là Mộc Dục.
      </p>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sao này có thể hiểu theo 2 dạng: Nếu ở năm và tháng, biểu thị Nội Đào
        Hoa, là dấu hiệu tình cảm hoà hợp giữa vợ chồng. Nếu xuất hiện ở trụ giờ
        thì biểu thị Ngoại Đào Hoa, biểu thị thích sắc dục, rượu chè và phụ nữ,
        quan hệ tình dục, thích vật chất, tiêu tiền và ra ngoài chơi bời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu Tứ Trụ có từ 3 Đào Hoa trở lên như Tý, Ngọ, Mão hay Dậu thì người
        này đi đến đâu cũng trăng hoa.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tâm trạng không ổn định, dễ hành xử phi đạo đức, chi xài tiền hao phí.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Lá số có Đào Hoa thường là người duyên dáng và ngoại hình ưa nhìn, nổi
        tiếng và đam mê, hơi nhiều dục vọng, xinh đẹp, hấp dẫn, gợi cảm, đào hoa
        nhưng cẩn trọng tránh việc ngoại tình. Sao này tốt cho người làm việc
        trong lĩnh vực giải trí và ăn chơi đêm.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Văn hóa Trung Quốc thường có xu hướng khắc khe về tình cảm lãng mạn, nên
        sao Đào Hoa thường mang nghĩa xấu. Điều này đặc biệt ứng với phụ nữ, gắn
        với quan hệ tình cảm xấu. Đối với đàn ông, đó là tình cảm ngoài hôn
        nhân.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tuy nhiên trong thời hiện đại thì sao Đào Hoa gắn với khả năng giao tiếp
        cá nhân tốt và sức cuốn hút cao. Những cá nhân có sao Đào Hoa thường
        thông minh, rộng lượng, nhạy cảm, quan tâm đến người khác và có sức hấp
        dẫn cao.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người nổi tiếng (ca sĩ, diễn viên,…) có sao Đào Hoa:
      </p>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        1. Trương Quốc Vinh (12/9/1956)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        2. Mai Diễm Phương (10/10/1963){" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        3. Đặng Lệ Quân (29/1/1953){" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sao Đào Hoa phổ biến trong những chính trị gia thành công, tướng lĩnh
        hay giới trí thức:{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        1. Chu Ân Lai (5/3/1898)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        2. Tập Cận Bình (15/6/1953){" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        3. Cai Yuan Pei (11/1/1868){" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        4. George Washington (22/2/1722){" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        5. Nikita Khruschev (15/4/1894){" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        6. Du Nhược Nhậm (11/4/1879){" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        7. Lương Khải Siêu (23/2/1873){" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        8. Nhạc Phi (24/3/1103){" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        9. Hải Nhuệ (23/1/1514){" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        10. Khang Hy (4/5/1564)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        11. Ung Chính (13/12/1678){" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sao Đào Hoa bị ảnh hưởng nhiều nhất bởi Hợp, Hình và Xung. Ảnh hưởng đến
        các mối quan hệ. Để ngăn chặn ảnh hưởng này, chúng ta cần lưu ý năm hay
        tháng xảy ra và tìm cách kiểm soát bản thân trong thời kỳ đó.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi sao Đào Hoa ở cùng Địa Chi như sao Quý Nhân và cùng trụ như Thiên
        Đức, Nguyệt Đức, thì hình thành cát cục. Nó biểu thị thành công và quý.
        Khi sao Đào Hoa và Văn Xương ở cùng Địa Chi, người đó sẽ trở thành tác
        giả nổi tiếng.
      </p>
      <img style={{ width: "100%" }} src={daohoa} />
    </div>
  );
};

export const HamTri = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Cách tính của sao Hàm Trì cũng tương tự như sao Đào Hoa nên có người còn
        gọi là Hàm Trì Đào Hoa{" "}
      </p>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Để tôn trọng học thuật nên chúng tôi cũng sẽ liệt kê như một sao khác
        biệt, mặc dù cũng có nhiều người xem Hàm Trì cũng thực chất chính là Đào
        Hoa.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sao Hàm Trì chủ về cuốn hút tình dục, vận may đào hoa rất tốt nhưng cần
        cẩn trọng chuyên ngoại tình. Biểu thị nhu cầu tình dục cao.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mệnh có Hàm Trì thường là người tướng mạo mỹ lệ, lãng mạn đa tình, nhiều
        tài năng nghệ thuật. Nếu là nữ thì tính tình phong lưu, xinh đẹp. Nếu là
        nam giới thì thích giao lưu kết bạn, quan hệ với bạn khác giới đặc biệt
        tốt. Nếu là Hàm Trì xuất hiện ở trụ năm và trụ tháng thì tốt cho quan hệ
        tình cảm vợ chồng, có lợi cho hôn nhân. Nếu là Hàm Trì xuất hiện ở trụ
        giờ thì xấu cho quan hệ vợ chồng, còn gọi là Hàm Trì Đào Hoa Ngoài
        Tường.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Đào Hoa Ngoài Tường có 2 cách xét: Một là từ chi năm định vị trụ ngày
        và trụ giờ có Hàm Trì. Ngoài ra còn 1 cách là từ chi ngày định vị chi
        giờ có Hàm Trì. Sau 1 thời gian dài nghiên cứu, chúng tôi thấy rằng
        phương pháp sau có vẻ hợp hơn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Làm sao để hoá giải Đào Hoa Ngoài Tường? Đối với người xưa thì cách
        nhìn Đào Hoa Ngoài Tường tương đối khắt khe, cho rằng người này sẽ phóng
        túng, quan hệ tình cảm phức tạp. Nhưng ngoại trừ vào vận Đào Hoa thì
        vượng cần đề phòng, người có Đào Hoa Ngoài Tường chủ yếu tốt về mặt tình
        cảm, giao tiếp tốt. Có thể chuyển hoá năng lượng Đào Hoa này thành dễ
        thành công, có thể rèn luyện năng lực hành vi trong giao tiếp, lấy lòng
        được người khác.
      </p>
      <img style={{ width: "100%" }} src={hamtri} />

    </div>
  );
};

export const HongDiem = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Ảnh hưởng của sao Hồng Diễm chỉ phát huy khi xuất hiện ở Chi Năm hay Chi
        Ngày.
      </p>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Biểu thị tính cách đáng yêu, dễ thương, rất cởi mở. Đam mê và nhiều dục
        vọng, thích lãng mạn và đa sầu đa cảm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sao này cũng biểu thị dấu hiệu ngoại tình, lẳng lơ, thích tình dục, hoặc
        thường xuyên bị tai tiếng, rắc rối do các mối quan hệ tình cảm. Đàn ông
        thường đẹp trai và phụ nữ lại xinh xắn. Nếu đã có gia đình thì có xu
        hướng ngoại tình.
      </p>
      <img style={{ width: "100%" }} src={hongdiem1} />


      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngoài ra Hồng Diễm Sát trên thì cũng có những ngày sinh thuộc về Hồng
        Diễm, mức độ ứng nghiệm càng cao hơn.
      </p>
      <img style={{ width: "100%" }} src={hongdiem2} />

    </div>
  );
};

export const HongLoan = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Sao Hồng Loan đem đến: Vận may, mối quan hệ tình cảm hòa hợp, quan hệ
        yêu đương lãng mạn tốt, các sự kiện vui vẻ như hôn nhân.
      </p>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngoài ra, sao này còn có khả năng gia tăng tác dụng tích cực của các cát
        tinh, đặc biệt nếu đây cũng là dụng thần.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nam hoặc nữ mà có sao Hồng Loan thường sẽ duyên dáng và có ngoại hình,
        thường dễ thành công trong tài chính, dễ gặp may mắn trong cuộc sống.
        Nếu xuất hiện ở trên Thiên Can thì càng tốt hơn. Nếu gặp Xung hay Hợp
        thì sẽ giảm bớt ảnh hưởng tốt của sao.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Một dạng sao Đào Hoa, đại diện cho sự lãng mạn đa tình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Hồng Loan là cát tinh, thông thường để chỉ hỉ sự hôn nhân. Người trong
        mệnh có Hồng Loan thường thông minh, ôn hoà, đẹp đẽ, kết hôn sớm. Mệnh
        không có Hồng Loan thì đến năm có Hồng Loan dễ kết hôn.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Hồng Loan là tính từ chi năm làm chủ, nếu thấy các địa chi khác có
        Hồng Loan thì là có.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Hồng Loan Tinh thuộc Âm Thuỷ (Quý Thuỷ) chủ hôn nhân tốt đẹp, nếu gặp
        vấn đề xấu về hôn nhân thì có thể biến hung thành cát.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>Ví dụ:</p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Tỵ trong năm Tuất
      </p>
      <img style={{ width: "100%" }} src={hongloan} />
    </div>
  );
};

export const KhongVong = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Sự hiện diện của Không Vong sẽ làm suy yếu ngũ hành nào nằm ở Thiên Can
        trong lá số Bát Tự.
      </p>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mỗi người có 2 sao Không Vong dựa trên trụ ngày.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những Không Vong này phải xuất hiện trong tứ trụ mới có giá trị. Nếu nó
        xuất hiện ở đại vận hay năm thì không sao.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Không Vong làm cho Địa Chi mất sức mạnh, đó là mất mát và không tốt. Nếu
        năm sinh bị Không vong thì người đó mồ côi hay bố mẹ ông bà không giúp
        đỡ nhiều. Nếu xuất hiện ở tháng là bản thân phải thay đổi nhiều công
        việc.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tổng thống Eisenhower (14/10/1890) bị không vong ở chi tháng. Ông phải
        trải qua nhiều chiến công mới có thể được phong tướng 5 sao và cuối cùng
        trở thành Tổng Thống. Những người có Không vong ở trụ giờ thì không có
        con trai hoặc không có thời gian dành cho con cái.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi Tài hay Quan bị không vong thì người đó gặp vấn đề về tiền hay
        quyền. Tuy nhiên nếu Không Vong lại có dính đến Lục Hợp hay Tam Hợp thì
        làm giảm bớt tác dụng của Không Vong. Chúng ta có thể sử dụng lúc mà
        Không Vong bị hợp. Mặt khác nếu 1 ngũ hành xấu mà Không Vong, thì bất cứ
        tác dụng xấu nào cũng sẽ giảm tối thiểu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi tứ trụ có 3 Không Vong thì lại rất tốt. Bị Không vong gần hết thì có
        nghĩa là mọi rắc rối được tháo bỏ. Đây gọi là cách cục “Không Vong
        Vượng”
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        VD: Thiệu Ung <br />
        Giờ Ngày Tháng Năm <br />
        Giáp Giáp Tấn Tân <br />
        Tuất Tý Sửu Hợi
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tuất và Hợi Không vong. Ngoài ra can Giáp và can Nhâm ẩn tàng bên trong
        cũng tính là Không Vong. Nên được xem là 4 Không Vong, đây là cách cục
        “Không Vong Vượng”
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đây cũng là cách cục tòng Ấn vì tam hội Thủy có sẵn. Ngoài ra khi Tuất
        và Hợi có xuất hiện trong Tứ Trụ biểu hiện yêu thích siêu hình học, tâm
        linh và tôn giáo.
      </p>
      <img style={{ width: "100%" }} src={khongvong} />
    </div>
  );
};

export const NhatPha = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Lá số có Nhật Phá thì rất bất ổn và không thích hạn chế và cảm thấy
        không an ổn ở nhà. Người phối ngẫu sẽ có sức khỏe xấu hoặc ly hôn hay dễ
        cãi nhau giữa vợ và chồng, hay không thể hòa hợp với con hoặc con có sức
        khỏe xấu. Thích hợp với các hoạt động phá hoại như đập nhà, ly hôn.
        Nhưng không phù hợp cho kết hôn. Phá Nhật/ Nhật Phá là ngày đại hung.
      </p>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Theo sách nếu như trong 1 năm mà có ngày Phá Nhật rơi vào ngày Đông Chí
        thì đó là thời điểm Thiên Địa dương khí yếu, khí âm gian và oán khí rất
        nặng, oán khí xung thiên, nên không thể chiêu sinh oan quỷ, ác linh, bất
        lợi cho việc cúng kiến người thân. Đêm hôm đó được gọi là Bách Quỷ Dạ
        Hành, ác quỷ sống lại, là ngày Âm Gian Mở Cổng. Do đó không nên cho trẻ
        em chơi trước nhà vào ngày này, nên đóng chặt cửa sổ, mở toàn bộ đèn
        trong nhà cho đến khi trời sáng.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Địa chi ngày và địa chi tháng tương xung thì ngày đó được gọi là Nguyệt
        Phá Nhật, gọi tắt là Phá Nhật. Đây cũng chính là sử dụng yếu tố xếp hạng
        12 ngày theo nguyên tắc Trạch Nhật: Thập Nhị Kiến Trừ mà người xưa đã
        dùng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Theo Hiệp Kỷ Biện Phương Thư quyển số 10 thì Phá Nhật là ngày nên cầu y,
        chữa bệnh, phá nhà, vá dột. Các việc khác thì không nên làm.
      </p>
      <img style={{ width: "100%" }} src={nhatpha} />
    </div>
  );
};

export const NgocMon = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Người có mệnh “Ngọc Môn Đào Hoa” nhiều đa tình đa dục, lãng mạn, có sức
        cuốn hút, phong lưu.
      </p>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngọc Môn Đào Hoa biểu thị dễ động tình, bản thân không thể khống chế hỉ
        nộ ái ố, dễ rơi vào lưới tình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Người sinh vào tháng 7 (Thân) Nông Lịch, tháng 11 (Tý), tháng 3 (Thìn)
        : sinh ngày Dậu hoặc giờ Dậu là mệnh đới Ngọc Môn Đào Hoa.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Người sinh vào tháng Dần Ngọ Tuất: sinh vào ngày hay giờ Mão là mệnh
        đới Ngọc Môn Đào Hoa.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Người sinh vào tháng Tỵ Dậu Sửu: sinh vào ngày hay giờ Ngọ là mệnh đới
        Ngọc Môn Đào Hoa.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Người sinh vào tháng Hợi Mão Mùi: sinh vào ngày hay giờ Tý là mệnh đới
        Ngọc Môn Đào Hoa.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhìn chung người có Ngọc Môn Đào Hoa thì nhu cầu tình dục tương đối cao,
        cuộc sống của họ nếu không có tình dục thì sống không bằng chết. Người
        mang Ngọc Môn Đào Hoa, nếu toàn cục là tốt thì bất luận là nam hay nữ
        đều có sự nghiệp thành công, nhưng phong lưu đào hoa, hôn nhân đa phần
        bại. Nếu không thành hôn thì sự nghiệp tốt hơn, cuộc sống như ý.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu cách cục xấu thì bất luận là nam hay nữ có thể làm nghề ca kỹ, phiêu
        dạt bốn phương, cả đời phóng đãng.
      </p>
      <img style={{ width: "100%" }} src={ngocmon} />
    </div>
  );
};

export const NguyenThan = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        + - Nguyên Thần – Dương Nam Âm Nữ (năm sinh can dương / âm) Đây là sao
        xui xẻo ám chỉ sự bất ổn, trồi lên sụt xuống. Có thể dẫn đến rắc rối
        pháp lý kiện tụng, vấn đề tiền bạc và scandal.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        + - Nguyên Thần – Âm Nam Dương Nữ
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Dấu hiệu của bệnh tật, vấn đề sức khoẻ và xui xẻo. Biểu thị hao tài lộc.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sao Nguyên Thần gắn với khó khăn và trở ngại tạo ra lo lắng và sợ hãi.
        Khi xuất hiện trong cột tháng và ngày, người đó thường xuyên gặp rắc rối
        gây ra do vợ/chồng. Nhưng nếu sao Nguyên Thần hợp với Tam Hợp hay Bán
        Tam Hợp thì nó sẽ mang lại nhiều may mắn. Ví dụ, 1 người có Thìn là sao
        Nguyên Thần thì sẽ gặp may mắn trong đại vận Thân, vì Thân và Thìn là
        Bán Tam Hợp. Quy trình này cũng áp dụng cho các năng lượng bên ngoài
        (năm, tháng, ngày và giờ).{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi một người có sao Nguyên Thần ở trụ ngày thì phải xét xem năm, tháng,
        ngày có hình thành Tam Hợp hay không. Ví dụ, với người sinh ngày Thìn
        thì Thìn là sao Nguyên Thần, hãy xét xem những gì xảy ra vào các năm
        tháng ngày Thân, Tý và Dậu. Bạn cũng có thể dùng các hình ảnh, biểu
        tượng hợp trong nhà để kích hoạt tác dụng tốt của sao Nguyên Thần.
      </p>
      <img style={{ width: "100%" }} src={nguyenthan} />
    </div>
  );
};

export const PhuTram = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Sao này thường sao xấu, dễ gây buồn bã, mất tập trung. Nếu rơi vào đại
        vận xấu, lá số nhật can yếu dễ bị trầm cảm, bệnh tâm lý.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu gặp ở lưu niên thì tránh làm việc quá sức, giành thời gian để nghỉ
        ngơi hồi phụ năng lượng rồi hãy quay lại công việc.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sao này là một sao xấu nhẹ, nếu có những cát thần khác như Thiên Đức,
        Nguyệt Đức, Thiên Ất Quý Nhân,...thì sẽ hóa giải được.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sao Nguyên Thần gắn với khó khăn và trở ngại tạo ra lo lắng và sợ hãi.
        Khi xuất hiện trong cột tháng và ngày, người đó thường xuyên gặp rắc rối
        gây ra do vợ/chồng. Nhưng nếu sao Nguyên Thần hợp với Tam Hợp hay Bán
        Tam Hợp thì nó sẽ mang lại nhiều may mắn. Ví dụ, 1 người có Thìn là sao
        Nguyên Thần thì sẽ gặp may mắn trong đại vận Thân, vì Thân và Thìn là
        Bán Tam Hợp. Quy trình này cũng áp dụng cho các năng lượng bên ngoài
        (năm, tháng, ngày và giờ).{" "}
      </p>

      <img style={{ width: "100%" }} src={phutram} />
    </div>
  );
};
export const PhucNgam = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Biểu thị đau đớn, sự kiện buồn, trầm cảm, mất mái tài lộc, thậm chí phá
        sản.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu Phục Ngâm giữa năm và tháng sinh thì quan hệ tình cảm giữa ông bà và
        cha mẹ không tốt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu Phục Ngâm giữa năm và ngày sinh thì quan hệ tình cảm giữa ông bà và
        vợ chồng không tốt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu Phục Ngâm giữa năm và giờ sinh thì quan hệ tình cảm giữa ông bà và
        con cháu xấu.
      </p>

      <img style={{ width: "100%" }} src={phucngam} />
    </div>
  );
};
export const ThienHy = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Sao Thiên Hỷ làm cho người khác lạc quan, hài lòng với đời sống, rộng
        lượng. Người này sẽ không chấp nhặt hơn thua, tiểu tiết. Nếu gặp khó
        khăn và trở ngại, người này sẽ cố gắng làm việc để giải quyết vấn đề.
        Người này sẽ chịu trách nhiệm và không trách móc ngưới khác nếu gặp khó
        khăn. Có ít lá số chứa 2 sao này, nếu có 1 lá số nào chứa cả 2 thì người
        này sẽ rất may mắn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đàn ông thường thẳng thắn và phụ nữ thường tinh tế. Tính tình vui vẻ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thiên Hỷ cũng là một dạng sao Đào Hoa. Người mệnh có Thiên Hỷ thường có
        hôn nhân mỹ mãn, gặp bất kỳ khó khăn nào trong vấn đề tình cảm đều là
        biến thành tốt đẹp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        - Thiên Hỷ Ca Quyết:
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Thiên Hỷ Đào Hoa Tối Hỷ Kiến
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Thượng Thiên Đới Hỷ Cát Lương Duyên
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Mệnh Trung Phùng Chi Tẩu Tình Lộ
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Nhân Mệnh Đới Hỷ Thượng Hoa Kiều
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thiên Hỷ là một loại cát tinh, thường để chỉ hôn sự tốt đẹp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thiên Hỷ Tinh thuộc Dương Thuỷ (Nhâm Thuỷ) chủ tốt về sinh dục và hôn
        nhân. Nếu như Hồng Loan, Thiên Hỷ đồng xuất hiện tại cung Phu Thê, thì
        lãng mạn đa tình, vợ chồng tình thâm.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thiên Hỷ Đào Hoa là Đào Hoa rất tốt, kết cục hoàn mỹ. Nếu mệnh không có
        Thiên Hỷ nhưng vào đại vận có Thiên Hỷ thì cũng đạt hiệu quả tương tự.
      </p>

      <img style={{ width: "100%" }} src={thienhy} />
    </div>
  );
};
export const ThietTao = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Thiết Tảo gọi là cây chổi bằng sắt. Thiết Tảo là chỉ những người có “Sao
        chổi sắt”.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nữ mệnh có Thiết Tảo thì khắc chồng, thường tái giá, đem đến xui xẻo cho
        gia đình chồng. Theo kinh nghiệm của các thầy nhiều kinh nghiệm truyền
        lại thì nam hay nữ phạm Thiết Tảo thì đều xấu như nhau.
      </p>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        - Nữ Mệnh Sở Phạm Thiết Tảo Trừu Ca như sau:
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Thân Tý Thìn Niên Thập Nhị Nguyệt Thị
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Tỵ Dậu Sửu Niên Cửu Nguyệt Trung
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Dần Ngọ Tuất Niên Thị Thất Nguyệt
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Hợi Mão Mùi Niên Bát Nguyệt Phùng
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        - Cách tra như sau (sử dụng tháng âm lịch)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Sinh năm Thân Tý Thìn vào tháng 12
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Sinh năm Tỵ Dậu Sửu vào tháng 9
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Sinh năm Dần Ngọ Tuất vào tháng 7
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Sinh năm Hợi Mão Mùi vào tháng 8
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        - Hoá giải Thiết Tảo bằng cách nào?
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Nếu lá số có ngũ hành thiên can địa chi thông, âm dương càn khôn không
        có xung khắc thì xem như là hoá giải được thiết tảo.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Có thể dùng Phong Thuỷ để hoá giải. Nếu phòng khách, phòng ngủ có thể
        dùng bát quái phù trấn trạch điều tiết âm dương. Ngũ hành lưu thông,
        vượng khí sinh phát, âm dương hài hoà thì không sợ Thiết Tảo.
      </p>

      <img style={{ width: "100%" }} src={thiettao} />
    </div>
  );
};
export const BaoBai = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Sao Bạo Bại gây ra tổn thất lớn về tài chính khi đầu tư, kinh doanh. Cần
        phải hết sức cẩn trọng khi gặp lưu niên hay đại vận có Bạo Bại.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sự kiện gây sốc, sợ hãi, cãi nhau, mách lẻo, tin đồn, bệnh tật, xúc
        phạm, hỗn loạn gia. đình. Nó cũng là sao rất xấu bởi vì biểu thị chuyện
        bất ngờ như chết bất đắc kỳ tử và nguy hiểm bất ngờ.
      </p>

      <img style={{ width: "100%" }} src={baobai} />
    </div>
  );
};
export const CanLoc = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Người có Can Lộc trong lá số thường may mắn sinh ra trong gia đình giàu
        có hoặc tích luỹ được khối tài sản khổng lồ trong đời. Họ có trí tuệ để
        vượt qua bất kỳ hoàn cảnh khó khăn nào. Sự nghiệp suôn chèo mát mái.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu gặp được lưu niên hay đại vận có Can Lộc thì dễ gặp may mắn về sự
        nghiệp trong thời điểm đó, nên tận dụng cơ hội này để kích hoạt sự
        nghiệp và tài lộc tốt.
      </p>

      <img style={{ width: "100%" }} src={canloc} />
    </div>
  );
};

export const KiepSat = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Sao này còn gọi là Đại Sát. Sự hiện diện của sao này biểu thị người này
        thông minh và có năng lực đạt được nhiều thứ trong đời, nhưng không phải
        là không gặp nhiều thách thức.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nguy cơ bị cướp hay móc túi, mất đồ đạc quan trọng, mất tiền, bị tiểu
        nhân phá hoại hay bệnh tật sẽ gây căng thẳng thần kinh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Sao này biểu thị: mất tài lộc, dễ bị tai tiếng, bệnh tật liên quan đến
        ruột non, tai, mũi, miệng và phổi.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Sao này biểu thị tính cách thiếu kiên nhẫn, cả đời dễ bị thị phi, phá
        sản và ít may mắn.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Nếu Kiếp Sát cũng là hoặc cùng trụ Tỷ Kiếp thì biểu thị người này
        nghiện rượu.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Nhưng nếu Kiếp Sát cùng trụ với Quý Nhân thì người này thường can đảm,
        có cá tính thu hút.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Nếu là kỵ thần thì biểu thị thích bạo hành tình dục, dễ gặp họa.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tương tự, nếu sao này cũng là hỉ dụng thần thì người này khôn lanh trong
        mọi việc ở đời, can đảm dám nghĩ dám làm và thích ganh đua.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu xuất hiện ở lưu niên thì dễ bị phá sản, gài bẫy và bị bạn bè làm tổn
        thương, có chuyện bất hạnh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>Ví dụ: </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Sửu trong tháng Dần{" "}
      </p>

      <img style={{ width: "100%" }} src={kiepsat} />
    </div>
  );
};

export const KimDu = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Kim Dư là xe kiệu bằng vàng, là cát tinh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người có Kim Dư chủ tính cách nhu hoà, thiện lương, ôn hoà, cách cục
        sang quý.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu là nam giới thì nhiều thê thiếp, âm phúc sâu dày. Nếu sinh ngày này,
        sinh giờ này thì tốt, cốt cách phi phàm, con cháu thịnh vượng, gia tộc
        phồn thịnh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sao Kim Dư nói chung biểu thị:
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Hôn nhân thường do mai mối mát tay.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Thường quan tâm đến anh chị em trong nhà.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Có mối quan hệ tốt, đàn ông và phụ nữ thì đều dịu dàng, tử tế và thích
        làm từ thiện, sống hạnh phúc và quan hệ gia đình tốt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Khả năng và vận may để trở nên giàu có, với sự giúp đỡ của người bạn
        đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • May mắn có khả năng cưới được vợ hay chồng từ gia đình giàu có quyền
        quý. Tuy nhiên, nếu chi ngày có Tài Tinh hay Kim Dư thì cần phải là dụng
        thần thì mới phát huy được hết tác dụng tốt của sao này.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>Ví dụ: </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Quý trong tháng Dần
      </p>

      <img style={{ width: "100%" }} src={kimdu} />
    </div>
  );
};
export const KimQuy = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Kinh doanh phát triển và tài lộc đổ vào. Có cơ hội để trở thành người có
        tài năng xuất chúng trong bất kỳ ngành nào, khả năng tài chính mạnh mẽ
        và khả năng quản lý. Nhưng Xung hoặc Hợp sẽ làm mất đi cái tốt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Kim Quỹ là xuất phát từ một trong những quyển sách của Đạo Giáo, trong
        sách có đề cập đến 36 Thiên Cương, phối với 72 Địa Sát. Kim Dư thuộc về
        108 Thiên Cương và Địa Sát.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nguyên văn trích từ “Tông Kính” như sau: Tam Hợp Vượng Phương, Hựu Vi
        Kim Quỹ Tinh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngày nay xét theo quan điểm hiện đại có thể hiểu như sau: Kim Quỹ là sao
        chủ về tài bạch, giúp tích luỹ tài phú, người trải qua vận Kim Quỹ có
        thể thành công về mặt tiền bạc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Nam mệnh, ngày sinh toạ Kim Quỹ có thể đạt được nhiều sự trợ giúp tốt,
        được âm đức tổ tiên giúp đỡ. Người nam có Kim Quỹ có thể lấy được vợ
        hiền, được vợ giúp đỡ, hưởng tài lộc từ nhà vợ, cả đời vinh hoa.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Nữ mệnh, ngày sinh toạ Kim Quỹ, có thể được tổ đức âm trợ, nhân duyên
        tốt, tài lộc cũng tốt. Người nữ hạnh phúc, bình an cả đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Người có mệnh Kim Quỹ, có thể được âm phúc trợ lực, cả đời đắc phú
        quý.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Khả năng và vận may để trở nên giàu có, với sự giúp đỡ của người bạn
        đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • May mắn có khả năng cưới được vợ hay chồng từ gia đình giàu có quyền
        quý. Tuy nhiên, nếu chi ngày có Tài Tinh hay Kim Dư thì cần phải là dụng
        thần thì mới phát huy được hết tác dụng tốt của sao này.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>Ví dụ: </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Quý trong tháng Dần
      </p>

      <img style={{ width: "100%" }} src={kimquy} />
    </div>
  );
};
export const LocThan = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Sự nghiệp suôn sẻ. Có khả năng đạt thành tích công việc có thể đem lại
        phần thưởng về tiền bạc. 
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
      Người có xu hướng thái độ chủ động và luôn suy
        nghĩ tích cực. Giàu năng lượng trong làm việc, hành động tích cực, có
        đời sống dư dả, thuận buồm xuôi gió, tiền bạc suôn sẻ.
      </p>

      <img style={{ width: "100%" }} src={locthan} />
    </div>
  );
};
export const NgocDuong = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Sao Ngọc Đường biểu thị số vốn có tích lũy gia tăng tài lộc, tiền bạc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Nếu gặp sao Ngọc Đường ở trụ tháng thì cha mẹ có nhiều tiền, để lại
        gia sản cho con cháu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Nếu gặp sao Ngọc Đường ở trụ ngày thì lấy được vợ hay chồng là người
        giàu có, hoặc sau khi kết hôn thì làm ăn kiếm được nhiều tiền.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Nếu gặp sao Ngọc Đường ở trụ giờ thì sau khi sinh con sẽ giàu có hoặc
        con cái sẽ làm ăn, kiếm được nhiều tiền.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Nếu gặp sao Ngọc Đường ở Lưu niên hay Đại vận thì vào thời gian đó sẽ
        có nhiều cơ hội kiếm tiền, phát tài lộc lớn.
      </p>
      <img style={{ width: "100%" }} src={ngocduong} />
    </div>
  );
};
export const NguPhu = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Sao Ngũ Phú biểu thị sự giàu có được nhờ được sự giúp đỡ từ quý nhân,
        người có địa vị cao.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người có sao Ngũ Phú dễ kiếm tiền thông qua các mối quan hệ làm ăn buôn
        bán và tận dụng các mối quan hệ rộng rãi trong xã hội.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người có sao Ngũ Phú có thể làm giàu thông qua việc tham gia vào lĩnh
        vực giảng dạy, giáo dục tuy nhiên ngũ hành Ngũ Phú phải là dụng thần thì
        mới tốt.{" "}
      </p>
      <img style={{ width: "100%" }} src={nguphu} />
    </div>
  );
};
export const NguyetPha = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Sao Nguyệt Phá là sao chủ về lãng phí. Bất lợi lớn nhất là hao tổn tài
        lộc hoặc thậm chí phá sản. Sự nghiệp lao dốc. Môi trường sống liên tục
        thay đổi, dễ gặp bất hòa trong cuộc sống và thị phi.
      </p>

      <img style={{ width: "100%" }} src={nguyetpha} />
    </div>
  );
};
export const DaiHao = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Thất thoát tài lộc nghiêm trọng, tránh mọi đầu cơ bằng mọi giá. Đừng đi
        sòng bạc thường xuyên. Hãy cẩn trọng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Phá Toái Tinh được xem là hung tinh, chủ cô độc, làm việc dễ dính đến
        thị phi, bị bức bách hãm hại, gia đình không hoà thuận, cả đời lao động
        vất vả nhưng ít thành công.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu là cát thần thì càng thêm cát, nếu là hung thần thì càng thêm hung.
        Kỵ thấy trong mệnh, nếu mệnh có Phá Toái thì mối quan hệ lục thân bao
        gồm anh em, cha mẹ, vợ chồng, con cái dễ bất toàn hoặc bất hoà, gặp bệnh
        tật, tài bạch hao tổn nhiều, tiểu nhân phá hoại. Gặp cát tinh thì càng
        tốt, có thể hoá giải hung thần.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Phá Toái được xem là Âm Hoả, người có Phá Toái tính tình cô độc nhưng
        lao lực, làm nhiều nhưng thất bại phần nhiều, thành công ít, thị phi.{" "}
      </p>
      <img style={{ width: "100%" }} src={daihao} />
    </div>
  );
};
export const QuyThuc = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Để xác định được sao Quý Thực thì cần làm các bước sau:
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Xác định Thực Thần của can ngày. Với can ngày Giáp Mộc thì Thực Thần hay
        Thực Thương đồng âm dương tức là Bính Hỏa.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Bước tiếp theo là xác định sao Quý Nhân cho Thực Thần Dương Hỏa tức
        Bính Hỏa là Dậu và Hợi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Những sao Quý Nhân này khác biệt âm dương với Bính Hỏa, do đó cần tiến
        hành thêm 1 bước.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Sau đó chúng ta xem xét Lục Hợp Chi của Dậu và Hợi tức là Thìn và Dần.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Quý Thực cho Giáp Mộc là Bính Dần và Bính Thìn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Bây giờ sẽ xét sao Quý Thực cho người sinh ngày Tân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Thực Thần của ngày Tân là Quý.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Sao Quý Nhân của Quý Thủy là Mão và Tỵ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Quý Nhân của Tân kim là Quý Mão và Quý Tỵ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi Quý Thực ở trong tứ trụ thì người đó sẽ có thu nhập cả đời tốt đẹp.
        Vị trí lý tưởng của sao Quý Thực là trụ tháng. Nếu gặp sao Quý Thực ở
        trụ Đại vận hay Lưu niên thì có nhiều cơ hội tạo ra tiền bạc, tăng
        lương.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Phá Toái được xem là Âm Hoả, người có Phá Toái tính tình cô độc nhưng
        lao lực, làm nhiều nhưng thất bại phần nhiều, thành công ít, thị phi.{" "}
      </p>
      <img style={{ width: "100%" }} src={quythuc} />
    </div>
  );
};

export const ThapAcDaiBai = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Ngày này là ngày hung. Thập ác giống như người phạm 10 trọng tội trong
        luật pháp không được giảm xá. Đại bại giống như ra trận bị đại bại,
        không còn 1 ai sống sót.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Kiêng kỵ: xuất hành, khởi sự công việc, việc hỷ sự, xuất binh, v.v…
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tuy nhiên, vào ngày này, nếu các thần tương phù, quý phi tương trợ thì
        cát. Hội Thiên Nguyệt nhị đức không kỵ, được Tuế kiến, Nguyệt kiến, Thái
        dương điền thực thì cũng không phải kỵ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Các ngày này còn gọi là ngày kho vàng biến thành đất bụi. Cho nên người
        sinh vào ngày này, khó giữ được tiền của.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thập Ác Đại Bại có 10 ngày sau: Giáp Thìn, Ất Tỵ, Bính Thân, Đinh Hợi,
        Mậu Tuất, Kỷ Sửu, Canh Thìn, Tân Tỵ, Nhâm Thân và Quý Hợi thuộc về 10
        ngày Thập Ác Đại Bại.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Dựa trên lý luận rằng ngày sinh yếu không thể khống chế được Tài. 10
        ngày này thì ngũ hành Thiên Can rơi vào Không Vong. Khi điều này xảy ra
        thì ngũ hành không thể hỗ trợ để đem lại sức mạnh. Nếu tỷ kiếp không thể
        phát huy sức mạnh thì người này trở nên không tự tin. Ngày sinh này sẽ
        không bao giờ trở nên mạnh được vì mùa sinh là Không Vong.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ thường lo sợ về tài năng bản thân. Họ thiếu phát triển và phụ thuộc
        vào người khác mới có thể phát triển bản thân được. Sức mạnh của mùa từ
        chối họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thậm chí mọi việc còn tồi tệ hơn vì họ thường toạ Thương Quan hoặc Kiếp
        Tài. Toạ Kiếp Tài sẽ khiến cho người này tiêu xài hoang phí trong khi
        Thương Quan sẽ khiến cho họ trở nên nổi loạn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Một trong những cách hóa giải dễ nhất đến từ Không Vong. Không Vong có
        thể hoá giải thông qua xung và hợp. Đối với bất kỳ địa chi nào, có thể
        dùng Lục Hợp, Tam Hợp hoặc Lục Xung để hoá giải để giúp thoát khỏi Không
        Vong. Ngoài ra, có đến 7 tháng trong vòng 1 năm mà Can Ngày có thể hoạt
        động bình thường..
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngoài ra, nếu có thể kết thân nhiều bạn bè sẽ giúp hoá giải Không Vong.
        Thiếu đi bạn bè cũng là một trong những nguyên nhân khiến Không Vong trở
        nên nghiêm trọng. Tỏ ra rộng rãi và vô tư trong mối quan hệ bạn bè cũng
        sẽ giúp ích rất nhiều cũng như họ cần tránh thái độ tự kiêu, một mình
        một cõi.
      </p>

      <img style={{ width: "100%" }} src={thapacdaibai} />
    </div>
  );
};

export const ThienTai = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Tính theo Can ngày hoặc Can năm
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Biểu thị tiền từ trên trời rơi xuống, đặc biệt nếu xuất hiện trong Lưu
        niên hay Đại vận.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tuy nhiên, Nhật can cũng phải vượng hoặc tướng, nếu không thì không
        hưởng được may mắn từ sao này.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>Ví dụ:</p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Người sinh ngày Mậu trong năm Nhâm
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Người sinh ngày Canh gặp đại vận Giáp
      </p>

      <img style={{ width: "100%" }} src={thientai} />
    </div>
  );
};

export const TieuHao = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Hao hụt bất ngờ nhưng chỉ ít thôi liên quan đến trả tiền cho chi phí y
        tế do bệnh tật bất ngờ, phạt, trộm, phải mua quà cáp, mất đồ đạc. Có thể
        dẫn đến sẹo trên mặt hay cơ thể trong khi giải phẫu thẩm mỹ. Tránh liên
        doanh. Tiểu Hao đại diện cho hao hụt nhỏ. Vị trí Tiểu Hao đại kỵ sát khí
        và động thổ. Tiểu Hao giống như Tử Phù và đại diện cho sức khoẻ và chết
        chóc. Không nên tổ chức tang lễ tại Tiểu Hao. Có thể đốt phù hoặc dán
        phù Tiểu Hao tại đây.
      </p>

      <img style={{ width: "100%" }} src={tieuhao} />
    </div>
  );
};

export const TuePha = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Tránh hành động quan trọng hay khởi sự công trình lớn, đầu tư kinh
        doanh. Nguy cơ bị tai nạn vì vậy cần phải cẩn thận khi đi xuất hành hay
        lái xe. Nếu là người lớn tuổi thì cần phải kiểm tra sức khỏe. Nhìn chung
        là hung tinh, biểu thị:
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Khó tích tụ tài lộc
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Có thái độ xài tiền như nước, hoang phí
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Thường đốt thời gian và có nhiều lúc lên voi xuống chó trong cuộc đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Vào những năm xung với tuổi thì cần cẩn trọng hao tiền, lo âu, sức
        khỏe kém.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>Ví dụ:</p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh năm Tuất, tháng Thìn
      </p>

      <img style={{ width: "100%" }} src={tuepha} />
    </div>
  );
};

export const DaMuu = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Những người sinh ngày này thông minh làm chiến lược tốt, nhiều mưu mẹo,
        khôn ngoan có thể ví như bậc lãnh đạo, chiến lược gia.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ở đây có 2 ngày sinh được xem là Đa Mưu Nhật đó là ngày sinh Tân Tỵ và
        Mậu Tý.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Chúng ta có thể xem những nhân vật nổi tiếng có ngày sinh Đa Mưu như
        dưới đây:
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>Tân Tỵ:</p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Minh Thần Tông (16/9/1507)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Trương Đình Ngọc (29/10/1672)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>Mậu Tý:</p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Đặng Tiểu Bình (22/8/1904)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Lâm Bưu (5/12/1907)
      </p>

      <img style={{ width: "100%" }} src={damuu} />
    </div>
  );
};

export const DucThan = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Thần Sát này xuất hiện trong lá số Bát Tự và giúp luận đoán xem người
        này có hưởng nhiều may mắn trong đời hay không. Đức Thần biểu thị là
        người có đạo đức, cuộc đời nhiều thuận lợi may mắn do công đức tâm thiện
        gây ra.
      </p>

      <img style={{ width: "100%" }} src={ducthan} />
    </div>
  );
};

export const HoaCai = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Những người có sao này sẽ rất thông minh, tài năng trong lĩnh vực văn
        hoá và nghệ thuật, mặc dù có thể tỏ ra hơi “Quái đản” trong mắt người
        xung quanh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người có sao này thường thích dành thời gian ở một mình, suy ngẫm, tư lự
        và buồn bã. Tuy nhiên nếu là dụng thần thì đây cũng là sao chủ về sáng
        tạo một mình, người này thích dành thời gian viết nhật ký, sách hay
        blog.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bị cuốn hút vào những chuyện tâm linh huyền bí như Phong Thủy, tôn giáo,
        triết lý và nghệ thuật trừu tượng. Đó cũng là sao chủ về sáng tạo và lập
        kế hoạch chiến lược.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đây là Thìn Tuất Sửu Mùi biểu thị tố chất nghệ sĩ, nhưng cũng biểu thị
        sự cô đơn vì đôi khi cảm thấy người xung quanh không hiểu mình. Nếu Hoa
        Cái xuất hiện ở trụ ngày hay trụ giờ thì cô đơn trong hôn nhân hay đường
        con cái. Hoặc khó kết hôn, hôn nhân không hạnh phúc hay khó có con,
        không được con chăm sóc. Sao Hoa Cái nếu xuất hiện ở các cột khác như
        năm tháng thì biểu thị quãng thời gian cô đơn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi Sao Hoa Cái chiếu đến hướng nhà, nó biểu thị ra quyết định sai lầm,
        mệt mỏi về tinh thần và đầu tư kinh doanh xấu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sao Hoa Cái biểu thị:{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Khả năng học giỏi, nhanh, nhớ lâu...
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Có tài năng trong lĩnh vực nghệ thuật, hội họa, âm nhạc, thiết kế và
        thẩm mỹ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Có tài sáng tạo độc đáo
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Có khả năng văn chương, cũng như có xu hướng thiên về các môn nghệ
        thuật tinh tế hay xã hội hoặc triết học, huyền học, tôn giáo, kiến thức,
        ngoại cảm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Có xu hướng thích triết lý hoặc tôn giáo.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu được thì sao này nên hợp với Ấn Tinh hay nên là Ấn Tinh. Nếu Hoa Cái
        là Ấn Tinh hay Quan Tinh cùng trụ sẽ được tôn trọng trong xã hội và có
        thành tích về lãnh đạo.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Xét về mặt tiêu cực thì sao này cũng biểu thị:{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Khả năng cô đơn hay xu hướng đơn độc (cảm giác một mình).{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Nếu sao này Không Vong thì người này có xu hướng theo tôn giáo, có khả
        năng trở thành tu sĩ, linh mục hay học giả về tôn giáo.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Nếu Hoa Cái rơi vào Tử hoặc Tuyệt, Xung hay Khắc, thì dễ bị bệnh hay
        hại con, khó sinh hay dạy con. Cuộc sống không suôn sẻ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>Ví dụ:</p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người này sinh năm Tuất, ngày Ngọ.{" "}
      </p>
      <img style={{ width: "100%" }} src={hoacai} />
    </div>
  );
};

export const HoiKhi = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Thần Sát này xuất hiện trong lá số Bát Tự và giúp luận đoán xem người
        này có hưởng nhiều may mắn trong đời hay không. Đức Thần biểu thị là
        người có đạo đức, cuộc đời nhiều thuận lợi may mắn do công đức tâm thiện
        gây ra.
      </p>

      <img style={{ width: "100%" }} src={hoikhi} />
    </div>
  );
};

export const LucTu = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Tú là chỉ sự ưu tú, người có mệnh Lục Tú thì thông minh, đa tài, đa
        nghệ, tướng mạo tuấn tú, có duyên. Có thể trở thành tác giả, nhà văn,
        hoạ sĩ, thư pháp gia, dễ thành công. Là người có trụ ngày sinh thuộc về
        một trong 6 ngày sau:
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>1. Mậu Tý</p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>2. Kỷ Sửu</p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>3. Mậu Ngọ</p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>4. Đinh Mùi</p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>5. Bính Ngọ</p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>6. Kỷ Mùi</p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Có thể giải thích thêm cụ thể một số ngày trong Lục Tú như sau:
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Bính Ngọ: Toạ Dương Nhẫn, thông minh đa tài. Nam mệnh khắc vợ, nữ mệnh
        khắc chồng, bất luận là nam hay nữ sinh vào ngày Bính Ngọ thì dễ bị tổn
        thương.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Đinh Mùi: Đắc khố thông căn, toạ Thực Thần vượng, chủ người xinh đẹp,
        nhưng tham ăn uống.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Mậu Tý: Ngày Lục Tú, chủ người thông minh tú khí, toạ Chính Tài, lấy
        được vợ hiền, giàu có. Can chi Mậu Quý hoá Hoả sinh thân, chủ sang quý.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Mậu Ngọ: Toạ Dương Nhẫn quá vượng, nữ mệnh khắc chồng, nam mệnh khắc
        vợ, dễ bị tai hoạ, Mậu Thổ quá khô nên chủ về tính cách dễ nóng giận.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Kỷ Sửu: Thông căn, nếu sinh đắc nguyệt lệnh là mạnh thì anh em đấu đá.
        Nếu sinh vào thời điểm thất lệnh thì được anh em giúp đỡ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Kỷ Mùi: Toạ khố thông căn, thân vượng, toạ Sát Ấn, người này tự cao.
        Nữ mệnh Kỷ Mùi thân tài đều tốt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Kỷ Sửu: Thuộc Lục Tú nhật, chủ người tú khí xinh đẹp, thân toạ Kim
        khố, không có căn, toạ Thất Sát vô chế. Người tự tin thái quá, thường cô
        độc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nam hay nữ mệnh Kỷ Sửu thường thì thông minh, trí huệ, tướng mại khôi
        ngô, có học vấn tốt, năng lực phán đoán, khả năng học tập tốt. Có năng
        lực nghệ thuật và học tập nhanh, đa tài. Văn võ song toàn, là người có
        phúc huệ, người theo chủ nghĩa hoàn mỹ. Xử sự có phân cố chấp, nhưng
        kiên trì, thích kết duyên với tôn giáo. Riêng nữ mệnh Kỷ Sửu thường có
        tính cách mạnh mẽ như nam giới.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người sinh vào 1 trong các ngày trên sẽ rất thông minh, xuất sắc,
        học giỏi, tài năng, danh tiếng và thành tích cao. Nhiều học giả, giáo sư
        và các nhân vật nổi tiếng sinh ngày này.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>VD:</p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Người sinh ngày Kỷ Mùi (tổng thống Mỹ Abraham Lincoln – 12/2/1809; tỷ
        phú Aristotle Onasis – 15/1/1906; tỷ phú Mỹ Donald Trump – 14/6/1946)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Người sinh ngày Kỷ Sửu có Sergey Brin (21/8/1973 – là người đồng sáng
        lập Google){" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Người sinh ngày Bính Ngọ (học giả Lương Khải Siêu – sinh 23/2/1873){" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Người sinh ngày Đinh Mùi (tổng thống Ronald Reagan – 6/2/1911; chính
        trị gia Lê Nguyên Hồng – 19/10/1864) – người 2 lần làm chủ tịch nước
        Cộng Hòa Trung Hoa, 1916 – 1917 và 1922 – 23.
      </p>

      <img style={{ width: "100%" }} src={luctu} />
    </div>
  );
};


