import moment from "moment-timezone";
import React, { useEffect, useState, useRef } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { DragSwitch } from "react-dragswitch";
import { VscTriangleLeft, VscTriangleRight } from "react-icons/vsc";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, InputGroup, Label, Table } from "reactstrap";
import SunCalc from "suncalc";
import * as XLSX from "xlsx";
import logo from "../assets/logo.png";
import Const from "../components/Const";
import { login } from "../Slice/UserSlice";

import {
  DaiVan,
  DaiVan10Nam,
  DaiVanDay,
  DayToCanChi,
  DongTayTuTrach,
  getNamAp,
  getNamtietKhi,
  getQue,
  handleYearTietKhi,
  HourToCanChi,
  KhoiCuong,
  KhongVong,
  KhongVong2,
  MonthToCanChi,
  Next,
  nguHanh,
  NguyetChi,
  Prev,
  solarTime,
  soNgayDaiVan,
  TangCan,
  TangCanArr,
  ThanSatDiaChi,
  ThanSatDiaChiArr,
  ThanSatThienCan,
  ThanSatThienCanArr,
  ThapThan,
  ThapThanArr,
  ThienXa,
  ThienY,
  TruongSinh,
  YearToCan,
  YearToChi,
  ThaiCung,
  MenhCung,
  changeYear,
} from "../components/convert";
import "../scss/main.scss";
import { loading } from "../Slice/UserSlice";
export const BatTuNew = ({
  // excelLichvanNien = [],
  tietKhiDate = [],
  tietKhiInYear = [],
}) => {
  const noLogin = true;

  const [excelLichvanNien, setExcelLichvanNien] = useState([]);

  const [excelCelerity, setExcelCelerity] = useState([]);
  const [dataCelerity, setDataCelerity] = useState([]);

  const [letter, setLetter] = useState("A");

  const searchTemp = useRef("");
  const [searchResults, setSearchResults] = useState([]);

  const checkLogin = useSelector((state) => state.user);
  const [date, setDate] = useState(new Date());
  const [city, setCity] = useState(Const.city);
  const [checked, setChecked] = useState(false);

  const [dateData, setDateData] = useState(Const.initDateData);
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [hour, setHour] = useState("null");
  const [minute, setminute] = useState("00");
  const [day, setDay] = useState("01");
  const [month, setMonth] = useState("0");
  const [year, setYear] = useState(2021);

  const [hourShow, setHourShow] = useState("00");
  const [minuteShow, setminuteShow] = useState("00");
  const [dayShow, setDayShow] = useState("01");
  const [monthShow, setMonthShow] = useState("01");
  const [yearShow, setYearShow] = useState(2021);
  const [noiSinh, setNoiSinh] = useState();
  const [show, setShow] = useState(false);
  const [checkSelected, setCheckSelected] = useState(false);

  const [kinhDo, setKinhDo] = useState();
  const [viDo, setViDo] = useState();

  const [thienCanYear, setThienCanYear] = useState("");
  const [DiachiYear, setDiachiYear] = useState("");
  const [napAmYear, setnapAmYear] = useState("");
  const [thienCanHour, setThienCanHour] = useState("");
  const [diaChiHour, setdiaChiHour] = useState("");
  const [napAmHour, setnapAmHour] = useState("");
  const [thienCanDay, setthienCanDay] = useState("");
  const [diaChiDay, setdiaChiDay] = useState("");
  const [napAmDay, setnapAmDay] = useState("");
  const [thienCanMonth, setthienCanMonth] = useState("");
  const [diaChiMonth, setdiaChiMonth] = useState("");
  const [napAmMonth, setnapAmMonth] = useState("");
  const [stateKhongVong, setstateKhongVong] = useState("");
  const [LuuNien, setLuuNien] = useState(2021);

  const [tangCanNguHanhHour, settangCanNguHanhHour] = useState("");
  const [tangCanNguHanhHour2, settangCanNguHanHourHour2] = useState("");
  const [tangCanNguHanhHour3, settangCanNguHanHourHour3] = useState("");
  const [tangCanNguHanhHour4, settangCanNguHanHourHour4] = useState("");
  const [tangCanNguHanhHour5, settangCanNguHanHourHour5] = useState("");
  const [tangCanNguHanhHour6, settangCanNguHanHourHour6] = useState("");

  const [tangCanNguHanhDay, settangCanNguHanhDay] = useState("");
  const [tangCanNguHanhDay2, settangCanNguHanhDay2] = useState("");
  const [tangCanNguHanhDay3, settangCanNguHanhDay3] = useState("");
  const [tangCanNguHanhDay4, settangCanNguHanhDay4] = useState("");
  const [tangCanNguHanhDay5, settangCanNguHanhDay5] = useState("");
  const [tangCanNguHanhDay6, settangCanNguHanhDay6] = useState("");

  const [tangCanMonth, settangCanMonth] = useState("");
  const [tangCanMonth2, settangCanMonth2] = useState("");
  const [tangCanMonth3, settangCanMonth3] = useState("");
  const [tangCanMonth4, settangCanMonth4] = useState("");
  const [tangCanMonth5, settangCanMonth5] = useState("");
  const [tangCanMonth6, settangCanMonth6] = useState("");

  const [tangCanYear, settangCanYear] = useState("");
  const [tangCanYear2, settangCanYear2] = useState("");
  const [tangCanYear3, settangCanYear3] = useState("");
  const [tangCanYear4, settangCanYear4] = useState("");
  const [tangCanYear5, settangCanYear5] = useState("");
  const [tangCanYear6, settangCanYear6] = useState("");

  const [thapThanHour, setthapThanHour] = useState("");
  const [thapThanMonth, setthapThanMonth] = useState("");
  const [thapThanYear, setthapThanYear] = useState("");

  const [thapThanHour1, setthapThanHour1] = useState("");
  const [thapThanHour2, setthapThanHour2] = useState("");
  const [thapThanHour3, setthapThanHour3] = useState("");

  const [thapThanDay1, setthapThanDay1] = useState("");
  const [thapThanDay2, setthapThanDay2] = useState("");
  const [thapThanDay3, setthapThanDay3] = useState("");

  const [thapThanMonth1, setthapThanMonth1] = useState("");
  const [thapThanMonth2, setthapThanMonth2] = useState("");
  const [thapThanMonth3, setthapThanMonth3] = useState("");

  const [thapThanYear1, setthapThanYear1] = useState("");
  const [thapThanYear2, setthapThanYear2] = useState("");
  const [thapThanYear3, setthapThanYear3] = useState("");

  const [checkNapAm, setcheckNapAm] = useState(true);
  const [checkTruongSinh, setcheckTruongSinh] = useState(true);
  const [checkThanSat, setcheckThanSat] = useState(true);

  const [thanSatDiachiHours, setthanSatDiachiHours] = useState("");
  const [thanSatDiaChiDay, setthanSatDiaChiDay] = useState("");
  const [thanSatDiachiMonth, setthanSatDiachiMonth] = useState("");
  const [thanSatDiaChiYear, setthanSatDiaChiYear] = useState("");

  const [thanSatThienCanHour, setthanSatThienCanHour] = useState("");
  const [thanSatThienCanDay, setthanSatThienCanDay] = useState("");
  const [thanSatThienCanMonth, setthanSatThienCanMonth] = useState("");
  const [thanSatThienCanYear, setthanSatThienCanYear] = useState("");

  const [truongSinhHour, settruongSinhHour] = useState("");
  const [truongSinhDay, settruongSinhDay] = useState("");
  const [truongSinhMonth, settruongSinhMonth] = useState("");
  const [truongSinhYear, settruongSinhYear] = useState("");

  const [thienCanDaiVan, setthienCanDaiVan] = useState("");
  const [nguHanhTCDV, setNguHanhTCDV] = useState("");

  const [diaChiDaiVan, setdiaChiDaiVan] = useState("");
  const [ThapThanDaiVan, setThapThanDaiVan] = useState("");

  const [TangCanDaiVan, setTangCanDaiVan] = useState("");
  const [TangCanDaiVan2, setTangCanDaiVan2] = useState("");
  const [TangCanDaiVan3, setTangCanDaiVan3] = useState("");
  const [ThapThanDaiVan1, setThapThanDaiVan1] = useState("");
  const [ThapThanDaiVan2, setThapThanDaiVan2] = useState("");
  const [ThapThanDaiVan3, setThapThanDaiVan3] = useState("");
  const [TangCanDaiVan4, setTangCanDaiVan4] = useState("");
  const [TangCanDaiVan5, setTangCanDaiVan5] = useState("");
  const [TangCanDaiVan6, setTangCanDaiVan6] = useState("");

  const [TruongSinhDaiVan, setTruongSinhDaiVan] = useState("");

  const [ThanSatThienCanDaiVan, setThanSatThienCanDaiVan] = useState("");
  const [ThanSatDiaChiDaiVan, setThanSatDiaChiDaiVan] = useState("");

  const [daiVanData, setdaiVanData] = useState([]);
  const [napAmDaiVan, setnapAmDaiVan] = useState("");

  const [huongTot, sethuongTot] = useState("");
  const [huongXau, sethuongXau] = useState("");

  const [tangCanDaiVanArr, settangCanDaiVanArr] = useState([]);

  const [alerError, setAlerError] = useState(false);

  const [alerError2, setAlerError2] = useState(false);

  const [daiVan10NamA, setdaiVan10NamA] = useState([]);

  const [CurrentYearDaiVan, setCurrentYearDaiVan] = useState();

  const [LuuNienState, setLuuNienState] = useState(new Date().getFullYear());

  const [ThienCanLuuNien, setThienCanLuuNien] = useState("");
  const [nguHanhLN, setNguHanhLN] = useState();
  const [thapthanLuuNien, setThapThanLuuNien] = useState("");

  const [DiaChiLuuNien, setDiaChiLuuNien] = useState("");
  const [TangCanLuuNien, setTangCanLuuNien] = useState("");
  const [TangCanLuuNien2, setTangCanLuuNien2] = useState("");
  const [TangCanLuuNien3, setTangCanLuuNien3] = useState("");
  const [TangCanLuuNien4, setTangCanLuuNien4] = useState("");
  const [TangCanLuuNien5, setTangCanLuuNien5] = useState("");
  const [TangCanLuuNien6, setTangCanLuuNien6] = useState("");
  const [ThapThanLuuNien1, setThapThanLuuNien1] = useState("");
  const [ThapThanLuuNien2, setThapThanLuuNien2] = useState("");
  const [ThapThanLuuNien3, setThapThanLuuNien3] = useState("");
  const [napAmLuuNien, setnapAmLuuNien] = useState("");
  const [TruongSinhLuuNien, setTruongSinhLuuNien] = useState("");
  const [ThanSatThienCanLuuNien, setThanSatThienCanLuuNien] = useState("");
  const [ThanSatDiaChiLuuNien, setThanSatDiaChiLuuNien] = useState("");

  const [gioSolar, setgioSolar] = useState("");
  const [solarphut, setSolarphut] = useState("");
  const [nguHanhHour, setnguHanhHour] = useState("");
  const [nguHanhDay, setnguHanhDay] = useState("");
  const [nguHanhMonth, setnguHanhMonth] = useState("");
  const [nguHanhYear, setnguHanhYear] = useState("");

  const [TSDiaChiArr, setTSDiaChiArr] = useState([]);
  const [TSThienCanArr, setTSThienCanArr] = useState([]);
  const [thienXa, setThienXa] = useState("");
  const [thienYDV, setThienYDV] = useState("");
  const [nguyetChiDV, setNguyetChiDV] = useState("");
  const [khoiCuongDV, setKhoiCuongDV] = useState("");

  const [thienYLN, setThienYLN] = useState("");
  const [nguyetChiLN, setNguyetChiLN] = useState("");
  const [khoiCuongLN, setKhoiCuongLN] = useState("");

  const [thienYHour, setThienYHour] = useState("");
  const [nguyetChiHour, setNguyetChiHour] = useState("");
  const [khoiCuongHour, setKhoiCuongHour] = useState("");

  const [thienYDay, setThienYDay] = useState("");
  const [nguyetChiDay, setNguyetChiDay] = useState("");
  const [khoiCuongDay, setKhoiCuongDay] = useState("");

  const [thienYMonth, setThienYMonth] = useState("");
  const [nguyetChiMonth, setNguyetChiMonth] = useState("");
  const [khoiCuongMonth, setKhoiCuongMonth] = useState("");

  const [thienYYear, setThienYYear] = useState("");
  const [nguyetChiYear, setNguyetChiYear] = useState("");
  const [khoiCuongYear, setKhoiCuongYear] = useState("");

  const [objectDV, setobjectDV] = useState();
  const [soNam, setSoNam] = useState();
  const [soNgay, setSoNgay] = useState();
  const [KhongVongDaiVan, setKhongVongDaiVan] = useState();
  const [KhongVongLN, setKhongVongLN] = useState();
  const [KhongVongHour, setKhongVongHour] = useState();
  const [KhongVongMonth, setKhongVongMonth] = useState();
  const [KhongVongYear, setKhongVongYear] = useState();
  const [SN, setSN] = useState();
  const [DVDays, setDVdays] = useState();
  const [reload, setReload] = useState(0);

  const [around, setAround] = useState("20");
  const [aroundd, setAroundd] = useState("30");
  const [checkMinute, setCheckMinute] = useState(true);

  const [checkHour, setCheckHour] = useState(false);
  const [thaiCungThienCan, setThaiCungThienCan] = useState();
  const [thaiCungDiaChi, setThaiCungDiaChi] = useState();

  const [menhCungTC, setmenhCungTC] = useState();
  const [menhCungDC, setmenhCungDC] = useState();

  const value = useRef();

  // useEffect(() => {
  //   if (reload) {
  //     dispatch(loading(true));
  //     handleResult();
  //   }
  // }, [reload]);

  useEffect(() => {
    loadExcel();
  }, []);

  // useEffect(() => {
  //   if (searchTerm) {
  //     const results = excelCelerity.filter((data) =>
  //       data.name.toLowerCase().includes(searchTerm)
  //     );
  //     setSearchResults(results);
  //   } else {
  //     setSearchResults([]);
  //   }
  // }, [searchTerm]);

  useEffect(() => {
    if (localStorage.getItem("profile")) {
      const current = new Date();
      const expired = new Date(
        JSON.parse(localStorage.getItem("profile"))?.expiredDate
      );

      const email = JSON.parse(localStorage.getItem("profile"))?.email;
      const name = JSON.parse(localStorage.getItem("profile"))?.name;
      const activeDate = new Date(
        JSON.parse(localStorage.getItem("profile"))?.activeDate
      );
      const expiredDate = new Date(
        JSON.parse(localStorage.getItem("profile"))?.expiredDate
      );

      if (current < expired) {
        return;
      } else {
        localStorage.setItem(
          "profile",
          JSON.stringify({
            name: name,
            email: email,
            isActive: false,
            isActiveHoaGiap: false,
            isActiveThanSat: false,
            activeDate: activeDate,
            expiredDate: expiredDate,
          })
        );
      }
    }
  }, []);

  const setAlerErrors = () => {
    setAlerError(true);
  };

  const dispatch = useDispatch();

  const handlePrevDaiVan = () => {
    let prevObj = daiVanData.filter((x) => x.year === CurrentYearDaiVan - 10);

    let next = +soNgay;
    let nextTo = +soNam;

    if (prevObj?.length) {
      next -= 10;
      nextTo -= 10;
      setSoNgay(next);
      setSoNam(nextTo);
    }

    let thapThanPrev = ThapThanArr(dateData[1]?.canChi, prevObj);
    let napAmPrev = getNamAp(prevObj[0]?.thienCan + " " + prevObj[0]?.diaChi);
    let prevTruongSinh = TruongSinh(dateData[1]?.canChi, prevObj[0]?.diaChi);
    let prevThanSatTC = ThanSatThienCan(
      dateData[1]?.canChi,
      prevObj[0]?.thienCan
    );
    let prevThanSatDC = ThanSatDiaChi(dateData[1]?.diaChi, prevObj[0]?.diaChi);
    let thienY = ThienY(prevObj[0]?.diaChi);
    // let nguyetChi = NguyetChi(prevObj[0]?.diaChi);
    let khoiCuong = KhoiCuong(prevObj[0]?.thienCan, prevObj[0]?.diaChi);
    let khongVong = KhongVong2(
      dateData[1]?.canChi + " " + dateData[1]?.diaChi,
      prevObj[0]?.diaChi
    );

    if (prevObj[0]?.year) {
      setKhongVongDaiVan(khongVong);
      setCurrentYearDaiVan(prevObj[0]?.year);
      setNguHanhTCDV(prevObj[0]?.nguHanhTC);

      setthienCanDaiVan(prevObj[0]?.thienCan);
      setThapThanDaiVan(prevObj[0]?.thapThan?.sinhKhac);

      setdiaChiDaiVan(prevObj[0]?.diaChi);
      setTangCanDaiVan(prevObj[0]?.TangCan[0]?.name);
      setTangCanDaiVan2(prevObj[0]?.TangCan[1]?.name);
      setTangCanDaiVan3(prevObj[0]?.TangCan[2]?.name);
      setTangCanDaiVan4(prevObj[0]?.TangCan[0]?.nguHanh);
      setTangCanDaiVan5(prevObj[0]?.TangCan[1]?.nguHanh);
      setTangCanDaiVan6(prevObj[0]?.TangCan[2]?.nguHanh);

      setThapThanDaiVan1(thapThanPrev[0]?.thapThan?.sinhKhac);
      setThapThanDaiVan2(thapThanPrev[0]?.thapThan2?.sinhKhac);
      setThapThanDaiVan3(thapThanPrev[0]?.thapThan3?.sinhKhac);

      setnapAmDaiVan(napAmPrev?.napAm);
      setTruongSinhDaiVan(prevTruongSinh);

      setThanSatDiaChiDaiVan(prevThanSatDC);
      setThanSatThienCanDaiVan(prevThanSatTC);
      setThienYDV(thienY);
      // setNguyetChiDV(nguyetChi)
      setKhoiCuongDV(khoiCuong);
    } else setCurrentYearDaiVan(CurrentYearDaiVan);
  };

  const handleNextDaiVan = () => {
    let nextObj = daiVanData.filter((x) => x.year === CurrentYearDaiVan + 10);
    let next = +soNgay;
    let nextTo = +soNam;

    if (nextObj?.length) {
      next += 10;
      nextTo += 10;
      setSoNgay(next);
      setSoNam(nextTo);
    }

    let thapThanNext = ThapThanArr(dateData[1]?.canChi, nextObj);
    let napAmNext = getNamAp(nextObj[0]?.thienCan + " " + nextObj[0]?.diaChi);
    let nextTruongSinh = TruongSinh(dateData[1]?.canChi, nextObj[0]?.diaChi);
    let nextThanSatTC = ThanSatThienCan(
      dateData[1]?.canChi,
      nextObj[0]?.thienCan
    );

    let nextThanSatDC = ThanSatDiaChi(dateData[1]?.diaChi, nextObj[0]?.diaChi);
    let thienY = ThienY(nextObj[0]?.diaChi);
    // let nguyetChi = NguyetChi(nextObj[0]?.diaChi);
    let khoiCuong = KhoiCuong(nextObj[0]?.thienCan, nextObj[0]?.diaChi);
    let khongVong = KhongVong2(
      dateData[1]?.canChi + " " + dateData[1]?.diaChi,
      nextObj[0]?.diaChi
    );
    if (nextObj[0]?.year) {
      setKhongVongDaiVan(khongVong);
      setNguHanhTCDV(nextObj[0]?.nguHanhTC);
      setCurrentYearDaiVan(nextObj[0]?.year);
      setthienCanDaiVan(nextObj[0]?.thienCan);
      setThapThanDaiVan(nextObj[0]?.thapThan?.sinhKhac);

      setdiaChiDaiVan(nextObj[0]?.diaChi);
      setTangCanDaiVan(nextObj[0]?.TangCan[0]?.name);
      setTangCanDaiVan2(nextObj[0]?.TangCan[1]?.name);
      setTangCanDaiVan3(nextObj[0]?.TangCan[2]?.name);
      setTangCanDaiVan4(nextObj[0]?.TangCan[0]?.nguHanh);
      setTangCanDaiVan5(nextObj[0]?.TangCan[1]?.nguHanh);
      setTangCanDaiVan6(nextObj[0]?.TangCan[2]?.nguHanh);

      setThapThanDaiVan1(thapThanNext[0]?.thapThan?.sinhKhac);
      setThapThanDaiVan2(thapThanNext[0]?.thapThan2?.sinhKhac);
      setThapThanDaiVan3(thapThanNext[0]?.thapThan3?.sinhKhac);

      setnapAmDaiVan(napAmNext?.napAm);
      setTruongSinhDaiVan(nextTruongSinh);

      setThanSatDiaChiDaiVan(nextThanSatDC);
      setThanSatThienCanDaiVan(nextThanSatTC);
      setThienYDV(thienY);
      // setNguyetChiDV(nguyetChi)
      setKhoiCuongDV(khoiCuong);
    } else setCurrentYearDaiVan(CurrentYearDaiVan);
  };

  const handlePrevLuuNien = () => {
    const arr = changeYear(LuuNienState-1, dateData[1]?.canChi, dateData[1]?.diaChi);
    let thienY = ThienY(arr[0]?.diaChi);
    // let nguyetChi = NguyetChi(arr[0]?.diaChi);
    let khoiCuong = KhoiCuong(arr[0]?.thienCan, arr[0]?.diaChi);
    let khongVong = KhongVong2(
      dateData[1]?.canChi + " " + dateData[1]?.diaChi,
      arr[0]?.diaChi
    );
    const thapThanLuuNien = ThapThan(dateData[1]?.canChi, arr[0]?.thienCan);

    setLuuNienState(arr[0]?.year);
    setThapThanLuuNien(thapThanLuuNien?.sinhKhac);

    setThienCanLuuNien(arr[0]?.thienCan);
    setNguHanhLN(arr[0]?.nguHanh);
    setDiaChiLuuNien(arr[0]?.diaChi);
    setTangCanLuuNien(arr[0]?.tangCan[0]?.name);
    setTangCanLuuNien2(arr[0]?.tangCan[1]?.name);
    setTangCanLuuNien3(arr[0]?.tangCan[2]?.name);
    setTangCanLuuNien4(arr[0]?.tangCan[0]?.nguHanh);
    setTangCanLuuNien5(arr[0]?.tangCan[1]?.nguHanh);
    setTangCanLuuNien6(arr[0]?.tangCan[2]?.nguHanh);
    setKhongVongLN(khongVong);
    setThapThanLuuNien1(arr[0]?.thapThanArr[0]?.thapThan?.sinhKhac);
    setThapThanLuuNien2(arr[0]?.thapThanArr[0]?.thapThan2?.sinhKhac);
    setThapThanLuuNien3(arr[0]?.thapThanArr[0]?.thapThan3?.sinhKhac);

    setnapAmLuuNien(arr[0]?.napAm);
    setTruongSinhLuuNien(arr[0]?.truongSinh);
    setThanSatDiaChiLuuNien(arr[0]?.thanSatDiaChi);
    setThanSatThienCanLuuNien(arr[0]?.thanSatThienCan);
    setThienYLN(thienY);
    // setNguyetChiLN(nguyetChi)
    setKhoiCuongLN(khoiCuong);
  };

  const handleNextLuuNien = () => {
    const arr = changeYear(LuuNienState+1, dateData[1]?.canChi, dateData[1]?.diaChi);
    let thienY = ThienY(arr[0]?.diaChi);
    // let nguyetChi = NguyetChi(arr[0]?.diaChi);
    let khoiCuong = KhoiCuong(arr[0]?.thienCan, arr[0]?.diaChi);
    let khongVong = KhongVong2(
      dateData[1]?.canChi + " " + dateData[1]?.diaChi,
      arr[0]?.diaChi
    );

    const thapThanLuuNien = ThapThan(dateData[1]?.canChi, arr[0]?.thienCan);

    setThapThanLuuNien(thapThanLuuNien?.sinhKhac);

    setLuuNienState(arr[0]?.year);
    setThienCanLuuNien(arr[0]?.thienCan);
    setNguHanhLN(arr[0]?.nguHanh);
    setDiaChiLuuNien(arr[0]?.diaChi);
    setTangCanLuuNien(arr[0]?.tangCan[0]?.name);
    setTangCanLuuNien2(arr[0]?.tangCan[1]?.name);
    setTangCanLuuNien3(arr[0]?.tangCan[2]?.name);
    setTangCanLuuNien4(arr[0]?.tangCan[0]?.nguHanh);
    setTangCanLuuNien5(arr[0]?.tangCan[1]?.nguHanh);
    setTangCanLuuNien6(arr[0]?.tangCan[2]?.nguHanh);
    setKhongVongLN(khongVong);
    setThapThanLuuNien1(arr[0]?.thapThanArr[0]?.thapThan?.sinhKhac);
    setThapThanLuuNien2(arr[0]?.thapThanArr[0]?.thapThan2?.sinhKhac);
    setThapThanLuuNien3(arr[0]?.thapThanArr[0]?.thapThan3?.sinhKhac);

    setnapAmLuuNien(arr[0]?.napAm);
    setTruongSinhLuuNien(arr[0]?.truongSinh);
    setThanSatDiaChiLuuNien(arr[0]?.thanSatDiaChi);
    setThanSatThienCanLuuNien(arr[0]?.thanSatThienCan);
    setThienYLN(thienY);
    // setNguyetChiLN(nguyetChi)
    setKhoiCuongLN(khoiCuong);
  };

  const handlePrevYear = async () => {
    // await loadExcel();

    let years = +year;
    years = years - 1;

    setYearShow(years);

    setYear(years);
    setReload(reload + 1);
  };

  const handleNextYear = async () => {
    //  await loadExcel();

    let years = +year;
    years = years + 1;
    if (years > 2022) {
      return;
    }
    setYearShow(years);

    setYear(years);
    setReload(reload + 1);
  };

  const handlePrevMonth = async () => {
    //  await loadExcel();

    let months = +month;

    months = months - 1;

    let years = +year;

    if (months == -1) {
      months = 11;
      years = +year - 1;
      setYear(years);
      setYearShow(years);
    }
    let montz = moment(`${years}-${months}`, "YYYY-MM");
    montz = montz.format("M");
    setMonthShow(montz);

    setMonth(months);
    setReload(reload + 1);
  };

  const handleNextMonth = async () => {
    //  await loadExcel();

    let months = +month;
    months = months + 1;

    // months = months + 1;

    let years = +year;

    if (months == 12) {
      months = 0;
      years = +year + 1;
      setYear(years);
      setYearShow(years);
    }
    let montz = moment(`${years}-${months}`, "YYYY-MM");
    montz = montz.format("M");
    setMonthShow(montz);

    setMonth(months);
    setReload(reload + 1);
  };

  const handlePrevDay = async () => {
    //  await loadExcel();

    let days = +day;
    let months = +month;
    let years = year;
    days = days - 1;

    if (days == 0) {
      months = months - 1;
      if (months == -1) {
        months = 11;
        years = years - 1;
      }

      let dayInMonth = moment(
        `${years}-${months + 1}`,
        "YYYY-MM"
      ).daysInMonth(); // 29

      days = dayInMonth;
    }

    let dayz = moment(`${year}-${months}-${days}`, "YYYY-MM-DD");
    dayz = dayz.format("D");

    setMonthShow(months);
    setDayShow(dayz);
    setMonth(months);
    setYearShow(years);

    setDay(days);
    setMonth(months);
    setYear(years);
    setReload(reload + 1);
  };

  const handleNextDay = async () => {
    //  await loadExcel();

    let days = +day;
    let months = +month;
    let years = year;

    let dayInMonth = moment(`${year}-${months + 1}`, "YYYY-MM").daysInMonth(); // 29

    days = days + 1;

    if (days > dayInMonth) {
      days = 1;
      months = months + 1;
      if (months == 12) {
        months = 0;
        years = years + 1;
      }
    }
    let dayz = moment(`${year}-${months + 1}-${days}`, "YYYY-MM-DD");
    dayz = dayz.format("D");

    setMonthShow(months);
    setDayShow(dayz);
    setMonth(months);
    setYearShow(years);

    setDay(days);
    setMonth(months);
    setYear(years);
    setReload(reload + 1);
  };

  async function handleResult(data) {
    try {
      dispatch(loading(true));
      if (
        !JSON.parse(localStorage.getItem("profile"))?.isActive ||
        JSON.parse(localStorage.getItem("profile"))?.isActive
      ) {
        let tempTime;
        let checkYear = false;

        tempTime = new Date(
          data[0]?.year + "",
          data[0]?.month - 1 + "",
          data[0]?.day + "",
          data[0]?.hour + "",
          data[0]?.minutes + "",
          "00"
        );

        setCheckHour(true);
        const gender_ = data[0]?.gender;
        setGender(gender_);

        if (year >= 0 && year <= 100) {
          tempTime.setFullYear(+year + 1000, month, day, hour, minute);
          checkYear = true;
        } else {
          checkYear = false;
        }

        if (excelLichvanNien) {
          getTietKhiAllYear(tempTime);
          const lichtietKHI = getNamtietKhi(tempTime, excelLichvanNien);
          get24TietKhiOfYear(tempTime);

          const dateTietKhi = getCorrecTietKhi(tempTime);
          // const yearExcel = getYearExcel(dateTietKhi);
          const luuNienYear = new Date(`${LuuNienState}`);
          const namTietKhi = handleYearTietKhi(
            dateTietKhi.getFullYear(),
            gender_
          ); // huong tot huong xau
          const dongTayTuTrach = DongTayTuTrach(namTietKhi);

          const canYear = YearToCan(dateTietKhi.getFullYear()); //

          const chiYear = YearToChi(dateTietKhi.getFullYear());

          const canChiDay = DayToCanChi(tempTime.toDateString());
          const canChiMonth = MonthToCanChi(
            canYear.name,
            dateTietKhi?.getMonth()
          ); //
          const khongVong = KhongVong(canChiDay?.name);

          const napAmNgay = getNamAp(canChiDay?.name);
          sethuongTot(dongTayTuTrach[0]?.huongTot);
          sethuongXau(dongTayTuTrach[0]?.huongXau);
          setHourShow(tempTime?.getHours());
          setminuteShow(tempTime?.getMinutes());

          setThienCanYear(canYear?.name);
          setDiachiYear(chiYear?.name);
          setstateKhongVong(khongVong);

          let chiHour = null;
          let canDay = null;
          let queNgay = null;
          let chiNgay = "";
          let chiMonthLunar = "";
          let suntime;

          setgioSolar(data[0]?.hour);
          setSolarphut(data[0]?.minutes);

          const initData = dateData.map((item) => {
            if (item.header === "Năm") {
              item.canChi = canYear?.name;
              item.diaChi = chiYear?.name;
              item.qua = getQue(canYear?.name, chiYear?.name)?.tenque;
              item.que = getQue(canYear?.name, chiYear?.name);
            } else if (item.header === "Tháng") {
              //tách chuỗi name trong canChiMonth
              let tempCan = canChiMonth?.name.split(" ");

              item.canChi = tempCan[0];
              item.diaChi = tempCan[1];
              item.qua = getQue(tempCan[0], tempCan[1]).tenque;
              item.que = getQue(tempCan[0], tempCan[1]);
              chiMonthLunar = tempCan[1];
            } else if (item.header === "Ngày") {
              let tempCanDay = canChiDay.name.split(" ");
              item.canChi = tempCanDay[0];
              item.diaChi = tempCanDay[1];
              // let chiDate = Const.ChiArr.find((v) => v.name === tempCanDay[1]);
              // let canDate = Const.CanArr.find((v) => v.name === tempCanDay[0]);

              item.qua = getQue(tempCanDay[0], tempCanDay[1]);
              item.que = getQue(tempCanDay[0], tempCanDay[1]);
              queNgay = item.que;
              chiNgay = tempCanDay[1];
            } else if (item.header === "Giờ") {
              let tempCanDay = canChiDay.name.split(" ");
              let tempCanChiHour;
              if (checkSelected) {
                tempCanChiHour = HourToCanChi(
                  tempCanDay[0],
                  suntime.hour
                ).name.split(" ");
              } else {
                tempCanChiHour = HourToCanChi(
                  tempCanDay[0],
                  tempTime.getHours()
                ).name.split(" ");
              }

              item.canChi = tempCanChiHour[0];
              item.diaChi = tempCanChiHour[1];
              item.qua = getQue(tempCanChiHour[0], tempCanChiHour[1]).tenque;
              item.que = getQue(tempCanChiHour[0], tempCanChiHour[1]);
              chiHour = tempCanChiHour;
              canDay = tempCanDay[0];
            }

            return item;
          });

          const khongVongH = KhongVong2(
            dateData[1].canChi + " " + dateData[1].diaChi,
            dateData[0]?.diaChi
          );
          const khongVongM = KhongVong2(
            dateData[1].canChi + " " + dateData[1].diaChi,
            dateData[2]?.diaChi
          );
          const khongVongY = KhongVong2(
            dateData[1].canChi + " " + dateData[1].diaChi,
            dateData[3].diaChi
          );

          setKhongVongHour(khongVongH);
          setKhongVongMonth(khongVongM);
          setKhongVongYear(khongVongY);
          setDateData(initData);

          setThienCanHour(dateData[0]?.canChi);
          setdiaChiHour(dateData[0]?.diaChi); // dia chi gio
          setnapAmHour(dateData[0]?.que.napAm);

          setthienCanDay(dateData[1].canChi); //thien can ngay
          setdiaChiDay(dateData[1].diaChi); // dia chi ngay
          setnapAmDay(dateData[1].que.napAm);

          const tsTCArr = ThanSatThienCanArr(dateData[1].canChi);
          setTSThienCanArr(tsTCArr);

          const nguHanhTCHour = nguHanh(dateData[1].canChi, dateData[0].canChi);
          const nguHanhTCDay = nguHanh(dateData[1].canChi, dateData[1].canChi);
          const nguHanhTCMonth = nguHanh(
            dateData[1].canChi,
            dateData[2]?.canChi
          );
          const nguHanhTCYear = nguHanh(dateData[1].canChi, dateData[3].canChi);

          setnguHanhHour(nguHanhTCHour);
          setnguHanhDay(nguHanhTCDay);
          setnguHanhMonth(nguHanhTCMonth);
          setnguHanhYear(nguHanhTCYear);

          const thapThanHourResult = ThapThan(
            dateData[1]?.canChi,
            dateData[0]?.canChi
          );
          const thapThanMonthResult = ThapThan(
            dateData[1]?.canChi,
            dateData[2]?.canChi
          );
          const thapThanYearResult = ThapThan(
            dateData[1]?.canChi,
            dateData[3]?.canChi
          );

          const resultTruongSinhHour = TruongSinh(
            dateData[1].canChi,
            dateData[0]?.diaChi
          );
          const resultTruongSinhDay = TruongSinh(
            dateData[1].canChi,
            dateData[1].diaChi
          );
          const resultTruongSinhMonth = TruongSinh(
            dateData[1].canChi,
            dateData[2]?.diaChi
          );
          const resultTruongSinhYear = TruongSinh(
            dateData[1].canChi,
            chiYear?.name
          );

          const TSArr = ThanSatDiaChiArr(dateData[1].diaChi);

          setTSDiaChiArr(TSArr);

          settruongSinhHour(resultTruongSinhHour);
          settruongSinhDay(resultTruongSinhDay);
          settruongSinhMonth(resultTruongSinhMonth);
          settruongSinhYear(resultTruongSinhYear);

          const thanSatHourArr = ThanSatDiaChi(
            dateData[1].diaChi,
            dateData[0]?.diaChi
          );
          const thanSatDayArr = ThanSatDiaChi(
            dateData[1].diaChi,
            dateData[1].diaChi
          );
          const thanSatMonthArr = ThanSatDiaChi(
            dateData[1].diaChi,
            dateData[2]?.diaChi
          );
          const thanSatYearArr = ThanSatDiaChi(
            dateData[1].diaChi,
            chiYear?.name
          );

          setthanSatDiachiHours(thanSatHourArr);
          setthanSatDiaChiDay(thanSatDayArr);
          setthanSatDiachiMonth(thanSatMonthArr);
          setthanSatDiaChiYear(thanSatYearArr);

          const thanSatHourCan = ThanSatThienCan(
            dateData[1].canChi,
            dateData[0]?.canChi
          );
          const thanSatDayCan = ThanSatThienCan(
            dateData[1].canChi,
            dateData[1]?.canChi
          );
          const thanSatMonthCan = ThanSatThienCan(
            dateData[1].canChi,
            dateData[2]?.canChi
          );
          const thanSatYearCan = ThanSatThienCan(dateData[1].canChi, canYear);

          setthanSatThienCanHour(thanSatHourCan);
          setthanSatThienCanDay(thanSatDayCan);
          setthanSatThienCanMonth(thanSatMonthCan);
          setthanSatThienCanYear(thanSatYearCan);

          setthapThanHour(thapThanHourResult?.sinhKhac);
          setthapThanMonth(thapThanMonthResult?.sinhKhac);
          setthapThanYear(thapThanYearResult?.sinhKhac);

          setthienCanMonth(dateData[2]?.canChi);
          setdiaChiMonth(dateData[2]?.diaChi);
          setnapAmMonth(dateData[2]?.que.napAm);

          setnapAmYear(dateData[3]?.que?.napAm);

          const TangCanHourArr = TangCan(dateData[0]?.diaChi);
          const TangCanDayArr = TangCan(dateData[1]?.diaChi);
          const TangCanMonthArr = TangCan(dateData[2]?.diaChi);

          const TangCanYearArr = TangCan(dateData[3]?.diaChi);

          const thapThanHour1 = ThapThan(
            dateData[1].canChi,
            TangCanHourArr[0]?.name
          );
          const thapThanHour2 = ThapThan(
            dateData[1].canChi,
            TangCanHourArr[1]?.name
          );
          const thapThanHour3 = ThapThan(
            dateData[1].canChi,
            TangCanHourArr[2]?.name
          );

          const thapThanDay1 = ThapThan(
            dateData[1].canChi,
            TangCanDayArr[0]?.name
          );
          const thapThanDay2 = ThapThan(
            dateData[1].canChi,
            TangCanDayArr[1]?.name
          );
          const thapThanDay3 = ThapThan(
            dateData[1].canChi,
            TangCanDayArr[2]?.name
          );

          const thapThanMonth1 = ThapThan(
            dateData[1].canChi,
            TangCanMonthArr[0]?.name
          );
          const thapThanMonth2 = ThapThan(
            dateData[1].canChi,
            TangCanMonthArr[1]?.name
          );
          const thapThanMonth3 = ThapThan(
            dateData[1].canChi,
            TangCanMonthArr[2]?.name
          );

          const thapThanYear1 = ThapThan(
            dateData[1].canChi,
            TangCanYearArr[0]?.name
          );
          const thapThanYear2 = ThapThan(
            dateData[1].canChi,
            TangCanYearArr[1]?.name
          );
          const thapThanYear3 = ThapThan(
            dateData[1].canChi,
            TangCanYearArr[2]?.name
          );

          setthapThanHour1(thapThanHour1?.sinhKhac);
          setthapThanHour2(thapThanHour2?.sinhKhac);
          setthapThanHour3(thapThanHour3?.sinhKhac);

          setthapThanDay1(thapThanDay1?.sinhKhac);
          setthapThanDay2(thapThanDay2?.sinhKhac);
          setthapThanDay3(thapThanDay3?.sinhKhac);

          setthapThanMonth1(thapThanMonth1?.sinhKhac);
          setthapThanMonth2(thapThanMonth2?.sinhKhac);
          setthapThanMonth3(thapThanMonth3?.sinhKhac);

          setthapThanYear1(thapThanYear1?.sinhKhac);
          setthapThanYear2(thapThanYear2?.sinhKhac);
          setthapThanYear3(thapThanYear3?.sinhKhac);

          settangCanNguHanhHour(TangCanHourArr[0]?.name);
          settangCanNguHanHourHour4(TangCanHourArr[0]?.nguHanh);
          settangCanNguHanHourHour2(TangCanHourArr[1]?.name);
          settangCanNguHanHourHour5(TangCanHourArr[1]?.nguHanh);
          settangCanNguHanHourHour3(TangCanHourArr[2]?.name);
          settangCanNguHanHourHour6(TangCanHourArr[2]?.nguHanh);

          settangCanNguHanhDay(TangCanDayArr[0]?.name);
          settangCanNguHanhDay4(TangCanDayArr[0]?.nguHanh);
          settangCanNguHanhDay2(TangCanDayArr[1]?.name);
          settangCanNguHanhDay5(TangCanDayArr[1]?.nguHanh);
          settangCanNguHanhDay3(TangCanDayArr[2]?.name);
          settangCanNguHanhDay6(TangCanDayArr[2]?.nguHanh);

          settangCanMonth(TangCanMonthArr[0]?.name);
          settangCanMonth4(TangCanMonthArr[0]?.nguHanh);
          settangCanMonth2(TangCanMonthArr[1]?.name);
          settangCanMonth5(TangCanMonthArr[1]?.nguHanh);
          settangCanMonth3(TangCanMonthArr[2]?.name);
          settangCanMonth6(TangCanMonthArr[2]?.nguHanh);

          settangCanYear(TangCanYearArr[0]?.name);
          settangCanYear4(TangCanYearArr[0]?.nguHanh);
          settangCanYear2(TangCanYearArr[1]?.name);
          settangCanYear5(TangCanYearArr[1]?.nguHanh);
          settangCanYear3(TangCanYearArr[2]?.name);
          settangCanYear6(TangCanYearArr[2]?.nguHanh);

          const daiVanDays = DaiVanDay(
            tempTime,
            gender_,
            nguHanhTCYear,
            lichtietKHI,
            tietKhiDate
          ); // so ngay dai van

          const soNgay = soNgayDaiVan(daiVanDays);

          const daiVan = DaiVan(
            dateTietKhi,
            soNgay,
            gender_,
            nguHanhTCYear,
            dateData[2]?.canChi,
            dateData[2]?.diaChi,
            dateData[1]?.canChi,
            dateData[1]?.diaChi,
            checkYear
          );

          setdaiVanData(daiVan);
          let objectDaivan = [];

          daiVan.map((val, idx) => {
            if (
              val?.year <= new Date().getFullYear() &&
              val?.year > new Date().getFullYear() - 10
            )
              return (objectDaivan = [...objectDaivan, daiVan[idx]]);
          });

          if (objectDaivan.length === 0)
            objectDaivan = [...objectDaivan, daiVan[0]];

          const muoiNamDaiVan = DaiVan10Nam(dateTietKhi, soNgay, checkYear);
          setdaiVan10NamA(muoiNamDaiVan);

          const arrTangCanDaiVan = TangCanArr(daiVan, dateData[1]?.canChi);

          settangCanDaiVanArr(arrTangCanDaiVan);

          let thienYDV = ThienY(objectDaivan[0]?.diaChi);
          let nguyetChiDV = NguyetChi(objectDaivan[0]?.diaChi);
          let khoiCuongDV = KhoiCuong(
            objectDaivan[0]?.thienCan,
            objectDaivan[0]?.diaChi
          );

          setobjectDV(objectDaivan);
          setKhongVongDaiVan(objectDaivan[0]?.khongVong);
          setSoNam(objectDaivan[0]?.year);
          setSoNgay(objectDaivan[0]?.soNgay);
          setThienYDV(thienYDV);
          setNguyetChiDV(nguyetChiDV);
          setKhoiCuongDV(khoiCuongDV);

          setNguHanhTCDV(objectDaivan[0]?.nguHanhTC);
          setthienCanDaiVan(objectDaivan[0]?.thienCan);
          setdiaChiDaiVan(objectDaivan[0]?.diaChi);
          setCurrentYearDaiVan(objectDaivan[0]?.year);

          const tangCanDaiVan = TangCan(objectDaivan[0]?.diaChi);

          const thapThanDaiVan = ThapThan(
            dateData[1]?.canChi,
            objectDaivan[0]?.thienCan
          );

          const ThapThanDaiVan1 = ThapThan(
            dateData[1].canChi,
            tangCanDaiVan[0]?.name
          );
          const ThapThanDaiVan2 = ThapThan(
            dateData[1].canChi,
            tangCanDaiVan[1]?.name
          );
          const ThapThanDaiVan3 = ThapThan(
            dateData[1].canChi,
            tangCanDaiVan[2]?.name
          );

          setThapThanDaiVan(thapThanDaiVan?.sinhKhac);

          setTangCanDaiVan(tangCanDaiVan[0]?.name);
          setTangCanDaiVan2(tangCanDaiVan[1]?.name);
          setTangCanDaiVan3(tangCanDaiVan[2]?.name);

          setThapThanDaiVan1(ThapThanDaiVan1?.sinhKhac);
          setThapThanDaiVan2(ThapThanDaiVan2?.sinhKhac);
          setThapThanDaiVan3(ThapThanDaiVan3?.sinhKhac);

          setTangCanDaiVan4(tangCanDaiVan[0]?.nguHanh);
          setTangCanDaiVan5(tangCanDaiVan[1]?.nguHanh);
          setTangCanDaiVan6(tangCanDaiVan[2]?.nguHanh);

          const getNapAmDaiVan = getNamAp(
            objectDaivan[0]?.thienCan + " " + objectDaivan[0]?.diaChi
          );
          const truongSinhDaiVan = TruongSinh(
            dateData[1].canChi,
            objectDaivan[0]?.diaChi
          );
          setnapAmDaiVan(getNapAmDaiVan?.napAm);
          setTruongSinhDaiVan(truongSinhDaiVan);

          const thanSatThienCanDaiVan = ThanSatThienCan(
            dateData[1].canChi,
            objectDaivan[0]?.thienCan
          );
          const thanSatDiaChiDaiVan = ThanSatDiaChi(
            dateData[1].diaChi,
            objectDaivan[0]?.diaChi
          );
          setThanSatThienCanDaiVan(thanSatThienCanDaiVan);
          setThanSatDiaChiDaiVan(thanSatDiaChiDaiVan);

          const thienCanLuuNien = YearToCan(LuuNienState);
          const thapThanThienCanLuuNien = ThapThan(
            dateData[1]?.canChi,
            thienCanLuuNien?.name
          );

          const nguHanhln = nguHanh(dateData[1]?.canChi, thienCanLuuNien.name);
          const diaChiLuuNien = YearToChi(LuuNienState);
          const khongVongLN = KhongVong2(
            dateData[1]?.canChi + " " + dateData[1]?.diaChi,
            diaChiLuuNien?.name
          );

          const tangCanluunien = TangCan(diaChiLuuNien?.name);
          const napAmLuunien = getNamAp(
            thienCanLuuNien.name + " " + diaChiLuuNien.name
          );
          const truongsinhluunien = TruongSinh(
            dateData[1]?.canChi,
            diaChiLuuNien.name
          );
          const thansatthiencanluunien = ThanSatThienCan(
            dateData[1]?.canChi,
            thienCanLuuNien.name
          );
          const thansatdiachiluunien = ThanSatDiaChi(
            dateData[1]?.canChi,
            diaChiLuuNien.name
          );

          let thienYLN = ThienY(diaChiLuuNien.name);
          let nguyetChiLN = NguyetChi(diaChiLuuNien.name);
          let khoiCuongLN = KhoiCuong(thienCanLuuNien.name, diaChiLuuNien.name);

          setKhongVongLN(khongVongLN);
          setThienYLN(thienYLN);
          setNguyetChiLN(nguyetChiLN);
          setKhoiCuongLN(khoiCuongLN);

          setThienCanLuuNien(thienCanLuuNien.name);
          setThapThanLuuNien(thapThanThienCanLuuNien?.sinhKhac);

          setNguHanhLN(nguHanhln);
          setDiaChiLuuNien(diaChiLuuNien.name);
          setTangCanLuuNien(tangCanluunien[0]?.name);
          setTangCanLuuNien2(tangCanluunien[1]?.name);
          setTangCanLuuNien3(tangCanluunien[2]?.name);
          setTangCanLuuNien4(tangCanluunien[0]?.nguHanh);
          setTangCanLuuNien5(tangCanluunien[1]?.nguHanh);
          setTangCanLuuNien6(tangCanluunien[2]?.nguHanh);
          setnapAmLuuNien(napAmLuunien);

          const thapthanluunien1 = ThapThan(
            dateData[1]?.canChi,
            tangCanluunien[0]?.name
          );
          const thapthanluunien2 = ThapThan(
            dateData[1]?.canChi,
            tangCanluunien[1]?.name
          );
          const thapthanluunien3 = ThapThan(
            dateData[1]?.canChi,
            tangCanluunien[2]?.name
          );

          setThapThanLuuNien1(thapthanluunien1?.sinhKhac);
          setThapThanLuuNien2(thapthanluunien2?.sinhKhac);
          setThapThanLuuNien3(thapthanluunien3?.sinhKhac);

          setTruongSinhLuuNien(truongsinhluunien);

          setThanSatThienCanLuuNien(thansatthiencanluunien);
          setThanSatDiaChiLuuNien(thansatdiachiluunien);

          const thienXa = ThienXa(
            dateData[2]?.canChi,
            dateData[1]?.canChi,
            dateData[1]?.diaChi
          );

          let khoiCuongDay = KhoiCuong(
            dateData[1]?.canChi,
            dateData[1]?.diaChi
          );

          let thaiCung = ThaiCung(dateData[2]?.canChi, dateData[2]?.diaChi);

          setThaiCungThienCan(thaiCung[0]?.tc);
          setThaiCungDiaChi(thaiCung[0]?.dc);

          let menhCung = MenhCung(
            dateTietKhi.getMonth(),
            tempTime.getHours(),
            canYear,
            dateTietKhi.getYear()
          );
          setmenhCungTC(menhCung[0]?.tc);
          setmenhCungDC(menhCung[0]?.dc);
          setKhoiCuongDay(khoiCuongDay);

          setThienXa(thienXa);
          setShow(true);
        }
      }
    } catch (e) {
      console.log("catch", e);
    } finally {
      let timeShow = moment(`${+year}-${+month + 1}-${+day}`, "YYYY-MM-DD"); // 29
      let months = timeShow.format("MM");
      let days = timeShow.format("DD");
      dispatch(loading(false));
      setDayShow(data[0]?.day);
      setMonthShow(data[0]?.month);
      setYearShow(data[0]?.year);
    }
  }

  const getTietKhiAllYear = (timeYear) => {
    const year = timeYear.getFullYear();
    const distance = timeYear?.getFullYear() - 1800;
    let thangLe = distance * 42 + 120;
    let thangChan = distance * 42 + 122;

    if (year <= 1800 && year >= 1583) {
      let ngayGioDauThang1 = Const.tietKhi_1583_1800_thangLe[0]
        .split(" - ")[0]
        .split(" ");

      let ngayGioDauThang3 = Const.tietKhi_1583_1800_thangLe[1]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang5 = Const.tietKhi_1583_1800_thangLe[2]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang7 = Const.tietKhi_1583_1800_thangLe[3]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang9 = Const.tietKhi_1583_1800_thangLe[4]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang11 = Const.tietKhi_1583_1800_thangLe[5]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang2 = Const.tietKhi_1583_1800_thangChan[0]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang4 = Const.tietKhi_1583_1800_thangChan[1]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang6 = Const.tietKhi_1583_1800_thangChan[2]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang8 = Const.tietKhi_1583_1800_thangChan[3]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang10 = Const.tietKhi_1583_1800_thangChan[4]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang12 = Const.tietKhi_1583_1800_thangChan[5]
        .split(" - ")[0]
        .split(" ");

      const ngayDauThangMot = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang1[0].split("/")[1]) - 1,
        Number(ngayGioDauThang1[0].split("/")[0]),
        Number(ngayGioDauThang1[1].split(":")[0]),
        Number(ngayGioDauThang1[1].split(":")[1])
      );

      const ngayDauThangHai = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang2[0].split("/")[1]) - 1,
        Number(ngayGioDauThang2[0].split("/")[0]),
        Number(ngayGioDauThang2[1].split(":")[0]),
        Number(ngayGioDauThang2[1].split(":")[1])
      );
      const ngayDauThangBa = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang3[0].split("/")[1]) - 1,
        Number(ngayGioDauThang3[0].split("/")[0]),
        Number(ngayGioDauThang3[1].split(":")[0]),
        Number(ngayGioDauThang3[1].split(":")[1])
      );
      const ngayDauThangTu = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang4[0].split("/")[1]) - 1,
        Number(ngayGioDauThang4[0].split("/")[0]),
        Number(ngayGioDauThang4[1].split(":")[0]),
        Number(ngayGioDauThang4[1].split(":")[1])
      );
      const ngayDauThangNam = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang5[0].split("/")[1]) - 1,
        Number(ngayGioDauThang5[0].split("/")[0]),
        Number(ngayGioDauThang5[1].split(":")[0]),
        Number(ngayGioDauThang5[1].split(":")[1])
      );
      const ngayDauThangSau = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang6[0].split("/")[1]) - 1,
        Number(ngayGioDauThang6[0].split("/")[0]),
        Number(ngayGioDauThang6[1].split(":")[0]),
        Number(ngayGioDauThang6[1].split(":")[1])
      );
      const ngayDauThangBay = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang7[0].split("/")[1]) - 1,
        Number(ngayGioDauThang7[0].split("/")[0]),
        Number(ngayGioDauThang7[1].split(":")[0]),
        Number(ngayGioDauThang7[1].split(":")[1])
      );
      const ngayDauThangTam = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang8[0].split("/")[1]) - 1,
        Number(ngayGioDauThang8[0].split("/")[0]),
        Number(ngayGioDauThang8[1].split(":")[0]),
        Number(ngayGioDauThang8[1].split(":")[1])
      );
      const ngayDauThangChin = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang9[0].split("/")[1]) - 1,
        Number(ngayGioDauThang9[0].split("/")[0]),
        Number(ngayGioDauThang9[1].split(":")[0]),
        Number(ngayGioDauThang9[1].split(":")[1])
      );
      const ngayDauThangMuoi = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang10[0].split("/")[1]) - 1,
        Number(ngayGioDauThang10[0].split("/")[0]),
        Number(ngayGioDauThang10[1].split(":")[0]),
        Number(ngayGioDauThang10[1].split(":")[1])
      );
      const ngayDauThangMuoiMot = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang11[0].split("/")[1]) - 1,
        Number(ngayGioDauThang11[0].split("/")[0]),
        Number(ngayGioDauThang11[1].split(":")[0]),
        Number(ngayGioDauThang11[1].split(":")[1])
      );
      const ngayDauThangMuoiHai = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang12[0].split("/")[1]) - 1,
        Number(ngayGioDauThang12[0].split("/")[0]),
        Number(ngayGioDauThang12[1].split(":")[0]),
        Number(ngayGioDauThang12[1].split(":")[1])
      );
      let lichTietKhi = [];
      lichTietKhi.push(
        ngayDauThangMot,
        ngayDauThangHai,
        ngayDauThangBa,
        ngayDauThangTu,
        ngayDauThangNam,
        ngayDauThangSau,
        ngayDauThangBay,
        ngayDauThangTam,
        ngayDauThangChin,
        ngayDauThangMuoi,
        ngayDauThangMuoiMot,
        ngayDauThangMuoiHai
      );
      tietKhiDate = lichTietKhi;
    } else if (year >= 0 && year <= 1582) {
      let ngayGioDauThang1 = Const.tietKhi_0_1582_thangLe[0]
        .split(" - ")[0]
        .split(" ");

      let ngayGioDauThang3 = Const.tietKhi_0_1582_thangLe[1]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang5 = Const.tietKhi_0_1582_thangLe[2]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang7 = Const.tietKhi_0_1582_thangLe[3]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang9 = Const.tietKhi_0_1582_thangLe[4]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang11 = Const.tietKhi_0_1582_thangLe[5]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang2 = Const.tietKhi_0_1582_thangChan[0]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang4 = Const.tietKhi_0_1582_thangChan[1]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang6 = Const.tietKhi_0_1582_thangChan[2]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang8 = Const.tietKhi_0_1582_thangChan[3]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang10 = Const.tietKhi_0_1582_thangChan[4]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang12 = Const.tietKhi_0_1582_thangChan[5]
        .split(" - ")[0]
        .split(" ");

      const ngayDauThangMot = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang1[0].split("/")[1]) - 1,
        Number(ngayGioDauThang1[0].split("/")[0]),
        Number(ngayGioDauThang1[1].split(":")[0]),
        Number(ngayGioDauThang1[1].split(":")[1])
      );

      const ngayDauThangHai = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang2[0].split("/")[1]) - 1,
        Number(ngayGioDauThang2[0].split("/")[0]),
        Number(ngayGioDauThang2[1].split(":")[0]),
        Number(ngayGioDauThang2[1].split(":")[1])
      );
      const ngayDauThangBa = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang3[0].split("/")[1]) - 1,
        Number(ngayGioDauThang3[0].split("/")[0]),
        Number(ngayGioDauThang3[1].split(":")[0]),
        Number(ngayGioDauThang3[1].split(":")[1])
      );
      const ngayDauThangTu = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang4[0].split("/")[1]) - 1,
        Number(ngayGioDauThang4[0].split("/")[0]),
        Number(ngayGioDauThang4[1].split(":")[0]),
        Number(ngayGioDauThang4[1].split(":")[1])
      );
      const ngayDauThangNam = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang5[0].split("/")[1]) - 1,
        Number(ngayGioDauThang5[0].split("/")[0]),
        Number(ngayGioDauThang5[1].split(":")[0]),
        Number(ngayGioDauThang5[1].split(":")[1])
      );
      const ngayDauThangSau = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang6[0].split("/")[1]) - 1,
        Number(ngayGioDauThang6[0].split("/")[0]),
        Number(ngayGioDauThang6[1].split(":")[0]),
        Number(ngayGioDauThang6[1].split(":")[1])
      );
      const ngayDauThangBay = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang7[0].split("/")[1]) - 1,
        Number(ngayGioDauThang7[0].split("/")[0]),
        Number(ngayGioDauThang7[1].split(":")[0]),
        Number(ngayGioDauThang7[1].split(":")[1])
      );
      const ngayDauThangTam = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang8[0].split("/")[1]) - 1,
        Number(ngayGioDauThang8[0].split("/")[0]),
        Number(ngayGioDauThang8[1].split(":")[0]),
        Number(ngayGioDauThang8[1].split(":")[1])
      );
      const ngayDauThangChin = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang9[0].split("/")[1]) - 1,
        Number(ngayGioDauThang9[0].split("/")[0]),
        Number(ngayGioDauThang9[1].split(":")[0]),
        Number(ngayGioDauThang9[1].split(":")[1])
      );
      const ngayDauThangMuoi = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang10[0].split("/")[1]) - 1,
        Number(ngayGioDauThang10[0].split("/")[0]),
        Number(ngayGioDauThang10[1].split(":")[0]),
        Number(ngayGioDauThang10[1].split(":")[1])
      );
      const ngayDauThangMuoiMot = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang11[0].split("/")[1]) - 1,
        Number(ngayGioDauThang11[0].split("/")[0]),
        Number(ngayGioDauThang11[1].split(":")[0]),
        Number(ngayGioDauThang11[1].split(":")[1])
      );
      const ngayDauThangMuoiHai = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang12[0].split("/")[1]) - 1,
        Number(ngayGioDauThang12[0].split("/")[0]),
        Number(ngayGioDauThang12[1].split(":")[0]),
        Number(ngayGioDauThang12[1].split(":")[1])
      );
      let lichTietKhi = [];
      lichTietKhi.push(
        ngayDauThangMot,
        ngayDauThangHai,
        ngayDauThangBa,
        ngayDauThangTu,
        ngayDauThangNam,
        ngayDauThangSau,
        ngayDauThangBay,
        ngayDauThangTam,
        ngayDauThangChin,
        ngayDauThangMuoi,
        ngayDauThangMuoiMot,
        ngayDauThangMuoiHai
      );
      tietKhiDate = lichTietKhi;
    } else {
      let ngayGioDauThang1 = excelLichvanNien[thangLe][2]
        .split(" - ")[0]
        .split(" ");

      let ngayGioDauThang3 = excelLichvanNien[thangLe][6]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang5 = excelLichvanNien[thangLe][10]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang7 = excelLichvanNien[thangLe][14]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang9 = excelLichvanNien[thangLe][18]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang11 = excelLichvanNien[thangLe][22]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang2 = excelLichvanNien[thangChan][2]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang4 = excelLichvanNien[thangChan][6]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang6 = excelLichvanNien[thangChan][10]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang8 = excelLichvanNien[thangChan][14]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang10 = excelLichvanNien[thangChan][18]
        .split(" - ")[0]
        .split(" ");
      let ngayGioDauThang12 = excelLichvanNien[thangChan][22]
        .split(" - ")[0]
        .split(" ");

      const ngayDauThangMot = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang1[0].split("/")[1]) - 1,
        Number(ngayGioDauThang1[0].split("/")[0]),
        Number(ngayGioDauThang1[1].split(":")[0]),
        Number(ngayGioDauThang1[1].split(":")[1])
      );

      const ngayDauThangHai = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang2[0].split("/")[1]) - 1,
        Number(ngayGioDauThang2[0].split("/")[0]),
        Number(ngayGioDauThang2[1].split(":")[0]),
        Number(ngayGioDauThang2[1].split(":")[1])
      );
      const ngayDauThangBa = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang3[0].split("/")[1]) - 1,
        Number(ngayGioDauThang3[0].split("/")[0]),
        Number(ngayGioDauThang3[1].split(":")[0]),
        Number(ngayGioDauThang3[1].split(":")[1])
      );
      const ngayDauThangTu = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang4[0].split("/")[1]) - 1,
        Number(ngayGioDauThang4[0].split("/")[0]),
        Number(ngayGioDauThang4[1].split(":")[0]),
        Number(ngayGioDauThang4[1].split(":")[1])
      );
      const ngayDauThangNam = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang5[0].split("/")[1]) - 1,
        Number(ngayGioDauThang5[0].split("/")[0]),
        Number(ngayGioDauThang5[1].split(":")[0]),
        Number(ngayGioDauThang5[1].split(":")[1])
      );
      const ngayDauThangSau = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang6[0].split("/")[1]) - 1,
        Number(ngayGioDauThang6[0].split("/")[0]),
        Number(ngayGioDauThang6[1].split(":")[0]),
        Number(ngayGioDauThang6[1].split(":")[1])
      );
      const ngayDauThangBay = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang7[0].split("/")[1]) - 1,
        Number(ngayGioDauThang7[0].split("/")[0]),
        Number(ngayGioDauThang7[1].split(":")[0]),
        Number(ngayGioDauThang7[1].split(":")[1])
      );
      const ngayDauThangTam = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang8[0].split("/")[1]) - 1,
        Number(ngayGioDauThang8[0].split("/")[0]),
        Number(ngayGioDauThang8[1].split(":")[0]),
        Number(ngayGioDauThang8[1].split(":")[1])
      );
      const ngayDauThangChin = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang9[0].split("/")[1]) - 1,
        Number(ngayGioDauThang9[0].split("/")[0]),
        Number(ngayGioDauThang9[1].split(":")[0]),
        Number(ngayGioDauThang9[1].split(":")[1])
      );
      const ngayDauThangMuoi = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang10[0].split("/")[1]) - 1,
        Number(ngayGioDauThang10[0].split("/")[0]),
        Number(ngayGioDauThang10[1].split(":")[0]),
        Number(ngayGioDauThang10[1].split(":")[1])
      );
      const ngayDauThangMuoiMot = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang11[0].split("/")[1]) - 1,
        Number(ngayGioDauThang11[0].split("/")[0]),
        Number(ngayGioDauThang11[1].split(":")[0]),
        Number(ngayGioDauThang11[1].split(":")[1])
      );
      const ngayDauThangMuoiHai = new Date(
        timeYear.getFullYear(),
        Number(ngayGioDauThang12[0].split("/")[1]) - 1,
        Number(ngayGioDauThang12[0].split("/")[0]),
        Number(ngayGioDauThang12[1].split(":")[0]),
        Number(ngayGioDauThang12[1].split(":")[1])
      );
      let lichTietKhi = [];
      lichTietKhi.push(
        ngayDauThangMot,
        ngayDauThangHai,
        ngayDauThangBa,
        ngayDauThangTu,
        ngayDauThangNam,
        ngayDauThangSau,
        ngayDauThangBay,
        ngayDauThangTam,
        ngayDauThangChin,
        ngayDauThangMuoi,
        ngayDauThangMuoiMot,
        ngayDauThangMuoiHai
      );
      tietKhiDate = lichTietKhi;
    }
  };

  const get24TietKhiOfYear = (tempTime) => {
    const tempDateTime = new Date(tempTime);

    const year = tempDateTime.getFullYear();

    const distance = tempDateTime?.getFullYear() - 1800;

    const dongNgayDauThangLe = distance * 42 + 36;

    const dongNgayCuoiThangLe = distance * 42 + 37;

    const dongNgayDauThangChan = distance * 42 + 38;

    const dongNgayCuoiThangChan = distance * 42 + 39;

    if (year <= 1800 && year >= 1583) {
      const cuoiThangLe = Const.tietKhi_1583_1800_cuoiThangLe;

      const dauThangChan = Const.tietKhi_1583_1800_thangChan;

      const dauThangLe = Const.tietKhi_1583_1800_thangLe;

      const cuoiThangChan = Const.tietKhi_1583_1800_cuoiThangChan;

      tietKhiInYear = [
        dauThangLe[0]?.split(" - "),
        cuoiThangLe[0]?.split(" - "),
        dauThangChan[0]?.split(" - "),
        cuoiThangChan[0]?.split(" - "),
        dauThangLe[1]?.split(" - "),
        cuoiThangLe[1]?.split(" - "),
        dauThangChan[1]?.split(" - "),
        cuoiThangChan[1]?.split(" - "),
        dauThangLe[2]?.split(" - "),
        cuoiThangLe[2]?.split(" - "),
        dauThangChan[2]?.split(" - "),
        cuoiThangChan[2]?.split(" - "),
        dauThangLe[3]?.split(" - "),
        cuoiThangLe[3]?.split(" - "),
        dauThangChan[3]?.split(" - "),
        cuoiThangChan[3]?.split(" - "),
        dauThangLe[4]?.split(" - "),
        cuoiThangLe[4]?.split(" - "),
        dauThangChan[4]?.split(" - "),
        cuoiThangChan[4]?.split(" - "),
        dauThangLe[5]?.split(" - "),
        cuoiThangLe[5]?.split(" - "),
        dauThangChan[5]?.split(" - "),
        cuoiThangChan[5]?.split(" - "),
      ];
    } else if (year >= 0 && year <= 1582) {
      const cuoiThangLe = Const.tietKhi_0_1582_cuoiThangLe;

      const dauThangChan = Const.tietKhi_0_1582_thangChan;

      const dauThangLe = Const.tietKhi_0_1582_thangLe;

      const cuoiThangChan = Const.tietKhi_0_1582_cuoiThangChan;

      tietKhiInYear = [
        dauThangLe[0]?.split(" - "),
        cuoiThangLe[0]?.split(" - "),
        dauThangChan[0]?.split(" - "),
        cuoiThangChan[0]?.split(" - "),
        dauThangLe[1]?.split(" - "),
        cuoiThangLe[1]?.split(" - "),
        dauThangChan[1]?.split(" - "),
        cuoiThangChan[1]?.split(" - "),
        dauThangLe[2]?.split(" - "),
        cuoiThangLe[2]?.split(" - "),
        dauThangChan[2]?.split(" - "),
        cuoiThangChan[2]?.split(" - "),
        dauThangLe[3]?.split(" - "),
        cuoiThangLe[3]?.split(" - "),
        dauThangChan[3]?.split(" - "),
        cuoiThangChan[3]?.split(" - "),
        dauThangLe[4]?.split(" - "),
        cuoiThangLe[4]?.split(" - "),
        dauThangChan[4]?.split(" - "),
        cuoiThangChan[4]?.split(" - "),
        dauThangLe[5]?.split(" - "),
        cuoiThangLe[5]?.split(" - "),
        dauThangChan[5]?.split(" - "),
        cuoiThangChan[5]?.split(" - "),
      ];
    } else {
      const cuoiThangLe = excelLichvanNien[dongNgayCuoiThangLe];

      const dauThangChan = excelLichvanNien[dongNgayDauThangChan];

      const dauThangLe = excelLichvanNien[dongNgayDauThangLe];

      const cuoiThangChan = excelLichvanNien[dongNgayCuoiThangChan];

      tietKhiInYear = [
        dauThangLe[1]?.split(" - "),
        cuoiThangLe[1]?.split(" - "),
        dauThangChan[1]?.split(" - "),
        cuoiThangChan[1]?.split(" - "),
        dauThangLe[5]?.split(" - "),
        cuoiThangLe[5]?.split(" - "),
        dauThangChan[5]?.split(" - "),
        cuoiThangChan[5]?.split(" - "),
        dauThangLe[9]?.split(" - "),
        cuoiThangLe[9]?.split(" - "),
        dauThangChan[9]?.split(" - "),
        cuoiThangChan[9]?.split(" - "),
        dauThangLe[13]?.split(" - "),
        cuoiThangLe[13]?.split(" - "),
        dauThangChan[13]?.split(" - "),
        cuoiThangChan[13]?.split(" - "),
        dauThangLe[17]?.split(" - "),
        cuoiThangLe[17]?.split(" - "),
        dauThangChan[17]?.split(" - "),
        cuoiThangChan[17]?.split(" - "),
        dauThangLe[21]?.split(" - "),
        cuoiThangLe[21]?.split(" - "),
        dauThangChan[21]?.split(" - "),
        cuoiThangChan[21]?.split(" - "),
      ];
    }
  };

  const getCorrecTietKhi = (tempTime) => {
    let day = tempTime.getDate();
    let month = tempTime.getMonth();
    let year =
      tempTime < tietKhiDate[1]
        ? tempTime.getFullYear() - 1
        : tempTime.getFullYear();

    switch (tempTime.getMonth()) {
      // tháng 1 dương lịch nếu sau ngày đầu tháng 1 thì là tháng 12 năm ngoái
      //input ngày 1 tháng 1 2020
      // out put ngày 1 tháng 12 2019
      case 0:
        if (tempTime < tietKhiDate[0]) {
          month = 11;
        }
        break;
      case 1:
        // trước ngày lập xuân thì là tháng 12 của năm ngoái, ngược lại là tháng 1 năm sau
        if (tempTime < tietKhiDate[1]) {
          month = month - 1;
        }
        break;
      case 2:
        if (tempTime < tietKhiDate[2]) {
          month = month - 1;
        }
        break;
      case 3:
        if (tempTime < tietKhiDate[3]) {
          month = month - 1;
        }
        break;
      case 4:
        if (tempTime < tietKhiDate[4]) {
          month = month - 1;
        }
        break;
      case 5:
        if (tempTime < tietKhiDate[5]) {
          month = month - 1;
        }
        break;
      case 6:
        if (tempTime < tietKhiDate[6]) {
          month = month - 1;
        }
        break;
      case 7:
        if (tempTime < tietKhiDate[7]) {
          month = month - 1;
        }
        break;
      case 8:
        if (tempTime < tietKhiDate[8]) {
          month = month - 1;
        }
        break;
      case 9:
        if (tempTime < tietKhiDate[9]) {
          month = month - 1;
        }
        break;
      case 10:
        if (tempTime < tietKhiDate[10]) {
          month = month - 1;
        }
        break;
      case 11:
        if (tempTime < tietKhiDate[11]) {
          month = month - 1;
        }
        break;
      default:
        break;
    }
    return new Date(year, month, day);
  };

  async function loadExcel() {
    await fetch("hitek_lich_van_nien_update.xlsx")
      .then((response) => {
        return response.arrayBuffer();
      })
      .then((ab) => {
        const wb = XLSX.read(ab, { type: "array" });
        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

        setExcelLichvanNien(data);
      });

    await fetch("data_nguoi_noi_tieng_v2.xlsx")
      .then((response) => {
        return response.arrayBuffer();
      })
      .then((ab) => {
        const wb = XLSX.read(ab, { type: "array" });
        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
        let dataJson = {};
        let arrData = [];
        for (let i = 1; i < data.length; i++) {
          dataJson = {
            stt: data[i][0],
            chuVietTat: data[i][1],
            chuVietTatCon: data[i][2],
            name: data[i][3],
            gender: data[i][4],
            city: data[i][5],
            hour: data[i][6],
            minutes: data[i][7],
            day: data[i][8],
            month: data[i][9],
            year: data[i][10],
          };
          arrData = [...arrData, dataJson];
        }
        setExcelCelerity(arrData);
      })
      .catch((error) => {
        alert("Có lỗi xảy ra khi setup dữ liệu: " + error.toString());
        console.log(error);
      });
  }

  const handleSearch = (e) => {
    searchTemp.current = e.target.value;
  };
  const handleClickSearch = (e) => {
    searchTemp.current = e.target.textContent;
  };

  const handleSearchClick = async () => {
    console.log("click p", searchTemp.current);
    const result = excelCelerity.filter(
      (data) => data.name === searchTemp.current
    );

    setDataCelerity(result);
    handleResult(result);
  };

  const handleLetter = (event) => {
    setLetter(event.target.textContent);
    const items = document.querySelectorAll(".parent");
    const items_ = document.querySelectorAll(".child");
    const listName = document.querySelectorAll(".list-name-child");
    for (let i = 0; i < items_.length; i++) {
      if (items_[i].classList.contains("show-letter"))
        items_[i].classList.remove("show-letter");
    }
    for (let i = 0; i < listName.length; i++) {
      listName[i].remove();
    }

    for (let i = 0; i < items.length; i++) {
      if (items[i].classList.contains("show-letter"))
        items[i].classList.remove("show-letter");
    }
    event.target.classList.add("show-letter");
  };

  const handleChildClick = async (event) => {
    const childItem = event.target.nextElementSibling;
    const divName = document.querySelectorAll(".list-name-child");

    if (childItem) childItem.remove();

    const item = event.target.parentNode;
    if (item.classList.contains("show-letter") && childItem) {
      childItem.remove();
      item.classList.remove("show-letter");
    } else {
      const items = document.querySelectorAll(".child");
      for (let i = 0; i < items.length; i++) {
        if (items[i].classList.contains("show-letter"))
          items[i].classList.remove("show-letter");
      }
      for (let i = 0; i < divName.length; i++) {
        divName[i].remove();
      }

      item.classList.add("show-letter");
    }

    if (!childItem) {
      const text = event.target.textContent;
      const div = document.createElement("div");
      div.classList.add("list-name-child");
      div.style.position = "absolute";
      div.style.background = "#fff";
      div.style.width = "200px";
      div.style.maxHeight = "310px";
      div.style.padding = "10px";
      div.style.zIndex = "998";
      div.style.color = "black";
      div.style.border = "1px solid";
      div.style.fontSize = "1rem";
      div.style.left = "50%";
      div.style.transform = "translateX(-50%)";
      div.style.overflow = "auto";
      const results = excelCelerity.filter(
        (data) => data.chuVietTatCon === text
      );
      let listName = await results.map((data) => {
        let p = document.createElement("p");
        p.style.transition = "all 0.3s";
        p.style.marginBottom = 0;
        p.textContent = data.name;
        value.current = data.name;
        p.addEventListener("click", clickP);

        return p;
      });

      if (listName.length > 0) div.append(...listName);
      else {
        let p = document.createElement("p");
        p.textContent = "Không có dữ liệu";
        p.style.marginBottom = 0;
        div.append(p);
      }
      item.append(div);
    } else {
      childItem.remove();
    }
  };

  const clickP = async (e) => {
    const divName = document.querySelectorAll(".list-name-child");
    searchTemp.current = e.target.textContent;

    for (let i = 0; i < divName.length; i++) {
      divName[i].remove();
    }

    handleSearchClick();
  };

  return (
    <div id="bat_tu_celerity" style={{ zIndex: "995", position: "relative" }}>
      <div
        style={{ marginTop: "25px" }}
        id="than-sat-component"
        className="than_sat_title hide-to-print"
      >
        <h3>BÁT TỰ NGƯỜI NỔI TIẾNG</h3>
      </div>

      <div className="hide-to-print" style={{ marginTop: "25px" }}>
        {" "}
        {/* div search */}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              border: "1px solid #e8eaed",
              background: "#fff",
              zIndex: "999",
            }}
          >
            <div style={{ width: "350px" }}>
              <input
                value={searchTemp.current}
                onChange={(event) => handleSearch(event)}
                style={{
                  width: "350px",
                  height: "50px",
                  position: "relative",
                  zIndex: "999",
                  border: "none",
                  outline: "none",
                  margin: "0 15px",
                }}
              />
            </div>

            {searchResults.length > 0 ? (
              <>
                <div
                  style={{
                    borderTop: "1px solid #e8eaed",
                    margin: "0 15px",
                    marginTop: "5px",
                  }}
                />

                <div
                  style={{
                    position: "absolute",
                    background: "#fff",
                    width: "350px",
                    height: "auto",
                    maxHeight: "250px",
                    overflow: "scroll",
                  }}
                >
                  {searchResults.map((data) => (
                    <p onClick={handleClickSearch} className="item-search">
                      {data.name}
                    </p>
                  ))}
                </div>
              </>
            ) : (
              ""
            )}
          </div>

          <Button
            onClick={handleSearchClick}
            style={{
              width: "150px",
              height: "50px",
              color: "#fff",
              position: "relative",
              zIndex: "999",
              background: "rgb(27, 97, 166)",
              fontWeight: "bold",
            }}
          >
            Tìm Kiếm
          </Button>
        </div>
      </div>

      <div
        className="hide-to-print"
        style={{
          textAlign: "center",
          position: "relative",
          zIndex: "997",
          marginTop: "25px",
        }}
      >
        <span
          onClick={handleLetter}
          className="search-letter parent show-letter"
        >
          A
        </span>
        <span onClick={handleLetter} className="search-letter parent">
          B
        </span>
        <span onClick={handleLetter} className="search-letter parent">
          C
        </span>
        <span onClick={handleLetter} className="search-letter parent">
          D
        </span>
        <span onClick={handleLetter} className="search-letter parent">
          E
        </span>
        <span onClick={handleLetter} className="search-letter parent">
          F
        </span>
        <span onClick={handleLetter} className="search-letter parent">
          G
        </span>
        <span onClick={handleLetter} className="search-letter parent">
          H
        </span>
        <span onClick={handleLetter} className="search-letter parent">
          J
        </span>
        <span onClick={handleLetter} className="search-letter parent">
          K
        </span>
        <span onClick={handleLetter} className="search-letter parent">
          L
        </span>
        <span onClick={handleLetter} className="search-letter parent">
          M
        </span>
        <span onClick={handleLetter} className="search-letter parent">
          N
        </span>
        <span onClick={handleLetter} className="search-letter parent">
          O
        </span>
        <span onClick={handleLetter} className="search-letter parent">
          P
        </span>
        <span onClick={handleLetter} className="search-letter parent">
          Q
        </span>
        <span onClick={handleLetter} className="search-letter parent">
          R
        </span>
        <span onClick={handleLetter} className="search-letter parent">
          S
        </span>
        <span onClick={handleLetter} className="search-letter parent">
          T
        </span>
        <span onClick={handleLetter} className="search-letter parent">
          U
        </span>
        <span onClick={handleLetter} className="search-letter parent">
          V
        </span>
        <span onClick={handleLetter} className="search-letter parent">
          W
        </span>
        <span onClick={handleLetter} className="search-letter parent">
          X
        </span>
        <span onClick={handleLetter} className="search-letter parent">
          Y
        </span>
        <span onClick={handleLetter} className="search-letter parent">
          Z
        </span>
      </div>

      <div
        className="hide-to-print"
        style={{
          textAlign: "center",
          position: "relative",
          zIndex: "997",
          marginTop: "15px",
        }}
      >
        <div className="search-letter child">
          <span onClick={handleChildClick}>{letter}a</span>
        </div>
        <div className="search-letter child">
          <span onClick={handleChildClick}>{letter}b</span>
        </div>
        <div className="search-letter child">
          <span onClick={handleChildClick}>{letter}c</span>
        </div>
        <div className="search-letter child">
          <span onClick={handleChildClick}>{letter}d</span>
        </div>
        <div className="search-letter child">
          <span onClick={handleChildClick}>{letter}e</span>
        </div>
        <div className="search-letter child">
          <span onClick={handleChildClick}>{letter}f</span>
        </div>
        <div className="search-letter child">
          <span onClick={handleChildClick}>{letter}g</span>
        </div>
        <div className="search-letter child">
          <span onClick={handleChildClick}>{letter}h</span>
        </div>
        <div className="search-letter child">
          <span onClick={handleChildClick}>{letter}i</span>
        </div>
        <div className="search-letter child">
          <span onClick={handleChildClick}>{letter}j</span>
        </div>
        <div className="search-letter child">
          <span onClick={handleChildClick}>{letter}k</span>
        </div>
        <div className="search-letter child">
          <span onClick={handleChildClick}>{letter}l</span>
        </div>
        <div className="search-letter child">
          <span onClick={handleChildClick}>{letter}m</span>
        </div>
        <div className="search-letter child">
          <span onClick={handleChildClick}>{letter}n</span>
        </div>
        <div className="search-letter child">
          <span onClick={handleChildClick}>{letter}o</span>
        </div>
        <div className="search-letter child">
          <span onClick={handleChildClick}>{letter}p</span>
        </div>
        <div className="search-letter child">
          <span onClick={handleChildClick}>{letter}q</span>
        </div>
        <div className="search-letter child">
          <span onClick={handleChildClick}>{letter}r</span>
        </div>
        <div className="search-letter child">
          <span onClick={handleChildClick}>{letter}s</span>
        </div>
        <div className="search-letter child">
          <span onClick={handleChildClick}>{letter}t</span>
        </div>
        <div className="search-letter child">
          <span onClick={handleChildClick}>{letter}u</span>
        </div>
        <div className="search-letter child">
          <span onClick={handleChildClick}>{letter}v</span>
        </div>
        <div className="search-letter child">
          <span onClick={handleChildClick}>{letter}w</span>
        </div>

        <div className="search-letter child">
          <span onClick={handleChildClick}>{letter}x</span>
        </div>

        <div className="search-letter child">
          <span onClick={handleChildClick}>{letter}y</span>
        </div>
        <div className="search-letter child">
          <span onClick={handleChildClick}>{letter}z</span>
        </div>
      </div>

      <div className="watermark">
        <img src={logo} />
      </div>

      <div
        style={{ display: "block", zIndex: "-1" }}
        className="title__battu"
        id="hide-title-celeb"
      >
        {searchTemp.current}
      </div>

      <div style={{ display: "block" }} className="title__print">
        CÔNG TY TNHH TƯỜNG MINH PHONG THỦY
      </div>

      <div style={{ display: "block" }} className="sub__title">
        Địa chỉ: 4A Đường D7, Khu dân cư Nam Long, Phước Long B, Quận 9
      </div>
      <hr style={{ display: "block" }} className="hr__print" />
      <div style={{ display: "block", zIndex: "-1" }} className="title__battu">
        Phân tích bát tự
      </div>

      <div style={{ display: "block", zIndex: "-1" }} id="info__printtt">
        <div id="info__print">
          Họ và tên: <span className="fw__bold"> {searchTemp.current}</span> |
          Ngày giờ sinh:{" "}
          <span className="fw__bold">
            {" "}
            {`${
              dataCelerity[0]?.hour
                ? String(dataCelerity[0]?.hour).padStart(2, "0")
                : "00"
            }:${
              dataCelerity[0]?.minutes
                ? String(dataCelerity[0]?.minutes).padStart(2, "0")
                : "00"
            } ${dataCelerity[0]?.day || " "}-${dataCelerity[0]?.month || " "}-${
              dataCelerity[0]?.year || " "
            }`}
          </span>
        </div>
        <div id="info__printt">
          Giới tính: <span className="fw__bold"> {gender}</span> | Nơi sinh:{" "}
          <span className="fw__bold"> {dataCelerity[0]?.city}</span>
        </div>
      </div>

      {show && noLogin ? (
        <div>
          <div className="main__content" id="page-break">
            <div className="column__  margin__print2" style={{ width: "17%" }}>
              <Table style={{ width: "100%", marginTop: "58px" }}>
                <thead>
                  <tr style={{ textAlign: "center" }}>
                    <th
                      id="bg__orgrane"
                      className="bg__orgrane"
                      style={{
                        width: "198px",
                        border: "1px solid black",
                        fontSize: "18px",
                        color: "#FFFFFF",
                      }}
                      colspan="2"
                    >
                      Thần Sát
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ textAlign: "center" }}>
                    <td
                      style={{
                        width: "103px",
                        border: "1px solid black",
                        fontSize: "12px",
                      }}
                    >
                      Tướng Tinh
                    </td>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                      }}
                    >
                      {TSDiaChiArr[0]?.giap}
                    </td>
                  </tr>

                  <tr style={{ textAlign: "center" }}>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                      }}
                    >
                      Quý Nhân
                    </td>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                      }}
                    >
                      {TSThienCanArr[1]?.giap}
                    </td>
                  </tr>

                  <tr style={{ textAlign: "center" }}>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                      }}
                    >
                      Văn Xương
                    </td>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                        textAlign: "center",
                      }}
                    >
                      {TSThienCanArr[0]?.giap}
                    </td>
                  </tr>

                  <tr style={{ textAlign: "center" }}>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                      }}
                    >
                      Đào Hoa
                    </td>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                        textAlign: "center",
                      }}
                    >
                      {TSDiaChiArr[1]?.giap}
                    </td>
                  </tr>

                  <tr style={{ textAlign: "center" }}>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                      }}
                    >
                      Dịch Mã
                    </td>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                      }}
                    >
                      {TSDiaChiArr[4]?.giap}
                    </td>
                  </tr>

                  <tr style={{ textAlign: "center" }}>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                      }}
                    >
                      Quả Tú
                    </td>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                      }}
                    >
                      {TSDiaChiArr[5]?.giap}
                    </td>
                  </tr>

                  <tr style={{ textAlign: "center" }}>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                      }}
                    >
                      Cô Thần
                    </td>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                        textAlign: "center",
                      }}
                    >
                      {TSDiaChiArr[2]?.giap}
                    </td>
                  </tr>

                  <tr style={{ textAlign: "center" }}>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                      }}
                    >
                      Kiếp Sát
                    </td>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                        textAlign: "center",
                      }}
                    >
                      {TSDiaChiArr[3]?.giap}
                    </td>
                  </tr>
                  <tr style={{ textAlign: "center" }}>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                      }}
                    >
                      Không Vong
                    </td>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                        textAlign: "center",
                      }}
                    >
                      {stateKhongVong}
                    </td>
                  </tr>
                </tbody>
              </Table>
              <Table>
                <thead>
                  <tr style={{ textAlign: "center" }}>
                    <th
                      id="bg__orgranetc"
                      style={{
                        width: "198px",
                        border: "1px solid black",
                        background: "#ff7400",
                        fontSize: "18px",
                        color: "white",
                      }}
                    >
                      Thai <br /> Cung
                    </th>
                    <th
                      id="bg__orgranemc"
                      style={{
                        width: "198px",
                        border: "1px solid black",
                        background: "#ff7400",
                        fontSize: "18px",
                        color: "white",
                      }}
                    >
                      Mệnh <br /> Cung
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ textAlign: "center" }}>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                      }}
                    >
                      {thaiCungThienCan}
                    </td>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                      }}
                    >
                      {menhCungTC}
                    </td>
                  </tr>
                  <tr style={{ textAlign: "center" }}>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                      }}
                    >
                      {thaiCungDiaChi}
                    </td>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                      }}
                    >
                      {menhCungDC}
                    </td>
                  </tr>
                </tbody>
              </Table>
              <p style={{ pageBreakAfter: "always" }}></p>
              <Table id="table_huong">
                <thead>
                  <tr style={{ textAlign: "center" }}>
                    <th
                      id="bg__orgrane"
                      style={{
                        width: "198px",
                        border: "1px solid black",
                        background: "#ff7400",
                        fontSize: "18px",
                        color: "white",
                      }}
                      colspan="2"
                    >
                      Hướng Tốt
                    </th>
                    <th
                      id="bg__orgrane_hx"
                      style={{
                        width: "198px",
                        border: "1px solid black",
                        background: "#ff7400",
                        fontSize: "18px",
                        color: "white",
                      }}
                      colspan="2"
                    >
                      Hướng Xấu
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ textAlign: "center" }}>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                      }}
                    >
                      {huongTot?.huongA}
                    </td>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                      }}
                    >
                      {huongTot?.huongB}
                    </td>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                      }}
                    >
                      {huongXau?.huongA}
                    </td>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                      }}
                    >
                      {huongXau?.huongB}
                    </td>
                  </tr>
                  <tr style={{ textAlign: "center" }}>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                      }}
                    >
                      {huongTot?.huongC}
                    </td>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                      }}
                    >
                      {huongTot?.huongD}
                    </td>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                      }}
                    >
                      {huongXau?.huongC}
                    </td>
                    <td
                      style={{
                        width: "99px",
                        border: "1px solid black",
                        fontSize: "12px",
                      }}
                    >
                      {huongXau?.huongD}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>

            <div id="box__" className="box__right margin__print">
              <Table bordered hover>
                <thead>
                  <th
                    className="hide__border__td head_box_celerity"
                    style={{ border: "1px solid transparent", height: "59px" }}
                    colspan="3"
                  ></th>

                  <th
                    className="hide__border__td"
                    style={{ border: "1px solid transparent", display: "none" }}
                    colspan="3"
                  >
                    <div
                      className="bg__"
                      style={{
                        marginBottom: "9px",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <button
                        className="hide-to-print"
                        onClick={handlePrevDay}
                        style={{
                          width: "50%",
                          border: "none",
                          borderRight: "1px solid white",
                          background: "transparent",
                        }}
                      >
                        <VscTriangleLeft style={{ color: "white" }} />
                      </button>
                      <button
                        className="hide-to-print"
                        onClick={handleNextDay}
                        style={{
                          width: "50%",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <VscTriangleRight style={{ color: "white" }} />
                      </button>
                    </div>
                  </th>

                  <th
                    className="hide__border__td"
                    style={{ border: "1px solid transparent", display: "none" }}
                    colspan="3"
                  >
                    <div
                      className="bg__"
                      style={{
                        marginBottom: "9px",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <button
                        className="hide-to-print"
                        onClick={handlePrevMonth}
                        style={{
                          width: "50%",
                          border: "none",
                          borderRight: "1px solid white",
                          background: "transparent",
                        }}
                      >
                        <VscTriangleLeft style={{ color: "white" }} />
                      </button>
                      <button
                        className="hide-to-print"
                        onClick={handleNextMonth}
                        style={{
                          width: "50%",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <VscTriangleRight style={{ color: "white" }} />
                      </button>
                    </div>
                  </th>

                  <th
                    className="hide__border__td"
                    style={{ border: "1px solid transparent", display: "none" }}
                    colspan="3"
                  >
                    <div
                      className="bg__"
                      style={{
                        marginBottom: "9px",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <button
                        className="hide-to-print"
                        onClick={handlePrevYear}
                        style={{
                          width: "50%",
                          border: "none",
                          borderRight: "1px solid white",
                          background: "transparent",
                        }}
                      >
                        <VscTriangleLeft style={{ color: "white" }} />
                      </button>
                      <button
                        className="hide-to-print"
                        onClick={handleNextYear}
                        style={{
                          width: "50%",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <VscTriangleRight style={{ color: "white" }} />
                      </button>
                    </div>
                  </th>
                </thead>

                <tbody>
                  <tr
                    className="clr__print"
                    style={{
                      textAlign: "center",
                      fontSize: "22px",
                      color: "white",
                    }}
                  >
                    <th
                      id="bg__h"
                      colspan="3"
                      style={{
                        width: "150px",
                        border: "3px solid #000000",
                        background: "#1B61A6",
                        fontWeight: "normal",
                        height: "64px",
                      }}
                    >
                      {String(gioSolar).padStart(2, "0")} :{" "}
                      {String(solarphut).padStart(2, "0")}
                    </th>

                    <th
                      id="bg__d"
                      colspan="3"
                      style={{
                        width: "150px",
                        border: "3px solid #000000",
                        background: "#1B61A6",
                        fontWeight: "normal",
                      }}
                    >
                      {" "}
                      {dayShow}
                    </th>
                    <th
                      id="bg__m"
                      colspan="3"
                      style={{
                        width: "150px",
                        border: "3px solid #000000",
                        background: "#1B61A6",
                        fontWeight: "normal",
                      }}
                    >
                      {" "}
                      {monthShow}
                    </th>
                    <th
                      id="bg__y"
                      colspan="3"
                      style={{
                        width: "150px",
                        border: "3px solid #000000",
                        background: "#1B61A6",
                        fontWeight: "normal",
                      }}
                    >
                      {" "}
                      {yearShow}
                    </th>
                    <th
                      id="bg__a"
                      colspan="3"
                      style={{
                        width: "58px",
                        border: "3px solid #000000",
                        background: "#1B61A6",
                      }}
                    >
                      {" "}
                    </th>
                  </tr>

                  <tr style={{ textAlign: "center" }}>
                    <th colspan="3" className="fz__25 clr__ border__">
                      <sup className="fz__15">{thapThanHour}</sup>
                      {thienCanHour}
                    </th>

                    <th
                      id="bg__tcd"
                      style={{ background: "#B2CFEA" }}
                      colspan="3"
                      className="fz__25 border__"
                    >
                      {thienCanDay}
                    </th>
                    <th colspan="3" className="fz__25 clr__ border__">
                      <sup className="fz__15">{thapThanMonth}</sup>
                      {thienCanMonth}
                    </th>

                    <th colspan="3" className="fz__25 clr__ border__">
                      <sup className="fz__15">{thapThanYear}</sup>
                      {thienCanYear}
                    </th>

                    <th
                      id="bg__tc"
                      rowSpan="2"
                      style={{ width: "58px", border: "3px solid #000000" }}
                      className="bg__ clr__white clr__print"
                    >
                      Thiên Can
                    </th>
                  </tr>
                  <tr style={{ textAlign: "center", fontSize: "18px" }}>
                    <th
                      style={{ fontWeight: "normal" }}
                      colspan="3"
                      className="border__"
                    >
                      {nguHanhHour}
                    </th>

                    <th
                      id="bg__nhd"
                      style={{ fontWeight: "normal", background: "#B2CFEA" }}
                      colspan="3"
                      className="border__"
                    >
                      {nguHanhDay}
                    </th>
                    <th
                      style={{ fontWeight: "normal" }}
                      colspan="3"
                      className="border__"
                    >
                      {nguHanhMonth}
                    </th>
                    <th
                      style={{ fontWeight: "normal" }}
                      colspan="3"
                      className="border__"
                    >
                      {nguHanhYear}
                    </th>
                  </tr>
                  {/* dia chi  */}
                  <tr style={{ textAlign: "center" }}>
                    {
                      <th colspan="3" className="fz__25 clr__ border__">
                        {diaChiHour}
                      </th>
                    }

                    <th
                      id="bg__dcd"
                      style={{ background: "#B2CFEA" }}
                      colspan="3"
                      className="fz__25 border__"
                    >
                      {diaChiDay}
                    </th>
                    <th colspan="3" className="fz__25 clr__ border__">
                      {diaChiMonth}
                    </th>

                    <th colspan="3" className="fz__25 clr__ border__">
                      {DiachiYear}
                    </th>

                    <th
                      id="bg__dc"
                      rowspan="1"
                      style={{ width: "58px", border: "3px solid #000000" }}
                      className="bg__ clr__white clr__print"
                    >
                      Địa Chi
                    </th>
                  </tr>

                  {/** tang can gio`*/}

                  <tr className="fz__18" style={{ textAlign: "center" }}>
                    {tangCanNguHanhHour2 ? (
                      <td
                        className="border__"
                        colspan="1"
                        style={{
                          verticalAlign: "middle",
                          padding: "0",
                          fontSize: "14px",
                        }}
                      >
                        <div>
                          <sup>{thapThanHour2}</sup>
                          {tangCanNguHanhHour2}
                        </div>
                        <div
                          classNamestyle={{
                            justifyContent: "center",
                            width: "47px",
                          }}
                        >
                          {tangCanNguHanhHour5}
                        </div>
                      </td>
                    ) : (
                      ""
                    )}

                    {tangCanNguHanhHour2 && tangCanNguHanhHour3 ? (
                      <th
                        id="tcbox"
                        className="border__"
                        colspan="1"
                        style={{
                          verticalAlign: "middle",
                          padding: "0",
                          fontSize: "14px",
                        }}
                      >
                        <div>
                          <sup>{thapThanHour1}</sup>
                          {tangCanNguHanhHour}
                        </div>
                        <div
                          style={{ justifyContent: "center", width: "47px" }}
                        >
                          {tangCanNguHanhHour4}
                        </div>
                      </th>
                    ) : tangCanNguHanhHour3 ? (
                      <th
                        id="tcbox"
                        className="border__"
                        colspan="2"
                        style={{ verticalAlign: "middle", padding: "0" }}
                      >
                        <div>
                          <sup>{thapThanHour1}</sup>
                          {tangCanNguHanhHour}
                        </div>
                        <div style={{ justifyContent: "center" }}>
                          {tangCanNguHanhHour4}
                        </div>
                      </th>
                    ) : (
                      <th
                        id="tcbox"
                        className="border__"
                        colspan="3"
                        style={{ verticalAlign: "middle", padding: "0" }}
                      >
                        <div>
                          <sup>{thapThanHour1}</sup>
                          {tangCanNguHanhHour}
                        </div>
                        <div style={{ justifyContent: "center" }}>
                          {tangCanNguHanhHour4}
                        </div>
                      </th>
                    )}

                    {tangCanNguHanhHour3 && tangCanNguHanhHour2 ? (
                      <td
                        className="border__"
                        colspan="1"
                        style={{
                          verticalAlign: "middle",
                          padding: "0",
                          fontSize: "14px",
                        }}
                      >
                        <div>
                          <sup>{thapThanHour3}</sup>
                          {tangCanNguHanhHour3}
                        </div>
                        <div style={{ justifyContent: "center" }}>
                          {tangCanNguHanhHour6}
                        </div>
                      </td>
                    ) : tangCanNguHanhHour3 && tangCanNguHanhHour ? (
                      <td
                        className="border__"
                        colspan="1"
                        style={{
                          verticalAlign: "middle",
                          padding: "0",
                          fontSize: "16px",
                        }}
                      >
                        <div>
                          <sup>{thapThanHour3}</sup>
                          {tangCanNguHanhHour3}
                        </div>
                        <div style={{ justifyContent: "center" }}>
                          {tangCanNguHanhHour6}
                        </div>
                      </td>
                    ) : (
                      ""
                    )}

                    {/** tang can gio`*/}

                    {/** tang can ngay`*/}

                    {tangCanNguHanhDay2 ? (
                      <td
                        id="bg__tcd"
                        style={{
                          background: "#B2CFEA",
                          verticalAlign: "middle",
                          padding: "0",
                          fontSize: "14px",
                        }}
                        className="border__"
                      >
                        <div>
                          <sup>{thapThanDay2}</sup>
                          {tangCanNguHanhDay2}
                        </div>
                        <div
                          style={{ justifyContent: "center", width: "47px" }}
                        >
                          {tangCanNguHanhDay5}
                        </div>
                      </td>
                    ) : (
                      ""
                    )}

                    {tangCanNguHanhDay2 && tangCanNguHanhDay3 ? (
                      <th
                        id="bg__tcd2"
                        colspan="1"
                        style={{
                          border: "none",
                          background: "#B2CFEA",
                          verticalAlign: "middle",
                          padding: "0",
                          fontSize: "14px",
                        }}
                      >
                        <div>
                          <sup>{thapThanDay1}</sup>
                          {tangCanNguHanhDay}
                        </div>
                        <div
                          style={{ justifyContent: "center", width: "47px" }}
                        >
                          {tangCanNguHanhDay4}
                        </div>
                      </th>
                    ) : tangCanNguHanhDay3 ? (
                      <th
                        id="bg__tcd2"
                        style={{
                          background: "#B2CFEA",
                          verticalAlign: "middle",
                          padding: "0",
                          fontSize: "16px",
                        }}
                        colspan="2"
                        className="border__"
                      >
                        <div>
                          <sup>{thapThanDay1}</sup>
                          {tangCanNguHanhDay}
                        </div>
                        <div style={{ justifyContent: "center" }}>
                          {tangCanNguHanhDay4}
                        </div>
                      </th>
                    ) : (
                      <th
                        id="bg__tcd2"
                        style={{ background: "#B2CFEA" }}
                        colspan="3"
                        className="border__"
                      >
                        <div>
                          <sup>{thapThanDay1}</sup>
                          {tangCanNguHanhDay}
                        </div>
                        <div style={{ justifyContent: "center" }}>
                          {tangCanNguHanhDay4}
                        </div>
                      </th>
                    )}

                    {tangCanNguHanhDay3 && tangCanNguHanhDay2 ? (
                      <td
                        id="bg__tcd3"
                        colspan="1"
                        style={{
                          background: "#B2CFEA",
                          verticalAlign: "middle",
                          padding: "0",
                          fontSize: "14px",
                        }}
                        className="border__"
                      >
                        <div>
                          <sup>{thapThanDay3}</sup>
                          {tangCanNguHanhDay3}
                        </div>
                        <div style={{ justifyContent: "center" }}>
                          {tangCanNguHanhDay6}
                        </div>
                      </td>
                    ) : tangCanNguHanhDay3 && tangCanNguHanhDay ? (
                      <td
                        id="bg__tcd3"
                        colspan="1"
                        style={{
                          background: "#B2CFEA",
                          verticalAlign: "middle",
                          padding: "0",
                          fontSize: "16px",
                        }}
                        className="border__"
                      >
                        <div>
                          <sup>{thapThanDay3}</sup>
                          {tangCanNguHanhDay3}
                        </div>
                        <div style={{ justifyContent: "center" }}>
                          {tangCanNguHanhDay6}
                        </div>
                      </td>
                    ) : (
                      ""
                    )}

                    {/** tang can ngay``*/}

                    {/** tang can thang*/}
                    {tangCanMonth2 ? (
                      <td
                        className="border__"
                        style={{
                          verticalAlign: "middle",
                          padding: "0",
                          fontSize: "14px",
                        }}
                      >
                        <div>
                          <sup>{thapThanMonth2}</sup>
                          {tangCanMonth2}
                        </div>
                        <div
                          style={{ justifyContent: "center", width: "47px" }}
                        >
                          {tangCanMonth5}
                        </div>
                      </td>
                    ) : (
                      ""
                    )}

                    {tangCanMonth2 && tangCanMonth3 ? (
                      <th
                        colspan="1"
                        style={{
                          border: "none",
                          verticalAlign: "middle",
                          padding: "0",
                          fontSize: "14px",
                        }}
                      >
                        <div>
                          <sup>{thapThanMonth1}</sup>
                          {tangCanMonth}
                        </div>
                        <div
                          style={{ justifyContent: "center", width: "47px" }}
                        >
                          {tangCanMonth4}
                        </div>
                      </th>
                    ) : tangCanMonth3 ? (
                      <th
                        colspan="2"
                        className="border__"
                        style={{
                          verticalAlign: "middle",
                          padding: "0",
                          fontSize: "16px",
                        }}
                      >
                        <div>
                          <sup>{thapThanMonth1}</sup>
                          {tangCanMonth}
                        </div>
                        <div style={{ justifyContent: "center" }}>
                          {tangCanMonth4}
                        </div>
                      </th>
                    ) : (
                      <th colspan="3" className="border__">
                        <div>
                          <sup>{thapThanMonth1}</sup>
                          {tangCanMonth}
                        </div>
                        <div style={{ justifyContent: "center" }}>
                          {tangCanMonth4}
                        </div>
                      </th>
                    )}

                    {tangCanMonth3 && tangCanMonth2 ? (
                      <td
                        className="border__"
                        style={{
                          verticalAlign: "middle",
                          padding: "0",
                          fontSize: "14px",
                        }}
                      >
                        <div>
                          <sup>{thapThanMonth3}</sup>
                          {tangCanMonth3}
                        </div>
                        <div
                          style={{ justifyContent: "center", width: "47px" }}
                        >
                          {tangCanMonth6}
                        </div>
                      </td>
                    ) : tangCanMonth3 && tangCanMonth ? (
                      <td
                        className="border__"
                        style={{
                          verticalAlign: "middle",
                          padding: "0",
                          fontSize: "16px",
                        }}
                      >
                        <div>
                          <sup>{thapThanMonth3}</sup>
                          {tangCanMonth3}
                        </div>
                        <div
                          style={{ justifyContent: "center", width: "47px" }}
                        >
                          {tangCanMonth6}
                        </div>
                      </td>
                    ) : (
                      ""
                    )}
                    {/** tang can nam*/}

                    {tangCanYear2 ? (
                      <td
                        className="border__"
                        style={{
                          verticalAlign: "middle",
                          padding: "0",
                          fontSize: "14px",
                        }}
                      >
                        <div>
                          <sup>{thapThanYear2}</sup>
                          {tangCanYear2}
                        </div>
                        <div
                          style={{ justifyContent: "center", width: "47px" }}
                        >
                          {tangCanYear5}
                        </div>
                      </td>
                    ) : (
                      ""
                    )}

                    {tangCanYear2 && tangCanYear3 ? (
                      <th
                        colspan="1"
                        className="border__"
                        style={{
                          verticalAlign: "middle",
                          padding: "0",
                          fontSize: "14px",
                        }}
                      >
                        <div>
                          <sup>{thapThanYear1}</sup>
                          {tangCanYear}
                        </div>
                        <div
                          style={{ justifyContent: "center", width: "47px" }}
                        >
                          {tangCanYear4}
                        </div>
                      </th>
                    ) : tangCanYear3 ? (
                      <th
                        colspan="2"
                        className="border__"
                        style={{
                          verticalAlign: "middle",
                          padding: "0",
                          fontSize: "16px",
                        }}
                      >
                        <div>
                          <sup>{thapThanYear1}</sup>
                          {tangCanYear}
                        </div>
                        <div style={{ justifyContent: "center" }}>
                          {tangCanYear4}
                        </div>
                      </th>
                    ) : (
                      <th colspan="3" className="border__">
                        <div>
                          <sup>{thapThanYear1}</sup>
                          {tangCanYear}
                        </div>
                        <div style={{ justifyContent: "center" }}>
                          {tangCanYear4}
                        </div>
                      </th>
                    )}

                    {tangCanYear3 && tangCanYear2 ? (
                      <td
                        className="border__"
                        style={{
                          verticalAlign: "middle",
                          padding: "0",
                          fontSize: "14px",
                        }}
                      >
                        <div>
                          <sup>{thapThanYear3}</sup>
                          {tangCanYear3}
                        </div>
                        <div
                          style={{ justifyContent: "center", width: "47px" }}
                        >
                          {tangCanYear6}
                        </div>
                      </td>
                    ) : tangCanYear3 && tangCanYear ? (
                      <td
                        className="border__"
                        style={{
                          verticalAlign: "middle",
                          padding: "0",
                          fontSize: "16px",
                        }}
                      >
                        <div>
                          <sup>{thapThanYear3}</sup>
                          {tangCanYear3}
                        </div>
                        <div style={{ justifyContent: "center" }}>
                          {tangCanYear6}
                        </div>
                      </td>
                    ) : (
                      ""
                    )}

                    {/** tang can nam*/}

                    <th
                      id="bg__tcc"
                      style={{ width: "58px", border: "3px solid #000000" }}
                      className="bg__ clr__white clr__print"
                    >
                      Tàng Can
                    </th>
                  </tr>

                  {/** nap am*/}
                  {checkNapAm ? (
                    <tr style={{ textAlign: "center" }}>
                      {
                        <th id="nabox" colspan="3" className="fz__18 border__">
                          {napAmHour}
                        </th>
                      }
                      <th
                        id="bg__nad"
                        style={{ background: "#B2CFEA" }}
                        colspan="3"
                        className="fz__18 border__"
                      >
                        {napAmDay}
                      </th>
                      <th colspan="3" className="fz__18 border__">
                        {napAmMonth}
                      </th>
                      <th colspan="3" className="fz__18 border__">
                        {napAmYear}
                      </th>
                      <th
                        id="bg__na"
                        style={{ width: "58px", border: "3px solid #000000" }}
                        className="bg__ clr__white clr__print"
                      >
                        Nạp Âm
                      </th>
                    </tr>
                  ) : (
                    ""
                  )}

                  {/** nap am*/}

                  {/** truong sinh*/}
                  {checkTruongSinh ? (
                    <tr style={{ textAlign: "center" }}>
                      {
                        <th id="boxtss" colspan="3" className="fz__18 border__">
                          {truongSinhHour}
                        </th>
                      }
                      <th
                        id="bg__tsd"
                        style={{ background: "#B2CFEA" }}
                        colspan="3"
                        className="fz__18 border__"
                      >
                        {truongSinhDay}
                      </th>
                      <th colspan="3" className="fz__18 border__">
                        {truongSinhMonth}
                      </th>
                      <th colspan="3" className="fz__18 border__">
                        {truongSinhYear}
                      </th>
                      <th
                        id="bg__ts"
                        style={{ width: "58px", border: "3px solid #000000" }}
                        className="bg__ clr__white clr__print"
                      >
                        Trường Sinh
                      </th>
                    </tr>
                  ) : (
                    ""
                  )}

                  {/** truong sinh*/}

                  {/** than sat*/}
                  {checkThanSat ? (
                    <tr style={{ textAlign: "center" }}>
                      {
                        <th
                          id="tsbox"
                          colspan="3"
                          className="fz__18 border__ text__wrap"
                        >
                          {thanSatDiachiHours} {thanSatThienCanHour}{" "}
                          {(thanSatDiachiHours && KhongVongHour) ||
                          (thanSatThienCanHour && KhongVongHour)
                            ? `, ${KhongVongHour}`
                            : `${KhongVongHour}`}
                        </th>
                      }
                      <th
                        id="bg__tssd"
                        style={{ background: "#B2CFEA" }}
                        colspan="3"
                        className="fz__18 border__ text__wrap"
                      >
                        {(khoiCuongDay && thanSatDiaChiDay) ||
                        (thanSatThienCanDay && thanSatDiaChiDay) ||
                        (thienXa && thanSatDiaChiDay)
                          ? `${thanSatDiaChiDay}, `
                          : thanSatDiaChiDay}{" "}
                        {thanSatThienCanDay} {khoiCuongDay} {thienXa}
                      </th>
                      <th colspan="3" className="fz__18 border__ text__wrap">
                        {(thanSatThienCanMonth && thanSatDiachiMonth) ||
                        (KhongVongMonth && thanSatDiachiMonth)
                          ? `${thanSatDiachiMonth}, `
                          : thanSatDiachiMonth}{" "}
                        {thanSatThienCanMonth} {KhongVongMonth}
                      </th>
                      <th colspan="3" className="fz__18 border__ text__wrap">
                        {(thanSatThienCanYear && thanSatDiaChiYear) ||
                        (KhongVongYear && thanSatDiaChiYear)
                          ? `${thanSatDiaChiYear}, `
                          : thanSatDiaChiYear}{" "}
                        {thanSatThienCanYear} {KhongVongYear}
                      </th>
                      <th
                        id="bg__tss"
                        style={{ width: "58px", border: "3px solid #000000" }}
                        className="bg__ clr__white clr__print"
                      >
                        Thần Sát
                      </th>
                    </tr>
                  ) : (
                    ""
                  )}

                  {/** than sat*/}
                </tbody>
              </Table>
              <p></p>
              <div
                className="fz__18 block__print"
                style={{
                  marginTop: "50px",
                  marginBottom: "10px",
                  width: "700px",
                  display: "none",
                }}
              >
                Ghi chú: Ngày giờ sinh nhập theo dương lịch. Giờ Tý 23:00-00:59;
                Sửu 01:00-02:59...;
              </div>
              <div
                style={{ margin: "0 auto", width: "700px", display: "none" }}
                className="fz__18 block__print"
              >
                CA: Chính Ấn; TA: Thiên Ấn; CQ: Chính Quan; TS: Thất Sát; TK: Tỷ
                Kiên; KT: Kiếp Tài; CT: Chính Tài; TT: Thiên Tài; TQ: Thương
                Quan; TH: Thực Thần
              </div>
            </div>

            {/** dai van hien tai*/}
            <div>
              <Table
                id="dvht"
                className="margin__print"
                style={{ width: "250px", maxHeight: "882px", height: "700px" }}
              >
                <thead>
                  <tr>
                    <th style={{ border: "1px solid transparent" }} colspan="3">
                      <div
                        className="bg__2"
                        style={{
                          marginBottom: "9px",
                          borderRadius: "8px",
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <button
                          className="hide-to-print"
                          onClick={handlePrevDaiVan}
                          style={{
                            width: "50%",
                            border: "none",
                            borderRight: "1px solid white",
                            background: "transparent",
                          }}
                        >
                          <VscTriangleLeft style={{ color: "white" }} />
                        </button>
                        <button
                          className="hide-to-print"
                          onClick={handleNextDaiVan}
                          style={{
                            width: "50%",
                            border: "none",
                            background: "transparent",
                          }}
                        >
                          <VscTriangleRight style={{ color: "white" }} />
                        </button>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ textAlign: "center" }}>
                    <th
                      id="bg__dv"
                      colspan="3"
                      className="fz__22"
                      style={{
                        width: "150px",
                        height: "64px",
                        border: "3px solid #000000",
                        background: "#ff7400",
                        color: "#FFFFFF",
                      }}
                    >
                      {`${soNgay} (${soNam})`}
                    </th>

                    <th
                      id="bg__b"
                      style={{
                        width: "58px",
                        border: "0.15em solid #000000",
                        background: "#ff7400",
                        height: "64px",
                      }}
                    >
                      {" "}
                    </th>
                  </tr>
                  {/* thien can dai van */}
                  <tr style={{ textAlign: "center", height: "63.27px" }}>
                    <th colspan="3" className="fz__25 clr__ border__">
                      <sup className="fz__15">{ThapThanDaiVan}</sup>
                      {thienCanDaiVan}
                    </th>

                    <th
                      id="bg__dvht"
                      rowSpan="3"
                      style={{ width: "58px", border: "3px solid #000000" }}
                      className="bg__2 clr__white clr__print"
                    >
                      Đại Vận Hiện Tại
                    </th>
                  </tr>

                  <tr style={{ textAlign: "center" }}>
                    <th
                      style={{ fontWeight: "normal", height: "56.73px" }}
                      colspan="3"
                      className="border__"
                    >
                      {nguHanhTCDV}
                    </th>
                  </tr>
                  {/* thien can dai van */}

                  {/* dia chi dai van */}

                  <tr style={{ textAlign: "center" }}>
                    <th
                      colspan="3"
                      className="fz__25 clr__ border__"
                      style={{ height: "62.55px" }}
                    >
                      {diaChiDaiVan}
                    </th>
                  </tr>

                  {/* dia chi dai van */}

                  {/* tang can dai van */}
                  <tr style={{ textAlign: "center", height: " 85.09px" }}>
                    {TangCanDaiVan2 ? (
                      <td
                        className="border__"
                        style={{
                          verticalAlign: "middle",
                          padding: "0",
                          fontSize: "14px",
                        }}
                      >
                        <div>
                          <sup>{ThapThanDaiVan2}</sup>
                          {TangCanDaiVan2}
                        </div>
                        <div style={{ justifyContent: "center" }}>
                          {TangCanDaiVan5}
                        </div>
                      </td>
                    ) : (
                      ""
                    )}

                    {TangCanDaiVan2 && TangCanDaiVan3 ? (
                      <th
                        colspan="1"
                        className="border__"
                        style={{
                          verticalAlign: "middle",
                          padding: "0",
                          fontSize: "14px",
                        }}
                      >
                        <div>
                          <sup>{ThapThanDaiVan1}</sup>
                          {TangCanDaiVan}
                        </div>
                        <div style={{ justifyContent: "center" }}>
                          {TangCanDaiVan4}
                        </div>
                      </th>
                    ) : TangCanDaiVan3 ? (
                      <th
                        colspan="2"
                        className="border__"
                        style={{
                          verticalAlign: "middle",
                          padding: "0",
                          fontSize: "16px",
                        }}
                      >
                        <div>
                          <sup>{ThapThanDaiVan1}</sup>
                          {TangCanDaiVan}
                        </div>
                        <div style={{ justifyContent: "center" }}>
                          {TangCanDaiVan4}
                        </div>
                      </th>
                    ) : (
                      <th colspan="3" className="border__">
                        <div>
                          <sup>{ThapThanDaiVan1}</sup>
                          {TangCanDaiVan}
                        </div>
                        <div style={{ justifyContent: "center" }}>
                          {TangCanDaiVan4}
                        </div>
                      </th>
                    )}

                    {TangCanDaiVan3 && TangCanDaiVan2 ? (
                      <td
                        className="border__"
                        style={{
                          verticalAlign: "middle",
                          padding: "0",
                          fontSize: "14px",
                        }}
                      >
                        <div>
                          <sup>{ThapThanDaiVan3}</sup>
                          {TangCanDaiVan3}
                        </div>
                        <div style={{ justifyContent: "center" }}>
                          {TangCanDaiVan6}
                        </div>
                      </td>
                    ) : TangCanDaiVan3 && TangCanDaiVan ? (
                      <td
                        className="border__"
                        style={{
                          verticalAlign: "middle",
                          padding: "0",
                          fontSize: "16px",
                        }}
                      >
                        <div>
                          <sup>{ThapThanDaiVan3}</sup>
                          {TangCanDaiVan3}
                        </div>
                        <div style={{ justifyContent: "center" }}>
                          {TangCanDaiVan6}
                        </div>
                      </td>
                    ) : (
                      ""
                    )}

                    <th
                      id="bg__tcdv"
                      style={{ width: "58px", border: "3px solid #000000" }}
                      className="bg__2 clr__white clr__print"
                    >
                      Tàng Can
                    </th>
                  </tr>
                  {/* tang can dai van */}

                  {/* nap am dai van */}
                  <tr style={{ textAlign: "center", height: "84.36px" }}>
                    <th colspan="3" className="fz__18 border__">
                      {napAmDaiVan}
                    </th>
                    <th
                      id="bg__nadv"
                      style={{ width: "58px", border: "3px solid #000000" }}
                      className="bg__2 clr__white clr__print"
                    >
                      Nạp Âm
                    </th>
                  </tr>

                  {/* nap am dai van */}

                  {/* truong sinh dai van */}
                  <tr style={{ textAlign: "center", height: "80px" }}>
                    <th colspan="3" className="fz__18 border__">
                      {TruongSinhDaiVan}
                    </th>
                    <th
                      id="bg__tsdv"
                      style={{ width: "58px", border: "3px solid #000000" }}
                      className="bg__2 clr__white clr__print"
                    >
                      Trường Sinh
                    </th>
                  </tr>

                  {/* truong sinh dai van */}

                  {/* than sat dai van */}
                  <tr style={{ textAlign: "center" }}>
                    <th
                      id="dvhtts"
                      colspan="3"
                      className="fz__18 border__ text__wrap"
                    >
                      {(ThanSatThienCanDaiVan && ThanSatDiaChiDaiVan) ||
                      (KhongVongDaiVan && ThanSatDiaChiDaiVan)
                        ? `${ThanSatDiaChiDaiVan}, `
                        : ThanSatDiaChiDaiVan}{" "}
                      {ThanSatThienCanDaiVan} {KhongVongDaiVan}
                    </th>
                    <th
                      id="bg__dvts"
                      style={{ width: "58px", border: "3px solid #000000" }}
                      className="bg__2 clr__white clr__print"
                    >
                      Thần Sát
                    </th>
                  </tr>
                  {/* than sat dai van */}
                </tbody>
              </Table>
            </div>

            {/** dai van hien tai*/}

            {/** luu nien hien tai*/}

            <Table
              id="lnht"
              className="margin__print"
              style={{
                width: "250px",
                marginLeft: "21px",
                maxHeight: "882px",
                height: "700px",
              }}
            >
              <thead>
                <tr>
                  <th colspan="3" style={{ border: "1px solid transparent" }}>
                    <div
                      className="bg__2"
                      style={{
                        marginBottom: "9px",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <button
                        className="hide-to-print"
                        onClick={handlePrevLuuNien}
                        style={{
                          width: "50%",
                          border: "none",
                          borderRight: "1px solid white",
                          background: "transparent",
                        }}
                      >
                        <VscTriangleLeft style={{ color: "white" }} />
                      </button>
                      <button
                        className="hide-to-print"
                        onClick={handleNextLuuNien}
                        style={{
                          width: "50%",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <VscTriangleRight style={{ color: "white" }} />
                      </button>
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr style={{ textAlign: "center" }}>
                  <th
                    id="bg__ln"
                    colspan="3"
                    className="fz__22"
                    style={{
                      width: "150px",
                      border: "3px solid #000000",
                      background: "#ff7400",
                      color: "#FFFFFF",
                    }}
                  >
                    {LuuNienState}
                  </th>

                  <th
                    id="bg__c"
                    style={{
                      width: "58px",
                      height: "64px",
                      border: "3px solid #000000",
                      background: "#ff7400",
                    }}
                  >
                    {" "}
                  </th>
                </tr>
                {/* thien can luu nien */}
                <tr style={{ textAlign: "center", height: "63.27px" }}>
                  <th colspan="3" className="fz__25 clr__ border__">
                    <sup className="fz__15">{thapthanLuuNien}</sup>
                    {ThienCanLuuNien}
                  </th>

                  <th
                    id="bg__lnht"
                    rowSpan="3"
                    style={{ width: "58px", border: "3px solid #000000" }}
                    className="bg__2 clr__white"
                  >
                    Lưu Niên Hiện Tại
                  </th>
                </tr>

                <tr style={{ textAlign: "center", height: "56.73px" }}>
                  <th
                    style={{ fontWeight: "normal" }}
                    colspan="3"
                    className="border__"
                  >
                    {nguHanhLN}
                  </th>
                </tr>
                {/* thien can luu nien */}

                {/* dia chi luu nien */}

                <tr style={{ textAlign: "center", height: "62.55px" }}>
                  <th colspan="3" className="fz__25 clr__ border__">
                    {DiaChiLuuNien}
                  </th>
                </tr>

                {/* dia chi luu nien */}

                {/* tang can luu nien */}
                <tr style={{ textAlign: "center", height: "85.09px" }}>
                  {TangCanLuuNien2 ? (
                    <td
                      className="border__"
                      style={{
                        verticalAlign: "middle",
                        padding: "0",
                        fontSize: "14px",
                      }}
                    >
                      <div>
                        <sup>{ThapThanLuuNien2}</sup>
                        {TangCanLuuNien2}
                      </div>
                      <div style={{ justifyContent: "center" }}>
                        {TangCanLuuNien5}
                      </div>
                    </td>
                  ) : (
                    ""
                  )}

                  {TangCanLuuNien2 && TangCanLuuNien3 ? (
                    <th
                      colspan="1"
                      className="border__"
                      style={{
                        verticalAlign: "middle",
                        padding: "0",
                        fontSize: "14px",
                      }}
                    >
                      <div>
                        <sup>{ThapThanLuuNien1}</sup>
                        {TangCanLuuNien}
                      </div>
                      <div style={{ justifyContent: "center" }}>
                        {TangCanLuuNien4}
                      </div>
                    </th>
                  ) : TangCanLuuNien3 ? (
                    <th
                      colspan="2"
                      className="border__"
                      style={{
                        verticalAlign: "middle",
                        padding: "0",
                        fontSize: "16px",
                      }}
                    >
                      <div>
                        <sup>{ThapThanLuuNien1}</sup>
                        {TangCanLuuNien}
                      </div>
                      <div style={{ justifyContent: "center" }}>
                        {TangCanLuuNien4}
                      </div>
                    </th>
                  ) : (
                    <th colspan="3" className="border__">
                      <div>
                        <sup>{ThapThanLuuNien1}</sup>
                        {TangCanLuuNien}
                      </div>
                      <div style={{ justifyContent: "center" }}>
                        {TangCanLuuNien4}
                      </div>
                    </th>
                  )}

                  {TangCanLuuNien3 && TangCanLuuNien2 ? (
                    <td
                      className="border__"
                      style={{
                        verticalAlign: "middle",
                        padding: "0",
                        fontSize: "14px",
                      }}
                    >
                      <div>
                        <sup>{ThapThanLuuNien3}</sup>
                        {TangCanLuuNien3}
                      </div>
                      <div style={{ justifyContent: "center" }}>
                        {TangCanLuuNien6}
                      </div>
                    </td>
                  ) : TangCanLuuNien3 && TangCanLuuNien ? (
                    <td
                      className="border__"
                      style={{
                        verticalAlign: "middle",
                        padding: "0",
                        fontSize: "16px",
                      }}
                    >
                      <div>
                        <sup>{ThapThanLuuNien3}</sup>
                        {TangCanLuuNien3}
                      </div>
                      <div style={{ justifyContent: "center" }}>
                        {TangCanLuuNien6}
                      </div>
                    </td>
                  ) : (
                    ""
                  )}

                  <th
                    id="bg__lntc"
                    style={{ width: "58px", border: "3px solid #000000" }}
                    className="bg__2 clr__white"
                  >
                    Tàng Can
                  </th>
                </tr>
                {/* tang can luu nien */}

                {/* nap am luu nien */}
                <tr style={{ textAlign: "center", height: "84.36px" }}>
                  <th colspan="3" className="fz__18 border__">
                    {napAmLuuNien?.napAm}
                  </th>
                  <th
                    id="bg__lnna"
                    style={{ width: "58px", border: "3px solid #000000" }}
                    className="bg__2 clr__white"
                  >
                    Nạp Âm
                  </th>
                </tr>

                {/* nap am luu nien */}

                {/* truong sinh luu nien */}
                <tr style={{ textAlign: "center", height: "80px" }}>
                  <th colspan="3" className="fz__18 border__">
                    {TruongSinhLuuNien}
                  </th>
                  <th
                    id="bg__lnts"
                    style={{ width: "58px", border: "3px solid #000000" }}
                    className="bg__2 clr__white"
                  >
                    Trường Sinh
                  </th>
                </tr>

                {/* truong sinh luu nien */}

                {/* than sat luu nien */}
                <tr style={{ textAlign: "center", height: "80px" }}>
                  <th
                    id="lnhtts"
                    colspan="3"
                    className="fz__18 border__ text__wrap"
                  >
                    {(ThanSatThienCanLuuNien && ThanSatDiaChiLuuNien) ||
                    (KhongVongLN && ThanSatDiaChiLuuNien)
                      ? `${ThanSatDiaChiLuuNien}, `
                      : ThanSatDiaChiLuuNien}{" "}
                    {ThanSatThienCanLuuNien} {KhongVongLN}
                  </th>
                  <th
                    id="bg__lntss"
                    style={{ width: "58px", border: "3px solid #000000" }}
                    className="bg__2 clr__white"
                  >
                    Thần Sát
                  </th>
                </tr>
                {/* than sat luu nien */}
              </tbody>
            </Table>
            {/** luu nien hien tai*/}
          </div>

          <div
            className="fz__18 hide-to-print"
            style={{ margin: "10px auto", width: "700px" }}
          >
            Ghi chú: Ngày giờ sinh nhập theo dương lịch. Giờ Tý 23:00-00:59; Sửu
            01:00-02:59...;
          </div>
          <div
            style={{ margin: "0 auto", width: "700px" }}
            className="fz__18 hide-to-print"
          >
            CA: Chính Ấn; TA: Thiên Ấn; CQ: Chính Quan; TS: Thất Sát; TK: Tỷ
            Kiên; KT: Kiếp Tài; CT: Chính Tài; TT: Thiên Tài; TQ: Thương Quan;
            TH: Thực Thần
          </div>
          <p style={{ pageBreakAfter: "always" }}></p>
          <div
            id="table_dv"
            className="table__dv"
            style={{
              width: "1440px",
              margin: "0 auto",
              zIndex: "1000",
              position: "relative",
            }}
          >
            <Table
              hover
              bordered
              style={{
                textAlign: "center",

                marginTop: "35px",
              }}
            >
              <thead>
                <tr>
                  <th
                    id="bg__lnn"
                    className="bg__ clr__white fz__22"
                    colspan="10"
                  >
                    Đại Vận
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="fz__16">
                  {daiVanData?.map((x) => {
                    return (
                      <td
                        id={
                          x?.year <= new Date().getFullYear() &&
                          x?.year > new Date().getFullYear() - 10
                            ? "bg_hang1"
                            : ""
                        }
                        style={{ width: "145px" }}
                        className={
                          x?.year <= new Date().getFullYear() &&
                          x?.year > new Date().getFullYear() - 10
                            ? "border__1px background_"
                            : "border__1px"
                        }
                      >
                        {x.soNgay} ({x.year})
                      </td>
                    );
                  })}

                  <th
                    id="bg__age"
                    className="bg__ clr__white fz__15 fw__b"
                    style={{ width: "58px" }}
                  >
                    Tuổi
                  </th>
                </tr>

                <tr style={{ textAlign: "center" }}>
                  {daiVanData.map((x) => {
                    return (
                      <td
                        id={
                          x?.year <= new Date().getFullYear() &&
                          x?.year > new Date().getFullYear() - 10
                            ? "bg_hang2"
                            : ""
                        }
                        style={{ fontWeight: "bold" }}
                        className={
                          x?.year <= new Date().getFullYear() &&
                          x?.year > new Date().getFullYear() - 10
                            ? "fz__22 clr__ border__1px background_"
                            : "fz__22 clr__ border__1px"
                        }
                      >
                        <div>
                          <sup
                            className="fz__15"
                            style={{ paddingRight: "4px" }}
                          >
                            {x.thapThan?.sinhKhac}
                          </sup>
                          {x.thienCan}
                        </div>
                      </td>
                    );
                  })}

                  <th
                    id="bg__lnntc"
                    rowSpan="2"
                    className="bg__ clr__white fz__15 fw__b"
                  >
                    Thiên Can
                  </th>
                </tr>

                <tr style={{ textAlign: "center" }}>
                  {daiVanData.map((x) => {
                    return (
                      <th
                        id={
                          x?.year <= new Date().getFullYear() &&
                          x?.year > new Date().getFullYear() - 10
                            ? "bg_hang3"
                            : ""
                        }
                        style={{ fontWeight: "normal" }}
                        colspan="1"
                        className={
                          x?.year <= new Date().getFullYear() &&
                          x?.year > new Date().getFullYear() - 10
                            ? "fz__15 border__1px background_"
                            : "fz__15 border__1px"
                        }
                      >
                        {x.nguHanhTC}
                      </th>
                    );
                  })}
                </tr>

                <tr>
                  {daiVanData.map((x) => (
                    <td
                      id={
                        x?.year <= new Date().getFullYear() &&
                        x?.year > new Date().getFullYear() - 10
                          ? "bg_hang4"
                          : ""
                      }
                      style={{ fontWeight: "bold" }}
                      className={
                        x?.year <= new Date().getFullYear() &&
                        x?.year > new Date().getFullYear() - 10
                          ? "fz__22 clr__ border__1px background_"
                          : "fz__22 clr__ border__1px"
                      }
                    >
                      {x.diaChi}
                    </td>
                  ))}

                  <th
                    id="bg__lnndc"
                    rowSpan="1"
                    className="bg__ clr__white fz__15 fw__b"
                  >
                    Địa Chi
                  </th>
                </tr>

                <tr className="fz__10">
                  {tangCanDaiVanArr?.map((val) => {
                    return (
                      <td
                        id={
                          val?.year <= new Date().getFullYear() &&
                          val?.year > new Date().getFullYear() - 10
                            ? "bg_hang5"
                            : ""
                        }
                        style={{ verticalAlign: "middle", padding: "0" }}
                        className={
                          val?.year <= new Date().getFullYear() &&
                          val?.year > new Date().getFullYear() - 10
                            ? "border__1px background_"
                            : "border__1px"
                        }
                      >
                        {val.TangCan[1]?.name ? (
                          <td
                            id={
                              val?.year <= new Date().getFullYear() &&
                              val?.year > new Date().getFullYear() - 10
                                ? "none_bg"
                                : "none"
                            }
                            style={{
                              border: "none",
                              padding: "0 10px 0 10px",
                            }}
                          >
                            <div>
                              <sup>{val?.ThapThan2?.sinhKhac}</sup>

                              {val?.TangCan[1]?.name}
                            </div>
                            <div>
                              <div>{val?.TangCan[1]?.nguHanh}</div>
                            </div>
                          </td>
                        ) : (
                          ""
                        )}

                        {val?.TangCan[1]?.name && val?.TangCan[2]?.nguHanh ? (
                          <th
                            id={
                              val?.year <= new Date().getFullYear() &&
                              val?.year > new Date().getFullYear() - 10
                                ? "none3_bg"
                                : "none3"
                            }
                            style={{
                              borderLeft: "1px solid black",
                              borderRight: "1px solid black",
                              borderTop: "none",
                              borderBottom: "none",
                              padding: "0 5px 0 5px",
                            }}
                          >
                            <div>
                              <sup>{val?.ThapThan1.sinhKhac}</sup>
                              {val?.TangCan[0]?.name}
                            </div>
                            <div>
                              <div>{val?.TangCan[0]?.nguHanh}</div>
                            </div>
                          </th>
                        ) : val?.TangCan[2]?.name ? (
                          <th
                            id={
                              val?.year <= new Date().getFullYear() &&
                              val?.year > new Date().getFullYear() - 10
                                ? "none_bg4"
                                : "none4"
                            }
                            style={{
                              borderLeft: "none",
                              borderRight: "1px solid black",
                              borderTop: "none",
                              borderBottom: "none",
                              padding: "0 15px 0 25px",
                            }}
                          >
                            <div>
                              <sup>{val?.ThapThan1.sinhKhac}</sup>
                              {val?.TangCan[0]?.name}
                            </div>
                            <div>
                              <div>{val?.TangCan[0]?.nguHanh}</div>
                            </div>
                          </th>
                        ) : (
                          <th
                            id={
                              val?.year <= new Date().getFullYear() &&
                              val?.year > new Date().getFullYear() - 10
                                ? "none2_bg"
                                : "none2"
                            }
                            style={{ border: "none", display: "inline-block" }}
                          >
                            <div>
                              <sup>{val?.ThapThan1.sinhKhac}</sup>
                              {val?.TangCan[0]?.name}
                            </div>
                            <div>
                              <div>{val?.TangCan[0]?.nguHanh}</div>
                            </div>
                          </th>
                        )}

                        {val?.TangCan[2]?.name && val?.TangCan[1]?.name ? (
                          <td
                            id={
                              val?.year <= new Date().getFullYear() &&
                              val?.year > new Date().getFullYear() - 10
                                ? "none2_bg"
                                : "none2"
                            }
                            style={{
                              border: "none",
                              padding: "0 5px",
                            }}
                          >
                            <div>
                              <sup>{val?.ThapThan3?.sinhKhac}</sup>

                              {val?.TangCan[2]?.name}
                            </div>
                            <div>
                              <div>{val?.TangCan[2]?.nguHanh}</div>
                            </div>
                          </td>
                        ) : (
                          <td
                            id={
                              val?.year <= new Date().getFullYear() &&
                              val?.year > new Date().getFullYear() - 10
                                ? "none2_bg"
                                : "none2"
                            }
                            style={{
                              border: "none",
                              padding: "0 15px",
                            }}
                          >
                            <div>
                              <sup>{val?.ThapThan3?.sinhKhac}</sup>

                              {val?.TangCan[2]?.name}
                            </div>
                            <div>
                              <div>{val?.TangCan[2]?.nguHanh}</div>
                            </div>
                          </td>
                        )}
                      </td>
                    );
                  })}

                  <th id="bg__lnntcc" className="bg__ clr__white fz__15 fw__b">
                    Tàng Can
                  </th>
                </tr>
                <tr className="fz__16">
                  {daiVanData?.map((x) => {
                    return (
                      <td
                        id={
                          x?.year <= new Date().getFullYear() &&
                          x?.year > new Date().getFullYear() - 10
                            ? "bg_hang6"
                            : ""
                        }
                        className={
                          x?.year <= new Date().getFullYear() &&
                          x?.year > new Date().getFullYear() - 10
                            ? "border__1px background_"
                            : "border__1px"
                        }
                      >
                        {x.napAm?.napAm}
                      </td>
                    );
                  })}
                  <th id="bg__lnnna" className="bg__ clr__white fz__15 fw__b">
                    Nạp Âm
                  </th>
                </tr>
                {/* nap am */}
                <tr>
                  {daiVanData?.map((x) => {
                    return (
                      <td
                        id={
                          x?.year <= new Date().getFullYear() &&
                          x?.year > new Date().getFullYear() - 10
                            ? "bg_hang7"
                            : ""
                        }
                        className={
                          x?.year <= new Date().getFullYear() &&
                          x?.year > new Date().getFullYear() - 10
                            ? "border__1px fz__16 background_"
                            : "border__1px fz__16"
                        }
                      >
                        {x.truongSinh}
                      </td>
                    );
                  })}
                  <th id="bg__lnnts" className="bg__ clr__white fz__15 fw__b">
                    Trường Sinh
                  </th>
                </tr>
                <tr>
                  {daiVanData?.map((x) => {
                    return (
                      <td
                        id={
                          x?.year <= new Date().getFullYear() &&
                          x?.year > new Date().getFullYear() - 10
                            ? "bg_hang8"
                            : ""
                        }
                        style={{ width: "150px" }}
                        className={
                          x?.year <= new Date().getFullYear() &&
                          x?.year > new Date().getFullYear() - 10
                            ? "border__1px fz__16 background_"
                            : "border__1px fz__16"
                        }
                      >
                        {(x.thanSatThienCan && x.thanSatDiaChi) ||
                        (x?.khongVong && x.thanSatDiaChi)
                          ? `${x.thanSatDiaChi}, `
                          : x.thanSatDiaChi}{" "}
                        {x.thanSatThienCan} {x?.khongVong}
                      </td>
                    );
                  })}
                  <th id="bg__lnntss" className="bg__ clr__white fz__15 fw__b">
                    Thần Sát
                  </th>
                </tr>
              </tbody>
            </Table>
          </div>

          <div
            id="table_lnn"
            className="page__2_print table__ln"
            style={{
              width: "1440px",
              margin: "0 auto",
              zIndex: "1000",
              position: "relative",
              top: "-17px",
            }}
          >
            <Table hover bordered style={{ textAlign: "center" }}>
              <thead>
                <tr>
                  <th
                    id="bg__dv10a"
                    className="bg__2 clr__white fz__22"
                    colspan="10"
                  >
                    Lưu Niên
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {daiVan10NamA?.map((x) => {
                    return (
                      <td
                        id={
                          x.cot1.years === new Date().getFullYear()
                            ? "bg_cot1"
                            : ""
                        }
                        className="border__1px fz__16"
                        style={{
                          paddingLeft: "0",
                          paddingRight: "0",
                          width: "160px",
                        }}
                      >
                        {x.cot1.year}: {x.cot1.thienCan?.name}{" "}
                        {x.cot1.diaChi?.name}
                      </td>
                    );
                  })}
                  <th
                    id="bg__dv10"
                    className="tranform__ bg__2 clr__white fz__15"
                    rowspan="11"
                    scope="rowgroup"
                    style={{
                      minWidth: "66px",
                      verticalAlign: "middle!important",
                    }}
                  >
                    10 Năm Đại Vận
                  </th>
                </tr>
                <tr>
                  {daiVan10NamA[8]?.cot2?.map((x) => {
                    return (
                      <td
                        id={
                          x.year === new Date().getFullYear() ? "bg_cot2" : ""
                        }
                        className="border__1px fz__16"
                        style={{ textAlign: "center" }}
                      >
                        {x?.year}: {x.thienCan?.name} {x.diaChi?.name}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  {daiVan10NamA[8]?.cot3?.map((x) => {
                    return (
                      <td
                        id={
                          x.year === new Date().getFullYear() ? "bg_cot3" : ""
                        }
                        className="border__1px fz__16"
                        style={{ textAlign: "center" }}
                      >
                        {x?.year}: {x.thienCan?.name} {x.diaChi?.name}
                      </td>
                    );
                  })}
                </tr>

                <tr>
                  {daiVan10NamA[8]?.cot4?.map((x) => {
                    return (
                      <td
                        id={
                          x.year === new Date().getFullYear() ? "bg_cot4" : ""
                        }
                        className="border__1px fz__16"
                        style={{ textAlign: "center" }}
                      >
                        {x?.year}: {x.thienCan?.name} {x.diaChi?.name}
                      </td>
                    );
                  })}
                </tr>

                <tr>
                  {daiVan10NamA[8]?.cot5?.map((x) => {
                    return (
                      <td
                        id={
                          x.year === new Date().getFullYear() ? "bg_cot5" : ""
                        }
                        className="border__1px fz__16"
                        style={{ textAlign: "center" }}
                      >
                        {x?.year}: {x.thienCan?.name} {x.diaChi?.name}
                      </td>
                    );
                  })}
                </tr>

                <tr>
                  {daiVan10NamA[8]?.cot6?.map((x) => {
                    return (
                      <td
                        id={
                          x.year === new Date().getFullYear() ? "bg_cot6" : ""
                        }
                        className="border__1px fz__16"
                        style={{ textAlign: "center" }}
                      >
                        {x?.year}: {x.thienCan?.name} {x.diaChi?.name}
                      </td>
                    );
                  })}
                </tr>

                <tr>
                  {daiVan10NamA[8]?.cot7?.map((x) => {
                    return (
                      <td
                        id={
                          x.year === new Date().getFullYear() ? "bg_cot7" : ""
                        }
                        className="border__1px fz__16"
                        style={{ textAlign: "center" }}
                      >
                        {x?.year}: {x.thienCan?.name} {x.diaChi?.name}
                      </td>
                    );
                  })}
                </tr>

                <tr>
                  {daiVan10NamA[8]?.cot8?.map((x) => {
                    return (
                      <td
                        id={
                          x.year === new Date().getFullYear() ? "bg_cot8" : ""
                        }
                        className="border__1px fz__16"
                        style={{ textAlign: "center" }}
                      >
                        {x?.year}: {x.thienCan?.name} {x.diaChi?.name}
                      </td>
                    );
                  })}
                </tr>

                <tr>
                  {daiVan10NamA[8]?.cot9?.map((x) => {
                    return (
                      <td
                        id={
                          x.year === new Date().getFullYear() ? "bg_cot9" : ""
                        }
                        className="border__1px fz__16"
                        style={{ textAlign: "center" }}
                      >
                        {x?.year}: {x.thienCan?.name} {x.diaChi?.name}
                      </td>
                    );
                  })}
                </tr>

                <tr>
                  {daiVan10NamA[8]?.cot10?.map((x) => {
                    return (
                      <td
                        id={
                          x.year === new Date().getFullYear() ? "bg_cot10" : ""
                        }
                        className="border__1px fz__16"
                        style={{ textAlign: "center" }}
                      >
                        {x?.year}: {x.thienCan?.name} {x.diaChi?.name}
                      </td>
                    );
                  })}
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      ) : (
        <div
          className="hide-to-print"
          style={{
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold",
            minHeight: "500px",
            paddingTop: "125px",
          }}
        >
          {!excelLichvanNien || excelLichvanNien.length === 0
            ? "Đang setup dữ liệu, vui lòng đợi trong giây lát..."
            : "Vui lòng nhập thông tin và xem bát tự"}
        </div>
      )}
      {alerError ? (
        <SweetAlert
          type={"warning"}
          title={"Vui lòng đăng nhập để sử dụng website"}
          onConfirm={() => setAlerError(false)}
        />
      ) : (
        ""
      )}

      {alerError2 ? (
        <SweetAlert
          type={"warning"}
          title={"Tài khoản của bạn đã hết hạn"}
          onConfirm={() => setAlerError2(false)}
        />
      ) : (
        ""
      )}
    </div>
  );
};
