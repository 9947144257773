import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Collapse,
  CardBody,
  Card,
} from "reactstrap";
import * as XLSX from "xlsx";
import { IoArrowDownCircleOutline } from "react-icons/io5";

// import Modal from "../components/Modal";
import { LuanDungThan } from "../components/renderLuanDungThan";
import { BoSungHanhKim } from "../components/LuanDungThanTheoThang/BoSungHanhKim";
import logo from "../assets/logo.png";
import "../scss/thansat.scss";

function PageTinhCach() {
  const [show, setShow] = useState(true);
  const [open, setOpen] = useState(false);
  const [alerError, setAlerError] = useState(false);

  const [title, setTitle] = useState("Giáp Mộc");
  const [text, setText] = useState(BoSungHanhKim);

  const noLogin = true;

  const handleClick = (e) => {
    e.currentTarget.classList.add("active");
    const p = document.getElementsByClassName("chiday");

    for (const item of p) {
      if (item.textContent !== e.currentTarget.textContent)
        item.classList.remove("active");
    }

    let value = e.currentTarget.textContent;

    const content = LuanDungThan(value);
    setText(content);
  };

  const setAlerErrors = () => {
    setAlerError(true);
  };

  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      {noLogin ? (
        <>
          <div className="than_sat_title" style={{ marginTop: "2rem" }}>
            <h3>NGŨ HÀNH</h3>
          </div>
          <div
            style={{
              width: "1440px",
              margin: "0 auto",
              display: "flex",
              minHeight: "512px",
            }}
          >
            <Card style={{ width: "15%" }}>
              <CardBody>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span className="chiday" onClick={handleClick}>
                    Bổ sung hành kim
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span className="chiday" onClick={handleClick}>
                    Bổ sung hành mộc
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span className="chiday" onClick={handleClick}>
                    Bổ sung hành thủy
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span className="chiday" onClick={handleClick}>
                    Bổ sung hành hỏa
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span className="chiday" onClick={handleClick}>
                    Bổ sung hành thổ
                  </span>
                </div>
              </CardBody>
            </Card>
            <div style={{ width: "90%", position: "relative" }}>
              <div
                className={
                  title
                    ? "watermark-hoagiap-page t-16"
                    : "watermark-hoagiap-page"
                }
              >
                <img src={logo} />
              </div>

              <span
                style={{
                  overflowY: "scroll",
                  maxHeight: 600,
                  display: "block",
                }}
                onCopy={(e) => e.preventDefault()}
                onCut={(e) => e.preventDefault()}
                onPaste={(e) => e.preventDefault()}
              >
                {text}
              </span>
            </div>
          </div>
        </>
      ) : (
        <div
          className="hide-to-print"
          style={{
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold",
            minHeight: "500px",
            paddingTop: "125px",
          }}
        ></div>
      )}
      <Modal close={(value) => setOpen(value)} open={open} />
    </div>
  );
}

export default PageTinhCach;
