export const KyTy = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Kỷ Tỵ có vận mệnh ngược, nếu sinh ra trong gia đình giàu
        có thì về già sẽ nghèo khó; nếu sinh ra trong gia đình nghèo khó thì về
        già sẽ giàu có. Người này sẽ dùng mọi cách để đạt được điều mình muốn,
        kể cả cách phi đạo đức. Rất chú trọng đến giàu có vật chất, tham vọng
        cao và tham lam, có khả năng sử dụng nguồn lực tốt để đạt thành công
        nhưng người vợ sẽ đem lại điều không may. Nếu là nữ thì hôn nhân bất ổn.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Tỵ được hình dung như một viên ngọc hình thành do chuyển động
        đều đặn và chậm của trái đất hình thành bởi nhiệt độ và áp suất cao.
        Trong cuộc sống, Kỷ Tỵ tập trung vào tìm kiếm kiến thức, và họ sẽ trải
        qua các thời kỳ chịu đựng để đạt được kiến thức. Tiến trình này cũng
        tương tự như ngọc được hình thành, và nó sẽ mất một khoảng thời gian
        chuyển tiếp trước khi đá tự nhiên có thể tạo thành viên ngọc đẹp đẽ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Phụ nữ Kỷ Tỵ thường ấm áp và từ bi. Cuộc sống sẽ khá êm đẹp vì cô ấy
        biết cách tìm được nguồn năng lượng trong cuộc sống. Còn đối với người
        đàn ông Kỷ Tỵ thì sẽ tận hưởng cuộc sống được nhiều người quan tâm bởi
        vì anh tâ thích hào quang lấp lánh và muốn được tập trung chú ý.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhìn chung, người Kỷ Tỵ sở hữu sự kết hợp hoàn hảo giữa sự kiên trì,
        quyết tâm, sự thu hút và duyên dáng. Họ là người có tầm nhìn, năng lượng
        và bền bỉ để dốc vào công việc. Vì lẽ này, họ rất tham vọng, năng động
        và luôn nhận thức rõ mục tiêu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bằng cách kết hợp mạo hiểm và sự đa dạng vào cuộc sống, họ có thể đạt
        được mục tiêu trong đời. Bản tính dám nghĩ dám làm và kiến thức rộng
        giúp cho họ đi 50 xa và sẵn lòng chấp nhận các cơ hội. Điều họ học hỏi
        và trải nghiệm trong đời, cả tốt lẫn xấu sẽ giúp họ có động lực cải
        thiện bản thân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày này có nhu cầu sâu sắc đối với kiến thức. Hành trình tìm
        kiếm kiến thức và sự thấu hiểu rất quan trọng mang tính sống còn đối với
        họ và đảm bảo sự phát triển tài năng và kỹ năng. Họ thường khao khát
        kiến thức và thậm chí muốn hiểu sâu sắc về vấn đề tâm linh. Họ đầu tư
        nhiều thời gian vào việc này và đạt được lợi ích khi có tầm nhìn rộng
        mở, cũng như tìm ra được con đường cả đời để đạt được kiến thức.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Tỵ thường gặp may mắn. Họ sinh ra với cát tinh để hoá giải mọi
        năng lượng tiêu cực. Do đó, bât cứ khi nào họ đối mặt khó khăn trong
        đời, họ luôn nhận được sự giúp đỡ và vận may dễ dàng suôn sẻ giúp họ đạt
        được mọi thứ họ muốn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù họ không gặp khó khăn trong việc tạo ra tiền bạc, họ phải tránh
        để bản thân mình bị mờ mắt bởi những vấn đề liên quan đến vật chất khiến
        họ dễ đánh mất thứ tự ưu tiên. Nếu không, họ sẽ huỷ hoại nguồn năng
        lượng của mình còn nhanh hơn khi họ xây dựng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Tỵ là những người khéo ăn nói, điều này khiến cho họ dễ trở
        thành diễn giả và những người kể chuyện xuất sắc với biệt tài chinh phục
        và gợi cảm hứng cho khán giả. Khả năng hùng biện của họ đến từ tình yêu
        kiến thức và ngôn ngữ, được gây dựng từ sự ham thích đọc và học hỏi.
        Năng lực này cùng với cách diễn đạt ngôn ngữ khiến cho họ trở thành
        những nhà lãnh đạo lôi cuốn một cách tự nhiên. Khả năng lãnh đạo của họ
        thiên về chia sẻ và giảng dạy kiến thức cũng như sự thông thái mà họ đạt
        được cho người khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sự thiếu kiên nhẫn và không dung thứ khiến cho người Kỷ Tỵ hay biểu lộ
        mặt áp đặt của mình khi mọi thứ không như họ mong muốn, họ hi vọng có
        thể nhanh chóng thấy kết quả ngay lập tức. Điều này có thể khiến cho họ
        tự phá hoại chính mình vì thái độ này thật sự sẽ đối đầu với nhiều
        người. Do đó, đôi khi họ trở thành kẻ thù lớn nhất của chính họ bởi vì
        không nhìn thấy được bức tranh toàn cảnh lớn hơn hoặc đánh giá kết quả
        dài hạn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Tỵ sẽ luôn có dòng chảy tiền bạc ra vào trong đời. Tuy nhiên,
        để có thể thật sự trở nên giàu có và ổn định về tài chính, những người
        này cần phải học cách quản lý tài chính của họ. Điều này sẽ giúp họ
        tránh rơi vào những thời kỳ thiếu tiền tạm thời. Người Kỷ Tỵ có thái độ
        xởi-lởi-trời-cho khi xét về vấn đề tiền bạc. Nhưng xã hội yêu cầu cao và
        liên tục thay đổi ngày nay nơi cần phải đảm bảo về tài chính thì mới c1o
        thể đảm bảo được sức khoẻ, sự thoải mái. Đối với người Kỷ Tỵ mà tự nhiên
        không quá lo lắng về tài chính, điều này có thể mang nghĩ là đôi khi họ
        có thể đối mặt rắc rối về dòng tiền. Do đó, việc quản lý tài chính và
        giỏi về quản lý tiền bạc là rất cần thiết. Điều này có thể ngăn nỗi lo
        sợ bất ổn tài chính buộc họ phải ra những quyết định không mong muốn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi xét về quan hệ gia đình, người Kỷ tỵ sẽ có sự ủng hộ ít ỏi từ gia
        đình và vợ hay chồng. Điều này nghĩa là thành công và thành tích của họ
        có thể thông qua nỗ lực và quyết tâm của họ. Trong trường hợp này, người
        Kỷ Tỵ khá độc lập và có xu hướng làm việc một mình mà không cần người
        khác giúp đỡ và họ cảm thấy điều này rất hợp với họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Những Đặc Tính Của Người Kỷ Tỵ:</b> tâm linh, thông minh, độc lập,
        quan tâm đến đạt lợi ích vất chất, trí thức, hấp dẫn, vội vã, nhân đạo,
        tự hào.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có tinh thần độc lập khá cao, người Kỷ Tỵ cũng là dạng người thích ra
        lệnh hơn là nhận mệnh lệnh. Họ vươn cao trong vai trò lãnh đạo, và họ
        thường nắm vị trí tốt hơn trong chính quyền và họ giỏi việc đánh ía khó
        khăn và cách giải quyết rắc rối. Điều này là lý do vì sao mọi người có
        xu hướng tìm đến họ mỗi khi có khủng hoảng xảy ra vì người Kỷ Tỵ luôn có
        giải pháp thực tế. Ngoài ra, họ có khả năng cộng tác trong 1 nhóm và
        cũng không làm ảnh hưởng đến phương pháp làm việc của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Tỵ có xu hướng trở thành người nghiện công việc. Họ tận tâm với
        công việc, và đôi khi, họ có thể trở nên cống hiến cho sự nghiệp và kinh
        doanh hơn tất cả mọi thứ khác trong đời họ. Họ cần phải nhớ rằng cuộc
        sống vốn không chỉ có công việc. Gia đình, đời sống cá nhân, sức khoẻ và
        phát triển bản thân là những điều cũng khá quan trọng để cần phải giữ
        cân bằng trong cuộc đời.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Công Việc:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Tỵ có thể lựa chọn nhiều nghề nghiệp khác nhau. Gỉang dạy và
        thuyết trình là công việc tốt cho người Kỷ Tỵ vì nó cho phép họ theo
        đuổi việc kiến thức. Họ cũng thích phân tích thông tin và bởi vì nó đòi
        hỏi họ với tư cách là một nhà giáo dục, họ có thể học cách để gia tăng
        cải thiện kỹ năng và mở rộng kiến thức.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người Kỷ Tỵ thích sử dụng kỹ năng thực tế cũng như sẽ giỏi trong
        ngành quản lý tài sản, bất động sản, ngân hàng và thị trường chứng
        khoán.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bởi vì họ không thích nhận nhiệm vụ, người Kỷ Tỵ phù hợp với việc quản
        lý và điều hành doanh nghiệp riêng của họ. Điều này cũng phù hợp với thế
        độc lập của họ, vì họ có thể thể hiện mặt giỏi nhất của mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bản tính thương người của họ có thể dẫn họ đến nghề nghiệp trong ngành y
        hay chữa bệnh. Hay thậm chí, nếu họ thích, đi vào chính trị với mục tiêu
        thay đổi cuộc sống của cộng đồng. Ham muốn giúp đỡ người khác cũng khiến
        cho họ trở thành những nhà chính trị lý tưởng, cố vấn hay chuyên gia tâm
        lý, những sự nghiệp đòi hỏi kích thích về tư duy. Điều này giúp cho họ
        học hỏi trong khi vẫn có thể giúp đỡ người khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngoài ra, sự yêu thích các vấn đề tâm linh có thể dẫn họ đến một đời
        theo đuổi tôn giáo và tâm linh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những Đặc Tính Trong Công Việc - Sự Nghiệp: óc tổ chức, công bằng, chăm
        chỉ, thân thiện, thấu hiểu, cân bằng, óc thực tế, cứng nhắc, lạm quyền,
        khó tính, đa nghi, áp đặt, thiếu hiểu biết về tài chính.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Quan Hệ - Gia Đình:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Xét về tình yêu thì người Kỷ Tỵ rất thấu hiểu, quan tâm và yêu thương.
        Họ luôn bảo vệ người mình yêu và sẵn lòng làm bất kỳ điều gì để quan tâm
        và chăm sóc người họ quan tâm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ thường bị lôi cuốn bởi những nhân vật quyền lực có địa vị xã hội cao.
        Họ cũng quan tâm người nào có đầu óc tư duy và cùng niềm yêu thích trong
        các 53 hoạt động giống họ. Bởi vì người Kỷ Tỵ rất tập trung vào sự
        nghiệp họ, họ rất dễ hình thành mối quan hệ với những người tại công sở.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù người Kỷ Tỵ ấm áp và yêu thương, họ không luôn bộc lộ cảm xúc của
        mình. Sự che giấu cảm xúc khiến cho họ có vẻ lạnh lùng. Ngoài ra, họ có
        xu hướng cứng đầu có thể dễ dẫn đến tranh cãi trong mối quan hệ. Tuy
        nhiên, các cuộc tranh cãi trong mối quan hệ tình cảm của họ thường không
        kéo dài.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi xét về tình bạn thì họ tỏ ra năng động, thực tế và bảo vệ. Họ lao
        động vất vả vì những người mà họ yêu thương và mong đợi được đền đáp.
        Cũng như tình yêu của người Kỷ Tỵ, phần lớn tình bạn của họ hình thành
        tại nơi làm việc. Điều này là bởi vì họ dành phần lớn thời gian và năng
        lượng của họ tại đây.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trong mối quan hệ, họ cần phải thận trọng nếu không sự thiếu quyết đoán
        hay tâm trạng thất thường có thể làm hỏng hạnh phúc của bạn. Nếu mối
        quan hệ không tốt như mong đợi, người Kỷ Tỵ có thể tỏ ra quyền uy, ép
        buộc, áp đặt. Người này không biết được rằng thông qua những thái độ
        tiêu cực này thì mối quan hệ của họ cuối cùng sẽ bị nghiền nát dưới áp
        lực căng thẳng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đời sống hôn nhân của người Kỷ Tỵ nhìn chung hạnh phúc. Người Kỷ Tỵ là
        bố mẹ tận tâm và con cái sinh ra thông minh và khôn ngoan, cũng như là
        học sinh thông minh và có tương lai hứa hẹn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Kết hôn với người nữ Kỷ Tỵ có thể khó khăn hơn vì họ có xu hướng mê công
        việc. Cũng có khả năng gặp vấn đề về thụ thai. Một khi họ có con, họ cần
        cẩn trọng tránh tỏ ra quá bảo bọc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Những Nét Đặc Tính Trong Tình Cảm - Quan Hệ:</b> chung thuỷ và quá
        bảo bọc, bị hấp dẫn bởi người thông minh và có quyền lực, thích kích
        thích về tâm linh và trí óc, các mối quan hệ xã hội đến từ công việc, có
        xu hướng ép buộc.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:{" "}
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu bạn yêu thích những bộ phim miền Viễn Tây nơi không có pháp luật
        nhưng vẫn có những người sẵn sàng đứng ra bảo vệ tình yêu, thì bạn sẽ
        yêu một người sinh ngày Kỷ Tỵ. Kỷ toạ trên Tỵ bao gồm cả Bính Chính Ấn,
        Mậu Kiếp Tài và Canh Thương Quan.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Hành Hoả đem đến sự sang quý và tương lai tốt đẹp cho người Kỷ Tỵ. Những
        người này mạnh mẽ, kiên trì và thông minh. Trụ ngày này toạ Chính Ấn,
        Kiếp Tài và Thương Quan. Chính Ấn Tinh đem đến cho người Kỷ Tỵ sự khao
        khát kiến thức. Tình yêu kiến thức khơi dậy trong họ ham muốn học hỏi
        liên tục và gặt hái những trải nghiệm mới trong đời. Kiến thức giúp cho
        họ có quyền lực trong sự nghiệp và cuộc sống. Mặt khác, Thương Quan cho
        họ khả năng thuyết trình và sức hấp dẫn, giúp củng cố khả năng gỉang
        dạy, gáio dục và gây ảnh hưởng đến người khác bởi kiến thức của họ. Bởi
        vì Kỷ Tỵ đi kèm với Thương Quan cùng với Ấn Tinh ở Địa Chi, là dấu hiệu
        của thịnh vượng, khi 2 sao này được kích hoạt. Khi cả hai sao đều xuất
        hiện ở Thiên Can của lá số hoặc trong những đại vận đầu tiên thì biểu
        thị may mắn. Tuy nhiên, trụ này có thể ít may mắn hơn nếu Thương Quan
        cùng gặp Kiếp Tài trên Thiên Can. Điều này biểu thị người này có thể đối
        mặt những cuộc chiến thường xuyên với những người đầu óc nhỏ mọn suốt cả
        cuộc đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Tỵ thích kỷ luật và sự ổn định. Họ đầy năng lượng, thực tế và
        quyết tâm. Tham vọng và có óc tổ chức, họ có thể đạt thành công thông
        qua công việc vất vả và kiên trì. Tất cả những điều này là do Kiếp Tài
        Tinh ẩn tàng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sáng tạo và lý tưởng, người Kỷ Tỵ thích gợi cảm hứng cho người khác. Họ
        có thể yêu thích vị trí quản lý vì thích kiểm soát mọi thứ. Họ có khiếu
        kinh doanh tốt và nhân viên thích mô tả họ là thẳng thắn, cứng rắn nhưng
        công bằng. Những điều này là do đặc tính của Canh Kim, tức Thương Quan
        mà họ toạ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Tỵ khát sự an toàn và họ biết rất rõ về lợi ích của nền tảng
        vững chắc cho bản thân họ và gia đình. Họ thích sống trong môi trường
        hạnh phúc và họ rất gắn bó với gia đình cũng như xu hướng thích quay về
        mái ấm. Nuôi dưỡng trẻ con là thiên bẩm của họ và họ là những bậc cha mẹ
        tuyệt vời. Họ có một mái nhà trật tự cũng như họ có thể cần phải học hỏi
        cách để trở nên tiết 55 kiệm và hoạch định tài chính tốt để có thể tránh
        những giai đoạn bị đe doạ về tài chính. Điều này là đặc tính của Chính
        Ấn thịnh vượng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù người Kỷ Tỵ rất từ bi và quan tâm, họ có thể gặp khó khăn để có
        thể biểu lộ mặt tình cảm của mình. Họ rất tự cao và không dễ dàng để lộ
        ra ngoài. Họ thường cần phải vượt qua xu hướng trở nên quá chỉ trích và
        bản tính kiểm soát khiến cho họ trở nên áp đặt và độc tài. Điều này là
        bởi vì nét tiêu cực của Mậu Thổ vượng, đến từ Kiếp Tài.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Hình ảnh để hình dung Kỷ Tỵ là một hạt ngũ cốc mọc lên trên một ngọn
        đồi, cao hơn mực nước biển nơi có không khí khô. Bất chấp vị trí của nó,
        vẫn có cơ hộ để hạt giống này nhận được đủ độ ẩm nhưng tại vị trí này
        thì nước nhanh chóng chảy đi. Do đó, bất kể Kỷ Thổ là âm thổ xuất hiện
        trong lá số, có dấu hiệu rằng Kỷ Tỵ có thể chịu đươc nước mưa nhưng
        không chịu được khô.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trụ này thường được xem là dấu hiệu của nét đẹp và thịnh vượng. Rất tốt
        nếu trụ này gặp thêm hành Kim, Thuỷ và Mộc. Sẽ tốt nếu tránh gặp nhiều
        Hoả và Thổ trong lá số hay đại vận cũng như những hành trên sẽ ít gây
        ảnh hưởng tốt lên lá số của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Vị trí của trụ ngày này hơi khô. Kim loại khô trong Tỵ không thể tạo ra
        ngũ hành Thuỷ cần thiết. Không có Thuỷ, Kỷ Thổ không thể có khả năng
        phát triển được Mộc, vốn là nhiệm vụ căn bản của mọi can Kỷ Thổ. Điều
        này là lý do vì sao nếu gặp thêm hành Hoả sẽ làm khô nước bên trong Kỷ.
        Là dấu hiệu người này sẽ tiêu xài hết tiền thừa kế mà họ nhận được hoặc
        lãng phí cơ hội được đem đến cho họ trong cuộc đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trong lá số này thì nếu Kỷ không bị ảnh hưởng bởi các ngũ hành Hoả khác
        thêm, thì Tỵ sẽ là hành Hoả duy nhất thống trị (biểu thị tôn giáo hoặc
        tâm linh). Điều này bởi thị người này sẽ có xu hướng thiên về nghiên cứu
        tâm linh. Đây là dấu hiệu tích cực gíac ngộ tâm linh sẽ là sự cứu rỗi
        của họ. Họ có thể đạt được sự thông thái và sự bình an bên trong, có xu
        hướng biến nó thành sự cứu rỗi của họ khi cần thiết. Trong nhiều trường
        hợp, người Kỷ Tỵ sẽ ít khi bị lôi cuốn bởi những ham muốn đời thường như
        sự tham lam, ghét bỏ, sắc dục. Nếu trụ này gặp Bính Dương Hoả ở trên Can
        thì biểu thị người này sẽ có khả năng đạt địa vị xã hội rất cao.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đối với nữ giới Kỷ Tỵ thì sẽ có dấu hiệu xấu về hôn nhân. Điều này bởi
        vì đối với người phụ nữ Kỷ, Giáp Mộc chính là Quan Tinh. Điều này bởi vì
        hành Mộc héo úa trong mùa hè, do đó biểu thị mối quan hệ tình cảm với
        chồng (hành Mộc) nhìn chung là yếu. Cần nhiều nỗ lực từ phía người vợ
        nếu muốn có một mối quan hệ dài lâu và bền vững. Cô ta phải học cách
        kiểm soát cảm xúc (vốn được đại diện bởi ngũ hành Hoả Ấn Tinh) và không
        được để cho cơn giận dữ, đa nghi che mờ lý trí và sự phán xét người
        chồng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ấn đại diện cho mẹ của người Kỷ Tỵ cũng toạ bên trong cung Phu Thê. Điều
        này biểu thị rằng mẹ của cô gái này cũng sẽ có ảnh hưởng to lớn lên đời
        sống hôn nhân, có thể gây hại cho người chồng. Trong nhiều trường hợp
        cực đoan, khi mà can Gíap Mộc lộ bên trên hay ở gần bên cạnh hành Thuy,
        là dấu hiệu của người chồng Kỷ Tỵ có thể tranh đoạt tài lộc từ cô ấy.
        Điều này bởi vì hành Thuỷ (là hành Tài cho lá số Kỷ Thổ) rất quan trọng
        đối với cả Kỷ và Giáp. Bởi vì Giáp toạ trên Thuỷ, ngũ hành này được dời
        ra xa khỏi Kỷ. Nếu phụ nữ sinh ngày Kỷ Tỵ là giàu có và thành công về sự
        nghiệp, tốt nhất là nên buộc người chồng ký cam kết tiền hôn nhân trước
        khi kết hôn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đối với nam giới sinh ngày Kỷ Tỵ, sao người vợ toạ bên trong địa chi
        Hợi. Nếu Tỵ gặp xung với Hợi thì biểu thị mối quan hệ xấu cho anh ấy. Có
        thể là tốt hơn nên xem cuộc hôn nhân của họ là hợp đồng hay thoả thuận
        liên minh thay vì tình yêu. Anh ta sẽ tốt hơn nếu có thể chọn được một
        người vợ cùng chia sẻ con đường sự nghiệp và mục tiêu, vì mối quan tâm
        chung và định hướng sự nghiệp sẽ giúp cho cặp đôi có chung nền tảng để
        phấn đấu. Sẽ tót nếu như người vợ cũng là bạn thân nhất của anh ấy trong
        đời. Điều này sẽ làm giảm ẩn xung trong lá số.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tỵ chứa Mậu Thổ (là Lộc của 12 vòng Trường Sinh). Điều này biểu thị
        người này có một cuộc đời yên bình, có vẻ ngoài bí ẩn khiến người khác
        cảm thấy khó lòng tiếp cận và không thân thiện. Có khả năng rằng ngay cả
        bạn bè và gia đình của họ cũng cảm thấy khó hiểu họ và không thể vượt
        qua vẻ ngoài được che đậy của người Kỷ Tỵ để có thể biết họ thật rõ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Tỵ sinh vào mùa Xuân hay Hè sẽ nhanh trí, trong khi người sinh
        vào mùa Thu hay Đông sẽ có tính cách mạnh mẽ và vững chãi. Người Kỷ Tỵ
        57 sinh vào ban ngày sẽ sống thọ và thịnh vượng. Người sinh vào ban đêm
        sẽ phải làm việc vất vả mới có thể đạt thành công thông qua nỗ lực của
        riêng họ vì họ sẽ khó nhận được sự giúp đỡ hay hỗ trợ từ người xung
        quanh. Không tốt cho người Kỷ Tỵ sinh ra vào tháng Mão, những người này
        sẽ phải làm việc rất vất vả và vẫn cảm thấy khó đạt được nhiều như họ
        mong đợi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Kỷ được xem như là hành mang tính chấp nhận và nuôi dưỡng, nhưng trong
        trường hợp này thiếu nước cho nên khiến cho bản tính của Thổ khó lòng
        nuôi dưỡng và trở nên cứng rắn. Với Bính là Chính Ấn là Khí chính, mặt
        trời soi rọi, họ biết cách phân tích mọi tình huống và rất thông minh.
        Người khác cũng không thể kể cho họ một câu chuyện tình cảm sướt mướt mà
        hầu hết người sinh ngày Kỷ Thổ đều tin nhưng Kỷ Tỵ sẽ tìm ra được sự
        thật đằng sau.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Kỷ Thổ được kẹp giữa bởi Kiếp Tài Mậu và Thương Quan Canh, đều là hai
        sao biểu thị sự chống nguyên tắc. Họ không sợ đối đầu, và không ngại
        ngùng chống lại người có quyền lực. Canh Kim là ngũ hành dữ dội sẽ muốn
        nói lên sự thật.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bởi vì họ có Kiếp Tài và Thương Quan trong lá số, cả nam và nữ đều có xu
        hướng phá huỷ mối quan hệ tình cảm vì cả Tài đều bị khắc bởi Kiếp Tài
        trong khi Quan sẽ bị tấn công bởi Thương Quan. Nếu bạn có vợ hay chồng
        là người muốn phơi bày mọi sự thật ra trước công chúng, thật khó để
        tưởng tượng làm sao để có được gia đình yên bình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tỵ hợp với Thân sẽ thu hút Canh Thương Quan, Mậu Kiếp TÀi và Nhâm Chính
        Tài. Nam giới ngày Kỷ Tỵ trông sẽ anh hùng vì họ có thể thu hút người vợ
        Chính Tài nhưng cùng lúc lại xua đuổi họ đi vì Kiếp Tài. Điều này thể
        hiện hình ảnh của một trai hư khi bạn biết rằng bạn muốn họ nhưng cũng
        đồng thời biết bạn sẽ bị họ bỏ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tỵ xung với Hợi xua đuổi Nhâm Chính Tài và Giáp Chính Quan. Cả sao người
        chồng hay vợ đều bị xung, biểu thị mối quan hệ xuống cấp. Cũng có thể
        nói rằng cả tài lộc hoặc quyền lực đều không cuốn hút người này.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhìn chung, chúng ta nhìn tấhy một con người phớt đời. Họ là những chiến
        binh đơn độc không sợ gì ngay cả khi tập thể chống ngược lại họ. Họ hi
        sinh sự thoải mái và gia đình để đạt được và giữ được cách sống của họ.
        Vào lúc khủng 58 hoảng, họ sẽ là những ứng cử viên số một cho chức danh
        lãnh đạo nhưng vào lúc thời bình thì họ sẽ là người quấy rối không thể
        ngồi yên và tận hưởng thành công.
      </p>
    </div>
  );
};

export const KyMao = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Kỷ Mão là người này là thiên tài trong lĩnh vực nghệ
        thuật, có tài năng văn hoá/nghệ thuật và có thể phát triển tốt trong
        ngành này. Có thể nắm bắt cơ hội tốt và tiến bộ mạnh mẽ. Độc lập với cảm
        xúc, yêu và ghét đều phân định rõ ràng. Có cách tiếp cận trực tiếp,
        thẳng thắn đối với bất cứ mọi thứ. Không phải là người nói nhiều và sôi
        nổi. Có vẻ ngoài độc đáo. Dễ gặp nhiều sự kiện quái đản, kỳ lạ và khó
        gỉai thích được. Dễ gặp nhiều tranh cãi trong đời sống hôn nhân về
        chuyện con cái. Nếu là nữ giới, có tình yêu bao la kiểu người mẹ, nhân
        từ nhưng cũng dễ bị lừa vì những lời nói mật ngọt.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu bạn từng thử tách nhổ cỏ khỏi đất, bạn sẽ cảm thấy khó khăn vì luôn
        có độ kết dính cũng như cỏ dại cũng sẽ mọc trở lại. Cũng vì rễ bám sâu
        vào đất, họ có sức mạnh và sự ổn định gắn với đất, nó bảo vệ họ và giúp
        họ liên tục sinh tồn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Hãy hình dung đến cây cỏ mọc trên đất tốt gìau dinh dưỡng, bạn sẽ có
        được một bức tranh chính xác Kỷ Mão là gì. Họ quan tâm, nồng nhiệt, tốt
        bụng và tử tế, cũng tương tự như đất nông trại sinh ra các mùa vụ để
        nuôi sống người nông dân cày xới.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Kỷ Thổ là người rất quan tâm đến tập thể. Họ luôn sẵn lòng cho đi và
        quan tâm đến người khác. Bạn có thể tưởng tượng họ là người mẹ, sẵn lòng
        hi sinh và cho đi vì đàn con cho đến giờ phút cuối cùng. Ngược lại, họ
        có thể trở thành người ngây thơ và dễ bị lừa nếu có dính đến yếu tố cảm
        xúc (thuỷ). Ngay cả khi họ phải vì lợi ích của tập thể để giải quyết một
        vấn đề nào đó thì họ cũng rất ngại vì lẽ đó mà phải làm tổn thương một
        người nào.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Mão rất tham vọng và chú trọng đến hành động. Người sinh trụ
        ngày này sở hữu cái nhìn lạc quan và sẵn lòng sống một cuộc đời trọn
        vẹn. Bị thúc đẩy bởi thành công, họ luôn tìm kiếm sự tiến bộ của bản
        thân, sự thông minh nhanh nhạy và quyết tâm thúc đẩy họ đạt được mục
        tiêu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Mão luôn giữ vẻ ngoài bình tĩnh và ổn định bất kể tình huống.
        Họ ít khi hành động hấp tấp và luôn thích nghĩ trước khi nói hay đồng ý
        cam kết gì. Họ khá thông minh với một cái đầu luôn nghĩ chiến lược. Do
        đó, bất cứ họ định làm gì đều được lên kế hoạch kỹ lưỡng. Họ sẽ tuân thủ
        theo từng bước của kế hoạch để đảm bảo mọi thứ theo đúng kế hoạch và
        không có gì mà họ không kiểm soát được.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Mão trong tâm hồn rất trẻ trung thường có tính cách thích vui
        đùa. Họ nhìn vào cuộc đời khác hơn những người khác. Bản tính tươi trẻ
        này sẽ ở với họ suốt cả đời và cũng là điều khiến cho người khác thích
        họ. Sự tử tế chân thành và bao dung của họ là điểm gây ấn tượng cuốn hút
        và cũng khiến cho họ dễ gần gũi với người khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đối với nhiều người sinh ngày này, họ có sự kết nối với thế giới giúp
        chuyển hoá mọi sự bình dị tầm thường. Sự liên kết này giúp gia tăng nhận
        thức về ánh sáng, màu sắc, hình dạng và âm thanh. Nhiều người sẽ sử dụng
        tài năng này để theo đuổi tiếng gọi tâm linh trong đời họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Có một mặt khác của người Kỷ Mão đánh giá cao vẻ ngoài và hình dáng cơ
        thể của họ. Ngoại hình và khiếu thẩm mỹ của họ rất quan trọng đối với
        người Kỷ Mão. Họ đầu tư nhiều thời gian để tôn lên cơ thể và cố gắng
        điều chỉnh cơ thể có dáng đẹp, và có lẽ tiêu tiền cho quần áo và các vật
        dụng xa xỉ khác để tạo ra ấn tượng của sự giàu có và tinh tế. Khẩu vị
        sang trọng thường xuyên thúc đẩy họ phải có thêm tiền cần thiết để hỗ
        trợ cho thói quen xa xỉ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tất cả mọi tiền trên thế giới cũng sẽ không giúp đem lại hạnh phúc thật
        sự mà người Kỷ Mão tìm kiếm. Điều này bởi vì người Kỷ Mão, cả hành Mộc
        và Thổ đều muốn có hành Thuỷ (là hành tài lộc). Nhưng cả Mộc và Thổ cuối
        cùng sẽ hút và làm cạn kiệt nước. Vì lẽ này, sự thích sở hữu đồ đắt tiền
        và cơ thể xinh đẹp sẽ không đem lại sự bình yên. Để tránh những thứ
        trên, rất quan trọng là họ nên tập trung vào bên trong, vấn đề tinh
        thần. Điều này cũng có nghĩa là họ cần có thêm hành Hoả vào trong đời
        họ. Hoả đem đến sự hạnh phúc cho trụ ngày này vì nó được sinh ra bởi
        Mão, và nó cũng nuôi dưỡng Kỷ Thổ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu lá số chứa nhiều Thổ, đôi khi họ cũng khó khăn trong việc ra quyết
        định và dễ dàng cảm thấy buồn chán vì họ không thích làm một việc lặp đi
        lặp lại nhiều lần. Do đó, họ có thể hành xử ích kỷ và hấp tấp. thái độ
        này không tốt về mặt sự nghiệp và kinh doanh, vì nó tạo ra sự phân chia
        giữa nhu cầu sáng tạo mà không bị tiền bạc thúc đẩy và nhu cầu muốn hi
        sinh khao khát để trở nên thực tế hơn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tìm kiếm sự giúp đỡ và định hướng vượt qua những khó khăn có thể là khó
        khăn đối với người Kỷ Mão. Trước tiên, hầu hết họ không có nhiều sự ủng
        hộ từ bạn bè, vợ chồng hay gia đình. Điều này khiến cho họ là những
        người bản chất độc lập không mong đợi sự giúp đỡ từ bất kỳ ai. Do đó, họ
        tự tạo nên thành công của họ thông qua nỗ lực của riêng họ. Niềm tin của
        họ đến từ lối suy nghĩ cũ kỹ, khi thành công trong cuộc sống có thể đạt
        được thông qua sự cố gắng và chăm chỉ của một người.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Mão có năng lực tập trung cao. Cũng như những mầm non vượt qua
        lớp băng vào tuần đầu tiên của tháng mùa Xuân, người Kỷ Mão thường tận
        tâm với công việc hay nghệ thuật, đến mức gần như ám ảnh. Thái độ này có
        thể tàn phá sức khoẻ và đời sống xã hội của họ về lâu dài. Tuy nhiên,
        khả năng đặc biệt này có thể tốt trong việc xây dựng nền tảng vững chắc
        trong sự nghiệp và tài chính.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Những Đặc Tính Của Kỷ Mão:</b> lý tưởng, cuốn hút, hướng ngoại, thẳng
        thắn, xa xỉ, tâm linh, cuốn hút, ham chơi.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Mão là những ứng cử viên xuất sắc cho bất kỳ sự nghiệp nào họ
        chọn bởi vì sự bộc lộ bản thân, kỹ năng quản lý tổ chức và lãnh đạo. Họ
        có khả năng phân tích kiến thức rất nhanh chóng và là ứng viên phù hợp
        cho gần như bất kỳ sự nghiệp nào họ muốn. Tham vọng của họ rất phù hợp
        cho việc kiếm tiền và danh vọng cho bản thân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Vì người Kỷ Mão là những người rất độc lập, họ thích chọn cách làm việc
        một mình - mặc dù họ không có vấn đề trong việc hợp tác với người khác
        nếu cần thiết. Họ có thể vươn cao trong những nghề nghiệp đòi hỏi thử
        thách thường xuyên cho kỹ năng và tri thức của họ. Họ đặt nặng giá trị
        cho việc phát triển bản thân, học hỏi điều mới thông qua kinh nghiệm
        thực hành và từ những người họ gặp gỡ.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Công Việc:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Công việc cho phép người Kỷ Mão thể hiện bản thân như viết lách, nghệ
        thuật, âm nhạc hay giải trí đều khá hứa hẹn. Tuy nhiên, con mắt thẩm mỹ
        của họ sẽ giúp họ tìm được thành công trong sự nghiệp có liên quan với
        nghệ thuật, hàng xa xỉ hay đồ cổ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khả năng phân tích tình huống chính xác giúp cho họ trở thành ứng viên
        cho sự nghiệp trong ngành kỹ thuật, khoa học hay môi giới chứng khoán.
        Họ cũng có thể sử dụng kỹ năng phân tích cho các nghề nghiệp như đối
        ngoại, luật, ngân hàng, chính trị, bán hàng và tiếp thị.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngoài ra, có nhiều người sẽ chọn tập trung vào bản tính quan tâm chăm
        sóc như tư vấn, điều dưỡng hay gây quỹ từ thiện.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bất kể họ chọn ngành nghề nào, miễn là họ cảm thấy sự thoả mãn, họ sẽ
        luôn tìm thấy hạnh phúc trong công việc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Những Đặc Tính Trong Công Việc - Sự Nghiệp:</b> sáng tạo, siêng năng,
        thân thiện, từ bi, nghệ sĩ, trí nhớ tốt, trực giác, nhanh nhạy, lo lắng,
        bất mãn, ích kỷ, chuyên quyền, ham vật chất, bí ẩn, thiếu kiên nhẫn.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Mối Quan Hệ - Gia Đình:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi xét về mối quan hệ, người Kỷ Mão thích cho đi, yêu thương và bảo vệ.
        Họ thường dành nhiều thời gian suy nghĩ trước khi đồng ý bước vào một
        mối quan hệ nghiêm túc nào và thường bị hấp dẫn bởi những ai thành công
        và độc lập. Người bạn đời của họ phải sôi động, đáng tin cậy và có thể
        dựa vào. Một khi họ đã tìm được người đồng điệu về tâm hồn, thành công
        và hạnh phúc sẽ theo sau.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Có một số nhân tố gây nên sự không hạnh phúc trong mối quan hệ tình cảm
        ví như thiếu tiền bạc. Nếu họ bị tiền bạc giới hạn gây căng thẳng, họ sẽ
        cảm thấy bất mãn và không hạnh phúc, dẫn đến họ thường phải chạy đua
        theo nó. Đây là vòng tuần hoàn bất tận mà họ cần chú ý.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trong khi tìm kiếm người đồng điệu về tâm hồn, người Kỷ Mão luôn đặt kỳ
        vọng cao cho mọi mối quan hệ. Bởi vì điều này, họ thích có những chuyện
        tình ái ngắn hạn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Mão có đời sống xã hội rất năng động. Họ dễ kết bạn và thường
        có nhiều loại bạn bè khác nhau. Họ thích bạn bè là những người thành
        công trong lĩnh vực của họ và có những câu chuyện hoặc kinh nghiệm thú
        vị để chia sẻ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi xét đến vấn đề họ quan tâm, những người này có thể tỏ ra rất bảo
        thủ. Mặc dù họ chỉ muốn tốt, họ có thể phải ngăn bản thân không trở nên
        quá cứng nhắc với người khác vì điều này có thể xua đuổi người khác đi.
        Hãy tôn trọng ranh giới của người khác và người Kỷ Mão sẽ có thể liên
        kết với người khác ở mức độ sâu hơn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Mão tôn trọng sự chung thuỷ và trung thành ở người khác, và họ
        đánh gía cao những nét tính cách này trong bạn bè và gia đình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Phụ nữ sinh ngày Kỷ Mão có hôn nhân tốt và là người vợ tuyệt vời. Họ
        cũng rất bảo vệ con cái, và cũng như quan tâm chăm sóc chồng mình bằng
        cách cho anh ta tình yêu vô điều kiện.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặt khác, đàn ông Kỷ Mão sẽ có hôn nhân hạnh phúc hơn nếu họ kết hôn với
        người lớn tuổi hơn. Người vợ lớn tuổi sẽ đóng vai trò ảnh hưởng về suy
        nghĩ hay thậm chí giúp ích để họ thành công hơn trong tương lai.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Mão cần phải thận trọng không được để cho nhu cầu an toàn làm
        chủ bản thân họ. Nếu như vậy thì họ sẽ kết hôn vì nhiều lý do khác chứ
        không phải tình yêu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Những Đặc Tính Trong Tình Yêu - Quan Hệ:</b> cuốn hút thử thách,
        thích chiến thắng, đời sống xã hội năng động, bị chia rẽ giữa trách
        nhiệm và tình yêu, chỉ tập trung vào mục tiêu, nghiện cảm gíac thành
        công.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Kỷ Mão toạ trên Thất Sát. Thất Sát mang theo Mão, là sao Đào Hoa. Cục
        này khiến cho người Kỷ Mão rất hấp dẫn, lôi cuốn. Thất Sát cho người này
        sự can đảm, khôn lanh và ham muốn bên trong để thành công và chiến
        thắng, trong khi Sao Đào Hoa khiến cho người khác yêu thích người này.
        Những phẩm chất này kết hợp lại khiến cho người Kỷ Mão lợi thế hơn người
        trong kinh doanh và sự nghiệp. Đôi khi nhiều người có thể nghĩ rằng Kỷ
        Mão là con rắn độc vì vẻ ngoài của họ ẩn chứa động lực che giấu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngược lại với nhận thức thông thường mà nhiều người hay nghĩ, người Kỷ
        Mão thật sự là những người rất chân thực và siêng năng. Họ thích nghi
        tốt và quyết tâm cũng như gặp nhiều cơ hội thành công trong đời. Điều
        này bởi vì Mão là Âm Mộc vươn lên trên Âm Thổ. Những người này thật sự
        đánh giá cao sự ổn định và an toàn; họ sẵn lòng lao động vất vả để xây
        dựng nền tảng vững chắc cho gia đình. Điều này hiển nhiên bởi vì người
        Kỷ Thổ đại diện cho sự bảo vệ và tình yêu. Với sự giúp đỡ của Ất Mộc
        Thất Sát thì họ có khiếu kinh doanh tốt giúp họ đạt được thành công về
        vật chất mà họ muốn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thích chỉ trích và khó hài lòng, người Kỷ Mão có những tiêu chuẩn rất
        cao. Đây là điển hình của Mão, là một trong Tứ Chính. Họ có kỳ vọng cao
        ở bản thân và có thể bị lôi cuốn vào làm việc quá độ để đạt mục tiêu. Sẽ
        không khó gì bất thường nếu thấy họ tự thúc đẩy bản thân và cố gắng
        nhanh chóng hoàn thành nhiệm vụ, trong khi mặt khác thì chấp nhận thêm
        nhiều nhiệm vụ và dự án vào trong công việc vốn đã rất nặng của họ. Đây
        là bản chất của Mộc khắc Thổ của trụ ngày này, đồng nghĩa với đất sẽ
        luôn bị cày xới. Điều quan trọng là những người này cần phải nhớ dành
        thời gian cho chính bản thân họ để có thể thư giãn và hồi phục. Họ có
        thể đạt lợi ích từ việc dùng thời gian này để học hỏi và phát triển thêm
        kỹ năng mới.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Mão thích nắm quyền kiểm soát hướng đi cuộc đời của họ. Đây là
        bản chất của Thất Sát Tinh. Họ đánh giá cao kinh nghiệm thực hành và nhu
        cầu cần cảm thấy họ có sự thấu hiểu rõ ràng bản thân về từng tình huống.
        Họ không thoả mãn khi phải chấp nhận nghe lời người khác. Họ không thích
        cảm thấy rằng họ đang bị thao túng hay ép buộc vào một dự án hay một mối
        quan hệ, và do đó có thể trở nên rất cứng đầu và ngoan cố khi đối mặt áp
        lực.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Mão thích học hỏi và trải nghiệm những điều mới mẻ. Đây là tính
        chất điển hình của can Âm Thổ. Họ hướng ngoại và có khả năng tỏ ra duyên
        dáng nhưng họ cũng nói rất thẳng và thậm chí là gây đau lòng cho người
        khác. Họ thiếu sự khéo léo ngoại giao và ăn nói ngọt ngào. Họ thường
        thiếu ngoại giao và chiến thuật và cũng có xu hướng tấn công người khác.
        Điều này dẫn đến cảm giác kỳ quặc và hiểu lầm vì họ có thể rất tử tế và
        họ lo lắng nhiều về những gì người khác nghĩ về mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Mão có tình yêu gia đình. Họ rất nhân từ với người mình yêu và
        có trách nhiệm với nhu cầu của người khác. Họ cũng có thể trở thành
        những bậc cha mẹ tận tâm. Chỉ trong mối quan hệ gần gũi, họ mới tiết lộ
        những tính cách ít lộ ra. Khi sự bất an xuất hiện, họ có thể trở nên tâm
        trạng và không giao tiếp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Mão thường được biết đến là Toạ Sát Đới Cước cục, ban đầu, mang
        theo ảnh hưởng nặng. Nó biểu thị cơ hội cao hơn trung bình rằng người
        này sẽ dễ bị ảnh hưởng bởi những bệnh dai dẳng kéo dài và thậm chí nếu
        bệnh nặng có thể chịu chấn thương hay thậm chí dẫn đến tàn tật.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu Kỷ Mão xuất hiện ở trụ Năm thì có ảnh hưởng tiêu cực cho bố mẹ và tổ
        tiên của người này. Nếu trụ này xuất hiện ở trụ Tháng thì có cơ hội tốt
        rằng bố mẹ của người này sẽ ly thân. Nếu cục này xuất hiện ở trụ Ngày,
        có nguy cơ rằng người này sẽ bị đặt ở tình huống nguy hiểm khi bước vào
        độ tuổi thanh niên. Nếu xuất hiện ở trụ giờ, bệnh tật cho con cái của
        người này hoặc gặp nhiều rắc rối khi về già.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trong trụ Kỷ Mão thì hành Thổ được xem như là ở trạng thái Hưu Tù. Điều
        này biểu thị mất mát Khí. Đối với Kỷ Mão, biểu thị đất đai không màu mỡ.
        Nếu 52Kỷ Thổ toạ trên Mão Mùi ở Địa Chi thì có khả năng người này sẽ từ
        bỏ giấc mơ trước khi họ đến độ tuổi trung niên. Họ có thể thiếu nguồn
        lực cần thiết để thật sự đạt được mục tiêu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người này có thể có nhiều may mắn hơn nếu trụ ngày này được hỗ trợ bởi
        ngũ hành tích cưc Bính và Đinh trong lá số. Sự hiện diện của Sửu và Tuất
        trong Địa Chi là dấu hiệu tích cực của thành công và may mắn. Cực kỳ
        quan trọng nếu Kỷ Mão không gặp Hình hay Xung từ Dậu hay Tý. Sự xuất
        hiện của những Địa Chi này sẽ biến ảnh hưởng tiêu cực của Thất Sát bị
        yếu đi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Cục có lợi nhất là Kỷ Mão gặp thêm Hoả, Thổ, Giáp Mộc, Sửu và Tuất trong
        lá số hay Đại Vận. Gặp thêm Kim, Thuỷ, Thân, Tý, Mão và Dậu thì không
        tốt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trong đời thực, về hình ảnh là hợp lý nếu có Mộc xuất hiện trên Thổ.
        Điều này biểu thị mộc mọc trên mặt đất. Nhưng trong trường hợp Kỷ Mão
        thì tình huống đảo ngược, trong đó Thổ bao phủ Mộc. Hành Mộc trong
        trường hợp này đại diện cho hạt giống nằm bên dưới đất và không phát
        triển. Nó sẽ cần có nước để tưới tắm và cho phép Kỷ Mão phát triển tài
        năng của họ. Đây là dấu hiệu tốt cho Kỷ Mão nếu Mộc xuyên lên trên Can.
        Điều này biểu thị sẽ tốt nếu thấy Ất Mộc ở Thiên Can trong lá số. Điều
        này biểu thị người Kỷ Mão sẽ có thể đánh thức tiềm năng trong đời và đạt
        được thành công mãnh liệt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trụ này ám hợp với Tuất chứa ngũ hành Hoả. Điều này biểu thị người này
        có thể có kỹ năng giỏi trong việc vận động sự ủng hộ từ người khác. Điều
        này bởi vì Tuất là hành bạn bè, cũng là hành mà Mão và Tuất cùng hợp đê
        tạo nên Hoả, biểu thị sự giúp đỡ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Mão sinh vào mùa Xuân và Hè có thể đạt được thành công tài
        chính. Nếu sinh vào mùa Thu và Đông thì có dấu hiệu danh tiếng. Nếu sinh
        vào ban ngày thì người này sẽ có thể tận dụng toàn bộ tài năng. Nếu sinh
        vào ban đêm thì chỉ đạt thành tích trung bình trong đời. Không tốt nếu
        Kỷ Mão sinh vào tháng Sửu vì họ sẽ thường xuyên gặp xui xẻo trong đầu tư
        và sẽ liên tục gặp thách thức trong đời dẫn đến nhụt chí.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mão mang theo Ất Thất Sát. Người này không còn đơn độc nếu có Thất Sát
        mạnh mẽ bên cạnh. Người có Thất Sát thường có xu hướng hành động rất
        mạnh mẽ. Điều này sẽ làm giảm bớt sự thụ động cố hữu của Kỷ Thổ bằng
        cách sử dụng Thất Sát như là phương tiện để trung hoà. Bởi vì họ có
        nhiều nguồn năng lượng để khởi đầu, năng lực hành động sẽ thúc đẩy hành
        động lên gấp nhiều lần. Bởi vì căn bản là Thổ, Thất Sát này sẽ không
        thuộc dạng tàn ác mà họ vẫn sẽ lo lắng cho người khác nhưng họ không
        kiểu mềm lòng theo dạng rơi nước mắt. Người Kỷ Thổ có tình yêu dạng cứng
        rắn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mão hấp dẫn Tuất tạo ra Lục Hợp mang cả Mậu Kiếp Tài, Đinh Thiên Ấn và
        Tân Thực Thần. Thiếu vắng sao bạn trai và bạn gái là Chính Quan, Chính
        Tài đối với cả nam giới và phụ nữ. Trụ này sẽ không cần phụ thuộc bởi vì
        Kỷ thường biết cách tự chăm sóc cho chính họ. Với sự xuất hiện của Kiếp
        Tài, họ sẽ tiêu xài khá hào phóng. Đối với nam giới thì mối quan hệ của
        họ do đó sẽ bị tổn hại.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mão làm mất đi sự hiện diện của Dậu ẩn tàng Tân là Thực Thần. Cũng không
        có dính đến Chính Quan Chính Tài. Họ không thích suy nghĩ quá nhiều hay
        phải nghĩ ra ý tưởng. Họ muốn thực hiện tất cả mọi việc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhìn chung, đây là người rất giàu năng lượng và họ luôn muốn mọi thứ
        phải thực hiện ngay. Họ không phải là tuýp người yêu tốt nhất nhưng họ
        cũng không quan tâm nhiều đến yếu tố tình cảm nhiều trong đời. Điều này
        bởi vì Kỷ Thổ vì mọi người chứ không vì một ai. Họ có thể trở thành một
        giám đốc Nhân Sự giỏi quan tâm nhưng lại lạnh lùng.
      </p>
    </div>
  );
};

export const KySuu = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Kỷ Sửu: người này thẳng thắn, hơi thô, nói chuyện không
        khéo léo, dễ làm phật lòng người khác mà không bận tâm, tốt bụng nhưng
        thích chỉ trích, chê bai, mắng người khác, nhưng không chấp nhận được
        lời chỉ trích từ người khác, thường dẫn đến không hạnh phúc trong các
        mối quan hệ giao tiếp. Dễ bị cô đơn, đối với giới nữ, họ thường giỏi
        thao túng người kia. Đôi khi im lặng, đôi khi nói rất nhiều. Hôn nhân
        bất ổn, vợ/chồng dễ ngoại tình.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Có nhiều người trong đời này sinh ra để giúp đỡ nuôi dưỡng người khác
        đạt hết tiềm năng, Kỷ Sửu là một trong số đó. Kỷ Sửu cũng giống như một
        mảnh đất vừa mới được cày xới để trồng, họ sẵn lòng để nhận mọi hạt
        giống và có thể biến chúng thành mùa vụ tươi tốt. Họ không lựa chọn và
        cũng không phàn nàn, điều mà họ thấy là tiềm năng và tương lai của người
        khác.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Kỷ là đất mềm mang theo đặc tính nuôi dưỡng và phát triển. Họ có khả
        năng độc nhất để hấp thu những gì cuộc đời mang đến. Họ có khả năng chấp
        nhận cao. Đây là hình tượng người mẹ và họ sẵn lòng là một người mẹ
        tuyệt vời. Cũng như đất, họ trở nên sẵn sàng khi được khuấy động. Rối
        loạn cảm xúc hay bi kịch thường làm cho họ động lòng và kích hoạt bản
        năng người mẹ của họ. Họ yêu việc chấp nhận và nuôi dưỡng đối phương.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Kỷ Sửu được xem như là đất tốt giàu khoáng chất. Vì tất
        cả các khoáng chất ẩn tàng, người Kỷ Sửu rất sáng tạo và giỏi trong thể
        hiện bản thân. Đất giàu khoáng chất cũng biểu thị cho họ khá tự cao vì
        sở hữu được nhiều tài năng và hấp dẫn những người xung quanh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Sửu có xu hướng thông minh, có động lực và tự tin trong cuộc
        sống. Với bản tính thoải mái, họ luôn cố gắng đạt được mọi điều trong
        cuộc sống, ngay cả những điều nhỏ nhặt nhất. Họ không thuộc tuýp người
        ngồi chờ đợi cơ hội tự đến. Họ đặt mục tiêu và hướng đến những gì mình
        muốn hơn là ngồi chờ sung rụng, tất nhiên là họ thuộc tuýp người thích
        hành động. Họ có tinh thần lạc quan và muốn tận hưởng từng khoảng khắc
        trong cuộc sống.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ sáng tao và có nhiều cách để thể hiện bản thân khác nhau. Du lịch sẽ
        giúp họ trong việc sáng tạo và họ thích nhìn ngắm thế giới cũng như sẽ
        muốn chọn nghề nghiệp nào giúp họ có thể đi lại dễ dàng. Điều này phù
        hợp với bản tính thích phiêu lưu và cũng là niềm vui của họ. Ngoài ra
        bản tính này cũng phù hợp với tính nghệ sĩ của họ. Thông qua đó, họ sẽ
        khám phá được tài năng bên trong của mình mà chính bản thân họ cũng chưa
        hề hay biết. Có thể là sự nghiệp trong lĩnh vực âm nhạc, phim ảnh hay
        nghệ thuật sẽ giúp khai phá tài năng nghệ sĩ thiên bẩm hoặc sự yêu thích
        thể hiện bản thân của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ nhanh nhạy và quyết đoán, đó đều là những phẩm chất giúp họ thẳng
        tiến trong nhiều tình huống. Cách nói chuyện rất thuyết phục cộng với
        khả năng giao tiếp khéo léo sẽ giúp cuốn hút người khác. Sự hấp dẫn
        người khác sẽ giúp gia tăng sự tự tin bên trong họ, tuy rằng đôi khi
        cũng sẽ khiến họ kiêu căng và ngạo mạn. Do đó người Kỷ Sửu cần kiểm soát
        chính mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người này thường nhạy cảm về cảm xúc và có trực giác cao, cũng như
        họ biết rất rõ mọi thứ xung quanh mình. Họ có thể nhận thức một sự kiện
        bất kỳ một cách nhanh chóng, hiểu được nguyên nhân và kết quả trước cả
        khi sự kiện đó có thể diễn ra. Lý tưởng trong họ cũng khá mạnh mẽ và họ
        khao khát muốn biết sự thật trong bất kỳ vấn đề nào. Bởi vì nguyên nhân
        này và sự hiếu kỳ ham hiểu biết, họ liên tục học hỏi và thường thích học
        lên cao.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sự chân thành là yếu tố rất quan trọng đối với họ và họ mong đợi cũng
        được đối xử chân thật. Vì sự ngay thẳng của họ, họ là những ứng cử viên
        xuất sắc để nắm giữ vị trí quyền lực cao. Mặt khác, khao khát tìm kiếm
        sự thật và công lý có thể thúc đẩy họ đi theo những hoạt động vì chính
        nghĩa hoặc nhân văn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi xét về vấn đề thành công và tài lộc, họ không bao giờ lo lắng về
        việc không có đủ nguồn lực trong cuộc sống để đạt được điều mình muốn.
        Họ sẽ luôn tìm được một cách nào đó. Ngoài ra, thành công cũng sẽ đến từ
        sự chú tâm và nỗ lực của họ. Họ luôn làm việc vất vả để giành được mọi
        thứ trong cuộc sống, thay vì ngồi đó và hi vọng mọi việc sẽ tự nhiên
        suôn sẻ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Sửu không gặp nhiều sự giúp đỡ từ những người xung quanh. Họ
        khá độc lập nếu so với những người ngày sinh Kỷ khác. Họ cần dựa vào nỗ
        lực tự thân hơn là chờ đợi ai đó đến giúp mình. Họ cũng nên cẩn trọng về
        việc chọn lựa gần gũi với người nào, vì nếu không chọn đúng thì những
        mối quan hệ sai lầm có thể gây hại cho tương lai của chính họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngoài ra, người sinh ngày Kỷ Sửu cũng dễ trở nên buồn chán và đánh mất
        sự hứng thú trong nhiều việc. Xu hướng này có thể khiến họ lạc hướng và
        đánh mất động lực cố gắng. Điều này đặc biệt đúng khi kế hoạch của họ
        không hoạt động như kỳ vọng. Họ cần học cách kết nối với nguồn năng
        lượng để giúp vượt qua những sự bất mãn trong đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ cũng gặp khó khăn trong việc tập trung định hướng hoặc theo đuổi chỉ
        1 mục tiêu. Một phần trong họ luôn muốn thử những thứ mới trong khi một
        nửa còn lại luôn muốn sự ổn định. Những cảm xúc này sẽ khiến họ không
        lúc nào yên, cuối cùng là đánh mất sự tập trung vì bị quá nhiều thứ lôi
        cuốn. Nếu họ có thể kết nối cảm xúc của mình đi theo 1 kế hoạch ổn định
        và tìm được sự kỷ luật để đi theo 1 hướng duy nhất thì sẽ hạn chế được
        xu hướng nổi loạn bên trong mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Sửu thường có quan điểm lạc quan và nhìn nhận thế giới không có
        gì quá khó khăn nếu họ cố gắng. Miễn là họ có một mục tiêu để hướng đến,
        sự quyết tâm và bền bỉ của họ sẽ tự giúp họ đạt điều mình muốn. Những
        nét tính cách này của người Ất Sửu khiến họ dễ được người xung quanh
        ngưỡng mộ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Nét Tính Cách Chính:</b> ngẫu hứng, sáng tạo, mất kiên nhẫn,
        năng động, tự cao, thẳng thắn.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Sửu có khả năng học hỏi những quy trình và kiến thức mới nhanh
        hơn những người ngày sinh khác. Điều này phần nhiều là do họ có bản tính
        thích học hỏi điều mới. Nét tính cách này cho phép họ có thể mò mẫm
        trong những lĩnh vực họ thích, nơi họ có thể khai phá tài năng của mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Một khi họ khám phá những chủ đề mà họ quan tâm, họ sẽ theo đuổi cả đời
        và muốn lựa chọn nghề nghiệp phù hợp. Điều này có thể bao gồm cả những
        lựa chọn liên quan đến nghệ thuật hoặc kinh doanh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Kỹ năng sắc bén và làm việc siêng năng sẽ giúp cho người Kỷ Sửu thành
        công trong mọi công việc mà họ lựa chọn. Họ không biết sợ bất cứ gì,
        điều này thúc đẩy họ đạt được mọi mục tiêu đặt ra. Tuy nhiên, họ rất cần
        được thử thách liên tục. Bất cứ điều này quá tầm thường sẽ làm cho họ
        buồn chán. Họ thông thái và thích lựa chọn nghề nghiệp linh hoạt cũng
        như công việc nào có thể giúp họ khai phá sự sáng tạo và tài năng của
        bản thân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Sửu sở hữu nhiều nét tính cách đối lập. Họ vừa siêng năng và
        làm việc có trật tự, lại vừa sáng tạo và đầu óc nghệ sĩ. Nếu người Kỷ
        Sửu biết cách cân bằng cả hai thì họ sẽ thành công trong gần như bất kỳ
        công việc nào.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ xuất sắc trong những lĩnh vực nghề nghiệp đòi hỏi hàm lượng tri thức
        cao như giáo dục, viết lách hay xuất bản. Nói chung, những công việc đòi
        hỏi xử lý thông tin và kiến thức gợi cảm hứng cho đam mê sẽ giúp họ
        thành công hơn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ sẽ cảm thấy thoả mãn trong những công việc đòi hỏi kỹ năng giao tiếp
        tốt ví dụ như ngành ngoại giao, du lịch hay giải trí.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặt khác, họ thích thể hiện bản thân nên sẽ phù hợp với các nghành nghề
        như nghệ thuật, âm nhạc hay thể thao. Họ luôn thích cạnh tranh, ganh đua
        để giành lấy vị trí giỏi nhất.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Những Nét Tính Cách Trong Công Viêc:</b> làm việc theo cảm hứng, sáng
        tạo, yêu thương, mối quan hệ lâu dài, thích làm việc với người khác, có
        kỹ năng cao, hay sợ hãi, hay phụ thuộc, thiếu tầm nhìn, thường thất
        vọng, hay lo lắng, thường chua chát.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Hôn Nhân - Gia Đình - Quan Hệ:
      </h4>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Sửu thường bị cuốn hút bởi người có tiền và thành công. Điều
        này bởi vì họ trân trọng việc chăm chỉ và độc lập. Nhìn thấy người khác
        nỗ lực siêng năng và tạo ra thành công riêng nhắc nhở họ về sức mạnh và
        giá trị của bản thân họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Xét về mối quan hệ tình cảm, người Kỷ Sửu cần phải thận trọng. Họ có đam
        mê muốn được tự do hoàn toàn và chỉ dựa vào bản thân, điều này khiến cho
        người phối ngẫu của họ cảm thấy bị rối trí hoặc cảm thấy bất an.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù họ là người yêu đương tình cảm, người Kỷ Sửu thể hiện là người
        hơi bí ẩn và đa nghi. Tính cách lúc lạnh lúc nóng của họ có thể gây khó
        chịu và khiến người thân yêu cảm thấy khó hiểu và dễ gây rạn nứt mối
        quan hệ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhiều người thích người Kỷ Sửu vì họ rất vui vẻ, thông minh và rất thú
        vị. Họ quan tâm đến nhiều lĩnh vực khác nhau và thường có óc hiếu kỳ,
        nên họ không bao giờ thiếu đề tài để nói chuyện cũng như dễ trở thành
        trung tâm của mọi cuộc nói chuyện sinh động.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ thường có đời sống xã hội năng động và là những người chủ tiệc hào
        phóng. Bởi vì họ thích hoà đồng với tập thể và với những người dễ dàng
        tán gẫu về những chủ đề hợp gu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Xét về hôn nhân, người Kỷ Sửu cần phải thận trọng khi lựa chọn bạn đời.
        Lúc đầu thì họ luôn gặp may và mối quan hệ tình cảm luôn tốt đẹp. Nhưng
        sau 1 thời gian, bất đồng bắt đầu xuất hiện khá thường xuyên. Điều này
        đặc biệt đúng sau khi họ đạt được một số thành công trong sự nghiệp hay
        kinh doanh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Con của họ thường khá thông minh vì người Kỷ Sửu thường chú trọng hàng
        đầu đến học vấn và muốn trực tiếp giảng dạy cho con của mình. Người Kỷ
        Sửu thường tỏ ra quá bảo bọc con cái mình và có thể khiến cho con của họ
        nổi loạn khi lớn hơn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Tính Cách Trong Tình Cảm và Mối Quan Hệ:</b> hướng ngoại, nhanh
        nhạy, xã giao, thông minh, vui vẻ, thích tiệc tùng, thích bảo bọc, yêu
        thương, bí ẩn, lạnh nhạt trong những mối quan hệ về lâu dài.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:
      </h4>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trong hầu hết mọi trường hợp thì người Kỷ Sửu đa phần có sức mạnh từ
        thông căn và luôn an toàn chắc chắn. Người Kỷ Sửu sinh vào mùa Xuân hay
        Hè thì lại càng dễ dàng vượng. Bởi vì ngũ hành Hoả mạnh trong 2 mùa này,
        củng cổ sức mạnh của Kỷ Thổ. Tuy nhiên, thường thì Tài Tinh sẽ hơi yếu
        trong lá số này và là dấu hiệu của khó khăn về tài chính về lâu dài. Nếu
        người Kỷ Sửu sinh vào tháng mùa Thu hay Đông thì sẽ hưởng lợi từ bạn bè
        hay anh em tốt giúp đỡ. Người Kỷ Sửu có xu hướng đạt được tài lộc khá dễ
        dàng mà không phải đối mặt với nhiều trở ngại. Nhưng mặc cho những lợi
        thế hiển nhiên thì họ sẽ thích đi theo con đường tâm linh hơn là lựa
        chọn trở nên cực kỳ giàu có. Điều này bởi vì cách cục của người Kỷ Sửu
        biểu thị rằng trụ ngày này sẽ khó hấp thu hay nắm giữ thật nhiều Thuỷ,
        ngũ hành đại diện cho khả năng gia tăng tài lộc. Người Kỷ Sửu về căn bản
        là đất ướt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Sửu rất sáng tạo và suy nghĩ thấu đáo. Điều này sẽ giúp họ đạt
        được nhiều thành tích lớn. Đây là hiệu ứng của Thực Thần Tinh ẩn mà trụ
        ngày này sở hữu. Họ học hỏi nhanh và dễ dàng thích ứng với các tình
        huống mới xuất hiện và dễ dàng tận dụng mọi cơ hội. Họ thường có nhiều
        đam mê và thích có nhiều bạn bè. Điều này bởi vì Thực Thần ẩn tàng giúp
        sinh ra Thuỷ, cũng chính là Tài Tinh trong địa chi. Điều này có thể biểu
        thị họ liên tục suy nghĩ, họ cũng có khả năng ý chí mạnh mẽ một khi được
        gợi cảm hứng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù họ có bản tính độc lập, người Kỷ Sửu cũng thích hợp tác với người
        khác. Đó là do Tỷ Kiên bên dưới họ. Họ có thể trở nên khá thành công khi
        làm việc nhóm. Tuy nhiên, họ không thuộc tuýp người ỷ lại và chỉ muốn
        tài năng và sự đóng góp của mình được công nhận.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Điều duy nhất có thể ngăn cản Kỷ Sửu thành công đó là đôi khi họ thiếu
        thực tế. Điều này đặc biệt đúng nếu có nhiều Âm Thổ trong lá số. Mặc dù
        Kỷ Sửu có trực giác mạnh, họ có thể dành năng lượng và nỗ lực của mình
        vào sai hướng. Khi kế hoạch của họ không thành công như mong đợi, họ có
        thể trở nên cứng nhắc và duy ý chí.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Sửu có tính cách khá thu hút. Họ hướng ngoại, thích vui vẻ và
        thường cuốn hút người khác cũng như kết bạn dễ dàng. Mặc dù họ có thể tỏ
        ra bất an và đôi khi lo lắng. Họ cũng dễ dàng cảm thấy mắc cỡ và bảo thủ
        nếu sự tự tin của họ bị đánh gục. Họ có thể cần tình cảm và cần sự chú
        trọng xây dựng mối quan hệ tình cảm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sửu là một phần của Thổ mùa đông do đó chứa nhiều nước. Hình ảnh của Kỷ
        Sửu là một dạng thổ ướt cũng như cánh đồng mùa đông lạnh lẽo đóng băng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Kỷ Sửu cũng được xem là Kim Thần, biểu thị tài năng trong việc thu hút
        quý nhân và người đỡ đầu trong đời. Điều này cũng biểu thị người này sẽ
        trở thành người cố vấn có ích cho những người khác. Nếu người này gặp
        Bính Hoả trong lá số thì có lợi. Vì Bính Hoả biểu thị cho mặt trời, nó
        biểu thị khí hậu lạnh được điều hậu và mặt đất ấm áp sẽ giúp cho thực
        vật phát triển và sự sống hồi sinh. Điều này cũng đem đến hạt giống hi
        vọng và sẽ giúp cho mọi người đều thành công.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Điều này cũng biểu thị khi trụ Kỷ Sửu gặp Chính Ấn Tinh (Bính Hoả) thì
        có khả năng là người này sẽ rất thành công trong đời. Tuy nhiên, nếu có
        Xung cùng với Ấn Tinh thì điều này biểu thị cũng sẽ thành công nhưng
        người này cũng sẽ chịu các vấn đề sức khoẻ suốt cả đời. Điều này cũng
        bởi vì Ấn Tinh đại diện cho sức khoẻ. Nếu có sự hiện diện của Thất Sát
        Tinh trong lá số, người này sẽ đạt được thành công vượt bậc trong đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu xét kỹ hơn thì người này có thể khá phụ thuộc vào mẹ (Ấn). Điều này
        bởi vì Ấn Tinh là Bính Hoả chính là mặt trời. Nếu không có mặt trời thì
        Thổ đóng băng sẽ không thể có sự sống.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trụ này cũng biểu thị người cha thụ động. Sao chỉ người cha chính là Quý
        Thuỷ và ẩn bên trong địa chi. Nếu như có Xung hoặc Hợp với ngũ hành Hoả
        trong lá số thì có thể mẹ của người Kỷ Sửu sẽ thao túng cuộc đời của
        người này. Nếu Hoả vắng mặt trong lá số thì người này có khả năng có một
        mối quan hệ hôn nhân khá kỳ lạ trong đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Vì trụ này cũng chứa ngũ hành Thuỷ, sẽ không có lợi nếu gặp thêm ngũ
        hành Thuỷ trong lá số hay trong đại vận. Rất nhiều Thuỷ có thể khiến cho
        lá số người này lạnh cóng và có thể biến ngũ hành Thuỷ, tức Tài tinh,
        trở thành ngũ hành bất lợi, biểu thị người này sẽ gặp nhiều rắc rối
        trong kinh doanh hoặc tài chính. Quá nhiều Kim và Thuỷ nói chung sẽ biểu
        thị vận xấu. Giáp và Bính sẽ được xem là đại vận có lợi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đàn ông Kỷ Sửu sẽ dễ có hôn nhân tốt hơn phụ nữ Kỷ Sửu. Thuỷ, là ngũ
        hành Tài trong lá số, biểu thị người vợ đối với lá số nam giới. Vì nam
        giới Kỷ Sửu không thể quản được nhiều hành Thuỷ, có khả năng người này
        sẽ chung thuỷ với người họ đang sống cùng. Tuy nhiên, nếu một người đàn
        ông Kỷ Sửu bị bắt quả tang tán tỉnh hay quan tâm nhiều đến những người
        phụ nữ khác (nghĩa là nhiều ngũ hành Thuỷ), biểu thị vợ người này sẽ nổi
        máu Hoạn Thư dữ dội có khả năng rời bỏ chồng và đem theo con.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Phụ nữ Kỷ Sửu có thể dễ dàng thao túng hoặc chi phối người chồng. Điều
        này bất kể là chồng cô ấy có giàu hay quyền lực không. Ngũ hành Mộc đối
        với Kỷ Sửu là đại diện cho người chồng. Không chỉ hành Mộc có lợi đối
        với Kỷ Sửu, mà Mộc cũng cần Kỷ Thổ để phát triển. Đó là lý do mà nữ giới
        Kỷ Sửu, họ có thể giúp đỡ ủng hộ người chồng và trở thành người phụ nữ
        đứng sau người đàn ông thành công.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Sửu sinh vào mùa Xuân và Hạ sẽ có cuộc đời sống thọ và thành
        công. Điều này một phần là vì họ sẽ gặp được nhiều quý nhân giúp đỡ
        trong cuộc đời. Nếu sinh trong mùa Thu hay Đông thì họ có thể phải đối
        phó với các vấn đề tài chính cản bước thành công. Nếu sinh vào ban ngày
        thì người Kỷ Sửu thông minh nhanh nhạy. Nếu sinh vào ban đêm thì dễ có
        sự nghiệp thành công. Bất lợi cho người Kỷ Sửu nếu sinh vào tháng Tuất,
        bởi vì gặp vận xui, và họ phải làm việc vất vả hơn thì mới có thể thành
        công.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Kỷ toạ trên Sửu mang theo năng lượng của Kỷ Thổ Tỷ Kiên, phụ khí của Tân
        Kim Thực Thần và Quý Thuỷ Thiên Tài. Là đất ướt, họ cũng không có vấn đề
        với việc cho đi và khá rộng rãi với nguồn năng lượng của mình. Sao Tỷ
        Kiên đánh thức cảm xúc tự tin và sự hợp tác. Họ có khả năng tự nhiên
        khiến cho mọi người đến gần họ và không đe doạ bất kỳ ai. Đây là sự cuốn
        hút khiến cho họ có khả ănng tìm kiếm sự thấu hiểu người khác và biết rõ
        cần phải làm gì để vượt qua khó khăn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thực Thần cho phép họ chi tiết hơn và do đó giỏi xử lý các vấn đề nhạy
        cảm về cảm xúc. Trí tưởng tượng và sáng tạo của họ cũng giúp xử lý các
        vấn đề mới và đang phát triển mà không căng thẳng nhiều. Thiên Tài là
        sao giá trị của tương lai liên quan đến việc tiến lên phía trước. Chính
        từ sao này mà có thể thấy được giá trị của bạn mà bạn phải nhận thức
        cũng như trân trọng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sửu hấp dẫn Tý trong Lục Hợp xuất hiện thêm Thiên Tài Quý Thuỷ vào trong
        lá số. Điều này cũng tương tự với giới thiệu thêm bạn gái cho người nam
        Kỷ Sửu. Bản tính quan tâm và thân thiện khá hấp dẫn đối với hầu hết mọi
        nữ giới và không ngạc nhiên nếu họ hay được săn đón. Nhìn chung là không
        tốt cho quan hệ tình cảm lâu dài vì hầu hết mọi phụ nữ không muốn chia
        sẻ đàn ông của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sửu sẽ xua đuổi Mùi trong Lục Xung bao gồm Tỷ Kiên Kỷ Thổ, Thiên Ấn Đinh
        Hoả và Thất Sát Ất Mộc. Người phụ nữ rời bỏ sao chỉ bạn trai là Thất
        Sát, biểu thị bất lợi cho mối quan hệ rủi ro. Điều này thật sự tốt vì họ
        ít có khả năng dính vào những mối quan hệ không hoàn toàn nghiêm túc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhìn chung, đây là người rất quan tâm đến mọi người. Họ cố gắng để giữ
        cái nhìn ngoại giao trong mọi tình huống và tạo cơ hội cho mọi người
        khác. Mỗi người xuất hiện trong cuộc đời họ đều được xem là một hạt
        giống có thể biến thành một điều gì đó to lớn và họ muốn là một phần của
        những gì giúp người khác phát triển. Điểm cần lưu ý là Kỷ Sửu sẽ có nguy
        cơ bệnh tật cao và rắc rối tiềm ẩn. Sức khoẻ là yếu tốt quan trọng nhất
        mà họ nên ưu tiên hơn những thứ khác.
      </p>
    </div>
  );
};

export const KyHoi = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Kỷ Hợi: có tính cách cứng rắn, hài hước, có động lực cố
        gắng, đánh giá cao sự kính trọng từ người khác, hoạt ngôn, có lối suy
        nghĩ độc đáo, dâm loạn, thường vướng vào rắc rối tình cảm yêu đương, có
        nhu cầu tình dục cao. Người này thường hợp với đối tác giàu năng lượng
        và thích chủ động trong tình dục. Người này không bao giờ thật sự giàu
        có, mặc dù đôi khi có tiền dư. Nếu là nữ giới thì thì không hợp, hay
        tranh cãi với mẹ chồng và không giúp ích được cho chồng.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Kỷ Hợi là đất ướt cũng như khi người ta làm công tác khai thác quặng mỏ
        và cần phải dùng nước để rửa sạch đất để lại một ít quặng. Trụ ngày Kỷ
        Hợi cũng có một quy trình tương tự khi cần nhiều thời gian đê rửa sạch
        trước khi có thể đem đến sự sáng bóng.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Kỷ Thổ là hành của sự nuôi dưỡng. Họ có khả năng độc nhất để chấp nhận
        mọi thứ. Khi bạn đổ nước vào đất thì nó hấp thu. Khi sóng biển vỗ vào bờ
        cát thì cát chấp nhận. Ngay cả khi bạn bị nhấn chìm gì xuống nước thì nó
        cũng chìm xuống dưới đáy biển và chờ đợi để nổi lên. Bất cứ gì cũng có
        thể sống trong đất và có thể lựa chọn ai hoặc gì để nó nuôi dưỡng. Họ là
        những người tốt bụng muốn giúp đỡ người khác phát triển, đôi khi bằng
        cách hi sinh bản thân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Cũng như khung cảnh hoàng hôn tuyệt đẹp ở bờ biển, người Kỷ Hợi có vẻ
        đẹp được những người xung quanh trân trọng. Họ toả sáng và ấm áp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Hợi thông minh, tử tế và gìau năng lượng. Họ khôn ngoan và luôn
        cố gắng đạt đến sự cân bằng. Khi nói đến tiền bạc, họ mơ ước lớn và muốn
        thiết lập việc kinh doanh của riêng mình. Hầu hết người Kỷ Hợi thích
        thiết lập một hệ thống ổn định, được thử thách qua thời gian để liên tục
        kiếm tiền.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tuy nhiên, chỉ một ít người Kỷ Hợi, sẽ lựa chọn lao vào những công việc
        tự do hay tự tạo lập con đường của chính mình và cuối cùng sẽ dẫn họ đến
        việc kinh doanh riêng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Hợi có thể dựa vào sự tự tin của chính mình để nhận lãnh vai
        trò một lãnh đạo doanh nghiệp có sức ảnh hưởng cao., bởi vì họ có bản
        năng tự nhiên để gây ảnh hưởng đến người khác. Vì điều này, người Kỷ Hợi
        là những lãnh đạo dự án xuất sắc hay những nhà quản lý có xu hướng lãnh
        đạo những nhóm hoạt động thiện nguyện. Xét về mặt chuyên môn, họ là
        người thấu hiểu gía trị của kiến thức. Tuy nhiên, thường do cách giao
        tiếp thẳng thắn và trực tiếp mà họ dễ làm mất lòng người khác, khiến
        người khác có ấn tượng xấu về họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Hợi bị thúc đẩy về mặt học thuật và họ có xu hướng dành nhiều
        thời gian để để nghiên cứu nhiều môn học. Tuy nhiên, sự quan tâm của họ
        đối với quá nhiều môn học có thể dẫn dắt họ dàn trải quá nhiều năng
        lượng ra quá mỏng. Nếu họ không cẩn trọng, điều này biến thành trở ngại
        trong đời và khiến cho họ phân tán sức lực.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có tài năng trực giác mạnh mẽ hay thậm chí cả năng lực ngoại cảm, họ
        có khả năng gíac ngộ và nhận ra được trí tuệ cao khi lớn tuổi hơn. Đây
        là một ví dụ điển hình khi nói họ có nhiều tài năng. Người Kỷ Hợi thường
        được trời phú cho khả năng ca hát và chất giọng tốt. Người Kỷ Hợi được
        hình dung như bờ biển, nơi những con sóng nhẹ nhàng vỗ bờ, tạo ra khung
        cảnh, giai điệu đại dương êm ái và thư giãn. Do đó, khi họ nói chuyện,
        mọi người xung quanh sẽ ngay lập tức chú ý.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu họ sử dụng chất giọng ca hát quyến rũ để truyền tải thông điệp cảm
        xúc qua lời hát, mọi người sẽ thường kết nối cảm xúc với họ hay thậm chí
        yêu họ vì khả năng biểu diễn sân khấu và ca hát.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Hợi có năng lực quyến rũ ma mị người khác. Chất giọng của họ
        làm tan chảy các tâm hồn và xuyên thấu sâu vào trái tim người nghe.
        Thông qua việc rèn luyện giọng nói, chất giọng của họ có thể được rèn
        luyện để mang đến sức mạnh cao nhất, biến người Kỷ Hợi thành nhân vật
        gây nhiều ảnh hưởng đến người khác thông qua các bài diễn văn hay ca
        hát.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Cũng cần lưu ý rằng người Kỷ Hợi có quan điểm thực tế và cần an toàn,
        biểu thị quan tâm nhiều đến vật chất. Điều này cũng biểu thị họ tuy có
        thể bay bổng nhưng nỗi lo về cơm áo luôn khiến họ rất thực dụng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trong cuộc sống, người Kỷ Hợi thường rộng lượng, đáng tin cậy. Tuy những
        người xung quanh sẽ mô tả họ là hay yêu cầu và căng thẳng. Điều này bởi
        vì sự bất an về cảm xúc khiến cho người Kỷ Hợi che giấu cảm xúc thật.
        Người Kỷ Hợi luôn tỏ ra tự tin bên ngoài, nhưng bên trong họ cần có sự
        ủng hộ từ người khác. Thường dành thời gian một mình để suy ngẫm hay
        thiền định là cách tốt để họ tìm được sự bình an và tình yêu bên trong.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thiền định cũng sẽ giúp ở một mặt khác, vì họ sở hữu cảm gíac trách
        nhiệm nặng nề và họ cần phải duy trì sự cân bằng với những cam kết khác
        trong đời. Điều có thể khiến mọi việc tệ hơn là xu hướng cầu toàn của
        họ, có thể dẫn họ đến việc thích phê bình hoặc đòi hỏi quá đáng. Họ dễ
        lo lắng, nhưng sự kiêu hãnh của họ không cho phép họ thể hiện cảm xúc
        này ra bên ngoài.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Hôn nhân đối với người Kỷ Hợi là trách nhiệm cao với con. Người Kỷ Hợi
        nữ giới sẽ tốt hơn nam vì họ sẽ hưởng đời sống thảnh thơi khi về già với
        nhiều may mắn.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Một khi người Kỷ hợi đã quyết định về việc gì, họ muốn có được sự đồng
        thuận từ người xung quanh. Điều này biểu thị rằng khi họ cần biết kế
        hoạch và mục tiêu hướng đến ở đâu, điều này sẽ giúp họ thành công. Cân
        bằng là từ khoá mấu chốt trong mọi mặt của người Kỷ hợi khi tìm kiếm
        hạnh phúc và thành công.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Của Người Kỷ Hợi:</b> có vẻ ngoài cuốn hút, nhiều ý
        tưởng tuyệt vời, giọng nói hay, có sức ảnh hưởng, cuốn hút, lo lắng,
        khao khát tình yêu, hay lo lắng, không yên, nổi loạn, sáng tạo.{" "}
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Quyết tâm và có ý chí mạnh mẽ, người Kỷ Hợi sẽ có cách tiếp cận nền tảng
        đối với bất kỳ sự nghiệp nào họ nhận lãnh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Điều quan trọng là công việc đó phải mang đến cho họ một mức độ độc lập.
        Đó là lý do vì sao công việc tự do hoặc tự làm chủ là sự lựa chọn tốt
        cho họ. Họ cũng cần sự đa dạng trong môi trường làm việc, đó là lý do họ
        thích thay đổi công việc ít nhất một lần.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Hợi giỏi thuyết phục và duyên dáng cũng như có kỹ năng quản lý
        tốt. Họ có thành công trong sự nghiệp, có thể từ khoa học đến nghệ thuật
        vì họ khôn ngoan. Những người này khao khát tri thức và họ sẽ vươn cao
        trong môi trường làm việc nào cho phép họ làm điều đó.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ nên cẩn trọng không nên chấp nhận những gì dưới tầm của mình chỉ vì
        nó an toàn. Ngoài ra, họ cần cẩn trọng không nên nhận lãnh quá nhiều
        công việc và trở nên quá tải.{" "}
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Công Việc:</h4>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tìm được công việc thành công không khó cho người Kỷ Hợi, vì họ có tài
        năng. Họ có thể có một sự nghiệp thành công và kéo dài trong lĩnh vực họ
        chọn theo đuổi.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bởi vì nhu cầu muốn thể hiện, họ sẽ tìm được hạnh phúc trong các công
        việc có liên kết với sự sáng tạo. Họ sẽ xuất sắc trong nghệ thuật, âm
        nhạc, xuất bản, viết lách, ca hát nhảy múa hay truyền thông, đặc biệt
        nếu họ chọn phát triển tài năng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Một số người Kỷ Hợi bị thu hút bởi công việc liên quan đến nhận thức xã
        hội. Lĩnh vực sự nghiệp tốt nhất cho những người này bao gồm cả chính
        trị, giáo dục, từ thiện, hành chính công, công việc xã hội hay tâm lý
        học.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Giọng nói của họ có sức thu hút khiến cho họ dễ trở thành ứng viên tốt
        trong việc tiếp thị, quan hệ công chúng và trong những công việc đòi hỏi
        họ ứng xử và phân tích ý tưởng hay khái niệm bằng lời nói.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bởi vì khiếu kinh doanh của họ và sự quyết tâm, họ s4 đạt những thành
        tựu to lớn trong kinh doanh và thương mại. Khả năng quan sát sắc bén,
        nhìn thấy những cơ hội và quyết đoán trong hành động giúp họ dễ đạt
        thành công to lớn trong lĩnh vực doanh nghiệp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bất kể nghề nghiệp nào họ chọn, họ cần phải thể hiện tầm nhìn và ý tưởng
        để có thể đạt được hạnh phúc trong sự nghiệp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Của Người Kỷ Hợi:</b> từ bi, cầu toàn, độc lập, chính
        xác, siêng năng, nghệ sĩ, khéo léo, ghen tuông, hống hách, đè nén, cứng
        nhắc, quá nhạy cảm, kẻ cả, tự cao.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Hôn Nhân - Quan Hệ - Gia Đình:{" "}
      </h4>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Hợi rất chung thuỷ và ủng hộ bạn đời. Họ thường bị lôi cuốn bởi
        những con người sáng tạo và thông minh có thể chia sẻ ý tưởng và hỗ trợ
        về tinh thần.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bởi vì họ làm việc khá vất vả, họ thường có xu hướng tìm bạn đời tại nơi
        công sở. Họ cũng thường thích bạn đời thích làm việc để nâng cấp bản
        tâhn, vì điều này sẽ giúp quan hệ được cân bằng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Hầu hết người Kỷ Hợi sẽ có xu hướng trải nghiệm tình yêu khi còn trẻ, và
        sẽ kết hôn với bạn trung học hay đại học. Người Kỷ Hợi cũng thường sống
        tình cảm và trân trọng tình yêu như là ưu tiên số 1 trên mọi vấn đề vật
        chất hay sự nghiệp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Hợi thường quan tâm, thân thiện, đam mê và gìau tình cảm với
        bạn bè. Họ thích gặp gỡ người từ mọi giai cấp trong xã hội và cá tính
        thân thiện khiến họ dễ kết bạn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ thích dành thời gian với những người thích tri thức và sáng tạo. Cũng
        vì lý tưởng và cảm xúc, họ thích tham gia vào những cuộc tranh luận sáng
        tạo giúp thắp lửa trí tưởng tượng của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ cần cẩn trọng nếu không muốn trở nên quá bất an. Điều đó có thể khiến
        họ thích phê bình hoặc có thái độ kẻ cả.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đời sống hôn nhân đối với người Kỷ Hợi sẽ thuận lợi, đặc biệt là đối với
        giới nữ. Họ sẽ là những người vợ/chồng lý tưởng vì nhu cầu cần tình yêu
        và cảm xúc. Họ cố gắng chiều lòng bạn đời và sẽ hi sinh vì con cái.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ cần tránh bản thân quá phụ thuộc vào vợ/chồng, vì điều này lâu dài sẽ
        gây ra rạn nứt trong mối quan hệ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Trong Tình Yêu - Hôn Nhân:</b> tôn thờ hôn nhân, cần
        tình yêu và cảm xúc, thích những người bạn có thể gợi cảm hứng tri thức,
        tránh việc quá bất an, có thể hoà hợp với nhiều dạng người, thiếu quyết
        đoán có thể gây khó chịu cho bạn đời.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:
      </h4>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Kỷ toạ trên Chính Tài và Chính Quan. Cục này sẽ biểu thị đây là người
        nhiều may mắn, bản tính tốt và quý khí. Đối với phụ nữ sinh ngày này thì
        có khả năng cao họ sẽ kết hôn với đàn ông giàu có.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Hợi sáng tạo và can đảm. Họ thích ở tiền tuyến và thích cảm
        giác là người đầu tiên bước đi tiên phong. Họ khá độc lập với ham muốn
        được xây dựng bản tính độc đáo.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tham vọng và quyêt tâm, người Kỷ Hợi nhắm đến vị trí quyền lực và họ có
        động lực nhắm đén thành công về vật chất. Làm việc siêng năng và thực
        dụng cũng như sáng tạo và giàu năng lượng, họ có khả năng sử dụng trí
        tưởng tượng mạnh mẽ để làm công cụ tiến về phía trước và dọn đường cho
        mình chinh phục thế giới. Đó là những tác dụng của Thuỷ sinh Mộc, Chính
        Tài sinh Chính Quan.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có cảm giác độc lập vì người Kỷ Hợi bị tác động trực tiếp của Nhâm
        Thuỷ Chính Tài Tinh. Mặc cho điều này, người Kỷ Hợi được xem là thành
        viên đội nhóm tốt do bản tính của Kỷ Hợi. Họ cân bằng, làm việc không
        ngừng nghỉ từ làm việc độc lập sang đến đội nhóm. Người Kỷ Hợi có thể
        cần phải phát triển kỹ năng giao tiếp và trở nên khéo léo hơn. Họ muốn
        là người tạo ra luật chơi, và mặc dù họ có thể rất cân bằng, họ thường
        dễ nóng giận vô cớ khi mọi việc không theo đúng kế hoạch, cũng do tính
        chất của Nhâm Thuỷ Chính Tài. Họ có thể có xu hướng mạnh mẽ ép buộc thay
        vì thuyết phục, và mặc dù hầu hết họ đều thẳng thắn nhưng họ thường trải
        qua những thời điểm mất kiên nhẫn, thích phê bình và phán xét.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Phía sau vẻ ngoài quyền lực thì người Kỷ Hợi nhạy cảm và sống tình cảm.
        Đúng với bản chất Âm Thổ, họ dễ bị tổn thương bởi lời chỉ trích hay
        những hành động thiếu suy nghĩ của người khác. Họ cảm tính và trực giác,
        và mặc dù họ có vẻ thu mình, họ thường cố gắng hết sức để làm hài lòng
        mọi người và dễ quên đi nhu cầu bản thân. Điều này đặc biệt đúng nếu và
        khi Nhâm Thuỷ trở nên quá nhiều, vì nó sẽ làm suy yếu Thô yếu trong lá
        số. Họ có tính chính trực cao và là những người bạn đáng tin cậy, họ sẵn
        lòng dùng suy nghĩ cua mình để giúp đỡ người khác vượt qua khó khăn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Hợi cảm thấy nhu cầu cần khám phá thế giới từ nền tảng vững
        chắc, họ khao khát sự ổn định và không thật sự muốn thấy nhiều nhiều
        thay đổi độ ngột trong đời. Họ sẵn lòng làm việc vất vả để xây dựng nên
        cuộc sống gia đình an toàn và hoà hợp. Điều này có thể vì người Kỷ Thổ
        vốn dĩ đã ở trạng thái yếu trong trụ ngày, và nước quá nhiều, mạnh mẽ có
        thể huỷ hoại tính chất của Kỷ Thổ. Do đó, người Kỷ hợi cần lên kế hoạch
        để bảo vệ cuộc sống chống lại những bất an trong cuộc sống. Họ nên đầu
        tư cho giáo dục và kiến thức, cũng như các hình thức đầu tư vào bản thân
        vì sẽ đem lại lợi nhuận tốt. Điều này là cách để người Kỷ Hợi sử dụng
        Bính Hoả mặt trời để củng cố sức mạnh của Kỷ Thổ. Về mặt hình ảnh, bạn
        có thể hình dung mặt trời đẹp lặn xuống chân trời, soi sáng bãi cát vàng
        trên biển. Không thể nói là người Kỷ Hợi quá hoang phí, nhưng họ thích
        đời sống hưởng thụ đôi khi. Người Kỷ Hợi có thể có xu hướng biểu hiện
        ghen tuông vì họ muốn bám chắc lấy mọi thứ mình có, cũng như những con
        sóng vỗ bờ dễ phá huỷ các đụn cát.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Vận may có lợi cho người Kỷ Hợi khi họ gặp Bính trong lá số. Có Bính mặt
        trời sẽ giúp tăng cường Kỷ Thổ và sưởi ấm lá số, tưởng tượng một bãi
        biễn đẹp với những tia nắng vàng rực rỡ. Tuy nhiên, sẽ bất lợi cho họ
        nếu họ gặp quá nhiều Ất Mộc hay Quý Thuỷ ở Thiên Can. Biểu hiện tiêu cực
        của Mưa Quý Thuỷ và Mộc trong lá số có khả năng gây ảnh hưởng xấu đến cơ
        hội thành công và tình trạng sức khoẻ nói chung.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Điều này bởi vì Nước Mưa sẽ làm tan chảy hình ảnh mong manh của Kỷ Thổ
        và quan trọng hơn là che mời ánh nắng mặt trời Bính Hoả. Âm Mộc, lại còn
        không giúp ích trong việc hấp thu nước biển mà còn bám vào Âm Thổ vốn
        mong manh. Do đó, gây bất lợi. Nó biểu thị một người có nhiều rác rưởi
        trong cuộc đời. Gặp nhiều hành Kim cũng sẽ làm cho Âm Thổ yếu đuối này
        yếu hơn và nó sẽ làm gia tăng hành Thuỷ. Sẽ có lợi nếu trụ Kỷ Hợi gặp
        nhiều Giáp Mộc, Hoả và Thổ trong đại vận.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bát Tự luôn chú trọng sự cân bằng. Quá nhiều Thuỷ có thể dẫn đến lũ lụt
        và phá hoại như sóng thần đánh vào bờ biển. Kỷ Thổ duy nhất đúng nghĩa
        có thể hấp thu Thuỷ là Kỷ Mùi, ngoài ra không có Kỷ nào khác có thể hấp
        thu nhiều Thuỷ. Điều quan trọng là người Kỷ Hợi phải tập trung vào việc
        nuôi dưỡng Gíap Mộc vì Hợi là Trường Sinh của Giáp Mộc. Trong trường hợp
        này, Giáp Mộc có thể được xem như là Chính Quan. Nó biểu thị quyền lực,
        địa vị, sự công nhận và những vị trí quan trọng trong đời. Đối với phụ
        nữ Kỷ Hợi, Gíap còn đại diện cho người chồng. Vì Giáp và Kỷ hợp, điều
        này biểu thị nước biển sẽ không thể phá tan Kỷ Thổ. Do đó với phụ nữ Kỷ
        Hợi, người chồng là quý nhân. Có vẻ như người nữ này sẽ hưởng cuộc hôn
        nhân tốt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặt khác, đối với nam giới Kỷ Hợi thì mọi chuyện ngược lại. Người vợ là
        Dương Thuỷ cũng như đại dương mang hàm nghĩa khó đoán. Hầu hết thời gian
        thì người Vợ thường có tính cách mạnh mẽ trong nhà. Hay có thể nói người
        đàn ông Kỷ Hợi thường phục tùng vợ mình. Vì Hợi tàng Dương Thuỷ cũng là
        sao Dịch Mã nên biểu thị người vợ thường có thể đến từ một quốc gia hay
        đất nước khác, sau khi kết hôn cặp đôi có thể dọn đến nơi khác hay về
        nhà của người vợ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Hợi sinh vào mùa Xuân và Hè sẽ thành công trong việc khởi
        nghiệp. Người Kỷ Hợi sinh vào mùa Thu và Đông sẽ đạt thành công dựa trên
        nỗ lực tự thân mà không cần sự gíup đỡ từ người khác. Người sinh vào ban
        ngày sẽ hơi gặp khó khăn và dễ nóng giận hơn người Kỷ khác, trong khi
        người sinh vào ban đêm sẽ sống thoải mái khi về già. Không tốt cho người
        Kỷ Hợi sinh vào tháng Thìn, vì họ có thể phải làm việc vất vả hơn và họ
        có thể không thể đạt được thành công như những người Kỷ khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Kỷ Thổ toạ Hợi mang đến 2 năng lượng của Nhâm Thuỷ Chính Tài và Giáp Mộc
        Chính Quan. Thuỷ cho họ khả năng để trở nên bao dung và rất nhanh nhạy
        trong việc đáp ứng nhu cầu của mọi người. Cũng như đại dương có thể chạm
        đến mọi sự sống, họ cố gắng tốt nhất để kết nối với mọi người xung quanh
        họ. Mộc sẽ đồng thời giúp sự ổn định và tăng trưởng. Họ không chỉ muốn
        một sự kết nối đơn giản, họ sẽ muốn hướng đến mối quan hệ lâu dài bền
        vững với những người mà họ tiếp xúc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Xét về tình cảm mối quan hệ thì tốt hơn với sự xuất hiện của sao Chính
        Quan và Chính Tài biểu thị người vợ và người chồng. Họ có khả năng mạnh
        trong việc giải quyết khó khăn. Họ sẵn lòng chạm vào cuộc sống và quyết
        định dành phần lớn thời gian để biến thành hiện thực. Họ biết những điều
        thực tế quan trọng để tiến hành và thường thực hiện mọi thứ theo một
        cách bài bản sách vở. Họ không lừa người khác và xét mọi sự cam kết đều
        là nghiêm túc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Hợi sẽ thu hút Dần là mối quan hệ kết hợp kéo theo năng lượng của Giáp
        Mộc Chính Quan, Mậu Thổ Kiếp Tài và Bính Chính Ấn. Đối với lá số nữ
        giới, điều này đem đến may mắn vì người chồng được củng cố. Đối với lá
        số nam giới, sự xuất hiện của Kiếp Tài sẽ làm suy yếu Chính Tài.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Hợi xung với Tỵ sẽ thúc đẩy năng lượng của Bính Hoả Chính Ấn, Canh Kim
        Thương Quan và Mậu Thổ Kiếp Tài. Ở đây chúng ta thấy cả hai loại gây rắc
        rối về tình cảm là Thương Quan và Kiếp Tài bị xua đuổi, biểu thị họ rất
        nghiêm túc trong việc giữ gìn mối quan hệ tình cảm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhìn chung, đây là người khá chăm chỉ và sách vở. Họ thường dành nhiều
        thời gian để chiêm nghiệm cuộc đời. Xét về tương lai lâu dài, họ là
        những người phù hợp để giữ quan hệ tình cảm bền lâu. Trong quá trình
        sàng lọc tìm kiếm quặng mỏ, để đạt được một ít kết quả cuối cùng, cần
        phải kiên nhẫn nếu quá vội vã trong tiến trình thực hiện thì có thể phản
        tác dụng.
      </p>
    </div>
  );
};

export const KyDau = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Kỷ Dậu là người vật chất, toan tính con số, có tài nghệ
        thuật, thích học hỏi, giọng lưỡi sắc bén, thích niềm vui khi người khác
        đau khổ, coi trọng giá trị vật chất hơn là tình cảm, không thích thua
        cuộc hay thất bại, năng động, dâm loạn, hấp dẫn người khác giới, có quan
        hệ tình cảm với 3 hay nhiều người cùng lúc, dễ có nhiều mối tình một
        đêm, thường bị xem là người không đàng hoàng. Dễ béo.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Hạt kim cương thô vừa mới được khai thác ra khỏi lòng đất. Thường trông
        không hấp dẫn với nhiều cạnh gồ ghề, thô ráp. Kỷ Dậu là trụ ngày có
        nhiều giá trị và chất lượng nhưng nằm ở dưới lớp vỏ bọc thô tự nhiên. Họ
        cần có một bàn tay cắt gọt, mài giũa để làm tinh tế những đường nét thô
        sơ và vẻ ngoài đẹp đẽ nhằm chứng tỏ nét đẹp bên trong.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Kỷ Thổ là đất tự nhiên mang đến sự phát triển và ẩn chứa bên trong nó
        những khoáng chất bí mật. Nó thường đòi hỏi vất vả đào xới trước khi làm
        lộ ra kho tàng quý giá. Điểm may mắn là Kỷ Thổ thường thiếu khả năng
        phản kháng và do đó khiến cho quá trình đào xới dễ dàng hơn nhiều. Người
        ta có thể sẽ không biết họ phát hiện gì trong quá trình đào xới thú vị
        này. Có thể là một khúc xương hoá thạch, một cổ vật nền văn minh hay kim
        cương và vàng bạc. Kỷ thường có bản tính thích cho đi và được người khác
        ca ngợ vì lý do này...nhưng mà thật sự thì nếu người ta lấy đi của bạn?
        Bạn vẫn cảm thấy ổn chứ?
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Kỷ Dậu cũng giống như những hạt cát nhỏ mịn trong đồng hồ cát. Hạt cát
        nhỏ mịn biểu thị hình ảnh yên bình, cấu trúc, thanh lịch của Kỷ Dậu đối
        với những thay đổi trong đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Dậu thông minh, năng động, giàu kiến thức và can đảm. Những
        người sinh ngày này nhanh nhạy và sẽ sử dụng sự nhạy bén của họ trong
        kinh doanh để đạt thành công trong đời. Bản tính hiếu kỳ, kỹ năng quan
        sát của họ sắc bén và chính xác. Cùng với kỹ năng tư duy khách quan, họ
        có khả năng xử lý thông tin từ nhiều nguồn. Họ thường có lối suy nghĩ
        mới mẻ và cách thu thập thông tin độc đáo khiến cho họ luôn đi trước
        thời đại.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Vốn dĩ độc lập và tham vọng, người Kỷ Dậu thường có kế hoạch lớn. Một
        khi họ xác định các kế hoạch thì họ luôn nỗ lưc để đạt mục tiêu. Là
        người tiên phong, họ có khả năng sáng tạo ra nhiều ý tưởng mới. Họ không
        ngủ quên trên thành công. Thay vào đó, họ luôn có tinh thần khởi nghiệp
        và luôn không ngừng suy nghĩ để tìm cách phát huy những ý tưởng và suy
        nghĩa của mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Dậu thích vui chơi và dễ trở nên nổi tiếng trong giới bạn bè.
        Khi những người khác nhìn họ, họ tỏ vẻ rất tự tin và duyên dáng. Ngoài
        ra, họ mong chờ những thách thức thú vị và khao khát nhữngg cuộc phiêu
        lưu lớn trong đời. Họ thích rủi ro và thường sẽ đem những thay đổi lớn
        và phấn khích vào trong đời. Họ yêu thích sự sôi động, thách thức và
        thích những gì kích thích.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Cần phải lưu ý rằng người Kỷ Dậu khao khát tự do. Họ ghét bị cầm tù ở
        nhà hay nơi làm việc. Họ cần sự kích thích về thể chất và tinh thần để
        cảm thấy hạnh phúc. Người Kỷ Dậu rất độc lập. Họ không thích bế tắc vì
        bế tắc sẽ khiến tư duy họ buồn chán và cùn. Họ đạt hiệu quá cao nhất khi
        liên tục làm việc và sẽ có hiệu quả kém nhất khi ở yên.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ yêu thích phiêu lưu và sự thú vị sẽ gợi cảm hứng cho họ liên tục tìm
        kiếm trí tuệ và kiến thức, cũng như là lý do việc kích thích thường
        xuyên về mặt trí óc là cần thiết cho họ để có thể tạo ra những tiến bộ
        có ý nghĩa trong đời sống. Họ có một hệ thống trực giác ngầm mách bảo họ
        rằng những quyết định hay suy nghĩ cuả họ có đúng hướng hay không. Phần
        lớn chỉ khám phá ra tài năng này của họ khi lớn tuổi hoặc khi họ theo
        đuổi con đường nghiên cứu tâm linh.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Dậu trẻ tuổi thường có rất nhiều sự hoài nghi và bất an. Điều
        này là bởi vì người Âm Thổ thường yếu đuối và mỏng manh trong giai đoạn
        đầu tiên của cuộc đời. Điều này dễ dẫn đến họ có tâm trạng mất cân bằng
        và nóng giận. Trong những hoàn cảnh tệ nhất, nó có thể dẫn đến việc họ
        thường xuyên đắm chìm hưởng thụ. Do đó, có một số người Kỷ Dậu gặp khó
        khăn trong kiểm soát cân nặng. Để có thể chống lại điều này, họ cần phải
        giữ tư duy và thể chất năng động trong đời.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đôi khi, người Kỷ Dậu có thể non nớt trong quyết định và hành xử với
        người thân yêu, điều này rất dễ thấy thông qua hành động hấp tấp của họ.
        Họ thường không bao giờ nghĩ về hậu quả, đặc biệt là nếu họ cảm thấy mất
        kiên nhẫn. Nếu họ cảm thấy họ không thể biểu đạt cảm gíac hoặc chia sẻ ý
        tưởng, nó có thể dẫn họ tới việc nóng giận và sự u sầu.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ không thuộc dạng người sẵn lòng dựa vào người khác để xin trợ giúp
        hay giúp đỡ. Điều này có nghĩa là hầu hết người Kỷ Dậu thích đạt được
        thành công từ từ. Nếu người Kỷ Dậu học được cách kiểm soát sự tự cao của
        họ và khiêm tốn xin sự giúp đỡ hay hướng dẫn, thì thành công sẽ ở trong
        tầm tay của họ nhanh hơn dự kiến.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thật thú vị là người Kỷ Dậu không thể nhận được nhiều sự trợ giúp về tài
        chính, hay thậm chí thừa kế từ cha mẹ. Và nhiều người Kỷ Dậu cảm thấy
        khó mà giữ tiền được. Nếu họ phát huy được tài năng, họ sẽ không gặp khó
        khăn trong việc làm ra tiền, nhưng nhìn chung, họ sẽ tiêu gần như hết
        tất cả những gì làm ra. Do đó, điều rất quan trọng là từ nhỏ, người Kỷ
        Dậu phải học cách quản lý tiền bạc và thay vì tiêu tiền vô tội vạ, họ
        phải học cách mua tài sản thay vì tiêu sản.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Dậu có đầu óc rất năng động. Họ suy nghĩ nhiêu và đôi khi nói
        lên thành lời những gì họ suy nghĩ. Họ thường xuyên nói chuyện với chính
        họ. Người Kỷ Dậu đôi khi không tìm được bình an bên trong mà họ sẽ thấy
        rằng sự xông xáo liên tục dủa họ đôi khi dẫn họ đến sự tức giận và bất
        mãn. Điều này ngược lại, dẫn đến sự quá hưởng thụ. Tu tập về tâm linh có
        thể là câu trả lời cho vấn đề trên.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Dậu, do đó cần phải học hỏi từ sai lầm và xem mọi trở ngại là
        một cơ hội để cải thiện bản thân. Chỉ dựa vào việc bỏ chạy và tránh xa
        khó khăn không thật sự dẫn họ đến những gì quan trọng. Nếu họ có thể sử
        dụng tình yêu tự do, sự độc lập và thông minh trong các việc họ làm, họ
        sẽ giỏi xử lý những mục tiêu thách thức bày ra trước họ.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Của Người Kỷ Dậu:</b> hấp tấp, cần phiêu lưu, yêu tự
        do, nhiều ý tưởng, suy nghĩ rất nhiều, từ bi, hống hách, độc lập, thông
        minh, mất kiên nhẫn.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Hôn Nhân - Quan Hệ - Gia Đình:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi là bạn đời, người Kỷ Dậu chung thuỷ và yêu thương. Họ sống lý tưởng
        và lãng mạn. An toàn về tài chính rất quan trọng đối với họ, đó cũng là
        lý do mà họ tìm kiếm mối quan hệ mà họ cảm thấy chắc chắn và thoải mái.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Không có gì hấp dẫn người Kỷ Dậu hơn là một người bạn đời có đầu óc tri
        thức. Họ ngưỡng mộ người có ý chí mạnh mẽ và quyết tâm. Điều này có
        nghĩa là họ kết nối về tình cảm với bạn đời thiên về mặt tri thức, sự
        sáng tạo và chia sẻ kiến thức.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trong tình cảm, người Kỷ Dậu lúc nào cũng cần sự quan tâm đầy đủ từ bạn
        đời. Bởi vì điều này, họ nên cẩn trọng tránh quá phụ thuộc vào bạn đời
        về mặt hạnh phúc.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Dậu thông minh, khôn ngoan và giao tiếp xã hội tốt. Họ ghét
        những cuộc trò chuyện vô bổ và thích ở cạnh những người có thể giúp họ
        có tư duy tích cực và động lực.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù họ tỏ ra tự tin, họ cần phải tranh đấu với cảm xúc bất an bên
        trong. Khi cảm thấy nghi ngờ bản thân, họ có xu hướng trả đũa bằng cách
        tỏ ra áp đặt người khác. Những hành động như vậy sẽ gây tổn hại cho mối
        quan hệ tình cảm với người khác bởi vì họ sẽ liên tục thúc đẩy người
        khác để che giấu bản tính dễ bị tổn thương của mình.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Dậu thường dễ có một mái ấm hạnh phúc vì họ thường gặp may mắn
        trong tình cảm, vì bản tính yêu thương và tận tâm. Mặc cho tất cả những
        điều này, những phút giây đắng cay trước ngọt ngào sau sẽ thỉnh thoảng
        ảnh hưởng đến hôn nhân của họ. Đối với phụ nữ Kỷ Dậu, có vẻ như chồng họ
        sẽ ít khi ở nhà khiến cho người nữ cảm thấy bị bỏ rơi và bất an.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Dậu có thể bắt đầu lập gia đình khi còn trẻ, và con cái họ
        thường rất thông minh vì Kỷ Dậu thường chú trọng đến nuôi dưỡng tình yêu
        tri thức từ khi còn nhỏ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Trong Tình Yêu - Quan Hệ:</b> độc đáo và độc lập, khó
        khăn khi bày tỏ tình cảm, bị cuốn hút bởi người mạnh mẽ, có tính cam kết
        cao, chung thuỷ và yêu thương.{" "}
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Kỷ toạ trên Trường Sinh và Văn Xương biểu thị người này rất thông minh
        với tình yêu văn chương và nghệ thuật. Hành Thổ và Kim có mối quan hệ
        tương sinh biểu thị người này sẽ có sức khoẻ tốt và ổn định suốt cuộc
        đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Dậu có cá tính rất mạnh. Điều này bởi vì Thực Thần Tinh rất
        mạnh trong lá số. Dậu địa chi ám hợp với Thìn. Đây gọi là Kiếp Tài Tinh.
        Họ có bản tính thích mạo hiểm và thích tham gia vào những hoạt động mạo
        hiểm vì có khả năng phân tích giỏi. Họ có khả năng phản ứng nhanh chóng
        với khó khăn và giỏi giải quyết khó khăn. Họ cũng có nhiều phẩm chất
        giúp đạt thành công.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Có ít trụ ngày tham vọng hơn Kỷ Dậu. Với sự kết hợp của Thực Thần và ám
        hợp Kiếp Tài Tinh Thìn, thì người Kỷ Dậu biểu thị sự can đảm, gai góc và
        dám phiêu lưu. Họ thường đánh giá bản thân mình bằng các thành tích, và
        thường cố gắng đạt được những điều tốt đẹp và to lớn hơn. Mặc dù điều
        này giúp thúc đẩy tiến lên, người Kỷ Dậu cũng dễ cảm thấy bị kéo căng
        quá mức. Do đó, họ cần hiểu giới hạn và học cách thiết lập mục tiêu thực
        tế cho công việc hay kinh doanh.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Kỷ Dậu là Thổ yếu. Do đó, họ có nhiều ham muốn ẩn giấu, sâu sắc về sự ổn
        định và an toàn trong đời. Họ sẽ muốn đảm bảo rằng họ luôn có nền tảng
        an toàn vững chắc về tài chính để ủng hộ họ trong những dự định tương
        lai. Người Kỷ Dậu, về mặt tiêu cực, có thể bị mù quáng về nhiều mặt
        ngoại trừ tiền bạc và tiến bộ, và khi điều này xảy ra, họ sẽ rất áp đặt,
        ích kỷ, giận dữ và phê bình.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Kết quả của Thực Thần Tinh, người Kỷ Dậu có sự quan tâm sâu sắc về tri
        thức và cuộc sống giàu tâm linh hay triết lý. Người Kỷ Dậu rất thông
        minh và họ thích tiếp thu thông tin về nhiều mặt trong cuộc sống. Một
        khi bạn hiểu sâu một người Kỷ Dậu, bạn sẽ thấy bên dưới vẻ ngoài nghiêm
        túc và khó khăn là một người lạc quan và cuốn hút. Họ thích ở bên cạnh
        những người có cùng tư duy và khiến họ cảm thấy thoải mái.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Rất quan trọng nếu người Kỷ Dậu biết cách sống chậm và thư giãn. Họ có
        khả năng tạo ra môi trường hoà hợp nhưng trước tiên họ cần học cách có
        những niềm vui nho nhỏ và sống theo dòng chảy.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Dậu thích sự hiện diện của Bính Hoả và Dần. Nếu có thì cuộc
        sống sẽ ổn định, thoải mái, thịnh vượng và suôn sẻ. Điều này là đúng bởi
        vì Bính Hoả là Chính Ấn rất cần đối với người Kỷ Dậu. Bính Hoả giúp tăng
        cường sức mạnh cho Kỷ Thổ yếu và giúp liên tục sinh ra Dậu Kim. Dần là
        Trường Sinh đối với Bính Hoả và cũng là Chính Quan đối với Kỷ Thổ. Mộc
        giúp làm tơi xốp Thổ và giúp nó có sức sống.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Dậu là Văn Xương Tinh. Trong lá số thì Kỷ bao phủ lên Dậu, khiến cho
        dòng năng lượng không chảy được. Đó là lý do tại sao sự hiện diện của
        Dần là cần thiết. Với sự giúp đỡ của Dần thì Thổ có thể tơi ra và Văn
        Xương giúp tạo ra sự thông minh, tri thức và sáng tạo tích cực giúp Kỷ
        Dậu toả sáng.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trong môn Bát Tự, Dậu xung với Mão. Biểu thị cho những rắc rối nội tại.
        Bởi vì Kỷ Dậu chủ yếu cần Mộc. Nhưng cùng lúc thì nó cũng toạ trên địa
        chi có xung âm thầm với Mộc. Đối với nữ giới Kỷ Dậu, hành Mộc đại diện
        cho chồng. Biểu thị mối quan hệ tình cảm với chồng, biểu thị rắc rối về
        hôn nhân.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khía cạnh tiêu cực này sẽ bị kích hoạt nếu và chỉ khi Tân kim xuất hiện
        trên Thiên Can trong lá số.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trụ này ám hợp với Thìn biểu thị người Chồng là Ất Mộc nằm ở Mộ. Điều
        này biểu thị môi quan hệ tình cảm của nữ Kỷ Dậu với chồng khá yếu. Điều
        này cũng có nghĩa là họ sẽ có quan hệ kém với chồng và hôn nhân kém khi
        còn trẻ. Rủi ro này sẽ được giảm bớt nếu có Bính hay Dần trong lá số.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Vì Kỷ Thổ bao phủ hay bảo bọc hành Kim trong lá số, điều này biểu thị
        người Kỷ Dậu sẽ quá bảo bọc con cái mình. Điều này dẫn đến họ sẽ trở
        thành cah mẹ quá lo lắng cho con mình. Trong quan hệ tình cảm, họ cũng
        là người thể hiện tính cách qúa sở hữu hay kiểm soát hoặc đòi hỏi quá
        đáng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Dậu sinh vào tháng mùa Xuân hay Hè có danh tiếng và nổi tiếng
        trong đời. Nếu sinh vào tháng mùa Thu hay Đông thì dễ thành công trong
        sự nghiệp. Nếu sinh vào ban ngày, họ có thể phải làm việc vất vả để
        thành công trong đời. Người sinh vào ban đêm thì sẽ có đời sống nhiều
        suôn sẻ. Không tốt nếu sinh vào tháng Tuất vì là dấu hiệu đối mặt nhiều
        thách thức và phải vật lộn để đạt được điều gì quan trọng trong đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Kỷ toạ trên Dậu với duy nhất Tân Kim Thực Thần. Tân bản thân nó đã là
        biểu hiện của kim loại quý có thể là vàng, bạc hay châu báu. Điều này có
        nghĩa là Kỷ Thổ đã có một thứ gì đó giá trị bên dưới vẻ ngoài của nó.
        Thực Thần là một sao sáng tạo hướng nội thường biểu thị người này có đầu
        óc sáng láng thông minh. Họ thường mơ mộng nhưng phần may mắn hơn là họ
        có thể bị đào xới lên dễ dàng vì đất sẵn lòng cho đi, bạn chỉ cần cào
        xới một ít thì Kỷ Dậu sẽ cho bạn thấy kho tàng của nó.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Vì Dậu đại diện cho sao Đào Hoa, họ sẽ cảm thấy khó có thể định vị ra
        được người theo đuổi. Kỷ rất thực tế và do đó họ thường đánh giá thấp
        bản thân mình và cuối cùng là ở với người không xứng đáng với mình. Họ
        cần một ai đó có thể đem ra những giá trị của họ và nểu ở với sai người
        thì họ cũng sẽ giống như một trang giấy trắng không thể đem đến điều tốt
        đẹp nhất cho thế giới.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Dậu kết hợp với Thìn sẽ đem đến năng lượng Mậu Thổ Kiếp Tài, Ất Mộc Thất
        Sát và Quý Thuỷ Thiên Tài. Sự kết hợp này tạo ra một người vừa khôn
        ngoan và ganh đua và có cặp mắt sắc bén nhìn ra cơ hội. Mối quan hệ tình
        cảm sẽ bị sứt mẻ vì Kiếp Tài xua đuổi Chính Tài đi xa trong khi giới
        thiệu Thất Sát đến.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Dậu xung với Mão Ất Mộc mang theo đến duy nhất 1 hành là Thất Sát. Với
        nữ giới, sao này đại diện cho bạn trai. Điều này một lần nữa phản ánh tư
        duy hạ mình của Kỷ Thổ. Phụ nữ Kỷ Dậu thường nghĩ rằng họ không xứng
        đáng trong một mối quan hệ trong khi cả nam giới hay nữ giới đều thiếu
        can đảm thật sự để vượt qua ranh giới.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đối với Kỷ Dậu để toả sáng, họ cần có ngoại lực để giúp họ tiến lên phía
        trước. Nếu họ dựa vào động lực của chính mình, thì có thể không thành
        hiện thực bởi vì Thực Thần giỏi trong việc khiêm tốn trong khi Kỷ Thổ tệ
        hơn về mặt uy tín. Họ sẽ yêu thích che giấu dưới bụi bẩn trong suốt cả
        đời. Họ cần phải được gột rửa sạch sẽ nếu họ muốn được toả sáng.{" "}
      </p>
    </div>
  );
};

export const KyMui = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Kỷ Mùi: người nhạy cảm, có trực giác, thiên về tình cảm
        hơn lý trí, khoan dung với người khác, thích những vật đẹp đẽ, đánh giá
        cao mọi thứ mà mình thích, có kỹ năng giúp bản thân thoát được những
        tình huống oái ăm. Nếu sinh ra trong ngày này, ngay cả nếu gặp ma, cũng
        sẽ thoát được mà không sao. Có quan hệ tình cảm và hiếu thuận với cha
        kém. Có tài lộc bất ngờ. Dễ tái giá.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đây là nơi mạo hiểm điên rồ và cũng là nơi được thiết kế để đảm bảo độ
        an toàn cho bạn. Kỷ Mùi cũng như đường đua xe dơ bẩn, nơi những người
        chung sở thích sẽ đến để thể hiện và rèn luyện kỹ năng trong khi vẫn
        tương đối an toàn và kiểm soát được môi trường xung quanh. Bạn có thể
        cảm thấy phấn kích, sợ hãi, hào hứng trong những cú nhảy nhưng cuối cùng
        vẫn tiếp đất an toàn.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Kỷ Thổ là đất quan tâm. Chúng thường được gắn với nông trại nơi những
        mùa vụ sẽ giúp nuôi dưỡng nhiều người. Hành này rất thích nghi trong
        việc cho đi và thường giàu dinh dưỡng mà họ che giấu bên trong lòng đất.
        Bị đào xới là một việc Kỷ Thổ khá quen thuộc và bạn sẽ thấy họ phơi bày
        điều này như là một người bị động trước sự lợi dụng của người khác. Mẹ
        Đất là điển hình của việc chấp nhận thích nghi bao dung với tất cả mọi
        người xung quanh. Chúng ta đều biết chúng ta đã lợi dụng Mẹ Thiên Nhiên
        biết bao nhiêu nhưng vẫn chưa nhận sự trả đũa nào.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Kỷ Dậu cũng giống như những hạt cát nhỏ mịn trong đồng hồ cát. Hạt cát
        nhỏ mịn biểu thị hình ảnh yên bình, cấu trúc, thanh lịch của Kỷ Dậu đối
        với những thay đổi trong đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Kỷ Mùi thường có hình ảnh như sa mạc ấm áp và nhiều cát
        vàng. Người Kỷ Mùi thường đẹp như những đụn cát vàng tơ óng ánh, bình
        yêu và tinh tế. Những ụ cát vàng thường chứa hàm lượng muối cao do đó
        rất dễ trở thành nguồn khoáng chất ẩn tàng trong lòng đất. Muối khoáng
        giúp giữ lượng nước ẩm cho đất mềm. Điều này phản ánh khả năng lưu giữ
        kiến thức và trí tuệ của người sinh ngày Kỷ Mùi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Mùi thường có trí tưởng tượng, biểu đạt và hào hứng. Họ có tính
        cách mạnh mẽ và can đảm, và thường tài năng và xinh đẹp hơn những người
        sinh ngày Kỷ khác. Cùng với tính nhạy cảm và thực dụng, họ có khả năng
        gây dựng được nền tảng cho những nỗ lực trong cuộc sống, sự nghiệp và
        kinh doanh để tiến bộ hơn nữa.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tư duy tiến bộ thường giúp họ vượt lên trước thời đại và giúp họ khác
        biệt với những người còn lại. Họ có bản tính thích nghiên cứu học hỏi
        những gì mà họ quan tâm. Tuy nhiên, sự ham thích học hỏi và biết mọi thứ
        có thể lấn át đến mức làm cho họ cạn kiệt năng lượng trí óc và trở nên
        thiếu quyết đoán trong nhiều vấn đề. Nếu họ không cẩn thân, sự thiếu
        quyết đoán này có thể khiến họ đi xa rời khỏi mục tiêu và khiến họ lười
        và chỉ chọn con đường dễ dàng thay vì dám đối đầu thử thách.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Mùi là những người sáng tạo và yêu thích nghệ thuật. Họ có tài
        năng về âm nhạc và vũ đạo, thích các hình thức tự thể hiện mình do đó họ
        có thể dễ dàng gây dựng sự nghiệp và sử dụng nghệ thuật như một cách
        giải trí và thư giãn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ là những người dám suy nghĩ lớn, thích thử thách. Họ dám chấp nhận
        những dự án thú vị hay vì chính nghĩa sẵn sàng nhảy vào bất cứ việc gì
        với nhiệt huyết tối đa. Họ mạnh mẽ trong cách làm việc và không chấp
        nhận làm những gì nửa vời. Người xung quanh có thể dựa vào họ trong mọi
        việc để hoàn thành trọn vẹn bất kỳ dự án nào. Bất cứ điều gì mà họ nhận,
        tinh thần khởi nghiệp sẽ thúc đẩy họ sáng tạo, phát triển các ý tưởng và
        biến chúng thành hiện thực.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ cũng bị thôi thúc phải giúp đỡ người khác bằng nhiều cách. Sử dụng
        trực giác bén nhạy, người Kỷ Mùi biết cách nắm bắt xu hướng hay những
        vấn đề đang xảy ra xung quanh họ. Vì họ có khả năng kết nối con người và
        nguồn lực, họ có thể tăng cường sức mạnh kết nối và xây dựng cộng đồng,
        cũng như đảm bảo tình huống cả đôi bên cùng thắng lợi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù họ có cách sống tham vọng, người Kỷ Mùi cũng che giấu sự bất an
        của họ vì cảm giác không đủ giỏi trong những việc mình làm, với mục đích
        là muốn chứng minh mình giỏi đến đâu. Họ cố gắng kiểm soát con người
        hoặc tình huống, đôi khi đến mức quá sức áp đặt, chỉ để đạt được điều
        mình muốn. Trong thực tế, họ làm những điều này thuần tuý là muốn tìm
        được sự chấp nhận và công nhận từ những người khác vì sự thừa nhận từ
        những người xung quanh rất có ý nghĩa đối với họ mặc dù họ có thể không
        thừa nhận.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Mùi rất thích triết học và khoa học tâm linh. Họ có xu hướng
        muốn theo đuổi những sở thích này và sử dụng kiến thức thu nhặt được để
        gợi cảm hứng cho những người xung quanh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Nét Tính Cách Tổng Quát:</b> trực giác, tài năng, hấp dẫn,
        thiếu quyết đoán, siêng năng, sáng tạo
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Mùi là những người làm việc siêng năng, quyết tâm đạt kết quả
        tốt. Họ làm việc hiệu quả và biết cách tổ chức trong công việc, đảm bảo
        rằng mọi vấn đề luôn được hoàn thành tốt. Khi được chuẩn bị tốt, họ có
        xu hướng sẵn sàng nắm bắt bất kỳ cơ hội nào xuất hiện.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ yêu thích thử thách và sẵn lòng thử làm mọi thách thức về kiến thức
        hay kỹ năng của họ. Mỗi khi làm, họ đều có một nguồn động lực mạnh mẽ
        tuôn chảy bên trong. Họ tiếp cận các thử thách với một chiến lược tốt,
        đảm bảo rằng mọi thứ họ làm đều sẽ đạt được kết quả như mong muốn và có
        khả năng quản trị bất kỳ rủi ro nào nổi lên.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ không thích quy trình hay bị ép buộc, do đó đây là lý do họ sẽ làm
        tốt nhất trong vai trò chủ doanh nghiệp. Nếu như tự làm chủ, họ có khả
        năng nắm giữ vị trí quản trị hay lãnh đạo.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Công Việc:</h4>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Mùi thích chuyên môn hoá trong 1 lĩnh vực và thích các công
        việc mang tính truyền thống. Họ sẽ trở nên xuất sắc trong những sự
        nghiệp mà ở đó họ có thể tự tay sáng tạo, ví dụ như thiết kế nội thất,
        xây dựng nhà cửa.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Công việc trong ngành thương mại cũng lý tưởng cho người Kỷ Mùi vì họ
        khôn ngoan và có đầu óc kinh doanh. Họ có kỹ năng thương lượng đàm phán
        và dễ vạch ra những chiến lược kinh doanh. Họ hợp tác tốt với người
        khác, nhưng họ dễ dẫn đầu hơn là theo đuôi, nhất là dẫn đầu tất cả mọi
        người trong bất kỳ ngành nghề nào.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ cũng giỏi trong việc tự xây dựng thương hiệu bản thân, và họ cũng
        thích đưa tên tuổi mình lên tầm cao. Do đó, họ có xu hướng thích làm
        việc trong ngành tiếp thị, thương hiệu và quảng cáo.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có bản tính thích giai điệu và sáng tạo do đó sẽ dễ thành công trong
        các lĩnh vực sự nghiệp như âm nhạc, thời trang, thiết kế, vũ đạo và viết
        lách.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Trong Sự Nghiệp - Công Việc:</b> truyền cảm hứng,
        quyết đoán, may mắn, sáng tạo, tưởng tượng, thích giao tiếp, siêng năng,
        phụ thuộc, thiếu kiên nhẫn, quá thận trọng, bất ổn, không suy nghĩ
        nhiều, cứng đầu.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Hôn Nhân - Gia Đình - Quan Hệ:
      </h4>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người sinh ngày này sở hữu cảm xúc mạnh mẽ và có thể trở thành
        người duyên dáng, chung thuỷ. Một khi họ tìm được một người đặc biệt, họ
        sẽ tận tâm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Mùi rất thích những người có nhiều nguồn lực. Họ cần người có
        thể đem đến tình yêu, sự hứng khởi và linh hoạt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Mùi nên bước vào mối quan hệ tình cảm thận trọng. Điều này sẽ
        đảm bảo cho họ tìm được người bạn đời đúng. Tình yêu có thể gây mù quáng
        cho nhiều người, đặc biệt là với người Kỷ Mùi, nhìn thấu rõ mọi việc là
        rất cần thiết trước khi họ có thể kết nối và tìm tình yêu ở một nửa kia.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người này có thể cần hết sức cẩn trọng trong việc tham gia vào một
        mối quan hệ tình cảm theo kiểu người đi cứu vớt. Cảm gáic liên tục là
        chỗ dựa cho người khác cuối cùng sẽ hoá ra là rút cạn cảm xúc của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người trụ ngày này sẽ làm bất cứ điều gì họ có thể cho người họ yêu, hi
        sinh mà không cần suy nghĩ lại. Họ thân thiện, rộng lượng và xã giao, đó
        cũng là lý do những người khác dựa vào họ trong thời điểm khó khăn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc cho sự thân thiện của họ, không phải là dễ dàng để chứng tỏ tình cảm
        của họ. Tuy nhiên, họ thường nhạy cảm hơn vẻ ngoài thể hiện. Gia đình và
        bạn bè cần phải chú ý đến cảm xúc của họ khi nói chuyện đến những chủ đề
        nhạy cảm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nói về hôn nhân thì người Kỷ Mùi có xu hướng tìm hạnh phúc và thịnh
        vượng trong những mối quan hệ mang tính cam kết cao. Họ cần sự an toàn
        và trật tự, bởi vì họ muốn tình cảm dài lâu và vợ hay chồng cần phải
        đồng hành cùng họ. Họ cũng là những bậc cha mẹ tốt vì họ có xu hướng
        thực tế khi xét vấn đề làm cha mẹ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ cần thận trọng tránh trở nên gây áp lực quá nhiều lên những người
        thân mặc dù đôi khi họ chỉ có ý muốn tốt đẹp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Của Người Kỷ Mùi Trong Tình Yêu - Hôn Nhân:</b> cần
        có thời gian để lắng đọng, tìm hạnh phúc trong hôn nhân, có khả năng áp
        đặt lên con cái, sống tình cảm hơn họ thể hiện, bạn đời chung thuỷ.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:
      </h4>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trụ Kỷ Mùi có Kỷ toạ trên Hoả Khố và thông căn. Hành Thổ được xem là ở
        giai đoạn thịnh vượng. Ở tàng can, có Tỷ Kiên, Thất Sát và Thiên Ấn cùng
        hiện diện. Với sự kết hợp của các sao này, Kỷ Mùi sẽ có sự tự cao rất
        cao, tự tin và cái tôi lớn. Phụ nữ ngày sinh này sẽ khá to lớn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Mùi có tính cách mạnh mẽ và hấp dẫn cũng như tinh thần tiên
        phong. Đây là những thuộc tính bẩm sinh do Thất Sát Âm Mộc ẩn tàng. Điều
        quan trọng là người Kỷ Mùi phải độc lập và có sự tự do để bày tỏ sự sáng
        tạo và theo đuổi những ý tưởng độc đáo của họ. Họ sáng tạo và năng động,
        thích dẫn lối và không thích bị ép buộc tuân lệnh.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có nhiều năng lượng và thích đi đầu cũng như đón nhận thử thách mới.
        Tỉnh táo, sắc bén và đa tài, họ nhanh chóng phản hồi lại những thay đổi
        trong môi trường và họ có trực giác tốt giúp ra những quyết định sắc
        bén. Người Kỷ Mùi hành động nhanh chóng và biết cách nhận diện cơ hội
        khi chúng xuất hiện. Đây là tính cách của Thiên Ấn.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù họ có thể đặc biệt mạnh mẽ về ý chí và thậm chí là cứng đầu,
        trong tất cả những mặt mạnh mẽ mà họ tỏ ra, người Kỷ Mùi có thể khá bất
        an bên trong. Điều này là do ảnh hưởng tiêu cực của Kỷ Thổ Tỷ Kiên mà
        sao này toạ. Nếu họ rơi vào tình huống tự nghi ngờ, họ có thể trở nên
        bất ổn và trở nên bế tắc trong vòng an toàn. Nếu họ dàn trải năng lượng
        quá rộng, nghĩa là họ không đủ tập trung thì họ có thể không khai phá
        được nguồn năng lượng đích thực trong đời. Họ sẽ có xu hướng muốn làm
        quá nhiều việc trong cùng 1 lúc.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Mùi hướng ngoại và thích xã giao do sự kết hợp của Tỷ Kiên và
        Thất Sát. Họ thích ở gần mọi người và có cá tính cuốn hút mọi người. Khi
        họ biết cách khai thác sức hấp dẫn bẩm sinh, người Kỷ Mùi có thể trở
        thành những nhà lãnh đạo có sức ảnh hưởng.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Mùi khá vui vẻ và đặc biệt duyên dáng khi giao tiếp, tuy nhiên
        lại tỏ ra xa lạ với những người thân. Họ có thể gặp khó khăn trong việc
        biểu đạt cảm xúc sâu bên trong và xu hướng thay đổi suy nghĩ nhanh chóng
        nếu không thoả mãn có thể khiến cho họ trở nên lạnh lùng và bất cần. Họ
        có thể rất cứng nhắc, đặc biệt là khi cảm thấy bất an. Họ đôi khi phải
        vật lộn để thoả hiệp nhất là khi họ thích mọi thứ theo ý mình. Họ cần
        phải bớt cứng đầu và nên cởi mở hơn với nhiều quan điểm, học cách tin
        tưởng trưc giác trong đối nhân xử thế.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Về kỹ thuật thì Kỷ Mùi được xem như là hành Thổ ở dạng cát sa mạc khô.
        Điều này biểu thị nhu cầu căn bản cần hành Thuỷ, nhất là nểu gặp Thuỷ
        trong lá số. Nước đối với người hành Thổ biểu thị cho hành Tài. Cách cục
        này biểu thị người Kỷ Mùi có nhu cầu cao tìm Tài Thuỷ so với người khác.
        Bản năng cần Thuỷ này có thể xem như là tinh thần khởi nghiệp thúc đẩy
        họ kiếm tiền.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Vì là trụ ngày Thổ, biểu thị sự cân bằng và thoải mái. Miễn là không có
        Hại hay Hình trong lá số thì chúng ta có thể mong đợi Kỷ Mùi có xu hướng
        có đời sống trên trung bình, Nếu họ tìm thấy Xung với Tý và Sửu trong lá
        số thì Thổ khí sẽ mỏng và bất ổn. Điều này biểu thị người này may mắn có
        nhiều cơ hội trong đời, nhưng đơn giản là không nhìn thấy cơ hội và
        không có mục tiêu sống. Khi nào Thổ bị rung lắc thì chất lượng của trụ
        này suy giảm.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trụ Kỷ Mùi được biết đến như là Phúc Tinh và đem đến may mắn, thịnh
        vượng và hạnh phúc. Người sinh trụ ngày này có khả năng sinh ra trong
        gia đình giàu có. Họ có phước. Người Kỷ Mùi, như là sa mạc hay đất cát,
        là đất khô nhất trong tất cả loại đất. Ngay cả với nhiều hành Thuỷ trong
        lá số thì không bao giờ thật sự ẩm. Điều này biểu thị khả năng hấp thu
        vô lượng Thuỷ, không giống như các trụ ngày Kỷ Thổ khác, vốn dĩ đã ướt
        và ẩm, không có khả năng xử lý nhiều nước. Vì là Thổ khô, Kỷ Mùi khao
        khát thêm nước, biểu thị sự quan tâm trong việc xây dựng và tích luỹ tài
        lộc. Nhu cầu cần thêm Thuỷ thường cũng là dấu hiệu cho thấy người Kỷ Mùi
        có khiếu kinh doanh tốt. Tuy nhiên, sự hiện diện tiêu cực của Xung hay
        Hình trong địa chi, có thể huỷ diệt ảnh hưởng của Phúc Tinh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Có Thất Sát Tinh ẩn tàng trong lá số, biểu thị người này sẽ hơi ma mãnh.
        Miễn là hành Thổ che giấu hành Kim thì người này có thể giấu những âm
        mưu của mình. Tuy nhiên, nếu Thất Sát xuất hiện trên Can, xuất hiện hành
        Kim, biểu thị người khác có thể thấy được ý định của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người vợ Kỷ Mùi sẽ đem đến may mắn cho chồng. Điều này bởi vì hành Mộc,
        đại diện cho người chồng đối với nữ Kỷ Mùi có căn gốc tại Thổ. vì Thổ
        mạnh, nó biểu thị cưới được người chồng có năng lực. Nếu kèm với hành
        Thuỷ trong lá số thì người chồng này cũng sẽ độc lập về tài chính. Nếu
        Hợi xuất hiện trong lá số người nữ Kỷ Mùi, tương đương với sự hiện diện
        của hành Thuỷ xuất hiện để tưới tắm ruộng khô để gieo trồng mùa vụ. Cục
        này đặc biệt có lợi vì biểu thị sức khoẻ tốt và khả năng sinh con tốt
        cho người nữ Kỷ Mùi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nữ Kỷ Mùi sinh vào mùa Xuân và Hè có thể gặp vấn đề sức khoẻ xấu dai
        dẳng suốt cả đời. Người Kỷ Mùi sinh vào mùa Đông hoặc Thu có thể rất
        giàu có và thịnh vượng. Nếu họ sinh vào ban ngày thì Kỷ Mùi sẽ có sức
        khoẻ và năng lượng tốt. Nếu sinh vào ban đêm thì con cái tốt. Không tốt
        cho người Kỷ Mùi sinh vào tháng Thìn biểu thị cuộc đời có nhiều rắc rối
        và trở ngại kéo dài cả đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Kỷ toạ Mùi mang theo chính khí Kỷ Thổ Tỷ Kiên và phụ khí Ất Mộc Thất Sát
        và Đinh Hoả Thiên Ấn. Cả 3 sao trên đều dính đến khá nhiều yếu tố cảm
        xúc trong đó Thiên Ấn là yếu tố trực giác. Họ có thể làm nhiều điều điên
        rồ và mạnhs mẽ vì bạn bè. Xét về mối quan hệ tình cảm cho nữ giới đối
        với nữ sinh ngày Kỷ Mùi thì sao Tỷ Kiên và Thất Sát không phải là tốt vì
        Tỷ Kiên có khả năng cướp mất người chồng Chính Quan trong khi Thất Sát
        biểu thị quan hệ tình cảm ngắn ngủi như kiểu một môi quan hệ tình cảm
        qua đường.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mùi hợp với Ngọ cuốn hút năng lượng của Đinh Hoả Thiên Ấn và Kỷ Thổ Tỷ
        Kiên. Nhìn chung về quan hệ tình cảm thì khá trung bình cho cả hai giới.
        Chủ đề chính là để trở nên hoà hợp với môi trường xung quanh, họ sẵn
        lòng bắt chước để tạo sự đồng cảm với những người khác. Nếu tất cả bạn
        bè của họ còn độc thân, họ sẽ không dám bước vào một mối quan hệ vì sợ
        bị tập thể tẩy chay.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mùi xung với Sửu mang theo khí chính của Kỷ Thổ Tỷ Kiên và phụ khí Quý
        Thuỷ Thiên Tài và Tân Kim Thực Thần. Thiên Tài là sao chỉ bạn gái đối
        với lá số nam và do đó sẽ là vấn đề với người Kỷ Mùi trong giai đoạn
        hình thành mối quan hệ tình cảm. Tân là Thực Thần sẽ dễ từ chối các mối
        quan hệ mới hình thành. Một người Kỷ Mùi bình thường rất thực tế và bạn
        khó nhận ra họ vì họ cố hoà nhập và hoà tan.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Kỷ Mùi thường đủ điên rồ để đi theo người mình yêu, chấp nhận
        những rủi ro không cần thiết. Họ sẵn lòng làm tất cả những điều này mà
        không cần gì hồi đáp nhưng chỉ cần chấp nhận tình cảm. Họ là những người
        đối tác kinh doanh trầm lặng vì họ sẵn lòng hỗ trợ giúp đỡ mà không cản
        trở khả năng ra quyết định của bạn.
      </p>
    </div>
  );
};
