export const BoSungHanhKim = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
            fontWeight: "bold",
          }}
        >
          Bổ sung Hành Kim
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          <strong
            id="docs-internal-guid-5a66f29d-7fff-bd53-7eb9-da6a3e88ca7e"
            style={{ fontWeight: "normal" }}
          >
            &nbsp;
          </strong>
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
            fontWeight: "bold",
          }}
        >
          Thiên:&nbsp;&nbsp;
        </span>
      </p>
      <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Chọn đại vận hoặc năm Thân, Dậu hoặc Canh Tân để lên kế hoạch dài
              lâu 5 - 10 năm.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Trong một năm, chọn tháng mùa thu: Thân, Dậu để lên khởi sự những
              kế hoạch đã tính toán từ trước nhằm đạt thành công.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Chọn những ngày Canh Thân, Tân Dậu để khởi sự những công việc quan
              trọng.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Luôn lên kế hoạch cho những cuộc hẹn, gặp gỡ quan trọng vào buổi
              chiều, từ 13h - 19h.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Chọn địa điểm gặp gỡ, cuộc hẹn ở các quán nước, cafe ở góc Tây
              hoặc Tây Bắc tính từ nhà mình. Góc Tây và Tây Bắc thuộc về hành
              Kim.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Chọn số sim điện thoại, số nhà, số xe, hoặc căn cước, tài khoản
              ngân hàng có nhiều số 4, 9 thuộc về hành Kim.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu mua hoặc thuê nhà chung cư, cao ốc văn phòng ưu tiên chọn
              phòng hoặc tầng có số 4, 9, 14, 19, 24, 29....&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nên giữ làn da trắng sáng bằng các loại kem dưỡng, kem chống nắng,
              nếu để da khô nứt nẻ hoặc đen sạm do tiếp xúc quá nhiều Hoả mặt
              trời là bất lợi vì Hoả khắc Kim.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nên đeo khẩu trang cẩn thận khi ra đường để bảo vệ đường mũi, phổi
              tránh bị khói bụi ô nhiễm, vi khuẩn, virus tấn công hệ hô hấp (vì
              hệ hô hấp thuộc hành Kim).
            </span>
          </p>
        </li>
      </ol>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          <strong style={{ fontWeight: "normal" }}>&nbsp;</strong>
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Địa:&nbsp;
        </span>
      </p>
      <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nên chọn mua nhà, đất ở các quận huyện ở phía Tây của một thành
              phố hoặc ở cung phía Tây có núi đồi cao sẽ giúp người này nhiều
              may mắn. Ở Tp.HCM có thể cân nhắc các quận như 10, Bình Chánh,
              Bình Tân, Hóc Môn...
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu muốn lập nghiệp, di cư có thể cân nhắc những khu vực như Tây
              Ninh, Tây Nguyên hoặc miền Tây Nam Bộ.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Chọn căn nhà toạ Tây hướng Đông hoặc toạ Tây Bắc hướng Đông Nam;
              với phòng ngủ đặt ở góc Tây hoặc Tây Bắc phía sau nhà sẽ giúp gặp
              nhiều may mắn.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nên chọn xây mái gồm nhiều đường cong, mềm, tròn cũng như màu sơn
              ngoại thất công trình là trắng, xám sẽ giúp cho căn nhà đem lại
              may mắn cho gia chủ.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu muốn chọn văn phòng làm việc hoặc căn hộ nên chọn trong những
              toà nhà cao tầng, đa phần đều xây bằng kết cấu thép lớn.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Phòng ngủ nên trang trí, sơn màu trắn, sáng, ghi xám nhẹ hoặc sử
              dụng tone màu lạnh.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Giường ngủ có thể chọn loại bằng kim loại như sắt, có nhiều drap
              giường, chăn gối nệm thiên về màu trắng, xám.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Phòng ngủ không nên đón ánh nắng Tây buổi chiều vì đây là Âm Kim,
              xấu cho vấn đề sức khoẻ của con người.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Phòng tắm nên trang trí bằng tone màu trắng và đen, sử dụng đèn
              vàng để tạo sự ấm cúng không quá lạnh lẽo. Đèn vàng cũng thuộc về
              Thổ Kim.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu đặt điều hoà, máy lạnh tại góc Tây sẽ giúp tăng cường thêm may
              mắn. Tuy nhiên điều này cần nên tham khảo ý kiến của chuyên gia
              phong thuỷ để chắc chắn hơn.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Trong phòng làm việc nên bố trí 2 loại đèn: màu trắng và màu vàng
              pha với nhau sẽ tạo thành ánh sáng tự nhiên dễ chịu, không quá
              lạnh và không quá nóng.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nên sử dụng đá cầu thang hoặc đá lót bếp cũng như tủ bếp có màu
              trắng, xám là tone màu Kim hoặc tone màu vàng vì vàng là Thổ sinh
              Kim.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Khi gặp xui xẻo thì tại nhà nên dùng chuông để gõ khắp nhà, nhất
              là tại những nơi gầm cầu thang, toilet, có nhiều âm khí để xua tan
              hoá giải nhẹ nhàng.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Sử dụng các vật liệu trang trí hình tròn, màu trắng hoặc bằng
              inox, đồng, bạc để trang trí trong nhà sẽ đem lại may mắn.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Tránh đặt bếp tại góc Tây hoặc Tây Bắc hoặc sao Lục Bạch, Thất
              Xích Kim vì Hoả khắc Kim sẽ xấu về sức khoẻ.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Có thể đặt vật phẩm Trống Đồng, tranh đồng phong thuỷ hoặc treo
              chuông gió 6 ống đồng Phong Thuỷ.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Có thể đặt đồng hồ quả lắc toàn bộ bằng đồng để tăng cường may
              mắn.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Trong phòng khách hoặc phòng làm việc có thể bố trí thêm 1 tủ lạnh
              để tạo nhiệt độ thấp, đóng tuyết nên có lợi với người cần hành
              Kim. Chọn tủ lạnh có màu trắng có lợi hơn màu đen.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Cũng có thể sử dụng thêm tủ đông kèm với tủ lạnh và thường xuyên
              chứa nhiều đá tại đây, hoặc sử dụng loại tủ lạnh trực tiếp làm đá
              sẽ có lợi.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Gương soi cũng là Kim Thuỷ, người cần Kim có thể thiết kế gương
              tại một số chỗ trong nhà tuy nhiên sử dụng gương phải tính toán
              đúng chỗ, nếu không sẽ gây nguy hại, đặc biệt tránh đặt gương ở
              gần giường ngủ. Đặt gương trong phòng tắm và phòng thay đồ thì
              tốt.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Cửa sổ nhôm kính mùa trắng là tốt.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nam giới thì nên xoay đầu giường về Tây Bắc, còn nữ giới thì nên
              xoay đầu về Tây sẽ tốt đẹp. Nếu nam giới xoay đầu về Tây thì ẻo
              lả, nữ giới xoay đầu về Tây Bắc thì mạnh mẽ, như đàn ông.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nhà không nên trồng quá nhiều cây. Nếu thích có thể trồng cây Âm
              Kim như trầu bà vì có thể tạo ra lượng lớn oxy, khi trồng nên để
              đèn thạch anh chiếu vào cây này hoặc dùng đèn màu vàng chiếu vào
              để tạo phản ứng quang hợp tạo Oxy nhiều.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Xung quanh nhà nếu phải trồng cây thì ưu tiên cho cây lá Kim như
              thông.&nbsp;
            </span>
          </p>
        </li>
      </ol>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          <strong style={{ fontWeight: "normal" }}>&nbsp;</strong>
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Nhân:&nbsp;
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Trang Phục / Ngoại Hình:&nbsp;
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          <strong style={{ fontWeight: "normal" }}>&nbsp;</strong>
        </span>
      </p>
      <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Quấn áo màu trắng, xám là tốt. Có thể mặc trang phục có đính các
              hạt trang trí như khuy, nút kim loại, màu vàng, bạc, mạ
              vàng.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Các loại quần áo jeans hoặc giày, áo khoác làm từ da bò, có các
              khuy sắt mạ bạc cũng rất tốt để tăng cường hành Kim
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Chọn laptop có màu trắng như MacBook sẽ giúp nhiều may mắn hơn các
              laptop màu đen Thuỷ.&nbsp;&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Chọn điện thoại có vỏ ốp nhôm hoặc vỏ ngoài sơn màu trắng, vàng
              hoặc có mạ vàng sẽ đem lại nhiều may mắn.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Đeo dây chuyền, vòng tay bằng vàng, bạc hoặc bạch kim sẽ tốt. Trên
              cổ đeo dây chuyền, cổ tay đeo vòng, ngón tay đeo nhẫn vì cổ, ngón
              tay thuộc Mộc nên có thể khống chế Mộc mà phát Kim, Nếu muốn may
              mắn nhanh thì đeo thêm lắc chân, vòng chân bằng vàng. Hành Kim
              nhiều nhất trong kim loại vàng, sau đến bạc, cuối cùng là đồng,
              nên tuỳ theo điều kiện kinh tế mà chọn trang sức để đeo.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Cũng có thể chọn đeo đá amolite, thạch anh màu trắng để tăng cường
              hành Kim.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nhuộm tóc màu hơi vàng sẽ giúp tăng cường may mắn. Tránh nhuộm tóc
              màu trắng hoặc nếu tóc bạc cần nhuộm đen nếu người này vẫn còn
              trẻ. Tóc bạc trắng sớm là biểu thị không có duyên phận với cha
              hoặc người cha mất sớm.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Móng tay để tự nhiên, hoặc sơn màu trắng là lý tưởng.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Có thể dùng kẹp tóc, buộc tóc hoặc băng đô màu trắng để gia tăng
              may mắn.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Treo 6 đồng xu tiền Lục Đế trong xe hơi hoặc làm móc gắn chìa khoá
              để tăng thêm may mắn.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Gọng mắt kính màu trắng, xám sẽ giúp tăng cường may mắn. Gọng kính
              mạ vàng và tròng kính hình tròn rất tốt.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Chọn ví bóp màu đỏ hoặc cho 1 vài đồng xu nhỏ (tiền các nước hoặc
              tiền cổ đã được rửa sạch) vào trong bóp ví để tăng cường may mắn
              về tài lộc.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Có thể mang theo gương nhỏ để trang điểm bên người, giúp may mắn.
              Loại gương tốt là có hình tròn. Phụ nữ cũng có thể mang theo bấm
              móng tay, nhíp nhổ lông mày, hộp dao kéo thẩm mỹ nhỏ theo bên
              người.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Có thể đeo đồng hồ cơ hình tròn, nhất là những loại có lộ rõ hình
              bánh răng kim loại bên trong rất tốt. Nếu có mạ vàng hay gắn hạt
              kim cương để Thổ sinh Kim càng tốt.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu còn trẻ nên thường xuyên chú ý sức khoẻ răng miệng, nếu thích
              làm dáng và có điều kiện có thể gắn răng vàng, bạc để tăng may
              mắn.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu về già cần chú ý sữa bổ sung Canxi đế chống loãng xương.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu cần Kim thì nên thường xuyên cắt tóc, móng gọn gàng, không nên
              để quá dài vì tóc thuộc Mộc, kỵ Kim. Nếu phải để tóc dài nên dùng
              kẹp tóc bằng kim loại để nẹp tóc lại.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Trong túi áo có thể dắt theo bút mạ vàng.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Mũi thuộc Kim nên nếu mũi có đốm, nám, sẹo, mụn cần phải đi điều
              trị để khử nốt ruồi, tàn nhang. Cũng có thể dùng mỹ phẩm làm trắng
              da. Người Kim yếu thường thiếu vitamin E nên bổ sung vitamin E
              cũng rất tốt.&nbsp;
            </span>
          </p>
        </li>
      </ol>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          <strong style={{ fontWeight: "normal" }}>&nbsp;</strong>
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Ẩm Thực&nbsp;
        </span>
      </p>
      <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Uống sữa bò, dùng các thực phẩm có nguồn gốc từ sữa như phô mai,
              bơ, váng sữa, rau câu, thạch sẽ giúp tăng cường hành Kim.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Trà sữa, cà phê sữa giúp tăng cường hành Kim.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Sử dụng yến sào tăng cường hành Kim, có thể nấu kèm đường phèn là
              Thổ Kim tương sinh.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Lê trắng và mộc nhĩ trắng cũng tăng cường hành Kim.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Các thức ăn chế biến từ phổi, cổ, đầu, lòng như đầu gà, đầu heo,
              phổi lợn, lòng gà cũng giúp tăng cường hành Kim. Gà xào xả ớt,
              canh phổi heo, thịt đông cũng giúp bổ sung hành Kim.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Ăn cháo lòng, canh phổi heo nấu củ cải trắng cũng rất tốt cho học
              hành.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Thịt gà, khỉ có hành Kim cao. Nếu ăn thịt gà nên ăn cả da gà vì có
              hành Kim cao.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Cũng có thể dùng hạt nêm từ gà, nước cốt gà.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Rắn thuộc Hoả và Kim. Nếu ăn canh rắn là Kim Thuỷ, nếu ăn xào
              nướng thì là Hoả.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Ăn kẹo cao su, ô mai, me, bát tiên, sẽ giúp tạo nước bọt giúp
              khoang miệng nhiều Kim Thuỷ.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Ăn kem cũng tốt bổ sung hành Kim.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Hạn chế không nên hút thuốc vì Hoả khắc Kim.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Có thể ăn đồ hơi lạnh, hoặc cay như Kim Chi cũng không có vấn đề
              gì.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Uống nước tăng lực màu vàng như Bò Húc cũng tăng cường hành
              Kim.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nấu chè đậu trắng hoặc canh thịt bò củ cải trắng cũng tăng cường
              Kim rất tốt.
            </span>
          </p>
        </li>
      </ol>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          <strong style={{ fontWeight: "normal" }}>&nbsp;</strong>
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Động Vật / Vật Phẩm:&nbsp;
        </span>
      </p>
      <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Có thể đặt tượng Gà, Khỉ hoặc Trâu bằng vàng hoặc đồng và đặt ở
              góc Tây của phòng ngủ để tăng cường may mắn.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Trẻ con khi đi thi, có thể đeo dây chuyền vàng bạc, cầm theo chai
              nước nhiều đá viên, mặc quần áo giày, tất màu trắng. Móng chân
              móng tay cắt gọn gàng, tóc hớt cao càng tốt.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Đặt tháp Văn Xương hoặc hình tháp Effiel hoặc các con lắc trên mặt
              bàn làm việc để gia tăng thêm may mắn cho bản thân.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Treo tranh, câu đối Tết có chữ mạ vàng Kim Ngọc Mãn Đường, Đại Cát
              Đại Lợi.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Có thể tắm sữa bò, sữa dê để gia tăng may mắn.
            </span>
          </p>
        </li>
      </ol>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          <strong style={{ fontWeight: "normal" }}>&nbsp;</strong>
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Thói Quen Tốt:&nbsp;
        </span>
      </p>
      <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Đi du lịch về phía Tây: Tây Ninh, Tây Nguyên, miền Tây Nam Bộ, hồ
              Tây.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Đi du lịch nước ngoài: đến Thái Lan, Ấn Độ, Châu Âu, Hàn Quốc, leo
              núi Phú Sỹ nhất là khi mùa đông, có tuyết rơi.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Đi trượt tuyết, đến những nơi có nhiều băng tuyết như Himalaya,
              Canada.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu không có nhiều điều kiện đi du lịch nước ngoài có thể đến
              những nơi có khí hậu lạnh như Sapa, Đà Lạt, Tây Bắc hoặc khu băng
              đăng, trượt tuyết trong nhà.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Đi siêu thị, nhất là siêu thị điện máy như Nguyễn Kim, showroom,
              triển lãm ô tô sẽ gặp nhiêu may mắn.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu có tiền để dành nên mua một ít vàng vật chất để làm tài sản
              phòng thủ.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Mỗi sáng thức dậy nên cạo sạch râu, cắt lông mũi, tỉa lông mày sao
              cho gọn gàng. Nên dùng dao cạo râu mà không nên dùng máy cạo râu
              bằng điện. Dùng kem cạo râu màu trắng cũng rất tốt để dùng Kim
              khắc Mộc.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Dùng kem đánh răng, nước súc miệng, kẹo cao su có hương bạc hà để
              làm sạch khoang miệng rất quan trọng. Nên mang theo 1 chai xịt
              thơm miệng để giữ vệ sinh vùng miệng rất tốt.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Mỗi ngày đi làm nên chọn tuyến đường đi làm sao để từ nhà đến văn
              phòng là đi theo hướng Tây hoặc Tây Bắc để nạp Kim tốt. Ví dụ có
              thể chọn tuyến đường hàng ngày đi làm là trục Võ Văn Kiệt, đại lộ
              Đông Tây thì trục đường này xây theo Đông - Tây.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Bố trí bàn làm việc tại góc Tây Bắc hoặc cung Tây Bắc của văn
              phòng làm việc sẽ giúp mau được thăng quan tiến chức.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Tại nơi bàn làm việc, thư phòng nên đặt thêm Tháp Văn Xương bằng
              đồng.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Vị trí Tây Bắc trong văn phòng tuỳ theo phong thuỷ có thể đặt các
              đồ vật màu trắng tại đây như quạt gió, điều hoà màu trắng, bạc
              chạy suốt ngày để thúc đẩy vượng khí đến.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Mang theo chìa khoá, móc khoá, các đồ chơi như Dao Thuỵ Sĩ, công
              cụ đa năng như kềm bấm móng, kéo, tua vít…để tăng cường may
              mắn.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Kỵ mang theo bật lửa, nhất là loại màu đỏ bên người.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Có thể chơi cá môn nhạc cụ như chuông, khánh, cồng, chiêng, kèn
              saxophone. Nếu cần Kim có thể đặt 1 chuông lớn tại cung Tây Bắc
              hoặc sao Lục Bạch hoặc cung có sao Ngũ Hoàng và Nhị Hắc, nhất là ở
              phòng thời mỗi ngày gõ 6 lần vào giờ Thân và Dậu để cầu nguyện. 6
              tiếng chuông sẽ xua đuổi Ngũ Hoàng và Nhị Hắc.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Cũng có thể học chơi đàn dương cầm hoặc đàn tranh vì mang hành Kim
              nhiều.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Người Kim yếu thì khi gặp xui xẻo thì Kim yếu tức phổi yếu, hệ hô
              hấp không tốt. Do đó không hấp thu đủ khí Oxy dẫn đến thiếu tỉnh
              táo vì não không đủ Oxy. Muốn cho cơ thể nạp đủ khí Oxy thì mỗi
              sáng vào giờ Thìn 7h - 9h có thể tập thể dục để hít thở không khí
              vì sau giờ Thìn là đến giờ Tỵ là Trường Sinh của Kim. Cũng không
              nên tập vào 5h - 7h sáng là giờ Mộc vượng bất lợi cho Kim. Ngoài
              ra cũng có thể tập luyện từ 15h - 19h là giờ Kim vượng để gia tăng
              kích thước lồng ngực hấp thu không khí.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Có thể chọn các môn thể thao như tập gym, nâng tạ hoặc bơi
              lội.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Đi lại bằng tàu hoả hoặc tàu điện, điện ngầm là tốt vì Kim vượng.
              Tránh đi xe buýt thuộc Hoả hoặc đi thuyền là Thuỷ.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Mủa Xuân và Mùa Hè dễ mắc bệnh về hô hấp, nên mang theo khẩu trang
              ra đường.&nbsp;
            </span>
          </p>
        </li>
      </ol>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          <strong style={{ fontWeight: "normal" }}>&nbsp;</strong>
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Tìm Quý Nhân:
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        &nbsp;
      </p>
      <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu như theo Phật Giáo thì nên thờ tượng Quan Âm Bồ Tát. Vì tượng
              của ngài màu trắng và ngài thuộc phương Tây cho nên nếu thờ tượng
              Quan Thế Âm hoặc đặt hình Quan Thế Âm trong nhà, trong xe hơi, đeo
              dây chuyền nơi cổ có tác dụng phù trợ tốt. Khi cầu nguyện với ngài
              thường được linh ứng.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu như theo Đạo Giáo thì nên thờ cúng Diêu Trì Kim Mẫu còn gọi là
              Tây Vương Mẫu sẽ được linh ứng.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu có duyên thì cũng có thể thờ Tề Thiên Đại Thánh hoặc đặt tranh
              tượng Tề Thiên, Vua Khỉ trong nhà.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu đầu tư vào lĩnh vực tài chính, ngân hàng, ngoại hối, vàng bạc
              cũng khá tốt.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Tìm đến quý nhân nhiều Kim: người sinh tháng Thân Dậu, trong lá số
              có Tỵ Dậu Sửu, Thân Dậu Tuất, có ngày sinh Canh, Tân.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Tìm đến bạn bè có làn da trắng, khuôn mặt thon hình oval, da mịn,
              đường nét thanh tú.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Kết giao với bạn bè, khách hàng, đối tác ở miền Tây, Tây Nguyên,
              Ấn Độ, Châu Âu.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Theo dõi thần tượng những nghệ sĩ, danh nhân có lá số nhiều Kim:
              Lưu Đức Hoa, Tạ Đình Phong, Ngọc Trinh, Jang Dong Gun, Bae Young
              Joon, Trịnh Tú Văn.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Có thể mở cửa hàng bán trà sữa, tiệm hớt tóc, bán vàng bạc đá quý,
              mỹ phẩm dưỡng trắng.
            </span>
          </p>
        </li>
      </ol>
    </div>
  );
};
