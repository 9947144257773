
import {
  DiemVuongQuanSat,
  ThieuDieuQuanSat,
  TuQuyQuanSat,
  HoaThuongQuanSat,
  KimToaQuanSat,
  LacTinhQuanSat,
  ThamThuyQuanSat,
  NguQuyQuanSat,
  BachNhatQuanSat,
  BachHoQuanSat,
  ThangHoaQuanSat,
  ThienCauQuanSat,
  DucBonQuanSat,
  TuTruQuanSat,
  LoiCongQuanSat,
  DoanKieuQuanSat,
  DoanMenhQuanSat,
  ThienNhatQuanSat,
  TuongQuanQuanSat,
  KimXaQuanSat,
  DieuPhiQuanSat,
  QuyMonQuanSat,
  DaDeQuanSat,
  ThuyHoaQuanSat,
  HaTinhQuanSat,
  CapCuocQuanSat,
  LuanVeQuanSat
} from "./ListTieuNhi/TieuNhiData";


export const TieuNhiList = (title) => {
  switch (title) {
    case "Diêm Vương Quan Sát":
      return DiemVuongQuanSat;
    case "Thiên Điếu Quan Sát":
      return ThieuDieuQuanSat;
    case "Tứ Quý Quan Sát":
      return TuQuyQuanSat;
    case "Hòa Thượng Quan Sát":
      return HoaThuongQuanSat;
    case "Kim Tỏa Quan Sát":
      return KimToaQuanSat;
    case "Lạc Tỉnh Quan Sát":
      return LacTinhQuanSat;
    case "Thâm Thủy Quan Sát":
      return ThamThuyQuanSat;
    case "Ngũ Quỷ Quan Sát":
      return NguQuyQuanSat;
    case "Bách Nhật Quan Sát":
      return BachNhatQuanSat;
    case "Bạch Hổ Quan Sát":
      return BachHoQuanSat;
    case "Thang Hỏa Quan Sát":
      return ThangHoaQuanSat;
    case "Thiên Cẩu Quan Sát":
      return ThienCauQuanSat;
    case "Dục Bồn Quan Sát":
      return DucBonQuanSat;
    case "Tứ Trụ Quan Sát":
      return TuTruQuanSat;
    case "Lôi Công Quan Sát":
      return LoiCongQuanSat;
    case "Đoạn Kiều Quan Sát":
      return DoanKieuQuanSat;
    case "Đoản Mệnh Quan Sát":
      return DoanMenhQuanSat;
    case "Thiên Nhật Quan Sát":
      return ThienNhatQuanSat;
    case "Tướng Quân Tiễn Quan Sát":
      return TuongQuanQuanSat;
    case "Kim Xà Quan Sát":
      return KimXaQuanSat;
    case "Điểu Phi Quan Sát":
      return DieuPhiQuanSat;
    case "Quỷ Môn Quan Sát":
      return QuyMonQuanSat;
    case "Dạ Đề Quan Sát":
      return DaDeQuanSat;
    case "Thủy Hỏa Quan Sát":
      return ThuyHoaQuanSat;
    case "Hạ Tình Quan Sát":
      return HaTinhQuanSat;
    case "Cấp Cước Quan Sát":
      return CapCuocQuanSat;
    case "(*) Luận Về Quan Sát":
      return LuanVeQuanSat;

    default:
      break;
  }
};
