export const KyTho = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "70%", margin: "0 auto" }}>
        <h1 style={{ textAlign: "center", color: "brown", fontWeight: "bold" }}>
          Chương VI: Kỷ Thổ <br /> Ngày Sinh Kỷ Thổ
        </h1>
      </div>

      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Dụng Thần Điều Hậu</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b>• Sinh Vào Mùa Xuân</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Dần: 05/02 – 05/03
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Bính Hỏa, Canh Kim, Giáp Mộc
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Mão: 06/03 – 04/04
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Bính Hỏa, Quý Thủy, Giấp Mộc
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Thìn: 05/04 – 05/05
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Bính Hỏa, Quý Thủy, Giấp Mộc
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b>• Sinh Vào Mùa Hạ</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Tỵ: 06/05 – 05/06
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Bính Hỏa, Quý Thủy
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Ngọ: 06/06 – 07/07
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Bính Hỏa, Quý Thủy
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Mùi: 08/07 – 07/08
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Bính Hỏa, Quý Thủy
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b>• Sinh Vào Mùa Thu</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Thân: 08/08 – 07/09
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Bính Hỏa, Quý Thủy
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Dậu: 08/09 – 08/10
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Bính Hỏa, Quý Thủy
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Tuất: 09/10 – 07/11
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Bính Hỏa, Quý Thủy, Giấp Mộc
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b>• Sinh Vào Mùa Đông</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Hợi: 08/11 – 07/12
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Bính Hỏa, Mậu Thổ, Giấp Mộc
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Tý: 08/12 – 05/01
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Bính Hỏa, Mậu Thổ, Giấp Mộc
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Sửu: 06/01 – 03/02
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Bính Hỏa, Mậu Thổ, Giấp Mộc
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Lưu ý: Các bạn cần lưu ý những dụng thần điều hậu phía trên chỉ mang
        tính chất tham khảo. Nếu cần biết thêm về các dụng thần điều hậu, cũng
        như các dụng thần khác trong lá số bát tự bạn cần tham khảo hoặc tư vấn
        từ các chuyên gia.
      </p>

      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Hình Tượng</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đất nuôi dưỡng cây lá{" "}
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Kỷ Thổ là Âm Thổ. Đất là biểu tượng của nó. Người Kỷ Thổ giống như là
        đất đai màu mỡ, dễ uốn hoặc là đất mềm xốp đầy chất dinh dưỡng và được
        xem như là cái nôi của vạn vật. Kỷ Thổ nuôi dưỡng thực vật, cây cối và
        tạo ra khả năng phát triển không ngừng trên hành tinh này. Tương tự vây,
        người Kỷ Thổ rất tháo vát, có ích và rất có năng lực.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Kỷ Thổ thường rất sáng tạo và thái độ thâm trầm của họ che giấu đi
        rất nhiều tài năng khác nhau. Họ có thể không chia sẻ với người khác
        biết hết những năng lực của họ từ ban đầu và dần dần những tài năng đó
        sẽ được chú ý đến nhiều hơn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ thường là những kiểu người mà có thể tự làm một ly nước chanh khi
        cuộc đời đưa cho họ những trái chanh, có thể biến bất kỳ khó khăn nào
        trở thành tốt đẹp hơn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ rất dễ thích nghi, linh hoạt và họ không gặp vấn đề gì trong việc
        đương đầu với nhiều hoàn cảnh và trường hợp khác nhau mà xảy ra bất ngờ
        ở phút chót. Tuy nhiên, cũng giống như đất cần phải được nuôi dưỡng và
        cày cấy trước khi có thể cho ra trái, người Kỷ Thổ cần người khác uốn
        nắn thì họ mới có thể làm nên việc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Kỷ Thổ là người bao dung và nhân hậu nhất trong số 10 Thiên Can. Cũng
        giống như đất có thể được canh tác, cày cấy và trồng trọt trong một thời
        gian dài, người Kỷ Thổ cũng có thể là người đảm nhiệm nhiều thử thách
        gian nan trong công việc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Cũng như Đất Mẹ, người Kỷ Thổ có nhiều tài lẻ và có khả năng làm ra
        nhiều thứ. Họ luôn biết được đâu là người phù hợp với công việc, đi đâu
        để tìm được sự giúp đỡ đúng đắn, đâu là nơi hợp lí cho việc ăn uống, đâu
        là nơi có thể mua được đồ rẻ nhưng chất lượng,… v.v
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Vấn đề duy nhất là đôi khi họ không biết cách tận dụng những kiến thức
        hay tài năng của họ để đem về lợi ích cho bản thân họ. Giống như ruộng
        đất cần được cày bừa và bỏ công sức để làm thì mới thu được kết quả, họ
        phải đợi người khác đến và bắt họ làm. Đó hầu như là vấn đề chính của
        họ, họ không nhận ra được giá trị của bản thân. Những người Kỷ Thổ thành
        công thường chú trọng vào những tài năng giúp họ luôn vượt trội.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Về tổng thể, người Kỷ Thổ là những người được cho là có khả năng nuôi
        dưỡng tốt nhất, giống với một người mẹ nhất trong 10 Thiên Can. Khái
        niệm “Đất Mẹ” áp dụng với người Kỷ Thổ nhất là tại vì nó là Âm Thổ, đại
        diện cho quẻ Khôn là người mẹ. Thiên Can này có bản năng mạnh mẽ nhất
        của một người mẹ và khuynh hướng nuôi nấng, bảo vệ và chăm sóc người
        khác.
      </p>

      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Tính Cách</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ là những người rất tận tuỵ và sẽ nỗ lực hết mình để chăm sóc người
        khác. Trong thâm tâm, họ luôn có đặt quyền lợi công lên trên hết. Họ vốn
        rát thích phục vụ người khác. Thực tế là sẽ rất quan trọng đối với họ để
        có thể chăm sóc mọi người – để có thể trở thành thủ lĩnh và đảm bảo ràng
        con thuyền đang được chèo lái một cách rất trơn tru. Họ sẽ luôn nỗ lực
        để chắc chắn rằng những người xung quanh họ đều thấy hài lòng và thoả
        mãn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Do đó, người khác thường hay nhờ vả người Kỷ Thổ. Khi có vấn đề gì xảy
        ra và không ai chịu đứng ra làm, người Kỷ Thổ sẽ thường xung phong và tự
        tay giải quyết công việc. Họ sẽ là những người tiếp tục lo lắng về một
        vấn đề mãi sau khi người khác đã nghỉ ngơi và làm một ly rồi!
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Kỷ Thổ đặt rất nhiều trọng tâm vào suy nghĩ của họ và họ rất coi
        trọng những điều bay bổng trong tâm trí họ! Do đó, người Kỷ Thổ nhìn
        chung rất thông minh và thích những thứ kích thích bộ não – như là triết
        học, mỹ thuật, âm nhạc, tín ngưỡng và lý thuyết và các ý tưởng hoặc sáng
        kiến. Họ cũng thích cái đẹp và vì thế họ thường là những người sưu tầm
        mỹ thuật và những đồ thủ công.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với họ, sự phát triển trí tuệ rất quan trọng và họ không ngại chờ
        đợi điều đó. Hầu như, họ có những kĩ năng tư duy rất cụ thể và có trật
        tự và họ sẽ không ngại suy nghĩ sâu và thích tập luyện cơ bắp của bộ
        não! Họ không phải là những người tránh việc suy ngẫm.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Kỷ Thổ thích cảm thấy bản thân có thể đạt được một lối sống văn
        minh và văn hoá. Nếu họ có thể sống cuộc sống như một “quý ông” hoặc
        “quý bà”, họ sẽ thấy được là họ đã đạt tới vị trí đặc biệt và vì vậy sẽ
        thấy thoải mái và tự tin với địa vị mà họ đang có. Họ thích được sống
        một cách giàu sang khi có thể.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu ở vào thời xưa, người Kỷ Thổ chắc hẳn muốn đạt đến vị thế giai cấp
        quý tộc! Nhưng đôi khi người Kỷ Thổ lại không thể nhận ra là người duy
        nhất có thể tận hưởng lối sống giàu có thoải mái vốn là những người được
        sinh ra trong gia đình giàu có – hoặc những người giàu có nhờ làm việc
        lâu dài và chăm chỉ (và cũng phải rất thông minh)! Sẽ rất hiếm có người
        nào ngồi không mơ mộng mà đạt được điều đó.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Kỷ Thổ cần phải cảm thấy an toàn về mặt kinh tế và thậm chí là có
        của ăn của mặc thì họ mới có thể càm thấy tự tin. Họ có đặc quyền và
        khiếu thẩm mỹ tao nhã, và phải đạt được gu thẩm mỹ đó. Còn không họ có
        thể sẽ không thấy hài lòng về bản thân. Họ không phải những người có thể
        tận hưởng cuộc sống không có xe hơi, xối nhanh bằng gáo nước lạnh và ăn
        mì ăn liền trong cuộc sống hằng ngày!
      </p>

      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Mối Quan Hệ/ Tình Cảm</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tư duy của người Kỷ Thổ rất linh hoạt và dễ dung hoà, và đó là lí sao
        tại sao họ thường có thể nghĩ ra được những cách giải quyết cho những
        vấn đề rắc rối. Họ thường có những góc nhìn độc đáo và tươi mới nhưng họ
        lại không thường cho phép bản thân mình đắm chìm vào những suy nghĩ quá
        nhiều.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Kỷ Thổ có thể là người có trí nhớ tốt nhất trong 10 Thiên Can! Họ có một
        lượng trí nhớ khổng lồ để có thể nhớ hết từng việc xảy ra và có khả năng
        nhớ những thông tin rất tốt. Họ sẽ có thể nhớ lại những điều cũ và hình
        dung ra chúng trong chớp mắt.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Điều này thật sự có ích cho họ trong công việc, và trong bất kì tình
        huống nào cần đến trí nhớ và sức mạnh trí não tốt. Nhưng nó cũng có thể
        ảnh hưởng nặng đến cuộc sống cảm xúc và cá nhân của họ tại vì nó có
        nghĩa là người Kỷ Thổ thường không dễ quên những quá khứ đau buồn và
        mong manh.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Chính vì thế, họ thường có tính chiếm hữu cao và có thể trở thành người
        hay lo lắng nhất. Họ nhớ những sự kiện trong quá khứ và cho phép trí
        tưởng tượng của họ hình thành những suy nghĩ điên rồ nhất với vô số các
        giả thuyết, từ đó sẽ dẫn đến xu hướng muốn ghen tuông, kiểm soát hoặc
        bảo bọc người khác quá mức.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Kỷ Thổ là kiểu người ra quyết định dựa nhiều vào nhận thức của riêng họ
        về thế giới xung quanh và trực giác bản thân. Họ nhìn chung sống cảm
        giác hơn là lý tính. Một người Quý Thuỷ vượng có thể dễ dàng hiểu rõ
        tính cách của một người nào đó ở lần gặp mặt đầu tiên. Họ không lạnh
        lùng, tính toán theo kiểu luôn tìm kiếm lợi ích cho mình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Do vậy, họ hiếm khi có thể đưa ra quyết định ngay lập tức hoặc lên kế
        hoạch cho một số hình thức chiến lược mà không đưa vào phong cách cá
        nhân của họ. Họ không thể tách rời suy nghĩ, quan điểm cá nhân khi giải
        quyết một vấn đề nào đó.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đôi khi, nếu như một mối làm ăn tưởng chừng như có lợi nhưng lại xung
        đột với niềm tin căn bản của họ thì họ thà từ chối mối hời mà giữ được
        niềm tin cố hữu của mình. Họ sẽ không bao giờ hi sinh sự liêm chính để
        đối lấy lợi nhuận tức thời hoặc chọn lối đi tắt.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Kỷ Thổ, như chúng ta đã biết, được trang bị với hàng nghìn tài
        năng và khả năng khác nhau! Họ rất đa tài và giống như mặt đất mà có thể
        cày cấy để thu hoạch lượng trái cây và hạt dồi dào cho đời sống, tiềm
        năng của một người Kỷ Thổ không bao giờ có thể cạn kiệt.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Chính vì vậy, và mặc cho họ may mắn có được cuộc sống tốt đẹp, họ không
        phải kiểu người thấy hài lòng với việc ngồi không một cách nhàn rỗi. Họ
        phải cảm thấy họ có ích đối với người nào đó hoặc điều gì đó và lòng tự
        tôn của họ có được từ sự cân đối trong việc họ nghĩ có có ích như thế
        nào đối với người khác. Vì lí do đó mà họ sẽ thường không than vãn nhiều
        khi được yêu cầu làm điều gì có kì quặc – miễn họ thấy bản thân có ích
        là được!
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu người Kỷ Thổ bắt đầu cảm thấy bản thân thừa thãi và không còn được
        trọng dụng, điều này cũng sẽ ảnh hưởng một cách đáng kể đến lòng tự
        trong của họ. Họ sẽ bắt đầu thể hiện tệ đi và có thể bị tổn thương đến
        mức tuyệt vọng và trở nên thờ ơ với mọi thứ. Họ sẽ không thể “bung xoã”
        bản thân nhiều như lúc họ từng thể hiện nếu công sức của họ không được
        trọng dụng hay ghi nhận.
      </p>

      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Sự Nghiệp/ Công Việc</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tại nơi làm việc, người Kỷ Thổ thường không chậm chạp hay trì hoãn. Là
        một Thiên Can của đất ruộng và cho ra rất nhiều trái, người Kỷ Thổ cũng
        thường là những người gặt hái những kết quả mà công việc đang được chú ý
        đến. Họ thậm chí có khả năng đáp ứng những yêu cầu khó nhất!
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Giống như đất có khả năng hấp thụ nước và chất dinh dưỡng dễ dàng để
        sinh ra sự sống, người Kỷ Thổ luôn luôn sẵn sàng để học hỏi những điều
        mới hoặc thích nghi với những hoàn cảnh mới. Do đó, ở chỗ làm họ thường
        được đánh giá cao vì sự uyên bác và có đầu óc minh mẫn để thích ứng với
        những thách thức và hoàn cảnh mới một cách dễ dàng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Bởi vì họ có khả năng tích hợp giữa những kiến thức mới và tiếp nhận
        thông tin, họ có thể cải thiện bản thân khi họ cần. Vì vậy, họ sẽ không
        có vấn đề với việc cải thiện bản thân họ và hình dung những việc cần làm
        với kiến thức của họ để bản thân vượt trội hơn. Họ thường phát huy tốt
        hơn nếu được đánh giá và khen thưởng đúng lúc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi có vấn đề xảy ra ở nơi làm việc, người bạn cần tìm đến thường là Kỷ
        Thổ. Tại sao? Vì họ thường không từ chối hoặc tránh né người nào khác.
        Không quan tâm tình huống đó yêu cầu cái gì, họ sẽ luôn tìm cách để giúp
        đỡ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Vì thế, người Kỷ Thổ thường là một phần không thể thiếu trong nhóm làm
        việc hoặc một nhóm nào đó vì họ là những người đồng đội tốt. Thậm chí
        nếu người khác có thể bất đồng họ, chung quy lại, người Kỷ Thổ sẽ thường
        không bất mãn mà vẫn sẽ hoàn thành công việc của họ tốt như thường.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Hơn thế nữa, bản chất tự nhiên này không chỉ giới hạn những người mà họ
        thích. Kỷ Thổ hay thể hiện sự không thiên vị trong việc giúp đỡ và hỗ
        trợ mọi người như nhau. Thậm chí nếu họ không thật sự thân với một ai
        đó, họ vẫn sẽ tìm cách để giải quyết vấn đề nếu như bạn đã tìm tới họ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Là một người lãnh đạo hoặc quản lý, người Kỷ Thổ thích kiểm soát tất cả
        mọi việc. Họ thường hợp nhất sức mạnh của họ và cảm thấy yên tâm khi
        phần lớn quyền lực đang nằm trong tay họ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ rất cởi mở khi nghe ý kiến của cấp dưới và sẽ có sự chú ý đặc biệt
        đối với những ý kiến phù hợp với kế hoạch quản lý của họ. Tuy nhiên, nếu
        họ biết có ai có ý định chống lại kế hoạch ban đầu của họ, họ sẽ không
        ngần ngại mà lơ nó đi. Mặc dù vậy, họ vẫn sẽ cố gắng lắng nghe những ý
        kiến của mọi người.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Công việc giấy tờ và báo cáo sẽ là đặc trưng như phần lớn công việc của
        những người Kỷ Thổ lãnh đạo khi họ trở thành quản lý hoặc là lãnh đạo.
        Những việc này sẽ giúp người Kỷ Thổ cảm thấy mọi thứ đều nằm trong tầm
        kiểm soát và diễn ra một cách trơn tru.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Cũng giống như đất nuôi dưỡng giúp cây phát triển, người Kỷ Thổ rất tận
        tâm và bảo vệ những người dưới cấp họ. Họ sẽ luôn luôn để ý đến quyền
        lợi của cấp dưới của họ và sẽ sẵn lòng đứng ra chịu trận cho những người
        đó nếu có gì phát sinh.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Điều này dựa vào sự quý trọng và đánh giá của những người cấp dưới một
        cách tự nhiên và người Kỷ Thổ sẽ có luôn bảo vệ những người tuân theo
        những luật lệ và quy tắc lãnh đạo của họ. Nhưng nếu người Kỷ Thổ lãnh
        đạo và quản lý thấy cấp dưới làm việc kém hiệu quả thì họ sẽ cho thấy sự
        không hài lòng bằng nhiều cách khác nhau.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nhiều người cảm thấy thoải mái khi làm việc dưới sự quản lí của người Kỷ
        Thổ nếu như họ không thường có sự phản đối hoặc không đồng tình! Những
        người Kỷ Thổ lãnh đạo không phải kiểu người để cho cấp dưới đi vào thế
        kẹt và sẽ bảo vệ quyền lợi của họ khi có tình huống khó chịu xảy ra.
      </p>

      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Kinh Doanh</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Trong kinh doanh nói riêng cũng như trong các mặt khác của cuộc sống nói
        chung, người Kỷ Thổ là những người theo chủ nghĩa lãng mạn lý tưởng. Họ
        tin rằng có một hũ vàng ở ở cuối bất kỳ chặng đường khổ ải nào dành cho
        người làm việc vất vả và thông minh,và họ tin rằng chỉ cần theo đuổi đam
        mê và họ sẽ được hưởng lợi ích và hạnh phúc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Trong khi điều này nhìn chung thì rất đúng trên hầu hết mọi phương diện,
        nó cũng cần phải phù hợp với thực tế. Người Kỷ Thổ thường không để ý đến
        kiến thức thực tế mà họ cần để tạo ra được sự thành công trong kinh
        doanh. Nếu như điều mà họ đam mê là một thú vui nghệ thuật ít người quan
        tâm thì họ sẽ cố gắng thương mại hóa nó và đem đi bán - mặc dù có thể
        không hề có thị trường cho lĩnh vực đó.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ dành tất cả những sự đam mê và hứng thú để đi sâu vào bất cứ chuyện
        gì mà họ làm và thật sự đây là điều tốt! Nhưng người Kỷ Thổ thường chú
        tâm nhiều vào cảm xúc và đam mê cá nhân mà quên đi việc cân nhắc những
        vấn đề thực tế. Nếu họ có thể tìm được cách cân bằng giữ đam mê và thực
        dụng, họ sẽ có thể thế rất thành công.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Kỷ Thổ cũng giỏi trong việc tích luỹ của cải. Cũng giống như đất,
        từ từ hấp thụ nước, người doanh nhân Kỷ Thổ sẽ lên kế hoạch nghỉ hưu từ
        rất sớm. Họ thường lên kế hoạch cùng với sự đầu tư mà cho ra nhiều lợi
        tức dư giả có thể đảm bảo cho tuổi già của họ. Tất nhiên người Kỷ Thổ
        yếu hơn sẽ chỉ nhận ra được điều này khi lớn tuổi rồi.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Kỷ Thổ không thường là những người chủ doanh nghiệp độc lập. Thực
        tế họ sẽ thích có người đồng hành cùng hoặc có một đội ngũ trợ lực ở
        đằng sau hơn là một mình liều lĩnh đâm đầu vào rủi ro. Họ thường muốn có
        người cùng đồng hành để tham gia vào việc đầu tư. Họ thích thoả thuận.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Vì họ hiếm khi suy nghĩ về những khỉa cạnh thực tế trong kinh doanh, họ
        thường cảm thấy đang bước vào khu vực không ổn định…hoặc vũng cát lầy
        tuỳ theo từng trường hợp! Nên họ cảm thấy sẽ tốt hơn nếu có người cùng
        chia sẻ những thăng trầm hoặc ít nhất có thể tận dụng điểm mạnh của nhau
        – nó sẽ giúp họ cảm thấy an toàn hơn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ sẽ gặp khó khăn khi chia sẻ sức mạnh vì việc kinh doanh luôn là tâm
        huyết của họ. Tuy nhiên, nếu họ chọn người nào đó có điểm mạnh bù cho họ
        tuy người đó có thể giữ vững lập trường để chống đối những quan điểm
        kiểm soát quá đáng của riêng người Kỷ Thổ thì sự hợp tác đó sẽ thành
        công hơn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Kỷ Thổ có những điểm tính cách nhìn chung rất lịch sự khi làm bạn
        với người khác. Họ sẽ làm mọi cách để trở nên thân thiện với tất cả và
        sẽ cố gắng hết mức để không bị ai ghét hoặc không thích.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ cố gắng để không có gây thù với ai và sẽ không cố gắng gây hấn chống
        đối ai đó. Kỷ Thổ, nói theo cách khác, không tạo ra kẻ thù khi kinh
        doanh! Họ sẽ chia sẽ một cách thoải mái và chân thành những suy nghĩ với
        người khác nhưng nếu họ nói gì đó ít dễ chịu hơn thì họ sẽ dồn hết tâm
        trí và công sức để diễn đạt điều đó tốt hơn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nhìn chung, sẽ khó để tìm thấy ai thật sự căm thù hoặc ghét cay ghét
        đắng người Kỷ Thổ. Thậm chí nếu những người đó không thích họ nhất thời,
        họ sẽ bị thuyết phục bởi vì sự cố gắng để trở nên thân thiện và tốt bụng
        của người Kỷ Thổ vì họ không bao giờ thiên vị trong việc giúp đỡ và hỗ
        trợ những người khác.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Kỷ Thổ thường thu hút người khác dễ dàng nhờ sự bản tính dung hoà, cả
        trong công việc lẫn đời sống cá nhân. Họ thích có cuộc sống tốt và sẽ
        thích được dạy dỗ, vui vẻ trong những thói quen hằng ngày.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Do vậy, họ thường gộp bạn bè thành bất kì nhóm nào mà họ thích. Ví dụ
        như tôn giáo và những giáo phái là một nhóm. Họ sẽ có nhiều người bạn có
        cùng suy nghĩ trong những nhóm thích học tập hoặc hoạt động văn hoá liên
        quan đến tôn giáo mà họ theo đuổi.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Vì họ thường có rất nhiều mối bận tâm trong cuộc sống, họ cũng có nhiều
        người bạn khác nhau. Họ thích có những cuộc thảo luận sâu với bạn bè về
        văn chương, học thuyết, triết học, tôn giáo, văn hoá và mỹ thuật và
        thường tìm những người có chung những suy nghĩ đó để làm bạn.
      </p>

      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Tài Lộc</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Trong vấn đề tiền bạc, 1 người Kỷ Thổ trung bình hiếm khi có thể quyết
        định được kế hoạch đầu tư tài chính nào để bám vào. Do vậy, họ thường dễ
        bị lạc lối. Họ không có chính kiến riêng cho những vấn đề đó và sẽ nhanh
        chóng thay đổi quan niệm của họ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nhưng vì họ nhìn chung không quá để tâm đến chuyện tiền bạc, một khi họ
        đã quyết định một kế hoạch thì họ sẽ không thể dễ dàng thay đổi suy nghĩ
        đó.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Vì thiếu ý kiến độc lập nên họ thường lắng nghe ý kiến từ người khác và
        sẽ dễ bị thuyết phục tới lui. Họ thường xuyên làm theo một cách mù quáng
        những gì người khác làm mà không hề cân nhắc xem họ đang làm cái gì. Nếu
        như họ đã lún sâu vào họ cũng sẽ khó mà thoát ra được.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Hoàn cảnh đương nhiên sẽ khác nhau đối với người Kỷ Thổ vượng. Người Kỷ
        Thổ mạnh mẽ đã có ý định chú trọng vào tương lai. Họ suy nghĩ và lập kế
        hoạch theo những hướng đi mà họ có thể tiến lên phía trước để đạt được
        thành công.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ cũng luôn tìm đường để phát triển hoặc làm giàu nhiều nhất có thể. Họ
        thích đồng tiền phục vụ chăm chỉ cho họ và điều đó sẽ giúp họ có động
        lực để cố gắng từ ban đầu. Họ không phải kiểu người lãng phí tiền của và
        rồi thắc mắc chuyện gì đã xảy ra với số tiền đó.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ sẽ bắt đầu xây dựng và lập kế hoạch cho hồ sơ tài chính của họ từ khi
        còn trẻ. Họ thường không để phí bất kì cơ hội nào và sẽ đầu tư lâu dài
        vào việc kinh doanh nếu nó đem lại cho họ thu nhập và lợi nhuận đáng kể.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi chọn kế hoạch tài chính hoặc đầu tư, người Quý Thuỷ sẽ chọn những
        người thường không gặp rủi ro và có tiềm năng phát triển lâu dài. Họ
        thường không hứng thú với những khía cạnh rủi ro trong đầu tư và rất cẩn
        trọng về cách họ giải quyết tiền bạc của họ. Họ không thích ‘chơi đùa’
        với tiền bạc và kiếm tiền một cách dễ dàng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu họ tự mình lên kế hoạch cho tài chính họ sẽ chọn con đường mà được
        thử nghiệm nhiều lần như là của cải và cổ phiếu blue chip. Những con
        đường đầu tư này sẽ đảm bảo được tiền lời ổn định nhưng có thể sẽ không
        đem lại lợi lộc bất ngờ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Kỷ Thổ thường thích tiết kiệm phòng khi sa cơ. Và đó sẽ là cách tốt hơn
        là để cho đồng tiền làm việc cho họ trong lúc họ ngủ. Kỷ Thổ, giống như
        đất thích nuôi dưỡng cây cối. Điều này nghĩa là họ thích làm giàu, chậm
        mà chắc. Họ sẽ không chọn cách làm giàu nhanh chóng. Nhưng những chiến
        lược đầu tư lâu dài thường sẽ thu hút họ.
      </p>
    </div>
  );
};
