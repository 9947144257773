export const BoSungHanhHoa = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
            fontWeight: "bold",
          }}
        >
          Bổ Sung Hành Hoả Cho Người Xem Hoả Là Hỉ Dụng Thần
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          <strong
            id="docs-internal-guid-cf315220-7fff-acd0-3994-90dab34e9e10"
            style={{ fontWeight: "normal" }}
          >
            &nbsp;
          </strong>
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Thiên:&nbsp;&nbsp;
        </span>
      </p>
      <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Chọn đại vận hoặc năm Tỵ, Ngọ hoặc Bính, Đinh để lên kế hoạch dài
              lâu 5 - 10 năm.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Trong một năm, chọn tháng mùa hè: Tỵ, Ngọ, Mùi để lên khởi sự
              những kế hoạch đã tính toán từ trước nhằm đạt thành công.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Chọn những ngày Bính Ngọ, Đinh Tỵ hoặc Đinh Mùi để khởi sự những
              công việc quan trọng.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Luôn lên kế hoạch cho những cuộc hẹn, gặp gỡ vào buổi trưa từ 9h -
              15h, tức giờ Tỵ, Ngọ và Mùi.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Buổi trưa là thời điểm nhiều may mắn, nên ăn trưa, gặp gỡ thảo
              luận kinh doanh, không nên nghỉ trưa.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Buổi sáng nên thức dậy vào từ 3h - 5h sáng để tập thể dục, thiền
              định hoặc đọc sách vì đây là giờ Trường Sinh của Hoả Khí.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Chọn địa điểm gặp gỡ, cuộc hẹn ở các quán, nhà hàng ở góc Nam tính
              từ nhà mình. Góc Nam thuộc về hành Hoả.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nên chọn địa điểm gặp gỡ là nhà hàng, quán ăn, đặc biệt nếu ăn lẩu
              hoặc đồ nướng càng tốt. Tránh chọn nơi hẹn gặp là các quán cà phê
              bờ sông, có nhiều thác nước vì Thuỷ sẽ không tốt cho Hoả.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Khi ngồi đàm phán thương lượng luôn quay lưng về phía Nam và mặt
              nhìn về Bắc để hành Hoả tiếp thêm sinh khí cho bạn từ phía
              sau.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Chọn số sim điện thoại, số nhà, số xe, hoặc căn cước, tài khoản
              ngân hàng có nhiều số 2, 7 thuộc về hành Hoả.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu mua hoặc thuê nhà chung cư, cao ốc văn phòng ưu tiên chọn
              phòng hoặc tầng có số 2, 7, 12, 17, 22, 27.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Chọn mùi hương nước hoa khói thơm đê lôi cuốn đối tác.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nên giữ cặp mắt luôn sáng rõ bằng cách ngủ đủ giấc, xoay kem chống
              thâm vùng mắt. Nếu mắt thâm quầng dù ngủ nhiều là dấu hiệu
              xấu.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Phụ nữ nên chọn son môi có màu hồng đỏ tự nhiên, tốt cho kinh
              nguyệt đều đặn tránh chọn màu nâu bầm, đen.&nbsp;
            </span>
          </p>
        </li>
      </ol>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          <strong style={{ fontWeight: "normal" }}>&nbsp;</strong>
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Địa:&nbsp;
        </span>
      </p>
      <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Tránh chọn nhà bị nước bao ôm ấp xung quanh như Thanh Đa, Đảo Kim
              Cương, Bến Tre.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nhà có mái chóp nhọn được xem là nhiều hành Hoả, nhà nhìn thấy
              phía xa xa có chùa hay nhà thờ mái nhọn là tốt. Lưu ý là nên ở xa
              chứ không nên ở gần.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Phòng ngủ đón ánh nắng mặt trời nhiều vào buổi sáng.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Phòng ngủ trang trí điểm xuyết màu hồng, đỏ, tím và vàng nhạt.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Drap giường, chăn, gối, nệm nên chọn màu hồng, đỏ,...
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nên bố trí đèn vàng trong phòng tắm và không nên bố trí bồn tắm
              nằm.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Tránh bố trí bếp gần toilet, bồn rửa vì Thuỷ Hoả tương xung.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nên bố trí đèn ngủ màu vàng trong phòng.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Khi gặp xui xẻo nên đốt các gói xông thảo mộc, trầm hương để xua
              tan âm khí.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Trồng hoa hồng, dâm bụt, treo tranh hoa mẫu đơn trong phòng khách.
              Nếu trồng hoa khác có thể cân nhắc chọn chậu hoặc lọ hoa màu
              đỏ.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu như bị đau mắt tìm xem có phải cửa sổ phía Nam của nhà đang bị
              bẩn hay không? Nếu đúng như vậy thì lau sạch sẽ khu vực cửa sổ
              phía Nam thì sẽ hết.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu bệnh về mắt thì cần kiểm tra xem có phải tại khu vực phía Nam
              của nhà mình đang có hồ nước hay đất thấp hay không. Nếu đúng như
              vậy thì nên lấp đất và tôn cho nền đất cao lên sẽ tốt.&nbsp;
            </span>
          </p>
        </li>
      </ol>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          <strong style={{ fontWeight: "normal" }}>&nbsp;</strong>
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Nhân:&nbsp;
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Trang Phục / Ngoại Hình:&nbsp;
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          <strong style={{ fontWeight: "normal" }}>&nbsp;</strong>
        </span>
      </p>
      <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Quấn áo màu đỏ
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Quần áo của hãng Polo là lý tưởng vì có logo ngựa.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Chọn điện thoại màu đỏ, hoặc ốp lưng điện thoại màu đỏ. Vì điện
              thoại là thuộc Hoả và thường xuyên mang theo bên người.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nhuộm tóc đỏ, để móng tay sơn màu đỏ là dùng Mộc sinh Hoả. Nhất là
              phần đầu có huyệt Bách Hội giúp hấp thu Hoả Khí.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu ngại nhuộm tóc đỏ có thể dùng kẹp tóc, băng đô buộc tóc màu đỏ
              để gia tăng may mắn.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Treo biểu tượng cát tường màu đỏ trong xe hơi hoặc làm móc khoá
              điện thoại.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              San hô đỏ mang tính Hoả rất tốt để mang theo bên người.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Chọn đeo vòng tay bằng đá núi lửa&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Gọng mắt kính màu đỏ cũng sẽ giúp bổ sung thêm hành Hoả cho
              mắt.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Chọn ví bóp tiền màu đỏ hoặc cho 1 phong bì lì xì màu đỏ nhỏ vào
              trong bóp ví để tăng cường may mắn về tài lộc.&nbsp;
            </span>
          </p>
        </li>
      </ol>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          <strong style={{ fontWeight: "normal" }}>&nbsp;</strong>
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Ẩm Thực&nbsp;
        </span>
      </p>
      <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Hút thuốc hoặc xông thuốc như ngải cứu.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Uống rượu vang đỏ
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Ăn 1 ít ớt, tỏi, tiêu gừng, gia vị cay nóng là bổ sung hành Hoả.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Thỉnh thoảng ăn đồ nướng hoặc mì cay cũng rất tốt. Tuy nhiên cần
              chú ý tránh đồ nướng thành than vì sinh ra Thổ bất lợi.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Ăn tim, tiết lợn thì hành Hoả,
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Ăn thịt dê, khô bò, rắn, ngựa là Hoả
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Trái cây như táo đỏ, vải thiều hoặc long nhãn cũng có tính
              Hoả.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Có thể sử dụng thực phẩm chức năng như COQ-10 để tăng cường Hoả
              tính cho tim nếu sức khoẻ tim mạch yếu.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu mắt yếu, mờ là biểu thị hành Hoả yếu nên đi khám bác sĩ ngay
              hoặc sử dụng các loại nước nhỏ mắt để giữ mắt sạch sẽ không bụi
              bẩn.&nbsp;
            </span>
          </p>
        </li>
      </ol>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          <strong style={{ fontWeight: "normal" }}>&nbsp;</strong>
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Động Vật / Vật Phẩm:&nbsp;
        </span>
      </p>
      <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nuôi chó là tốt.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nuôi mèo cũng tốt nhưng mèo là âm nên không tốt nhiều bằng.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu có điều kiện nên thường xuyên đến sở thú, thảo cầm viên có
              chuồng ngựa hoặc tập cưỡi ngựa.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Xe hơi có nội thất hoặc lớp sơn ngoài màu đỏ, hoặc thuộc nhãn hiệu
              có logo ngựa như Ferrari.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Ở gần nơi nuôi nhiều ngựa là tốt.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Đi xem đua chó, đua ngựa như Đại Nam, trường đua Phú Thọ là tốt.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Trong nhà treo tranh ảnh có hình Mã Đáo Thành Công gồm 8 con ngựa.
              Tránh treo ảnh 4, 5 con ngựa vì là Tứ , Ngũ Mã Phanh Thây.
            </span>
          </p>
        </li>
      </ol>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          <strong style={{ fontWeight: "normal" }}>&nbsp;</strong>
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Thói Quen Tốt:&nbsp;
        </span>
      </p>
      <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Đi du lịch về phía Nam: miền Nam, Sài Gòn, Phú Quốc, Tây Ninh, núi
              Bà Đen.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Đi du lịch nước ngoài: đến Malaysia,Singapore, Úc, bang Texas (Hoa
              Kỳ)
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Thắp hương, đi chùa lễ lạt, đốt hương tại ban thờ, tránh để ban
              thờ lạnh lẽo.&nbsp;&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Để đèn ngủ ban đêm và sạc điện thoại xa giường ngủ.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Trong phòng bếp nên dùng bếp gas.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nên vào bếp nấu nước thường xuyên.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nên đem theo bật lửa mảu đỏ, cục sạc di động bên người khi đi ra
              ngoài.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nến tắm đứng và không nên ngâm bồn nước quá lâu.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu thích làn da nâu có thể nhuộm da, phơi nắng.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu mệt mỏi trong người có thể đi xông hơi hoặc giác hơi.&nbsp;
            </span>
          </p>
        </li>
      </ol>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          <strong style={{ fontWeight: "normal" }}>&nbsp;</strong>
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Tìm Quý Nhân:
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        &nbsp;
      </p>
      <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Tìm đến quý nhân nhiều Hoả: người sinh tháng Tỵ, Ngọ, Mùi, trong
              lá số có Dần Ngọ Tuất, có Tỵ Ngọ Mùi, có ngày sinh Bính, Đinh
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Tìm đến bạn bè có cặp mắt to tròn, khuôn mặt có hành Hoả (trán
              hẹp, cằm rộng).
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Kết giao với bạn bè, khách hàng, đối tác ở miền Nam Việt Nam,
              Singapore, Malaysia, Indonesia, Úc.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Theo dõi thần tượng những nghệ sĩ, doanh nhân có lá số nhiều
              Hoả:&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Có thể mở nhà hàng, quán ăn hoặc đầu tư vào những cổ phiếu ngành
              nhà hàng, chuỗi thức ăn nhanh.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Khi gặp khó khăn, mệt mỏi có thể thắp 1 nén nhang tại ban thờ ở
              nhà, đi đến đình chùa, sẽ tự khắc tìm được quý nhân.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Ngồi thiền theo tư thế Hoa Sen trước ban thờ để cầu nguyện. Tư thế
              này khiến cho đỉnh đầu và 2 gối tạo thành thế Tam Giác vững chãi
              (Tam giác thuộc hành Hoả) giúp cho dễ tìm được cảm giác bình yên
              và cầu nguyện.&nbsp;
            </span>
          </p>
        </li>
      </ol>
    </div>
  );
};
