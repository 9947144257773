export const GiapMoc = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "70%", margin: "0 auto" }}>
        <h1 style={{ textAlign: "center", color: "green", fontWeight: "bold" }}>
          Chương I: Giáp Mộc <br /> Ngày Sinh Giáp Mộc
        </h1>
      </div>

      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Dụng Thần Điều Hậu</b>
      </p>

      <p style={{ textAlign: "left", padding: "1rem" }}>
        <b> • Sinh Vào Mùa Xuân</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Dần: 05/02 – 05/03
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Bính Hỏa, Quý Thủy
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Mão: 06/03 – 04/04
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Canh Kim, Mậu Thổ, Bính Hỏa, Kỷ Thổ, Đinh Hỏa
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Thìn: 05/04 – 05/05
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Canh Kim, Nhâm Thủy, Đinh Hỏa
      </p>

      <p style={{ textAlign: "left", padding: "1rem" }}>
        <b> • Sinh Vào Mùa Hạ:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Tỵ: 06/05 – 05/06
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Quý Thủy, Canh Kim, Đinh Hỏa
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Ngọ: 06/06 – 07/07
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Quý Thủy, Canh Kim, Đinh Hỏa
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Mùi: 08/07 – 07/08
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Quý Thủy, Canh Kim, Đinh Hỏa
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b>• Sinh Vào Mùa Thu: </b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Thân: 08/08 – 07/09
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Canh Kim, Nhâm Thủy, Đinh Hỏa
      </p>

      <p style={{ textAlign: "left", padding: "1rem" }}>
        • Tháng Dậu: 08/09 – 08/10
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Canh Kim, Bính Hỏa, Đinh Hỏa
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Tuất: 09/10 – 07/11
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Canh Kim, Nhâm Quý, Giáp Mộc, Quý Thủy, Đinh Hỏa
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b>• Sinh Vào Mùa Đông:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Hợi: 08/11 – 07/12
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Bính Hỏa, Canh Kim, Mậu Thổ, Đinh Hỏa
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Tý: 08/12 – 05/01
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Đinh Hỏa, Bính Hỏa, Canh Kim
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Sửu: 06/01 – 03/02
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Đinh Hỏa, Bính Hỏa, Canh Kim
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Lưu ý: Các bạn cần lưu ý những dụng thần điều hậu phía trên chỉ mang
        tính chất tham khảo. Nếu cần biết thêm về các dụng thần điều hậu, cũng
        như các dụng thần khác trong lá số bát tự bạn cần tham khảo hoặc tư vấn
        từ các chuyên gia.
      </p>

      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Hình Tượng</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người có ngày sinh Giáp thường có tính cách như một cây to lớn, cứng
        chắc, vươn cao từ từ như một cây cổ thụ. Họ có tính cách kiên trì và
        nhẫn nại.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ cũng thẳng thắn và không ngại nói đụng chạm đến ai. Họ sẵn lòng gọi
        đúng tên bản chất sự vật sự việc dù cho nó có làm người nào khác đau
        lòng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ cũng là những người thích suy nghĩ lớn và thường thích nghĩ tầm nhìn
        dài hạn. Họ không sẵn lòng làm hài lòng, dĩ hoà vi quý với người khác
        nếu họ thật sự không đồng ý về quan điểm. Mặc dù điều này được xem như
        là tính cách tích cực, người sinh ngày Giáp thường đi theo tiêu chuẩn
        đạo đức, lý tưởng của bản thân hơn là theo số đông xã hội. Người Giáp
        Mộc cũng quan tâm và có lòng nhân từ với người khác nếu như họ đủ thân
        thiết với bạn, họ sẽ bộc lộ lòng nhân ái của họ cho bạn thấy rõ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ thuộc tuýp người làm nhiều nhưng không hay khoe và có sự cuốn hút
        riêng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ cũng khá cứng đầu, ngoan cố sẵn lòng tranh luận và cố bảo vệ quan
        điểm của mình đến cùng. Họ thiếu sự khách quan trong mọi việc. Họ cũng
        thiếu khả năng xét đoán các quyết định của mình hay khả năng nhìn nhận
        sự việc từ quan điểm của người khác. Khi họ hỏi xin ý kiến của người
        khác, họ muốn nghe thấy ý kiến quan điểm của người khác phải giống với
        quan điểm của họ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ có thể hơi ép buộc người khác và muốn mọi việc phải theo cách làm của
        mình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Vì họ khá cứng đầu nên điều này có cả ưu lẫn nhược điểm. Họ cần phải hết
        sức nỗ lực mới có thể thay đổi được quan điểm của chính mình, và phải
        mất nhiều thời gian.
      </p>

      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Tính Cách</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Chính vì quá cứng rắn nên khi gặp giông bão, khó khăn họ thường muốn tự
        mình đương đầu chứ không nhờ đến sự giúp đỡ từ bên ngoài. Đây là điểm
        yếu vì đôi khi cần phải linh hoạt và thừa nhận điểm yếu của mình để nhờ
        sự trợ giúp từ người xung quanh. Kết quả là nếu họ không vượt qua được
        bão tố thì họ sẽ bị gục ngã và rất khó gượng dậy vì cảm giác bị mất mặt.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Giáp Mộc luôn muốn vươn cao và luôn nói thẳng, nói thật mà không e
        ngại hay muốn gỉả tạo, ăn nói khéo léo để lấy lòng người khác.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Chính bởi điều này mà người hay nhìn nhận người Giáp Mộc là người thiếu
        nhạy cảm hay vô tâm. Mặc dù đôi khi chính là điều ngược lại vì người
        Giáp Mộc thường có lòng nhân từ và họ ít khi nói ra điều gì độc ác mà
        chỉ đơn giản vì họ quá thẳng thắn. Nếu bạn muốn nghe một sự thật, thô mà
        không màu mè, hoa mỹ thì nên hỏi một người ngày sinh Giáp mộc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Giáp Mộc luôn thích giúp đỡ người đang gặp khó khăn. Tựa như một
        cái cây vươn cành lá ra xung quanh để che chở cho người cô thế, người
        Giáp Mộc thích giúp đỡ người yếu hơn mình. Tuy vậy, cũng như trong rừng
        cây, nếu như là cây cao nhất họ dễ bị hạ bệ, do đó họ nên xếp hạng thứ
        hai sẽ tốt hơn là đứng đầu. Điều này nghĩa là họ có thể leo cao, nhưng
        đến một mức độ nào đó chứ không phải là cao nhất. Nhưng người Giáp Mộc
        thường cũng không ngại vì họ thuộc tuýp người trung thành, không thích
        đấu đá hay qua cầu rút ván, vắt chanh bỏ vỏ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Giáp Mộc tựa như một cây cao, thường thích suy nghĩ toàn diện,
        nhìn bức tranh lớn tổng quát hơn là chi tiết. Họ có khả năng nắm bắt mọi
        mặt của một vấn đề chính một cách nhanh chóng. Họ có khả năng tưởng
        tượng và hình dung ra bức tranh tổng quát. Tuy nhiên, khả năng tập trung
        suy nghĩ về từng khía cạnh nhỏ của bức tranh lại không phải là thế mạnh
        của họ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi đối mặt với những vấn đề chi tiết và nhỏ nhặt, người Giáp Mộc thường
        dễ mất tập trung. Họ không có sự bền bỉ tinh thần và ý chí để phân tích
        từng chi tiết nhỏ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Trong khi hướng đến những vấn đề tổng quát, người Giáp Mộc thường hay
        suy nghĩ chủ quan, một chiều và có thể bỏ nhiều thời gian vào những vấn
        đề vĩ mô nhưng ít quan trọng bằng tiểu tiết.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Giáp Mộc thường suy nghĩ với tốc độ nhanh chóng và thường ra quyết
        định vội vã vì họ tin rằng thà quyết định gì đó sai còn hơn là không ra
        một quyết định nào. Do đó, họ thường ra quyết định nhanh, không chần chừ
        hay suy nghĩ thật kỹ lưỡng, vì vậy có thể đôi khi họ quyết định sai lầm
        vì không kiểm chứng một vài thông tin nghe đồn. Vì vậy nếu vào thời vận
        xấu, trực giác kém, họ có thể cảm thấy mệt mỏi về thể chất và tinh thần
        mà không đạt được thành tích nào cụ thể.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Thường thì những quyết định vội vàng có nhược điểm là nó thường được đưa
        ra vào lúc khẩn cấp, sự giận dữ, tham lam hay sợ hãi và do đó có thể là
        quyết định sai lầm. Điều tồi tệ hơn là một khi ra quyết định sai, họ
        thường không sửa đổi quyết định ban đầu của mình mà cứ để mặc nó.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Giáp Mộc thường thích tìm những cách giải thích hợp với logic mà
        họ nghĩ trong đầu để bảo vệ cho lý luận của riêng họ. Họ thường không
        suy nghĩ thật thấu đáo mà cố thuyết phục người khác nghe theo lập luận
        của mình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Giáp Mộc có thể tỏ ra lắng nghe bạn bè hay đồng nghiệp góp ý vì
        lịch sự nhưng họ không dễ bị thuyết phục hay chỉ sử dụng những góp ý nào
        hợp với bản thân mình thôi.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nhìn chung, người Giáp Mộc có thể là những chuyên gia huấn luyện hay
        giáo viên tuyệt với vì họ giỏi trình bày suy nghĩ trong đầu họ cho người
        khác và hướng dẫn người khác cách thức đúng. Họ có tài giảng dạy và khả
        năng ăn nói lưu loát.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Giáp Mộc thường dễ nổi nóng bất ngờ vì đôi khi sự tự tin của họ
        quá đà. Đôi khi sự tức giận của người Giáp Mộc là vì họ cảm thấy họ
        thiếu sự kiểm soát và cảm thấy mình bất lực trước một tình huống khó
        khăn. Sự nổi giận cho họ một cảm giác giả tạo về việc mình có khả năng
        kiểm soát tình huống và có ít nhiều sự tự tin. Họ cần phải sống chậm lại
        và bình tĩnh trước khi ra một quyết định nào đó.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Giáp Mộc thường rất kiên trì, mặc cho mưa gió bão bùng thì họ luôn
        học hỏi, vươn lên cao. Giống như rễ cây thường đâm sâu, người khác
        thường không nhận ra người Giáp Mộc đã phát triển đến đâu cho đến một
        ngày họ chứng tỏ sự vượt trội bất ngờ của mình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ thích ăn mặc đẹp, chăm chút cho vẻ ngoài để tôn lên sự tự tin của
        chính mình.
      </p>

      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Mối Quan Hệ/ Tình Cảm</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ cũng giỏi trong việc phê bình, vạch lá tìm sâu trong xem xét những
        sai lầm của người khác. Đôi khi có thể họ không tìm ra được lỗi của mình
        trong khi lại rất giỏi chỉ ra sai lầm của người khác. Vì vậy người xung
        quanh đôi khi ngại chia sẻ công việc với người Giáp Mộc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Giáp Mộc đặc biệt yêu thích tiệc tùng và giao tiếp xã hội, nhất là
        trong nhóm quan hệ mà họ cho là cùng đẳng cấp. Người Giáp Mộc cũng dễ
        rơi vào những mối quan hệ tình cảm hay tình dục không lành mạnh, nhất là
        vì họ luôn muốn khoe vẻ gợi cảm của mình với cả thế giới.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Do đó, người Giáp Mộc cần phải hết sức thận trọng không mù quáng khi
        quan hệ tình cảm với những người có tiền bạc, địa vị mà hi sinh đi
        nguyên tắc sống của bản thân.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Giáp Mộc không dễ bộc lộ hay chia sẻ cảm xúc của mình mà thường
        che giấu tình cảm bên trong mình. Họ ít khi tìm được người bạn đáng tin
        cậy và họ thường chỉ giữ bên mình những mối quan hệ lợi ích tương hỗ về
        sự nghiệp. Họ không thuộc tuýp người sẵn lòng chia sẻ những góc khuất về
        tình cảm của họ ngay cả đối với người thân yêu nhất.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Giáp Mộc thường chú trọng rất nhiều đến giá trị vật chất và tiền
        bạc, nhưng họ thường quản lý tiền bạc cẩu thả chứ ít khi tỉ mỉ hay tính
        toán. Họ không thuộc tuýp người thích cầm theo máy tính kiểm tra sổ sách
        số liệu
      </p>

      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Sự Nghiệp/ Công Việc</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Giáp Mộc thường rất chú trọng đến sự nghiệp, địa vị là chính mặc
        dù có thể không quá cần tiền lương cao. Ngược lại, nếu giao cho họ một
        vị trí chức vụ thấp thì họ sẽ hình thành tâm lý thấp kém và từ đó sai
        lầm trong hành động. Chức danh công việc rất quan trọng đối với bản thân
        họ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Giáp Mộc không phải tuýp người nhảy việc mà họ thường chú tâm vào
        một sự nghiệp, một công việc miễn là nó thoả mãn tiêu chí về địa vị và
        tiền bạc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngũ hành Mộc là hành duy nhất luôn phát triển theo chiều thẳng đứng. Họ
        thường không dựa dẫm vào người khác mà tự học hỏi vươn lên, Tuy nhiên,
        họ lại không đủ linh hoạt, nhanh nhạy để thay đổi quan điểm và phương
        pháp làm việc. Đôi khi chính việc cứng nhắc và thiếu kiên nhẫn sẽ gây
        hại đến công việc của họ. Họ cũng thường không xem trọng những người làm
        việc với tốc độ chậm chạp.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Do đó, đôi khi trong công việc họ thường không nghe lời khuyên của đồng
        nghiệp về việc thay đổi hệ thống hay góc nhìn khác lạ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Giáp Mộc thường không phải là những lãnh đạo tốt. Họ thường điều
        chỉnh phong cách lãnh đạo của mình tuỳ theo tình huống, và đôi khi quá
        tuỳ tiện. Khi công ty kinh doanh tốt và có lợi nhuận, họ thường có xu
        hướng khen thưởng nhân viên hào phóng. Nhưng khi công ty kinh doanh kém
        và lợi nhuận ít, họ thường tính toán tủn mủn và không sẵn lòng mở hầu
        bao.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi môi trường làm việc không tốt, họ thường không chịu nỗ lực của vun
        đắp quan hệ tình cảm giữa nhân viên và sếp mà thường chủ quan.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Giáp Mộc thường không phải thuộc tuýp người quản lý hay lãnh đạo
        thân thiên với nhân viên. Trong nhiều trường hợp, họ thường thuộc tuýp
        vắt chanh bỏ vỏ. Nếu như họ tỏ ra thân thiện với một nhân viên nào, thì
        thường là do họ đang cần người đó. Nếu người Giáp Mộc cần nhân viên đóng
        góp thì người đó sẽ tỏ ra cực kỳ ấm áp.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nhưng nếu họ không thấy người nào đóng góp tốt, họ sẽ cư xử như người
        lạ. Điều này sẽ khiến người xung quanh xem người Giáp Mộc như là tuýp
        người cơ hội.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Giáp Mộc thường có sốt ruột và nôn nóng thành công. Họ thường chọn
        con đường nào ngắn gọn mà ít khi kiên nhẫn chờ đợi những thành quả chậm
        chạp.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ thường dựa nhiều vào mọi tình huống hiện tại mà ít khi chịu đi đầu,
        tiên phong hay có sáng kiến mới. Họ thường có góc nhìn tiên phong nhưng
        lại ít khi chịu bắt tay vào làm những gì cách mạng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ tham vọng và có động lực tìm kiếm sự phát triển nhưng họ ít khi sáng
        tạo một thứ gì hoàn toàn mới mẻ và mang tính cách mạng.
      </p>

      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Kinh Doanh</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu một người Giáp Mộc làm công việc kinh doanh, tiếp thị, họ thường hay
        “thúc đẩy” khách hàng bằng cách gia tăng áp lực. Người Giáp Mộc không
        chấp nhận cho khách hàng thời gian suy nghĩ mà thường hối thúc vì Giáp
        Mộc không có thời gian hay sự kiên nhẫn dùng những cách thức tinh tế hay
        thuyết phục nhẹ nhàng. Họ muốn bán hàng trong vài phút chứ ít khi chịu
        đàm phán thương lượng hay kiên nhẫn thuyết phục khách hàng trong nhiều
        ngày.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Giáp Mộc thường thích giúp đỡ người khác với điều kiện người kia
        cũng sẽ giúp đỡ ngược lại mình, trên cơ sở cả hai cùng có lợi. Trong
        công việc, họ thường chỉ giúp đỡ người mình đã quen biết để nhận được
        lợi ích thấy rõ mà ít khi giúp đỡ người mới quen vì chưa rõ lợi ích đằng
        sau.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Mặc dù không phải là người xấu, nhưng người Giáp Mộc thường có sự tính
        toán nhất định để tìm cách đầu tư thời gian, công sức hay tiền bạc vào
        trong mối quan hệ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Cũng như các cây trong rừng sẽ luôn muốn cạnh tranh xem đâu là cây cao
        nhất, người Giáp Mộc luôn muốn mình trở thành người giỏi hơn, khoẻ hơn,
        thành công hơn người khác. Mặc dù họ không bao giờ tỏ ra ghen tỵ mà luôn
        muốn tỏ ra là tử tế và lịch sự bè ngoài, họ luôn đố kỵ với người hơn
        mình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đôi khi người Giáp Mộc cũng khen ngợi, nịnh nhân viên của mình để đạt
        được một số kêt quả cụ thể, mặc dù không phải với mục đích xấu, nhưng vì
        họ luôn có tham vọng thành công. Điều này khiến cho người Giáp Mộc bị
        một số người xung quanh không ưa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ là người thích chộp lấy bất kỳ cơ hội nào và đầu tư vào những gì họ
        cho là đáng giá. Tuy nhiên vì phong cách quản lý tài chính lỏng lẻo hơn
        là một doanh nhân đúng nghĩa, họ thường đầu tư như đánh bạc dựa vào
        những gì họ nghĩ là mang lại lợi nhuận cao nhất thay vì những kế hoạch
        cẩn trọng chậm nhưng dài lâu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ thích lướt sóng cổ phiếu ngắn hạn hơn là đầu tư nghiêm túc lâu dài
        với sự tính toán nghiên cứu cẩn thận.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ thường khá cứng đầu không chịu lắng nghe lời khuyên về tài chính từ
        người khác. Họ thích theo những kế hoạch mà bản thân họ nghĩ là tốt.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Bởi vì họ ít khi nghiên cứu cẩn trọng, do đó họ thường bỏ qua những lời
        khuyên có giá trị và rốt cuộc là thua lỗ nhiều hơn. Trong nhiều tình
        huống, họ dễ rơi vào nợ nần lớn.
      </p>

      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Tài Lộc</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Giáp Mộc rất thích sở hữu nhiều tiền bạc và hấp dẫn giới tính. Nếu
        tình trạng tài chính phát triển, họ sẽ càng ngày tự tin hơn. Tuy nhiên,
        nếu họ nghèo thì họ sẽ cảm thấy tự ti, thấp kém ngay cả nếu họ thông
        minh, có năng lực.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Giáp Mộc thường thực dụng và sống nguyên tắc, cũng như họ thường
        không theo đuổi niềm tin tâm linh hay tôn giáo cực đoan. Họ thường đặt
        niềm tin vào chính mình hơn là vào tôn giáo
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tuy nhiên, sự tự tin của họ chỉ ở đỉnh cao khi cuộc sống suôn sẻ, thuận
        lợi. Nếu họ đối mặt với thất bại hay trở ngại thì họ dễ mất tự tin.
        Người Giáp Mộc thường không có sự tự tin vững chắc trừ khi hoàn cảnh bên
        ngoài đúng như mong đợi của họ.
      </p>
    </div>
  );
};
