export const convertTieuNhiMonth = (chiHour, Month) => {

    if (chiHour === "Sửu") {
        return ["Điểu Phi", "Dạ Đề"]
    }
    else if (chiHour === "Tý" && Month === 1 || chiHour === "Tý" && Month === 2 || chiHour === "Tý" && Month === 3) {
        return [
            "Tướng Quân",
            "Dạ Đề",
            "Hạ Tình",
            "Cấp Cước"
        ]
    } else if (chiHour === "Tý" && Month === 4 || chiHour === "Tý" && Month === 5 || chiHour === "Tý" && Month === 6 || chiHour === "Tý" && Month === 11) {
        return [
            "Tướng Quân",
            "Dạ Đề"
        ]
    } else if (chiHour === "Tý" && Month === 7 || chiHour === "Tý" && Month === 8 || chiHour === "Tý" && Month === 9 || chiHour === "Tý" && Month === 12) {
        return [
            "Diêm Vương",
            "Tướng Quân",
            "Dạ Đề"
        ]
    }



    else if (chiHour === "Dần" && Month === 1) {
        return ["Tứ Quý", "Thâm Thúy", "Bách Nhật", "Lôi Công", "Đoạn Kiều", "Hạ Tình"]
    } else if (chiHour === "Dần" && Month === 2) {
        return [
            "Tứ Quý",
            "Thâm Thúy",
            "Lôi Công",
            "Đoạn Kiều",
            "Hạ Tình"
        ]
    } else if (chiHour === "Dần" && Month === 3) {
        return [
            "Tứ Quý",
            "Thâm Thúy",
            "Lôi Công",
            "Hạ Tình"
        ]
    } else if (chiHour === "Dần" && Month === 4 || chiHour === "Dần" && Month === 5 || chiHour === "Dần" && Month === 6 || chiHour === "Dần" && Month === 11) {
        return [
            "Lôi Công"
        ]
    } else if (chiHour === "Dần" && Month === 7 || chiHour === "Dần" && Month === 8 || chiHour === "Dần" && Month === 9 || chiHour === "Dần" && Month === 10) {
        return [
            "Diêm Vương",
            "Lôi Công"
        ]
    } else if (chiHour === "Dần" && Month === 12) {
        return [
            "Diêm Vương",
            "Lôi Công"
        ]
    } else if (chiHour === "Mão" && Month === 1 || chiHour === "Mão" && Month === 2) {
        return [
            "Kim Tỏa",
            "Lạc Tỉnh",
            "Đoạn Kiều"
        ]
    } else if (chiHour === "Mão" && Month === 3 || chiHour === "Mão" && Month === 4 || chiHour === "Mão" && Month === 5 || chiHour === "Mão" && Month === 6 || chiHour === "Mão" && Month === 11) {
        return [
            "Lạc Tỉnh"
        ]
    } else if (chiHour === "Mão" && Month === 7 || chiHour === "Mão" && Month === 8 || chiHour === "Mão" && Month === 9 || chiHour === "Mão" && Month === 10 || chiHour === "Mão" && Month === 12) {
        return [
            "Diêm Vương",
            "Lạc Tỉnh"
        ]
    } else if (chiHour === "Thìn" && Month === 1 || chiHour === "Thìn" && Month === 2 || chiHour === "Thìn" && Month === 3) {
        return [
            "Tứ Quý",
            "Tướng Quân",
            "Điểu Phi"
        ]
    } else if (chiHour === "Thìn" && Month === 4 || chiHour === "Thìn" && Month === 5 || chiHour === "Thìn" && Month === 6 || chiHour === "Thìn" && Month === 7 || chiHour === "Thìn" && Month === 8 || chiHour === "Thìn" && Month === 9 || chiHour === "Thìn" && Month === 10 || chiHour === "Thìn" && Month === 11 || chiHour === "Thìn" && Month === 12) {
        return [
            "Tướng Quân",
            "Điểu Phi"
        ]
    } else if (chiHour === "Tỵ" && Month === 1) {
        return [
            "Lạc Tỉnh",
            "Bách Nhật"
        ]
    } else if (chiHour === "Tỵ" && Month === 2 || chiHour === "Tỵ" && Month === 3 || chiHour === "Tỵ" && Month === 4 || chiHour === "Tỵ" && Month === 5 || chiHour === "Tỵ" && Month === 6 || chiHour === "Tỵ" && Month === 7 || chiHour === "Tỵ" && Month === 8 || chiHour === "Tỵ" && Month === 9 || chiHour === "Tỵ" && Month === 10 || chiHour === "Tỵ" && Month === 11 || chiHour === "Tỵ" && Month === 12) {
        return [
            "Lạc Tỉnh"
        ]
    } else if (chiHour === "Ngọ" && Month === 1 || chiHour === "Ngọ" && Month === 2 || chiHour === "Ngọ" && Month === 3 || chiHour === "Ngọ" && Month === 4 || chiHour === "Ngọ" && Month === 5 || chiHour === "Ngọ" && Month === 6 || chiHour === "Ngọ" && Month === 11) {
        return [
            "Lạc Tỉnh",
            "Lôi Công",
            "Dạ Đề"
        ]
    } else if (chiHour === "Ngọ" && Month === 7 || chiHour === "Ngọ" && Month === 8 || chiHour === "Ngọ" && Month === 9 || chiHour === "Ngọ" && Month === 10 || chiHour === "Ngọ" && Month === 12) {
        return [
            "Diêm Vương",
            "Lạc Tỉnh",
            "Lôi Công",
            "Dạ Đề"
        ]
    } else if (chiHour === "Mùi" && Month === 1 || chiHour === "Mùi" && Month === 2 || chiHour === "Mùi" && Month === 3) {
        return [
            "Lôi Công",
            "Điểu Phi",
            "Dạ Đề",
            "Thủy Hỏa"
        ]
    } else if (chiHour === "Mùi" && Month === 4 || chiHour === "Mùi" && Month === 5 || chiHour === "Mùi" && Month === 6 || chiHour === "Mùi" && Month === 7 || chiHour === "Mùi" && Month === 8 || chiHour === "Mùi" && Month === 9 || chiHour === "Mùi" && Month === 10 || chiHour === "Mùi" && Month === 11 || chiHour === "Mùi" && Month === 12) {
        return [
            "Lôi Công",
            "Điểu Phi",
            "Dạ Đề",
        ]
    } else if (chiHour === "Thân" && Month === 1 || chiHour === "Thân" && Month === 2) {
        return [
            "Kim Tỏa",
            "Lạc Tỉnh",
            "Thâm Thúy",
            "Dục Bồn",
            "Lôi Công"
        ]
    } else if (chiHour === "Thân" && Month === 3) {
        return [
            "Lạc Tỉnh",
            "Thâm Thúy",
            "Dục Bồn",
            "Lôi Công"
        ]

    } else if (chiHour === "Thân" && Month === 4 || chiHour === "Thân" && Month === 5 || chiHour === "Thân" && Month === 5 || chiHour === "Thân" && Month === 6 || chiHour === "Thân" && Month === 7 || chiHour === "Thân" && Month === 8 || chiHour === "Thân" && Month === 9 || chiHour === "Thân" && Month === 10 || chiHour === "Thân" && Month === 11 || chiHour === "Thân" && Month === 12) {
        return [
            "Lạc Tỉnh",
            "Lôi Công"
        ]
    } else if (chiHour === "Dậu") {
        return [
            "Lôi Công",
            "Tướng Quân"
        ]
    } else if (chiHour === "Tuất" && Month === 1 || chiHour === "Tuất" && Month === 2 || chiHour === "Tuất" && Month === 3) {
        return [
            "Lạc Tỉnh",
            "Tướng Quân",
            "Điểu Phi",
            "Thủy Hỏa"
        ]
    } else if (chiHour === "Tuất" && Month === 4 || chiHour === "Tuất" && Month === 5 || chiHour === "Tuất" && Month === 5 || chiHour === "Tuất" && Month === 6 || chiHour === "Tuất" && Month === 7 || chiHour === "Tuất" && Month === 8 || chiHour === "Tuất" && Month === 9 || chiHour === "Tuất" && Month === 10 || chiHour === "Tuất" && Month === 11 || chiHour === "Tuất" && Month === 12) {
        return [
            "Lạc Tỉnh",
            "Tướng Quân",
            "Điểu Phi",
        ]
    } else if (chiHour === "Hợi" && Month === 1 || chiHour === "Hợi" && Month === 2 || chiHour === "Hợi" && Month === 3) {
        return [
            "Lôi Công",
            "Cấp Cước"
        ]
    } else if (chiHour === "Hợi" && Month === 4 || chiHour === "Hợi" && Month === 5 || chiHour === "Hợi" && Month === 5 || chiHour === "Hợi" && Month === 6 || chiHour === "Hợi" && Month === 7 || chiHour === "Hợi" && Month === 8 || chiHour === "Hợi" && Month === 9 || chiHour === "Hợi" && Month === 10 || chiHour === "Hợi" && Month === 11 || chiHour === "Hợi" && Month === 12) {
        return [
            "Lôi Công",
        ]
    }

}

export const convertTieuNhiYear = (chiHour, chiYear, CanYear) => {

    const arr = []
    if (chiHour === "Tý") {
        if (CanYear === "Giáp" && chiYear === "Tý" || CanYear === "Bính" && chiYear === "Tý" || CanYear === "Mậu" && chiYear === "Tý") {
            return arr.push("Quỹ Môn Quan")
        }
        return arr
    }
    else if (chiHour === "Sửu") {
        if (chiYear === "Tý") arr.push("Hòa Thượng")
        if (chiYear === "Mão") arr.push("Hòa Thượng")
        if (chiYear === "Ngọ") arr.push("Hòa Thượng")
        if (chiYear === "Dậu") arr.push("Hòa Thượng")
        if (CanYear === "Giáp" && chiYear === "Tý" || CanYear === "Bính" && chiYear === "Tý" || CanYear === "Mậu" && chiYear === "Tý") {
            arr.push("Quỹ Môn Quan")
        }
        return arr
    }
    else if (chiHour === "Dần") {
        if (chiYear === "Ngọ") arr.push("Thiên Nhật")
        if (CanYear === "Nhâm" && chiYear === "Tý" || CanYear === "Canh" && chiYear === "Tý" || CanYear === "Bính" && chiYear === "Tý" || CanYear === "Mậu" && chiYear === "Dần") {
            arr.push("Ngũ Quỷ")
        }
        if (CanYear === "Giáp" && chiYear === "Tý" || CanYear === "Bính" && chiYear === "Tý" || CanYear === "Mậu" && chiYear === "Tý") {
            arr.push("Quỹ Môn Quan")
        }
        return arr
    }
    else if (chiHour === "Mão") {
        if (CanYear === "Giáp" && chiYear === "Tý" || CanYear === "Bính" && chiYear === "Tý" || CanYear === "Mậu" && chiYear === "Tý") {
            arr.push("Quỹ Môn Quan")
        }
        return arr
    }
    else if (chiHour === "Thìn") {
        if (chiYear === "Tý") arr.push("Hòa Thượng")
        if (chiYear === "Dần") arr.push("Thiên Điếu")
        if (chiYear === "Mão") arr.push("Hòa Thượng")
        if (chiYear === "Ngọ") {
            arr.push("Thiên Điếu")
            arr.push("Hòa Thượng")
        }
        if (chiYear === "Dậu") arr.push("Hòa Thượng")
        if (chiYear === "Tuất") arr.push("Thiên Điếu")
        if (CanYear === "Giáp" && chiYear === "Tý" || CanYear === "Bính" && chiYear === "Tý" || CanYear === "Mậu" && chiYear === "Tý") {
            arr.push("Quỹ Môn Quan")
        }
        return arr
    }
    else if (chiHour === "Tỵ") {
        if (chiYear === "Tý") arr.push("Đoản Mệnh")
        if (chiYear === "Thìn") arr.push("Đoản Mệnh")
        if (chiYear === "Ngọ") arr.push("Thiên Nhật")
        if (CanYear === "Giáp" && chiYear === "Tý" || CanYear === "Bính" && chiYear === "Tý" || CanYear === "Mậu" && chiYear === "Tý") {
            arr.push("Quỹ Môn Quan")
        }
        return arr
    }
    else if (chiHour === "Ngọ") {
        if (chiYear === "Tý") arr.push("Thang Hỏa")
        if (chiYear === "Mão") arr.push("Thang Hỏa")
        if (chiYear === "Ngọ") arr.push("Thang Hỏa")
        if (chiYear === "Dậu") arr.push("Thang Hỏa")
        if (CanYear === "Giáp" && chiYear === "Tý" || CanYear === "Bính" && chiYear === "Tý" || CanYear === "Mậu" && chiYear === "Tý") {
            arr.push("Quỹ Môn Quan")
        }
        return arr
    }
    else if (chiHour === "Mùi") {
        if (chiYear === "Tý") arr.push("Hòa Thượng")
        if (chiYear === "Mão") arr.push("Hòa Thượng")
        if (chiYear === "Ngọ") arr.push("Hòa Thượng")
        if (chiYear === "Dậu") arr.push("Hòa Thượng")
        if (CanYear === "Giáp" && chiYear === "Tý" || CanYear === "Bính" && chiYear === "Tý" || CanYear === "Mậu" && chiYear === "Tý") {
            arr.push("Quỹ Môn Quan")
        }
        return arr
    }
    else if (chiHour === "Thân") {
        if (chiYear === "Ngọ") arr.push("Thiên Nhật")
        if (CanYear === "Giáp" && chiYear === "Tý" || CanYear === "Bính" && chiYear === "Tý" || CanYear === "Mậu" && chiYear === "Tý") {
            arr.push("Quỹ Môn Quan")
        }
        return arr

    }
    else if (chiHour === "Dậu") {
        if (CanYear === "Giáp" && chiYear === "Tý" || CanYear === "Bính" && chiYear === "Tý" || CanYear === "Mậu" && chiYear === "Tý") {
            arr.push("Quỹ Môn Quan")
        }
        return arr
    }
    else if (chiHour === "Tuất") {
        if (chiYear === "Tý") arr.push("Hòa Thượng")
        if (chiYear === "Mão") arr.push("Hòa Thượng")
        if (chiYear === "Ngọ") arr.push("Hòa Thượng")
        if (chiYear === "Dậu") arr.push("Hòa Thượng")
        if (CanYear === "Giáp" && chiYear === "Tý" || CanYear === "Bính" && chiYear === "Tý" || CanYear === "Mậu" && chiYear === "Tý") {
            arr.push("Quỹ Môn Quan")
        }
        return arr
    }
    else if (chiHour === "Hợi") {
        if (chiYear === "Ngọ") arr.push("Thiên Nhật")
        if (CanYear === "Giáp" && chiYear === "Tý" || CanYear === "Bính" && chiYear === "Tý" || CanYear === "Mậu" && chiYear === "Tý") {
            arr.push("Quỹ Môn Quan")
        }
        return arr
    }





}

export const convertTuTru = (chiHour, chiYear, Month) => {
    if (Month === 1 || Month === 2) {
        if (chiYear === "Tỵ" || chiYear === "Hợi") {
            if (chiHour === "Thìn" || chiHour === "Tỵ") {
                return true
            }
        }
    }
}

const checkNguHanhThienCan = (canHour, canDay, canMonth, canYear) => {
    const arr = []
    if (canHour === "Giáp" || canHour === "Ất") arr.push("Mộc")
    if (canHour === "Bính" || canHour === "Đinh") arr.push("Hỏa")

    if (canHour === "Mậu" || canHour === "Kỷ") arr.push("Thổ")
    if (canHour === "Canh" || canHour === "Tân") arr.push("Kim")
    if (canHour === "Nhâm" || canHour === "Quý") arr.push("Thủy")

    if (canDay === "Giáp" || canDay === "Ất") arr.push("Mộc")
    if (canDay === "Bính" || canDay === "Đinh") arr.push("Hỏa")

    if (canDay === "Mậu" || canDay === "Kỷ") arr.push("Thổ")
    if (canDay === "Canh" || canDay === "Tân") arr.push("Kim")
    if (canDay === "Nhâm" || canDay === "Quý") arr.push("Thủy")

    if (canMonth === "Giáp" || canMonth === "Ất") arr.push("Mộc")
    if (canMonth === "Bính" || canMonth === "Đinh") arr.push("Hỏa")

    if (canMonth === "Mậu" || canMonth === "Kỷ") arr.push("Thổ")
    if (canMonth === "Canh" || canMonth === "Tân") arr.push("Kim")
    if (canMonth === "Nhâm" || canMonth === "Quý") arr.push("Thủy")

    if (canYear === "Giáp" || canYear === "Ất") arr.push("Mộc")
    if (canYear === "Bính" || canYear === "Đinh") arr.push("Hỏa")

    if (canYear === "Mậu" || canYear === "Kỷ") arr.push("Thổ")
    if (canYear === "Canh" || canYear === "Tân") arr.push("Kim")
    if (canYear === "Nhâm" || canYear === "Quý") arr.push("Thủy")

    return arr
}

const checkNguHanhDiaChi = (chiHour, chiDay, chiMonth, chiYear) => {
    const arr = []
    if (chiHour === "Dần" || chiHour === "Mão") arr.push("Mộc")
    if (chiHour === "Tỵ" || chiHour === "Ngọ") arr.push("Hỏa")

    if (chiHour === "Thìn" || chiHour === "Tuất" || chiHour === "Sửu" || chiHour === "Mùi") arr.push("Thổ")
    if (chiHour === "Thân" || chiHour === "Dậu") arr.push("Kim")
    if (chiHour === "Hợi" || chiHour === "Tý") arr.push("Thủy")

    if (chiDay === "Dần" || chiDay === "Mão") arr.push("Mộc")
    if (chiDay === "Tỵ" || chiDay === "Ngọ") arr.push("Hỏa")

    if (chiDay === "Thìn" || chiDay === "Tuất" || chiDay === "Sửu" || chiDay === "Mùi") arr.push("Thổ")
    if (chiDay === "Thân" || chiDay === "Dậu") arr.push("Kim")
    if (chiDay === "Hợi" || chiDay === "Tý") arr.push("Thủy")

    if (chiMonth === "Dần" || chiMonth === "Mão") arr.push("Mộc")
    if (chiMonth === "Tỵ" || chiMonth === "Ngọ") arr.push("Hỏa")

    if (chiMonth === "Thìn" || chiMonth === "Tuất" || chiMonth === "Sửu" || chiMonth === "Mùi") arr.push("Thổ")
    if (chiMonth === "Thân" || chiMonth === "Dậu") arr.push("Kim")
    if (chiMonth === "Hợi" || chiMonth === "Tý") arr.push("Thủy")

    if (chiYear === "Dần" || chiYear === "Mão") arr.push("Mộc")
    if (chiYear === "Tỵ" || chiYear === "Ngọ") arr.push("Hỏa")

    if (chiYear === "Thìn" || chiYear === "Tuất" || chiYear === "Sửu" || chiYear === "Mùi") arr.push("Thổ")
    if (chiYear === "Thân" || chiYear === "Dậu") arr.push("Kim")
    if (chiYear === "Hợi" || chiYear === "Tý") arr.push("Thủy")




    return arr
}

export const checkNguHanh = (canHour, canDay, canMonth, canYear, chiHour, chiDay, chiMonth, chiYear) => {

    const result = []
    const arr_tc = checkNguHanhThienCan(canHour, canDay, canMonth, canYear)
    const arr_dc = checkNguHanhDiaChi(chiHour, chiDay, chiMonth, chiYear)

    const arr_combine = arr_tc.concat(arr_dc)

    if (arr_combine.includes("Kim")) result.push("1")
    if (arr_combine.includes("Mộc")) result.push("2")
    if (arr_combine.includes("Thủy")) result.push("3")
    if (arr_combine.includes("Hỏa")) result.push("4")
    if (arr_combine.includes("Thổ")) result.push("5")

    if (result.length === 5) {
        return true
    } else {
        return false
    }

}

