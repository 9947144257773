export const BinhHoa = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "70%", margin: "0 auto" }}>
        <h1 style={{ textAlign: "center", color: "red", fontWeight: "bold" }}>
          Chương III: Bính Hỏa <br /> Ngày Sinh Bính Hỏa
        </h1>
      </div>

      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Dụng Thần Điều Hậu</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b>• Sinh Vào Mùa Xuân</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Dần: 05/02 – 05/03
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Nhâm Thủy, Canh Tân
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Mão: 06/03 – 04/04
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Nhâm Thủy, Kỷ Thổ
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Thìn: 05/04 – 05/05
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Nhâm Thủy, Giáp Mộc
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b>• Sinh Vào Mùa Hạ</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Tỵ: 06/05 – 05/06
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Quý Thủy, Canh Kim, Nhâm Thủy
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Ngọ: 06/06 – 07/07
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Nhâm Thủy, Canh Kim
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Mùi: 08/07 – 07/08
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Nhâm Thủy, Canh Kim
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b>• Sinh Vào Mùa Thu</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Thân: 08/08 – 07/09
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Nhâm Thủy, Mậu Thổ
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Nhâm Thủy, Quý Thủy
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Tuất: 09/10 – 07/11
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Nhâm Quý, Giáp Mộc
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b>• Sinh Vào Mùa Đông</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Hợi: 08/11 – 07/12
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Canh Kim, Mậu Thổ, Giáp Mộc, Nhâm Thủy
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Tý: 08/12 – 05/01
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Nhâm Thủy, Kỷ Thổ, Mậu Thổ
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Sửu: 06/01 – 03/02
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Nhâm Thủy, Giáp Mộc
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Lưu ý: Các bạn cần lưu ý những dụng thần điều hậu phía trên chỉ mang
        tính chất tham khảo. Nếu cần biết thêm về các dụng thần điều hậu, cũng
        như các dụng thần khác trong lá số bát tự bạn cần tham khảo hoặc tư vấn
        từ các chuyên gia.
      </p>

      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Hình Tượng</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Bính Hoả là Dương Hoả. Khi bạn nghĩ đến Bính Hoả, cần nghĩ về mặt trời!
        Bính Hoả biểu thị cho Hoả xuất phát từ mặt trời, và liên kết với mặt
        trời. Đây là lửa chiếu lên và soi sáng vạn vật trên mặt đất. Hãy nghĩ về
        điều này: chúng ta sẽ ở đâu nếu không có mặt trời!
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Giống như một thanh kiếm hoặc rìu cần được rèn để trở nên sắc bén và hữu
        dụng, những người Canh Kim phát triển mạnh nhờ làm việc chăm chỉ. Họ có
        khuynh hướng lười biếng. Nhưng thông qua kỷ luật, tập trung và khó khăn,
        họ nổi lên như những nhân vật vững chắc thấm nhuần tinh thần trách
        nhiệm.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Vì vậy, khó khăn với Canh Kim như như vịt gặp nước! Mặc dù sẽ là nói quá
        khi nói rằng họ thích khó khăn, nhưng họ sẽ phản ứng lại theo cách có
        lợi cho họ. Cho dù họ có than vãn hay phàn nàn về những khó khăn như thế
        nào, Canh Kimtốt nhất có động lực bẩm sinh để chiến thắng tất cả. Người
        ngày sinh Canh Kim yếu có thể phải làm việc chăm chỉ hơn (và ngừng phàn
        nàn) để trau dồi đặc điểm này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Canh Kim đã qua tôi luyện là người đã trải qua rất nhiều quá trình ‘rèn
        giũa’ (thử thách và khổ nạn, và đã sống sót qua chúng) trong cuộc sống.
        Đó chính là Thép đã tôi thế đấy! Bạn hãy nhớ, Canh Kim cũng như thanh
        kiếm chưa qua tinh chế chỉ là sắt thô, cồng kềnh và xấu xí. Họ không
        được rèn giũa để trở nên hữu dụng và họ cứng rắn hoặc thậm chí cứng đầu.
      </p>

      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Tính Cách</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Một trong những đặc điểm nhận dạng chính của tính cách Canh kim là xu
        hướng coi trọng tình bạn và mối quan hệ của họ với người khác. Họ sẽ sẵn
        sàng đi đến tận cùng trái đất để giúp đỡ bạn bè của mình. Điều này càng
        đúng hơn nếu đó là những người bạn mà họ đã quen biết từ những ngày còn
        đi học.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ thường sẵn sàng giúp đỡ bạn bè bất cứ khi nào cần. Tuy bề ngoài họ có
        vẻ rắn rỏi và bất cần, nhưng bên trong họ thực sự rất dịu dàng và chu
        đáo. Thông thường, sự nghiêm khắc và cứng cỏi chỉ là biểu hiện bề ngoài
        của họ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Những người thuộc ngày sinh Canh Kim rất ghét nhìn thấy sự bất công dưới
        bất kỳ hình thức nào, và thậm chí còn hơn thế nữa nếu nó liên quan đến
        bạn bè của họ và những người họ quan tâm. Họ thường sẽ đấu tranh cho
        quyền lợi của những người bị áp bức và những người yếu thế, đồng thời
        đặt giá rất cao cho công lý. Họ sẽ không bao giờ coi thường bạn bè và sẽ
        luôn tôn trọng và tôn vinh những người đã giúp đỡ họ trong quá khứ. Họ
        biết ý nghĩa của lòng biết ơn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Canh Kim đã qua tôi luyện cũng giống như một thanh kiếm. Một thanh kiếm
        không bao giờ nửa vời, và một chiếc rìu cũng không nhẹ nhàng cứa vào một
        khúc gỗ. Khi họ muốn cắt, họ cắt! Tương tự như vậy, những người thuộc
        Canh Kim luôn vượt qua các mục tiêu và chướng ngại vật trên đường để đạt
        được điều họ muốn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với những người Canh Kim, khi nói đến tham vọng và mục tiêu của họ,
        nó giống như đang tham gia một trận chiến. Họ chỉ đơn giản thực hiện mà
        không xem xét tất cả các hậu quả có thể xảy ra. Họ không tin vào việc
        ngồi nghĩ về các khả năng. Họ tin tưởng vào việc bước ra và hành động.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Những người khác đôi khi có thể lầm tưởng Canh Kim là người quá hấp tấp
        và bốc đồng, bởi vì họ hiếm khi dành thời gian để xem xét hệ quả của
        hành động của mình. Đôi khi, thái độ của họ là "làm hoặc là chết". Họ là
        những người rất ham học hỏi và tin tưởng vào việc đi theo những gì họ
        muốn - một khi họ đã quyết định về những gì họ muốn!
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngược lại, người Canh Kim yếu có hình ảnh như mỏ sắt thô là thứ không có
        định hướng, mục đích và quyết tâm trong cuộc sống. Chúng chỉ đơn giản là
        một mảnh kim loại đang chờ được rèn luyện, và vô dụng ở dạng hiện tại.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Những người Canh kim bẩm sinh được trang bị một bộ óc sắc bén để phát
        triển mạnh khi làm việc trong một hệ thống. Họ thích có một cách tiếp
        cận có hệ thống đối với các mục tiêu của cuộc sống - một cách tiếp cận
        có các bước và quy trình đúng cách, trái ngược với cách bạ đâu làm đó và
        rối rắm. Điều này có thể tốt và xấu. Điều tốt là một khi biết họ muốn
        gì, không có gì ngăn cản họ đạt được điều đó. Tuy vậy thật là tệ khi họ
        vẫn chưa tìm ra những gì họ thực sự muốn. Người Canh Kim trở nên giống
        như một chiếc rìu không mục đích vung vẩy và chém vào không khí.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Vì lẽ đó, sự rõ ràng phải là mục tiêu trong tâm trí của Canh Kim. Sự rõ
        ràng là sức mạnh. Giống như một cái rìu, nó phải biết chính xác vị trí
        vật cần chặt. Nếu tâm trí của Canh Kim bối rối hoặc mất phương hướng,
        người Canh Kim có thể tự gây nguy hiểm đối với chính họ. Họ phải cố gắng
        để biết chính xác những gì họ muốn và làm thế nào để đạt được điều đó.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu không phải là người đứng đầu, họ có xu hướng ghen tị một chút. (Tất
        nhiên, họ có thể tỏ ra bên ngoài cool ngầu, nhưng sâu bên trong luôn có
        một chút ghen tị). Tuy nhiên, hầu như lúc nào thì khả năng cạnh tranh
        này có xu hướng có lợi cho họ, vì họ luôn tràn đầy năng lượng và đủ năng
        động để đạt được những gì họ muốn. Canh Kim có sức chịu đựng cao và sức
        bền.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ trở nên sống động khi họ phải chiến đấu và vươn tới đỉnh cao - nói
        cách khác, họ muốn thi đấu hết khả năng của mình! Nếu không có thử
        thách, họ có xu hướng trở nên bơ phờ và kỹ năng tư duy của họ giảm sút,
        và họ có thể trở nên uể oải và nhàn rỗi. Thử thách giúp mọi thứ luôn mới
        mẻ và thú vị đối với họ. Khi một người Canh kim đưa ra phán quyết, rất
        nhiều quyết định bị ảnh hưởng bởi ý thức của họ về thể diện, hoặc niềm
        tự hào của họ. Họ cần đảm bảo rằng niềm tự hào của họ còn nguyên vẹn.
        Nhưng hãy nhớ rằng, Canh kim - niềm kiêu hãnh đến trước khi sụp đổ!
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Sự tự tôn là rất quan trọng đối với Canh Kim. Họ không thể chịu “mất
        mặt” trước đối thủ cạnh tranh hoặc thậm chí bạn bè thân thiết hoặc đối
        tác kinh doanh của họ. Mặc dù bề ngoài họ rao giảng “Chúng ta cùng chiến
        thắng”, nhưng bên trong họ rất coi trọng việc thắng và thua.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đôi khi điều này không thực sự mang lại điều tốt cho Canh kim, vì nếu họ
        không gạt niềm kiêu hãnh của mình sang một bên, họ sẽ không thể suy luận
        một cách hợp lý và khắc phục sự cố. Và trong những thời điểm tồi tệ
        nhất, niềm tự hào của họ thường là Gót chân Achilles.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Canh Kim không thích thừa nhận sai lầm. Dù biết mình sai nhưng họ có xu
        hướng tránh 'thừa nhận' lỗi lầm của mình. Sự tự cao quá lớn của họ luôn
        cản trở họ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Canh Kim là Dương Kim. Vốn có lõi là sắt lạnh, Canh kim đương nhiên rất
        ‘ngầu’. Khi họ đủ lạnh, họ rất có chiến thuật và có kịch bản trong suy
        nghĩ của mình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tuy nhiên, một khi họ thực sự bắt đầu tham gia vào một ‘cuộc chiến’ -
        khi họ đang chuẩn bị cho một cuộc chiến - họ có thể bị kích động! Giống
        như một thứ kim loại bị ném vào trong lò lửa, nó càng ngày càng nóng
        lên. 'Cảm xúc' của họ nổi lên, và sau đó họ để cho cơn nóng giận kiểm
        soát bản thân mình. Họ không tự kìm nén cơn giận được. Chẳng bao lâu,
        những cảm xúc rực lửa sẽ thiêu rụi họ - do đó làm tan chảy kim loại (dẫn
        đến sự sụp đổ)!
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Những người Canh kim vốn có tính cạnh tranh và hơn thế nữa, ý thức về
        bản thân của họ bắt nguồn từ việc có thể chiến thắng trong một cuộc
        chiến - bất kỳ cuộc chiến nào. Đây là lý do tại sao họ tìm thấy cơ hội
        phát triển mạnh trong các tình huống cạnh tranh (hoặc căng thẳng). Khi
        nghĩ đến việc tính kế, lên kế hoạch để đạt được vị trí cao nhất, lòng tự
        trọng của họ thường tăng vọt.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đương nhiên, điều ngược lại sau đó cũng đúng. Nếu họ thua cuộc trong một
        điều gì đó họ đã đặt ra, sự tự tin của họ sẽ ngay lập tức mất hết. Điều
        này có thể nhận thấy ngay ở một người Canh kim. Họ mất tinh thần chiến
        đấu và có thể buồn bã rút lui. Hoặc tệ nhất, họ có thể mất niềm tin và
        trở thành người sống ẩn dật.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ có xu hướng liên tục so sánh mình với những người khác trong suốt
        cuộc đời của họ, công khai hoặc trong tiềm thức. Sâu thẳm bên trong, họ
        luôn cố gắng để trở nên tốt hơn tất cả những người khác. Điều này đặc
        biệt đúng trong vòng kết nối bạn bè của họ và thậm chí, anh chị em ruột
        hoặc đối tác kinh doanh của họ. Điều này có thể có khả năng gây tổn hại,
        bởi vì sự tự tin của họ có thể dễ dàng bị xói mòn khi họ bắt đầu cảm
        thấy rằng họ đang thua cuộc ‘cạnh tranh’ này. Hầu hết người sinh ngày
        Canh kim đều cố hữu một nỗi sợ thất bại. Do đó họ vốn dĩ không thích rủi
        ro.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Canh kim phản đối sự bất công dưới bất kỳ hình thức nào. Họ đứng về phía
        công lý, chiến đấu vì kẻ yếu. Sự bất công theo đúng nghĩa đen làm cho
        máu của họ sôi lên, và họ sẽ sẵn sàng đối đầu với bất kỳ ai dám tiếp tục
        gây ra sự bất công trên thế giới!
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu họ chứng kiến sự bất công diễn ra và không được đem ra để giải quyết
        vấn đề, họ thường cảm thấy tồi tệ. Và điều này ảnh hưởng đến ý thức về
        bản thân của họ. Họ phải giữ vững lập trường khi họ cảm thấy cần phải
        làm như vậy, nếu không việc họ thiếu hành động sẽ khiến họ cảm thấy bất
        lực - và do đó, vô vọng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tuy nhiên, đôi khi họ có thể hơi quá đà chống lại sự phân biệt đối xử,
        và đôi khi điều này có thể khiến họ trở nên bướng bỉnh và thiếu linh
        hoạt trong suy nghĩ. Những hành động vội vàng có thể dẫn đến kết quả
        không như mong muốn, càng làm xói mòn lòng tự tin của họ.
      </p>

      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Mối Quan Hệ/ Tình Cảm</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Với sự rõ ràng và tập trung, những người Canh Kim có thể trở nên thành
        công rực rỡ trong cuộc sống. Họ không phải là những người dễ bị ảnh
        hưởng bởi những thay đổi đột ngột, hoặc nhượng bộ những kế hoạch bất
        ngờ, hoặc những thay đổi theo sở thích nhất thời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Canh Kim trải qua tinh luyện thích suy nghĩ như một nhà chiến lược chính
        trị, bởi vì họ thích hướng đến cách tiếp cận cuộc sống có hệ thống. Quá
        trình suy nghĩ theo quy trình và phương pháp đối với Canh Kim đã qua tôi
        luyện, và họ có thể vạch ra một kế hoạch trong đầu và tuân thủ các bước
        đó khá chặt chẽ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Bất cứ ai đã từng có một khoảng thời gian dài tiếp xúc với người có tính
        cách Canh Kim sẽ nhận ra bản tính thích cạnh tranh vốn có của họ. Trong
        mắt người Canh Kim, không có vị trí thứ 2, chỉ có vị trí số 1 mới là
        “xứng đáng”. Canh Kim theo bản năng thích chiến thắng. Đó là lý do tại
        sao hầu hết họ đều có khuynh hướng tự nhiên thích thể thao và trò chơi
        mạo hiểm. Họ yêu thích thử thách và vốn dĩ bị nó lôi cuốn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Một khi cảm xúc của họ (hoặc cơn thịnh nộ) bắt đầu, mọi thứ bắt đầu
        xuống dốc đối với Canh Kim. Đôi khi, ngay từ đầu họ đã quên mất “lý do”
        đằng sau cuộc chiến. Họ có thể mù quáng đến mức chỉ ở muốn tranh đấu vì
        mục đích chứng kiến kẻ thù của họ gục ngã (ngay cả khi điều đó có nghĩa
        là phải hy sinh bản thân theo một cách nào đó). Họ tấn công kẻ thù của
        họ một cách không vì lợi ích gì chỉ để tiêu diệt kẻ thù. Họ không quan
        tâm gì xem có lợi ích gì từ việc đào hố chôn kẻ thù hay không, hay mọi
        tính toán trước đó sẽ như cơn gió thoảng qua vô ích - tất cả phụ thuộc
        vào họ - Canh kim.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ sẽ làm bất cứ điều gì cho những người bạn tốt của họ, và sẽ làm mọi
        việc vì bạn bè. Không thể hiện cảm xúc công khai, Canh Kim không phải là
        kiểu người dễ xúc động thường xuyên ôm người khác và hôn gió bạn bè của
        họ - họ thể hiện sự cân nhắc và cẩn thận trong hành động của mình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Do đó, những gì họ nói không giống như những gì họ làm. Và họ đặt tầm
        quan trọng hàng đầu vào lòng trung thành với những người bạn tốt của
        mình, và sẽ cố gắng hết sức để bảo vệ những mối quan hệ này. Nếu được
        kêu gọi để bảo vệ một người bạn, họ sẽ làm như vậy mà không do dự.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ vốn dĩ miễn cưỡng chia sẻ cảm xúc của họ (và chủ yếu là không thể
        chia sẻ cảm xúc của họ, vì họ không biết làm thế nào), họ cực kỳ chú tâm
        vào các mối quan hệ và tình bạn thân thiết của mình. Đôi khi, những
        người khác có thể không biết cách xử lý cảm xúc mạnh mẽ của Canh Kim
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Bởi vì họ rất coi trọng tình bạn và rất trung thành, họ mong đợi sự
        trung thành và kiên định tương tự từ bạn bè của họ. Đôi khi, vì tính
        cách mạnh mẽ của họ, họ có xu hướng yêu cầu người khác giống như vậy.
        Những người khác có thể hơi ngạc nhiên rằng những người Canh Kim có thể
        theo cách này, đặc biệt là vì họ thể hiện rất ít cảm xúc!
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Do đó, nếu họ bị tổn thương hoặc bị phản bội bởi một người bạn, đó là
        một tổn thương rất sâu. Đó không phải là điều mà một người Canh Kim có
        thể quên hoặc tùy theo tình huống mà tha thứ một cách dễ dàng. Họ sẽ
        phản ứng mạnh mẽ, một cách quyết liệt, trước sự phản bội và lừa dối.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đáng ngạc nhiên là dù rất coi trọng tình bạn của mình, những người Canh
        kim dễ gây thù chuốc oán với người khác. Đó là một khía cạnh bất ngờ của
        tính cách Canh Kim thường khiến người khác phải ngạc nhiên. Tần suất họ
        gây thù chuốc oán dường như trái ngược với khả năng coi trọng tình bạn
        của họ
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tuy nhiên, chính vì họ coi trọng tình bạn đến mức họ luôn phân biệt rõ
        ranh giới giữa bạn và thù. Điều này đặc biệt đúng ở nơi làm việc. Một
        khi họ coi ai đó là đối thủ cạnh tranh hoặc kẻ thù, rất hiếm khi có thể
        thuyết phục được người Canh Kim thay đổi quyết định.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đôi khi, họ có thể hơi tàn nhẫn. Họ muốn nhìn thấy những người họ yêu
        quý chiến thắng, và nhìn thấy những người họ không thích gục ngã. Điều
        này có thể khiến người khác có ấn tượng rằng Canh Kim có thể có hai mặt,
        nhưng nếu quan sát kỹ thì đó chỉ là hai thái cực của cùng một tình cảm.
      </p>

      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Sự Nghiệp/ Công Việc</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Canh Kim là những người rất chú trọng đến khâu hình ảnh. Ngay cả
        nếu họ không chú trọng nhiều đến cách ăn mặc (quần áo bóng bẩy hay theo
        xu hướng thời trang), họ cũng thường nhận thức rõ người khác nhìn mình
        như thế nào. Họ thường rất quan tâm đến việc người khác đánh giá mình ra
        sao.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Do đó, người Canh Kim rất cẩn trọng không muốn mất mặt hay làm trò hề
        trong mắt người khác. Cảm giác tự tôn của họ thường dựa vào việc tỏ ra
        bảnh bao ra sao, họ thích vượt trội hơn người khác. Bởi vì đặc tính này,
        người Canh Kim thường tỏ ra hơi hời hợt. Đôi khi do nỗi sợ bị cười cợt
        dẫn đến họ thích sự an toàn và tránh không bộc lộ những nét tính cách
        thú vị của họ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Canh kim rất muốn đi đến cuối vạch đích. Không phải lúc nào họ
        cũng muốn chờ đợi, hoàn thiện một sản phẩm hoặc dịch vụ cho đến khi sản
        phẩm hoặc dịch vụ đó đủ hoàn hảo để được tiết lộ với thế giới. Đối với
        họ, không thực hiện bất kỳ hành động nào tương đương với thất bại hoàn
        toàn!
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Do đó, những người Canh Kim không thực sự quan tâm quá nhiều đến các chi
        tiết hoặc điểm tốt hơn của mọi thứ. Họ không thực sự chú ý quá nhiều nếu
        đề xuất hoặc báo cáo nghiên cứu đó được xác minh chính xác, miễn là nó
        được hoàn thành đúng giờ - và tốt hơn nữa, hãy giao nộp trước khi những
        người khác hoàn thành nhiệm vụ tương ứng của họ!
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đôi khi họ có thể hơi liều lĩnh và có xu hướng muốn làm nhanh mọi việc
        chỉ để hoàn thành công việc. Đôi khi họ có thể khá bất cẩn và có thể mắc
        sai lầm, nhưng họ cũng có xu hướng muốn che đậy điều đó - bởi vì họ cần
        duy trì sự kiêu hãnh và giữ thể diện của mình!
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Những người sinh ngày Canh Kim đôi khi khá bướng bỉnh, và đặc điểm này
        đặc biệt thể hiện ở nơi làm việc! Khi họ muốn hoàn thành một việc gì đó,
        họ thường đã nghĩ về cách họ muốn hoàn thành nó, và họ sẽ không để ai
        thay đổi kế hoạch đó.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Điều này đôi khi có thể gây tổn thương người khác. Nhưng những người
        Canh Kim không phải là người xấu, và không muốn vượt lên phía trước bằng
        những cách gian xảo. Như vậy, họ có thể bị hiểu nhầm. Họ chỉ đơn giản
        muốn hoàn thành công việc và tiến lên phía trước.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người sinh ngày Canh Kim không thu mình trước quyền lực, hoặc bỏ đi khi
        đối mặt với những quan điểm bất đồng. Trên thực tế, nếu những quan điểm
        bất đồng đó được trình bày bởi những đồng nghiệp mà họ không ưa, những
        người Canh Kim sẽ sẵn sàng giữ vững lập trường và sẵn sàng chiến đấu!
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi nói đến khả năng lãnh đạo và là người đứng đầu một nhóm hoặc bộ
        phận, các cá nhân của Canh Kim đều là những người độc lập. Họ thích làm
        theo suy nghĩ của riêng mình trong việc dẫn dắt, và sẽ không phải là
        kiểu người đi trưng cầu ý kiến khác nhau.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu họ đang đàm phán và bị đẩy vào một góc, những người Canh Kim có xu
        hướng đưa ra tối hậu thư, và không có xu hướng giải quyết thoả hiệp, mỗi
        bên đều nhường nhịn nhau. Trên thực tế, họ rất tận tâm với vai trò quyền
        lực và quyền hạn của mình đến nỗi họ sẽ không để cho bất kỳ cấp dưới nào
        thách thức quyền lực của họ. Trên thực tế, cấp dưới hoặc thành viên
        trong nhóm quyết định tham gia thử thách chiến đấu với sếp Canh Kim cần
        được chuẩn bị cho những hậu quả có thể xảy ra với họ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu bạn khiến lãnh đạo Canh Kim “ ngứa mắt”, bạn sẽ nghe đi nghe lại lời
        che trách về công việc cho dù bạn đã cố gắng đến thế nào đi chăng nữa.
        Nhưng khi bạn làm vừa lòng họ, thì mọi lỗi của bạn sẽ từ lớn mà thành
        nhỏ, từ nhỏ thành chẳng có gì. (Hãy nhớ, sếp Canh kim rất chú trọng đến
        thể diện).
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Không có hai nhà lãnh đạo Canh Kim nào giống nhau. Mặc dù họ có thể có
        những đặc điểm chung trong cách lãnh đạo nói chung, những người Canh Kim
        lại thích có phong cách quản lý riêng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ rất độc lập và thích sử dụng các kỹ năng lập luận chiến thuật của
        mình để đưa ra kế hoạch quản lý hoạt động cho nhóm của họ. Cho dù tốt
        hơn hay tệ hơn, họ thích làm theo cách riêng của họ. Nếu họ tìm kiếm
        thông tin, đó chỉ đơn thuần là để đảm bảo rằng họ đang đi đúng hướng!
        Nói cách khác, các nhà lãnh đạo Canh Kim không thích (hoặc tệ nhất, được
        coi là) cầm tay chỉ việc và được cho biết rằng họ đang làm điều đúng
        đắn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Và đối với một nhà lãnh đạo Canh Kim không gì tuyệt vời hơn là khẳng
        định phong cách lãnh đạo độc đáo của họ! Họ mong muốn cho người khác
        biết rằng phong cách quản lý của họ khác (và tốt hơn) so với những người
        còn lại. Điều quan trọng đối với họ là có thể nổi bật và được coi là
        xuất chúng.
      </p>

      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Kinh Doanh</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi trở thành ông chủ của một doanh nghiệp, những người Can Kim bắt đầu
        kinh doanh vì những lý do riêng. Họ bắt đầu hoặc bắt tay vào một liên
        doanh kinh doanh dựa trên niềm tin của họ về thế giới kinh doanh nói
        chung. Họ thường phân biệt giữa kinh doanh dựa trên lợi nhuận và kinh
        doanh dựa trên sở thích Do đó, nếu không có “sở thích” để theo đuổi, hầu
        hết Canh Kim thường không bắt đầu công việc kinh doanh của riêng họ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Một lá số Canh Kim xấu tức bao gồm toàn kỵ thần sẽ gặp rất nhiều bất ổn.
        Thời quá khứ của họ và cách họ được nuôi dạy từ nhỏ đóng một vai trò
        quan trọng trong cách họ làm kinh doanh. Họ sẽ làm tất cả những gì có
        thể để giành được lợi nhuận và thắng thế đối thủ cạnh tranh.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tuy nhiên, nếu họ được nuôi dạy rằng việc kinh doanh có thể được hình
        thành dựa trên sở thích và niềm đam mê, thì họ sẽ toàn tâm toàn ý theo
        đuổi nó như một phương tiện để đạt được mục tiêu của mình. Họ sẽ không
        dễ dàng bị làm hỏng bởi các hoạt động ít hơn thông thường chỉ vì lợi ích
        tài chính. Họ cũng sẽ có hứng thú để làm điều đó vì lợi ích của sự phát
        triển cá nhân của họ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Hiếm khi những người Canh Kim thích liên doanh, tham gia vào các hoạt
        động kinh doanh với một ai khác. Họ là những người thích đi một mình
        trong thế giới kinh doanh và thích bắt tay vào các hoạt động kinh doanh
        của riêng mình mà không cần sự hỗ trợ của các đối tác hoặc đội nhóm.
        Điều này là bởi vì làm việc cùng với Canh Kim là khó khăn cho những
        người khác. Họ thường cứng đầu và không chịu chấp nhận phong cách của
        đối tác trong kinh doanh.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ sẽ không thể làm tốt trong các hợp tác với các đối tác lâu dài, bởi
        vì họ sẽ không bắt tay vào làm bất cứ việc gì nếu không được phép có ý
        kiến mạnh mẽ về cách thức họ thực hiện! Hợp tác kinh doanh với đối tác
        sẽ gây ra nhiều bất hòa và bất đồng, vì họ sẽ không bao giờ chịu từ bỏ
        quyền kiểm soát đủ lâu để làm theo ý kiến của người khác.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phần lớn, họ thường không thích nghe về những ý tưởng của mình không đủ
        hay và không thích phải bảo vệ nó trước những người khác. Một khi họ đã
        quyết định về một kế hoạch hành động, họ sẽ muốn theo đuổi nó cho đến
        cùng và có một đối tác nói ra nói vào, góp ý kiến sẽ làm cho họ khó
        chịu. Do đó, những người Canh Kim thường phát triển mạnh khi họ cố gắng
        một mình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với những người Canh Kim, lòng trung thành cá nhân được đánh giá cao
        trong tất cả các mối quan hệ bạn bè của họ. Một khi họ đã thành lập ‘bộ
        lạc’ gồm những người bạn tâm giao và bạn bè thân thiết, họ sẽ hiếm khi
        là kiểu người cắt đứt những mối quan hệ đó hoặc cắt đứt với những người
        khác.
      </p>

      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Tài Lộc</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Những người sinh ngày Canh Kim thích được coi là những người có tầm nhìn
        độc đáo, có một không hai. Điều này có xu hướng thấm nhuần vào tất cả
        các lĩnh vực trong cuộc sống của họ, bao gồm cả kỹ năng quản lý tiền bạc
        của họ. Họ có khuynh hướng làm ngược lại với những gì mọi người khác
        đang làm.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ là kiểu người sẽ đi ngược lại giới hạn và không dễ bị ảnh hưởng bởi
        các xu hướng phổ biến. Họ không thực sự chú ý đến những người khác đang
        đầu tư gì. Đặc điểm này là một con dao hai lưỡi. Trừ khi đã có được kỹ
        năng và tầm nhìn phù hợp, việc quá cứng rắn với một ý tưởng hoặc thái độ
        cao ngạo có thể dẫn họ đến những thảm họa lớn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Hầu hết thời gian, Canh kim thích tỏ ra cứng đầu. Họ muốn trở nên độc
        đáo và đặc biệt. Một người Canh kim được tôi luyện sẽ luôn thực dụng và
        có tầm nhìn rõ ràng về các mục tiêu tài chính của mình. Họ được coi là
        là không thích rủi ro hoặc sống hoàn toàn không có mục đích.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi nói đến việc đạt được sự giàu có về tài chính, những người Canh kim
        không bao giờ bỏ cuộc. Họ có thể chỉ là những người khởi đầu chậm chạp.
        Cũng giống như tất cả các khía cạnh khác trong cuộc sống của họ, nếu họ
        có một mục tiêu để hướng tới, họ rất có thể sẽ đạt được nó cho dù họ có
        muốn đạt được mục tiêu gì đi nữa. Nhưng một trong những vấn đề chính với
        Canh Kim là - tìm ra được mục tiêu rõ ràng!
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tùy thuộc vào mức độ thịnh nộ bên trong của họ (hành Hoả ẩn trong lá số
        Bát Tự đun nóng và làm tan chảy Kim loại), đôi khi họ có thể hấp tấp và
        lao thẳng vào các khoản đầu tư tài chính “làm giàu nhanh chóng”. Canh
        Kim không thể nói không với một ‘thử thách’. Vì vậy, họ có thể có xu
        hướng rơi vào cái bẫy này nếu ai đó đốt cháy ngọn lửa kích thích họ.
        Trong thời điểm tồi tệ, họ có thể vô tình dấn thân vào trò chơi đỏ đen,
        cơ hội chỉ đơn giản là để chứng minh người khác sai, hoặc chỉ đơn giản
        là để cho người khác thấy rằng họ có thể chấp nhận thử thách và chiến
        thắng nó!
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tuy nhiên, Canh kim vượng là người tìm ra một con đường rõ ràng trong
        tâm trí của mình đối với kế hoạch tự do tài chính. Và nếu họ có thể chế
        ngự được ngọn lửa bên trong mình, hầu hết thời gian, họ có khả năng
        thành công trong nhiệm vụ của mình. Bởi vì bản tính kiên trì của họ, họ
        sẽ hiếm khi chấp nhận mất mát như một điều không thể tránh khỏi. Như
        vậy, những người Canh Kim thường sẽ làm khá tốt trong các dự án kinh
        doanh của họ nếu họ thực hiện nó với mục đích đúng đắn và đầu óc tỉnh
        táo.
      </p>
    </div>
  );
};
