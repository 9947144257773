export const NhamThan = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người này thông minh và có nhiều suy nghĩ sâu sắc, dễ coi thường những
        người mà anh ta/cô ta xem là “nông cạn”. Thường suy nghĩ sâu và thích
        gặp gỡ giao tiếp với người khác nhưng không tiết lộ suy nghĩ cá nhân của
        mình cho người lạ, dễ bị lừa gạt vì tham lam. Kỹ năng sao chép giỏi, có
        khả năng đáp ứng nhu cầu tình dục của người bạn đời. Hôn nhân không
        thành công, dễ kết hôn muộn. Có con giỏi. Thường lo lắng và không yên
        trong việc hoàn thành các nỗ lực, dễ lao lực và thường bị người khác
        hiểu nhầm.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tựa như một thùng nước bằng sắt lớn, Nhâm Thân cũng tựa như đại dương
        mạnh mẽ chứa đầy trí tuệ. Làm cách nào bạn giấu đi một thùng sắt lớn?
        Bằng cách thả nó xuống lòng đại dương biển cả. Người Nhâm Thân có thể
        hiểu như là một con thuyền to lớn bị chìm xuống đáy biển. Những người
        này có vẻ ngoài buồn bã và đau thương, thường thương nhớ những thời quá
        khứ đã qua. Họ thường gắn với một thời điểm nào đó trong quá khứ hoặc
        các sự kiện đã qua và không tiến lên được nữa trong đời.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người này thông minh, khôn ngoan, và tràn đầy ý tưởng mới. Họ
        thông minh, nhanh nhạy, đầy đam mê và kiến thức cũng như có trái tim ấm
        áp nhưng dễ nổi nóng. Phụ nữ sinh ngày Nhâm thường có năng lực và xinh
        đẹp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người này có thể hành xử khá kịch tính do họ có cảm xúc mạnh. Điều này
        giúp họ có thể truyền đạt cảm xúc đến cho người khác. Những người này có
        xu hướng đắm chìm trong nỗi buồn và suy ngẫm, đến mức cảm thấy tự gục
        ngã.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có thể tỏ ra không thân thiện vì nỗi lo sợ phải chịu đựng những nỗi
        đau hay thất vọng. Họ thường sợ lo lắng cho người khác cũng như việc
        phải gắn bó với một ai đó. Nhưng sâu thẳm bên trong, họ là người chân
        thật và chân thành.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ thường không thể tiết kiệm tiền mà họ kiếm được, vì đặt giá trị của
        tình nghĩa anh em huynh đệ lên trên tiền bạc, trọng nghĩa khinh tài.
        Những người này có thể phải làm việc vất vả để giành được thành công
        cũng như thiếu kết nối với gia đình. Con cái của họ, có thể lớn lên có
        khả năng độc lập.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người Nhâm Thân duyên dáng và thân thiện, tuy nhiên cũng ranh ma
        và thực dụng. Họ có đầu óc sắc bén và thích nói ra những gì mình nghĩ
        trong đầu. Họ giỏi trong cách đối nhân xử thế và có thể khá sáng tạo.
        Những người này có nhiều suy nghĩ và tư tưởng dễ gợi cảm hứng cho người
        khác cũng như có khả năng đọc vị người đối diện. Họ là người giỏi giải
        quyết vấn đề cũng như có sự tận tâm và chủ nghĩa hoàn mỹ đối với công
        việc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Thân đấu tranh cho chính nghĩa và mặc dù họ muốn hoà bình và
        yên tĩnh, họ có nhu cầu muốn được cuộc sống tốt đẹp hơn. Họ có xu hướng
        làm việc vất vả vì chính nghĩa. Họ tự tin và quan tâm, và thường là
        người mà được người khác tìm đến khi gặp khó khăn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người này tham vọng và quyết tâm. Họ có năng lực cần thiết và sức
        mạnh ý chí để biến mọi thứ thành hiện thực. Họ năng động, thực dụng và
        có hiệu suất làm việc tốt, kỹ năng tổ chức tốt, khả năng giao tiếp với
        con người và tư duy về thời gian tốt. Họ có vẻ thiêu quyết đoán nhưng
        một khi họ ra quyết định, họ có thể rất hiệu quả. Họ siêng năng và có
        trách nhiệm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Thân tự tin và trực giác. Họ đánh giá cao uy tín và chung
        thuỷ cũng như khả năng gia tăng danh tiếng. Họ thận trọng với tiền bạc
        và thích những gì kích thích về mặt trí óc. Họ có thể khó chịu hoặc đôi
        khi khá duy ý chí và thể hiện dấu hiệu lo lắng, căng thẳng. Thái độ
        duyên dáng của họ giúp họ ghi điểm trong quan hệ giao tiếp với người
        khác, về mặt xã giao và giao tiếp khéo léo.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ giỏi trong sáng tạo về màu sắc, âm thanh, hay thẩm mỹ thông qua các
        hình thức diễn đạt nghệ thuật. Họ thông minh, khôn ngoan, và thể hiện
        khả năng tư duy tốt trong việc thu nạp kiến thức. Họ có thể cùng lúc vừa
        nghi ngờ và ngây thơ và có thể hành động khá ngẫu hứng vì quá tin tưởng
        vào trực giác của mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ giỏi về viết lách và các hình thức giao tiếp sáng tạo khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ là người giỏi phát biểu trước công chúng và là những nghệ sĩ giải trí
        vì đầu óc khôn ngoan và vẻ ngoài dễ mến. Họ đa tài và linh hoạt, những
        người này thích tạo dựng mối quan hệ tốt với mọi người.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ tràn đầy ý tưởng cảm hứng xuất phát từ suy nghĩ sáng tạo. Sự thất
        vọng có thể dẫn họ đến xu hướng nổi loạn và giận dữ. Năng lực giao tiếp
        sẽ giúp cho họ trở nhành nhân vật quan trọng trước công chúng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Thân có đầu óc sắc bén và yêu thích kiến thức. Thành tích của
        họ sẽ bị cản trở vì sự nghi ngờ quá mức và đầu óc hoài nghi. Họ thích
        nói rõ ra những gì trong tâm trí mình bất kể cảm giác của người khác ra
        sao. Kỹ năng giao tiếp của họ là xuất sắc và họ dễ trở thành những
        chuyên gia tâm lý giỏi. Điều này cộng với suy nghĩ thấu đáo, giúp họ trở
        thành những nhà hoạt động nhân quyền năng động và cũng là người tìm kiếm
        sự thông thái.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ là người có năng lượng tinh thần cao và thích vui vẻ. Tuy nhiên họ
        cũng là người có cá tính hướng nội đòi hỏi khoản thời gian một mình để
        có thể suy ngẫm mọi việc và thư giãn. Chính động lực bên trong khiến cho
        họ là người làm việc tận tâm để đạt được mục tiêu và lý tưởng. Họ có thể
        có tài lộc và địa vị vì thấu hiểu giá trị của mọi sự vật sự việc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Những Đặc Tính Của Nhâm Thân:</b> nhanh nhạy và sắc bén, thấu hiểu,
        nhân đạo, thẳng thắn và chân thành, lý tưởng, thiếu quyết đoán, thích xã
        giao.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Thân có đầu óc thực tế và tìm kiếm địa vị và thấu hiểu tâm lý
        con người. Họ có thể trộn lẫn công việc và đời sống cá nhân. Họ cũng
        năng động và đa tài, và tỏ ra vẻ ngoài duyên dáng tự tin và luôn tìm
        kiếm bổ sung kiến thức. Những người này rất sáng tạo và tài năng, tỏ ra
        vẻ ngoài thân thiện.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ thích quyền lực, cấu trúc và sự hiệu quả và thích làm ở những vị trí
        quyền lực hay thích tự làm chủ. Họ thích sáng tạo và nghệ thuật. Họ
        thông minh và thân thiện, cũng như thích các hoạt động kích thích về trí
        óc. Họ có kỹ năng giao tiếp xuất sắc và thích nhân đạo.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Công Việc:</h4>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tính cách của họ phù hợp với ngành nghề tiếp thị, bán hàng hay giao tiếp
        công chúng. Ngành thương mại và ngân hàng cũng là thế mạnh của họ. Họ có
        xu hướng thích công lý nên phù hợp với lĩnh vực chính trị và pháp luật.
        Họ mạnh về tri thức nên có thể xuất sắc trong lĩnh vực triết học, giáo
        dục, khoa học, huyền học và các môn đòi hỏi sự sâu sắc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đầu óc sáng tạo của họ rất phù hợp với ngành thiết kế, diễn xuất, âm
        nhạc hay nghệ thuật. Họ giỏi quản lý tài sản và những gì sáng tạo. Họ
        thích những sự nghiệp liên quan đến chữa lành, tâm lý học và các hoạt
        động xã hội do bản tính thích nhân đạo và giúp đỡ người khác. Họ giỏi
        trong viết lách và soạn nhạc, và thường rất thành công trong việc thương
        mại hoá tài năng của họ. Các ngành như xuất bản, giáo dục và tiếp thị
        cũng tốt cho họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Những Đặc Tính Trong Sự Nghiệp - Công Việc:</b> thích công lý, sáng
        tạo, nhân đạo, tri thức, tự tin, nhiều kiến thức, mất kiên nhẫn, đa
        nghi, nổi loạn.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Quan Hệ:
      </h4>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Thân sống lý tưởng, quyến rũ, một phần là do kỳ vọng của họ
        trong mối quan hệ. Hành động của họ đôi khi sẽ bị xem là phản ứng thái
        quá, nhưng họ thường có xu hướng thương người cũng như bảo vệ người thân
        yêu. Họ ấm áp và duyên dáng cũng như tỏ ra đáng yêu trong mắt người mình
        thích.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người này rất tự tin, nhưng họ có thể bày tỏ cảm xúc không rõ ràng
        khi xét các vấn đề liên quan đến tình cảm. Điều này đặc biệt quan trọng
        khi người này đồng thời có đến nhiều mối quan hệ tình cảm. Tuy nhiên, họ
        lại rất đa cảm và chung thuỷ. Họ có năng lực và thường hi sinh trong một
        mối quan hệ. Người bạn đời lý tưởng của họ thường là người có thể tỏ ra
        năng lượng tư duy tốt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Thân thường đa nghi, và họ có xu hướng suy nghĩ cân nhắc tới
        lui nhiều và kỹ trong một thời gian dài trước khi cam kết trong một mối
        quan hệ dài lâu. Họ là người bạn đời chung thuỷ một khi họ đã quyết định
        ổn định cuộc sống với ai. Đàn ông Nhâm Thân thường mạnh mẽ và độc lập
        trong khi phụ nữ Nhâm Thân thường hấp dẫn và thường gặp may mắn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người Nhâm Thân giỏi đối nhân xử thế và điều này biểu thị khả năng
        hoà hợp với bất kỳ tập thể nào mà họ gia nhập vào. Họ có cá tính mạnh,
        nếu kết hợp với thái độ ấm áp và duyên dáng, có thể lấy lòng bất kỳ ai.
        Họ thường có xu hướng thích người có tham vọng, lao động siêng năng và
        tự tay tạo nên thành tích cho riêng mình. Chính bản thân họ cũng là
        người có những phẩm chất đó do vậy, việc họ thích tìm người bạn đời có
        phẩm chất tương tự là điều dễ hiểu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có sự duyên dáng bẩm sinh, người Nhâm Thân thường không gặp khó khăn
        trong việc thu hút những người ngưỡng mộ mình. Họ thích tham gia các
        hoạt động xã hội vì những sự kiện này giúp họ cải thiện kỹ năng phân
        tích con người.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu là nam giới sinh ngày Nhâm Thân thì họ thường dựa trên nền tảng mối
        quan hệ bạn bè vững trước khi tiến xa hơn trong mối quan hệ. Người vợ là
        một phần quan trọng của đời sống người Nhâm Thân, và người vợ là con
        đường tốt nhất để làm khơi gợi ra tài năng ẩn giấu của người Nhâm Thân.
        Sự thông minh và địa vị đáng nể trọng của họ sẽ thu hút những người phụ
        nữ danh giá tìm đến để kết hôn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Những Đặc Tính Trong Tình Yêu - Hôn Nhân:</b> ấm áp và duyên dáng, hi
        sinh, cá tính mạnh, duyên dáng, quan tâm, thiếu quyết đoán trong tình
        cảm.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:
      </h4>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Can Nhâm toạ trên vị trí Trường Sinh của Vòng Trường Sinh biểu thị đây
        là trụ ngày rất vượng. Nó biểu thị người Nhâm Thân rất năng động và có
        động lực. Nhâm Thân còn được biết đến là Kiếm Phong Kim trong Nạp Âm.
        Điều này biểu thị sự yêu thích chiến đấu và tranh cãi. Người Nhâm Thân
        nhìn mọi thứ trong đời là sự cạnh tranh và họ luôn muốn là người chiến
        thắng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người này hướng ngoại và năng động. Họ yêu thích sự vui vẻ và xã
        giao. Thân thiện, xã giao và giàu năng lượng, họ có tính cách có sức hút
        và thường cuốn hút được người khác đến giúp đỡ mình. Họ là những người
        sống lý tưởng và rất chân thành đến mức họ là những người đáng tin cậy
        sẽ luôn giữ bí mật và đối xử với bạn bè tử tế. Họ thường có sự khiêm tốn
        thể hiện ra ngoài là sự khả ái và duyên dáng và luôn thể hiện sự hứng
        thú.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Thân sáng tạo và thích cải tiến. Họ có đầu óc sâu sắc, thích
        quan sát và có thái độ khởi nghiệp. Họ muốn được công nhận vì kỹ năng và
        tài năng của mình nhưng họ phải nỗ lực vất vả để được công nhận. Đôi khi
        họ cần có thời gian để phát triển suy nghĩ và cần có mục tiêu cụ thể để
        tập trung toàn bộ năng lượng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Thân thường có thái độ vô lo và điều này có thể khiến họ cảm
        thấy thoải mái để chấp nhận những gì không mong muốn và đủ tự tin để
        liều lĩnh nhưng cũng có thể khiến họ thiếu quyết đoán và thiéu mục tiêu.
        Họ cũng có giác quan tốt và khả năng tự tin vào bản thân khiến họ trở
        nên rất độc lập nhưng cũng có nghĩa là họ khá cứng đầu và áp đặt người
        khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Thân toạ Trường Sinh và Du Dịch Tinh trong lá số. Tỷ Kiên nằm
        trong địa chi Thân biểu thị người này thích tự mình suy ngẫm. Người Nhâm
        Thân là người thích tìm kiếm sự bình an và trí tuệ bên trong. Nếu gặp
        thêm Kim, Thuỷ và Hoả thì là có lợi vì gia tăng ngoại hình của người
        Nhâm Thân. Nếu có thêm nhiều hành Mộc và Thổ trong lá số thì biểu thị
        bất lợi cho họ vì sẽ không có kết quả tốt hoặc không được quý nhân giúp
        đỡ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Điều quan trọng cần lưu ý là nếu có nhiều ngũ hành Thuỷ trong lá số.
        Hành Kim và Thuỷ (tàu đắm) liên quan đến cảm xúc. Điều này biểu thị
        người này đa cảm và kịch tính cũng như dễ khóc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu có Bính xuất hiện trong lá số Bát Tự thì người Nhâm Thân là người từ
        bi và tử tế với người khác. Nếu không có thêm hành Hoả trong lá số thì
        Nhâm Thân có thể bi quan và đôi khi hơi lạnh lùng. Quan trọng cần lưu ý
        xem có Thân và Dần (trường sinh của Tài) xuất hiện trong lá số hay không
        vì biểu thị người Nhâm Thân có thể cảm có tội trong việc tự phá hỏng
        thành công của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Thân sinh vào mùa Xuân và Hè dễ có hạnh phúc trong hôn nhân.
        Họ có thể gần gũi với người phối ngẫu và mối quan hệ tình cảm có thể
        viên mãn và dài lâu. Nếu có Thất Sát và vị trí phù hợp của Dương Nhẫn
        Cục trong lá số biểu thị gặn vận xui về hôn nhân. Tuy nhiên, điều quan
        trọng cần lưu ý rằng nếu sử dụng Thất Sát mà thiếu Dương Nhẫn thì sẽ là
        cách cục ít may mắn hơn. Sẽ bất lợi nếu trụ ngày này gặp nhiều Giáp hoặc
        Mậu trong lá số, biểu thị đối mặt nhiều vân đề và cản trở.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sinh vào mùa Thu và Đông thì sẽ ghen và nghĩ rằng vợ hay chồng phản bội
        và khiến cho tình cảm bị ảnh hưởng. Tuy nhiên, nếu trụ ngày gặp Canh
        trong lá số Bát Tự thì có nghĩa là giàu có và rất thành công trong sự
        nghiệp. Nếu sinh trong tháng Tuất thì biểu thị cuộc đời phức tạp hơn các
        người ngày sinh Nhâm Thân khác và phải làm việc vất vả hơn mà nhận được
        ít kết quả hơn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Thân sinh vào ban ngày sẽ phải trải qua vất vả nhiều hơn khi
        còn trẻ trước khi đạt thành công về sau trong khi người sinh vào ban đêm
        sẽ luôn may mắn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ là người thông minh, mạnh mẽ và rộng lớn. Cũng như đại dương, hành
        động của họ khó lường nhưng vẫn có chủ đích.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhâm toạ Thân mang đến Canh Thiên Ấn, Nhâm Tỷ Kiếp và Mậu Thất Sát. Sự
        bình tĩnh trên vẻ ngoài thực chất che giấu những cơn sóng ngầm bên dưới.
        Thiên Ấn đem đến 2 thế mạnh rất lớn. Trước tiên là khả năng biết được
        mọi thứ nhưng không học hỏi, hay còn gọi là khả năng trực giác. Thứ hai
        là sao hồi phục biểu thị cho sức khoẻ và sức sống. Họ là người rất kiên
        trì và gan lì, có thể bước vào bất kỳ tình huống nào mà có thể chịu đau
        đớn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ấn biểu thị cho kiến thức và khi kèm với hành Thuỷ biểu thị sự thông
        thái, điều này khiến cho họ trở thành người thông minh có thể xử lý
        thông tin dễ dàng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ở bên cạnh Nhâm là Tỷ Kiếp, họ biểu thị khả năng có thể hoà quyện và
        thấu hiểu người khác. Mặt khác họ cũng có Thất Sát, biểu thị khả năng
        hành động. Khi điều kiện chính muồi, hành động sẽ tự bộc phát.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thân thu hút Tỵ trong Lục Hợp mang đến Bính Thiên Tài, Mậu Thất Sát và
        Canh Thiên Ấn. Họ có thể nhìn thấy tài lộc, và cơ hội tương lai. Điều
        này giúp họ tăng cường giác quan sinh tồn và có hi vọng tương lai. Phụ
        nữ Nhâm Thân sẽ hơi may mắn hơn đàn ông Nhâm Thân xét về mặt tình cảm vì
        Thất Sát xuất hiện. Vấn đề ở đây là cả sao Quan Tinh và Tài Tinh biểu
        thị sự bất ổn. Họ là những người tình tuyệt vời nhưng không phải là
        người của gia đình hoàn hảo.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thân khắc Dần trong Lục Xung xua đuổi Giáp Thực Thần, Bính Thiên Tài và
        Mậu Thất Sát. Điều họ cuốn hút đó là bị xua đuổi, điều này khiến cho họ
        càng rơi vào thế châm biếm hơn. Thực Thần là biểu thị sáng tạo tương lai
        và niềm vui bị đẩy ra xa. Ngay cả khi tương lai đến với họ, họ cũng
        thiếu sự sáng tạo. Cả hai sao Thiên Tài và Thất Sát biểu thị cho bạn
        trai và bạn gái đều bị đẩy ra xa, biểu thị họ gặp bất ổn trong tình cảm
        và mối quan hệ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhìn chung, trụ này mang theo nhiều rắc rối. Bề ngoài thì nhìn sáng lán,
        nhưng khá nặng nề và rắc rối bên trong. Điều gì mang đến cho họ cũng bị
        mang đi. Cuộc sống của họ đầy những lần nhắm và trật khiến cho họ trở
        nền hoài nghi. Rất khó để vực dậy tinh thần họ. Cũng như tàu ngầm, một
        khi họ đã lặn xuóng, bạn sẽ không bao giờ biết được họ sắp tấn công từ
        hướng nào. Để có thể nhận biết được tàu ngầm, bạn cần nghe tiếng vọng do
        đó cũng tương tự bạn cần nói chuyện với họ, họ là những người rất dễ hồi
        đáp. Không phải là người nói chuyện giỏi nhưng họ sẽ cho bạn câu trả lời
        vì khối lượng nền tảng kiến thức khổng lồ và sự sẵn lòng chia sẻ của họ.
      </p>
    </div>
  );
};

export const NhamNgo = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Nhâm Ngọ đánh giá cao vật chất và những tiện nghi vật
        chất đem lại, vật chất lượng cao, có đầu óc trẻ trung phức tạp, thiếu
        suy nghĩ chu đáo (dễ sơ sót điều gì), giỏi trong việc kiếm tiền, thường
        gây ấn tượng xấu trong lần đầu nhưng dễ được lòng người khác vì tinh
        thần trách nhiệm, có duyên học tôn giáo/tâm linh. Thường mong đợi
        vợ/chồng khoan dung với mình. Dễ thu hút người khác phái trong quan hệ
        tình cảm yêu đương nhưng cũng dễ chia tay. Nhìn chung cuộc sống không
        thiếu giàu có về vật chất.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đây là khi đường ống nước bị nén và nước tiến về phía trước mà không có
        gì cản được. Nhâm Thuỷ bị đông cứng trong mùa đông và bị cản bởi Mộc
        trong mùa xuân, chỉ trong mùa hè thì mới có thể tuôn chảy trong vinh
        quang...đó là khi các cuộc đua ngựa được mở cửa, đám đông reo hò và họ
        hướng đến sự vĩ đại.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhâm có một khả năng độc nhất vô nhị trong việc đạt được sự bền vững, họ
        tìm kiếm sự cân bằng...làm việc tốt và quả báo tốt sẽ quay trở lại....và
        ác gỉa ác báo. Nước sẽ luôn luôn dâng lên. Đại dương là một ví dụ rõ
        ràng nó bao hàm tri thức ra sao. Họ là những người tìm kiếm kiến thức và
        thông qua trải nghiệm, họ đạt được sự thông thái. Tích luỹ quyền lực là
        một trong những trò chơi bí mật của họ. Nước có thể yên bình trên bề mặt
        nhưng không một ai biết được con sóng nào nằm bên dưới. Khi xét Nhâm thì
        tốt hơn hết bạn không nên mạo hiểm đoán.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Ngọ cũng như một nguồn suối nước nóng với sự ấm áp và tình
        yêu từ bên trong. Tình yêu, gia đình và mối quan hệ là quan trọng hàng
        đầu với những người này vì họ dành nhiều đam mê cho người thân. Họ có
        tri thức cao và khá sáng tạo, kết hợp giữa 2 tố chất này khiến họ vượt
        trội hơn so với những người Nhâm khác. Điểm yếu của họ là cảm xúc bất ổn
        bởi vì họ có thể trở nên mất kiên nhẫn hay vô lý. Tuy nhiên, những người
        này sẽ có một cuộc sống thành công nếu họ có thể quản lý được cảm xúc
        của họ một cách khéo léo.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Ngọ làm việc vất vả để tạo nên tương lai may mắn và thịnh
        vượng. Họ giỏi trong khởi nghiệp bởi vì kỹ năng quản lý và trực gíac bẩm
        sinh. Họ có đam mê đạt thành công trong mọi việc họ chú tâm đến. Phẩm
        chất này giúp họ hướng đến thành công và giúp họ giàu có và liên tục
        tiến lên.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Ngọ sinh ra trong gia đình bình thường và sẽ làm việc vất vả
        để đạt được mục tiêu phi thường. Họ đối mặt nhiều trở ngại và trục trặc
        trong những năm tháng đầu đời. Họ sẽ thua nhiều tiền nhưng về sau, họ sẽ
        học cách để trở thành người quản lý tiền bạc tốt. Họ làm việc để đạt
        được sự giàu có trong khi thoả mãn với lối sống như họ muốn. Mặc dù
        những người này rất tập trung vào sự nghiệp, họ xem mối quan hệ tình cảm
        mới là quan trọng nhất.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tình cảm hôn nhân của người Nhâm Ngọ phụ thuộc vào giới tính. Nam giới
        sẽ có quan hệ tốt và cuộc sống tốt đặc biệt nếu họ lấy vợ giàu. Tuy
        nhiên, phụ nữ Nhâm Ngọ sẽ không có sự cân bằng trong tình cảm. Họ sẽ
        chọn bạn đời có năng lực hơn họ và tránh người chồng yếu đuối.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Ngọ rất tài năng và giỏi kỹ năng tổ chức. Họ thông minh và có
        thể bất kỳ ngành nghề nào nhanh chóng và tỏ ra giỏi giang. Vì những
        người này rất giỏi trong công việc và ngoài ra họ còn có trực giác tốt,
        họ có cảm giác quyền lực tốt. Họ không thích nghe lời và tỏ ra chuyên
        quyền và kiêu ngạo. Một điểm yếu có thể phá hỏng cuộc đời họ là sự bất
        ổn về cảm xúc. Họ có thể trở nên đa nghi, nóng tính vào những lúc bất
        ngờ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có kỹ năng phân tích tốt và trực giác khoa học khiến cho họ dễ tham
        gia lĩnh vực nghiên cứu. Họ thực tế và nắm bắt cơ hội trong mọi tình
        huống. Họ nói năng chính xác và không thích những cuộc trò chuyện dài
        dòng vô nghĩa.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Ngọ rất cảm xúc và lắng nghe cảm xúc họ khi phải làm việc với
        người khác. Họ tìm tình yêu và đam mê và luôn tìm kiếm sự hoàn hảo hay
        mối quan hệ tình cảm lý tưởng. Họ thường có sự nghi ngờ và bất an, khiến
        họ không có thể cảm nhận tình cảm tốt. Tuy nhiên, nếu họ bỏ những cảm
        xúc giận dữ sợ hãi, họ sẽ là những người chugn thuỷ nhất, yêu thương và
        quan tâm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những phẩm chất xuất sắc của người này gắn liền với khả năng dùng ngôn
        ngữ. Họ giỏi nói chuyện và có khả năng giao tiếp tốt. Họ có thể đem đến
        niềm vui cho bất kỳ một cuộc trò chuyện chán ngắt nào và tìm ra được câu
        trả lời từ những người có tinh thần phản kháng. Cách tinh tế của họ làm
        cho mọi người cảm thấy thoải mái trong khi vẫn tham gia trò chuyện là
        rất độc đáo.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Ngọ có khả năng thực hiện bất kỳ công việc nào hứng thú về
        mặt tri thức. Họ rất sáng tạo, quyết tâm, siêng năng và mạnh mẽ và đạt
        được thành công vĩ đại thông qua nỗ lực chăm chỉ trong đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Ngọ rất thấu hiểu với kỹ năng phán đoán tốt về con người và
        tình hình. Họ rộng lượng và thích chia sẻ với những người có nhu cầu.
        Nếu họ tin tưởng vào con người, họ sẽ sẵn lòng đầu tư thời gian và tiền
        bạc để làm lợi cho những người họ quan tâm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ rất sáng tạo và có thể làm chủ bất kỳ kỹ năng công nghệ hay nghê
        thuật nào trong thời gian ngắn nhất. Chính những tố chất này giúp họ trở
        nên xuất sắc trong bất kỳ sự nghiệp nào họ chọn. Họ có thể trở nên khách
        quan và chọn quan sát một sự vật sự việc dưới nhiều góc nhìn. Họ có khả
        năng phán xét cá tính tốt và tỏ ra thích xã giao.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ nổi tiếng trong bất kỳ hội nhóm nào và có những phẩm chất phù hợp với
        một lãnh đạo. Họ thông minh và thường tham gia những hoạt động giúp họ
        mở rộng kiến thức và sự thông thái. Lao động vất vả, thành tâm và quyết
        tâm là những phẩm chất từ trong máu của họ. Những điều này giúp họ đạt
        thành công, tài lộc và thịnh vượng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có xu hướng khá hoài nghi, cảm xúc bất ổn. Những phẩm chất này có thể
        ngăn họ có một cuộc sống thoải mái.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ không thích sống một mình. Họ đánh giá cao tình yêu, gia đình và quan
        hệ lãng mạn hơn bất kỳ thứ nào khác trong đời và có thể là những người
        bạn đời chung thuỷ nhất.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Những Đặc Tính Chung:</b> thích xã giao, nhạy cảm, đa nghi, trực
        giác, hài hước, nói thẳng, tự tin và nhận thức rõ bản thân.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bị thúc đẩy bởi đam mê và khao khát quyền lực, trí tuệ và kiến thức,
        người Nhâm Ngọ hứng thú bởi thách thức và vượt qua trở ngại trong đời
        với sự quyết tâm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ ở những vị trí siêu việt vì họ có kỹ năng quản lý giỏi và khá giỏi
        giao thiệp. Họ thích xã giao, khiến cho họ phù hợp với các vị trí trong
        khu vực hành chính công. Họ có khả năng trở thành người làm từ thiện, tư
        vấn, giáo viên và các vai trò liên quan đến hội đoàn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ khá sáng tạo và có thể học hỏi bất kỳ kỹ năng nào. Điều này cho phép
        họ chọn bất kỳ lĩnh vực nào quan tâm và trở nên xuất sắc trong đó. Bản
        tính thiếu quyết đoán không gây nhiều nguy hiểm cho họ bởi vì họ có thể
        định hình bất kỳ sự nghiệp nào thành con đường thành công.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người này thích nắm quyền lực và phù hợp cho vị trí điều hành hay
        quản lý. Bởi vì họ không thích nhận mệnh lệnh, tốt nhất là họ làm
        freelance tự do hoặc doanh nhân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khả năng ăn nói tốt giúp họ dễ chọn nghề nghiệp trong ngành nghệ thuật.
        Tuy nhiên, họ cũng giỏi trong quan hệ công chúng hay quảng cáo, bởi vì
        họ cũng khá ngoại giao và sáng tạo.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Công Việc:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người này thích nắm quyền và thường sở hữu tổ chức kinh doanh. Họ
        cũng phù hợp cho những vị trí cao trong tổ chức như điều hành hay quản
        trị.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Ngọ giỏi trong ngành luật, quan hệ công chúng, nhân sự, dịch
        vụ khách hàng, quảng cáo bởi vì kỹ năng giao tiếp tốt. Họ cũng có thể
        tham gia những công việc tập trung vào lợi ích xã hội như giảng dạy, tâm
        lý...
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Ngọ sáng tạo cao, khiến cho họ rất phù hợp với các nghề liên
        quan đến nghệ thuật như vẽ, âm nhạc, vũ đạo và nhà hát. Họ thích làm cho
        người khác vui vẻ và tim ra những gì tạo động lực cho họ. Do đó, họ có
        thể làm các ngành chủ về giải trí như câu lạc bộ và nhà hàng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Những Đặc Tính Trong Sự Nghiệp - Công Việc:</b> năng động, sáng tạo,
        chú trọng gia đình, xã giao, thực dụng, ăn nói giỏi, lý tưởng, thông
        minh, tập trung bản thân, cảm giác thấp kém, lười biếng, cảm xúc bất ổn,
        bất an, thiếu kiên nhẫn.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Quan Hệ - Gia Đình:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Quan hệ tình cảm là quan trọng nhất đối với người Nhâm Ngọ. Họ thích
        quan tâm đến người khác và cũng muốn được yêu. Họ thích tìm kiếm tình
        yêu lý tưởng và bạn đời lý tưởng
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đôi khi, sự thiếu quyết đoán trong tâm trí và tính cách đa nghi sẽ phá
        huỷ mối quan hệ của họ. Tuy nhiên, những người này có thể là bạn đồng
        hành tốt nhất bởi vì họ khá trung thành, rất đam mê và thích an ủi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Ngọ biểu đạt tốt. Họ mong chờ người bạn đời của họ cũng như
        vậy. Họ dễ cảm thấy thất vọng và luôn tìm kiếm mối quan hệ tình cảm có
        thể khơi gợi trí óc cuả họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đàn ông Nhâm Ngọ có thể phù hợp với mọi kiểu phụ nữ. Họ đạt được thành
        công lớn khi quan hệ với phụ nữ giàu có. Mặt khác, phụ nữ Nhâm Ngọ sẽ
        không tốt nếu người bạn đời kém năng lực hoặc tư duy kém hơn người khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Ngọ rất quảng giao và hài hước, khiến cho họ được nhiều người
        thích. Mọi người thường cảm thấy người Nhâm Ngọ cuốn hút vì sự sáng tạo
        và ăn nói tốt. Họ rất giỏi bày tỏ cảm xúc và quan tâm, biểu thị họ rất
        thông minh và nhạy cảm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Ngọ có thể rèn giũa kỹ năng và sự sáng tạo của họ bằng cách
        tập trung vào các khía cạnh tích cực của cuộc sống hơn là chú tâm đến
        mảng tiêu cực hoặc những khó khăn của quá khứ. Họ có mong muốn mạnh mẽ
        cần phải bày tỏ bản thân với người thân yêu và trở thành những người bạn
        đồng hành tốt. Họ có thể rất chung thuỷ và tuy nhiên cũng khá lo lắng và
        sợ hãi khi nghĩ đến vấn đề khó khăn trong mối quan hệ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ đôi khi cảm thấy bất an trong mối quan hệ mặc dù họ có thể rất yêu
        thương và quan tâm. Sự bất an hay thiếu quyết đoán có thể phá huỷ tình
        cảm hay đem đến nỗi đau về cảm xúc. Tuy nhiên, bất kể là số lần thất.
        vọng người Nhâm Ngọ thường gặp trong đời, họ không bao giờ chùng bước
        torng hành trình đi tìm kiếm mối qnan hệ tình cảm lý tưởng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Ngọ rất cuốn hút, thích giải trí. Họ là những người bạn rất
        tuyệt vời cũng như là người chủ hiếu khách trong các buổi tiệc xã giao.
        Nếu phải ở một mình, họ sẽ chìm đắm trong sự cô đơn và những người khác
        có thể cảm thấy tiêu cực. Do đó người Nhâm Ngọ giỏi kết bạn và thích tận
        hưởng tình bạn. Bởi vì họ luôn tìm kiếm tình yêu lý tưởng, họ có thể
        nghi ngờ mọi mối quan hệ tình cảm lãng mạn trong đời. Nếu những người
        này biết học cách thích nghi và điều chỉnh tuỳ theo người bạn đời, họ sẽ
        hạnh phúc trong đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Ngọ thích sự hứng khởi và thể hiện tình yêu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Phụ nữ Nhâm Ngọ không phù hợp cho đời sống hôn nhân trừ khi người chồng
        có năng lực giỏi hơn họ. Người đàn ông Nhâm Ngọ may mắn hơn, và có thể
        có cuộc sống giàu có và thịnh vượng sau khi kết hôn. Nếu đàn ông Nhâm
        Ngọ kết hôn với phụ nữ giàu có thì họ sẽ đạt được nhiều thành công và
        giàu có hơn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Trong Tình Yêu - Quan Hệ:</b> ưu tiên cho gia đình và
        tình yêu, rất nhạy cảm, thích xã giao, bị cuốn hút bởi những người có sở
        thích khác nhau, ấm áp, tận tâm và quan tâm, thể hiện sự quan tâm sâu
        sắc về tình cảm cá nhân.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhâm toạ trên Tài Tinh và Quan Tinh mà không có tạp khí. Điều này hình
        thành nên cách cục Quan Quý. Cách cục này rất tốt cho thành công trong
        sự nghiệp. Có Đinh, Nhâm và Tài Tinh cùng nhau tạo thành kết hợp ám chỉ
        người đàn ông Nhâm Ngọ có thể trở nên giàu có sau khi kết hôn với phụ nữ
        giàu. Kết hợp của Nhâm và Ngọ (có cả Thuỷ và Hoả) biểu thị sự hấp dẫn
        đối với quan vận. May mắn có thể đến dưới nhiều hình thức và trộn lẫn
        với cả thành công và mạo hiểm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Ngọ rất sâu sắc và gìau tình cảm. Họ thường để trái tim sai
        khiến và cần sự vui vẻ khoái lạc trong tình yêu cũng như hôn nhân đối
        với họ là phải hạnh phúc. Họ tử tế, quan tâm, rộng lượng và suy nghĩ
        nhiều. Họ nhân từ và bao dung nhưng họ cũng có thể phải trải qua những
        lúc tâm trạng dao động lên xuống, cũng nhiều lúc cảm thấy mất kiên nhẫn
        và trở nên thô lỗ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Ngọ có trực giác cao cũng như thích tiếp thu các ý kiến mới
        mẻ. Họ là người có tầm nhìn tham vọng dám mơ ước lớn nhưng nếu họ giữ
        một tầm nhìn hiện thực trong tâm trí thì họ có đủ kỹ năng để đạt mục
        tiêu. Họ có đầu óc sắp xếp công việc khi cần thiết nhưng cũng có thể tỏ
        ras thích hưởng thụ quá nhiều và lười biếng nếu trong lá số có quá nhiều
        hành Thuỷ. Họ thích du lịch và thường mở mang đầu óc và trải nghiệm cá
        nhân thông qua việc tương tác với những người từ các nền văn hoá khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ sống lý tưởng cao và thích các hoạt động nhân đạo và thường quan tâm
        nhiều đến công bằng xã hội. Họ thích dẫn đầu và không ngại thách thức
        các tình huống mà họ xem là bất công hay cản trở sự tiến bộ của họ. Họ
        không thích bị kiềm hãm hay tỏ ra sáng tạo và nổi loạn. Họ cũng rất
        thích những gì kịch tích và do đó phù hợp với công việc biểu diễn nghệ
        thuật như âm nhạc, nhà hát.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trong lá số Bát Tự, vị trí của Nhâm Thuỷ trên can và Ngọ Hoả ở địa chi
        tạo thành cách cục nổi tiếng Thuỷ Hoả Ký Tế. Vị trí Thai nguyên của Vòng
        Trường Sinh của Nhâm là ở Ngọ, biểu thị nước này dễ tưới tiêu. Điều này
        có nghĩa là ngay cả nếu như người Nhâm Ngọ được trang bị để vượt qua
        những lúc căng thẳng về tinh thần.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Lá số sẽ lý tưởng nếu hành Thuỷ và Hoả trong lá số Bát Tự được giữ ở mức
        cân bằng. Miễn là sự cân bằng giữa hai hành này được giữ thì lá số này
        sẽ hài hoà và có lợi. Tuy nhiên, nếu không giữ được sự cân bằng thì cách
        cục này rất bất lợi gây ra cho người Nhâm Ngọ đau khổ trong cả đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Để giúp đạt được sự cân bằng trong lá số Bát Tự, Nhâm có thể trở thành
        có lợi với sự hỗ trợ tích cực ủa Đinh Hoả. Nếu Đinh hiện diện ở Thiên
        Can của Nhâm Ngọ thì biểu thị may mắn trong hôn nhân, đặc biệt là nếu so
        sánh với những người có ngày sinh là Nhâm Tý. Tuy nhiên, kết quả này chỉ
        xảy ra khi hôn nhân là tiến trình phát triển quan hệ tự nhiên, nó sẽ
        không ảnh hưởng nếu một trong hai bên bị ép buộc cưới.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu phụ nữ Nhâm Ngọ có chồng thuộc Thất Sát Mậu Thổ thì họ dễ có hôn
        nhân ổn định và viên mãn. Nó biểu thị người chồng sẽ có sự nghiệp rất
        thành công và anh ta sẽ đi lại nhiều nhưng chính thời gian xa nhau sẽ
        giúp cho quan hệ tình cảm có đất để phát triển. Nếu không phải như vậy
        thì người phụ nữ Nhâm Ngọ nên chọn lấy người đàn ông có ít tham vọng,
        đặc biệt nếu hành Nhâm mạnh trong lá số của cô ấy. Ở đây ám chỉ rằng nếu
        người này không tuân thủ theo như vậy thì sẽ có rạn nứt giữa cô và chồng
        trong hôn nhân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Ngọ sinh tháng mùa Hè và Xuân thì sẽ gặp khó khăn mà không có
        được sự trợ giúp từ quý nhân trong đời, họ có thể phải đối mặt vận xui
        và phải làm việc gấp đôi để đạt mục tiêu. Người sinh vào tháng mùa Thu
        và Đông có thể phải vất vả để ra được quyết định tài chính tốt và có thể
        thấy được thu nhập rơi vào túi mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh vào tháng Mão và có Tý trong lá số thì khi lớn tuổi hơn sẽ
        vất vả hơn. Họ được khuyên là nên tích luỹ nhiều kinh nghiệm và sức mạnh
        cũng như tiết kiệm để chuẩn bị cho những lúc xui rủi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Ngọ sinh vào ban ngày sẽ có khả năng giàu có và dễ trở nên
        may mắn về tài chính. Người sinh ban đêm sẽ thịnh vượng với tài lộc đến
        từ việc kinh doanh hoặc thông qua hôn nhân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi Nhâm toạ trên Ngọ thì khơi gợi năng lượng ban đầu của Đinh Hoả Chính
        Tài và năng lượng hỗ trợ Kỷ Thổ Chính Quan. Đây là người thực thi nhiệm
        vụ đang chịu phải áp lực. Nhâm không thích Kỷ Thổ vì đất này làm bẩn
        nước. Nhâm có xu hướng say quyền lực. Họ vốn dĩ có quyền lực và thông
        minh, điều gì sẽ xảy ra khi người xung quanh đầu hàng trước quyền lực
        của Nhâm?
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Chính Tài khiến cho họ không ngừng nỗ lực hướng về mục tiêu và họ không
        suy nghĩ rõ ràng...hay không chắc chắn về việc họ có thể sai lầm trên
        đường đi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngọ hợp với Mùi trong kết hợp mang theo Kỷ Thổ Chính Quan, Ất Mộc Thương
        Quan và Đinh Hoả Chính Tài. Không tốt nhiều cho nữ giới về mặt tình cảm
        vì Thương Quan bị thu hút vào và gây hại cho sao người chồng là Chính
        Quan. Người nam thì tốt hơn vì Chính Tài là người vợ được đem đến. Nam
        giới ngày Nhâm Ngọ sẽ giao tiếp nhiều hơn và cũng có Ất làm màng lọc để
        loại bỏ Kỷ Thổ gây rắc rối.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngọ xung với Tý trong Lục Xung xua đuổi Quý Thuỷ Kiếp Tài. Đây là điều
        tốt cho nam giới vì Kiếp Tài không thể quấy rối Chính Tài là người vợ.
        Điều này cũng có nghĩa là cả nam và nữ đều không thể đề kháng chống lại
        việc lãng phí tiền bạc. Sự cạnh tranh xét cho cùng không phải là điều họ
        thích mà họ luôn thích dĩ hoà vi quý.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đây là trụ ngày mà mang theo nhiều tài lộc và quyền lực đúng nghĩa nhất.
        Họ tuân thủ theo tất cả mọi quy luật của xã hội bao gồm cả học hành
        siêng năng, kiếm tiền, xây dựng một gia đình và cống hiến ngược lại cho
        cộng đồng.
      </p>
    </div>
  );
};

export const NhamThin = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Nhâm Thìn: nữ giới sẽ rất nữ tính và duyên dáng, có thể
        giúp đỡ chồng. Nếu là nam giới, có khả năng kinh doanh tốt nhưng thiếu
        kỹ năng quản lý tài lộc, có thể được cấp trên ưu ái, có quyền lực nhưng
        vận hôn nhân kém, dễ tái giá, hôn nhân bắt đầu đi xuống từ sau trung
        niên.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người ta hay nói rằng không có người nào thật sự là một hòn đảo...không
        ai có thể hoạt động một mình nhưng điều gì sẽ xảy ra nếu một số người
        trên 1 hòn đảo và có thể tự nuôi sống mình. Người Nhâm Thìn may thay có
        thể tiếp tục sống mà không cần nhiều sự đồng thuận từ người khác. Họ là
        một hòn đảo được bao quanh bởi đại dương rộng lớn, họ không có sự lựa
        chọn nào khác ngoài việc phát triển kỹ năng sinh tồn độc lập.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhâm Thuỷ là đại diện cho nước lớn, là nước mà có thể thấy rõ phương
        hướng chảy ra đại dương. Nước là hành duy nhất có thể vượt qua được trở
        ngại, vì họ sẽ tìm cách đi vòng qua những gì cản đường họ. Người sinh
        ngày Nhâm nhận thức rõ mục tiêu và họ nhìn trở ngại như một thứ gì cần
        phải vòng tránh hơn là đối đầu trực tiếp. Ngay cả nếu như bạn muốn xây
        tường bao vây họ, họ cũng sẽ chờ một vết nứt hình thành dưới áp lực to
        lớn của họ và bắt đầu trốn thoát.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhâm Thìn cũng có thể hình dung như là một ngọn thác đẹp đẽ, yên bình ơ
        giữa một vùng rừng sâu. Có những người tỏ ra tình cảm nhưng thực sự thì
        họ có ý chí mạnh mẽ. Họ có khả năng tập trung cao vào những điều họ
        muốn, vượt qua nhiều thử thách, cố gắng và trở nên rất thành công về
        sau.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Thìn cũng là Khôi Cương, một thuật ngữ dùng để chỉ những
        người sinh tồn bẩm sinh. Họ có tinh thần bất khuất và không dễ bị hạ
        gục. Vì những đặc tính này mà người Nhâm Thìn dễ trở thành lãnh đạo giỏi
        và có sự nghiệp trong lĩnh vực doanh nghiệp. Họ có kỹ năng phân tích
        giúp họ thích hợp cho sự nghiệp trong ngành IT, máy tính và công nghiệp
        mạng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ là những người tiêu tiền như nước, không tiết kiệm tiền khi còn trẻ
        nhưng với tuổi già thì họ sẽ quản lý tiền tốt hơn. Trong thời kỳ này, họ
        sẽ tích luỹ được nhiều tài lộc và thịnh vượng. Họ có cá tính thu hút và
        khiến cho họ dễ toả sáng trong các nhóm xã hội. Ngoài ra, họ có trực
        giác cao và thái độ kiên trì giúp họ có sức mạnh để đạt được bất cứ gì
        họ mơ ước. Phụ nữ ngày sinh này sẽ rất kiên trì với khả năng quản lý gia
        đình khá tốt. Ngoài ra, phụ nữ ngày sinh này sẽ đem đến may mắn và thịnh
        vượng bất kỳ nơi nào họ đi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Điều quan trọng nhất đối với những người này là nếu có thêm Nhâm trong
        lá số. Nếu đạt được như vậy, họ khá thực dụng và tham vọng. Họ có quyết
        tâm cao để làm việc vất vả và đạt thành công trong bất kỳ lĩnh vực nào.
        Họ nhắm đến mục tiêu cao và muốn trở thành hành đầu trong cuộc chơi. Họ
        có tầm nhìn mạnh mẽ giúp họ nhạy cảm với các cơ hội. Họ có khả năng để
        chuyển bất kỳ cơ hội nào nhỏ nhất thành kết quả thành công lớn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tất cả những công việc vất vả này sẽ có xu hướng họ tiêu hao năng lượng
        và do đó khiến họ đôi khi trì hoãn. Họ có thể đòi hỏi những phần thưởng
        về vật chất và đôi khi cần động lực để hoàn thành những gì mình đã bắt
        đầu. Tuy nhiên, người Nhâm Thìn có động lực và sức sống để chinh phục
        khó khăn và trở nên bất bại.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ khá dễ giao tiếp, thích hợp tác và thích làm việc nhóm. Mối quan hệ
        cũng rất quan trọng đối với người Nhâm Thìn. Họ rất tin tưởng và đầu tư
        tất cả những gì họ có với sự thành tâm trong quan hệ. Tình bạn và quan
        hệ yêu đương rất quan trọng đối với kích thích tư duy, nếu không đạt
        được cả hai điều trên sẽ khiến họ dễ buồn chán.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Thìn khát khao an toàn về cảm xúc và có thể đôi khi thích
        hướng nội vì cảm thấy bất an. Tuy nhiên, họ có sức mạnh ý chí và thích
        bước vào những mối quan hệ an toàn và ổn định. Ngoài ra, những người này
        cũng có thể đem đến sự ổn định vào công việc hoặc căn nhà bất kể họ phải
        chịu bao nhiêu áp lực từ mọi phía. Họ có thể thể hiện sực cuốn hút bằng
        cách biểu lộ sự duyên dáng hay một nụ cười khả ái.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người này chung thuỷ, nhạy cảm, trọng danh dự và thẳng thắn. Họ
        nói thẳng ra suy nghĩ của mình, điều mà có thể làm phật lòng nhiều
        người. Vì đó là bản tính của họ, họ không thật sự bận tâm về hậu quả. Họ
        ủng hộ những ai tin tưởng ngay cả khi điều đó đồng nghĩa việc họ phải
        đứng ra 1 mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Thìn có đam mê đi xa khắp thế giới. Họ luôn tìm cách mở rộng
        kiến thức bằng mọi giá. Họ đa tài và có thể làm trong các lĩnh vực như
        công nghệ, khoa học, xã hội hay nghệ thuật. Bất kể họ lựa chọn nghề
        nghiệp nào, họ sẽ tận tâm và sẽ thành công bất kỳ nơi nào họ đi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có mặt trực giác và điều này sẽ giúp họ páht triển trong lĩnh vực
        triết học, chính trị, tâm linh và huyền học. Trí tuệ của họ giúp họ vượt
        qua trở ngại dễ dàng. Nó cũng đem đến cho họ sức mạnh cần thiết để dừng
        lại, hồi phục và tái khởi động lại từ đầu khi họ cảm thây bế tắc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù họ thường làm người khác khó chịu với sự thẳng thắn, áp đăt và
        các hành động hấp tấp, họ cũng có khả năng phân tích nhanh tình huống và
        hiểu được lỗi. Điều này cho phép họ cảm thông với người khác và đền đáp
        bất kỳ lỗi lầm nào. Khả năng ăn nói và chất giọng dễ chịu giúp thu hút
        người khác cũng tương tự như vẻ ngoài duyên dáng của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ là người có tầm nhìn cao và có thể biến giấc mơ thành hiện thực với
        động lực bên trong. Họ có trí tưởng tượng mạnh mẽ và sự sáng tạo giúp
        củng cố sự nhạy cảm của họ với môi trường. Họ thể hiện sức mạnh cảm xúc
        và thích sức mạnh tri thức, điều này giải thích được tại sao họ liên tục
        đi tìm kiến thức.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có thể giao tiếp với con người tốt đẹp và trong mọi tình huống dễ
        dàng nhất là khi phải sử dụng kiến thức hiểu biết cao. Họ yêu thích môi
        trường tích cực và luôn cố gắng giữ môi trường hài hoà ở bất kỳ nơi đâu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Của Nhâm Thìn:</b> thực dụng, tham vọng, siêng năng,
        trực giác, thích xã giao, chung thuỷ, rộng lượng, hấp tấp.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Thìn năng động và thích xã giao cũng như yêu thích việc hoạt
        động trong các đội nhóm và hợp tác. Họ có khả năng phân tích và trực
        giác, những điều này là tài năng cho phép họ đánh giá tình huống nhanh
        chóng và có thể dễ dàng ra những ý tưởng đầu tư kinh doanh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ không sợ dấn thân vào vùng nước sâu trước khi họ biết đến nguy hiểm.
        Họ chắc chắn về sự thắng lợi trong bất kỳ quyết định hay hành động nào.
        Họ khá nhạy cảm với môi trường xung quanh và thích tham gia các hoạt
        động kích thích về trí tuệ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ thường lo lắng và không yên, chính những điều này dẫn đến sáng tạo.
        Họ thường cảm thấy rằng họ có mục tiêu trong đời và thường xuyên tìm
        kiếm các cơ hội và ý tưởng mới để mở rộng sự hiểu biết.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thái độ lý tưởng của họ thường bao phủ xung quanh họ cũng như giúp giải
        quyết mâu thuẫn giữa hiện thực và lý tưởng. Họ có xu hướng áp đặt, hấp
        tấp và do đó thường muốn hướng đến việc nâng cao bản thân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ giỏi trong mọi hoạt động thách thức về trí tuệ và có thể trở thành
        những thành viên đội nhóm xuất sắc. Họ hi sinh vì người mà họ cảm thấy
        tin tưởng và người mà họ yêu thương. Họ thể hiện khả năng thấu hiểu cảm
        xúc và quyền lực.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Công Việc:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Thìn có óc phân tích cao và trí tuệ giup1 họ có thể làm trong
        những lĩnh vực liên quan đến công nghệ, chính trị và kết nối. Họ có khả
        năng quản lý, tổ chức giúp họ phù hợp với môi trường doanh nghiệp. Họ
        cũng có thể khá đồng cảm, điều này có thể dẫn họ đến việc tham gia vào
        những ngành nghề phục vụ cộng đồng. Khía cạnh sáng tạo giúp họ trong các
        ngành nghề như nghệ thuật, âm nhạc, vũ đạo và nhà hát.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Một trong những đặc điểm quan trọng nhất của người này là sự kiên trì và
        ý chí để đạt bất kỳ mục tiêu nào. Đây thật sự là phẩm chất đáng trân
        trọng giúp họ có thể phù hợp cho bất kỳ sự nghiệp nào họ chọn, từ y học,
        nghệ thuật, khoa học, công nghệ hay kinh doanh. Tuy nhiên, những người
        này tốt hơn nên tự làm, tốt hơn là nhà tuyển dụng hơn là người làm công.
        Họ là những người yêu sự hoàn mỹ và sẽ trở thành lãnh đạo kinh doanh
        giỏi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Của Người Nhâm Thìn Trong Công Việc:</b> trực giác
        cao, lý tưởng, chủ nghĩa hoàn mỹ, siêng năng, kiên trì, rất thích xã
        giao, sáng tạo, hoạt động đội nhóm tốt, thống trị, hấp tấp, chần chừ,
        bất an, ngoan cố.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Hôn Nhân - Quan Hệ - Gia Đình:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Thìn xây dựng tình cảm dựa trên sự hấp dẫn về tri thức và
        kích thích về đầu óc. Họ tò mò về người khác và bản tính mạnh mẽ, thích
        tranh luận giúp họ dễ tham gia vào triết học, chính trị và tâm linh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ đầu tư toàn bộ thời gian và tiền bạc vào những mối quan hệ thú vị và
        thường có xu hướng hình thành mối liên kết về cảm xúc với những người có
        suy nghĩ giống họ. Người Nhâm Thìn yêu thích sự ấm áp và quan hệ nồng ấm
        với người khác giới. Họ khá bất an trong tình cảm. Tuy nhiên, họ thường
        có mái ấm ổn định và nhạy cảm trong quan hệ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khía cạnh quan trọng nhất về những người này là họ luôn cố gắng giữa
        quan hệ hoà hợp với người bạn đời của họ mặc dù có thể gặp nhiều áp lực.
        Họ đáng tin cậy và rất ủng hộ trong tình cảm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Một khía cạnh đặc biệt khác về họ là những người này khiến cho bạn đời
        cảm thấy đặc biệt và suy nghĩ về nhiều cách để tạo động lực cho
        vợ/chồng. Tiền đề cho quan hệ tình cảm cuả họ bao gồm tìm một người chia
        sẻ cùng hệ thống giá trị và thấu hiểu cũng như họ. Họ yêu những người
        siêng năng và bị kích thích bởi những người khác giới dám nghĩ dám làm
        và thích thử thách.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ tận tâm với bạn đời nhưng mất nhiều thời gian để có thể tìm được tình
        yêu thật sự trong đời. Bởi vì điều này mà người Nhâm Thìn được xem như
        trải qua nhiều mối tình trước khi tìm được bạn đời hoàn hảo. Những người
        này rất ấm áp, tử tế và quan tâm sâu sắc cũng như là người vợ/chồng lý
        tưởng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Thìn có cá tính duyên dáng và do đó dễ kết bạn. Họ là người
        tri thức thích kết nối với những người thẳng thắn và có khả năng kích
        thích trí óc. Bản chất của người Nhâm Thìn giúp cho họ có thể cộng tác
        với những người khác có cùng bản chất và cho họ sự hào hứng trong việc
        tham gia vào các hoạt động tri thức.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ thường có lý tưởng và là những người bạn bè turng thành và quan tâm.
        Những người này thường rộng rãi và thể hiện sự tử tế khi có cơ hội. Họ
        thích trộn lẫn kinh doanh và niềm vui và do đó là một trong những người
        thích quảng giao.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người này cũng có cảm gíac bất an về quan hệ tình cảm, điều có thể
        khiến họ căng thẳng. Tuy nhiên, hầu như người Nhâm Thìn sẽ thích ở bên
        cạnh những người cùng xây dựng niềm tin và làm việc hướng về mục tiêu
        chung.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thông thường thì người Nhâm Thìn kết hôn sớm thường đổ vỡ. Vợ chồng ly
        hôn sau một thời gian ngắn. Do đó, những người này thích hợp nếu kết hôn
        muộn. Tuy nhiên, nếu có ngũ hành Hoả là dụng thần hiện diện thì đây sẽ
        là ngoại lệ vì nó giúp cân bằng mối liên kết cảm xúc của Nhâm Thìn và
        vợ/chồng. Khi điều kiện phù hợp xuất hiện, điều này sẽ báo hiệu hôn nhân
        rất tốt đẹp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Trong Tình Yêu - Hôn Nhân:</b> thích người tri thức,
        có lý tưởng cao và chung thuỷ, thích xã giao và cuốn hút, không thích ở
        một mình vì cảm giác bất an, rất tận tâm, yêu thương và quan tâm, cá
        tính rộng rãi thể hiện qua cử chỉ tốt bụng.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:{" "}
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhâm Thìn biểu thị thịnh vượng dài lâu bởi vì toạ trên địa chi Thuỷ Khố
        trong lá số Bát Tự. Họ có xu hướng rất giàu có và họ có động lực để sử
        dụng toàn bộ các công cụ cần thiết để theo đuổi thành công. Điều này là
        bởi vì Kiếp Tài trong lá số sinh cho Thực Thần, từ đây có ảnh hưởng mạnh
        lên Thất Sát.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trụ Nhâm Thìn còn được biết đến như là Nhâm Ký Long Bối Cục. Khi tìm
        được thì Hợi ở trụ giờ thì sẽ tạo thành Long Quy Đại Hải Cục là giờ sinh
        rất tốt cho người Nhâm Thìn. Giờ Ngọ là giờ sinh rất xấu. Điều này biểu
        thị Thuỷ Long hạ trên đất khô.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày này có cá tính mạnh và thích được dẫn dắt bởi trực giác
        và sự phán đoán. Họ có kỹ năng phân tích tốt và thấu hiểu do đó họ chú
        trọng đến việc họ dựa vào bản thân sẽ đem lại kết quả tốt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Là một người theo chủ nghĩa cá nhân, người Nhâm Thìn sẵn sàng đứng lên
        bảo vệ cho những gì họ tin tưởng. Họ làm việc vất vả và là người theo
        chủ nghĩa hoàn mỹ sẵn lòng làm việc hướng đến mục tiêu với sự thực dụng
        và kiên quyết. Họ có thể lười biếnt và có xu hướng trì hoãn. Họ cũng
        ghét quy trình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Thìn có cá tính thích giao tiếp và sự hấp dẫn khiến họ luôn
        toả sáng trong các tình huống giao tiếp xã hội. Họ không thích ở một
        mình và có thể trở thành bạn bè có trách nhiệm và trung thành. Nhu cầu
        đồng hành, tuy vậy có thể dẫn họ đến cảm giác bất an. Sự bất an này có
        thể khiến họ tỏ ra căng thẳng, không thích giao tiếp và đôi khi hướng
        nội.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Lá số người Nhâm Thìn có thể tạo thành cách cục Long Tiềm Thâm Uyên nếu
        trụ ngày gặp thêm Hợi và Tý trong lá số. Cách cục đặc biệt này là mở
        rộng của Dương Nhẫn Cục có thể biến thành tích cực. Điều này được xem là
        có lợi nhiều và là cách cục đặc biệt cho người Nhâm Thìn. Mặc dù rất
        hiếm, cục này có thể giúp hình thành nên người có thể trải nghiệm cuộc
        sống phi thường. Nếu lá số chứa can Giáp hay Canh, nó cũng sẽ rất tốt
        biểu thị người này sẽ trải qua những thành công về tài chính.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Thìn tuy vậy có thể trải nghiệm sự thịnh vượng chỉ khi không
        kèm với Thìn trong lá số Bát Tự. Họ cũng sẽ khá giàu có khi gặp nhiều
        hơn 1 Dần trong lá số Bát Tự. Tuy nhiên, Tuất là vô tình với Thìn và bất
        lợi cho những người này vì sẽ tạo nên lá số bất ổn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi Nhâm xuất hiện nhiều trong lá số Bát Tự, người này sẽ cảm thấy họ đi
        lại nhiều và họ phải vất vả mới có thể gắn bó ở một nơi một thời gian
        dài vì hành Thuỷ đại diện cho tính linh hoạt. Họ càng đi nhiều, Thủy sẽ
        dần dần tan và Nhâm sẽ biến thành Quý. Điều này biểu thị cuộc sống sẽ
        liên tục phát triển và tiến lên và khả năng vượt mọi trở ngại trên đường
        đi. Thuỷ ấm sẽ biểu thị sự hiện diện của Bính Hoả có lợi và nước chảy
        nhiều, nước sạch trong lá số biểu thị thành công.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Để các cục đặc biệt hình thành, trụ Nhâm Thìn nên gặp thêm nhiều Kim
        hoặc Thuỷ hoặc Mộc để có lợi. Trong tình huống này, thêm Thân, Tý, Thìn,
        Hợi hay Tý Sửu trong lá số biểu thị vận may trong đời Nhâm Thìn. Điều
        này bởi vì để Nhâm Thìn hoá thành Long Tiềm Thâm Uyên cục thì phải có
        đầy đủ cục thuỷ hoàn chỉnh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sẽ bất lợi nếu trụ ngày gặp nhiều Hoả hay Thổ, nếu Mùi, Dần, Tuất trong
        lá số Bát Tự biểu thị rằng cát cục sẽ không hình thành và rằng cuộc đời
        họ sẽ bình thường thay vì phi thường.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Thìn sinh vào mùa Xuân hay Hạ thì sẽ cuốn hút. Họ sẽ là diễn
        giả tài năng và lãnh đạo giỏi với khả năng tạo động lực cho người khác.
        Sinh vào mùa Thu hay Đông sẽ sống vô tư, không phải bởi vì họ sẽ trải
        nghiệm ít rắc rối hơn mà bởi vì họ có cách để giải quyết rắc rối trước
        khi chúng trở thành khủng hoảng. Cuộc đời ít may mắn chờ đợi người Nhâm
        Thìn sinh vào tháng Mùi. Họ sẽ đối mặt nhiều thách thức trong sự nghiệp
        và sẽ thường xuyên phải làm việc vất vả gấp đôi đồng nghiệp để cạnh
        trnah cả những việc nhỏ nhất.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh vào ban ngày sẽ sống cuộc sông giản dị. Họ sẽ cảm thấy vui vẻ
        khi theo đuổi hạnh phúc và viên mãn đối nghịch với quyền lực và tài sản
        vật chất. Mặt khác, người sinh vào ban đêm sẽ sống trường thọ và thịnh
        vượng. Họ có nhiều may mắn về tài chính và được trao cho nhiều cơ hội để
        theo đuổi tài lộc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhâm toạ Thìn mang theo chính khí của Thất Sát Mậu Thổ kèm với 2 phụ khí
        của Thương Quan Ất Mộc và Kiếp Tài Quý Thuỷ. Có căng thẳng ở mức độ cao
        cùng với năng lượng dữ dội chất chồng lên. Bạn có Thuỷ nuôi dưỡng, Mộc
        sống còn và Thổ cân bằng, bạn sẽ thật sự có môi trường tốt nuôi dưỡng
        bản thân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Hành động của họ là lời nói bởi vì Thương Quan chủ về ồn ào và khả năng
        giao tiếp, kỹ năng giao tiếp của họ có thể thay đổi như đám mây bị gió
        thổi đi nhưng hành động đồng thuận của họ là Thất Sát nên sẽ giữ nguyên
        như một ngọn núi không dời chuyển. Phương pháp của họ sẽ thay đổi theo
        thời gian nhưng mục tiêu giữ nguyên, một khi họ đã quyết tâm đạt được
        điều gì, thì Kiếp Tài Ất Mộc đảm bảo rằng họ sẽ thắng mọi cuộc ganh đua.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thìn hợp với Dậu mang theo hành duy nhất của Tân Kim Chính Ấn. Sao này
        trung lập cho cả nam và nữ xét về mặt tình cảm. Ấn đại diện cho tri thức
        và sự hỗ trợ. Họ sẽ không bao giờ thiếu người đến giúp đỡ cũng như kiến
        thức giúp đỡ trong cuộc sống. Nếu bạn muốn trở thành bạn đời của người
        Canh Thìn thì bạn sẽ cần phải tỏ ra rất thông minh và độc lập vì họ mong
        đợi bạn cũng có thể chạy nhanh như họ, nếu bạn trượt nga vì bất kỳ
        nguyên nhân gì không dự báo được, bạn sẽ bị bỏ lại phía sau.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thìn xua đuổi Tuất bao gồm Mậu Thổ Thất Sát, Đinh Hoả Chính Tài và Tân
        Kim Chính Ấn. Ở đây bạn có thể thấy sao người vợ Chính Tài bị từ chối.
        Bạn có thể đã biết mối quan hệ tình cảm không phải là điều gì mà người
        nam Canh Thìn chú trọng. Họ quá chú tâm vào ý tưởng muốn thực hiện mọi
        thứ và nhu cầu của người nữ sẽ cản lối họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bạn có thể thấy người Canh Thìn khá dữ dội và thường cố gắng đạt mục
        tiêu mà không cần bạn hỗ trợ. Tốt nhất là bạn không nên đứng cản đường
        họ, bạn cần phải xây dựng mục tiêu của bạn. Họ giỏi trong việc vượt qua
        những tình huống khó khăn.
      </p>
    </div>
  );
};

export const NhamDan = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Nhâm Dần có mệnh sang quý và giàu có. Người này rất may
        mắn, có quan hệ tình cảm giao tiếp tốt, liều lĩnh lãng mạn, chủ động,
        thích hướng dẫn và dạy cho người khác. Dễ có tài lộc bất ngờ. Nam giới
        thì hiểu suy nghĩ phụ nữ, có vợ đức hạnh nhưng vợ hay bệnh. Với nữ giới,
        có năng lực cao nhưng tự cao và thường coi thường đàn ông.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ánh nắng, biển cả bao la và những cây dừa cao lớn, đây là cuộc sống hoàn
        hảo. Bờ biển mở rộng gắn liền với niềm vui và thời gian tự do trong cuộc
        sống. Bạn đã từ nghe nói đến sóng thần và siêu bão có xu hướng đổ bộ lên
        những nơi này chưa? Nhâm Dần thực chất là loại người này, nhiều niềm vui
        và khả năng nhưng cũng đi kèm với nguy hiểm lớn được che đậy.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhâm Thuỷ là hành của sự to lớn bao la. Nó bao phủ toàn bộ bề mặt trái
        đất và liên kết mọi người ở nhiều phía thông qua nước. Bởi vì họ là
        những người liên kết tự nhiên và có năng lực kết nối với người khác một
        cách nhanh chóng. Họ là những con tắc kè hoa với khả năng nhanh chóng
        hoà lẫn và trở thành 1 phần trong cuộc đời của bạn. Họ có thể đem đến
        cảm giác lạ lùng như một người bạn thân thất lạc từ lâu của bạn. Tuy
        nhiên cũng cần cảnh báo rằng họ khó lòng để buông bỏ, ngay cả khi bạn
        muốn chia tay hay không tiếp tục tình bạn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Nhâm Dần thường có tính cách như một cánh rừng nhiệt đới
        U Minh. Dày đặc, tối đen và bí ẩn. Đồng thời, những cánh rừng này có thể
        giàu nguồn lực và sản lượng. Họ thể hiện tính cách thu hút và thông minh
        nằm bên dưới vẻ ngoài huyền bí. Điều này khiến cho người khác tìm đến họ
        và cũng là nguyên nhân khiến cho họ nỏi tiếng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ cũng như một con sông trong rừng mưa nhiệt đới chảy mạnh nhưng có
        kiểm soát. Những người Nhâm Dần về bản tính thì rất thích giao tiếp và
        muốn tham gia vào các hoạt động tập thể. Họ quan tâm sâu sắc đến người
        khác. Họ rất trung thành và có sự phán đoán tốt khiến cho họ dễ trở
        thành bạn tốt của bất kỳ ai. Họ là những người cố vấn rất tốt cho ai
        đang cần sự giúp đỡ hay lời khuyên.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Dần có thể bộp chộp và nói thẳng, gây tổn thương và xúc phạm
        đến người xung quanh. Tuy nhiên, họ luôn chú trọng đến việc tự phê bình
        và tự tiến bộ, để giúp họ tự vượt qua khuyết điểm của mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ khá thực tế, trực giác và giàu kiến thức. Họ sở hữu đầu óc khách quan
        và có nhận thức rõ ràng. Những phẩm chất này cho phép họ thực hiện được
        công việc của họ với ít công sức và dễ dàng thành công trong mọi mảng mà
        họ chọn. Họ có kỹ năng quản lý tổ chức xuất sắc. Vì điều này, họ có thể
        phát triển tài năng và điều chỉnh kỹ năng của mình để thành công trong
        vô số lĩnh vực.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có kỹ năng giao tiếp con người tốt. Họ cũng có khả năng phân tích và
        lý luận giúp cho họ có thể chọn sự nghiệp tốt trong lĩnh vực quản trị,
        quán lý hya liên quan đến con người.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những nguòi Nhâm Dần có đam mê đạt mục tiêu cụ thể. Họ có khao khát kiến
        thức và thích kết thân với người có nhiều mối quan tâm khác nhau. Trí
        thức cao và sự quan tâm nhiều mảng rộng giúp cho họ có thể chọn sự
        nghiệp phù hợp trong mảng kinh doanh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có trực giác tốt và biết nhiều thông tin. Họ có thể thấy rủi ro trong
        kinh doanh và dám chấp nhận rủi ro để đạt được mục tiêu cuối cùng. Khả
        năng thành công cao của họ khiến họ khó lòng thất bại. Với năng lực này,
        họ có khả năng gây áp đặt. Họ không thích phục tùng người ở quyền lực
        cao hơn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ rất nhạy cảm với môi trường và thường để mắt tìm kiếm cơ hội. Họ kết
        hợp công việc và giải trí và do đó cũng tìm cách để biến một cơ hội nhỏ
        trở thành việc thương vụ kinh doanh lớn và thành công.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người Nhâm Dần cũng thường phải đối mặt với nhiều thách thức, đặc
        biệt là khi còn trẻ. Họ cũng dễ bị cản trở về mặt tài chính, khiến cho
        họ khó đạt được lợi nhuận cao. Khi họ lớn tuổi hơn, họ sẽ giành được may
        mắn, tài lộc và thịnh vượng. Nam giới sinh ngày Nhâm Dần có nhiều mối
        quan hệ tốt. Nhìn chung, trụ Nhâm Dần tốt cho phụ nữ bởi vì nhiều tài
        lộc và thịnh vượng luôn đi theo họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhìn chung người Nhâm Dần có trực giác cao, thực dụng, có lý tưởng và
        thích giao du. Họ rất quan tâm người khác, nhạy cảm và sống cảm xúc, tất
        cả những điều này khiến cho họ là người bạn đồng hành rất tốt. Họ biết
        cách ưu tiên mối quan hệ cá nhân trong cuộc sống.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Dần cũng có sự sáng tạo cao, những người này luôn tìm cách để
        thể hiện tài năng. Họ có nhu cầu muốn làm vừa lòng người khác. Người
        Nhâm Dần có thói quen tự dằn vặt bản thân và thường cảm thấy những nỗ
        lực cố gắng của họ là không bao giờ đủ. Điều này khiến cho họ cảm thấy
        bất mãn và rối trí. Ngoài ra, họ cũng luôn tự cố gắng động viên và cải
        thiện bản thân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ thường được biết đến như những chuyên gia tâm lý học bẩm sinh bởi vì
        họ có cách nhìn sâu sắc về tâm trí của người khác. Họ luôn cố gắng tìm
        ra điều gì gợi cảm hứng hay tạo động lực cho những người xung quanh họ.
        Họ rất rộng lượng và tử tế, những phẩm chất khiến cho họ luôn có thể cho
        những lời khuyên giá trị đối với bạn bè đang gặp khó khăn hoặc căng
        thẳng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Dần thường phát ngôn thẳng thắn và không sợ đối đầu. Họ
        thường tinh tế trong cách thao túng tình huống và tìm câu trả lời cho
        mọi câu hỏi xuất hiện trong tâm trí họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Dần rất dễ bị thu hút bởi những người biết cách vận dụng đầu
        óc cũng như những người đa tài. Người Nhâm Dần có nhận thức rất rõ mọi
        việc xung quanh họ và họ có tài trong việc phát hiện bạn bè và những
        người xung quanh có tài hay kỹ năng gì. Những người này cũng yêu thích
        đi lại vòng quanh thế giới và tích luỹ nhiều kinh nghiệm mà cuộc sống
        mang lại.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ rất thích phân tích và ăn nói khéo. Những phẩm chất này khiến cho họ
        dễ nảy sinh ra những ý tưởng, định hìinh nó và biến chúng thành những
        thu hoạch tuyệt vời. Họ có thể sử dụng ngôn ngữ để giải thích những ý
        tưởng tuyệt vời và kế hoạch sự nghiệp trong đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Một đặc tính độc đáo của người Nhâm Dần là họ dành tình yêu cho triết
        học và tâm linh. Họ thích nhân đạo cũng như biết cách nhìn nhận thế giới
        một cách khách quan và hiểu mọi thứ từ góc nhìn đặc biệt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ sở hữu vẻ ngoài tự tin và cá tính lôi cuốn khiến cho họ luôn dễ dàng
        có sự thu hút đặc biệt đối với người khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Của Người Nhâm Dần:</b> thích xã giao, nhạy cảm, phân
        tích, trực giác, hài hước, nói thẳng, tự tin, nhận thức bản thân.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày này sẽ giỏi giao tiếp con người, họ dễ dàng gia tăng
        giao tiếp xã hội. Họ nhanh nhạy, hài hước và thông minh. Những đặc tính
        này khiến cho họ dễ tham gia vào các cuộc tranh luận, cũng như họ thích
        tham gia vào những tập thể trò chuyện về nhiều chủ đề khác nhau.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Dần, mặc dù rất thông minh, không thích hoạt động một mình mà
        thích là 1 phần của đội nhóm, tập thể hay hợp tác. Họ có khả năng giao
        tiếp tốt và có thể tận dụng điều này để tiến bộ và thịnh vượng trong
        kinh doanh.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ giỏi thích nghi và có khả năng cải thiện kỹ năng trong mọi lĩnh vực.
        Họ có khả năng thành công trong mọi công việc mà họ theo đuổi. Họ luôn
        cảm thấy cần phải làm hài lòng mọi người, cũng như đôi khi cảm thấy nóng
        giận hoặc đau lòng nếu không đạt được điều gì họ mong đợi từ người khác.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có tri thức siêu việt và có một nhận thức sâu sắc giúp cho họ có khả
        năng xử lý thông tin một cách khách quan. Tuy nhiên, năng lực này đi kèm
        với những kỹ năng khiến cho họ nắm quyền. Điều này không nên bị nhầm lẫn
        với cảm giác thắng thế. Ngược lại, người Nhâm Dần đa phần cố gắng đạt
        được nhiều thứ và luôn cảm thấy rằng kết quả của họ là chưa đủ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ luôn suy nghĩ thấu đáo, thích tự ngẫm nghĩ và đắm chìm bản thân trong
        sự nhân thức với mục tiêu cải thiện tính cách và hoạt động. Họ cũng dễ
        kết hợp với những người tự nhận thức và quan tâm những gì thúc đẩy mọi
        người xung quanh họ hành động.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Công Việc:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Dần có kỹ năng giao tiếp rất tốt. Họ có khả năng quản lý tốt
        giúp cho họ phù hợp với vị trí đòi hỏi quyền lực. Bởi vì họ thấu hiểu và
        đồng cảm, họ sẽ rất giỏi trong vị trí quản trị của một tổ chức.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người này cũng không thích bị đánh giá thấp hay bị người sử dụng
        lao động ra lệnh. Họ sẽ là những doanh nhân có trực giác bẩm sinh và đầu
        óc khách quan.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ là những người quản lý tiền bạc tốt và rất tài năng. Cả hai đặc tính
        trên giúp họ dễ làm quản lý nhà hàng hoặc phòng tranh nghệ thuật. Họ đa
        tài và thích các lĩnh vực như viết lách, thiết kế, trang trí nội thất,
        kịch nghệ và văn hoá.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ cũng có tính nghĩa khí và thích làm các công tác xã hội. Họ có thể
        phục vụ xã hội thông qua các quỹ từ thiện hay theo đuổi những công việc
        chú trọng đến lợi ích cộng đồng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ là những người tư vấn tốt, do đó họ sẽ thành công trong những công
        việc như giảng dạy, y học và tâm lý học. Những kỹ năng sắp xếp tổ chức
        sẽ giúp họ thành công trong mọi lĩnh vực họ chọn theo đuổi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Trong Công Việc:</b> trực giác cao, lý tưởng, thực
        dụng, thích giao tiếp, nghĩa khí, thấu hiểu, khá sáng tạo, thích tập
        thể, thiếu quyết đoán, hấp tấp, áp đặt, cứng đầu.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Hôn Nhân - Quan Hệ - Gia Đình:
      </h4>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Dần có nhu cầu tình yêu sâu đậm trong đời. Họ tìm kiếm những
        người có đầu óc và không khiến cho họ buồn chán. Họ có nhiều sở thích
        khác nhau và điều này dễ dẫn đến họ muốn có nhiều mối quan hệ khác nhau.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ đôi khi dao động và thiếu quyết đoán bởi vì họ không biết mình nên ở
        với ai. Tuy nhiên, khi họ bình tĩnh, họ có thể trở thành người ấm áp,
        quan tâm đến người khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ thích tìm kiếm người bạn đời lý thú và bị cuốn hút bởi những người
        thông minh và hiểu rõ mình. Họ cũng mơ về một cuộc sống đơn giản với một
        người bạn đời quan tâm và chung thuỷ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ thường tìm thấy được chân ái khi họ cảm thấy thảoi mái và tự tin
        trong một mối quan hệ. Gia đình và tình yêu ở mức độ ưu tiên cao hơn đối
        người Nhâm Dần hơn hẳn nhiều thứ khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ thích giao tiếp với người có nhiều sở thích đa dạng, cũng như thích
        mở rộng kiến thức và từ đó có thể chọn những người này thành bạn đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ thích đi xa và trải nghiệm. Mặc dù họ có xu hướng ngẫm về những kinh
        nghiệm cay đắng trong quá khứ, họ sẽ luôn chú trọng đến thời gian hạnh
        phúc với người hiện tại và chú trọng đến hạnh phúc của người bạn đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Dần thường thích giao tiếp. Họ thường thích thảo luận các vấn
        đề xã hội và thích tham gia vào các sự kiện đội nhóm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ rất nhạy cảm và suy nghĩ thấu đáu. Những đặc tính này cuốn hút bạn bè
        và người quen đến với họ để xin lời khuyên. Họ thường trộn lẫn kinh
        doanh và tình cảm và thường tìm kiếm cơ hội để tham gia vào các buổi gặp
        gỡ xã giao hay công việc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ khá rộng lượng, quan tâm, và hỗ trợ mọi người khi cần thiết. Họ có xu
        hướng cảm thấy thích gần gũi những người sáng tạo. Họ khao khát kiến
        thức và từ đó thích gần gũi những người có nhiều đam mê đa dạng. Tất cả
        mọi mối quan hệ của họ đều rất kích thích về trí óc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ cảm thấy tự tin giữa bạn bè và rất trung thành, nhạy cảm và quan tâm
        đến người khác, nhất là những người gần gũi họ. Họ tỏ ra là người thông
        minh và cuốn hút nhiều người hâm mộ mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Dần nên kết hôn muộn để giảm thiểu khó khăn trong hôn nhân.
        Đôi khi, kết hôn sớm sẽ dẫn đến vấn đề về hôn nhân và kết thúc bằng ly
        hôn. Tương tự, họ cũng nên sinh con muộn. Nếu người Nhâm Dần có con
        muộn, thì sẽ không gặp nhiều nỗi bất hạnh trong đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Đặc Tính Của Người Nhâm Dần Trong Hôn Nhân Tình Cảm:</b> ưu tiên gia
        đình và tình yêu, rất nhạy cảm, thích xã giao, thích người có sở thích
        đa dạng, ấm áp quan tâm.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:
      </h4>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trong lá số Bát Tự, Nhâm Dần là Nhâm toạ Thực Thần nên sẽ sinh ra Thiên
        Tài Tinh, là cách cục giúp tăng cường tác dụng của Thất Sát Tinh. Nó
        cũng được xem như là Nhâm Ký Hổ Bối (Nhâm cưỡi lưng cọp). Người này sẽ
        giàu có và thịnh vượng cũng như có trực giác tốt trong thị trường tài
        chính. Thiên Can và Địa Chi ở trụ này tương sinh cho nhau biểu thị quan
        hệ tình cảm gia đình sẽ thông căn và tạo ra hạnh phúc và niềm vui.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh Nhâm Dần thường không theo khuôn khổ và lý tưởng. Họ chú
        trọng tình yêu và mối quan hệ, đặt lên trên mọi thứ khác trong đời cũng
        như cố gắng cân bằng công việc và cuộc sống. Họ khôi hài và hấp dẫn
        khiến lôi cuốn nhiều người khác và họ luôn cố gắng làm hài lòng người
        khác bằng sự quan tâm và ấm áp. Họ nhạy cảm, suy nghĩ thấu đáo và rất
        thông cảm cho người khác. Họ cũng có thể giao tiếp thành công với người
        ở các tầng lớp khác nhau trong xã hội. Họ đặc biệt thích hoạt động đội
        nhóm và tham gia các hoạt động xã hội với niềm hứng thú.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Dần kết hợp giữa sự nhạy cảm và khả năng độc lập trong các
        tình huống nguy hiểm. Ngay cả khi phải chịu đựng những nỗi đau về tình
        cảm, họ vẫn tỏ ra yêu thương và tử tế với những người khác. Những phẩm
        chất này của họ nếu kèm với sự giao tiếp khéo léo sẽ cho phép họ trở
        thành những người cố vấn xuất sắc trong việc giúp đỡ người gặp căng
        thẳng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trong công việc thì người Nhâm Dần khá thực dụng và độc lập. Họ thích
        thử thách và yêu việc được làm việc hướng đến mục tiêu mới sau khi đã
        đạt được mục tiêu cũ. Họ rất quyết tâm và có đầy đủ nguồn lực và sở hữu
        óc đánh giá giúp giải quyết các khó khăn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù họ thường có đầu óc cân bằng và lý trí tốt, họ thích chiều lòng
        mọi người trong mọi lúc. Người Nhâm Dần thường có xu hướng mất nhiều
        thời gian để thiết lập các kỹ năng mới và mở rộng kiến thức cũng như họ
        có thể trở nên quá xét nét bản thân dẫn đến cảm giác trầm cảm. Họ cũng
        cần phải tránh việc buồn chán hay có xu hướng trở nên thiếu quyết đoán
        khiến cho họ dễ lạc hướng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Dần rất may mắn và thịnh vượng nếu như có thêm Quý hay Nhâm
        trong lá số Bát Tự. Và nếu trụ này có thêm Bính Hoả trong lá số thì biểu
        thị người này có thể tạo ra nguồn tài lộc lớn một cách dễ dàng. Bởi vì
        Dần là Trường Sinh của Bính Hoả. Nó biểu thị người này có thể tích luỹ
        được tài chính tốt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu Nhâm Dần gặp hành Mộc trong lá số thì biểu thị khả năng đạt thành
        công trong lĩnh vực quân sự nếu người này chọn con đường binh nghiệp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trụ Nhâm Dần cho thấy rằng hành Thuỷ khá trật tự và kiểm soát. Điều này
        bởi vì Mộc và Mậu Thổ có thể thu hút và kiểm soát hành Thuỷ. Điều này
        biểu thị người Nhâm Dần rất đáng tin cậy và chung thuỷ nếu họ thật sự
        quan tâm mối quan hệ tình cảm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu trụ Nhâm Dần gặp thêm Kim, Thuỷ hay Mộc thì biểu thị người này sẽ có
        xu hướng may mắn về tài chính, ngược lại họ cũng gặp khó khăn nếu quá
        nhiều Hoả hay Thổ hay có Mậu Ngọ xuất hiện trong lá số. Đáng buồn là
        cách cục này biểu thị người này sẽ gặp chuyện xui xẻo trong đầu tư và
        đối mặt lỗ lã.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu hành Hoả và Thổ có trong lá số Bát Tự, nếu gặp nhiều Thuỷ và Kim
        cùng lúc, người Nhâm Dần sẽ rất cứng đầu và điều này có thể ám chỉ thiếu
        học thức hay khả năng tiếp thu ý tưởng mới.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Dần sinh trong mùa Xuân và Hè sẽ thích xa xỉ và may mắn thay
        họ cũng thường may mắn giúp họ đủ điều kiện tài chính. Họ dễ bị lôi cuốn
        nắm bắt mọi cơ hội đến trước mặt và họ sẽ may mắn thấy nhiều cơ hội đến
        với mình. Ngoài ra, người sinh trong mùa Thu hay Đông sẽ khó có được con
        đường sự nghiệp tốt. Họ dễ rối rắm và thiếu định hướng trong vấn đề tài
        chính. Một kịch bản khá tệ khác là người sinh vào ban ngày và trong
        tháng Mùi, đáng buồn là họ sẽ đối mặt nhiều thử thách trong đời cả trong
        sự nghiệp và mối quan hệ cũng như họ sẽ gặp nhiều căng thẳng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhâm toạ trên Dần mang theo khí chính của Giáp Mộc Thực Thần kẹp bởi phụ
        khí của Bính Hoả Thiên Tài và Mậu Thổ Thất Sát. Bản thân địa chi Dần có
        Mộc biểu thị sự tăng trưởng, Hoả đem đến ánh sáng mặt trời của hạnh phúc
        và Thổ đem đến sự ổn định của niềm tin. Khi kết hợp với Nhâm thì mọi thứ
        hơi gian một chút.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Giáp Mộc là niềm vui và là sao nghệ sĩ Thực Thần biểu thị sự tinh tế.
        Điều này nhìn chung giải phóng mọi người xung quanh. Thiên Tài là sao
        chủ về cơ hội. Thất Sát là người luôn muốn đạt mục tiêu hành động và nếu
        có dính đến Thổ thì cũng là loại vất chất dồi gìau mà chúng ta đứng
        trên. Trụ này bao gồm cả đất đai và biển cả...trừ khi bạn bay, thì không
        có cách gì thoát được.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Dần hợp với Hợi bao gồm năng lượng của Nhâm Thuỷ Tỷ Kiên và Giáp Mộc
        Thực Thần. Cả hai sao này đều không liên quan đến sao của mối quan hệ
        tình cảm vợ chồng. Bởi vì bản chất của sao chỉ mang theo Thiên Tài và
        Thất Sát mang theo sao bạn trai, bạn gái, họ không ở trong một mối quan
        hệ tình cảm lâu dài. Cũng như bãi biển lấp lánh ánh sáng mặt trời, bạn
        không thể đi chơi suốt 365 ngày/năm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Dần xung với Thân mà bỏ đi Canh Kim Thiên Ấn, Nhâm Tỷ Kiên và Mậu Thổ
        Thất Sát. Do đó người phụ nữ này sẽ không cần tình cảm nhiều mà họ sẵn
        sàng sống một mình. Nam giới Nhâm Dần sẽ có tính toán chiến lược hơn với
        tình yêu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhìn chung, hãy hình dung người Nhâm Dần là “không có bữa trưa nào miễn
        phí”. Xin đừng nghĩ rằng họ ma mãnh, họ chỉ đơn giản là chơi bài ngửa so
        với những người thường giấu giếm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mọi người đều muốn đi chơi nghỉ hè miễn phí gần bãi biễn...còn việc tỏ
        ra quá hiếu khách mà bản thân bị thiệt thòi lại là lỗi của chính bạn.{" "}
      </p>
    </div>
  );
};

export const NhamTy = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Nhâm Tý: có vận đào hoa mạnh nhất trong 60 hoa giáp
        nhưng quan hệ tình cảm giao tiếp kém, có nhu cầu tình dục rất cao, cần
        sự giúp đỡ từ tâm linh hay huyền học để giảm bớt “đào hoa”, khó chung
        thuỷ trong hôn nhân, thường dễ buông thả vì nhu cầu tình dục, hôn nhân
        bất ổn và không viên mãn, dễ tái giá, và dính vào nhiều mối tình tay ba
        tay tư. Nhìn chung, cuộc sống bị ảnh hưởng xấu bởi rắc rối tình cảm yêu
        đương và sự nghiệp cũng bị đi xuống vì lý do tương tự. Nếu sinh vào giờ
        Dậu thì có con vô thừa nhận. Nữ giới sẽ bị bệnh liên quan đến bộ phận
        sinh dục, sinh sản.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Cơn bão biễn là một trong những lực mạnh mẽ trong tự nhiên vốn không
        biết đến giới hạn. Nó có thể đi bất kỳ nơi nào và càn quét tất cả những
        gì trên đường đi. Không có gì cản nổi và không có gì làm chậm lại được,
        bạn chỉ có nước trốn tránh càng nhanh càng tốt. Nhâm Tý là khi 2 lực của
        nước gặp nhau, thúc đẩy năng lượng cảm xúc căng thẳng và tạo nên kho
        tàng kiến thức.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhâm Thuỷ là biểu hiện của trí tuệ. Họ có khả năng cân bằng, đáp miếng
        trả miếng. Họ thông minh nhưng cũng đầy cảm xúc. Vì khả năng nhanh chóng
        cân bằng bản thân, họ thường được dán nhãn là tàn ác hay lạnh lùng. Họ
        có khả năng tiến lên bất kể những gì trở ngại trước mặt. Khả năng càn
        quét tất cả mọi thứ xung quanh khiến cho họ trở thành một hình mẫu lý
        tưởng thường gợi cảm hứng cho người khác đồng điệu với mình.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:{" "}
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Hình tượng Nhâm Tý là khối băng trôi tự do trên đại dương bao la, phần
        nổi của tảng băng chỉ bằng 1 phần nhỏ so với phần chìm bên dưới. Người
        Nhâm Tý do đó cũng sẻ ẩn mình nhiều hơn những gì họ thể hiện ra bên
        ngoài.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Tý là những người học hỏi nhanh nhạy, giao tiếp tốt và là
        lãnh đạo giỏi cũng như là 1 người đỡ đầu lý tưởng. Điều này cùng với khả
        năng tách bản thân ra khỏi vấn đề sẽ giúp cho người này có khả năng ra
        được những quyết định tốt. Sự thông minh và sức mạnh sẽ giúp định hình
        tính cách chính của người Nhâm Tý. Là người không thích nghỉ ngơi, người
        Nhâm Tý có thể mò mẫm và thành công trong nhiều mảng nếu họ được được
        quyền tự do suy nghĩ hay biểu lộ cảm xúc.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Là người sáng tạo, nhanh nhạy, người Nhâm Tý nhìn chung hình thành đam
        mê đối với nghệ thuật và có những ám ảnh bất thường. Những điều này cộng
        với nhu cầu thể hiện bản thân khiến cho người Nhâm Tý phù hợp với ngành
        nghề giải trí. Họ có khả năng ăn nói lưu loát, đầu óc sắc bén, do đó
        giỏi giao tiếp xã hội. Tuy nhiên, họ cũng cần phải chú trọng đến bản
        thân mình, cần soi chiếu nội tâm và bình an bên trong.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Tý có thể khá đa nghi. Tuy nhiên họ lại cũng sở hữu 1 ít sự
        hồn nhiên và theo đuổi suy nghĩ lý tưởng. Chân thành nhưng cũng khéo léo
        trong ngoại giao, người Nhâm Tý quan tâm thành thật đến người khác và tỏ
        ra là người khá thân thiện.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Tý (đặc biệt là phụ nữ) sẽ tỏ ra là người xốc vác, sẵn lòng
        vì người khác. Đây là sự kết hợp giữa sự thông minh, quyết đoán và bản
        tính dám ăn nói bẩm sinh.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Tý nói chung và nữ giới nói riêng, luôn muốn làm thoả mãn bản
        thân và độc lập, họ luôn khao khát thành công trên con đường sự nghiệp.
        Trên con đường này, đôi khi họ tỏ ra mù quang, xa lạ với sếp, và thất
        bại trong việc giữ các mối quan hệ dài lâu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Tý là người khá kiên nhẫn. Họ có khả năng xác định mục tiêu
        lâu dài, lên kế hoạch và cách theo đuổi nó. Họ hiểu được giá trị to lớn
        của kiến thức và suy nghĩ lý trí. Tuy nhiên, họ dễ bị lung lạc bởi chính
        ý chí riêng của họ hay sự cứng đầu khiến cho họ lao dốc trên 1 con đường
        mà họ đã tự vạch sẵn cho mình.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Để hoà hợp bản thân với mục tiêu lâu dài, người Nhâm Tý đánh giá cao sự
        an toàn và an ninh, sử dụng sự khôn ngoan bẩm sinh và trực giác để đạt
        được điều này. Họ có khả năng trân trọng những gì đẹp đẽ , xa hoa của
        cuộc đời và cố gắng để đạt được nó. Điều này cũng có thể khiến cho người
        Nhâm Tý trở nên quá chú trọng đến đồng tiền.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Tý thường tỏ rõ sự tự tin, độc lập, bản tính thích mạo hiểm
        và thẳng thắn của mình. Họ thường sử dụng sự tự tin của mình để khoả lấp
        che giấu sự bất an của mình trước người khác. Nếu họ không tham khảo ý
        kiến của người khác, họ có thể đi sai mục tiêu của mình.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ là nạn nhân và cũng là người yêu thích sự cô đơn, người Nhâm Tý có
        thể chọn lối sống cô đơn vì họ chọn sống không cảm xúc tình cảm thay vì
        dành hết cảm xúc cho cuộc sống. Người Nhâm Tý luôn trên hành trình trên
        hành trình khám phá bản thân và tìm kiếm câu trả lời cho những khó khăn
        trong nhận thức của họ.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Xét về khía cạnh cá nhân, họ chú trọng đến bản thân mình trong việc tìm
        kiếm giải pháp. Người Nhâm Tý không ngại ngần nếu gặp khó khăn trong tài
        chính hay vật chất và họ sẵn sàng chơi hết mình trong bất kỳ nỗ lực nào.
        Vào lúc khó khăn, họ thường được vợ hay chồng giúp đỡ.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đôi khi họ có thể suy nghĩ quá nhiều và điều này tạo thành gánh nặng cho
        người Nhâm Tý, đến mức suy nghĩ cua họ có thể cản trở cảm xúc và khả
        năng giao tiếp của họ. Họ nên tập trung vào viêc suy nghĩ tích cực và
        tìm cách gợi cảm hứng cho bản thân từ đó.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Tý thường có tính cách đôi, trầm lặng nhưng sâu sắc. Mặt
        ngoài thì họ tỏ ra im lặng, dễ dãi nhưng họ luôn có một nguồn lực tuôn
        chảy bên trong họ. Để có thể giải mã và khai phóng được nguồn năng lượng
        này, thì điều bắt buộc là người Nhâm Tý phải được xây dựng nền tảng từ
        bên trong.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ thường phải chịu những khó khăn do thái độ cảm xúc của họ. Họ là nạn
        nhân của những dao động liên tục trong tâm trí họ, do đó họ nên tìm đến
        những giải pháp thiền định như yoga giúp sắp xếp lại trí óc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có thể gặp nhiều khó khăn khi còn trẻ vì họ phải đối mặt với khó khăn
        một mình. Họ biết cách làm việc chăm chỉ, tiết kiệm để đạt được thành
        công bền vững. Họ biết cách đầu tư dài hạn, mua tài sản (như bất động
        sản) ngay cả khi gặp khó khăn về tiền bạc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tính Cách Chung Của Nhâm Tý: sống cảm xúc và thường ám ảnh, đạo đức,
        hướng nội, độc lập, cứng đầu, rất thông minh, bất an và đôi khi sợ hãi,
        kiên nhẫn, thống trị, chân thật, lý trí, đa nghi, lý tưởng hoá.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp: </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Tý thường không bị giới hạn về lựa chọn nghề nghiệp. Họ sở
        hữu trí thông minh, đầu óc nhanh nhạy, sắc bén, kỹ năng giao tiếp tốt,
        trực giác cao, tương lai nghề nghiệp rất rộng mở cho người Nhâm Tý. Họ
        cần khám phá mục tiêu quan trọng để hướng nhằm có thể tận dụng quyết tâm
        bền bỉ của mình. Họ thường sáng tạo và thích làm mọi việc theo cách của
        mình. Họ không giỏi về cấu trúc hay quản lý con người và do đó thường
        thích làm mọi việc một mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ thích nghệ thuật và sáng tạo nên có thể có tham vọng theo đuổi những
        đam mê này. Nếu họ khám phá được khía cạnh nhân văn trong tính cách của
        mình, họ có thể thành công trong những nỗ lực mang tính cải cách, cách
        mạng hay thậm chí cả những lĩnh vực như tôn giáo. Họ có khả năng hiếm có
        để điều chỉnh suy nghĩ tinh tế, sắc bén giúp họ dám đi những con đường
        ít ai chạm đến, dù cho đó là lĩnh vực nặng về phân tích hay thậm chí
        triết học.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Công Việc: </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Tý có suy nghĩ vừa khoa học vừa sáng tạo. Họ giỏi trong việc
        đối nhân xử thế và có thể có khiếu kinh doanh xuất sắc. Họ có thể thành
        công trong lĩnh vực giáo dục hay nghiên cứu. Họ yêu thích con người do
        đó sự yêu thích này có thể thúc đẩy họ theo đuổi sự nghiệp về xã hội học
        hoặc tâm lý học. Họ thích thể hiện bản thân do đó thích âm nhạc, viết
        lách, kịch nghệ. Họ là những người suy nghĩ có phương pháp và giao tiếp
        hiệu quả do đó cũng yêu thích những môn có hệ thống như pháp luật, triết
        lý…Họ luôn tìm kiếm sự cân bằng và trật tự, cũng như yêu thích kỹ thuật,
        kiến trúc, thiết kế hay toán học.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Trong Công Việc - Sự Nghiệp:</b> thông minh, trực
        giác nhạy, thẳng thắn, sáng tạo, nhạy cảm, giao tiếp, rộng lượng, nổi
        loạn, đa nghi, khó chịu.{" "}
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Quan Hệ - Gia Đình - Hôn Nhân:{" "}
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Tý thường bị cuốn hút vào những người có tính cách giống họ.
        Khi họ tìm kiếm người bạn đời có sự độc lập hay thông minh, họ cũng bị
        thu hút bởi những người làm chủ được cảm xúc, và cũng là người họ muốn
        học hỏi. Họ cũng tìm kiếm người có sự kiên trì. Mặc dù họ có thể quan
        tâm, giàu cảm xúc nhưng người Nhâm Tý cũng thường đối xử lạnh lùng với
        nhu cầu của người bạn đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Vì họ vốn cần sự an toàn, người Nhâm Tý thường tự tạo môi trường trong
        gia đình là nơi họ có thể được sự ủng hộ giúp đỡ. Hôn nhân là một ngôi
        trường quan trọng cho họ, nơi họ có thể nhận được những ảnh hưởng tích
        cực mà họ cần. Đàn ông sinh ngày Nhâm Tý thường thích phụ nữ scó cá tính
        mạnh mẽ và cuốn hút.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù hôn nhân khá quan trọng đối với người Nhâm Tý, họ thường tỏ ra
        ngại ngần trong việc kết hôn. Cảm thấy chỉ cần 1 mình là đủ, người Nhâm
        Tý thường khó ra những quyết định cương quyết trong hôn nhân và tình
        yêu. Khi người Nhâm Tý quyết định kết hôn, họ thường tỏ ra là những
        người vợ hay chồng đặc biệt rộng lượng và quan tâm chu đáo. Đàn ông Nhâm
        Tý nói chung thường ghét cô đơn và luôn muốn tìm kiếm một bạn đời lâu
        dài. Họ cũng yêu thích người phụ nữ có tiết hạnh đoan tranh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Phụ nữ ngày sinh Nhâm Tý thường không may mắn trong hôn nhân. Họ chú
        trọng đến sự nghiệp hơn và thường gặt hái được kết quả tốt hơn là xây
        dựng quan hệ bền vững lâu dài. Nếu họ có ý định kết hôn, phụ nữ Nhâm Tý
        thường được khuyên kết hôn với đàn ông đã ly hôn hay lớn tuổi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trong mối quan hệ xã hội, người Nhâm Tý thường sở hữu sự tự tin và quyết
        đoán bẩm sinh giúp cho họ dễ lấy lòng bạn bè và người thân. Họ có khả
        năng giao tiếp khéo léo cùng với sự chân thật và chân thành giúp cho họ
        không bao giờ thiếu bạn bè đồng hành.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Tý có suy nghĩ nhanh nhạy và năng động trong giao tiếp với
        người khác. Họ thích là trung tâm của sự chú ý và có khả năng kiểm soát
        mọi mối quan hệ xã hội. Họ cũng có khả năng rèn giũa kỹ năng giao tiếp
        và phân tích của mình. Họ rất linh hoạt và không gặp khó khăn khi giao
        tiếp với đủ mọi hạng người khác nhau.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Xét về gia đạo, người Nhâm Tý có xu hướng áp đặt bản thân lên người thân
        yêu đến mức có thể xem như là thống trị. Họ cần cẩn trọng không trở nên
        xa lạ, thờ ơ đối với vợ chồng hay con cái. Liên tục tìm kiếm người đồng
        điệu về tâm hồn, người Nhâm Tý có khả năng trở nên quá phụ thuộc vào
        người kia sau khi họ kết hôn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Trong Tình Yêu và Mối Quan Hệ:</b> chung thuỷ, thấu
        đáo, chân thật, duyên dáng, phụ thuộc, rộng lượng, đa nghi, thích giải
        trí, lý tưởng hoá.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Tý có tinh thần phiêu lưu. Họ thích những trải nghiệm mới và
        đón nhận nó với niềm hứng khởi lớn. Họ tin rằng sự đa dạng chính là gia
        vị của cuộc sống và luôn tìm kiếm sự bất ngờ. Có vẻ như là trong cuộc
        sống, họ luôn muốn gặp nhiều ý tưởng mới giúp cho họ có những thay đổi
        căn bản về giá trị hay góc nhìn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Tý sở hữu trực giác tốt, sự sáng tạo và thông minh. Họ là
        người lý tưởng hoá, cảm xúc và từ bi. Yêu thích kiến thức, họ là người
        có khả năng hấp thu thông tin nhanh chóng và trộn lẫn lý thuyết và ứng
        dụng thực tế trong mọi suy nghĩ. Họ có khả năng thích nghi và sức mạnh
        tiềm ẩn để đối phó với bất kỳ tình huống nào. Ngoài ra, họ cũng có khả
        năng gây hỏng mọi chuyện do sự hấp tấp và cảm giác bất an. Mặc dù họ có
        thể phân tích vấn đề và cân nhắc một cách khách quan, họ có thể trở
        thành nạn nhân bởi thói quen thiếu kiên nhẫn của mình, cũng như sự bất
        an sẽ khiến họ lo lắng, thiếu quyết đoán và cứng đầu. Niềm tin vào bản
        thân có thể là rất quan trọng đối với Nhâm Tý nhưng họ cũng cần có sự
        cân bằng giữa lý trí và nhận thức.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Tý đề cao gia đạo nhưng họ không thích bị kiềm chặt. Họ có
        đầu óc năng động và học hành, văn chương và tâm linh nên họ cũng có nhu
        cầu lớn về thể hiện bản thân. Họ thường cần phải học cách cân bằng bản
        tính nhưng đơn độc của mình với sự giao tiếp quan hệ lành mạnh cũng như
        an toàn về cảm xúc. Người Nhâm Tý toạ Dương Nhẫn và Kiếp Tài do sự hiện
        diện của dụng thần Thuỷ trong lá số sẽ biểu thị người này rất hấp dẫn.
        Phụ nữ có ngày sinh này sẽ khó có thể chung thuỷ bền lâu trong 1 mối
        quan hệ và đàn ông sinh ngày Nhâm Tý thường có nhu cầu tình dục cao.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tài lộc cũng dễ đến dễ đi bởi vì cục Kiếp Tài. Sao này xung kéo Tài
        Tinh, và hành Thuỷ lạnh cũng muốn có Hoả Tài Tinh ấm áp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Tý cũng như nước vòi rồng. Họ có thể bị kiềm hãm bởi Thất Sát
        Tinh khắc chế Dương Nhẫn cục trong lá số Bát Tự. Nếu Ấn Tinh, Thực Thần
        Tinh hay Thương Quan Tinh cũng hiện diện trong lá số, các sao này có thể
        ngăn sự ảnh hưởng của Quan Sát không trở nên quá nhiều. Trong tình huống
        này, người Nhâm Tý sẽ được xem là có lá số tốt. Điều này biểu thị người
        này sẽ thành công lớn và có nhiều thành tích cộng với danh tiếng cao và
        địa vị xã hội vững chắc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu có Quý xuất hiện ở Thiên Can trong lá số ngày sinh Nhâm Tý thì bất
        lợi. Điều này biểu thị một trận bão tuyết và cuộc sống đảo lộn. Vì Kiếp
        Tài Tinh hiện diện trong lá số là một phần của Dương Nhẫn Cục, nó vốn
        không có nhiều sức mạnh để đảo ngược tình thế.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Phụ nữ Nhâm Tý có thể không may mắn về hôn nhân vì kết hôn nhiều lần.
        điều này bởi vì Kiếp Tài Cục trong lá số Bát Tự biểu thị người vợ khó có
        thể tin tưởng chồng và do đó nền tảng hôn nhân không chắc chắn. Có khả
        năng là hôn nhân chỉ dựa trên cảm xúc nhất thời. Nếu họ muốn thay đổi
        vận may trong hôn nhân, người nữ Nhâm Tý phải chuyển đổi được Kỷ (Chính
        Quan) thành Mậu (Thất Sát) trong lá số.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu có thêm Hoả và Thổ trong lá số Nhâm Tý thì tốt. Điều này sẽ làm cho
        lá số khoẻ mạnh và giúp cho người Nhâm T1y có tương lai xán lạn. Lá số
        này chỉ xấu khi có quá nhiều Kim, Thuỷ hay Mộc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu người Nhâm Tý sinh vào tháng mùa Xuân hay Hè thì sẽ có khiếu tài
        chính. Họ dễ đạt được tài lộc, những vật chất xa xỉ từ sự đầu tư khôn
        ngoan. Còn người sinh vào mùa Thu hay Đông thì sẽ gặp nhiều trở ngại
        trên đường sự nghiệp và do đó họ phải làm việc vất vả hơn đồng nghiệp để
        được công nhận tài năng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu người Nhâm Tý sinh vào ban ngày thì sẽ đối mặt nhiều trở ngại trong
        đời khiến họ khó có thể sử dụng hết tiềm năng. Nếu sinh vào tháng Mùi
        thì họ thường có áp lực phải vượt qua hoàn cảnh khó khăn mà cuộc đời đem
        đến cho họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhâm toạ trên Tý vốn dĩ mang theo 1 hành duy nhất là Quý Thuỷ Kiếp Tài.
        Sự trao đổi liên tục giữa Quý và Nhâm khiến cho người khác cảm thấy rối
        rắm vì đôi khi họ được xem là đồng minh đôi khi là kẻ thù. Họ thường sử
        dụng giác quan cảm giác nhạy bén để điều khiển mọi người xung quanh. Họ
        tích luỹ nhiều thông tin và sử dụng chúng để lên kế hoạch hành động và
        điều khiển mọi người khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mối quan hệ tình cảm khá xấu cho nam giới Nhâm Tý vì toạ Kiếp Tài xua
        đuổi đẩy Chính Tài đi xa. Họ thường lờ đi những thứ nhỏ nhặt mà họ nghỉ
        là tình yêu và đi tìm thứ tình cảm dễ dàng mà theo họ là có thể chọn
        lựa. Phụ nữ thì ổn hơn nhưng cũng chịu đựng 1 ít nếu không biết cách giữ
        cảm xúc và có thể biến thành cơn bão thù hận.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tý hợp với Sửu mang đến năng lượng Kỷ Thổ Chính Tài, Quý Thuỷ Kiếp Tài
        và Tân Kim Chính Ấn. Điều này tốt cho phụ nữ vì Chính Quan đại diện cho
        chồng xuất hiện và làm cho nam giới tệ hơn vì Kiếp Tài ẩn bên trong. Bởi
        vì Kỷ Thổ đại diện cho bãi biển, điều này cũng báo hiệu cơn bão đổ bộ
        lên trên bãi biễn nơi nó gây ra sự huỷ diệt lớn nhất cho hầu hết cư dân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tý xung với Ngọ mang đến Đinh Hoả Chính Tài và Kỷ Thổ Chính Quan đem đến
        rắc rối cho cả nam và nữ vì sao chủ về bạn đời bị tấn công. Về căn bản,
        thì Nhâm Thuỷ không muốn bị trói buộc bởi người khác và muốn giữ sự tự
        do để có thể lang thang khắp nơi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ tích luỹ năng lượng ở tít xa ngoài khơi và vội vã ào đến khi đủ năng
        lượng. Đừng nghĩ rằng sự yên lặng là yếu đuối và đừng quấy rối vùng biển
        trọng có vẻ thanh bình.{" "}
      </p>
    </div>
  );
};

export const NhamTuat = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Nhâm Tuất: người này có miệng lưỡi sắc bén, dễ làm phật
        lòng người khác, mặc dù tốt bụng nhưng không được lòng người xung quanh,
        thích được khen ngợi, lạc quan, đặt yêu cầu cao đối với bản thân và
        những người bên cạnh. Dễ kết hôn nhiều lần. Nếu lá số có Mậu không có
        Kỷ, sẽ giàu có hơn. Không hợp với người sinh ngay Mậu Tuất, cần tránh.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhâm Tuất cũng như một dòng sông hung hãn gặp phải đối thủ là đất cứng.
        Đất không khuất phục, không bị sạt lở. Ngược lại, chúng ta phải quyết
        định xem có thể khuất phục lực mạnh mẽ này bằng cách xây đường ống
        turbine và tạo ra dòng điện hay không.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhâm là hành mạnh mẽ, quét trôi mọi thứ trên đường đi. Nó đem đến sự
        sống và lấy đi sự sống, không khoan dung. Vì nó là biểu hiện của trí tuệ
        rộng lớn và kiến thức sâu sắc. Người Nhâm Thuỷ thường bí ẩn trên bề mặt,
        lạnh lùng và khiêm tốn. Bên dưới, họ có thể mang theo một cơn sóng mạnh
        mẽ mà không ai có thể kháng lại được.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhâm Tuất tượng trưng cho hồ nước trên núi cao. Người Nhâm Tuất có thể
        rất tập trung và mạnh mẽ ẩn bên dưới vẻ ngoài của họ. Người Nhâm Tuất
        chứa nhiều phẩm chất tốt nhất của Nhâm. Bền ngoài mạnh mẽ và cứng rắn
        nhưng bên trong họ lại rất chú tâm đến người khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Tuất giỏi kinh doanh do khả năng lên kế hoạch và chiến lược
        tốt. Họ cũng sở hữu khả năng giỏi kiểm soát cảm xúc. Họ thường có vận
        may trung bình khi còn trẻ, có thể tệ hơn vào thời trung niên trước khi
        may mắn về già.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Có hành Hoả trong lá số không ảnh hưởng xấu đến họ, trong thực tế thì họ
        thích hành Hoả vì giúp họ ấm áp ở độ cao của hồ nước trên núi. Họ giỏi
        giao tiếp xã hội và cuốn hút người khác vì độ duyên dáng và thông minh
        của mình. Họ là những người giỏi tranh biện và có khả năng làm cùng lúc
        nhiều việc. Người Nhâm Tuất thân thiện và có khả năng thấu hiểu giá trị
        và động cơ thúc đẩy người khác. Họ khéo léo và giỏi giao tiếp với người
        khác. Họ dễ trở nên nổi tiếng vì bản tính thân thiện và nhận thức bản
        thân tốt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Tuất thường rất sáng tạo, và luôn cố gắng học hỏi nhanh chóng
        cũng như có xu hướng chinh phục kiến thức và khả năng tưởng tượng cao.
        Việc thích hưởng thụ và thiếu kiên nhẫn có thể khiến cho họ lo lắng và
        dễ từ bỏ nhiệm vụ một cách dễ dàng. Họ có xu hướng liên tục tiến bộ, do
        ham muốn tìm kiếm và khai phá những chân trời mới. Trong lòng họ cũng
        rất quan tâm đến các chủ đề như quyền con người và chính trị xã hội.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Tuất có thể tỏ vẻ thờ ơ trong nhiều tình huống. Điều này bởi
        vì họ dễ cảm thấy buồn chán vì những quy trình lặp đi lặp lại gây nhàm
        chán đầu óc vốn suy nghĩ nhanh của họ. Họ thường thích tham gia những
        hoạt động gồm những con người thông minh hoặc khơi gợi trí thức. Có đầu
        óc thông minh và nhiều kiến thức, những người này có thể gặp mâu thuẫn
        giữa những gì tẻ nhạt và lý tưởng cao cả.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Tuất có xu hướng thích dịch chuyển và thậm chí sống ở nước
        ngoài. Có tài năng, góc nhìn trung lập, đầu óc cởi mở, họ thường thẳng
        thắn và rất cởi mở. Họ có sức hút tự nhiên có thể giúp họ đến vị trí
        lãnh đạo dù trong bất cứ lĩnh vực nào. Họ rất cần tìm những phương thức
        tốt để thể hiện bản thân vì họ sẽ không quen giả tạo.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Tuất đôi khi có thể thích tranh cãi và nói nhiều, nếu họ
        không thể kìm chế sự tự tin mà biến thành sự kiêu ngạo. Họ có thể tỏ ra
        hời hợt nhưng thực ra cũng rất tình cảm. Họ thích thách thức về trí óc
        và vẻ ngoài dễ dãi của họ có thể đánh lừa người khác. Sự thật dẫn dắt
        họ, và họ có thể luôn cố gắng phơi bày những gì đang ẩn giấu. Sự sâu sắc
        trong suy nghĩ của họ cũng thường gợi cảm hứng cho người khác.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mức độ học tập có liên quan trực tiếp đến trọng tâm cuộc sống của họ. Họ
        có xu hướng xây dựng nền tảng vững chắc dựa trên những lý lẽ xác đáng để
        tạo dựng sự bình an trong tâm hồn. Những tình huống đấu tranh có thể
        khiến họ bất đồng và mang tính khiêu khích. Họ thường sắc bén, chân thật
        và thân thiện cũng như có kỹ năng giao tiếp tốt. Họ có khả năng thấu
        hiểu con người sâu sắc, cũng như xu hướng nghệ thuật, triết học, âm
        nhạc, tôn giáo hay huyền học.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sự hợp tác với những người khác sẽ giúp tăng cường thành tích của họ.
        Người Nhâm Tuất sẽ đứng lên vì niềm tin của mình, và sẵn lòng hi sinh vì
        người mình yêu.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Tuất thích thể nghiệm những thứ mới mẻ và có cá tính độc đáo.
        Sự thiếu quyết đoán và lo lắng có thể khiến họ hoạt động thiếu hiệu quả.
        Sự tự tin của họ che giấu nhu cầu ẩn cần sự ủng hộ từ những người khác.
        Họ nhạy cảm bên trong, và có tiềm năng phát triển tâm linh và triết lý,
        giúp họ có niềm tin vào bản thân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người này có vẻ mê vật chất vì họ thường xuyên lo lắng về tài
        chính, mặc dù họ rất rộng rãi với những người họ mến. Họ có suy nghĩ
        chắc chắn, quyết tâm, sáng tạo và có khả năng hưởng lợi ích từ các hoạt
        động nhân đạo vì họ sẵn sàng hi sinh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ tự hào về thành tích của mình và không muốn thất bại. Sự tập trung và
        kỷ luật bản thân có thể củng cố khả năng tư duy. Họ tin rằng kiến thức
        là sức mạnh, và luôn tìm kiếm học hỏi điều mới. Họ sở hữu vẻ duyên dáng,
        và thường được tạo động lực bởi sự ham muốn được công nhận. Họ có thể
        đọc vị và sở hữu cảm xúc năng động và mạnh mẽ. Họ có thể thiếu kiên nhẫn
        trong việc sử dụng sự thông thái của mình sao cho hiệu quả, và có thể
        dính đến các giải pháp trốn tránh thực tế như rượu, ma tuý…
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những Đặc Tính Của Người Nhâm Tuất: cảm xúc mạnh, đa tài và thông minh,
        nổi tiếng, kỹ năng giao tiếp xuất sắc, nhân đạo, duyên dáng, tin tâm
        linh.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Tuất quyết tâm, siêng năng, và tham vọng cũng như có đầu óc
        nhanh nhạy và khả năng lãnh đạo tốt. Họ có xu hướng kỹ năng phân tích
        tâm lý và giỏi chữa lành. Do đó, họ dễ làm các công việc liên quan đến
        con người.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ không thích nhận mệnh lệnh từ người khác, và thường giỏi trong môi
        trường đội nhóm. Họ vốn dĩ quan tâm đến nghệ thuật. Họ có bản tính mạo
        hiểm và thích hành động liên tục. Họ là người sáng tạo và có tư duy cấu
        trúc cũng như tầm nhìn trong mọi công việc họ làm.{" "}
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Công Việc:</h4>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Tuất phù hợp với thế giới kinh doanh và giỏi giải quyết vấn
        đề cũng như phân tích. Họ có khả năng chữa lành do đó phù hợp với làm
        việc trong lĩnh vực chăm sóc sức khoẻ. Giảng dạy và thuyết trình cũng là
        nghề nghiệp phù hợp với họ. Viết lách, nhà báo, thể thao và các ngành
        gỉai trí cũng giúp khai thác bản tính sáng tạo và mạo hiểm bên trong
        người Nhâm Tuất.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có khả năng toả sáng khi được chú ý và do đó phù hợp với nghề diễn
        viên hay chính trị gia, và kiến thức kinh doanh có thể đóng vai trò rất
        lớn nếu họ chọn làm tư vấn, đàm phán hay cố vấn tài chính. Họ cũng phù
        hợp cho sự nghiệp trong ngành kỹ thuật như khoa học máy tính hay kỹ sư.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Trong Công Việc - Sự Nghiệp:</b> siêng năng, quyết
        tâm, dễ làm việc nhóm, mạo hiểm, sáng tạo, phân tích, không giỏi nhận
        mệnh lệnh, thích làm việc một mình.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Hôn Nhân - Gia Đình - Quan Hệ:{" "}
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Tuất có thể chỉ trích hay tỏ thái độ kẻ cả đối với những
        người xung quanh hay vợ chồng. Họ thích cải thiện bản thân, và do đó có
        thể thu hút nhiều người tham vọng khác cũng mong muốn thay đổi bản thân.
        Họ cần phải tìm được người bạn đời có thể đem đến sự kích thích tâm trí.
        Những người này thường thẳng thắn, và do đó cần người bạn đời có thể làm
        cho họ cảm thấy an tâm và luôn nghe sự chỉ đạo từ họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người này rất nồng nhiệt và chung thuỷ, cũng là người ngọt ngào.
        Cá tính ấm áp và rộng lượng của họ hướng đến người họ yêu và đôi khi họ
        có thể tỏ ra quá hướng nội và rụt rè. Mặc dù họ cũng bản tính thích điều
        lý tưởng và có những ý tưởng vững chắc về tình yêu, sự thiếu kiên nhẫn
        và tinh thần không ổn định có thể tạo ra sự mâu thuẫn với người bạn đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Tuất thể hiện sự quan tâm đến việc học hỏi và điều này khiến
        họ dễ gần gũi với người có cùng suy nghĩ. Họ thích mối quan hệ thẳng
        thắn và hi vọng có thể giữ mối quan hệ tình cảm tốt bằng cách giao thiệp
        khéo léo. Thái độ trung lập giúp họ có thể hoà nhập với nhiều đám đông
        khác nhau. Những người mạnh mẽ và có kỷ luật bản thân thường là người mà
        Nhâm Tuất thích kết nối và những người lớn tuổi, có quyền thống trị
        thường sẽ gây ảnh hưởng lớn góc nhìn của họ. Không có nhiều duyên hôn
        nhân lắm cho người Nhâm Tuất, nhất là đối với nữ giới, tốt hơn là giữ
        quan hệ hơn là kết hôn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Những Đặc Tính Trong Hôn Nhân - Quan Hệ:</b> thẳng thắn, đam mê,
        chung thuỷ, ngoại giao, trung lập, ấm áp và rộng lượng.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Tuất toạ trên ẩn Tài Tinh trong lá số Bát Tự và sẽ sinh ra
        Thất Sát. Thất Sát sẽ sinh ra Ấn Tinh và kết hợp này sẽ sinh lẫn nhau
        trong lá số. Điều này đem đến cho người Nhâm Tuất tài lộc và thịnh
        vượng. Điều này cũng báo hiệu khả năng thành công trong lĩnh vực quân sự
        nếu người này theo đuổi binh nghiệp. Đáng buồn là cũng biểu thị kẻ tiểu
        nhân đặc biệt khi hành Mộc gặp Thổ khô trong lá số Nhâm Tuất.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Tuất thích sự ổn định và trật tự. Họ thích quy trình tổ chức.
        Họ có nhiều mạng lưới bạn bè. Kết nối cao với xã hội. Họ có khiếu kinh
        doanh mạnh mẽ và khả năng lý luận thực tế. Họ cũng sáng tạo nhưng luôn
        có góc nhìn thực tế. Với sự quyết tâm và kiên trì, họ có thể tìm được sự
        thoả mãn trong cuộc sống.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Tuất thường cảm nhận rằng mình dễ bị hiểu nhầm. Họ phải vất
        vả trong việc chia sẻ cảm giác và có thể cảm thấy bị áp lực. Họ thường
        quan tâm và lo lắng cho người khác ngay cả khi họ không biết cách bày tỏ
        tình cảm. Họ thẳng thắn, chân thật và công bằng trong đối nhân xử thế và
        họ luôn hướng đến việc xây dựng nền tảng vững chắc cho các mối quan hệ.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhâm Tuất vốn toạ Thất Sát biểu thị khả năng giàu có, quyền lực và địa
        vị. Người này sẽ có nhiều may mắn do Thất Sát Tinh, Tài Tinh và Ấn Tinh
        để có thể khởi đầu hành trình cuộc sống của mình. Nhâm cần có Thất Sát
        để định hướng và giữ trọng tâm cần thiết để chinh phục mục tiêu.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhâm trở nên mạnh mẽ chỉ khi được gom tụ. Nếu bị phân tán thì Nhâm sẽ
        yếu đuối. Nếu trong lá số có nhiều Nham hay Quý là tốt. Nếu quá nhiều
        Thuỷ và Kim hiện diện thì Nhâm Tuất nên đi lại nhiều vì điều này sẽ giúp
        làm giảm bớt tác động tiêu cực do quá nhiều hành Thuỷ gây ra.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu trụ này gặp nhiều Mộc, Thổ và Hoả thì biểu thị lá số lành mạnh cho
        Nhâm Tuất. Nhưng sự hiện diện của nhiều Kim hay Thuỷ có thể khiến cho lá
        số mất cân bằng cảm xúc. Khi thêm hành Kim hay Thuỷ trong lá số thì
        người Nhâm Tuất có xu hướng bất ổn về cảm xúc. Điều quan trọng cần lưu ý
        là người Nhâm Tuất không thể hoàn thành được ước mong của mình nếu không
        có thêm hành Hoả trong lá số.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhâm Tuất sinh vào ban ngày sẽ gặp vận may trung bình và cần phải tự tạo
        dựng may mắn cho riêng mình. Cơ hội sẽ không tự nhiên rơi xuống cho họ
        mà họ phải đi tìm cơ hội để tiến bộ và chứng minh giá trị bản thân.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhâm Tuất sinh vào ban đêm sẽ có tuổi thọ và tài lộc tốt. Cơ hội sẽ đến
        và họ sẽ phải cố gắng khai thác tối đa. Họ có thể chất và sức khoẻ tốt.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Nhâm Tuất sinh vào mùa Xuân hay Hạ dễ tìm được sự cân bằng mà họ
        khao khát trong đời. Họ sẽ cảm nhận bình yên bên trong khi đối mặt với
        thách thức. Người sinh vào mùa Thu hay Đông sẽ thẳng thắn và siêng năng.
        Họ sẽ làm việc hết mình để có cuộc sống tốt hơn. Người sinh vào tháng
        Thìn sẽ không may mắn vì đối mặt nhiều thách thức trong đời cản bước
        thành công.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tuất khiến cho Nhâm toạ trên Thất Sát Mậu. Một sự đe doạ thường xuyên
        bên dưới làn nước Nhâm. Họ thường xuyên thể hiện mình có động lực liên
        tục và sự dữ dội muốn đạt được mọi thứ. Họ có vẻ như nợ thế giới điều gì
        mà họ muốn thực hiện. Họ sẽ cố gắng và cố gắng cho đến khi thành công.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Cả Chính Tài Đinh và Chính Ấn Tân đều kẹp Tuất. Họ rất thực dụng và biết
        cách sử dụng kiến thức. Điều này rất hợp với Nhâm khao khát tự do, sẵn
        lòng tìm cách để đào thoát ra khỏi Thổ kiểm soát.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Có cả Tài và Ấn đồng nghĩa là họ không bao giờ thiếu cơ hội, do bản thân
        tự tìm ra hay được người khác hỗ trợ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tuất hợp với Mão chào đóng Ất Thương Quan. Xét về tình cảm, khá khó cho
        nữ giới, không khó khăn cho nam giới. Họ thích đề nghị sự thay đổi và
        cách mạng để thay đổi hiện trạng. Phụ nữ có thể nói lên sự phản đối,
        muốn kết thúc và phá hỏng mối quan hệ tình cảm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tuất xung với Thìn xua đuổi Kiếp Tài Quý, Thất Sát Mậu và Thương Quan Ất
        đi xa. Khi có kế hoạch thoát, họ cũng cố gắng để giảm bớt tác động bằng
        cách trở nên ít dữ dội hơn, tất cả 3 sao hung như Kiếp Tài, Thất Sát và
        Thương Quan đều không phải sự yêu thích của họ. Do đó điều này sẽ mang
        đến sự kiểm soát bản thân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhìn chung, để kiểm soát lực mạnh mẽ này thì khó nhưng Nhâm Tuất có thể
        xoay xở làm được. Họ là người tập trung và quyết tâm đồng thời muốn đạt
        đến sự vĩ đại nhưng không sẵn lòng phá bỏ hết tất cả. Bạn cũng có thể
        thấy họ là những người hiền hậu nhưng một khi đập vỡ thì bạn sẽ thấy rõ
        cơn thịnh nộ của họ.
      </p>
    </div>
  );
};
