import battoa from "../../assets/thansat/battoa.png";
import tamdai1 from "../../assets/thansat/tamdai1.png";
import tamdai2 from "../../assets/thansat/tamdai2.png";
import chiboi from "../../assets/thansat/chiboi.png";
import diagiai from "../../assets/thansat/diagiai.png";
import giaithan from "../../assets/thansat/giaithan.png";
import nguquy from "../../assets/thansat/nguquy.png";
import banan from "../../assets/thansat/banan.png";
import caugiao from "../../assets/thansat/caugiao.png";
import lancan from "../../assets/thansat/lancan.png";
import quanphu from "../../assets/thansat/quanphu.png";
import quanto from "../../assets/thansat/quanto.png";
import quyenthiet from "../../assets/thansat/quyenthiet.png";
import longduc from "../../assets/thansat/longduc.png";
import luchopquy from "../../assets/thansat/luchopquy.png";
import phuctinhqn from "../../assets/thansat/phuctinhqn.png";
import phuctinhqn1 from "../../assets/thansat/phuctinhqn1.png";
import qhqn from "../../assets/thansat/qhqn.png";
import thaiam from "../../assets/thansat/thaiam.png";
import tcqn from "../../assets/thansat/tcqn.png";
import thaiduong from "../../assets/thansat/thaiduong.png";
import taqn from "../../assets/thansat/taqn.png";
import thienxa from "../../assets/thansat/thienxa.png";
import qaqn from "../../assets/thansat/qaqn.png";
import mokho from "../../assets/thansat/mokho.png";
import nguyetduchop from "../../assets/thansat/nguyetduchop.png";
import mocduc from "../../assets/thansat/mocduc.png";
import daohoa1 from "../../assets/thansat/daohoa1.png";
import thienladiavong from "../../assets/thansat/thienladiavong.png";
import tamdaiquy from "../../assets/thansat/tamdaiquy.png";

export const BatToa = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Bát Tòa hay Bát Tọa biểu thị sự may mắn về tiếng tăm, nổi tiếng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu gặp sao Bát Tòa trong lưu niên thì dễ được nhiều người biết đến danh
        tiếng, nhiều người yêu thích. Đây là sao số 1 tốt về mặt danh tiếng, nếu
        là dụng thần thì tiếng tăm vang xa, nếu là kỵ thần và có xung khắc thì
        có thể bị tai tiếng.
      </p>

      <img style={{ width: "100%" }} src={battoa} />
    </div>
  );
};
export const TamDai = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Người có sao Tam Đài trong lá số tham gia bất kỳ cuộc thi nào cũng dễ
        thành công, thuận lợi thắng cuộc với vị trí thứ 1 – 3.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngoài ra, sao Tam Đài cũng biểu thị dễ đạt vị trí cao trong chính trị
        khi tham gia chính trường.
      </p>

      <img style={{ width: "100%" }} src={tamdai1} />
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trong tứ trụ có 3 chi liền kề như bên dưới cũng được xem là có được Tam
        Đài
      </p>

      <img style={{ width: "100%" }} src={tamdai2} />
    </div>
  );
};
export const ChiBoi = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Sao Chỉ Bối chủ về bị tiểu nhân hãm hại, bị đâm sau lưng bởi những người
        xung quanh mình hoặc bị gài bẫy.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người trong mệnh có sao Chỉ Bối nếu đồng thời là kỵ thần hoặc xung khắc,
        phản phục ngâm với trụ ngày thì dễ bị người thân phản bội, đâm sau lưng.{" "}
      </p>

      <img style={{ width: "100%" }} src={tamdai1} />
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sao Chỉ Bối nếu xuất hiện ở lưu niên hay đại vận thì trong thời gian này
        cần phải hết sức thận trọng vì dễ bị đối tác, khách hàng, bạn bè đâm sau
        lưng.{" "}
      </p>

      <img style={{ width: "100%" }} src={chiboi} />
    </div>
  );
};
export const DiaGiai = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Nếu trong lá số gặp Tam Sát hoặc Địa Sát nhưng có sao Địa Giải trong lá
        số, đại vận hay lưu niên thì có thể hóa giải sát khí, tai ương được.
      </p>

      <img style={{ width: "100%" }} src={diagiai} />
    </div>
  );
};
export const GiaiThan = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Nếu trong lá số hay trong Lưu niên, Đại vận gặp Thiên Sát thì Giải Thần
        hoặc Thiên Giải có thể hóa giải. Bạn đọc có thể kiểm tra xem những tác
        hại của Thiên Sát ở các chương trước.
      </p>

      <img style={{ width: "100%" }} src={giaithan} />
    </div>
  );
};
export const NguQuy = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        - Sự hiện diện của Ngũ Quỷ Quan Phù ám chỉ:
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Khả năng bị quấy rối hay bực bội bởi tiểu nhân hay những người hiểm
        ác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Khả năng bị rắc rối do họa thị phị, tin đồn hay cãi nhau – và dẫn đến
        hao tài
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Dễ bị kiện tụng, bị tiểu nhân gài bẫy hay hãm hại.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngũ Quỷ là một loại ôn thần, còn gọi là Ngũ Ôn, trên trời là Ngũ Quỷ,
        dưới đất là Ngũ Ôn. Ngũ Quỷ là sát thương lực tương đối mạnh, Ngũ Quỷ
        trong mệnh còn được gọi là Ngũ Quỷ Quan Phù.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Ngũ Quỷ Tinh chủ về phá tài, nhân đinh bất an, bệnh tật, tử vong. Nếu
        ngày sinh gặp Ngũ Quỷ là dễ đắc Thiên Tài, có tiền từ trên trời rơi
        xuống, nhưng cũng đồng thời dễ bị tiểu nhân hãm hại. Nếu lưu niên gặp
        Ngũ Quỷ quan Phù thì cần cẩn trọng trong năm đó.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Ngũ Quỷ tinh âm khí khá mạnh, có thể làm loạn phúc vận của Tiên Thiên
        Bát Tự, khiến cho âm dương xung động, vận thế chiêu tiểu nhân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>Ví dụ:</p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Dần trong năm Mùi
      </p>
      <img style={{ width: "100%" }} src={nguquy} />
    </div>
  );
};
export const BanAn = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Sao Bản An nếu xuất hiện trong mệnh thì cả đời dễ dính đến họa thị phi,
        kiện tụng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu Bản An xuất hiện ở trụ tháng thì dễ có kiện tụng dính đến bố mẹ, phụ
        thân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu Bản An xuất hiện ở trụ ngày thì vợ chồng dễ ly hôn, kiện tụng.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu Bản An xuất hiện ở trụ giờ thì về già dễ dính đến kiện tụng với nhân
        viên, con cái.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu Bản An là dụng thần thì mệnh dễ làm luật sư, thẩm phán.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu Bản An là kỵ thần thì dễ bị dính líu đến kiện tụng và thua kiện.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu Bản An đồng thời là sao Quý Nhân thì sẽ được giúp đỡ tại nơi tòa án.
      </p>
      <img style={{ width: "100%" }} src={banan} />
    </div>
  );
};
export const CauGiao = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Đời sống tình yêu sẽ rất lộn xộn và nhiều trục trặc. Cẩn trọng các vấn
        đề liên quan đến tình cảm, tình dục có thể gây ra chuyện xấu trong gia
        đình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu có sao này trong lá số Bát Tự thì dễ rơi vào rắc rối, cả đời bị rắc
        rối và thị phi. Cần phải suy nghĩ kỹ trước khi nói, dễ bị kiện tụng và
        người khác đánh giá là nhiều chuyện.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Dễ gặp quan binh, đạo tặc, khó tránh hoạ. Nếu là người làm trong ngành
        quân đội, nắm quyền hành, thống soái, thì tốt. Nếu người bình thường mà
        mệnh phạm Câu Giảo thì dễ bị người khác tranh chấp, hoạ quan phi, khẩu
        thiệt, sóng gió không dứt. Sách Tam Mệnh Thông Hội viết: “Hoạ Câu Giảo
        khiến cho mọi việc làm khó thành công, dễ bị người khác ám hại, bày mưu
        tính kế hãm hại, bị đâm sau lưng và hoạ khẩu thiệt, thị phi và hình
        ngục.”
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu gặp năm hay đại vận có Câu Giảo thì chủ thân bị tổn thương, phá tài,
        tai hoạ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu mệnh mang Câu Giảo thì cả đời đều liên lụy những việc không tốt, hôn
        nhân bất lợi, thường tranh cãi, bị hãm hại, hận thù.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trong mệnh có Thất Sát là kỵ thần và Câu Giảo đồng chiếu đến thì hoạ
        nặng, cần hết sức đề phòng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Dương Nam Âm Nữ, thì trước mệnh 3 thần là Câu, sau mệnh 3 thần là
        Giảo. Âm Nam Dương Nữ, trước mệnh 3 thần là Giảo, sau mệnh 3 thần là
        Câu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Nếu như mệnh Giáp Tý Dương Mệnh, Mão là Câu, Dậu là Giảo. Ất Sửu Âm
        Mệnh Nhân, Thìn là Giảo, Tuất là Câu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Âm Nam Dương Nữ, ý nghĩa là người Nam sinh vào năm Âm, người Nữ sinh
        vào năm Dương. Cũng là nói người Nữ sinh vào năm Tý, Dần, Thìn, Ngọ,
        Thân, Tuất. Người Nam sinh vào năm Sửu, Mão, Tỵ, Mùi, Dậu, Hợi.
      </p>
      <img style={{ width: "100%" }} src={caugiao} />
    </div>
  );
};
export const LanCan = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Sao Lan Can biểu thị tự do bị giới hạn trong một không gian hẹp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đôi khi gặp phải sao Lan Can người đó có thể bị kẹt trong thang máy,
        phòng vệ sinh…
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu sao Lan Can xuất hiện ở đại vận kỵ thần kèm với là Tử Tuyệt thì có
        thể bị tù tội, hình ngục.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu trong mệnh có sao Quý Nhân, Thiên Đức, Nguyệt Đức hoặc Tướng Tinh
        thì có thể hóa giải tác dụng xấu của sao Lan Can.
      </p>

      <img style={{ width: "100%" }} src={lancan} />
    </div>
  );
};

export const QuanPhu = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Sao Quan Phù cuốn hút mách lẻo, tai tiếng, kiện tụng như bị phạt vì đậu
        xe, vượt đèn đỏ, xả rác, vi phạm pháp luật, trốn thuế, phá hoại hay bị
        gạt gẫm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sao Quan Phù cũng là Súc Quan hay quan trông coi gia súc. Quan Phù đại
        diện cho kiện tụng. Nếu Quan Phù Sát là nghiêm trọng thì người trong nhà
        phải đi tù, gia súc bị bệnh.
      </p>

      <img style={{ width: "100%" }} src={quanphu} />
    </div>
  );
};
export const QuanTo = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Sao Quán Tố biểu thị mình dễ bị liên lụy vào những việc mình không trực
        tiếp gây ra.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu là dụng thần thì người này tốt tính, vốn thích lo chuyên bao đồng
        cho xã hội, người dung yếu thế.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu là kỵ thần thì người này hay bị lợi dụng, dính vào những chuyện thị
        phi, họa từ trên trời rơi xuống do tính tình nhiều chuyện, thích can dự
        vào việc không phải của mình.{" "}
      </p>

      <img style={{ width: "100%" }} src={quanto} />
    </div>
  );
};
export const QuyenThiet = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Sao chủ về mách lẻo, cải vã, vụ bê bối, tranh cãi, do đó cần phải cẩn
        trọng tránh dây vào việc người khác.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sao Quyển Thiệt là chủ về thị phi, khẩu thiệt. Nếu là kỵ thần thì phải
        hết sức thận trọng, lời nói của họ dễ bị suy diễn tiêu cực bởi những kẻ
        tiểu nhân.{" "}
      </p>

      <img style={{ width: "100%" }} src={quyenthiet} />
    </div>
  );
};
export const LongDucQuyNhan = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Người trong mệnh có sao Long Đức Quý Nhân biểu thị tính tình cô độc,
        không hoà hợp với người thân nhưng được quý nhân giúp đỡ, hỉ sự nhiều,
        được quý nhân đề bạt, đi xa có tiền bạc, làm ăn phát tài, đại lợi tốt
        đẹp, mọi việc đều như ý. Long Đức chủ về quý nhân, gặp hung hoá cát, nỗ
        lực thành công, đức vọng cao.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu trong vận có Long Đức Quý Nhân thì dù là vận Tỷ Kiếp, Tài Tinh, Quan
        Sát đều có thể biến hung thành cát.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Long Đức chủ hoá hung thành cát, tránh sát, hoá giải được Kiếp Sát, Tai
        Sát, Tam Sát. Nếu gặp Giải Thần thì càng hoá giải được toàn bộ chuyện
        lớn thành nhỏ, nhỏ biến thành không có.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu có Tấu Thư, Giải Thần, Hoa Cái tương hội thì có thể hoá giải quan
        phi, kiện tụng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Là cát tinh, sao Long Đức biểu thị:
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Khả năng hóa giải, giảm bớt hay tránh các ảnh hưởng tiêu cực từ các
        hung tinh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Điềm tốt cho may mắn và các mối quan hệ thuận lợi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Khả năng hóa giải, giảm bớt hay tránh các ảnh hưởng tiêu cực từ các
        hung tinh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Long Đức nếu trong lưu niên thì năm đó có quý nhân, gặp nhiều may mắn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Được quý nhân giúp đỡ nhiều, gặp hoạ thành phúc, không sợ hung hiểm,
        có thể tránh được sát.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Nếu có sao Long Đức trong lá số, mọi việc thuận lợi, may mắn. Nhưng
        nếu gặp Xung và Hợp thì sẽ làm ảnh hưởng của sao Long Đức.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>Ví dụ:</p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Người sinh năm Dậu gặp đại vận Thìn
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Người sinh năm Tuất, giờ Tỵ
      </p>

      <img style={{ width: "100%" }} src={longduc} />
    </div>
  );
};
export const LucHopQuy = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Lục hợp quý biểu thị cặp Thiên Can Địa Chi mà Can ở trên Chi chính là
        Quý Nhân của Can. Những bước sau:
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người sinh ngày Giáp có Sửu và Mùi là Quý Nhân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Tý hợp với Sửu và Ngọ hợp với Mùi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Tý và Ngọ là lục hợp chi của can ngày Giáp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Giáp Tý và Giáp Ngọ là Lục Hợp Quý.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tác dụng của Lục Hợp Quý sẽ mạnh mẽ hơn là Quý Nhân trong Tứ Trụ. Đặc
        biệt là xét về mặt tài lộc và vận may khi vào trung niên. Sao Lục Hợp
        phải xuất hiện trong cột ngày để phát huy tác dụng tích cực. Không có
        tác dụng nếu xuất hiện ở các cột khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ví như Quý Nhân của Đinh là Dậu. Thìn hợp với Dậu nhưng Thìn không hợp
        với Đinh Hỏa do khác cực. Tương tự, Quý Nhân cho Quý là Mão, Mão hợp
        Tuất. Vì Quý và Tuất là khác cực nên không thể cùng kết hợp để tạo thành
        cặp Can Chi Lục Hợp Quý. Điều này có nghĩa là không có cặp can chi lục
        hợp quý cho người sinh vào ngày Đinh và Quý.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người sinh vào Lục Hợp Quý bao gồm:
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        1. Qi Ji Guang sinh ngày Kỷ Tỵ, 12/11/1548 – tướng nhà Minh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        2. Thanh Thái Tông – Tân Hợi, 28/11/1592 – vua thứ 2 đời nhà Thanh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        3. Tăng Quốc Phiên – Bính Thìn, 21/11/1811 – học giả nhà Thanh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        4. Steve Jobs – Bính Thìn, 24/2/1955
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        5. Bill Clinton - Ất Sửu, 19/8/1946
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        6. John F Kennedy – Tân Mùi, 29/5/1917
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Hai sao này có tác dụng như nhau. Sao Thiên Đức biểu thị năng lượng
        Dương bảo vệ giúp mạnh mẽ, đáng tin cậy hơn. Sao Nguyệt Đức thì Âm hơn
        và biểu thị năng lượng bảo vệ nhẹ nhàng hơn. Dù cho khác biệt âm dương
        nhưng cả 2 sao có thể làm giảm bớt tác dụng xấu. Nếu bạn có cả 2 sao này
        trong lá số, thì bạn sẽ là người nhân từ, rộng lượng, hòa ái và nhận
        được nhiều sự trợ giúp từ vũ trụ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người có cả 2 sao thường thành công và siêng năng làm việc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        1. Mao Trạch Đông (26/12/1893)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        2. Chu Ân Lai (5/3/1898)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        3. Nhạc Phi (24/3/1103)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        4. Dwight D. Eisenhower (14/10/1890)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        5. Napoleon Bonaparte (15/8/1769)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Cận đại hơn, những tỷ phú ở Silicon Valley có 1 trong 2 sao:
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        1. Bill Gates (28/10/1955)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        2. Larry Ellison (17/8/1944)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        2. Larry Ellison (17/8/1944)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        3. Larry Page (26/3/1973)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        4. Steve Jobs (24/2/1955)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        5. Jeff Bezos (12/1/1964)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu sao Nguyệt Đức, Thiên Đức không có trong lá số, bạn cũng có thể dùng
        khi gặp trong Đại Vận hay năm. Hãy nhớ rằng các sao này hoạt động khác
        nhau nên cần ghi chú lại những gì nó gây ảnh hưởng cho bạn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trong lá số Bill Gates, thì hành Tài ở trong sao Đức, vì vậy ông ta nổi
        tiếng là người làm từ thiện. Nếu ngũ hành Quan là dụng thần và có chứa
        trong sao Đức, thì công danh sự nghiệp. Nếu Ấn có trong sao Đức thì lợi
        ích càng nâng cao. Ví dụ, Minh Thành Tổ có hành Tài trong 3 Thiên Đức,
        Nguyệt Đức của ông.
      </p>

      <img style={{ width: "100%" }} src={luchopquy} />
    </div>
  );
};
export const PhucTinhQuyNhan = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Sao Phúc Tinh Quý Nhân là cát tinh, sẽ đem lại quan hệ và phù hộ tốt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người có ngày sinh này thì sẽ không gặp khó khăn về tiền bạc, cả đời
        luôn gặp may mắn và có danh tiếng. Người có Phúc Tinh Quý Nhân thường
        khôn khéo và sống thọ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mệnh có Phúc Tinh, thì cả đời phúc lộc vô hạn, kim ngọc mãn đường, cả
        đời ăn no mặc ấm không phải lo nghĩ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Phúc Tinh Quý Nhân theo các nguồn tài liệu khác nhau thì có đến 2 cách
        tính khác nhau. Ở đây để tôn trọng học thuật, chúng tôi liệt kê luôn cả
        2 cách tính toán Phúc Tinh Quý Nhân như dưới đây:
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Giáp Tý và Giáp Ngọ là Lục Hợp Quý.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tác dụng của Lục Hợp Quý sẽ mạnh mẽ hơn là Quý Nhân trong Tứ Trụ. Đặc
        biệt là xét về mặt tài lộc và vận may khi vào trung niên. Sao Lục Hợp
        phải xuất hiện trong cột ngày để phát huy tác dụng tích cực. Không có
        tác dụng nếu xuất hiện ở các cột khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ví như Quý Nhân của Đinh là Dậu. Thìn hợp với Dậu nhưng Thìn không hợp
        với Đinh Hỏa do khác cực. Tương tự, Quý Nhân cho Quý là Mão, Mão hợp
        Tuất. Vì Quý và Tuất là khác cực nên không thể cùng kết hợp để tạo thành
        cặp Can Chi Lục Hợp Quý. Điều này có nghĩa là không có cặp can chi lục
        hợp quý cho người sinh vào ngày Đinh và Quý.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người sinh vào Lục Hợp Quý bao gồm:
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        1. Qi Ji Guang sinh ngày Kỷ Tỵ, 12/11/1548 – tướng nhà Minh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        2. Thanh Thái Tông – Tân Hợi, 28/11/1592 – vua thứ 2 đời nhà Thanh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        3. Tăng Quốc Phiên – Bính Thìn, 21/11/1811 – học giả nhà Thanh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        4. Steve Jobs – Bính Thìn, 24/2/1955
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        5. Bill Clinton - Ất Sửu, 19/8/1946
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        6. John F Kennedy – Tân Mùi, 29/5/1917
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Hai sao này có tác dụng như nhau. Sao Thiên Đức biểu thị năng lượng
        Dương bảo vệ giúp mạnh mẽ, đáng tin cậy hơn. Sao Nguyệt Đức thì Âm hơn
        và biểu thị năng lượng bảo vệ nhẹ nhàng hơn. Dù cho khác biệt âm dương
        nhưng cả 2 sao có thể làm giảm bớt tác dụng xấu. Nếu bạn có cả 2 sao này
        trong lá số, thì bạn sẽ là người nhân từ, rộng lượng, hòa ái và nhận
        được nhiều sự trợ giúp từ vũ trụ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người có cả 2 sao thường thành công và siêng năng làm việc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        1. Mao Trạch Đông (26/12/1893)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        2. Chu Ân Lai (5/3/1898)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        3. Nhạc Phi (24/3/1103)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        4. Dwight D. Eisenhower (14/10/1890)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        5. Napoleon Bonaparte (15/8/1769)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Cận đại hơn, những tỷ phú ở Silicon Valley có 1 trong 2 sao:
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        1. Bill Gates (28/10/1955)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        2. Larry Ellison (17/8/1944)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        2. Larry Ellison (17/8/1944)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        3. Larry Page (26/3/1973)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        4. Steve Jobs (24/2/1955)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        5. Jeff Bezos (12/1/1964)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu sao Nguyệt Đức, Thiên Đức không có trong lá số, bạn cũng có thể dùng
        khi gặp trong Đại Vận hay năm. Hãy nhớ rằng các sao này hoạt động khác
        nhau nên cần ghi chú lại những gì nó gây ảnh hưởng cho bạn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trong lá số Bill Gates, thì hành Tài ở trong sao Đức, vì vậy ông ta nổi
        tiếng là người làm từ thiện. Nếu ngũ hành Quan là dụng thần và có chứa
        trong sao Đức, thì công danh sự nghiệp. Nếu Ấn có trong sao Đức thì lợi
        ích càng nâng cao. Ví dụ, Minh Thành Tổ có hành Tài trong 3 Thiên Đức,
        Nguyệt Đức của ông.
      </p>

      <img style={{ width: "100%" }} src={phuctinhqn} />
      <img style={{ width: "100%" }} src={phuctinhqn1} />
    </div>
  );
};
export const QuyHopQuyNhan = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Để xác định Quý Hợp thì cần các bước như sau:
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Với người sinh ngày Giáp thì xác định Can hợp đó là Kỷ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Sao Quý Nhân của Kỷ là Sửu và Mùi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Sao Quý Hợp của ngày sinh Giáp là Kỷ Sửu và Kỷ Mùi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sao Quý Hợp ảnh hưởng đến sự nghiệp và địa vị của 1 người. Nếu sao Quý
        Hợp có trong tứ trụ thì người đó sẽ có sự nghiệp tuyệt vời, công danh
        nổi tiếng cả đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi sao Quý Hợp tồn tại trong đại vận hay năm thì người đó sẽ thành công
        trong công việc hay tìm được việc làm tốt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Người sinh ngày Giáp: Kỷ Sửu, Kỷ Mùi là Quý Hợp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Người sinh ngày Giáp: Kỷ Sửu, Kỷ Mùi là Quý Hợp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Người sinh ngày Mậu: Quý Sửu, Quý Mùi là Quý Hợp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Người sinh ngày Canh: Ất Sửu, Ất Mùi là Quý Hợp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Người sinh ngày Ất: Canh Tý, Canh Thân là Quý Hợp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Người sinh ngày Kỷ: Giáp Tý, Giáp Thân là Quý Hợp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Người sinh ngày Bính: Tân Hợi, Tân Dậu là Quý Hợp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Người sinh ngày Nhâm: Đinh Mão, Đinh Tỵ là Quý Hợp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Người sinh ngày Tân: Bính Ngọ, Bính Dần là Quý Hợp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người có cả 2 sao thường thành công và siêng năng làm việc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        1. Mao Trạch Đông (26/12/1893)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        2. Chu Ân Lai (5/3/1898)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        3. Nhạc Phi (24/3/1103)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        4. Dwight D. Eisenhower (14/10/1890)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        5. Napoleon Bonaparte (15/8/1769)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Cận đại hơn, những tỷ phú ở Silicon Valley có 1 trong 2 sao:
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        1. Bill Gates (28/10/1955)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        2. Larry Ellison (17/8/1944)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        2. Larry Ellison (17/8/1944)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        3. Larry Page (26/3/1973)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        4. Steve Jobs (24/2/1955)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        5. Jeff Bezos (12/1/1964)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu sao Nguyệt Đức, Thiên Đức không có trong lá số, bạn cũng có thể dùng
        khi gặp trong Đại Vận hay năm. Hãy nhớ rằng các sao này hoạt động khác
        nhau nên cần ghi chú lại những gì nó gây ảnh hưởng cho bạn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trong lá số Bill Gates, thì hành Tài ở trong sao Đức, vì vậy ông ta nổi
        tiếng là người làm từ thiện. Nếu ngũ hành Quan là dụng thần và có chứa
        trong sao Đức, thì công danh sự nghiệp. Nếu Ấn có trong sao Đức thì lợi
        ích càng nâng cao. Ví dụ, Minh Thành Tổ có hành Tài trong 3 Thiên Đức,
        Nguyệt Đức của ông.
      </p>

      <img style={{ width: "100%" }} src={qhqn} />
    </div>
  );
};
export const ThaiAm = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Người có sao Thái Âm trong mệnh thì khi làm ăn, kinh doanh với nữ giới
        thì gặp may mắn.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sao này chủ về âm, biểu thị tốt cho nữ giới.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu gặp năm có sao Thái Âm thì nếu là nữ giới thì may mắn, nếu là nam
        giới thì cần thuận nghe theo lời nữ giới thì mới tốt đẹp.
      </p>

      <img style={{ width: "100%" }} src={thaiam} />
    </div>
  );
};
export const ThaiCucQuyNhan = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Người có sao Thái Cực Quý Nhân thì dễ được tôn trọng, ủng hộ và gia tăng
        địa vị.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có xu hướng xuất sắc trong thi cử, sự nghiệp và may mắn nhiều.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có xu hướng thích học những gì siêu nhiên, phi thường như Đông Y,
        phong thuỷ, tâm linh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người có Thái Cực Quý Nhân thường thông minh, thích học hỏi, được công
        nhận, được tôn trọng.
      </p>

      <img style={{ width: "100%" }} src={tcqn} />
    </div>
  );
};
export const ThaiDuong = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Sao Thái Dương biểu thị năng lượng tốt, cơ hội tiền bạc tốt, người duyên
        dáng, quý nhân, kết bạn mới.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sao này chỉ giúp ích cho nam giới. Nếu nữ giới mang mệnh này thường quá
        cứng rắn, mang tính nam giới nên hôn nhân khó thuận lợi, nhưng sự nghiệp
        có thể đạt đến vị trí lãnh đạo cao.
      </p>

      <img style={{ width: "100%" }} src={thaiduong} />
    </div>
  );
};
export const ThienAtQuyNhan = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Từ xưa đến nay thần sát thì có rất nhiều, riêng các loại thần sát liên
        quan đến Quý Nhân thì vốn có đến 11 loại thường được nhắc đến là: Thiên
        Ất Quý Nhân, Thái Cực Quý Nhân, Thiên Đức Quý Nhân, Nguyệt Đức Quý Nhân,
        Tam Kỳ Quý Nhân, Văn Xương Quý Nhân, Quốc Ấn Quý Nhân, Khôi Cương Quý
        Nhân, Tướng Tinh, Kim Dư và Đào Hoa
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thiên Ất Quý Nhân là thần sát mạnh nhất, đứng đầu trong tất cả các quý
        nhân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thiên Ât thuộc văn tinh, người có Thiên Ất thường thông minh trí huệ, có
        thiên tư sáng sủa, trí huệ siêu quần. Thường được người khác giúp đỡ,
        gặp hung hoá cát.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu Thiên Ất Quý Nhân là hỉ dụng thần, không xung khắc hình hại thì cả
        đời được che chở.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thiên Ất Quý Nhân nếu xuất hiện ở can năm gọi là đại quý nhân, nếu ở can
        ngày gọi là tiểu quý nhân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bao quanh và được người tốt giúp đỡ. Phụ nữ sẽ được sếp là đàn ông giúp
        đỡ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Dựa trên can ngày. Được xem như là Thiên Ất Quý Nhân và có thể tách ra
        là Âm và Dương Quý Nhân. Tuy nhiên, trong đời thực, chúng ta không cần
        cố gắng phân quý nhân dựa trên giới tính. Điều quan trọng là tìm cách
        xác định ngày, tháng và năm, cũng như vị trí và hướng với sao Quý Nhân.
        Điều này cho phép bạn lôi kéo sự giúp đỡ của quý nhân. Hãy nhớ lại những
        người từng giúp bạn trong quá khứ. Kiểm tra xem nếu họ sinh vào năm,
        tháng, ngày hay giờ có liên quan là sao Quý Nhân theo Can ngày của bạn.
        Hãy nhớ đến những người này đã giúp bạn như thế nào.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu vào giờ Tý trở đi gọi là Dương Quý, nếu từ giờ Ngọ trở đi gọi là Âm
        Quý.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trong lúc khó khăn, chúng ta có thể tìm lời khuyên, trợ giúp từ những
        quý nhân này. Không phải lúc nào cũng thấy quý nhân ở đời thực, chúng ta
        cũng có thể chọn ngày Quý Nhân theo lịch vạn niên.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ví dụ: Người sinh vào ngày Quý, có Mão là Quý nhân. Ngày Mão sẽ là ngày
        Quý Nhân, giờ Mão là giờ Quý Nhân và hướng Mão là hướng Quý Nhân. Để tìm
        sao Quý Nhân, bạn có thể cầu nguyện xoay về hướng Mão hoặc tìm những
        người sinh năm Mão. Xét về mặt cá nhân hay trang trí nhà cửa, bạn có thể
        dùng con Mão là linh vật, bùa may mắn hay vật trang trí hình Mão.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Vậy thì chính xác những sao Quý Nhân ảnh hưởng ra sao lên cá nhân và độ
        chính xác ra sao? Đối với ai có sao Quý Nhân trong lá số không có nghĩa
        là họ sẽ thành công nhanh chóng. Tuy nhiên, biểu thị thành tích trong
        suốt cuộc đời. Một vài lá số gặp trở ngại khó khăn vẫn có thể đạt thành
        công với sao Quý Nhân. Lá số có dòng chảy khí suôn sẻ có thể thất bại vì
        thiếu sao Quý Nhân. Công nương Diana không có sao Quý Nhân nào và cuộc
        đời may mắn của cô khá ngắn ngủi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhà chính trị Viên Thế Khải (16/9/1859) có lá số Tòng Ấn. Tuy nhiên, vì
        thiếu sự trợ giúp của sao Quý Nhân, nên mọi cố gắng thành vua đều thất
        bại.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Jacqueline Kennedy Onassis (14:30, ngày 28/7/1929) cưới 2 người đàn ông
        thành đạt? Cô ấy có đến 2 Quý Nhân bên cạnh trụ ngày, ở chi tháng và chi
        giờ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mao Trạch Đông (26/12/1893, lúc 7:30 phút), có ngày sinh là Đinh Dậu.
        Chi ngày chính là Quý Nhân và sao Văn Xương.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bác sĩ Lý Chính Đạo sinh ngày 24/11/1926, có sao Quý Nhân ở cột tháng.
        Chủ tịch Mao Trạch Đông trở thành lãnh đạo TQ sau khi chiến đấu với các
        đối thủ, trong khi Lý Chính Đạo vượt qua chống lại phải từ bỏ học thuật
        và trở thành người đạt giải Nobel trẻ nhất trong lịch sử. Tổng thống
        John F Kennedy (29/5/1917, 15:00) có lásố Tòng Ấn và đạt thành công khi
        còn trẻ. Tuy nhiên vì không có sao Quý Nhân nên thành công của ông ngắn
        hạn.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sao Quý Nhân dễ bị xung như trong trường hợp thượng nghị sĩ Robert
        Kennedy (sinh ngày 20/11/1925, 15:10 phút). Ông là ngày sinh Mậu can và
        Sửu Mùi là Quý Nhân của ông. Có Sửu trong cột năm. Ông bị ám sát vào
        ngày Mùi trong vận Mùi, khi sao Quý Nhân bị xung 2 lần.{" "}
      </p>

      <img style={{ width: "100%" }} src={taqn} />
    </div>
  );
};
export const ThienXa = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Người sinh ra 1 trong 4 ngày dưới đây được gọi là Thiên Xá Nhật hoặc có
        tài liệu gọi là Thiên Thứ. Tuy nhiên Xá hay Thứ thì ý nghĩa cũng tương
        tự như nhau là xá tội, tha thứ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Ngày Mậu Dần sinh trong tháng Dần Mão Thìn
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Ngày Giáp Ngọ sinh trong tháng Tỵ Ngọ Mùi
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Ngày Mậu Thân sinh trong tháng Thân Dậu Tuất
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Ngày Giáp Tý sinh trong tháng Hợi Tý Sửu
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người có Thiên Xá thì trong cuộc sống luôn may mắn cát tường. Có khả
        năng biến mọi thứ xấu thành tốt, và thường gặp nhiều phù hộ. Có thể trở
        nên giàu có và quyền lực. Cuộc sống không cần phải lo lắng và bất kỳ nơi
        nào đi thì cũng có người giúp đỡ. Nếu can ngày không vượng thì sức mạnh
        của sao này bị giảm xuống khá nhiều.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sao này có tính bảo vệ cao. Nếu sao Thiên Thứ xuất hiện trong lá số thì
        ngay cả nếu người này phạm tội thì cũng sẽ được tha bổng hoặc phán tội
        nhẹ. Khi sao Thiên Thứxuất hiện ở giờ hay ngày, thì có lợi cho pháp lý.
        Nếu bạn có 1 vụ kiện, thì tốt nhất là sử dụng ngày Thiên Thứ. Sao Thiên
        Thứcó thể xuất hiện trong trụ giờ, tháng hay năm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>Ví dụ:</p>
      <pre style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khách hàng A (sinh ngày 3/7/1937, 12h trưa) <br />
        Giờ Ngày Tháng Năm
        <br />
        Giáp Tân Bính Đinh
        <br />
        Ngọ Mão Ngọ Sửu
        <br />
      </pre>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Chung sinh vào tháng Ngọ. Sao Thiên Thứ là Giáp Ngọ ở trụ giờ, sao Thiên
        Hỷ Sửu ở chi năm, và sao Nguyệt Đức Bính Hỏa ở can tháng. Cô ấy không có
        sao Thiên Đức trong lá số nhưng có thể hưởng lợi nếu thấy xuất hiện
        trong Đại vận hay là vào năm, tháng, ngày, giờ nào. Cũng có thể tìm
        người có Hợi trong lá số vì những người này sẽ giúp đỡ bảo vệ cô.
      </p>

      <img style={{ width: "100%" }} src={thienxa} />
    </div>
  );
};
export const QuocAnQuyNhan = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Đem lại may mắn về thăng tiến, quyền lực và địa vị. Nhiều cơ hội gặp
        người quý nhân địa vị cao.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người có Quốc Ấn Quý Nhân tính tình thành thực, hành xử công bằng, đối
        xử nhân ái, trọng lễ nghĩa nhân đức.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu Quốc Ấn Quý Nhân trong bát tự lại gặp sinh vượng, có thêm cát tinh
        khác trợ giúp, lá số không gặp Xung Phá Khắc Hại, thì chủ nắm được quyền
        hành lớn.
      </p>
      <img style={{ width: "100%" }} src={qaqn} />
    </div>
  );
};
export const MoKho = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Là người hướng nội và cứng đầu, thích che giấu sự thật. Có nhiều tài
        năng ẩn giấu, thích tranh cãi, thường suy nghĩ tiêu cực, dễ bị rối loạn
        trong cảm xúc và tinh thần. Ý chí thường yếu đuối.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>Ví dụ:</p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Bính trong năm Tuất
      </p>
      <img style={{ width: "100%" }} src={mokho} />
    </div>
  );
};
export const NguyetDucHop = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Ý nghĩa của Nguyệt Đức Hợp như sau: thiên can tương hợp với Nguyệt Đức
        thì được xem là Nguyệt Đức Hợp. Thiên Nguyệt Nhị Đức, gặp thì có tác
        dụng phùng hung hoá cát.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Được giúp đỡ âm thầm. Luôn thoát được tai họa. Người nào có Nguyệt Đức
        Hợp hay Nguyệt Đức Quý Nhân thì sẽ tử tế và đồng cảm. Thích từ thiện và
        có khí chất. Mọi việc trong đời đều suôn sẻ và sống thọ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trong Đại Lục Nhâm cũng có nhấn mạnh đến ý “Nhất Đức Hoá Bách Sát”. Đặc
        biệt là trong mệnh có Thiên Nguyệt Nhị Đức lại được sinh vượng là xem là
        hỉ dụng thần thì sức mạnh càng lớn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nguyệt Đức Hợp và Nguyệt Đức tính chất đều tốt như nhau.{" "}
      </p>
      <img style={{ width: "100%" }} src={nguyetduchop} />
    </div>
  );
};
export const MocDuc = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Tương tự Đào Hoa. Nó cũng là một dạng sao Đào Hoa. Cũng thường gây ra
        cãi nhau trong quan hệ tình cảm.
      </p>

      <img style={{ width: "100%" }} src={mocduc} />
    </div>
  );
};
export const DaoHoa = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Sao này có thể hiểu theo 2 dạng: nếu ở năm và tháng, biểu thị Nội Đào
        Hoa, là dấu hiệu tình cảm hoà hợp giữa vợ chồng. Nếu xuất hiện ở trụ giờ
        thì biểu thị Ngoại Đào Hoa, biểu thị thích sắc dục, rượu chè và phụ nữ,
        quan hệ tình dục, thích vật chất, tiêu tiền và ra ngoài chơi bời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu Tứ Trụ có từ 3 Đào Hoa trở lên như Tý, Ngọ, Mão hay Dậu thì người
        này đi đến đâu cũng trăng hoa.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tâm trạng không ổn định, dễ hành xử phi đạo đức, chi xài tiền hao phí.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Lá số có Đào Hoa thường là người duyên dáng và ngoại hình ưa nhìn, nổi
        tiếng và đam mê, hơi nhiều dục vọng, xinh đẹp, hấp dẫn, gợi cảm, đào hoa
        nhưng cẩn trọng tránh việc ngoại tình. Sao này tốt cho người làm việc
        trong lĩnh vực giải trí và ăn chơi đêm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Văn hóa Trung Quốc thường có xu hướng khắc khe về tình cảm lãng mạn, nên
        sao Đào Hoa thường mang nghĩa xấu. Điều này đặc biệt ứng với phụ nữ, gắn
        với quan hệ tình cảm xấu. Đối với đàn ông, đó là tình cảm ngoài hôn
        nhân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tuy nhiên trong thời hiện đại thì sao Đào Hoa gắn với khả năng giao tiếp
        cá nhân tốt và sức cuốn hút cao. Những cá nhân có sao Đào Hoa thường
        thông minh, rộng lượng, nhạy cảm, quan tâm đến người khác và có sức hấp
        dẫn cao.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người nổi tiếng (ca sĩ, diễn viên,…) có sao Đào Hoa:
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        1. Trương Quốc Vinh (12/9/1956)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        2. Mai Diễm Phương (10/10/1963)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        3. Đặng Lệ Quân (29/1/1953)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sao Đào Hoa phổ biến trong những chính trị gia thành công, tướng lĩnh
        hay giới trí thức:
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        1. Chu Ân Lai (5/3/1898)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        2. Tập Cận Bình (15/6/1953)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        3. Cai Yuan Pei (11/1/1868)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        4. George Washington (22/2/1722)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        5. Nikita Khruschev (15/4/1894)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        6. Du Nhược Nhậm (11/4/1879)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        7. Lương Khải Siêu (23/2/1873)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        8. Nhạc Phi (24/3/1103)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        9. Hải Nhuệ (23/1/1514)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        10. Khang Hy (4/5/1564)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        11. Ung Chính (13/12/1678)
      </p>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sao Đào Hoa bị ảnh hưởng nhiều nhất bởi Hợp, Hình và Xung. Ảnh hưởng đến
        các mối quan hệ. Để ngăn chặn ảnh hưởng này, chúng ta cần lưu ý năm hay
        tháng xảy ra và tìm cách kiểm soát bản thân trong thời kỳ đó.
      </p>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi sao Đào Hoa ở cùng Địa Chi như sao Quý Nhân và cùng trụ như Thiên
        Đức, Nguyệt Đức, thì hình thành cát cục. Nó biểu thị thành công và quý.
        Khi sao Đào Hoa và Văn Xương ở cùng Địa Chi, người đó sẽ trở thành tác
        giả nổi tiếng.
      </p>

      <img style={{ width: "100%" }} src={daohoa1} />
    </div>
  );
};
export const ThienLaDiaVong = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Thìn và Tuất được xem là cổng thiên đàng và địa ngục, giờ Thìn buổi sáng
        là thiên môn, giờ Tuất là giờ tối nên là địa hộ. Nếu 1 người có cả Thiên
        Môn và Địa Hộ trong lá số, người đó có nhạy cảm và có thể có những năng
        lực ngoại cảm, có kinh nghiệm tâm linh. Thiên Môn Địa Hộ cũng thấy nhiều
        trong lá số của những vận động viên thể thao xuất sắc, ngôi sao võ
        thuật, người có tầm nhìn phi thường như Steve Jobs.
      </p>
      <pre style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
      Lý Tiểu Long 27/11/1940 <br/>
      Giờ	   Ngày	   Tháng       Năm <br/>
      Mậu	   Giáp	   Đinh        Canh <br/>
      Thìn   Tuất	   Hợi         Thìn <br/>
      </pre>
      <pre style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
      Lý Liên Kiệt 26/4/1963	<br/>
	    Giờ	  Ngày	    Tháng        Năm <br/>
	    Giáp	 Kỷ	      Bính	         Quý <br/>
	    Tuất	 Hợi	      Thìn	       Mão <br/>

      </pre>
      <pre style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
      Li Na 26/2/1982	<br/>
	    Giờ	  Ngày      Tháng      Năm<br/>
	    Canh	 Nhâm        Nhâm<br/>
	    Thìn	 Dần        Tuất<br/>
      </pre>
      <pre style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
      Maria Sharapova 19/4/1987 <br/>
	    Giờ	  Ngày	  Tháng      Năm <br/>
	    Mậu	  Giáp	  Đinh <br/>
	    Tuất	 Thìn	  Mão <br/>

      </pre>
      <pre style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
      Steve Jobs 24/2/1955 <br/>
	    Giờ 	Ngày	  Tháng     Năm	<br/>
	    Mậu 	Bính	  Mậu	      Ất<br/>
	    Tuất	Thìn	  Dần	      Mùi<br/>
      </pre>

      <pre style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
      Putin 7/10/1952 <br/>
	    Giờ 	Ngày	  Tháng      Năm <br/>
	    Quý 	Bính	  Kỷ	       Nhâm <br/>
	    Tỵ  	Tuất	  Dậu	       Thìn <br/>
      </pre>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
      Ngoài việc có giác quan thứ 6 thì những giờ này nếu xảy ra tai nạn cũng thảm khốc hơn.
      </p>
      <img style={{ width: "100%" }} src={thienladiavong} />
     
    </div>
  );
};

export const TamDaiQuy = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
    <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
    Địa chi xuất hiện 3 không xung ở 3 trụ liền kề.
	Dần Thìn Thân / Mão Tỵ Ngọ / Hợi Tý Dậu.
	Người nào có 1 trong 3 bộ này thì sẽ gặp may mắn có địa vị và quyền lãnh đạo lớn.

    </p>
    <img style={{ width: "100%" }} src={tamdaiquy} />
   
    </div>
  )
}

