import { createSlice } from "@reduxjs/toolkit";


const data = createSlice({
    name: "data",
    initialState: true,
    reducers: {
        saveData:
            (state, action) => {
                state = action.payload
                return state;
            },
    }
})

const { reducer, actions } = data;
export const { saveData } = actions;
export default reducer;