export const BoSungHanhTho = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
            fontWeight: "bold",
          }}
        >
          Bổ sung hành Thổ
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          <strong
            id="docs-internal-guid-b19e3739-7fff-1762-8738-51b3d12368dc"
            style={{ fontWeight: "normal" }}
          >
            &nbsp;
          </strong>
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Thiên:&nbsp;&nbsp;
        </span>
      </p>
      <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Chọn đại vận hoặc năm Mậu, Kỷ hoặc Thìn Tuất Sửu Mùi để lên kế
              hoạch dài lâu 5 - 10 năm.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Trong một năm, chọn tháng mùa hè: Tỵ, Ngọ, Mùi hoặc các tháng Thìn
              Tuất Sửu Mùi để khởi sự những kế hoạch đã tính toán từ trước nhằm
              đạt thành công.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Chọn những ngày Mậu Thìn, Mậu Tuất, Kỷ Sửu, Kỷ Mùi để khởi sự
              những công việc quan trọng.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Luôn lên kế hoạch cho những cuộc hẹn, gặp gỡ vào buổi trưa từ 7-9h
              sáng, 13h-15h, 19h-21 đêm.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Buổi sáng nên lập ăn sáng, cafe và làm những việc quan trọng vào
              lúc 7-9h sáng, tức giờ Thìn Thổ.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Chọn địa điểm gặp gỡ, cuộc hẹn ở các quán, nhà hàng ở góc Đông Bắc
              hoặc Tây Nam tính từ nhà mình.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Chọn số sim điện thoại, số nhà, số xe, hoặc căn cước, tài khoản
              ngân hàng có nhiều số 0, 5 thuộc về hành Thổ.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu mua hoặc thuê nhà chung cư, cao ốc văn phòng ưu tiên chọn
              phòng hoặc tầng có số 5, 10, 15, 20, 25.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nên giữ cho vùng bụng, dạ dày luôn khoẻ mạnh vì đây là đại diện
              cho hành Thổ mạnh hay yếu.&nbsp;&nbsp;
            </span>
          </p>
        </li>
      </ol>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          <strong style={{ fontWeight: "normal" }}>&nbsp;</strong>
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Địa:&nbsp;
        </span>
      </p>
      <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nên chọn nhà gần những nơi núi, đồi cao.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nhà có mái bằng, nhiều đường nét vuông vắn được xem là hành Thổ
              vượng cũng như nên có tổng số phòng hoặc tầng là 5,
              10…phòng.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Phòng ngủ đón ánh nắng mặt trời nhiều từ 7h - 12h trưa vì Bính Hoả
              sẽ giúp sinh Thổ. Tránh chọn hướng cửa sổ đón nắng chiều vào giờ
              13h - 15h tuy là cũng tốt và may mắn nhưng ánh nắng này có hại cho
              sức khoẻ.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Phòng ngủ trang trí điểm xuyết màu vàng, nâu.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Drap giường, chăn, gối, nệm nên chọn vàng, nâu, hồng đỏ.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nên bố trí đèn vàng trong ngủ và mở đèn suốt ngày 24/24h vì Hoả
              sinh Thổ..&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Tránh bố trí bếp gần toilet, bồn rửa vì Thuỷ Hoả tương xung và
              khiến cho Hoả bị suy yếu.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Trong nhà nên bố trí nhiều cửa sổ có thể đón được ánh nắng mặt
              trời ấm áp là tốt.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nên bố trí đèn vàng trong nhà và ưu tiên hơn đèn trắng.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Khi gặp xui xẻo nên đốt các gói xông thảo mộc, trầm hương để xua
              tan âm khí và giúp tạo ra hành Thổ.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Có thể đặt đá non bộ, đá thạch anh trang trí ở bên trong hoặc
              ngoài nhà.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Có thể đặt tượng Thần Phật bằng đá ở sân thượng, ban công để chống
              những sát khí.&nbsp;
            </span>
          </p>
        </li>
      </ol>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          <strong style={{ fontWeight: "normal" }}>&nbsp;</strong>
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Nhân:&nbsp;
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Trang Phục / Ngoại Hình:&nbsp;
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          <strong style={{ fontWeight: "normal" }}>&nbsp;</strong>
        </span>
      </p>
      <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Quấn áo màu đỏ, nâu hoặc vàng.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Chọn điện thoại màu vàng, nâu hoặc ốp lưng điện thoại màu đỏ,
              vàng, nâu.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nhuộm tóc vàng, để móng tay sơn màu vàng thuộc Thổ. Nhất là phần
              đầu có huyệt Bách Hội giúp hấp thu Thổ Khí.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu ngại nhuộm tóc đỏ có thể dùng kẹp tóc, băng đô buộc tóc màu
              vàng, nâu để gia tăng may mắn.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Đặt biểu tượng ngựa màu vàng trong xe hơi hoặc làm móc khoá điện
              thoại.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              San hô đỏ mang tính Hoả rất tốt để mang theo bên người.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Chọn đeo vòng tay bằng đá núi lửa.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Đeo kim cương hoặc các loại đá quý rất tốt để bổ sung Thổ.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Đeo các loại đá thạch anh, nhất là loại có màu vàng sẽ rất tốt.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Gọng mắt kính màu vàng, đỏ cũng sẽ giúp tăng cường hành Thổ.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Chọn ví bóp màu vàng hoặc cho 1-2 đồng xu màu vàng hoặc vào trong
              bóp ví để tăng cường may mắn về tài lộc.&nbsp;
            </span>
          </p>
        </li>
      </ol>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          <strong style={{ fontWeight: "normal" }}>&nbsp;</strong>
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Ẩm Thực&nbsp;
        </span>
      </p>
      <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Hút thuốc hoặc xông thuốc như ngải cứu.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Uống rượu vang đỏ
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Ăn các loại trái cây giàu hàm lượng chất chống oxy hoá.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Ăn 1 ít ớt, tỏi, tiêu gừng, gia vị cay nóng là bổ sung hành Hoả.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Thỉnh thoảng ăn đồ nướng hoặc mì cay cũng rất tốt. Tuy nhiên cần
              chú ý tránh đồ nướng thành than vì sinh ra Thổ bất lợi.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Ăn thịt chó cũng rất tốt để bổ sung Hoả Thổ.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Ăn thịt trâu bò rất tốt, đặc biệt là thịt trâu giàu dinh dưỡng.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Ăn thịt dê, cừu rất tốt để bổ sung cả Hoả và Thổ.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Ăn các loại chè ngọt, mật ong sẽ giúp tăng cường sức khoẻ.&nbsp;
            </span>
          </p>
        </li>
      </ol>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          <strong style={{ fontWeight: "normal" }}>&nbsp;</strong>
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Động Vật / Vật Phẩm:&nbsp;
        </span>
      </p>
      <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nuôi chó là tốt.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Đặt tượng hình rồng rất tốt để tăng cường may mắn về tài
              lộc.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Tượng hoặc hình rồng nếu được nên để ở gần bể cá hoặc nhìn về
              hướng Bắc sẽ tốt hơn vì Bắc có Thuỷ là phương vị của rồng.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Đặt tượng hình dê, cừu hoặc trâu bò sẽ giúp may mắn ổn định về mặt
              tài sản.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu có điều kiện nên thường xuyên đến sở thú, thảo cầm viên thăm
              các khu vực nuôi nhiều loại chó, dê, cừu, trâu nước.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Ở gần nơi nuôi nhiều ngựa là tốt.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Đi xem đua chó, đua ngựa như Đại Nam, trường đua Phú Thọ là tốt.
            </span>
          </p>
        </li>
      </ol>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          <strong style={{ fontWeight: "normal" }}>&nbsp;</strong>
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Thói Quen Tốt:&nbsp;
        </span>
      </p>
      <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Đi du lịch về miền Trung: Đà Nẵng, Huế, Hội An hoặc đến các vùng
              núi như Đà Lạt, núi Yên Tử, Mẫu Sơn.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Đi du lịch nước ngoài: đến Trung Quốc, Địa Trung Hải, Trung
              Đông.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Đi leo núi, đến viếng những núi linh thiêng như Long Hổ Sơn, Ngũ
              Nhạc, Núi Bà Đen, Núi Sam Châu Đốc,…sẽ rất tốt.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu đi viếng các núi linh thiêng, có ý nghĩa về tâm linh tôn giáo
              nên đi vào tháng Thìn, Tuất là Thiên Môn Địa Hộ sẽ rất tốt.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Khi đi đến các khu vực núi thiêng nên lấy 1 ít đất, đá tại đây về
              đặt trong vườn, đất nhà để may mắn thêm.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Thắp hương, đi chùa lễ lạt, đốt hương tại ban thờ, tránh để ban
              thờ lạnh lẽo, đặc biệt là ban thờ Thần Tài Thổ Địa.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Khi đi đến đền chùa, nên xin một ít tro hương nhang để đem về cho
              vào trong bát hương nhà để tăng cường may mắn.&nbsp;
            </span>
          </p>
        </li>
      </ol>
      <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Để đèn ngủ ban đêm và sạc điện thoại xa giường ngủ.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Trong phòng bếp nên dùng bếp gas.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nến tắm đứng và không nên ngâm bồn nước quá lâu.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu thích làn da nâu có thể nhuộm da, phơi nắng.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu mệt mỏi trong người có thể đi xông hơi hoặc giác hơi.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Khi mệt mỏi nhiều nên dành nhiều thời gian để ngủ sẽ lấy lại
              sức.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Ban đêm nên ngủ trước 11h sẽ tốt cho sức khoẻ hơn. Không nên thức
              quá khuya, đặc biệt là sau 0h sẽ bất lợi.&nbsp;
            </span>
          </p>
        </li>
      </ol>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          <strong style={{ fontWeight: "normal" }}>&nbsp;</strong>
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Tìm Quý Nhân:
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        &nbsp;
      </p>
      <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Tìm đến quý nhân nhiều Thổ như sinh tháng Thìn Tuất Sửu Mùi, nhiều
              Mậu Kỷ trong lá số.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Tìm đến bạn bè có khuôn mặt vuông vắn, cằm vuông như chữ Điền càng
              tốt.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Kết giao với bạn bè, khách hàng, đối tác ở miền Trung như Đà Nẵng,
              Huế,…hoặc Trung Quốc, khu vực Trung Đông, Địa Trung Hải.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Theo dõi thần tượng những nghệ sĩ, doanh nhân có lá số nhiều Thổ:
              Donald Trump, Phạm Nhật Vượng.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Có thể mở công ty thiết kế, xây dựng nhà ở, môi giới bất động sản,
              buôn bán vật liệu xây dựng hay đầu tư vào các mã cổ phiếu ngành
              này.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Khi gặp khó khăn, mệt mỏi có thể thắp 1 nén nhang tại ban thờ ở
              nhà, đi đến đình chùa, sẽ tự khắc tìm được quý nhân.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nhất là thắp hương thờ cúng Thổ Địa cũng sẽ rất có ích.
            </span>
          </p>
        </li>
      </ol>
    </div>
  );
};
