export const BoSungHanhThuy = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
            fontWeight: "bold",
          }}
        >
          Bổ sung Hành Thuỷ&nbsp;
        </span>
      </p>
      <p>
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          <strong
            id="docs-internal-guid-97c20c46-7fff-4980-8804-616ef7cbbb4e"
            style={{ fontWeight: "normal" }}
          >
            &nbsp;
          </strong>
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Thiên:&nbsp;&nbsp;
        </span>
      </p>
      <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Chọn đại vận hoặc năm Hợi Tý hoặc Nhâm, Quý để lên kế hoạch dài
              lâu 5 - 10 năm.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Trong một năm, chọn tháng mùa đông như Hợi Tý Sửu để lên khởi sự
              những kế hoạch đã tính toán từ trước nhằm đạt thành công.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Chọn những ngày Nhâm Tý, Quý Hợi hoặc Quý Sửu để khởi sự những
              công việc quan trọng.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Luôn lên kế hoạch cho những cuộc hẹn, gặp gỡ vào buồi chiều (từ
              15h) hoặc buổi tối tức từ 21h - 1h tức giờ Hợi, Tý và Sửu.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nên đi ngủ sớm trong khoảng từ 21h đến trước 0h vì đây là giờ Tý
              vượng nhất, nếu ngủ sau 12h đêm sẽ không có lợi cho sức
              khoẻ.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Chọn địa điểm gặp gỡ, cuộc hẹn ở góc Bắc tính từ nhà mình. Góc Bắc
              thuộc về hành Thuỷ.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Và nên chọn địa điểm hẹn gặp là quán cafe, giải khát, đặc biệt
              những quán nào có hồ nước, thác nước nhiều.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Chọn số sim điện thoại, số nhà, số xe, hoặc căn cước, tài khoản
              ngân hàng có nhiều số 1, 6 thuộc về hành Thuỷ.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu mua hoặc thuê nhà chung cư, cao ốc văn phòng ưu tiên chọn
              phòng hoặc tầng có số 1, 6, 11, 16, 21, 26...&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu là phụ nữ có thể đánh mascara màu xanh biển đê lôi cuốn đối
              tác.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nên kiểm tra phần da vùng 2 tai, nếu tại đây màu đen là biểu hiện
              xấu về sức khoẻ, nếu là tai bên trái là quá khứ xấu, nếu là tai
              bên phải là trong vòng 10 ngày sắp tới sức khoẻ xấu và xui xẻo.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu vùng miệng quá đen, hoặc tô son màu đen là xấu, biểu thị bộ
              phận sinh dục bị viêm nhiễm, dễ bị bệnh phụ khoa, nên chọn màu
              hồng, đỏ tự nhiên là tốt.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Không nên ra đường vào buổi trưa lúc 12h là lúc Hoả cực vượng sẽ
              không may mắn.&nbsp;
            </span>
          </p>
        </li>
      </ol>
      <p>
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          <strong style={{ fontWeight: "normal" }}>&nbsp;</strong>
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Địa:&nbsp;
        </span>
      </p>
      <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nên chọn nhà ở nơi có nhiều nước như Thanh Đa, Đảo Kim Cương, Bến
              Tre, Thảo Điền, sông Sài Gòn.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nhà nên lợp mái ngói âm dương, mái ngói hoặc tôn uốn cong như sóng
              biển, chọn màu xanh dương, đen, nâu là lý tưởng.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Phòng ngủ nên có cửa sổ hoặc ban công đón ánh trăng rằm là tốt
              nhất. Ánh trăng là nguyệt quang mang tính dịu dàng không gay gắt
              như ánh mặt trời Hoả khí nóng.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Phòng ngủ trang trí điểm xuyết trên trần có các hình ngôi sao, bầu
              trời đêm bằng dạ quang để khi tắt đèn ngủ sẽ rất tốt cho giấc ngủ
              của người cần Thuỷ.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Tường nên sơn hoặc có giấy dán tường có nhiều hoa văn hành Thuỷ,
              xanh biển, gợn sóng. Có thể treo tranh hình bãi biển, đại dương ở
              góc Bắc trong phòng ngủ.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Drap giường, chăn, gối, nệm nên chọn màu xanh biển, hoặc màu trắng
              Kim vì Kim sinh Thuỷ.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nên bố trí một chậu cá nhỏ hay chậu cây thuỷ sinh ở gần bàn làm
              việc.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Có thể bố trí thác nước, hồ cá Koi, hòn non bộ để kích hoạt tài
              lộc tuy nhiên vị trí đặt thì cần phải được chuyên gia phong thuỷ
              tư vấn cụ thể vì nếu đặt sai thì rất nguy hiểm.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Tránh bố trí bếp gần toilet, bồn rửa vì Thuỷ Hoả tương xung sẽ làm
              cho hành Thuỷ không vượng.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Có thể bố trí phòng xông hơi hoặc bể bơi trong nhà nếu muốn. Tuy
              nhiên vị trí đặt để cũng cần phải được tư vấn từ chuyên gia.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Góc Bắc của nhà tránh đặt nhiều đá phong thuỷ sẽ bất lợi cho chủ
              nhà cần Thuỷ.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Có thể treo chuông gió 6 ống đồng tại góc Bắc hoặc sao Nhất Bạch
              Thuỷ Tinh để có lợi.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Khi gặp xui xẻo nên đun vỏ cây quế trong nước sôi rồi lấy nước này
              để lau rửa khắp nhà sẽ giúp xua tan âm khí. Cũng có thể nhỏ 1 ít
              tinh dầu quế vào nước để lau dọn ban thờ, nhà cửa.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Tránh nhìn thấy ao nước tù đọng, muỗi mòng, bẩn thỉu, nhiều rác
              tại khu vực phía Bắc sẽ khiến cho nhà bị danh tiếng và tài lộc
              xấu.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nên có rãnh hứng vào lu nước đựng nước mưa sẽ tốt hơn. Nước mưa là
              lộc của ông trời, không nên để chảy tự do. Nên hứng lại và dùng để
              rửa sân, tưới cây cối trong vườn.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu biết cách thiết kế phong thuỷ thì có thể cho đào các rãnh
              thoát nước mưa - trong phong thuỷ gọi là thuỷ khẩu sẽ rất tốt cho
              tài lộc.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Giường ngủ không nên đặt gần toilet hoặc xoay đầu giường về
              toilet, phòng tắm sẽ khiến cho giấc ngủ nhiều ác mộng.&nbsp;
            </span>
          </p>
        </li>
      </ol>
      <p>
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          <strong style={{ fontWeight: "normal" }}>&nbsp;</strong>
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Nhân:&nbsp;
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Trang Phục / Ngoại Hình:&nbsp;
        </span>
      </p>
      <p>
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          <strong style={{ fontWeight: "normal" }}>&nbsp;</strong>
        </span>
      </p>
      <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Làn da, đặc biệt là da mặt nên sáng trắng là tốt, không nên bị đen
              sẽ không tốt vì da trắng là Thuỷ sáng, da đen là Thuỷ u tối, thiếu
              may mắn.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Quần áo màu xanh dương sẽ tốt. Đừng mặc quần áo màu đen vì màu đen
              là màu hấp thu tất cả năng lượng nên nếu đi đến những nơi có đám
              tang, nghĩa trang tuyệt đối không nên mặc.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Có thể chọn áo quần thể thao có logo Cá Sấu.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Chọn in danh thiếp, thiết kế logo công ty có nhiều màu sắc xanh
              biển, đen, đường nét gợn sóng.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nhuộm tóc highlight có màu xanh, để móng tay sơn màu xanh sẽ tốt.
              Nhất là phần đầu có huyệt Bách Hội giúp hấp thu Thuỷ Khí.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu ngại nhuộm tóc màu xanh có thể dùng kẹp màu xanh dương, băng
              đô buộc tóc màu đen, xanh để gia tăng may mắn.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Đặt lọ nước hoa trong xe hơi hoặc chọn nội thất xe hơi hoặc xe có
              màu đen, xanh dương sẽ giúp gia tăng may mắn.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Có thể chọn đeo ngọc trai vì ngọc trai có hành Thuỷ rất cao.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu muốn biết hiện đang có may mắn hay không chỉ cần nhìn vào mắt,
              xem trắng đen rõ ràng (không có màu đỏ, vàng…) thì là đang may mắn
              thuận lợi.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Gọng mắt kính màu xanh dương, đen cũng sẽ giúp bổ sung thêm hành
              Thuỷ. Hoặc có thể đeo kính râm màu đen khi ra đường để tránh tiếp
              xúc ánh nắng quá nhiều tức Hoả vượng thì Thuỷ suy.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Chọn ví bóp màu xanh dương để tăng cường may mắn về tài lộc.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Có thể đeo bông tai bằng kim loại bạc hay vàng sẽ tốt. Tuy nhiên
              chỉ nên đeo khi trên 16 tuổi.&nbsp;
            </span>
          </p>
        </li>
      </ol>
      <p>
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          <strong style={{ fontWeight: "normal" }}>&nbsp;</strong>
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Ẩm Thực&nbsp;
        </span>
      </p>
      <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Ăn thuỷ hải sản, nhất là các loại cá đồng, cá biển sẽ giúp tăng
              cường hành Thuỷ.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Uống một ít bia lạnh để tăng cường tiêu hoá cũng sẽ tốt.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Người cần hành Thuỷ hay thích uống nhiều nước đá, nhất là ở miền
              Nam do nóng bức nên thường thịnh hành trà đá tuy nhiên uống đồ quá
              lạnh sẽ không tốt cho sức khoẻ.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu cơ thể hay bị nóng trong người có thể chọn các loại nước sâm
              giải nhiệt, làm mát cơ thể.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Ăn thịt lợn, thịt vịt mang tính Thuỷ.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Có thể dùng Omega 3 để tăng cường hành Thuỷ.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Người Thuỷ yếu có thể ăn hải sâm, bào ngư, vi cá để bổ sung hành
              Thuỷ.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Tổ yến, nấm mèo đen cũng là hành Thuỷ.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nấu chè mè đen, chè đậu đen để ăn sẽ giúp tăng cường hành Thuỷ lợi
              cho thận.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Có thể uống sữa, dùng phô mai, bơ để tăng cường Kim Thuỷ.&nbsp;
            </span>
          </p>
        </li>
      </ol>
      <p>
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          <strong style={{ fontWeight: "normal" }}>&nbsp;</strong>
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Động Vật / Vật Phẩm:&nbsp;
        </span>
      </p>
      <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nên nuôi cá trong nhà hoặc trong sân sẽ tốt, đặc biệt là cá rồng
              như Kim Long, Ngư Long.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Đặt 1 bể nuôi cây thuỷ sinh hoặc đặt 1 chậu trúc quan âm, trúc
              thuỷ trong phòng làm việc, tại khu vực ban thờ sẽ tốt.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nuôi chuột Hamster, chuột bạch hoặc lợn Guinea kiểng cũng sẽ
              tốt.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Treo tranh con lợn dòng tranh Đông Hồ truyền thống.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Treo tranh Niên Nhiên Hữu Dư gồm 9 con cá chép sẽ tốt biểu thị mỗi
              năm đều thịnh vượng.&nbsp;
            </span>
          </p>
        </li>
      </ol>
      <p>
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          <strong style={{ fontWeight: "normal" }}>&nbsp;</strong>
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Thói Quen Tốt:&nbsp;
        </span>
      </p>
      <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Đi du lịch về phía Bắc: Hạ Long, Hà Giang, Hà Nội, Hồ Ba Bể, các
              đập thuỷ điện Sơn La, Hoà Bình.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Đi du lịch nước ngoài: đến Bắc Kinh, Tây Hồ, Hàng Châu (Trung
              Quốc), Nhật Bản, …
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Lúc rãnh rỗi có thể đi đến công viên nước, thuỷ cung để ngắm cá
              tăng cường may mắn.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Sinh hoạt tình dục đều đặn, giữ gìn bộ phận sinh dục sạch sẽ tránh
              nhiễm bẩn.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Uống nhiều nước, tránh để cơ thể rơi vào tình trạng khô nóng. Nên
              đặt 1 bình nước bên cạnh bàn làm việc và giường ngủ để lúc nào
              cũng tiện uống nước bổ sung Thuỷ. Nếu để môi khô, nứt nẻ, hoặc lở
              miệng là khuyết Thuỷ cực độ, không tốt.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Khi ngủ dậy nên uống Âm Dương Thuỷ, tức 1 cốc nước nóng đun sôi và
              1 cốc nước lạnh sẽ rất tốt cho cơ thể.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Trong cơ thể con người thì các bộ phận đại diện cho hành Thuỷ là:
              tai, thận, máu huyết, cơ quan sinh dục. Nếu bị bệnh, viêm, nấm,
              mụn tại những khu vực này đều rất nguy hiểm. Cần đi khám ngay.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Đêm ngủ nên tắt hết đèn và TV, tránh mở TV hoặc đèn suốt đêm khi
              ngủ sẽ không tốt cho sức khoẻ.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Tránh đặt hồ cá trong phòng ngủ vì phòng ngủ là Âm, hồ cá là Dương
              sẽ khiến cơ thể thao thức, quá dương nên không ngủ được.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Lúc cảm thấy quá căng thẳng, mệt mỏi có thể đi gội đầu, bơi lội để
              giải toả stress và giúp bản thân suy nghĩ được tốt hơn.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nên giữ gìn vệ sinh phòng tắm thật tốt, không nên để vòi nước bị
              hỏng, rò rỉ nước hoặc thoát nước kém đều biểu thị sức khoẻ và tài
              lộc hao tán.&nbsp;&nbsp;&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu phòng tắm rộng nên bố trí một bồn tắm nằm, thỉnh thoảng ngâm
              mình với muối biển (đặc biệt là muối biển Chết) sẽ rất tốt cho sức
              khoẻ.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nên giữ cho làn da trắng, đặc biệt là khuôn mặt trắng trẻo, tròn
              trịa đầy đặn (tránh xương xẩu, góc cạnh) sẽ gặp nhiều may mắn
              thuận lợi.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Lúc rảnh rỗi nên đi câu cá, hoặc đi bơi lội sẽ gặp nhiều may mắn.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu bị cách ly vì dịch bệnh, không đi dược ra ngoài có thể chùi
              rửa nhà vệ sinh, rửa xe, hoặc tưới cây cối, tắm cho chó mèo trong
              nhà để cải thiện vận may.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Thường xuyên lấy ráy tai và giữ tai sạch sẽ, nếu tai dơ là biểu
              thị Thổ khắc Thuỷ không tốt.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Có thể thường xuyên nhai kỹ khi ăn, ăn kẹo chewinggum sẽ giúp
              khoang miệng tiết ra Ngọc Tuyền thuỷ. Theo Đông Y thì Ngọc Tuyền
              Thuỷ sẽ giúp cơ thể sống thọ.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Đến tiết Đông Chí (21/12 Dương Lịch hàng năm), nên đến các khu vực
              thác nước, suối để hứng nước Thái Âm sẽ rất tốt để dành lau dọn
              phòng thờ, ban thờ, nhà cửa.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Chợ là nơi có nhiều Thuỷ, nhất là chợ cá hải sản, nên đi bộ đến
              chơ mua rau, mua cá sẽ tốt hơn đi siêu thị.&nbsp;
            </span>
          </p>
        </li>
      </ol>
      <p>
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          <strong style={{ fontWeight: "normal" }}>&nbsp;</strong>
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Tìm Quý Nhân:
        </span>
      </p>
      <p>&nbsp;</p>
      <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Tìm đến quý nhân nhiều Thuỷ: người sinh tháng Hợi, Tý trong lá số
              có Hợi Tý Sửu, có Thân Tý Thìn, hoặc có ngày sinh Nhâm Quý.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Tìm đến bạn bè có cặp mắt to tròn, khuôn mặt có hành Thuỷ (mặt
              tròn trịa, cằm đôi, nhiều thịt).
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Kết giao với bạn bè, khách hàng, đối tác ở miền Bắc Việt Nam,
              Trung Quốc, Hong Kong, Đài Loan, Nhật Bản, Nga.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Theo dõi thần tượng những nghệ sĩ, danh nhân có lá số nhiều Thuỷ.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Có thể mở quán cafe, trà sữa, đồ ăn mang đi.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Có thể nộp đơn vào làm hoặc đầu tư trong những ngành như
              Logistics, Vận Tải, Bán Lẻ, Sản Xuất Hàng Tiêu Dùng, Nước Giải
              Khát.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Khi gặp khó khăn, mệt mỏi có thể đi du lịch, đi chơi đến những nơi
              có biển, nhiều hồ ao, sông ngòi sẽ tự nhiên nghĩ ra được giải pháp
              thoát khỏi bế tắc.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu chưa theo bất kỳ tôn giáo nào có thể theo Thiên Chúa Giáo vì
              Ki Tô vốn gắn liền với hành Thuỷ. Trẻ mới sinh ra thì được rửa
              tội, xoa dầu thánh, kiến trúc bên trong chủ yếu sử dụng tông màu
              trắng và đen, toà thánh Vatican lại rất gần khu vực biển)
            </span>
          </p>
        </li>
      </ol>
    </div>
  );
};
