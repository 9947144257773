export const QuyDau = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Quý Dậu: người giỏi giao tiếp, có khả năng thích nghi
        cao đối với mọi môi trường, dễ nổi nóng, có vẻ ngoài nhìn trẻ hơn tuổi
        thật, mất kiên nhẫn, không phải tuýp người năng động và tràn đầy sinh
        lực. Có thể có sự nghiệp tốt dù làm trong bất kỳ lĩnh vực nào. Phù hợp
        lấy người ít hoạt động, năng động. Nếu là nam giới, không thể tiếp quản
        được việc kinh doanh của dòng họ. Nếu là nữ giới, tính cách độc lập
        nhưng sau khi kết hôn sẽ cảm thấy cuộc sống trong gia đình trở nên bấp
        bênh và nhàm chán.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mưa mùa thu tượng trưng cho Quý Dậu. Quý Dậu ủng hộ ý tưởng về công lý
        và chính nghĩa, và sẽ làm tất cả mọi điều cần thiết trong khả năng để đề
        cao tính minh bạch. Điều này biểu thị mưa sẽ dọn sạch môi trường, làm
        cho mọi thứ đúng trật tự và sức sống tươi mới.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trong số 60 ngày sinh thì Quý Dậu được xem là khá nhạy cảm và có thể là
        nhạy cảm nhất trong số các trụ ngày Thuỷ. Tương tự, họ là những con
        người thông minh có khả năng tự giáo dục bản thân nếu cần thiết. Họ sở
        hữu động lực để tự nghiên cứu và tìm kiếm câu trả lời. Họ có khả năng
        tạo động lực bên trọng cho phép khám phá các khái niệm mới và lựa chọn
        con đường họ muốn đi. Bởi vì điêu này, thành công của họ là mạnh mẽ vì
        do động lực không ngơi nghỉ của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Được dẫn lới bởi cảm xúc và trực giác, hành động của họ có thể thẳng
        thắn với nhiều người. Tâm trí tập trung của họ dựa trên sự công bằng và
        chính nghĩa mà họ ủng hộ. Người Quý Dậu hành xử không chê vào đâu được
        trước công chúng và thể hiện quý khí. Họ có khả năng kết nối mọi nguồn
        năng lượng và nỗ 83 lực để hành động tập trung cho một dự án công việc
        tại một thời điểm. Điều này giúp tập trung cao độ, tinh thần sắc bén
        cùng với nhận thức tốt và cuối cùng tất cả giúp họ đảm bảo thành công.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Dậu có tính cách dám nghĩ dám làm và năng động, họ thường tỏ
        ra không sợ hãi hay thích mạo hiểm. Những thử thách đối với người khác
        có vẻ rất khó khăn thì chỉ là sợi lông tơ đối với họ. Những trở ngại
        tưởng như bất khả khiến cho những người này có một nguồn năng lượng to
        lớn để vượt lên trên cuộc sống tẻ nhạt của những người xung quanh họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có bản tính yêu thích thử thách và có thể xây dựng sự nghiệp dựa trên
        điều này. Khi sự nghiệp của họ phát triển, họ có cơ hội để thu được một
        lượng lớn tài lộc và đạt thành công vĩ đại. Là người có kiến thức cao,
        họ sống tốt vả giàu có cũng như bền bỉ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù họ có khả năng để thành công cao, họ nên đặt các mục tiêu theo
        thứ tự ưu tiên để có thể tránh thái độ quá vì đồng tiền. Sự tham lam có
        thể khiến họ lạc bước khỏi mục tiêu thật sự của mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Dậu thường đợi đến khi đứng tuổi mới theo đuổi được hạnh phúc
        và viên mãn thật sự. Điều này khiến cho họ làm việc vất vả lúc còn trẻ
        để đạt được ổn định về tài chính. Một khi họ tìm được bạn đời, họ sẽ
        vươn lên để tạo ra mái ấm hoàn hảo. Điều này đặc biệt đúng đối với phụ
        nữ sinh ngày Quý Dậu. Họ có xu hướng cần cù và là người nội trợ tiết
        kiệm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Những Đặc Tính Của Người Quý Dậu:</b> thông minh, độc lập, trực giác,
        chính nghĩa, không sợ hãi, năng lượng cao, tự tạo động lực.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Dậu là lãnh đạo có tầm nhìn, suy nghĩ độc lập, thẳng thắn và
        mạnh mẽ. Người Quý Dậu sẵn lòng sử dụng các mối quan hệ của họ để gia
        tăng cơ hội thành công cho họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bởi vì quan điêm độc lập mà họ nắm giữ, họ thường có xu hướng suy nghĩ
        lớn. Kiểu suy nghĩ này thường diễn dịch ra thành khởi nghiệp kinh doanh
        mới hoặc dẫn đầu những dự án quan trọng. Họ yêu thích thành công lớn và
        thực 84 hiện nó theo trực giác và chiến lược. Tốt nhất là họ không lần
        lữa suy nghĩ tới lui và xa rời khỏi các nguyên tắc của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi họ cảm thấy tích cực, họ sẽ thể hiện nguồn năng lượng bất tận. Sự
        phát tiết nguồn năng lượng này củng cố khả năng lãnh đạo tinh tế của họ.
        Họ nên cân nhắc điều chỉnh nguồn năng lượng tích cực của họ vào những
        con đường sáng tạo như nghệ thuật hay giải trí.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có nhiều kỳ vọng giành cho đồng nghiệp và cảm thấy thất vọng nếu
        không đáp ứng được tiêu chuẩn cao của họ. Họ phản ứng một cách bản năng
        đối với thất bại bằng cách phát ngôn thẳng thừng và phân tích chỉ trích
        thái quá đối với hành động của người khác. Sự thất bại của người khác
        không cản bước Quý Dậu khỏi mục tiêu của họ. Thay vào đó, họ càng quyết
        tâm nhắm đến mục tiêu thành công. Họ cần phải cẩn trọng tránh để cho mối
        quan tâm của bản thân lấn át. Nếu không họ sẽ cảm thấy chịu đựng không
        nổi và trở nên cáu kỉnh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù đôi khi suy nghĩ đơn độc, họ có khả năng thay đổi suy nghĩ nhanh
        chóng. Khi điều này xảy ra, họ trở nên cáu gắt và dễ trì hoãn cũng như
        ngần ngại trong mọi việc. Điều này xảy ra khi lá số Bát Tự chứa qua
        nhiều hành Thuỷ. Dù trong trường hợp nào thì người Quý Dậu cũng trở nên
        suy nghĩ quá nhiều và thích sống một cuộc sống chậm chạp và thư giãn mà
        người khác cho là lười nhác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thông thường, người Quý Dậu nên điều chỉnh sự dữ dội của họ nếu không
        muốn bị dán nhãn là kẻ độc tài.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Công Việc:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Dậu thường sẽ dùng quan điểm nồng nhiệt và suy nghĩ chiến lược
        của mình để đảm bảo thành công của bất kỳ dự án nào. Họ có xu hướng phát
        hiện những cơ hội ẩn giấu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Dậu giỏi trong việc khiến cho người khác thể hiện tốt nhất khả
        năng của họ. Khi tìm ra được động lực đằng sau hành động cuả người khác,
        họ sẽ khuyến khích người khác làm hết khả năng của mình. Bởi vì điều
        này, họ rất phù hợp với vị trí huấn luyện. Họ cũng trở thành những nhà
        tư vấn tài chính thành công, nhà thương lượng hay trọng tài tốt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ sở hữu sự cân bằng giữa lạc quan và tính thực dụng cộng với bản tính
        nhân đạo khiến họ dễ được người khác yêu thích. Bất kể con đường sự
        nghiệp nào họ lựa chọn, họ dễ đạt được lợi ích nếu biết tận dung tất cả
        những kỹ năng xuất sắc trên.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Trong Công Việc Của Quý Dậu:</b> thông thái, giỏi
        giao tiếp, thực tế, ngoại giao, sáng tạo, chiến lược, quyết tâm, nói
        thẳng thắn, cảm xúc, chịu đựng, chỉ trích người khác, cảm xúc mãnh liệt,
        sống chậm.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Quan Hệ - Gia Đình:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ là những lãnh mạn bẩm sinh, họ chung thuỷ một vợ một chồng và gắn bó
        chân thành với người bạn đời. Mặc dù bẩm sinh họ thuộc tuýp có thể hướng
        ngoại nhưng người Quý Dậu có nhu cầu mãnh liệt được nhận sự yêu thương.
        Họ sẵn lòng yêu thương ngược lại, nhưng chỉ dưới điều kiện của họ. Họ
        chỉ tự do bày tỏ tình cảm của mình khi mọi hoàn cảnh đều phù hợp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Dậu sở hữu cảm giác nhạy cảm cao và bản tính trực giác. Họ
        thường có sự cân bằng cảm xúc, nhưng thỉnh thoảng cũng dễ bị trầm cảm.
        Để hoá giải điều này, họ nên lắng nghe tiếng nói và trực giác bên trong
        của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Dậu thân thiện, duyên dáng và hứng khởi. Bởi vì điều này, họ
        dễ thu hút bạn bè và người quen.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có xu hướng chịu cảm xúc bất ổn có thể khiến họ gây ra những quyết
        định hấp tấp. Những quyết định này thường khiến cho họ gặp khó khăn
        trong việc duy trì mối quan hệ tình cảm lâu dài. Họ thường gặp cảm giác
        bất mãn và có nhu cầu thường xuyên thay đổi, rối trí về điều họ thật sự
        cần.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người sinh ngày Quý Dậu thường cảm thấy khó gắn bó với một mối
        quan hệ bởi vì họ nghĩ rằng nó sẽ làm giới hạn hành động của họ. Bất kỳ
        mối quan hệ dài lâu nào phải đi kèm với ai đó mà có thể khiến cho họ
        hứng thú bằng cách cung cấp cho họ những ý tưởng kích thích.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có thể chọn bạn bè và người bạn đời nếu họ dành thời gian, kiềm chế
        sự hấp tấp của mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù người Quý Dậu có thể tìm đượ tình yêu và tình bạn dài lâu, không
        có vẻ gì là mối quan hệ của họ sẽ giống như tiểu thuyết. Hôn nhân của họ
        sẽ trung bình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nam giới sinh ngày Quý Dậu bị cuốn hút bởi người nữ thể hiện vẻ thông
        minh và dí dỏm trong khi nữ giới sinh ngày Quý Dậu bị cuốn hút bởi nam
        giới hiểu biết và thông minh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Dậu có xu hướng dễ tăng cân, tuy nhiên người bạn đời của họ có
        thể nghĩ rằng sự thông minh của họ sẽ bù đắp cho ngoại hình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc cho sự sang quý và cảm gíac công bằng, người Quý Dậu có thể có một
        mối quan hệ với gia đình không tốt. Họ có thể bắt đầu tốt nhưng khi thời
        gian trôi đi một khoản ngắn thì họ có thể cảm thấy bản thân xa lạ với
        những người mình yêu quý nhất. Điều này có thể dẫn đến họ bị từ chối
        thừa kế gia tài, nhưng may mắn thay là điều này không làm ảnh hưởng đến
        tình hình tài chính của họ. Họ có khả năng sử dụng sự chăm chỉ và tiết
        kiệm của họ để kiếm tiền mà họ cần.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Những Đặc Tính Trong Tình Yêu - Gia Đình:</b> lãng mạn, bị hấp dẫn
        bởi sự thông minh, chung thuỷ, nhạy cảm cao, đam mê, hấp tấp, đa cảm.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Dậu có thể tỏ ra là thích suy nghĩ âm mưu vì Quý Thuỷ toạ trên
        Thiên Ấn còn gọi là Kiêu trong lá số Bát Tự. Mặc cho điều này, Quý Dậu
        là người chân thành và chăm chỉ. Sự ảnh hưởng của Thiên Ấn hấp dẫn địa
        chi Thìn biểu thị người này sẽ trưởng thành thông qua nghịch cảnh.
        Nghịch cảnh giúp xây dựng nên tính cách của Quý Dậu. Nếu không gặp nhiều
        nghịch cảnh và thử thách, người Quý Dậu đa phần sẽ vô lo và ngây thơ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Dậu có bản tính bình tĩnh. Đôi khi họ tỏ ra quá bình thản đến
        mức trông có vẻ là người lạnh lùng hay không cảm xúc. Điều này là bất
        công cho người Quý Dậu vì họ rất quan tâm đến việc tạo động lực cho
        người khác. Sự kết hợp giữa bản tính của họ với cách làm việc thẳng thắn
        và cá tính cuốn hút sẽ khiến cho họ trở thành lãnh đạo hiệu quả.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những cát tinh trong lá số Bát Tự sẽ khiến cho người Quý Dậu trở nên
        linh hoạt, sáng tạo và trực giác. Khi Dậu thu hút Tỵ trong lá số Bát Tự,
        người Quý 87 Dậu có thể được thừa nhận về khả năng trực giác. Người Quý
        Dậu là người suy nghĩ phân tích với khả năng giải quyết rắc rối. Họ có
        khả năng sử dụng kỹ năng và sự hỗ trợ của những người gần gũi với họ để
        đạt mục tiêu và trở nên thành công.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi họ ở đại vận xấu, họ có thể trở nên bi quan và chỉ trích người khác
        cũng như điều này sẽ dẫn họ đến mức nghi ngờ người khác. Nếu họ gặp hung
        tinh trong lá số, họ có thể lười biếng và yêu thích vật chất và sự kết
        hợp này có thể dẫn đến họ chọn cách kiếm tiền thông qua những cách ít
        đạo đức. Họ trở nên tham lam và tỏ vẻ tự cao để che giấu sự thiếu tự tin
        bên trong.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Quý Dậu thường được xem như dòng suối chảy ra từ đá
        khoáng. Họ có thể sinh ra trong gia đình giàu có hay khá giả, người Quý
        Dậu thường tinh tế, lịch sự và có giáo dục tốt, đặc biệt nếu trụ này gặp
        thêm ngũ hành Mộc và Hoả trong lá số.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sự hiện diện của hành Mộc và Hoả trong lá số biểu thị thành công về tài
        chính cho những người này. Thành công của họ được đảm bảo chắc chắn và
        nếu gặp thêm Bính hay Mộc trong lá số. Nếu thiếu những hành trên biểu
        thị họ sẽ phải làm việc vất vả để chinh phục mục tiêu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sự ảnh hưởng của sao xấu có thể biểu thị một đời sống bình dị cho người
        Quý Dậu. Lá số Bát Tự này biểu thị Quý Dậu nên tập trung vào mối quan
        hệ. Họ sẽ hưởng lợi từ mối quan hệ với đối tác ấm áp, yêu thương, Bính
        Hoả hay Tỵ có thể hoàn thiện tính cách của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu Mão và Dậu xung trong lá số Quý Dậu, biểu thị cảm giác thiếu an toàn
        sẽ làm hỏng cơ hội thành công của họ. Nếu có thêm sao Quý Nhân xuất hiện
        trong lá số thì biểu thị sẽ có người cố vấn hoặc bạn bè tốt giúp đỡ và
        hỗ trợ Quý Dậu khi họ cảm thấy dễ bị tổn thương.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu Quý Dậu gặp Tý trong lá số thì biểu thị người này sẽ chịu nhiều cảm
        giác bất ổn bên trong đặc biệt là liên quan đến quan hệ tình cảm. Họ cần
        phải học cách tin tưởng người bạn đời. Sự nghi ngờ có thể gây nên cảm
        xúc bất ổn, dẫn 88 đến hôn nhân tan vỡ. Họ nên học cách kết nối năng
        lượng để học hỏi điều mới, khám phá thế giới và dạy lại cho thế hệ trẻ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu Quý Dậu sinh trong tháng mùa Xuân hay Hè thì họ dễ trở thành những
        doanh nhân bẩm sinh người sẵn lòng gây dựng sự nghiệp kinh doanh của họ.
        Người sinh vào mùa Đông hay Thu thì dễ có nhiều con cháu. Người Quý Dậu
        sinh vào tháng Tuất thì ít may mắn và có thể phải vất vả trong đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Dậu sinh vào ban ngày thì sẽ chăm chỉ và thành công. Người Quý
        Dậu sinh vào ban đêm sẽ ít thành công về tài chính.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Quý Dậu tựa như một giọt nước mưa và tiến trình khám phá những gì quý
        giá ẩn chứa bên trong. Quý là hành mềm mại nhất trong tất cả các hành và
        còn mang vẻ huyền bí và nuôi dưỡng. Cũng như sương mù và giọt sương vào
        buổi sáng, Quý DẬu có khả năng nuôi dưỡng với sự quan tâm dịu dàng. Mềm
        mại và che chở, tất cả mọi sự lo lắng và đề phòng của bạn sẽ tan chảy
        khi gặp người Quý Dậu. Khả năng thay đổi hình dạng và nhạy cảm đối với
        không gian xung quanh mình cho họ lợi thế về nhận thức. Họ có thể ở đây
        nhưng không thật sự ở đây, cũng như mây trôi, bạn khó lòng nhận thấy họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Toạ Dậu chứa Tân Thiên Ấn biểu thị họ là những người học hỏi phi chính
        thống. Họ học hỏi bằng cách thử và sai. Họ thử tất cả những phương pháp
        kỳ và không được chấp thuận để tìm ra sự giác ngộ. Thiên Ấn biểu thị cho
        sức khỏe và sức sống. Điều này cho thái độ không sợ hãi vì họ luôn được
        nâng đỡ bởi bàn tay vô hình hỗ trợ trong mọi tình huống bất lợi. Tân
        cũng giúp cho họ có sự hoàn mỹ và đầu óc chú trọng chi tiết, kèm với khả
        năng học hỏi khiến họ trở nên quý giá và hiếm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Dậu kết hợp với Thìn hấp thu Mậu Chính Quan, Quý Tỷ Kiên và Ất Thực
        Thần. Quan Tinh hiện diện và hợp với người nữ Quý Dậu. Với sao Tỷ Kiên
        biểu thị họ may mắn có sự tự tin và hỗ trợ từ bạn bè. Sự hiện diện của
        sao Thực Thần biểu thị sự sáng tạo và thái độ luôn hướng đến tương lai,
        điều này cũng giúp hỗ trợ cho nhu cầu thích sự hoàn hảo của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Dậu xung với Mão xua đuổi Ất Thực Thần. Họ thiếu khả năng thật sự hưởng
        thụ một mình. Họ không cho mình một phút giây ngơi nghỉ và dễ mâu thuẫn
        với bản thân, mặt khác họ cũng muốn tỏ ra hoàn hảo nhưng một mặt khác
        lại bỏ mặc đến đâu hay đến đó.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhìn chung, họ là một tâm hồn nhạy cảm mong manh cần có thời gian để
        hoàn thiện. Họ có thể đôi khi giống như không có định hướng nhưng ẩn
        giấu bên trong là một kho báu quý giá đang chờ đợi hình thành. Toàn bộ
        trụ này có vẻ như không thu hút hay xua đuổi tài lộc, do đó tài lộc sẽ
        không giúp chinh phục họ. Để chinh phục trái tim họ, bạn cần dùng thái
        độ của bạn bè. Hãy chinh phục lòng tin và sự ngưỡng mộ của họ, họ sẵn
        lòng hết mình vì bạn.
      </p>
    </div>
  );
};

export const QuyMui = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Quý Mùi thích được người khác quan tâm chăm sóc, rộng
        lượng, giỏi giao tiếp, thông minh và thông thái, nhiều tài lẻ, có thể hi
        sinh lợi ích bản thân để cho những điều tốt đẹp lớn hơn và giỏi văn
        chương. Giỏi kỹ năng giao tiếp với người khác giới nhưng dễ gặp vấn đề
        trong hôn nhân và khó gìn giữ hôn nhân. Nhìn chung, cuộc sống không
        thiếu thốn vật chất, nhưng cũng không quá giàu có.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Một nơi thường bị hiểu nhầm là nguy hiểm, đen tối và đáng sợ. Đầm lầy
        chứa đựng nhiều bất ngờ trong cuộc sống ẩn dưới lớp bùn và nước đọng.
        Nhiều người không biết làm cách nào để làm khô đầm lầy và để có được
        thêm đất tốt để gieo cấy. Quý Mùi là một trong số các trụ ngày chứa đựng
        nhiều kho tàng bên trong. Âm Thuỷ toạ trên Âm Thổ khiến người này trở
        nên khá nhiều cảm xúc.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Quý Mùi có thể hình dung như là nước bốc hơi, hơi nước. Điều này biểu
        thị trong lá số Bát Tự một người liên tục dịch chuyển, tìm kiếm những cơ
        hội mới trong đời. Nó cũng biểu thị cảm giác trong tâm của họ không ngơi
        nghỉ. Quý Mùi đại diện cho tâm hồn, năng lượng và tinh thần.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Mùi rất tử tế, thông minh, tâm linh và độc lập. Thường được
        gắn với mác là “thực tế”, họ được biết đến vì khả năng xử lý mọi việc
        theo lý trí. Họ thường thể hiện ra vẻ ngoài tự tin và sáng tạo đồng thời
        có óc phân tích và thực tế. Ngoài ra, người thuộc trụ ngày Quý Mùi có 2
        mục tiêu trong đời. Trong khi họ cần sự ổn định, họ cũng cảm thấy bản
        thân mình liên tục tìm kiếm những phương thức để chế ngự sự bất an trong
        tâm hồn. Để chế ngự được sự bất an trong lòng, họ thường xuyên tìm kiếm
        những cơ hội mới để tìm ra được bản ngã thật sự của mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Mùi thật sự có tài. Họ sẽ có tương lai tài chính khá thuận
        lợi. Mức độ kiên trì và tập trung giúp cho họ có thể may mắn về tài
        chính. Do đó, họ nên tận dụng tất cả mọi cơ hội bắt gặp được.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Được biết đến như là người cầu toàn, họ rất tự hào về công việc của
        mình. Sự thành công mà họ cảm nhận trong cuộc sống đến từ thực tế là họ
        có khả năng tự chế ngự bản thân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có khả năng tạo ra và bảo trì một hệ thống làm việc hiệu quả. Những
        kỹ năng này giúp họ phát triển năng lực kinh doanh, tổ chức và kỹ thuật.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Mùi giỏi về trực giác và tâm linh và nếu họ đạt sự giác ngộ
        cao họ có thể biết về những kiếp trước của mình. Họ có nhiều bài học cần
        phải học trong đời, bao gồm cả việc tin vào trực giác và thấu hiểu bên
        trong mình. Sức mạnh nội tâm cua họ thật sự mạnh mẽ hơn họ nghĩ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người có ngày sinh này sẽ cho thấy dấu hiệu của sự mất kiên nhẫn
        thông qua việc thể hiện xu hướng nói trước khi nghĩ. Phát ngôn thẳng
        thắn của họ sẽ không được trân trọng hay thấu hiểu bởi mọi người. Họ nên
        kiềm chế không trở nên quá mất kiên nhẫn với người hác và cố gắng kiềm
        chế bản tính cứng đầu của mình. Vì những tính cách cố hữu này, người Quý
        Mùi có thể phải tập tính kiên nhẫn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tất cả mọi người đều không đánh giá cao sự nhạy cảm và bản tính quan tâm
        của họ. Họ thường nhanh chóng ngỏ lời giúp đỡ tuy nhiên lại phát hiện ra
        không phải tất cả mọi người đều trân trọng nghĩa cử tốt bụng này. Nhiều
        người còn hiểu sai rằng hành động tử tế của họ và muốn lợi dụng lòng
        tốt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi xây dựng được sức mạnh nội tâm, tài năng của họ sẽ mạnh mẽ hơn những
        gì mà bạn bè hay gia đình họ nhận ra. Tiềm năng của họ sẽ chỉ thành hiện
        thực khi nào họ tìm ra cách để kết nối với nó.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Với sự hiện diện của hugn tinh, họ có thể trở nên bất mãn trong đời sống
        và bắt đầu tìm cách để lấp đầy khoảng trống. Tình huống này khiến cho
        người Quý Mùi tìm đến sự trốn tránh. Bởi vì điều này, họ có thể rơi vào
        trầm cảm và phản ứng thái quá. Họ có xu hướng dễ bị bệnh kinh niên, cũng
        như người Quý Sửu. Thường những vấn đề sức khoẻ của họ xuất hiện khi về
        già và xoay quanh bao tử, da và tế bào.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người ngày sinh này nên học cách tiêu tiền khôn ngoan. Sự giàu có
        của họ không ổn định và họ gặp khó khăn khi tiết kiệm tiền. Họ có xu
        hướng tiêu tiền nhanh chóng ngay khi họ vừa kiếm được và mặc cho thói
        quen hoang phí, họ vẫn có nhu cầu an ninh. Khi họ phải vật lộn hoặc
        thiếu tiền mặt, họ có xu hướng sầu muộn. Họ nhanh chóng phát hiện ra
        rằng những tình huống này chỉ tồn tại trong một khoảng thời gian ngắn.
        Họ có thể nhanh chóng hồi phục lại và cảm nhận quả ngọt tài chính.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ ít khi nhận được giúp đỡ từ gia đình và do đó, quen thuộc với việc
        dựa vào chính bản thân mình. Do thói quen chi tiêu của họ và khi thiếu
        sự giúp đỡ, họ có thể phải tự vật vã một mình. Tất cả những vất vả trên
        thay đổi khi họ đến độ tuổi trung niên khi có thể cảm nhận được may mắn.
        Không chỉ họ sẽ đạt được thịnh vượng, họ cũng sẽ được thảnh thơi đầu óc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Của Người Kỷ Mùi:</b> thông minh, tử tế, độc lập,
        trực giác, quan tâm, tâm linh.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người sinh ngày Quý Mùi không bao giờ phải lo lắng về tương lai
        nghề nghiệp. Cơ hội liên tục tìm đến gõ cửa họ, rất thường xuyên với cả
        những lời mời đi xa hoặc thay đổi chổ ở định cư.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trong kinh doanh, người Quý Mùi sở hữu sự kết hợp giữa tính thực tế và
        sự tưởng tượng. Họ sẽ cẩn trọng trong việc lên kế hoạch để có thể biến
        giấc mơ thành hiện thực.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi họ tiến vào thế giới kinh doanh, họ sẽ sớm nhận ra rằng họ đặc biệt
        thích hợp cho việc quản lý tiền của người khác. Họ thường chọn sự nghiệp
        trong ngành ngân hàng hay giao dịch ngoại hối cũng như luật pháp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù được nhiều người kính trọng, ít ai biết được họ khá ích kỷ và áp
        đặt. Họ cũng hồi hộp và căng thẳng khi phải làm việc hay kết nối với
        người khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bất kể họ chọn bước vào sự nghiệp nào, họ cũng sẽ có được thành công gần
        như chắc chắn. Bản tính thích sự hoàn mỹ, cộng với nhiều tài năng và đa
        nhiệm, khiến cho họ dễ xuất sắc trong mọi thứ. Công thức để thành công
        được khuếch đại bằng việc sử dụng trực giác mạnh mẽ của mình. Cùng với
        điều này, họ thường làm việc chăm chỉ và có khả năng lên kế hoạch cho
        những bước tiếp theo trong đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bản tính thích quan tâm người khác khiến cho họ dễ thành công trong các
        ngành như kinh doanh, giao tiếp công chúng hay tiếp thị. Nếu họ muốn
        dùng kỹ năng giao tiếp lái theo một hướng khác, họ có thể cân nhắc ngành
        giáo dục, tư vấn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Mùi bẩm sinh khéo tay. Điều này khiến cho họ có thể xuất sắc
        trong ngành mộc, thủ công hay kể cả ẩm thực. Họ có con mắt nhà nghề
        thích hợp cho thiết kế và hình ảnh cũng như có thể chọn nghề trong lĩnh
        vực nghệ thuật, âm nhạc và phim ảnh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Trong Sự Nghiệp - Công Việc:</b> sáng tạo, óc tổ
        chức, thực tế, quyết tâm, kiểm soát, thiếu kiên nhẫn.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Quan Hệ - Gia Đình:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Mùi rất nhạy cảm, lãng mạn, thích tưởng tượng và lý tưởng hoá.
        Họ có nhiều tình yêu để cho đi đúng người và sẽ sẵn lòng tìm kiếm một
        người bạn đời tận tâm. Do đó, họ đặt kỳ vọng cao nơi bạn đời. Nếu họ
        không tìm được người bạn đời phù hợp, cảm xúc mãnh liệt có thể khiến tâm
        trạng họ thất thường.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Mùi là những người bạn rất quan tâm và thích giúp đỡ. Họ ủng
        hộ và nhanh chóng đề nghị giúp đỡ, nếu họ phát hiện ra rằng bạn bè đang
        cần họ. Nói cách khác, họ thường rộng lượng khi giúp đỡ những mảnh đời
        mà họ quan tâm. Tuy nhiên, họ có thể phát hiện ra rằng không phải tất cả
        mọi người họ yêu thương đều sẽ trân trọng nghĩa cử tốt bụng của họ. Họ
        cũng sẽ dễ bị lợi dụng bởi nhiều người khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Mùi là những người bạn đời rất tận tâm và thường cũng có vợ
        hay chồng hết mình. Nhiều người Quý Mùi khó có con. Phụ nữ ngày sinh này
        thường tiết hạnh, trọng danh dự và rất tài năng đảm đang việc nhà.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Của Quý Mùi Trong Tình Cảm - Gia Đạo:</b> không ngơi
        nghỉ, nhạy cảm, lãng mạn, tận tâm.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Quý tọa trên tàng can Thực Thần sinh ra Tài Tinh Hoả. Tài này lần lượt
        lại sinh cho Thất Sát Tinh. Quý Mùi được xem như toạ trên tàng Thiên Tài
        và Thất Sát biểu thị người này sẽ có hình thể và trí tuệ đẹp đẽ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Mùi rất nghệ sĩ và sáng tạo. Họ sống lý tưởng và có xu hướng
        theo đuổi tâm linh. Người Quý Mùi rất kín kẽ nhưng cũng ẩn giấu bộ óc
        năng động bên dưới và họ thông minh, quyết tâm và tham vọng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tuy nhiên người Quý Mùi cũng rất tình cảm và nhạy cảm. Họ tập trung chú
        ý nhiều đến quan hệ hôn nhân. Mặc dù họ có thể không chỉ kết hôn 1 lần
        duy nhất trong đời, họ sẽ luôn cống hiến trọn vẹn cho sự chung thuỷ mà
        họ cam kết là một phần của nó. Cảm xúc sẽ dẫn dắt họ trở nên quá lo lắng
        và họ cần phải học cách tách rời khỏi tình cảm để có thể trở nên kiên
        nhẫn hơn, thấu hiểu và bao dung. Họ giỏi thể hiện ấn tượng rằng họ mong
        đợi mọi thứ sẽ đi theo đúng cách họ muốn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        May mắn nhất nếu đi kèm với cát tinh, họ có khả năng tìm được cân bằng
        hạnh ph1uc giữa mối quan hệ và mục tiêu sự nghiệp. Khi họ có thể tìm
        được một mục tiêu đốt cháy được ngọn lửa bên trong họ, họ sẽ rất tập
        trung và làm việc hừng hực với nhiệt tình và năng lượng cao độ. Nếu
        không tìm được động lực, họ có thể dàn trải năng lưỡng quá rộng hay tệ
        hơn là không có mục tiêu nào.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Mùi hầu như khá thông minh và thông thái nếu lá số Bát Tự của
        họ không bị nhiễm bẩn bởi những hành tiêu cực. Nó biểu thị tương lai lý
        tưởng cho trụ ngày này khi Thiên Can xuất hiện Kim và Thuỷ trong lá số
        vì biểu thị người Quý Mùi sẽ thịnh vượng, khoẻ mạnh và thành công. Khi
        Hợi và Mão gặp nhiều Hoả và Thổ trong lá số thì có thể biểu thị rằng
        người này phải vất vả mới hiện thực hoá được giấc mơ của mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đàn ông Quý Mùi có thể có được hôn nhân tốt khi có thêm Hoả và Thuỷ hiện
        diện trong lá số tuy nhiên điều này không đúng với phụ nữ Quý Mùi. Phụ
        nữ ngày sinh Quý Mùi có thể đối mặt khó khăn trong hôn nhân. Sẽ có lợi
        cho người nữ Quý Mùi nếu thấy có Mậu Thổ xuất hiện để hợp mất Quý. Với
        sự xuất hiện của Mậu Thổ và Ất Mộc, lá số của người nữ sẽ được củng cố
        và đảm bảo hạnh phúc và ổn định trong hôn nhân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngoài ra Thìn có thể đóng vai trò như màng lọc hành bất lợi. Thìn lọc
        Thuỷ và bỏ đi những gì không tinh khiết. Nếu sinh mổ, phụ nữ Quý Mùi sẽ
        khôn ngoan nếu chọn giờ Thìn và tránh dùng giờ Tuất vì nó xung với Thìn.
        Nếu Thìn xuất hiện với Bính ở Can thì sẽ xảy ra hợp hoá.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu Quý không chạm đất Thổ thì người này không cần phải lo lắng về việc
        nhiễm bẩn. Tuy nhiên nếu Quý không tiếp xúc với Thổ thì sẽ có bùn. Người
        này sẽ thường xuyên bị những bệnh kinh niên, nhiều bệnh chỉ nhỏ nhặt
        nhưng một số bệnh có thể gây chết người.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Mùi phải có Hợi trong lá số để có thể hợp mất Mùi tuy nhiên
        cần lưu ý rằng Mậu Thổ hay Kim có thể bị thay thế. Mùi không cần Ấnt
        Tinh nhưng Mùi được xem như là tâm linh và độc lập. Sẽ tốt hơn nếu lá số
        gặp Kim, Thuỷ và Mộc trong đại vận vì điều này biểu thị lá số vượng và
        biểu thị người này sẽ bình an, tâm linh và viên mãn. Bất lợi nếu trụ
        ngày này gặp Hoả Thổ Khố trong lá số biểu thị tinh thần bị chèn ép và
        tương lai mù mịt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh trong tháng mùa Xuân và Hạ sẽ gặp may mắn và sống thọ. Người
        sinh vào tháng mùa Đông và Thu sẽ hưởng phước về tài lộc. Sinh vào tháng
        Tý thì không tốt vì người này sẽ phải đấu tranh để tìm đường đi đến một
        tương lai tài chính tốt hơn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trong hôn nhân, nếu nữ giới Quý Mùi có Thương Quan, người này sẽ rất
        buồn trong tình cảm vì chồng dữ dằn hoặc bạo lực và điều này khả năng
        cao nếu sao này hợp với Thất Sát và chuyển hoá Quan Tinh (Gíap Kỷ biến
        Thổ). Mặt khác, khi Thiên Tài hợp với Kiếp Tài trong lá số, điều này sẽ
        chuyển hoá Thực Thần vì nó nhập Mộ để sinh ra tài lộc. Điều này biểu thị
        rằng phụ nữ Quý Mùi sẽ được giúp đỡ và ủng hộ bởi anh hoặc chị là người
        sẽ bảo vệ họ trước người chồng vũ phu. Điều này xảy ra là vì Đinh và
        Nhâm hoá Mộc để khắc Thổ, và khi điều này xảy ra thì Quan Tinh bị khắc
        chế.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mùi đem đến 3 hành năng lượng mang tên Kỷ Thổ Thất Sát, Đinh Hoả Thiên
        Tài và Ất Mộc Thực Thần. Khi toạ trên Thất Sát là chính khí, người ta có
        thể cho rằng hành thuỷ mềm mỏng này sẽ không bạo liệt. Cũng giống như
        đầm lầy, họ là những người rất nguy hiểm nếu bị ép sai cách. Nước và cát
        thường tạo thành cát lún, chỉ một bước sai là bạn sẽ không thể bước
        thoát ra. Người Quý Mùi sẽ thường dám nghĩ dám làm và thích thách thức,
        cũng như đám mây bão mang theo vòi rồng, nó sẵn lòng rửa sạch tất cả.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thiên Tài giúp họ nhìn thấy trước được cơ hội. Họ có sự kiên nhẫn để chờ
        đợi cơ hội phù hợp. Đây là điều khiến họ trở thành người chăm sóc cho
        trẻ con tốt, họ nhìn thấy tiềm năng của đứa bé trong tương lai và đặt
        niềm tin vào tương lai của bé. Những hành quan trọng giúp tiềm năng có
        thể thành hiện thực là Thực Thần. Thực Thần giúp kiểm soát sự nổi loạn
        của Thất Sát, họ có khả năng kiềm chế những kịch bản dữ dội trong đầu,
        giúp cân nhắc hậu quả của hành động.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tình cảm không tốt cho cả nam và nữ có Thất Sát bạn trai và Thiên Tài
        bạn gái. Quý Thuỷ cảm thấy bất an với ý tưởng của sự ổn định. Mùi tạo
        thành hợp với Ngọ khiến cuốn hút thêm Thiên Tài Đinh Hoả và Thất Sát Kỷ
        Thổ. Điều này tạo nên hoàn cảnh muốn được tự do. Mặt khác, điều này
        khiến họ trở thành bạn gái tốt bởi vì không tìm kiếm địa vị bền vững
        khiến họ trở nên hấp dẫn hơn đối với người đàn ông.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mùi xung với Sửu xua đuổi Thất Sát Kỷ Thổ, Quý Thuỷ Tỷ Kiên và Thiên Ấn
        Tân Kim. Điều này khiến cho nữ Quý Mùi thêm 1 chút mâu thuẫn vì họ muốn
        cũng tránh gắn bó với bạn trai cả đời. Bằng cách từ chối Tỷ Kiếp và Ấn
        Tinh, điều này cũgn chỉ ra rằng họ không muốn nhận sự giúp đỡ và ủng hộ
        và muốn tự mình đi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhìn chung, người Quý Mùi là người thích khoái lạc và sẽ giữ những mặt
        nổi loạn của mình bên trong. Họ quan tâm vừa đủ để chịu đựng gánh nặng
        và đảm bảo rằng mọi người xung quanh không bị tổn thương. Điều này ngược
        lại sẽ làm họ tổn thương nếu họ không tìm được một cách để giải phóng
        cảm xúc và yêu đương. Thường thì bạn sẽ thấy họ là nhà văn giỏi (Thực
        Thần) với trí tưởng tượng nhìn xa (Thiên Tài) để nói về những thứ thực
        tế trong cuộc sống (Thất Sát).
      </p>
    </div>
  );
};

export const QuyTy = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Quý Tỵ rất may mắn. Nếu là nam giới, sẽ rất thông minh.
        Nếu là nữ, cô ấy sẽ xinh đẹp, là người vợ tiết hạnh nhưng bị gia đình
        chồng không ưa thích, sau 40 tuổi sẽ đem lại nhiều điều xui xẻo. Dù là
        nam hay nữ, người này được nhiều người biết đến và quý mến, có kỹ năng
        sinh tồn giỏi, suy nghĩ nhanh, phân tích nhanh, có khả năng nắm bắt cơ
        hội. Nhìn chung, vận mệnh giàu có nhưng dễ bị vấn đề sức khoẻ liên quan
        đến rượu và tình dục.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Quý Tỵ cũng như việc đun nước nóng và để cho hơi nước thúc đẩy nước nóng
        để làm chạy động cơ, làm đun sôi cà phê. Quý Tỵ là sử dụng hơi nước.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Quý là những giọt nước hay sương ban mai. Những giọt nước này thường
        không có hình dạng và không thể bị kiểm soát. Nếu không có định hướng
        tốt, họ dễ vỡ ra một cách dễ dàng, chỉ với một cơn gió nhẹ. Quý nổi
        tiếng là có đầu óc không thiên vị và tâm lý muốn làm hài lòng đám đông.
        Họ thường bỏ đi khỏi áp lực và cuối cùng chọn tình huống thư giãn hơn.
        Lúc mới gặp khó khăn, bạn sẽ thấy họ sợ hãi và quay trở về vòng an toàn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Quý Tỵ có thể xem như là hiện tượng khi mà cả Mặt Trời và Mặt Trăng còn
        xuất hiện cùng lúc trên bầu trời. Hiện tượng thiên văn hiếm gặp này biểu
        thị sự sang quý hiếm thấy mà bạn có thể thấy ở người Quý Tỵ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Tỵ được xem là ngày sinh Quý sang nhất trong các Quý và người
        sinh ngày này có phẩm giá cao. Người Quý Tỵ có những nét tính cách thông
        minh và trí tuệ cùng với sự hiểu biết mọi thứ mà họ làm. Họ có trí thông
        minh bẩm sinh cùng với cá tính năng động và cá tính có thể giúp họ có
        thể có được năng lượng cần thiết để đạt mục tiêu. Ngoài ra, họ cũng cần
        được đảm bảo cuộc sống tốt vì có thể sinh ra trong gia đình giàu có.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người có ngày sinh này thường thích các môn huyền học hay tâm
        linh. Lý tưởng của họ đi kèm với tương tác với người khác và thường dẫn
        họ đến việc làm việc với người thuộc đủ mọi loại thành phần trong xã
        hội. Họ có sự hứng thú đối với cuộc sống và luôn tràn đầy ý tưởng tuyệt
        vời. Vì lẽ đó, điều quan trọng là họ tiếp tục học hành và phát triển các
        sự quan tâm mới.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Tỵ thông thường sẽ hướng ngoại và không gặp khó khăn trong
        việc hoà trộn giữa sự nghiệp và giao tiếp xã hội. Bởi vì họ thích làm
        những công việc có liên quan đến mối quan hệ với người khác, đặc biệt là
        người siêng năng và kỷ luật. Ngoài ra, trí tưởng tượng và góc phán đoán
        cũng giúp họ gây ấn tượng với người khác và gợi cảm hứng cho những giao
        tiếp thân thiện.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Tỵ là những chiến lược gia xuất sắc và có khát khao cháy bỏng
        để tiến lên phia trước. Sự can đảm của họ thúc đẩy họ vượt lên trên
        những khó khăn chắn đường và bởi vì họ có trực giác và hiểu lý lẽ, họ có
        thể nhận ra nhiều cơ hội kinh doanh xuất sắc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bởi vì Tỵ được xem là sao chủ về năng động, người sinh ngày này sẽ có
        nhu cầu cao hơn đối với an toàn và ổn định về tài chính. Mặc dù họ sẽ
        làm việc vất vả để đạt được điều họ muốn, họ về căn bản là hơi lười và
        không muốn phải làm việc vất vả nếu họ không cần. Thương xuyên họ sẽ là
        kẻ thù tệ nhất của chính mình vì các hành động, thái độ của họ là trở
        lực lớn nhất cho thành công. Tuy nhiên, họ biết rõ rằng không có gì có
        thể đạt được mà không phải lao động vất vả. Do đó họ sẵn lòng vượt qua
        quán tính tự nhiên không thích hành động để đạt mục tiêu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ thường mang theo nỗi sợ không có đủ tiền. Nỗi sợ hãi này choáng ngợp
        họ ngay cả khi họ có nhiều tiền và thành công. Họ được khuyên là nên cẩn
        trọng khi kinh doanh lúc trẻ tuổi. Nếu họ có thể xây dựng và giữ doanh
        nghiệp phát triển thành công thì nó sẽ giúp họ đạt thịnh vượng. Một khi
        họ đến độ tuổi trung niên, họ có thể đối mặt nhiều trở ngại kể cả trong
        sự nghiệp hay gia đình. Khi họ lớn tuổi hơn, tài chính của họ sẽ được
        cải thiện bởi vì họ sẽ gặp nhiều vận may với tiền bạc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có xu hướng đầu tư vào những người khác và điều này đòi hỏi họ phải
        biết được người nào họ có thể hoặc không thể tin tưởng. Đầu tư sai người
        thì người Quý Tỵ sẽ mất tiền và tự tạo ra nhiều trở ngại không cần thiết
        đối với mục tiêu của mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Tỵ đôi khi có thể khá cứng đầu, có xu hướng dễ cãi nhau nếu họ
        không thể đạt được điều họ muốn. Họ cần phải học nghệ thuật thoả hiệp.
        Họ cũng được biết đến là có tâm trạng nóng nảy và nên họ cách bình tĩnh
        hơn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trong khi một số người cố gắng thành công để mong đạt được tài lộc nhiều
        hơn, điều này không thật sự là động lực của Quý Tỵ. Động lực của họ là
        nhận được nhiều sự công nhận từ người khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Những Đặc Tính Của Người Quý Tỵ:</b> thông minh, không siêng năng,
        tâm linh, can đảm, nhiệt tình, hướng ngoại.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khả năng bẩm sinh về lãnh đạo, quản trị và điều hành giúp họ có nhiều
        lựa chọn cho sự nghiệp. Những đặc tính này có thể giúp họ nắm giữ các vị
        trí có trách nhiệm và quyền hành như trong lĩnh vực tiếp thị trực tiếp
        hay bất động sản.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trong khi người Quý Tỵ điển hình sẽ thích làm việc cho bản thân, họ sẽ
        thường xuất sắc trong các lĩnh vực đòi hỏi sự hợp tác với người khác. Ở
        đây thì kỹ năng lãnh đạo giúp cho họ có thể khởi sự các dự án và phân
        quyền cho người khác. Họ rất thích điều này vì thích giao tiếp với nhiều
        người.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Công Việc:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có quan điểm độc đáo về thế giới, họ cũng sẽ thành công trong những
        công việc đòi hỏi sự sáng tạo. Họ có thể chọn nghề nghiệp trong ngành
        nghệ thuật, chữa trị, âm nhạc hay sáng tạo.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngoài ra, người Quý Tỵ có tài trong việc liên lạc và đối nhân xử thế với
        con người. Điều này khiến cho họ lý tưởng phù hợp với sự nghiệp trong
        quản lý truyền thông, quan hệ công chúng hay luật. Họ cũng có thể muốn
        sử dụng tài năng vào việc tốt như trở thành người đàm phán, môi giới, tư
        vấn hay bán hàng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ sở hữu lý tưởng cao. Những lý tưởng này là cần thiết nếu họ chọn nghề
        nghiệp bao gồm giúp đỡ người khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Những Đặc Tính Trong Công Việc - Sự Nghiệp:</b> là lãnh đạo bẩm sinh,
        nhiệt tình, nhận thức cao, suy nghĩ chiến lược, không quá vất vả, quá
        tin người, cứng đầu.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Quan Hệ - Gia Đình:{" "}
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Tỵ hướng ngoại và rộng rãi khi họ yêu. Để có thể thành công
        trong tình yêu, họ cần phải cân bằng giữa nhu cầu cá nhân và nhu cầu của
        vợ/ chồng. Ngoài ra, họ phải tránh chơi trò đấu trí với bạn đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Xét quan hệ, người Quý Tỵ thân thiện, hợp tác. Họ thích đời sống cá nhân
        năng động và thích ở chung với tập thể. Mặt xã hội của người Quý Tỵ tiết
        lộ nhu cầu ẩn trở thành một thành viên đội nhóm. Bởi vì nhu cầu kích
        thích trí óc, họ bị thu hút bởi những người thông minh và có vị trí
        quyền lực.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù những người này thích tự do và độc lập, họ có thể thể hiện một
        vài dấu hiệu ngại ngùng. Khi cần thiết, họ có thể quyết tâm và thẳng
        thắn. Họ chỉ cần một ít tư vấn để hình thành sự thẳng thắn trong quan hệ
        gần gũi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Điều quan trọng là người Quý Tỵ có thể đạt được sự bình an trong tâm hồn
        bằng cách thiết lập một mái ấm giúp che chở họ và những người thân yêu
        khỏi thế giới bên ngoài. Nhìn chung, người Quý Tỵ thích quan hệ tốt với
        vợ/chồng. Hôn nhân của họ thường sinh ra nhiều con, nhưng chỉ một người
        con thành công xuất sắc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Trong Tình Yêu - Hôn Nhân:</b> rộng lượng, hướng
        ngoại, hợp tác, thân thiện.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:{" "}
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Quý toạ Chính Quan, Chính Ấn và Chính Tài. 3 sao này sinh cho nhau và
        biểu thị người này sẽ mang hào quang quyền quý và có thể giàu có hoặc
        liêm khiết. Họ là người có sức khoẻ tốt và thành công lớn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Tỵ yêu thích sự vui vẻ và duyên dáng. Họ có cá tính lôi cuốn
        và những người xung quanh bị cuốn hút bởi sự thân thiện và hoà nhã của
        họ. Họ rất sẵn lòng giúp đỡ và hỗ trợ người khác. Người Quý Tỵ đánh gía
        cao sự hợp tác và họ có thể có nhiều mối quan hệ cả về xã hội và trong
        nghề nghiệp sẵn lòng giúp họ đạt mục tiêu. Người nữ Quý Tỵ có khả năng
        cưới chồng giàu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi họ gặp cát tinh, họ không thiếu nguồn cảm hứng. Sáng tạo cao, họ
        cũng duyên dáng và hấp dẫn và thể hiện khả năng lãnh đạo mạnh mẽ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu gặp hung tinh, tuy nhiên họ có thể trở nên lo lắng sợ hãi và không
        kiểm soát được cảm xúc. Họ cần phải nhận được sự công nhận và lời khen
        cho tài năng và năng lực của họ vì nếu thiếu những thứ này họ sẽ cảm
        thấy bất an. Điều này cũng có nghĩa là họ bắt đầu phụ thuộc nhiều vào
        người thân yêu và tỏ ra ghen tỵ muốn đạt được sự chú ý của họ. Họ cũng
        sợ thay đổi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Tỵ thường hi vọng muốn nhận được không chỉ lợi ích về tài
        chính mà còn là sự công nhận thành tích. Do đó bất lợi nếu trụ ngày này
        gặp nhiều Thuỷ và Mộc trong lá số Bát Tự, vì sẽ gây ra sự mất cân bằng
        và có thể làm giảm khả năng đạt mục tiêu. Nó cũng sẽ bất lợi nếu trụ này
        gặp thêm Hợi vì gây ra xung với Hoả. Hiệu ứng xấu tương tự xảy ra nếu
        trụ này cũng gặp Hình và Hại trong lá số.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhìn chung thì người Quý Tỵ tốt hơn Quý Hợi. Điều này bởi vì trong lá số
        Quý Tỵ thì có chứa Tài Quan Ấn. Đàn ông Quý Tỵ có thể chắc chắn rằng họ
        sẽ tiến bộ nhiều. Tuy nhiên sẽ ít may mắn nếu trụ này gặp Thất Sát trong
        lá số. Sự hoá của Quý Tỵ thành Thuỷ xảy ra thông qua Bính. Cả hai cục
        này đều mạnh và phải có Bính để ngưng tụ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngọ Mùi ở Không Vong đối với người Quý Tỵ ở tuần Giáp Thân. Khi Kiến Lộc
        (cũng là Trường Sinh của Tài Tinh) gặp Không Vong, điều này biểu thị hôn
        nhân thất bại hay hôn nhân thường xuyên cãi nhau. Nếu Tài Tinh và Dương
        Nhẫn gặp Không Vong trong lá số, nó có thể biểu thị cuộc sống cha của
        người này bất ổn và người này mong chờ thay đổi người yêu hoặc vợ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Tỵ sinh vào mùa Xuân và Hạ sẽ có nhiều may mắn và sống thọ.
        Người sinh vào mùa Thu và Đông sẽ cần làm việc nhiều hơn người khác để
        có thể thành công.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Quý toạ trên Tỵ mang theo nguồn năng lượng chính của Bính Hoả và 2 phụ
        khí của Mậu Thổ và Canh Kim. Toạ trên Tài Tinh Bính Hoả, điều này khiến
        cho họ trở thành người rất chăm chỉ. Họ thường nỗ lực rất nhiều đến mức
        họ sẵn lòng nhận lấy việc khi người khác bận rộn. Điều này thật sự bắt
        họ phải hành động khi có áp lực. Vì Chính Tài đại diện cho người vợ
        trong lá số nam giới, Quý Tỵ là một trong số những người chồng trách
        nhiệm nhất trong 60 Hoa Giáp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Phụ khí Canh Kim biến thành Chính Ấn. Ấn bao gồm cả quý nhân đến giúp đỡ
        và thường đem đến kiến thức quý giá. Quý Tỵ thông minh cùng với kiến
        thức thực tế vì Kim liên quan đến những gì hành động. Vì Mậu Thổ diễn
        dịch thành Chính Quan. Sao này sẽ cho họ sự kiểm soát luật lệ và trật
        tự, và đối với nữ giới đây cũng là sao chỉ người chồng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Chính Tài, Chính Quan và Chính Ấn là một phần của 3 sao quý là Tài, Quan
        và Ấn Cục giúp đem lại quý khí. Thường thì bao gồm cả đạo đức và sẽ
        không dính đến những gì đen tối. Thành công khá dễ dàng cho họ vì mọi
        người sẽ dễ co xu hướng áp đăt và giúp đỡ họ trong đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tỵ xung với Hợi mang theo năng lượng của Kiếp Tài Nhâm Thuỷ và Thương
        Quan Giáp Mộc. Cả hai đều là những sao khắc mối quan hệ tình cảm đối với
        nam và nữ là Kiếp Tài và Thương Quan. Do đó, người này sẽ thường xuyên
        phải tránh đối đầu và ít khi đi ngược lại tập thể. Họ rất tuân thủ luật
        pháp và tuân thủ theo tiêu chuẩn xã hội.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tỵ sẽ hợp với Thân mang theo năng lượng của Chính Ấn Canh Kim, Chính
        Quan Mậu Thổ và Kiếp Tài Nhâm Thuỷ. Ở đây bạn sẽ thấy người đàn ông sẽ
        bị nhiều rắc rối về tình cảm với sao Kiếp Tài nhưng sẽ thích được Thổ và
        Kim hỗ trợ và khắc thuỷ. Với sự hấp dẫn của Chính Quan thì người nữ sẽ
        được theo đuổi nhiều và tìm được bạn trai dễ dàng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Quý Tỵ có cách cục quý và sẵn lòng chịu áp lực. Với sự hướng dẫn phù
        hợp, họ thu nhập kiến thức cần thiết trong đời và hướng đến đích đến như
        động cơ hơi nước được nén chặt. Cũng như hơi nước, Quý Tỵ cần được hướng
        dẫn, nếu không được kiểm soát sẽ tan vào trong không khí và không bao
        giờ tìm lại được.
      </p>
    </div>
  );
};

export const QuyMao = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Quý Mão: người có tính cách ôn hoà, tinh tế, được lòng
        bạn bè và người lớn tuổi, có khả năng xuất sắc trong bất kỳ lĩnh vực
        nào, nhưng không phải là 1 chiến binh độc lập, thích kinh doanh, có tài
        năng văn hoá nghệ thuật, thích học hỏi, tiết kiệm nhưng lại sẵn sàng chi
        tiêu nhiều cho người khác giới. Có đời sống hôn nhân hạnh phúc và viên
        mãn. Với nữ giới, là người giỏi nội trợ, lạc quan, sống tích cực.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Cây sống khi chúng được chăm sống đúng cách. Quý Mão cũng như một nông
        trại nhỏ sẽ phát triển bất kỳ hạt giống nào được gieo xuống vào trong
        chậu. Với tình yêu ngọt ngào, họ có thể cảm nhận được sự phát triển và
        trưởng thành từ từ. Một số người sẽ có thể gieo hạt giống của họ ra cho
        môi trường xung quanh và từ đó họ sẽ trưởng thành trong khi những người
        khác lại dễ phát huỷ mọi thứ chỉ để cho một cái cây phát triển.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Quý là hạt sương sớm. Đó là hành đem lại sức sống và nuôi dưỡng. Họ có
        khả năng để sống sót và đeo bám cũng tựa như sương mù ngay cả khi mọi
        thứ xung quanh không chào đón họ. Bạn cũng có thể đánh đấm vào màn sương
        mù nhưng đó chỉ là tạm thời và màn sương sẽ nhanh chóng quay trở lại.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Quý Mão là hạt sương sớm đậu trên cành lá xanh hay nhuỵ hoa nở vào sáng
        sớm hoặc tối. Vì sương cần bề mặt để thành hình, nó biểu thị bản năng sở
        hữu mà người Quý Mão thường tìm kiếm trong cuộc đời. Điều này cũng biểu
        thị một cảm giác mạnh mẽ về sự gắn bó thông qua thế giới vật chất sở
        hữu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Điều bạn muốn thấy đó là khi bạn gặp một người Quý Mão thì không phải
        luôn luôn là một chân dung chính xác. Nhiều người Quý Mão có thể được mô
        tả như là bản tính hai mặt. Hành động bên ngoài của họ không phản ánh
        bản chất suy nghĩ hay cảm xúc bên trong thật sự.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bề ngoài xa rời, hời hợt với tri thức có thể biểu thị một khao khát mãnh
        liệt đối với kiến thức ở bên trong. Tương tự, mối quan tâm về vật chất
        và đôi khi bản tính ám ảnh về tài sản có thể che giấu sự quan tâm và
        tình yêu chân thật dành cho nhân loại.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Điều đặc biệt về tính cách của người Quý Mão là tình yêu và khao khát
        kiến thức. Họ tìm kiếm kiến thức từ mọi nguồn và sau đó tìm cách áp dụng
        nó vào thực tế. Ngoài ra, người Quý Mão được biết đến là những người tạo
        dựng ra các lý thuyết mới.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ ít khi buồn chán vì khi họ cố gắng đạt sự cân bằng thông qua việc sở
        hữu kiến thức với trí óc nhanh nhạy và cá tính mạnh mẽ. Họ có thể cần
        chú ý nhiều đến chi tiết của bất kỳ dự án nào vì họ có xu hướng làm việc
        quá nhanh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người khác sẽ cảm thấy tính cách của người Quý Mão khá rối rắm,
        nếu không muốn nói là mâu thuẫn. Đối với những ai nhìn vào hành động của
        họ, họ tỏ vẻ hời hợt, thậm chí lạnh lùng và xa cách. Điều không biểu lộ
        ra là trạng thái cảm xúc bên trong. Họ khao khát kiến thức liên tục và
        điều này không đồng nghĩa với việc họ thiếu cảm xúc. Ngược lại, nằm bên
        dưới bề mặt là trí óc họ luôn suy ngẫm về mọi thứ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Điểm yếu của họ là gắn quá chặt với thế giới vật chất, đến mức họ lo
        lắng về tiền quá mức cần thiết. Một lần nữa, nhiều người sẽ hiểu nhầm ý
        định của họ mà cho là họ tự cho mình là ngôi sao. Khi ai đó gặp họ lần
        đầu thì ấn tượng người Quý Mão gây cho họ là thái độ “nghĩ mình hơn
        người”.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sức mạnh nhất của người Quý Mão, có lẽ là bản tính bên trong kết nối
        mình với người khác. Tuy nhiên, ở trong khía cạnh này thì mặt họ thể
        hiện ra bên ngoài cho người khác cũng đối lập với động cơ bên trong.
        Điều có thể gây ra ngạc nhiên cho người khác là dù người Quý Mão thật sự
        quyết tâm và có thái độ luôn chú tâm đến tiền bạc là người cũng có tâm
        từ và rộng lượng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ít người nghĩ đến bên trong con người này là sự khao khát kiến thức của
        họ rất lớn và họ có động lực thành công lớn, họ vừa lý tưởng cao cũng
        như trí tưởng tượng năng động. Người Quý Mão thể hiện sự tưởng tượng của
        họ thông qua nhiều cách truyền thông, bao gồm cả âm nhạc và nghệ thuật
        cũng như tôn giáo và tâm linh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có sự kết hợp giữa ý tưởng sáng tạo và ý chí quyết tâm không ngừng.
        Tuy vậy, không ngạc nhiên nếu đôi khi thấy họ không có khả năng tiến tới
        phía trước để tạo nên sự tiến bộ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đôi lúc, họ cần được thúc đẩy theo đúng hướng để có thể thật sự khởi
        động.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đừng nhầm lẫn sự thân thiện với sự chấp nhận mù quáng con người. Đây
        cũng là một ví dụ khác của việc nhìn sâu hơn vào bản chất con người. Bản
        tính thích xã giao của họ có thể được nguỵ trang dưới con mắt khắt khe
        và tính thích đánh giá xét đoán tính cách người khác. Họ rất giỏi trong
        việc đánh giá tính cách người khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Mão sở hữu một góc nhìn rộng mở về cuộc sống và chấp nhận mọi
        thứ dung hoà. Chính góc nhìn này thúc đẩy cuộc sống của họ cân bằng, có
        thể dẫn họ đến lòng trắc ẩn. Không chỉ như vậy, điều này cũng gợi cảm
        hứng cho họ hành động theo một cách đầy nghĩa khí.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thanh thiếu niên ngày sinh Quý Mão có thể phát thiện nhiều bệnh nhỏ kỳ
        quặc, tuy nhiên không có bệnh nào trong số đó phát triển thành bệnh nguy
        hiểm chết người. Bởi vì điều này, không chỉ tài lộc của họ bị ảnh hưởng,
        mà họ còn phải trải qua những thời điểm không may mắn. Những điều này
        đều sẽ khiến cho họ khó kiếm tiền.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi người Quý Mão trưởng thành đến độ tuổi trung niên, họ sẽ thấy được
        rằng họ ở một vị trí mà tài lộc phát triển lại bị ngăn cản bởi sự bất ổn
        và khó khăn. Một khi họ vượt qua giai đoạn này của cuộc đời, họ sẽ trải
        nghiệm thay đổi trong vận may cũng như tình hình tài chính.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc cho hoàn cảnh chung này, người Quý Mão có thể trải nghiệm cuộc đời
        mà những người khác dán mác cho là “sống khoẻ”. Họ không bao giờ thiếu
        thức ăn ngon và quần áo đẹp.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Những Đặc Tính Của Người Quý Mão:</b> thông minh, cảm xúc, nghệ sĩ,
        thân thiện, lo lắng quá mức, nghĩa khí, thích vật chất.{" "}
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bởi vì Thực Thần bên dưới ngày sinh, người này có trực giác cao và tham
        vọng. Khi họ cảm thấy tự tin, họ sẽ thể hiện sự hào hứng. Ngoài ra, họ
        là những người sẵn lòng hợp tác với đồng nghiệp cũng như với cấp trên.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặt khác, khi Thực Thần bị xung, mọi thứ có thể thay đổi. Trong trường
        hợp này, họ có thể trở nên bất hợp tác. Điều này đặc biệt đúng khi họ
        được mong chờ cộng tác như một phần của đội nhóm.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khao khát bỏng cháy hành động và thành tích cá nhân thúc đẩy họ. Trong
        thực tế, tính cách này thường đóng góp vào xu hướng dễ dẫn đến sự thiếu
        kiên nhẫn và ngoan cố. Nhưng chính đạo đức nghề nghiệp sẽ thúc đẩy nhiều
        người nghĩ về họ như là người có tầm nhìn.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ sáng tạo và can đảm. Do đó, nhiềun người tin rằng họ ăn nói thẳng
        thừng. Mặc dù có nhiều khi đây là sự thật, nhiều người cho rằng đây là
        nét quyến rũ của họ.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ sáng tạo và can đảm. Do đó, nhiềun người tin rằng họ ăn nói thẳng
        thừng. Mặc dù có nhiều khi đây là sự thật, nhiều người cho rằng đây là
        nét quyến rũ của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Vì khả năng suy nghĩ nhanh nhạy, người Quý Mão thích những trải nghiệm
        mới và hứng thú. Họ thích đi đến những vùng đất xa xôi và gặp gỡ người
        mới. Họ có cách tiếp cận mới mẻ trong những khởi đầu mới.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Vì lẽ này, họ thích hợp với những công việc ngắn hạn. Họ cần phải nhanh
        chóng kết thúc một dự án trước khi họ cảm thấy nó chán nản. Cũng vậy, họ
        dễ cảm thấy chán nản khi mọi việc không theo đúng kế hoạch. Họ cần học
        cách kiên nhẫn, nếu không sự nổi giận của họ sẽ khiến tình hình tồi tệ
        hơn.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Công Việc:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Kết nối và tạo dựng mối quan hệ kết nối rất dễ dàng với người Quý Mão,
        mặc dù họ có tính độc lập mạnh mẽ và cá tính riêng, họ vẫn là một thành
        viên đội nhóm hoàn hảo. Họ dựa vào trực giác và suy nghĩ sáng tạo để
        thúc đẩy họ đến thành công. Điều này dẫn đến sự tin tưởng tự nhiên vào
        trực giác và giúp họ hình thành cảm tính tốt trong công việc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bởi vì họ đa tài, họ có thể thành công trong hầu hết mọi nghề nghiệp. Họ
        có tài năng thật sự trong lĩnh vực viết lách, ăn nói, giảng dạy và tư
        vấn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Những Đặc Tính Trong Công Việc - Sự Nghiệp:</b> trực giác cao, cầu
        toàn, nhận thức, sáng tạo, giỏi đối nhân xử thế, mất kiên nhẫn, thiếu
        trách nhiệm, quá đa cảm, ghen tuông, kín đáo, chỉ trích, tâm trạng.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Hôn Nhân - Gia Đình - Quan Hệ:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tính hai mặt của người Quý Mão xuất hiện trong đời sống tình cảm của họ.
        Đối với người ngoài thì người Quý Mão không hơn gì ngoài quái đản và
        khác biệt. Họ có thể có khẩu vị khá đặc biệt trong chuyện tình cảm. Nếu
        như không phải là kỳ lạ, thì ít nhất là họ cũng có chút lập dị.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có xu hướng hơi hư hỏng trong tình cảm. Địa chi Mão đại diện cho bộ
        phận sinh dục. Điều này cũng có nghĩa là họ cần phải thận trọng tránh
        rơi vào những chuyện tình dục ngoài hôn nhân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nam giới Quý Mão thường bị lôi cuốn bởi nhu cầu tình dục hơn là nữ giới.
        Nam giới có thói quen có nhiều bạn tình cùng một lúc. Nữ giới Quý Mão bị
        thu hút bởi đàn ông có xu hướng quái đản. Khi họ kết hôn, họ sẽ hướng
        tình yêu và đam mê của mình về phía con cái hơn là chồng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Xu hướng này đặt nữ giới vào thế bất lợi bởi vì sự ghen tuông bẩm sinh
        của họ. Họ cảm thấy họ bị thiệt thòi trong tình cảm với chồng. Họ thật
        sự tin rằng “chồng người ta hơn chồng mình”.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngay cả nếu nữ giới Quý Mão thích có quan hệ tình cảm tuyệt vời với bạn
        trai, họ vẫn cần phải cẩn trọng trước khi kết hôn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù họ thích tình cảm ấm áp, yêu thương đối với bạn đời trước khi kết
        hôn, họ có thể thay đổi tất cả ngay khi vừa kết hôn và trở thành vợ
        chồng vì xu hướng của họ thích cầu toàn. Khi cả hai đã kết hôn, nữ Quý
        Mão có thể nhận ra người chồng không phải thật sự hoàn hảo như họ từng
        nghĩ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Mão thường thích quảng giao và tập thể. Cùng lúc, họ muốn có
        mối quan hệ tốt với tất cả bạn bè. Họ là những người thường biết cách
        giữ gìn và chăm sóc tình bạn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Điều này thường khiến họ trở thành người năng động về mặt xã hội và giỏi
        kết nối - không chỉ trong mối quan hệ công việc mà còn trong đời sống cá
        nhân. Không ngạc nhiên khi phát hiện người Quý Mão thích quảng giao và
        kết bạn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi người Quý Mão thân thiết với ai, họ sẽ thể hiện mặt hướng ngoại của
        mình. Họ thể hiện tính nghệ sĩ và sáng tạo của mình với bạn thân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Mão có đời sống gia đình hạnh phúc. Người vợ/chồng thường có
        thể đem đến sự giúp đỡ mà người Quý Mão cần. Gia đình của người Quý Mão
        thường thể hiện sự tôn trọng và yêu thương chân thành lẫn nhau.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Những Đặc Tính Trong Tình Yêu - Quan Hệ:</b> thích lập dị và kỳ quặc,
        nữ giới khao khát sự hoàn mỹ ở bạn đời, đánh gía cao tình bạn, hướng
        ngoại, năng động, thích sự ấm áp, yêu thương.{" "}
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Quý toạ trên sao Trường Sinh cùng với Quý Nhân ở Mão.
      </p>{" "}
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Cục này gọi là Nhật Quý. Ngoài ra, Mão còn là Thực Thần của Quý. Tất cả
        những điều này gọi là cục Thực Thần Thổ Thấu.
      </p>{" "}
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người trụ ngày này được xem là cực kỳ thông minh. Họ có đầu óc sắc bén
        và thể hiện suy nghĩ rõ ràng. Họ sẽ nhìn vào một vấn đề, tập trung vào
        những sự kiện tương ứng, đoán định mối liên hệ và ý nghĩ cũng như từ đó
        rút ra kết luận chính xác, nhanh hơn những người khác.
      </p>{" "}
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ năng động về tinh thần và thể chất cũng như có tài diễn đạt và giao
        tiếp. Họ có khiếu về văn chương và viết lách. Họ giỏi về tri thức và phụ
        nữ ngày Quý Mão thích có thành tích học giỏi. Kỹ năng học tập của họ và
        sự sắc bén về trí óc nghĩa là họ sẽ rất thành công trong sự nghiệp.
      </p>{" "}
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Quý Mão toạ trên Trường Sinh, Quý Nhân và Thực Thần. Điều này biểu thị
        người Quý Mão sẽ có xu hướng từ bi và nhân ái. Họ cũng sẽ sở hữu khả
        năng cần thiết để có được cuộc đời thành công. Vì họ cũng là ngày sinh
        Thuỷ, tuy nhiên nếu có nhiều Thổ bẩn xuất hiện ở Can Năm và Can Tháng
        trong lá số biểu thị năng lượng tiêu cực hay bẩn ảnh hưởng đến người Quý
        Thuỷ.
      </p>{" "}
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sao Quý Nhân của người Quý (Mão) biểu thị họ sẽ có đời sống may mắn và
        được quý nhân giúp đỡ. Nếu có thêm Thuỷ xuất hiện trong lá số, có thể là
        người này gặp các vấn đề về cảm xúc ảnh hưởng tiêu cực đến đời sống và
        cảm xúc của người Quý Mão.
      </p>{" "}
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Quý Thuỷ cần phải đặc biệt chú ý đến Mão Mộc, đặc biệt nếu Mộc xuất hiện
        nhiều trong lá số. Hành Mộc sẽ hút hết toàn bộ hành Thuỷ của Quý. Điều
        này có thể khiến người Quý Mão làm việc lao lực, dẫn đến cạn kiệt năng
        lượng. Nếu như vậy, thì lá số này cần có sự ổn định. Trong trường hợp
        đó, Bính Thiên Can hay Tỵ ở Địa Chi sẽ giảm bớt căng thẳng thần kinh.
        Điều này cũng giúp tạo lá số ấm áp và giàu sức sống.
      </p>{" "}
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Hành Thuỷ đại diện cho ý tưởng, niềm tin và cảm xúc và Thực Thần Tinh
        biểu thị suy nghĩ hiệu quả. Nếu quá nhiều Thuỷ xuất hiện, người Quý Mão
        có thể suy nghĩ quá nhiều, có thể dẫn đến kỳ quặc.
      </p>{" "}
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trong số tất cả ngày sinh thì Quý Thuỷ được xem là hành yếu nhất. Do đó
        không nên gặp quá nhiều Mộc. Mặc dù Quý Thuỷ là trợ lực tốt nhất cho Mão
        Mộc vì nó tạo ra môi trường lý tưởng cho cây thuỷ sinh và Ất Mộc, biểu
        thị hoa cỏ, cần phải được tưới từ gốc rễ. Điều cần lưu ý là Mão là Thực
        Thần, biểu thị suy nghĩ bên trong và có thể khiến cho người Quý Mão suy
        nghĩ quá nhiều, phân tích quá nhiều và có thể trở nên cảm xúc bất ổn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngay cả dù cho người Quý có thể được xem là yếu nhất trong số các ngày
        sinh, nhưng Quý Thuỷ cũng không phải là yếu đuối. Thật sự thì giọt mưa
        hình thành tạo thành Nhâm Thuỷ (suối). Chu kỳ tự nhiên khiến cho nước
        bốc hơi và rơi xuống tạo thành mưa. Không khôn ngoan nếu đánh giá thấp
        sức mạnh và tiềm năng của người Quý Thuỷ, họ có thể tỏ ra dễ tổn thương
        nhưng vẻ ngoài có thể đánh lừa người khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Mão sinh vào mùa Xuân hay Hạ có thể có danh tiếng tốt và giàu
        có. Nếu sinh vào mùa Thu hay Đông thì họ có thể khó đạt thành công nếu
        không có sự giúp đỡ của bạn bè và gia đình. Sinh vào ban ngày thì Quý
        Mão có suy nghĩ vì cộng đồng. Nếu sinh vào ban đêm thì họ tốt hơn là làm
        việc một mình và xây dựng công việc kinh doanh riêng của mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Quý toạ trên Mão với năng lượng duy nhất của Ất Mộc Thực Thần. Thực Thần
        là sao của sự vui vẻ và do đó họ giỏi thể hiện sự dễ chịu. Sự kết hợp
        giữa Mộc Thực Thần cho họ thế mạnh khi đề cập đến quan hệ giao tiếp con
        người.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ biết cách thấu hiểu nhu cầu tâm lý và nắm giữ nó. Mão cũng là Đào Hoa
        và do đó dính líu đến hình Vô Lễ Tý Mão cũng như khiến cho họ dễ nhạy
        cảm và dễ bị tai tiếng liên quan đến tình dục.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mão hợp với Tuất giữ lại Mậu Thổ Chính Quan, Đinh Hoả Thiên Tài và Tân
        Kim Thiên Ấn. Đối với phụ nữ thì sao người chồng Chính Quan là dấu hiệu
        tốt đẹp trong khi đàn ông thì chỉ muốn mối quan hệ tạm thời vì có sự
        hiện diện của Thiên Tài.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mão bị Dậu tấn công mang theo năng lượng của Tân Kim Thiên Ấn. Ở đây nó
        không ảnh hưởng đến mối quan hệ tình cảm nhưng đóng vai trò quyết định
        trong việc kiểm soát Thương Quan vốn dĩ gây rắc rối cho quan hệ tình cảm
        của nữ giới.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trụ này có lợi cho nữ giới vì nó có vai trò nuôi dưỡng. Với sự gắn kết
        mạnh mẽ với Chính Quan và kiểm soát Thuơng Quan, phụ nữ có cơ hội tốt
        hơn để tiếp tục mối quan hệ tình cảm. Họ không đòi hỏi nhiều ngoài việc
        muốn được chăm sóc, quan tâm. Mặc dù họ có thể dễ dàng bị lợi dụng do
        dáng vẻ bề ngoài yếu đuối nhưng có khả năng thao túng người khác để đạt
        được điều mình muốn.
      </p>
    </div>
  );
};

export const QuySuu = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Quý Sửu: có tầm nhìn xa, năng lực cao, tự tin, dám chấp
        nhận rủi ro lớn, ít nói nhưng giỏi giao tiếp, có đời sống xã hội năng
        động. Nữ giới thì rất năng động đến nỗi không thích ở nhà làm nội trợ.
        Hôn nhân của nữ Quý Sửu thường không hạnh phúc và dễ ly hôn vì chồng gia
        trưởng.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Kem, bánh ngọt, sô cô la...có thể là thứ thoả mãn mọi nhu cầu của bạn.
        Sự hưởng thụ có thể là tốt với nhiều người nhưng quá hưởng thụ thì lại
        có phản ứng ngược. Sự kết hợp có thể trông vô hại nhưng chúng ta biết
        rằng một lượng đường xuât hiện trong kết hợp trên có thể gây vấn đề về
        sức khoẻ về lâu dài. Quý Sửu giỏi trong việc điều hoà.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Quý là hành biểu thị dòng nuóc nuôi dưỡng nhẹ nhàng, cũng như giọt sương
        sớm đậu trên lá cây. Sự nuôi dưỡng và quan tâm thường có thể giúp xử lý
        các vấn đề cảm xúc. Nó có khả năng tránh sự tổn hại bản thân vì họ có sự
        nhạy cảm đối với cảm xúc của người khác và có thể di chuyển đi xa khi
        cần. Họ thường đến và đi khi muốn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Quý Sửu có hình tượng như tuyết hay nước đá. Hình ảnh
        này nhấn mạnh đến vẻ ngoài lạnh băng mà họ thể hiện ra thường không biểu
        thị tình yêu thương với người khác, và chỉ biểu thị sự bất mãn của họ.
        Tuy nhiên, ẩn bên dưới lớp băng tuyết là một trái tim nồng ấm, thông
        minh và quan tâm.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Sửu thường thích xã giao, thân thiện, sáng tạo và cảm xúc. Họ
        cũng chung thuỷ, quan tâm và có hệ thống giá trị mạnh mẽ giúp họ có đầu
        óc nhân văn cởi mở. Họ có khả năng nắm bắt mọi việc nhanh chóng và dễ
        đánh giá mọi tình huống mà họ đang ở. Nhìn chung, họ trân trọng kiến
        thức và thường tìm cách để cải thiện bản thân và tình huống xung quanh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Sửu có nhu cầu lớn bộc lộ vô số ý tưởng. Họ luôn muốn động não
        suy nghĩ. Do đó, điều kiên quyết là họ cần có giáo dục tốt và khám phá
        mọi cảm hứng làm việc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ không thích học hỏi trực tiếp từ người khác. Thay vào đó, họ thích
        dành thời gian và nỗ lực để học hỏi và trải nghiệm mọi thứ một mình. Hầu
        như người Quý Sửu thường sống một cuộc đời suôn sẻ với tài chính đầy đủ.
        Nếu họ cảm thấy muốn được dẫn dắt theo đuổi con đường học hỏi tâm linh,
        họ có thể muốn tu luyện bản thân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Sửu thích sống trong vùng an toàn, và sẽ không chịu rời xa
        khỏi những gì mà họ biết rõ. Họ có thể dễ dàng rời bỏ vùng an toàn bằng
        cách chấp nhận thử thách mới. Họ nên giữ thái độ tích cực trong cuộc
        sống. Khi họ làm điều này, người Quý Sửu sẽ khám phá ra rằng họ có thể
        thật sự tạo nên phép màu. Sự hiểu biết này sẽ giúp họ tránh được sự tức
        giận hay trầm cảm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Sửu cần phải tránh tự mình gây áp lực cho chính mình. Tâm
        trạng dao động của họ có thể dao động từ tích cực và sáng tạo sang đến
        lo lắng và bi quan. Nếu họ tự yêu cầu bản thân quá khắt khe thì dễ thúc
        đẩy hành động bộc phát và do đó họ sẽ không bao giờ hoàn thành được
        những gì họ bắt đầu. Sự kiên nhẫn là một đức tính tốt mà họ cần rèn
        luyện.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ không thường bảy tỏ sự ấm áp đối với những người xung quanh và thường
        tỏ ra một cảm giác không thoả mãn. Họ có thể tỏ ra xù lông để bảo vệ
        chính mình tránh người khác chú ý. Nếu họ cho phép người xung quanh nhìn
        nhận rõ con người của họ, họ sẽ có nhiều người yêu mến hơn. Họ là những
        người theo chủ nghĩa hoàn mỹ và thường yêu cầu bản thân phải luôn xuất
        sắc. Điều này tạo ra nhiều áp lực không cần thiết trong đời sống hàng
        ngày và cho những ai phải làm việc với người Đinh Sửu.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có thể gặp vấn đề bất ổn về sức khoẻ và tài lộc khi còn trẻ, nhưng
        khi lớn tuổi, mọi thứ sẽ ổn hơn. Khi họ đến độ tuổi trung niên, họ sẽ
        thấy có sự cải thiện đáng kể trong vận may và thành công.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tài lộc và sức khoẻ của người Quý Sửu thường quyện chặt với nhau và khi
        họ tích tụ được tài lộc, họ thường sẽ cảm nhận tình trạng sức khoẻ của
        họ có biến động. Nói chung, họ càng có nhiều tiền trong tài khoản ngân
        hàng thì càng dễ đối mặt các vấn đề sức khoẻ.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Với người sinh ngày Quý Sửu thì họ thường xuyên phải đối mặt với các vấn
        đề sức khoẻ hàng ngày, cho tới ít nhất là khi họ đạt độ tuổi trung niên.
        Nhìn chung, họ dễ gặp vấn đề liên quan đến bao tử, máu huyết. Trong
        nhiều trường hợp thì sự trao đổi chất của họ bất ổn, khiến cho họ gặp
        vấn đề rắc rối về quản lý cân nặng. Tốt nhất là họ nên khám bác sĩ
        thường xuyên để kiểm tra định kỳ sức khoẻ.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những Đặc Tính Chung Của Người Quý Sửu: thường gặp vấn đề sức khoẻ hay
        cân nặng, người thích tự học hỏi, cảm xúc, thân thiện, nhân đạo.{" "}
      </p>

      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Sửu thường rộng rãi, ngẫu hứng và có cái nhìn lạc quan về mọi
        thứ. Họ thường sống thực tế giúp họ làm việc tốt. Họ có nhu cầu tìm kiếm
        những hoạt động nào có tính hứng khởi và gợi cảm xúc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có thể muốn học hỏi tâm linh và huyền học trong quá trình tìm kiếm ý
        nghĩa của cuộc đời. Con đường này sẽ khiến cho người Quý Sửu đạt được độ
        sâu sắc trong cảm xúc trong khi đón nhận và chinh phục nhiều thử thách
        hơn.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Một người bình thường sẽ gặp trở ngại lớn trong việc giải mã những gì mà
        người Quý Sửu đang suy tính nếu chỉ nhìn vào vẻ tự kiêu bề ngoài của họ.
        Người Quý Sửu thật sự rất bí hiểm.{" "}
      </p>

      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Công Việc:</h4>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Sửu có nhiều sự lựa chọn công việc và do đó sẽ sử dụng kiến
        thức của mình cho những mục tiêu cụ thể. Họ có thể lèo lái đưa mình đến
        thành công nếu thật sự giỏi chuyên môn. Họ cũng thật sự ham muốn đạt đến
        mức độ thành công nhất định về vật chất và địa vị.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Sửu thích kịch tính và họ có thể gắn đặc điểm này với tham
        vọng nghề nghiệp cua mình, họ thường có sự nghiệp thành công trong mảng
        kịch nghệ, giải trí, âm nhạc, văn chương hay nghệ thuật. Họ cũng rất phù
        hợp trong mảng chính trị và kinh doanh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ bẩm sinh thích nhân đạo và thường xuyên giúp đỡ người khác. Điều này
        sẽ khiến cho việc lựa chọn sự nghiệp tư vấn kinh doanh hoặc cá nhân rất
        phù hợp cho họ. Họ cũng có tài năng đặc biệt trong lĩnh vực truyền
        thông. Sự nghiệp lý tưởng của họ bao gồm pháp luật, giáo dục hay viết
        lách.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhận thức xã hội cao cùng với độ nhạy cảm bên trong giúp cho họ dễ tham
        gia vào những lĩnh vực như trị liệu. Họ cũng thích những gì liên quan
        đến công bằng xã hội. Họ cũng cực kỳ thành công trong lĩnh vực nghiên
        cứu hay chuyên gia.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Nét Đặc Điểm Trong Công Việc Của Người Quý Sửu:</b> tham vọng,
        sáng tạo, thực dụng, làm việc tốt với người khác, chỉ trích phê bình,
        hấp tấp, thiếu kiên nhẫn.{" "}
      </p>

      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Hôn Nhân - Quan Hệ - Gia Đình:{" "}
      </h4>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Quý Sửu thường là người vợ/chồng yêu thương, quan tâm và
        tận tâm. Đời sống yêu đương của họ càng được củng cố nếu họ gặp người
        sáng tạo. Điều này có thể do cả hai đều là những người thích sáng tạo.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ cần cẩn trọng không được để cho những gì cực đoan xuất hiện. Có lúc,
        họ có thể trở nên quá phụ thuộc người khác hoặc đôi khi họ trở nên áp
        đặt cho người khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ là những người thích xã giao với nhiều bạn bè và các mối quan hệ
        rộng. Mối quan hệ trở thành một phần không thể thiếu của họ và do đó họ
        rất quan tâm đền người khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu xét về tình bạn thì họ là những người rất đáng kính và phân biệt rõ
        ràng đúng và sai. Họ cũng thường tránh soi mói đời sống người khác và
        cũng thích giữ đồi sống cá nhân mình bí mật.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Sửu biết rất rõ những gì là trách nhiệm và nghĩa vụ của mình.
        Họ cần một mái ấm và gia đình để cảm thấy an toàn. Họ thích có một cuộc
        hôn nhân kéo dài, viên mãn và hạnh phúc. Con cái của họ cũng thường thừa
        hưởng sự thông minh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mối quan hệ của họ với các thành viên trong gia đình như bố mẹ và anh em
        thường căng thẳng và khó dựa vào nhau. Người Quý Sửu ít khi gặp người
        thân và cũng khó dựa vào người thân để nhận được sự ủng hộ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Trong Tình Yêu và Mối Quan Hệ:</b> cống hiến, xã
        giao, trách nhiệm, quan tâm.{" "}
      </p>

      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:
      </h4>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trong lá số Bát Tự, can ngày Quý toạ trên Kim Khố và có thông căn, biểu
        thị tính cách duyên dáng và tinh tế. Quý cũng toạ ẩn Thất Sát và Ấn
        Tinh, gián tiếp sẽ sinh ra Tỷ Kiên. Điều này cũng biểu thị cuộc sống
        nhiều thách thức lớn và phải lao động vất vả trước khi có thành tích,
        cũng như vận may cũng người này sẽ biến đổi theo hướng có lợi cho anh em
        và vợ chồng về sau.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Sửu rất khôn ngoan. Họ có đầu óc phân tích thích dành thời
        gian nghiên cứu số liệu và dữ kiện cũng như yêu thích sự chính xác. Tính
        cách này là do ẩn Thiên Ấn. Ảnh hưởng thứ hai của Thất Sát Tinh biểu thị
        họ là người theo chủ nghĩa hoàn mỹ. Họ có những giá trị đạo đức tốt và
        rất quyết tâm. Họ cũng sẽ gặp thuận lợi nếu học cao và nắm vững những kỹ
        năng cần thiết.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Sửu có ham muốn gây ảnh hưởng đến người khác cũng như thành
        công nhưng có thể những điều này không dễ xảy đến cho họ và họ phải làm
        việc vất vả mới có thể đạt được. Sự hiện diện của ẩn Thiên Ấn trong lá
        số biểu thị họ là những người tư duy độc lập và hơn thế nữa, người Quý
        Sửu là những người kín đáo và hướng nội. Điều này có thể khiến cho họ có
        vẻ ngoài lạnh lùng với người khác và không thích xã giao. Họ có thể tỏ
        ra nghi ngờ và e ngại người đối diện.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu gặp những cát tinh, người Quý Sửu có thể suy nghĩ rất thấu đáo. Họ
        là những người lên kế hoạch tốt và có kỹ năng kinh doanh tốt cũng như
        niềm tin vào sự chính xác khiến cho họ dễ trở thành nhà nghiên cứu giỏi.
        Khi gặp các hung tinh, sự nghi ngại khiến cho họ trở thành người cô đơn.
        Chủ nghĩa hoàn mỹ có thể dẫn dắt họ trở nên cứng nhắc, đầu óc nhỏ hẹp và
        ngoan cố sẽ làm cản trở sự tiến bộ cua họ. Họ có thể có tâm trạng thất
        thường.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu người này gặp trụ Ất Mão, họ sẽ thành công và thịnh vượng. Cách cục
        này biểu thị cho thành công vượt bậc trong sự nghiệp. Nó cũng biểu thị
        người Ất Sửu biết cách biến kiến thức thành kỹ năng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu Quý Sửu gặp trụ Giáp Dần trong lá số Bát Tự, họ có thể đối mặt với
        những trở ngại lớn cần phải vượt qua. Kết quả tốt hay không sẽ phụ thuộc
        vào khả năng kiểm soát xu hướng mất kiên nhẫn, hay hấp tấp của họ có
        thành công hay không.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Dù cho là gặp Giáp Dần hay Ất Mão thì nếu là kỵ thần đều có thể gây Hại
        và biểu thị tình trạng bất lợi cho người Quý Sửu. Sẽ tốt hơn nếu Quý Sửu
        xung bởi Mùi trong lá số bởi điều này sẽ làm giảm thiểu tác dụng xấu. Sẽ
        không may cho người sinh ngày Quý Sửu nếu gặp cặp Tý Sửu hợp là kỵ thần
        vì nó sẽ biểu thị cuộc sống nhiều thăng trầm.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sư tan chảy của băng hoà lẫn Kỷ Thổ với nước bẩn Thuỷ. Do cách cục này
        trong lá số mà đa phần người Quý Sửu có thể gặp vấn đề sức khoẻ xấu. Đặc
        biệt bất lợi nếu Bính Hoả hiện diện trong lá số vì điều này chỉ làm thúc
        đẩy nhanh hơn quá trình tan chảy. Với hành Bính Hoả, sẽ dễ dàng hơn để
        đạt tài lộc nhưng đáng đổi sẽ là sức khoẻ suy dần. Sẽ tốt hơn nếu gặp
        Đinh Hoả vì Đinh Hoả không gây ảnh hưởng xấu lên băng đá.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Sửu rất hợp để học các môn huyền học bởi vì nó giúp kiểm soát
        Bính. Một ví dụ điển hình là Gia Cát Lượng là người có ngày Quý Sửu và
        có Bính trong lá số nên điều này cũng báo hiệu cái chết sớm cua ông.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người Quý Sửu sinh vào mùa Xuân và Hạ sẽ có đời sống thịnh vượng.
        Người sinh vào mùa Thu và Đông sẽ có sức khoẻ và sống thọ. Nếu người Quý
        Sửu sinh vào tháng Mùi thì sẽ khó thành công và giàu có. Người Quý Sửu
        sinh vào ban ngày sẽ dễ thịnh vượng trong khi người sinh vào ban đêm sẽ
        gặp nhiều may mắn.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Quý toạ Sửu mang theo hành chính của Kỷ Thổ Thất Sát và phụ khí của Tân
        Kim Thiên Ấn và Quý Thuỷ Tỷ Kiên. Họ có sự nhạy cảm về bạn bè bởi vì Tân
        kim củng cố Thuỷ, cùng lúc Kỷ Thổ lại khuấy động cảm xúc khiến cho họ
        mất đi khả năng bỏ đi. Họ trở nên.căng thẳng trong việc muốn giải quyết
        vấn đề của người khác.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mối quan hệ tình cảm có thể chấp nhận được nếu như người bạn đời không
        quá quan tâm đến mức độ bạn bè phụ thuộc vào người này đến mức người Quý
        Sửu có thể giúp đỡ bạn bè bất cứ lúc nào họ cần. Sự xuất hiện của Thất
        Sát cũng gợi ý vẻ ngoài khá dữ dội và gây ngạc nhiên. Miễn là bạn đời
        cảm thấy ổn với việc họ trở thành anh hùng trong mắt bạn bè của họ thì
        mối quan hệ tình cảm hôn nhân vẫn ổn định. Sẽ hơi khó khăn đối với người
        nữ Quý Sửu vì Thất Sát đại diện cho bạn trai (đàn ông khá ganh tỵ).{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sửu hợp với Tý mang theo năng lượng Quý Thuỷ Tỷ Kiên. Đối với nữ giới
        thì Tỷ Kiên biểu thị tổn hại cho mối quan hệ tình cảm vì Tỷ Kiên có thể
        hợp với sao người chồng trong khi đối với nam giới thì sẽ làm suy yếu
        Tài Tinh. Nhìn chung, người này quan tâm nhiều bạn bè và đồng nghiệp.
        Cần có một mức độ kiềm chế để có thể là vợ hay chồng của người này vì
        bạn sẽ cần thường xuyên chia sẻ họ với những người mà người Quý Sửu gọi
        là bạn bè.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sửu xung với Mùi mang theo năng lượng của Kỷ Thổ Thất Sát, Đinh Hoả
        Thiên Tài và Ất Mộc Thực Thần. Bởi vì mọi người đối với họ đều là bạn
        bè, họ có ít nhu cầu cần bạn trai hay bạn gái. Nghe có vẻ tốt nhưng mọi
        chuyện phức tạp hơn vì ranh giới mờ nhạt của họ và họ rất vui khi làm
        vừa lòng bạn bè, kể cả vấn đề tình dục.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhìn chung, họ không thuộc tuýp người yêu tiêu chuẩn mà hơi giống với
        con chim xã hội luôn sẵn sàng bảo vệ bạn bè của mình. Nhìn chung thật
        tuyệt để trở thành bạn bè của họ nhưng khá đau đầu khi trở thành vợ hay
        chồng. Đối với người yêu bình thường thì chỉ cần lo lắng trở thành bạn
        trai hay bạn gái lý tưởng nhưng ở đây bạn phải chia sẻ người Quý Sửu với
        bạn bè của họ.
      </p>
    </div>
  );
};

export const QuyHoi = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Quý Hợi có vận mệnh cuộc đời như sóng biển đại dương,
        lên voi và xuống chó, từ bãi rác đến tỷ phú, có tài năng văn chương,
        tình cảm với gia đình không tốt, thích du lịch xuất hành, có nhu cầu
        tình dục cao, mất tài lộc vì quá tự tin hoặc tham lam. Cuộc hôn nhân đầu
        tiên thường không kéo dài, dễ kết hôn nhiều lần. Với phụ nữ, dễ khiến
        cho người bạn đời gặp chuyện xấu sau năm 40 tuổi.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Quý Hợi có hình tượng là những giọt nước, có hình dạng
        tự nhiên yếu đuối. Một khi gặp điều kiện môi trường phù hợp thì biến
        thành một lực mạnh. Một là không ai có thể chặn đường bạn hoặc là bạn sẽ
        bị thay thế không thương tiếc.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Quý Hợi là người im lặng và khiêm tốn. Một khi gặp điều kiện phù hợp thì
        họ sẽ chuyển hoá thành một thứ ở thái cực đối lập với năng lực vô biên
        đối đầu mọi thứ. Tốt nhất là không nên cản đường của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Quý Hợi là có thể hình dung là vùng nước đen hoặc vào một đêm không
        trăng. Điều này biểu thị sự bí ẩn trong tính cách của họ. Hành động và
        suy nghĩ của họ không dễ đoán và người xung quanh họ thường khó khăn khi
        phải đoán bước đi tiếp theo của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bí ẩn, thực dụng và có ý chí mạnh mẽ là một số trong những nét tinh cách
        của người Quý Hợi. Bản tính học hỏi và lãnh đạo bẩm sinh khiến cho họ có
        lợi thế trong nhiều hoàn cảnh cuộc sống. Được xem là nổi loạn, họ nổi
        tiếng về sự độc lập, nhiệt tình và tham vọng. Đây là lý do vì sao họ
        tiếp tục tìm kiếm những kiến thức và mối quan tâm mới.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nền tảng học vấn vững chắc giúp gia tăng cơ hội thành công của họ. Họ là
        người chủ động và thường ít khi bị cản bước bởi khó khăn. Họ nhanh chóng
        khám phá và tận dụng những phương thức hiệu quả để giải quyết các tình
        huống khó khăn. Khi họ đối mặt các tình huống với tâm thế bình tĩnh, họ
        sẽ khám phá giấc mơ của họ không quá khó để biến thành hiện thực.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Hợi thẳng thắn và chân thành khi giao tiếp và trong khi họ
        thường tuân thủ theo xã hội, họ cũng có những hành động mạnh mẽ khi cần
        thiết. Họ thích sự công bằng và sẽ sẵn sàng đấu tranh cho những gì mà họ
        tin là đúng đắn. Ngoài ra, họ có bản tính độc đáo bí ẩn khi bàn về những
        vấn đề triết lý.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Hợi yêu sự tự do trong thể hiện nhu cầu. Họ khao khát những gì
        giúp họ thoát ra khỏi quy trình thông thường. Điều này biểu thị khát
        khao đi lại, cũng là lý do vì sao những người này thích đi xa vào các kỳ
        nghỉ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sự yêu thích ngẫu hứng của họ khiến lôi kéo họ vào những con người hoặc
        sự kiện bất thường.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Có xu hướng chịu bệnh tật, người Quý Hợi nên dành sự chú tâm đặc biệt
        đến sức khoẻ và nhu cầu dinh dưỡng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Hợi tỏ ra bay bổng và thường để cho sự nghi ngờ bên trong họ
        ngăn cản hi vọng viên mãn hạnh phúc và bình an nội tại.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những Đặc Tính Của Người Quý Hợi: nổi loạn, cầu toàn, thẳng thắn và chân
        thật, độc lập, nhạy cảm, nhiệt tình, tham vọng.
      </p>

      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Hợi giỏi lãnh đạo và khao khát độc lập cho bản thân. Họ thích
        tự khởi nghiệp cũng như đi đầu tiên phong trong kinh doanh. Với tất cả
        những phẩm chất tích cực, người Quý hợi rất dễ khởi sự doanh nghiệp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khả năng cân bằng giữa kinh doanh và niềm vui là một khía cạnh độc đáo
        của người Quý Hợi. Khá thân thiện, họ thường có nhiều người thích ở
        quanh họ. Họ có tầm nhìn về sự nghiệp và chân thành với tất cả mọi người
        ở chỗ làm.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Luôn tìm kiếm thách thức, những người này tin tưởng rằng trở ngại sẽ
        giúp họ xây dựng nên tài năng và kỹ năng. Điều này không có nghĩa là họ
        nên chủ động chấp nhận tất cả mọi cơ hội. Họ cần cân nhắc các khả năng
        có thể xảy ra trước khi nhận trách nhiệm nào. Họ cũng nên cân nhắc cơ
        hội đó có thể gia tăng tiềm năng của họ đến đâu.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Không phải tất cả người Quý Hợi đều có thể phát huy tối đa tiềm năng.
        Một số người làm việc trong những ngành nghề không thể phát huy những
        tài năng ẩn giấu của họ. Trong những tình huống đó, họ có thể có sự mâu
        thuẫn bên trong gây ra sự nóng giận, cay đắng hay thậm chí ghen tức với
        người khác.{" "}
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Công Việc:</h4>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người Quý Hợi sẽ giỏi giang trong nhiều lĩnh vực. Bởi vì khả năng
        lãnh đạo độc đáo, họ sẽ trở thành những nhà điều hành xuất sắc. Họ cũng
        giỏi trong việc kinh doanh của riêng mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trong khi họ được thừa hưởng tài kinh doanh thiên bẩm, người Quý Hợi vốn
        sáng tạo và sẽ thành công trong sự nghiệp đòi hỏi sự tưởng tượng cao,
        đặc biệt trong lĩnh vực âm nhạc, nghệ thuật và kịch nghệ. Tâm linh và
        huyền học cũng sẽ là lựa chọn phù hợp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có lý tưởng sống là giúp đỡ mọi người. Vì lẽ này, họ dễ bị thu hút
        bởi những công việc phi lợi nhuận. Họ có thể trở thành những người giáo
        viên, cố vấn lý tưởng trong những lĩnh vực này.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bản tính của họ cũng thích hợp cho công việc trong ngành quân sự hay
        chính trị.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Những Đặc Tính Trong Sự Nghiệp - Công Việc:</b> khả năng lãnh đạo,
        khiếu kinh doanh, năng động, sáng tạo, ghen tỵ, nghi ngờ, chua chát.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Hôn Nhân - Quan Hệ - Gia Đình:{" "}
      </h4>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Hợi yêu thích niềm vui, duyên dáng và nhanh nhạy khi yêu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhu cầu quan trọng nhất của họ trong vấn đề tình cảm là kích thích trí
        óc và tự do và tìm hiểu thông tin mới mẻ.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Hợi thích gặp gỡ người hoàn toàn đối lập với mình và có thể
        muốn kết hôn muộn trong đời. Mặc dù thỉnh thoảng những cặp đối lập cũng
        có thể hợp nhau, nhưng thường thì là kết quả tệ hại.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ cảm thấy khó khăn khi phải tập trung trong một mối quan hệ và không
        ngạc nhiên khi thấy người Quý Hợi có nhiều mối quan hệ cùng lúc. Điều
        này làm phức tạp vấn đề và khiến tình hình tệ hơn, và người Quý Hợi được
        khuyên rằng không nên vội vã lao vào kết hôn.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bản tính của người Quý Hợi là hướng ngoại và thân thiện. Họ thường giao
        tiếp thành công và được xem là linh hồn của các buổi tiệc. Khi người
        ngoài mới gặp Quý Hợi lần đầu, họ thường lập tức bị tính thực tế và độc
        lập của người Quý Hợi gây ấn tượng. Ngay sau khi gặp gỡ, họ phát hiện ra
        Quý Hợi sở hữu cảm xúc sâu sắc, cũng như sự nhạy cảm. Ngoài ra, người
        Quý Hợi được biết đến là có khả năng hoà hợp với nhiều kiểu người có
        hoàn cảnh xã hội và kinh tế khác nhau. Họ thường bị cuốn hút bởi những
        người thông minh, có giáo dục tốt.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Phụ nữ ngày Quý Hợi thường cảm thấy hôn nhân viên mãn và có khả năng lấy
        chồng gìau. Bởi vì điều này, hôn nhân của họ thường kéo dài. Nói chung,
        phụ nữ Quý Hợi không phản bội tình dục chồng. Nếu họ rơi vào một cuộc
        hôn nhân không hạnh phúc, họ sẽ kết hôn lần thứ hai. Phụ nữ ngày sinh
        Quý Hợi cần phải cẩn trọng không trở nên quá nhiều chuyện hoặc tỏ ra áp
        đặt người chồng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đàn ông Quý Hợi, ngược lại, thì không gặp hạnh phúc và thoả mãn trong
        đời sống hôn nhân. Họ sở hữu những nét hấp dẫn và dễ dàng cuốn hút phụ
        nữ. Điều này bởi vì hành Thuỷ lạnh của Quý Hợi thường cuốn hút sự ấm áp
        của Hoả (vốn đại diện cho bạn gái của người Quý). Bởi vì điều này, đời
        sống gia đình của người sinh ngày này có thể không thuận lợi đặc biệt
        nếu vợ hay chồng không tin họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Quý Hợi chú trọng nhiều đến tự do. Khi nào họ cảm thấy mất tự do trong
        quan hệ, họ sẽ cảm thấy bất hạnh và trầm cảm. Đối với người Quý Hợi
        mạnh, họ sẽ cố gắng vùng lên đòi tự do và kết thúc hôn nhân. Không gian
        riêng là rất quan trọng đối với người Quý Hợi. Khi nào không gian riêng
        của họ được tôn trọng, người Quý Hợi sẽ có hôn nhân bền vững kéo dài.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Những Đặc Tính Trong Hôn Nhân - Tình Cảm:</b> hướng ngoại, duyên
        dáng, nhanh nhạy, thân thiện, thực tế, độc lập.{" "}
      </p>

      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Hợi toạ Dương Nhẫn, biểu thị ham muốn làm việc và hành động
        của họ. Người Quý Hợi còn được gọi là Huyền Vũ Nhật. Quý Hợi rất độc lập
        và ganh đua. Họ không muốn gì hơn là được xem là người giỏi nhất trong
        lĩnh vực của họ và họ theo đuổi mục tiêu này với sự can đảm, nhiệt tình
        và tràn đầy năng lượng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Hợi hướng ngoại và có thể rất duyên dáng và nhanh nhạy. Họ lạc
        quan và thích xã giao, và khi gặp cát tinh thì biểu thị khả năng lãnh
        đạo. Họ rất sáng tạo và tràn đầy ý tưởng độc đáo - và dưới ảnh hưởng của
        Thực Thần trong Hợi biểu thị họ sáng tạo, thực hành và siêng năng. Những
        tính chất trên kết hợp với nhau có thể thúc đẩy họ đạt được thành công
        lớn mà họ mong đợi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Hợi có thể khá tự cao. Khi gặp các sao tiêu cực thì họ sẽ hống
        hách, áp đặt. Họ có thể cảm thấy rằng toàn bộ thế giới xoay quanh họ và
        họ trở nên ích kỷ cũng như tự kiêu. Họ cũng thiếu kiểm soát bản thân mà
        trở nên mất kiên nhẫn và bất ổn. Nam giới sinh ngày này dễ không chung
        thuỷ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Quý Hợi đại diện cho bầu trời trong xanh với nguồn nước đến từ núi cao,
        nơi khí chảy tự do từ phương Tây Bắc, sơn Càn và Hợi, tượng trưng cho
        Thiên trong Kinh Dịch. Sẽ tốt đẹp nếu trụ này gặp nhiều Mộc biểu thị cho
        gió, vì điều này biểu thị người Quý Hợi sẽ có thể trở nên rất giàu có
        thậm chí cực kỳ nổi tiếng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi gặp thêm Tỵ và Hợi kẹp hai bên trong lá số Bát Tự, nó tạo ra Xung và
        Hình, biểu thị những thách thức lớn trong đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Hợi không nên gặp quá nhiều Nhâm Thân vì tạo ra lá số bất lợi,
        khiến cho nước bất ổn và tâm trạng bất an. Cũng tốt hơn nhiều nếu trụ
        ngày này gặp đại vận Hợp vì biểu thị con đường suôn sẻ đến thành công và
        đạt thành tích cao.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Lá số Bát Tự thường biểu thị những điều tốt đẹp hơn cho nữ giới Quý Hợi
        hơn là nam Quý Hợi vì nữ giới có thể sử dụng Quan Tinh giúp củng cố khả
        năng tạo ra tài lộc vật chất và thoải mái. Sự hiện diện của Kiếp Tài có
        thể ảnh hưởng đến tài lộc của Quý Hợi trong ngắn hạn nhưng nhìn tổng thể
        vẫn là dấu hiệu thịnh vượng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ra vào mùa Xuân và Hạ có thể rất tử tế mặc dù họ có thể tỏ ra
        khó chịu. Người sinh vào mùa Thu và Đông sẽ cô đơn và thích có nhiều bạn
        bè. Bất lợi nếu họ sinh vào tháng Thìn vì người này sẽ khó đạt sự thành
        công mong muốn vì lý do sức khoẻ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Quý Hợi sinh vào ban ngày sẽ đạt thành công trong sự nghiệp kinh
        doanh riêng. Người sinh vào ban đêm sẽ có may mắn và danh tiếng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Quý thuỷ là ngũ hành yếu nhất trên 10 Thiên Can. Họ mang tính nuôi dưỡng
        như giọt sương trên cây non, luôn mang tính nuôi dưỡng từ từ. Ngũ hành
        Quý Thuỷ biểu thị sự đồng cảm với cảm giác của bạn và điều chỉnh bản
        thân. Cũng như mây bị gió thổi, một chút thay đổi trong phương hướng sẽ
        định hướng lại mây. Người Quý Hợi bước vào cuộc đời của bạn một cách
        lặng lẽ và cũng sẽ ra đi không để lại dấu vết. Họ là người tin tưởng vào
        thuyết “đến với thế giới này không có gì và sẽ ra đi không kèm theo gì”.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Quý toạ Hợi mang theo khí của Nhâm Thuỷ (Kiếp Tài) và Giáp Mộc (Thương
        Quan). Cả hai đều là năng lượng mạnh mẽ đặc biệt Giáp biểu thị ngũ hành
        không ngừng tiến bộ, biểu thị sự gia tăng. Kiếp Tài giúp họ có khả năng
        hoàn thiện và học hỏi từ người khác trong khi Thương Quan khiến cho họ
        có nhu cầu muốn phá vỡ những quy luật hiện tại. Nếu như không có thay
        đổi, họ sẽ sẵn lòng tạo ra sự thay đổi. Cũng như một cơn bão lớn đến để
        dọn đường cho cái cũ ra đi và cái mới xây dựng lại.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Với mục đích tình cảm thì Quý là người cho đi và họ có khả năng bỏ đi.
        Khi liên kết giữa 2 điềm xấu cho mối quan hệ là Thương Quan và Kiếp Tài
        xuất hiện, biểu thị tai hoạ cho những người muốn có tình cảm nghiêm túc
        với họ. Thương Quan sẽ xua đuổi sao người chồng trong khi Kiếp Tài sẽ
        gây xấu cho người vợ. Trong mối quan hệ tình cảm của họ thì họ không
        phải luôn chú trọng vì họ có mục tiêu lớn hơn trong đời là thay đổi thế
        giới chưa tốt xung quanh. Họ thích phê bình và ganh đua với bạn đời và
        đòi hỏi người kia phải theo kịp họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trừ khi bạn là người yêu thích tốc độ, bạn sẽ khá ngạc nhiên khi thấy
        người này xoay như chong chóng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Hợi hợp với Dần mang đến Giáp Mộc Thương Quan, Mậu Thổ Chính Quan và
        Bính Hoả Chính Tài. Chính Tài là sao biểu thị người vợ đối với lá số nam
        giới trong khi Chính Quan là sao biểu thị người chồng đối với lá số nữ
        giới. Ở đây bạn có thể thấy họ muốn có một mối quan hệ nhưng tình huống
        ép buộc họ phải chọn giữa tình cảm hay bức tranh toàn cảnh. Khó hơn cho
        nữ giới vì kết hợp này cũng mang đến Thương Quan và do đó phá hỏng tình
        cảm với Chính Quan.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Hợi xung với Tỵ mang theo khí của Bính Hoả Chính Tài, Mậu Thổ Chính Quan
        và Canh Kim Chính Ấn. Chúng ta có thể nhìn thấy ở đây việc Chính Tài và
        Chính Quan bị xua đuổi. Không muốn bị ràng buộc và giờ đây lại thêm sự
        xua đuổi của Ấn Tinh, họ thậm chí không muốn bạn giúp đỡ. Việc của tôi
        là của riêng tôi thôi!
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đây hoàn toàn là một thách thức để đuổi theo kịp người Quý Hợi và càng
        thách thức hơn khi bạn muốn trở thành người yêu của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những đặc quyền bình thường của bạn sẽ bị tước đoạt và hầu như bạn sẽ
        cảm tưởng như mình bị ném đi. Chìa khoá để có thể ở bên cạnh họ là tránh
        ra khi cơn bão nổi lên và quay lại khi nó kết thúc. Trong chiến tranh,
        đây là nơi thả bom, bạn quay trở lại dọn dẹp khi khói bụi đã lắng xuống.
        Không ai điên rồ đến mức đi ngăn quả bom đang rơi.
      </p>
    </div>
  );
};
