export const QuyThuy = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "70%", margin: "0 auto" }}>
        <h1 style={{ textAlign: "center", color: "blue", fontWeight: "bold" }}>
          Chương X: Quý Thủy <br /> Ngày Sinh Quý Thủy
        </h1>
      </div>

      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Dụng Thần Điều Hậu</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Sinh Vào Mùa Xuân</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Dần: 05/02 – 05/03
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Bính Hỏa, Tân Kim
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Mão: 06/03 – 04/04
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Canh Kim, Tân Kim
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Thìn: 05/04 – 05/05
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Tân Kim, Bính Hỏa, Giáp Mộc
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Sinh Vào Mùa Hạ</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Tỵ: 06/05 – 05/06
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Tân Kim
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Ngọ: 06/06 – 07/07
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Quý Thủy, Canh Kim, Nhâm Thủy
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Mùi: 08/07 – 07/08
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Quý Thủy, Nhâm Thủy, Tân Kim, Canh Kim
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Sinh Vào Mùa Thu</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Thân: 08/08 – 07/09
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Đinh Hỏa
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Dậu: 08/09 – 08/10
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Tân Kim, Bính Hỏa
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Tuất: 09/10 – 07/11
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Tân Kim, Nhâm Quý, Giáp Mộc, Quý Thủy
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Sinh Vào Mùa Đông</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Hợi: 08/11 – 07/12
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Tân Kim, Canh Kim, Mậu Thổ, Đinh Hỏa
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Tý: 08/12 – 05/01
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Bính Hỏa, Tân Kim
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Sửu: 06/01 – 03/02
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Đinh Hỏa, Bính Hỏa
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Lưu ý: Các bạn cần lưu ý những dụng thần điều hậu phía trên chỉ mang
        tính chất tham khảo. Nếu cần biết thêm về các dụng thần điều hậu, cũng
        như các dụng thần khác trong lá số bát tự bạn cần tham khảo hoặc tư vấn
        từ các chuyên gia.
      </p>

      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Hình Tượng</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Quý Thuỷ là Âm Thuỷ, đại diện cho sương mù, sương mai và những hạt nước
        nhỏ và những cơn mưa rào nhẹ nhàng. Đây là một dạng thuỷ có thể bao trùm
        lên những nơi rộng lớn của không gian trong một khoảng thời gian ngắn.
        Cũng giống như nước nuôi dưỡng đất để trồng cây và cho ra hạt, đem đến
        sự đơm hoa kết trái, những người Quý Thuỷ thuộc tuýp người nuôi nấng và
        truyền cảm hứng cho người khác. Họ rất giỏi trong việc truyền cảm hứng
        hoặc chỉ ra được điều tốt nhất bên trong mỗi con người.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Vốn thuộc hành Thuỷ, người Quý Thuỷ vốn rất thông thái và có khả năng
        cảm thụ tốt. Họ là những nhà thông thái bẩm sinh và thường suy ngẫm về
        mặt triết lý, đời sống, vấn đề hoặc khó khăn từ ngày này qua ngày khác.
        Người Quý Thuỷ thường suy nghĩ rất nhiều. Họ cũng thường có sự nhìn thấu
        một cách kì lạ về con người và những tình huống khác nhau.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Quý Thuỷ, giống như những đám mây trên trời, rất hay bay bổng và
        bí ẩn. Họ có những cảm xúc thất thường và có những hành động cũng như
        lời nói khó đoán. Cũng chính vì thế, mọi người thường hay gặp khó khăn
        trong việc đoán tính cách người Quý Thuỷ. Một phút trước họ nghĩ theo
        hướng này, một phút sau họ lại nghĩ theo hướng khác. Sự kiên định không
        phải là một trong những nét tính cách mạnh nhất của họ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Quý Thuỷ, giống như những đám mây trên trời, rất hay bay bổng và
        bí ẩn. Họ có những cảm xúc thất thường và có những hành động cũng như
        lời nói khó đoán. Cũng chính vì thế, mọi người thường hay gặp khó khăn
        trong việc đoán tính cách người Quý Thuỷ. Một phút trước họ nghĩ theo
        hướng này, một phút sau họ lại nghĩ theo hướng khác. Sự kiên định không
        phải là một trong những nét tính cách mạnh nhất của họ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Thông thường, điều này sẽ khiến người khác khó chịu với người Quý Thuỷ.
        Mọi người thường sẽ thấy thích thú với điều bí ẩn, nhưng đôi khi nó cũng
        sẽ làm họ thấy chán nản. Người Quý Thuỷ rất hay trở nên thất thường và
        tâm trạng, giống như một trận mưa rào bất chợt, vừa ào xuống lại vội
        ngưng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Quý Thuỷ là Âm Thuỷ. Hình ảnh đó giống như những đám mây hay sương mù.
        Hình ảnh tự nhiên của chúng thường rất khó để dự đoán trước. Ở nơi làm
        việc, đồng nghiệp của họ thường hay băn khoăn họ thường làm gì trong một
        ngày. Sự kiên định không bao giờ là điểm mạnh của họ. Năng suất của Quý
        Thuỷ bị ảnh hưởng rất nhiều vào tâm trạng trong ngày của họ. Tuy họ rất
        thông minh và có khả năng đạt được nhiều thành tựu to lớn, rào cản lớn
        nhất của Quý Thuỷ là cái tính dễ thay đổi và không kiên định của họ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Trong khi Quý Thuỷ có thể đóng góp nhiều ý tưởng cũng như khía cạnh mới
        lạ và có những hướng giải quyết vấn đề rất sáng tạo, sự không kiên định
        trong cách thể hiện đôi khi có thể khiến đồng nghiệp cảm thấy khó chịu.
        Để có thể làm việc với người Quý Thuỷ đòi hỏi phải có sự vị tha và thấu
        hiểu.
      </p>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Tính Cách</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ dễ có những “cảm giác đặc biệt” về mọi thứ và tìm hiểu qua trực giác
        của họ. Đó là những khả năng cảm nhận vốn có của họ. Họ rất tinh khôn và
        có thể đưa ra những kết luận rất thuyết phục dựa vào những gì họ quan
        sát, thấy và nghiên cứu. Hầu như lúc nào họ cũng rất hóm hỉnh vì họ biết
        cách dẫn dắt câu chuyện của mình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ là những người sâu sắc và phức tạp, luôn có sự riêng tư và đặc biệt
        là rất khó để hiểu được họ. Người Quý Thuỷ thường giữ lại chút gì đó cho
        riêng mình và hay giấu giếm. Đối với người khác, họ rất bí ẩn mặc dù họ
        cũng có thể trở nên thật ấm áp. Người Quý Thuỷ rất hay nghĩ cho người
        khác và quan tâm đến cảm xúc của mọi người. Họ luôn cố gắng nhẹ nhàng
        với người khác để tránh làm tổn thương ai đó.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Quý Thuỷ rất nhạy cảm nên rất dễ đụng phải xung đột và họ thường
        không dễ chịu đựng những chuyện đó. Những tình huống dẫn đến cuộc mâu
        thuẫn có thể khiến một người Quý Thuỷ yên lặng trở nên bị kích động và
        tức giận. Họ có xu hướng sẽ chịu đựng những năng lượng mâu thuẫn đó vào
        bên trong cơ thể và chịu những căng thẳng về sức khoẻ và cảm xúc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Quý Thuỷ rất hiếm khi có cùng suy nghĩ với người khác. Họ thường
        được truyền cảm hứng và rất sáng tạo, mơ mộng về những ý tưởng và suy
        nghĩ khác thường – và vì thế, khác xa với hiện thực. Những suy nghĩ đó
        thường không có kết cấu trật tự và luôn tuôn chảy một cách tự do, rất
        giống với cách giọt sương được hình thành trên những ngọn lá hoặc ngọn
        cỏ. Họ thích sự thoải mái trong cách suy nghĩ khi nào họ thích và họ
        thích ra làm sao. Bởi vì suy nghĩ của họ không có một cái lối suy nghĩ
        cụ thể mà nó rất thất thường.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Hầu như tư duy của họ là thích nghi và lười nhác nếu như một giá trị nào
        đó của họ không bị thách thức. Sau đó họ sẽ tiếp tục rèn luyện và giữ
        vững lòng tin của họ. Nhìn chung, người Quý Thuỷ là những người kể
        chuyện và nhà văn rất tài năng. Họ có thể nhìn thấy được tiềm năng ở
        khắp mọi nơi kết hợp với khả năng thấu hiểu, nuôi dưỡng và giúp đỡ mọi
        người.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Những người khác thỉnh thoảng lại thấy suy nghĩ của những người Quý Thuỷ
        rất kì lạ hoặc là “ở trển”. Nhưng thực chất đó lại là bí mật để dẫn đến
        sự thành công về mặt sáng tạo của họ. Người Quý Thuỷ sẽ làm việc một
        khoảng thời gian rất dài và dốc hết sức lực vào những công việc mà họ
        nghĩ là quan trọng để đạt được mục tiêu đề ra. Tuy nhiên, họ sẽ không
        dồn hết năng lượng vào những việc vô lí hoặc họ không thấy được ứng dụng
        thực tế của chúng. Phần lớn người Quý Thuỷ rất độc lập với suy nghĩ của
        mình họ nên họ thường thích làm việc một mình, nhưng sẽ làm việc nhóm
        rất tốt nếu như bản chất công việc đó đòi hỏi sự độc lập.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nhìn chung, người Quý Thuỷ là người thiên về trí tuệ và học thuật. Họ
        thích bị kích thích về trí óc và luôn hứng thú với những gì kích thích
        tinh thần và trí óc. Đối với họ, không rèn luyện trí óc là một tội lỗi.
        Họ mạnh mẽ theo một cách thầm lặng, và thường chăm chăm vào suy nghĩ
        cũng như công việc cho tới khi họ hoàn thành.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Như vậy, họ thường nghĩ kỹ và lường trước được những rắc rối họ sẽ gặp
        phải. Đôi khi, nếu như trong lá số của họ có hành Thuỷ quá vượng, họ có
        thể suy nghĩ quá nhiều và dẫn đến suy nghĩ quẩn quanh, lòng vòng không
        tìm ra lối thoát.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Và cũng đôi khi, nếu họ không cẩn trọng, họ sẽ dễ bị rối bởi những suy
        nghĩ của họ hoặc không thể chịu được những nỗi lo lắng dẫn đến rối loạn
        lo âu. Nhưng nói chung, khi người Quý Thuỷ đang trên đà tiến lên và kiểm
        soát được suy nghĩ của họ, những gì họ nghĩ có thể mang lại một nguồn
        cảm hứng đáng kinh ngạc. Họ đơn giản chỉ cần có kỉ luật hơn và chú tâm
        vào suy nghĩ của họ để có thể đảm bảo rằng suy nghĩ của họ thật sự hữu
        ích.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ thường rất nhiệt tình, duy tâm và là những cá nhân có tính sáng tạo
        cực kì cao đối với hầu hết những thứ khiến họ thấy hứng thú. Họ thường
        được ban phước cho những kĩ năng tốt, và họ cần phải sống đúng với những
        giá trị sâu bên trong. Người Quý Thuỷ luôn luôn bị thu hút bởi những ý
        tưởng mới nhưng lại dễ chán nản với những tiểu tiết của một cái dự án
        nào đó. Họ thường là những người phóng khoáng, linh hoạt và có niềm hứng
        thú to lớn cũng như khả năng làm được rất nhiều việc khác nhau. Họ sẽ
        cảm thấy rất vui khi đáp ứng được nhu cầu của mọi người.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Quý Thuỷ không phải tuýp người chỉ tập trung vào một thứ suốt. Vì
        họ hứng thú với rất nhiều thứ nên họ thường làm nhiều việc cùng một lúc.
        Trong 10 Thiên Can, Quý Thuỷ có lẽ có khả năng làm được nhiều việc cùng
        lúc nhất.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ dễ tự thích ứng và luôn hướng về hành động. Vì sương mù tượng trưng
        cho họ và sương mù rất dễ xuất hiện và dễ biến mất – cũng giống như tính
        khí của Quý Thuỷ. Người Quý Thuỷ giỏi nhất thường là những “người hành
        động” mà chú trọng vào các kết quả ngay trước mắt. Họ sống trong thực
        tại, và họ thường là những người thích rủi ro và thích sống vội.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Và người Quý Thuỷ không kiên nhẫn với những lời giải thích dài dòng. Mỗi
        lúc có công việc hoặc dự án nào đó khiến họ thấy chán, họ thường không
        ngần ngại mà bỏ nó đi. Tất nhiên, đây chính là vấn đề đối với đồng đội
        và cấp dưới của Quý Thuỷ vì họ thường phải tổng hợp lại những công việc
        rải rác và hoàn thành nó.
      </p>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Mối Quan Hệ/ Tình Cảm</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ dường như rất đa cảm và ngây thơ ở bên ngoài nhưng trong lại rất khôn
        khéo. Điển hình là họ biết cách để phát hiện ra tiềm năng cũng như tài
        năng của một người nào đó và khiến họ bộc lộ chúng thông qua sự tinh tế
        và sự kiên trì nhẹ nhàng. Họ có xúc giác tinh vi – cũng giống như cơn
        mưa phùn – và sẽ rất hiếm khi độc đoán hoặc mạnh mẽ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Mối quan tâm chủ yếu của người Quý Thuỷ trong cuộc sống là thấu hiểu
        được thế giới mà họ đang sống. Họ luôn luôn tiếp thu những ý tưởng và
        hình ảnh về những gì mà họ thấy được trong cuộc sống. Bằng cách sử dụng
        trực giác tiến hành thông tin này, họ thường rất nhạy và chính xác về
        khả năng đo lường sự việc. Người Quý Thuỷ thường là người của những ý
        tưởng. Khả năng cảm thụ giúp họ có thể nhìn thấy được khả năng ở mọi
        nơi.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ có sự hứng thú và nhiệt huyết về những ý tưởng và có thể lan toả tinh
        thần đó đến mọi người. Bằng cách này, họ sẽ dễ có được sự giúp đỡ mà họ
        cần để thực hiện được cái tầm nhìn sáng tạo của họ. Người Quý Thuỷ không
        thích nghĩ giống người khác. Họ có tinh thần tự do một cách mãnh liệt
        khi họ muốn nói lên suy nghĩ của họ. Họ không phải kiểu người tôn sùng
        quá mức những lối suy nghĩ truyền thống.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu hành Thuỷ vượng là dụng thần, nó sẽ trở thành ý tưởng nằm ngoài sức
        tưởng tượng và đặc biệt là cách giải quyết cho những vấn đề độc đáo. Họ
        lúc nào cũng có thể truyền cảm hứng và dẫn dắt mọi người suy nghĩ theo
        hướng khác và mới lạ hơn (bởi vì tính nuôi dưỡng của họ đảm nhận việc
        đó). Tuy nhiên, đôi khi người Quý Thuỷ trở nên yếu ớt những kỵ thần
        trong lá số số của họ, họ có thể để nó trở thành cái điểm không có tác
        dụng gì cả. Họ có thể bị ám ảnh với việc luôn phải trở nên khác biệt so
        với mọi người và điều đó trở nên lập dị. Sự sáng tạo của người Quý Thuỷ
        phụ thuộc nhiều và cảm xúc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nói chung, người Quý Thuỷ có xu hướng thiếu sự kiên trì có liên quan đến
        sự hứng thú của họ trong cuộc sống. Giống như cơn mưa phùn ào xuống chỉ
        trong phút chốc rồi lại hết do chuyển động nhanh của những đám mây,
        người Quý Thuỷ có thể hoàn toàn mê say với một cái gì đó trong hôm nay –
        và rồi nhanh chóng chán nó vào tuần sau!
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đó là vì, người Quý Thuỷ thường có rất nhiều kĩ năng và tài năng. Thường
        là họ sẽ làm tốt việc gì khiến họ thấy thích thú. Vấn đề là: rất nhiều
        thứ làm họ thấy thích thú. Đó là lí do vì sao họ thường làm rất nhiều
        nghề trong cuộc đời của họ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Theo như mọi người thường thấy, người Quý Thuỷ dường như bị mất phương
        hướng, không kiên định và không có mục đích sống, nhưng người Quý Thuỷ
        thật ra rất kiên định ở mặt này: họ có một nhu cầu rất mạnh mẽ trong
        việc tìm kiếm những điều “mới mẻ” trong cuộc sống. Mọi thứ họ làm phải
        phù hợp với nguyên lý này. Người Quý Thuỷ cần phải thấy được là họ đang
        sống cuộc sống của họ thông qua những ý tưởng mới mẻ và luôn luôn giải
        quyết bằng nhiều điều thú vị. Họ cho rằng mọi thứ đều có ý nghĩa và vẫn
        tiếp tục tìm kiếm những thứ khác để hoà hợp với cuộc sống và những giá
        trị của họ để đạt được sự an yên nội tâm.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người xung quanh không thể kỳ vọng quá nhiều vào người Quý Thuỷ trong
        việc tiếp thu hoàn toàn một lĩnh vực nào đó trong thời gian nhất định.
        Quý, giống như những đám mây, xuất hiện ở khắp nơi. Do đó, họ có rất
        nhiều sự hứng thú trong cuộc sống. Về mặt tích cực, điều này giúp họ
        tiếp cận và giải quyết một vấn đề theo nhiều khía cạnh khác nhau. Họ hầu
        như lúc nào cũng có thể nghĩ ra được những cách giải quyết mới cho những
        vấn đề cũ. Về mặt tiêu cực, điều này có nghĩa là họ không hề kiên định
        trong mắt những người khác. Hơn nữa, họ thiếu sự kiên nhẫn để đào sâu
        hơn và nghiên cứu kỹ lưỡng một vấn đề nào đó. Họ sẽ chỉ nhìn lướt qua,
        nếu như họ thấy chán, họ sẽ làm cho xong việc rồi chuyển qua làm cái
        khác.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Một phần sự tự tin của Quý Thuỷ bắt nguồn từ việc chiến thắng bằng lời
        nói. Họ hãnh diện về khả năng tác động một cách tinh tế để khiến người
        khác đồng tình với mình. Việc thay đổi suy nghĩ của mọi người và có sức
        ảnh hưởng tích cực đến người khác giúp người Quý Thuỷ cảm thấy vui.
        Triết lý và tư duy của họ càng có tác động tích cực đến người khác thì
        họ sẽ càng thấy kiêu hãnh về bản thân họ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ có năng khiếu nhất định trong việc tranh luận bằng lời nói. Bằng sự
        dí dỏm và hùng hồn, họ thường khiến đối thủ phải khổ sở trong sự hổ thẹn
        vì để lộ sườn. Điển hình là Quý Thuỷ thường vượt trội trong các cuộc
        thảo luận, tranh luận và mỗi khi cần phát biểu trước đám đông.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tuy nhiên, việc này phụ thuộc rất nhiều vào trình độ học vấn của người
        Quý Thuỷ. Nếu họ là người Quý Thuỷ vượng, họ thường rất nhanh trí và có
        học thức (kể cả là được giáo dục hoặc là tự học) và rất thông minh, đó
        là những điều đáng để nói. Một người Quý Thuỷ nhược, ngược lại thiếu
        hiểu biết sâu rộng và sự lanh lợi về tư duy để gây được sự ảnh hưởng với
        người khác.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Quý Thuỷ nhìn chung không thích lãnh đạo đám đông. Họ hầu như
        thích nép mình vì không muốn có những sự chú ý không đáng có. Họ thường
        rất vị tha và linh hoạt trong hầu hết những hoàn cảnh… nếu một trong
        những đức tin vững chắc của họ không bị xúc phạm hoặc thách thức trong
        những trường hợp họ giữ vững lập trường. Đây là lúc “chiến binh thực
        thụ” bên trong họ bị đánh thức.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đừng thách thức người Quý Thuỷ làm bất cứ chuyện gì nếu bạn không sẵn
        sàng chống đỡ giao kèo đó đến phút cuối cùng vì họ dường như sẽ không
        nhường bất cứ ai! Một người Quý Thuỷ biết rõ về việc họ là người không
        hề biết sợ sẽ không khuất phục trước nghịch cảnh. Đây là lúc bạn có thể
        thấy được đám mây trẳng từ từ chuyển thành đám mây bão, đã sẵn sàng cho
        cuộc chiến.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ thường có can đảm để thử những cái mới hoặc lao vào một cái dự án đầy
        rủi ro. Đó là vì Quý Thuỷ vốn cảm thấy hứng thú với những cái mới và thú
        vị. Rất thú vị khi chúng ta xét tính 2 mặt của ngày sinh Quý Thuỷ. Sự
        can đảm của họ đến từ nghịch cảnh trước đó hoặc khi họ bị thách thức
        hoặc đe doạ. Trong những giai đoạn bình thường của cuộc sống, đa số
        người Quý Thuỷ thường dễ ngại ngùng khi nói đến chuyện gặp gỡ bạn mới.
        Họ chỉ tự tin và thích tụ tập với người họ đã quen từ trước, hoặc khi
        thảo luận về những chuyện mà họ hiểu rõ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Quý Thuỷ cũng có thể rất đề phòng và hay hoài nghi về động cơ hành
        động của người khác. Họ thường tự mình kiểm soát mọi thứ mỗi khi xuất
        hiện sự bất đồng quan điểm. Do đó, nếu đồng nghiệp của họ muốn đưa ra
        những hướng giải quyết khác nhanh hơn thì họ thường chống đối với đề
        nghị đó. Bởi vì tính khí thất thường của họ nên họ dễ bị thu hút bởi ý
        tưởng rằng chỉ có 1 người toả sáng trong công việc. Họ rất thích những ý
        tưởng và các đề án mới nhưng có thể thờ ơ với việc lặp đi lặp lại quy
        trình để biến mọi ý tưởng thành hiện thực.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Quý Thuỷ có suy nghĩ vượt trội sẽ truyền cảm hứng cho đội của mình
        bằng một tầm nhìn chung về tương lai. Giống như cách mà giọt gương mai
        nuôi dưỡng cây lá vào buổi sáng, một người Quý Thuỷ có thể thuyết phục
        được đồng đội của mình bằng tầm nhìn vào trong tương lai và cách mà họ
        muốn mọi thử phải diễn ra.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ tận hưởng với những kế hoạch và ý tưởng hơn là thảnh quả họ đạt được.
        Họ thấy hứng thú về những khả năng có thể xảy ra trong tương lai nhưng
        có thể dễ dàng chán nản và bồn chồn với hiện tại.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Quý Thuỷ thường có các nhóm bạn muôn màu muôn vẻ và thú vị. Họ ít
        khi sa lầy vào việc biết về người khác chỉ qua phạm vi và địa vị của
        người đó. Họ thích làm quen với nhiều người ở khắp nơi
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Do vậy, vì khuynh hướng này nên họ rất dễ làm bạn với mọi người ở mọi
        nơi. Họ không phân biệt bạn bè dựa trên ‘địa vị cao/thấp’ hoặc ‘có giáo
        dục/không được giáo dục’. Vì lí do đó mà người khác thường dễ bị thu hút
        bởi người Quý Thuỷ mặc cho tính tình dễ thay đổi, sáng nắng chiều mưa
        của người Quý Thuỷ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Quý Thuỷ thường có tài năng đặc biệt: có thể kết nối được với mọi
        người. Người Quý Thuỷ cảm thấy hạnh phúc khi có thể dùng việc đó để giúp
        đỡ người khác. Họ thấy thoả mãn khi phục vụ người khác. Họ cảm thấy
        thích thú khi làm việc với con người và trực giác giúp cho họ có thể lôi
        kéo kể cả những người dè dặt nhất.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ có được sự thoả mãn từ việc đi chơi với nhóm bạn khắn khít và thân
        thiết một cách thoải mái. Ở những nhóm nhỏ, người Quý Thuỷ có thể là một
        trong những người thích nói và sôi nổi nhất. Một khi họ đã thấy được
        “tông” chung của họ và họ có thể hoàn toàn thấy thoải mái, thư giãn và
        có những thời gian vui vẻ khi ở bên nhau, họ sẽ thấy thích đi chơi với
        nhau.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người Quý Thuỷ, quan hệ tình cảm với người khác là sự mở rộng
        trí tưởng tượng và sự sáng tạo của họ. Do đó, họ đặt rất nhiều kì vọng
        vào những mối quan hệ đó, và không đối xử với người xung quanh một cách
        nhạt nhẽo và bình thường.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Trong thế giới lý tưởng của Quý Thuỷ, họ có thể sống chung với bạn bè
        trên một ốc đảo và có thể đi chơi với nhau ngày qua ngày, trao đổi ý
        tưởng, suy nghĩ và giúp nhau vui vẻ bằng nhiều cách thể hiện sáng tạo.
        Họ thích ý tưởng về cộng đồng những người thân thiết – cũng giống như
        một đại gia đình. Điều này khiến họ thấy an toàn và hạnh phúc. Họ tin là
        mỗi người đều có cái gì đó thu hút và thích hợp để đề nghị điều gì đó.
        Và do vậy, họ cố gắng gặp gỡ và biết thêm nhiều người hơn. Và trong một
        vài trường hợp, đối với nam Quý Thuỷ, điều này có nghĩa là họ sẽ có thêm
        nhiều bạn thân khác giới sau hôn nhân.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Hầu như người Quý Thuỷ đều phản đối các truyền thống và tục lệ. Họ là
        những người khác biệt. Họ thường có những cuộc tranh cãi nóng nảy với
        những người tuân theo truyền thống bảo thủ. Vì lí do đó, bạn thân của họ
        là cần phải là những người chấp nhận tính cách này của Quý Thuỷ và yêu
        thương họ mặc kệ điều đó. Điều này khiến Quý Thuỷ cảm thấy thoải mái và
        được công nhận, và họ rất quý trọng và không hề xem nhẹ điều đó.
      </p>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Sự Nghiệp/ Công Việc</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Những người lãnh đạo Quý Thuỷ quyền lực nhất phải có khả năng quan sát
        và dành nhiều thời gian để giao tiếp. Họ không cần thiết phải lãnh đạo
        từ tiền tuyến, vì họ thường giao phó trách nhiệm cho những thành viên
        khác và cấp dưới. Là một người đứng đầu, sự nhiệt tình của Quý Thuỷ rất
        dễ lây lan đến người khác nhưng họ cần được hỗ trợ bởi những đồng đội có
        khả năng để ý đến những chi tiết nhỏ nhặt. Nếu không thì tầm nhìn của họ
        không thực hiện được. Người Quý Thuỷ thường không bao giờ có thể tự mình
        làm những công việc chi tiết và cụ thể. Phong cách chính của họ là thêm
        vào đó những giá trị mới.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi họ lãnh đạo hay quản lí một nhóm nào đó, người Quý Thuỷ sẽ không quá
        khắc khe với những hình thức và luật lệ. Thử thách lớn nhất về mặt lãnh
        đạo của người Quý Thuỷ là thiếu sự kiên định. Một người Quý Thuỷ giỏi có
        thể cung cấp chỉ dẫn phù hợp cho cấp dưới của họ. Một người Quý Thuỷ tệ
        thường không có lập trường và làm cấp dưới cảm thấy nóng giận.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khác với những ngày sinh khác, người Quý Thuỷ thường gặp khó khăn trong
        việc tách rời đời sống cá nhân và công việc. Đó là vì ranh giới phân
        chia mọi thứ đối với họ rất mong manh và dễ đổi. Họ thường không có sự
        phân chia dứt khoát giữa công việc và đời sống cá nhân.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Quý Thuỷ thường thích biến công việc thành những việc cá nhân. Là
        những người lãnh đạo họ thường ưa thích những đồng đội hoặc cấp dưới
        nương theo ý họ. Họ không phải là người chấp nhận những sự bất đồng quan
        điểm. Người Quý Thuỷ sẽ dễ chịu khi làm việc với đồng đội mà họ cảm thấy
        là “chung thuyền”.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ thường cảm thấy sự tận tâm của người khác rất quan trọng đối với bất
        kì dự án nào khi làm. Động lực thúc đẩy của họ sẽ giảm nếu như họ không
        thấy được sự tận tâm của mọi người trong nhóm đều như nhau. Nếu họ tâm
        huyết với công việc đó 24 tiếng thì họ mong là những người khác cũng sẽ
        như vậy.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi căng thẳng, người Quý Thuỷ có thể mất đi khả năng tạo ra những gì
        thực tế và bị ám ảnh với những chi tiết nhỏ nhặt nhất. Những chi tiết đó
        có thể xem là rất quan trọng đối với họ nhưng thực tế thì không tới mức
        như thế đối với sự việc hoặc kế hoạch lớn. Có thể sẽ mất rất nhiều thời
        gian để giải quyết những chi tiết không đáng này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Quý Thuỷ vốn rất vui vẻ. Tuy nhiên, họ có thể trở nên không vui
        hoặc rất không hài lòng khi họ bị hạn chế bởi những hạn chót nghiêm ngặt
        hoặc công việc tẻ nhạt. Người Quý Thuỷ sẽ phát huy hết khả năng trong
        điều kiện họ có nhiều sự linh hoạt và nơi họ có thể làm việc với nhiều
        người và ý tưởng khác nhau. Họ không thích chịu sự quản lý từ người
        khác. Thực tế là rất nhiều người Quý Thuỷ thích tự kinh doanh. Họ có khả
        năng để làm ra được nhiều thứ mà không có ai giám sát miễn là họ thấy
        hứng thú với những gì họ đang làm.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Vấn đề là, họ thường bị thu hút bởi nhiều thứ trong cuộc sống. Chỉ khi
        nào họ chọn lọc lại chỉ còn một đam mê quan trọng nhất, họ thật sự không
        thể khởi nghiệp. Hơn thế nữa, họ không tài năng vượt trội như những
        người chủ doanh nghiệp. Đó là vì họ thường có xu hướng đổi ý về những gì
        cần phải làm. Trong khi họ có thể thấy được những ý tưởng khác lạ trong
        sự theo đuổi nghệ thuật và sáng tạo, họ ít khi biết cách để biến viễn
        cảnh này thành hiện thực. Đó là vì họ có một khoảng thời gian khó khăn
        để suy nghĩ về kết cấu, cách vận hành để thực hiện được nó.
      </p>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Kinh Doanh</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Và để thành công trong lĩnh vực kinh doanh, mỗi người cần phải sắp xếp
        mọi thứ theo trật tự. Người Quý Thuỷ sẽ làm mọi thứ suôn sẻ hơn nếu họ
        có một đội ngũ giỏi giang để có thể giúp họ trọn vẹn ở lĩnh vực này.
        Công việc giấy tờ hành chính không phải là sở trường của họ, nếu họ có
        thể lựa chọn ra những người tốt nhất và biết cách vận dụng các điểm mạnh
        bản thân thì họ sẽ có thể thành công trong việc khởi nghiệp.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tất nhiên là Quý Thuỷ vượng sẽ là người làm mọi việc có trật tự và kỹ
        lưỡng trong suy nghĩ. Tiêu biểu là họ sẽ sáng tạo như cách họ đã chuẩn
        bị. Những điều đặc biệt này của Quý Thuỷ đã giúp họ khắc phục được điểm
        yếu vốn có và biết cách suy nghĩ cũng như lên kế hoạch một cách logic,
        tạo ra những phương pháp hiệu quả và dựa vào sức mạnh của người khác để
        đạt được sự giàu có to lớn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lý do đầu tiên để người Quý Thuỷ tự khởi nghiệp kinh doanh là gì? Sự tự
        do! Họ muốn thoát khỏi việc phải làm những công việc cực nhọc cho người
        khác để sinh tồn. Và ngược lại, Quý Thuỷ muốn sống để họ có thể tận
        hưởng công việc của họ. Do đó, người Quý Thuỷ thường chọn kinh doanh
        liều lĩnh để họ có thể hoàn toàn tự do.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Trong công việc, họ muốn mọi thứ phải được chuẩn bị tốt và sẽ làm việc
        chăm chỉ để duy trì kết cấu và giải quyết những điều không rõ ràng. Họ
        cũng có xu hướng trở nên kiểu cách rườm rà và điều này đặc biệt đúng khi
        xét nơi làm việc hay nhà ở của họ. Người Quý thuỷ kinh doanh tốt khi họ
        có được cơ hội để giao thiệp với mọi người. Họ vốn thuộc về xã hội. Họ
        có khả năng kì lạ để thấu hiểu mọi người và chỉ nói những điều nên nói
        để khiến người khác vui.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ thích làm trung tâm của sự chú ý và xử lý rất tốt trong những lúc mà
        họ truyền được cảm hứng và lãnh đạo người khác như là giảng dạy và cố
        vấn. Nhu cầu của Quý Thuỷ để được tự do cho thấy rằng họ không thích đối
        mặt với những lý luận không gắn với yếu tố con người. Họ không hiểu và
        trân trọng giá trị của nó và sẽ thấy không vui trong trường hợp mà họ
        buộc phải đối mặt chỉ với những con số, tư duy và những việc khó mà
        không làm việc gì liên quan đến con người.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Những kĩ năng của người Quý Thuỷ vượng rất khác thường vì họ có thể
        khiến người khác làm đúng như những gì mà người Quý Thuỷ muốn. Họ khiến
        người khác thấy khó chịu và có thể moi ra được những gì mà người đó đang
        giấu. Hầu hết động cơ của Quý Thuỷ thường không phải ích kỉ, nhưng một
        người Quý Thuỷ yếu hoặc “Quý Thuỷ bẩn” có thể dùng sức mạnh của họ lên
        người khác để thao túng người đó.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Quý Thuỷ thường tranh cãi dữ dội và nóng nảy với những người không
        cùng quan điểm với họ. Nhưng Quý Thuỷ phải chấp nhận những sự bất đồng
        đó hoặc không nó sẽ ảnh hưởng đến các mối quan hệ theo nhiều hướng bất
        lợi. Không phải ai cũng muốn thoát ra khỏi vùng bình thường.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Quý Thuỷ thường thiên về những cuộc đầu tư ngắn hạn, trái với
        những gì dài hạn. Họ thường không có sự kiên nhẫn để chờ đợi những kết
        quả dài xuất hiện. Người Quý Thuỷ thường thoải mái với những điều rủi ro
        và với người Quý Thuỷ vượng, họ có thể thích mạo hiểm với rủi ro.
      </p>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Tài Lộc</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tất nhiên, người Quý Thuỷ vượng biết được sức mạnh và giá trị của đồng
        tiền và biết làm sao để khiến những đồng tiền đó phục vụ cho họ. Họ
        thích đầu tư cho dòng tiền hơn là kiểu đầu tư “mua và giữ xong cầu
        nguyện phép màu sẽ xảy ra”. Ngoài ra, hầu hết người Quý Thuỷ thiếu sự
        kiên nhẫn để đợi kiểu phương pháp chậm rãi trong việc ‘để dành tiền cho
        việc nghỉ hưu’. Ngay từ lúc đầu, họ biết rằng cách để họ trở nên giàu có
        là tạo ra chế độ dòng chảy liên tục để có thể tự mình làm nên sự giàu
        có.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Xét về đầu tư cá nhân, người Quý Thuỷ yếu thiếu duy nhất khả năng đưa ra
        quyết định. Xu hướng dễ thay đổi của họ sẽ ảnh hưởng đến những chiến
        lược và kế hoạch tài chính lâu dài. Vì vậy, họ thường trông cậy nhiều
        vào người khác bảo họ cần phải làm gì.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Quý Thuỷ vượng sẽ có tư duy đồng nhất và dùng giải pháp tốt nhất
        để tạo ra nguồn tiền nhằm giúp cho nguồn tài lộc của họ sinh sôi nảy nở.
        Điều này có nghĩa, họ sẽ có chiều hướng lâu dài cho các ý tưởng (tư duy
        và ước mơ) trong các cây phát tài bằng cách phát triển nhóm hỗ trợ đúng
        đắn và chuyển đổi ý tưởng của họ thành tài sản thuộc về trí óc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Quý Thuỷ tạo ra giá trị từ ý tưởng. Sự giàu sang thật sự đến từ
        đó. Sự yếu kém của người Quý Thuỷ là họ cần phải tự giám sát bản thân.
        Họ thường không có khả năng để có thêm ý tưởng hoặc ước mơ. Họ cần phải
        vượt qua điểm yếu của mình bằng cách tận dụng các điểm mạnh và nếu họ
        làm càng sớm thì tự do tài chính sẽ đến sớm hơn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Gieo hạt giàu sang hôm nay. Để biến nó cho ngân lưu hoặc đầu tư có giá
        trị, người Quý Thuỷ cần có kế hoạch. Họ chuẩn bị càng sớm thì họ sẽ đạt
        được sự tự do về tài chính sớm.
      </p>
    </div>
  );
};
