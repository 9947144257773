import React, { useRef, useEffect, useState } from "react";
import { Table } from "reactstrap";
import ReactTooltip from "react-tooltip";

import {
  MonthToCanChi,
  nguHanh,
  ThapThan,
  ThanSatDiaChi,
  ThanSatThienCan,
  TangCan,
  YearToCan,
  DayToCanChi,
} from "./convert";
import TableHour from "./TableHour";
const TableDay = ({ year, month, dungThan, checkDungThan, thienCanDay }) => {
  const [thangData, setThangData] = useState([]);
  const [tangCanThang, setTangCanThang] = useState([]);

  const [objectDaiVanData, setObjectDaiVan] = useState([]);
  const [hourShow, setHourShow] = useState(false);
  const [dataMonthYear, setMonthYear] = useState({
    year: year,
    month: month,
    day: "",
    thienCan: "",
  });
  const handleResult = async () => {
    const result = [];
    let months = month - 1;
    const tangCanThang = [];
    let objectDaivan = [];
    let currentYear = new Date().getFullYear();
    const date = new Date(year, months);

    let allDayInMonth = getAllDaysInMonth(date.getFullYear(), date.getMonth());

    for (let index = 0; index < allDayInMonth.length; index++) {
      const tempTime = new Date(year, months, allDayInMonth[index].getDate());

      const dateTietKhi = tempTime;
      const dayShow = allDayInMonth[index].getDate();
      const canChiDay = DayToCanChi(dateTietKhi.toDateString());

      const thienCanNgay = canChiDay?.name.split(" ")[0];
      const diaChiNgay = canChiDay?.name.split(" ")[1];

      const nguHanhTC = nguHanh(thienCanNgay, thienCanNgay);
      const thapThan = ThapThan(thienCanDay, thienCanNgay);
      const thanSatMonthArr = ThanSatDiaChi(thienCanNgay, thienCanNgay);
      const thanSatMonthCan = ThanSatThienCan(thienCanNgay, thienCanNgay);
      const TangCanMonthArr = TangCan(diaChiNgay);
      const thapThanMonth1 = ThapThan(thienCanNgay, TangCanMonthArr[0]?.name);
      const thapThanMonth2 = ThapThan(thienCanNgay, TangCanMonthArr[1]?.name);
      const thapThanMonth3 = ThapThan(thienCanNgay, TangCanMonthArr[2]?.name);

      tangCanThang.push({
        TangCan: TangCanMonthArr,
        ThapThan1: thapThanMonth1,
        ThapThan2: thapThanMonth2,
        ThapThan3: thapThanMonth3,
        year,
        month: months + 1,
        date: dayShow,
        thienCan: thienCanNgay,
      });
      result.push({
        thienCan: thienCanNgay,
        diaChi: diaChiNgay,
        nguHanhTC,
        thapThan,
        thanSatMonthArr,
        thanSatMonthCan,
        month: months + 1,
        year,
        date: dayShow,
      });
    }

    if (result[0]?.year <= currentYear && result[0]?.year > currentYear - 10) {
      objectDaivan = result[0];
    }
    tangCanThang.reverse();
    result.reverse();
    setThangData(result);
    setObjectDaiVan(objectDaivan);
    setTangCanThang(tangCanThang);
  };

  function getAllDaysInMonth(year, month) {
    const date = new Date(year, month, 1);

    const dates = [];

    while (date.getMonth() === month) {
      dates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }

    return dates;
  }

  useEffect(() => {
    handleResult();
  }, [month]);

  useEffect(() => {}, [thangData]);

  return (
    <>
      <div
        id="table_dv"
        style={{
          maxWidth: "1600px",
          margin: "0 auto",
          zIndex: "1000",
          position: "relative",
          overflowX: "scroll",
        }}
      >
        <Table
          hover
          bordered
          style={{
            textAlign: "center",
            marginTop: "35px",
          }}
        >
          <tbody>
            <tr className="fz__16">
              {thangData?.map((x) => {
                return (
                  <td
                    onClick={() => {
                      setHourShow(true);
                      setMonthYear({
                        year: x.year,
                        month: x.month,
                        day: x.date,
                        thienCan: x.thienCan,
                      });
                    }}
                    id={
                      objectDaiVanData.year === x.year && x.month === 1
                        ? "bg_hang1"
                        : ""
                    }
                    style={{ minWidth: "155px", cursor: "pointer" }}
                    className={
                      objectDaiVanData.year === x.year && x.month === 1
                        ? "border__1px background_"
                        : "border__1px"
                    }
                  >
                    Năm ({x.year}) <br /> Tháng ({x.month}) <br /> Ngày {x.date}
                  </td>
                );
              })}

              <th
                id="bg__age"
                className="bg__ clr__white fz__15 fw__b"
                style={{ width: "58px" }}
              >
                Tháng
              </th>
            </tr>

            <tr style={{ textAlign: "center" }}>
              {thangData.map((x) => {
                return (
                  <td
                    data-for="con-giap"
                    data-tip={x.thienCan}
                    onClick={() => {
                      setHourShow(true);
                      setMonthYear({
                        year: x.year,
                        month: x.month,
                        day: x.date,
                        thienCan: x.thienCan,
                      });
                    }}
                    id={
                      objectDaiVanData.year === x.year && x.month === 1
                        ? "bg_hang2"
                        : ""
                    }
                    style={{ fontWeight: "bold", cursor: "pointer" }}
                    className={
                      objectDaiVanData.year === x.year && x.month === 1
                        ? "fz__22 clr__ border__1px background_"
                        : "fz__22 clr__ border__1px"
                    }
                  >
                    <div>
                      <sup className="fz__15" style={{ paddingRight: "4px" }}>
                        {x.thapThan?.sinhKhac}
                      </sup>
                      {x.thienCan}
                    </div>
                  </td>
                );
              })}

              <th
                id="bg__lnntc"
                rowSpan="2"
                className="bg__ clr__white fz__15 fw__b"
                data-for="thien-can"
                data-tip="Thiên Can"
              >
                Thiên Can
              </th>
            </tr>

            <tr style={{ textAlign: "center" }}>
              {thangData.map((x) => {
                return (
                  <th
                    onClick={() => {
                      setHourShow(true);
                      setMonthYear({
                        year: x.year,
                        month: x.month,
                        day: x.date,
                        thienCan: x.thienCan,
                      });
                    }}
                    style={{ fontWeight: "normal", cursor: "pointer" }}
                    colspan="1"
                    className={
                      dungThan.includes(x.nguHanhTC.split(" ")[1]) &&
                      checkDungThan
                        ? "fz__15 border__1px background"
                        : objectDaiVanData.year === x.year && x.month === 1
                        ? "fz__15 border__1px background_"
                        : dungThan.includes(x.nguHanhTC.split(" ")[1]) &&
                          objectDaiVanData.year === x.year &&
                          x.month === 1
                        ? "fz__15 border__1px background"
                        : "fz__15 border__1px"
                    }
                    id={
                      dungThan.includes(x.nguHanhTC.split(" ")[1]) &&
                      checkDungThan
                        ? "dungThan"
                        : objectDaiVanData.year === x.year && x.month === 1
                        ? "bg_hang3"
                        : dungThan.includes(x.nguHanhTC.split(" ")[1]) &&
                          objectDaiVanData.year === x.year &&
                          x.month === 1
                        ? "dungThan"
                        : ""
                    }
                  >
                    {x.nguHanhTC}
                  </th>
                );
              })}
            </tr>

            <tr>
              {thangData.map((x) => (
                <td
                  onClick={() => {
                    setHourShow(true);
                    setMonthYear({
                      year: x.year,
                      month: x.month,
                      day: x.date,
                      thienCan: x.thienCan,
                    });
                  }}
                  id={
                    objectDaiVanData.year === x.year && x.month === 1
                      ? "bg_hang4"
                      : ""
                  }
                  style={{ fontWeight: "bold", cursor: "pointer" }}
                  className={
                    objectDaiVanData.year === x.year && x.month === 1
                      ? "fz__22 clr__ border__1px background_"
                      : "fz__22 clr__ border__1px"
                  }
                >
                  {x.diaChi}
                </td>
              ))}

              <th
                id="bg__lnndc"
                rowSpan="1"
                className="bg__ clr__white fz__15 fw__b"
                data-for="dia-chi"
                data-tip="Địa Chi"
              >
                Địa Chi
              </th>
            </tr>

            <tr className="fz__10">
              {tangCanThang?.map((val) => {
                return (
                  <td
                    onClick={() => {
                      setHourShow(true);
                      setMonthYear({
                        year: val.year,
                        month: val.month,
                        day: val.date,
                        thienCan: val.thienCan,
                      });
                    }}
                    style={{
                      verticalAlign: "middle",
                      padding: "0",
                      cursor: "pointer",
                    }}
                    className={
                      dungThan.includes(
                        val?.TangCan[0]?.nguHanh.split(" ")[1]
                      ) && checkDungThan
                        ? "border__1px background"
                        : objectDaiVanData.year === val.year && val.month === 1
                        ? "border__1px background_"
                        : "border__1px"
                    }
                    id={
                      dungThan.includes(
                        val?.TangCan[0]?.nguHanh.split(" ")[1]
                      ) && checkDungThan
                        ? "dungThan"
                        : objectDaiVanData.year === val.year && val.month === 1
                        ? "bg_hang5"
                        : ""
                    }
                  >
                    {val.TangCan[1]?.name ? (
                      <td
                        onClick={() => {
                          setHourShow(true);
                          setMonthYear({
                            year: val.year,
                            month: val.month,
                            day: val.date,
                            thienCan: val.thienCan,
                          });
                        }}
                        id={
                          dungThan.includes(
                            val?.TangCan[0]?.nguHanh.split(" ")[1]
                          ) && checkDungThan
                            ? "dungThan"
                            : val?.year <= new Date().getFullYear() &&
                              val?.year > new Date().getFullYear() - 10
                            ? "none_bg"
                            : "none"
                        }
                        style={{
                          border: "none",
                          padding: "0 10px 0 10px",
                        }}
                      >
                        <div>
                          <sup>{val?.ThapThan2?.sinhKhac}</sup>

                          {val?.TangCan[1]?.name}
                        </div>
                        <div>
                          <div>{val?.TangCan[1]?.nguHanh}</div>
                        </div>
                      </td>
                    ) : (
                      ""
                    )}

                    {val?.TangCan[1]?.name && val?.TangCan[2]?.nguHanh ? (
                      <th
                        onClick={() => {
                          setHourShow(true);
                          setMonthYear({
                            year: val.year,
                            month: val.month,
                            day: val.date,
                            thienCan: val.thienCan,
                          });
                        }}
                        id={
                          dungThan.includes(
                            val?.TangCan[0]?.nguHanh.split(" ")[1]
                          ) && checkDungThan
                            ? "dungThan"
                            : val?.year <= new Date().getFullYear() &&
                              val?.year > new Date().getFullYear() - 10
                            ? "none3_bg"
                            : "none3"
                        }
                        style={{
                          borderLeft: "1px solid black",
                          borderRight: "1px solid black",
                          borderTop: "none",
                          borderBottom: "none",
                        }}
                      >
                        <div>
                          <sup>{val?.ThapThan1.sinhKhac}</sup>
                          {val?.TangCan[0]?.name}
                        </div>
                        <div>
                          <div>{val?.TangCan[0]?.nguHanh}</div>
                        </div>
                      </th>
                    ) : val?.TangCan[2]?.name ? (
                      <th
                        onClick={() => {
                          setHourShow(true);
                          setMonthYear({
                            year: val.year,
                            month: val.month,
                            day: val.date,
                            thienCan: val.thienCan,
                          });
                        }}
                        id={
                          dungThan.includes(
                            val?.TangCan[0]?.nguHanh.split(" ")[1]
                          ) && checkDungThan
                            ? "dungThan"
                            : val?.year <= new Date().getFullYear() &&
                              val?.year > new Date().getFullYear() - 10
                            ? "none_bg4"
                            : "none4"
                        }
                        style={{
                          borderLeft: "none",
                          borderRight: "1px solid black",
                          borderTop: "none",
                          borderBottom: "none",
                        }}
                      >
                        <div>
                          <sup>{val?.ThapThan1.sinhKhac}</sup>
                          {val?.TangCan[0]?.name}
                        </div>
                        <div>
                          <div>{val?.TangCan[0]?.nguHanh}</div>
                        </div>
                      </th>
                    ) : (
                      <th
                        id={
                          dungThan.includes(
                            val?.TangCan[0]?.nguHanh.split(" ")[1]
                          ) && checkDungThan
                            ? "dungThan"
                            : val?.year <= new Date().getFullYear() &&
                              val?.year > new Date().getFullYear() - 10
                            ? "none2_bg"
                            : "none2"
                        }
                        style={{
                          border: "none",
                          display: "inline-block",
                          margin: 0,
                        }}
                      >
                        <div>
                          <sup>{val?.ThapThan1.sinhKhac}</sup>
                          {val?.TangCan[0]?.name}
                        </div>
                        <div>
                          <div>{val?.TangCan[0]?.nguHanh}</div>
                        </div>
                      </th>
                    )}

                    {val?.TangCan[2]?.name && val?.TangCan[1]?.name ? (
                      <td
                        onClick={() => {
                          setHourShow(true);
                          setMonthYear({
                            year: val.year,
                            month: val.month,
                            day: val.date,
                            thienCan: val.thienCan,
                          });
                        }}
                        id={
                          dungThan.includes(
                            val?.TangCan[0]?.nguHanh.split(" ")[1]
                          ) && checkDungThan
                            ? "dungThan"
                            : val?.year <= new Date().getFullYear() &&
                              val?.year > new Date().getFullYear() - 10
                            ? "none2_bg"
                            : "none2"
                        }
                        style={{
                          border: "none",
                          padding: "0 5px",
                        }}
                      >
                        <div>
                          <sup>{val?.ThapThan3?.sinhKhac}</sup>

                          {val?.TangCan[2]?.name}
                        </div>
                        <div>
                          <div>{val?.TangCan[2]?.nguHanh}</div>
                        </div>
                      </td>
                    ) : (
                      <td
                        onClick={() => {
                          setHourShow(true);
                          setMonthYear({
                            year: val.year,
                            month: val.month,
                            day: val.date,
                            thienCan: val.thienCan,
                          });
                        }}
                        id={
                          dungThan.includes(
                            val?.TangCan[0]?.nguHanh.split(" ")[1]
                          ) && checkDungThan
                            ? "dungThan"
                            : val?.year <= new Date().getFullYear() &&
                              val?.year > new Date().getFullYear() - 10
                            ? "none2_bg"
                            : "none2"
                        }
                        style={{
                          border: "none",
                          padding: "0 15px",
                        }}
                      >
                        <div>
                          <sup>{val?.ThapThan3?.sinhKhac}</sup>

                          {val?.TangCan[2]?.name}
                        </div>
                        <div>
                          <div>{val?.TangCan[2]?.nguHanh}</div>
                        </div>
                      </td>
                    )}
                  </td>
                );
              })}

              <th id="bg__lnntcc" className="bg__ clr__white fz__15 fw__b">
                Tàng Can
              </th>
            </tr>
          </tbody>
        </Table>
      </div>
      <ReactTooltip
        id="con-giap"
        getContent={(dataTip) => (
          <div>
            <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
            <p style={{ color: "blue" }}>
              Xin vui lòng{" "}
              <span
                className="click-span"
                onClick={() => {
                  if (dataTip === "Giáp") {
                    window.open(
                      "https://www.youtube.com/watch?v=N9E8dt6mtHc&t=31s"
                    );
                  } else if (dataTip === "Ất") {
                    window.open("https://www.youtube.com/watch?v=phqBCvqlLVk");
                  } else if (dataTip === "Bính") {
                    window.open("https://www.youtube.com/watch?v=MWg_sgJxcto");
                  } else if (dataTip === "Đinh") {
                    window.open("https://www.youtube.com/watch?v=-QB6i5bWwxE");
                  } else if (dataTip === "Mậu") {
                    window.open("https://www.youtube.com/watch?v=qs5jw6rGNBI");
                  } else if (dataTip === "Kỷ") {
                    window.open("https://www.youtube.com/watch?v=7Q3ivslkQk4");
                  } else if (dataTip === "Canh") {
                    window.open("https://www.youtube.com/watch?v=cNZGmL6mpAI");
                  } else if (dataTip === "Tân") {
                    window.open("https://www.youtube.com/watch?v=xR1EIu_J6oo");
                  } else if (dataTip === "Nhâm") {
                    window.open("https://www.youtube.com/watch?v=SUdNG6Zuoow");
                  } else if (dataTip === "Quý") {
                    window.open(
                      "https://www.youtube.com/watch?v=thOn-UpGob8&t=8s"
                    );
                  }
                }}
              >
                Click
              </span>{" "}
              vào đây để tìm hiểu thêm
            </p>
          </div>
        )}
        effect="solid"
        delayHide={500}
        delayShow={500}
        delayUpdate={500}
        place="top"
        border={true}
        type="light"
      />
      {hourShow && checkDungThan && (
        <TableHour
          year={dataMonthYear.year}
          month={dataMonthYear.month}
          day={dataMonthYear.day}
          thienCanNgay={dataMonthYear.thienCan}
          dungThan={dungThan}
          checkDungThan={checkDungThan}
        />
      )}

      <ReactTooltip
        id="thien-can"
        getContent={(dataTip) => (
          <div>
            <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
            <p style={{ color: "blue" }}>
              Xin vui lòng
              <span
                className="click-span"
                onClick={() => {
                  window.open(
                    "https://www.phongthuytuongminh.com/thien-can-dia-chi-la-gi-thien-can-dia-chi-co-y-nghia-quan-trong-nhu-the-nao.html"
                  );
                }}
              >
                {" "}
                Click{" "}
              </span>
              vào đây để tìm hiểu thêm
            </p>
          </div>
        )}
        effect="solid"
        delayHide={500}
        delayShow={500}
        delayUpdate={500}
        place="top"
        border={true}
        type="light"
      />
      <ReactTooltip
        id="dia-chi"
        getContent={(dataTip) => (
          <div>
            <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
            <p style={{ color: "blue" }}>
              Xin vui lòng
              <span
                className="click-span"
                onClick={() => {
                  window.open(
                    "https://www.phongthuytuongminh.com/vi/thien-can-dia-chi-la-gi-thien-can-dia-chi-co-y-nghia-quan-trong-nhu-the-nao.html"
                  );
                }}
              >
                {" "}
                Click{" "}
              </span>
              vào đây để tìm hiểu thêm
            </p>
          </div>
        )}
        effect="solid"
        delayHide={500}
        delayShow={500}
        delayUpdate={500}
        place="top"
        border={true}
        type="light"
      />
    </>
  );
};

export default TableDay;
