import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import Modal from "react-bootstrap/Modal";

import { BsList } from "react-icons/bs";
import "../scss/thansat.scss";
import logo from "../assets/logo.png";
import { ThanSatList } from "./renderThanSat";
import Editor from "./Editor";

const ModalPopup = (props) => {
  const { className, open, close, save, dataEdit_ } = props;

  const [dataEditor, setDataEditor] = useState("");

  useEffect(() => {
    setDataEditor(dataEdit_);
  }, [dataEdit_]);

  return (
    <div className="test">
      <Modal
        onHide={() => close(false)}
        size="md"
        show={open}
        className={className}
      >
        <Modal.Header
          style={{
            background: "#1B61A6",
            color: "#fff",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <b>GHI CHÚ</b>
        </Modal.Header>
        <Modal.Body>
          <Editor
            value={(data) => setDataEditor(data)}
            dataContent={dataEditor}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            color="primary"
            onClick={() => {
              sessionStorage.setItem("note", JSON.stringify(dataEditor));
              save(false, dataEditor);
            }}
          >
            Lưu
          </Button>
          <Button color="secondary" onClick={() => close(false)}>
            Hủy
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ModalPopup;
