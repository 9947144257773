import { configureStore } from '@reduxjs/toolkit'
import userReducer from './Slice/UserSlice'
import dataReducer from './Slice/SaveDataSlice'


const rootReducer = {
    user: userReducer,
    data: dataReducer
}

const store = configureStore({
    reducer: rootReducer,
});

export default store;