export const NhamThangTi = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        className="p1"
        style={{
          margin: "6px 0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          <strong>Ngày sinh Nhâm trong tháng Tý</strong>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Khái Quát:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Tý tàng chứa Quý Thủy là nguồn Thủy.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nói chung thì hình thành cục Dương Nhẫn.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Dụng Thần Điều Hậu:
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì dụng thần điều hậu là Mậu Thổ
          và Bính Hỏa.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Vì Thủy khí rất vượng trong tháng Tý, Mậu Thổ cần thiết để kiểm soát
          Thủy. Nói chung thì Bính Hỏa là cân thiết để điều hậu khí trong lá số
          Bát Tự.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Bính Hỏa và Mậu Thổ có vai trò dụng thần tương ứng trong lá số.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Luận Giải:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Trong tháng này khi cả Kim và Thủy đều rất lạnh. Do đó thì Tỷ Kiếp cần
          để tạo liên kết đặc biệt với ngày sinh này và giúp cho ngày sinh Nhâm
          Thủy rất vượng và hình thành nên cách cục đặc biệt.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Trong những tình huống bình thường thì Hỏa vẫn hiện diện, và cũng cần
          có Mộc để tiếp tục sản sinh tạo ra Hỏa, ngày sinh Nhâm Thủy sẽ trở nên
          hữu tình và cân bằng. Cuộc đời đầy hạnh phúc và thịnh vượng.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Mộc, Hỏa và Thổ nên thông căn và định vị hợp lý trong lá số Bát Tự.
          Chỉ khi đó thì người này sẽ hưởng thành công và hạnh phúc.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nói chung thì nên tránh Kim và Thủy xuất hiện cùng lúc nếu được, vì sẽ
          làm suy yếu Mộc, Hỏa và Thổ.{" "}
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Lưu Ý:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Bính Hỏa là Dụng Thần ưu tiên, với Mậu Thổ là dụng thần thứ hai.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Có 2 quy luật cần tuân theo, trong việc quyết định Dụng Thần của
            tháng mùa đông. Dụng Thần Điều Hậu sẽ cần hơn là Phù Ức Dụng Thần.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Địa Chi tạo thành Thủy Cục, thì Mậu Thổ nên được chọn làm Dụng
            Thần chính.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi 2 Đinh Hỏa đấu nhau để hợp với ngày sinh Nhâm ở Thiên Can thì
            ngày sinh Nhâm chỉ có cuộc đời trung bình.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Cách Cục:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Thiên Tài và Thất Sát:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Bính Hỏa và Mậu Thổ cùng xuất hiện trong lá số thì ngày sinh Nhâm
          có kỹ năng và kiến thức khó lòng so sánh được.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Mậu Thổ xuất hiện nhưng không kèm Bính Hỏa thì ngày sinh Nhâm Thủy
          sẽ có kỹ năng quản trị tốt, và do đó có cách làm việc hệ thống.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Bính Hỏa xuất hiện nhưng không có Mậu Thổ thì ngày sinh này sẽ sở
          hữu nhiều ý tưởng trong đời, mặc dù họ có thể không thành công tương
          ứng với tri thức của mình.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu có Mậu Thổ kiểm soát quá nhiều Thủy.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu có quá nhiều hành Thủy.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>
                Nếu lá số có Tỷ Kiếp:
                <span className="Apple-converted-space">&nbsp;</span>
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Địa Chi hình thành Thủy nhiều biến lá số này thành cục Dương Nhẫn
          thì Mậu Thổ Thất Sát sẽ vô dụng. Thay vào đó, Bính Hỏa Tài Tinh nên
          được sử dụng.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu Mậu Thổ và Bính Hỏa đều cùng sử dụng được.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi chỉ có Mậu Thổ Thất Sát sử dụng thì ngày sinh Nhâm Thủy sẽ chỉ
          sống cuộc đời trung bình.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Thiên Tài và Thất Sát:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Địa Chi hình thành Hỏa Cục thì có thể tạo tah2nh Tài Cục và người
          này sẽ thịnh vượng và giàu có trong đời.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu có Mộc sinh ra Hỏa.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu thiếu hành Kim.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Tóm Tắt:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Bính Hỏa và Mậu Thổ là dụng thần ưu tiên.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi có 2 Đinh Hỏa cùng tranh hợp với ngày sinh Nhâm Thủy ở Thiên Can
            thì người này khó có được danh tiếng và may mắn trong đời. Đối với
            nữ giới thì biểu thị rắc rối về tình cảm.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Lá số không có Bính Hỏa thì không thể cảm thấy hạnh phúc.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
    </div>
  );
};

export const NhamThangSuu = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        className="p1"
        style={{
          margin: "6px 0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          <strong>Ngày sinh Nhâm trong tháng Sửu</strong>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Khái Quát:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì nếu có Kỷ Thổ hiện lên ở Thiên
          Can thì xem là hình thành cục Chính Quan.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Tân Kim hiện lên ở Thiên Can thì hình thành cục Chính Ấn.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Quý Thủy hiện lên ở Thiên Can thì hình thành cục Dương Nhẫn.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng
          có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy
          ý.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Dụng Thần Điều Hậu:
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì dụng thần điều hậu là Bính Hỏa,
          Đinh Hỏa và Giáp Mộc.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Trong nửa đầu tháng Sửu thì Bính Hỏa đóng vai trò là Dụng Thần. Trong
          nửa sau tháng Sửu thì Giáp đóng vai trò dụng thần trung gian trong khi
          Bính Hỏa cũng vẫn được ưu tiên.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Luận Giải:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Sửu chứa Kim khí vượng.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Thổ, Thủy và Kim tạo thành không khí lạnh lẽo băng giá nên cần có Hỏa
          sưởi ấm những hành này.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Nhâm Thủy sống động hay được phép tuôn chảy thì khí trong lá số
          Bát Tự sẽ lành mạnh và cân bằng. Người này sẽ có cuộc sống may mắn.
          Sức khỏe tốt đặc biệt là vê tim mạch.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Vì Mộc là nguồn sinh ra Hỏa nên sẽ không thiếu trong lá số Bát Tự. Bởi
          vì cả Mộc và Hỏa là dụng thần cho ngày sinh Nhâm Thủy trong tháng Sửu.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Tháng Sửu chứa Kim ở tàng can. Gốc của Mộc do đó bị yếu và vì Mộc Khí
          cũng yếu trong tháng này. Do đó, chỉ có Mộc ấm áp có thể sinh ra Hỏa.
          Nên tránh Kim và Thủy nếu được để giữ cho lá số không băng giá.{" "}
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Lưu Ý:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khí sẽ lạnh giá trong tháng này. Do đó Bính Hỏa cần để điều hậu các
            ngũ hành trong lá số Bát Tự. Nếu không có Bính Hỏa thì lá số này
            không vượng.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Địa Chi tạo thành Kim Cục và thiếu Bính Hỏa và Đinh Hỏa ở Thiên
            Can thì ngày sinh này khó thành công trong sự nghiệp. Trên thực tế,
            người này sẽ là người thiếu sức sống.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi có đủ 4 địa chi Mộ Khố, Giáp Mộc có thể đóng vai trò dụng thần.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Cách Cục:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Thiên Tài và Chính Ấn:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Bính Hỏa và Tân Kim cùng xuất hiện tách xa nhau trong lá số thì
          ngày sinh này sẽ thành công.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu Bính Hỏa và Tân Kim không ở cạnh nhau.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu Bính Hỏa không có trong lá số, ngày sinh Nhâm sẽ lạnh
          giá.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>
                Nếu lá số có Thiên Ấn và Chính Ấn:
                <span className="Apple-converted-space">&nbsp;</span>
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Địa Chi tạo thành Kim nhưng thiếu Bính Hỏa và Đinh Hỏa trong lá số
          thì bất kỳ cách cục nào cũng sẽ kém cỏi.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu Bính Hỏa và Đinh Hỏa xuất hiện ở Thiên Can.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu gặp thêm Quý Thủy.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>
                Nếu lá số có Chính Tài:
                <span className="Apple-converted-space">&nbsp;</span>
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Ngay cả dù cho Đinh Hỏa và Nhâm Thủy hợp với nhau thì ngày sinh này
          vẫn sẽ thành công miễn là không có Quý Thủy xuất hiện để dập tắt Đinh
          Hỏa.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Hành Mộc cần để giữ Đinh Hỏa liên tục cháy.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu Quý Thủy xuất hiện ở Thiên Can.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Tóm Tắt:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Ngày sinh Nhâm trong tháng Sửu tạo thành Ấn Cục, nếu thiếu Đinh Hỏa
            và Bính Hỏa trong lá số thì người này có thể vất vả trong việc đạt
            thành công trong đời.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu thiếu Bính Hỏa thì bất kỳ cục nào cũng sẽ kém.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu Đinh Hỏa là dụng thần thì Quý Thủy không nên ở bên cạnh.{" "}
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
    </div>
  );
};

export const NhamThangDan = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        className="p1"
        style={{
          margin: "6px 0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          <strong>Ngày sinh Nhâm trong tháng Dần</strong>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Khái Quát:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì nếu có Giáp Mộc hiện lên ở
          Thiên Can thì xem là hình thành cục Thực Thần.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Bính Hỏa hiện lên ở Thiên Can thì hình thành cục Thiên Tài.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Mậu Thổ hiện lên ở Thiên Can thì hình thành cục Thất Sát.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng
          có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy
          ý.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Dụng Thần Điều Hậu:
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì dụng thần điều hậu là Canh Kim,
          Bính Hỏa và Mậu Thổ.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi không có Tỷ Kiếp thì không cần dùng Mậu Thổ như là dụng thần.
          Trong hoàn cảnh đó thì chỉ cần tập trung vào Canh Kim và Bính Hỏa.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi có Tỷ Kiếp xuất hiện nhiều thì cần có ít nhất một Mậu Thổ ở Thiên
          Can để dễ dàng kiểm soát toàn bộ lá số Bát Tự.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Luận Giải:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Ngày Nhâm Thủy sinh trong tháng Dần có Tài Cục và Quan Cục. Tương ứng
          thì Kim và Thủy sẽ yếu.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Mộc Khí vượng thì Kim Khí yếu. Và Khi Kím yếu yếu thì Thủy khí
          cũng yếu dẫn đến ngày sinh này yếu. Vì Kim vốn sinh ra Thủy nên Kim
          được xem là dụng thần.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Hỏa và Thổ không nên vượng vì Hỏa Khí vượng sẽ làm suy yếu Kim Khí.
          Tương tự, Thổ khí vượng sẽ ngăn cản Thủy Khí vươn lên.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Nhâm Thủy sinh trong giờ Thìn có Hỏa Và Thổ mạnh có thể dễ dàng
          tạo thành Hỏa Cục với Địa Chi Dần và Ngọ. Trong hoàn cảnh đó thì Kim
          Khí mạnh sẽ cần để đem đến sự cân bằng cho lá số Bát Tự.{" "}
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Lưu Ý:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Bính Hỏa và Mậu Thổ là dụng thần của ngày sinh Nhâm Thủy.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Giáp Mộc không nên xuất hiện trên Thiên Can.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Vào nửa đầu mùa xuân thì ngày Nhâm Thủy sẽ lạnh giá. Do đó, Nhâm
            Thủy nên chảy trong tháng Dần. Giáp Mộc mạnh trong tháng Dần. Do đó
            sẽ tốt hơn nếu Giáp Mộc không lộ ra vì sẽ khắc chế Mậu Thổ vốn là
            một trong những dụng thần của Nhâm Thủy.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Giáp Mộc, Bính Hỏa và Mậu Thổ lộ Thiên Can trong tháng Dần thì
            ngày sinh Nhâm Thủy sẽ cân bằng và lý tưởng. Lá số này sẽ thuộc về
            người thành công có cuộc đời cao quý.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Mậu Thổ lộ Thiên Can, thì ngày sinh Nhâm Thủy sẽ nổi tiếng.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Mậu Thổ xuất hiện ở Thiên Can thì ngày sinh Nhâm Thủy sẽ có
            nhiều kỹ năng sống giúp họ kiếm được nhiều tài lộc.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Bính Hỏa không nên xuất hiện trong lá số. Vì Bính Hỏa là Thiên Tài
            của ngày sinh Nhâm Thủy nên nếu thiếu thì người này sẽ thấy khó khăn
            trong việc tích lũy tài sản bất kể họ kiếm được bao nhiêu.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Địa Chi tạo thành Hỏa Cục thì ngày sinh này thiếu địa vị hay
            quyền lực trong đời. Trong thực tế, cuộc sống người này sẽ nhiều trở
            ngại và khó khăn.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi có 4 Nhâm Dần trong lá số thì ngày sinh này sẽ có cuộc sống phi
            thường nhưng thiếu địa vị và quyền lực thật sự.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Cách Cục:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Thiên Ấn, Thiên Tài và Thất Sát:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi có Canh Kim, Bính Hỏa và Mậu Thổ xuất hiện trong lá số Bát Tự thì
          ngày sinh Nhâm Thủy này sẽ hưởng tài lộc lớn.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Ngay cả nếu chỉ có 1 Canh Kim xuất hiện thì ngày sinh này cũng không
          thiếu danh tiếng và tài lộc.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu có Canh Kim, Bính Hỏa và Mậu Thổ xuất hiện ở Thiên Can.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu Đinh Hỏa làm suy yếu Canh Kim Ấn Tinh.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>
                Nếu lá số có Thất Sát:
                <span className="Apple-converted-space">&nbsp;</span>
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Chỉ nên sử dụng Thất Sát khi Tý tạo thành Dương Nhẫn. Nếu không thì
          không nên.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu Mậu Thổ thông căn.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu Kỷ Thổ cũng xuất hiện ở Thiên Can.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Thiên Tài và Chính Tài:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu Địa Chi tạo thành Hỏa Cục thì hình thành cục Chính Tài. Mặc dù
          Thủy và Hỏa khắc nhau trong việc giữ sự cân bằng trong lá số Bát Tự,
          ngày sinh Nhâm Thủy này vẫn sẽ thiếu danh tiếng và may mắn.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu Nhâm Thủy thông căn.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số không tốt nếu quá nhiều Thủy ở Địa Chi.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Tóm Tắt:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu có 4 Nhâm Dần thì đây là lá số của bậc đế vương đặc biệt.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi có hình thành cục Tòng Tài thì vẫn bị xem là cục kém. Ngày sinh
            Nhâm Thủy với cục Tòng Tài sẽ thường hứa hẹn vô nghĩa. Người này có
            bề ngoài đẹp nhưng không đi đến đâu.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Thất Sát Cục nên được dùng nếu như có Dương Nhẫn. Nếu có Thất Sát
            Cục thì nên cần có Chính Ấn. Khi có Thực Thần để kiểm soát Thất Sát
            thì ngày sinh Nhâm Thủy sẽ chiếm giữ quyền lực và danh tiếng. Lá số
            này sẽ thuộc về người có thành tích cao.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Sao Thiên Ấn sẽ là dụng thần của người này.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
    </div>
  );
};

export const NhamThangMao = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        className="p1"
        style={{
          margin: "6px 0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          <strong>Ngày sinh Nhâm trong tháng Mão</strong>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Khái Quát:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì hình thành cục Thương Quan dù
          có Ất Mộc lộ Thiên Can hay không.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Dụng Thần Điều Hậu:
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì dụng thần điều hậu là Mậu Thổ,
          Tân Kim và Canh Kim,
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nhâm Thủy vào mùa xuân nên dùng Canh Kim và Tân Kim vì là nguồn sinh
          Thủy.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Thủy xuất hiên nhiêu thì Mậu Thổ cần thiết để kiểm soát.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Luận Giải:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Tháng Mão thì Mộc vượng nên Nhâm Thủy yếu cũng như Kim rất yếu.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Vì Mộc Khí vượng trong mùa xuân, nên sẽ có hao tiết Thủy Khí. Ngoài
          ra, nhiều Hỏa Khí cũng sẽ kiểm soát Kim Khí. Thổ khí nên được tránh đi
          để cho Nhâm Thủy được chảy tự do.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nhâm Thủy trong tháng Mão không thích Hỏa, Thổ và Mộc Khí vì những
          hành này sẽ làm suy yếu Nhâm Thủy. Sẽ tốt nhất nếu sử dụng Kim và
          Thủy, vì cả hai sẽ tăng cường giúp biến Nhâm Thủy yếu thành mạnh.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Lưu Ý:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Mậu Thổ và Tân Kim có thể xem là Dụng Thần.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu được thì nên tránh Bính Hỏa và Đinh Hỏa.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Giáp Mộc và Đinh Hỏa không xuất hiện ở Thiên Can thì ngày sinh
            này chỉ sống cuộc đời trung bình.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Địa Chi tạo thành Mộc Cục và Canh Kim xuất hiện ở Thiên Can thì
            ngày sinh này sẽ có kết quả bất ngờ và thành công bất ngờ.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Địa Chi tạo thành Thủy Cục và Thủy Khí xuất hiện ở Thiên Can thì
            Mậu Thổ cần xuất hiện ở Thiên Can. Nếu không thì ngày sinh Nhâm Thủy
            có thể bất an và khó khăn.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Mậu Thổ đem đến sự cân bằng, bình tĩnh và định hướng cho ngày sinh
            Nhâm Thủy.
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Cách Cục:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Thất Sát và Chính Ấn:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Mậu Thổ và Tân Kim xuất hiện trong lá số Bát Tự thì ngày sinh Nhâm
          Thủy sẽ hưởng thành công về mặt sự nghiệp.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu có cả Mậu Thổ và Tân Kim ở Thiên Can.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu có Đinh Hỏa ở Thiên Can và khắc Tân Kim cũng như hợp với
          Nhâm Thủy.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>
                Nếu lá số có Thực Thần và Thương Quan:
                <span className="Apple-converted-space">&nbsp;</span>
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu Địa Chi hình thành Mộc Cục cùng với Canh Kim Ấn thì sẽ giúp người
          này có cuộc sống khá thành công.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu Canh Kim đóng vai trò dụng thần ở Thiên Can.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu thiếu Mậu Thổ ở Thiên Can.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Tỷ Kiên:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Địa Chi tạo thành Thủy Cục thì sẽ phụ thuộc vào sự hiện diện của
          Mậu Thổ như là Thất Sát để kiểm soát và đem đến sự cân bằng cho ngày
          sinh Nhâm Thủy.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu như Mậu Thổ thông căn và có ở Thiên Can.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu thiếu Mậu Thổ.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Tóm Tắt:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Mậu Thổ và Tân Kim là dụng thần phù hợp.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Ngay cả nếu Thủy vượng trong lá số thì cũng không đủ vì Mộc mạnh
            trong mùa này. Thủy cần Mậu Thổ để tạo dòng chảy. Do đó, sẽ cần phụ
            thuộc nhiều vào khả năng Mậu Thổ Thất Sát để xuyên qua Thiên Can như
            là dụng thần.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Trong tháng Mão thì ngay cả nếu Giáp Mộc và Đinh Hỏa không xuất hiện
            ở Thiên Can thì ngày sinh Nhâm Thủy cũng sẽ có thể sống cuộc sống
            trung bình, cân bằng.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Trong tháng Mão thì ngay cả nếu không dùng Đinh Hỏa thì Thất Sát hay
            Chính Ấn sẽ cần dùng để cho phép Nhâm Thủy thịnh vượng.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
    </div>
  );
};

export const NhamThangThin = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        className="p1"
        style={{
          margin: "6px 0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          <strong>Ngày sinh Nhâm trong tháng Thìn</strong>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Khái Quát:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì nếu có Mậu Thổ hiện lên ở Thiên
          Can thì xem là hình thành cục Thất Sát.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Ất Mộc hiện lên ở Thiên Can thì hình thành cục Thương Quan.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Quý Thủy hiện lên ở Thiên Can thì hình thành cục Dương Nhẫn.
          <span className="Apple-converted-space">
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          </span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng
          có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy
          ý.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Dụng Thần Điều Hậu:
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì dụng thần điều hậu là Giáp Mộc
          và Canh Kim.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Giáp Mộc thiếu vào đầu tháng Thìn thì nên dùng Canh Kim như là Ấn
          phù hợp và là dụng thần.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Kim xuất hiện nhiều thì nên dùng Bính Hỏa để kiểm soát Kim.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Luận Giải:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Thìn là kho chứa nhiều Thủy khí. Ngoài ra Thổ khí còn đóng vai trò khí
          mạnh trong tháng Thìn.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Trong tháng Thìn thì Nhâm Thụy bị Thổ ngăn trở.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Với sự hiện diện của Mộc Khí để khắc Thổ Khí, thì tuy nhiên Kim Khí và
          Thủy Khí có thể xuất hiện cùng lúc để đem đến sự cân bằng cho ngày
          sinh này.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Tuy nhiên, ngày sinh Nhâm Thủy trong tháng Thìn sẽ cần tránh gặp nhiều
          Hỏa Khí. Bởi vì ngay cả nếu Hỏa Khí là Tài Tinh của ngày sinh Nhâm
          Thủy, nó có khả năng tạo ra Thổ Khí và làm suy yếu Kim Khí, vốn là
          dụng thần.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Miễn là có đủ Mộc Khí để kiểm soát nhiều Thổ Khí do Thìn mang lại thì
          ngày sinh Nhâm Thủy sẽ có cuộc sống cân bằng.{" "}
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Lưu Ý:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Giáp Mộc là dụng thần ưu tiên trong khi Canh Kim có thể dùng khi
            Giáp Mộc yếu hoặc không có.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Giáp Mộc và Canh Kim cùng xuất hiện trong lá số thì ngày sinh
            Nhâm Thủy hưởng danh tiếng, may mắn và tất cả những gì tốt đẹp nhất.
            Có mặt Giáp Mộc và Canh Kim thì kết quả rất tốt trong khi nếu vắng
            thì kết quả rất tệ.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu cả 4 Mộ Khố Thìn Tuất Sửu Mùi có mặt thì ngày sinh Nhâm thủy sẽ
            hưởng danh tiếng trong đời khi Giáp Mộc xuất hiện ở Thiên Can, ngay
            cả dù Canh Kim không có mặt trong lá số.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Đinh Hỏa hợp với Nhâm Thủy hình thành Mộc. Trong tình huống đó thì
            ngày sinh này sẽ có cuộc đời trung bình, nếu như hợp hóa Mộc thành
            công.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu Địa Chi tạo thành Mộc Cục thì Canh Kim phải xuất hiện ở Thiên
            Can.<span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Cách Cục:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Thực Thần và Thiên Ấn:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Canh Kim là dụng thần Ấn lý tưởng cho ngày sinh này. Nếu không có Giáp
          Mộc thì ngày sinh này sẽ sở hữu sự kiên trì mạnh mẽ và sống tình cảm.
          Nếu không có Canh Kim thì ngày sinh Nhâm Thủy sẽ sở hữu tính cách
          ngoan cố cứng đầu.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Mặc dù Giáp Mộc và Canh Kim có mặt trong lá số nhưng nên ở tách nhau
          hoặc ít nhất không bên cạnh nhau trên Thiên Can.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu thiếu Canh Kim trong lá số.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>
                Nếu lá số có Chính Tài:
                <span className="Apple-converted-space">&nbsp;</span>
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đinh Hỏa hợp với Nhâm Thủy tạo thành Mộc.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Ngày sinh Nhâm Thủy trong tháng Thìn sẽ có thể hợp hóa nếu gặp Đinh.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu Địa chi thuộc hành Thủy có mặt.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu không có hành Thủy trong lá số và ngày sinh Nhâm Thủy
          chỉ có cuộc sống trung bình.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Thất Sát:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu Thìn Tuất Sửu Mùi có đầy đủ thì cần ít nhất một Giáp Mộc ở Thiên
          Can thì ngày sinh này sẽ thịnh vượng trong đời.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu có hai hay nhiều Giáp Mộc.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu Kỷ thổ xuất hiện ở Thiên Can và hợp với Giáp Mộc để sinh
          ra Thổ.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Tóm Tắt:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Cần lưu ý rằng Nhâm Thủy và Đinh Hỏa nên hợp và hóa thành Mộc trong
            tháng này. Hợp hóa thành công sẽ báo hiệu cuộc sống sang quý.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Mậu Thổ Thất Sát không nên xuất hiện ở Thiên Can. Miễn là có nhiều
            Thìn Tuất Sửu Mùi trong lá số thì ngày sinh này sẽ cân bằng. Nhưng
            để lá số này vượng thì Giáp Mộc cần phải xuất hiện ở Thiên Can. Khi
            Giáp Mộc xuất hiện ở lá số Bát Tự thì người này sẽ có cuộc sống phi
            thường, Giáp Mộc sẽ giữ cân bằng cho lá số.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu có 4 Thìn trong lá số thì còn gọi là Nhâm Ký Long Bối, trong đó
            Giáp Mộc ở Thiên Can thì lá số này được xem là hàng đầu trong Thất
            Sát Cục. Cách cục này sẽ có lá số cực kỳ đặc biệt phi thường về danh
            tiếng và may mắn.
          </span>
        </li>
      </ul>
    </div>
  );
};

export const NhamThangTy = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        className="p1"
        style={{
          margin: "6px 0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          <strong>Ngày sinh Nhâm trong tháng Tỵ</strong>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Khái Quát:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì nếu có Bính Hỏa hiện lên ở
          Thiên Can thì xem là hình thành cục Thiên Tài.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Mậu Thổ hiện lên ở Thiên Can thì hình thành cục Thất Sát.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Canh Kim hiện lên ở Thiên Can thì hình thành cục Thiên Ấn.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng
          có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy
          ý.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Dụng Thần Điều Hậu:
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì dụng thần điều hậu là Nhâm
          Thủy, Tân Kim, Canh Kim và Quý Thủy là dụng thần.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Vì Nhâm Thủy yếu trong tháng Tỵ, Canh Kim và Tân Kim nên được chọn làm
          dụng thần. Với sự giúp đỡ của Canh Kim và Tân Kim thì cả Nhâm và Quý
          Thủy giúp hỗ trợ cho ngày sinh Nhâm.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Luận Giải:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Canh Kim ẩn tàng trong Tỵ. Hỏa Khí rất vượng trong mùa hè. Vì mạnh nên
          Hỏa Khí kiểm soát Kim Khí. Tương tự, ngày sinh Nhâm Thủy sinh trong
          tháng Tỵ sẽ yếu.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Vì Kim và Thủy sẽ có xu hướng yếu nên hành Kim đóng vai trò là dụng
          thần chính. Do đó, trong tháng Tỵ, thì Canh Kim cần phải dùng như là
          dụng thần cho ngày sinh Nhâm Thủy.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Nhâm Thủy có thể chảy “hàng nghìn dặm” thì ngày sinh này sẽ có thể
          kiểm soát Tài Khí. Tuy nhiên cần chú ý lá số này không nên gặp thêm
          Hỏa, Thổ và Mộc Khí. Điều này bởi vì Mộc khí sẽ sinh ra Hỏa Khí làm
          suy yếu đi Kim Khí.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Lưu Ý:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nhâm Thủy và Tân Kim là dụng thần lý tưởng.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Bính Hỏa nên tránh hợp hóa với Tân Kim hình thành Thủy vì cả hai đều
            là dụng thần.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Canh Kim và Tân Kim Ấn Tinh là dụng thần lý tưởng cho lá số vì sinh
            ra và củng cố ngày sinh Thủy.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nhâm Thủy và Quý Thủy Tỷ Kiếp cũng hỗ trợ và tăng cường cho ngày
            sinh Thủy.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Giáp Mộc và Ất Mộc xuất hiện trong lá số thì Canh Kim cần phải
            hiện diện ở Thiên Can và thông căn Địa Chi. Như vậy thì lá số này có
            thể tốt nhiều.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Đinh Hỏa thiếu trong lá số nhưng Nhâm Thủy và Quý Thủy hiện diện
            nhiều thì ngày sinh này sẽ có danh tiếng, tài lộc và hạnh phúc.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Địa Chi Tỵ Dậu Sửu hợp thành Kim Cục thì sẽ tốt hơn nếu gặp Mậu
            Thổ ở Thiên Can.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Thổ và Mộc không nên xuất hiện nhiều trong lá số vì có thể chèn ép
            Nhâm Thủy. Khi có nhiều Mộc và thổ thì ngày sinh này sẽ không hưởng
            danh tiếng và may mắn dài lâu cả đời. Cuộc sống toàn hứa hẹn suông.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi chỉ có Canh Kim lộ ở Thiên Can trong khi Nhâm và Quý không lộ
            Thiên Can thì ngày sinh này chỉ sống trung bình.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Hỏa Khí nhiều nhưng chỉ có Quý Thủy và thiếu một Nhâm Thủy, thì
            ngày sinh này sẽ hưởng tài lộc, nhưng chịu sức khỏe kém.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Cách Cục:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Thực Thần và Tỷ Kiên:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi có Thực Thần Cục kèm với sao Tỷ Kiên và Chính Ấn thì ngày sinh này
          sẽ hưởng danh tiếng.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Giáp Mộc xuất hiện ở Thiên Can và Nhâm Thủy ở Địa Chi là lá số tốt.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Giáp Mộc không xuất hiện ở Thiên Can thì ngày sinh này chỉ hưởng
          danh tiếng ngắn hạn.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>
                Nếu lá số có Chính Tài:
                <span className="Apple-converted-space">&nbsp;</span>
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Đinh Hỏa Chính Tài xuất hiện ở Thiên Can và hợp với ngày sinh Nhâm
          Thủy thì Quý Thủy Kiếp tài nên xuất hiện ở Thiên Can. Nếu không thì
          ngày sinh này sẽ không đạt được danh tiếng như họ mong muốn trong đời.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu như Ất Mộc xuất hiện ở Thiên Can.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu như Quý Thủy xuất hiện ở Thiên Can.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>
                Nếu lá số có Thiên Ấn và Chính Ấn:
                <span className="Apple-converted-space">&nbsp;</span>
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Địa Chi gặp Kim Cục thì ngày sinh không nên gặp Giáp Mộc cùng lúc.
          Nếu không thì ngày sinh Nhâm Thủy sẽ dễ có sức khỏe kém và bệnh tật.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu Bính Hỏa và Mậu Thổ là Chính Tài và Thất Sát Dụng Thần.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Giáp Mộc tọa trên địa chi Dần thì ngày sinh Nhâm Thủy này sẽ thiếu
          danh tiếng và may mắn trong đời
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Tóm Tắt:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nhâm Thủy và Tân Kim là dụng thần lý tưởng.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Địa Chi tạo thành Thủy Cục thì ngày sinh này hưởng thịnh vượng.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Quý Thủy xuất hiện nhưng thiếu thêm Nhâm Thủy thì ngày sinh này
            sẽ gặp nhiều bệnh tật nguy hiểm đến tính mạng.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Canh Kim tạo thành Thiên Ấn Cục thì thiếu vắng Tỷ Kiếp Tinh có
            thể khiến lá số này chỉ trung bình.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Địa Chi tạo thành Kim Cục, thì Bính Hỏa và Mậu Thổ tàng can ở Tỵ
            nên được dùng sẽ tạo ra ngày sinh Nhâm thủy thịnh vượng.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
    </div>
  );
};

export const NhamThangNgo = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        className="p1"
        style={{
          margin: "6px 0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          <strong>Ngày sinh Nhâm trong tháng Ngọ</strong>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Khái Quát:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì nếu có Đinh Hỏa hiện lên ở
          Thiên Can thì xem là hình thành cục Chính Tài.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Kỷ Thổ hiện lên ở Thiên Can thì hình thành cục Chính Quan.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng
          có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy
          ý.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Dụng Thần Điều Hậu:
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì dụng thần điều hậu là Quý Thủy,
          Canh Kim và Tân Kim.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu được thì Canh Kim nên là dụng thần lý tưởng trong khi Quý Thủy là
          lựa chọn thứ hai.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu thiếu Canh Kim thì Tân Kim có thể dùng thay thế.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Luận Giải:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Ngày sinh Nhâm Thủy tương đối yếu. Điều này bởi vì Thổ khô trong giữa
          tháng mùa hè và do đó Thủy cũng yếu.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Kim và Thủy thông căn ở Địa Chi hỗ trợ cho ngày sinh Nhâm Thủy trong
          tháng Ngọ. Mộc Khí cần nên tránh vì sẽ làm cho Hỏa Khí vượng thêm.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Do đó, cần tránh gặp thêm Hỏa và Thổ Khí.{" "}
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Lưu Ý:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Canh Kim và Nhâm Thủy là dụng thần ưu tiên.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Quý Thủy có thể được dùng để kiểm soát và ngăn Đinh Hỏa không trở
            nên quá mạnh.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Đinh Hỏa nên ngăn không hợp với Nhâm Thủy để hình thành Mộc. Mậu Thổ
            cần phải tránh không hiện diện nhiều trong lá số Bát Tự.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Canh Kim và Quý Thủy lộ ở Thiên Can thì ngày sinh Nhâm Thủy sẽ
            hưởng thành công trong đời.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Canh Kim hiện diện ở Thiên Can nhưng không có Quý Thủy thì ngày
            sinh này thiếu kỹ năng cần thiết để thành công, mặc dù họ có thể có
            cuộc sống trung bình.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Đinh Hỏa và Mậu Thổ xuất hiện ở Thiên Can và khí xuất hiện trong
            toàn bộ lá số Bát Tự thì ngày sinh Nhâm Thủy có thể vất vả mới đạt
            thành công trong đời. Người này sẽ tự mình gây ra trở ngại trong
            cuộc sống.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi gặp thêm Giáp Mộc và Ất Mộc để tạo thành Mộc Cục thì ngày sinh
            Nhâm Thủy sẽ tri thức, mặc dù họ có thể cô đơn.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Cách Cục:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Kiếp Tài và Thiên Ấn:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu Canh Kim và Quý Thủy xuất hiện trong lá số Bát Tự thì ngày sinh
          Nhâm Thủy sẽ hưởng danh tiếng và may mắn.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu Canh Kim xuất hiện ở Thiên Can.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu Đinh Hỏa xuất hiện ở Thiên Can.
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>
                Nếu lá số có Thiên Tài và Chính Tài:
                <span className="Apple-converted-space">&nbsp;</span>
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu Địa chi gặp Hỏa Khí tạo thành Chính Tài Cục và thiếu Canh Kim và
          Quý Thủy thì sẽ có thể hình thành cục Tòng Tài
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu Canh Kim và Quý Thủy xuất hiện ở Thiên Can.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu thiếu Kim và Thủy, ngày sinh này sẽ nghèo, bất kể gia đình họ giàu
          ra sao.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Tóm Tắt:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Mậu Thổ và Quý Thủy cần phải được bảo vệ do đó sẽ cần Nhâm Thủy là
            dụng thần số 1.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Đinh Hỏa và Nhâm Thủy cần phải tránh không hợp với nhau để hình
            thành Mộc. Do đó cần phải sử dụng trước tiên Quý Thủy nếu được như
            là dụng thần trong lúc sử dụng Nhân Thủy nếu không còn sự lựa chọn
            khác.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu thiếu Đinh Hỏa ở Thiên Can thì có thể dùng Tân Kim thay cho Canh
            Kim như là dụng thần.{" "}
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
    </div>
  );
};

export const NhamThangMui = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        className="p1"
        style={{
          margin: "6px 0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          <strong>Ngày sinh Nhâm trong tháng Mùi</strong>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Khái Quát:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì nếu có Kỷ Thổ hiện lên ở Thiên
          Can thì xem là hình thành cục Chính Quan.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Đinh Hỏa hiện lên ở Thiên Can thì hình thành cục Chính Tài.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Ất Mộc hiện lên ở Thiên Can thì hình thành cục Thương Quan.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng
          có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy
          ý.
        </span>
      </p>
      <p
        className="p4"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Dụng Thần Điều Hậu:
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì dụng thần điều hậu là Tân Kim
          và Giáp Mộc.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Tân Kim giúp tăng cường Thủy khí. Trong khi Giáp Mộc giúp cày xới và
          làm cho Thổ Khí mềm hơn.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p4"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Luận Giải:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Hỏa và Thổ vượng trong tháng này. Do đó, Mộc khí ẩn trong Địa Chi Mùi
          sẽ khiến hao tiết đi Thủy khí trong khi khiến cho Hỏa khí vượng hơn.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nhâm Thủy sẽ cần có Kim trợ giúp, để có thể phát triển hơn. Chỉ với sự
          trợ giúp của Kim và Thủy thì Nhâm Thủy mới có thể phát triển.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Dù cho Thổ khí vượng trong tháng Mùi thì Mộc vẫn không phù hợp để khắc
          chế kiểm soát Thổ. Bởi vì Mộc sinh ra Hỏa, khiến cho Thủy suy yếu hơn.{" "}
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Lưu Ý:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Tân Kim và Giáp Mộc là dụng thần cho ngày Nhâm.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Thổ Khí mạnh trong tháng Mùi. Do đó, Giáp Mộc nên được dùng để cày
            xới và làm cho Thổ Khí mềm và sử dụng được.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Sự hiện diện của Bính Hỏa khi có Chính Quan xuất hiện nhiều cũng như
            Thực Thần và Thương Quan, ngày sinh Nhâm Thủy có tính cách thẳng
            thắn, chân thật, mặc dù có thể có đời sống bình thường.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Canh Kim và thêm Nhâm Thủy xuất hiện ở Bát Tự thì ngày Nhâm Thủy
            sẽ có quyền lực, địa vị và tài lộc trong đời.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Canh Kim xuất hiện nhưng Nhâm Thủy không xuất hiện thêm thì ngày
            sinh này có tri thức và học vấn mặc dù cũng chỉ có đời sống trung
            bình.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Tân Kim và Giáp Mộc cùng xuất hiện, ngày sinh Nhâm Thủy biểu thị
            danh vọng và địa vị trong đời, mặc dù tài lộc có thể không tương
            xứng với địa vị.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Mộc và Thổ thống trị trong lá số, người này sẽ vật lộn để có thể
            đạt được thành tích mà họ mong muốn trong đời.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
      <p
        className="p4"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Cách Cục:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Chính Ấn và Thực Thần:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu Giáp Mộc và Tân Kim cùng xuất hiện trong lá số Bát Tự thì ngày
          sinh Nhâm Thủy sẽ thịnh vượng, thịnh vượng tiến bộ.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi có ít nhất một Tỷ Kiếp là tàng can ở Địa cHi thì lá số tốt.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu Đinh Hỏa xuất hiện ở Thiên Can.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>
                Nếu lá số có Chính Quan:
                <span className="Apple-converted-space">&nbsp;</span>
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu thiếu Giáp Mộc và Ất Mộc thì có thể có tính cách ẩn của ngày sinh
          này vốn người khác không thể hiểu. Người này có thể khá bí ẩn.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Tương tự, ngày sinh này có thể phải vật lộn cả đời, chỉ để kiếm sống
          vừa đủ ăn.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu thiếu Giáp Mộc ở bên cạnh Kỷ Thổ.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu Giáp Mộc và Ất Mộc không xuất hiện ở Thiên Can.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Thiên Ấn:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Chỉ có Thiên Ấn hay Tỷ Kên xuất hiện ở Thiên Can. Trong tình huống đó
          thì ngày sinh Nhâm Thủy sẽ có thể có kỹ năng và quyền lực giúp tiến bộ
          trogn đời.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu có Canh Kim và Nhâm Thủy xuất hiện ở Thiên Can.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Bính Hỏa là Thiên Tài làm suy yếu Canh Kim. Trong hoàn cảnh đó
          thì ngay sinh Nhâm Thủy sẽ không sở hữu động lực tạo ra tài lộc đúng
          với tài năng tốt nhất của người này.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p4"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Tóm Tắt:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Ngày sinh Nhâm Thủy trong tháng Mùi sẽ cảm thấy khó phù hợp với cục
            Chính Quan hay Thất Sát. Ngay cả nếu Giáp Mộc và Ất Mộc xuất hiện ở
            Thiên Can thì sẽ kiểm soát Kỷ Thổ. Vì lẽ đó, ngày sinh Nhâm Thủy sẽ
            vất vả cả đời và khó kiếm đủ ăn.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Sao Chính Ấn là dụng thần lý tưởng mà khí này sẽ được củng cố, bảo
            vệ cho ngày sinh này thêm.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
    </div>
  );
};

export const NhamThangThan = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        className="p1"
        style={{
          margin: "6px 0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          <strong>Ngày sinh Nhâm trong tháng Thân</strong>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Khái Quát:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì nếu có Canh Kim hiện lên ở
          Thiên Can thì xem là hình thành cục Thiên Ấn.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Mậu Thổ hiện lên ở Thiên Can thì hình thành cục Thất Sát.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Nhâm Thủy khác hiện lên ở Thiên Can thì hình thành cục Vượng.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng
          có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy
          ý.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Dụng Thần Điều Hậu:
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì dụng thần điều hậu là Mậu Thổ
          và Đinh Hỏa.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đinh Hỏa dùng để hỗ trợ Mậu Thổ trong việc sinh Canh Kim.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Mậu Thổ nên được thông căn ở Thìn hay Tuất với Đinh Hỏa thông căn ở
          Ngọ hay Tuất thì mới có thể phát huy tác dụng Dụng Thần.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Luận Giải:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi vào tháng Thân thì Kim khí sẽ vượng trong khi Thủy Khí sẽ mạnh. Vì
          là một trong Tứ Trường Sinh nên Thân chứa Nhâm Thủy ở tàng can, giúp
          tăng cường sức mạnh cho ngày sinh Nhâm Thủy.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi ngũ hành ngày sinh mạnh thì Hỏa sẽ được chọn là Tài Tinh cần
          thiết.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Hỏa và Thổ Khí vượng trong lá số Bát Tự, ngày sinh này sẽ có quyền
          lực.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Mộc và Hỏa Khí vượng trong lá số Bát tự thì ngày sinh này sẽ thịnh
          vượng và trở nên giàu có trong đời.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Ngoài ra, ngày sinh này không nên gặp thêm Kim và Thủy khí bởi vì Kim
          và Thủy khí sẽ làm hại Tài Tinh và Quan Tinh của người này.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Lưu Ý:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Tàng can Mậu Thổ trong Thân được xem là đất rỗng. Do đó sẽ không
            giúp ích được gì nhiều cho ngày sinh Nhâm Thủy này.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Địa Chi Thân Tý Thìn tạo thành Thủy Cục, ngày sinh Mậu Thổ sẽ
            cần thiết để kiểm soát Thủy, để giúp cho ngày sinh này thịnh vượng
            trong đời.<span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu cùng lúc, Quý Thủy lại gặp Mậu Thổ thì ngày sinh Nhâm Thủy này
            sẽ có những suy nghĩ bệnh tật, hay thậm chí sở hữu xu hướng yếm thế.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi ngày sinh Nhâm Thủy gặp thêm hành Thủy cũng như nhiều Mậu Thổ
            Thất Sát thì khả năng của Mậu Thổ sẽ bị giới hạn trong việc kiểm
            soát Thủy. Ngày sinh Nhâm Thủy sẽ sở hữu thái độ thoải mái thậm chí
            là lười nhác trước mọi vấn đề.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Thủy và Mộc Khí thống trị trong lá số, người này sẽ có thể giàu
            có đến mức sở hữu nhiều bất động sản khắp nơi.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi lộ ra Canh Kim thì ngày sinh Nhâm Thủy sẽ khó tạo thành bất kỳ
            cách cục Bát Tự quan trọng nào. Do lẽ đó thì ngày sinh Nhâm Thủy chỉ
            sống cuộc đời trung bình.
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Cách Cục:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Thất Sát và Chính Tài:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Ngày sinh Nhâm Thủy sẽ có thành công vượt trội đặc biệt là trong tháng
          Mậu của năm Đinh.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt khi cả Mậu Thổ và Đinh Hỏa xuất hiện ở Thiên Can.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu có Quý Thủy, đặc biệt là Kiếp Tài hiện diện kèm với
          Chính Tài ở Thiên Can và hợp mất với Mậu Thổ.
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>
                Nếu lá số có Thất Sát:
                <span className="Apple-converted-space">&nbsp;</span>
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Chỉ có Mậu Thổ Thất Sát nên dùng khi có nhiều Nhâm Thủy trong lá số.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu thiếu Quý Thủy ở Thiên Can.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu có qua nhiều Giáp Mộc Thực Thần hiện ra trong lá số Bát
          Tự.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Thực Thần Cục:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Địa Chi tạo thành Mộc Cục và thiếu Canh Kim Thiên Ấn thì ngày sinh
          này sẽ thích nói nhiều nhưng thiếu thực chất.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu có Canh Kim Thiên Ấn xuất hiện ở Thiên Can bên cạnh Giáp
          Mộc Thực Thần.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu thiếu căn gốc của Thực Thần.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Tóm Tắt:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Ngày sinh Nhâm Thủy trong tháng Thân nên dùng Mậu Thổ như là Thất
            Sát khi có nhiều Thủy.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Can Mậu Thổ ẩn tàng trong Thân Địa Chi được xem là Thổ rỗng do đó
            nên chọn Mậu Thổ trong Thìn hay Tuất.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
    </div>
  );
};

export const NhamThangDau = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        className="p1"
        style={{
          margin: "6px 0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          <strong>Ngày sinh Nhâm trong tháng Dậu</strong>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Khái Quát:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu trong tháng Dậu thì khi có Tân Kim lộ hoặc không lộ ở Thiên Can
          cũng dễ hình thành cục Chính Ấn.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p4"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Dụng Thần Điều Hậu:
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với sinh trong tháng Dậu thì Giáp Mộc và Canh Kim là dụng thần
          điều hậu.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu thiếu Giáp Mộc thì Kim nên được sử dụng làm dụng thần và nguồn để
          lọc sạch Thủy. Sau đó dùng Canh Kim để giữ cho dòng nước chảy tốt.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p4"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Luận Giải:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Trong tháng này thì cả Kim khí và Thủy khí đều mạnh.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Sẽ có lợi nếu gặp được Chính Tài và Chính Quan thông căn.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Kim Khí vượng thì Mộc Khí sẽ bị suy yếu. Trong tháng này, ngày
          sinh Nhâm Thủy sẽ có cuộc đời tốt khi gặp nhiều Hỏa Khí, có thể hỗ trợ
          bởi Mộc. Cuộc sống nhiều hạnh phúc và may mắn. Sẽ tốt nếu không có
          thêm Kim và Thủy Khí trong lá số.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Nhâm Thủy thông căn thì cần sự trợ giúp của Mộc ấm đóng vai trò
          như Chính Tài hay Thiên Tài.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Lưu Ý:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Chỉ có Tân Kim mới nên dùng, khi cần thiết, để giúp kiểm soát Giáp
            Mộc mạnh.<span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Mậu Thổ và Kỷ Thổ nên tránh xuất hiện ở Thiên Can.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi có 3 Canh Kim hay Tân Kim thông căn ở Thiên Can thì người này sẽ
            có cuộc đời phi thường. Chỉ có Thủy tinh khiết nhất mới có năng lực
            làm sạch 3 Tân Kim. Đây là lá số rất đặc biệt.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Giáp Mộc xuất hiện ở Thiên Can, và được hỗ trợ bởi Kim và Thủy
            sạch sáng thì người này có danh tiếng và may mắn đáng kể. Đặc biệt
            khi Canh Kim và Mậu Thổ không xuất hiện ở Thiên Can. Để lá số này
            được xem là đặc biệt thì Hỏa Khí vừa phải được xem là cần thiết.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
      <p
        className="p4"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Cách Cục:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Thực Thần:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Giáp Mộc hiện ra ở Thiên Can của trụ giờ. Người này sẽ có cuộc đời
          suôn sẻ.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu Giáp Mộc hiện ra ở Thiên Can.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu CAnh Kim hiện ra ở Thiên Can.
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Thiên Ấn và Chính Ấn:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Chỉ có Thủy mới có khả năng làm sạch Tân Kim.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Thủy khí được xem là yếu khi Kim khí xuất hiện quá nhiều.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt khi Nhâm Thủy và Quý Thủy xuất hiện và có Hỏa Tinh thông căn
          giúp kiểm soát Kim.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu khi quá nhiều Kim.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Tỷ Kiếp:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Địa Chi gặp Tý Thủy thì hình thành cục Dương Nhẫn.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nên sử dụng Mậu Thổ là Dụng Thần.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu khi thấy Giáp Mộc hay Quý Thủy đứng cạnh Mậu Thổ.
        </span>
      </p>
      <p
        className="p4"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Tóm Tắt:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Cách cục Dương Nhẫn hình thành khi ngày sinh Nhâm Thủy trong tháng
            Dậu gặp Địa Chi Tý. Khi này thì Mậu Thổ được xem là Dụng Thần.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Giáp Mộc Thực Thần là dụng thần chính cho ngày sinh Nhâm Thủy này.
            Sự vắng mặt của Giáp Mộc sẽ ảnh hưởng đến cục khiến cho đây trở
            thành lá số kém.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi cả Kim và Thủy Cục hình thành thì Thổ cần thiết để giữ cân bằng
            cho lá số Bát Tự.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
      <div className="ddict_btn" style={{ top: "18px", left: "517.273px" }}>
        <img src="chrome-extension://bpggmmljdiliancllaapiggllnkbjocb/logo/48.png" />
      </div>
    </div>
  );
};

export const NhamThangTuat = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        className="p1"
        style={{
          margin: "6px 0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          <strong>Ngày sinh Nhâm trong tháng Tuất</strong>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Khái Quát:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì nếu có Mậu Thổ hiện lên ở Thiên
          Can thì xem là hình thành cục Thất Sát
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Đinh Hỏa hiện lên ở Thiên Can thì hình thành cục Chính Tài.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Tân Kim hiện lên ở Thiên Can thì hình thành cục Chính Ấn.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng
          có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy
          ý.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Dụng Thần Điều Hậu:
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì dụng thần điều hậu là Giáp Mộc
          và Bính Hỏa.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Giáp Mộc dùng để kiểm soát Mậu Thổ. Trong lúc đó thì Bính Hỏa đóng vai
          trò trung gian đảm bảo cân bằng giữa Giáp Mộc và Mậu Thổ trong lá số
          Bát Tự.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Luận Giải:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Thổ dày nặng trong tháng Tuất. Thổ sẽ càng dày hơn nếu Mậu Thổ xuất
          hiện ở Thiên Can.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Sức mạnh của Nhâm Thủy chủ yếu phụ thuộc vào sự tinh khiết của Kim
          khí.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Mộc xuất hiện với số lượng nhỏ, có thể được dùng vì Mộc Khí sẽ khá
          giòn và không ổn định trong tháng này.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nhiều Kim và Thủy khí trong lá số sẽ dẫn đến người này gặp trở ngại
          pháp lý liên tục và liên tục gặp kẻ tiểu nhân.{" "}
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Lưu Ý:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Giáp Mộc là dụng thần ưu tiên, nếu thiếu thì có thể dùng Bính Hỏa và
            Đinh Hỏa thay.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Kỷ Thổ nên tránh hợp với Giáp Mộc vì sẽ khiến Thổ được sinh ra.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Thổ Khí vượng trong tháng Tuất. Mặc dù hiện diện của Thủy sẽ hỗ trợ
            dòng chảy của ngày Nhâm Thủy, khả năng làm ô nhiễm Thổ sẽ khiến cho
            Thủy xuất hiện trong tháng Tuất không đúng thời điểm.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Giáp Mộc nên dùng để làm tơi Thổ, khiến cho hành này hữu dụng trong
            tháng Tuất.<span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi một trong bốn Địa Chi Thổ là Thìn Tuất Sửu Mùi xuất hiện thì
            chọn Giáp Mộc làm dụng thần. hành này sẽ giúp tạo sự cân bằng. Tuy
            nhiên, Giáp Mộc cần phải xuất hiện ở Thiên Can thì mới hiệu quả.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi thiếu Bính Hỏa thì ngày sinh Nhâm Thủy sẽ chỉ có cuộc đời trung
            bình.<span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Cách Cục:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Thiên Ấn:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi hình thành cục Thiên Ấn thì Đinh Hỏa phái xuất hiện trong lá số.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi thiếu Đinh Hỏa thì sẽ khó giữ cân bằng. Lá số sẽ là thảm hỏa nếu
          thiếu Đinh Hỏa.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu Đinh Hỏa xuất hiện ở Thiên Can.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu Giáp Mộc xuất hiện ở Thiên Can và không có Đinh Hỏa.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>
                Nếu lá số có Thực Thần:
                <span className="Apple-converted-space">&nbsp;</span>
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lý tưởng nhất là Giáp Mộc xuất hiện ở trụ giờ. Trụ giờ ngăn không có
          Giáp Mộc bị hãm hại bởi những nhân tố khác. Thực Thần được bảo vệ sẽ
          biểu thị khả năng sáng tạo và thông minh.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu Giáp Mộc xuất hiện ở Thiên Can Trụ Giờ.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu Kỷ Thổ hợp với Giáp Mộc. Toàn bộ cuộc đời mất đi cơ hội.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Tóm Tắt:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Vì Thổ Khí ở Tuất ngăn trở nên cần có Giáp Mộc để kiểm soát Thổ bất
            kể là Mậu Thổ có xuất hiện ở Thiên Can hay không.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Giáp Mộc không xuất hiện trong lá số thì ngày sinh Nhâm Thủy
            thiếu mục tiêu sống.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Lý tưởng là khi Đinh Hỏa xuất hiện và có thông căn. Lưu ý rằng Đinh
            Hỏa không nên xuất hiện ở chi Tháng hay chi Giờ. Điều này để ngăn
            cản kết hợp không mong muốn khiến cho Đinh Hỏa vô dụng.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Bính Hỏa cần được sử dụng như là Dụng Thần.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
    </div>
  );
};

export const NhamThangHoi = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        className="p1"
        style={{
          margin: "6px 0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          <strong>Ngày sinh Nhâm trong tháng Hợi</strong>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Khái Quát:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì nếu có Giáp Mộc hiện lên ở
          Thiên Can thì xem là hình thành cục Thực Thần.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Nhâm Thủy hiện lên ở Thiên Can thì hình thành cục Vượng.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng
          có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy
          ý.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Dụng Thần Điều Hậu:
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì dụng thần điều hậu là Mậu Thổ,
          Bính Hỏa và Canh Kim.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Giáp Mộc xuất hiện ở Thiên Can thì sự trợ giúp của Canh Kim sẽ cần
          thiết để chặt Mộc và sinh ra Hỏa, để sinh cho Thổ và đảm bảo lá số cân
          bằng.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Luận Giải:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Sẽ lý tưởng nếu có Hỏa và Thổ xuất hiện và được chọn như là Tài Tinh
          và Quan Tinh phù hợp.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khí của lá số này sẽ có xu hướng trở nên lạnh lẽo trong tháng này với
          kết quả là Thủy băng giá và Thổ yếu. Do đó, khi có đủ Hỏa sưởi ấm Thổ
          thì ngày sinh Nhâm Thủy sẽ có thể hưởng tài lộc, địa vị và quyền lực.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Mộc Khí ở Địa Chi có thể sinh Hỏa Khí có thể được dùng cho mục tiêu
          này. <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Lưu Ý:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Mậu Thổ là dụng thần lý tưởng, với Bính Hỏa nên được dùng làm dụng
            thần cần thiết để sinh ra và hỗ trợ Mậu Thổ.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Quý Thủy không nên xuất hiện ở Thiên Can.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Địa Chi Hợi, Mão và Mùi tạo thành Mộc Cục, thì sẽ là khí của
            Nhâm Thủy tiêu hao. Trong tình huống đó thì Canh Kim vốn là dụng
            thần sẽ được dùng để kiểm soát Mộc khí. Do đó, vai trò của dụng thần
            là ổn định cho ngày sinh bất ổn này. Do đó, Giáp Mộc không thể tách
            rời khỏi Canh Kim, vì Canh Kim cũng không thể tách rời khỏi Đinh
            Hỏa.<span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Tự hình giữa 2 Thìn trong khi thiếu Mậu Thổ ở Thiên Can có thể biểu
            thị người này sẽ dễ gặp tai nạn và tai họa cả đời.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Địa Chi hình thành Thủy Cục, và khi thiếu Mậu Thổ và Kỷ Thổ thì
            ngày sinh Nhâm Thủy sẽ vượng trong đại vận Kim và Thủy, nhưng sẽ
            nghèo trong đại vận Mộc và Hỏa. Điều này bởi vì nó sẽ hình thành
            Tòng Vượng Cách.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu thiếu Bính Hỏa trong lá số thì người này chỉ có cuộc sống trung
            bình.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Đinh Hỏa không đủ để sưởi ấm lá số.
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        <span
          className="Apple-converted-space"
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          &nbsp;
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Cách Cục:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Thất Sát và Thiên Ấn:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Mậu Thổ và Canh Kim cùng xuất hiện trong lá số nhưng không có dấu
          hiệu Giáp Mộc xuất hiện ở Thiên Can bên cạnh Canh, thì ngày sinh này
          sẽ có danh tiếng, may mắn và tuổi thọ.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu hành Hỏa xuất hiện trong lá số.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu Giáp Mộc xuất hiện ở Thiên Can bên cạnh Canh.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>
                Nếu lá số có Thực Thần và Thương Quan:
                <span className="Apple-converted-space">&nbsp;</span>
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Địa Chi tạo thành hành Mộc và thiếu Canh Kim ở Thiên Can thì ngày
          sinh Nhâm Thủy chỉ có cuộc đời trung bình.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu Canh Kim xuất hiện ở Thiên Can.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu thiếu Đinh Hỏa trong lá số.
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Tỷ Kiếp:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Địa Chi tạo thành Thủy cục thì Mậu Thổ sẽ cần để xuất hiện ở Thiên
          Can.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu thiếu đi Mậu Thổ sẽ khiến cho lá số kém cỏi.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu ngày sinh này bước vào đại vận Kim và Thủy thì cũng sẽ tốt (điều
          này chỉ ứng khi hình thành Vượng Tòng Cách).
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu ngày sinh này rơi vào vận Thổ.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Thất Sát:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Sẽ sử dụng Thất Sát Mậu Thổ nếu thiếu Bính Hỏa.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Ngay cả như vậy thì ngày sinh Nhâm Thủy sẽ khó khăn trong việc tích
          lũy tài lộc nếu thiếu Hỏa.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu bước vào đại vận Hỏa hay Thổ.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu hoàn toàn không có Hỏa.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Tóm Tắt:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu thiếu Mậu Thổ và Bính Hỏa thì lá số sẽ kém cỏi.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu Địa Chi tạo thành Thủy Cục thì cục Dương Nhẫn sẽ rất cần Mậu Thổ
            Thất Sát để kiểm soát Thủy là dụng thần.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Bính Hỏa là cần thiết cho lá số này thịnh vượng.
          </span>
        </li>
      </ul>
    </div>
  );
};
