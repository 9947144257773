export const AtThangTi = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh ẤT trong tháng Tý
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với ngày sinh Ất trong tháng Tý thì hình thành cục Thiên Ấn khi Quý
        Thủy hiện ra như là một trong những Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngay cả nếu như Quý Thủy không hiện ra ở Thiên Can thì vẫn có thể xem
        xét hình thành cục Thiên Ấn.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với tháng sinh Tý thì Bính Hỏa là dụng thần điều hậu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Bính Hỏa được dùng để cung cấp hơi ấm cho Mộc, vốn dĩ lạnh lẽo trong
        tháng mùa đông.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Không tốt nếu ngày sinh này gặp nhiều Quý Thủy.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Thủy và Mộc lạnh lẽo trong tháng này mùa đông. Do đó, những ngày sinh
        này sẽ không thể thiếu Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngoài ra, việc thiếu Mộc khô sẽ khiến cho Hỏa không thể bùng cháy và
        sinh ra.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Hỏa là quan trọng, bởi vì nó giúp cho Thổ nóng hơn và dày hơn; qua đó sẽ
        giúp cho Mộc có gốc rễ và ngăn không cho Mộc bị cuốn trôi dạt đi.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Do đó, Mộc, Hỏa và Thổ là dụng thần rất quan trọng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngày sinh này không nên gặp thêm Kim và Thủy Khí, vì hai hành này chỉ
        làm cho thời tiết môi trường thêm lạnh lẽo và qua đó làm suy yếu Hỏa.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Bính Hỏa là dụng thần duy nhất của ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Quý Thủy không nên xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Mậu Thổ có thể dùng để kiểm soát Quý Thủy, do đó là Dung Thần Thông
        Quan.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Thủy ở đỉnh cao trong tháng Tý. Do đó, Nhâm Thủy và Quý Thủy không nên
        xuất hiện ở Thiên Can.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Trong tháng này, Thủy không thể sinh ra Mộc bởi vì thời tiết lạnh giá.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Bính Hỏa không lộ ở Thiên Can, ngày sinh này sẽ thiếu cơ hội trong
        đời. Người này sẽ tài năng nhưng không tìm ra được cơ hội.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Sẽ rất có lợi cho ngày sinh này nếu Bính Hỏa ở Thiên Can và có căn gốc
        ở Địa Chi và không nên thấy Nhâm Thủy hay Quý Thủy ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Địa Chi hình thành Thủy Cục với Nhâm Thủy và Quý Thủy xuất hiện ở
        Thiên Can thì ngày sinh này sẽ cảm thấy khó khăn để đạt được địa vị
        trong đời ngay cả nếu Mậu Thổ có ở Thiên Can. Thành tích tốt nhất sẽ chỉ
        là trên trung bình. Nếu thiếu Mậu Thổ thì cuộc sống sẽ cô đơn và khổ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi có Bính Hỏa và Mậu Thổ đồng thời hiện lên ở Thiên Can và thông căn
        thì người này sẽ có cuộc đời nhiều bình an, rất thành công.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Đinh Hỏa không đủ để khắc chế lạnh giá của Tý. Nếu Bính Hỏa không lộ
        nhưng là Đinh Hỏa lộ và thông căn ở Địa Chi thì ngày sinh này có cuộc
        sống bình thường, thay vì địa vị cao.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Đinh Hỏa xuất hiện 3 lần ở Thiên Can trong khi thiếu Bính Hỏa thì
        ngày sinh này sẽ sở hữu tính cách thiếu chân thành.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi sử dụng 3 Đinh Hỏa để thay thế cho Bính Hỏa như là dụng thần thì
        cần có Giáp Mộc, Canh Kim. Bởi vì Canh Kim, Giáp Mộc có thể tạo ra Đinh
        Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu ngày sinh này muốn hưởng sự nghiệp tốt và tài lộc thì Giáp Mộc
        phải có mặt trong lá số.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu không có Giáp Mộc thì ngày sinh này sẽ chỉ là người thông thái,
        chăm học nhưng không có thành tích trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Địa Chi hình thành Thủy cục với Nhâm Thủy và Quý Thủy hiện diện ở
        Thiên Can và thiếu Mậu Thổ và Bính Hỏa thì người này sẽ nghèo khổ và có
        sức khỏe kém.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu lá số có cả Kỷ Thổ và Bính Hỏa hiện ở Thiên Can thì ngày sinh này
        có thịnh vượng. Nếu thiếu Kỷ Thổ thì sự hiện diện của một hay Bính Hỏa ở
        Thiên Can sẽ đủ để người này đạt thành công và danh tiếng (nếu không có
        Quý Thủy hiện ở Thiên Can). Rất tốt nếu như Bính Hỏa có căn gốc. Nếu Quý
        Thủy hiện ở Thiên Can thì xấu.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Cục Thực Thần:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Giáp Mộc cần xuất hiện nếu lá số sử dụng dụng thần Đinh Hỏa. Nếu không
        thì người này sẽ cô đơn và bệnh tật trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Giáp Mộc xuất hiện ở Thiên Can thì sẽ đóng vai trò để Ất Mộc đeo
        bám.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu không có Giáp Mộc thì ngày sinh này sẽ sở hữu tính cách vô lương
        tâm. Bởi vì Ất Mộc thiếu hỗ trợ của Giáp Mộc và do bản chất Âm thì sẽ có
        xu hướng “đong đưa”.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu có cục Chính Ấn:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Thủy xuất hiện nhiều thì Mậu Thổ được xem là Dụng Thần chính.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngoài ra thì ngày sinh này sẽ cô đơn, nghèo và có sức khỏe kém trong
        đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tài Tinh Thổ có thể dùng để kiểm soát Thủy. Luôn cần có Bính Hỏa để giữ
        cho lá số ấm áp.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Sẽ rất xấu nếu không có Mậu Thổ xuất hiện ở Thiên Can.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Không nên có Quý Thủy lộ Thiên Can. Nếu có thì người này sẽ cô đơn,
        thiếu định hướng trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Bính Hỏa quyết định chất lượng lá số này nếu có xuất hiện.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Mục đích của Kỷ Thổ được dùng để khắc chế và kiểm soát Quý Thủy. Nhưng
        tốt hơn là dùng Mậu Thổ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Đinh Hỏa Thực Thần được sử dụng làm Dụng Thần thì ngày sinh này sẽ
        hưởng cuộc sống trung bình, có thể kiếm được tiền. Tuy nhiên, sẽ không
        nên có quá nhiều Đinh Hỏa nếu không người này sẽ có bản chất không thiện
        tâm hoặc thiếu chân thật.
      </p>
    </div>
  );
};

export const AtThangSuu = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Ất Mộc trong tháng Sửu
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với ngày sinh Ất trong tháng Sửu thì hình thành cục Thiên Tài nếu Kỷ
        Thổ hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Tân Kim hiện ở Thiên Can thì hình thành Thất Sát Cục.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Quý Thủy hiện ở Thiên Can thì hình thành Thiên Ấn Cục.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tuy nhiên nếu không có Kỷ Thổ, Tân Kim hay Quý Thủy thì bạn đọc tùy chọn
        cục theo ngũ hành vượng nhất.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu sinh trong tháng Sửu thì Bính Hỏa là dụng thần điều hậu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Mặc dù sắp đến mùa Xuân nhưng thời tiết vẫn rất lạnh giá. Do đó Bính Hỏa
        sẽ cung cấp hơi ấm cho ngày sinh này.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Trong tháng Sửu thì Thổ ẩm ướt sẽ khiến cho Mộc bị úng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khó cho Ất Mộc phát triển nếu thiếu Hỏa ấm áp. Nhiều Mộc cần thiết để
        làm tơi đất và giúp kiểm soát Kim khí để cho ngày sinh Ất Mộc phát
        triển.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Không tốt nếu Thủy xuất hiện ở Thiên Can.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Bính Hỏa là dụng thần của ngày sinh Ất Mộc
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Không tốt nếu gặp Thủy đặc biệt là Quý Thủy ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Để tốt thì Bính Hỏa nên lộ ở Thiên Can.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Mậu Thổ cần có để ngăn Quý Thủy không hại Bính Hỏa. Đây là lý do vì
        sao Mậu Thổ nên được dùng kèm với Bính Hỏa.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Mậu Thổ xuất hiện 2 hay 3 lần ở Thiên Can thì ngày sinh này sẽ quá
        được nuông chiều hay nâng đỡ. Do đó cần có Giáp Mộc để kiểm soát Mậu
        Thổ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Bính Hỏa ẩn tàng trong Địa Chi thì sẽ khó cho ngày sinh này nếu
        muốn thành công mặc dù có thể gặp nhiều cơ hội.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Kỷ Thổ lộ ở Thiên Can thay vì Mậu Thổ thì ngày sinh này sẽ vẫn
        hưởng may mắn, nếu Giáp Mộc không xuất hiện.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Cục Tòng Tài sẽ hình thành nếu Kỷ Thổ xuất hiện ở Thiên Can và toàn bộ
        lá số có Thổ và thiếu Mộc.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có cục Thương Quan:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Bính Hỏa Thương Quan xuất hiện và không có Quý Thủy Thiên Tài thì
        ngày sinh này sẽ vượt trội trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu như có Bính Hỏa lộ ở Thiên Can sẽ tốt và không nên nhìn thấy Quý
        Thủy thấu lộ ở Thiên Can.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có cục Chính Tài:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Mậu Thổ và Bính Hỏa xuất hiện ở Thiên Can thì cần có Giáp Mộc để bảo
        vệ ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lý tưởng nếu Mậu Thổ không thông căn. Xấu nếu như Mậu Thổ thông căn hay
        xuất hiện nhiều.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu không có Bính Hỏa thì lá số này chỉ trung bình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Để hình thành cục Tòng Tài thành công thì cần vắng Tỷ Kiên và Kiếp
        Tài.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Mậu Thổ cần để cản Quý Thủy không hại Bính Hỏa.
      </p>
    </div>
  );
};

export const AtThangDan = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Ất Mộc trong tháng Dần
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh Ất vào tháng Dần thì hình thành cục Chính Tài khi Mậu
        Thổ lộ trên Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Giáp Mộc xuất hiện ở Thiên Can thì hình thành cục Dương Nhẫn nếu
        thoả các điều kiện và được Địa Chi hỗ trợ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Bính Hoả xuất hiện ở một trong những Thiên Can thì hình thành cục
        Thương Quan.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Mậu Thổ xuất hiện ở Thiên Can thì hình thành cục Chính Tài nếu thoả
        các điều kiện và được hỗ trợ bởi Địa Chi.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu không có Bính Hoả hay Mậu Thổ xuất hiện ở Thiên Can thì có thể chọn
        theo khí nào vượng nhất.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh tháng Dần thì Bính Hoả và Quý Thủy được xem là Dụng
        Thần Điều Hậu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Bính Hoả cung cấp hơi ấm và xua tan lạnh giá trong tháng này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Quý Thủy ở mức độ ít dùng để cung cấp độ ẩm cho ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Không nên thấy Bính Hoả bị kẹt hay không thể toả ra Khí nếu như có Quý
        Thủy xuất hiện bên cạnh.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tháng Dần chứa hành Hoả, Thổ và Mộc ở tàng can. Điều này có lợi cho ngày
        sinh Ất Mộc nói chung.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Vào đầu mùa xuân thì thời tiết vẫn còn hơi lạnh giá.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Do đó, Bính Hoả được xem là dụng thần cần phải xuất hiện ở Thiên Can, để
        cung cấp hơi ấm cho Mộc và sinh ra Thổ, vốn là Tài Tinh của ngày sinh.
        Nếu đạt được điều này thì ngày sinh này sẽ vững mạnh, dẫn đến tài lộc
        kéo dài.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi nào cần xét Dụng Thần Điều Hậu thì Bính Hoả được xem là dụng thần
        số 1, Quý Thủy là dụng thần số hai.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nhâm Thủy và Mậu Thổ không nên xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Vốn là ngày sinh Âm nên Ất Mộc là Mộc mềm, cũng như cỏ hay hoa. Do đó,
        Thủy cần phải xuất hiện để nuôi dưỡng Mộc phát triển.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Ngoài ra, sẽ tốt nếu có Hoả ở lượng vừa đủ để hỗ trợ sự phát triển của
        Ất Mộc. Nếu không thì ngày sinh này sẽ có nguy cơ quá ẩm ướt cũng như
        như cỏ hay hoa bị úng nước.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Đây là lý do mà Bính Hoả và Quý Thủy không nên thiếu như là Dụng Thần
        trong lá số này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Ngày sinh thiếu Quý Thủy nhưng có Bính Hoả là Dụng Thần sẽ có thể
        thành công trong sự nghiệp.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Có Quý Thủy nhưng thiếu Bính Hoả, ngày sinh này sẽ thiếu động lực và
        quyết tâm cũng như thiếu khả năng phát triển và tiến bộ trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Vì bản chất Ất Mộc mềm mại nên nếu thiếu Bính Hoả và Quý Thủy thì
        thiếu khả năng kiểm soát Thổ, vốn là Tài Tinh. Nó cũng thiếu sức mạnh và
        khả năng chống chọi lại Kim Chính Quan hay Thất Sát.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tài và Quan là lựa chọn không phù hợp để làm Dụng Thần cho ngày sinh
        này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu xuất hiện Thiên Can Mậu Thổ và Kỷ Thổ là Tài Tinh, thì Quý Thủy sẽ
        là lựa chọn phù hợp làm Dụng Thần Điều Hậu cho ngày sinh này. Điều này
        bởi vì cả Mậu Thổ và Kỷ Thổ có khả năng kiểm soát khắc chế Quý Thủy. Nếu
        không, sẽ tốt hơn nếu tránh Mậu Thổ và Kỷ Thổ, bởi vì sẽ làm giảm tác
        dụng của Dụng Thần. Cần nhớ rằng Ất Mộc thiếu sức mạnh để tự kiểm soát
        Mậu Thổ hay Kỷ Thổ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Sẽ không tốt cho ngày sinh này nếu Bính Hoả không xuất hiện ở Thiên
        Can mà thay vào đó là xuất hiện nhiều Kim và Thủy. Lá số này sẽ quá ẩm
        ướt và Mộc không phát triển được. Trong những điều kiện đó thì ngày sinh
        này sẽ bị giới hạn sự phát triển và tiến bộ trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi có cả Bính Hoả và Quý Thủy cùng xuất hiện thì ngày sinh này sẽ có
        cuộc sống khá tốt, đạt được cả thành công và danh tiếng. Tuy nhiên, nếu
        Bính Hoả xuất hiện tạo thành Thương Quan Cục với ngày sinh này nhưng
        thiếu Quý Thủy Thiên Ấn thì tài lộc sẽ bị ảnh hưởng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi có Địa Chi Dần Ngọ Tuất hình thành Hoả Cục thì cần có Quý Thủy
        xuất hiện để kiểm soát Hoả Khí.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Quý Thủy xuất hiện ở Thiên Can nhưng không có Bính Hoả thì mộc quá
        lạnh và không phát triển được.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Bính Hoả xuất hiện nhiều nhưng Quý Thủy hoàn toàn vắng bóng thì sẽ
        dễ khô hạn. Trong hoàn cảnh đó thì ngày sinh này sẽ giàu có và thịnh
        vượng, mặc dù họ có thể thích một cuộc sống đơn giản.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có cục Chính Tài Thiên Tài:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Mậu Thổ hay Kỷ Thổ xuất hiện ở Thiên Can thì nên kèm với Bính
        Hoả.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Quý Thủy Thiên Ấn xuất hiện ở Thiên Can gặp Mậu hay Kỷ Thổ thì
        ngày sinh này sẽ tựa như Mộc mọc trên Thổ ướt. Lá số này sẽ không tốt.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có cục Chính Tài:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Mậu Thổ hay Kỷ Thổ xuất hiện ở Thiên Can thì nên kèm với Bính
        Hoả.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Quý Thủy Thiên Ấn xuất hiện ở Thiên Can gặp Mậu hay Kỷ Thổ thì
        ngày sinh này sẽ tựa như Mộc mọc trên Thổ ướt. Lá số này sẽ không tốt.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có sự hiện diện của Bính Hoả ở Thiên Can là tốt, nếu Quý Thủy xuất
        hiện ở Thiên Can thì tổng thể là lá số kém.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Quý Thủy Dụng Thần sẽ cung cấp độ ẩm cho ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Để Quý Thủy có thể hiện lên ở Thiên Can thì cần có Bính Hoả cũng xuất
        hiện - hay là Địa Chi Dần Ngọ Tuất xuất hiện để hình thành Hoả Cục. Đây
        là kịch bản lý tưởng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Bính Hoả và Quý Thủy không nên xuất hiện bên cạnh nhau.
      </p>
    </div>
  );
};

export const AtThangMao = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Ất trong tháng Mão
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với sinh trong tháng Mão thì Địa Chi Mão được xem là Đế Vượng của Ất
        Mộc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Ất Mộc hiện ra như là một trong những Thiên Can thì hình thành cục
        Lâm Quan.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngay cả nếu như Ất Mộc không hiện ra trên Thiên Can thì Lâm Quan Cục sẽ
        đạt được khi thoả các điều kiện và hô trợ bởi Địa Chi.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng Mão thì Bính Hoả và Quý Thủy là dụng thần
        điều hậu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Quý Thủy dùng để cung cấp độ ẩm trong khi Bính Hoả dùng để kiểm soát cả
        hai.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người Ất Mộc sinh trong tháng Mão thì Địa Chi là vị trí Đế Vượng
        của Ất Mộc. Nói cách khác, Mộc có gốc căn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngày sinh này sẽ có sức mạnh, động lực và quyết tâm để tạo ra tài lộc và
        thịnh vượng trong đời.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Bính Hoả và Quý Thủy là dụng thần điều hậu. Thổ là dụng thần quan trọng
        nhất. Khi nào ngày sinh này được hỗ trợ đầy đủ bởi Hoả và Thổ là dụng
        thần thì tài lộc sẽ rất hứa hẹn.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Bính Hoả và Quý Thủy là dụng thần điều hậu chính cho ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Ất Mộc có gốc rễ chính tại Mão Mộc nên đem đến sức mạnh lớn. Đây là lý
        do vì sao Canh Kim cần vắng mặt trong lá số này. Tuy nhiên, Canh Kim
        không nên xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi nào Bính Hoả và Quý Thủy không có mặt, thì Đinh Hoả và Nhâm Thủy
        cũng không thể thay thế được tốt. Điều này bởi vì Nhâm Thủy và Đinh Hoả
        sẽ có xu hướng hợp hoá và tạo ra thêm Mộc.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Canh Kim và Ất Mộc không nên xuất hiện bên cạnh nhau vì cả hai sẽ hợp
        tạo ra Kim.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Cục Tỷ Kiên Kiếp Tài:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Hợi Mão Mùi tạo thành Mộc Cục, chỉ có Mộc, Hoả. và Thủy là dụng
        thần điều hậu phù hợp để đem đến kết quả tốt nhất cho người này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Quý Thủy nhưng thiếu Bính Hoả thì ngày sinh này vẫn sẽ giàu có
        trong đời. Khi thiếu Bính Hoả nhưng có Quý Thủy thì ngày sinh này sẽ
        không thể thể hưởng thụ cuộc sống tốt đẹp.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tốt nhất là có cả Bính hoả và Quý Thủy trong lá số.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi chỉ có Quý Thủy là Dụng Thần thì ngày sinh này sẽ chỉ sống cuộc đời
        trung bình.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Cục Chính Quan Thất Sát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Canh Kim rất cần để giữ cho Ất Mộc không trở nên quá mạnh. Nhưng không
        nên xuất hiện Canh Kim ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nên có cả Đinh Hoả để kiểm soát Canh Kim.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Thiếu Bính Hoả hay Quý Thủy đều không tốt cho lá số này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Ngay cả nếu như Quý Thủy xuất hiện ở Thiên Can thì chỉ nên ở một lượng
        vừa đủ, thay vì quá nhiều.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Đinh Hoả làm suy yếu ngày sinh này và tạo ra thêm Thổ vốn là Tài Tinh
        của người này. Tuy vậy, nó vẫn tốt cho người ngày sinh này, nếu Đinh Hoả
        được dùng để kiểm soát Canh Kim là sao Chính Quan.
      </p>
    </div>
  );
};

export const AtThangThin = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Ất trong tháng Thìn
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Sinh trong tháng Thìn thì hình thành cục Chính Tài khi Mậu Thổ xuất hiện
        ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Quý Thủy xuất hiện ở Thiên Can thì hình thành cục Thiên Ấn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Ất Mộc xuất hiện ở Thiên Can thì hình thành cục Lâm Quan khi thoả
        các điều kiện và hỗ trợ bởi Địa Chi.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu như cả Mậu Thổ, Ất Mộc hay Quý Thủy đều không có ở Thiên Can thì nên
        chọn cục ctheo ngũ hành vượng nhất trong lá số Bát Tự
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Với ngườii Ất sinh trong tháng Thìn thì Quý Thủy Bính Hoả và Mậu Thổ là
        những dụng thần điều hậu quan trọng nhất.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Mậu Thổ còn đóng vai trò dụng thần Thông Quan khi Địa Chi tạo thành Thủy
        Cục.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Địa chi Thìn chứa tàng can Ất Mộc, Mậu Thổ và Quý Thủy.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Do đó, Ất Mộc được xem như là thông căn và rất mạnh. Trong hoàn cảnh này
        thì ngày sinh này vượng và do đó đạt đạt nhiều sự vĩ đại trong đời.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Sự hiện diện của Thủy Khí và Hoả Khí sẽ ủng hộ và gia tăng sức mạnh cho
        ngày sinh này. Với hơi ẩm vừa đủ từ Thủy, ngày Ất Mộc sẽ có khả ănng
        nuôi dưỡng Thổ. Và khi Thổ Tài Tinh đủ mạnh thì cấu trúc của ngày sinh
        này sẽ cân bằng và hoàn hảo.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Quý Thủy là dụng thần chính cho ngày sinh Ất Mộc trong tháng Thìn và
        Bính Hoả đóng vai trò là dụng thần số hai.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu được người này nên tránh gặp Kỷ Thổ và Nhâm Thủy.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Bất kể việc bản chất mềm mại của Ất Mộc, Mộc không thể thấy Canh Kim ở
        Thiên Can.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Bất lợi nếu thấy Kỷ Thổ Thiên Tài xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu cả Bính Hoả và Quý Thủy xuất hiện thì người này sẽ hưởng thành
        công trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi nào có sẵn Bình Hoả nhưng không có Quý Thủy thì người này sẽ có
        lúc may mắn lúc không. Khi có nhiều Quý Thủy và cũng có Tỵ Địa Chi ở Bát
        Tự thì cần có Mậu Thổ để kiểm soát Thủy.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu không, ngày sinh này sẽ lang thang đi tìm may mắn cho cuộc đời của
        mình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Giữa Quý Thủy và Bính Hoả thì Quý Thủy được xem là Dụng Thần có lợi
        hơn cho ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi có nhiều Quý Thủy thì Mậu Thổ cũng cần có mặt ở Thiên Can và Địa
        Chi.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Kỷ Thổ và Canh Kim không nên bị lộ ở Thiên Can. Điều này bởi vì cả hai
        có khả năng gây bất lợi cho lá số.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Quý Thủy và Bính Hoả là dụng thần có lợi cho ngày sinh Ất Mộc trong
        tháng Thìn. Nếu cả Bính Hoả và Quý Thủy đều vắng mặt trong lá số thì lá
        số này sẽ kém.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Kỷ Thổ và Canh Kim không nên hiện ở Thiên Can bởi vì cả hai sẽ phá huỷ
        các dụng thần.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu có cục Chính Quan:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Bất lợi nếu có Canh bên cạnh ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Chỉ có Đinh Hoả được dùng làm Dụng Thần và đem đến kết quả tốt nhất cho
        người này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Không được có Đinh Hoả lộ ở Thiên Can.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu có Nhâm Thủy, Quý Thủy và Tân Kim:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Địa Chi hình thành Thủy Cục thì ngày sinh này sẽ chịu nghèo khổ
        trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tuy nhiên, khi có Mậu Thổ Chính Tài người này sẽ có tri thức cao và
        hưởng thành công tương đối trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi có Tân Kim thì Mậu Thổ cần để giữ Thủy do Tân Kim sinh ra không quá
        nhiều.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Sẽ khó để dùng Canh Kim ở Thiên Can khi Ất và Canh có xu hướng hợp.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi có nhiều Nhâm Thủy và Quý Thủy, Mậu Thổ sẽ cần để kiểm soát Thủy.
        Kỷ Thổ sẽ không đủ.
      </p>
    </div>
  );
};

export const AtThangTy = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Ất trong tháng Tỵ
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh ngày Ất trong tháng Tỵ thì cục Thương Quan sẽ hình
        thành nếu Bính Hoả lộ trên Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi có Canh Kim xuất hiện ở Thiên Can, hình thành cục Chính Quan.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Mậu Thổ xuất hiện ở Thiên Can, hình thành cục Chính Tài.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu cả Bính Hoả, Canh Kim hay Mậu Thổ đều không xuất hiện ở Thiên Can
        thì nên chọn theo ngũ hành vượng nhất trong lá số tuỳ ý.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Quý Thủy là dụng thần điều hậu cho người sinh trong tháng Tỵ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Bởi vì Bính Hoả đặc biệt mạnh trong tháng này, Quý Thủy sẽ đóng vai trò
        dũng thần điều hậu trong việc kiểm soát Bính Hoả.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Hoả vượng trong khi Thổ mạnh trong tháng Tỵ. Kết quả là Ất Mộc sẽ bị Hoả
        mạnh làm cho suy yếu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đó là lý do vì sao Thủy, nhiều là dụng thần, để có thể sản sinh và giúp
        cho ngày sinh này mạnh hơn. Tương tự, Canh Kim ẩn tàng bên dưới địa chi
        Tỵ sẽ đóng vai trò sinh Thủy.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Rõ ràng là Ất Mộc sẽ héo và yếu trong tháng mùa hè.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Do đó, sẽ không tốt cho Kim xuất hiện ở Thiên Can đặc biệt nếu xét về
        Kim mạnh hay nhiều. Hiển nhiên là Kim khắc Mộc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Thủy là không thể thiếu, bởi vì nó sinh ra và đóng vai trò Ấn Tinh sinh
        cho Mộc. Nếu có xuất hiện thì ngày sinh này sẽ hưởng may mắn về tình cảm
        và mối quan hệ, đặc biệt khi xét đến việc tìm được và giữ được người
        chồng có năng lực cũng như nuôi dưỡng được những đứa con giỏi giang.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Địa chi Tỵ tàng Bính Hoả như là tàng can chính. Đó là lý do vì sao
        Dụng Thần Điều Hậu thì Quý Thủy là không thể thiếu, để cho ngày sinh này
        có thể sống sót.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Không tốt nếu thấy Mậu Thổ và Kỷ Thổ ở Thiên Can. Điều này bởi vì Thổ
        sẽ khắc chế Thủy cần thiết.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Hoả vượng trong tháng Tỵ và hiển nhiên là môi trường sẽ nóng. Ất Mộc
        sẽ héo và trở nên rất yếu trong hoàn cảnh này. Đó là lý do vì sao Quý
        Thủy là dụng thần quan trọng nhất, trong việc sinh ra cho ngày sinh này.
        Nếu thiếu Quý Thủy, có thể thay thế bằng Nhâm Thủy.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu không có Thủy, ngày sinh này sẽ có sức khoẻ xấu suốt cả đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi nào có Mậu Thổ và Kỷ Thổ Tài Tinh hiện diện nhiều ở Thiên Can, có
        khả năng Mậu Thổ sẽ hợp với Quý Thủy. Trong hoàn cảnh đó thì ngày sinh
        này có thể hi sinh sức khoẻ khi cầu tài và lợi ích vật chất.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Quý Thủy xuất hiện ở Thiên Can, nhưng không thông căn ở Địa Chi
        thì sẽ thiếu sức mạnh cần thiết để hành xử như là Dụng Thần. Trong hoàn
        cảnh này thì Canh Kim là cần thiết để sinh ra cho Thủy.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Quý Thủy có mặt cả Thiên Can và Địa Chi với Canh Kim cũn có mặt,
        thì ngày sinh này sẽ bị quá nhiều Dụng Thần gây tác dụng dư thừa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi có nhiều Mậu Thổ và Kỷ Thổ xuất hiện ở Thiên Can, trong khi Địa
        Chi hình thành Hoả Cục đầy đủ, ngày sinh này sẽ thiếu trí tuệ, kiến
        thức, cảm hứng và thậm chí cả quan điểm trong cuộc sống.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu hình thành đủ Kim Cục hay Thủy Cục thì sẽ gây hại cho sự ảnh hưởng
        của Hoả.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Hoả rất mạnh trong tháng Tỵ. Do đó sẽ không thể để dùng Dụng thần là
        Canh Kim và Tân Kim.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Ngay cả nếu Quý Thủy xuất hiện ở lá số thì thiếu Canh Kim ở Thiên Can
        cũng khiến cho người này có lá số tầm thường - bất kể người này tài năng
        bao nhiêu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi gặp được Thủy, thì dù ở Thiên Can hay tạo thành cục Địa Chi, ngày
        sinh này cũng gặp nhiều thành công bất ngờ trong đời.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Cục Thiên Ấn:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Không có Canh Kim hay Tân Kim để sinh cho Quý Thủy thì người này sẽ
        hưởng thành công và tài lộc giới hạn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi có Canh Kim xuất hiện như là Dụng Thần, có thể hợp với Ất Mộc (tạo
        thành Kim).
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Sự hiện diện của Canh Kim và Tân Kim để tạo ra Quý Thủy. Quý Thủy phải
        thông căn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Mậu Thổ xuất hiện ở Thiên Can hay Địa Chi hình thành Hoả Cục thì
        ngày sinh này sẽ nghèo khổ cũng như bị bệnh mắt trong đời.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Đơn giản là không thể thể thay thể Quý Thủy dụng thần bằng gì khác.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Ngày sinh này sẽ không thể may mắn nếu như Thổ Khí tạo thành Tài Cục
        nếu như lá số không có Thủy Khí.
      </p>
    </div>
  );
};

export const AtThangNgo = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Ất trong tháng Ngọ
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với sinh trong tháng Ngọ thì cục Thực Thân sẽ hình thành khi Đinh
        Hoả xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Kỷ Thổ xuất hiện ở Thiên Can thì hình thành cục Thiên Tài.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu không có Đinh Hoả hay Kỷ Thổ xuất hiện ở Thiên Can thì có thể tuỳ ý
        chọn ngũ hành vượng nhất để lập cục.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh tháng Ngọ thì Quý Thủy và Bính Hoả là dụng thần điều
        hậu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu sinh vào nửa đầu tháng thì Quý Thủy được xem là dụng thần số 1. Nếu
        sinh vào nửa cuối tháng thì cả Bính Hoả và Quý Thủy cần phải dùng chung
        với nhau.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Hoả và Thổ vượng trong tháng Ngọ, và sẽ xung với Thủy.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Do đó, Thủy vừa đủ sẽ cần để vượt qua sức nóng và khô hạn, cũng như có
        thể cung cấp độ ẩm cho Thổ khiến cho sử dụng được cũng như cùng lúc gia
        t8ang sức mạnh của Mộc.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Do đó, Kim nếu dùng với lượng vừa đủ, sẽ có lợi vì Kim đóng vai trò
        nguồn sinh ra Thủy.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Kim và Thủy cùng có mặc, Mộc sẽ cùng lúc được dùng như là một dụng
        thần trong giúp cho ngày sinh này mạnh hơn. Điều này khiến cho tạo ra
        kịch bản cân bằng, bởi vì sự hiện diện của Kim và Thủy sẽ ngăn Mộc ngừng
        tạo ra thêm Hoả.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Quý Thủy là dụng thần quan trọng nhất cho người sinh tháng Ngọ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Bính Hoả cũng đóng vai trò là Dụng Thần quan trọng trong nửa sau của
        tháng này, tiếp theo là Quý Thủy. Điều quan trọng là không được thiếu
        Quý Thủy hay Bính Hoả trong lá số.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Sẽ rất tốt nếu Canh Kim xuất hiện ở Thiên Can năm và Quý Thủy xuất
        hiện ở can giờ.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Địa Chi tạo thành Hoả Cục, trong khi Quý Thủy lộ nhiều ở Thiên Can
        - ngày sinh này sẽ hưởng thành công lớn vê mặt sự nghiệp.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Quý Thủy không xuất hiện ở Thiên Can, nhưng lại có Hoả và Thổ xuất
        hiện ở Thiên Can và Địa Chi - ngày sinh này sẽ thiếu sự thông minh cũng
        như mục tiêu và định hướng trong đời.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu có cục Thực Thương:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Địa Chi tạo thành Hoả Cục hoàn toàn, ngày sinh này sẽ có tri thức và
        rộng lượng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Điều cần lưu ý là Quý Thủy sẽ phải xuất hiện ở Thiên Can nếu ngày sinh
        này muốn hưởng thịnh vượng trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Quý Thủy xuất hiện ở Thiên Can là tốt. Nếu không thì ít nhất cũng là
        Nhâm Thủy.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu không có Quý Thủy thì người này sẽ sống cuộc đời trung bình. Tệ nhất
        là người này có thể có sức khoẻ xấu.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu có cục Thiên Ấn:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Quý Thủy được dùng như là Dụng Thần thì cần phải có căn gốc ở Địa
        Chi. Ngoài ra, Canh Kim và Tân Kim cần được xem như là Dụng Thần để sinh
        cho Thủy.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Quý Thủy có căn gốc ở Địa Chi là tốt.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Quý Thủy không có căn gốc ở Địa Chi, trong khi Mậu Thổ cũng xuất hiện
        tại Thiên Can là xấu.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Trong tháng Ngọ thì Hoả rất vượng. Ất Mộc gặp nguy hiểm bị héo và rất
        yếu. Do đó, không có Quý Thủy thì ngày sinh này sẽ kém cỏi.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Địa Chi không nên hình thành một Hoả Cục hoàn toàn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu thiếu Quý Thủy thì Nhâm Thủy có thể được dùng như là Dụng Thần
        thay thế.
      </p>
    </div>
  );
};

export const AtThangMui = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Ất trong tháng Mùi
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh vào ngày Ất trong tháng Mùi thì hình thành cục Thiên
        Tài khi Kỷ Thổ xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Đinh Hoả xuất hiện ở Thiên Can, hình thành cục Thực Thân
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Ất Mộc hiện diện ở một trong những Thiên Can, cục Lâm Quan hình
        thành khi thoả các điều kiện cũng như được Địa Chi ủng hộ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tuy nhiên nếu không có Đinh Hoả, Ất Mộc hay Kỷ Thổ xuất hiện ở Thiên Can
        thì nên chọn theo ngũ hành nào vượng nhất tuỳ ý.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với sinh trong tháng Mùi thì cần Quý Thủy và Bính Hoả làm dụng thần
        điều hậu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Quý Thủy là dụng thần không thể thiếu, trong việc cung cấp độ ẩm cho cả
        Thổ và Mộc. Điều này khiến đây là dụng thần số 1 cho ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Kim và Thủy cùng xuất hiện ở lá số này thì Bính Hoả đóng vai trò là
        dụng thần số một.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Trong tháng Mùi, Nhâm Thủy và Quý Thủy là dụng thần rất quan trọng, cũng
        cần chú ý không nên bị quá nhiều Mậu Thổ và Kỷ Thổ gây ô nhiễm.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tháng Mùi đóng vai trò như là Mộ Khố cho Mộc Khí. Do đó, Ất Mộc sẽ có
        căn gốc mạnh và rất mạnh trong tháng Mùi.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Bởi vì Kỷ Thổ vượng trong tháng này, có khả năng rút năng lượng từ Đinh
        Hoả và để sinh ra cho Thổ. Trong hoàn cảnh này thì cả Thổ Tài Tinh và Ất
        Mộc sẽ mạnh nhất trong tháng này.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Thủy ở mức độ ít sẽ cần thiết để có thể sinh ra Mộc vốn dĩ khô. Không có
        hay chỉ có ít Thủy thì ngày sinh này sẽ đạt thành công tài chính ít, mặc
        dù có khả năng kiếm được tiền. Đối với ngày sinh này để có thể thành
        công trong đời, thì Kim ở mức độ ít có thể được dùng để sinh ra cho
        Thủy.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Trong mọi tình huống thì ngày sinh này sẽ không gặp khó khăn trong việc
        tìm kiếm sự giúp dỡ từ quý nhân.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Bính Hoả và Quý Thủy là dụng thần lý tưởng cho ngày sinh Ất Mộc này.
        Nếu khi cả hai cùng xuất hiện ở Thiên Can kèm với Giáp Mộc thì người này
        là lãnh đạo rất tài năng. Có thể đạt thành công lớn trong đời. Nếu như
        có Giáp Mộc xuất hiện nhưng lại không có Bính Hoả hay cả Quý Thủy thì
        người này sẽ sống cuộc đời vô định. Ngoài ra họ cũng có tính cách bất
        ổn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Mậu Thổ và Kỷ Thổ không nên xuất hiện ở Thiên Can vì sẽ khắc Quý Thủy.
        Đây là lý do mà Giáp Mộc Kiếp Tài cần để bảo vệ Quý Thủy.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Mậu Thổ có ở Thiên Can thì Giáp Mộc có thể được dùng để khắc chế
        và kiểm soát.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi có Mậu Thổ, Kỷ Thổ và Giáp Mộc xuất hiện thì ngày sinh này sẽ
        hưởng may mắn trong đời. Nếu không có Giáp Mộc Kiếp Tài thì lá số này sẽ
        có cách cục lá số dưới trung bình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Quý Thủy không xuất hiện ở Thiên Can mà có Nhâm Thủy thì ngay sinh
        này sẽ có thể sống cuộc đời trung bình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Mậu Thổ xuất hiện nhưng Giáp Mộc không lộ ở Thiên Can thì Thủy cần
        để sinh ra và củng cố Mộc. Ngược lại, ngày sinh này sẽ chỉ có thể sống
        cuộc sống trung bình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi có 3 Ất Mộc Thiên Can ở trong lá số thì ngày sinh này sẽ bị cô đơn
        và bi quan trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi có 3 Tân Kim xuất hiện trong lá số thì ngỳ sinh này sẽ không chỉ
        bị cô đơn trong đời mà có tính cách kiêu ngạo.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi có 3 Giáp Mộc hiện diện ở lá số Bát Tự thì ngày sinh này có thể có
        cuộc sống không định hướng. Người này sẽ gặp vấn đề lớn về tính cách và
        thái độ. Trong hoàn cảnh này thì Bính Hoả và Canh Kim là dụng thần quan
        trọng cần thiết để hoá giải các ảnh hưởng xấu trong lá số.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có cục Thất Sát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu dùng Bính Hoả và cả Tân Kim thì không nên ở bên cạnh nhau vì sẽ hợp
        nhau tạo thành Thủy. Nếu không thì thành công sẽ dễ bị người khác chiếm
        đạoạt.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Cả Bính Hỏa và Quý Thủy không nên hiện diện như là dụng thần. Nếu không,
        ngày sinh này sẽ bị nghèo và sở hữu tính cách kiêu ngạo.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Canh Kim và Tân Kim không nên bị hợp mất. Vì Kim xuất hiện thì Giáp
        Mộc cũng cần xuất hiện. Nếu không thì ngày sinh này sẽ khó phát triển
        trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Không có Bính Hoả và Quý Thủy là Dụng Thần, ngày sinh này sẽ gặp Đào
        Hoa xấu, tệ hơn nữa nếu có Giáp Mộc ở Thiên Can. Người này sẽ có tính
        cách dâm dục.
      </p>
    </div>
  );
};

export const AtThangThan = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Ất trong tháng Thân
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh ngày Ất Mộc trong tháng Thân, hình thành cục Chính
        Quan khi Canh Kim lộ ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Mậu Thổ lộ Thiên Can thì hình thành cục Chính Tài.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Nhâm Thủy lộ Thiên Can thì hình thành Chính Ấn cục.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu không có Canh Kim, Mậu Thổ, Nhâm Thủy lộ Thiên Can thì có thể chọn
        cục nào theo ngũ hành vượng nhất trong lá số Bát Tự.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng Thân, thì Bính Hỏa, Quý Thủy và Kỷ Thổ là
        Dụng Thần Điều Hậu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Vì Canh Kim vượng nhất trong tháng này, Bính Hỏa được dùng để kiểm soát.
        Nếu không thì Quý Thủy có thể dùng để làm suy yếu Canh Kim.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Bất kể Bính Hỏa hay Quý Thủy được dùng như là Dụng Thần, thì Kỷ Thổ cũng
        đóng vai trò như là Dụng Thần trung gian.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Canh Kim vượng nhất trong tháng này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ất Mộc dễ bị cắt đứt bởi Canh Kim. Ngoài ra, có nguy cơ là quá nhiều
        Thủy sẽ dìm ngày sinh này.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Trong những hoàn cảnh này, Mộc có nguy cơ bị suy yếu quá nhiều. Điều này
        là lý do vì sao Hỏa là Dụng Thần quan trọng nhất đối với ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Thổ ấm được xem là dụng thần, để có thể khắc và ngăn cản Thủy không quá
        nhiều. Điều này có nghĩa là cả Hỏa và Thổ cần cùng lúc. Nếu những hành
        này cùng xuất hiện, ngày sinh này có thể hấp thu tài lộc lớn trong đời.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Bính Hỏa là dụng thần số một cho ngày sinh Ất Mộc này cũng như Quý
        Thủy là dụng thần số hai.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Canh Kim mạnh nhất trong tháng Thân. Do đó, khi Canh Kim hợp với Ất
        Mộc ngày sinh thì Ất Mộc sẽ đánh mất sức sống.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Lý tưởng nếu có Bính Hỏa, Quý Thủy và Kỷ Thổ đều xuất hiện ở Thiên
        Can.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu không có Bính Hỏa hay Quý Thủy thì lá số này chỉ đạt trung bình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Kỷ Thổ được xem như là dụng thần chính cho lá số này. Điều này bởi vì
        Kỷ Thổ có khả năng làm bẩn Nhâm Thủy và Quý Thủy.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Nhâm Thủy và Quý Thủy được xem là dụng thần điều hậu thì không thể
        sử dụng Kỷ Thổ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Quý Thủy Thiên Ấn xuất hiện thì nhưng không có Kỷ Thổ hay Bính
        Hỏa, ngày sinh này sẽ kiểu tỏ ra biết nhiều nhưng thực ra rất vất vả để
        kiếm tiền mưu sinh.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Bính Hỏa Thương Quan xuất hiện thì Quý Thủy Thiên Ấn không được
        xuất hiện ở Thiên Can, ngày sinh này chỉ sống cuộc đời trung bình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Quý Thủy và Bính Hỏa xuất hiện ở Thiên Can thì tốt. Nếu thiếu Quý Thủy
        thì lá số này sẽ lá số kém.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có cục Chính Quan:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Sẽ khó để xem Canh Kim là dụng thần cho ngày sinh này. Kỷ Thổ, Quý Thủy
        và Bính Hỏa phải cùng có mặt thì Canh Kim mới có thể phát huy tác dụng
        tích cực.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Kỷ Thổ nếu lộ trên Thiên Can thì ổn.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Cần lưu ý rằng mặc dù Kỷ Thổ không thể sinh Kim nhưng có có khả năng
        chôn vùi và làm bẩn Kim.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi xuất hiện Kim tạo thành cục Tòng Quan, không được thấy Thủy thì
        mới có thể tạo thành cục được.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu dùng Ấn Tinh, Thổ phải xuất hiện ở Thiên Can. Nếu không có Thổ,
        Thủy Ấn Tinh sẽ quá nhiều.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi hoàn toàn thiếu Bính Hỏa, Quý Thủy và Kỷ Thổ thì lá số này chỉ
        trung bình.
      </p>
    </div>
  );
};

export const AtThangDau = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Ất trong tháng Dậu
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với sinh trong tháng Mão thì Địa Chi Mão được xem là Đế Vượng của Ất
        Mộc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Ất Mộc hiện ra như là một trong những Thiên Can thì hình thành cục
        Lâm Quan.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngay cả nếu như Ất Mộc không hiện ra trên Thiên Can thì Lâm Quan Cục sẽ
        đạt được khi thoả các điều kiện và hô trợ bởi Địa Chi.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng Mão thì Bính Hoả và Quý Thủy là dụng thần
        điều hậu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Quý Thủy dùng để cung cấp độ ẩm trong khi Bính Hoả dùng để kiểm soát cả
        hai.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người Ất Mộc sinh trong tháng Mão thì Địa Chi là vị trí Đế Vượng
        của Ất Mộc. Nói cách khác, Mộc có gốc căn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngày sinh này sẽ có sức mạnh, động lực và quyết tâm để tạo ra tài lộc và
        thịnh vượng trong đời.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Bính Hoả và Quý Thủy là dụng thần điều hậu. Thổ là dụng thần quan trọng
        nhất. Khi nào ngày sinh này được hỗ trợ đầy đủ bởi Hoả và Thổ là dụng
        thần thì tài lộc sẽ rất hứa hẹn.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Bính Hoả và Quý Thủy là dụng thần điều hậu chính cho ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Ất Mộc có gốc rễ chính tại Mão Mộc nên đem đến sức mạnh lớn. Đây là lý
        do vì sao Canh Kim cần vắng mặt trong lá số này. Tuy nhiên, Canh Kim
        không nên xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi nào Bính Hoả và Quý Thủy không có mặt, thì Đinh Hoả và Nhâm Thủy
        cũng không thể thay thế được tốt. Điều này bởi vì Nhâm Thủy và Đinh Hoả
        sẽ có xu hướng hợp hoá và tạo ra thêm Mộc.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Canh Kim và Ất Mộc không nên xuất hiện bên cạnh nhau vì cả hai sẽ hợp
        tạo ra Kim.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Cục Tỷ Kiên Kiếp Tài:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Hợi Mão Mùi tạo thành Mộc Cục, chỉ có Mộc, Hoả. và Thủy là dụng
        thần điều hậu phù hợp để đem đến kết quả tốt nhất cho người này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Quý Thủy nhưng thiếu Bính Hoả thì ngày sinh này vẫn sẽ giàu có
        trong đời. Khi thiếu Bính Hoả nhưng có Quý Thủy thì ngày sinh này sẽ
        không thể thể hưởng thụ cuộc sống tốt đẹp.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tốt nhất là có cả Bính hoả và Quý Thủy trong lá số.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi chỉ có Quý Thủy là Dụng Thần thì ngày sinh này sẽ chỉ sống cuộc đời
        trung bình.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Cục Chính Quan Thất Sát:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Canh Kim rất cần để giữ cho Ất Mộc không trở nên quá mạnh. Nhưng không
        nên xuất hiện Canh Kim ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nên có cả Đinh Hoả để kiểm soát Canh Kim.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Thiếu Bính Hoả hay Quý Thủy đều không tốt cho lá số này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Ngay cả nếu như Quý Thủy xuất hiện ở Thiên Can thì chỉ nên ở một lượng
        vừa đủ, thay vì quá nhiều.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Đinh Hoả làm suy yếu ngày sinh này và tạo ra thêm Thổ vốn là Tài Tinh
        của người này. Tuy vậy, nó vẫn tốt cho người ngày sinh này, nếu Đinh Hoả
        được dùng để kiểm soát Canh Kim là sao Chính Quan.
      </p>
    </div>
  );
};

export const AtThangTuat = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Ất trong tháng Tuất
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với ngày sinh Ất trong tháng Tuất thì hình thành cục Chính Tài nếu
        Mậu Thổ xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Tân Kim xuất hiện ở Thiên Can thì hình thành cục Thất Sát.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Đinh Hỏa xuất hiện ở Thiên Can thì hình thành cục Thực Thương.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tuy nhiên, nếu không có Đinh Hỏa, Tân Kim và Mậu Thổ ở Thiên Can thì có
        thể chọn cách cục theo hành vượng nhất trong lá số Bát Tự.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng Tuất thì Quý Thủy và Tân Kim là dụng thần
        điều hậu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tân Kim giúp sinh ra Thủy.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Ất Mộc gặp Giáp Mộc, thì cũng tương tự như Ất Mộc bao quanh lấy cây
        to Giáp Mộc, sẽ được bảo vệ.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Thổ dày đặc trong tháng Tuất.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Do đó, ngày sinh này sẽ gặp nguy hiểm vì trở nên quá khô nóng. Đây là lý
        do vì sao Thủy là dụng thần không thể thiếu, trong việc cung cấp độ ẩm.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tuất Thổ cũng chứa Đinh Hỏa, Tân Kim và Mậu Thổ là tàng can. Khi Thủy
        xuất hiện trong lá số, thì ngày sinh này không cần phải quá lo lắng về
        khô.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Hỏa Khí xuất hiện mạnh mẽ - nhưng Thủy Khí bị thiếu – thì sẽ không
        thể phụ thuộc nhiều vào Mộc như là Dụng Thần. Bởi vì Thổ quá nặng nề và
        chặt khei6n1 cho Mộc dễ gãy.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Quý Thủy được xem là Dụng Thần chính cho ngày sinh này, điều này càng
        đúng nếu như Giáp Mộc và Canh Kim cũng xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Sẽ lý tưởng nếu ngày sinh Ất Mộc thông căn ở Địa Chi Dần hay Mão.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu ngày sinh này gặp Giáp Mộc, nó có thể cho mượn sức mạnh để vươn
        lên. Trong cuộc sống, người này sẽ có thể dựa vào bản lĩnh bên trong để
        nhận ra tài năng của người khác và có thể giành được sự giúp đỡ khi nào
        cần.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Quý Thủy hiện diện nhưng thiếu Tân Kim thì ngày sinh này sẽ chỉ có
        cuộc sống trung bình. Nếu Nhâm Thủy thay thế Quý Thủy là dụng thần thì
        cũng cùng kết quả, mặc dù người này sẽ có khả năng đảm nhận trách nhiệm
        lãnh đạo hay quản lý trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu cả Quý Thủy và Tân Kim cùng xuất hiện ở Thiên Can, thì ngày sinh
        này sẽ hưởng thành công và danh tiếng trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Quý Thủy có nhưng thiếu Tân Kim thì ngày sinh này chỉ sống một
        cuộc đời trung bình. Nếu có Tân Kim nhưng không có Quý Thủy thì ngày
        sinh này sẽ nghèo khổ cả đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Quý Thủy được xem là Dụng Thần chính cho ngày sinh Ất Mộc trong tháng
        Tuất.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có cục Thiên Ấn Chính Ấn:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Địa Chi tạo thành Thủy Cục thì Mậu Thổ phải xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tốt nếu như Mậu Thổ có căn. Và lá số sẽ xấu nếu không có Mậu Thổ.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có cục Chính Tài Thiên Tài:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tốt nhất là có cả Bính hoả và Quý Thủy trong lá số.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi chỉ có Quý Thủy là Dụng Thần thì ngày sinh này sẽ chỉ sống cuộc đời
        trung bình.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Cục Chính Quan Thất Sát:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Thổ nhiều ở Địa Chi thì sẽ không thể hình thành cách cục Tòng Tài.
        Không được có hành Thủy hay Mộc thì mới có thể hình thành được.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Sẽ tốt nếu nhiều Thổ Khí.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Ngay cả nếu Quý Thủy được sử dụng mà không có Tân Kim thì cũng sẽ
        thiếu sức mạnh. Nếu thiếu Tân Kim Thất Sát thì thủy rất mong manh.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Địa Chi Thìn Tuất Sửu Mùi có mặt thì sẽ có thể tạo thành cục Tòng
        Tài.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Đối với cục Tòng Tài hình thành được thì cần phải thiếu đi Tỷ Kiếp và
        Ấn Tinh.
      </p>
    </div>
  );
};

export const AtThangHoi = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Ất trong tháng Hợi
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với ngày sinh Ất trong tháng Hợi thì hình thành cục Chính Ấn khi
        Nhâm Thủy xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngay cả nếu như Nhâm Thủy không xuất hiện ở Thiên Can thì cũng có thể
        hình thành cục Chính Ấn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Giáp Mộc xuất hiện ở Thiên Can thì có thể hình thành cục Dương Nhẫn
        nếu thỏa các điều kiện cũng như được Địa chi hỗ trợ.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với sinh trong tháng Hợi thì Bính Hỏa và Mậu Thổ là dụng thần điều
        hậu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Bính Hỏa là dụng thần số một của ngày sinh này. Mậu Thổ là dụng thần số
        hai để kiểm soát Thủy.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Thủy vượng trong tháng này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khí sẽ lạnh cũng như Mộc ẩm ướt.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Do đó Hỏa là dụng thần không thể thiếu đối với ngày sinh này. Điều này
        nghĩa là khi gặp Thổ, ngày sinh này sẽ đủ mạnh để kiểm soát, dẫn đến sự
        giàu có và thịnh vượng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nhâm Thủy và Giáp Mộc đặc biệt mạnh trong tháng Hợi. Nói cách khác, Mộc
        Khí có căn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nhiều Hỏa Khí sẽ dẫn đến nhiều Thổ vượng cũng là Tài Tinh của ngày sinh
        này. Trong tình huống này thì sự xuất hiện của Kim và Thủy đồng thời sẽ
        giúp cho người này nổi tiếng và sang quý trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Hỏa và Thổ là dụng thần Phù Ức tốt nhất cho lá số này. Thiếu cả hai hành
        trên thì có thể dẫn đến ngày sinh này phải vất vả trong việc tìm hạnh
        phúc và ổn định trong đời.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Nhâm Thủy và Quý Thủy ở Thiên Can, Mậu Thổ có căn gốc sẽ có thể
        được dùng đê kiểm soát Thủy. Điều này bởi vì Mậu Thổ là một trong những
        dụng thần Phù Ức và Điều Hậu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khí của Bính Hỏa nhanh chóng bị suy giảm trong tháng Hợi. Do đó, mục
        đích của Bính Hỏa như là dụng thần có thể cung cấp hơi ấm cho ngày sinh
        này. Bính Hỏa không sinh ra hay khắc chế các ngũ hành khác nên cũng
        không hỗ trợ hay làm suy yếu can ngày.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Do đó, cần tránh gặp Nhâm Thủy và Quý thủy quá nhiều. Điều này bởi vì
        có năng lực để khắc chế và làm suy yếu Bính Hỏa không cung cấp được hơi
        ấm.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Thủy quá nhiều thì Mậu Thổ cần dùng để kiểm soát Thủy.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Thủy vốn mạnh trong tháng Hợi. Và khi Bính Hỏa và Mậu Thổ xuất hiện ở
        Thiên Can thì ngày sinh sẽ có thể giàu có và danh tiếng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Địa Chi hình thành Thủy Cục khi thiếu Mậu Thổ ở Thiên Can thì ngày
        sinh có thể mất định hướng sống.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi thấy Kỷ Thổ thì ngày sinh này sẽ chỉ có thể sống cuộc đời trung
        bình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Địa Chi hình thành Mộc Cục – nếu thiếu Bính Hỏa và Mậu Thổ thì
        ngày sinh này vẫn có thể thành công trong đời mặc dù cũng có nguy cơ gặp
        một chuỗi thất bại đến cuối đời. Điều này bởi vì Thủy sẽ cuốn trôi Mộc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Mậu Thổ nên được chọn là Dung Thần, tốt hơn là Kỷ Thổ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tốt nhất là người này nên gặp Bính Hỏa và Mậu Thổ lộ ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu như không có Bính Hỏa Thương Quan để sinh ra Mậu Thổ Chính Tài thì
        ngày sinh này cần phải bước vào đại vận Hỏa để được may mắn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu người này gặp Nhâm Thủy ở Thiên Can và thiếu Mậu Thổ Chính Tài thì
        ngày sinh này sẽ sống bấp bênh và không ổn định.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số hình thành Cục Ấn: </b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Thủy hình thành cục Ấn và Mậu Thổ không thể kiểm soát Thủy thì ngày
        sinh này sẽ lười nhác.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Mậu Thổ sẽ được chọn làm Dụng Thần của Cục. Nếu không có thì có thể dùng
        Kỷ Thổ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá sô này sẽ xấu nếu Hợi Tý và Sửu hình thành Thủy Cục và Mậu Thổ không
        có căn ở Địa Chi.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Lý tưởng nhất là lá số này gặp Bính Hỏa Thương Quan để sinh ra Tài
        Tinh Thổ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi có Nhâm Thủy và Quý Thủy ở Thiên Can, Mậu Thổ rất cần để kiểm soát
        Thủy. Trong tháng Hợi, chức năng của Mậu Thổ là kiểm soát Thủy.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi hình thành Tài Cục – nếu có Mậu Thổ ở Thiên Can nhưng Bính Hỏa ẩn
        tàng và Tỷ Kiên chiếm Can Tháng và Can Giờ, ngày sinh này sẽ thiếu mục
        tiêu và định hướng trong đời. Người này sẽ ích kỷ và cứng đầu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Nhâm Thủy hay Quý Thủy tạo thành Thủy Cục trong khi Bính Hỏa
        Thương Quan, Mậu Thổ Chính Tài và Canh Kim Chính Quan không xuất hiện ở
        Thiên Can thì ngày sinh này bất kể giới tính sẽ nghèo.
      </p>
    </div>
  );
};
