export const AtMoc = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "70%", margin: "0 auto" }}>
        <h1 style={{ textAlign: "center", color: "green", fontWeight: "bold" }}>
          Chương II: Ất Mộc <br /> Ngày Sinh Ất Mộc
        </h1>
      </div>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Dụng Thần Điều Hậu</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b>• Sinh Vào Mùa Xuân</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Dần: 05/02 – 05/03
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Bính Hỏa, Quý Thủy
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Mão: 06/03 – 04/04
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Bính Hỏa, Quý Thủy
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Thìn: 05/04 – 05/05
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Bính Hỏa, Quý Thủy, Mậu Thổ
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b>• Sinh Vào Mùa Hạ:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Tỵ: 06/05 – 05/06
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Quý Thủy
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Ngọ: 06/06 – 07/07
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Bính Hỏa, Quý Thủy
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Mùi: 08/07 – 07/08
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Bính Hỏa, Quý Thủy
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b>• Sinh Vào Mùa Thu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Thân: 08/08 – 07/09
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Bính Hỏa, Quý Thủy, Kỷ Thổ
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Dậu: 08/09 – 08/10
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Bính Hỏa, Quý Thủy, Đinh Hỏa
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Tuất: 09/10 – 07/11
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Quý Thủy, Tân Kim
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b>• Sinh Vào Mùa Đông:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Hợi: 08/11 – 07/12
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Bính Hỏa, Mậu Thổ
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Tý: 08/12 – 05/01
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Bính Hỏa
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Sửu: 06/01 – 03/02
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Bính Hỏa
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Lưu ý: Các bạn cần lưu ý những dụng thần điều hậu phía trên chỉ mang
        tính chất tham khảo. Nếu cần biết thêm về các dụng thần điều hậu, cũng
        như các dụng thần khác trong lá số bát tự bạn cần tham khảo hoặc tư vấn
        từ các chuyên gia.
      </p>

      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Hình Tượng</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người sinh ngày Ất Mộc thường tựa như bông hoa xinh đẹp và mềm mại.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ất Mộc là Âm Mộc, thường có vẻ ngoài dịu dàng, ăn nói mềm mỏng và dễ gây
        thiện cảm với người đối diện. Họ thường rất sáng tạo, giỏi ăn nói. Nếu
        bạn mời một người Ất Mộc đến một buổi tiệc, họ sẽ ăn mặc rất quý phái,
        ăn nói nhỏ nhẹ và biết cách cư xử như một người lịch thiệp nhất.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Ất Mộc rất giỏi trong khoa ăn nói vì họ có đầu óc suy nghĩ nhanh
        nhạy và sắc bén. Họ luôn khôn ngoan, hài hước và hoạt ngôn cũng như biết
        cách nói cho con kiến trong hang cũng phải bò ra.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ rất khéo léo trong giao tiếp và cư xử với người xung quanh. Cũng tựa
        như các con côn trùng dễ bị cây hoa toả ra mùi hương thu hút. Bạn rất dễ
        dàng bị một người Ất Mộc thu hút mà không biết mình đang bị sự duyên
        dáng của họ lôi cuốn một cách âm thầm.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi phải tranh luận hay thảo luận về một chủ đề nào đó, người Ất Mộc
        thường chấp nhận nghe theo quan điểm của người khác để giữ hoà khí. Vì
        lẽ đó mà họ thường bị đánh giá là nhạt, thiếu quan điểm riêng hoặc gió
        chiều nào xoay chiều ấy, ba phải. Họ thường nghĩ ra được giải pháp để
        vừa lòng tất cả mọi người.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Ất Mộc cũng như cây hoa luôn tìm được ánh sáng mặt trời và sống
        sót trong rừng rậm. Người Ất Mộc không bao giờ tuyệt vọng. Họ luôn tìm
        được cách vượt lên trên nghịch cảnh và là người cuối cùng đứng vững
        trong khi những người khác đều đã gục ngã.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Điều này là bởi vì bản chất của Ất Mộc luôn là uyển chuyển và linh hoạt.
        Họ suy nghĩ nhanh và họ luôn biết cách vượt qua những tình huống khó
        khăn theo cách sáng tạo.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ thường bị đánh giá là toan tính và đôi khi là hơi thủ đoạn để đạt
        được lợi ích.
      </p>

      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Tính Cách</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Ất Mộc thường có đầu óc sắc bén có thể giúp xử lý mọi khó khăn và
        rắc rối bằng nhiều cách khác nhau. Người Ất Mộc có một sức mạnh có là
        khả năng nhìn nhận vấn đề dưới nhiều góc nhìn đa chiều khác nhau.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Cách suy nghĩ của họ thường không bị ảnh hưởng bởi những quan điểm định
        kiến hay niềm tin cố hữu. Ngoài ra, họ có khả năng suy nghĩ linh hoạt và
        mặc dù đây cũng là điểm yếu vì họ ít khi chú ý đến chi tiết mà thường
        xem xét mọi việc trên bề mặt. Nói chung, suy nghĩ của họ thường thiếu
        chiều sâu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tuy nhiên, khi cần thiết, họ cũng có thể trở nên rất tính toán và biết
        cách xoay xở trong mọi tình huống. Họ thường ít suy nghĩ đến những giải
        pháp lâu dài hay kiên nhẫn vì họ thường ít làm mọi thứ nhanh chóng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Cũng như một cây hoa dễ bị gió thổi đong đưa, họ thường ít có khả năng
        bình tĩnh, điềm đạm hay suy nghĩ logic hay phân tích cẩn thận. Họ thường
        tham lam tiền bạc và vật chất khiến cho họ dễ hi sinh đạo đức hay lờ đi
        lý trí.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Ất Mộc thường dễ mất bình tĩnh và nóng giận. Họ thường để cho sự
        giận dữ và cảm xúc xen vào suy nghĩ logic của họ. Sự giận dữ của họ
        thường khiến cho họ trở nên giỏi hoặc trở nên tệ hại. Họ có thể trở nên
        cáu bẳn trong thời gian ngắn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tuy nhiên, họ cũng sẵn sàng suy nghĩ và bỏ qua suy nghĩ bản thân mình để
        làm vừa lòng người khác.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Ất Mộc nói chung rất siêng năng và có năng lực giải quyết khó khăn
        và biết rõ điểm mạnh và điểm yếu của từng tình huống. Vấn đề là đôi khi
        họ không ra được quyết định chính xác vì đôi khi phán đoán của họ thường
        không độc lập cũng như cỏ bị thổi theo nhiều chiều trong gió.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngay cả khi họ đã ra được 1 quyết định đúng, họ cũng không tự tin mà
        thường phải tham khảo ý kiến của rất nhiều người để xác nhận. Chính điều
        này làm cho họ chậm trễ trong khâu hành động và họ thường loay hoay
        không biết mình có quyết định đúng hay không.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ có xu hướng chỉ cân nhắc những kết quả ngắn hạn và khả năng lên kế
        hoạch dài hạn kém. Nếu cấp trên yêu cầu họ lên kế hoạch dài hạn, họ
        thường phạm nhiều sai lầm trong phán đoán vì họ chỉ quen những gì nhanh
        chóng và ngắn hạn.
      </p>

      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Mối Quan Hệ/ Tình Cảm</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Ất Mộc thường giỏi tạo dựng mối quan hệ và bạn bè cũng như đồng
        nghiệp rất dễ ảnh hưởng đến suy nghĩ của họ. Nếu họ có bạn bè thông minh
        và tri thức thì họ có khả năng nâng cấp suy nghĩ và tư tưởng của mình
        lên đẳng cấp cao.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khả năng suy nghĩ và phán đoán của họ thường bị ảnh hưởng lớn bởi quan
        điểm của những người xung quanh họ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Ất Mộc giỏi tưởng tượng và tâm hồn họ thường treo ngược cành cây.
        Khi sự tưởng tượng của họ chạm phải thực tế, thì sự tưởng tượng của họ
        thường ảnh hưởng tiêu cực đến tiến trình suy nghĩ thực tế của họ. Họ cần
        có những bạn bè có thể giúp họ vượt qua giới hạn của bản thân.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với hầu hết người Ất Mộc, sự tự tin của họ đến từ sự công nhận của
        người xung quanh đối với sự nghiệp của họ. Họ rất cần sự nể trọng từ
        người khác đối với thành tích trong sự nghiệp của họ. Sự tự tin của họ
        sẽ tăng cao nếu họ được khen ngợi hay thừa nhận về thành quả của họ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu bạn bè hay đồng nghiệp ghen tỵ với họ thì họ cảm thấy rất tự tin,
        khiến cho họ cảm thấy bản thân mình đủ giỏi giang. Khi người khác đánh
        giá cao họ, họ cảm thấy như vụt bay lên thiên đường.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngược lại, nếu người khác coi thường họ, hay cảm thấy họ thấp kém, người
        Ất Mộc thường cảm thấy tệ hại. Nói cách khác, người Ất Mộc thường chính
        là tấm gương phản chiếu lại những gì người xung quanh nghĩ về họ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Ất Mộc thường cảm thấy cần sự quan tâm của người khác và họ sẵn
        lòng làm mọi điều cần thiết để lôi kéo sự chú ý từ người khác. Về lâu
        dài, nếu họ chỉ dựa vào ý kiến của người khác để xây dựng sự tự tin của
        bản thân thì điều này có thể khiến cho họ trở nên tự ti khi người xung
        quanh đánh giá tiêu cực về họ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Xét về tình cảm bạn bè và mối quan hệ cá nhân, người Ất Mộc luôn có xu
        hướng muốn thể hiện niềm tin và lý tưởng riêng của họ đối với người
        khác, tạo nên mối quan hệ căng thẳng. Mặc dù ý định của họ không phải là
        lừa gạt, những điều này sẽ khiến cho mối quan hệ tình cảm bạn bè không
        tốt.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Vì giá trị của người Ất được dựa trên những gì họ nghĩ về chính mình. Họ
        không ngần ngại thể hiện ra kỹ năng, tài năng của mình, và nắm bắt lấy
        bất kỳ cơ hội đến tay họ. Đó là bởi vì họ biết rõ là người Ất Mộc có
        hướng cảm nhận bản thân theo những gì người xung quanh nghĩ về họ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Bất cứ lúc nào họ trao đổi với người khác, họ ít khi thật sự cảm thấy
        thoải mái trao đổi ý tưởng. Thay vào đó, họ sẽ biến mọi cuộc tranh luận
        trở thành cãi nhau. Và họ bắt buộc phải thắng trong cuộc tranh luận đó.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Những người khác sẽ cảm thấy khó thích họ vì lý do này. Không ai thích
        tranh luận với một người luôn muốn thắng một cuộc tranh cãi. Bởi vì điều
        này, người Ất Mộc sẽ thường gặp khó khăn trong việc kéo dài một mối quan
        hệ.
      </p>

      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Sự Nghiệp/ Công Việc</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Ất Mộc nên học cách tách biệt giữa sự nghiệp và đời sống cá nhân.
        Sự tự tin của họ không nên chỉ được xây dựng dựa trên thành công trong
        sự nghiệp hay từ sự khen ngợi, đánh giá từ người xung quanh. Họ nên tự
        đánh giá bản thân mình theo góc nhìn bớt khắt khe hơn và dựa trên những
        giá trị mà mình cảm thấy phù hợp với mình chứ không phải cố ý làm vừa
        lòng người xung quanh.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Ất Mộc luôn linh hoạt và muốn được xem là người giỏi nhất. Họ
        thường rất thông minh, sáng tạo và nghĩ ra nhiều cách giải quyết khó
        khăn. Tuy nhiên họ lại thường không tự tin đưa ra sáng kiến mà luôn tìm
        sự đồng thuận từ người khác.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Ất Mộc thường hay nhảy việc, nếu như công việc đang làm khiến cho
        họ cảm thấy buồn chán hay không còn động lực, họ không ngần ngại đổi
        việc. Họ cần sự kích thích hoặc động lực thúc đẩy trong công việc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Ất Mộc giỏi trong việc toan tính, thủ đoạn để đạt được kết quả
        mình muốn. Đôi khi họ thành công trong việc điều khiển người khác theo ý
        mình, tuy nhiên đôi khi những người xung quanh khám phá ra họ bị lợi
        dụng bởi người Ất Mộc nên khiến cho kết quả ngược lại, làm cho người
        xung quanh khó chịu vì bị điều khiển.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Ất Mộc giỏi trong vai trò là lãnh đạo. Họ biết cách quản lý và dẫn
        dắt không phải với tư cách là một lãnh đạo từ tháp ngà phòng họp. Họ
        thích hoà đồng với nhân viên và muốn lãnh đạo dựa trên quan hệ tình cảm
        tốt.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ thích tạo ra nhiều hoạt động và sự kiện mà nhân viên và gia đình có
        thể cùng tham gia với nhau ví như các buổi tiệc, họp mặt, đội nhóm cùng
        đi bar hay club.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ không thuộc tuýp người thích chỉ trích hay la mắng nhân viên. Thay
        vào đó, họ thích hướng dẫn nhẹ nhàng cho người khác và cũng như nuôi
        dưỡng và chỉ dạy người khác thông qua tài ăn nói khéo léo của họ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Ất Mộc thường không thuộc dạng người gây áp lực cho nhân viên bằng
        cách giao hàng đống công việc vào phút chót. Họ thường tránh tạo ra căng
        thẳng trong mối quan hệ. Do đó nhiều nhân viên thường nhìn sếp Ất Mộc là
        người rất tử tế và muốn lợi dụng người này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Về lâu dài, người Ất Mộc nên cân nhắc phong cách lãnh đạo cứng rắn hơn
        khi cần thiết. Nếu không thì những người gian xảo dễ lợi dụng họ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Ất Mộc vẫn có thể thân thiện với cấp dưới nhưng cần biết giới hạn.
        Họ cần giữ khoảng cách để phát triển kỹ năng lãnh đạo của mình.
      </p>

      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Kinh Doanh</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Trong kinh doanh, người Ất Mộc thường giỏi trong việc sử dụng con người
        để giúp đỡ họ đạt được mục tiêu. Nói cách khác, việc tận dụng thời gian
        và năng lực của người khác là một trong những mấu chốt trong việc xây
        dựng sự nghiệp kinh doanh thành công của người Ất Mộc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Ất Mộc rất chân thành trong kinh doanh, họ thường tin rằng sản
        phẩm và dịch vụ tốt đồng nghĩa với kinh doanh thành công. Họ dễ tin vào
        mô hình kinh doanh sẽ tạo ra quảng cáo truyền miệng và nếu bán sản phẩm
        kém dễ dẫn đến kinh doanh thất bại. Vì lẽ này, họ nên khởi nghiệp trong
        những ngành kinh doanh mà họ yêu thích sản phẩm hay dịch vụ đó. Kết hợp
        sự yêu thích và kinh doanh sẽ là một lợi thế rất lớn của người này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Ất Mộc có nguyên tắc và lý tưởng, họ có một niềm tin mạnh mẽ trong
        giao tiếp với người khác. Họ có bản tính thân thiện, thích giúp đỡ và
        làm bạn với người khác, và họ thích thể hiện hay hướng dẫn cho người
        khác cách thức đúng để hoàn thành mọi việc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đôi khi, người Ất Mộc quên mất rằng “thuốc bổ của người này có thể là
        thuốc độc của người khác” và cố nhồi nhét niềm tin của họ vào người
        khác. Người nào tiếp nhận những lời của họ có thể trở nên nổi nóng hoặc
        im lặng chịu đựng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Ất Mộc thường là những người khôn ngoan, vấn đề chỉ là họ sử dụng
        sự khôn ngoan đó vào việc gì. Trong tình bạn, dù với bạn thân hay đồng
        nghiệp, đầu óc khôn ngoan khiến họ luôn muốn đạt được mọi điều theo ý
        mình muốn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ có mồm miệng khôn khéo. Hầu như mọi lúc, người Ất Mộc có thể rất tính
        toán trong việc tìm lợi ích về phía mình. Đôi khi, họ có đè lên người
        khác để giành ánh hào quang về phía mình. Điều này hơi độc ác, dù đôi
        khi chỉ để thoả mãn cái tôi của mình, họ phải có được thứ mà mình muốn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Bởi vì điều này, họ có thể làm phật ý người khác hay xua đuổi người khác
        đi xa khỏi mình vì quá tính toán. Không phải là họ sẽ nhường nhịn vào
        một lúc nào khác như kiểu cho đi và lấy lại, người Ất Mộc sẽ khá tàn
        nhẫn và luôn tính toán.
      </p>

      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Tài Lộc</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Ất Mộc thường im lặng khi nói đến chủ đề tiền bạc hay tài chính.
        Họ thường thiếu tự tin để ra những quyết định lành mạnh về tài chính và
        thường do dự nhiều trong hành động.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Kết quả là họ có xu hướng theo đuổi những trào lưu và tư tưởng phổ biến
        hiện thời trong thị trường tài chính một cách mù quáng mà không phân
        tích hay tự đánh giá. Họ có xu hướng nhảy vào đoàn tàu kiếm tiền theo
        trào lưu mà đám đông hùa nhau.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Bởi vì thiếu suy nghĩ về tài chính và không thể rút ra kết luận, họ
        thiếu khả năng lên kế hoạch tài chính. Họ dễ phá sản.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Trong quản lý tài chính, họ giao phó toàn bộ cho suy nghĩ và quyết định
        cho người khác. Họ sẽ bắt chước theo người khác và thích lao vào đầu tư
        như một trò cờ bạc mà không có nghiên cứu tính toán mà chỉ dựa trên vận
        may.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Bởi vì điều này, khi họ bị rơi vào mối đầu tư xấu, thì họ hoàn toàn
        không biết cách thoát ra khỏi tình huống xấu. Họ sẽ tiếp tục phóng lao
        theo lao, và làm cho mọi tình huống trở nên tệ hại hơn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ dễ lao theo các chương trình, kế hoạch làm giàu dễ dàng và thường
        nghe theo lời những người tự nhận mình là chuyên gia tài chính mà có thể
        kiến thức còn ít hơn cả họ.
      </p>
    </div>
  );
};
