import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Collapse,
  CardBody,
  Card,
} from "reactstrap";
import { BsList } from "react-icons/bs";
import "../scss/thansat.scss";
import logo from "../assets/logo.png";
import { TieuNhiList } from "./renderTieuNhi";

const ModalPopup = (props) => {
  const { className, open, close, value } = props;

  const [isOpen, setIsOpen] = useState(true);
  const [title, setTitle] = useState();
  const [text, setText] = useState();

  const handleClick = (e) => {
    e.currentTarget.classList.add("active");
    const p = document.getElementsByClassName("chiday");

    for (const item of p) {
      if (item.textContent !== e.currentTarget.textContent)
        item.classList.remove("active");
    }

    const modal = document.getElementsByClassName("modal");

    let value = e.currentTarget.textContent;

    const content = TieuNhiList(value);
    setText(content);
    setTitle(`${value}`);
    modal[0].scrollTo(0, 0);
  };
  const toggle = () => close(false);

  useEffect(() => {
    let content = TieuNhiList(value);
    setText(content);
    setTitle(`${value}`);
  }, [value]);

  return (
    <div>
      <Modal toggle={toggle} size="xl" isOpen={open} className={className}>
        <ModalHeader
          style={{
            background: "#1B61A6",
            color: "#fff",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button onClick={() => setIsOpen(!isOpen)} className="icon-modal">
            <BsList />
          </button>
          <b>DIỄN GIẢI TIỂU NHI QUAN SÁT</b>
        </ModalHeader>
        <ModalBody style={{ display: "flex" }}>
          <Collapse isOpen={isOpen}>
            <Card>
              <CardBody>
                <p className="chiday" onClick={handleClick}>
                  Diêm Vương Quan Sát
                </p>
                <p className="chiday" onClick={handleClick}>
                  Thiên Điếu Quan Sát
                </p>
                <p className="chiday" onClick={handleClick}>
                  Tứ Quý Quan Sát
                </p>
                <p className="chiday" onClick={handleClick}>
                  Hòa Thượng Quan Sát
                </p>
                <p className="chiday" onClick={handleClick}>
                  Kim Tỏa Quan Sát
                </p>
                <p className="chiday" onClick={handleClick}>
                  Lạc Tỉnh Quan Sát
                </p>
                <p className="chiday" onClick={handleClick}>
                  Thâm Thủy Quan Sát
                </p>
                <p className="chiday" onClick={handleClick}>
                  Ngũ Quỷ Quan Sát
                </p>
                <p className="chiday" onClick={handleClick}>
                  Bách Nhật Quan Sát
                </p>
                <p className="chiday" onClick={handleClick}>
                  Bạch Hổ Quan Sát
                </p>
                <p className="chiday" onClick={handleClick}>
                  Thang Hỏa Quan Sát
                </p>
                <p className="chiday" onClick={handleClick}>
                  Thiên Cẩu Quan Sát
                </p>
                <p className="chiday" onClick={handleClick}>
                  Dục Bồn Quan Sát
                </p>
                <p className="chiday" onClick={handleClick}>
                  Tứ Trụ Quan Sát
                </p>
                <p className="chiday" onClick={handleClick}>
                  Lôi Công Quan Sát
                </p>
                <p className="chiday" onClick={handleClick}>
                  Đoản Mệnh Quan Sát
                </p>
                <p className="chiday" onClick={handleClick}>
                  Đoạn Kiều Quan Sát
                </p>
                <p className="chiday" onClick={handleClick}>
                  Thiên Nhật Quan Sát
                </p>
                <p className="chiday" onClick={handleClick}>
                  Tướng Quân Tiễn Quan Sát
                </p>
                <p className="chiday" onClick={handleClick}>
                  Kim Xà Quan Sát
                </p>
                <p className="chiday" onClick={handleClick}>
                  Điểu Phi Quan Sát
                </p>
                <p className="chiday" onClick={handleClick}>
                  Quỷ Môn Quan Sát
                </p>
                <p className="chiday" onClick={handleClick}>
                  Dạ Đề Quan Sát
                </p>

                <p className="chiday" onClick={handleClick}>
                  Thủy Hỏa Quan Sát
                </p>
                <p className="chiday" onClick={handleClick}>
                  Hạ Tình Quan Sát
                </p>

                <p className="chiday" onClick={handleClick}>
                  Cấp Cước Quan Sát
                </p>
                <p className="chiday" onClick={handleClick}>
                  (*) Luận Về Quan Sát
                </p>
              </CardBody>
            </Card>
          </Collapse>
          {isOpen ? (
            <div className={isOpen ? "content-modal-show" : "content-modal"}>
              <div className="watermark-hoagiap">
                <img
                  style={{
                    position: "relative",
                    left: "50%",
                    transform: "translateX(-30%)",
                  }}
                  src={logo}
                />
              </div>

              <h3 style={{ textTransform: "uppercase" }}>{title}</h3>
              <div style={{ maxHeight: "700px", overflowY: "scroll" }}>
                {text}
              </div>
            </div>
          ) : (
            ""
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => close(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalPopup;
