export const CanhThangTi = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h1 style={{ textAlign: "center", fontWeight: "bold" }}>
          Chương I: NGÀY SINH CANH TRONG 12 THÁNG
        </h1>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Canh trong tháng Tý
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng này thì nếu có Quý Thủy hiện lên ở Thiên
        Can thì xem là hình thành cục Thương Quan.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng này thì dụng thần điều hậu là Đinh Hỏa,
        Giáp Mộc và Bính Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đinh Hỏa và Giáp Mộc là dụng thần chính cho ngày sinh Canh Kim trong
        tháng Tý. Ngoài ra, Bính Hỏa, vốn là dụng thần thứ hai, đóng vai trò
        cung cấp hơi ấm cho ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu không có Hỏa thì ngày sinh này sẽ cô đơn, thiếu sức sống.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Trong trường hợp Canh Kim sinh trong tháng Tý thì Kim sẽ lạnh và Nước
        đóng băng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Thủy Khí quá nhiều, Kim sẽ bị suy yếu. Do đó lý tưởng là phải gặp đủ
        Hỏa để sưởi ấm ngày sinh Canh Kim đảm bảo Thổ phải được ấm và mạnh mẽ đủ
        để kiểm soát Thủy.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Chỉ khi có sự hiện diện của Thổ ấm nóng thì ngày sinh Canh Kim mới có
        thể sống sót.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Hỏa xuất hiện ở Thiên Can thì lúc đó Mộc mới được xem là Dụng Thần.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngoài ra, Thổ nóng có thể được dùng để kiểm soát Thủy. Ngoài ra, sự hiện
        diện và hỗ trợ của Mộc sẽ giúp sinh ra vào tạo tính liên tục của Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Chỉ có Mộc ấm áp mới sinh ra Hỏa giúp hỗ trợ cho phép Hỏa được cháy mạnh
        mẽ như là dụng thần của ngày sinh Canh Kim.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Bính Hỏa và Đinh Hỏa là dụng thần chính trong khi Giáp Mộc đóng vai
        trò là hỉ thần.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Quý Thủy không nên xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Ngày sinh Canh Kim sinh trong mùa đông cần có Bính Hỏa đóng vai trò
        điều hậu. Chỉ khi đó thì Đinh Hỏa mới có thể tiếp tục giữ cho Canh Kim
        ấm áp và cân bằng.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Giáp Mộc có thể được dùng để duy trì Hỏa Khí của lá số này. Thật sự,
        không có kịch bản nào tốt hơn cho ngày sinh này là có những dụng thần
        cần thiết đều xuất hiện.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Cần phải chú ý đến Nhâm Thủy và Quý Thủy, nếu xuất hiện ở Thiên Can
        thì sẽ làm suy yếu và giảm tác dụng của Bính Hỏa và Đinh Hỏa như là dụng
        thần.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tương tự, sẽ bất lợi nhiều cho ngày sinh này nếu có thêm Canh Kim xuất
        hiện ở Thiên Can, vì Canh Kim sẽ chặt Giáp Mộc dụng thần.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Đinh Hỏa và Giáp Mộc đồng xuất hiện với Bính Hỏa ở Thiên Can thì
        ngày sinh này sẽ rất thành công trong cuộc sống.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Đinh Hỏa và Giáp Mộc cùng xuất hiện và không có Bính Hỏa thì ngày
        sinh Canh Kim sẽ cũng thành công trong đời mặc dù mức độ thành công giới
        hạn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi có xuất hiện Giáp Mộc nhưng không có Đinh Hỏa thì ngày sinh Canh
        Kim chỉ sống cuộc đời trung bình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Bính Hỏa ở Thiên Can và Đinh Hỏa ở tàng can Địa Chi thì ngày sinh
        này sẽ có thành công bất ngờ.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Thất Sát, Thiên Tài và Chính Quan:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Đinh Hỏa, Giáp Mộc và Bính Hỏa đồng xuất hiện thì lá số này xuất
        sắc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Đinh Hỏa xuất hiện nhưng thiếu Giáp Mộc thì ngày sinh này sẽ tạo ra
        tài lộc từ hai bàn tay trắng mà không được gia đình trợ giúp.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Đinh Hỏa và Giáp Mộc cùng xuất hiện nhưng không có Bính Hỏa thì có
        Phú nhưng không Quý.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số tốt nếu có Đinh Hỏa và Giáp Mộc cùng xuất hiện ở Thiên Can trong
        khi Bính Hỏa ở Địa Chi.{" "}
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Quý Thủy Thương Quang xuất hiện ở Thiên Can thì ngày sinh này thiếu
        động lực thành công trong đời.{" "}
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Bính Hỏa và Đinh Hỏa là dụng thần ưu tiên. Nếu thiếu Bính Hỏa và Đinh
        Hỏa thì ngày sinh này nghèo và bất mãn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Quý Thủy lộ Thiên Can che mờ Bính Hỏa thì ngày sinh này sẽ vất vả
        mưu sinh cả đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Địa Chi tạo thành Thủy Cục nhưng Nhâm Thủy và Quý Thủy không xuất
        hiện ở Thiên Can và có Mậu Thổ Thiên Ấn thì người này vẫn đạt thành công
        tương đối và may mắn trong đời, ngay cả nếu Bính Hỏa và Đinh Hỏa không
        xuất hiện.
      </p>
    </div>
  );
};

export const CanhThangSuu = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h1 style={{ textAlign: "center", fontWeight: "bold" }}>
          Chương I: NGÀY SINH CANH TRONG 12 THÁNG
        </h1>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Canh trong tháng Sửu
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng này thì nếu có Kỷ Thổ hiện lên ở Thiên
        Can thì xem là hình thành cục Chính Ấn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Quý Thủy hiện lên ở Thiên Can thì hình thành cục Thương Quan.{" "}
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Tân Kim hiện lên ở Thiên Can thì hình thành cục Dương Nhẫn.{" "}
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng
        có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy ý.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng này thì dụng thần điều hậu là Bính Hỏa,
        Đinh Hỏa và Giáp Mộc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đinh Hỏa và Giáp Mộc là dụng thần lý tưởng. Ngoài ra Bính Hỏa là dụng
        thần phụ giúp đem đến hơi ấm cho ngày sinh lạnh giá.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu không có Hỏa Khí thì không thể tìm được dụng thần nào khác.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khí Bính Hỏa và Đinh Hỏa xuất hiện ở Địa Chi Dần, Tỵ, Ngọ, Mùi và Tuất
        sẽ chứa đủ sức mạnh để xem như dụng thần cho ngày sinh Canh Kim.{" "}
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Sửu là khố chứa nhiều Kim Khí.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Vào thời điểm này trong năm thì khá lạnh giá rét buốc trong khi mặt đất
        phủ đầy tuyết. Đất băng giá.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Trong tình huống như vậy thì sẽ khó để Thổ sinh ra được củng cố Kim trừ
        khi Thổ được suổi ấm đến mức nào đó bởi Hỏa. Và Mộc sẽ cần để sinh ra
        đảm bảo Hỏa liên tục.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Mộc và Hỏa là dụng thần không thể thiếu đối với ngày sinh Canh Kim trong
        tháng Sửu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Mặc dù tháng Sửu chứa Kim ẩn tàng nhưng Mộc cũng còn rất yếu trong mùa
        Đông. Do đó chỉ có Mộc ấm áp và khô ráo thì mới có thể sinh ra Hỏa để có
        lợi cho Canh Kim.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu được thì ngày sinh Canh Kim nên tránh gặp thêm Kim và Thủy. Điều này
        bởi vì Thủy sẽ khắc chế Hỏa vốn đang rất cần thiết. Từ đó sẽ khiến cho
        lá số này lạnh giá bất lợi hơn cho Mộc Khí.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Bính Hỏa, Đinh Hỏa và Giáp Mộc là dụng thần lý tưởng cho ngày sinh
        này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nhâm Thủy và Quý Thủy không nên xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Địa Chi tạo thành Kim Cục, nhưng thiếu Đinh Hỏa thì ngày sinh Canh
        này sẽ chịu cô đơn cả đời. (Sự xuất hiện của Bính Hỏa sẽ không giúp ích
        gì cho Canh Kim trong khi Đinh hỏa có thể chỉ làm tồi tệ hơn vấn đề).
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Bính Hỏa và Đinh Hỏa cùng xuất hiện ở Thiên Can trong khi thiếu
        Giáp Mộc trong lá số thì ngày sinh này có tri thức và thông thái, mặc dù
        họ có thể bắt đầu từ con số 0 nhưng có thể vẫn sẽ thành công về cuối
        đời.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Bính Hỏa không có trong lá số thì ngày sinh này chỉ sống đời trung
        bình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Kỷ Thổ ở Thiên Can (vốn là đất ướt ẩm) thì không thể dùng được như
        là dụng thần. Ngày sinh Canh Kim này sẽ có kỹ năng mặc dù họ lại không
        biết cách tận dụng các kỹ năng này để phát triển cuộc sống họ tốt hơn.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Thất Sát, Thiên TÀi và Chính Quan:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có đủ Đinh Hỏa, Giáp Mộc và Bính Hỏa thì lá số này vượt trội.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Đinh Hỏa nhưng thiếu Giáp Mộc thì người này sẽ rất tài năng nhưng
        không gặp được cơ hội để sử dụng tài năng đó.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi có Đinh Hỏa và Giáp Mộc ở Thiên Can nhưng không có Bính Hỏa thì
        người này có vấn đề về tính cách ngăn cản họ thành công. Nếu không có
        Bính Hỏa thì có cảm giác bất mãn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số tốt nếu có Bính Hỏa và Giáp Mộc ở Thiên Can và Đinh Hỏa ở Địa Chi.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số xấu nếu có Nhâm Quý Thủy ở Thiên Can, ngày sinh này sẽ thiếu động
        lực thành công trong đời.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Bính Hỏa và Đinh Hỏa là dụng thần ưu tiên. Nếu thiếu Bính và Đinh Hỏa
        thì ngày sinh này sẽ nghèo và bất mãn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Trong tháng Sửu thì Bính Hỏa nên xuất hiện ở Thiên Can và Đinh Hỏa ở
        Địa Chi.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Giáp Mộc nên kiểm soát Kỷ Thổ trong khi Ất Mộc xếp thứ hai.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Quý Thủy lộ ở Thiên Can che phủ Bính Hỏa thì ngày sinh Canh Kim sẽ
        vất vả mưu sinh cả đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Địa Chi tạo thành Thủy Cục nhưng Nhâm Thủy và Quý Thủy không hiện
        lên Thiên Can thì với Kỷ Thổ Chính Ấn, người này vẫn có thành công tương
        đối nhưng thường gặp vấn đề về sức khỏe.
      </p>
    </div>
  );
};

export const CanhThangDan = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h1 style={{ textAlign: "center", fontWeight: "bold" }}>
          Chương I: NGÀY SINH CANH TRONG 12 THÁNG
        </h1>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Canh trong tháng Dần
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng này thì nếu có Giáp Mộc hiện lên ở Thiên
        Can thì xem là hình thành cục Thiên Tài.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Bính Hỏa hiện lên ở Thiên Can thì hình thành cục Thất Sát.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Mậu Thổ hiện lên ở Thiên Can thì hình thành cục Thiên Ấn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng
        có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy ý.{" "}
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nhâm Thủy và Canh Kim là dụng thần điều hậu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nhâm Thủy là dụng thần chính trong khi Canh Kim là dụng thần phụ.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng này thì dụng thần điều hậu là Mậu Thổ,
        Giáp Mộc, Nhâm Thủy, Bính Hỏa và Đinh Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Bính Hỏa là dụng thần làm ấm Canh Kim. Bởi vì Thổ vẫn còn dày nặng trong
        tháng này nên sẽ chôn vùi Kim. Do đó Giáp Mộc cần để làm tơi Thổ.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Hỏa xuất hiện nhiều thì Thổ cần yếu và để kiểm soát Hỏa. Khi Địa Chi
        tạo thành Hỏa Cục thì Nhâm Thủy sẽ được dùng để kiểm soát Hỏa Khí.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi có Giáp Mộc, Mậu Thổ và Bính Hỏa hiện diện trong lá số Bát Tự thì
        Mộc và Hỏa Khí sẽ thắng Thổ và Kim Khí. Sẽ tốt nếu cả 3 can trên xuất
        hiện ở Thiên Can, sẽ là lá số đặc biệt.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Mộc mạnh và vượng trong tháng mùa Xuân trong khi Kim yếu. Do đó Thổ cần
        được dùng để sinh ra và củng cố Kim, cũng như có thể dùng Giáp Mộc như
        là Tài Tinh tốt.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tuy nhiên sẽ bất lợi cho ngày sinh Canh Kim nếu gặp Thủy. Điều này bởi
        vì Thủy làm suy yếu Kim và cũng sinh ra Mộc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số Canh Kim sinh trong giờ có Hỏa và Thổ mạnh dễ hình thành cục Dần
        Ngọ Hỏa. Lúc đó thì Kim và Thủy được xem là dụng thần trong việc giúp
        ngày sinh này cân bằng.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Canh Kim và Tân Kim là dụng thần phù hợp để hỗ trợ sinh và củng cố
        ngày sinh này. Do đó Bính Hỏa và Giáp Mộc có thể được dùng để điều chỉnh
        và thông quan khí trong lá số.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu được thì ngày sinh Canh Kim không nên gặp Mậu Thổ Thiên Ấn ở Thiên
        Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Trong tháng Dần thì Canh Kim sẽ suy trong khi Mộc khí vượng. Do đó sẽ
        khó để Kim khí có thể kiểm soát những hành khác. Ngoài ra, Dương Thổ
        cũng không thể sinh và hỗ trợ tăng cường Kim.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Bính Hỏa và Giáp Mộc xuất hiện ở Thiên Can thì ngày sinh Canh Kim
        sẽ trở nên nổi tiếng, quyền lực.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu thiếu Bính Hỏa hay Giáp Mộc lộ thì ngày sinh Canh Kim có thể sống
        đời sống trung bình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Thổ xuất hiện nhiều ở Thiên Can và Địa Chi thì cần có Giáp Mộc ở
        Thiên Can. Nếu thỏa điều kiện này thì ngày sinh Canh Kim sẽ thành công
        trong đời, miễn là họ không gặp thêm Canh Kim trong lá số gốc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Hỏa Cục hình thành ở Địa Chi thì Nhâm Thủy cần xuất hiện ở Thiên
        Can. Khi thỏa điều kiện này thì ngày sinh Canh Kim sẽ trở nên nổi tiếng,
        quyền lực.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu thiếu Bính Hỏa và Đinh Hỏa thì ngày sinh Canh Kim sẽ có cuộc sống
        trung bình. Nếu không có Hỏa thì Canh Kim chỉ là cục sắt vô dụng.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Thiên Tài và Thất Sát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Bính Hỏa và Giáp Mộc nên xuất hiện trong lá số thì ngày sinh Canh Kim sẽ
        có thành công.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số tốt nếu có Bính Hỏa và Giáp Mộc cùng xuất hiện ở Thiên Can.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Chính Ấn: </b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Kỷ Thổ Ấn Tinh hiện diện nhưng thiếu Quý Thủy Thương Quan, ngày sinh
        Canh Kim vẫn có thể thành công trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Chỉ có thể sử dụng Kỷ Thổ là dụng thần chứ không thể dùng Mậu Thổ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số xấu nếu như Quý Thủy là Thương Quan lại xuất hiện ở Thiên Can.{" "}
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Thất Sát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Địa Chi tạo thành Hỏa Cục Thất Sát cùng với Nhâm Thủy lộ ở Thiên Can
        thì ngày sinh này sẽ hưởng may mắn trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu xuất hiện Nhâm Thủy ở Thiên Can thì lá số tốt.{" "}
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Nhâm Thủy và Quý Thủy không xuất hiện ở Thiên Can thì ngày sinh
        này sẽ bị tàn tật hoặc có bệnh trong đời.{" "}
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Bính Hỏa và Giáp Mộc là dụng thần lý tưởng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu thiếu Hỏa thì ngày sinh này sẽ nghèo đói.{" "}
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Ngày sinh này không nên dùng Mậu Thổ Thiên Ấn. Dương thổ sẽ không thể
        hỗ trợ Canh Kim.{" "}
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Không tốt nếu Canh Kim có Chính Quan gặp Thương Quan ở Thiên Can.{" "}
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Thổ xuất hiện nhiều trong lá số thì cần có Giáp Mộc lộ Thiên Can.
        Nếu thỏa tiêu chí này thì lá số có cuộc đời trọn vẹn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Ngày sinh Canh Kim sẽ không được hưởng lợi nếu gặp Ất Mộc thay vì Giáp
        Mộc. Ất Mộc sẽ không kiểm soát được Thổ vượng.
      </p>
    </div>
  );
};

export const CanhThangMao = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h1 style={{ textAlign: "center", fontWeight: "bold" }}>
          Chương I: NGÀY SINH CANH TRONG 12 THÁNG
        </h1>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Canh trong tháng Mão
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng này thì nếu có Ất Mộc hiện lên ở Thiên
        Can thì xem là hình thành cục Chính Tài.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng
        có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy ý.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng này thì dụng thần điều hậu là Đinh Hỏa,
        Giáp Mộc, Canh Kim và Bính Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Canh Kim mạnh mẽ nhưng vân còn tăm tối, cần chú ý dùng Đinh Hỏa để thắp
        sáng. Do đó Giáp Mộc giúp mượn và tạo ra Đinh Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Kịch bản lý tưởng là Canh Kim dùng để chặt Giáp Mộc.{" "}
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu thiếu Đinh Hỏa thì Bính Hỏa có thể dùng thay.{" "}
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Sinh vào tháng Mão thì Mộc khí vượng nhất mùa xuân.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Do đó khi Mộc giúp kiểm soát Kim thì Kỷ Thổ Ấn Tinh có thể dùng để sinh
        trợ Kim.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Trong ngũ hành thì Mộc vượng nhất trong tháng này. Do đó có xu hướng gặp
        Canh Kim hình thành Chính Tài và làm suy yếu Canh Kim.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tiếp theo, chỉ có Thổ là phù hợp để sinh ra và củng cố sức mạnh Canh
        Kim.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu xét giờ sinh, nếu giờ sinh Hỏa và Mộc vượng thì sẽ có lợi cho ngày
        sinh này nếu không gặp Thủy. Bởi vì Thủy sẽ sinh và dưỡng thêm cho Mộc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngày sinh Canh Kim trong tháng Mão vào đại vận Kim và Thổ thì cần hợp
        với Kim cục sẽ tốt. Người này sẽ gặp may mắn nhất trong đời.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Ngày sinh Canh Kim trong tháng này nên dùng Đinh Hỏa và Giáp Mộc làm
        dụng thần.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Giáp Mộc ở Thiên Can và Địa Chi và không có Tỷ Kiếp cũng như Ấn
        Tinh thì ngày sinh này sẽ vượng trong đời. Đây là cách cục đặc biệt.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Đinh Hỏa là dụng thần lý tưởng cho ngày sinh Canh Kim trong tháng Mão.
        Nhâm Thủy không nên xuất hiện ở Thiên Can vì sẽ hợp với Đinh Hỏa.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Bính Hỏa và Đinh Hỏa không xuất hiện ở Thiên Can thì ngày sinh này
        sẽ không thể giữ được cuộc sống mình mong muốn dù cố gắng bao nhiêu.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Ngoài ra, Canh Kim trong tháng Dần Mão thì khí Kim bị suy nhưng cũng
        không nên gặp Thổ Ấn Tinh nhiều vì có nguy cơ Thổ vượng sẽ vùi lấp và
        làm Canh Kim nghẹt thở.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Chính Quan và Thiên Tài:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngày sinh Can Kim nên có căn ở Địa Chi thì mới có thể thành công.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Canh Kim có căn gốc ở Địa chi thì biểu thị Canh Kim ổn định. Do đó Canh
        Kim sẽ có lợi nếu gặp Đinh Hỏa và Giáp Mộc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Canh Kim không thông căn Địa Chi thì người này sẽ nghèo khó trong
        đời.{" "}
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Thiên Tài: </b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Địa Chi chủ yếu là Mộc hình thành Tài vượng thì thiếu đi Tỷ Kiếp và
        Ấn Tinh sẽ khiến dễ hình thành cục Tòng Tài. Nếu như vậy thì người này
        sẽ thịnh vượng và rất giàu có trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Giáp Mộc Thiên Tài lộ Thiên can thì may mắn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Thiên Can gặp Tỷ Kiếp thì không tốt.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Giáp Mộc và Ất Mộc xuất hiện cùng lúc thì vai trò của Giáp Mộc sẽ
        trở nên vô dụng.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Canh Kim nên thông căn ở Địa Chi. Lý tưởng nếu có Địa Chi Thân trong
        lá số.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Đinh Hỏa và Giáp Mộc là dụng thần ưu tiên của ngày sinh Canh Kim này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có Tài Tinh thì Giáp Mộc sẽ ưu tiên hơn Ất Mộc. Khi Ất Mộc hiện
        diện thì không được phép hợp với Canh Kim. Hợp sẽ khiến cho Tài Tinh vô
        dụng. Trong cuộc sống của nam giới thì người này sẽ mất đi tính cách,
        phản bội bạn bè vì phụ nữ. Hay tệ hơn là mất hết tài lộc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tốt nhất là tránh Mậu Thổ Thiên Ấn hay Kỷ Thổ Chính Ấn ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Đinh Hỏa Chính Quan là dụng thần thì không nên có Quý Thủy Thương
        Quan ở Thiên Can.
      </p>
    </div>
  );
};

export const CanhThangThin = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h1 style={{ textAlign: "center", fontWeight: "bold" }}>
          Chương I: NGÀY SINH CANH TRONG 12 THÁNG
        </h1>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Canh trong tháng Thìn
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng này thì nếu có Mậu Thổ hiện lên ở Thiên
        Can thì xem là hình thành cục Thiên Ấn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Ất Mộc hiện lên ở Thiên Can thì hình thành cục Chính Tài.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Quý Thủy hiện lên ở Thiên Can thì hình thành cục Thương Quan.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng
        có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy ý.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng này thì dụng thần điều hậu là Giáp Mộc,
        Đinh Hỏa, Nhâm Thủy và Quý Thủy.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đinh Hỏa được xem là dụng thần thích hợp vì Canh Kim là Dương Kim và
        cứng rắn. Do đó Đinh Hỏa giúp rèn giũa Canh Kim khiến cho nó hữu dụng
        hơn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Thổ vượng thì cần Giáp Mộc để kiểm soát. Canh Kim không nên dùng để
        cắt và làm suy yếu Mộc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Địa chi chứa nhiều Hỏa Khí thì cần có Quý Thủy để kiểm soát Hỏa Khí.
        Tương tự, nếu Thiên Can chứa Hỏa Khí quá nhiều thì có thể dùng Nhâm Thủy
        để kiểm soát Hỏa.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Địa chi Thìn chứa tàng can Mậu Thổ, Ất Mộc và Quý Thủy. Thổ khí mạnh
        trong tháng Thìn là khi có Mậu Thổ xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Do đó, nếu Thổ sinh ra Canh Kim, trong khi Thủy đồng thời sinh ra Mộc
        thì ngày sinh Canh Kim sẽ thịnh vượng và giàu có. Cần thiết nên thấy Mộc
        để kiểm soát và làm tơi Thổ từ đó mà Kim phát triển.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Kim rất mạnh thì Hỏa cần dùng để khắc chế và kiểm soát Kim khí. Khi
        đủ Mộc Khí để sinh ra Hỏa thì Tài Tinh và Quan Tinh của ngày sinh Canh
        sẽ vượng do đó giúp cho lá số này sẽ có quyền lực.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Đinh Hỏa và Giáp Mộc là dụng thần ưu tiên cho ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nhâm Thủy và Quý Thủy nên tránh gặp vì cả hai sẽ gây nhiễm bệnh (do
        được tàng chứa ở Thìn quá lâu) và làm suy yếu Canh Kim.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Giáp Mộc sẽ thích hợp, Bính Hỏa cũng nên gặp Nhâm Thủy; Mậu Thổ nên bị
        Giáp Mộc khống chế; Nhâm Thủy nên bị Mậu Thổ khắc chế; trong khi Canh
        Kim nên gặp Đinh Hỏa. Nếu có chu kỳ ngũ hành như vậy trong lá số thì sẽ
        có cuộc đời vĩ đại.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Đinh Hỏa và Giáp Mộc cùng xuất hiện trong lá số nhưng Tỷ Kiếp
        không xuất hiện ở Thiên can thì ngày sinh này sẽ hưởng thành công trong
        sự nghiệp.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Địa Chi gồm 4 Thìn thì Giáp Mộc cần xuất hiện ở Thiên Can. Nếu
        không thì ngày sinh Canh Kim sẽ chịu cô đơn và nghèo khổ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Ất Mộc dùng thay Giáp Mộc thì sức mạnh của Ất Mộc sẽ bị giới hạn
        trong việc làm tơi hành Thổ. Trong hoàn cảnh đó thì ngày sinh Canh Kim
        thiếu động lực và độc lập để thành công trong sự nghiệp.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Đinh Hỏa lộ ở Thiên Can nhưng Giáp Mộc tàng ở Địa Chi thì ngày
        sinh Canh Kim sẽ là người có học thức.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Giáp Mộc ở Thiên Can nhưng Đinh Hỏa tàng ở Địa Chi thì ngày sinh
        Canh Kim sẽ có thành công bất ngờ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Đinh Hỏa và Giáp Mộc đều ẩn tàng ở Địa Chi thì ngày sinh Canh Kim
        sẽ là người có học thức mà không được phát hiện, trân trọng.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Giáp Mộc xuất hiện nhưng thiếu Đinh Hỏa thì người này có cuộc sống
        trung bình.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Đinh Hỏa xuất hiện nhưng thiếu Giáp Mộc thì ngày sinh Canh Kim là
        người giỏi giang tri thức nhưng thiếu tài lộc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Đinh Hỏa và Giáp Mộc đều vắng bóng thì ngày sinh này thiếu định
        hướng cuộc sống.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Cần phải chú ý rằng nếu thiếu Đinh Hỏa sẽ khiến cho ngày sinh này khó
        đạt danh tiếng trong đời. Tương tự, sự vắng bóng của Giáp Mộc sẽ khiến
        cho ngày sinh này khó có được sự độc lập và thành tích. Nếu cả Đinh Hỏa
        và Giáp Mộc đều thiếu thì ngày sinh này chỉ sống trung bình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Hỏa thiếu trong lá số thì người này có thể nghèo và bệnh tật cả
        đời. Bất cứ tài lộc nào họ gặt hái được cũng không kéo dài.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Thiên Tài và Chính Quan:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Giáp Mộc Thiên Tài hiện diện thì ngày sinh này sẽ hưởng thành
        công trong sự nghiệp.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số tốt nếu có Đinh Hỏa ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số xấu nếu có Tỷ Kiếp ở Thiên Can.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Thiên Tài và Chính Tài:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Địa Chi tạo thành Mộc Cục trong khi Giáp Mộc và Ất Mộc lộ Thiên Can
        thì ngày sinh này sẽ giàu có dù không dài lâu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Canh Kim cần phải thông căn. Hành Hỏa cần phải xuất hiện để rèn Kim và
        sử dụng Mộc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số xấu nếu Canh Kim không thông căn.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Thất Sát và Chính Quan:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Địa chi gặp Bính Hỏa Thất Sát thì Nhâm Thủy phải có mặt ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số xấu nếu Nhâm Thủy thiếu hoặc không có ở Thiên Can, ngày sinh này
        có thể bị tàn tật hoặc bệnh kinh niên.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Giáp Mộc và Đinh Hỏa là dụng thần ưu tiên.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Quý Thủy không nên xuất hiện ở Thiên Can trừ khi Địa Chi toàn là hành
        Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu thiếu Hỏa trong lá số thì người này có thể nghèo trong đời. Nếu
        thiếu Hỏa thì Canh Kim không tỏa sáng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Địa Chi tạo thành Tài Cục trong lá số với Giáp Mộc xuất hiện ở
        Thiên Can thì ngày sinh Canh Kim sẽ thịnh vượng trong đời, dù cho tài
        lộc không kéo dài.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Không nên sử dụng Đinh Hỏa thay vì Bính Hỏa như là dụng thần, cũng như
        có liên quan đến ngày sinh Canh Kim.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Mậu Thổ là Thiên Ấn không nên được dùng vì sẽ chôn vùi Kim.
      </p>
    </div>
  );
};

export const CanhThangTy = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h1 style={{ textAlign: "center", fontWeight: "bold" }}>
          Chương I: NGÀY SINH CANH TRONG 12 THÁNG
        </h1>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Canh trong tháng Tỵ
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng này thì nếu có Bính Hỏa hiện lên ở Thiên
        Can thì xem là hình thành cục Thất Sát.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Mậu Thổ hiện lên ở Thiên Can thì hình thành cục Thiên Ấn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Canh Kim hiện lên ở Thiên Can thì hình thành cục Vượng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng
        có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy ý.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng này thì dụng thần điều hậu là Nhâm Thủy,
        Mậu Thổ, Bính Hỏa và Đinh Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Bính Hỏa không nên trực tiếp làm tan chảy Kim. Nhâm Thủy sẽ được chọn là
        lựa chọn lý tưởng hơn để kiểm soát Kim.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Mậu Thổ nên là dụng thần thứ hai cùng với Bính Hỏa là dụng thần trung
        gian.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Địa Chi tạo thành Kim Cục thì Đinh Hỏa sẽ giúp chuyển hóa ngày sinh
        từ yếu thành mạnh và vượng.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Canh Kim liên tục được sinh ra mặc dù Hỏa rất vượng trong tháng này. Tuy
        nhiên, Kim vẫn bị Hỏa kiểm soát trong tháng Tỵ. Do đó Canh Kim sinh
        trong tháng Tỵ sẽ cần phải có Thủy làm ẩm.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tỵ có chứa tàng can Canh Kim và Mậu Thổ nên được xem là dụng thần. Điều
        này là tốt vì có căn gốc cho Canh Kim.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Thủy xuất hiện đủ thì Mộc có thể dùng kèm với Thủy như là dụng thần.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Cùng với hành Thủy và Hỏa trong lá số thì ngày sinh Canh Kim sẽ vượng và
        có được quyền lực trong đời.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Chỉ có Nhâm Thủy là dụng thần nên xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Đinh Hỏa không nên hợp với Nhâm Thủy để sinh ra Mộc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Hỏa mạnh trong tháng Tỵ. Khi không có Nhâm Thủy ở Thiên Can thì ngày
        sinh này sẽ khó trong việc định hướng sự nghiệp cho bản thân.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Sẽ tốt nhất nếu tránh được Đinh Hỏa không xuất hiện ở Thiên Can. Khi
        Đinh Hỏa hợp với Nhâm Thủy thì bất kỳ thành công nào người này đạt được
        cũng sẽ dễ biến thành thất bại, và khiến cho họ hối hận và thất vọng về
        sau.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Thực Thần, Thiên Ấn và Thất Sát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Nhâm Thủy, Mậu Thổ và Bính Hỏa cùng tồn tại trong lá số thì ngày
        sinh này sẽ hưởng cuộc sống thành công và hạnh phúc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Bính Hỏa thiếu và vẫn có Nhâm Thủy và Mậu Thổ thì ngày sinh này sẽ
        không thể thành công như khi có đầy đủ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số tốt nếu Canh Kim thông căn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số xấu nếu quá nhiều Hỏa ở Địa Chi.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Thất Sát: </b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Trong Thất Sát Cục thì Kim không nên là dụng thần.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số tốt nếu Nhâm Thủy xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu thiếu Nhâm Thủy thì chất lượng hôn nhân của người này rất tệ.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Tỷ Kiếp: </b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Địa Chi tạo thành Kim Cục thì chỉ có Đinh Hỏa mới được xem là Dụng
        Thần chứ không phải Bính Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số tốt nếu Đinh Hỏa xuất hiện ở Thiên Can và thông căn Địa Chi.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số xấu nếu Nhâm Thủy và Quý Thủy làm tổn thương Đinh Hỏa.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nhâm Thủy và Mậu Thổ sẽ là dụng thần ưu tiên.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Bính Hỏa đóng vai trò dụng thần thứ hai nếu thiếu đi Đinh Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi hình thành Thất Sát Cục nếu có Dương Nhẫn sẽ thúc đẩy người này
        trở nên cực kỳ hung bạo. Do đó là bất lợi lớn. Tốt nhất nên dùng Nhâm
        Thủy Thực Thần kiểm soát Thất Sát. Điều này sẽ giúp tạo ra ngày sinh
        Canh Kim có quyền lực lớn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Địa Chi có Dậu tạo thành Dương Nhẫn Cục thì Thất Sát sẽ gây bất
        lợi. Thay vào đó nên dùng Đinh Hỏa để tạo sự cân bằng. Cũng cần tránh
        Quý Thủy xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Canh Kim tốt nhất nên gặp đại vận Thân Dậu Tuất hay Hợi Tý Sửu. Sẽ bất
        lợi nếu Canh Kim rơi vào đại vận Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Sự xuất hiện của Nhâm Thủy sẽ củng cố sức mạnh của Mộc Tài Tinh.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có Mậu Thổ ở Thiên Can thì sẽ ngăn cách giữa Thủy và Mộc.
      </p>
    </div>
  );
};

export const CanhThangNgo = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h1 style={{ textAlign: "center", fontWeight: "bold" }}>
          Chương I: NGÀY SINH CANH TRONG 12 THÁNG
        </h1>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Canh trong tháng Ngọ
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng này thì nếu có Đinh Hỏa hiện lên ở Thiên
        Can thì xem là hình thành cục Chính Quan.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Kỷ Thổ hiện lên ở Thiên Can thì hình thành cục Chính Ấn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng
        có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy ý.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng này thì dụng thần điều hậu là Nhâm Thủy
        và Quý Thủy.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ưu tiên nên dành cho Nhâm Thủy vì là dụng thần chính, với Quý Thủy là
        dụng thần phụ. Nếu được thì Địa Chi nên thấy Canh Kim và Tân Kim, vì cả
        hai đóng vai trò hỉ thần.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu thiếu Nhâm Thủy và Quý Thủy thì có thể dùng Mậu Thổ và Kỷ Thổ để làm
        suy yếu Hỏa Khí.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Hỏa và Thổ vượng trong tháng Ngọ. Thổ khô và Hỏa đặc biệt nóng. Nếu
        không có Thủy thì sẽ khó nếu không muốn nói là bất khả thi để cho Kim
        được sinh ra và tăng cường sức mạnh.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Hỏa gặp thêm Mộc thì ngày sinh này sẽ có được ủng hộ và hỗ trợ giúp
        cho Hỏa vượng hơn nữa. Kim sẽ cần phải được thông căn ở Thổ ẩm ướt bởi
        Thủy, để có thể cân bằng. Sự hiện diện của một mình Thổ khô sẽ không thể
        sinh ra và tăng cường sức mạnh cho Canh Kim.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tương tự, ngày sinh Canh Kim cần phải tránh gặp thêm nhiều Mộc và Hỏa
        càng nhiều càng tốt để có thể ngăn không cho Canh Kim bị tan chảy bởi
        Hỏa vượng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Thủy nhiều sẽ giúp cho lá số Canh Kim bằng cách làm mềm ẩm Thổ. Nếu được
        vậy thì ngày sinh này sẽ thông minh và có thành tích tốt. Thành công đến
        với họ thông qua tài năng và sự sáng tạo.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nhâm Thủy là dụng thần số 1 cho người ngày sinh Canh Kim này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Mậu Thổ và Kỷ Thổ cần tránh không xuất hiện hoặc ít nhất không có ở
        Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Để đem lại sự cân bằng cho ngày sinh Canh Kim thì Nhâm Thủy phải xuất
        hiện ở Thiên Can. Ngoài ra, Mậu Thổ và Kỷ Thổ không nên xuất hiện ở
        Thiên Can. Bởi vì cả hai hành Thổ sẽ làm bẩn Nhâm Thủy và nếu vậy thì
        cuộc đời của người Canh Kim sẽ chỉ trung bình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Thiên Can và Địa Chi tạo thành Hỏa Cục thì người này sẽ vất vả mưu
        sinh. Nếu Nhâm Thủy và Quý Thủy xuất hiện ở Thiên Can thì người này sẽ
        có thành công bất ngờ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Mậu Thổ và Kỷ Thổ xuất hiện ở Thiên Can thì ngày sinh Canh Kim sẽ
        cô đơn và nghèo khổ.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Thực Thần, Tỷ Kiên và Thương Quan:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi có Nhâm Thủy, Canh Kim và Quý Thủy xuất hiện trên Thiên Can thì ngày
        sinh này sẽ hưởng tài lộc và thịnh vượng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Mậu Thổ hay Kỷ Thổ xuất hiện thì cần có Giáp Mộc để kiểm soát.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Mậu Thổ hay Kỷ Thổ xuất hiện ở Thiên Can, toàn bộ lá số sẽ xấu.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Chính Quan Thất Sát: </b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Địa Chi tạo thành Hỏa Cục và không xuất hiện Nhâm Thủy, Quý Thủy hay
        Canh Kim ở Thiên Can thì người này sẽ nghèo cả đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số tốt nếu Nhâm xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Nhâm Thủy và Quý Thủy xuất hiện và lộ ra Mậu Thổ hay Kỷ Thổ Ấn Tinh
        thì ngày sinh này chỉ trung bình.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Thiên Tài Thất Sát: </b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Mộc và Hỏa nhiều trong lá số trong khi hoàn toàn thiếu Tỷ Kiếp và Ấn
        Tinh thì sẽ hình thành cục Tòng Quan.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Trong hoàn cảnh đó thì ngày sinh này sẽ hưởng thành công và danh tiếng
        cả đời. Điều này cách cục tốt.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số tốt nếu có Hỏa và Mộc ở Thiên Can và Địa Chi.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Không tốt nếu có Kim và Thủy.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nhâm Thủy là dụng thần số một trong khi Quý Thủy là dụng thần số hai.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Ấn Tinh Mậu và Kỷ Thổ không nên xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu thiếu Thủy ở tháng Ngọ thì sẽ khiến lá số trung bình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu thiếu Thủy thì Kỷ Thổ sẽ được sử dụng để làm suy yếu và kiểm soát
        Hỏa. Ngày Canh Kim khi bước vào đại vận Thủy sẽ hưởng cuộc sống tốt đẹp
        hơn.
      </p>
    </div>
  );
};

export const CanhThangMui = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h1 style={{ textAlign: "center", fontWeight: "bold" }}>
          Chương I: NGÀY SINH CANH TRONG 12 THÁNG
        </h1>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Canh trong tháng Mùi
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng này thì nếu có Kỷ Thổ hiện lên ở Thiên
        Can thì xem là hình thành cục Chính Ấn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Ất Mộc hiện lên ở Thiên Can thì hình thành cục Chính Tài.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Đinh Hỏa hiện lên ở Thiên Can thì hình thành cục Chính Quan.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng
        có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy ý.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng này thì dụng thần điều hậu là Đinh Hỏa và
        Giáp Mộc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Địa Chi tạo thành Thổ Cục thì Giáp Mộc sẽ là lựa chọn ưu tiên
        hàng đầu với Đinh Hỏa là dụng thần thứ hai.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Hỏa và Thổ rất mạnh trong tháng Mùi. Thổ sẽ khô cứng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Do đó, Thủy đóng vai trò Dụng Thần cần phải được sử dụng để làm ẩm ướt
        Thổ. Do đó Mộc cần dùng để làm tơi Thổ. Nếu đúng như vậy thì ngày Canh
        Kim sẽ vượng và trở nên giàu có cả đời.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Thủy và Mộc là những dụng thần quan trọng nhất cho người này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Trong hoàn cảnh mà ngày sinh Canh Kim sinh vào giờ mà Kim và Thủy vượng,
        Mộc khí sẽ yếu khi quá nhiều Kim. Trong hoàn cảnh đó thì nhiều Thủy sẽ
        khiến cho Mộc Tài Tinh mạnh và cũng giúp cho Canh Kim có thể giàu có.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Giáp Mộc Tài Tinh xuất hiện ở lá số Bát Tự nhưng không có Đinh Hỏa
        thì Canh Kim sẽ đóng vai trò là người buôn bán lặt vặt, nhỏ lẻ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Đinh Hỏa lộ Thiên Can nhưng không có Giáp Mộc, ngày sinh Canh Kim
        sẽ chỉ sống cuộc đời trung bình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có 2 Đinh Hỏa và 2 Canh Kim ở Thiên Can thì ngày sinh này sẽ là
        người thông thái và sẽ nắm giữ vị trí quản trị tốt trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Nhâm Thủy xuất hiện ở Thiên Can thì sẽ có nhiều cơ hội trong đời.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Chính Quan và Thiên Tài:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi có Nhâm Thủy, Canh Kim và Quý Thủy xuất hiện trên Thiên Can thì ngày
        sinh này sẽ hưởng tài lộc và thịnh vượng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Mậu Thổ hay Kỷ Thổ xuất hiện thì cần có Giáp Mộc để kiểm soát.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Mậu Thổ hay Kỷ Thổ xuất hiện ở Thiên Can, toàn bộ lá số sẽ xấu.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Chính Quan Thất Sát: </b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu cả Đinh Hỏa và Giáp Mộc đều hiện diện ở Thiên Can thì người này sẽ
        có cuộc đời thành công về tài chính.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số tốt nếu có hành Thổ ướt ở Địa Chi.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số xấu nếu như Quý Thủy Thương Quan xuất hiện ở Thiên Can.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Thiên Ấn và Chính Ấn:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có 4 Địa Chi Thìn Tuất Sửu Mùi trong lá số cùng lúc thì Giáp Mộc sẽ
        là dụng thần chính, còn Đinh Hỏa là dụng thần thứ hai.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số tốt nếu thiếu Thổ ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số không tốt nếu có Kỷ Thổ bên cạnh Giáp Mộc.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Quý Thủy Thương Quan không nên xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu thiếu Thủy Thực Thương để sinh ra Mộc vốn là Tài Tinh thì toàn bộ
        lá số này dưới trung bình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Đinh Hỏa và Giáp Mộc đều không có trong lá số Bát Tự, bất kỳ cách
        cục nào cũng sẽ kém cỏi.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi xét Mộc thì chỉ có Giáp Mộc mới giúp cho ngày sinh Canh Kim vượt
        lên trong đời. Nếu thiếu Giáp thì Ất Mộc không thể thay thế như là dụng
        thần vì không thể đem lại kết quả tương tự.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi xét về Hỏa thì chỉ có Đinh Hỏa là dụng thần mới đem đến cho ngày
        sinh Canh Kim này cơ hội thịnh vượng. Nếu thiếu thì Bính Hỏa sẽ không
        thể thay thế Đinh Hỏa như là dụng thần trong việc đem lại kết quả tốt.
      </p>
    </div>
  );
};

export const CanhThangThan = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h1 style={{ textAlign: "center", fontWeight: "bold" }}>
          Chương I: NGÀY SINH CANH TRONG 12 THÁNG
        </h1>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Canh trong tháng Thân
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng này thì nếu có Canh hiện lên ở Thiên Can
        thì xem là hình thành cục Vượng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngày sinh Canh sinh trong tháng Thân thì Thân vốn đã là vị trí Đế Vượng
        của Canh Kim.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Mậu Thổ hiện lên ở Thiên Can thì hình thành cục Thiên Ấn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Nhâm Thủy hiện lên ở Thiên Can thì hình thành cục Thực Thần.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng
        có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy ý.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng này thì dụng thần điều hậu là Đinh Hỏa và
        Giáp Mộc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nên ưu tiên cho Đinh Hỏa như là dụng thần chính còn Giáp Mộc là định
        hướng và giúp Đinh Hỏa liên tục phát triển.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đế Vượng rất mạnh trong trường hợp ngày sinh Canh trong tháng Thân. Kim
        và Thủy rất mạnh trong tháng này. Điều này bởi vì Kim vượng trong mùa
        thu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Mộc được chọn làm dụng thần vơi sự có mặt của Thủy để tương sinh và
        củng cố Mộc thì điều này giúp cho Canh Kim hưởng thịnh vượng dài lâu.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Đinh Hỏa và Giáp Mộc nên cùng xuất hiện trong lá số Bát Tự.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Địa Chi tạo thành Hỏa Cục thì ngày sinh Canh Kim sẽ hưởng may mắn
        và thành tích cao.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Địa Chi hình thành Thổ Cục thì Giáp Mộc nên được chọn làm Dụng
        Thần chính, với Đinh Hỏa đóng vai trò là Dụng Thần phụ. Trong hoàn cảnh
        này thì ngày sinh Canh Kim sẽ trở nên cực kỳ giàu có.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Địa Chi tạo thành Thủy Cục với 3 Canh Kim xuất hiện ở Thiên Can
        thì người này có sự ủng hộ rất lớn và công nhận đối với ý tưởng và kiến
        thức của họ.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Chính Quan và Thiên Tài:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngày sinh Canh Kim với cả Đinh Hỏa và Giáp Mộc xuất hiện trong lá số Bát
        Tự sẽ giúp người này có cuộc đời phi thường, đầy thành công và thịnh
        vượng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số tốt nếu có Đinh Hỏa và Giáp Mộc thông căn ở Địa Chi.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số xấu nếu không có mặt Giáp Mộc, thì cũng không nên thấy Quý Thủy vì
        dễ dập tắt Bính Hỏa.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Thiên Tài và Chính Tài:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu không có Đinh Hỏa thì sự hiện diện của Giáp Mộc và Ất Mộc có thể
        hình thành cách cục Tài tầm thường.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá sô tốt nếu có Đinh Hỏa giúp tạo thành công và danh tiếng dài lâu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số xấu nếu thiếu Đinh Hỏa.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Thực Thương:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Địa Chi hình thành Thủy Cục và không có Giáp Mộc trong lá số thì
        người này không thông minh. Cuộc đời nhiều cơ hội bị bỏ lỡ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số tốt nếu có Giáp Mộc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số xấu nếu thiếu hoàn toàn Giáp Mộc.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Đinh Hỏa và Giáp Mộc được xem là Dụng Thần ưu tiên cho ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi 3 Canh Kim xuất hiện ở Thiên Can của ngày sinh trong tháng này và
        Địa Chi tạo thành Thủy Cục còn được gọi là Tĩnh Lan. Nếu có sự hiện diện
        của Giáp và Ất Mộc thì đây là lá số của người thành công trong kinh
        doanh. Trải qua đại vận Mộc sẽ rất tốt.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Địa Chi tạo thành Thủy Cục với nhiều Canh Kim, cũng như Bính Hỏa
        và Đinh Hỏa xuất hiện ở Thiên Can thì cần có sự giúp đỡ của Giáp Mộc hay
        Ất Mộc Tài Tinh để thể hiện tốt nhất tài năng của mình.
      </p>
    </div>
  );
};

export const CanhThangDau = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h1 style={{ textAlign: "center", fontWeight: "bold" }}>
          Chương I: NGÀY SINH CANH TRONG 12 THÁNG
        </h1>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Canh trong tháng Dậu
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với ngày sinh trong tháng này thì xem như là hình thành Dương Nhẫn
        Cục.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Kim vượng nhất trong mùa thu và trong tình huống này thì Canh Kim trong
        tháng Dậu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Bởi vì Kim vượng trong mùa Thu, Mộc sẽ bị kiểm soát trong tháng này. Do
        đó Hỏa cần thiết để kiểm soát Kim.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi có Hỏa nhưng thiếu Mộc thì ngày sinh Canh Kim sẽ thiếu khả năng tài
        chính, bất kể họ có quyền lực trong đời ra sao. Bất kỳ địa vị nào của
        người này nắm giữ trong đời cũng chỉ tạm bợ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu cả Thủy và Mộc đều xuất hiện, ngày sinh Canh Kim sẽ thịnh vượng và
        trở nên giàu có, quyền lực. Khi Mộc Khí vượt hơn Thủy Khí, người này sẽ
        rất giàu có trong đời.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Bính Hỏa và Đinh Hỏa là dụng thần ưu tiên cho ngày sinh Canh Kim trong
        tháng Dậu trong khi Giáp Mộc đóng vai trò là hỉ thần.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Ngày sinh này hình thành cục Dương Nhẫn. Lúc này cần có Bính Hỏa và
        Đinh Hỏa là dụng thần.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi lộ ra Đinh Hỏa, Giáp Mộc và Bính Hỏa thì ngày sinh Canh Kim này sẽ
        hưởng thành công trong đời.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Bính Hỏa và Đinh Hỏa là dụng thần ưu tiên cho ngày sinh Canh Kim trong
        tháng Dậu trong khi Giáp Mộc đóng vai trò là hỉ thần.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Ngày sinh này hình thành cục Dương Nhẫn. Lúc này cần có Bính Hỏa và
        Đinh Hỏa là dụng thần.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi lộ ra Đinh Hỏa, Giáp Mộc và Bính Hỏa thì ngày sinh Canh Kim này sẽ
        hưởng thành công trong đời.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Thất Sát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu chỉ có Bính Hỏa Thất Sát được xem là dụng thần thì ngày sinh Canh
        Kim sẽ cảm thấy khó để làm giàu, bất kể họ có sức hấp dẫn bao nhiêu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số tốt nếu có ít nhất một Đinh Hỏa xuất hiện, mặc dù Chính Quan và
        Thất Sát của ngày sinh Canh Kim không nên xuất hiện cùng lúc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số xấu nếu có Nhâm Thủy và Quý Thủy cùng xuất hiện ở Thiên Can.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Tỷ Kiếp:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Địa Chi gặp nhiều Kim khí thì có mặt cùng lúc hành Thủy sẽ tạo thành
        cách cục Tòng Tử.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số tốt nếu xuất hiện Thủy trong lá số Bát Tự vì nếu thiếu thì sẽ
        không thể tạo thành Tòng Tử.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Sẽ bất lợi cho ngày sinh Canh Kim nếu bước vào đại vận Hỏa.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Địa chi Dậu không bị xung. Nếu xung với Dương Nhẫn sẽ gây ra thảm họa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Bính Hỏa và Đinh Hỏa có thể xuất hiện cùng lúc như là dụng thần nhưng
        nên ở hai vị trí khác nhau.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi có Ất Mộc, Bính hỏa và Đinh Hỏa đều xuất hiện ở Thiên Can thì sẽ
        tạo thành Tam Kỳ Quý Nhân. Đặc biệt tốt.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tam Kỳ Quý Nhân có thể tìm thấy ở Địa Chi Mão Tỵ Ngọ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi có nhiều Giáp Mộc và Ất Mộc cũng như Tài Tinh nhưng thiếu đi Bính
        Hỏa và Đinh Hỏa thì ngày sinh Canh Kim sẽ sở hữu tài năng nghệ thuật
        sáng tạo nhưng thiếu tài lộc thật sự.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Chính Quan và Thất Sát nên sử dụng cùng lúc chỉ trong tháng này cho
        ngày sinh này.
      </p>
    </div>
  );
};

export const CanhThangTuat = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h1 style={{ textAlign: "center", fontWeight: "bold" }}>
          Chương I: NGÀY SINH CANH TRONG 12 THÁNG
        </h1>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Canh trong tháng Tuất
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng này thì nếu có Mậu Thổ hiện lên ở Thiên
        Can thì xem là hình thành cục Thiên Ấn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Đinh Hỏa hiện lên ở Thiên Can thì hình thành cục Chính Quan.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng
        có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy ý.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng này thì dụng thần điều hậu là Giáp – Nhâm
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Thổ dày đặc trong tháng Tuất thì Giáp Mộc có thể dùng để làm tơi
        lỏng. Nhâm Thủy có thể dùng như là dụng thần thứ hai, để là sạch ngày
        sinh Canh Kim này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngày sinh Canh Kim trong tháng Tuất cần tránh Kỷ Thổ vốn có thể gây
        nhiễm bẩn cho Nhâm Thủy.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Mặc dù Thổ và Kim vượng trong thang Tuất, Thổ sẽ dày đặc trong tháng mùa
        thu trong khi Thổ và Kim lại khô.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Thủy nên là dụng thần số một, cùng với Mộc là dụng thần thứ hai để làm
        tơi Thổ. Khi Thủy Khí yếu và thiếu và Thổ lại dày đặc thì Mộc khí bị
        giòn và yếu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Cũng sẽ tốt cho lá số này cần tránh thêm nhiều Thổ vì Kim sẽ bị chôn
        vùi.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu được thì cũng không nên có nhiều Hỏa Khí vì Hỏa cũng sẽ sinh ra thêm
        và tăng cường Thổ khô.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Giáp Mộc và Nhâm Thủy là dụng thần lý tưởng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu được thì Kỷ Thổ không nên xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Địa Chi tạo thành Hỏa Cục và Nhâm Thủy lộ ở Thiên Can thì lá số
        này sẽ tạo ra lãnh đạo năng lực cao, được tôn trọng và ngưỡng mộ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Kỷ Thổ không nên xuất hiện ở Thiên Can vì nó sẽ gây suy yếu và ảnh
        hưởng tiêu cực đến Giáp Mộc và làm bẩn Nhâm Thủy vốn dĩ là Dụng Thần của
        ngày sinh Canh Kim.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Thiên Tài và Thực Thần:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Giáp Mộc và Nhâm Thủy xuất hiện cùng lúc thì sẽ có nhiều thành tích.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Mậu Thổ và Kỷ Thổ Ấn Tinh xuất hiện ở Thiên Can thì đời sống của
        người này sẽ khó khăn và khó đạt thành công.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Mậu Thổ và Kỷ Thổ xuất hiện trong lá số thì phải có 2 Giáp Mộc ở
        Thiên Can xuất hiện để khắc chế ảnh hưởng tiêu cực của 2 Ấn Tinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số xấu nếu Mậu và Kỷ Thổ xuất hiện ở Thiên Can.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Thất Sát: </b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Địa Chi tạo thành Hỏa Cục và Nhâm Thủy xuất hiện ở Thiên Can thì
        ngày sinh này sẽ hưởng cuộc sống hạnh phúc và thịnh vượng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số tốt nếu Nhâm Thủy xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Xấu nếu Nhâm Thủy không xuất hiện ở Thiên Can.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Giáp Mộc và Nhâm Thủy là dụng thần lý tưởng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Nhâm Thủy xuất hiện ở Thiên Can thì sẽ có thành công trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Mậu Thổ và Kỷ Thổ không nên xuất hiện ở Thiên Can. Nếu không thì cần
        có 2 Giáp Mộc ở Thiên Can để khắc chế 2 Ấn Tinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Ất Mộc Tài Tinh sẽ không có lợi cho ngày sinh Canh Kim này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Nhâm Thủy và Giáp Mộc vắng mặt trong lá số thì bất kỳ cục nào cũng
        sẽ được xem là kém.
      </p>
    </div>
  );
};

export const CanhThangHoi = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h1 style={{ textAlign: "center", fontWeight: "bold" }}>
          Chương I: NGÀY SINH CANH TRONG 12 THÁNG
        </h1>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Canh trong tháng Hợi
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng này thì nếu có Nhâm Thủy hiện lên ở Thiên
        Can thì xem là hình thành cục Thực Thần.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Giáp Mộc hiện lên ở Thiên Can thì hình thành cục Thiên Tài.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng
        có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy ý.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng này thì dụng thần điều hậu là Đinh Hỏa và
        Bính Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Vì Kim và Thủy khá lạnh trong tháng này nên cần phải gặp dụng thần là
        Bính Hỏa và Đinh Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Giáp Mộc cần phải sinh cho Đinh Hỏa để mạnh trong tháng Hợi.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khí trong lá số này sẽ khá lạnh giá, cùng với Thổ Khí cực yếu trong mùa
        đông.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu không có đủ Hỏa, thì sẽ khó cho Thổ được giữ ấm trong mùa đông. Do
        đó, sự hiện diện của Hỏa sẽ đủ để giữ ấm Kim, Thổ và Mộc. Không có Hỏa
        thì kể cả là Kim, Thổ và Mộc đều không thể đóng vai trò dụng thần cho
        ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngày sinh Canh Kim trong tháng Hợi cần có Thổ ấm, vì đây là dụng thần số
        một. Điều này bởi vì Thổ sẽ giúp cho Kim khí được tăng cường thêm trong
        lúc lại kiểm soát Thủy khí đang vượng.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Chỉ có Bính Hỏa và Đinh Hỏa được xem là Dụng Thần giúp điều chỉnh khí
        hậu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu thiếu Bính Hỏa và Đinh Hỏa trong lá số Bát Tự thì sẽ khó nếu không
        muốn nói là không thể giúp cho Thương Quan của Canh Kim tạo ra Tài Tinh.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Bính Hỏa và Đinh Hỏa hiện ra trong lá số thì ngày sinh Canh Kim
        này sẽ hưởng thành công trong sự nghiệp.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Địa Chi tạo thành Thủy cục, nhưng Bính Hỏa và Đinh Hỏa không xuất
        hiện ở Thiên Can thì toàn bộ lá số vẫn là dưới trung bình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Bính Hỏa và Giáp Mộc cùng xuất hiện ở Thiên Can nhưng vẫn thiếu
        Đinh Hỏa thì ngày sinh Canh Kim sẽ sống cuộc sống trung bình.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Chính Quan Thất Sát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Đinh Hỏa và Bính Hỏa cùng lộ ở Thiên Can thì người này sẽ có danh
        tiếng, cũng như thành công trong sự nghiệp.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tốt nếu như Địa Chi Dần, Ngọ và Tỵ vốn đều chứa Bính Đinh ở tàng can, có
        mặt ở trong lá số.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Không tốt nếu có Quý Thủy vì sẽ dập tắt Đinh Hỏa và ngăn cản Bính Hỏa.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Thiên Tài:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu như hình thành Tài Cục nhưng thiếu Đinh Hỏa thì ngày sinh Canh Kim
        sẽ chỉ sống một cuộc đời trung bình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số tốt nếu như có Đinh Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số xấu nếu như có Đinh Hỏa nhưng gặp Nhâm Thủy.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Bính Hỏa và Đinh Hỏa là dụng thần lý tưởng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu thiếu Đinh Hỏa và Bính Hỏa thì lá số này kém cỏi. Ngoài ra, ngày
        sinh Canh Kim sẽ chịu cô đơn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Đinh Hỏa là quan trọng nhất và là dụng thần chính cho ngày sinh Canh
        Kim này. Vẫn sẽ chấp nhận được nếu thiếu Bính Hỏa và Giáp Mộc trong lá
        số nhưng Đinh Hỏa phải có mặt trong bất kỳ tình huống nào.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Bính Hỏa và Giáp Mộc ở Thiên Can nhưng không có Đinh Hỏa thì ngày
        sinh này chỉ có cuộc sống trung bình.
      </p>
    </div>
  );
};
