export const QuyThangTi = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        className="p1"
        style={{
          margin: "6px 0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          <strong>Ngày sinh Quý trong tháng Tý</strong>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Khái Quát:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì dễ hình thành Vượng Cục.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Dụng Thần Điều Hậu:
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì dụng thần điều hậu là Bính Hỏa
          và Tân Kim.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Bính Hỏa giúp sưởi ấm và xua tan lạnh giá mùa đông. Tân Kim đóng vai
          trò nguồn của Thủy giúp cho Khí tuôn chảy trong các tháng mùa đông.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Luận Giải:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Ngày sinh Quý Thủy trong tháng Tý thì Tý chỉ chứa duy nhất Quý Thủy.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Bởi vì Kim và Thủy mạnh trong mùa đông nên chỉ có Tỷ Kiếp Tinh có khả
          năng hỗ trợ ngày sinh Quý Thủy. Nước lạnh giá tuy mạnh nhưng cùng lúc
          vẫn có thể xem là yếu. Với băng giá thì Hỏa cần thiết để tan băng.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Sự hiện diện của Thổ dày nặng và Hỏa mạnh sẽ giúp cho nước chảy và đem
          đến cuộc sống hạnh phúc và giàu có. Chỉ với sự ủng hộ của Quan Tinh và
          Tài Tinh giúp cho người này có cuộc sống tốt đẹp.{" "}
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Lưu Ý:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Vì Tý Thủy là giữa mùa đông nên Bính Hỏa có thể được dùng để đem đến
            hơi ấm cho ngày sinh này. Không có Hỏa thì Thủy này vẫn bị xem là
            yếu.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu Bính Hỏa vắng mặt trong lá số thì ngày sinh Quý Thủy không vượng
            và cũng không tiến bộ gì. Người này sẽ nghèo khổ và cực trong cả
            đời.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Nhâm Thủy và Quý Thủy cùng xuất hiện trong lá số và Bính Hỏa
            không có ở Thiên Can thì ngày sinh này sẽ ghen tỵ và đầy những biến
            động cảm xúc cả đời.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Mậu Thổ và Kỷ Thổ xuất hiện ở tàng can thì người này sẽ có sức
            khỏe kém và bệnh tật.
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Cách Cục:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Chính Tài và Kiếp Tài:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Bính Hỏa và Nhâm Thủy xuất hiện ở Thiên Can thì người này sẽ có
          cuộc sống thành công.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Sẽ tốt hơn nếu có 2 Bính Hỏa dụng thần. Một ở Thiên Can và một ở Địa
          Chi. Cuộc sống phong phú, may mắn và danh tiếng đầy đủ.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu có Đinh Hỏa xuất hiện ở Tàng Can và hợp với Nhâm Thủy để
          tạo ra Mộc.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>
                Nếu lá số có Tỷ Kiên và Thiên Tài Cục:
                <span className="Apple-converted-space">&nbsp;</span>
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Đinh Hỏa xuất hiện ở Thiên Can năm thì người này nên sinh vào ban
          đêm sẽ thịnh vượng.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Địa Chi hình thành Thủy Cục thì Hỏa sẽ bị dập tắt.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Trong hoàn cảnh đó thì Mậu Thổ và Kỷ Thổ có thể dùng như là dụng thần.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu không có Hỏa trong lá số biểu thị người này sẽ cô đơn và
          nghèo túng cả đời.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Chính Quan và Thất Sát:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu Thổ Khí vượng ở Địa Chi thì ngày sinh này sẽ nghèo và bệnh tật.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu có Mộc để lọc Thủy.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số không tốt nếu thiếu Mộc vì Thủy sẽ bị ô nhiễm.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Tóm Tắt:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Bính Hỏa là dụng thần số 1 trong khi Nhâm Thủy là lựa chọn số 2.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi không có Tài Tinh hay Ấn Tinh xuất hiện ở Thiên Can thì nam giới
            sẽ cảm thấy khó có thể tìm được bạn hoặc người phối ngẫu.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Bính Hỏa vắng mặt trong lá số thì bất kỳ cục nào cũng sẽ xấu.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi có mặt Thực Thần và Thương Quan nhưng không có Kim nào đóng vai
            trò Ấn Tinh thì ngày sinh này sẽ tàn tật.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu Bính Hỏa xuất hiện là Dụng Thần thì nên thông căn ở Địa Chi Dần
            Tỵ Ngọ. Nếu không thì Bính Hỏa sẽ không thể đóng vai trò Dụng Thần.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Khí ở Thiên Can là Tân Kim thì không nên dùng Bính Hỏa, vì Tân
            Kim hợp với Bính Hỏa tạo thành Thủy. Tuy nhiên khí của Đinh Hỏa vẫn
            cần phải phân phối hợp lý nếu không thì không có tác dụng đối với
            ngày sinh này.<span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
    </div>
  );
};

export const QuyThangSuu = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        className="p1"
        style={{
          margin: "6px 0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          <strong>Ngày sinh Quý trong tháng Sửu</strong>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Khái Quát:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì nếu có Kỷ Thổ hiện lên ở Thiên
          Can thì xem là hình thành cục Thất Sát.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Tân Kim hiện lên ở Thiên Can thì hình thành cục Thiên Ấn.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Quý Thủy khác hiện lên ở Thiên Can thì hình thành cục Vượng.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng
          có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy
          ý.
        </span>
      </p>
      <p
        className="p4"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Dụng Thần Điều Hậu:
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì dụng thần điều hậu là Bính Hỏa
          và Đinh Hỏa.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Sẽ lý tưởng nếu Hỏa thông căn ở Dần Tỵ Ngọ Mùi hay Tuất. Trong hoàn
          cảnh đó thì được xem là lý tưởng.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu Địa Chi tạo thành Hỏa Cục thì sẽ lý tưởng nếu chọn Canh Kim hay
          Tân Kim làm dụng thần.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p4"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Luận Giải:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Sửu chứa nhiều Kim khí.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Bởi vì cả Thổ lạnh và Kim lạnh có thể tạo thành không khí lạnh lẽo
          băng giá và băng tuyết nên cần có Hỏa để sưởi ấm.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Quý Thủy sống động và chảy mượt mà thì khí của Quý Thủy sẽ cân
          bằng và máu huyết lưu thông tốt. Do đó Hỏa khí là rất quan trọng.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Vì Mộc là nguồn để sinh ra Hỏa, không nên thiếu Mộc trong lá số.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Ngày sinh Quý Thủy trong tháng Sửu với Kim cục mạnh sẽ cần Đinh Hỏa
          vốn có thể khắc chế Kim khí. Do đó, khi Mộc và Hỏa xuất hiện để hỗ trợ
          Đinh Hỏa thì ngày sinh này sẽ có hạnh phúc và sức khỏe tốt dài lâu.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Lưu Ý:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Bính Hỏa là rất quan trọng vì không có Hỏa thì Thủy rất băng giá và
            biểu thị cuộc sống mệt mỏi.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Sự xuất hiện của Tỵ trong lá số sẽ giúp đem lại bình an và hạnh
            phúc.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Sự xuất hiện của Đinh Hỏa sẽ soi chiếu ánh sáng lấp lánh cho băng
            tuyết.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi thiếu Bính Hỏa trong lá số thì ngày sinh này sẽ không thịnh
            vượng. Người này sẽ bị nghèo khổ cả đời.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Kỷ Thổ xuất hiện ở Địa Chi và Thiên Can thì ngày sinh này sẽ có
            sức khỏe kém và bệnh tật.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Kỷ Thổ xuất hiện ở Thiên Can thì cần có Giáp Mộc để lọc bụi bẩn.
          </span>
        </li>
      </ul>
      <p
        className="p4"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Cách Cục:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Chính Tài và Kiếp Tài:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Bính Hỏa và Nhâm Thủy xuất hiện ở tàng can trong Bát Tự thì ngày
          sinh này sẽ vượng trong đời.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Sẽ tốt hơn nếu có Bính Hỏa dụng thần hiện diện. Người này nên sinh vào
          buổi sáng.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu có Đinh Hỏa xuất hiện ở tàng can và hợp với Nhâm Thủy để
          tạo ra Mộc. Ngoài ra cũng cần tránh nhìn thấy Quý Thủy ở Địa Chi và lộ
          ở Thiên Can.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>
                Nếu lá số có Tỷ Kiên và Thiên Tài:
                <span className="Apple-converted-space">&nbsp;</span>
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu Đinh Hỏa lộ ở Thiên Can năm hoặc tàng can năm thì ngày sinh Quý
          Thủy nên sinh vào ban đêm sẽ vượng.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Địa Chi tạo thành Thủy Cục thì Hỏa khí sẽ bị dập tắt. Trong tình
          huống đó thì Mậu Thổ và Kỷ Thổ nên được dùng làm dụng thần.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu thiếu Hỏa vì sẽ biểu thị người này nghèo và khổ.
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Chính Quan và Thất Sát:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Thổ khí xuất hiện nhiều ở Địa Chi thì ngày sinh này sẽ nghèo và
          bệnh tật.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu có Hỏa.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          La số xấu nếu có Sửu và Tý ở Địa Chi tạo thành Thủy Cục.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p4"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Tóm Tắt:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Bính Hỏa là dụng thần số 1.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nhâm Thủy là dụng thần số hai.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu không có Tài lộ Thiên Can thì nam giới sẽ khó tìm được vợ phù
            hợp. Tài là hành Hỏa, rất cần thiết để tạo sự ấm áp và hạnh phúc.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu thiếu Bính Hỏa trong lá số thì bất kỳ cục nào cũng sẽ kém cỏi.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Đinh Hỏa là lựa chọn số hai vì ánh sáng không đủ đem lại sự ấm áp.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi có Thực Thần và Thương Quan nhưng không có Kim để đóng vai trò
            Ấn Tinh, người này sẽ bị tàn tật. Chỉ có Hỏa mới hóa giải được.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Bính Hỏa là dụng thần và có mặt, thì nên thông căn ở Địa Chi Dần
            Tỵ Ngọ. Nếu không thì Bính Hỏa sẽ không thể đóng vai trò dụng thần
            một cách hiệu quả.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
    </div>
  );
};

export const QuyThangDan = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        className="p1"
        style={{
          margin: "6px 0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          <strong>Ngày sinh Quý trong tháng Dần</strong>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Khái Quát:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì nếu có Giáp Mộc hiện lên ở
          Thiên Can thì xem là hình thành cục Thương Quan.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Bính Hỏa hiện lên ở Thiên Can thì hình thành cục Chính Tài.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Mậu Thổ hiện lên ở Thiên Can thì hình thành cục Chính Quan.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng
          có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy
          ý.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Dụng Thần Điều Hậu:
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì dụng thần điều hậu là Tân Kim
          và Bính Hỏa.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Tân Kim là dụng thần ưu tiên vì giúp tạo ra Quý Thủy. Trong bối cảnh
          này thì Tân Kim được xem như là dụng thần gốc để tăng cường sức mạnh
          Quý Thủy.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu thiếu Tân Kim thì có thể dùng Canh Kim đóng vai trò thay thế.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Bính Hỏa nên xuất hiện trong lá số Bát Tự dù kèm với Tân Kim hay Canh
          Kim.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Luận Giải:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Tháng Dần chứa ngũ hành Mộc, Hỏa và Thổ ở tàng can.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Do đó, ngày sinh Quý Thủy trong tháng Dần có sự hiện diện của Mộc, Hỏa
          và Thổ sẽ tạo thành Tài và Quan Cục.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Mộc khí vượng, Kim khí tương đối yếu. Và khi Kim khí yếu, Quý thủy
          thiếu hỗ trợ thì dẫn đến can ngày Quý Thủy yếu. Bởi vì Kim sinh ra Quý
          Thủy có thể xem như là dụng thần.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Hỏa và Thổ khí không nên xuất hiện nhiều trong lá số. Điều này bởi vì
          Hỏa Khí vượng sẽ làm suy yếu Kim Khí. Tương tự, Thổ khí vượng sẽ ngăn
          cản Thủy Khí. <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Lưu Ý:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Tân Kim và Bính Hỏa xuất hiện ở Thiên Can thì ngày sinh này sẽ
            hưởng cuộc sống thịnh vượng và hạnh phúc.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu Địa Chi tạo thành Hỏa Cục thì Hỏa Khí sẽ làm suy yếu Tân Kim vốn
            là dụng thần của ngày sinh Quý Thủy. Trong hoàn cảnh đó thì Nhâm
            Thủy nên hiện diện trong lá số Bát Tự, để có thể ngăn cản Quý Thủy
            không bị suy yếu hoàn toàn. Nếu thỏa tình huống này thì người này có
            tài lộc vượng thông qua mối quan hệ tốt.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Địa Chi tạo thành Thủy cục cùng với Bính Hỏa lộ Thiên Can thì
            ngày sinh này sẽ có cuộc đời thành công. Nếu thiếu Tân Kim ở lá số
            Bát Tự thì sự hiện diện của Canh Kim sẽ đóng vai trò hỗ trợ Tân.
            Nhưng lá số sẽ kém.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Hỏa và Thổ khí vượng trong lá số thì ngày sinh này sẽ có sức
            khỏe kém và nghèo đói.
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Cách Cục:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Thiên Ấn và Chính Tài:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Bính Hỏa và Tân Kim không nên xuất hiện bên cạnh nhau trong lá số Bát
          Tự vì cả hơi hợp sẽ sinh ra Thủy.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu xuất hiện rời rạc trong lá số thì hiện diện của cả Tân Kim và Bính
          Hỏa sẽ hỗ trợ cho Quý Thủy và giúp thành công và may mắn cả đời.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Sự hiện diện của Bính Hỏa và Tân Kim rời rạc sẽ giúp lá số tốt.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu Bính Hỏa và Đinh Hỏa ở cạnh nhau và có thêm Thủy cục
          trong Địa Chi.
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>
                Nếu lá số có Chính Tài:
                <span className="Apple-converted-space">&nbsp;</span>
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Địa Chi hình thành Hỏa Cục, cùng với Nhâm Thủy xuất hiện ở Thiên
          Can nhiều thì ngày sinh này sẽ có tài lộc và thịnh vượng lớn. Người
          này giỏi giao tiếp.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu có Nhâm Thủy để phản chiếu Bính Hỏa.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Nhâm Thủy xuất hiện ở Thiên Can và bên cạnh Đinh Hỏa Thiên Can
          thì là lá số xấu.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Tỷ Kiếp:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi có quá nhiều Địa Chi tạo thành Thủy Cục và Bính Hỏa lộ Thiên Can
          thì ngày sinh này sẽ hưởng danh tiếng lớn.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Kiếp Tài không xuất hiện ở Thiên Can thì lá số tốt.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu khi có Kỷ Thổ xuất hiện làm ô nhiễm Thủy khí.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Tóm Tắt:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Tân Kim và Bính Hỏa là dụng thần ưu tiên.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu có Thiên Can Quý Thủy và Mậu Thổ gặp nhau thì cả hợp hợp thành
            Hỏa. Điều này sẽ thành hiện thực nếu có Địa Chi tạo thành Hỏa Cục để
            thúc đẩy hợp hóa.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu thiếu Tân Kim và Bính Hỏa trong lá số thì sẽ làm cho ngày sinh
            Quý Thủy thiếu địa vị và quyền lực.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nêu thiếu Canh Kim và Tân Kim Ấn Tinh cũng như thiếu Bính Hỏa và
            Đinh Hỏa thì sẽ dẫn đến ngày sinh Quý Thủy thiếu động lực trong cuộc
            sống.<span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
    </div>
  );
};

export const QuyThangMao = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        className="p1"
        style={{
          margin: "6px 0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          <strong>Ngày sinh Quý trong tháng Mão</strong>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Khái Quát:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì hình thành cục Thực Thần dù có
          Ất Mộc lộ Thiên Can hay không.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p4"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Dụng Thần Điều Hậu:
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì dụng thần điều hậu là Canh Kim
          và Tân Kim.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Ất Mộc là khí mạnh nhất trong tháng này. Canh Kim là dụng thần ưu tiên
          còn Tân Kim chỉ là dụng thần thứ hai.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p4"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Luận Giải:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Mộc Khí vượng nhất trong mùa xuân và Quý Thủy yếu cũng như mong manh.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Kim do đó là yếu khi xét là dụng thần của ngày sinh Quý Thủy. Vì Mộc
          vượng trong mùa xuân nên sẽ dễ dàng để Mộc sinh ra Hỏa. Hiện diện của
          nhiều Hỏa Khí sẽ lại sinh ra Thổ Khí. Và Hỏa Khí mạnh sẽ làm suy yếu
          Kim Khí.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Hiện diện của nhiều Hỏa và Thổ Khí trong lá số Bát Tự sẽ kìm hãm làm
          suy yếu ngày sinh Quý Thủy. Do đó lá số này sẽ không thích Hỏa, Thổ và
          cả Mộc Khí.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Sự lựa chọn tốt nhất cho Quý Thủy là chọn Kim và Thủy, cả hai sẽ tăng
          cường và biến Quý THủy yếu thành mạnh.
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Lưu Ý:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Bởi vì Mộc Khí vượng trong tháng Mão nên Hỏa Khí sẽ mạnh, vì Mộc
            sinh cho Hỏa. Do đó lá số này không cần Bính Hỏa và Đinh Hỏa.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu Canh Kim và Tân Kim xuất hiện ở Thiên Can thì ngày sinh Quý Thủy
            sẽ có sự nghiệp thành công.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Địa Chi tạo thành Mộc cục mạnh thì ngày sinh này sẽ tốt nhất nếu
            họ không bước vào đại vận Kim. Bước vào đại vận Kim sẽ biểu thị khả
            năng dễ bị tai nạn.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Ngay cả nếu Canh Kim và Tân Kim thì ngày sinh Quý Thủy sẽ có xu
            hướng thích nghệ thuật và tình cảm. Điều này bởi vì hiện diện của
            Thực Thần mạnh sẽ khiến cho ngày sinh này có cuộc sống khá ổn định,
            vận may trung bình.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
      <p
        className="p4"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Cách Cục:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Chính Ấn Thiên Ấn:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Sự hiện diện của Đinh Hỏa cùng với Canh Kim ẩn tàng xuất hiện ở Thiên
          Can thì người này có thành công đáng ngưỡng mộ.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu Canh Kim thông căn với sự giúp đỡ của Đinh Hỏa.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu Ất Mộc hợp với Canh Kim.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>
                Nếu lá số có Thực Thương:
                <span className="Apple-converted-space">&nbsp;</span>
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi nhiều Mộc ở Địa Chi cùng với thiếu Canh Kim và Tân Kim trong lá số
          thì hình thành cục Tòng Tử. Khi hình thành cục này thành công thì
          người này sẽ có thành công và thịnh vượng ngay.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu không có Kim hiện diện trong lá số khiến lá số theo cách
          cục Tòng Quan Sát.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu bước vào vận Kim.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p4"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Tóm Tắt:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Canh Kim và Tân Kim là dụng thần lý tưởng.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Đinh Hỏa không nên xuất hiện ở Thiên Can.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Ngày sinh này có thể hình thành cục Thực Thần. Cùng với sự hiện diện
            của Tài Tinh, lá số này sẽ rất giàu có và thành công.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Bất kỳ Tài Tinh nào hiện diện trong lá số cần phải hợp với Ấn Tinh.
            Nếu thiếu Ấn Tinh thì bất kỳ cục Tài nào cũng sẽ vô dụng.
          </span>
        </li>
      </ul>
    </div>
  );
};

export const QuyThangThin = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        className="p1"
        style={{
          margin: "6px 0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          <strong>Ngày sinh Quý trong tháng Thìn</strong>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Khái Quát:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì nếu có Mậu Thổ hiện lên ở Thiên
          Can thì xem là hình thành cục Chính Quan.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Ất Mộc hiện lên ở Thiên Can thì hình thành cục Thực Thần.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Quý Thủy hiện lên ở Thiên Can thì hình thành cục Vượng.
          <span className="Apple-converted-space">
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          </span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng
          có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy
          ý.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Dụng Thần Điều Hậu:
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì dụng thần điều hậu là Bính Hỏa,
          Tân Kim và Giáp Mộc.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Bính Hỏa nên là dụng thần điều hậu cho nửa đầu tháng Thìn. Sự hỗ trợ
          của Tân Kim và Giáp Mộc là điều hậu rất cân thiết trong nửa sau tháng
          Thìn.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Luận Giải:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Thìn vốn là một trong Tứ Khố Địa Chi đóng vai trò Khố của Thủy Khí và
          chứa rất nhiều Thổ.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Với sự hiện diện của Mộc Khí kiểm soát Thổ khí làm suy yếu Thủy, Kim
          và Thủy sẽ cần để hỗ trợ cho ngày sinh Quý Thủy.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Ngày sinh này nên tránh gặp thêm Hỏa Khí. Điều này bởi vì mặc dù Hỏa
          trong lá số này là Tài Tinh, nhưng vẫn có khả năng tạo ra Thổ Khí và
          và làm suy yếu Kim Khí vốn là dụng thần.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Miễn là có đầy đủ Mộc Khí để kiểm soát nhiều Thổ Khí trong tháng Thìn
          thì ngày sinh Quý Thủy này vẫn cân bằng.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Lưu Ý:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Địa Chi Thân Tý Thìn tạo thành Thủy Cục. Khi Mậu Thổ xuất hiện ở
            Thiên Can thì ngày sinh này sẽ thịnh vượng. Bính Hỏa cũng cần hiện
            diện trong lá số.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu cả 4 Thìn Tuất Sửu Mùi hiện diện thì Giáp Mộc là dụng thần duy
            nhất.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Địa chi Dần Mão và Thìn tạo thành Mộc Cục. Trong hình huống đó thì
            Thực Thần sẽ chuyển thành Thương Quan Cục. Bính Hỏa cần để kết nối
            với Mộc Cục nhiều. Cục Thương Quan đặc biệt sẽ không thể đóng vai
            trò nếu như thiếu Tân Kim.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Cả Tân Kim và Bính Hỏa phải tách nhau ra để tránh hợp tạo thành
            Thủy.<span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Cách Cục:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Chính Quan Thất Sát:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu cả 4 Địa Chi Mộ Khố xuất hiện thì Giáp Mộc là dụng thần duy nhất.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Ngay cả nếu Giáp Mộc xuất hiện ở Thiên Can thì cần có Tân Kim.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu Giáp Mộc và Kỷ Thổ bên cạnh nhau và tạo thành hành Thổ.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>
                Nếu lá số có Thực Thần Thương Quan:
                <span className="Apple-converted-space">&nbsp;</span>
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Địa Chi tạo thành Mộc Cục thì cân có Tân Kim ở Thiên Can và thông
          căn Địa Chi.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu có Giáp Mộc ở địa chi tháng biểu thị danh tiếng và cao
          quý.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu không có Tân Kim Ấn Tinh thì ngày sin Quý Thủy sẽ khó tụ tài lộc
          mặc dù có thể tri thức và thông minh.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Tỷ Kiếp:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Địa Chi tạo thành Thủy Cục, Mậu Thổ và Bính Hỏa nên xuất hiện ở
          Thiên Can. Khi thỏa tiêu chí này thì người này cao quý.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Quý Thủy trải qua đại vận Kim thì người này thịnh vượng.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu Giáp Mộc Thương Quan hợp với Kỷ Thổ để tạo ra hành Thổ thì ngày
          sinh Quý Thủy sẽ có cuộc sống bình thường.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Tóm Tắt:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Mậu Thổ và Quý Thủy xuất hiện ở Thiên Can thì cần có Bính Hỏa để
            người này thịnh vượng và giàu có.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Mậu Thổ và Quý Thủy tách nhau ra thì Tân Kim cần có là Ấn Tinh
            xuất hiện ở Thiên Can nếu ngày sinh Quý Thủy muốn thịnh vượng trong
            đời.<span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
    </div>
  );
};

export const QuyThangTy = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        className="p1"
        style={{
          margin: "6px 0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          <strong>Ngày sinh Quý trong tháng Tỵ</strong>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Khái Quát:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì nếu có Bính Hỏa hiện lên ở
          Thiên Can thì xem là hình thành cục Chính Tài.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Mậu Thổ hiện lên ở Thiên Can thì hình thành cục Chính Quan.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu Canh Kim lộ ở Thiên Can thì hình thành cục Chính Ấn.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng
          có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy
          ý.
        </span>
      </p>
      <p
        className="p4"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Dụng Thần Điều Hậu:
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì dụng thần điều hậu là Tân Kim.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu thiếu Tân Kim thì có thể dùng Canh Kim như là dụng thần điều hậu
          thứ hai.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p4"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Luận Giải:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Hỏa Khí vượng trong mùa hè, mặc dù Canh Kim ẩn tàng ở địa chi Tỵ, vẫn
          bị xem là yếu. Trong tháng Tỵ thì cả Kim và Thủy khí đều kém.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Kim đóng vai trò dụng thần chính cho ngày sinh Quý Thủy. Canh Kim xuất
          hiện ở Thiên Can là dụng thần lý tưởng.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Ngày sinh Quý Thủy trong tháng Tỵ không nên gặp thêm Hỏa và Thổ khí.
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Lưu Ý:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Tân Kim và Canh Kim nên là dụng thần.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Không tốt nếu thấy Đinh Hỏa và Kỷ Thổ xuất hiện ở Thiên Can.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu Đinh Hỏa và Kỷ Thổ xuất hiện ở Thiên Can thì ngày sinh Quý Thủy
            sẽ dễ bị bệnh tật và nghèo khổ.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Canh Kim và Nhâm Thủy xuất hiện ở căn gốc và lộ Thiên Can thì
            ngày sinh này sẽ hưởng danh tiếng và may mắn.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Canh Kim lộ Thiên Can nhưng Nhâm Thủy chỉ có ở Địa Chi mà không
            phải ở Thiên Can nhưng Đinh Hỏa vắng mặt thì ngày sinh Quý Thủy sẽ
            có kiến thức và kỹ năng.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu Đinh Hỏa xuất hiện ở Thiên Can và cũng xuất hiện nhiều ở Địa Chi
            thì ngày sinh Quý Thủy sẽ thiếu động lực để thành công trong đời.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Không tốt nếu Đinh Hỏa lộ Thiên Can trừ khi có Thủy.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
      <p
        className="p4"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Cách Cục:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Thiên Ấn và Kiếp Tài:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Tân Kim và Nhâm Thủy xuất hiện ở Thiên Can và thiếu Đinh Hỏa sẽ
          giúp lá số thịnh vượng.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu Đinh Hỏa xuất hiện trong lá số và cùng đồng hành với Quý
          Thủy Tỷ Kiên.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu Đinh Hỏa là Tài Tinh xuất hiện ở Thiên Can và ngày sinh
          Quý Thủy sẽ chỉ có cuộc đời trung bình.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>
                Nếu lá số có Chính Tài và Thiên Tài:
                <span className="Apple-converted-space">&nbsp;</span>
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu thiếu Nhâm Thủy hay thậm chí Quý Thủy Tỷ Kiếp thì ngày sinh này sẽ
          bị bệnh tật cả đời.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nhâm Thủy và Quý Thủy là Tỷ Kiếp cùng xuất hiện ở Thiên Can là lá số
          tốt.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu có Tân Kim ở bên cạnh Bính Hỏa, người này mất phần lớn
          tài lộc.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p4"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Tóm Tắt:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Canh Kim và Tân Kim là dụng thần.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Đinh Hỏa không nên xuất hiện ở Thiên Can.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Đinh Hỏa xuất hiện ở Thiên Can thì sẽ xung với Canh Kim và Tân
            Kim và hợp với Nhâm Thủy tạo thành Mộc. Như vậy thì người Quý Thủy
            sẽ mất mẹ cũng như thiếu động lực thành công trong đời.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Đinh Hỏa xuất hiện ở Thiên Can thì Quý Thủy Tỷ Kiên sẽ giúp kiểm
            soát Đinh Hỏa. Trong tình huống đó thì ngày sinh này sẽ gặp Hình
            trong lá số và người vợ của nam giới Quý Thủy sẽ dễ bị tai nạn.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu thiếu Tân Kim thay bằng Canh Kim thì ngày sinh này sẽ có thành
            công giới hạn.<span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
    </div>
  );
};

export const QuyThangNgo = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        className="p1"
        style={{
          margin: "6px 0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          <strong>Ngày sinh Quý trong tháng Ngọ</strong>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Khái Quát:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì nếu có Đinh Hỏa hiện lên ở
          Thiên Can thì xem là hình thành cục Thiên Tài.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Kỷ Thổ hiện lên ở Thiên Can thì hình thành cục Thất Sát.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng
          có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy
          ý.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Dụng Thần Điều Hậu:
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì dụng thần điều hậu là Canh Kim,
          Tân Kim, Nhâm Thủy và Quý Thủy.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Canh Kim và Tân Kim đóng vai trò Ấn Tinh giúp sức mạnh cho Quý Thủy.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Không may là trong tháng này thì Đinh Hỏa mạnh nhất. Kim khí không
          vượt qua được Hỏa Khí nên ngày sinh Quý Thủy cần sự trợ giúp từ Tỷ
          Kiếp để cả Canh Kim và Tân Kim đóng vai trò ủng hộ.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Luận Giải:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Ngày sinh Quý Thủy trong tháng Ngọ bị yếu. Bởi vì Thổ khô nóng xung
          với Thủy.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          Khi hỏa xung với Thủy trong tháng Ngọ thì xung giữa các hành. Rõ ràng
          thì điều này sẽ khiến cho ngày sinh này yếu hơn.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Kim và Thủy khí thông căn ở Địa Chi như là sao hỗ trợ cho ngày sinh
          Quý Thủy yếu.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Mộc khí nên tránh vì sẽ tăng cường Hỏa thêm.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nên tránh Hỏa và Thổ khí thêm vì sẽ là huyệt tử của ngày sinh này.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Lưu Ý:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Tân Kim hay Canh Kim sẽ được xem là dụng thần ưu tiên.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Đinh Hỏa xuất hiện ở Thiên Can thì Canh Kim và Tân Kim sẽ bị
            hỏng.<span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nhâm Thủy nên là lựa chọn thay thế khi Canh Kim vắng mặt.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Quý Thủy có thể được dùng như là dụng thần tương ứng khi Canh Kim và
            cả Nhâm Thủy đều thiếu.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Địa Chi Tỵ Ngọ Mùi tạo thành Hỏa Cục thì có thể tạo thành Tòng
            Tài Cục. Tuy nhiên cần thiếu Nhâm Thủy trong lá số thì mới thành
            hiện thực. Nếu cục này không hình thành thì ngày sinh Quý Thủy sẽ
            chịu cô đơn và vất vả trong đời.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu hình thành cục Tòng Tài thì không nên xuất hiện hành bổ sung cho
            ngày sinh này.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu Kỷ Thổ xuất hiện ở Thiên Can thì người này sẽ có bệnh tật.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi có hành ủng hộ cho Quý Thủy thì người này sẽ có thể có tài lộc,
            thịnh vượng, danh tiếng và may mắn nhiều.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Cách Cục:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Chính Ấn và Thiên Ấn:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Canh Kim và Tân Kim lộ trên thiên Can thì lá số này sẽ hưởng thành
          công, sức khỏe và hạnh phúc.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nên có Địa Chi Thủy để ủng hộ ngày sinh Quý Thủy.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu nhiều Hỏa Khí xuất hiện ở Thiên Can.
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>
                Nếu lá số có Chính Tài và Thiên Tài:
                <span className="Apple-converted-space">&nbsp;</span>
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Địa Chi tạo thành Hỏa Cục thì Canh Kim và Nhâm Thủy xuất hiện ở
          Thiên Can thì ngày sinh này sẽ có nhiều danh tiếng và tài lộc.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu có Canh Kim và Nhâm Thủy ở Thiên Can hoặc ít nhất là ở
          Địa Chi.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu thiếu Nhâm Thủy thì ngày sinh này sẽ đối mặt với cô đơn và dễ sống
          đời ẩn dật.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Chính Quan và Thất Sát:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Canh Kim và Tân Kim hiện lên lá số như là dụng thần hoặc sẽ khó khăn
          để cho ngày sinh này tìm được sự giúp đỡ và hỗ trợ.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu có Canh Kim và Tân Kim trong lá số.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu Canh Kim và Tân Kim vắng mặt hoặc không thể là dụng
          thần.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Tóm Tắt:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Canh Kim và Tân Kim là dụng thần chính.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nhâm Thủy và Quý Thủy là lựa chọn dụng thần thứ hai.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Ngày sinh Quý Thủy trong tháng Ngọ được xem là rất yếu. Lá số này
            không nên nhìn thấy Xung hay Hình. Nếu có Xung hay Hình thì lá số
            này sẽ kém cỏi.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Tài Cục mạnh sẽ hình thành nếu không co cả Nhâm Thủy và Quý Thủy ở
            Thiên Can. Trong tình huống đó thì ngày sinh này sẽ cô đơn. Với sự
            hiện diện của Tỷ Kiếp ở Địa Chi thì ngày sinh này sẽ hưởng phú quý.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Tân Kim xuất hiện như là Ấn Tinh tốt thì sẽ tốt nhất nếu gặp
            Bính Hỏa hợp.<span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Ngay cả nếu Canh Kim và Tân Kim xuất hiện là dụng thần thì nếu thiếu
            Nhâm Thủy và Quý Thủy cũng sẽ khiến người này khó đạt danh tiêng và
            may mắn.<span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
    </div>
  );
};

export const QuyThangMui = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        className="p1"
        style={{
          margin: "6px 0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          <strong>Ngày sinh Quý trong tháng Mùi</strong>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Khái Quát:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì nếu có Kỷ Thổ hiện lên ở Thiên
          Can thì xem là hình thành cục Thất Sát.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Đinh Hỏa hiện lên ở Thiên Can thì hình thành cục Thiên Tài.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Ất Mộc hiện lên ở Thiên Can thì hình thành cục Thực Thần.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng
          có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy
          ý.
        </span>
      </p>
      <p
        className="p4"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Dụng Thần Điều Hậu:
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì dụng thần điều hậu là Canh Kim,
          Tân Kim, Nhâm Thủy và Quý Thủy.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Vì Kim khí đang khá yếu và Hỏa khí mạnh trong tháng Mùi, ngày sinh Quý
          Thủy cần sự hỗ trợ của Tỷ Kiếp trong khoảng thời gian này.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p4"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Luận Giải:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Hỏa và Thổ khí mạnh nhất trong tháng này và Quý Thủy bị khô cạn. Mộc
          khí tàng ở Địa Chi Mùi sẽ đem đến thủy khí bị hao tiết và đồng thời
          sinh ra thêm cho Hỏa Khí vượng hơn.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Quý Thủy sẽ cần sự trợ giúp từ Kim và Thủy, để có thể phát triển tốt.
          Chỉ với sự trợ giúp từ Kim và Thủy thì Quý Thủy mới phát triển được.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Ngay cả mặc dù Thổ Khí vượng trong tháng Mùi, Mộc vân không phù hợp để
          dùng kiểm soát Thổ. Bởi vì Mộc sinh ra Hỏa, và dẫn đến hao tiết cạn
          kiện Thủy trừ khi Mộc bị ướt.{" "}
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Lưu Ý:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Có cả bất lợi và thuận lợi cho ngày sinh này. Phụ thuộc phần lớn vào
            giờ sinh. Nếu Mộc ở giờ sinh thì cuộc đời nhiều phú quý và chỉ khi
            Mộc ướt.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Canh Kim và Tân Kim xuất hiện là dụng thần.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
      <p
        className="p4"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Cách Cục:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Chính Ấn và Thiên Ấn:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Canh Kim và Tân Kim xuất hiện ở Thiên Can thì ngày sinh này hưởng
          danh tiếng và may mắn.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Tốt nếu có Mộc ở trụ giờ đem đến phú quý.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Xấu nếu có thêm Hỏa ở trụ giờ biểu thị về già khó khăn.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>
                Nếu lá số có Chính Tài và Thiên Tài:
                <span className="Apple-converted-space">&nbsp;</span>
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Địa Chi tạo thành Hỏa Cục và có Canh Kim và Nhâm Thủy xuất hiện ở
          Thiên Can thì ngày sinh này sẽ hưởng tài lộc lớn trong đời.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu có Tỷ Kiếp ở trụ giờ biểu thị thịnh vượng.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu thiếu Nhâm Thủy thì người này sẽ cô đơn, dễ có đời sống ẩn dật.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Chinh Quan Thất Sát</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Canh Kim và Tân Kim là Ấn Tinh có lợi nếu không thì khó để ngày sinh
          Quý Thủy tìm được sự giúp đỡ trong đời.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Thủy ở Địa Chi là tốt.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu không có Canh Kim và Tân Kim.
        </span>
      </p>
      <p
        className="p4"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Tóm Tắt:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Canh Tân Kim là dụng thần ưu tiên trong khi Nhâm Quý Thủy là dụng
            thần số hai.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Mộc ướt ở trụ giờ thì người này có thành công và tài lộc lớn,
            nhất là trong học thuật.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Quý Thủy sinh tháng này dễ hình thành cục Tòng Quan khi có nhiều Thổ
            Khí. Tuy nhiên nếu hình thành cục này thì không nên có Xung Hình ở
            Địa Chi, nếu có Xung Hình thì lá số sẽ mang tai họa.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Cách cục Tài hình thành nếu không có cả Nhâm Thủy và Quý Thủy ở
            Thiên Can. Trong tình huống đó thì ngày sinh Quý Thủy sẽ cô đơn
            trong đời, trừ khi thông căn ở Địa Chi.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Tân Kim là dụng thần thì sẽ tốt nhất nếu bị Bính Hỏa hợp. Biểu
            thị mất tài lộc.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Kỷ Thổ xuất hiện ở Thiên Can, Mộc Khí cân thiết để giữ Thủy
            không bị nhiễm bẩn.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Mặc dù Canh Kim và Tân Kim xuất hiện như là Ấn Tinh cho ngày sinh
            này thì thiếu Nhâm Thủy và Quý Thủy sẽ ngăn cản người này không đạt
            được danh tiếng và may mắn.
          </span>
        </li>
      </ul>
    </div>
  );
};

export const QuyThangThan = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        className="p1"
        style={{
          margin: "6px 0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          <strong>Ngày sinh Quý trong tháng Thân</strong>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Khái Quát:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì nếu có Canh Kim hiện lên ở
          Thiên Can thì xem là hình thành cục Chính Ấn.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Mậu Thổ hiện lên ở Thiên Can thì hình thành cục Chính Quan.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Nhâm Thủy hiện lên ở Thiên Can thì hình thành cục Dương Nhẫn.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng
          có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy
          ý.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Dụng Thần Điều Hậu:
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì dụng thần điều hậu là Đinh Hỏa.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Vì Canh Kim vượng nhất trong tháng này nên Đinh Hỏa cần để rèn Kim và
          giúp cho lá số cân bằng.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Để Đinh Hỏa phát huy được tác dụng tôt thì cần phải thông căn ở Ngọ
          Tuất hặc Mùi.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Luận Giải:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Ngày sinh Quý Thủy trong tháng Thân là gặp Chính Ấn. Vì là một trong
          Tứ Trường Sinh, Thân chứa Nhâm Thủy ở tàng can và cũng sinh cho Quý
          Thủy. Hỏa là Tài Tinh.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Thông căn ở Thủy Khí, ngày sinh này sẽ hưởng may mắn, khi cả Mộc và
          Hỏa đều có ở Thiên Can.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Ngày sinh trong tháng Thân sẽ gặp Kim và Thủy khí thêm. Điều này bởi
          vì Kim khí và Thủy khí mạnh sẽ làm suy yếu Tài và Quan Tinh. Ngày sinh
          này sẽ được củng cố sức mạnh.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Lưu Ý:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Đinh Hỏa là dụng thần ưu tiên và Giáp Mộc là dụng thần thứ hai.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nhâm Thủy và Quý Thủy không nên xuất hiện ở Thiên Can.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Đinh Hỏa và Giáp Mộc xuất hiện ở Thiên Can thì ngày sinh Quý
            Thủy sẽ đạt danh tiếng lớn.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Đinh Hỏa lộ ở Thiên Can nhưng thiếu Giáp Mộc trong lá số thì
            ngày sinh Quý Thủy chỉ có cuộc đời trung bình.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Địa Chi tạo thành Kim Cục và Đinh Hỏa không có ở Thiên Can thì
            ngày sinh Quý Thủy sẽ không được hưởng danh tiếng mà họ muốn. Cuộc
            sống sẽ nhiều cơ hội bỏ lỡ.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Đinh Hỏa lộ lên Thiên Can thì ngày sinh này sẽ thịnh vượng và
            thậm chí rất giàu có trong đời.
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Cách Cục:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Thiên Tài và Thương Quan:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi không có Nhâm Thủy và Quý Thủy ở Thiên Can và có Đinh Hỏa ở Thiên
          Can và thông căn Địa Chi thì ngày sinh này sẽ hưởng thịnh vượng nhiều
          mặt.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu Đinh Hỏa thông căn Địa Chi.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu Nhâm Thủy và Quý Thủy không xuất hiện ở Thiên Can.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>
                Nếu lá số có Chính Ấn và Thiên Ấn:
                <span className="Apple-converted-space">&nbsp;</span>
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Vai trò của Chính Ấn trong lá số này rất nhỏ. Nếu quá mạnh thì khiến
          cho người này sẽ mong manh về cảm xúc.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu có thêm Đinh Hỏa rèn Kim.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu thiếu Đinh Hỏa.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Tóm Tắt:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Giáp Mộc và Đinh Hỏa là dụng thần.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Không nên nhìn thấy Canh hay Tân Kim xuất hiện ở Thiên Can.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu Đinh Hỏa xuất hiện ở Địa Chi Ngọ và ở Thiên Can thì đây sẽ là
            dụng thần lý tưởng.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Ngay cả nếu Đinh Hỏa xuất hiện ở Thiên Can và ở Địa Chi Mùi hay Tuất
            cũng là lựa chọn tốt.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Bất kể cục gì thì vẫn tốt nếu tránh được có Tỷ Kiếp ở Thiên Can. Sẽ
            biểu thị cuộc đời nhiều khổ và phải ganh đua.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu thiếu Đinh Hỏa dẫn đến lá số xấu cho bất kỳ cục gì.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Mậu Thổ và Kỷ Thổ có thể có lợi cho ngày sinh này dù cho Hỏa yếu. Có
            lợi bởi nếu có sao Tỷ Kiếp ở Thiên Can. Nếu không có Thổ Khí thì lá
            số này chỉ tầm thường. Hành Hỏa hay Thổ cần phải thông căn thì mới
            tốt cho ngày sinh này.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
    </div>
  );
};

export const QuyThangDau = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        className="p1"
        style={{
          margin: "6px 0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          <strong>Ngày sinh Quý trong tháng Dậu</strong>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Khái Quát:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu sinh trong tháng Dậu thì hình thành cục Thiên Ấn nếu có Tân Kim lộ
          Thiên Can.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Ngay cả nếu Tân Kim không lộ Thiên Can thì cũng sẽ hình thành cục
          Thiên Ấn.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p4"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Dụng Thần Điều Hậu:
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với sinh trong tháng Dậu thì Tân Kim và Bính Hỏa là dụng thần điều
          hậu.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Để Tân Kim đóng vai trò dụng thần thì cần có sự hỗ trợ từ Bính Hỏa.
          Bởi vì cần Kim ấm nóng và Thủy nóng thì sẽ đem sự cân bằng cho lá số.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Do đó cả Tân Kim và Bính Hỏa phải xuyên lộ Thiên Can để đóng vai trò
          là dụng thần.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p4"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Luận Giải:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Kim khí sẽ thúc đẩy sức mạnh cho Thủy Khí đạt thịnh vượng. Do đó, Tài
          và Quan là dụng thần.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Mậu Thổ, nếu gặp Hỏa khí thì là kết hợp lý tưởng cho ngày sinh này.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Mộc đóng vai trò hỗ trợ cho Hỏa khí yếu. Do đó thuận lợi.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Kim và Thủy khí không nên xuất hiện nhiều.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu ngày sinh Quý Thủy này thông căn, thì sẽ cần có Mộc ấm để đóng vai
          trò sinh ra Chính Thiên Tài.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Lưu Ý:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu có Tân Kim và Bính Hỏa thì nên đứng cạnh nhau để cùng sinh ra
            hành Thủy.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Tân Kim sẽ giúp Thủy tinh khiết nhưng không nên sử dụng riêng mỗi
            Tân Kim.
          </span>
        </li>
      </ul>
      <p
        className="p4"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Cách Cục:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Thiên Ấn Chính Tài:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Tân Kim và Bính Hỏa không xuất hiện cạnh nhau và có xuất hiện ở
          Thiên Can thì ngày sinh này sẽ có địa vị và quyền lực.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Giáp Mộc sẽ củng cố thêm Bính Hỏa và Tân Kim dụng thần. Người này sẽ
          có tài lộc và quý.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Bất lợi nếu có Bính Hỏa và Tân Kim dùng chung sẽ tạo ra Thủy. Lá số sẽ
          bỏ lỡ cơ hội và nhiều vấn đề về cảm xúc ám ảnh cuộc đời.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Chính Quan Thất Sát:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nhâm Thủy và Quý Thủy Tỷ Kiếp nên cần xuát hiện đồng thời trên Thiên
          Can. Nếu không thì sẽ ảnh hưởng tốt của Chính Quan và Thất Sát sẽ
          không cảm nhận được.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu có Bính Hỏa thông căn ở Địa Chi. Nếu Hỏa xuất hiện thì
          giúp người này thành công.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu nhiều Thủy tạo ra bất ổn. Người này chịu rắc rối về tài
          chính và tình cảm cả đời.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p4"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Tóm Tắt:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Tân Kim và Bính Hỏa là dụng thần lý tưởng.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Chỉ có Tài và Ấn là Tân Kim và Bính Hỏa sẽ giúp cho người này thành
            công lớn và có danh tiếng.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu cả Tỷ Kiếp không xuất hiện trên Thiên Can thì không thể dùng
            Chính Quan và Thất Sát.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Bính Hỏa và Tân Kim tránh ở cạnh nhau nếu không muốn có thêm hành
            Thủy.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Giáp Mộc và Ất Mộc giúp tăng cường hành Hỏa và sinh ra nguồn tài lộc
            không ngừng.
          </span>
        </li>
      </ul>
    </div>
  );
};

export const QuyThangTuat = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        className="p1"
        style={{
          margin: "6px 0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          <strong>Ngày sinh Quý trong tháng Tuất</strong>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Khái Quát:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì nếu có Mậu Thổ hiện lên ở Thiên
          Can thì xem là hình thành cục Chính Quan.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Đinh Hỏa hiện lên ở Thiên Can thì hình thành cục Thiên Tài.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Tân Kim hiện lên ở Thiên Can thì hình thành cục Thiên Ấn.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng
          có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy
          ý.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Dụng Thần Điều Hậu:
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì dụng thần điều hậu là Tân Kim,
          Giáp Mộc, Nhâm Thủy và Quý Thủy.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Dụng thần ưu tiên là Tân Kim. Tốt nhất là tránh Mậu Thổ.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Sẽ tốt nếu có Tỷ Kiếp để hỗ trợ Giáp Mộc kiểm soát Mậu Thổ.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Luận Giải:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Tháng này có nhiều Thổ và có Hỏa bên trong. Thổ dày đặc trong tháng
          mùa thu trong khi Quý Thủy thiếu sức sống.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Sức mạnh của Quý Thủy phù thuộc phần lớn vào Kim để sinh Thủy. Kim Khí
          rõ ràng sẽ giúp cho Thủy chảy tốt.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Mộc với số lượng ít cũng có thể dùng vì Mộc khá giòn và bất ổn trong
          tháng này. Mục đích của Mộc là để kiểm soát Thổ.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Lưu Ý:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Với người này thì dụng thần lý tưởng là Tân Kim và Giáp Mộc.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu có Hỏa ở Thiên Can sẽ giúp tăng cường hơi ấm và cảm giác hạnh
            phúc. Tuy nhiên, Hỏa không nên ở cạnh Tân Kim.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu có thêm Tỷ Kiếp sẽ giúp gia tăng sự nổi tiếng nhưng không nhất
            thiết gia tăng địa vị.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Cách Cục:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Thiên Ấn và Thương Quan:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Ngày sinh Quý Thủy trong tháng Tuất có cả Tân Kim và Giáp Mộc trong lá
          số đảm bảo thành công về mặt sự nghiệp.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu thiếu Tỷ Kiếp.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu Mậu Thổ và Kỷ Thổ xuất hiện ở Thiên Can.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Tóm Tắt:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Không nên sử dụng Chính Tài hoặc Chính Quan mà thay vào đó nên dùng
            thương Quan để tạo ra cục Chính Ấn. Thương Quan phối với Ấn sẽ là
            cách cục quý.<span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Mậu Thổ xuất hiện ở Thiên Can, Nhâm Thủy và Quý Thủy sẽ cần có
            như là Tỷ Kiếp để hỗ trợ Giáp Mộc vốn là Thương Quan trong việc kiểm
            soát Mậu Thổ.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Ngay cả dù cho Tân Kim và Quý Thủy vắng mặt trong lá số thì vẫn có
            thể thay thế bằng Giáp Mộc Thương Quan, khiến cho ngày sinh Quý Thủy
            sẽ hưởng cuộc sống khá thịnh vượng.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
    </div>
  );
};

export const QuyThangHoi = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        className="p1"
        style={{
          margin: "6px 0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          <strong>Ngày sinh Quý trong tháng Hợi</strong>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Khái Quát:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì nếu có Giáp Mộc hiện lên ở
          Thiên Can thì xem là hình thành cục Thương Quan.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Nhâm Thủy hiện lên ở Thiên Can thì hình thành cục Dương Nhẫn.
        </span>
      </p>
      <p
        className="p4"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Dụng Thần Điều Hậu:
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì dụng thần điều hậu là Canh Kim,
          Tân Kim, Mậu Thổ và Đinh Hỏa.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Vì Hợi là Tứ Trường Sinh nên Giáp Mộc phát triển nhanh trong tháng
          Hợi. Vì Giáp Mộc làm suy Quý Thủy nên sẽ cần khôi phục Khí của Quý
          Thủy bằng cách sử dụng Canh Kim hoặc Tân Kim.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Thủy xuất hiện nhiều thì có thể dùng Mậu Thổ để kiểm soát Thủy.
          Khi Kim xuất hiện nhiều thì Đinh Hỏa dùng để kiểm soát Kim.
        </span>
      </p>
      <p
        className="p4"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Luận Giải:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Hỏa là cần thiết cho lá số này. Tài Tinh sẽ giúp cân bằng cho lá số.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Cần phải đảm bảo Hỏa khí mạnh bằng cách cung cấp Mộc khí. Điều này bởi
          vì Mộc sinh ra Hỏa nên sẽ giúp cho người này có tài lộc thịnh vượng và
          hạnh phúc dài lâu.{" "}
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Lưu Ý:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Tân Kim và Đinh Hỏa là lựa chọn Dụng thần ưu tiên. Một lựa chọn khác
            là Canh Kim và Mậu Thổ.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Sẽ không tốt nếu có thêm Quý Thủy và Nhâm Thủy xuất hiện ở Thiên
            Can.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Bính Hỏa sẽ được lựa chọn là dụng thần nếu không có sẵn Đinh Hỏa.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Sự hiện diện của Dần, Ngọ và Tuất tạo thành Hỏa Cục. Điều này đặc
            biệt có lợi cho lá số.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi có đầy đủ Tài Cục và thiếu Thổ trong lá số thì dễ hình thành Cục
            Vượng giúp có cuộc đời phi thường.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Canh Kim và Tân Kim xuất hiện cùng lúc tại Thiên Can nhưng hoàn
            toàn thiếu Đinh Hỏa ở Thiên Can thì ngày sinh Quý Thủy sẽ không có
            cuộc sống hạnh phúc.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
      <p
        className="p4"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Cách Cục:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Chính Ấn và Thiên Ấn:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Kim chỉ nên được dùng khi có Hỏa.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu thiếu Hỏa và Thổ thì lá số sẽ cực vượng.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu thiếu Bính Hỏa sẽ gây ra sự cô đơn hay thích sống ẩn
          dật.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>
                Nếu lá số có Thương Quan và Thực Thần:
                <span className="Apple-converted-space">&nbsp;</span>
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Địa Chi tạo thành Mộc Cục thì chất lượng của Thực Thần Cách hay
          Thương Quan Cách sẽ phụ thuộc phần lớn vào Canh Kim và Tân Kim ở Thiên
          Can.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu có Hỏa giúp cho Thủy ấm và trong.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu có Canh Kim và Tân Kim xuất hiện ở Thiên Can.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>
                Nếu lá số có Tỷ Kiếp:
                <span className="Apple-converted-space">&nbsp;</span>
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Địa Chi tạo thành Thủy Cục cùng với Mậu Thổ ở Thiên Can, người này
          sẽ có cuộc sống tốt và cân bằng.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu có Hỏa giúp cho Thủy ấm áp và chảy.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu không có Mậu Thổ xuất hiện ở Thiên Can thì ngày sinh Quý Thủy sẽ
          vất vả mưu sinh.
        </span>
      </p>
      <p
        className="p4"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Tóm Tắt:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Bính Hỏa là dụng thần ưu tiên.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Ngày sinh Quý Thủy trong tháng Hợi mà có Địa Chi tạo thành Thủy Cục,
            cần có Mậu Thổ Chính Quan để kiểm soát. Không nên sử dụng Thất Sát
            Cục bởi vì nếu có Thất Sát làm nhiễm bẩn Thủy.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu có thêm Thủy sẽ tạo ra Dương Nhẫn cục. Dương Nhẫn cần có Thất
            Sát để làm lưỡi dao. Thất Sát cần được rèn giũa cẩn thận nếu có Mộc.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu muốn dùng Tài Cục thì phải có Tỷ Kiếp xuất hiện ở Thiên Can. Để
            điều này xảy ra thì Mậu Thổ và Hỏa Khí cần thiết.
            <span className="Apple-converted-space">&nbsp; </span>Nếu không,
            ngày sinh Quý Thủy sẽ bị bệnh tật cả đời.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Thủy giúp phản chiếu ánh sáng mặt trời. Đây được xem là nghịch sinh.
            Do đó Bính Hỏa là tốt trong tháng Hợi.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Canh Kim và Tân Kim Ấn Tinh không phù hợp với Đinh Hỏa. Do đó Đinh
            Hỏa không nên xuất hiện bên cạnh. Bính Hỏa có thể dùng kèm với Canh
            Kim.
          </span>
        </li>
      </ul>
    </div>
  );
};
