import React, { memo, useState } from "react";
import "../scss/tieunhiquansat.scss";
import { Table } from "reactstrap";
import { TiTick } from "react-icons/ti";
import ReactTooltip from "react-tooltip";
import Modal from "../components/ModalTieuNhi";

function TieuNhiQuanSat(props) {
  const { monthTieuNhi, yearTieuNhi, tuTru, chiHour, nguHanhTieuNhi } = props;

  const [isOpen, setOpen] = useState(false);
  const [values, setValues] = useState("");

  const handleClick = (value) => {
    setOpen(!isOpen);

    if (value === "Phi Đao" || value === "Phi Nhẫn" || value === "Phi Nhận")
      setValues("Phi Đao/ Phi Nhẫn/ Phi Nhận");
    else if (value === "Thiên La" || value === "Địa Võng")
      setValues("Thiên La Địa Võng");
    else if (value === "Quý Hợp") setValues("Quý Hợp Quý Nhân");
    else if (
      value === "Thiên Thượng Tam Kỳ" ||
      value === "Nhân Trung Tam Kỳ" ||
      value === "Địa Hạ Tam Kỳ"
    )
      setValues("Tam Kỳ Quý Nhân");
    else if (value === "Thiên Xá" || value === "Thiên Thứ")
      setValues("Thiên Xá / Thiên Thứ");
    else if (value === "Lưu Hạ" || value === "Lưu Hà")
      setValues("Lưu Hạ / Lưu Hà");
    else if (value === "Đa Mưu") setValues("Chiến lược gia (Đa Mưu)");
    else if (value === "Giải Thần") setValues("Giải Thần / Thiên Giải");
    else if (value === "Huyết Nhẫn") setValues("Huyết Nhẫn / Huyết Đao");
    else if (value === "Long Đức") setValues("Long Đức / Long Đức Quý Nhân");
    else if (value === "Đại Hao") setValues("Phá Toái / Đại Hao");
    else if (value === "Nhật Phá") setValues("Nhật Phá / Phá Nhật");
    else setValues(value);
  };

  return (
    <div>
      <div id="than-sat-component" className="than_sat_title">
        <h3>TIỂU NHI QUAN SÁT</h3>
      </div>

      {/* tieu nhi thang  */}
      <div id="html-break" className="table_tieunhi">
        <Table>
          <thead>
            <tr>
              <th className="th-row-1">
                <div className="right">
                  Quan <br /> Sát
                </div>
                <hr className="hr-row-1" />
                <div className="left">Giờ</div>
              </th>
              <td
                id="diemvuong"
                className="background-two"
                data-tip="Diêm Vương Quan Sát"
                data-for="row-1"
                style={{ cursor: "pointer" }}
              >
                Diêm Vương
              </td>
              <td
                className="background-two"
                id="thuyhoa"
                data-tip="Thủy Hỏa Quan Sát"
                data-for="row-1"
                style={{ cursor: "pointer" }}
              >
                Thủy Hỏa
              </td>
              <td
                className="background-two"
                id="tuongquan"
                data-tip="Tướng Quân Tiễn Quan Sát"
                data-for="row-1"
                style={{ cursor: "pointer" }}
              >
                Tướng Quân
              </td>
              <td
                className="background-two"
                id="dieuphi"
                data-tip="Điểu Phi Quan Sát"
                data-for="row-1"
                style={{ cursor: "pointer" }}
              >
                Điểu Phi
              </td>

              <td
                className="background-two"
                id="lactinh"
                data-tip="Lạc Tỉnh Quan Sát"
                data-for="row-1"
                style={{ cursor: "pointer" }}
              >
                Lạc Tỉnh
              </td>

              <td
                id="thamthuy"
                data-tip="Thâm Thủy Quan Sát"
                data-for="row-1"
                style={{ cursor: "pointer" }}
                className="background-two"
              >
                Thâm Thủy
              </td>
              <td
                id="tuquy"
                data-tip="Tứ Quý Quan Sát"
                data-for="row-1"
                style={{ cursor: "pointer" }}
                className="background-two"
              >
                Tứ Quý
              </td>

              <td
                id="ducbon"
                data-tip="Dục Bồn Quan Sát"
                data-for="row-1"
                style={{ cursor: "pointer" }}
                className="background-two"
              >
                Dục Bồn
              </td>
              <td
                id="kimtoa"
                data-tip="Kim Tỏa Quan Sát"
                data-for="row-1"
                style={{ cursor: "pointer" }}
                className="background-two"
              >
                Kim Tỏa
              </td>
              <td
                id="capcuoc"
                data-tip="Cấp Cước Quan Sát"
                data-for="row-1"
                style={{ cursor: "pointer" }}
                className="background-two"
              >
                Cấp Cước
              </td>
              <td
                id="hatinh"
                data-tip="Hạ Tình Quan Sát"
                data-for="row-1"
                style={{ cursor: "pointer" }}
                className="background-two"
              >
                Hạ Tình
              </td>
              <td
                id="dade"
                data-tip="Dạ Đề Quan Sát"
                data-for="row-1"
                style={{ cursor: "pointer" }}
                className="background-two"
              >
                Dạ Đề
              </td>
              <td
                id="loicong"
                data-tip="Lôi Công Quan Sát"
                data-for="row-1"
                style={{ cursor: "pointer" }}
                className="background-two"
              >
                Lôi Công
              </td>
              <td
                id="doankieu"
                data-tip="Đoạn Kiều Quan Sát"
                data-for="row-1"
                style={{ cursor: "pointer" }}
                className="background-two"
              >
                Đoạn Kiều
              </td>
              <td
                id="bachnhat"
                data-tip="Bách Nhật Quan Sát"
                data-for="row-1"
                style={{ cursor: "pointer" }}
                className="background-two"
              >
                Bách Nhật
              </td>
            </tr>
          </thead>

          <tbody>
            <tr>
              <th id="chihour" className="background-two">
                {chiHour}
              </th>
              <td className="h__50">
                {monthTieuNhi.includes("Diêm Vương") && (
                  <TiTick fontSize={24} color={"red"} />
                )}
              </td>
              <td className="h__50">
                {monthTieuNhi.includes("Thủy Hỏa") && (
                  <TiTick fontSize={24} color={"red"} />
                )}
              </td>
              <td className="h__50">
                {monthTieuNhi.includes("Tướng Quân") && (
                  <TiTick fontSize={24} color={"red"} />
                )}
              </td>
              <td className="h__50">
                {monthTieuNhi.includes("Điểu Phi") && (
                  <TiTick fontSize={24} color={"red"} />
                )}
              </td>
              <td className="h__50">
                {monthTieuNhi.includes("Lạc Tỉnh") && (
                  <TiTick fontSize={24} color={"red"} />
                )}
              </td>
              <td className="h__50">
                {monthTieuNhi.includes("Thâm Thúy") && (
                  <TiTick fontSize={24} color={"red"} />
                )}
              </td>
              <td className="h__50">
                {monthTieuNhi.includes("Tứ Quý") && (
                  <TiTick fontSize={24} color={"red"} />
                )}
              </td>
              <td className="h__50">
                {monthTieuNhi.includes("Dục Bồn") && (
                  <TiTick fontSize={24} color={"red"} />
                )}
              </td>
              <td className="h__50">
                {monthTieuNhi.includes("Kim Tỏa") && (
                  <TiTick fontSize={24} color={"red"} />
                )}
              </td>
              <td className="h__50">
                {monthTieuNhi.includes("Cấp Cước") && (
                  <TiTick fontSize={24} color={"red"} />
                )}
              </td>
              <td className="h__50">
                {monthTieuNhi.includes("Hạ Tình") && (
                  <TiTick fontSize={24} color={"red"} />
                )}
              </td>
              <td className="h__50">
                {monthTieuNhi.includes("Dạ Đề") && (
                  <TiTick fontSize={24} color={"red"} />
                )}
              </td>
              <td className="h__50">
                {monthTieuNhi.includes("Lôi Công") && (
                  <TiTick fontSize={24} color={"red"} />
                )}
              </td>
              <td className="h__50">
                {monthTieuNhi.includes("Đoạn Kiều") && (
                  <TiTick fontSize={24} color={"red"} />
                )}
              </td>
              <td className="h__50">
                {monthTieuNhi.includes("Bách Nhật") && (
                  <TiTick fontSize={24} color={"red"} />
                )}
              </td>
            </tr>
          </tbody>
        </Table>
        <ReactTooltip
          id="row-1"
          getContent={(dataTip) => (
            <div>
              <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
              <p style={{ color: "blue" }}>
                Xin vui lòng{" "}
                <span
                  className="click-span"
                  onClick={() => {
                    handleClick(dataTip);
                  }}
                >
                  Click
                </span>{" "}
                vào đây để tìm hiểu thêm
              </p>
            </div>
          )}
          effect="solid"
          delayHide={500}
          delayShow={500}
          delayUpdate={500}
          place="top"
          border={true}
          type="light"
        />
      </div>
      {/* can ngày  */}

      {/* chi ngày  */}
      <div className="table_tieunhi">
        <Table>
          <thead>
            <tr>
              <th className="th-row-2">
                <div className="right">
                  Quan <br /> Sát
                </div>
                <hr className="hr-row-2" />
                <div className="left">Giờ</div>
              </th>

              <td
                id="doanmenh"
                data-tip="Đoản Mệnh Quan Sát"
                data-for="row-2"
                style={{ cursor: "pointer" }}
                className="background-two"
              >
                Đoản Mệnh
              </td>
              <td
                id="hoathuong"
                data-tip="Hòa Thượng Quan Sát"
                data-for="row-2"
                style={{ cursor: "pointer" }}
                className="background-two"
              >
                Hòa Thượng
              </td>
              <td
                id="thanghoa"
                data-tip="Thang Hỏa Quan Sát"
                data-for="row-2"
                style={{ cursor: "pointer" }}
                className="background-two"
              >
                Thang Hỏa
              </td>
              <td
                id="thiendieu"
                data-tip="Thiên Điếu Quan Sát"
                data-for="row-2"
                style={{ cursor: "pointer" }}
                className="background-two"
              >
                Thiên Điếu
              </td>
              <td
                id="thiennhat"
                data-tip="Thiên Nhật Quan Sát"
                data-for="row-2"
                style={{ cursor: "pointer" }}
                className="background-two"
              >
                Thiên Nhật
              </td>
            </tr>
          </thead>

          <tbody>
            <tr>
              <th id="chihour" className="background-two">
                {chiHour}
              </th>
              <td className="h__50">
                {yearTieuNhi.includes("Đoản Mệnh") && (
                  <TiTick fontSize={24} color={"red"} />
                )}
              </td>
              <td className="h__50">
                {yearTieuNhi.includes("Hòa Thượng") && (
                  <TiTick fontSize={24} color={"red"} />
                )}
              </td>
              <td className="h__50">
                {yearTieuNhi.includes("Thang Hỏa") && (
                  <TiTick fontSize={24} color={"red"} />
                )}
              </td>
              <td className="h__50">
                {yearTieuNhi.includes("Thiên Điếu") && (
                  <TiTick fontSize={24} color={"red"} />
                )}
              </td>
              <td className="h__50">
                {yearTieuNhi.includes("Thiên Nhật") && (
                  <TiTick fontSize={24} color={"red"} />
                )}
              </td>
            </tr>
          </tbody>
        </Table>
        <ReactTooltip
          id="row-2"
          getContent={(dataTip) => (
            <div>
              <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
              <p style={{ color: "blue" }}>
                Xin vui lòng{" "}
                <span
                  className="click-span"
                  onClick={() => {
                    handleClick(dataTip);
                  }}
                >
                  Click
                </span>{" "}
                vào đây để tìm hiểu thêm
              </p>
            </div>
          )}
          effect="solid"
          delayHide={500}
          delayShow={500}
          delayUpdate={500}
          place="top"
          border={true}
          type="light"
        />
      </div>

      {/* chi ngày  */}

      <div
        style={{
          width: "1440px",
          display: "flex",
          alignItems: "center",
          margin: "0 auto",
        }}
      >
        <div style={{ width: "25%" }} className="table_tieunhi">
          <Table>
            <thead style={{ height: 66 }}>
              <tr>
                <th className="th-row-2">
                  <div className="right" style={{ padding: 0 }}>
                    Quan <br /> Sát
                  </div>
                  <hr
                    className="hr-row-3"
                    style={{
                      width: "120%",
                      transform: "rotate(44deg)",
                      position: "relative",
                      right: 11,
                      top: 9,
                      background: "black",
                    }}
                  />
                  <div className="left">Giờ</div>
                </th>
                <td
                  id="nguquy"
                  className="background-two"
                  data-tip="Ngũ Quỷ Quan Sát"
                  data-for="row-3"
                  style={{ cursor: "pointer" }}
                >
                  Ngũ Quỷ
                </td>
                <td
                  id="quymon"
                  className="background-two"
                  data-tip="Quỷ Môn Quan Sát"
                  data-for="row-3"
                  style={{ cursor: "pointer" }}
                >
                  Quỷ Môn
                </td>
              </tr>
            </thead>

            <tbody>
              <tr>
                <th id="chihour" className="background-two">
                  {chiHour}
                </th>
                <td className="h__50">
                  {yearTieuNhi.includes("Ngũ Quỷ") && (
                    <TiTick fontSize={24} color={"red"} />
                  )}
                </td>
                <td className="h__50">
                  {yearTieuNhi.includes("Quỹ Môn Quan") && (
                    <TiTick fontSize={24} color={"red"} />
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
          <ReactTooltip
            id="row-3"
            getContent={(dataTip) => (
              <div>
                <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
                <p style={{ color: "blue" }}>
                  Xin vui lòng{" "}
                  <span
                    className="click-span"
                    onClick={() => {
                      handleClick(dataTip);
                    }}
                  >
                    Click
                  </span>{" "}
                  vào đây để tìm hiểu thêm
                </p>
              </div>
            )}
            effect="solid"
            delayHide={500}
            delayShow={500}
            delayUpdate={500}
            place="top"
            border={true}
            type="light"
          />
        </div>
        {/* tứ trụ  */}
        <div style={{ width: "40%" }} className="table_tieunhi">
          <Table>
            <thead>
              <tr>
                <th style={{ width: "3%" }} className="th-row-3">
                  <div className="right">
                    Quan <br /> Sát
                  </div>
                  <hr className="hr-row-3B" />
                  <div className="left">Giờ</div>
                </th>
                <td
                  id="thiencau"
                  className="background-two"
                  data-tip="Thiên Cẩu Quan Sát"
                  data-for="row-4"
                  style={{ cursor: "pointer" }}
                >
                  Thiên Cẩu
                </td>
                <td
                  id="kimxa"
                  className="background-two"
                  data-tip="Kim Xà Quan Sát"
                  data-for="row-4"
                  style={{ cursor: "pointer" }}
                >
                  Kim Xà
                </td>
                <td
                  id="bachho"
                  className="background-two"
                  data-tip="Bạch Hổ Quan Sát"
                  data-for="row-4"
                  style={{ cursor: "pointer" }}
                >
                  Bạch Hổ
                </td>
              </tr>
            </thead>

            <tbody>
              <tr>
                <th id="chihour" className="background-two">
                  {chiHour}
                </th>
                <td className="h__50">
                  {nguHanhTieuNhi && <TiTick fontSize={24} color={"red"} />}{" "}
                </td>
                <td className="h__50">
                  {nguHanhTieuNhi && <TiTick fontSize={24} color={"red"} />}{" "}
                </td>
                <td className="h__50">
                  {nguHanhTieuNhi && <TiTick fontSize={24} color={"red"} />}{" "}
                </td>
              </tr>
            </tbody>
          </Table>
          <ReactTooltip
            id="row-4"
            getContent={(dataTip) => (
              <div>
                <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
                <p style={{ color: "blue" }}>
                  Xin vui lòng{" "}
                  <span
                    className="click-span"
                    onClick={() => {
                      handleClick(dataTip);
                    }}
                  >
                    Click
                  </span>{" "}
                  vào đây để tìm hiểu thêm
                </p>
              </div>
            )}
            effect="solid"
            delayHide={500}
            delayShow={500}
            delayUpdate={500}
            place="top"
            border={true}
            type="light"
          />
        </div>
        <div style={{ width: "25%" }} className="table_tieunhi">
          <Table>
            <thead style={{ height: 66 }}>
              <tr>
                <th className="th-row-3C">
                  <div className="right" style={{ padding: 0 }}>
                    Quan <br /> Sát
                  </div>
                  <hr
                    className="hr"
                    style={{
                      width: "115%",
                      transform: "rotate(32deg)",
                      position: "relative",
                      right: "10px",
                      top: "10px",
                      background: "black",
                    }}
                  />
                  <div className="left">Giờ</div>
                </th>
                <td
                  id="tutrutieunhi"
                  className="background-two"
                  data-tip="Tứ Trụ Quan Sát"
                  data-for="row-5"
                  style={{ cursor: "pointer" }}
                >
                  Tứ Trụ
                </td>
              </tr>
            </thead>

            <tbody>
              <tr>
                <th id="chihour" className="background-two">
                  {chiHour}
                </th>
                <td className="h__50">
                  {tuTru && <TiTick fontSize={24} color={"red"} />}
                </td>
              </tr>
            </tbody>
          </Table>
          <ReactTooltip
            id="row-5"
            getContent={(dataTip) => (
              <div>
                <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
                <p style={{ color: "blue" }}>
                  Xin vui lòng{" "}
                  <span
                    className="click-span"
                    onClick={() => {
                      handleClick(dataTip);
                    }}
                  >
                    Click
                  </span>{" "}
                  vào đây để tìm hiểu thêm
                </p>
              </div>
            )}
            effect="solid"
            delayHide={500}
            delayShow={500}
            delayUpdate={500}
            place="top"
            border={true}
            type="light"
          />
        </div>
      </div>

      <div className="table_tieunhi">
        <Table>
          <thead>
            <tr>
              <td id="phisat" className="background-two">
                Phi Sát
              </td>
              <td id="quanmoc" className="background-two">
                Quan Mộc
              </td>
              <td id="liemsat" className="background-two">
                Liêm Sát
              </td>
              <td id="phisa" className="background-two">
                Phi Sa
              </td>
              <td id="tangsat" className="background-two">
                Tang Sát
              </td>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td className="">
                Phi Sát (飞煞) - Đứa trẻ bị loại Phi Sát này khi người thân dùng
                gậy tre gõ xuống sàn, gọi tên người quá cố trong khi đốt giấy
                tiền vàng mã trong đám tang
              </td>
              <td className="">
                Quan Mộc Sát (棺木煞)- Đứa trẻ bị loại Sát này khi đóng đinh
                quan tài gỗ trong đám tang
              </td>
              <td className="">
                Liêm Sát (廉煞) - Đứa trẻ bị Liêm Sát khi tang gia khóc lóc và
                thực hiện nghi thức "bước qua cầu Nại Hà"
              </td>
              <td className="">
                Phi Xa Sát (飞车煞) - Đứa trẻ bị Phi Xa Sát khi tang gia động
                quan và đưa quan tài đến nghĩa trang
              </td>
              <td className="">
                Tang Sát (喪煞) - Đứa trẻ bị Tang Sát khi tang gia dùng dù để
                mời thủy thần, mua nước, mở cửa mả, tụng kinh cầu siêu
              </td>
            </tr>
          </tbody>
        </Table>
        <ReactTooltip
          id="row-3"
          getContent={(dataTip) => (
            <div>
              <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
              <p style={{ color: "blue" }}>
                Xin vui lòng{" "}
                <span
                  className="click-span"
                  onClick={() => {
                    handleClick(dataTip);
                  }}
                >
                  Click
                </span>{" "}
                vào đây để tìm hiểu thêm
              </p>
            </div>
          )}
          effect="solid"
          delayHide={500}
          delayShow={500}
          delayUpdate={500}
          place="top"
          border={true}
          type="light"
        />
      </div>

      <div
        style={{
          border: "2px solid black",
          textAlign: "center",
          fontSize: 16,
          fontWeight: "bold",
          width: "30%",
          margin: "50px auto",
        }}
      >
        <div className="quan-sat">
          {" "}
          Sinh ngày Giáp/Ất => năm 4, 9 tuổi gặp Quan Sát{" "}
        </div>
        <div className="quan-sat">
          {" "}
          Sinh ngày Bính/Đinh => năm 1, 6 tuổi gặp Quan Sát{" "}
        </div>
        <div className="quan-sat">
          {" "}
          Sinh ngày Mậu/Kỷ => năm 3, 8 tuổi gặp Quan Sát{" "}
        </div>
        <div className="quan-sat">
          {" "}
          Sinh ngày Canh/Tân => năm 2, 7 tuổi gặp Quan Sát{" "}
        </div>
        <div className="quan-sat">
          {" "}
          Sinh ngày Nhâm/Quý => năm 5, 10 tuổi gặp Quan Sát{" "}
        </div>
      </div>
      <Modal
        className={"modal-scroll"}
        open={isOpen}
        value={values}
        close={(value) => setOpen(value)}
      />
    </div>
  );
}

export default memo(TieuNhiQuanSat);
