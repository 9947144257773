export const DinhHoa = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "70%", margin: "0 auto" }}>
        <h1 style={{ textAlign: "center", color: "red", fontWeight: "bold" }}>
          Chương III: Đinh Hỏa <br /> Ngày Sinh Đinh Hỏa
        </h1>
      </div>

      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Dụng Thần Điều Hậu</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b>• Sinh Vào Mùa Xuân</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Dần: 05/02 – 05/03
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Giáp Mộc, Canh Kim
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Mão: 06/03 – 04/04
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Canh Kim, Giáp Mộc
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Thìn: 05/04 – 05/05
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Canh Kim, Giáp Mộc
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b>• Sinh Vào Mùa Hạ</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Tỵ: 06/05 – 05/06
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Canh Kim, Giáp Mộc
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Ngọ: 06/06 – 07/07
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Quý Thủy, Canh Kim, Nhâm Thủy
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Mùi: 08/07 – 07/08
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Quý Thủy, Bính Hỏa, Giáp Mộc
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b>• Sinh Vào Mùa Thu</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Thân: 08/08 – 07/09
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Canh Kim, Bính Hỏa, Giáp Mộc, Mậu Thổ
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Dậu: 08/09 – 08/10
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Canh Kim, Bính Hỏa, Giáp Mộc, Mậu Thổ
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Tuất: 09/10 – 07/11
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Canh Kim, Giáp Mộc, Mậu Thổ
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b>• Sinh Vào Mùa Đông</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Hợi: 08/11 – 07/12
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Giáp Mộc, Canh Kim
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Tý: 08/12 – 05/01
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Giáp Mộc, Canh Kim
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Sửu: 06/01 – 03/02
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu: Giáp Mộc, Canh Kim
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Lưu ý: Các bạn cần lưu ý những dụng thần điều hậu phía trên chỉ mang
        tính chất tham khảo. Nếu cần biết thêm về các dụng thần điều hậu, cũng
        như các dụng thần khác trong lá số bát tự bạn cần tham khảo hoặc tư vấn
        từ các chuyên gia.
      </p>

      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Hình Tượng</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đinh Hoả là Âm Hoả. Nếu bạn muốn hình dung xem người sinh ngày Đinh thế
        nào, hãy nghĩ đến lửa cháy âm thầm như ngọn nến leo lắt, đuốc hay đèn
        ngủ. Đinh Hoả là hoả bị hạn chế, ngược lại với lửa toả sáng rực rỡ như
        mặt trời. Người Đinh Hoả thường bí mật, tinh tế.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tính cách của họ thường không phải dạng ồn ào, và tính cách của họ
        thường không gai góc. Trong nhiều trường hợp thì người Đinh Hoả khá tinh
        tế, hấp dẫn, lịch sự, can đảm và duyên dáng. Họ thường có vẻ ngoài hơi
        bảo thủ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi mới tiếp xúc, họ có thể tỏ ra lạnh lùng hay điềm đạm. Đó giống như
        hình ảnh của một ngọn nến. Nhưng khi họ quen biết họ thân hơn, họ thuộc
        tuýp người cười tươi và đùa giỡn và dễ trở thành nguồn vui trong 1 buổi
        tiệc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Đinh Hoả rất tinh tế và duyên dáng, và sẽ đặc biệt chú ý đến những
        gì họ nói hoặc làm. Họ thường quan tâm đến người khác và sẽ đặc biệt chú
        ý đến nhu cầu và sở thích của người khác.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Đinh Hoả thường là những người suy nghĩ tinh tế thích suy ngẫm
        những vấn đề tri thức. Đôi khi họ có thể tỏ ra rất nhạy cảm và dễ lo
        lắng và sợ hãi. Trong nhiều trường hợp, họ thường phân tích một tình
        huống hoặc rắc rối rất kỹ lưỡng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ thường cởi mở trong việc học tập điều mới, dù là học theo cách truyền
        thống như đọc sách hoặc thông qua những cách tiếp xúc, học qua tiếp xúc
        thực hành và kinh nghiệm. Họ rất thích kiến thức. Họ rất cởi mở với
        những ý tưởng, khái niệm và quan sát mới và thích suy tư.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Đinh Hoả thường chú ý đến tiểu tiết và chính xác, họ cũng thường
        thích hướng dẫn cho người khác. Họ thích tìm hiểu những kiến thức mà gợi
        cảm hứng cho họ, cũng như những tia lửa nhỏ sẽ thắp sáng những đám cháy.
      </p>

      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Tính Cách</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ cũng có thể đi từ việc rất kiên nhẫn và ngăn nắp trở nên rất vội vã
        và hấp tấp trong vòng 1 thời gian ngắn. Mặc dù họ rất cẩn thân, họ không
        kiên trì và nhất quán. Đôi khi, sự nhạy cảm của họ khiến cho họ trở nên
        rất khó chịu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Bởi vì cảm xúc của họ có thể bất ổn, họ dễ trở nên đa nghi đối với người
        khác và dễ trở nên đặc biệt cảnh gíac. Điều này khiến cho quan hệ tình
        cảm cá nhân của họ mất cân bằng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Đinh Hoả có thể không toả ra sự ấm áp đến cho tất cả mọi người
        trên thế giới nhưng khi cần thiết thì một ngọn nến hay ngọn đuốc có thể
        đủ sưởi ấm cho người cần nó. Tương tự, người Đinh Hoả rất trung thành,
        chung thuỷ, hết lòng và yêu thương người họ cảm thấy gần gũi.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Đinh Hoả, hơn những ngày sinh khác, thể hiện đầy đủ tính chất hiếu
        thuận đối với cha mẹ. Họ sẵn lòng hi sinh cho những người thân thuộc và
        gần gũi nhất cũng như đặt nhu cầu của những người khác lên trên nhu cầu
        của bản thân mình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Do đó, đôi khi họ cần phải được nhắc nhở rằng họ cần phải có nhu cầu và
        ước muốn của bản thân. Họ rất giỏi trong việc kìm nén bản thân trong khi
        chờ đợi cơ hội để toả sáng trở lại khi đến đúng lúc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Đinh Hoả rất chi tiết và chu đáo trong suy nghĩ, bất kể là vấn đề
        lớn hay nhỏ. Dù là vấn đề nhỏ hay lớn, người Đinh Hoả luôn sẽ quan tâm
        chú ý cũng như ngọn lửa sẽ đốt tất cả mọi thứ nó gặp. Họ thuộc tuýp
        người sẵn sàng làm tất cả mọi việc để đảm bảo rằng mọi vấn đề sẽ được
        giải quyết gọn gàng và được sự đồng thuận từ mọi bên có liên quan.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ có một phong cách nhất quán là điềm đạm và chậm rãi bề ngoài nhưng
        bên trong thì có lối suy nghĩ phá cách, phi truyền thống. Người Đinh Hoả
        thích bám theo lối suy nghĩ cẩn trọng, cổ điển. Tuy nhiên họ vẫn có thể
        tiếp cận bất kỳ vấn đề nào từ những góc nhìn khác biệt. Chỉ là không
        không thích làm cho mọi thứ rối rắm hay náo loạn.
      </p>

      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Mối Quan Hệ/ Tình Cảm</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Cũng như ngọn lửa nến leo lắt tuỳ theo sức mạnh và hướng của làn gió,
        người Đinh Hoả có khả năng trở nên rất nổi nóng và tức giận. Một phút
        trước họ còn điềm đạm và bình tĩnh thì phút tiếp theo họ có thể nổi
        điên.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Đinh Hoả nói chung rất quan tâm chăm sóc và yêu thương những người
        họ biết. Người Đinh Hoả muốn những người xung quanh trở thành phiên bản
        giỏi nhất. Đinh Hoả chính là ngọn đèn cầy và đèn cầy thì cháy để cung
        cấp ánh sáng. Khi phải làm việc với những người xung quanh, người Đinh
        Hoả luôn đặt nhu cầu của người xung quanh lên trên nhu cầu của họ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Điều này sẽ ảnh hưởng đến sự tự tin của họ, tuy nhiên tích cực hay tiêu
        cực thì còn tuỳ vào cách người xung quanh tương tác phản hồi với họ ra
        sao. Người Đinh Hoả thường là những người thích nuôi dưỡng và gợi cảm
        hứng cho người khác nhưng cũng như một ngọn nến toả sáng quá mức, họ
        không nên hi sinh bản thân quá nhiều và không chú ý gì đến mình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Những người khác nếu có ít nguyên tắc đạo đức hơn hoặc đơn giản là toan
        tính nhiều hơn có thể sẽ lợi dụng tính tốt của người Đinh Hoả. Khi người
        Đinh Hoả nhận ra mình đang bị lợi dụng, lòng tự tin của họ có thể bị suy
        sụp. Do đó người Đinh Hoả cần phải nỗ lực để quan tâm đến bản thân mình
        bên cạnh việc hi sinh cho người khác nếu không muốn mình bị thiệt.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Đinh Hoả rất nhạy cảm, và bởi vì tâm trạng của họ thường dao động
        như ngọn nến, mối quan hệ tình cảm của họ và người khác thường khá phức
        tạp. Khá ngạc nhiên là người Đinh Hoả có thể tỏ ra khá chân thành và yêu
        thương đối với những người mà họ tin tưởng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ thường không có niềm tin rõ ràng vào bản thân rằng họ sẽ được người
        khác yêu mến. Do đó, rất khó để họ hình thành tình bạn thật sự với người
        khác, bởi vì họ luôn tự hỏi rằng người xung quanh có yêu thích họ hay
        không.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nhiều người Đinh hoả thường nghi ngờ động cơ phía sau sự quan tâm của
        người xung quanh dành cho họ, và thường không thấy được những mối quan
        hệ tình cảm tốt bởi vì họ khép kín và nghi ngờ. Họ luôn có xu hướng muốn
        bảo vệ bản thân, và do đó nhiều tình cảm đáng lẽ có thể phát triển tốt
        lại bị đi chệch hướng.
      </p>

      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Sự Nghiệp/ Công Việc</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đôi khi, người Đinh Hoả còn làm những việc này và chịu thiệt thòi. Họ bỏ
        đi nhu cầu và ham muốn của bản thân, và không đòi hỏi ngược lại. Người
        Đinh Hoả có thái độ tích cực và lịch thiệp, họ luôn tỏ ra hoan nghênh
        trong mọi hoàn cảnh. Họ tỏ ra dễ chịu và thích bình an, và không phải
        thuộc tuýp người nổi loạn, gây rắc rối cho người khác.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Đinh Hoả cũng như một ngọn đèn cầy hay đuốc, trước khi cháy thì
        không ai để ý quan tâm đến lượng ánh sáng hay ấm áp nó mang lại. Đó là
        hình ảnh của người Đinh Hoả, họ không thể hiện tài năng hay khả năng
        ngay từ đầu. Thay vào đó, họ có xu hướng nằm lặng im trước khi bắt lửa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Trong công sở, người Đinh Hoả thường không có vẻ gì bắt mắt ngay cái
        nhìn đầu tiên, nhưng điều này không có nghĩa là họ không có tài. Họ đơn
        giản là ẩn mình trong bóng tối, chờ đợi đến lúc có thể toả sáng với sự
        tự tin.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Một khi họ sẵn sàng chia sẻ tài năng của họ với thế giới thì người Đinh
        Hoả có thể tỏa sáng không ngừng. Thông thường, họ sẽ gây ngạc nhiên với
        người xung quanh về những việc họ có thể làm. Người Đinh Hoả thường sở
        hữu nhiều tài năng và thông minh hơn người khác nghĩ về họ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu là lãnh đạo thì người Đinh Hoả thích tuân thủ theo các bộ quy trình
        và thủ tục chuẩn mực. Họ rất nghiêm túc trong quy trình và không thuộc
        dạng lãnh đạo mà sẽ bẻ luật khi cần thiết. Họ là người tập trung vào
        nguyên tắc. Một khi đã thiết lập ra luật lệ, lãnh đạo Đinh Hoả sẽ tuân
        thủ theo.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lãnh đạo Đinh hoả cảm thấy rằng họ có cảm gíac quyền lực từ việc có một
        bộ nguyên tắc rõ ràng mà họ và những nhân viên có thể tuân thủ theo.
        Theo nghĩa đó, cách lãnh đạo của người Đinh Hoả thường là chú trọng đến
        nguyên tắc được áp đặt từ bên ngoài. Nếu quy luật được áp dụng là nghiêm
        khắc, người lãnh đạo Đinh Hoả sẽ có cách quản trị khắt khe. Nếu nguyên
        tắc dễ dãi, họ cũng sẽ dùng cách lãnh đạo mềm mỏng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu xét đến tính cách bẩm sinh, người Đinh Hoả là những lãnh đạo rất
        quan tâm người khác. Họ có thể cứng rắn trong việc áp dụng nguyên tắc
        đặt ra bởi công ty hoặc cấp trên, nhưng họ cũng muốn đảm bảo rằng nhân
        viên của họ cảm thấy thoải mái và thoả mãn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Không may là điều này có thể tạo ra sự chống đối từ những nhân viên.
        Người Đinh Hoả có thể bị nhận định sai lầm là người hai mặt bởi vì đôi
        khi họ quá quan tâm đến nhu cầu của người khác. Do đó, nếu họ bận rộn cố
        gắng giúp đỡ hay động viên người khác, họ sẽ không thể hiện ra đúng bản
        chất con người của mình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Từ đó, những người xung quanh sẽ kết luận sai về họ rằng người Đinh Hoả
        không có suy nghĩ độc lập. Ngoài ra, dạng quan tâm quá mức đối với cấp
        dưới sẽ có tác dụng tiêu cực đối với người Đinh Hoả. Họ có thể trở nên
        cực kỳ đa nghi đối với cảm xúc của người khác.
      </p>

      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Kinh Doanh</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Trong kinh doanh, người Đinh Hoả là kiểu chủ doanh nghiệp hoặc doanh
        nhân đánh giá khách hàng của mình quá cao. Họ hiểu rằng con người là tối
        quan trọng đối với thành công của công ty, và do đó sẽ không thuộc tuýp
        người lợi dụng lòng tin của khách.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Đinh Hoả thích giao tiếp thẳng thắn với khách hàng, và sẽ cởi mở
        đón nhận bình luận và phản hồi. Họ thích nhận được suy nghĩ và học hỏi
        từ những lời đề nghị, hi vọng nhận được nhiều lợi tức và thu nhập.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Bởi vì bản chất thông cảm, người Đinh Hoả có tài năng rõ rệt có khả năng
        hiểu được khách hàng muốn gì. Họ sẽ có khả năng xác định những gì cần
        nói và cần đáp ứng nhu cầu khách hàng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Xét về mặt tiếp thị và bán hàng, người Đinh Hoả có khả năng tính toán
        logic trong mọi tình huống. Họ giỏi trong việc sắp xếp đưa ra các luận
        điểm và sở hữu khả năng thuyết phục mạnh mẽ. Họ sẽ kiên nhẫn để tác động
        đến khách hàng để dần dần thay đổi quan điểm.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi phải đối mặt với bất kỳ khách hàng nào khó tính, người Đinh Hoả sẽ
        biết cách xoay chuyển tình thế và thuyết phụ một vị khách nào hoài nghi
        nhất cũng tin vào hàng hoá và dịch vụ của họ. Họ làm điều này thông qua
        số liệu và dữ kiện. Họ rất hào hứng và giàu cảm xúc trong tất cả những
        việc làm này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Hầu như mọi lúc, chiến thuật này sẽ giúp họ giành được trái tim người
        khác và biến những khách lẻ trở thành khách hàng trung thành. Tuy nhiên,
        trong nhiều tình huống, nếu khách hàng khá cứng cỏi, người Đinh Hoả có
        thể gây ra hiệu ứng ngược vì thái độ của họ bị đánh giá là thích tranh
        cãi.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Đinh Hoả nhìn có vẻ rất dịu dàng bên ngoài. Tuy nhiên, một khi
        ngọn lửa bén thì họ sẵn sàng bốc hoả. Khi họ đắm chìm hay tham gia vào
        trong một hoạt động nào thì họ sẽ tham gia rất nhiệt tình và hết mình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Bởi vì Âm Hoả đại diện cho lửa, người Đinh Hoả sẽ tiêu thụ tất cả mọi
        thứ cần thiết để làm nhiên liệu, họ ít khi chỉ cháy dở nửa chừng rồi
        ngừng. Để có thể tiếp tục cháy, họ cần nhiên liệu. Đó là lý do Đinh Hoả
        không bao giờ ngồi không. Họ luôn cảm thấy cần phải làm gì đó. Họ hiếm
        khi dừng lại nửa chừng, ngay cả khi nếu thiếu sự hỗ trợ hay chuẩn bị.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Đinh Hoả không nên hành động hấp tấp trong kinh doanh cho tới khi
        họ nghiên cứu và chuẩn bị đầy đủ. Một trong những sai lầm phổ biến của
        người Đinh Hoả trong kinh doanh là họ có xu hướng hành động hấp tấp hay
        nặng về trực giác mà không chuẩn bị đầy đủ. Người Đinh Hoả nên cẩn trọng
        và chính xác hơn trước khi bắt đầu đầu tư tài chính.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Đinh Hoả thích quan tâm đến những người xung quanh họ. Ngược lại,
        họ cũng mong đợi người xung quanh quan tâm đến họ. Cũng tương tự như trẻ
        con, họ thích quan tâm và thích được quan tâm chăm sóc ngược lại.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ thích được nuông chiều và nuôi dưỡng và đôi khi điều này có thể hơi
        khó chịu cho bạn bè thân nhất của họ. Nếu họ không có được sự quan tâm
        mà họ muốn từ bạn bè, hay nếu họ cảm thấy bạn bè phớt lờ họ hoặc không
        chú ý đến họ nhiều như họ muốn, thì họ sẽ mất tinh thần dễ dàng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Do đó, họ có thể gây sự chú ý từ những người xung quanh bằng cách khơi
        gợi lòng thương hại và sự quan tâm nhưng nếu họ làm quá, thì dễ gây hiệu
        ứng ngược lại.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Đinh Hoả có một mối quan hệ đặc biệt với những người lớn tuổi và
        thường dành cho tiền bối sự kính trọng đặc biệt. Họ thích ở bên cạnh
        những người lớn tuổi hơn họ bởi vì họ cảm thấy có nhiều điều có thể học
        hỏi từ những người thông thái hơn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Họ cảm thấy dễ dàng hơn để chấp nhận mệnh lệnh từ những người lớn tuổi
        hơn mình, và sẽ không gặp khó khăn trong việc kết bạn với người lớn
        tuổi. Tại nơi công sở, người Đinh Hoả thường có một người đỡ đầu lớn
        tuổi mà họ có thể tìm đến để xin lời khuyên và trao đổi.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tuy nhiên, nếu một người nhỏ tuổi hơn tìm đến họ để xin lời khuyên thì
        người Đinh Hoả dễ mất kiên nhẫn. Họ không thích trở thành người mà mọi
        người xung quanh tìm đến. Thay vào đó, họ thích cảm thấy thoải mái ở bên
        cạnh những người lớn tuổi mà không bị gánh nặng do cái nhìn ngưỡng mộ từ
        những người nhỏ hơn. Đôi khi, thật sự không phải là vấn đề tuổi tác, mà
        là đa phần là sự trưởng thành về tinh thần. Do đó, bạn sẽ thấy rằng
        người Đinh Hoả thường có mối quan hệ tốt với những người lớn tuổi hoặc
        thông thái hơn họ.
      </p>

      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        <b> • Tài Lộc</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Đinh Hoả thuộc tuýp người tìm kiếm lời khuyên tài chính từ những
        người có chuyên môn cao. Nhất là khi vấn đề có liên quan đến tiền bạc.
        Họ thích được hướng dẫn và chỉ đường. Nếu có thể, họ thường tìm kiếm
        những giải pháp không liên quan đến vấn đề tài chính phức tạp, đặc biệt
        nếu họ không có kiến thức nền về chủ đề đó.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đa phần người Đinh Hoả cảm thấy nếu họ có thể dựa vào người khác sẽ tốt
        hơn. Xét về vấn đề tài chính, họ cảm thấy thà giao cho các chuyên gia tư
        vấn tài chính hơn là tự tìm ra giải pháp cho mình. Vì lẽ đó, người Đinh
        Hoả có thể dễ dàng bị lừa bởi các chuyên gia dỏm nếu họ không cẩn thận.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Điều này là bởi vì họ tin tưởng người khác hoàn toàn nếu họ cảm thấy
        rằng họ có thể tin tưởng vào phương pháp hoạch định kế hoạch tài chính
        mà không xét đến yếu tố tính cách của người mà họ muốn giao phó. Có rất
        nhiều người tri thức và giỏi về tài chính sẵn sàng cho các lời khuyên
        tài chính nhưng sẽ ra sao nếu gặp người có bản tính gian dối và ý định
        không chân thật. Điều này có thể khá nguy hiểm và tốn kém về sau đối với
        một số người Đinh Hoả, vì họ dễ bị lừa gạt vì nghĩ ai cũng tử tế.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Mặc cho việc muốn được hướng dẫn và chỉ lối, người Đinh Hoả tin tưởng
        rất cao vào những phân tích của chuyên gia. Do đó, họ muốn tin tưởng lời
        khuyên chuyên nghiệp nhưng họ chỉ giao phó toàn bộ niềm tin cho những
        chuyên gia tài chính nào mà họ cảm thấy an tâm rằng phương pháp đó là
        đáng tin cậy. Nói cách khác, họ tin và “dữ kiện và con số”.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Đinh Hoả thường không phải dạng người đốt nến và cầu nguyện xin
        tài lộc. Họ không thuộc dạng người tin vào tâm linh để có thể tìm câu
        trả lời về tiền bạc. Người Đinh Hoả thích dữ kiện. Họ thích các phương
        pháp chính quý.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Do đó, họ dành sự tôn trọng cao đối với những phân tích tài chính dựa
        trên nền tảng sự thật, dữ kiện và số liệu. Ngay cả nếu họ không hoàn
        toàn hiểu các hệ thống hay phương pháp này, họ vẫn sẽ trân trọng giá trị
        của nó và sẽ quý trọng lời khuyên của những người quản lý tài chính theo
        các cấu trúc này. Ví lẽ này, người nào muốn thuyết phục người Đinh Hoả
        sẽ cần phải thuyết phục họ tin vào phương pháp phân tích của riêng bạn.
      </p>
    </div>
  );
};
