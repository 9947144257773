import firebase from 'firebase/app';
import "firebase/firestore";
import { signOut } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDz2cKTIbcfhUe724IjAvIxbvGHAYT8gek",
  authDomain: "huyenkhongbattu.firebaseapp.com",
  projectId: "huyenkhongbattu",
  storageBucket: "huyenkhongbattu.appspot.com",
  messagingSenderId: "1045246803033",
  appId: "1:1045246803033:web:96e45001bea4a17f7a8548",
  measurementId: "G-G2ZL8V9ZPZ"
};

const db = {
  apiKey: "AIzaSyAqDXUKgULXkPDQIs2Le_beT6R0_JcmyY8",
  authDomain: "tmfs-yi-jing.firebaseapp.com",
  databaseURL: "https://tmfs-yi-jing.firebaseio.com",
  projectId: "tmfs-yi-jing",
  storageBucket: "tmfs-yi-jing.appspot.com",
  messagingSenderId: "331042421722",
  appId: "1:331042421722:web:862f72fc6b6c3ed5419ee3"
};

firebase.initializeApp(db);

export const AuthDB = ()=>{

  const auth = firebase.auth();
  auth.setPersistence(firebase.auth.Auth.Persistence.SESSION)
  .then(() => {
    return auth.signInWithEmailAndPassword( process.env.REACT_APP_EMAIL_AUTH, process.env.REACT_APP_PASS_AUTH);
  })
  .catch((error) => {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log('xem data errr',errorCode,errorMessage)
  });

}
export const auth = firebase.auth();

export const firebase_store = firebase.firestore();

const provider = new firebase.auth.GoogleAuthProvider();

provider.setCustomParameters({ prompt: 'select_account' });

export const signInWithGoogle = () => auth.signInWithPopup(provider);

export const logOut = () => firebase.auth().signOut();


export default firebase_store;
