export const BinhThangTi = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Bính trong tháng Tý
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngày sinh trong tháng này sẽ hình thành cục Chính Quan nếu có Quý Thủy
        lộ ở Thiên Can. Ngay cả nếu không lộ thì cũng có khả năng hình thành cục
        Thất Sát.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với sinh trong tháng Tý thì dụng thần điều hậu là Nhâm Thủy, Mậu Thổ
        và Kỷ Thổ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nhâm Thủy đóng vai trò là dụng thần chính trong khi Mậu Thổ có thể được
        dùng để kiểm soát Nhâm Thủy. Cùng nhau thì Nhâm Thủy và Mậu Thổ giúp cho
        Bính Hỏa biến thành mạnh.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Có thể dùng Kỷ Thổ để thay thế Mậu Thổ nếu thiếu.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Thủy lạnh và dễ đóng băng trong tháng Tý cũng như Mộc dễ bị băng giá.
        Trong những hoàn cảnh đó, Bính Hỏa bị cô lập và không được sự giúp đỡ từ
        xung quanh.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đó là lý do cần có Mộc nóng. Mộc sẽ giúp níu giữ Hỏa Khi cần thiết để có
        thể đem hơi ấm đến cho khung cảnh băng giá. Mộc sẽ sinh ra và đảm bảo
        Hỏa liên tục.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Không cần nói thì nếu không có Mộc và Thủy khí thì sẽ khó lòng để Bính
        Hỏa tỏa sáng và giữ được độ sáng rõ trong thời điểm này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Vì Kim, khắc và làm suy yếu Mộc cũng như sinh ra Thủy thì nên tránh Kim
        khí càng nhiều càng tốt.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngoài ra, Thổ nóng có thể được dùng để kiểm soát Thủy. Ngoài ra, sự hiện
        diện và hỗ trợ của Mộc sẽ giúp sinh ra vào tạo tính liên tục của Hỏa.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nhâm Thủy là dụng thần hàng đầu của ngày sinh này trong khi Mậu Thổ là
        dụng thần thứ hai. Khi cả hai cùng có mặt thì sẽ không cần Giáp Mộc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Mậu Thổ được dùng để kiểm soát Thủy khi lá số thiếu Giáp Mộc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu không thì khi có mặt thì Giáp Mộc không nên thiếu Đinh Hỏa, Mậu
        Thổ hay Nhâm Thủy.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Đinh Hỏa tách ra khỏi Giáp Mộc thì ngày sinh này sẽ khó thành công
        trong đời. Giáp Mộc chỉ cần khi có rủi ro Thổ dày.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Mậu Thổ và Kỷ Thổ cùng với Nhâm Thủy xuất hiện ở Thiên Can nhưng
        Giáp Mộc thì không, ngày sinh này sẽ thuộc về một người học thức.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có Nhâm Thủy nhưng bị Mậu Thổ khắc thì đây là mâu thuẫn giữa các
        Dụng Thần. Điều này biểu thị khả năng người này chỉ thành công ở lĩnh
        vực văn chương học thuật. Họ có thể có thành công bị giới hạn trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Sẽ không cần có Nhâm Thủy xuất hiện ở Thiên Can nếu Địa Chi tạo thành
        Hỏa Cục.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Thất Sát và Thực Thần:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Cần có Mậu Thổ để kiểm soát Nhâm Thủy Thất Sát.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Mộc Ấn Tinh không nên thiếu, có thể dùng để sinh ra Bính Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số xấu nếu Nhâm Thủy xuất hiện ở Thiên Can và hình thành Thủy Cục với
        các Địa Chi. Trong hoàn cảnh đó thì ngày sinh này sẽ chỉ hưởng thành
        công rất ngắn ngủi.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Để tạo thành cục Tòng Sát thì Mộc không được xuất hiện trong lá số.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Mộc và Hỏa xuất hiện nhiều trong Địa Chi thì Nhâm Thủy Thất Sát có
        thể được dùng để đảm bảo ngày sinh vẫn được cân bằng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Thủy xuất hiện nhiều ở Địa Chi thì Mậu Thổ Thực Thần cần dùng để
        kiểm soát Thủy.
      </p>
    </div>
  );
};

export const BinhThangSuu = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Bính trong tháng Sửu
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngày sinh Bính trong tháng Sửu thì Thương Quan hình thành khi Kỷ Thổ
        xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Tân Kim xuất hiện ở Thiên Can thì hình thành cách cục Chính Tài.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Quý Thủy xuất hiện ở Thiên Can thì hình thành cách cục Chính Quan.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nêu không có Tân Kim, Quý Thủy hay Bính Hỏa thì tùy theo ngũ hành nào
        vượng nhất mà chọn cục.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với tháng sinh Sửu thì Nhâm Thủy và Giáp Mộc là dụng thần điều hậu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nhâm Thủy là dụng thần số 1. Khi có nhiều Thổ thì cần có Giáp Mộc để
        kiểm soát Thổ.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Trong tháng Sửu thì Thủy mạnh sẽ tạo nên đất ướt, băng giá và lạnh lẽo
        khiến cho Bính Hỏa Khí bị suy yếu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngoài ra, Mộc Ấn Tinh có thể lạnh lẽo và ẩm ướt cũng như không thể giúp
        sinh ra hay củng cố ngày sinh. Thực sự thì hoàn cảnh này sẽ dẫn đến ngày
        sinh yếu.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Điều này là lý do vì sao Mộc nóng có thông căn là dụng thần cần thiết
        đối với ngày sinh này. Điều này chỉ khi kèm với sức mạnh của Mộc và Hỏa
        mà củng cố Bính Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Cũng cần tránh gặp thêm Kim và Thủy, càng nhiều càng tốt.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Điều này bởi vì Kim và Thủy có khả năng khắc chế khí của Bính Hỏa.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nhâm Thủy là dụng thần Chính và Giáp Mộc là dụng thần Phụ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Kỷ Thổ lộ ở Thiên Can thì cần có Giáp Mộc để kiểm soát.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Vì Bính là Dương Hỏa, nên có thể chịu được sự tấn công của khí lạnh
        của tháng. Nhâm Thủy là cần thiết và là dụng thần để Bính Hỏa tỏa sáng.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Địa Chi Sửu đóng vai trò là Kho của Thổ vì Kim Khí và kho Tài Khí. Nếu
        cả hai đều xuất hiện ở Thiên Can thì cần có Giáp Mộc để biến thành hữu
        dụng.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Nhâm Thủy được dùng như là Dụng Thần đối với ngày sinh này, Kỷ Thổ
        không nên được phép làm bẩn Nhâm Thủy. Điều này bởi vì mặc dù không có
        nguy cơ Bính Hỏa bị Nhâm Thủy khắc nhưng có nguy cơ Kỷ Thổ làm suy yếu
        Bính Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Nhâm Thủy là dụng thần thì có nhu cầu cần phải ngăn cản Kỷ Thổ
        không làm bẩn nó.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Kỷ Thổ không nên xuất hiện ở Thiên Can, ngoại trừ việc Tân Kim cũng
        xuất hiện ở lá số này. Kim làm suy yếu Thổ. Nhưng lưu ý rằng Tân Kim
        cũng có xu hướng kết hợp với Bính Hỏa để hình thành Thủy. Đó là lý do vì
        sao Giáp Mộc nên xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Giáp Mộc ẩn tàng ở Địa Chi thì ngày sinh này sẽ sống cuộc đời
        trung bình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Kỷ Thổ xuất hiện nhiều trên Thiên Can nhưng Giáp Mộc và Ất Mộc
        hoàn toàn vắng bóng thì là tình huống xấu. Điều này bởi vì Thương Quan
        sẽ khá yếu và không thể tạo thành cục Tòng Quan. Và ngay cả nếu tạo
        thành cục đặc biệt thì cũng chỉ thành công bề nổi, bất kể người này giỏi
        giang sáng sủa đến đâu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Nhâm Thủy không có mặt ở Thiên Can nhưng có Quý Thủy nhiều, có
        nguy cơ rằng Quý Thủy khắc và bị Kỷ Thổ làm bẩn. Trong hoàn cảnh đó thì
        ngày sinh này sẽ không thể vượng trong đời, bất kể họ có tinh thần doanh
        nhân như thế nào.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Thất Sát và Thiên Ấn:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu cả Nhâm Thủy và Giáp Mộc xuất hiện vượng, ngày sinh này sẽ có thành
        công trong sự nghiệp.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu thiếu Giáp Mộc nhưng có Nhâm Thủy thì ngày sinh này sẽ thịnh vượng
        và trở nên giàu có, dù chỉ ở mức độ giới hạn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngày sinh này sẽ khá trong đời, đặc biệt nếu bước vào đại vận Mộc hay
        Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số xấu nếu không có Nhâm Thủy.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Thương Quan và Chính Quan:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Kỷ Thổ ở Thiên Can và Giáp Ất Mộc thiếu thì ngày sinh này chỉ thành
        công trên bề mặt; bất kể họ thông minh hay xuất sắc như thế nào.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi cả Kỷ Thổ và Quý Thủy xuất hiện ở Thiên Can cùng lúc, thì ngày sinh
        này sẽ tốt hơn trong đời mặc dù có nhiều vấn đề sức khỏe.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Giáp Mộc Thiên Ấn lý tưởng hơn Ất Mộc nếu xét về Dụng Thần.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Kỷ Thổ và Quý Thủy cùng xuất hiện ở Thiên Can thì Tân Kim nên xuất
        hiện, đặc biệt nếu ngày sinh này có thành công về mặt quản trị.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu không có Giáp Ất Mộc Ấn Tinh thì cục Thương Quan này sẽ khá yếu hoặc
        chỉ hời hợt trên bề mặt.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu gặp cục Thiên Tài:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Canh Kim Thiên Tài xuất hiện ở trụ Giờ hay Tháng thì người này sẽ là
        người có học thức. Lý tưởng nếu không xuất hiện Tân Kim.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Kỷ Thổ tạo thành cục Thương Quan và cả Giáp và Ất Mộc đều thiếu,
        thì bất kỳ cục Thương Quan nào cũng sẽ yếu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Không cần Bính Hỏa lo lắng về việc bị khắc bởi Nhâm Thủy miễn là Mộc
        có đầy đủ, để sinh ra và đảm bảo tính liên tục của Hỏa khí.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Nhâm Thủy thiếu trong lá số thì lá số đó chỉ kém cỏi.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu không có Giáp Mộc thì sẽ khó để ngày sinh này được hỗ trợ. Sẽ khó
        để dùng Tỷ Kiếp hỗ trợ tăng cường sức mạnh cho ngày sinh nếu thiếu Giáp.
      </p>
    </div>
  );
};

export const BinhThangDan = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Bính trong tháng Dần
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với ngày sinh Bính trong tháng Dần thì hình thành cục Thiên Ấn nếu
        Giáp Mộc lộ ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Bính hỏa lộ ra ở Thiên Can thì thành hình thành cục Lâm Quan
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tuy nhiên, nếu không có Giáp Mộc, Bính Hỏa hay Mậu Thổ xuất hiện ở Thiên
        Can thì người này có thể chọn cục theo ngũ hành mạnh nhất trong lá số.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nhâm Thủy và Canh Kim là dụng thần điều hậu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nhâm Thủy là dụng thần chính trong khi Canh Kim là dụng thần phụ.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Mộc và Hỏa cùng xuất hiện thì sẽ sinh ra và củng cố ngày sinh Bính
        Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nhâm Thủy có khả năng chuyển hóa và phát huy Bính Hỏa. Ngoài ra thì Kim
        cũng cần xuất hiện đồng thời để sinh ra Thủy.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Thổ xuất hiện nhiều, ngày sinh này sẽ thông minh và giàu có cũng như
        tài năng.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nhâm Thủy là dụng thần chính của ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Sẽ không tốt nếu gặp Kỷ Thổ ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Bính Hỏa không quá mạnh trong tháng này vì đây là thời điểm giao giữa
        Đông và Xuân. Bính Hỏa biểu thị ánh sáng mặt trời bắt đầu tỏa ra sự ấm
        áp khi mùa đông lạnh giá đi qua.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Vai trò của Nhâm Thủy ở đây là không phải là kiểm soát Bính Hỏa. Thay
        vào đó, nó hiện diện để cho phép Bính Hỏa mặt trời tỏa sáng lấp lánh
        trên mặt nước và tạo ra một bức tranh hữu tình.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Bính Hỏa không nên tách rời Nhâm Thủy, cũng như Nhâm Thủy không nên
        tách rời Canh Kim. Đó là lý do vì sao Nhâm Thủy là dụng thần chính của
        ngày sinh này cũng như Canh Kim đóng vai trò là dụng thứ hai.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Kỷ Thổ hiện diện ở Địa Chi thì ngày sinh này sẽ biểu thị người
        giỏi giang, nhiều kiến thức và điều này sẽ giúp cho người này đạt thành
        công, địa vị và quyền lực.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Kỷ Thổ và Nhâm Thủy hiện diện ở Thiên Can thì người này sẽ có tính
        cách cứng rắn, thậm chí ngang ngạnh và hơi thiên về gây ra nhiều việc
        sai lầm trong đời. Chỉ với sự có mặt của Mậu Thổ thì tình hình mới được
        giảm bớt.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có Canh Kim và Tân Kim cùng xuất hiện ở Thiên Can thì ngày sinh
        này sẽ có cuộc sống trung bình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi có Thiên Ấn xuất hiện ở Thiên Can thì ngày sinh này sẽ là người
        tri thức.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi có 2 Chính Tài Tân Kim xuất hiện ở Thiên Can cùng tranh nhau hợp
        với Bính Hỏa để tạo thành Thủy thì bất kể là ngày sinh nam hay nữ thì
        người này đều bị ảnh hưởng bởi nghiện rượu cũng như có tính cách dâm
        đãng. Người này sẽ thiếu định hướng trong cuộc sống và cảm thấy khó
        thành công trong mọi việc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Canh Kim và Tân Kim đều không hiện diện, người này sẽ có thể hướng
        đến một cuộc sống giản dị và khiêm tốn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Mậu Thổ xuất hiện ở Thiên Can, và Giáp Mộc không đồng xuất hiện
        thì người này sẽ cảm thấy khó để để xây dựng sự nghiệp cho bản thân cũng
        như có thể nghèo khó.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Địa Chi hình thành Hỏa Cục, Mậu Thổ không được lộ ở Thiên Can. Nếu
        không thì ngày sinh này chỉ sống cuộc đời trung bình. Điều này đúng,
        ngay cả nếu Nhâm Thủy cũng hiện diện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Ngay cả nếu Địa Chi hình thành Hỏa Cục, Hỏa là khá yếu trong tháng
        Dần. Do đó ngày sinh này sẽ bị ảnh hưởng bởi sự cô đơn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Quý Thủy dùng thay cho Nhâm Thủy thì mọi cục sẽ chỉ trung bình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi cả Canh Kim và Nhâm Thủy cùng xuất hiện thì ngày sinh này sẽ hưởng
        thành công lớn trong sự nghiệp. Chỉ khi có Nhâm Thủy xuất hiện mà không
        có Canh Kim thì ngày sinh này vẫn sở hữu tính cách rộng lượng, duyên
        dáng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Sẽ xấu nếu gặp Canh Thân vì lại xung khắc với Dần.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu gặp cục Thất Sát Chính Quan:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Địa Chi tạo thành Thủy Cục thì Mậu Thổ cần có mặt để kiểm soát Thủy.
        Sẽ tốt hơn nếu có Bính Hỏa Tỷ Kiên và Đinh Hỏa Kiếp Tài hiện diện.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu thiếu Nhâm Thủy, có thể dùng Quý Thủy thay. Tuy nhiên, Quý Thủy phải
        thông căn ở Địa Chi. Tuy vậy, ngày sinh này có thể bị bệnh về mắt.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Nhâm Thủy xuất hiện nhiều nhưng không có Mậu Thổ để kiểm soát thì
        ngày sinh này có thể là người cứng đầu và có thể phạm nhiều sai lầm
        trong đời.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu gặp cục Chính Tài:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Tân Kim là ở can giờ và năm thì người này có tính cách tham lam vì
        xảy ra tranh hợp. Họ cũng sẽ nghiện rượu, có tính cách dâm đãng. Người
        này sẽ tiêu phí hết tài sản ông bà để lại.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lý tưởng nếu Tân Kim cách xa ngày sinh.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu gặp cục Thiên Tài:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Canh Kim Thiên Tài xuất hiện ở trụ Giờ hay Tháng thì người này sẽ là
        người có học thức. Lý tưởng nếu không xuất hiện Tân Kim.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu gặp cục Thực Thần:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Mậu Thổ xuất hiện nhiều thì Giáp Mộc cũng nên xuất hiện ở Thiên Can.
        Nếu không thì ngày sinh này sẽ bị nghèo suốt đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Giáp Mộc Thiên Ấn xuất hiện ở Thiên Can là tốt.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu gặp cục Tỷ Kiếp:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Hỏa Cục hình thành ở Địa Chi, Quý Thủy không được hiện diện. Tốt nếu
        xuất hiện Nhâm Thủy. Không tốt nếu có Quý Thủy ở dạng Tý.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Sẽ không có lợi nếu Đinh Hỏa Kiếp Tài xuất hiện. Bởi vì Đinh Hỏa hợp
        với Nhâm Thủy hình thành Mộc. Trong hoàn cảnh này thì ngày sinh dễ mất
        Nhâm Thủy vốn là dụng thần điều hậu quan trọng.
      </p>
    </div>
  );
};

export const BinhThangMao = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Bính trong tháng Mão
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với ngày sinh Bính Trong tháng Mão thì hình thành cục Chính Ấn khi
        Ất Mộc xuất hiện ở Thiên Can. Nếu không xuất hiện ở Thiên Can cũng có
        thể xét hình thành cục.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng Mão thì dụng thần điều hậu là Nhâm và Kỷ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ưu tiên cho dụng thần Nhâm. Khi có Nhâm nhiều thì dùng Mậu Thổ điểm
        soát.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Bính yếu thì dùng Mộc để hỗ trợ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu thiếu đi Nhâm Thủy thì Kỷ Thổ dùng như là Dụng Thần.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Bính Hỏa vượng trong tháng ngày xuất hiện nhiều Mộc vượng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Vì Mộc và Hỏa vượng nên Kim và Thủy và Dụng Thần quan trọng.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Kim cần để kiểm soát Mộc cũng như sinh ra Thủy.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi thỏa các điều kiện trên thì nữ giới sinh ngày này sẽ có bản thân
        mạnh mẽ và có thể hấp dẫn người chồng giàu có. Ngoài ra, họ cũng sẽ có
        địa vị và quyền lực cao trong đời, bởi vì sự ảnh hưởng và ủng hộ của
        người chồng.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nhâm Thủy là dụng thần quan trọng nhất.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Không nên có Đinh Hỏa trên Thiên Can vì có thể hợp với Nhâm để hình
        thành Mộc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Kỷ Thổ đi kèm thì có nguy cơ làm ô nhiễm Nhâm Thủy, ngày sinh này
        sẽ có cuộc đời trung bình.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Mặc dù Nhâm Thủy là dụng thần chính đối với ngày sinh này, Canh Kim
        vốn là dụng thần thứ hai cũng không nên vắng mặt.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi thiếu Nhâm Thủy hoặc không có ở Thiên Can thì có thể dùng Kỷ Thổ
        để như là dụng thần thay thế. Tuy nhiên, trong tình huống này thì ngày
        sinh sẽ chỉ trên trung bình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Nhâm Thủy xuất hiện nhiều trên Thiên Can thì Mậu Thổ có thể dùng
        để kiểm soát. Trong mọi tình huống thì không nên dùng Kỷ Thổ vì dễ làm
        bẩn nước Nhâm.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Nhâm Thủy xuất hiện ở 4 trụ và lá số không có Mậu Thổ thì ngày
        sinh này sẽ có xu hướng mất định hướng trong đời. Tuy nhiên, sẽ có bất
        lợi nếu Tân Kim và Bính Hỏa hợp với nhau. Chỉ khi nào có sự hiện diện
        của Đinh Hỏa thì mới có thể thay đổi kịch bản trên. Nếu không có Đinh
        Hỏa để khắc chế và kiểm soát Tân Kim thì ngày sinh này có xu hướng
        nghiện rượu, dâm đãng và tiêu hết tài sản thừa kế.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Nhâm Thủy sẵn sàng để sử dụng như là Dụng Thần cho ngày sinh này
        thì Giáp Mộc nên xuất hiện trong lá số để có thể ngăn Mậu Thổ không kiểm
        soát Nhâm Thủy quá nhiều.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Sẽ không tốt nếu có hơn 2 Chính Tài Kim xuất hiện, bởi vì cả hai sẽ
        cạnh tranh với nhau để hợp hóa với Bính Hỏa tạo thành Thủy.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Người sinh vào giờ Tân hay tháng Tân sẽ có thể thiết lập được chỗ đứng
        trong đời nhất là liên quan đến sự nghiệp.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có mặt cùng lúc Nhâm Thủy và Canh Kim thì ngày sinh này sẽ vượng.
        Nếu thiếu Nhâm Thủy và Quý Thủy, có thể mượn Kỷ Thổ để làm dụng thần
        thay thế. Tuy nhiên, trong tình huống đó thì ngày sinh này sẽ khó thành
        công thật sự bất kể họ tài năng như thế nào. Nếu Mậu Thổ xuất hiện ở
        Thiên Can và kiểm soát được Nhâm Thủy mạnh thì cần có Giáp Mộc để khống
        chế và kiểm soát Mậu Thổ. Không tốt nếu có xuất hiện Đinh Hỏa hợp với
        Nhâm Thủy để tạo thành Mộc. Hay có hiện diện Mậu Thổ để hình thành khắc
        Nhâm Thủy mạnh.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu có cục Chính Quan Thất Sát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Địa Chi hình thành Chính Quan hay Thất Sát thì Mậu Thổ cần xuất
        hiện để kiểm soát Thủy. Mậu Thổ nên xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu không có Mậu Thổ thì ngày sinh này sẽ sống cuộc đời vô vị.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Kim xuất hiện nhiều và sinh ra Thủy thì lá số này sẽ kém cỏi.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu có cục Thực Thần:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi có Mậu Thổ xuất hiện nhiều thì cần có Nhâm Thủy như là dụng thần.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Giáp Mộc thì tốt. Không nên trải qua đại vận Thổ.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Đinh Hỏa chỉ cần để khắc chế Tân Kim nếu có xuất hiện Tân.
      </p>
    </div>
  );
};

export const BinhThangThin = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Bính trong tháng Thìn
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với ngày sinh Bính trong tháng Thìn, cục Thực Thần hình thành khi
        Mậu Thổ xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Ất Mộc xuất hiện ở Thiên Can thì hình thành cục Chính Ấn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Quý Thủy xuất hiện ở Thiên Can thì hình thành cục Chính Quan.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tuy nhiên nếu không có cả Mậu Thổ hay Ất Mộc hay Quý Thủy xuất hiện ở
        Thiên Can thì nên chọn theo ngũ hành nào vượng nhất trong lá số.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với sinh trong tháng Thìn thì Nhâm Thủy và Giáp Mộc được xem là Dụng
        Thần Điều Hậu quan trọng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nên ưu tiên cho Nhâm Thủy như là dụng thần chính. Ngoài ra, nếu xuất
        hiện nhiều Thổ thì cần Giáp Mộc như là dụng thần phụ để kiểm soát Thổ.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Thổ vượng trong tháng Thìn. Sẽ tạo ra ảnh hưởng lớn đến Nhâm Thủy.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đó là lý do vì sao Thủy xét về sức mạnh được yêu cầu là dụng thần chính
        trong hoàn cảnh này. Và khi Thổ vượng thì Mộc có thể được dùng để làm
        tơi đất, và kiểm soát Thổ.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngoài ra, Kim được xem là Dụng Thần khi nếu Hỏa nhiều.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Cần ưu tiên Nhâm Thủy là dụng thần chính. Giáp Mộc là dụng thần phụ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Bất cứ khi nào Thổ vượng thì cần có Giáp Mộc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Không nên có Kỷ Thổ xuất hiện ở Thiên Can.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Nhâm Thủy và Giáp Mộc cùng xuất hiện thì ngày sinh này sẽ hưởng
        thành công trong sự nghiệp và địa vị cũng như danh tiếng. Tuy nhiên,
        không nên thấy Canh Kim xuất hiện ở Thiên Can. Điều này bởi vì Mộc sẽ bị
        Kim chẻ nhỏ. Nếu rơi vào tình huống này thì người này sẽ chỉ có cuộc đời
        trung bình.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Nhâm Thủy xuất hiện ở Thiên Can nhưng Giáp Mộc ẩn tàng ở Địa Chi
        thì người này có thể vẫn giàu có nhưng địa vị thì không bằng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Giáp Mộc xuất hiện ở Thiên Can nhưng thiếu Nhâm Thủy thì ngày sinh
        này sẽ chịu cô đơn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu cả Nhâm Thủy và Giáp Mộc đều không có mặt ở Thiên Can thì Nhâm
        Thủy có thể xuất hiện ở tàng can mặc dù không có Giáp Mộc thì người này
        sống cuộc đời đơn giản dù cho họ có giỏi giang học thức đến bao nhiêu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Nhâm Thủy và Giáp Mộc hoàn toàn biến mất khỏi lá số thì này sinh
        này có thể sống đời sống của một người mờ nhạt.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu cả Nhâm Thủy và Giáp Mộc đều hiện ra ở Thiên Can thì Canh Kim
        không được xuất hiện ở Thiên Can cùng lúc. Nếu không có Nhâm Thủy và
        Giáp Mộc ở Thiên Can thì Canh Kim phải xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Đinh Hỏa và Kỷ Thổ cùng có mắt ở Thiên Can thì ngày sinh này sẽ
        không đạt được thành công cao dù giỏi giang bao nhiêu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có Thân Tý Thìn tạo thành Tam Hợp Thủy thì không nên xuất hiện
        Nhâm Thủy lộ và thông căn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Thủy cục hình thành và Nhâm Thủy lộ Thiên Can thì Mậu Thổ phải
        xuất hiện ở cả Thiên Can và Địa Chi.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Mậu Thổ không xuất hiện ở Thiên Can nhưng vẫn nằm ở tàng Can thì
        ngày sinh này sẽ sống tầm thường. Cuộc sống đầy trở ngại.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có Dần, Ngọ và Tuất hình thành Hỏa Cục và có Bính Đinh lộ Thiên
        Can thì Mậu Thổ phải lộ ở Thiên Can. Mậu Thổ sẽ đảm bảo sự ổn định của
        lá số này.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Nhâm Thủy và Giáp Mộc:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Nhâm Thủy và Giáp Mộc là dụng thần thì ngày sinh này sẽ hưởng
        thành công trong sự nghiệp và cuộc sống.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Giáp Mộc ẩn tàng thì Canh Kim phải xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Canh Kim và Tân Kim lộ và tấn công Giáp Mộc thì ngày sinh này sẽ
        không thật sự thành công bất kể thông minh hay có kiến thức bao nhiêu.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu có cục Thực Thần Thương Quan:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Địa Chi tạo thành Thổ Cục thì Giáp Mộc cần. Giáp Mộc nên xuất hiện ở
        Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nhâm Thủy được xem l2 dụng thần quan trọng cho ngày sinh Bính Hỏa trong
        tháng Thìn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Địa Chi tạo thành Thổ Cục thì Giáp Mộc cần xuất hiện để kiểm soát
        Thổ, và Nhâm Thủy cũng không nên thiếu.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nhâm Thủy và Giáp Mộc đóng vai trò quan trọng là dụng thần.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu xuất hiện Nhâm Thủy ở Thiên Can nhưng Giáp Mộc tàng can thì ngày
        sinh này có thể giàu có nhưng không được địa vị cao.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Nhâm Thủy không xuất hiện ở Thiên Can với Giáp Mộc vắng hoàn toàn
        thì ngày sinh này sẽ chỉ trung bình bất kể học hành giỏi giang bao
        nhiêu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi cả Nhâm và Giáp đều vắng mặt thì ngày sinh này sẽ khó thành công
        trong đời bất kể vào đại vận nào.
      </p>
    </div>
  );
};

export const BinhThangTy = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Bính trong tháng Tỵ
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với ngày sinh Bính trong tháng Tỵ thì Địa Chi Tỵ là vị trí Đế Vượng
        của Bính Hỏa. Đây là cách cục Vượng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Bính Hỏa lộ lên trên Thiên Can thì hình thành cục Lâm Quan.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Mậu Thổ hình thành ở Thiên Can thì hình thành cục Thực Thần nếu thỏa
        các điều kiện.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Canh Kim hình thành ở một trong những Thiên Can thì cục Thiên Tài
        nếu thỏa các điều kiện.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với tháng Tỵ thì Nhâm Thủy, Canh Kim và Quý Thủy là dụng thần điều
        hậu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Canh Kim là dụng thần phụ của ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Không nên thấy Mậu Thổ quá vượng vì kiểm soát Nhâm Thủy vốn là dụng thần
        chính của ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu thiếu Nhâm Thủy thì có thể dùng Quý Thủy thay.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Giáp Mộc vượng thì Kim và Thủy sẽ khô và yếu. Nếu Canh Kim lộ thì
        người này sẽ có cuộc sống thịnh vượng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Cần phải tránh có thêm ngũ hành Hỏa khắc Kim. Tương tự nếu có Thổ sẽ
        khắc Thủy.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Không tốt cho ngày sinh này nếu gặp thêm Mộc. Nếu Hỏa nhiều thì ngày
        sinh này sẽ rất vượng.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nên ưu tiên cho Nhâm Thủy là dụng thần chính, tốt hơn nếu có thông
        căn. Cần phải tránh Hợi và Tỵ xung nhau trong lá số.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Nhâm Thủy thiếu ở Thiên Can thì người này sẽ thiếu động lực thành
        công.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Ngay cả nếu Nhâm Thủy xuất hiện thì Canh Kim cũng cần xuất hiện ở
        Thiên Can.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có cả Nhâm Thủy và Canh Kim nhưng không có Mậu Thổ và Kỷ Thổ ở
        Thiên Can thì ngày sinh này sẽ thành công lớn.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Nhâm Thủy là dụng thần thì nên có ít nhất một Địa Chi Thân. Địa
        Chi Thân lý tưởng nếu ở cạnh Hợi.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Quý Thủy có mặt thay cho Nhâm Thủy và Bính Hỏa cũng như Quý Thủy
        cùng có ở Thiên Can thì ngày sinh này sẽ chỉ đạt thành công và tài lộc
        bị giới hạn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu cả Nhâm Thủy và Quý Thủy đều thiếu, ngày sinh này sẽ trung bình.
        Họ sẽ vất vả mưu sinh.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu cả Mậu Thổ và Kỷ Thổ cùng xuất hiện ở Thiên Can thì sự nghiệp và
        quan hệ kém cũng như sức khỏe tệ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Mộc và Hỏa Cục hình thành trong lá số thì cần có Thủy. Nếu không
        thì quá nhiều Hỏa dẫn đến khó được ủng hộ trong mọi việc. Họ chịu sự cô
        đơn và khổ trong suốt cuộc đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Kim Tài Tinh xuất hiện nhưng Tỷ Kiếp không xuất hiện ở Thiên Can
        thì người này sẽ giàu có. Nếu có Tỷ Kiếp xuất hiện thì ngày sinh này sẽ
        chịu nghèo khổ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu thiếu Nhâm Thủy và Bính Hỏa tọa trên Ngọ thì hình thành Dương Nhẫn
        Tiêu Cực. Trong tình huống đó thì người này sẽ thiếu nguyên tắc sống, dễ
        làm những gì lỗi lầm.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Kỷ Thổ xuất hiện và kiểm soát Nhâm Thủy thì ngày sinh này sẽ có
        tính cách đáng khinh.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số vừa có Canh Kim và Nhâm Thủy:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Có Canh Kim ủng hộ Nhâm Thủy người này sẽ giàu có.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tốt hơn nếu có ít nhất một Thân trong lá số. Không tốt nếu Kỷ Thổ làm
        bẩn Nhâm Thủy.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Canh Kim và Quý Thủy:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người này sẽ giàu có tuy rằng chỉ bị giới hạn, bất kể họ thông minh hay
        không.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Canh Kim nếu có căn gốc ở Địa Chi Thân thì sẽ tốt. Xấu nếu Kỷ Thổ ở
        Thiên Can.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Thiên TÀi Cục:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Canh Kim giúp sinh Thủy khí vốn cần cho lá số này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Canh Kim ở Thiên Can sẽ rất tốt. Nếu có Địa Chi tạo thành Hỏa Cục cũng
        như có Tỷ Kiếp ở Thiên Can thì ngày sinh này sẽ nghèo.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu thiếu Nhâm và Quý Thủy thì không chỉ ngày sinh này nghèo mà họ còn
        chịu cô đơn cũng như đoản thọ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu tạo thành Dương Nhẫn thì không được dùng Giáp Mộc hay Ất Mộc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có sự hình thành của Dương Nhẫn thì sự hiện diện của Nhâm Thủy kèm
        với vắng mặt Mậu Thổ sẽ khiến cho lá số này không bị tai nạn và dễ cô
        đơn và thất vọng trong đời.
      </p>
    </div>
  );
};

export const BinhThangNgo = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Bính trong tháng Ngọ
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Sinh trong tháng này thì hình thành cục Thương Quan nếu Kỷ Thổ xuất hiện
        ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Đinh Hỏa xuất hiện ở Thiên Can thì hình thành cục Dương Nhẫn nếu
        thỏa đủ điều kiện.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với tháng Ngọ thì Nhâm Thủy và Canh Kim là dụng thần điều hậu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Nhâm Thủy và Canh Kim là dụng thần phù hợp thì nên có thông căn ở
        Thân.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi hình thành cục Dương Nhẫn thì Hỏa và Thổ thì Hỏa và Thổ sẽ quá vượng
        trong lá số.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đây là lý do mà cần có Kim và Thủy thông căn, mặc dù sự hiện diện của 2
        hành này sẽ bị yếu vì quá nhiều Hỏa Thổ. Khi Thủy xuất hiện ở Thiên Can
        thì sự hiện diện của Kim và Thủy ở Địa Chi sẽ giúp cải thiện tình hình.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Thổ ẩm ướt là Dụng Thần Phù Ức khi Mộc và Hỏa xuất hiện nhiều. Chỉ có
        Thổ này mới có khả năng sinh và tạo ra Kim, và cùng lúc làm suy yếu Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Thủy đại diện cho Quan Tinh đối với lá số nữ. Đây là lý do vì sao Kim
        cần để sinh ra Thủy.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Thổ và Kim đóng vai trò quan trọng với lá số nam thì Thủy sẽ đại
        diện cho tài năng lãnh đạo. Sẽ tốt hơn nếu gặp Thủy, vì sẽ cho phép ngày
        sinh này được hưởng danh tiếng và may mắn trong đời.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Lý tưởng nhất là này sinh này gặp Nhâm Thủy và Canh Kim ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Không tốt nếu có Mậu Thổ, Kỷ Thổ hay Đinh Hỏa xuất hiện ở Thiên Can
        cùng với Canh Kim.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Ngọ Hỏa chứa Đinh Hỏa và Kỷ Thổ tàng can. Và Kỷ Thổ có khả năng khắc
        chế Thủy. Đây là lý do vì sao sẽ cần thiết nếu gặp được Nhâm Thủy, vì
        đây là dụng thần chính. Cần phải tránh Kỷ Thổ ở Thiên Can. Điều này bởi
        vì Kỷ Thổ làm bẩn Nhâm Thủy và hợp với Giáp Mộc tạo thành Thổ.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tốt nhất nếu có Nhâm Thủy và Giáp Mộc ở Thiên Can. Trong tình huống
        này thì cần tránh Canh Kim lộ Thiên Can.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu chỉ có Nhâm Thủy nhưng không có Giáp Mộc – Canh Kim cũng nên xuất
        hiện.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có Đinh Hỏa và Kỷ Thổ xuất hiện và hợp với nhau trên Thiên Can thì
        ngày sinh này chỉ có cuộc đời trung bình, vất vả mưu sinh.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Địa Chi tạo thành Hỏa Cục trong khi thiếu Thủy thì ngày sinh này
        sẽ cô đơn và độc hành.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có Địa Chi hình thành Hỏa Cục và Mậu Thổ và Kỷ Thổ xuất hiện ở
        Thiên Can thì Thổ Khí sẽ thay đổi Hỏa. Trong tình huống này, thì ngày
        sinh sẽ tìm được sự ủng hộ từ người thân và bạn bè khi cần.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Kim và Thủy thiếu trong lá số, thì ngày sinh này sẽ khó thành công
        trong đời.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Thất Sát và Thiên Tài:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lý tưởng nếu có Nhâm Thủy và Canh Kim ở Thiên Can. Người này sẽ có tài
        lộc và quyền lực.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nên có ít nhất 1 Địa Chi Thân.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Mậu Thổ và Kỷ Thổ xuất hiện ở Thiên Can cùng với Đinh Hỏa thì lá số
        không tốt.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có cục Thực Thần Thương Quan:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Địa Chi tạo thành cục Thổ thì Nhâm Thủy và Giáp Mộc phù hợp là dụng
        thần, không dùng Canh Kim và Nhâm Thủy.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nhâm Thủy và Giáp Mộc ở Thiên Can thì lá số tốt. Nếu thiếu hành Thủy thì
        không tốt.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Thủy bị thiếu trong lá số thì Kim cũng không nên bị khắc thì lá số
        mới hình thành Tòng Vượng Cách.
      </p>
    </div>
  );
};

export const BinhThangMui = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Bính trong tháng Mùi
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngày sinh Bính trong tháng Mùi thì hình thành cục Thương Quan khi Kỷ Thổ
        xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Ất Mộc lộ ở Thiên Can thì hình thành cục Chính Ấn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Đinh Hỏa lộ ở Thiên Can thì hình thành cục Lâm Quan nếu thỏa các
        điều kiện.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu không có Ất Mộc, Đinh Hỏa hay Kỷ Thổ thì nên chọn ngũ hành nào mạnh
        nhất trong lá số Bát Tự.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với ngày sinh trong tháng Mùi thì Nhâm Thủy và Canh Kim là dụng thần
        điều hậu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Canh Kim được xem là hỷ thần của người này.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Mặc dù Mùi là kho chứa nhiều Mộc Khí, Địa Khí vẫn thống trị mạnh trong
        tháng này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Hỏa có xu hướng yếu hơn Thổ và Hỏa Khí sẽ thông với Thổ.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Thủy được dùng để cung cấp độ ẩm và giảm khô của Thổ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Mộc Khí sẽ được dùng để làm tơi đất.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Sẽ không tốt cho ngày sinh này nếu gặp Thổ Khí thêm. Điều này bởi vì Thổ
        sẽ làm bẩn Thủy và làm suy yếu Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Hỏa và Thổ xuất hiện nhiều thì Kim và Mộc cần để đem lại sự cân bằng
        cho ngày sinh này.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Canh Kim là dụng thần chính của ngày sinh này, với Nhâm Thủy là dụng
        thần phụ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Kỷ Thổ không nên xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tiêu chí để xác định Dụng Thần cũng tương tự như trong tháng Ngọ.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Trong mọi tình huống thì không nên thấy Canh Kim hay Nhâm Thủy trong
        lá số.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Ngày sinh này sẽ có thể có cuộc sống khá tốt, nếu không muốn nói là
        tốt hơn, khi cả Canh Kim và Nhâm Thủy xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Không tốt nếu có Kỷ Thổ xuất hiện ở Thiên Can. Điều này bởi vì Kỷ Thổ
        làm bẩn Nhâm Thủy dụng thần.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Nhâm Thủy bị thiếu, ngày sinh này sẽ thiếu thông minh và thông
        thái. Người này sẽ không thể đạt được sự ổn định mặc dù vất vả và quyết
        tâm.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Thiên Tài và Thất Sát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Cả Canh Kim và Nhâm Thủy đều xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Địa Chi hình thành Hỏa Cục, thì ngày sinh này sẽ vượng và có nhiều
        may mắn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Kỷ Thổ xuất hiện ở Thiên Can thì ngày sinh này sẽ có cuộc sống trung
        bình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu sinh trước tiết Đại Thử thì nên có 2 Nhâm Thủy và 1 Canh Kim là dụng
        thần.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu sinh sau tiết Đại Thử thì nên có cả Nhâm Thủy và Canh Kim mạnh như
        nhau như là dụng thần trong lá số này.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Chỉ có Canh Kim và Nhâm Thủy phù hợp làm Dụng Thần.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu không có Canh Kim và Nhâm Thủy thì bất cứ lá số nào cũng sẽ không
        phải tốt nhất.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi có Nhâm Thủy là Dụng Thần thì sẽ tốt nếu như trải qua đại vận Mộc
        hay Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu lá số bình thường thì ngày sinh này sẽ cảm thấy thuận lợi nếu bước
        vào đại vận Kim hay Thủy.
      </p>
    </div>
  );
};

export const BinhThangThan = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Bính trong tháng Thân
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Canh Kim xuất hiện ở Thiên Can thì tạo thành Thiên Tài Cục.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Mậu Thổ ở Thiên Can thì hình thành Thực Thần Cục.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Nhâm Thủy ở Can thì hình thành Thất Sát Cục.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu không có cả Canh Kim, Mậu Thổ, Nhâm Thủy thì chọn theo ngũ hành nào
        vượng nhất trong lá số.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nhâm Thủy và Mậu Thổ được xem là Dụng Thần Điều Hậu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Sẽ lý tưởng nếu có Nhâm Thủy thông căn ở Thân.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Cùng lúc, nếu Nhâm Thủy xuất hiện nhiều thì Mậu Thổ sẽ được dùng để kiểm
        soát.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Canh Kim vượng nhất trong tháng này. Kim khí và Thủy Khí cũng vượng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Kim khí xuất hiện nhiều, Mộc Ấn Tinh sẽ yếu. Ngoài ra, Hỏa và Thổ
        nếu yếu thì sẽ khó để kiểm soát Thủy.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đây là lý do vì sao Hỏa, Thô và Mộc cần như là Dụng Thần bởi vì kết hợp
        lại sẽ đem đến sự cân bằng cho ngày sinh, khiến lá số hữu tình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Mộc và Hỏa đặc biệt là dụng thần, đặc biệt khi Kim rất vượng, để bảo vệ
        đảm bảo Bính Hỏa. Thổ nóng sẽ cần xuất hiện để ngăn Thủy quá vượng.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nhâm Thủy là dụng thần.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Không tốt nếu có Kỷ Thổ ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nhâm Thủy không được xuất hiện ở Thiên Can. Nếu không thì Bính Hỏa khó
        đạt được tiềm năng tối đa của mình.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Mậu Thổ và Kỷ Thổ không xuất hiện ở Thiên Can thì ngày sinh này sẽ
        hưởng may mắn trong đời, nhất là khi Nhâm Thủy hiện diện.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Mậu Thổ và Kỷ Thổ xuất hiện ở Thiên Can, thì toàn bộ khí của ngày
        sinh này sẽ khá yếu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Sự hiện diện của Tỷ Kiếp sẽ tăng cường sự lấp lánh của Bính Hỏa. Bính
        Hỏa sẽ được ủng hộ và được tỏa sáng thêm, khi có Nhâm Thủy. Quy luật
        chung rất đơn giản là Bính Hỏa càng vượng trong lá số thì càng tốt cho
        ngày sinh này.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có cục Thất Sát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nhâm Thủy đóng vai trò là Thất Sát lý tưởng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Thân tuy có tàng Mậu Thổ nhưng vẫn dùng được chỉ khi không đi kèm
        với Giáp Mộc Thiên Ấn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu gặp Thìn và Ngọ thì không nên thấy Mậu Thổ lộ can, nếu không thì lá
        số xấu.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có cục Thiên Tài:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Canh Kim xuất hiện ở Thiên Can và thiếu Tỷ Kiếp và Ấn Tinh trong lá
        số thì sẽ có thể hình thành cục Tòng Tài.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tuy nhiên, Canh Kim không nên đi kèm với Tân Kim thì mới đạt được mục
        đích.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu thiếu Tỷ Kiếp và Ấn thì có thể thành công trong cục Tòng Tài.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu tạo thành cục Tòng Tài thì không nên gặp Tỷ Kiếp hay Ấn.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Sẽ tốt nếu cùng lúc xuất hiện Thất Sát và Ấn Tinh.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có cục Tòng Tài thì Canh Kim là quan trọng nhất, quyết định chất
        lượng của cục này. Tuy nhiên không nên đi kèm với Tân Kim.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nêu Canh Kim và Tân Kim đều ở Thiên Can, thì sẽ không thể hình thành
        cục Tòng Tài.
      </p>
    </div>
  );
};

export const BinhThangDau = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Bính trong tháng Dậu
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng Dậu thì hình thành cục Chính Tài nơi có
        Tân Kim xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngay cả khi Tân Kim không có ở Thiên Can vẫn có thể tạo thành cục Thiên
        Tài.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nhâm Thủy và Quý Thủy được xem là Dụng Thần Điều Hậu. Quý Thủy chỉ được
        dùng để thay thế Nhâm Thủy nếu như không có Nhâm.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Kim vượng nhất vào mùa Thu. Âm Kim ở Dậu sẽ xung với Mộc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Sức mạnh của Mộc Khí - Ấn Tinh – sẽ bị giới hạn. Thủy Khí rất mạnh trong
        tháng này. Nếu không có Mộc thì sẽ khó để Bính Hỏa tỏa sáng.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Do đó, Mộc và Hỏa sẽ là dụng thần phụ của ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Cần phải tránh gặp Tân Kim càng nhiều càng tốt, vì làm suy yếu Mộc vốn
        là Ấn Tinh của Hỏa Khí.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nhâm thủy là dụng thần chính.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Kỷ Thổ không nên xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Mộc là dụng thần quan trọng với sức sống của ngày sinh này.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Sự xuất hiện của Tỷ Kiếp sẽ giúp tăng thêm độ tỏa sáng của Bính Hỏa.
        Nhâm Thủy sẽ ủng hộ và giúp ánh sáng thêm lấp lánh. Bính Hỏa mạnh hay
        nhiều trong lá số sẽ tốt hơn cho người này.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Kỷ Thổ không xuất hiện ở Thiên Can thì ngày sinh này sẽ hưởng cuộc
        sống tốt. Điều này để tránh nhiễm bẩn cho Nhâm Thủy.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Dụng thần điều hậu của ngày sinh trong tháng Thân và tháng Dậu căn bản
        là giống nhau. Sự khác biệt duy nhất là Tỷ Kiên có thể hiện diện ở tháng
        Dậu.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Thất Sát Cục:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Cả Ấn Tinh và Tỷ Kiên nên đồng hiện diện cùng với Nhâm Thủy.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Không nên gặp Mậu Thổ hay Kỷ Thổ ở Thiên Can.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Chính Tài Cục:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tân Kim xuất hiện ở Thiên Can thì người này sẽ dễ bị nghèo, ngay cả sau
        khi kiếm được một khoản khá.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lý tưởng nếu Tân và Bính xa nhau và không nên gặp Đinh Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nhâm Thủy là dụng thần lý tưởng cho ngày sinh Bính trong tháng Dậu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Mậu Thổ, Kỷ Thổ và Tân Kim không nên lộ ở Thiên Can.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Quý Thủy lộ ở Thiên Can thì ngày sinh này vượng và giàu có dù tài
        lộc có thể không lâu bền.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Tân Kim lộ và Đinh Hỏa cũng xuất hiện ở Thiên Can thì ngày sinh
        này có thể sở hữu tính cách khó đoán hay bất ổn. Người này sẽ cô đơn và
        thích đắm chìm trong tình dục. Có thể giàu có khi còn trẻ và mất dần khi
        về già.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Tân Kim xuất hiện nhiều và cạnh tranh với nhau để hợp với Bính thì
        người này sẽ nghèo, bị cản trở khi về già.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi một Bính Hỏa và một Tân Kim hợp với nhau để tạo thành Thủy, khi
        Địa Chi tạo thành Kim cục, và không có Tỷ Kiên hay Kiếp Tài xuất hiện
        thì ngày sinh này sẽ được hưởng những gì tốt nhất trong cuộc sống. Trong
        điều kiện này thì Hóa Cục có thể hình thành.
      </p>
    </div>
  );
};

export const BinhThangTuat = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Bính trong tháng Tuất
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với ngày sinh Bính trong tháng Tuất thì hình thành Cục Thực Thần nếu
        Mậu Thổ xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngay cả khi Tân Kim không có ở Thiên Can vẫn có thể tạo thành cục Thiên
        Tài.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nhâm Thủy và Quý Thủy được xem là Dụng Thần Điều Hậu. Quý Thủy chỉ được
        dùng để thay thế Nhâm Thủy nếu như không có Nhâm.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Kim vượng nhất vào mùa Thu. Âm Kim ở Dậu sẽ xung với Mộc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Sức mạnh của Mộc Khí - Ấn Tinh – sẽ bị giới hạn. Thủy Khí rất mạnh trong
        tháng này. Nếu không có Mộc thì sẽ khó để Bính Hỏa tỏa sáng.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Do đó, Mộc và Hỏa sẽ là dụng thần phụ của ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Cần phải tránh gặp Tân Kim càng nhiều càng tốt, vì làm suy yếu Mộc vốn
        là Ấn Tinh của Hỏa Khí.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nhâm thủy là dụng thần chính.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Kỷ Thổ không nên xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Mộc là dụng thần quan trọng với sức sống của ngày sinh này.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Sự xuất hiện của Tỷ Kiếp sẽ giúp tăng thêm độ tỏa sáng của Bính Hỏa.
        Nhâm Thủy sẽ ủng hộ và giúp ánh sáng thêm lấp lánh. Bính Hỏa mạnh hay
        nhiều trong lá số sẽ tốt hơn cho người này.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Kỷ Thổ không xuất hiện ở Thiên Can thì ngày sinh này sẽ hưởng cuộc
        sống tốt. Điều này để tránh nhiễm bẩn cho Nhâm Thủy.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Dụng thần điều hậu của ngày sinh trong tháng Thân và tháng Dậu căn bản
        là giống nhau. Sự khác biệt duy nhất là Tỷ Kiên có thể hiện diện ở tháng
        Dậu.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Thất Sát Cục:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Cả Ấn Tinh và Tỷ Kiên nên đồng hiện diện cùng với Nhâm Thủy.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Không nên gặp Mậu Thổ hay Kỷ Thổ ở Thiên Can.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Chính Tài Cục:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tân Kim xuất hiện ở Thiên Can thì người này sẽ dễ bị nghèo, ngay cả sau
        khi kiếm được một khoản khá.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lý tưởng nếu Tân và Bính xa nhau và không nên gặp Đinh Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nhâm Thủy là dụng thần lý tưởng cho ngày sinh Bính trong tháng Dậu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Mậu Thổ, Kỷ Thổ và Tân Kim không nên lộ ở Thiên Can.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Quý Thủy lộ ở Thiên Can thì ngày sinh này vượng và giàu có dù tài
        lộc có thể không lâu bền.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Tân Kim lộ và Đinh Hỏa cũng xuất hiện ở Thiên Can thì ngày sinh
        này có thể sở hữu tính cách khó đoán hay bất ổn. Người này sẽ cô đơn và
        thích đắm chìm trong tình dục. Có thể giàu có khi còn trẻ và mất dần khi
        về già.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Tân Kim xuất hiện nhiều và cạnh tranh với nhau để hợp với Bính thì
        người này sẽ nghèo, bị cản trở khi về già.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi một Bính Hỏa và một Tân Kim hợp với nhau để tạo thành Thủy, khi
        Địa Chi tạo thành Kim cục, và không có Tỷ Kiên hay Kiếp Tài xuất hiện
        thì ngày sinh này sẽ được hưởng những gì tốt nhất trong cuộc sống. Trong
        điều kiện này thì Hóa Cục có thể hình thành.
      </p>
    </div>
  );
};

export const BinhThangHoi = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Bính trong tháng Hợi
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với ngày sinh Bính trong tháng Hợi thì hình thành cục Thất Sát khi
        Nhâm Thủy xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Giáp Mộc xuất hiện ở một trong những Thiên Can thì hình thành cục
        Thiên Ấn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu không có Nhâm Thủy hay Giáp Mộc xuất hiện ở Thiên Can thì nên chọn
        cục theo ngũ hành vượng nhất trong lá số.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với tháng sinh Hợi thì Giáp Mộc, Mậu Thổ, Canh Kim và Nhâm Thủy là
        dụng thần điều hậu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Thủy mạnh nhất trong tháng này. Do đó Giáp Mộc sẽ được dùng để kiểm soát
        trong lúc lại hỗ trợ cho Bính Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Cùng mạch đó thì Mậu Thổ có thể được dùng để giữ những hành này trong
        tầm kiểm soát.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Giáp Mộc được dùng để kiểm soát Thủy, trong khi Canh Kim được dùng để
        kiểm soát Mộc và sinh ra Hỏa.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Thủy vượng ở tháng Hợi. Mộc Khí xếp thứ hai.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Căn cứ theo Khí và thời gian của tháng thì mặt trời đại diện cho Bính
        Hỏa dần dân bị mất đi sự tỏa sáng, khi ngày càng tối hơn. Và mặc dù Hợi
        chứa Giáp Mộc ở tàng can, vẫn không thể để cho Mộc ướt sinh ra Hỏa.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Mộc và Hỏa là dụng thần quan trọng đối với ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Kim chỉ được dùng khi Mộc và Hỏa rất mạnh trong lá số. Sẽ có lợi cho
        ngày sinh này nếu Tài và Quan cũng có sức mạnh đủ.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Giáp Mộc là dụng thần số 1 cho ngày sinh này, trong khi Nhâm Thủy là
        dụng thần số hai.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Không nên có Kỷ Thổ xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu can ngày này gặp Tân Kim Chính Tài thì hóa thành Thủy có thể diễn
        ra. Khi điều kiện xung quanh ủng hộ hóa hợp này thì lá số này sẽ là của
        một người phi thường.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Giáp Mộc xuất hiện nhiều trong lá số và lộ ở Thiên Can thì Canh
        Kim cần xuất hiện trong lá số, để có thể giúp cho ngày sinh này được
        thịnh vượng và trở nên giàu có trong đời.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Cục Chính Tài:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Tân Kim Chính Tài kết hợp với Bính Hỏa và chuyển thành Thủy thì ít
        nhất cần có 1 Thìn ở Địa Chi trong lá số.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tốt nếu những hoàn cảnh xung quanh hỗ trợ cho việc chuyển hóa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Bất lợi nếu có Giáp Mộc nhưng thiếu Mậu Thổ.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Cục Chính Quan Thất Sát:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Cục Tòng Quan có thể hình thành khi thiếu hoàn toàn Kỷ Thổ trong lá số.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lý tưởng nếu cục Tòng Tài hình thành.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Xấu nếu xuất hiện Kỷ Thổ và Hỏa Khí.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Giáp Mộc nhiều thì Cần có Canh Kim để kiểm soát Giáp Mộc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu không có Tài Tinh trong lá số thì ngày sinh này sẽ sống cuộc đời
        trung bình
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu sử dụng Nhâm Thủy thì cần có Mậu Thổ Thực Thần, để kiểm soát Nhâm
        Thủy.
      </p>
    </div>
  );
};
