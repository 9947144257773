import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Collapse,
  CardBody,
  Card,
} from "reactstrap";
import * as XLSX from "xlsx";
import { IoArrowDownCircleOutline } from "react-icons/io5";

// import Modal from "../components/Modal";
import { tinhCach } from "../components/convertTinhCach";
import { GiapTy } from "../components/60hoagiap/Giap";
import logo from "../assets/logo.png";
import "../scss/thansat.scss";

function PageTinhCach() {
  const [show, setShow] = useState(true);
  const [open, setOpen] = useState(false);
  const [alerError, setAlerError] = useState(false);

  const [title, setTitle] = useState("Giáp Mộc");
  const [text, setText] = useState(GiapTy);

  const noLogin = true;

  const handleClick = (e) => {
    let content = tinhCach(e.currentTarget.textContent);
    setText(content);
    // setTitle(`Ngày Sinh ${e.currentTarget.textContent}`);
  };

  const setAlerErrors = () => {
    setAlerError(true);
  };

  useEffect(() => {
    let content = tinhCach("Giáp Mộc");
    setText(content);
  }, []);

  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      {noLogin ? (
        <>
          <div className="than_sat_title" style={{ marginTop: "2rem" }}>
            <h3>TÍNH CÁCH 10 NGÀY SINH</h3>
          </div>
          <div
            style={{
              width: "1440px",
              margin: "0 auto",
              display: "flex",
              minHeight: "512px",
            }}
          >
            <Card style={{ width: "15%" }}>
              <CardBody>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span className="chiday" onClick={handleClick}>
                    Giáp Mộc
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span className="chiday" onClick={handleClick}>
                    Ất Mộc
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span className="chiday" onClick={handleClick}>
                    Bính Hỏa
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span className="chiday" onClick={handleClick}>
                    Đinh Hỏa
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span className="chiday" onClick={handleClick}>
                    Mậu Thổ
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span className="chiday" onClick={handleClick}>
                    Kỷ Thổ
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span className="chiday" onClick={handleClick}>
                    Canh Kim
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span className="chiday" onClick={handleClick}>
                    Tân Kim
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span className="chiday" onClick={handleClick}>
                    Nhâm Thủy
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span className="chiday" onClick={handleClick}>
                    Quý Thủy
                  </span>
                </div>
              </CardBody>
            </Card>
            <div style={{ width: "90%", position: "relative" }}>
              <div
                className={
                  title
                    ? "watermark-hoagiap-page t-16"
                    : "watermark-hoagiap-page"
                }
              >
                <img src={logo} />
              </div>

              <span
                style={{
                  overflowY: "scroll",
                  maxHeight: 600,
                  display: "block",
                }}
                onCopy={(e) => e.preventDefault()}
                onCut={(e) => e.preventDefault()}
                onPaste={(e) => e.preventDefault()}
              >
                {text}
              </span>
            </div>
          </div>
        </>
      ) : (
        <div
          className="hide-to-print"
          style={{
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold",
            minHeight: "500px",
            paddingTop: "125px",
          }}
        ></div>
      )}
      <Modal close={(value) => setOpen(value)} open={open} />
    </div>
  );
}

export default PageTinhCach;
