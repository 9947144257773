export const KyThangTi = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Kỷ trong tháng Tý
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng này thì nếu có Quý Thủy hiện lên ở Thiên
        Can thì xem là hình thành cục Thiên Tài.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng này thì dụng thần điều hậu là Bính Hỏa,
        Giáp Mộc và Mậu Thổ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngày sinh này cần có Bính Hỏa sưởi ấm vì gặp lạnh giá mùa đông.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Thủy khí nhiều trong tháng này. Đó là lý do vì sao Mậu Thổ cần có để
        kiểm soát được Thủy Khí.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Thổ xuất hiện nhiều thì Giáp Mộc cần để làm tơi xốp nó.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khí sẽ rất lạnh trong tháng này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Do đó, cần có Hỏa để sưởi ấm ngày sinh này trong khi Thổ nóng ấm sẽ tốt
        để kiểm soát Thủy mạnh. Chỉ khi đó thì ngày sinh Kỷ Thổ mới có sức sống.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Chỉ khi Hỏa xuất hiện ở Thiên Can thì phải sử dụng Mộc như là dụng thần
        cho ngày sinh này. Mộc ấm áp và khô giúp đảm bảo sinh ra Hỏa khí.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Kim lạnh lẽo sẽ sinh ra Thủy lạnh băng giá. Điều này sẽ dẫn đến bùn đất
        ướt khi Thủy gặp Thổ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Do đó ngày sinh này không nên gặp thêm Kim và Thủy Khí.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Bính Hỏa và Giáp Mộc là dụng thần chính của ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Không có lợi nếu có Nhâm và Quý Thủy ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Nhâm Thủy xuất hiện ở Thiên Can hoặc Địa Chi và hoàn toàn vắng
        bóng Bính Hỏa thì ngày sinh này sẽ thiếu mục tiêu và định hướng cuộc
        sống.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Quý Thủy xuất hiện ở Thiên Can hoặc Địa Chi trong khi thiếu Tỷ
        Kiếp và Ấn Tinh thì hình thành cục Tòng Tài. Nếu cục này thành công thì
        người này sẽ có lá số phi thường.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Mậu Thổ và Kỷ Thổ xuất hiện ở Thiên Can cùng với Giáp Mộc thì ngày
        sinh này sẽ sở hữu tri thức và kỹ năng cần thiết để thành công. Tuy
        nhiên, không được thiếu Bính Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Canh Kim ở Thiên Can thì Bính Hỏa và Đinh Hỏa phải mạnh.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Lá số này dù theo cục gì cũng cần có Bính Hỏa hiện diện trong lá số.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Chính Ấn và Chính Quan:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Bính Hỏa và Giáp Mộc cùng hiện diện, ngày sinh Kỷ Thổ sẽ được thừa
        nhận về danh tiếng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người này sẽ thành công trong sự nghiệp.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Nhâm Thủy thì cần Mậu Thổ để kiểm soát.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số xấu nếu Nhâm Thủy lộ Thiên Can.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Thiên Tài:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Địa Chi tạo thành Thủy Cục cùng với vắng mặt Tỷ Kiếp và Ấn Tinh thì
        hình thành cục Tòng Tài.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số tốt nếu không có Tỷ Kiếp và Ấn Tinh.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số xấu nếu có Tỷ Kiếp và Ấn Tinh ở Thiên Can, lúc này ngày sinh Kỷ
        Thổ chỉ có cuộc sống trung bình.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Tỷ Kiếp:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có đầy đủ Thìn Tuất Sửu Mùi trong lá số thì cần có Giáp Mộc ở Thiên
        Can. Điều này sẽ đảm bảo thịnh vượng dài lâu và may mắn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số tốt nếu có Giáp Mộc ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số xấu nếu không có Giáp Mộc ở Thiên Can.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Thương Quan:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Đinh Hỏa xuất hiện ở Thiên Can thì người này sẽ có nhiều may mắn.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Bính Hỏa quan trọng đối với sự tồn tại của Kỷ Thổ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Ngày sinh Kỷ Thổ nên dùng Đinh Hỏa để khắc chế Canh Kim.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu hình thành cục Tòng Tài thì sẽ phụ thuộc phần lớn vào sức mạnh của
        sao Thiên Tài. Nếu thành cục thì người này sẽ có cuộc sống phi thường
        nhiều mạo hiểm và tài lộc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Quý Thủy ở Thiên Can thì Mậu Thổ cần để kiểm soát Thủy.
      </p>
    </div>
  );
};

export const KyThangSuu = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Kỷ trong tháng Sửu
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng này thì nếu có Tân Kim hiện lên ở Thiên
        Can thì xem là hình thành cục Thực Thần.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Quý Thủy hiện lên ở Thiên Can thì hình thành cục Thiên Tài.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Kỷ Thổ hiện lên ở Thiên Can thì hình thành Vượng Cục.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng
        có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy ý.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng này thì dụng thần điều hậu là Bính Hỏa,
        Giáp Mộc và Mậu Thổ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngày sinh này cần có Bính Hỏa sưởi ấm vì gặp lạnh giá mùa đông.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Thủy khí nhiều trong tháng này. Đó là lý do vì sao Mậu Thổ cần có để
        kiểm soát được Thủy Khí.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Thổ xuất hiện nhiều thì Giáp Mộc cần để làm tơi xốp nó.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngày sinh Kỷ Thổ trong tháng Sửu là thổ ướt hay băng giá.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Vì vẫn là mùa đông, trời vẫn rét buốt lạnh giá, tuyết phủ đầy trên đất.
        Do đó mặc dù Sửu chứa nhiều Thổ Khí nhưng vẫn không thể sinh ra hay hỗ
        trợ cho Kỷ Thổ.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Mặc dù Hỏa cần để cung cấp hơi ấm cho ngày sinh Kỷ Thổ, chức năng của
        Hỏa Dụng Thần phụ thuộc phần lớn vào tính hữu dụng của Mộc để sản sinh.
        Do đó, Mộc và Hỏa là dụng thần thông quan của ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Không tốt nếu lá số gặp thêm hành Kim và Thủy. Những yếu tố này có khả
        năng làm suy yếu Hỏa, cũng như Mộc và làm cho thời tiết càng thêm băng
        giá.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Bính Hỏa và Giáp Mộc là dụng thần chính.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Không tốt nếu có Nhâm Thủy và Quý Thủy ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Nhâm Thủy ở Thiên Can hay Địa Chi trong khi thiếu Bính Hỏa thì
        ngày sinh Kỷ Thổ sẽ thiếu định hướng và mục tiêu sống.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Quý Thủy ở Thiên Can hay Địa chi trong khi vắng hoàn toàn Tỷ Kiếp
        và Ấn Tinh thì hình thành cục Tòng Tài. Người này sẽ có cuộc đời phi
        thường nhiều mạo hiểm và thành công.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Mậu Thổ và Kỷ Thổ xuất hiện ở Thiên Can cùng với Giáp Mộc thì
        người này sẽ hưởng danh tiếng và được thừa nhận. Thành công sẽ đến thông
        qua mối quan hệ. Tuy nhiên, Bính Hỏa không được thiếu trong lá số.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Canh Kim xuất hiện ở Thiên Can thì Bính Hỏa và Đinh Hỏa phải mạnh
        để ngày sinh này có thể hưởng thịnh vượng, hạnh phúc và danh tiếng dài
        lâu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Bất kể thuộc cục gì thì ngày sinh này đều cần có Bính Hỏa trong lá số.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Chính Ấn và Chính Quan:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Bính Hỏa và Giáp Mộc cùng hiện diện thì ngày sinh này sẽ nổi
        tiếng và thành công trong sự nghiệp.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Nhâm Thủy xuất hiện thì cần có Mậu Thổ để kiểm soát.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số xấu nếu Nhâm Thủy xuất hiện ở Thiên Can.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Thiên Tài:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu hình thành Thủy Cục thì cần có sao Tỷ Kiếp và Ấn Tinh.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu hoàn toàn vắng Tỷ Kiếp và Ấn Tinh thì lá số này sẽ liên tục gặp trở
        ngại cả đời.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Tỷ Kiếp:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có đủ Thìn Tuất Sửu Mùi trong lá số thì cần có Giáp Mộc ở Thiên Can
        mới tốt.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu không có Giáp Mộc ở Thiên Can thì là lá số xấu.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Thương Quan:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Đinh Hỏa ở Thiên Can thì ngày sinh này sẽ hưởng thành công và
        thịnh vượng suốt đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số tốt nếu Đinh Hỏa thông căn và mạnh.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số xấu nếu thiếu Đinh Hỏa.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Bính Hỏa là dụng thần quan trọng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Ngày sinh Kỷ Thổ trong tháng mùa đông nên dùng Đinh Hỏa để kiểm soát
        Canh Kim.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi hình thành Thủy Cục thì cần có Tỷ Kiếp để giữ sự ổn định về danh
        tiếng của người này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi có Kim Cục thì Hỏa Khí là dụng thần chính. Thành công sẽ đạt được
        thông qua hi sinh và khổ cực. Nhưng thành công lớn sẽ dần dần đạt được.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi xuất hiện nhiều Thủy thì cần có Mậu Thổ để kiểm soát. Kỷ Thổ không
        thay thế được.
      </p>
    </div>
  );
};

export const KyThangDan = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Kỷ trong tháng Dần
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng này thì nếu có Giáp Mộc hiện lên ở Thiên
        Can thì xem là hình thành cục Chính Quan.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Bính Hỏa hiện lên ở Thiên Can thì hình thành cục Chính Ấn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Mậu Thổ hiện lên ở Thiên Can thì hình thành cục Dương Nhẫn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng
        có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy ý.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng này thì dụng thần điều hậu là Bính Hỏa,
        Canh Kim và Giáp Mộc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Bính Hỏa cung cấp ấm áp cho ngày sinh Kỷ Thổ vốn lạnh giá trong tháng
        này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Không tốt nếu gặp Nhâm Thủy trong lá số.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Thủy xuất hiện nhiều thì Mậu Thổ có thể dùng như là dụng thần để
        kiểm soát Thủy. Tương tự, khi Thổ xuất hiện nhiều thì Giáp Mộc có thể
        được dùng để kiểm soát Thổ. Tương tự, khi có Giáp Mộc xuất hiện nhiều,
        Canh Kim có thể được dùng để kiểm soát Giáp Mộc.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi ngày sinh Kỷ Thổ sinh trong tháng Dần thì Giáp Mộc, Bính Hỏa và Mậu
        Thổ đóng vai trò là dụng thần, từ việc đem đến hơi ấm đến việc cân bằng
        và hỗ trợ hành Thổ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Hỏa Khí cần thiết để đem đến hơi ấm và thổi sự sống vào hoàn cảnh lạnh
        giá này.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Hỏa và Thổ xuất hiện mạnh mẽ trong lá số, Kim và Thủy đóng vai trò
        dụng thần và giúp đem lại sự cân bằng trong lá số Bát Tự, cũng như đảm
        bảo lá số cân bằng và hữu tình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Bính Hỏa là dụng thần chính còn Giáp Mộc và Quý Thủy là dụng thần phụ.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Bính Hỏa là dụng thần chính với Giáp Mộc và Quý Thủy đóng vai trò là
        dụng thần thứ hai đối với ngày sinh Kỷ sinh trong tháng Dần.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Không tốt nếu có Nhâm Thủy hiện lên ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Giáp Mộc là cần thiết để làm tơi Kỷ Thổ sau mùa đông lạnh giá. Kỷ Thổ
        cần phải tránh gặp Nhâm Thủy, nhưng không cần tránh Quý Thủy. Nhâm Thủy
        đại diện cho lũ lụt.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Bất kể Giáp Mộc có mặt ở Thiên Can hay tàng can ở Địa Chi thì phải có
        mặt Canh Kim, tiếp theo là Bính Hỏa và Quý Thủy. Khi có đủ 3 dụng thần
        thì ngày sinh Kỷ Thổ sẽ hưởng cuộc sống thịnh vượng và hạnh phúc.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu xuất hiện nhiều Giáp Mộc nhưng thiếu Canh Kim thì ngày sinh Kỷ Thổ
        sẽ có xu hướng sở hữu tính cách lười.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Địa chi hình thành tạo nên Hỏa Cục, kèm với thiếu Nhâm Thủy thì
        ngày sinh Kỷ Thổ sẽ hưởng thịnh vượng (bởi vì Kỷ Thổ đã được xem là Thổ
        ẩm ướt, không cần phải lộ ra Nhâm Thủy).
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Ngay cả dù cho Ất Mộc có lộ Thiên Can hay không thì Ất Mộc đơn giản
        không thể làm tơi lỏng hay đem lại sự cân bằng cho Kỷ Thổ. Trong tình
        huống này thì ngày sinh Kỷ Thổ có thể không thực hiện hành động song
        song với lời nói hay lời hứa hẹn.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Chính Quan:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Địa Chi tạo thành Mộc Thất Sát Cục thì cần có Canh Kim để xuất hiện
        ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Canh Kim Thực Thương sẽ xuyên thấu lên Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tân Kim Thực Thần sẽ không thể dùng được.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Chính Ấn:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Địa chi tạo thành Hỏa Ấn Cục thì ngay cả dù Thủy vắng mặt trong lá
        số thì ngày sinh Kỷ Thổ vẫn khá ổn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số tốt nếu có mặt Quý Thủy. Không tốt nếu Mậu Thổ có mặt ở Thiên Can.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số Thất Sát Ất Mộc:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi hình thành Thất Sát Cục thì ngày sinh Kỷ Thổ sẽ sở hữu tính cách mềm
        mỏng nhưng thông minh.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số tốt nếu có Quý Thủy. Xấu nếu thiếu Canh Kim đứng cạnh Ất Mộc.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu thiếu đi hành Thủy Tài Tinh thì Ngày sinh Kỷ này vẫn sẽ tốt. Điều
        này bởi vì Kỷ Thổ được xem là Thổ ướt.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Ất Mộc Thất Sát không thể làm tơi Thổ hiệu quả trong tháng này.
      </p>
    </div>
  );
};

export const KyThangMao = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Kỷ trong tháng Mão
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng này thì nếu có Ất Mộc hiện lên ở Thiên
        Can thì xem là hình thành cục Thất Sát.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngay cả nếu không có Ất Mộc hiện ở Thiên Can thì vẫn có thể hình thành
        cục Thất Sát.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng này thì dụng thần điều hậu là Giáp Mộc,
        Bính Hỏa và Quý Thủy.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Giáp Mộc là dụng thần số một đối với ngày sinh Kỷ Thổ trong tháng Mão.
        Kỷ Thổ nên bị ngăn cản không cho hợp với Giáp Mộc chuyển thành Thổ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngoài ra, Quý Thủy được xem là Dụng Thần số hai để làm ẩm Kỷ Thổ.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Vì tháng Mão biểu thị giữ mùa xuân nên Mộc khí vượng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Do đó, Mộc khí sẽ dùng để kiểm soát Kỷ Thổ. Kỷ Thổ sẽ thiếu sức mạnh cần
        thiết để hợp với Giáp Mộc.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Vì Mộc Khí đang ở đỉnh cao do đó cần có Hỏa để làm suy yếu Mộc để không
        biến thành Sát khí đối với ngày sinh Kỷ Thổ này. Ngoài ra, Hỏa cần đóng
        vai trò là sao Ấn Tinh tốt để sinh ra và hỗ trợ cho ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Hỏa Khí ưu tiên hơn Kim trong vai trò dụng thần để đem đến sự cân bằng
        cho ngày sinh Kỷ Thổ. Điều này bởi vì Kim làm suy yếu Thổ trong khi Hỏa
        lại tăng cường.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Hỏa và Thổ đặc biệt mạnh thì Thủy nên được chọn là dụng thần. Điều
        này rõ ràng vì Thủy sẽ điều hòa và kiểm soát Hỏa, và do đó ngăn cản toàn
        bộ lá số không trở nên quá nóng bức.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Giáp Mộc và Bính Hỏa là dụng thần lý tưởng đối với ngày sinh Kỷ Thổ
        sinh trong tháng Mão cùng với Quý Thủy đóng vai trò là dụng thần số hai.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Kỷ Thổ và Giáp Mộc cần phải ngăn cản không cho hợp với nhau.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi đủ Mộc Cục xuất hiện ở Địa Chi thì Canh Kim cần thiết đối với sự
        sinh tồn của ngày sinh này. (nếu thiếu Canh Kim thì có thể dùng Đinh Hỏa
        thay thế. Đó là bởi vì Đinh Hỏa làm suy yếu Giáp Mộc vốn cũng là dụng
        thần cho ngày sinh Kỷ Thổ. Tuy nhiên, điều này cũng biểu thị người này
        có thể có động lực và mục tiêu hơi ám muội).
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Giáp Mộc, Quý Thủy và Bính Hỏa đều vắng mặt ở Thiên Can thì ngày
        sinh Kỷ Thổ này sẽ chỉ sống cuộc đời trung bình.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Nhâm Thủy và Kỷ Thổ đều xuất hiện ở Thiên Can thì ngày sinh Kỷ Thổ
        sẽ có sự nghiệp không quá hứa hẹn.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Chính Quan và Thiên Tài:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Với Giáp Mộc Chính Quan hiện diện, thì ngày sinh Kỷ Thổ sẽ thành công
        trong sự nghiệp.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đây là kịch bản lý tưởng nhất cho ngày sinh Kỷ Thổ nếu có Giáp Mộc hiện
        lên ở Can Năm.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lý tưởng nếu có Bính Hỏa ở lá số.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số xấu nếu có Giáp Mộc và Kỷ Thổ phát hiện ở bên cạnh nhau trong lá
        số, trong khi Canh Kim cũng hiện diện ở Thiên Can và có Giáp Mộc kiểm
        soát.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Chính Quan Thất Sát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Địa Chi hình thành cục Mộc thì cần có Canh Kim ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu không có Canh Kim ở Thiên Can thì cần có Đinh Hỏa làm suy yếu Giáp
        Mộc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số xấu nếu có Ất Mộc vốn dĩ là Thất Sát ở Thiên Can và hợp với Canh
        Kim Thương Quan.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu thiếu đi Bính hỏa thì Ngày sinh Kỷ này sẽ không thỏa mãn trong
        cuộc sống.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Giáp Mộc và Quý Thủy là dụng thần và không nên bị tù trong lá số.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi có Canh Kim, Nhâm Thủy và Mậu Thổ xuất hiện nhiều trong lá số thì
        ngày sinh Kỷ Thổ sẽ có cuộc sống trung bình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi có Địa Chi tạo thành Mộc cục mà không có Ấn Tinh hoặc Tỷ Kiếp thì
        có thể hình thành cục Tòng Quan.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi không có Bính Hỏa, Giáp Mộc và Quý Thủy hoàn toàn trong lá số thì
        lá số kém.
      </p>
    </div>
  );
};

export const KyThangThin = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Kỷ trong tháng Thìn
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng này thì nếu có Ất Mộc hiện lên ở Thiên
        Can thì xem là hình thành cục Thất Sát.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Quý Thủy hiện lên ở Thiên Can thì hình thành cục Thiên Tài.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Mậu Thổ hiện lên ở Thiên Can thì hình thành cục Dương Nhẫn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng
        có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy ý.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng này thì dụng thần điều hậu là Bính Hỏa,
        Quý Thủy và Giáp Mộc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Bính Hỏa là dụng thần số một trong khi Quý Thủy là dụng thần số hai.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Vì Thổ ấm áp và ẩm ướt trong tháng Thìn nên Giáp Mộc cần dùng để làm tơi
        xốp và đảm bảo Thổ khí được cân bằng.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Địa chi Thìn chứa tàng can Mậu Thổ, Ất Mộc và Quý Thủy. Thổ khí sẽ khá
        vượng trong tháng Thìn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Thủy là cân thiết để giữ sức sống của hành Thổ trong tháng này. Khi Kỷ
        Thổ gặp một ít Thủy trong lá số thì người này sẽ có thịnh vượng trong
        đời. Khi cả Thủy và Mộc khí đều ở đúng chỗ thì người này sẽ có danh
        tiếng, địa vị và quyền hành.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Hỏa và Thổ mạnh trong lá số thì Kim và Thủy được xem là dụng thần.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Kim và Thủy khí mạnh thì Hỏa và Thổ có thể được dùng như là dụng
        thần thông quan, để điều chỉnh toàn bộ khí của lá số Bát Tự này.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Bính Hỏa là dụng thần số một cho ngày Kỷ Thổ sinh trong tháng Thìn
        trong khi Quý Thủy đóng vai trò dụng thần thứ hai, và Giáp Mộc đóng vai
        trò dụng thần thứ ba.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Giáp Mộc cần để làm tơi Thổ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Kỷ Thổ có khả năng chứa Thủy, bởi vì bản chất Âm.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi có Bính Hỏa, Giáp Mộc và Quý Thủy ở Thiên Can, lá số Kỷ Thổ này sẽ
        hưởng chất lượng cuộc sống tốt.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Ất Mộc xuất hiện ở Thiên Can và Địa Chi nhưng thiếu Canh Kim thì
        Thổ Khí và Mộc Khí có thể mâu thuẫn với nhau. Trong tình huống đó thì
        ngày sinh Kỷ Thổ sẽ nghèo khó cũng như có sức khỏe xấu trong cả đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi xuất hiện Bính Hỏa nhưng không xuất hiện Quý Thủy thì ngày sinh Kỷ
        Thổ có thể có tài lộc ít trong đời và không bao giờ quá giàu có.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Quý Thủy lộ nhưng không có Bính Hỏa và Giáp Mộc thì ngày sinh Kỷ
        Thổ chỉ sống trung bình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Bính Hỏa và Quý Thủy đều lộ nhưng không có Giáp Mộc thì ngày sinh
        Kỷ Thổ sẽ sở hữu kỹ năng và kiến thức đủ để thành công trong đời.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Chính Ấn, Thiên Tài và Chính Quan:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Bính Hỏa, Quý Thủy và Giáp Mộc cùng xuất hiện thì ngày sinh Kỷ Thổ
        sẽ thịnh vượng trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi chỉ có 1 trong 3 xuất hiện thì ngày sinh Kỷ Thổ có thể thiếu mục
        tiêu hoặc khả năng kiểm soát trong đời mặc dù có thể giàu có trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số tốt nếu có cả ba – Bính Hỏa, Quý Thủy và Giáp Mộc xuất hiện.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số xấu nếu có Bính Hỏa nhưng Nhâm Thủy đứng bên cạnh hoặc có Quý Thủy
        nhưng Kỷ Thổ bên cạnh, có Giáp Mộc nhưng Canh Kim bên cạnh.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Thất Sát Ất Mộc:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi có Địa Chi tạo thành Mộc Cục Thất Sát mà không có Kim để kiểm soát
        Mộc thì ngày sinh Kỷ Thổ có tuổi thọ tốt.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số tốt nếu Kim xuất hiện ở Thiên Can. Xấu nếu Kim không có mặt trong
        lá số.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Bính Hỏa, Quý Thủy và Giáp Mộc nên được sử dụng làm dụng thần.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi có Bính Hỏa Ấn Tinh xuất hiện nhưng không có Quý Thủy thì ngày
        sinh này vẫn sẽ thịnh vượng và giàu có.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi có Quý Thủy Tài Tinh nhưng thiếu Giáp Mộc và Bính Hỏa thì ngày
        sinh này sẽ chỉ trung bình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi có Bính Hỏa và Quý Thủy cùng xuất hiện mặc dù thiếu Giáp Mộc nhưng
        ngày sinh Kỷ thổ vẫn có khả năng nắm giữa kỹ năng và kiến thức cần thiết
        để thành công trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Địa chi tạo thành Mộc Thất Sát Cục thì cần có Canh Kim Thương Quan
        ở Thien Can và giữ Mộc trong vòng kiểm soát để có thể tạo sự cân bằng
        cho lá số ngày sinh Kỷ Thổ.
      </p>
    </div>
  );
};

export const KyThangTy = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Kỷ trong tháng Tỵ
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng này thì nếu có Bính Hỏa hiện lên ở Thiên
        Can thì xem là hình thành cục Chính Ấn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Canh Kim hiện lên ở Thiên Can thì hình thành cục Thương Quan.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Mậu Thổ hiện lên ở Thiên Can thì hình thành cục Dương Nhẫn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng
        có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy ý.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng này thì dụng thần điều hậu là Quý Thủy và
        Bính Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu không có Quý Thủy thì sẽ không thể điều hậu và điều chỉnh Khí trong
        tổng thể lá số. Và ngay cả với sự hiện diện của Quý Thủy thì Thổ ẩm ướt
        không thể thiếu Bính Hỏa.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Hỏa Khí rất mạnh trong tháng mùa hè. Bởi vì cả Hỏa và Thổ đều mạnh trong
        tháng Tỵ, Kim và Thủy sẽ có xu hướng bị khô hạn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Do đó, Thủy cần trước tiên để làm ẩm ngày sinh Kỷ Thổ này. Do đó, tàng
        can Canh Kim trong Tỵ Địa Chi sẽ được dùng như là Dụng Thần để có thể
        sinh ra đảm bảo sự liên tục của Thủy. Nếu đạt được điều này thì lá số sẽ
        cân bằng và có cuộc đời bình an.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Thủy nhưng thiếu Kim thì ngày sinh Kỷ Thổ sẽ thiếu cân bằng và
        sức mạnh. Trong hoàn cảnh đó thì ngày sinh này sẽ vẫn thịnh vượng và
        giàu có mặc dù tài lộc có thể không kéo dài được lâu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số này không nên gặp Hỏa Khí vì nó làm suy yếu Kim, vốn cần để có thể
        sinh ra Thủy như là dụng thần của ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Kim và Thủy khí mạnh do lá số này, Bính Hỏa sẽ là dụng thần. Nếu Kim
        và Thủy vượng thì ngày sinh này sẽ có cuộc đời cao quý, quyền lực và tài
        lộc lớn.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Quý Thủy là dụng thần lý tưởng cho ngày sinh Kỷ Thổ này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Địa Chi tạo thành Hỏa Cục và Thủy thiếu trong lá số này thì ngày
        sinh Kỷ Thổ sẽ cô đơn và không hạnh phúc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Sẽ tốt nhất cho ngày sinh Kỷ Thổ nếu có cả Tân Kim và Quý Thủy.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Không nên gặp thêm Thổ Khí và Hỏa Khí.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi có Tỵ Ngọ Mùi tạo thành Hỏa Khí thì lá số cần có Canh Kim và Tân
        Kim để sinh ra và đảm bảo hành Thủy liên tục, ngay cả nếu Thủy đã có mặt
        trong lá số Bát Tự này.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Thiên Tài và Chính Ấn:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Bính Hỏa và Quý Thủy cùng xuất hiện, ngày sinh Kỷ Thổ sẽ thành công
        trong sự nghiệp.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngày sinh Kỷ Thổ sẽ càng thành công hơn nếu có Tân Kim xuất hiện.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số tốt nếu thiếu Mậu Thổ ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số không tốt nếu Mậu Thổ xuất hiện ở Thiên Can và khắc chế làm suy
        yếu Quý Thủy cũng như là Bính Hỏa.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Chính Ấn và Thiên Ấn:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Địa Chi tạo thành Hỏa Cục, Nhâm Thủy và Quý Thủy sẽ cần như là Dụng
        Thần.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số tốt nếu như có Thủy và thông căn ở Địa chi. Lá số xấu nếu như
        thiếu Thủy.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Chính Quan và Thất Sát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Địa Chi tạo thành Hỏa Cục và Mộc Cục, cả Canh Kim và Nhâm Thủy sẽ
        phải xuất hiện.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu như có Nhâm Thủy và Canh Kim xuất hiện thì lá số tốt.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số xấu nếu như thiếu hành Thủy, ngày sinh Kỷ Thổ sẽ bị ảnh hưởng bởi
        sự cô đơn cả đời.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Quý Thủy Thiên Tài là dụng thần số một cho ngày sinh Kỷ Thổ trong
        tháng mùa hè.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Địa Chi tạo thành Hỏa Cục thì Nhâm Thủy nên có mặt.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu thiếu Quý Thủy thì Nhâm Thủy có thể dùng thay. Tương tự, nếu thiếu
        Tân Kim thì có thể dùng Canh Kim thay.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Mậu Thổ không nên xuất hiện ở Thiên Can. Nếu không dễ hình thành cục
        Dương Nhẫn. Với Dương Nhẫn thì cần có Thất Sát để hoàn thiện cách cục
        này.
      </p>
    </div>
  );
};

export const KyThangNgo = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Kỷ trong tháng Ngọ
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với ngày sinh Kỷ Thổ trong tháng Ngọ thì Địa Chi Ngọ là vị trí Đế
        Vượng của Kỷ Thổ nên hình thành Vượng Cục.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Đinh Hỏa xuất hiện ở Thiên Can thì hình thành cục Thiên Ấn tùy
        theo lá số.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng này thì dụng thần điều hậu là Quý Thủy.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu không có Quý Thủy thì sẽ không thể thông khí của lá số Bát Tự này.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Thổ khô rất cần Thủy. Nếu vắng Kim và Thủy thì lá số này sẽ khô hạn. Kim
        và Thủy vốn cũng yếu trong tháng này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Do đó, cả Kim và Thủy Khí cũng cần thông căn và lộ can để đảm bảo là
        Dụng Thần đối với ngày sinh này.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Không tốt nếu gặp thêm Mộc và Hỏa Khí.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi có Kim và Thủy thông căn thì lá số này sẽ hưởng danh tiếng, thành
        công và sang quý.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Giáp Mộc – Chính Quan – có thể dễ dàng kết hợp với ngày sinh này và biến
        thành Thổ. Do đó, Kim sẽ cần như là Dụng Thần để kiểm soát Giáp Mộc. Khi
        Thủy xuất hiện để sinh ra Mộc thì ngày sinh này sẽ trở nên nổi tiếng và
        giàu có trong đời.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Quý Thủy là dụng thần lý tưởng cho ngày sinh Kỷ Thổ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Địa Chi tạo thành Hỏa Cục và thiếu Thủy trong lá số thì ngày sinh
        Kỷ Thổ sẽ cô đơn và khổ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Sẽ tốt nếu cả Tân Kim và Quý Thủy đều hiện diện trong lá số.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tốt nhất là tránh gặp thêm Hỏa và Thổ, đặc biệt vì Hỏa vốn đã mạnh
        trong mùa hè, và có khả năng làm khô Thổ.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Canh Kim và Tân Kim cần để sinh ra và đảm báo tính liên tục của Thủy,
        ngay cả nếu Thủy đã hiện diện trong lá số Bát Tự.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Thiên Tài và Chính Ấn:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi lộ ra Quý Thủy thì ngày sinh Kỷ Thổ sẽ hưởng thành công trong sự
        nghiệp.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngày sinh Kỷ Thổ sẽ hưởng thêm nhiều thành công nếu Tân Kim cũng xuất
        hiện ở lá số.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số tốt nếu như có Quý Thủy thông căn Địa Chi và lộ Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số xấu nếu như Mậu Thổ ở Thiên Can khắc chế Quý Thủy.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Chính Ấn và Thiên Ấn:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Địa Chi tạo thành Hỏa Cục, thì cần dụng thần là Nhâm Thủy và Quý
        Thủy.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số tốt nếu có Thủy thông căn ở Địa Chi.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu thiếu Thủy thì ngày sinh Kỷ Thổ sẽ bị ảnh hưởng bởi nghèo đói và cô
        đơn, thất bại và đau khổ trong đời.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Chính Quan và Thất Sát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Địa Chi tạo thành Mộc Cục, cần có cả Canh Kim và Nhâm Thủy.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số tốt nếu có Nhâm Thủy hiện diện.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số không tốt nếu Thủy không có trong Bát Tự, ngày sinh Kỷ Thổ sẽ chịu
        khổ cực và cô độc trong đời.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Quý Thủy Thiên Tài là dụng thần chính cho ngày sinh Kỷ Thổ trong tháng
        hè.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Địa Chi tạo thành Hỏa Cục thì cần có Nhâm Thủy xuất hiện.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu thiếu Quý Thủy thì có thể dùng Nhâm Thủy thay. Tương tự, nếu thiếu
        Tân Kim thì cần Canh Kim thay.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nguyên tắc trong xác định dụng thần cho ngày sinh trong tháng mùa hè
        dù là Tỵ Ngọ hay Mùi đều giống nhau. Nhưng khác nhau ở chỗ là xét xem
        Nhâm Thủy và Quý Thủy vốn là dụng thần chính có hiện diện hay không.
      </p>
    </div>
  );
};

export const KyThangMui = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Kỷ trong tháng Mùi
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng này thì nếu có Ất Mộc hiện lên ở Thiên
        Can thì xem là hình thành cục Thất Sát.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Đinh Hỏa hiện lên ở Thiên Can thì hình thành cục Thiên Ấn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Kỷ Thổ hiện lên ở Thiên Can thì hình thành Vượng Cục.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng
        có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy ý.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng này thì dụng thần điều hậu là Quý Thủy và
        Bính Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu không có Quý Thủy thì sẽ khó để thông quan và điều chỉnh khí nóng
        của lá số.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đây là tháng mà Thổ rất mạnh.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Thổ rất khô trong tháng Mùi và cần có Thủy như là dụng thần để làm ẩm
        đất.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Mộc có mặt để làm tơi đất thì ngày sinh Kỷ Thổ sẽ có quyền lực.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Kim và Thủy là dụng thần chính đối với ngày sinh Kỷ Thổ trong tháng này
        khi Hỏa và Thổ đều mạnh. Điều này ngăn cho Thổ không quá mạnh hay vượng
        cũng như Thủy không bị yếu đi.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Kim và Thủy, trong tình huống này, có thể hỗ trợ và giúp cho ngày sinh
        Kỷ Thổ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Không tốt nếu lá số gặp thêm Hỏa Khí.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Quý Thủy là dụng thần số 1 cho ngày sinh Kỷ Thổ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Địa Chi hình thành Hỏa Cục thì có thể tạo thành cục Vượng. Khi
        hình thành cục này thì sẽ có cuộc đời phi thường. Có nhiều tài lộc, danh
        tiếng và quyền lực.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Trong tình huống bình thường thì này sinh này cũng cần có Tân Kim và
        Quý Thủy hiện diện sẽ tốt nhất cho lá số này.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nên tránh gặp thêm Hỏa và Thổ, đặc biệt vì Hỏa đã rất mạnh trong mùa
        hè, có khả năng làm khô đất.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Mộc và Hỏa xuất hiện nhiều trong lá số, trong khi Thủy thiếu trong
        lá số và không hình thành cục nào đặc biệt thì người này sẽ cô đơn, khổ
        cực.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Canh Kim và Tân Kim cần có để sinh ra Thủy, ngay cả nếu Thủy đã có mặt
        trong lá số.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Thiên Tài và Chính Ấn:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi xuất hiện Quý Thủy thì ngày sinh Kỷ Thổ sẽ hưởng thành công trong sự
        nghiệp.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số này sẽ có thêm nhiều thành công nếu có Tân Kim xuất hiện.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Quý Thủy có thông căn ở Địa Chi và xuất hiện ở Thiên Can là lá số tốt.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Không tốt nếu có Mậu Thổ xuất hiện ở Thiên Can vì sẽ hợp mất Quý Thủy.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Thiên Ấn Chính Ấn:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Địa Chi tạo thành Hỏa Cục đầy đủ thì Nhâm Thủy và Quý Thủy sẽ cần
        như là Dụng Thần.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số tốt nếu có Thủy thông căn ở Địa Chi.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu thiếu Thủy thì ngày sinh Kỷ Thổ sẽ gặp nghèo khó và cô đơn.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số Chính Quan Thất Sát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Địa Chi tạo thành Mộc Cục đầy đủ thì cần có mặt cả Canh Kim và Nhâm
        Thủy.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số tốt nếu có Nhâm Thủy. Quý` có thể thay thế nếu thiếu Nhâm.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số không tốt nếu thiếu Thủy, ngày sinh Kỷ Thổ sẽ cô đơn cả đời.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Quý Thủy Thiên Tài được xem là dụng thần số một cho ngày sinh Kỷ Thổ
        này. Tân Kim là dụng thần thứ hai.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Địa Chi tạo thành Hỏa Cục thì Nhâm Thủy nên xuất hiện, để tạo ra
        hoàn cảnh tốt nhất cho người này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Nhâm Thủy vắng mặt và Hỏa Thổ Khí xuất hiện nhiều thì lá số có thể
        hình thành Vượng Cục. Khi Cục này hình thành thì lá số này sẽ là người
        đạt thành tích tuyệt vời. Cuộc đời vĩ đại.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu thiếu Quý Thủy thì có thể dùng Nhâm Thủy thay. Tương tự, nếu thiếu
        Tân Kim thì Canh Kim dùng thay cũng được.
      </p>
    </div>
  );
};

export const KyThangThan = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Kỷ trong tháng Thân
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng này thì nếu có Canh Kim hiện lên ở Thiên
        Can thì xem là hình thành cục Thương Quan.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Nhâm Thủy hiện lên ở Thiên Can thì hình thành cục Chính Tài.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Mậu Thổ hiện lên ở Thiên Can thì hình thành cục Dương Nhẫn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng
        có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy ý.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng này thì dụng thần điều hậu là Quý Thủy và
        Bính Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Bính Hỏa cung cấp hơi ấm cho ngày Kỷ Thổ trong khi Quý Thủy dưỡng ẩm.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Canh Kim mạnh trong tháng Thân và do đó cần có Bính Hỏa để kiểm soát Kim
        Khí. Quý Thủy dùng để làm suy yếu và kiểm soát Kim Khí.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Kim Khí vượng trong khi Thủy Khí mạnh. Bởi vì sức mạnh của Kim và Thủy
        làm suy yếu Hỏa và cả Thổ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Bởi vì Thân là đầu mùa thu nên khí của ngày sinh này dần lạnh hơn. Đó là
        lý do vì sao Hỏa cần để cung cấp hơi ấm cho ngày sinh này, cũng như cần
        có thêm Thổ nóng ấm hỗ trợ. Chỉ khi đó thì ngày sinh này mới cứng rắn và
        mạnh mẽ.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Cần chú ý nếu dùng Mộc. Mặc dù Giáp Mộc kết hợp với ngày sinh Kỷ Thổ để
        sinh ra Thổ, Thổ vẫn yếu. Trong tình huống này thì Hỏa cần có để kiểm
        soát Kim Khí và cung cấp hơi ấm cho ngày sinh này.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Bính Hỏa là dụng thần số một.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Quý Thủy là dụng thần số hai.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu hình thành Kim Cục thì Hỏa phải xuất hiện trong lá số. Nếu không
        có Hỏa thì người này sẽ rất tài năng nhưng tài năng của họ sẽ tạo ra
        những rắc rối lớn trong đời của họ.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Thiên Tài và Chính Ấn:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu cả Bính Hỏa và Quý Thủy cùng xuất hiện thì ngày sinh này sẽ hưởng
        danh tiếng và tài lộc trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số tốt nếu Bính Hỏa ở Thiên Can và Quý Thủy ở Địa Chi.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số xấu nếu như Mậu Thổ ở Thiên Can.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Chính Tài: </b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngay cả nếu Nhâm Thủy xuất hiện ở Thiên Can thì lá số sẽ không tốt hay
        có lợi so với Quý Thủy. Kỷ Thổ sẽ có lòng kiểm soát Nhâm Thủy.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Không nên thiếu Bính Hỏa trong lá số này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Bính Hỏa Chính Ấn nên xuất hiện ở Thiên Can thì lá số tốt.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu thiếu Bính Hỏa thì người này sẽ vẫn có kiến thức và giỏi giang nhưng
        tài lộc vật chất mà người này hưởng trong đời sẽ bị giới hạn.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Thực Thần Thương Quan:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Địa Chi tạo thành Kim Cục và Quý Thủy lộ ở Thiên Can thì ngày sinh
        Kỷ thổ sẽ có tài lộc trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số tót nếu có Bính Hỏa và Đinh Hỏa không chỉ hiện diện mà còn là ở
        Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số không tốt nếu không có Bính Hỏa và cả Đinh Hỏa trong lá số.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Tỷ Kiếp:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Mậu Kỷ Thổ thì hình toàn bộ lá số bao gồm Thổ và Kim khí, có thể
        hình thành cục Tòng Tử.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số tốt nếu không có Mộc khí xuất hiện.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số Chính Ấn Thiên Ấn:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Sẽ bất lợi nhiều nếu ngày sinh Kỷ Thổ này có Địa Chi tạo thành Hỏa Cục
        và thiếu Thủy.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số tốt nếu có Thủy.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Sẽ xấu nếu như Mậu Thổ xuất hiện và làm suy yếu Nhâm Thủy và Quý Thủy.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Bính Hỏa và Quý Thủy là dụng thần ưu tiên.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu tạo thành cục Tòng Tử thì người này sẽ có danh tiếng và tài lộc
        lớn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Hỏa Khí đóng vai trò sức sống của ngày sinh này.
      </p>
    </div>
  );
};

export const KyThangDau = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        className="p1"
        style={{
          margin: "0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          <strong>Ngày sinh Kỷ trong tháng Dậu</strong>
        </span>
      </p>
      <ul
        className="ul1"
        style={{
          listStyleType: "disc",
          fontSize: "medium",
          textAlign: "start",
        }}
      >
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Khái Quát:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          lineHeight: "normal",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          Đối với ngày sinh Kỷ trong tháng Dậu nếu có Tân Kim xuất hiện ở Thiên
          Can thì hình thành cục Thực Thần.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          lineHeight: "normal",
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul
        className="ul1"
        style={{
          listStyleType: "disc",
          fontSize: "medium",
          textAlign: "start",
        }}
      >
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Dụng Thần Điều Hậu:
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          lineHeight: "normal",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          Đối với người sinh trong ngày Mậu Thổ tháng Dậu thì cần Bính Hỏa và
          Quý Thủy làm dụng thần điều hậu.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          lineHeight: "normal",
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul
        className="ul1"
        style={{
          listStyleType: "disc",
          fontSize: "medium",
          textAlign: "start",
        }}
      >
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Luận Giải:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          lineHeight: "normal",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          Kim mạnh nhất trong tháng này.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          lineHeight: "normal",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          Vì Kim khí mạnh sẽ làm suy yếu khí của Kỷ Thổ. Nếu không có Hỏa và Thổ
          sinh ra để củng cố ngày sinh này, lá số sẽ là người thiếu sức sống.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          lineHeight: "normal",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          Sẽ không cần thiết phải gặp thêm Thủy vì Kim vốn dĩ rất mạnh và có thể
          sinh ra Thủy.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          lineHeight: "normal",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          Không cần thiết phải gặp thêm Mộc Khí vốn dĩ sẽ làm suy yếu Kỷ Thổ.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          lineHeight: "normal",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          Vào tháng Dậu thì ngày sinh Kỷ thổ sinh vào giờ Hỏa và Thổ mạnh thì sẽ
          sở hữu tính cách tinh tế, dịu dàng và có văn hóa.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul
        className="ul1"
        style={{
          listStyleType: "disc",
          fontSize: "medium",
          textAlign: "start",
        }}
      >
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Lưu Ý:</strong>
          </span>
        </li>
      </ul>
      <ul
        className="ul1"
        style={{
          listStyleType: "disc",
          fontSize: "medium",
          textAlign: "start",
        }}
      >
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Bính Hỏa là dụng thần lý tưởng cho ngày sinh này và Quý Thủy là dụng
            thần thứ hai.<span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Hỏa Khí xuất hiện ở Thiên Can hay Địa Chi thì Thủy phải xuất
            hiện.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu tạo thành Hỏa Cục đầy đủ thì Hỏa phải xuất hiện. Nếu không thì
            ngày sinh Kỷ Thổ sẽ cô đơn và bị ghét.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Bởi vì Khí bắt đầu lạnh giá hơn vì Thân Dậu Tuất biểu thị mùa thu
            nên Hỏa là dụng thần ưu tiên của ngày sinh này.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          lineHeight: "normal",
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul
        className="ul1"
        style={{
          listStyleType: "disc",
          fontSize: "medium",
          textAlign: "start",
        }}
      >
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Cách Cục:</strong>
          </span>
        </li>
      </ul>
      <ul
        className="ul1"
        style={{
          listStyleType: "disc",
          fontSize: "medium",
          textAlign: "start",
        }}
      >
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
          }}
        >
          <span
            className="s1"
            style={{
              textDecorationLine: "underline",
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Nếu lá số có Thiên Tài Chính Ấn:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          lineHeight: "normal",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          Quý Thủy nên là dụng thần ưu tiên còn Bính Hỏa là dụng thần thứ hai.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          lineHeight: "normal",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          Nếu cả hai cùng xuất hiện thì ngày sinh này sẽ hưởng danh tiếng, hạnh
          phúc và may mắn trong đời.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          lineHeight: "normal",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          Lá số tốt nếu như Quý Thủy và Bính Hỏa xuất hiện trong lá số.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          lineHeight: "normal",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          Gặp bất lợi nếu Mậu Thổ xuất hiện ở Thiên Can.
        </span>
      </p>
      <ul
        className="ul1"
        style={{
          listStyleType: "disc",
          fontSize: "medium",
          textAlign: "start",
        }}
      >
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
          }}
        >
          <span
            className="s1"
            style={{
              textDecorationLine: "underline",
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Nếu lá số có Chính Tài:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          lineHeight: "normal",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          Kỷ Thổ không thể kiểm soát được Nhâm Thủy.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          lineHeight: "normal",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          Lá số tốt nếu như Bính Hỏa xuất hiện ở Thiên Can.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          lineHeight: "normal",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          Nếu thiếu bính Hỏa thì ngày sinh này sẽ tri thức nhưng thành công về
          vật chất sẽ bị rất giới hạn.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul
        className="ul1"
        style={{
          listStyleType: "disc",
          fontSize: "medium",
          textAlign: "start",
        }}
      >
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
          }}
        >
          <span
            className="s1"
            style={{
              textDecorationLine: "underline",
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Nếu lá số có Thực Thần Thương Quan:
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          lineHeight: "normal",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          Khi Địa Chi tạo thành Kim Cục và Quý Thủy lộ ở Thiên Can thì ngày sinh
          này sẽ hưởng danh tiếng thông qua kiến thức, kỹ năng hay tài năng.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          lineHeight: "normal",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          Lá số tốt nếu Bính Hỏa và Đinh Hỏa không chỉ nên hiện diện mà còn phải
          xuất hiện ở Thiên Can.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          lineHeight: "normal",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          Lá số không tốt nếu như cả Bính Hỏa và Đinh Hỏa đều không xuất hiện
          trong lá số Kỷ Thổ này.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul
        className="ul1"
        style={{
          listStyleType: "disc",
          fontSize: "medium",
          textAlign: "start",
        }}
      >
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
          }}
        >
          <span
            className="s1"
            style={{
              textDecorationLine: "underline",
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Nếu lá số có Tỷ Kiếp:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          lineHeight: "normal",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          Nếu cả Tỷ Kiên và Kiếp Tài cùng xuất hiện nhiều trong lá số thì cần có
          Mộc để làm tơi đất.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          lineHeight: "normal",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          Lá số tốt nếu có Mộc Khí.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          lineHeight: "normal",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          Xấu nếu như Giáp không xuất hiện ở Thiên Can thì ngày sinh này sẽ cô
          đơn trong đời.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul
        className="ul1"
        style={{
          listStyleType: "disc",
          fontSize: "medium",
          textAlign: "start",
        }}
      >
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
          }}
        >
          <span
            className="s1"
            style={{
              textDecorationLine: "underline",
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Nếu lá số có Chính Ấn và Thiên Ấn:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          lineHeight: "normal",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          Sẽ bất lợi cho ngày sinh Kỷ Thổ khi Địa Chi tạo thành Hỏa Cục nếu
          thiếu Thủy trong lá số.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          lineHeight: "normal",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          Lá số tốt nếu có Thủy hiện diện ở Địa Chi.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          lineHeight: "normal",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          Lá số xấu nếu Mậu Thổ xuất hiện và khắc chế Nhâm Thủy và Quý Thủy.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          lineHeight: "normal",
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul
        className="ul1"
        style={{
          listStyleType: "disc",
          fontSize: "medium",
          textAlign: "start",
        }}
      >
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Tóm Tắt:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <ul
        className="ul1"
        style={{
          listStyleType: "disc",
          fontSize: "medium",
          textAlign: "start",
        }}
      >
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Bính Hỏa và Quý Thủy là dụng thần ưu tiên của ngày sinh này.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi có đầy đủ 4 Địa Chi Thìn Tuất Sửu Mùi mà không có Giáp ở Thiên
            Can thì ngày sinh Kỷ Thổ sẽ nghèo và cô đơn trong đời.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Hỏa là cần thiết để kiểm soát Kim và duy trì sự sống cho Thổ.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
    </div>
  );
};

export const KyThangTuat = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        className="p1"
        style={{
          margin: "0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontSize: "18pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          <strong>Ngày sinh Kỷ trong tháng Tuất</strong>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "0px",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            <strong>Khái Quát:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì nếu có Đinh Hỏa hiện lên ở
          Thiên Can thì xem là hình thành cục Thiên Ấn.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Nếu có Tân Kim hiện lên ở Thiên Can thì hình thành cục Thực Thần.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Nếu có Mậu Thổ hiện lên ở Thiên Can thì hình thành cục Vượng.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng
          có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy
          ý.
        </span>
      </p>
      <p
        className="p4"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "0px",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            <strong>
              Dụng Thần Điều Hậu:
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì dụng thần điều hậu là Bính Hỏa,
          Giáp Mộc và Quý Thủy.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Thổ khí vượng trong tháng Tuất. Đây là nơi Giáp Mộc xuất hiện như là
          dụng thần, để làm tơi xốp Thổ.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Trong lúc đó thì Bính Hỏa và Quý Thủy đóng vai trò là dụng thần thứ
          hai đối với ngày sinh Kỷ Thổ.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p4"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "0px",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            <strong>
              Luận Giải:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Địa chi Tuất đóng vai trò là khố chứa Hỏa và Thổ khí.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Ngày sinh này có nguy cơ bị khô. Do đó Thủy là dụng thần cần thiết để
          cung cấp hơi ẩm.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Sẽ tốt hơn nếu có Kim sinh Thủy để làm ẩm Thổ; thay vào đó nên sử dụng
          riêng Mộc. Điều này bởi vì Thổ sẽ dày và đặc, Mộc có xu hướng giòn và
          yếu nếu chỉ dùng riêng Mộc.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Ngày sinh Kỷ Thổ vào giờ có Kim và Thủy mạnh thì có thể hưởng xa hoa
          hoặc thậm chí cuộc sống giàu có.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            <strong>Lưu Ý:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            Quý Thủy là dụng thần ưu tiên cho ngày này cùng với Bính và Giáp là
            dụng thần số hai.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            Ngay cả nếu Thổ mạnh trong tháng Tuất thì Giáp Mộc vẫn cần phải xuất
            hiện ở Thiên Can để làm tơi đất.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            Khi hình thành Hỏa cục đầy đủ, thì Thủy phải xuất hiện.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            Khi hình thành Kim cục đầy đủ, thì cần có Hỏa. Nếu không thì ngày
            sinh Kỷ Thổ sẽ chịu khổ và bất hạnh cũng như gánh nặng trong đời.
          </span>
        </li>
      </ul>
      <p
        className="p4"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "0px",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            <strong>Cách Cục:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Thiên Tài và Chính Ấn:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Quý Thủy nên là dụng thần chính còn Bính Hỏa là dụng thần số hai.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Nếu cả hai dụng thần đều có mặt thì người này sẽ hưởng danh tiếng và
          may mắn.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Bất lợi nếu có Mậu Thổ xuất hiện ở Thiên Can.
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>
                Nếu lá số có Chính Tài:
                <span className="Apple-converted-space">&nbsp;</span>
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Quý được xem là giá trị cho Kỷ Thổ hơn là Nhâm Thủy. Tuy nhiên nếu như
          có Hỏa Khí thì Kỷ Thổ có thể sử dụng được Nhâm Thủy.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Bính Hỏa không nên vắng mặt trong lá số Bát Tự.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Nếu thiếu Bính Hỏa thì ngày sinh Kỷ Thổ sẽ là người học thức nhưng
          thành công về vật chất sẽ ngắn ngủi.
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số Thương Quan Thực Thần:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Khi Địa Chi hình thành Kim Cục và Quý Thủy lộ Thiên Can thì ngày sinh
          Kỷ Thổ sẽ có danh tiếng dài lâu.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Lá số tốt nếu như có Bính Hỏa và Đinh Hỏa không chỉ xuất hiện mà còn
          lộ ra ở Thiên Can.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Lá số xấu nếu như Bính Hỏa và Đinh Hỏa đều không xuất hiện trong lá số
          ngày sinh Kỷ Thổ.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Tỷ Kiếp:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Nếu cả 4 Địa Chi là Thìn Tuất Sửu Mùi đều xuất hiện trong khi Giáp Mộc
          cũng xuất hiện ở Thiên Can thì ngày sinh Kỷ Thổ sẽ trở nên giàu có.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Lá số tốt nếu có Giáp Mộc ở Thiên Can.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Lá số không tốt nếu không có Giáp Mộc ở Thiên Can, ngày sinh Kỷ Thổ sẽ
          chịu vất vả, đau khổ trong đời.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Chính Ấn Thiên Ấn:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Sẽ bất lợi nếu Địa Chi tạo thành Hỏa Cục và thiếu hành Thủy trong lá
          số.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Sẽ có lợi nếu gặp hành Thủy.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Bất lợi nếu Mậu Thổ xuất hiện và khắc Nhâm Quý Thủy.
        </span>
      </p>
      <p
        className="p4"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "0px",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            <strong>
              Tóm Tắt:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            Bính Hỏa và Quý Thủy là dụng thần số 1 cho ngày sinh này.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            Nếu 4 Địa Chi Thìn Tuất Sửu mùi cùng xuất hiện mà không có Giáp Mộc
            lộ Thiên Can thì ngày sinh này sẽ chịu cô đơn, vất vả, buồn bã và
            nghèo khổ.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            Hỏa rất cần thiết để cung cấp sự sống cho ngày sinh Thổ này.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
    </div>
  );
};

export const KyThangHoi = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        className="p1"
        style={{
          margin: "0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          <strong>Ngày sinh Kỷ trong tháng Hợi</strong>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Khái Quát:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì nếu có Nhâm Thủy hiện lên ở
          Thiên Can thì xem là hình thành cục Chính Tài.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Giáp Mộc hiện lên ở Thiên Can thì hình thành cục Chính Quan.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng
          có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy
          ý.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Dụng Thần Điều Hậu:
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì dụng thần điều hậu là Bính Hỏa,
          Giáp Mộc và Mậu Thổ.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Ngày sinh Kỷ Thổ trong tháng mùa đông Hợi Tý Sửu sẽ cần Bính Hỏa để
          cung cấp hơi ấm. Nếu không thì sẽ khó cho ngày sinh này vượt qua giá
          lạnh.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nhâm Thủy rất mạnh vào đầu mùa đông. Đây là lý do vì sao Mậu Thổ cần
          để kiểm soát Nhâm Thủy.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Thổ xuất hiện nhiều thì Giáp Mộc cần để làm tơi xốp.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Luận Giải:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số này vốn lạnh cùng với Thổ yếu. Mặc dù Thủy mạnh và có khả năng
          làm ẩm Mộc, vẫn sẽ rất khó để Mộc sinh ra. Kỳ thực, Mộc bị đóng băng.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Thổ nóng và Hỏa sẽ có lợi, trong việc sinh trợ và giúp ngày sinh Kỷ
          Thổ mạnh hơn. Cũng cần phải tránh gặp Kim, Thủy và Mộc nếu được.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Điều này bởi vì Kim, Thủy và Mộc sẽ kiểm soát và làm suy yếu ngày sinh
          Kỷ Thổ.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu sinh vào giờ Hỏa Thổ mạnh thì tài vận của người này sẽ rất tốt.
          Vận may của người sinh ngày Kỷ Thổ trong tháng này phụ thuộc phần lớn
          vào sự hiện diện của hành Hỏa và Thổ trong lá số Bát Tự.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Lưu Ý:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Bính Hỏa và Giáp Mộc là dụng thần chính cho ngày sinh Kỷ Thổ.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Không nên có Nhâm Thủy và Quý Thủy ở Thiên Can.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu Nhâm Thủy ở Thiên Can hoặc Địa Chi khi thiếu Bính Hỏa thì ngày
            sinh này sẽ thiếu định hướng trong cuộc sống.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Quý Thủy lộ Thiên Can hay ở Địa Chi trong khi thiếu Tỷ Kiếp và
            Ấn hoàn toàn thì sẽ hình thành cục Tòng Tài. Trong hoàn cảnh này thì
            ngày sinh Kỷ Thổ sẽ cuộc đời phi thường nhiều mạo hiểm và thành
            công.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Mậu Thổ và Kỷ Thổ xuất hiện ở Thiên Can cùng với Giáp Mộc thì
            ngày sinh Kỷ Thổ sẽ sở hữu tri thức và kỹ năng cần thiết để thành
            công trong đời. Tuy nhiên, Bính Hỏa không nên thiếu.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Canh Khim xuất hiện ở Thiên Can thì Bính Hỏa và Đinh Hỏa phải
            mạnh để giúp cho ngày sinh Kỷ Thổ có sự thịnh vượng, hạnh phúc và
            danh tiếng.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Bất kể lá số Kỷ Thổ như thế nào thì vẫn phải cần có Bính Hỏa hiện
            diện trong lá số.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Cách Cục:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Chính Quan và Chính Ấn:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Bính Hỏa và Giáp Mộc cùng hiện diện thì ngày sinh Kỷ Thổ sẽ trở
          nên nổi tiếng và thành công trong sự nghiệp.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu xuất hiện Nhâm Thủy thì sẽ cần Mậu Thô để kiểm soát.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu Nhâm Thủy xuất hiện ở Thiên Can.
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>
                Nếu lá số có Thiên Tài:
                <span className="Apple-converted-space">&nbsp;</span>
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Địa Chi hình thành Thủy Cục đầy đủ cùng với vắng mặt Tỷ Kiếp và Ấn
          Tinh thì hình thành cục Tòng Tài.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu không gặp Tỷ Kiếp và Ấn Tinh.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu Tỷ Kiếp và Ấn Tinh ở Thiên Can thì ngày sinh Kỷ Thổ chỉ sống cuộc
          đời trung bình.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Kiếp Tài Tỷ Kiên:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có đầy đủ 4 địa chi Thìn Tuất Sửu Mùi hình thành Thổ Cục, thì Giáp
          Mộc cần xuất hiện ở Thiên Can.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu có Thiên Can Giáp Mộc.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu không có Giáp Mộc tại Thiên Can.
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Thương Quan:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu Đinh Hỏa Ấn Tinh ở Thiên Can thì ngày sinh Kỷ Thổ sẽ hưởng thịnh
          vượng và thành công trong đời.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu có Đinh Hỏa thông căn và mạnh.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu không có Đinh Hỏa.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Tóm Tắt:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Bính Hỏa và Giáp Mộc là dụng thần ưu tiên.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Kỷ Thổ sinh trong tháng này nên dùng Đinh Hỏa để kiểm soát Canh Kim.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu hình thành cục Tòng Tài thì sẽ phụ thuộc lớn vào sự xuất hiện và
            sức mạnh của Thiên Tài của ngày sinh này. Nếu hình thành cục này thì
            người này có cuộc đời phi thường nhiều mạo hiểm và tài lộc.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Sẽ tốt nếu có cả Kim Thương Quan và Hỏa Ấn Tinh hiện diện. Điều này
            giúp đảm bảo thành công lâu dài và hạnh phúc.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Thủy xuất hiện nhiều, cần có Mậu Thổ Kiếp Tài để kiểm soát. Kỷ
            Thổ không thay được.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
    </div>
  );
};
