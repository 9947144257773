export const NhatCanDungThan = async (value) => {
  switch (value) {
    case "Giáp Tháng Dần":
      return "Bính Hỏa, Quý Thủy";
    case "Giáp Tháng Mão":
      return "Canh Kim, Bính Hỏa, Đinh Hỏa, Mậu Thổ và Kỷ Thổ";
    case "Giáp Tháng Thìn":
      return "Canh Kim, Đinh Hỏa, Nhâm Thuỷ";
    case "Giáp Tháng Tỵ":
      return "Quý Thuỷ, Đinh Hỏa, Canh Kim";
    case "Giáp Tháng Ngọ":
      return "Quý Thuỷ, Đinh Hỏa, Canh Kim ";
    case "Giáp Tháng Mùi":
      return "Quý Thuỷ, Canh Kim, Đinh Hỏa ";
    case "Giáp Tháng Thân":
      return "Canh Kim, Đinh Hỏa, Nhâm Thuỷ ";
    case "Giáp Tháng Dậu":
      return "Canh Kim, Đinh Hỏa, Bính Hỏa ";
    case "Giáp Tháng Tuất":
      return "Canh Kim, Giáp Mộc, Đinh Hỏa, Nhâm Thuỷ, Quý Thuỷ";
    case "Giáp Tháng Hợi":
      return "Canh Kim, Đinh Hỏa, Bính Hỏa, Mậu Thổ.";
    case "Giáp Tháng Tý":
      return "Canh Kim, Đinh Hỏa, Bính Hỏa";
    case "Giáp Tháng Sửu":
      return "Đinh Canh Bính";

    case "Ất Tháng Dần":
      return "Bính Hoả, Quý Thủy ";
    case "Ất Tháng Mão":
      return "Bính Hoả, Quý Thủy ";
    case "Ất Tháng Thìn":
      return "Quý Thủy Bính Hoả, Mậu Thổ ";
    case "Ất Tháng Tỵ":
      return "Quý Thủy ";
    case "Ất Tháng Ngọ":
      return "Quý Thủy, Bính Hoả";
    case "Ất Tháng Mùi":
      return "Quý Thủy, Bính Hoả ";
    case "Ất Tháng Thân":
      return "Bính Hỏa, Quý Thủy, Kỷ Thổ ";
    case "Ất Tháng Dậu":
      return "Quý Thủy, Bính Hỏa, Đinh Hỏa ";
    case "Ất Tháng Tuất":
      return "Quý Thủy, Tân Kim ";
    case "Ất Tháng Hợi":
      return "Bính Hỏa, Mậu Thổ ";
    case "Ất Tháng Tý":
      return "Bính Hỏa";
    case "Ất Tháng Sửu":
      return "Bính Hỏa";

    case "Bính Tháng Dần":
      return "Nhâm Thủy, Canh Kim";
    case "Bính Tháng Mão":
      return "Nhâm Thủy, Kỷ Thổ";
    case "Bính Tháng Thìn":
      return "Nhâm Thủy, Giáp Mộc";
    case "Bính Tháng Tỵ":
      return "Nhâm Thủy, Canh Kim, Quý Thủy";
    case "Bính Tháng Ngọ":
      return "Nhâm Thủy, Canh Kim";
    case "Bính Tháng Mùi":
      return "Nhâm Thủy, Canh Kim";
    case "Bính Tháng Thân":
      return "Nhâm Thủy, Mậu Thổ ";
    case "Bính Tháng Dậu":
      return "Nhâm Thủy, Quý Thủy";
    case "Bính Tháng Tuất":
      return "Giáp Mộc, Nhâm Thủy";
    case "Bính Tháng Hợi":
      return "Giáp Mộc, Mậu Thổ, Canh Kim, Nhâm Thủy";
    case "Bính Tháng Tý":
      return "Nhâm Thủy, Mậu Thổ, Kỷ Thổ";
    case "Bính Tháng Sửu":
      return "Nhâm Thủy, Giáp Mộc ";

    case "Đinh Tháng Dần":
      return "Giáp Mộc, Canh Kim";
    case "Đinh Tháng Mão":
      return "Canh Kim, Giáp Mộc ";
    case "Đinh Tháng Thìn":
      return "Giáp Mộc, Canh Kim ";
    case "Đinh Tháng Tỵ":
      return "Giáp Mộc, Canh Kim ";
    case "Đinh Tháng Ngọ":
      return "Nhâm Thủy, Canh Kim, Quý Thủy ";
    case "Đinh Tháng Mùi":
      return "Giáp Mộc, Nhâm Thủy, Canh Kim";
    case "Đinh Tháng Thân":
      return "Giáp Mộc, Canh Kim, Bính Hỏa, Mậu Thổ ";
    case "Đinh Tháng Dậu":
      return "Giáp Mộc, Canh Kim, Bính Hỏa, Mậu Thổ.";
    case "Đinh Tháng Tuất":
      return "Canh Kim, Giáp Mộc, Mậu Thổ ";
    case "Đinh Tháng Hợi":
      return "Giáp Mộc, Canh Kim";
    case "Đinh Tháng Tý":
      return "Giáp Mộc, Canh Kim";
    case "Đinh Tháng Sửu":
      return "Giáp Mộc, Canh Kim";

    case "Mậu Tháng Dần":
      return "Bính Hỏa, Giáp Mộc, Quý Thủy.";
    case "Mậu Tháng Mão":
      return "Bính Hỏa, Giáp Mộc, Quý Thủy.";
    case "Mậu Tháng Thìn":
      return "Giáp Mộc, Bính Hỏa, Quý Thủy";
    case "Mậu Tháng Tỵ":
      return "Giáp Mộc, Bính Hỏa, Quý Thủy";
    case "Mậu Tháng Ngọ":
      return " Nhâm Thủy, Giáp Mộc, Bính Hỏa";
    case "Mậu Tháng Mùi":
      return "Quý Thủy, Bính Hỏa, Giáp Mộc";
    case "Mậu Tháng Thân":
      return "Quý Thủy, Bính Hỏa, Giáp Mộc";
    case "Mậu Tháng Dậu":
      return "Bính Hỏa, Quý Thủy";
    case "Mậu Tháng Tuất":
      return "Giáp Mộc, Bính Hỏa, Quý Thủy";
    case "Mậu Tháng Hợi":
      return "Giáp Mộc, Bính Hỏa";
    case "Mậu Tháng Tý":
      return "Bính Hỏa, Giáp Mộc";
    case "Mậu Tháng Sửu":
      return "Bính Hỏa, Giáp Mộc";

    case "Kỷ Tháng Dần":
      return "Bính Hỏa, Canh Kim, Giáp Mộc";
    case "Kỷ Tháng Mão":
      return "Giáp Mộc, Bính Hỏa, Quý Thủy";
    case "Kỷ Tháng Thìn":
      return "Bính Hỏa, Quý Thủy, Giáp Mộc";
    case "Kỷ Tháng Tỵ":
      return "Quý Thủy, Bính Hỏa";
    case "Kỷ Tháng Ngọ":
      return "Quý Thủy";
    case "Kỷ Tháng Mùi":
      return "Quý Thủy, Bính Hỏa";
    case "Kỷ Tháng Thân":
      return "Quý Thủy, Bính Hỏa";
    case "Kỷ Tháng Dậu":
      return "Bính Hỏa, Quý Thủy";
    case "Kỷ Tháng Tuất":
      return "Bính Hỏa, Giáp Mộc, Quý Thủy";
    case "Kỷ Tháng Hợi":
      return "Bính Hỏa, Giáp Mộc, Mậu Thổ";
    case "Kỷ Tháng Tý":
      return "Bính Hỏa, Giáp Mộc, Mậu Thổ";
    case "Kỷ Tháng Sửu":
      return "Bính Hỏa, Giáp Mộc, Mậu Thổ";

    case "Canh Tháng Dần":
      return "Mậu Thổ, Giáp Mộc, Nhâm Thủy, Bính Hỏa, Đinh Hỏa";
    case "Canh Tháng Mão":
      return "Đinh Hỏa, Giáp Mộc, Canh Kim, Bính Hỏa";
    case "Canh Tháng Thìn":
      return "Giáp Mộc, Đinh Hỏa, Nhâm Thủy, Quý Thủy";
    case "Canh Tháng Tỵ":
      return "Nhâm Thủy, Mậu Thổ, Bính Hỏa, Đinh Hỏa";
    case "Canh Tháng Ngọ":
      return "Nhâm Thủy, Quý Thủy";
    case "Canh Tháng Mùi":
      return "Đinh Hỏa, Giáp Mộc";
    case "Canh Tháng Thân":
      return "Đinh Hỏa, Giáp Mộc";
    case "Canh Tháng Dậu":
      return "Đinh Hỏa, Giáp Mộc, Bính Hỏa ";
    case "Canh Tháng Tuất":
      return "Giáp Mộc, Nhâm Thủy";
    case "Canh Tháng Hợi":
      return "Đinh Hỏa, Bính Hỏa";
    case "Canh Tháng Tý":
      return "Đinh Hỏa, Giáp Mộc, Bính Hỏa";
    case "Canh Tháng Sửu":
      return "Bính Hỏa, Đinh Hỏa, Giáp Mộc";

    case "Tân Tháng Dần":
      return "Kỷ Thổ, Nhâm Thủy, Canh Kim";
    case "Tân Tháng Mão":
      return "Nhâm Thủy, Giáp Mộc";
    case "Tân Tháng Thìn":
      return "Nhâm Thủy, Giáp Mộc";
    case "Tân Tháng Tỵ":
      return "Giáp Mộc, Nhâm Thủy, Quý Thủy";
    case "Tân Tháng Ngọ":
      return "Nhâm Thủy, Kỷ Thổ, Quý Thủy";
    case "Tân Tháng Mùi":
      return "Nhâm Thủy, Canh Kim, Giáp Mộc";
    case "Tân Tháng Thân":
      return "Nhâm Thủy, Mậu Thổ, Giáp Mộc";
    case "Tân Tháng Dậu":
      return "Nhâm Thủy, Giáp Mộc";
    case "Tân Tháng Tuất":
      return "Nhâm Thủy, Giáp Mộc";
    case "Tân Tháng Hợi":
      return "Nhâm Thủy, Bính Hỏa";
    case "Tân Tháng Tý":
      return "Bính Hỏa, Mậu Thổ, Nhâm Thủy, Giáp Mộc.";
    case "Tân Tháng Sửu":
      return "Bính Hỏa, Nhâm Thủy Mậu Thổ, Kỷ Thổ";

    case "Nhâm Tháng Dần":
      return "Canh Kim, Bính Hỏa, Mậu Thổ";
    case "Nhâm Tháng Mão":
      return "Mậu Thổ, Tân Kim, Canh Kim";
    case "Nhâm Tháng Thìn":
      return "Giáp Mộc, Canh Kim";
    case "Nhâm Tháng Tỵ":
      return "Nhâm Thủy, Tân Kim, Canh Kim, Quý Thủy ";
    case "Nhâm Tháng Ngọ":
      return "Quý Thủy, Canh Kim, Tân Kim";
    case "Nhâm Tháng Mùi":
      return "Tân Kim, Giáp Mộc";
    case "Nhâm Tháng Thân":
      return "Mậu Thổ, Đinh Hỏa";
    case "Nhâm Tháng Dậu":
      return "Giáp Mộc, Canh Kim ";
    case "Nhâm Tháng Tuất":
      return "Giáp Mộc, Bính Hỏa";
    case "Nhâm Tháng Hợi":
      return "Mậu Thổ, Bính Hỏa, Canh Kim";
    case "Nhâm Tháng Tý":
      return "Mậu Thổ, Bính Hỏa";
    case "Nhâm Tháng Sửu":
      return "Bính Hỏa, Đinh Hỏa, Giáp Mộc";

    case "Quý Tháng Dần":
      return "Tân Kim, Bính Hỏa";
    case "Quý Tháng Mão":
      return "Canh Kim, Tân Kim";
    case "Quý Tháng Thìn":
      return "Bính Hỏa, Tân Kim, Giáp Mộc";
    case "Quý Tháng Tỵ":
      return "Tân Kim";
    case "Quý Tháng Ngọ":
      return "Canh Kim, Tân Kim, Nhâm Thủy, Quý Thủy";
    case "Quý Tháng Mùi":
      return "Canh Kim, Tân Kim, Nhâm Thủy, Quý Thủy";
    case "Quý Tháng Thân":
      return "Đinh Hỏa";
    case "Quý Tháng Dậu":
      return "Tân Kim, Bính Hỏa ";
    case "Quý Tháng Tuất":
      return "Tân Kim, Giáp Mộc, Nhâm Thủy, Quý Thủy";
    case "Quý Tháng Hợi":
      return "Canh Kim, Tân Kim, Mậu Thổ, Đinh Hỏa";
    case "Quý Tháng Tý":
      return "Bính Hỏa, Tân Kim";
    case "Quý Tháng Sửu":
      return "Bính Hỏa, Đinh Hỏa";

    default:
      break;
  }
};
