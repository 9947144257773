import React from "react";

export const GiapThangDan = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày Sinh Giáp Mộc Trong Tháng Dần
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người sinh ngày Giáp trong tháng Dần, có Địa Chi Dần thuộc về Lộc của
        Giáp khiến hình thành nên cục Lâm Quan.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Giáp Mộc lộ lên trên Thiên Can thì được xem là cục Lâm Quan.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Bính Hỏa xuất hiện trên Thiên Can thì được xem là cục Thực thần
        nếu có Địa Chi phù hợp.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Mậu Thổ hiện lên trên Thiên Can thì hình thành cục Thiên Tài nếu
        đủ các điều kiện của Địa Chi.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b>• Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với Giáp sinh trong tháng Dần thì Bính Hỏa và Quý Thuỷ là Điều Hậu
        Dụng Thần.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Bính Hỏa được xem là Dụng Thần số 1 còn Quý Thuỷ được xem là Dụng Thần
        số 2.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Vì tháng Dần là mốc thời gian giao giữa mùa Xuân và mùa Đông nên Giáp
        Mộc vốn dĩ đang lạnh giá. Cần phải hoá giải hàn lạnh trong lá số để Mộc
        có thể phát triển.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Hỏa cần để cung cấp hơi ấm cho ngày sinh Giáp Mộc, vì đây vẫn là thời
        điểm rất lạnh trong năm. Không thể đánh giá thấp Hỏa vì nó đem lại tài
        lộc và tương lai may mắn cho ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngày sinh này cần Hỏa để sinh ra Thổ, vốn dĩ là Tài Tinh của Giáp Mộc,
        giúp gia tăng tài năng của Giáp Mộc.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Luư Ý:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Bính Hỏa và Quý Thuỷ đều cùng xuất hiện ở Thiên Can thì Giáp Mộc
        sẽ hưởng may mắn về sự nghiệp và tài lộc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Ngay cả nếu Quý Thuỷ ẩn trong Địa Chi chứ không lộ trên Thiên Can thì
        ngày Giáp Mộc cũng sẽ gặp nhiều may mắn về Phú trong đời, nhưng có thể
        thiếu Quý.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Ngày sinh Giáp Mộc sẽ chỉ bình bình bậc trung nếu thiếu Quý Thuỷ trong
        lá số, và Bính Hỏa cũng không lộ Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Sẽ rất bất lợi cho ngày sinh này nếu cả Nhâm Thuỷ và Kỷ Thổ đều lộ ở
        Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Bính Hỏa rất quan trọng vì là Dụng Thần Điều Hậu đối với ngày sinh
        này, đặc biệt là trong tháng Dần, vì sẽ khắc chế lạnh giá và rét buốt.
        Bính Hỏa vẫn sẽ tương tác khắc chế Kim, nếu có hiện diện trong lá số Bát
        Tự.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Vì Giáp Mộc có thông căn ở tháng Dần nên hình thành nên cách cục Lâm
        Quan. Vì quá mạnh nên không thể theo cục Tòng Tài, Tòng Quan hay các cục
        Hợp Hoá.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Không tốt nếu gặp nhiều Tài Tinh Thổ. Quá nhiều Thổ sẽ hạn chế hành
        Mộc phát triển trong tháng này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có Canh Kim hay Tân Kim lộ ở Thiên Can nhưng thiếu Bính Đinh thì
        ngày sinh này vất vả để mưu sinh. Mối quan hệ với những người thân yêu
        cũng sẽ tệ, vì phải chịu nghèo và sức khoẻ kém.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có Bính hay Đinh Hỏa ở Thiên Can cùng với Quý Thuỷ thì ngày sinh
        này sẽ vượng và trở nên rất giàu có. Nếu không có Quý Thuỷ, ngày sinh
        này sẽ chỉ có thể sống cuộc đời bình thường, bất kể siêng năng chăm chỉ
        như thế nào.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có Nhâm hay Quý Thuỷ ở Thiên Can thì Bính Hỏa là Dụng Thần Điều
        Hậu phải xuất hiện và không bị cản trở. Nếu đạt được điều này thì ngày
        sinh này sẽ tài năng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có thêm Giáp Mộc hay Ất Mộc ở Thiên Can thì phải có Thiên Can Canh
        Kim mạnh và có căn gốc. Nếu không thì ngày sinh này sẽ thiếu mục tiêu và
        định hướng trong cuộc sống, dù cho họ có học rộng hiểu biết đến đâu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có Mậu Thổ hay Kỷ Thổ xuất hiện ở Thiên Can, Canh Kim Thất Sát sẽ
        được sinh ra và củng cố sức mạnh bởi Thổ. Trong hoàn cảnh này thì ngày
        sinh này có thể tiêu xài hết toàn bộ tài lộc kiếm được, đặc biệt vì
        những gì phù phiếm và xa xỉ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Bính Hỏa hay Đinh Hỏa xuất hiện ở Thiên Can thì ngày sinh này sẽ
        là người cực kỳ tài năng. Tuy nhiên, bởi vì Hỏa làm suy yếu nhật can
        Giáp nên người này sẽ dễ bị bệnh tật và sức khoẻ kém cả đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Giáp Mộc và Ất Mộc lộ Thiên Can trong khi Mậu Thổ và Kỷ Thổ cũng
        lộ can và có căn gốc bên dưới thì ngày sinh này sẽ hưởng tài vận tốt
        trong đời. Nếu không có Quý Thuỷ thì người này sẽ có tính cách bướng
        bỉnh và cứng đầu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có Mậu Thổ và Kỷ Thổ hiện ở Thiên Can thì sức xuất hiện của Đinh
        Hỏa Thương Quan sẽ khiến cho Tài Khí của người này được hỗ trợ. Nếu có
        Đinh Hỏa xuất hiện, Quý Thủy không nên thiếu, nếu không ngày sinh này sẽ
        nghèo, ngay cả nếu họ được sinh ra trong gia đình gìau có.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có Canh Kim hoặc Tân Kim ở Thiên Can thì sẽ người này sẽ bị suy
        yếu và kiểm soát rất nhiều. Người này sẽ thể hiện tính cách cứng đầu,
        khiến cho họ thường xuyên mâu thuẫn với người khác. Họ cũng sẽ cảm thấy
        khó khăn trong việc tích luỹ tài lộc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có Nhâm Thuỷ hay Quý Thuỷ ở Thiên Can thì Mộc dễ bị trôi. Như vậy
        thì người này sẽ chịu nghèo, bất kể họ có tài năng bao nhiêu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có Giáp Mộc hay Ất Mộc ở Thiên Can thì ngày sinh này sẽ không
        thích có bạn bè trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có Canh Kim hay Tân Kim lộ ở Thiên Can trong khi có Thuỷ Ấn Tinh
        hiện diện thì ngày sinh này sẽ có địa vị trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có Bính Hỏa hay Đinh Hỏa ở Thiên Can, sự hiện diện của Đinh Hỏa
        Thương Quan và Thuỷ Khí Ấn Tinh sẽ khiến cho người này đạt được địa vị
        cao trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có Giáp Mộc hay Ất Mộc ở Thiên Can trong khi ngày sinh này chứa
        toàn Mộc nên rất cần Canh Kim ở Thiên Can. Nếu không thì người này sẽ
        cảm thấy cô đơn và thường xuyên nóng giận.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có Mậu Thổ hay Kỷ Thổ ở Thiên Can - nhiều Thổ sẽ làm cho người này
        bị suy yếu. Do đó, họ có thể hướng đến viêc theo đuổi Tài Lộc vốn sẽ làm
        suy yếu thêm Giáp Mộc. Tiếp đó, người này sẽ quá đam mê cầu tài đến mức
        mà họ sẽ quên hết tất cả mọi thứ và mọi người xung quanh.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có Bính Hỏa hay Đinh Hỏa lộ ở Thiên Can trong khi có ít nhất một
        trong 4 Mộ Khố là Thìn Tuất Sửu Mùi thì ngày sinh này sẽ giàu có trong
        đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có Nhâm Thuỷ và Quý Thuỷ lộ ở Thiên Can thì ngày sinh này sẽ có
        tính cách thất thường. Ngoài ra, khả năng đạt danh vọng và tài lộc bị
        giới hạn vì thiếu quyết đoán.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có Mậu Thổ hay Kỷ Thổ ở Thiên Can, sự hiện diện quá nhiều Tài Tinh
        Thổ sẽ giúp kiểm soát Thuỷ và giúp cho ngày sinh này giàu có, mặc dù họ
        không thể hưởng thụ được nhiều điều thú vị trong cuộc sống và cảm thấy
        bất mãn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có Canh Kim hay Tân Kim ở Thiên Can thì sự hiện diện của Thổ sẽ
        khiến cho Thất Sát Canh Kim được hỗ trợ nhiều hơn. Như vậy ngày sinh này
        sẽ gặp bất hoà trong gia đạo, vì liên quan đến tài lộc và tranh cãi.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Bính Hỏa và Đinh Hỏa xuất hiện ở Thiên Can, sự hiện diện của Bính
        Hỏa Thực Thần và Đinh Hỏa Thương Quan sẽ dẫn đến tạo ra Tài Tinh Thổ.
        Như vậy, ngày sinh này sẽ có tính cách hời hợt và phớt đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có cục Chính Quan / Thất Sát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Canh Kim xuất hiện nhiều thì cũng cần Đinh Hỏa, để có thể gia tăng
        vận may cho người Giáp Mộc cũng như rèn Canh Kim. Nếu Đinh Hỏa xuất hiện
        nhiều thì Canh Kim cũng cần.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu lúc nào có một Thiên Can Canh xuất hiện thì cũng cần ít nhất 1 Đinh
        Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Cần có Bính Hỏa hoặc Đinh Hỏa ở Thiên Can và Địa Chi để được tuổi thọ và
        may mắn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu như không có Bính Đinh Hỏa thì sẽ phải vất vả trong đời, mối quan hệ
        tình cảm với vợ con sẽ kém.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Đinh Hỏa nhưng không có Canh Kim thì cần phải tránh xuất hiện Quý
        Thuỷ Chính Ấn ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lý tưởng nếu như Bính Hỏa hay Đinh Hỏa hình thành Thương Quan hay Thực
        Thần Cục.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có cục Chính Ấn / Thiên Ấn:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Thuỷ sẽ không sinh cho Mộc trong mùa Xuân, nếu có Ấn Cục mà không có Mậu
        Thổ xuất hiện thì ngày sinh này sẽ nghèo hoặc gặp nhiều trắc trở trong
        đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngay cả nếu như Mậu Thổ xuất hiện, Bính Hỏa cũng phải xuất hiện ở Thiên
        Can. Nếu như có Mậu Thổ thì Giáp Mộc sẽ không cần đến Kỷ Thổ nếu có xuất
        hiện trừ khi có Bính Hỏa cũng đồng thời hiện ra.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu không có Mậu Thổ hay Bính Hoá hiện ra ở Thiên Can thì xấu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Cần có Bính Hỏa lộ Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu không có Bính Hỏa thì sẽ nghèo.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lý tưởng nếu có Mậu Thổ và Kỷ Thổ ở Địa Chi và Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu không có Bính Hỏa lộ ở Thiên Can thì ngày sinh này khôn được giúp
        đỡ.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có cục Chính Tài / Thiên Tài:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Cần tránh những Địa Chi sau xuất hiện nhiều: Thân, Dậu, Sửu và Tuất.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Bính Hỏa xuất hiện ở Thiên Can là tốt.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Canh Kim và Tân Kim không nên xuất hiện sẽ gây bất lợi.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có cục Thực Thần / Thương Quan:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Cần có Nhâm Thuỷ và Quý Thuỷ Ấn Tinh ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nhâm Thuỷ và Quý Thuỷ cần phải xuất hiện ở Thiên Can thì là lá số tốt.
        Nếu không thì ngày sinh này thiếu thông minh, cũng như dễ bị bệnh tật và
        sức khoẻ kém.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số xấu nếu như Nhâm và Quý Thuỷ không lộ ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Bính Hỏa và Quý Thuỷ là 2 dụng thần lý tưởng cho ngày sinh Giáp Mộc
        trong tháng Dần.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có Dần, Mão và Thìn tạo thành Tam Hội Mộc, thì Canh Kim Thất Sát
        sẽ là dụng thần cần thiết giúp kiểm soát Mộc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Thuỷ hình thành Ấn Cục thì sẽ cần có Thổ để kiểm soát Thuỷ. Nếu
        không có Ấn Cục thì không cần đến Thổ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Thổ hình thành Tài Cục và Tân Kim cũng như Canh Kim đều xuất hiện
        ở Thiên Can thì đây là cục trung bình. Sẽ bất lợi nếu có nhiều Thổ Tài
        Tinh sinh ra thêm Kim.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Ngay cả nếu không có Canh Kim, Đinh Hỏa vẫn có thể được dùng để làm
        suy yếu ngày sinh này đến một mức độ nhất định để giữ cân bằng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Canh Kim hoặc Đinh Hỏa đều không phải là Dụng Thần trong lá số này
        thì ngày sinh này vẫn sẽ ổn và phát triển tốt nếu như gặp được Mậu Thổ
        hay Kỷ Thổ.
      </p>
    </div>
  );
};

export const GiapThangMao = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Giáp Mộc trong tháng Mão
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh ngày Giáp Mộc trong tháng Mão xem như thuộc về Dương
        Nhẫn Cục.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b>• Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người Giáp Mộc sinh trong tháng Mão thì Canh Kim, Bính Hỏa, Đinh
        Hỏa, Mậu Thổ và Kỷ Thổ là dụng thần điều hậu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Cục Dương Nhẫn sẽ đem đến Sát Khí. Đây cũng là lý do vì sao Mậu Thổ và
        Kỷ Thổ là dụng thần để hạn chế Sát Khí.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu thiếu Canh Kim, thì có thể dùng Bính Hỏa và Đinh Hỏa để làm suy yếu
        ngày sinh này và giữ cho lá số cân bằng. Cũng sẽ cần làm suy yếu (chứ
        không phải kiểm soát) Sát Khí.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với ngày sinh này thì Mão được xem là vị trí Dương Nhẫn của Giáp.
        Hay nói khác hơn thì Mộc có gốc vững chắc, do đó thịnh vượng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Hỏa sinh ra Thổ, là Tài Tinh của Giáp Mộc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Kim cần có để tạo hình cho Giáp Mộc và giúp cho người Giáp Mộc hấp dẫn
        hơn. Nếu có Kim thì nam giới ngày Giáp Mộc sẽ có địa vị cao trong xã
        hội.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tương tự, Kim cần trường hợp nữ giới sinh ngày Giáp để có thể có quan hệ
        tình cảm tốt với chồng.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Luư Ý:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Trong lá số này chắc chắn hình thành cục Dương Nhẫn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Không nên có Quý Thuỷ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Canh Kim và Đinh Hỏa là Dụng Thần Điều Hậu lý tưởng cho Giáp Mộc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Để có thể tạo ra ngày sinh Giáp Mộc vững chắc, tốt thì Canh Kim nên là
        Dụng Thần chính.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu thiếu Canh Kim, có thể dùng Đinh Hỏa để làm suy yếu Giáp Mộc và
        đảm bảo cân bằng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Giáp Mộc chỉ có Canh Kim đi kèm mà không có Đinh Hỏa thì không tốt
        cho lá số này. Bởi vì nếu đi kèm Canh Kim mà không có Đinh Hỏa thì Canh
        Kim này bất lực.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Không nên có Bính Hỏa và Quý Thuỷ ở Thiên Can. Bởi vì Đinh Hỏa có tác
        dụng khắc chế và làm suy yếu Canh Kim, Quý Thuỷ lại đi khắc chế Đinh Hỏa
        và cũng làm suy yếu Canh Kim.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Canh Kim Thất Sát và Thuỷ Ấn Tinh không nên cùng lúc xuất hiện trong
        lá số. Nếu không thì lá số này sẽ tầm thường.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Không thể hình thành được cục Tòng Quan trong tháng này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu thiếu Canh Kim sẽ có nghĩa là người này thiếu sự thông thái và
        hạnh phúc trong đời bất kể họ có học vấn sâu rộng bao nhiêu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu thiếu Canh Kim thì có thể dùng Tân Kim để thay thế. Tuy vậy thì
        ngày sinh này sẽ thích những lợi ích ngắn hạn, nhanh chóng, thay vì
        những gì dài lâu và ổn định.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Hình thành nên cách cục Dương Nhẫn:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu cục Dương Nhẫn có kèm với Canh Kim và Đinh Hỏa xuất hiện thì ngày
        sinh này sẽ hưởng danh tiếng, quyền lực lớn. Người này sẽ là lãnh đạo có
        năng lực và được nhiều người ngưỡng mộ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Canh Kim và Đinh Hỏa đồng xuất hiện ở Thiên Can là lý tưởng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Quý Thuỷ Chính Ấn ở Thiên Can thì sẽ hạn chế các Dụng Thần. Lúc
        này người này sẽ có tính cách thô lỗ, tàn nhẫn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Có 2 tình huống gây bất lợi cho ngày sinh này:
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tình huống 1 là Bính Hỏa Thực Thần mạnh nhưng lại khắc chế Canh Kim ở
        Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tình huống 2 là cả Canh Kim và Thuỷ cùng xuất hiện một lúc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu xuất hiện 2 tình huống trên thì lá số này kém. Người này sẽ có
        tính cách tàn nhẫn và thô lỗ. Họ luôn muốn nhanh chóng lợi dụng tình
        bạn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Sẽ tốt nếu có Thổ Tài Tinh để sinh cho Canh Kim Thất Sát nhưng không
        nên quá nhiều.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Canh Kim nên đi kèm với Đinh Hỏa. Nếu được như vậy thì lá số Dương
        Nhẫn Cục này sẽ phát huy ảnh hưởng và quyền lực tối đa. Tuy nhiên cả
        Đinh Hỏa và Canh Kim cũng cần phải mạnh ngang nhau chứ không được thấy
        hành này mạnh hơn hành kia.
      </p>
    </div>
  );
};

export const GiapThangThin = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Giáp Mộc trong tháng Thìn
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với ngày sinh Giáp Mộc trong tháng Thìn thì hình thành nên cục thiên
        Tài nếu có Mậu xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Quý Thuỷ xuất hiện ở Thiên Can thì hình thành cục Chính Ấn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Ất Mộc xuất hiện ở Thiên Can thì tạo thành cục Lâm Quan nếu các
        Địa Chi tHỏa điều kiện.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tuy nhiên nếu Mậu Thổ, Ất Mộc hay Quý Thuỷ xuất hiện ở Thiên Can thì cần
        chọn một cục tương ứng với khí mạnh nhất trong lá số.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b>• Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng Thìn thì Dụng Thần Điều Hậu là Canh Kim,
        Đinh Hỏa và Nhâm Thuỷ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Canh Kim được xem là Dụng Thần, thì phải kèm với Đinh Hỏa, nếu không
        có Đinh Hỏa thì Canh Kim sẽ không bị kiểm soát và cũng khó rèn giũa tôi
        luyện được.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu thiếu Canh Kim thì Nhâm Thuỷ cũng có thể dùng thay.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Địa chi Thìn chứa Mậu Thổ như một trong những Tàng Can và là Tài Tinh
        của ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Do đó Thuỷ là Dụng Thần, bởi vì nó cung cấp độ ẩm cho ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Mộc vẫn còn rất vượng trong tháng Thìn. Do đó Kim cần để chạm trổ và
        biến Mộc thành hữu dụng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Kim xuất hiện trong lá số Bát Tự của nữ thì người này sẽ hưởng thịnh
        vượng và hôn nhân tốt trong đời. Họ có khả năng thu hút và kết hôn người
        chồng yêu thương và giàu có.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Luư Ý:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Mộc khí bắt đầu bước vào giai đoạn suy thoái ở tháng Thìn. Đó là lý do
        vì sao chỉ một Đinh Hỏa sẽ không phải dụng thần phù hợp cho ngày sinh
        này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Canh Kim và Nhâm Thuỷ là những dụng thần lý tưởng hơn cho ngày sinh
        này. Nếu như lá số xuất hiện cả Canh Kim và Nhâm Thuỷ ở Thiên Can thì
        ngày sinh này sẽ có nhiều thành công và sang quý.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu thiếu Canh Kim thì cũng có thể dùng Nhâm Thuỷ để đảm bảo người này
        sẽ có học thức. Nhâm Thuỷ xuất hiện ở Thiên Can cũng là để bảo vệ Canh
        Kim.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Không tốt nếu xuất hiện Đinh Hỏa ở Thiên Can. Đinh Hỏa quá mạnh sẽ
        khắc chế Canh Kim.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu hình thành Tài Cục thì sẽ lý tưởng nếu có thêm một Giáp Mộc Tỷ
        Kiên hoặc Ất Mộc Kiếp Tài xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Giáp Mộc hay Ất Mộc không thông căn với Địa Chi thì ngày sinh này
        sẽ có tính cách mềm mỏng hoặc rất thân thiện do đó dễ bị vợ lấn lướt.
        Mặt khác, nữ giới sinh ngày này sẽ có tính cách mạnh mẽ và do đó dễ đạt
        được quyền lực cao hơn nam giới sinh ngày này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Giáp Mộc và Ất Mộc đều thông căn như là Chính Khí thì ngày sinh
        này bất kể là nam hay nữ sẽ có gia đình không hạnh phúc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Đinh Hỏa không nên xuất hiện ở Thiên Can nếu như dụng thần Canh Kim và
        Nhâm Thuỷ có hiện ra. Điều này bởi vì Đinh Hỏa sẽ toả ra sự kiểm soát
        mạnh mẽ đối với Canh Kim và làm nó suy yếu tương ứng. Đinh Hỏa cũng hợp
        với Nhâm Thuỷ để tạo ra Mộc. Nói cách khác, Đinh Hỏa sẽ làm suy giảm đi
        tác dụng tốt của Canh Kim và Nhâm Thuỷ nếu nó xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Cách duy nhất để khắc chế sự ảnh hưởng của Đinh Hỏa là có thật nhiều
        Canh Kim hặc Tân Kim xuất hiện ở Thiên Can.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Cách Cục:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Thực Thần Cục:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Bính Hỏa xuất hiện và tạo thành Thực Thần Cục thì không nên xuất
        hiện 2 Bính Hỏa trong lá số vì sẽ làm hại Canh Kim vốn là dụng thần của
        Giáp Mộc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có 2 Bính Hỏa thì cần có Nhâm Thuỷ hoặc Quý Thuỷ xuất hiện ở Thiên
        Can. Lúc này Nhâm Thuỷ và Quý Thuỷ sẽ được xem là Dụng Thần.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Tài Cục:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Thổ vượng và Thuỷ Khí hoàn toàn vắng mặt trong lá số thì có thể hình
        thành Tòng Tài Cục.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu hình thành Tài Cục được thì người này sẽ có một cuộc đời phi thường.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tốt hơn nếu thấy Canh Kim xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Không nên gặp cả Nhâm Thuỷ và Quý Thuỷ là Ấn Tinh.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Tỷ Kiên / Kiếp Tài Cục:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Mộc mạnh và vượng trong tháng Thìn. Nếu cả Giáp Mộc và Ất Mộc đều xuất
        hiện ở Thiên Can, ngày sinh nữ giới sẽ có hôn nhân ngắn ngủi. Ngoài ra,
        nam giới sẽ bị vợ hiếp đáp.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lý tưởng nếu thấy Canh Kim ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Giáp Mộc và Ất Mộc xuất hiện ở Thiên Can và Địa Chi thì ngày sinh
        này sẽ có tính cách đáng hổ thẹn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Trừ khi Thìn, Dậu, Thân, Tuất và Sửu hình thành nên Kim Cục đầy đủ, sẽ
        không tốt để dùng Đinh Hỏa như là Dụng Thần cho ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Đinh Hỏa ở Địa Chi cũng dùng được.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Canh Kim cần thiết để lá số này thành công.
      </p>
    </div>
  );
};

export const GiapThangTy = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày Sinh Giáp Mộc trong tháng Tỵ
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với ngày sinh Giáp Mộc trong tháng Tỵ, cục Thực Thần sẽ hình thành
        nếu Bính Hỏa xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Canh Kim xuất hiện ở Thiên Can, hình thành cách cục Thất Sát.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Mậu Thổ xuất hiện ở Thiên Can, hình thành cách cục Thiên Tài.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tuy nhiên, nếu không có Bính Hỏa hay Canh Kim hoặc cả Mậu Thổ xuất hiện
        ở Thiên Can thì người này nên chọn một cách cục tuỳ theo khí mạnh nhất
        trong lá số Bát Tự tuỳ ý.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b>• Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu của người sinh trong tháng Tỵ là Quý Thuỷ, Đinh Hỏa
        và Canh Kim.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Trong số đó thì Quý Thuỷ được xem là Dụng Thần hàng đầu. Điều này bởi vì
        Quý Thuỷ cung cấp độ ẩm cho ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngoài ra, Canh Kim và Đinh Hỏa sẽ là Dụng Thần thứ cấp đối với ngày sinh
        này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Hỏa và Thổ mạnh trong tháng Tỵ. Hỏa nóng bức và tạo ra Thổ nóng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Điều này sẽ khiến cho ngày sinh khô và nóng hơn. Do đó cần có Thuỷ để
        cung cấp độ ẩm cho ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Bởi vì Thuỷ bị tù trong thời điểm này và được xem như là yếu nhất trong
        tháng Tỵ, Canh Kim được xem như Dụng Thần hỗ trợ để sinh cho Thuỷ. Nói
        cách khác, Thuỷ là dụng thần chính trong lá số cùng với Kim là dụng thần
        số hai.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dĩ nhiên, nếu người này sinh vào giờ mà Kim và Thuỷ đủ mạnh thì có thể
        dùng Mộc như là Dụng Thần. Rõ ràng người này sẽ cần có Hỏa và Thổ càng
        nhiều càng tốt.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Luư Ý:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Quý Thuỷ là dụng thần điều hậu chính cho ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu được thì dụng thần thứ hai là Canh Kim và Đinh Hỏa cũng nên xuất
        hiện.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Mậu Thổ và Kỷ Thổ không nên xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Mộc sẽ khô nóng trong tháng Tỵ. Do đó cần có Quý Thuỷ như là dụng thần
        không thể thiếu cho ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tuy nhiên, Thuỷ bị tù và do đó rất yếu trong tháng này. Nhưng Tỵ chứa
        Canh Kim ẩn tàng. Bởi vì Kim sinh Thuỷ, bất kỳ những gì khô hạn trong lá
        số này sẽ được khắc phụ nếu có Canh Kim ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Đó là lý do vì sao Hỏa chỉ hơi mạnh trong tháng này, vì nó bị Canh Kim
        hiện diện (nếu có lộ ở Thiên Can) bởi vì có căn gốc ở Tỵ Địa Chi.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Dưới những hoàn cảnh như vậy, Hỏa khí bị suy yếu dần dần. Điều này bởi
        vì Canh Kim sinh ra Quý Thuỷ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Trong lá số tốt nhất nên thấy Giáp Mộc đi kèm với Canh Kim, Canh Kim
        thì nên đi kèm với Đinh Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Điều này bởi vì Canh Kim được dùng để tạo ra Thuỷ, vốn là dụng thần
        quan trọng đối với can ngày này. Khi nào dùng Canh Kim thì cần có Đinh
        Hỏa, ít nhất là một lượng nhỏ, để có thể giữ cho Canh Kim trong vòng
        kiểm soát.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Đây là lý do vì sao Quý Thuỷ, Canh Kim và Đinh Hỏa là dụng thần quan
        trọng nhất đối với ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tuy nhiên, cần cân bằng giữa sức mạnh của Quý Thuỷ và Canh Kim bởi vì
        Bát Tự là chủ về sự cân bằng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi phải so sánh thì Quý Thuỷ lý tưởng hơn Nhâm Thuỷ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Nhâm Thuỷ thay thế cho Quý Thuỷ như là dụng thần căn bản của ngày
        sinh này, thì người này sẽ có thê sống cuộc đời tốt. Ngày sinh này sẽ có
        tri thức, hào phóng, rộng lượng và yêu hoà bình. Tuy nhiên, mức độ thành
        tích sẽ không thể vượt qua Quý Thuỷ Dụng Thần.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Mặc dù Bính Hỏa Thực Thần sinh ra Thổ Tài Tinh, nơi Bính Hỏa, Mậu Thổ
        và Kỷ Thổ xuất hiện ở Thiên Can, ngày sinh này chỉ hưởng được thành công
        giới hạn trong đời. Tuy vậy, người này vẫn có thể thịnh vượng, dù cho
        hơi khiêm tốn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu chỉ có Quý Thuỷ, Canh Kim và Đinh Hỏa đồng thời xuất hiện trên
        Thiên Can thì ngày sinh này có thể cực kỳ thịnh vượng, hạnh phúc và viên
        mãn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Hỏa rất mạnh trong tháng Tỵ. Đây là lý do vì sao nếu không có Canh Kim
        là gốc thì khả năng Quý Thuỷ có thể giúp như là Dụng Thần của ngày sinh
        này sẽ bị giới hạn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu xuất hiện Bính Hỏa và Mậu Thổ ở Thiên Can thì ngày sinh này thiếu
        mục đích và định hướng trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Quý Thuỷ là dụng thần số 1 cho ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu không có Canh Kim Thất Sát để sinh ra Quý Thuỷ, thì nước không đủ
        mạnh để hỗ trợ cho can ngày. Tuy nhiên, nếu hình thành cục Thất Sát bởi
        Canh Kim thì phải đi kèm với cả Đinh Hỏa để giữ mọi thứ trong tầm kiểm
        soát.
      </p>
    </div>
  );
};

export const GiapThangNgo = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Giáp Mộc trong tháng Ngọ
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với ngày sinh Giáp trong tháng Ngọ thì hình thành cục Thương Quan
        nếu Đinh Hỏa hiện ra ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Kỷ Thổ hiện ra trên Thiên Can thì hình thành cục Chính Tài.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Đinh Hỏa hay Kỷ Thổ hiện ra ở Thiên Can thì nên chọn một cục nào phù
        hợp nhất với ngũ hành mạnh nhất trong lá số.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b>• Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với sinh ra trong tháng Ngọ, Quý Thuỷ, Đinh Hỏa và Canh Kim là Dụng
        Thần Điều Hậu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Mộc hiển nhiên là khô và nóng trong tháng này do nhiệt mùa hè.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đây là lý do vì sao Quý Thuỷ được xem là dụng thần số 1 của ngày sinh
        này. Nếu thiếu Quý Thuỷ, Đinh Hỏa sẽ được xem là dụng thần xếp thứ hai.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngày sinh này sẽ thành công trong đời nếu trải qua đại vận Thuỷ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Mộc quá mạnh, Canh Kim được dùng để kiểm soát Mộc. Tương tự, nếu
        Canh Kim mạnh, Đinh Hỏa được dùng để kiểm soát nó. Canh Kim nếu không
        kèm Đinh Hỏa thì vô dụng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Hỏa rất mạnh trong tháng Ngọ và do đó vô tình xung với Kim và Thuỷ khí,
        cũng vốn là những dụng thần của ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Giáp Mộc yếu nhất trong tháng này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Kim và Thuỷ vượng thì có thể được xem là Điều Hậu cũng như là Phù Ức
        Dụng Thần để hỗ trợ cho Giáp Mộc yếu. Trong hoàn cảnh này, Thổ cũng sẽ
        ẩm và ướt để hiệu quả. Khi đầy đủ những hoàn cảnh trên, lá số này sẽ
        giúp tạo nên con người giàu có, quyền lực và cao quý.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Hỏa vượng thì Mộc không nên được dùng như là Dụng Thần. Điều này để
        tránh cho Mộc sinh thêm và tăng cường sức mạnh cho Hỏa - vốn dĩ đã mạnh.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Trong mọi hoàn cảnh, thì bất lợi nếu Thổ quá mạnh khắc chế Thuỷ. Điều
        này bởi vì Thổ - Tài Tinh - vượng thì ngày sinh này sẽ bị tổn hại năng
        nề.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Thuỷ do đó là Dụng Thần phù hợp cho ngày sinh này, trong khi Canh Kim
        đóng vai trò như là Dụng Thần thứ hai.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu sinh trong giờ mà Kim và Thuỷ mạnh thì người này có thể chọn Mộc như
        là Dụng Thần. Tuy nhiên, cần tránh Hỏa và Thổ khí.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Luư Ý:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Quý Thuỷ là dụng thần, thì phải đi kèm với Canh Kim như là dụng
        thần thứ hai.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Canh Kim vượng hay nhiều về số lượng thì Đinh Hỏa phải có mặt để
        kiểm soát Canh Kim.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Hình thành Mộc Cục hay Hỏa Cục thì chỉ có Đinh Hỏa mới có thể dùng
        (khi không có Quý Thuỷ) đem lại kết quả tốt cho ngày sinh này. Lúc này,
        người này sẽ sở hữu sự rộng lượng, có văn hoá và tính cách dịu dàng. Đây
        được xem là cách cục đăc biệt, Tòng Tử Tôn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Lá số này sẽ có cục xuất sắc, khi cả Quý Thuỷ và Canh Kim đều xuất
        hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Ngày sinh này sẽ bị tù hay cực yếu nếu như Đinh Hỏa tạo thành Thương
        Quan Cục khi bước vào Đại Vận Hỏa. Trong hoàn cảnh đó thì sẽ xảy ra
        những trở ngại khó lường xảy ra trong đời người này. Vận may của họ chỉ
        khá lên khi bước vào đại vận Mộc hay Thuỷ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Canh Kim xuất hiện ở Thiên Can, ngày sinh này sẽ may mắn nếu bước
        vào đại vận Thuỷ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu cả Nhâm Thuỷ hoặc Quý Thuỷ đều không xuất hiện thì ngay cả nếu
        người này bước vào đại vận Kim thì cũng sẽ gặp nhiều trở ngại nghiêm
        trọng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có đầy đủ Canh Kim và Quý Thuỷ ở Thiên Can thì người này sẽ có đời
        sống sung túc. Gìau có và khoẻ mạnh.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu cả Nhâm Thuỷ và Quý Thuỷ cùng xuất hiện ở Thiên Can thì ngày sinh
        này về sau sẽ thành công - mặc dù thời trẻ có thể hơi khó khăn.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Canh Kim quá nhiều thì ngày sinh này có thể mất hết toàn bộ tài
        lộc mặc dù có khởi đầu tốt trong cuộc sống.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu có Cục Thực Thương:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Có Bính Hỏa và Đinh Hỏa tạo thành Hỏa Cục, thì Đinh Hỏa sẽ khiến cho
        người này mặc dù được sự tôn trọng và ngưỡng mộ nhưng bên trong lòng thì
        họ dễ cảm thấy bất mãn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Thuỷ và Mộc với lượng vừa phải thì Thổ Tài Tinh có thể được dùng
        để giữ cân bằng giữa các hành.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Thuỷ vắng mặt hoàn toàn trong lá số thì người này dễ nghèo khó. Sẽ
        mất định hướng trong đời.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu có Cục Tài Tinh:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Mậu Thổ Thiên Tài nên có mặt, nếu không thì khó hình thành nên cục Tòng
        Tài. Nếu hình thành cục thành công thì có nhiều khả năng to lớn chờ đợi.
        Tuy nhiên, nếu không hình thành được cục Tài Tinh thì biêu thị cuộc sống
        gặp vất vả về tài chính và vợ hay chồng quá áp đặt.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lý tưởng nhất cho ngày sinh này là nếu cả Nhâm Thuỷ và Quý Thuỷ hiện
        diện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu không có cả Nhâm Thuỷ hoặc Quý Thuỷ ở Thiên Can thì ngay cả có nhiều
        Thổ Tài Tinh cũng chỉ khiến cho người này nghèo khổ cả đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu tạo thành Hỏa Cục, thì Đinh Hỏa Thương Quan có thể quá nóng. Nếu
        không có Quý Thuỷ Ấn Tinh thì ngày sinh này sẽ không thể hưởng thụ cuộc
        sống.
      </p>
    </div>
  );
};

export const GiapThangMui = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Giáp Mộc trong tháng Mùi
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh ngày Giáp Mộc trong tháng Mùi thì hình thành cục
        Chính Tài khi Kỷ Thổ hiện lên trên Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Đinh Hỏa xuất hiện ở Thiên Can, tạo thành cục Thương Quan.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Ất Mộc xuất hiện ở Thiên Can, tạo thành cục Dương Nhẫn nếu thỏa các
        điều kiện và được Địa Chi hỗ trợ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu không có Đinh Hỏa, Ất Mộc, Kỷ Thổ xuất hiện ở Thiên Can thì nên chọn
        hành nào vượng nhất trong lá số tuỳ ý.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b>• Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu sinh trong tháng Mùi thì Quý Thuỷ, Canh Kim và Đinh Hỏa là dụng thần
        điều hậu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng thần số một của người sinh nửa đầu tháng này là Quý Thuỷ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng thần của người sinh vào nửa sau tháng này là Canh Kim và Đinh Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Mùi Thổ đóng vai trò Mộ Khố của Mộc Khí và cũng chứa Kỷ Thổ Chính Tài ở
        tàng can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Do đó, ngày sinh này yếu mặc dù không đến nỗi quá yếu. Điều này bởi vì
        Kỷ Thổ ở tàng can ở Mùi nếu khi lộ ở Thiên Can có thể kết hợp với Giáp
        Mộc tạo thành Thổ trong tháng này.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngoài ra, Hỏa khí dần dần bị suy giảm trong tháng này, tháng cuối của
        mùa Hè.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Thuỷ, dù ít hay vừa phải, có thể được dùng để sinh trợ cho Mộc ngày
        sinh. Nếu đúng vậy thì ngày sinh này sẽ sở hữu năng lực quản trị tài
        chính vững chắc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tương tự, Kim, dù ít hay vừa phải, có thể được dùng để sinh ra Thuỷ. Và
        Thuỷ lần lượt sẽ đóng vai trò Ấn trong việc sinh trợ cho ngày sinh Giáp
        Mộc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Quý Thuỷ, Canh Kim và Đinh Hỏa là dụng thần lý tưởng cho ngày sinh
        Giáp Mộc này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có cả Canh Kim và Đinh Hỏa cùng ở Thiên Can và Địa Chi thì ngày
        sinh này sẽ hưởng danh tiếng và quý trong đời. Nếu Đinh Hỏa ẩn còn Canh
        Kim lộ thì ngày sinh này sẽ chỉ trung bình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu không xuất hiện Đinh Hỏa thì sẽ xấu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Không cần phải thấy Quý Thuỷ lộ Thiên Can thì mới được xem là Dụng
        Thần.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nơi nào Kỷ Thổ Chính Tài lộ ở Thiên Can, thì không nên đứng bên cạnh
        ngày sinh, vì Giáp Mộc và Kỷ Thổ hợp tạo thành Thổ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Để tạo thành Tòng Tài Cục, thì sẽ phải cần nhiều Thuỷ và Thổ. Khi cục
        này có thể hình thành thì người này sẽ sống cuộc đời phi thường.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu như không có Thuỷ Ấn Tinh thì ngày sinh này sẽ chịu nghèo khổ.
        Thật sự thì người này cũng sẽ thiếu sự can đảm và cá tính, trong khi nếu
        là lá số nam giới cũng sẽ bị vợ lấn át. Nói cách khác, ngày sinh này sẽ
        thiếu mục tiêu sống trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu như Tài Cục hình thành, cùng lúc với Thiên Can của Tháng và Giờ
        tranh hợp với ngày sinh (Kỷ Thổ cùng xuất hiện ở Thiên Can của Tháng và
        Giờ tranh nhau để hợp với Giáp Mộc), nam giới sinh ngày này sẽ có cuộc
        sống không mục đích, lang thang. Tuy nhiên, nữ giới sinh ngày này cũng
        sẽ mang tiếng dễ dãi. Kết quả sẽ là bất lợi.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tốt nhất là có địa chi Thìn trong lá số. Nếu được như vậy thì người
        này sẽ hưởng thịnh vượng và tài lộc trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Ngược lại, nếu không có Thìn trong lá số, ngày sinh này chỉ sống một
        đời trung bình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu có Bính Hỏa, Đinh Hỏa và Kỷ Thổ cùng xuất hiện:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đây sẽ được xem là Hỏa Cục hoàn hảo, có thể đọc là Tòng Tử Tôn hoặc Tòng
        Tài Cục - ngày sinh này sẽ hưởng thịnh vượng rất lớn trong đời, mặc dù
        người này có thể phải trải qua những trở ngại lớn trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có 2 Giáp Mộc và 2 Kỷ Thổ xuất hiện thì người này giàu có hơn nữa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Mộc và Thổ xuất hiện ở đúng vị trí trong lá số Bát Tự; hoặc khi
        Thìn và Quý Thuỷ cũng xuất hiện thì lá số tốt.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu đã hình thành cục Tòng Tử thì không nên bước vào đại vận Thuỷ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu chỉ có cách cục bình thường thì cần có Thìn (ám chỉ Quý Thuỷ). Nếu
        không có Thìn Địa Chi hay Quý thuỷ là dụng thần hiện diện, ngày sinh này
        sẽ chỉ bình bình, tầm thường.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Kỷ Thổ xuất hiện ở cả Thiên Can Tháng và Giờ thì sẽ tranh nhau để
        hợp với can ngày. Trong tình huống này thì bất kể đây là ngày sinh nam
        hay nữ thì ngày sinh này cũng sẽ gặp rắc rối về hôn nhân mặc dù có thể
        có được ít danh tiếng và thành tích trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi hình thành cục Tòng Tài, người này sẽ sau mọi khó khăn có thể đạt
        được thành tích tài chính phi thường.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Đối với mọi cách cục khác, nếu không có Thuỷ thì bất kỳ cách cục nào
        cũng chỉ là hạng bình thường.
      </p>
    </div>
  );
};

export const GiapThangThan = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Giáp Mộc trong tháng Thân
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với ngày sinh Giáp Mộc trong tháng Thân, thì hình thành cách cục
        Thất Sát khi Canh Kim lộ Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Mậu Thổ hiện diện ở Thiên Can thì hình thành Thiên Tài Cục.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Nhâm Thuỷ lộ ở Thiên Can biểu thị cục Thiên Ấn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu như không có Canh Kim, Nhâm Thuỷ hay Mậu Thỏ xuất hiện ở Thiên Can
        thì có thể chọn cách cục theo ngũ hành mạnh nhất trong lá số Bát Tự.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b>• Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh tháng Thân thì Canh Kim, Đinh Hỏa và Nhâm Thuỷ là
        dụng thần điều hậu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nhâm Thuỷ sẽ phù hợp là Dụng Thần cần phải sử dụng nếu không có Đinh
        Hỏa. Đinh Hỏa được xem là lựa chọn tốt vì giúp kiểm soát Thất Sát Canh
        Kim.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Canh Kim rất mạnh trong tháng này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Kim và Thuỷ cùng xuất hiện trong lá số, Mộc có thể ẩm ướt.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Do đó, Hỏa rất quan trọng trong việc giữ ấm cho ngày sinh này. Nếu Kim
        xuất hiện nhiều thì nhiều Mộc Tỷ Kiếp hơn rất cần để có thể bảo vệ và
        giữ cho ngày sinh này không bị tận diệt.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Mộc quá lạnh, thì cần Thổ nóng hay Thổ ấm, để có thể kiểm soát và
        ngăn cản Thuỷ quá nhiều. Chỉ có khi đó thì mới có thể đóng vai trò như
        một trong những Dụng Thần.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Sẽ tốt nhất nếu có cả hai Đinh Hỏa và Canh Kim lộ Thiên Can. Người này
        sẽ đạt thành công lớn trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Trong lá số này nếu dùng Đinh Hỏa và Canh Kim, không cần Nhâm Thuỷ hay
        Quý thuỷ lộ ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Canh Kim vượng nhất trong tháng Thân. Tuy nhiên, chỉ với sự giúp đỡ
        của Canh Kim bị kiểm soát thì ngày sinh này mới đạt cân bằng tốt.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Do đó, khi nào Canh Kim được dùng như Dụng Thần, luôn cần kèm với Đinh
        Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Canh Kim lộ mà không có Đinh Hỏa, ngày này sẽ vất vả không gặp may
        mắn trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Đinh Hỏa lộ nhưng Canh Kim ẩn tàng, thì thành tích của người này
        sẽ tốt nhưng không quá xuất sắc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có 3 Canh Kim nhưng thiếu Đinh Hỏa, ngày sinh này sẽ bị tàn tật,
        sức khoẻ kém và kể cả cô đơn trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Sẽ cực kỳ có lợi nếu Dần, Mão và Thìn hiện diện. nếu không thì ngày
        sinh này sẽ không lấy được lợi ích toàn diện từ sự hiện diện của Đinh
        Hỏa và Canh Kim như là dụng thần.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có Canh Kim xuất hiện ở Thiên Can, không có Đinh Hỏa, ngày sinh
        này sẽ vất vả để mưu sinh trong đời.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu có Cục Tài Tinh Thiên Tài / Chính Tài:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Cần có Canh Kim xuất hiện, nếu như nhiều Mậu Thổ và Kỷ Thổ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu không, Thổ sẽ khắc Nhâm Thuỷ và Quý Thuỷ Ấn Tinh, và điều này sẽ
        biểu thị tài lộc không tốt cho người này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Canh Kim và Đinh Hỏa cần phải hiện diện như là Dụng Thần, nếu không thì
        ngày sinh này sẽ không được hưởng lợi từ bất kỳ cách cục nào.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu thiếu Nhâm Thuỷ hay Quý Thuỷ Ấn Tinh thì ngày sinh này sẽ khó có thể
        liên tục tiến bộ trong đời. Họ sẽ nghèo hay gặp khó khăn về dòng tiền.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Không có Đinh Hỏa, thì lá số này sẽ không thể tốt đẹp.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có Ấn Cục hình thành, Đinh Hỏa Dụng Thần sẽ bị khắc chế. Trong
        hoàn cảnh đó, Đinh Hỏa sẽ bị xem là vô dụng. Trong trường hợp này sẽ
        biểu thị cuộc sống nhiều mâu thuẫn, giữa thành công, tình cảm và sức
        khoẻ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi xuất hiện 2 Đinh Hỏa Thiên Can, nếu không có Hợi hay Dậu xuất hiện
        thì ngày sinh này sẽ rất dễ thành công trong đời nhờ nhiều quý nhân.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu hình thành Tài Cục và Thổ khắc chế Thuỷ, người này sẽ khó có được
        bình yên trong lòng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Đinh Hỏa sẽ kiểm soát Kim tốt hơn Bính Hỏa.
      </p>
    </div>
  );
};

export const GiapThangDau = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Giáp Mộc trong tháng Dậu
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng Dậu được xem là hình thành Chính Quan Cục
        nếu Tân Kim xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngay cả nếu như Tân Kim không xuất hiện ở Thiên Can, vẫn có thể xem như
        hình thành Cục Chính Quan.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với sinh trong tháng Dậu thì Canh Kim, Đinh Hỏa và Bính Hỏa là Dụng
        Thần Điều Hậu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đinh Hỏa có thể dùng để khắc chế Thất Sát và kiểm soát nó.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngoài ra, Bính Hỏa được xem như là Dụng Thần Điều Hậu.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Kim sẽ mạnh nhất trong tháng này. Với Tân Kim vượng nhất trong tháng này
        với tư cách là Chính Quan, thì Mộc khí sẽ yếu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đây là lý do mà thêm Mộc (Tỷ Kiếp) sẽ cần thiết để có thể hỗ trợ cho
        ngày sinh này. Nếu có thể được thì cả ngày sinh này và Tân Kim đều cần
        phải mạnh. Trong hoàn cảnh này, thì ngày sinh này sẽ được hưởng danh
        tiếng, thành công tài chính và hạnh phúc trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Thuỷ nếu có một lượng vừa đủ, sẽ cần thiết như là Dụng Thần Điều Hậu.
        Điều này đặc biệt đúng nếu Hỏa mạnh trong giờ sinh. Điều này giúp ngăn
        Giáp Mộc không bị khắc và suy yếu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tương tự, Hỏa và Thổ sẽ có lợi thế trong việc sinh cho ngày sinh này nếu
        Kim và Thuỷ Khí vượng trong giờ sinh. Điều này không chỉ cho phép Mộc
        được sinh ra và củng cố, mà Kim cũng sẽ được kiểm soát. Nói cách khác,
        sự hiện diện của Hỏa và Thổ sẽ giúp trung hoà các ảnh hưởng xấu do quá
        nhiều Kim và Thuỷ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Đinh Hỏa và Bính Hỏa là Dụng Thần Điều Hậu quan trọng nhất của ngày
        sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu hình thành Kim Cục thì Đinh Hỏa sẽ đóng vai trò là Dụng Thần Điều
        Hậu chính. Trong các trường hợp khác thì là Bính Hỏa.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Không tốt cho ngày sinh này nếu gặp Quý Thuỷ và Nhâm Thuỷ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Địa Chi hình thành Hỏa Cục, sẽ tốt hơn để ngày sinh này đạt thành
        công trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu cả Canh Kim và Tân Kim đều xuất hiện ở Thiên Can - ngày sinh này
        sẽ bị bệnh bí hiểm cho đến độ tuổi trung niên.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Cần có Mậu Thổ hay Kỷ Thổ Tài Tinh hiện diện ở Thiên Can cùng với Đinh
        Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Địa Chi hình thành đầy đủ Kim Cục, ngày sinh này có thể bị bệnh
        kéo dài và sức khoẻ kém.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tương tự nếu Bính Hỏa và Đinh Hỏa ở Thiên Can nhưng không có ở Địa Chi
        - ngày sinh này có thể bị sức khoẻ kém và bệnh tật khi về già.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Địa Chi tạo thành Mộc Cục hoàn hảo, nếu Tỷ Kiếp Giáp Mộc xuất hiện
        ở Thiên Can thì chỉ có Canh Kim và Đinh Hỏa cần thiết như là Dụng Thần
        cho ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Dụng Thần Điều Hậu của ngày sinh Giáp Mộc sinh trong tháng Thân hay
        Dậu về cơ bản là giống nhau.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu có Cục Thực Thương:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu xuất hiện Bính Hỏa ở Thiên Can nhưng thiếu Quý Thuỷ thì ngày sinh
        này vẫn có thể đạt thịnh vượng trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Địa Chi hình thành Hỏa Cục đầy đủ và với Thổ ở Thiên Can thì ngày
        sinh này sẽ rất giàu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Mậu Thổ và Kỷ Thổ Tài Tinh cùng lộ ở Thiên Can là tốt.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Quý Thuỷ hiện diện ở Thiên Can và Địa chi thì người này sẽ nghèo.
        Bởi vì điều này sẽ ảnh hưởng tiêu cực về tài vận của người này.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu có Cục Thất Sát Chính Quan:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Địa Chi hình thành Kim Cục đầy đủ, biểu thị ngày sinh này sẽ có bệnh
        tật và sức khoẻ xấu trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Cùng lúc thì Bính Hỏa hay Đinh Hỏa hiện diện, thì ngày sinh này sẽ dễ bị
        bệnh về mắt khi lớn tuổi.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tốt nếu như cùng có Bính Hỏa và Đinh Hỏa hiện lên ở Thiên Can.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu không có Hỏa, thì ngày sinh này sẽ hướng đến cuộc sống ẩn dật, cô
        độc.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt: </b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Quý Thuỷ Chính Ấn không nên được sử dụng hay áp dụng như là Dụng Thần
        cho ngày sinh này. Điều này bởi vì Quý Thuỷ sẽ dập tắt Hỏa Khí.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Tỷ Kiếp hiện diện ở Thiên Can thì Canh Kim sẽ là dụng thần chính
        của ngày sinh này. Chỉ với Canh kim thì Mộc mới hữu dụng.
      </p>
    </div>
  );
};

export const GiapThangTuat = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Giáp Mộc trong tháng Tuất
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với ngày sinh Giáp Mộc trong tháng Tuất, Thiên Tài Cục sẽ hình thành
        nếu Mậu Thổ hiện lên trên Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Tân Kim hiện lên trên Thiên Can thì hình thành Chính Quan Cục.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Đinh Hỏa hiện diện ở Thiên Can thì hình thành cục Thương Quan.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tuy nhiên, nếu Tân Kim, Đinh Hỏa hay Mậu Thổ đều không xuất hiện ở Thiên
        Can thì cần chọn cục nào tuỳ thuộc vào ngũ hành nào vượng nhất tuỳ chọn.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với sinh trong tháng Tuất thì Canh Kim, Giáp Mộc, Đinh Hỏa, Nhâm
        Thuỷ và Quý Thuỷ là Dụng Thần Điều Hậu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Thổ nhiều thì Giáp Mộc cần để kiểm soát.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Mộc nhiều thì cần Kim để kiểm soát.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Trong các tình huống khác, Đinh Hỏa, Nhâm Thuỷ và Quý Thuỷ đóng vai trò
        như là Dung Thần cho ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Thổ khô trong tháng Tuất cuối thu. Đó là lý do Thuỷ là Dụng Thần không
        thể thiếu trong việc cung cấp độ ẩm cho ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Với Thuỷ Khí hiện diện, Giáp Mộc vốn khô cần được nuôi dưỡng.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Mộc không phải là dụng thần cần thiết cho ngày sinh này nhất là nếu sinh
        ra trong giờ có Hỏa Khí mạnh và thiếu Thuỷ khí. Điều này bởi vì Mộc sinh
        và củng cố thêm Hỏa.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Thổ khô trong tháng Tuất.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Phần nhiều dựa vào sự hiện diện hay thiếu Canh Kim như là Dụng Thần
        cần thiết cho ngày sinh này. Canh Kim biến Mộc trong mùa này trở thành
        ngũ hành cần thiết.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Canh Kim được sử dụng, thì luôn phải kèm với Đinh Hỏa.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Ngoài ra, Thuỷ không được thiếu trong lá số này. Bời vì tháng này khá
        khô.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có nhiều Mậu Thổ và Kỷ Thổ Tài Tinh và thiếu Thuỷ Khí thì sẽ có
        thể hình thành cục Tòng Tài.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Ngày sinh này gặp Bính Hỏa thì không tốt. Điều này bởi vì Bính Hỏa có
        năng lực để khắc chế Canh Kim. Nếu đúng như vậy thì ngày sinh này sẽ
        thiếu sự chân thành và có xu hướng đổ lỗi cho người khác cho những thất
        bại trong cuộc đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Thổ hình thành cục Hỏa hoàn toàn, thì Thổ Khí sẽ dồi giàu. Trong
        hoàn cảnh này thì ngay cả với sự hiện diện của Canh Kim sẽ không giúp
        ích gì cho người này. Tiếp theo, ngày sinh này sẽ chịu cô đơn và nghèo
        khổ trong đời.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu là cục Tài Tinh:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số này nếu có Nhâm Thuỷ, Đinh Hỏa và Quý Thuỷ sẽ rất tốt.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu chỉ có Thuỷ Ấn Tinh hiện diện, ngày sinh này sẽ được hưởng tài lộc
        dù chỉ hơi khiêm tốn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Tỷ Kiếp ở Thiên Can, Canh Kim sẽ là dụng thần chính của ngày sinh
        này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu không có Canh Kim thì ngày sinh này sẽ chỉ trung bình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Sẽ xấu nếu không có Canh Kim ở Thiên Can và cũng không xuất hiện Tỷ
        Kiếp.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu là cục Thất Sát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đinh Hỏa là dụng thần chính của ngày sinh này. Sao này giúp kiểm soát
        Thất Sát của người này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tốt nếu như Đinh Hỏa xuất hiện ở cả Thiên Can và Địa Chi.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Xấu nếu như có Quý Thuỷ xuất hiện bên cạnh Đinh Hỏa.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu là cục Thực Thương:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu dùng Bính Hỏa mà không dùng Đinh Hỏa thì người này thường có tính
        cách thiếu chân thành.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tốt nếu như Mậu Thổ và Kỷ Thổ Tài Tinh cũng xuất hiện và mạnh.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu không có Canh Kim hay Tân Kim ở Thiên Can thì ngày sinh này sẽ nghèo
        và khó khăn trong tiến bộ trong đời - hơn nữa nếu như có Tỷ Kiếp.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu hình thành Tài Cục - trong khi Tỷ Kiếp cũng xuất hiện ở Thiên Can
        thì ngày sinh này sẽ thuộc về người trung bình với thành tích tầm
        thường. Đặc biệt đúng nếu như thiếu Canh Kim.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Sẽ tốt hơn nếu có Đinh Hỏa Thương Quan sinh Thổ Tài Tinh thay vì Bính
        Hỏa Thực Thần.
      </p>
    </div>
  );
};

export const GiapThangHoi = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Giáp Mộc trong tháng Hợi
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với ngày sinh Giáp Mộc trong tháng Hợi thì hình thành cục Thiên Ấn
        khi Nhâm Thuỷ xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngay cả nếu như Nhâm Thuỷ không xuất hiện ở Thiên Can thì vận có thể xem
        như hình thành Cục Thiên Ấn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Giáp Mộc hiện lên ở Thiên Can thì có thể hình thành cục Lâm Quan nếu
        đủ các điều kiện và được Địa Chi hỗ trợ.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng Hợi thì Dụng Thần Điều Hậu là Canh Kim,
        Đinh Hỏa, Bính Hỏa và Mậu Thổ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Canh Kim được sử dụng là Dụng Thần, Đinh Hỏa nên đi kèm để giúp kiểm
        soát Canh Kim.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Bính Hỏa, được sử dụng như là Dụng Thần Điều Hậu cho ngày sinh này. Nếu
        Thuỷ vượng thì Mậu Thổ có thể được dùng để kiểm soát nó.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Xét về đây là đầu mùa Đông nên khí rất lạnh, khiến cho Mộc rét cóng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Vì lẽ đó, Hỏa là dụng thần không thể thiếu đối với ngày sinh này. Bởi vì
        Hỏa cung cấp hơi ấm và sinh ra Thổ vốn cũng là một dụng thần khác của
        ngày sinh này. Nếu thiếu đi 2 hành này thì lá số không được xem là tốt.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Giáp Mộc thông căn trong tháng này. Nhưng ngay cả như vậy thì nếu không
        có Thổ Tài Tinh thì ngày sinh này vẫn liên tục gặp nhiều bất an trong
        đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Hỏa và Thổ là những dụng thần điều hậu tốt nhất cho ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Hỏa là dụng thần số một cho ngày sinh này nếu họ sinh vào giờ có Kim
        mạnh, bởi vì nó giúp sản sinh Thổ - vỗn cũng là một dụng thần khác.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngày sinh này cần Thổ ấm nóng nhiều hơn để có thể kiểm soát Thuỷ không
        quá tràn lan.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Canh Kim là dụng thần rất quan trọng cho ngày sinh Giáp Mộc. Nếu không
        có Canh Kim thì Giáp Mộc không thể hiện được hết tài năng. Nhưng Canh
        Kim luôn phải đi kèm với Đinh Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Bính Hỏa cũng cần để cung cấp ấm áp cho lá số, cũng như là dụng thần
        điều hậu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Ít nhất một Bính Hỏa được cần như là Dụng Thần Điều Hậu, để cung cấp
        hơi ấm và xua tan đi lạnh giá trong năm.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Sẽ rất tốt cho ngày sinh này, khi mà Canh Kim và Đinh Hỏa cùng xuất
        hiện ở Thiên Can và thông căn ở Địa Chi.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Canh Kim xuất hiện ở Thiên Can nhưng Đinh Hỏa ẩn tàng thì ngày
        sinh này sẽ thiếu những cơ hội lớn nhất trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Đinh Hỏa và Canh Kim đều xuất hiện ở Can, thì ngày sinh này sẽ
        hưởng danh tiếng, địa vị, tài lộc và quyền lực trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu thiếu Canh Kim, thì ngày sinh này có thể nghèo khó bởi vì bi quan,
        thiếu can đảm và quyết tâm.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tương tự, nếu thiếu Đinh Hỏa, ngày sinh này sẽ vất vả trong việc kiếm
        và giữ tiền, bất kể người này tài năng hay giỏi giang đến đâu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu thiếu Đinh Hỏa thì cũng có thể dùng Bính Hỏa như một dụng thần
        thay thế. Tuy nhiên, điều này chỉ có nghĩa là lá số này chỉ đạt được
        phân nửa những gì tiềm năng có thể.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tân Kim sẽ không thể thay thế Canh Kim nếu như thiếu Canh Kim.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Sẽ tốt hơn cho lá số này nếu Tỷ Kiếp xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có một Đinh Hỏa và một Canh Kim xuất hiện ở Thiên Can thì lá số
        này hưởng tài lộc. Nếu xuất hiện Canh Kim, Mậu Thổ và Đinh Hỏa cùng xuất
        hiện ở Thiên Can thì lá số này sẽ rất giàu có.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có một Canh Kim và một Mậu Thổ xuất hiện thì ngày sinh này sẽ
        hưởng và tuổi thọ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Không nên thiếu Mậu Thổ Thiên Tài nếu không thì sẽ khó có phú quý.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu xuất hiện Nhâm Thuỷ ở Thiên Can và Địa Chi, thì phải dùng Mậu Thổ
        Thiên Tài để giữ kiểm soát Nhâm Thuỷ.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số là cục Thiên Tài Chính Tài:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu chỉ có Mậu Thổ xuất hiện trong Canh Kim không xuất hiện ở Thiên Can,
        thì Tỷ Kiếp cũng không nên xuất hiện ở Thiên Can. Nếu không thì ngày
        sinh này sẽ chỉ trung bình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Canh Kim cũng thông căn vững chắc, để có thể bảo vệ Mậu Thổ Thiên Tài.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tỷ Kiếp không nên lộ ở Thiên Can, nếu không sẽ không tốt.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Mậu Thổ Thiên Tài được xem là Dụng Thần chung cho người sinh ngày Giáp
        Mộc trong tháng này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Kỷ Thổ thiếu sức mạnh để kiểm soát hành Thuỷ Ấn Tinh.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Không nên chọn Thuỷ Ấn Tinh như là dụng thần cho người sinh trong
        tháng này.
      </p>
    </div>
  );
};

export const GiapThangTi = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Giáp Mộc trong tháng Tý
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người Giáp Mộc sinh trong tháng Tý là có Chính Ấn hình thành nếu Quý
        Thuỷ có thể xuất hiện ở Thiên Can. Nếu không có Quý Thuỷ xuất hiện ở
        Thiên Can thì vẫn có thể xem như là Chính Ấn Cục.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với tháng Tý, thì Canh Kim, Đinh Hỏa và Bính Hỏa là Dụng Thần Điều
        Hậu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Bởi vì Mộc rét giá trong tháng này nên Đinh Hỏa là dụng thần chính trong
        khi Canh Kim đóng vai trò là dụng thần thứ hai.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngoài ra, Bính Hỏa cũng đóng vai trò là dụng thần hỗ trợ. Sẽ tốt hơn nếu
        có Tỵ và Dần địa chi xuất hiện thì lá số này có cách cục tốt.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Thuỷ vốn lạnh trong tháng Tý. Vì lẽ đó, Mộc yếu do quá lạnh.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi không có Thổ ấm áp hay nóng để kiểm soát nó thì Thuỷ Khí sẽ dâng
        tràn. Quá nhiều Thuỷ sẽ làm Mộc trôi dạt.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Do đó, Thổ Tài Tinh là dụng thần rất quan trọng cho ngày sinh này vì nó
        giúp kiểm soát Thuỷ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nhưng chỉ khi Hỏa có thể cung cấp hơi ấm cho ngày sinh này thì Thổ mới
        trở nên hữu dụng. Hỏa cũng sẽ làm ấm Thuỷ và giúp cho Thuỷ này có thể
        nuôi dưỡng ngày sinh Giáp Mộc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngày sinh này rất cần tránh gặp nhiều Kim mà không có Hỏa. Điều này bởi
        vì Kim sinh Thuỷ mà khí lạnh vốn dĩ đã lạnh trong tháng này. Kim cũng sẽ
        khắc Mộc trong khi làm Thổ Tài Tinh suy yếu.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Đinh Hỏa được xem là Dụng Thần số 1 trong khi Canh Kim được xem là
        Dụng Thần số 2. Khi xuất hiện cả hai Canh Kim và Đinh Hỏa thì Đinh Hỏa
        giúp kiểm soát Canh Kim, trong khi Canh Kim giúp cho Giáp Mộc hữu dụng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Ngoài ra, Bính Hỏa cũng đóng vai trò là Dụng Thần hỗ trợ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Ngày sinh này nên tránh gặp Quý Thuỷ nếu được. Nhâm Thuỷ và Quý Thuỷ
        nếu xuất hiện ở can sẽ làm cho người này mất tài lộc và thịnh vượng. Nếu
        có Nhâm Quý thì nên dùng Mậu Thổ để kiểm soát Thuỷ. Nếu có Nhâm Quý mà
        không có Mậu Thổ thì ngày sinh này sẽ chỉ tầm thường.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Ngày sinh Giáp Mộc sinh trong mùa đông như thế này nên gặp Kim và Hỏa.
        Nói cách khác, lý tưởng nếu có Bính Hỏa, Đinh Hỏa và Canh Kim trong lá
        số.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Trong tháng Tý Mùa Đông thì thời tiết đặc biệt lạnh và tối.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Trong những hoàn cảnh đó thì Quý Thuỷ không nên lộ ở Thiên Can cũng
        như không nên thiếu Bính Hỏa, Đinh Hỏa và Canh Kim trong Bát Tự.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Lá số ngày sinh này sẽ xuất sắc nếu có Canh Kim và Đinh Hỏa hiện lên ở
        Thiên Can trong khi cùng có Tỵ và Dần ở Địa Chi. Tuy nhiên Tỵ và Dần
        không nên xuất hiện bên cạnh nhau để tránh hình thành Tượng Hình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Địa Chi hình thành Thuỷ Cục đầy đủ và cộng với Nhâm Thuỷ xuất hiện
        ở Thiên Can thì ngày sinh này sẽ sống cuộc đời vô định hướng. Ngay cả dù
        cho có xuất hiện Mậu Thổ và Kỷ Thổ thì vẫn sẽ khó cho ngày sinh này tìm
        được sự cân bằng hay thiết lập nền tảng vững chắc trong cuộc sống.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu thiếu Bính Hỏa, thì có thể dùng Đinh Hỏa để thay thế. Nếu được vậy
        thì ngày sinh này vẫn sẽ có cuộc đời bình an, ổn định.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có cả Bính Hỏa và Mậu Thô ở Thiên Can thì ngày sinh Bính Hỏa sẽ
        sưởi ấm và sinh ra Mậu Thổ Thiên Tài. Nếu vậy thì ngày sinh này sẽ hưởng
        tài lộc và thịnh vượng.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu có cục Thiên Ấn:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Địa Chi hình thành Thuỷ Cục, thiếu Mậu và Kỷ Thổ có thể dẫn đến
        người này nghèo đói hoặc rất ích kỷ. Nếu có Mậu Kỷ sẽ rất tốt.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu có cục Chính Ấn:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Địa Chi hình thành Thuỷ Cục đầy đủ, ngày sinh này sẽ nghèo đói, nếu
        không có Hỏa và Thổ hiện diện để hoá giải Thuỷ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tuy nhiên, ngay cả nếu chỉ có hiện diện của Mậu Thổ và Kỷ Thổ cũng sẽ
        không giúp nhiều cho ngày sinh này.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Canh Kim, Đinh Hỏa và Bính Hỏa rất cần thiết cho ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có Dần và Tỵ thì Bính Hỏa phải đi kèm với Mậu Thổ thì mới tốt.
      </p>
    </div>
  );
};

export const GiapThangSuu = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Giáp Mộc trong tháng Sửu
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu ngày Giáp sinh trong tháng Sửu thì hình thành cục Chính Tài nếu kèm
        với Kỷ Thổ xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Quý Thuỷ xuất hiện ở một trong những Thiên Can thì hình thành cục
        Chính Ấn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Tân Kim xuất hiện ở một trong những Thiên Can thì hình thành cục
        Chính Quan.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu không có Kỷ Thổ, Quý Thuỷ hay Tân Kim xuất hiện thì có thể chọn theo
        những cách cục lá số khác tuỳ theo khí vượng nhất trong lá số.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng Sửu thì dụng thần điều hậu là Đinh Canh
        Bính.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đinh Hỏa không nên thiếu trong lá số Bát Tự này. Sẽ tốt nếu như Hỏa có
        gốc ở Địa Chi Tỵ hoặc Dần.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngoài ra, Giáp Mộc cũng đóng vai trò Dụng Thần hỗ trợ cho ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Trong lúc đó, Canh Kim được dùng để chặt Giáp Mộc để tạo ra Đinh Hỏa.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Kim, Thuỷ và Thổ trên cũng quan trọng trong tháng Sửu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Mộc vốn bị mắc kẹt trong tháng này. Ngay cả với sự hiện diện của Thổ sẽ
        không giúp ích cho Mộc được có căn vững chắc và có sức mạnh. Điều này
        bởi vì Thổ này chứa Kim ẩn tàng cắt đứt rễ.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Điều này là lý do vì sao cần thêm Mộc trước tiên như là Dụng Thần, trong
        việc cung cấp sự hỗ trợ cho ngày sinh này. Hỏa nếu vượng là cần thiết
        nếu người này muốn trở thành một học giả giàu có và quyền lực trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Không tốt nếu ngày sinh này trải qua vận Kim và Thuỷ. Nếu vậy thì người
        này sẽ liên tục bị rắc rối trong đời nhất là liên quan đến công việc
        hoặc tài lộc.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Đinh Hỏa là dụng thần số một của ngày sinh này trong khi Canh Kim được
        xem là Dụng Thần số hai.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Ngoài ra, Bính Hỏa xem như là dụng thần hỗ trợ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Ngày sinh này cũng cần tránh gặp Quý Thuỷ.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tháng Sửu đặc biệt lạnh và tối tăm.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Trong điều kiện này thì Quý Thuỷ không nên xuất hiện ở Thiên Can cũng
        như là Bính Hỏa, Đinh Hỏa và Canh Kim cần có mặt trong lá số.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Sẽ rất lý tưởng nếu Canh Kim và Đinh Hỏa cùng xuất hiện ở Thiên Can
        cũng như Địa Chi có Tỵ và Dần cùng xuất hiện.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Địa Chi hình thành Thuỷ Cục, trong khi Nhâm Thuỷ xuất hiện ở Thiên
        Can, ngày sinh này sẽ có xu hướng sống cuộc đời vô định hướng. Ngay cả
        dù cho Mậu Thổ và Kỷ Thổ xuất hiện, sẽ khó khăn cho ngày sinh này tìm
        được sự cân bằng hay thiết lập cơ sở vững chắc trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Vì Hỏa có thể cung cấp hơi ấm cho ngày sinh này, nếu không có Hỏa khí
        thì Giáp Mộc sẽ có rất ít tiến bộ trong đời. Gần như người này chỉ ở yên
        một chỗ suốt đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Chỉ có Đinh Hỏa và Bính Hỏa mới có thể dùng để hỗ trợ và sinh cho Thổ
        lạnh cũng như cho phép hình thành Tài Cục.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có Canh Kim ở Thiên Can nhưng Đinh Hỏa lại ẩn tàng thì ngày sinh
        này nếu có thịnh vượng cũng chỉ ở mức độ khiêm tốn.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        • Nếu Đinh Hỏa xuất hiện nhưng Canh Kim ẩn tàng thì ngày sinh này sẽ
        giàu có trong đời.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        • Nếu chỉ có Đinh Hỏa xuất hiện nhưng thiếu Canh Kim thì cần thật nhiều
        Tỷ Kiếp để bù đắp cho thiếu hụt này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Đinh Hỏa xuất hiện ở Thiên Can và có căn ở Địa Chi là lý tưởng cho lá
        số này.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        • Nếu không có Canh Kim thì ngày sinh này thiếu quyết tâm, can đảm và
        niềm tin trong cuộc sống.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Ngoài ra, người này có thể sở hữu tính keo kiệt. Nếu không có Đinh Hỏa
        thì ngày sinh này sẽ nhút nhát. Nếu có Đinh Hỏa kèm với Tỷ Kiếp và Quý
        Thuỷ cũng xuất hiện ở Thiên Can thì ngày sinh này chỉ tầm thường.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tân Kim không thể thay thế được cho Canh Kim xét về mặt Dụng Thần.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu không có Canh Kim là Dụng Thần, ngày sinh này sẽ dễ bị sức khoẻ
        kém và bệnh tật trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu thiếu Đinh Hỏa thì có thể dùng Bính Hỏa thay.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu cả Nhâm và Quý đều xuất hiện ở Thiên Can hay Địa Chi hình thành
        Thuỷ Cục thì ngày sinh này sẽ khó thành công trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có Tân Kim hình thành Chính Quan Cục thì ngày sinh này sẽ chỉ
        trung bình.
      </p>
    </div>
  );
};
