export const TanMui = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Tân Mùi: người này thông minh, sáng tạo nhưng có những
        suy nghĩ phức tạp, có khiếu kinh doanh, tỏ vẻ dễ gần nhưng có ý định ẩn
        giấu mà không tiết lộ, muốn dùng các phương thức “dưới gầm bàn” để đạt
        được mục tiêu. Hôn nhân xấu, khó kết hôn. Nếu kết hôn, dễ cãi nhau với
        gia đình chồng/ vợ, ảnh hưởng đến hôn nhân. Nếu sinh trong tháng Mão,
        người này sẽ giàu có hơn sinh trong những tháng khác. Nếu sinh trong mùa
        đông, có địa vị xã hội cao.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tân Mùi là kim loại bị chôn dưới đất bẩn và không sáng bóng. Điều này
        phản ánh rằng người này có thể tỏ ra thiếu tinh tế hay không hấp dẫn
        trên bề mặt nhưng ẩn sâu bên trong che giấu nhiều phẩm chất hơn.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Mùi duyên dáng, thân thiện và nổi tiếng. Họ có tính cách lãnh
        đạo bẩm sinh giúp thúc đẩy họ trở nên độc lập và giúp họ có thể đạt
        thành công cá nhân to lớn. Điều họ muốn là danh tiếng và quyền lực và họ
        thích cạnh tranh để đạt được điều đó.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người sinh ngày Tân Mùi khôn ngoan, và có thể nhanh chóng đánh giá
        được con người và tình huống. Đó là lý do tại sao khi họ nắm giữ vị trí
        quyền lực thì họ có xu hướng tỏ ra mạnh mẽ và đầy sức sống. Họ có xu
        hướng nắm quyền và áp đặt, cũng ít chịu tha thứ cho lỗi lầm. Những người
        xung quanh biết rõ xu hướng này của người Tân Mùi và kính nể họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sự tự tin của họ dẫn họ đến cơ hội khởi nghiệp. Họ có thể tập trung toàn
        bộ sự chú ý lên một mục tiêu, đó là lý do tại sao nhiều cơ hội mà họ
        chọn có thể trở nên thành công.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Du lịch và tự do rất quan trọng đối với Tân Mùi đến nỗi họ sẽ chọn lựa
        ngành nghề cho phép họ được cả hai điều trên. Sự yêu thích tự do cũng
        đóng vai trò quan trọng trong nhu cầu thể hiện bản thân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu nhu cầu quyền lực của họ không được đáp ứng đúng đắn, họ có thể trở
        thành người thao túng. Họ có tính cách gian xảo và sẽ không ngần ngại
        đâm sau lưng người khác nếu bị cản đường. Họ nên học cách để dung hoà
        thì sẽ tốt hơn về lâu dài.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi xét về vấn đề vật chất, người Tân Mùi có thể tỏ ra hời hợt. Nếu họ
        không cẩn thận, họ có thể rơi vào những lúc vung tay quá trán hay tận
        hưởng quá độ. Họ thường không được thừa hưởng gia tài. Vì lẽ này họ cũng
        không được gì che lưng hỗ trợ. Tốt nhất là họ nên đầu tư và tiết kiệm
        tiền khôn ngoan.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sâu thẳm bên trong, người Tân Mùi có thể cảm thấy bất an liệu họ có ra
        quyết định đúng hay không. Bằng cách tập trung vào mục tiêu thật sự, họ
        có thể luôn ra quyết định chính xác và đạt được sự thông thái. Một cách
        khác để tiêu diệt sự bất an là tìm cách phát triển bản tính nhân đạo bên
        trong. Điều này sẽ giúp họ không còn lo lắng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        So với những ngày Tân Kim khác, người Tân Mùi bị xem là có ngoại hình
        khá chán và đơn điệu. Họ thường trông không tinh tế và thiếu hấp dẫn, có
        thể là do nguyên nhân những khó khăn của họ trong việc tự nhận thức hình
        ảnh bản thân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngoài việc tỏ ra thiếu hấp dẫn, họ còn có thể mang theo nhiều bệnh
        nghiêm trọng suốt cả đời. Khi họ già hơn, những vấn đề trên sẽ dần bớt
        đi nhưng họ gặp rắc rối ở phần chân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Những Đặc Tính Của Người Tân Mùi:</b> khôn ngoan, giao tiếp tốt, sáng
        tạo, gian xảo, tìm kiếm quyền lực, thiếu kiên nhẫn, lãnh đạo bẩm sinh,
        trực giác, khéo léo.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Có 2 nhân tốt rất quan trọng với người Tân Mùi khi chọn lựa nghề nghiệp:
        phần thưởng về tài chính và sự tự do đi lại. Bất cứ nghề nghiệp nào họ
        theo đuổi phải đem đến cho họ những lợi ích về tài chính và có quy trình
        làm việc thay đổi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ tham vọng, đó là lý do họ cần một sự nghiệp có tính đa dạng và sự can
        đảm. Khi họ ở một vị trí mà được phép trở nên đa dạng, họ có thể khám
        phá những ý tưởng mới. Một khi Tân Mùi rất hào hứng về một thứ gì đó mà
        họ đang làm, họ sẽ ngay lập tức nhanh chóng thành công.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Với kỹ năng quản lý tốt và tính cách duyên dáng, họ sẽ trở thành những
        nhà lãnh đạo tốt và có khả năng gợi cảm hứng cho người khác bằng những ý
        tưởng và khái niệm cùa riêng họ. Kèm với sự quyết tâm mạnh mẽ, họ có thể
        vươn cao trong thế giới kinh doanh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thông qua việc học hỏi, họ sẽ phù hợp với tất cả mọi nghề nghiệp.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Công Việc:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mọi lựa chọn nghề nghiệp đều phù hợp với người Tân Mùi. Bằng cách khai
        thác thành công khả năng của mình, người Tân Mùi có thể nắm giữ thành
        công ở tất cả mọi lĩnh vực.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những công việc cho phép họ bày tỏ tính cách của mình là tốt nhất. Các
        công việc lý tưởng như nhiếp ảnh, nghệ thuật, viết lách, báo chí, âm
        nhạc hay giải trí. Họ có khả năng tìm được thành công vững chắc ở các
        lĩnh vực trên.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc khác, người Tân Mùi cũng có thể dùng kỹ năng phân tích của họ trong
        các lĩnh vực như khoa học, công nghệ hay luật.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đối với những người thích làm trong lĩnh vực công, họ nên chọn nghề
        nghiệp chính trị, truyền thông, giảng dạy hay tâm lý học. Sự nghiệp
        trong tôn giáo hay huyền học cũng có thể vì sự quan tâm của họ đối với
        lĩnh vực tâm linh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bởi vì họ có ham muốn mạnh mẽ để thành công, gần như bất kỳ lĩnh vực nào
        họ theo đuổi cũng sẽ giúp họ phát triển và thành công.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Những Đặc Tính Trong Công Việc - Sự Nghiệp:</b> cân nhắc kỹ, có trách
        nhiệm, trực giác tốt, siêng năng, đa tài, thích bảo vệ, chủ quan, áp
        đặt, ích kỷ, thiếu kiên nhẫn, bất an.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Hôn Nhân - Quan Hệ - Gia Đình:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Mùi duyên dáng, điều này khiến người khác bị thu hút đến với
        họ. Họ là những người bạn đời có xu hướng thích bảo vệ người khác và sẵn
        lòng làm bất cứ điều gì cho nửa kia đặc biệt của mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Mùi tìm kiếm mối quan hệ với những người chung mối quan tâm.
        Họ có sự trân trọng đối với tri thức và bị hấp dẫn bởi những người cùng
        đánh giá cao tri thức. Họ cũng bị hấp dẫn bởi những người có khả năng
        giao tiếp tốt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Mùi thường muốn giữ bình an trong mối quan hệ tình cảm của họ.
        Tuy nhiên, họ lại có xu hướng rơi vào tâm trạng thất thường và điều này
        có thể gây ảnh hưởng đến ham muốn bình an của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu họ thật sự muốn tình cảm suôn sẻ, người Tân Mùi nên thận trọng không
        trở nên hách dịch, chuyên quyền hay thích chỉ trích người bạn đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Xét dưới góc độ bạn bè, người Tân Mùi rất thấu hiểu và hỗ trợ bạn. Khi
        họ ở trong các mối quan hệ xã hội, họ thường năng động và vui vẻ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tình bạn lành mạnh nhất đối với người Tân Mùi là những tình bạn đem đến
        những kích thích về tư duy. Họ thích ở bên cạnh những người thông minh,
        hấp dẫn và cuốn hút. Họ nhiều cảm xúc và có nhu cầu kết nối mạnh mẽ. Một
        khi họ đã thiết lập được mối quan hệ, họ sẽ luôn giữ đồng minh của mình
        ở bên cạnh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ nhận thức rõ về hình ảnh của mình do đó họ lo lắng về việc bạn bè
        nghĩ vì về mình. Trong lúc cố gắng bảo vệ hình ảnh bản thân, họ cần cẩn
        trọng tránh tỏ ra quá kiêu ngạo hay tự mãn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Gia đình và mái ấm là những gì rất quan trọng đối với người Tân Mùi. Mái
        nhà là nơi họ cảm thấy an toàn nhất. Họ là những người tận tâm và sẵn
        lòng làm bất cứ gì để bảo vệ gia đình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Một lý do nữa để họ trân trọng giá trị gia đình là vì chất nam tính mạnh
        mẽ của họ vào những năm đầu đời. Sự ảnh hưởng này tạo ra cho họ hình
        tượng cũ kỹ về việc gia đình nên mang kiểu mẫu hình dáng ra sao.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Hôn nhân là quyết định tốt đối với người Tân Mùi. Họ sẵn lòng xây dưng
        mối quan hệ tình cảm bền vững và lâu dài với người bạn đời. Tuy nhiên,
        kết hôn sớm không phải là lý tưởng đối với họ. Tốt nhất là họ nên dành
        thời gian cho chính mình trước khi thành gia lập thất. Nó sẽ cho họ đủ
        không gian để trưởng thành và có lẽ là trách nhiệm của yêu thương và hôn
        nhân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Cuối cùng, mối quan hệ tình cảm vợ chồng sẽ tốt và họ có thể có nhiều
        con.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Chung Trong Tình Yêu - Quan Hệ:</b> mặc dù rất tham
        vọng nhưng họ có nhu câu cần an toàn trong mối quan hệ, đánh giá cao
        tình bạn, kết hôn muộn sẽ tốt hơn, gia đình lớn, thích đầu óc mạnh mẽ,
        dễ cảm thấy bất an.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngày Tân Mùi là trụ ngày may mắn toạ trên Khố Mộc và được hỗ trợ bởi
        hành Ấn. Đó là dấu hiệu nhiều may mắn trong đời vì trụ này toạ trên
        Thiên Tài, Thiên Ấn và Thất Sát.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Mùi có nhu cầu cân phải chinh phục. Họ siêng năng và quyết tâm
        và có khả năng nắm giữ trách nhiệm lớn. Tuy nhiên, họ cũng sáng tạo và
        giàu trí tưởng tượng cũng như yêu thích đi lại và khám phá. Họ có thể
        cảm thấy bị mâu thuẫn bởi hai nhu cầu trên.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Mùi rất tham vọng, một nét tính cách do Thất Sát ẩn. An toàn
        về tài chính rất quan trọng đối với họ và họ sẽ sử dụng tài sản để làm
        thước đo cho sự thành công trong đời. Họ thích phân tích, rất thông minh
        và sẵn lòng làm mọi điều cần thiết để biến một ý tưởng thành hiện thực
        rõ ràng. Giá trị mạnh mẽ và óc nhận xét phán đoán tốt kết hợp với nhau
        cộng với khiếu kinh doanh và khả năng điều hành sẽ đem đến kỹ năng mà họ
        cần để đạt được mọi thứ mà mình muốn. Điều này có thể làm lợi cho họ nếu
        họ vạch ra một kế hoạch hành động và gắn chặt với nó bất kể thử thách
        hay điều gì làm nản chí.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Mùi duyên dáng và khả ái (đặc tính của Thiên Ấn). Họ rất trực
        giác và có khả năng trở nên giỏi nhất trong mọi tình huống. Họ có sự
        thấu hiểu trực giác đối với tập thể cũng như biết rõ về nhu cầu và động
        lực của từng cá nhân. Họ thích các hoạt động tập thể cho phép họ học hỏi
        từ người khác và họ sẽ trở nên thân thiện và thích giúp đỡ. Đôi khi,
        người Tân Mùi có thể mất khả năng tự nhìn sâu vào chính mình và quên mất
        nhu cầu và cảm giác của bản thân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Mùi có thể tự tin đến mức tỏ ra kiêu căng. Sự cứng đầu của họ
        có thể khiến họ áp đặt và họ có thể tỏ ra khá ích kỷ, vô lý và thao
        túng. Đây là những ảnh hưởng kết hợp của Thiên Tài và Thất Sát. Họ có
        thể thích chỉ trích và thậm chí là phán xét người xung quanh họ, ý kiến
        của họ có thể bị ảnh hưởng bởi tâm trạng và cảm xúc của họ. Thái độ cực
        đoan này có thể do sự bất an về cảm xúc bên trong mà họ cảm thấy khó để
        giãi bày. Người Tân Mùi có thể rất nhạy cảm, họ dễ bị nản chí bởi lời
        chỉ trích và có nhu cầu cao cần sự ổn định. Nhu cầu này cao đến độ họ
        thường xuyên phải cố gắng vật lộn với sự thay đổi và điều này khiến họ
        trở nên kiểm soát và thiếu linh hoạt hơn. Họ phải làm việc vất vả để
        dung hoà các thái cực của lòng tin và sự dễ tổn thương để phát triển một
        quan điểm cân bằng và niềm tin đúng đắn vào khả năng của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Mùi khao khát yêu thương và cảm xúc. Họ trân trọng môi trường
        hòa hợp và muốn phát triển nền tảng bền vững cho bản thân và gia đình. Ở
        nhà, họ muốn kiểm soát mọi thứ và là tiếng nói của quyền uy bất kể là
        nam hay nữ. Họ có thể cần phải học cách buông xả nếu không muốn lấn át
        người thân yêu của mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bởi vì Tân Mùi đại diện cho hành Kim thoát thai từ đất, sẽ biểu thị cho
        kim loại không sạch sẽ hay sáng bóng so với những Kim khác. Tân Mùi sẽ
        dựa vào chất lượng của hành Thổ trong lá số để xét về thịnh vượng và
        tuổi thộ cũng như tốt nhất nếu họ có sự hỗ trợ của Mậu và Kỷ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những yếu tố tích cực của Nhâm và Quý Thuỷ là rất cần thiết, vì những
        hành này sẽ giúp rửa sạch Khí. Thành công của người này trong đời và
        danh tiếng chung của họ sẽ phụ thuộc lớn vào sự hiện diện của hành Thuỷ.
        Nếu không có hành Thuỷ, người Tân Mùi sẽ trở nên cứng đầu và ngây thơ.
        Người Tân Mùi sẽ 72 không thể đạt được bất kỳ thành công đặc biệt nào
        trong đời. Do đó, điều quan trọng là cả hành Thuỷ và Thổ không bị xâm
        hại trong lá số.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu gặp nhiều Gíap hay Ất thì tốt nhất nên tránh nhiều ngũ hành Mộc vì
        Mộc sẽ khắc ngũ hành Thổ rất quan trọng. Trụ Tân Mùi cũng gặp khó khăn
        nếu sinh trong giờ âm vì Tân Mùi không thể phát huy Thiên Ấn như là giác
        quan thứ sáu của họ. Giờ Âm biểu thị giờ trước khi mặt trời mọc. Nó sẽ
        làm suy giảm giác quan thứ sáu của họ không còn chính xác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Mùi sinh vào mùa Xuân hay Hè thì sẽ đối mặt với nhiều thách
        thức hay trở ngại hơn các ngày Tân khác và điều này sẽ tiếp diễn cho đến
        khi họ đạt độ tuổi trung niên. Người sinh vào mùa Thu hay Đông sẽ sống
        thọ. Nếu sinh vào tháng Thìn thì không nhiều may mắn và phải lao động
        vất vả mới có thể thành công.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tân Mùi có thể là trụ ngày biểu thị sự cân bằng tinh tế. Vừa là ngày
        sinh Kim vào cuối mùa hè, khả năng tiềm năng thì to lớn. Tân kim không
        giỏi trong mùa hè vì lửa mùa hè sẽ diệt nó, tuy nhiên Tân Mùi sẽ lại
        khai thác tốt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tân kim thường gắn liền với sự chính xác và tinh tế, những gì đẹp đẽ
        dưới con mắt của một người. Mùi là Địa Chi của tháng mùa hè là đất bụi
        Kỷ cộng với cỏ Ất đốt cháy Đinh. Khi đặt bên cạnh nhau, Tân không còn
        như những gì nó vốn là. Thay vì chấp nhận từ bỏ, Tân Mùi tiến lên và
        biến hoá bản thân cũng như các kim loại khác làm, rèn dũa để thay hình
        lột xác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tân toạ trên Thiên Ấn Kỷ, điều này đem đến kiến thức tốt mà họ có thể sử
        dụng để chuyển hoá lột xác bản thân. Vì Ấn thường gắn liền với sức khoẻ
        và tái tạo, nó cũng giúp cho Tân Mùi có thể tái xuất hiện nếu gặp những
        gì đau thương. Tân Mùi được hỗ trợ giúp đỡ, và có đủ quý nhân đến giúp
        khi cần thiết.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tân bị kẹp giữa 2 hành quan trọng là Thất Sát Đinh và Thiên Tài Ất. Đinh
        là hành chuyển hoá cho Tân, cho Tân sự dữ dội cần thiết để hành động và
        đôi khi để tồn tại. Ất giúp cho những gì mà Tân thiếu, sự uyển chuyển.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thiên Tài được gắn liền với khả năng nhìn xa trông trộng, nhìn thấy
        những cơ hội tương lai, những gì đáng đầu tư từ bây giờ. Điều này mang
        đến khả năng nhìn thấy trước, cẩn trọng nhưng đồng thời ra những hành
        động cần thiết để tập trung vào cơ hội đang đến.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mùi kết hợp với Ngọ, thu hút thêm hành Đinh Thất Sát và Kỷ Thiên Ấn. Đối
        với nữ giới thì mối quan hệ tình cảm thường gặp rủi ro với kỳ vọng cao
        được quan tâm. Nó thường gắn liền với lối sống xã giao, rượu và tiệc
        tùng, đẳng cấp và gợi cảm nhưng cũng khá rỗng tuếch. Điều này không có
        nghĩa là họ không hạnh phúc. Đối với nam giới Tân Mùi thì thái độ cương
        quyết bất khuất (Thiên Ấn) kèm với thái độ hãy thử xem sao (Thất Sát)
        khiến cho họ trở thành người yêu lý tưởng (trong ngắn hạn) nhưng không
        phải kiểu đàn ông của gia đình dài hạn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mùi khắc Sửu xua đuổi Kỷ (Thiên Ấn), Quý (Thực Thần) và Tân (Tỷ Kiên).
        Điều này biến thành sự từ chối giúp đỡ và thưởng thức. Những gì họ tự
        tay xây dựng, họ sẽ không cho người khác bước vào. Bạn sẽ thấy một thái
        độ chiếm hữu của họ đối với những gì họ làm, do đó nếu bạn muốn giúp
        những người này, bạn cần phải đứng ẩn đằng sau.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người Tân Mùi không gặp quá nhiều khó khăn với tình cảm, dù là nam
        hay nữ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhìn chung, Tân Mùi là những người biết rõ họ sẽ không toả sáng, nhưng
        thay vào đó, họ tập trung vào môi trường xung quanh giúp họ. Họ để lại
        dấu vết để người khác có thể nối gót, lâu sau khi họ đã ra đi. Ất sinh
        ra Đinh sau đó lại sinh Kỷ tạo nên Tân. Điều rất quan trọng là Tân cần
        phải nhìn thấy cơ hội (Thiên Tài), chính cơ hội là cái sẽ biến chuyển,
        lột xác họ, giúp lèo lái hành động của họ.
      </p>
    </div>
  );
};

export const TanTy = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Tân Tỵ là người này có quan hệ giao tiếp tốt, chăm sóc
        vẻ ngoài bản thân tốt, chi nhiều cho chăm sóc nhan sắc, tóc tai, thiếu
        khả năng đánh giá và suy nghĩ thông thường, dễ bị thao túng, thiếu kỹ
        năng phối hợp, dễ bị rối trí và không có kỹ năng giải quyết vấn đề hay
        tìm ra giải pháp. Bệnh nặng khi còn nhỏ. Với nam giới, vợ bị sức khoẻ
        xấu. Với nữ giới, chồng dễ ngoại tình.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tân Tỵ có thể hình dung như hổ phách bao phủ nạn nhân của nó vào một
        thời điểm bảo đảm sự lưu giữ vô tận. Gây ấn tượng với người sở hữu nó,
        đây là một tác phẩm nghệ thuật đặc biệt được hình thành do tự nhiên.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tân Tỵ cũng có thể hình dung như là viên ngọc thô, biểu thị thẩm mỹ và
        tinh tế cao. Người Tân Tỵ sinh ra vốn xinh đẹp và duyên dáng. Những
        người khác có thể ngay lập tức nhận ra họ, đó là lý do họ thường có thể
        dựa vào ngoại hình bắt mắt để vươn lên trong cuộc sống. Họ thường thích
        tỏ ra đẳng cấp và thể hiện vẻ hấp dẫn, kiêu sa và sang trọng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Tỵ rất cẩn trọng về ngoại hình của mình và dành thời gian để
        tạo nên hình ảnh đẹp đẽ. Về lâu dài, họ muốn được sự ủng hộ của người
        khác. Sự tranh giành ủng hộ có thể biến thành tốt đẹp hơn bởi vì người
        Tân Tỵ rất nổi tiếng và được yêu mến.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đừng nhầm lẫn, người Tân Tỵ không chỉ có 1 gương mặt đẹp. Họ thường
        nhanh nhạy, thông minh và kín kẽ. Họ có tốc độ tư duy nhanh và thường có
        khả năng hành động nhanh chóng khi có cơ hội. Ngoài việc nhanh nhẹn, họ
        thường sáng tạo. Có một ham muốn cao bên trong người Tân Tỵ để làm một
        thứ gì đó vượt ngoài khuôn khổ thông thường. Để đạt được thành công, họ
        thường tập trung những phẩm chất tốt của họ và chú trọng vào những những
        gì trong khả năng của mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Cuộc đời thường đem đến nhiều điều tốt cho họ .Không chỉ là họ có vẻ
        ngoài sáng sủa, họ còn có cuộc sống nhiều phước báo và tài lộc. Họ rất
        thành công trong việc tích luỹ tài sản và tiền bạc. Một khi họ học được
        cách không xem mọi thứ trong đời là hiển nhiên dành cho mình, họ sẽ dễ
        dàng khai thác hết tiềm năng trong đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ không bao giờ phải tìm kiếm sự giúp đỡ ở đâu xa xôi. Bất cứ khi nào
        họ phải đối mặt với trục trặc, luôn có người ở gần bên để giúp đỡ. Người
        ta sẽ luôn ở bên cạnh để đem đến sự định hướng và trợ giúp cho họ. Những
        người xung quanh sẽ sẵn lòng giúp đỡ họ vì vẻ ngoài ấm áp và thân thiện
        của họ. Họ giao tiếp tốt với người khác và sẽ luôn được xem là thấu
        hiểu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đôi khi họ tỏ ra căng thẳng và sợ hãi, đặc biệt là khi họ chịu áp lực
        quá nhiều. Họ có khả năng cao tỏ ra mất kiên nhẫn, và thường tập trung
        năng lượng vào bức tranh toàn cảnh thay vì những tiểu tiết. Việc không
        chú ý vào chi tiết có thể làm giảm khả năng thành công của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Có nhiều vấn đề là dấu hiệu cho thấy họ cần sự cân bằng. Cân bằng sẽ
        giúp thúc đẩy họ tránh những trục trặc và vấp ngã trong đời. Bởi vì họ
        quan tâm vào nhiều lĩnh vực khác nhau, họ có thể dàn trải bản thân quá
        rộng và khiến cho mình mất cân bằng và gia tăng thêm căng thẳng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ muốn làm tất cả mọi thứ cùng một lúc và đầu óc dàn trải nên có xu
        hướng gặp khó khăn khi ra quyết định hoặc đặt trọng tâm vào những nhu
        cầu nào cần làm trước tiên. Nếu họ không học được cách cam kết vào một
        quyết định, họ sẽ rủi ro dàn trải năng lượng của mình và kết cục là
        không có gì hoàn thành hoặc họ không có khả năng thực hiện điều họ hứa
        hẹn đúng như kế hoạch. Điều này về lâu dài sẽ khiến cho họ có vẻ không
        đáng tin cậy đối với người khác. Lời khuyên là họ nên tìm cách bình tĩnh
        để có thể tự suy ngẫm và ra quyết định tốt hơn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Tỵ cần phải cẩn trọng trong vân đề tài chính. Họ yêu thích
        những thứ tốt đẹp trên đời nhưng có thể chi tiêu quá đà và không kiểm
        soát hết chi phí. Họ cần phải tránh chi tiêu hoang phí và tính toán kỹ
        hơn về vấn đề đầu tư.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Của Người Tân Tỵ:</b> hấp dẫn, đồng cảm, tài năng,
        nghi ngờ bản thân, may mắn, muốn được người khác ủng hộ, duyên dáng.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Tỵ có sự sâu sắc tri thức, giúp cho họ dễ thành công trong bất
        kỳ sự nghiệp nào họ chọn. Tuy nhiên, họ hạnh phúc nhất khi nắm giữ các
        vị trí mà họ có thể thực hiện một mình như freelance hay chủ doanh
        nghiệp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có những kỹ năng độc nhất khi xét về đối xử với người khác. Điều này
        có thể dẫn họ đến những lĩnh vực nghề nghiệp bao gồm như chữa lành hay
        các phương pháp chăm sóc sức khoẻ. Ham muốn giúp đỡ người khác cũng có
        thể dẫn họ đến các tổ chức từ thiện hay phi lợi nhuận.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù họ khao khát chức danh lãnh đạo, họ có thể thiếu sự tự tin để
        biến mọi thứ thành hiện thực. Sự nghi ngờ và chần chừ ra quyết định có
        thể cản trở họ đạt mục tiêu sự nghiệp.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Công Việc:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Tỵ sẽ cảm thấy nhiều nghề nghiệp phù hợp với họ. Tất cả những
        gì họ cần làm là quyết định theo đuổi sự nghiệp nào.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ không thích nghe mệnh lệnh từ người khác và nên tránh những sự nghiệp
        mà họ không thể tự làm chủ. Bởi vì điều này, tự khởi nghiệp, làm chủ và
        quản lý là lý tưởng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những sự nghiệp mà Tân Tỵ có thể giúp đỡ người khác sẽ đem đến sự thoả
        mãn nhất cho họ trong cuộc đời. Khao khát bên trong được giúp đỡ người
        khác có thể khiến cho những nghề nghiệp như tư vấn hay tâm lý thích hợp
        cho họ. Một số người khao khát chữa lành người khác. Trong những trường
        hợp này, họ cũng có thể làm trong lĩnh vực y tế.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Tỵ là những nhà định giá số 1, họ có thể làm kinh doanh,
        thương mại, bất động sản.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Tỵ cũng rất dẻo dai và có thân hình uyển chuyển giúp cho họ có
        thể tham gia vào các môn thể theo như gym, nhảy, võ thuật, yoga hay thậm
        chí người mẫu. Họ thậm chí có thể đạt danh tiếng tốt trong những lĩnh
        vực này.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Một số người Tân Tỵ có đam mê khám phá và du lịch do đó họ rất thích đi
        làm việc ở nước ngoài.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Trong Công Việc:</b> sáng tạo, lý luận tốt, nhân đạo,
        dễ dựa vào, siêng năng, cạnh tranh, độc lập, lạc quan, ghen tuông, cái
        tôi, ích kỷ, bất ổn, bất mãn, thiếu kiên nhẫn, giận dữ.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Quan Hệ - Gia Đình:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Tỵ quan tâm đến cải thiện bản thân và bị thu hút bởi những
        người có đam mê nâng cao giá trị bản thân. Họ cũng tìm kiếm người bạn
        đời có tham vọng và tri thức. Nhìn chung, họ đặt ra yêu cầu cao trong
        tình cảm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bởi vì họ duyên dáng và xinh đẹp, họ thường được những người khác giới
        săn đón. Ngay cả khi họ có nhiều người theo đuổi muốn chiếm được trái
        tim, người Tân Tỵ vẫn cảm thấy khó khăn khi chọn một người bạn đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Tỵ là người giao tiếp xuất sắc và có thể khiến mọi khó khăn
        trong tình cảm đều vượt qua được. Lúc duy nhất họ cảm thấy khó khăn
        trong đời sống tình cảm là khi họ để cho sự bất an nổi lên. Điều này dẫn
        họ đến sự tranh cãi, đấu đá và bất đồng trong tình cảm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi ở trong mối quan hệ, họ có xu hướng chỉ trích hay sai khiến người
        bạn đời. Họ cần cẩn trọng không nên trở nên quá ghen tuông, chiếm hữu
        hay đa nghi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Tỵ thông minh và có khiếu hài hước. Họ là những người pha trò
        và ai cũng thích giao tiếp với họ. Bởi vì điều này, họ có xu hướng có
        nhiều bạn bè từ khắp mọi nơi. Sự thu hút và duyên dáng của họ khiến họ
        trở thành người rất nổi tiếng trong bất kỳ tập thể nào.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ cũng thích học hỏi và chia sẻ kiến thức, và thích ở bên cạnh những
        người thú vị và có thể đem đến sự học hỏi tri thức cho họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trong mọi mối quan hệ tình cảm, người Tân Tỵ thích thẳng thắn và khéo
        léo.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ cống hiến cho gia đình và trở thành những người vợ hay chồng xuất
        sắc. Họ sẽ sinh ra nhiều đứa con thông minh và khéo giao tiếp. Ngoài ra,
        con cái họ cũng sẽ đạt nhiều thành công trong đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Hôn nhân của họ thường trung bình. Đời sống tình cảm trộn lẫn giữa những
        tâm trạng thất thường lên xuống, hạnh phúc và khổ đau.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù cam kết với gia đình, cuộc sống của họ có thể rắc rối nếu hôn
        nhân không hoà hợp. Nếu không có sự hoà hợp, nó sẽ là một sự đi xuống
        khó có thể kiềm phanh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Những Đặc Tính Trong Tình Yêu:</b> nhạy cảm và quan tâm, thích được
        tán dương, dễ ghen tuông, thích có nhiều mối quan hệ rộng.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Tỵ toạ trên Chính Ấn, Chính Quan và Kiếp Tài. Tân rất may mắn.
        Mặc dù những người này thường có tính cách mềm mỏng và hiền lành, sự hỗ
        trợ mà họ có từ Chính Ấn và Kiếp Tài thường biến mọi yếu tố tiêu cực
        thành tích cực, đặc biệt nếu liên quan đến thành công về vật chất. Sẽ là
        lá số tốt hơn nữa nếu ngày sinh này thấy Bính Hoả và Chính Quan ở Thiên
        Can, vì nó đem đến sự cân bằng và ổn định cho trụ ngày. Cục này biểu thị
        người này sẽ rất giàu có và họ đạt được nhiều trong lĩnh vực họ chọn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Tỵ có tầm nhìn. Tâm trí của họ đầy những ý tưởng độc đáo và họ
        rất sáng tạo và năng động. Tất cả những điều này là do ẩn tinh Kiếp Tài.
        Họ thường bị lôi kéo về những lĩnh vực nghệ thuật và biểu diễn, người
        Tân Tỵ sử dụng khả năng nghệ sĩ để thể hiện cảm xúc sâu sắc của họ. Rất
        quan trọng nếu họ có thể phát triển ý tưởng của riêng họ thì họ sẽ tìm
        được hạnh phúc và viên mãn. Họ cũng có thể học cách tập trung năng lượng
        to lớn nếu họ có thể đạt đến tiềm năng đầy đủ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Để cân bằng với sự sáng tạo, người Tân Tỵ cũng có trực giác kinh doanh
        bén nhạy. Họ đánh gía cao cấu trúc và trật tự và thích vị trí nắm quyền
        để họ có thể được tự do ra quyết định riêng mình. Họ có xu hướng trở
        thành người theo chủ nghĩa hoàn mỹ với con mắt phê bình và ý kiến mạnh
        mẽ. Họ có thể cần phải tỏ ra thuyết phục hơn là chuyên quyền nếu họ muốn
        khai thác điều tốt nhất ở mỗi người để đạt được kết quả tốt nhất.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù người Tân Tỵ có thể, đôi khi tỏ ra khá chủ quan và tập trung vào
        bản thân, họ là người rất chân thật và công bằng trong mọi việc. Họ rất
        nhân đạo, quan tâm đến người khác, bảo vệ người mình yêu. Đây là hiệu
        ứng của Chính Quan Tinh. Họ sẵn lòng và có thể sử dụng suy nghĩ sáng tạo
        để giúp đỡ người khác tìm ra giải pháp cho vấn đề. Họ thường xuyên bị
        hiểu lầm và sự nhạy cảm khiến cho họ rất dễ bị lời chỉ trích gây tổn
        thương.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Tỵ đánh giá cao sự an toàn về cảm xúc và họ có ham muốn thật
        sự lắng đọng và muốn lập gia đình ổn định. Mặc dù họ có thể muốn một mái
        ấm và trở thành bố mẹ, họ có thể khó khăn trong việc xây dựng nền tảng
        cho hôn nhân hạnh phúc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Tỵ sẽ toả sáng khi gặp các điều kiện tốt đẹp cỏ hành Thuỷ. Sự
        kết hợp tốt nhất cho họ là nằm ở hành Thuỷ có lợi cho lá số. Điều tích
        cực nếu họ gặp thêm hành Kim. Tuy nhiên, họ cần tránh gặp thêm quá nhiều
        Mộc, Hoả và Thổ vì nó sẽ làm giảm chất lượng của Tân Tỵ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu người Tân Tỵ sinh vào ban đêm thì họ có ngoại hình hấp dẫn. Người
        sinh trong tháng mùa Hè và Xuân thì có tuổi thơ vất vả, và có thể bị bố
        mẹ bỏ rơi. Vì thiếu quý nhân giúp đỡ, những người có trụ ngày sinh này
        sẽ tìm được thành công dựa trên nỗ lực và sự thông minh của bản thân nếu
        họ sinh vào mùa Thu và mùa Đông.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh vào tháng Ngọ sẽ đối mặt cuộc chiến khó khăn để thành công.
        Họ phải chú ý vấn đề sức khoẻ vì họ dễ ốm. Người Tân Tỵ sinh vào tháng
        Sửu, nơi tạo thành Mộ Địa Bán Hợp thì sẽ thiếu động lực trong cuộc sống
        và vận may về tài lộc và hôn nhân kém. Hình tạo giữa Tân Tỵ sinh vào
        tháng Dần. Hình do Dần chứa Tài Tinh, biểu thị tài chính kém. Người sinh
        vào tháng Thân hay Dậu thì có xu hướng giàu có và thịnh vượng trong đời.
        Tuy nhiên, họ cần phải cẩn trọng với tình hình tài chính và tài sản vì
        họ có xu hướng làm mất nhiều tiền vào một lúc nào đó trong đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh trong tháng Tý sẽ có danh tiếng tốt vì Thực Thần trong lá số
        sẽ ở vị trí vượng. Tương tự đối với người sinh vào tháng Tỵ vì Canh Kim
        ở Trường Sinh. Tuy nhiên, để xảy ra được kết quả này, lá số cần phải có
        khả năng chuyển hoá Thuỷ hiệu quả. Người Tân Tỵ sinh trong tháng Hợi sẽ
        có phước với nhiều may mắn tài lộc vì Hợi xung với Tỵ trong địa chi
        ngày. Người sinh trong tháng Mão sẽ gặp nhiều may mắn về thiên tài do
        tiềnthưởng và thừa kế.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tân là hành của sự chú ý. Họ hay được người khác chú ý bất kể thời điểm
        nào. Tân hình thành dưới áp lực và đôi khi cần có thời gian để đạt hình
        dạng và trở nên hoàn hảo. Họ không ngại trước việc phải thực hành mới có
        thể trở nên hoàn hảo, bắt đầu là một hòn đá gồ ghề và sau đó được mài
        bóng loáng dần trở thành kim hoàn đẹp đẽ. Cà hình ảnh (Hoả) và âm thanh
        (Thuỷ) đều là người bạn thân nhất của họ, cho họ những gì mà họ muốn, họ
        có khả năng được người khác chú ý. Họ quan tâm nhiều đến sự hiện diện
        của mình ảnh hưởng đến người khác ra sao và thường không ngừng thể hiện
        bản thân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tân toạ trên Tỵ đem lại nguồn năng lượng của Bính Hoả Chính Quan, Canh
        Kim Kiếp Tài và Mậu Thổ Chính Ấn. Họ yêu cầu quyền lực một cách dễ dàng
        với việc sử dụng hiệu ứng của Chính Quan. Hoả là những gì đem lại năng
        lượng để hình thành nên hổ phách khi nhựa cây bao bọc nạn nhân của nó.
        Họ giỏi trong việc nắm bắt được khoảnh khắc, nhanh chóng hành động khi
        có cơ hội.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ biết rằng tuy họ nhỏ bé nhưng họ biết khi nào thì nên toả sáng với sự
        thông minh của họ. Họ không ngại khi Bính hoả chiếu bên trên thì kim
        hoàn càng gây chú ý vì phản chiếu lấp lánh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Kiếp Tài đem đến cho họ khả năng cạnh tranh và đánh giá được bức tranh
        toàn cảnh. Họ là những người giỏi kết nối, tận dụng sự giải trí để lôi
        kéo mọi người về phía mình. Cũng như so sánh một viên đá thô, không sáng
        bóng với một miếng kim loại sáng bóng. Khá dễ cho họ để tỏ ra duyên dáng
        và bước chân vào bất kỳ vòng giao tiếp xã hội nào. Là sao Kim, hành động
        là cần thiết để xây dựng bản thân họ. Những gì cần thiết phải được làm,
        không có gì xấu hổ trong việc cố gắng hoàn thành những ước muốn sâu thẳm
        nhất.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Chính Ấn cho họ kiến thức và sự trợ giúp cần thiết để làm những gì vĩ
        đại. Ấn là đại diện cho cấp dưới và những người che lưng cho họ. Hành
        Thổ là sự tin cậy và ổn định. Họ thể hiện sự hiểu biết và đáng tin cậy,
        một người nào đó mà bạn có thể dựa vào và ai có thể đỡ lưng cho bạn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Xét về mặt tiền bạc thì khi có Kiếp Tài, họ giống một người tiêu tiền
        hơn là người tiết kiệm tiền. Hôn nhân có lợi cho nữ giới nhiều vì sao
        Chính Quan hiện diện. Tỵ hấp dẫn Thân trong Lục Hợp và lôi kéo Canh Kiếp
        Tài, Mậu Chính Ấn và Nhâm Thương Quan. Biểu thị hôn nhân dễ bị phá hỏng
        đối với cả nam hay nữ giới. Không có sự hấp dẫn về mặt tài lộc và xu
        hướng lãng phí tiền bạc. Tỵ xung với Hợi xua đuổi Nhâm Thương Quan và
        Giáp Chính TÀi. Ở đây bạn sẽ thấy nam giới dễ thất bại hoàn toàn trong
        tình cảm trong khi nữ giới vẫn cố cứu vãn tình hình. Xét chung về sức
        khoẻ tài chính thì tệ cho nam giới hơn là nữ giới.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người này được người mà họ quan tâm trao cho sự yêu thương, quyền lực và
        biết cách giành sự ủng hộ. Đây cũng là vũ khí mạnh mẽ trong việc kiểm
        soát. Nếu bạn nhớ rằng trong thời xưa, khi có mặt nhà vua hay hoàng hậu
        thì mọi người xung quanh phải cuối mình, người Tân Tỵ cũng có khả năng
        gây ấn tượng tương tự.
      </p>
    </div>
  );
};
export const TanMao = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Tân Mão: giỏi kiếm tiền, rộng rãi và có mối quan hệ tình
        cảm tốt, chú trọng hưởng thụ hơn công việc, dám làm và chịu rủi ro.
        Thích cờ bạc, thú vui và thoả mãn trong việc chấp nhận rủi ro. Thích
        dính vào những mối quan hệ tình cảm yêu đương phức tạp với nhiều người.
        Dễ đạt thành công nếu ít tham lam và tham vọng hơn. Nhìn chung không
        thiếu của cải vật chất trong cuộc đời. Nếu là nữ, sau 40 tuổi sẽ gây xui
        xẻo cho chồng.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi bạn có một cái nhìn năng động về thế giới, bạn sẽ muốn người Tân Mão
        ở bên cạnh bạn. Họ có khả năng sinh tồn và khả năng phát hiện ra cơ hội.
        Bằng cách chà sát kim loại lại với nhau, bạn sẽ tạo ra tia lửa để cuối
        cùng thắp lửa và ánh lửa sẽ thay đổi tất cả mọi thứ.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tân là hành của sự chính xác. Cũng như viên đạn, họ sở hữu khả năng có
        thể nắm đến đúng điểm trọng tâm. Họ khá quan tâm về vẻ ngoài vì họ hiểu
        được tầm quan trọng của gây ấn tượng. Họ thường có nhu cầu cạnh tranh
        cũng như một khay kim hoàn....họ phải thật nổi bật. Hầu hết mọi Tân trải
        qua thời gian để phát triển hệ giá trị sinh tồn của họ luôn bao gồm khả
        năng ngồi lại tĩnh lặng và chờ đợi cũng như kỹ năng thuyết phục giúp họ
        chiến thắng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tân Mão có hình tượng như một đồng hồ cổ hay một huy chương vàng tuy có
        giá trị nhưng mong manh dễ vỡ. Những hình ảnh này đại diện cho người có
        nguyên tắc cao trong cuộc sống.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Mão có xu hướng độc tài tự nhiên, siêng năng và tham vọng. Họ
        tự bước đi và có niềm tin vững chắc rằng điều gì là đúng sai. Họ thích
        quyền lực và không gặp khó khăn trong việc dẫn đầu. Khi họ muốn đạt mục
        tiêu gì thì đều có thể đạt được hoàn hảo.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người này có sức mạnh ý chí và kiên trì, họ thường xuyên cố gắng
        tạo sự khác biệt trong môi trường xung quanh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Từ khi tuổi còn nhỏ thì người Tân Mão hiểu được rằng sự cứng rắn và kiên
        trì là những đức tính sẽ đem đến thành công dài lâu trong đời họ. Họ có
        năng lượng và sức bền khó tin. Tất cả những điều này đến từ đầu óc mạnh
        mẽ và sức mạnh ý chí. Họ theo đuổi cái mới, sáng tạo và đổi mới cũng như
        bắt đầu sự nghiệp kinh doanh riêng và theo đuổi các công việc trong
        ngành thể thao sẽ thúc đẩy họ tìm đến thành công.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người Tân Mão rất dễ nhận ra tại nơi công sở vì họ là người có khả
        năng đạt được thành tích ngoại hạng. Khi xét về lên chiến lược, người
        Tân Mão rất tỉ mỉ và cẩn trọng. Thông qua những nỗ lực không ngừng nghỉ,
        họ luôn là người leo lên các nấc thang doanh nghiệp và tiến bộ nhanh
        chóng trong sự nghiệp. Họ không sợ những vấp ngã dọc đường, Họ có thể
        nhanh chóng bật ngay lại khi bị cản trở. Họ có khả năng chinh phục thành
        công ngoại hạng trong đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có khả năng đón nhận khó khăn từ khi còn trẻ và biến nó thành trí tuệ
        dẫn lối tương lai. Vì lý do này, họ giỏi trong việc lên kế hoạch tài
        chính và có khả năng xử lý các vấn đề tài chính một cách tự tin.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ tự tin và siêng năng và khiến cho những người khác cảm nhận rằng
        người Tân Mão không cần họ, điều này vô tình xua đuổi những người quen
        của họ đi. Cuối cùng, họ có xu hướng có nhiều mối quan hệ dần dần xa lạ.
        Họ không tin người khác dễ dàng bởi vì họ cảm thấy khó để mở lòng hay dễ
        bị tổn thương. Họ cần phải hiểu rằng chấp nhận sự giúp đỡ từ người khác
        không phải là dấu hiệu của sự yếu đuối và không nên tỏ ra phê bình hay
        chỉ trích.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Mão trẻ tuổi có thể thường cảm thấy bất an. Điều này có thể do
        việc tự phá huỷ và sẽ cản trở họ đạt đến khả năng toàn diện khi họ lớn
        hơn, đặc biệt nếu họ không thử vượt qua sự hoài nghi chính mình. Họ phải
        học cách củng cố năng lực của mình kèm với sự tự tin và có lòng tin vào
        bản thân để có thể tiến xa.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ cũng có thể tỏ ra thái độ kẻ cả và vô cảm vì việc ăn nói huỵch toẹch
        và miệng lưỡi chua ngoa. Họ cần phải nhớ rằng có một lằn ranh giới mỏng
        giữa khôn ngoan và mỉa mai. Thất bại khi giữ cho giọng điệu phù hợp
        trong một số tình huống có thể khiến người Tân Mão phải trả giá đắt. Sự
        thiếu khéo léo trong giao tiếp có thể đến từ sự đa nghi đối với người
        khác, hay họ sử dụng điều này như một dạng công cụ phòng vợ để bảo vệ họ
        khỏi sự tổn thương. Cuối cùng, họ có thể gây hấn và chống đối người
        khác, thể hiện ra vẻ ngoài mà nhiều người đánh giá là lạnh lùng trong
        khi sự thật có thể ngược lại.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sự thiếu tin tưởng người khác không hoàn toàn là lỗi của họ. Có vẻ như
        trong quá khứ, nhiều người xung quanh họ đã phản bội hoặc lợi dụng họ
        cũng như lòng tốt của họ. Những trải nghiệm xấu này ăn sâu vào trí óc
        họ, nuôi dưỡng cảm giác bất an qua thời gian và đó là lý do tại sao họ
        cần thời gian dài mới có thể lấy được sự tin tưởng từ họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Của Tân Mão:</b> siêng năng, giỏi chiến lược, thích
        nghi, giỏi chịu đựng, kiên nhẫn, nhanh nhạy, có óc tổ chức.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Mão liên tục có nhiều sự sáng tạo không ngừng. Cộng với những
        nét tính cách này là sự độc lập và siêng năng, họ hoàn toàn sẵn sàng để
        thành công trong sự nghiệp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có khả năng lý luận vững chắc, điều làm cho họ giỏi trong lĩnh vực
        giáo dục và quản trị. Mặt khác, tính cách thích nắm trách nhiệm khiến họ
        trở thành quản lý hoặc những giám sát xuất sắc. Ngoài ra, họ sở hữu khả
        năng điều hành bẩm sinh và thấu hiểu thế giới kinh doanh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Mão rất tri thức và nhanh nhạy, đó cũng là lý do vì sao họ cần
        sự nghiệp đem đến sự kích thích và phát triển. Tham gia vào những ngành
        nghề thách thức giới hạn của sự tưởng tượng luôn giúp họ trở nên giỏi
        nhất. Họ không nên bao giờ cho phép bản thân rơi vào những quy trình lập
        lại chán ngắt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ví vốn dĩ họ giàu năng lượng, người Tân Mão bẩm sinh có sức bền bỉ cao -
        những phẩm chất sẽ giúp họ thăng hoa trong sự nghiệp thể thao. Điều này
        giúp họ dễ vượt trội trong những lĩnh vực đòi hỏi thúc đẩy thể lực và
        tinh thần. Với sự quyết tâm cao, họ có thể trở thành những vận động viên
        được trả thù lao cao nhất.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Môi trường làm việc ăn ý rất cần thiết để người Tân Mão cảm thấy hạnh
        phúc và thoả mãn. Căng thẳng và đấu đá không hợp với họ. Nếu họ gặp khó
        khăn trong việc hoà hợp với đồng nghiệp hay cấp trên, họ sẽ đi tìm công
        việc tại chỗ khác.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Công Việc:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Tân Mão thông minh và chi tiết. Họ có xu hướng phân tích
        và điều tra giỏi khiến họ dễ trở thành những thám tử xuất sắc, và điều
        tra viên độc lập. Những phẩm chất này cũng sẽ giúp ích cho họ trong công
        việc luật sư hay thẩm phán.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Theo đuổi sự nghiệp trong ngành viết lách cũng sẽ khá lý tưởng bởi vì họ
        có thể sử dụng trí tưởng tượng năng động để tạo nên những câu chuyện lôi
        cuốn và đậm chất giải trí. Họ cũng có thể sử dụng khả năng sáng tạo để
        thử tham gia các nghề nghiệp liên quan đến nghệ thuật và thiết kế.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Vì khả năng tư duy độc lập và yêu thích kiến thức, họ là những nhà giáo
        dục tuyệt vời. Ngoài ra, họ cũng có yêu thích việc giúp đỡ hay tạo động
        lực cho người khác, cũng là lý do vì sao các công việc như giảng dạy,
        đào tạo và chữa lành rất lý tưởng cho họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Của Người Tân Mão:</b> lên kế hoạch tốt, suy nghĩ cá
        nhân, đồng thuận, khéo léo, thấu đáo, tâm trạng, thiếu tự tin, ích kỷ,
        đa nghi, cứng đầu.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Hôn Nhân - Quan Hệ - Gia Đình:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày này thích những người có quyền lực, thành công và có sức
        ảnh hưởng. Quen người bạn đời thú vị hay khác thường rất quan trọng với
        người Tân Mão.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi xét về tình yêu, mối quan hệ và cam kết, người Tân Mão có thể khá
        thiếu quyết đoán. Cam kết tình cảm lâu dài không thú vị với người Tân
        Mão bởi vì họ có nhu cầu phải thay đổi và đa dạng trong đời sống.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ muốn đi đây đó và gặp gỡ những người khác ngoài nhóm người họ thân
        quen. Vì lẽ này, rất khó khăn nếu yêu cầu họ phải gắn bó lâu dài với một
        người và phải trải qua nhiều năm với nhiều mối quan hệ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi họ tìm được người đặc biệt, họ sẽ rộng lượng và sẵn sàng hi sinh vì
        bạn đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ở bên cạnh nhiều người tích cực rất quan trọng đối với người Tân Mão. Họ
        cần giao tiếp với những người tham vọng và cầu tiến. Là người thích đem
        lại niềm vui cho người khác, hô thông minh, hài hước và ít khi cảm thấy
        lạc lõng. Điều quan trọng là họ cần phải ở bên cạnh những người trân
        trọng cá tính năng động của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người khác cảm thấy nhanh chóng nhận thấy rằng người Tân Mão trung
        thành và quan tâm ra sao. Họ là những người bạn xuất sắc và là người tin
        cẩn để có thể giúp đỡ bất cứ ai khi cần.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trong hầu hết mọi trường hợp, họ cam kết gắn bó với gia đình và có thể
        dẫn dắt gia đình đi theo hướng tích cực. Được mô tả nhưng là người hiểu
        rõ lý lẽ, họ luôn muốn hướng đến bình an và hoà hợp trong tình cảm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bởi vì sự chung thuỷ của họ, đôi khi họ sẽ biến bản thân mình thành
        “thánh tử vì đạo” cho gia đình. Họ cần phải cẩn trọng tránh để bản thân
        mình bị lợi dụng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù họ cống hiến nhiều cho gia đình, họ có một khao khát muốn đi ra
        ngoài và khám phá thế giới. Nếu họ không thoả mãn được khao khát này, họ
        sẽ cảm thấy bị mắc kẹt. Điều này cũng sẽ xung với đời sống gia đình của
        họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Trong Tình Yêu - Quan Hệ:</b> quan hệ với nhiều nhóm
        người khác nhau, khao khát hoà bình và vui vẻ, dễ hi sinh bản thân, bị
        cuốn hút bởi quyền lực và sáng tạo, thích gần gũi, dễ phát sinh vấn đề
        tình dục.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tân toạ trên Thiên Tài cũng là sao Đào Hoa. Điều này sẽ biểu thị họ là
        người xinh đẹp và duyên dáng với cá tính lãng mạn, nhưng gặp khó khăn
        trong cam kết tình cảm dài lâu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Mão thường xuyên thể hiện vẻ ngoài nghiêm túc. Họ khá kín kẽ
        và mặc dù họ thích con người và giao lưu kết nối, họ khá lãnh đạm và
        hướng nội.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Với sự quan tâm nhiều về dữ liệu và các con số, người Tân Mão thích dành
        thời gian để thật sự phân tích một vấn đề trước khi rút ra kết luận. Họ
        thông thái và thích phân tích cũng như rất thực tế và linh hoạt, họ có
        thể áp dụng các kỹ năng cho một loạt các nhiệm vụ khác nhau.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù họ khá bảo thủ, người Tân Mão có những kỹ năng giao tiếp rất tốt.
        Bởi vì ám kéo của Tuất bao gồm Tỷ Kiên, họ thường xuyên có thể giao tiếp
        với nhiều nhóm người khác nhau, kết nối với những người từ mọi thành
        phần xã hội. Họ ngoại giao giỏi, trực giác, cảm xúc và thấu hiểu. Điều
        này bởi vì Tuất cũng chứa Chính Ấn. Họ thích cảm nhận rằng họ là một
        phần của nhóm và họ cũng đươc hưởng lợi từ các hoạt động nhóm mà họ có
        thể tương tác và học hỏi từ người khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù họ có thể rất có tài trong việc viết lách, người Tân Mão có thể
        phải vật lộn để biểu đạt bản thân bằng từ ngữ. Thông qua việc phát triển
        kỹ năng giao tiếp, họ có thể thể hiện khả năng độc lập và xây dựng cho
        mình sự tự tin. Điều này là bởi vì xung đột giữa Kim và Mộc của trụ ngày
        này. Rất dễ bị tổn thương bởi lời chỉ trích, họ thường nghi ngờ khả năng
        chính mình và họ có thể thiếu quyết đoán và có xu hướng suy nghĩ quá
        nhiều trước khi ra quyết định. Họ cũng có thể rất phụ thuộc nhiều vào
        người thân yêu để được nhận trợ giúp về tình cảm. Nếu họ học được cách
        tin tưởng bản năng và có thêm niềm tin vào bản thân, họ có thể trở nên
        thẳng thắn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Vì trụ ngày này toạ Tài Tinh, Tân Mão có thể khá sành điệu và tinh tế.
        Xung giữa Kim và Mộc của trụ này biểu thị rằng họ khá nhạy cảm và dễ bị
        tổn thương về tình cảm. Mặc dù họ có khả năng để phát triển tình bạn và
        thoải mái trong mọi tình huống giao tiếp xã hội, họ thường khá mong manh
        và cô đơn bên trong. Tốt cho trụ ngày này nếu được hỗ trợ và ủng hộ bởi
        sự xuất hiện của Mậu Tý, Mậu Tuất hay Bính Tuất ở Thiên Can và Địa Chi.
        Sự kết hợp này sẽ giúp họ thêm tự tin, và là dấu hiệu chỉ báo họ sẽ kết
        hôn với người giàu có hoặc là họ sẽ giàu có và có danh tiếng thông qua
        sự vất vả và kiên trì của mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sẽ không tốt nếu ngày sinh này chỉ được hỗ trợ bởi Thiên Can Bính và
        Đinh trong lá số. Cũng thường tốt nhất cho họ nếu tránh được nhiều Thuỷ
        hay Hoả, đặc biệt nếu có sự hiện diện của Hợi và Mão, vì những thứ này
        có thể làm lệch lạc khả năng của người này.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu người này được sinh vào ban ngày thì họ có khả năng có hôn nhân hạnh
        phúc mà không chịu nhiều mâu thuẫn. Người sinh vào ban đêm có thể gặp
        khó khăn khi duy trì quan hệ dài lâu. Những người sinh vào tháng Sửu sẽ
        không gặp may mắn nhiều nhất trong đời, việc đầu tư có thể thất bại và
        đối mặt nhiều thách thức trên đường sự nghiệp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tân toạ trên một hành Mão mang theo năng lượng của Ất Mộc. Ất là bậc
        thầy của kết nối quan hệ trong khi đối với can ngày Tân là Thiên Tài
        biểu thị cho cơ hội tương lai. Ở đây, một người Tân Mão có thể phát hiện
        ra nhu cầu của bạn trong 1 tíc tắc. Họ sẽ đạt thoả thuận với bạn dựa
        trên nhu cầu và đổi lại cho thành công của riêng họ. Mão là Đào Hoa, bao
        gồm cả sự cuốn hút tự nhiên mà Tân đã vốn sở hữu. Họ thường có vẻ ngoài
        gợi cảm, ồn ào và gợi ham muốn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bính và Tân tạo thành nước từ trên trời trong khi Tý - Mão Địa Hình sẽ
        biểu thị mối quan hệ tình cảm thiên về tình dục.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mão hợp với Tuất mang theo năng lượng của Chính Ấn Mậu Thổ, kẹp bởi Tỷ
        Kiên Tân Kim và Thất Sát Đinh Hoả. Bạn có thể để ý rằng người Tân Mão
        không có sao truyền thống đại diện cho tình cảm là Chính Quan Chồng và
        Chính Tài Vợ và cuốn hút Thiên Tài và Thất Sát. Họ là người hấp dẫn và
        họ tỏ ra với người bạn đời là mình không tự tin và cũng như tầm nhìn về
        tương lai của họ thường dễ làm lộn xộn việc họ chọn bạn đời. Điều này rõ
        ràng với nữ giới là người bạn đời thường từ bỏ việc cố gắng chiều theo
        nhu cầu và mong muốn của đối phương.
      </p>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mão xung với Dậu xua đuổi với năng lượng của Tỷ Kiên Tân Kim. Ở đâu bạn
        có thể thấy tại sao việc chia sẻ tình cảm không phù hợp họ. Người phụ nữ
        Tân Mão điển hình sẽ phản ứng bằng cách chiếm hữu người bạn đời. Nhưng
        đừng nhầm với việc chung thuỷ, ngay khoảng khắc bạn tỏ ra kém cỏi, bạn
        sẽ bị ngay lập tức bị thay thế mà không ngần ngại.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tân Mão giỏi trong việc tìm xem điều gì tốt đẹp nhất trong đời. Họ không
        muốn lãng phí thời gian quý giá chời đợi trong tuyệt vọng. Họ sẽ nhanh
        chóng di chuyển sang cơ hội khác. Ham muốn tình dục trở thành một trong
        những công cụ của họ nên được sử dụng. Khả năng kiểm soát Ất sẽ thay thế
        Giáp (Vị vua) và biến họ trở thành hoàng hậu của sự canh tranh.
      </p>
    </div>
  );
};

export const TanSuu = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Tân Sửu thân thiện và khiêm tốn, có tài năng kinh doanh,
        nghiêm túc trong công việc, có tố chất lãnh đạo, đầu óc cởi mở, thích đi
        ve vãn, xem yêu đương lãng mạn như trò chơi. Có tình cảm và duyên kém
        với cha mẹ. Với nữ giới, là người rất xinh đẹp.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi mùa đông gần kết thúc, băng tan chảy và tạo nên thời tiết lạnh lẽo.
        Đây là lúc để mong chờ một thứ mới mẻ có thể xuất hiện từ mộ mùa đông
        dài lâu. Người Tân Sửu cũng như những hạt ngọc không vết sước vừa được
        khám phá ra. Dưới bàn tay của một người thợ tay nghề cao, chúng có thể
        trở thành trang sức quý giá đem lại nguồn tài lộc lớn. Tuy nhiên, chỉ
        cần một bước đi sai, chúng có thể tan biến thành tro bụi.
      </p>

      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tân Kim có bản tính chính xác. Họ là những người chú ý nhiều đến tiểu
        tiết. Họ thường ghét thái độ nhặt sạn tìm sâu nhưng lại có khả năng chỉ
        ra những thứ nhỏ nhặt mà người khác ít khi chú ý. Họ không gặp khó khăn
        trong việc tạo dựng sự chú ý đến bản thân và do đó rất thích hợp để trở
        thành ngôi sao và ngồi vào ghế nóng. Thường cô đơn, họ cảm thấy khó khăn
        để có thể tìm được một người bạn đồng địa vị.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Hình ảnh biểu thị Tân Sửu là kim loại thô được đào lên khỏi lòng đất.
        Kim loại thô này biểu thị người Tân Sửu tinh khiết và cứng chắc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Tân Sửu thường có lý tưởng sống, trực giác và tự tin. Là
        những nhà lãnh đạo bẩm sinh, họ thích gây ảnh hưởng đến người khác. Họ
        thường có vẻ ngoài ưa nhìn, duyên dáng và điều này đem đến lợi thế lớn
        cho họ trong mọi tình huống. Nói chung, người Tân Sửu được xem là người
        lạc quan, thông minh, nhân văn và khá độc đáo.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Sửu bẩm sinh thông minh và nhận thức mọi thứ xung quanh rõ
        ràng. Sự thông thái của họ giúp họ ra những quyết định chính xác trong
        khi khả năng nhận thức giúp họ tiếp nhận được những bài học mà cuộc đời
        dạy họ. Vì lý do này, họ được xem là những học trò xuất sắc từ trường
        đời. Ngoài ra, họ cũng có khả năng đánh gía nghệ thuật và sáng tạo cao.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ cũng là những người suy nghĩ nhanh nhạy. Lối suy nghĩ nhanh giúp cho
        họ thoát ra khỏi các tình huống khó khăn dễ dàng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Sửu thích sự hoà hợp trong đời sống. Họ thiên về tâm linh và
        sẵn lòng nỗ lực để giữ sự thuần khiết và vô tư trong tâm hồn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Sửu luôn có nguồn lực sẵn sàng. Họ học được cách duy trì kỷ
        luật và nhịp độ làm việc siêng năng. Họ ít khi thiếu vật chất mà ngược
        lại, sẽ luôn đầy đủ thực phẩm, quần áo và tất cả mọi thứ tốt đẹp trong
        đời. Ngoài ra, họ dễ thừa kế tài sản từ tổ tiên để lại.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đối với người nữ Tân Sửu thì họ có thể tiếp xúc với mọi thứ tốt đẹp
        trong đời và sẽ đem sự thịnh vượng đến cho gia đình. Họ cũng thường khó
        có con và thường chỉ thích 1 gia đình nhỏ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Sửu thường rất chung thuỷ với những người trong cuộc đời họ.
        Họ sẽ hi sinh hết mình cho những người mà họ quan tâm cũng như sẵn lòng
        làm tất cả những gì cần thiết để khiến người xung quan hạnh phúc và
        thoải mái.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù họ chung thuỷ, họ cần phải lưu ý không nên hi sinh bản thân vào
        những tình huống bất lợi về cảm xúc và tinh thần đối với họ. Nó có thể
        dẫn đến suy nghĩ tiêu cực và tự hạ thấp bản thân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bởi vì người Tân Sửu cũng thích lên kế hoạch chi tiết và tỉ mỉ, họ có
        thể trở nên tự xét nét bản thân nếu mọi thứ không theo đúng kế hoạch. Họ
        cần học cách tránh mất kiên nhẫn và tự gây khó dễ cho bản thân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Chung Của Tân Sửu:</b> thích xã giao, trực giác, sáng
        tạo, thực dụng, tự tin, thiếu kiên nhẫn, siêng năng.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Có vô số cơ hội nghề nghiệp dành cho người sinh vào ngày Tân Sửu vì họ
        thông minh và sáng tao. Người Tân Sửu thường có sự kết hợp giữa sự lạc
        quan và khả năng lãnh đạo giúp cho họ đặc biệt thành công trên con đường
        sự nghiệp. Một sự nghiệp cho phép họ có những hoạt động đa dạng sẽ giúp
        cho họ cảm thấy hạnh phúc nhất. Họ cần phải tìm kiếm công việc mà không
        bắt buộc họ phải phục tùng hay được phép có sự tự do để độc lập.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có đam mê được chia sẻ các bài học và trải nghiệm giúp cho họ làm
        việc tốt với người khác. Họ có khả năng ngoại giao và cố vấn tốt giúp
        cho họ thành công trong các công việc đòi hỏi sự giao tiếp tốt với người
        khác. Mặt khác, họ cũng rất phù hợp với những ngành nghề đòi hỏi sự sáng
        tạo.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sự cần cù bẩm sinh giúp cho họ có thể hết mình trong mọi công việc mà họ
        đảm nhận. Bởi vì họ là những người theo chủ nghĩa hoàn mỹ, họ luôn mong
        đợi mọi thứ diễn ra theo kế hoạch. Nếu một dự án không diễn ra theo dự
        tính, họ sẽ trở nên mất kiên nhẫn và bắt đầu chỉ trích.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bản tính nghĩa khí giúp cho họ có những đặc tính phù hợp cho công việc
        làm trong các tổ chức từ thiện hay các phong trào xã hội mà họ tin tưởng
        vào chính nghĩa.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Công Việc:</h4>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Lựa chọn nghề nghiệp tốt nhất cho người Tân Sửu là những công viêc liên
        quan đến kỹ năng sáng tạo bẩm sinh như âm nhạc, diễn xuất, chỉ đạo, các
        ngành nghề nghệ thuật khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngoài kỹ năng sáng tạo, họ cũng là những người có tầm nhìn tốt. Họ có
        thể áp dụng kỹ năng nhìn xa trông rộng của họ vào những ý tưởng, sáng
        kiến trong các ngành như quảng cáo, xuất bản hay truyền thông.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sự yêu thích chia sẻ kiến thức giúp cho họ trở thành những giáo viên
        hoặc nhà văn xuất sắc, trong khi mặt thực dụng giúp họ có sự nghiệp tốt
        trong ngành tư vấn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Cách làm việc thực tế có thể giúp họ tiếp cận công việc như trị liệu hay
        tâm sinh lý tốt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sự khôn ngoan và kỹ năng lãnh đạo sẽ giúp họ có sự nghiệp tốt trong
        ngành ngoại giao. Họ cũng sẽ thành công trong thế giới kinh doanh,
        thương mại và kỹ thuật bởi vì tham vọng, tính thực tiễn và tận tâm của
        mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những Nét Tính Cách torng Sự Nghiệp: sáng tạo, nghệ sĩ, tư duy tích cực,
        tận tâm, lãnh đạo bẩm sinh, tham vọng, thực dụng, chỉ trích nhiều, gắn
        liền cảm xúc, kiêu ngạo, cứng nhắc, chuyên quyền, dễ buồn chán, không
        tập trung, thích phán xét.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Hôn Nhân - Quan Hệ - Gia Đình:{" "}
      </h4>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Sửu thích bày tỏ cảm xúc, duyên dáng và lãng mạn. Họ có xu
        hướng hết mình trong một mối quan hệ và bởi vì điều này nên họ có rất
        nhiều người theo đuổi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ thường bị thu hút bởi những người mạnh mẽ có cá tính. Tuy nhiên, điều
        họ muốn và điều họ cần đôi khi không giống nhau. Điều họ thật sự cần là
        người nào đó có trách nhiệm có thể giúp neo họ lại và cho họ sự ổn định.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Lựa chọn nghề nghiệp tốt nhất cho người Tân Sửu là những công viêc liên
        quan đến kỹ năng sáng tạo bẩm sinh như âm nhạc, diễn xuất, chỉ đạo, các
        ngành nghề nghệ thuật khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ không lười nhác hay thờ ơ trong mối quan hệ. Ngược lại, họ sẽ làm tất
        cả những gì cần thiết để giúp đỡ người bạn đời và để đảm bảo mối quan hệ
        suôn sẻ. Vì lý do này, mối quan hệ của họ thường khá tốt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Hôn nhân cũng sẽ giúp gia tăng địa vị xã hội của người Tân Sửu. Nói
        chung, phụ nữ sinh ngày Tân Sửu sẽ có lợi từ hôn nhân hơn là đàn ông
        sinh ngày Tân Sửu. Tuy vậy, cả hai đều sẽ được gia tăng địa vị xã hội
        tốt hơn sau khi kết hôn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù họ đều xem mối quan hệ của mình là rất nghiêm túc, họ cần phải
        thận trọng để không quá cam kết sâu trong mối quan hệ. Nếu người phối
        ngẫu không thật sự trân trọng cam kết tình cảm, người Tân Sửu sẽ dễ dàng
        đánh mất sự cân bằng bên trong nội tâm. Từ đó họ sẽ trở nên khó tính và
        cứng nhắc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mọi mối quan hệ bạn bè đêu có ý nghĩa với người Tân Sửu. Cá tính hướng
        ngoại của họ cùng với việc họ yêu thích gặp gỡ người mới, cũng sẽ đảm
        bảo người Tân Sửu có cuộc sống luôn tràn đầy bạn bè.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Cá tính thân thiện giúp cho những người xung quanh dễ đến với người Tân
        Sửu, cũng là lý do vì sao Tân Sửu thường tham gia vào nhiều hoạt động xã
        hội và luôn có nhiều bạn bè, cả cũ lẫn mới vây quanh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ được xem như là người chung thuỷ, quan tâm và tận tâm với bạn bè và
        nhất là nếu với bạn bè thân, họ luôn ủng hộ và bảo vệ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Không có gì ngăn cản được người Tân Sửu trở nên gần gũi và hết mình với
        bạn bè thân. Sự rộng rãi của họ thường khiến họ dệ cho đi hơn mức cần
        thiết đối với người thân yêu. Sự nhân từ và đồng cảm luôn là đích đến
        trong mọi mối quan hệ bạn bè của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Sửu ủng hộ gia đình và luôn toàn tâm toàn ý trong mọi hoạt
        động mà họ có thể làm cho gia đình. Hôn nhân rất có lợi cho họ. Một khi
        người Tân Sửu và bạn đời thật sự đồng lòng, họ có thể tạo ra khác biệt
        cho gia đình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Hôn nhân đối với nữ giới Tân Sửu rất viên mãn. Nữ Tân Sửu dễ đem đến vận
        may, tài lộc và thịnh vượng cho gia đình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Sửu cũng gặp khó khăn về vấn đề con cái. Hoặc họ sẽ khó thụ
        thai hoặc không thể có con. Họ cũng thường ghen tỵ và nổi nóng với những
        người xung quanh về vấn đề con cái.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù người người Tân Sửu luôn cố gắng đạt được hạnh phúc, họ có thể
        trở nên bực bội khi mọi thứ không theo đúng kế hoạch. Nó có thể khiến
        cho họ trở nên cứng nhắc và tiêu cực. Họ cũng cần phải tránh đắm chìm
        trong sự ghen tuông quá mức.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những Nét Tính Cách Trong Tình Yêu và Mối Quan Hệ: tận tâm và chung
        thuỷ, phụ nữ thường có lợi trong hôn nhân hơn, khó có con, dễ thu hút
        những tính cách bất ổn, dễ đánh mất bản thân trong tình cảm, từ bi, rộng
        rãi với người mình yêu.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Sửu được xem như là toạ Ấn Tinh, Tỷ Kiếp, Thực Thần và được
        xem như là thông căn ẩn tàng. Điều này biểu thị người này sẽ có vẻ duyên
        dáng và sang quý bẩm sinh. Phụ nữ ngày sinh này rất đẹp, mảnh mai và
        duyên dáng. Họ cũng sẽ yêu gia đình sâu sắc và làm việc vất vả để quan
        tâm chăm sóc gia đình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Tân Sửu thường thích tạo ra điểm khác biệt với đám đông.
        Họ luôn muốn chiếm sự chú ý trung tâm. Vì lẽ này, họ thường cố gắng
        không chịu thua kém hay đồng tình với đồng nghiệp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Sửu thường rất sáng tạo và có trí tưởng tượng sâu sắc. Điều
        này là bởi vì Thực Thần ẩn tàng. Họ dễ thích nghi và có động lực cũng
        như sự kiên trì để làm bất cứ việc gì cũng như sẵn lòng biến tầm nhìn
        của họ trở thành hiện thực vững chắc. Họ rất tham vọng và quyết đoán với
        khiếu kinh doanh tốt và họ thường muốn nắm vị trí quản lý. Họ ham muốn
        tạo ra một trật tự mà trong đó họ có thể thực hiện vai trò của mình và
        lãnh đạo, dẫn dắt để có thể bày tỏ ý tưởng của mình tự do mà không bị
        ngăn cản.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù người Tân Sửu hay thể hiện ra là người rất tự tin, họ thường có
        thể là người nhạy cảm và sống chú trọng cảm xúc, tình cảm. Điều này có
        thể là do Thiên Ấn Tinh ở bên dưới. Họ thường cảm thấy khó để bày tỏ
        những cảm xúc sâu sắc bên trong với người gần gũi họ. Họ có thể sống cực
        đoan với các trạng thái cảm xúc ở hai thái cực lên xuống liên tục.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Lời khuyên cho họ là nên dành thời gian để nghỉ ngơi và khám phá sự cân
        bằng cảm xúc.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Sửu là những người chung thuỷ và sống chân thật cũng như gắn
        bó với mái ấm và gia đình. Bản tính cảm xúc của họ dẫn dắt họ họ làm
        việc vất vả để tạo ra sự bình an và hạnh phúc trong mái ấm của mình.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Sửu dễ giàu có và thịnh vượng nếu như trụ ngày này được hỗ trợ
        bởi Mậu, Canh, Dần, Tuất, Thìn và Tý. Nếu trụ này gặp Xung, Hình và Phá,
        thì kết quả sẽ không tích cực, và những người này sẽ vật lộn để đạt được
        mục tiêu tài chính hay mối quan hệ vì biểu thị rằng nhiều nét tính tình
        tốt sẽ không thể hiện ra được.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Sửu nếu gặp thêm Tỷ Kiên Tinh trong lá số Bát Tự sẽ chung thuỷ
        hơn người gặp sao Kiếp Tài.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Tân Sửu nếu sinh trong tháng mùa Xuân hay Hè thì sẽ được
        quý nhân giúp đỡ nhiều cũng như dễ đạt được thành công nhờ bạn bè, gia
        đình và đồng nghiệp hỗ trợ. Người sinh trong mùa Thu hay Đông thường
        phải làm việc vất vả để đạt được thành công dựa trên nỗ lực tự thân.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh vào ban ngày sẽ cần phải bứt phá khỏi vòng an toàn và làm
        việc chủ động để đạt được thành công. Người Tân Sửu sinh vào ban đêm thì
        sẽ gặp nhiều may mắn trong nửa đầu cuộc đời. Nếu người này được sinh ra
        trong tháng Thân thì có thể sẽ phải vật lộn để đạt được tài lộc hoặc
        cũng có thể là không đạt được cho đến khi về già do sự xuất hiện của sao
        Kiếp Tài trong Chi Tháng.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tân toạ trên Sửu mang theo khí chính là Kỷ Thổ Thiên Ấn và phụ khí là
        Quý Thuỷ Thực Thần và Tân Kim Tỷ Kiếp. Điều này đem đến một người trung
        thành. Thiên Ấn là sao chủ về trực giác bao gồm cả khả năng nhận biết
        được toàn cảnh. Suy nghĩ của họ thường xoay quanh vấn đề cấu trúc và kết
        hợp với Tân vốn dĩ đã mang tính nhạy cảm, cho họ khả năng suy nghĩ lớn
        và suy nghĩ chi tiết. Cả Thực Thần và Tỷ Kiên đều là những nguồn năng
        lượng yêu hoà bình và do đó lý tưởng của họ thường bị mắc kẹt giữa tính
        toán về lợi ích. Người Tân Sửu thường chọn cuộc chơi dài hơi bằng cách
        chọn lối tiếp cận gây ảnh hưởng.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sửu hợp với Tý hấp dẫn năng lượng của Thực Thần Quý Thuỷ. Đối với cả nam
        và nữ thì vì thiếu cả Chính Quan và Chính Tài Tinh, nên chúng ta có thể
        kết luận rằng mối quan hệ tình cảm thường không phải là lựa chọn ưu tiên
        hàng đầu. Vì Thực Thần là sao của sáng tạo và tưởng tượng, điều này gợi
        ý rằng họ khao khát sáng tạo nhưng chỉ là trong tưởng tượng mà thôi và
        tiêu chuẩn của họ có thể sẽ hơi xa rời thực tế, mang màu sắc tiểu thuyết
        lãng mạn.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sửu xung với Mùi xua đuổi năng lượng của Thiên Ấn Kỷ Thổ, Thất Sát Đinh
        Hoả và Thiên Tài Ất Mộc. Cả Thất Sát và Thiên Tài đều biểu hiện người
        yêu và do đó họ thường từ chối tình yêu thậm chí trước khi nó có thể
        biến thành mối quan hệ lâu dài. Điều này cũng đồng nghĩa với họ thiếu
        năng lượng để hành động (Thất Sát) và có thể có tầm nhìn tương lai ngắn
        hạn (Thiên Tài). Họ chờ đợi và xem...và lại tiếp tục chờ đợi.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ là những hạt kim cương mà bản thân còn chưa nhận thức được hoặc chờ
        được khám phá. Họ sinh ra vào cuối mùa đông và sắp bước sang mùa xuân và
        cũng là toạ hành Tài. Sự tiến bộ của họ phụ thuộc vào đại vận. Phụ nữ
        sinh vào năm Can Âm và đàn ông sinh vào năm Can Dương sẽ có thể trở nên
        vĩ đại.{" "}
      </p>
    </div>
  );
};

export const TanHoi = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Tân Hợi: người có tính cách mạnh mẽ, thích tự khẳng định
        mình, không quan tâm người khác nghĩ gì, tâm trạng thất thường, thích
        những gì bề mặt hơn là bên trong, dùng tất cả mọi cách để đạt được điều
        mình muốn, vận đào hoa vượng nhưng chung thuỷ.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tân Hợi cũng giống như việc bạn tìm kiếm vàng ở giữa biển khơi, bạn có
        thể lạc lối nhưng khi tìm ra thì giá trị rất lớn. Tân Hợi là kho tàng ở
        đáy đại dương bao la hoặc 1 xác tàu đắm. Không có gì đảm bảo rằng bạn sẽ
        tìm được kho tàng vì có thể đó chỉ là tiếng hát của một nàng mỹ nhân ngư
        để quyến rũ tàu bè tìm đến vùng nước chết. Đối với những người tìm được
        kho tàng thì họ có thể xây dựng một vương quốc kéo dài trường tồn mãi
        mãi.
      </p>

      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tân là hành gắn liền với định nghĩa giá trị như vàng, bạc và châu báu.
        Kim thường được tìm kiếm bởi người khác nhưng hoàn toàn không biết đến
        chân giá trị của mình. Sự hình thành kim loại quý đòi hỏi nhiều sự tập
        trung và do đó người Tân Kim nắm giữ khả năng chính xác. Họ có thể dễ
        dàng xuyên qua bề mặt của mọi thứ và đi đến nền tảng giá trị cốt lõi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Hình tượng của trang sức hình thành tự nhiên dưới biển cũng như hạt ngọc
        trong con trai đại diện cho Tân Hợi. Hình ảnh này biểu thị cái đẹp và
        người đang bình an và không bị ảnh hưởng bởi bất kỳ sự xáo trộn nào.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Hợi rất sáng tạo và nghệ sĩ. Họ rất cuốn hút cả về tri thức và
        ngoại hình có thể cuốn hút bất kỳ ai dễ dàng. Là người tài năng, họ giàu
        tham vọng và thường cảm thấy rằng không có gì là không thể.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi những người xung quanh gặp Tân Hợi, họ ngay lập tức để ý thấy sự
        thẳng thắn, không vớ vẩn hay dữ dội của họ. Tuy nhiên, khi họ quen biết
        người Tân Hợi thân hơn, họ sẽ phát hiện ra người Tân Hợi rất quan tâm và
        tôn trọng người khác. Bởi vì sự quyết tâm và kiên trì, người Tân Hợi sẽ
        là những lãnh đạo xuất sắc có thể phân tích tình huống nhanh chóng và
        nghĩ ra được hành động tốt nhất. Họ cũng là những người tư duy và chiến
        lược gia xuất sắc.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những nét tính cách khác giúp họ leo lên vị trí lãnh đạo bao gồm cả hệ
        giá trị và tính bền bỉ của họ. Thái độ kiên trì tạo động lực cho họ hoàn
        thành mọi nhiệm vụ, trong khi giá trị của họ đảm bảo rằng bất kỳ nhiệm
        vụ nào họ theo đuổi đều thành công. Họ luôn đảm bảo được họ luôn được
        hiểu đúng. Điều này giải thích cho cá tính thẳng thắn và trực tiếp của
        họ. Tuy nhiên, đôi khi chính cá tính thẳng thắn của người Tân Hợi gây ấn
        tượng rằng họ là người lạnh lùng hay không có tình người.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi xét về việc đối nhân xử thế, họ thường có trái tim ấm và tính nuôi
        dưỡng. Những người khác thường bị cuốn hút bởi đặc tính thân thiện và
        thích xã giao của họ. Điều này tốt cho người Tân Hợi vì họ khát khao yêu
        thương và quan tâm từ bạn bè và người thân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù họ thích xã giao, họ cũng cần thời gian một mình để nạp lại năng
        lượng. Họ thường xuyên dành cho thời gian cho bản thân, đó là lý do vì
        sao họ cần dành thời gian để suy ngẫm và thiền định. Sâu thẳm bên trong,
        họ có khí chất và có cách sống nghĩa khí. Lối sống này có thể dẫn họ đến
        thế giới tâm linh và huyền học.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tiền có thể là vấn đề đối với người Tân Hợi. Tài chính của họ dễ trồi
        sụt. Khi còn trẻ, người Tân Hợi có thể khó khăn trong việc giữ tiền hay
        tích luỹ tiền bạc. Có nhiều lý do cho việc này, một trong những lý do
        chính là vì họ có thói quen xa hoa. Khi họ già hơn, tài chính sẽ cải
        thiện. Khi họ đến độ tuổi trung niên và già thì họ sẽ đạt được sự thịnh
        vượng. Tốt hơn cho họ nếu có những khoản tiết kiệm thông minh hoặc kế
        hoạch tài chính để phòng cho những gì bất ngờ xảy ra.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Dưới áp lực, người Tân Hợi có xu hướng mất kiên nhẫn, lo lắng và thiếu
        quyết đoán. Những trở ngại này sẽ chắn đường thành công của họ. Những
        nét tính cách tiêu cực này sẽ khiến Tân Hợi lãng phí nhiều năng lực để
        theo đuổi các mục tiêu không xứng đáng. Năng lượng lãng phí này sẽ khiến
        họ cảm thấy nghi ngờ bản thân. Liên kết năng lượng và trở nên kỷ luật
        hơn sẽ giúp họ biến giấc mơ thành sự thật.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Điều quan trọng là người Tân Hợi không được cho phép bản thân ra những
        quyết định vội vàng bằng cách nhận lấy bất cứ gì mà sau này họ sẽ hối
        hận. Họ có thể bỏ dở dự án đó về sau.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Hợi bị thúc đẩy bởi cảm xúc bên trong. Bề ngoài, họ cố hành xử
        logic nhưng sự thật là họ bị ảnh hưởng bởi những cảm xúc sâu bên trong.
        Họ có thể khó bị xoay chuyển một khi đã quyết định bất cứ điều gì.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Hợi có thể cảm thấy buồn chán nếu họ không nhận được sự kích
        thích về tinh thần và cảm xúc thường xuyên để giữ chân họ. Nếu không, họ
        dễ mất tập trung và sự hứng thú đối với những dự án ban đầu và chuyển
        đến những gì mới mẻ tiếp theo có thể thu hút sự chú tâm của họ.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những Đặc Tính Của Người Tân Hợi: thông minh, nhanh trí, tự nghi ngờ,
        yêu thương, quan tâm, chân thành, nhạy cảm, chính xác, hấp dẫn.{" "}
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người này sẽ dễ dàng tìm được thành công trong nhiều công việc do
        tài năng và kỹ năng đa dạng. Họ là gười theo chủ nghĩa hoàn mỹ và sẽ
        luôn làm hết khả năng của mình. Tính cách này này vô giá đối với sự phát
        triển sự nghiệp và cho phép họ đạt được vị trí quản lý và lãnh đạo.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ thích những ý tưởng mới và dễ thành công trong những lĩnh vực sáng
        tạo và công nghệ tiên phong đi đầu.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Với bản tính thích thống trị, họ sẽ rất thành công trong thế giới kinh
        doanh. Những người khác sẽ chú ý đến khả năng ra lệnh của họ, xem nó như
        là dấu hiệu của sự tự tin và sẵn lòng tuân theo.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bất kể sự chăm chỉ của họ, họ cần phải tránh các công việc đơn điệu. Để
        có thể cảm thấy thoả mãn, vị trí công việc của họ phải đảm bảo tính linh
        hoạt.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bởi vì họ đặc biệt và chính xác, họ nên có môi trường làm việc có tổ
        chức. Dấu hiệu rối loạn hay mất trật tự sẽ khiến cho họ nổi giận.{" "}
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Công Việc:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Hợi đa tài. Những nghề nghiệp đòi hỏi độ chính xác trong nhận
        định của họ phù hợp là khoa học và công nghệ. Với khiếu kinh doanh bẩm
        sinh, người Tân Hợi dễ xuất sắc trong ngành ngân hàng, bất động sản hay
        quản lý quỹ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Kỹ năng giao tiếp tốt đảm bảo họ sẽ thành công trong lĩnh vực giao tiếp
        công chúng. Họ cũng sẽ có sự nghiệp thành công trong chính trị, pháp
        luật, tư vấn, tâm lý hay xã hội học.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu họ có thể thoả mãn khao khát sáng tạo và thể hiện sáng tạo, người
        Tân Hợi có thể thành công trong các công việc liên quan đến nghệ thuật.
        Họ có cặp mắt nghệ thuật và giỏi kỹ năng kể chuyện khiến họ dễ thành
        công trong diễn xuất , âm nhạc, phim ảnh, truyền thông hay giải trí.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Yêu thích học hỏi và chia sẻ kiến thức, người Tân Hợi có thể trở thành
        giáo viên hay thuyết trình viên xuất sắc. Họ cũng có thể sử dụng sự hứng
        thú tương tự nếu tham gia nghề tư vấn hay cố vấn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Trong Công Việc - Sự Nghiệp:</b> có trách nhiệm, trực
        giác, sáng tạo, lạc quan, độc lập, thích xã giao, đồng cảm, bất an, quái
        đản, quá nhạy cảm, ghen tuông, mất kiên nhẫn.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Hôn Nhân - Quan Hệ - Gia Đình:{" "}
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Hợi có vẻ ngoài bắt mắt và không gặp khó khăn trong việc thu
        hút nhiều người theo đuổi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có nhiều kỳ vọng cao trong quan hệ. Bởi vì điều này, họ chọn cách
        trải nghiệm với nhiều người đối tác bạn tình khác nhau. Suy cho cùng, họ
        tìm kiếm người bạn đời có thể chia sẻ một sợi dây kết nối đặc biệt.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Hợi cần một người bạn đời đồng cảm, kiên nhẫn, thông minh và
        thấu hiểu. Họ cần một người bạn đời có thể xứng với họ về mặt tinh thần
        và tri thức. Họ cũng cần người có thể hiểu và quan tâm đến bản tính dịu
        dàng và cảm xúc của họ. Một khi họ yêu thì họ tràn đầy lý tưởng và sẵn
        lòng hi sinh.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhìn chung, họ lo âu và không yên khi xét về quan hệ tình cảm lãng mạn.
        Họ thường thiếu quyết đoán có thể dẫn đến tâm trạng thái độ lưỡng cực
        trong tình cảm. Lúc này, họ cảm thấy hào hứng và đam mê trong khi lúc
        khác lại thấy lạnh lùng và hờ hững.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ cần phải cẩn trọng không tỏ ra quái hời hợt. Hời hợt là vẻ ngoài để
        che giấu cảm xúc ghen tuông và nghi ngờ bên trong.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Hợi thích xã giao, thân thiện và thích giải trí. Là người giỏi
        xã giao, họ thật sự có kết nối với nhiều dạng người khác nhau.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Là một phần trong tập thể rất quan trọng đối với tinh thần của họ. Không
        gì làm cho người Tân Hợi vui bằng việc có thể học hỏi những thứ mới mẻ
        từ người khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Vì họ có xu hướng thông thái, những người xung quanh thường tìm kiếm xin
        lời khuyên từ họ. Bởi vì họ nhạy cảm, nhưng chân thật, họ thường vui vẻ
        chia sẻ suy nghĩ và quan điểm với người khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có cách giao tiếp độc đáo. Tuy nhiên, họ cần cẩn trọng tránh trở nên
        độc đoán hay phê bình. Điều này làm cho những người xung quanh cảm thấy
        thấp kém và cuối cùng làm hỏng những mối quan hệ giá trị và gần gũi nhất
        của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi xét về hôn nhân thì người Tân Hợi tốt hơn nên chờ đợi. Hôn nhân muộn
        sẽ giúp cho người Tân Hợi đủ thời gian để trưởng thành. Hôn nhân muộn
        cũng sẽ giúp họ có cái kết có hậu hơn vì người Tân Hợi biết cách kiểm
        soát tâm trạng cảm xúc tốt hơn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Một khi họ tìm được người bạn đời, họ cống hiến và chung thuỷ. Họ nỗ lực
        hết mình để đồng hành với bạn đời trong mọi quyết định và sẽ tôn trọng
        quyết định bạn đời ở mức cao nhất.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Hôn nhân đối với nữ giới Tân Hợi ít may mắn hơn nam Tân Hợi và nữ Tân
        Hợi có xu hướng độc thân. Bản tính nói thẳng và thô của người Tân Hợi sẽ
        xua đuổi nhiều người theo đuổi. Những thái độ như vậy xuất phát từ sự
        bất an bên trong và họ cần phải vượt qua nó nếu muốn kết hôn lập gia
        đình. So với đa phần mọi người, nữ Tân Hợi cần nhiều thời gian hơn để
        tìm bạn đời lý tưởng, nhưng cơ hội sẽ cao hơn nếu cô ấy đi lại nhiều
        hơn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trong khi người Tân Hợi thích bình an, họ có thể trở nên ghen tuông và
        giận dữ. Họ không được phép để cho sự bất an và tự nghi ngờ làm hỏng một
        cuộc hôn nhân đáng lẽ có thể thành công.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những Đặc Tính Trong Hôn Nhân - Tình Yêu: hôn nhân muộn sẽ tốt hơn, giao
        tiếp nhiều loại người khác nhau, đam mê bảo vệ trong tình cảm, bị thu
        hút bởi người có đam mê và bảo vệ, lo âu và không yên.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tân và Hợi hoà hợp tương sinh. Điều này biểu thị người này sẽ có học vấn
        và trình độ mặc dù điều này đúng với nam hơn là nữ. Mộc Dục của can Tân
        ở Hợi. Hợi cũng chứa Thương Quan Tinh biểu thị người Tân Hợi sẽ có cá
        tính mạnh, thống trị. Đối với nam giới Tân Hợi, thường là dấu hiệu tốt
        có lợi để thành công trong sự nghiệp. Điều này cũng biểu thị họ sẽ lấy
        phụ nữ xinh đẹp và thành công bởi vì Địa Chi biểu thị cung Phu Thê. Với
        nữ Tân Hợi, thì ít may mắn hơn về hôn nhân vì gặp khó khăn trong tìm
        kiếm người đàn ông phù hợp với cá tính mạnh của cô ấy.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Hợi rất thú vị. Họ có cảm xúc và quan điểm mạnh mẽ. Họ thích
        mạo hiểm với sự đam mê đi lại và gặp gỡ người mới. Đây là hiệu ứng của
        Hợi cũng là Dịch Mã. Họ khá độc lập nhưng cũng rất thích quảng giao. Họ
        thích học hỏi kinh nghiệm mới và có xu hướng có lịch làm việc rất năng
        động.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Hợi có đầu óc sắc bén và nhanh nhạy. Họ học hỏi rất nhanh và
        thích đưa kiến thức của mình vào ứng dụng thực tế. Họ có năng lực lý
        luận mạnh mẽ và có thể suy nghĩ sáng tạo để hình thành các giai pháp
        sáng tạo. Điều này nhờ vào sức mạnh của Thương Quan Tinh và Chính Tài
        Tinh trong Hợi. Vì 2 sao này là hành Thuỷ và Mộc, trong quan hệ tương
        sinh, Tân Hợi rất thông minh và nhạy bén. Can đảm và tiên phong, họ có
        xu hướng tự phấn đấu để lại thành quả cho cuộc đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Hợi có khả năng tự bắt đầu và sự nhạy cảm, khi kết hợp lại cho
        họ khả năng lãnh đạo. Họ có linh cảm và thấu hiểu con người cùng kỹ năng
        giao tiếp xuất sắc. Tất cả điều này bởi vì Thuỷ sinh Thương Quan. Họ dễ
        thấu hiểu nhu cầu và động lực của người khác. Họ có thể sử dụng sự khéo
        léo và ngoại giao trong việc khuyến khích người xung quanh họ hợp tác.
        Họ cũng thường có khả năng gợi cảm hứng cho người khác để tìm ra con
        đường và trở thành phiên bản tốt nhất mà họ có thể.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Hợi có nhu cầu sâu sắc đối với con người, đến mức có thể gây
        hại cho họ. Điều này bởi vì Tân Kim ám hợp với Bính Hoả, trong khi Hợi
        lại ám xung với Tỵ vốn cũng là Bính Hoả. Quan hệ xung hợp của Bính Hoả
        biểu thị Tân Hợi khao khát sự quan tâm chú ý và khi họ đạt được, họ lại
        âm thầm vô thức đuổi đi.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đôi khi họ quên tìm sự cân bằng giữa công việc và đời sống bận rộn.
        Ngoài ra, mặc dù họ có sự độc lập và khao khát thiết lập bản ngã, họ có
        thể thấy họ cho phép bản thân bị ảnh hưởng nhiều bởi bạn bè. Điều này
        bởi vì Hợi ám xung với Tỵ, cũng là Trường Sinh của Kim - biểu thị bạn bè
        đối với người Tân Kim. Họ thường cho phép sự tiêu cực của những người
        khác kéo lùi bản thân và họ có thể cần phải xây dựng lòng tin vào lý lẽ
        bản thân.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thông minh và có tri thức trong nhiều lĩnh vực, người Tân Hợi đôi khi
        cảm thấy tội lỗi trong việc dàn trải bản thân. Họ thấy quá nhiều niềm
        cảm hứng do đó đôi khi thiếu trọng tâm. Họ cũng có thể hướng đến việc
        cho phép cảm xúc che mờ óc phân tích và điều này ngăn cản họ phát huy
        hết khả năng tối đa. Rất quan trọng là họ cần phải giữ bình tĩnh và kỷ
        luật để có thể thành công và thoả mãn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đối với Tân Hợi thì sự kết hợp giữa Dần và Hợi trong lá số Bát Tự được
        xem là Lao Kim Dụng Tiết hay Đăng Phá Ngạn. Điều này nghĩa là vàng, khi
        sáng lấp lánh, thường vô dụng khi ở dưới bùn. Điều này cũng là bởi vì
        Dần và Hợi tạo thành Hình trong lá số.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Phụ nữ Tân Hợi có thể khó khăn trong việc tìm chồng. Ngay cả nếu họ tìm
        được, thì cũng khó gắn bó lâu dài. Điều này bởi vì sự hiện diện của Hợi
        trong lá số, xua đuổi mặt trời. Trong môn Bát Tự thì Hợi xung Tỵ, chứa
        Bính Hoả. Mặt trời trong trường hợp này đại diện bởi Bính Hoả. Bính Hoả
        là Chính Quan Tinh, biểu thị người chồng trong lá số. Vì khi mặt trời
        xuất hiện, ánh sáng sẽ bị phản chiếu (Hợi chứa Nhâm Thuơng Quan, cũng
        như dòng sông phản chiếu ánh sáng mặt trời, do đó có khả năng xua đuổi
        những người theo đuổi). Phụ nữ Tân Hợi cần phải cẩn trọng không để cho
        Thương Quan phát huy sức mạnh trong lá số. Những phụ nữ này cần học cách
        kiểm soát bản tính nóng giận dữ dội và trở nên thấu hiểu và kiên nhẫn
        hơn. Người Tân Hợi có Thương Quan Tinh mạnh, cũng như Nhâm Thuỷ chiếm
        phần lớn địa cầu. Để có hôn nhân tốt cho người nữ này thì Hợi trước tiên
        phải bị dời đi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Có lợi cho trụ ngày này nếu gặp Dần, Ngọ, Tuất và Hoả trong đại vận. Tuy
        nhiên, ngược lại, nếu Thân Tý Thìn và Thuỷ trong đại vận cần phải tránh
        vì sẽ gây xấu về quan hệ tình cảm. Để người này trở nên giàu có thì họ
        cần kết hợp tốt giữa Mão và Hợi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Kết hợp giữa Bính và Tân sẽ có lợi cho nữ giới vì có nghĩa là xinh đẹp.
        Nếu phụ nữ xinh đẹp đủ để hấp dẫn Bính Hoả, Mộc Tài Tinh sẽ phát triển
        và đây là dấu hiệu thịnh vượng và hạnh phúc trong hôn nhân. Trong hoàn
        cảnh này thì bất kỳ người chồng nào cũng sẽ cam kết với vợ và sẽ chân
        thành trong tình cảm. Ảnh hưởng đối với nam giới Tân Hợi sẽ ít may mắn
        hơn vì nó sẽ làm giảm sức mạnh của họ và khiến cho họ kín kẽ hơn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tân toạ Hợi mang theo năng lượng của Nhâm Thuỷ Thương Quan và Giáp Mộc
        Chính Tài. Nước thường hành xử như là tác nhân đánh bóng nhưng trong
        trường hợp này còn là người bảo vệ. Nó cũng là bản đồ kho báu dẫn lối
        cho các thuỷ thủ khác tìm đến biển cả rộng lớn Nhâm Thuỷ. Tân là từ
        trường hấp dẫn tự nhiên được khuếch đại bởi Thương Quan, nó giúp cho bạn
        kêu gọi sự cuốn hút.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đối với nữ giới thì Thương Quan xua đuổi sao đại diện cho người chồng là
        Chính Quan vì Thương Quan khắc Chính Quan. Sự quan tâm và tìm kiếm sự
        chú ý quá mức từ người bạn đời khiến họ xua đuổi người kia đi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đối với nam giới Tân Hợi thì sự xuất hiện của Chính Tài mang đến góc
        nhìn tích cực. Đàn ông sẽ quan tâm và thể hiện tình yêu của mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Hợi hợp với Dần mang theo năng lượng của Giáp Mộc Chính Tài, Bính Hoả
        Chính Quan và Mậu Thổ Chính Ấn. Đây là hành của TÀi, Quan và Ấn mà theo
        Bát Tự truyền thống đem lại sự cao quý. Cả vợ Chính Tài và Chồng Chính
        Quan đều có hiện diện khiến cho cả nam và nữ đều tốt trong tình cảm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Hợi xung với Tỵ mang theo năng lượng của Bính Hoả Chính Quan, Canh Kim
        Kiếp Tài và Mậu Thổ Chính Ấn. Ở đây sao người chồng Chính Quan bị xua
        đuổi đi vốn dĩ không tốt cho lá số nữ giới Tân Hợi nhưng đối với đàn ông
        thì do xua đuổi Kiếp Tài nên có thể tốt cho người vợ là Chính Tài.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sự kết hợp giữa Tân Hợi phù hợp cho nam giới hơn là nữ giới do sự phức
        tạp của sao đại diện cho bạn đời. Người phụ nữ hấp dẫn sẽ tìm kiếm sự
        quan tâm thường khó giữ trong khi người đàn ông biết cách giữ phụ nữ.
        Nhìn chung kết hợp này tạo nên một người khá mạnh mẽ muốn được nhìn
        nhận. Đó là một con thuyền khai phá đầy những của cải châu báu và nếu
        không được quản lý cẩn thận thì một ngày nào đó sẽ nằm ở đáy biển do một
        trong những cuộc hải trình.
      </p>
    </div>
  );
};

export const TanDau = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Tân Dậu: nghiêm túc trong công việc, có thần thái “sếp
        lớn”, có lòng tự tôn cao, cứng đầu, sáng tạo, cách tân, nếu biết rằng có
        ai không ưa mình thì họ sẽ tỏ vẻ không bị ảnh hưởng. Hay dính tới chuyện
        rắc rối về tình cảm. Hôn nhân không hạnh phúc, hay tranh cãi, dễ kết hôn
        nhiều lần. Đối với giới nữ, dễ khiến chồng xui sau năm 40 tuổi.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu một người nào đó cứng hơn cả kim cương? Chắc chắn đó là Tân Dậu lấp
        lánh. Người chủ về sự chắc chắn.
      </p>

      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tân Dậu là toạ trên chính bản thân Tỷ Kiên. Tỷ Kiên đồng nghĩa với tự
        tin. Họ là những người rất yêu bản thân mình. Họ thần tượng bản thân
        mình và không bao giờ thể hiện ra mặt “xấu” (hình ảnh xấu) ra trước công
        chúng. Bạn sẽ để ý rằng Tân Dậu thì hấp dẫn và cuốn hút về ngoại hình.
        Cũng như kim cương luôn được trưng bày trong tủ kính với mặt đẹp nhất.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tân Dậu là chiếc kéo hay lưỡi dao nhọn sắc, hay trang sức lấp lánh bắt
        mắt. Nó biểu thị người Tân Dậu là kết hợp độc đáo giữa thẩm mỹ và đầu
        óc. Không chỉ họ hấp dẫn, họ cũng khôn ngoan và sắc sảo. Người Tân Dậu
        còn quyến rũ, duyên dáng và có tài thuyết phục. Người Tân Dậu cũng có
        khiếu hài hước. Họ thường được kính trọng và hâm mộ vì năng lực và
        thường có tầm nhìn thật sự.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tính hấp tấp và ngẫu hứng khiến cho cuộc sống người Tân Dậu tràn đầy
        hành động. Họ không ngại rủi ro. Thực tế, việc chấp nhận rủi ro là những
        gì làm cho họ hạnh phúc nhất. Đối với họ, thời gian rất quan trọng. Bởi
        vì quý thời gian nên họ muốn nhắm đến tất cả mọi cơ hội trên đời. Bằng
        cách nắm bắt cơ hội cuộc sống, họ có thể chuyển hoá ngay cả tình huống
        tuyệt vọng nhất trở thành câu chuyện thành công.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người này sống trực giác, sáng tạo và có suy nghĩ độc đáo. Một khi
        họ để cho tinh thần sáng tạo tự do, họ cảm thấy thoả mãn nhất. Phụ nữ
        Tân Dậu cũng có xu hướng đầu óc cởi mở và đứng đắn hơn đàn ông cùng trụ
        ngày Tân Dậu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người này dễ thu hút tiền bạc và có giác quan tốt về tài chính.
        Tuy nhiên, điều quan trọng là họ học cách lên ngân sách và đầu tư dài
        hạn. Khi gặp khó khăn, họ không ngại đối đầu. Họ là những chiến binh
        thật sự và sẽ làm tất cả những gì cần thiết để vươn lên đỉnh cao. Họ cần
        phải chấp nhận lời chỉ trích mang tính xây dựng để có thể cải thiện bản
        thân và tránh sai lầm. Thông thường, thay vì chấp nhận lời phê bình xây
        dựng, họ lại trở nên quá nhạy cảm và phản công. Kết quả là họ không thấp
        thu và biết cách tận dụng những lời phê bình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ cần nền tảng vững chắc, và nên tránh hao tổn năng lượng vào quá nhiều
        hướng đi. Bằng cách lắng nghe trực giác và làm việc thật sự siêng năng,
        họ có thể cải thiện cuộc sống và gia tăng cơ hội thành công.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Của Người Tân Dậu: </b>giỏi giao tiếp, thành công,
        sắc bén, siêng năng, hấp dẫn, thích rủi ro, có động lực tài chính, khiếu
        kinh doanh.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Với tri thức, kỷ luật bên trong bản thân, họ có thể thành công trong mọi
        con đường sự nghiệp họ chọn. Đối với họ, không gì định nghĩa thành công
        tốt hơn là sự nghiệp bản thân. Điều quan trọng là họ chọn sự nghiệp cho
        phép sự tự do.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có kỹ năng lãnh đạo bẩm sinh và sẽ dễ dàng leo lên vị trí nắm quyền
        hành vì tính cách năng động.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sức mạnh của họ nằm ở kinh doanh, nghệ thuật, giảng dạy và tài chính.
        Đây là những lĩnh vực sự nghiệp mà họ có thể dễ dàng leo lên vị trí đỉnh
        cao.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Công Việc:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Không bao giờ có một danh sách giới hạn những nghề nghiệp nào người Tân
        Dậu có thể làm. Với quá nhiều kỹ năng và tài năng, họ có thể phù hợp vào
        bất cứ ngành nghề nào.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bản tính giỏi thuyết phục cùng kỹ năng giao tiếp tốt sẽ khiến họ dễ trở
        thành diễn giả, dẫn chương trình hay nhân viên bán hàng giỏi. Những nét
        tính cách này cũng giúp họ làm việc tốt trong ngành quan hệ công chúng
        hay quản lý.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu họ muốn tận dụng kỹ năng suy nghĩ hệ thống, họ có thể làm việc trong
        lĩnh vực khoa học, công nghệ.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Một vài người Tân Dậu có thể sử dụng bản tính nghệ sĩ. Trong những
        trường hợp này, họ sẽ phù hợp với sự nghiệp có liên quan đến nghệ thuật,
        viết lách, phát biểu, ca hát hay bất cứ gì liên quan đế ngành giải trí.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngoài ra, những người này cũng thích tham gia vào lĩnh vực từ thiện hay
        chữa lành.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Trong Sự Nghiệp - Công Việc:</b> sáng tạo, khôi hài,
        kỷ luật, lạc quan, cạnh tranh, trách nhiệm, độc lập, lãnh đạo, cái tôi,
        hống hách, mất kiên nhẫn, thèm khát quyền lực, cứng đầu, thiếu sự tự
        tin.{" "}
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Hôn Nhân - Quan Hệ - Gia Đình:{" "}
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Dậu nhanh nhạy và thích đem lại niềm vui, đó là lý do vì sao
        họ không khó tìm được người hâm mộ. Tuy nhiên, có những tiêu chí mà
        những người theo đuổi người Tân Dậu cần phải thoả mãn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trước tiên, người Tân Dậu muốn người yêu thông minh và có thể khơi gợi
        trí óc. Điều này rất quan trọng vì họ giúp cho người Tân Dậu có đầu óc
        sắc bén và thực tế.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tiếp theo, người Tân Dậu cần người đáng tin cậy và ủng hộ. Điều này giúp
        họ có môi trường dễ chịu và mái ấm tốt đẹp.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngoài ra, người Tân Dậu muốn người yêu là người thành công, quyền lực và
        có khả năng chinh phục để xứng với họ.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Dậu nhanh nhạy và thích đem lại niềm vui, đó là lý do vì sao
        họ không khó tìm được người hâm mộ. Tuy nhiên, có những tiêu chí mà
        những người theo đuổi người Tân Dậu cần phải thoả mãn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Với những yêu cầu như trên, rất khó để tìm được người sống đúng với kỳ
        vọng của người Tân Dậu. Tuy nhiên, tìm được người bạn đời phù hợp rất
        quan trọng với người Tân Dậu vì họ sẽ sớm đánh mất sự hứng thú nếu người
        bạn đời không tham vọng hay thông minh.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Dậu giỏi kết bạn. Họ duyên dáng, thích xã giao và năng động
        trong hoạt động xã hội.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù họ có thể quảng giao, họ thường xem trọng tình bạn và ham muốn có
        những người bạn đúng nghĩa. Vì lẽ này, họ sẽ tìm kiếm bạn bè cũng siêng
        năng và tri thức giống họ.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người nà sẽ thu hút đủ mọi loại người. Họ nên phân biệt ai là bạn
        bè nên kết giao. Điều này sẽ ngăn họ không lãng phí thời gian cho những
        người không thật sự đáng chú tâm.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người Tân Dậu thường hướng đến gia đình, do đó gia đình đóng vai
        trò rất quan trọng đối với người Tân Dậu. Tham vọng và quyết tâm của họ
        đóng vai trò lớn trong đời sống gia đình. Họ muốn điều tốt đẹp nhất cho
        gia đình và sẽ làm việc để đảm bảo điều này. Đây là một trong nhiều lý
        do mà gia đình thường dựa vào họ như điểm tựa khi họ cần sự hỗ trợ và
        định hướng.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Hôn nhân của họ thường tốt đẹp. Họ dễ có người bạn đời thấu hiểu hoàn
        toàn và ủng hộ họ trong mọi hoàn cảnh.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những sự kiện không mong đợi có thể xảy đến trong hôn nhân và làm lấn át
        niềm vui. Nếu họ để điều này xảy ra, nó sẽ đem đến nỗi buồn thương và
        không hạnh phúc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Trong Tình Yêu - Quan Hệ:</b> hấp dẫn bởi người thông
        minh và thành công, hiếu khách, hôn nhân khó khăn, kỳ vọng cao ở bạn
        đời, dễ thay lòng.
      </p>

      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tân toạ Lộc và Thông Căn. Điều này biểu thị người này sẽ khá thông minh
        và có năng lực trong lĩnh vực họ chọn. Họ có tài viết lách và văn
        chương. Người Tân Dậu cần rất ít sự giúp đỡ và hỗ trợ từ người khác để
        đạt được điều này.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Dậu toạ Tỷ Kiên nên họ nhanh nhạy, sắc bén, hoạt bát và hấp
        dẫn. Nếu suy nghĩ hay quan điểm của họ xa lạ với những người xung quanh
        thì họ cũng không cho là vấn đề lớn. Họ rất độc lập và sáng tạo, can đảm
        và tiên phong với động lực khám phá thế giới một mình. Họ có đầu óc sắc
        bén và khiếu thẩm mỹ bẩm sinh, có rất ít điều mà vượt ra khỏi khả năng
        của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Dậu thường đạt được đỉnh cao trong sự nghiệp, đặc biệt nếu bao
        gồm cả nói và viết. Họ thường siêng năng và thông minh cùng kỹ năng phân
        tích mạnh mẽ và giỏi gỉai quyết rắc rối. Họ có khả năng có nền tảng giáo
        dục vững chắc và do đó có kiến thức và nhiều năng lượng, nhiệt tình hay
        ý tưởng thú vị. Họ đặt trọng tâm vào những thành tích trong đời và họ có
        đam mê mãnh liệt đối với thành công về vật chất. Họ đa tài cùng với
        quyết tâm vượt mọi trở ngại trong hành trình theo đuổi mục tiêu và rào
        cản duy nhất thật sự đối với thành tích mong muốn là xu hướng dàn trải
        năng lượng họ quá rộng và đánh mất trọng tâm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Dậu có cá tính mạnh mẽ nhưng cũng có bản chất tình cảm. Tỷ
        Kiên ở Dậu cũng là sao Đào hoa. Điều này đồng nghĩa họ có năng lực cuốn
        hút và hấp dẫn người khác. Họ có thể dễ bị tổn thương đối với sự chỉ
        trích và cần xây dựng niềm tin vào khả năng của chính mình nếu họ muốn
        đạt được tiềm năng tối đa.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Với tình yêu gia đình và khao khát nền tảng vững chắc để có thể mạnh dạn
        xây dựng ý tưởng và khám phá, người Tân Dậu có xu hướng xây dựng mái nhà
        vững chắc. Điều này bởi vì Dậu ám hợp với Thìn, là sao Chính Ấn. Họ có
        khả năng ủng hộ và đôi khi hơi kiểm soát, mặc dù với ý định tốt là muốn
        gia đình của mình tốt hơn nữa.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bài học cuộc sống tốt nhất cho người Tân Dậu thật sự là học ra được cách
        đặt thứ tự ưu tiên mối quan hệ tình cảm và biết cách sử dụng kỹ năng con
        người tốt nhất. Người Tân Dậu rất nhạy cảm và trực giác. Thân thiện và
        thích giúp đỡ, người Tân Dậu thấu hiểu và suy nghĩ sâu sắc. Họ có tài
        khéo léo và là nguồn cảm hứng cũng như sức mạnh cho nhiều người. Họ sẽ
        đạt được sự thoả mãn về tình cảm cao nhất khi giúp người khác có thêm
        sức mạnh và suy nghĩ thấu đáo, tuy nhiên, họ cần phải cố gắng vượt qua
        cái gọi là cái tôi ích kỷ đem đến. Nếu họ có thể dùng trực giác như là
        gợi ý để tìm ra cách liên kết giữa nhu cầu thể hiện cá nhân cùng với
        mong muốn có những mối quan hệ tích cực, họ sẽ tìm được mức độ thoả mãn
        hạnh phúc cao nhất.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ở Tân Dậu thì Lộc của Tân nằm ở Dậu. Nếu có hành Thuỷ xuất hiện mà không
        có Mão thì biểu thị người này có nhiều phẩm chất đáng quý. Miễn là có
        Thuỷ trong lá số, người Tân Dậu có thể sử dụng hiệu quả Mão Địa Chi.
        Điều này biểu thị cá nhân này có duyên với tiền bạc và khả năng thiết
        lập được đế chế kinh doanh của mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bản chất của Tân Dậu luôn xung với Mão và điều này bất lợi lớn cho nam
        giới. Nếu không có Thuỷ, xung giữa Mão và Dậu biểu thị sự phản bội, cảm
        xúc đau lòng và thiếu định hướng trong đời. Hình cũng không tốt cho lá
        số, biểu thị chia tay, trong khi Xung do quá nhiều Mộc biểu thị nguy cơ
        tai nạn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sẽ có lợi nếu trụ này gặp nhiều Kim và Thuỷ trong lá số. Điều này bởi vì
        giúp may mắn về tài chính.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Dậu sẽ hưởng lợi từ sự xuất hiện của Đinh Hoả. Đinh Hoả giúp
        thanh lọc và làm sắc bén Tân Dậu Kim, khiến tỏa sáng rực rỡ, tương tự
        như trang sức sáng chói. Điều này biểu thị danh tiếng và thành công
        trong đời. Tuy nhiên, nếu Tân cắt hành Mộc mà không có Đinh Hoả hay bất
        kỳ hành Thuỷ, biểu thị người này sẽ không đạt được mục tiêu tài chính
        hay là có cái tôi thấp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh vào mùa Xuân và Hạ sẽ có cuộc sống bình an mà không có quá
        nhiều thách thức trong khi người sinh vào mùa Thu và Đông lại có tuổi
        thọ kéo dài.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Tân Dậu sinh vào ban ngày gặp nhiều vất vả khi còn trẻ trước khi
        tìm được thành công vào tuổi trung niên. Người sinh vào ban đêm sẽ rất
        may mắn và có thể đạt thành công và tài lộc. Không tốt cho người Tân Dậu
        sinh vào tháng Tuất. Họ sẽ có thể phải đấu với nhiều tiểu nhân ra sức
        phá hoại thành công của họ. Họ sẽ gặp nhiều khó khăn hơn những người Tân
        khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ thường là người đơn giản và quyết tâm. Họ chỉ làm một việc cho đến
        khi trở thành chuyên gia. Một khi họ đã quyết tâm thì ít khi lùi lại. Có
        thể có người xem đó là sự cứng đầu. Họ rất trân trọng và ủng hộ những
        người giống họ hoặc “bạn bè” có cùng địa vị xã hội. Bạn đừng mù quáng đi
        khen ngợi người Tân Dậu nếu bạn không cùng đẳng cấp của họ, họ giữ vững
        tiêu chuẩn, chỉ có người tốt đẹp nhất mới đến gần.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Dậu thu hút Thìn trong Lục Hợp đem đến Mậu Chính Ấn, Ất Thiên Tài và Quý
        Thực Thần. Họ trân trọng kiến thức, cơ hội và sự sáng tạo. Họ thích học
        hỏi để hoàn thiện bản thân. Bởi vì có Tài Tinh, đối với lá số nam giới
        thì thường cuốn hút phụ nữ vào trong đời nhưng đối với lá số nữ giới thì
        lại không tương tự do Thất Sát, Chính Quan không xuất hiện. Dậu khắc Mão
        trong Lục Xung dời Ất Chính Tài. Bởi vì họ thích những thứ có độ chính
        xác, có nhiều khoảnh khắc phải chờ đợi cho đến khi chúng ta chắc chắn
        đôi khi họ bỏ lỡ mất cơ hội trôi qua. Điều cũng có nghĩa rằng lá số nam
        giới sẽ thua trong việc cạnh tranh theo đuổi phụ nữ vì họ không tìm được
        ai hoàn hảo.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhìn chung, trụ thuần như Tân Dậu rất dễ vì họ đơn thuần. Bạn chỉ cần
        xua tan đi sự cứng đầu của họ thì mọi thứ khác có thể diễn ra. Tân Dậu
        muốn ở trên đỉnh cao, vượt lên trên bản thân họ.
      </p>
    </div>
  );
};
