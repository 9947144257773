export const DinhThangTi = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Đinh trong tháng Tý
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với ngày sinh Đinh Hỏa trong tháng Tý thì hình thành cục Thất Sát
        nếu Quý Thủy hiện lên ở Thiên Can. Ngay cả nếu không có Quý Thủy hiện ở
        Thiên Can thì vẫn có thể hình thành cục này.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với sinh trong tháng Tý thì dụng thần là Giáp Mộc và Canh Kim.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Canh Kim dùng để chặt Giáp Mộc cũng như thu hút Đinh Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Giáp Mộc là dụng thần chính đối với ngày sinh này cùng với Canh Kim là
        dụng thần phụ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu không có cả hai thì Mậu Thổ và Quý Thủy cũng được xem là dụng thần
        thay thế.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nước lạnh giá trong mùa đông trong tháng Tý. Trong hoàn cảnh này thì
        Đinh Hỏa bị cô lập, không được bất cứ gì giúp đỡ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đó là lý do vì sao cần có Mộc nóng để nuôi dưỡng Hỏa Khí, để khắc và làm
        suy yếu Thủy đến mức chấp nhận được. Mộc cũng sẽ sinh ra và kéo dài sự
        liên tục của Hỏa.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Không cần phải nói, nếu không có Mộc và Hỏa thông căn thì không thể để
        Đinh Hỏa có thể tiếp tục cháy vào thời điểm này trong năm.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Cần phải lưu ý rằng trong tháng Tý thì Thủy vượng nhất do đó sẽ làm suy
        yếu Hỏa Khí.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Kim lại khắc và làm suy yếu Mộc trong khi Thủy kiểm soát Hỏa mạnh. Do đó
        không tốt nếu lá số này trải qua đại vận Kim hay Mộc.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Giáp Mộc Chính Ấn xuất hiện ở Thiên Can thì Đinh Hỏa sẽ có cuộc
        sống thoải mái. Nếu không có Giáp Mộc thì sẽ chỉ trung bình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Giáp Mộc là dụng thần chính cho người sinh trong tháng Hợi Tý Sửu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Bính Hỏa nên hiện diện như là dụng thần quan trọng nhưng không nên
        xuất hiện ở Thiên Can. Cần phải tránh gặp Bính Hỏa ở Thiên Can.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi có ít nhất một bính Hỏa ở Thiên Can thì cần có Kim và Thủy để kiểm
        soát Bính Hỏa.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Bính Hỏa cướp mất ánh sáng của Đinh Hỏa thì ngày sinh này thiếu
        mục tiêu và định hướng trong đời, đặc biệt nếu Kim và Thủy hoàn toàn
        vắng bóng trong lá số.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi có Kim nhưng thiếu Thủy thì ngày sinh này sẽ nghèo bất kể họ học
        rộng bao nhiêu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi có Nhâm Thủy nhưng thiếu Kim thì ngày sinh này sẽ thiếu quyền lực
        thật sự bất kể địa vị xã hội.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi có 2 Nhâm Thủy ở tháng và giờ và cạnh tranh với nhau để hợp hóa
        Đinh Hỏa, Mậu Thổ có thể được dùng để hóa giải Nhâm Thủy. Ngay cả với
        Mậu Thổ hiện diện thì ngày sinh này cũng chỉ sống cuộc đời trung bình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Mậu Thổ không xuất hiện ở Thiên Can thì ngày sinh này sẽ có cuộc
        đời tầm thường.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Mậu Thổ xuất hiện ở tàng can Địa Chi, ngày sinh này có cuộc sống
        trung bình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Giáp Mộc là dụng thần chính của ngày sinh Đinh trong tháng mùa đông.
        Cần phải kèm với Canh Kim. Nếu không có Canh Kim thì Giáp Mộc không thể
        sinh Hỏa.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Chính Ấn và Chính Tài</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Giáp Mộc cần xuất hiện nếu lá số sử dụng dụng thần Đinh Hỏa. Nếu không
        thì người này sẽ cô đơn và bệnh tật trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Giáp Mộc xuất hiện ở Thiên Can thì sẽ đóng vai trò để Ất Mộc đeo
        bám.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu không có Giáp Mộc thì ngày sinh này sẽ sở hữu tính cách vô lương
        tâm. Bởi vì Ất Mộc thiếu hỗ trợ của Giáp Mộc và do bản chất Âm thì sẽ có
        xu hướng “đong đưa”.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu có cục Chính Ấn:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Giáp Mộc là dụng thần số 1. Nếu có thì ngày sinh này sẽ hưởng thành công
        trong sự nghiệp.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lý tưởng nếu Giáp Mộc xuất hiện ở Thiên Can và có Canh Kim trong lá số.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Xấu nếu như Kỷ Thổ xuất hiện ở Thiên Can và hợp với Giáp để tạo thành
        Thổ.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có cục Chính Quan:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Không có lợi nếu gặp 2 Nhâm Thủy ở Thiên Can. Điều này bởi vì cả hai
        Chính Quan sẽ cạnh tranh để hợp hóa với Đinh Hỏa. Nếu vậy thì người này
        sẽ có cuộc sống trung bình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Mậu Thổ để trung hòa Nhâm Thủy là tốt nhất. Lá số xấu nếu không
        có Mậu Thổ.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Chính Ấn và Thiên Tài:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi có Hợi Mão Mùi ở Địa Chi thì ngày sinh này vẫn sẽ thành công trong
        đời, mặc dù phần lớn những thành công đều đến từ người khác giúp đỡ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tốt nếu có Giáp Ất Mộc ở Thiên Can. Xấu nếu như không có Mộc ở Thiên
        Can.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Giáp Mộc nên đi kèm với Canh Kim để có thể trở thành dụng thần hữu
        ích.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Không thể thay thế Giáp Mộc và Canh Kim bằng Ất Mộc và Tân Kim.
      </p>
    </div>
  );
};

export const DinhThangSuu = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Đinh trong tháng Sửu
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngày sinh Đinh trong tháng Sửu thì nếu Kỷ Thổ hiện ở Thiên Can thì hình
        thành cục Thực Thần.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Tân Kim xuất hiện ở Thiên Can thì hình thành cục Thiên Tài.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Quý Thủy ở Thiên Can thì hình thành cục Thất Sát.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với sinh trong tháng Sửu thì Giáp Mộc và Canh Kim là dụng thần điều
        hậu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Canh Kim dùng để chặt Mộc cũng như thu hút Đinh Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Điều này sẽ giúp cho Giáp Mộc thật sự là dụng thần chính của ngày sinh
        này trong khi CanhKim là dụng thần thứ hai.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu không có những điều trên thì Mậu Thổ và Quý Thủy là dụng thần thay
        thế.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Thủy vốn mạnh trong tháng Sửu tạo ra Thổ ướt. Kim khí càng gia tăng thêm
        hàn lạnh của môi trường. Và điều này càng làm suy yếu thêm Đinh Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngay cả với Thổ hiện diện thì khí lạnh giá sẽ rất khó sinh ra được Mộc
        vào thời điểm này. Kết quả là Mộc Ấn Tinh sẽ bị ướt.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đó là lý do vì sao Hỏa cần để kiểm soát Kim và đem lại hơi ấm. Trong lúc
        đó thì Mộc được dùng để sinh ra Hỏa, giúp củng cố ngỳ sinh.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Mộc nên đi kèm với Hỏa Khí cũng như được thông căn để là dụng thần cho
        ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Chỉ với Mộc và Hỏa Khí thì ngày sinh này đủ mạnh và được cân bằng. Ngoài
        ra, ngày sinh này cũng cần tránh gặp Kim và Thủy Khí, càng nhiều càng
        tốt.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Điều này bởi vì Kim và Thủy có khả năng làm suy yếu Mộc và Hỏa. Do đó sẽ
        ảnh hưởng xấu đến tài lộc và sức khỏe.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có Giáp Mộc Ấn Tinh ở Thiên Can thì ngày sinh này sẽ ít nhất có
        cuộc sống thoải mái.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Giáp Mộc đóng vai trò dụng thần điều hậu chính cho ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Bính Hỏa nên tránh xuất hiện ở Thiên Can mà chỉ cần là chính khí ở
        tàng can Địa Chi là được.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có một Bính Hỏa xuất hiện ở Thiên Can thì Kim và Thủy cần có để
        kiểm soát Bính Hỏa.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi có quá nhiều Bính Hỏa sẽ đoạt hào quang của Đinh thì ngày sinh này
        mất định hướng trong đời. Người này sẽ cảm thấy bị bạn bè và người thân
        vượt mặt.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi có Kim nhưng thiếu Thủy thì ngày sinh này vẫn sẽ nghèo và trầm
        cảm, bất kể họ học rộng bao nhiêu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi có Nhâm Thủy nhưng thiếu Kim thì ngày sinh này thiếu quyền lực
        thật sự, bất kể họ học rộng bao nhiêu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi có 2 Nhâm Thủy ở Tháng và Giờ và tranh hợp với Đinh Hỏa thì Mậu
        Thổ được dùng để kiểm soát Nhâm Thủy. Ngay cả với Mậu Thổ xuất hiện thì
        ngày sinh này sẽ trung bình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Mậu Thổ không xuất hiện ở Thiên Can thì ngày sinh này chỉ có thành
        công trung bình. Họ sẽ luôn cảm thấy bất mãn và bất ổn trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Mậu Thổ là một trong những tàng can ở Địa Chi thì ngày sinh này có
        cuộc sống bình thường. Nếu không có Mậu Thổ thì ngày sinh này không thể
        xuất sắc và có cuộc sống hoài nghi bản thân.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Ấn và Tỷ Kiếp bị thiếu thì với Kim và Thủy hiện diện nhiều sẽ dẫn
        đến cục Tòng Quan, ngày sinh này sẽ có địa vị và quyền lực cao.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Giáp Mộc là dụng thần chính giúp cho ngày sinh Đinh Hỏa trong tháng
        mùa đông. Cần phải dùng Canh Kim kèm để thật sự đóng vai trò dụng thần
        cho ngày sinh này.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Khi lá số có Chính Ấn và Chính Tài:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Giáp Mộc là dụng thần quan trọng cho ngày sinh này. Nếu có thì ngày sinh
        sẽ có thể thành công về sự nghiệp.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tốt nếu như Giáp Mộc ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Xấu nếu có Kỷ Thổ xuất hiện ở Can và hợp hóa.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Khi lá số có Chính Quan:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Sẽ bất lợi nếu có 2 Nhâm Thủy cùng xuất hiện ở Thiên Can. Bởi vì 2 Chính
        Quan sẽ cạnh tranh để hợp hóa Đinh Hỏa. Khi đó thì người này thiếu định
        hướng trong cuộc ống và thường gặp tình huống tiến thoái lưỡng nan.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Sự hiện diện của Mậu Thổ để trung hòa 1 Nhâm Thủy là tốt. Nếu 2 Nhâm
        Thủy kẹp thì xấu.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Khi lá số có cục Kiếp TÀi:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi có 1 Bính Hỏa ở Thiên Can thì ngày sinh này chỉ trung bình. Nếu 2
        Bính Hỏa ở Thiên Can và kèm một Quý Thủy thì ngày sinh này sẽ có những
        cú lội ngược dòng kỳ lạ, trở nên nổi tiếng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tốt nếu như 2 Bính Hỏa và một Quý Thủy. Xấu nếu không có Quý Thủy.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Khi lá số có Chính Ấn và Thiên Ấn:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Hợi Mão Mùi hiện diện ở Địa chi đủ thì ngày sinh này vẫn thành công
        nhưng phần lớn là nhờ người khác giúp đỡ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tốt nếu có Giáp và Ất lộ Thiên Can. Xấu nếu Giáp và Ất không có ở Thiên
        Can.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Giáp Mộc phải đi kèm với Canh Kim thì mới hữu dụng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Sẽ không thể thay thế dụng thần Giáp Mộc và Canh Kim bằng Ất Mộc và
        Tân Kim.
      </p>
    </div>
  );
};

export const DinhThangDan = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Đinh trong tháng Dần
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngày sinh Đinh trong tháng Dần thì dễ hình thành cục Chính Ấn nếu có
        Giáp Mộc ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Mậu Thổ ở Thiên Can thì hình thành cục Thương Quan.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Bính Hỏa ở Thiên Can thì hình thành cục Dương Nhẫn nếu thỏa các
        điều kiện.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dụng Thần Điều Hậu là Giáp Mộc và Canh Kim.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Canh Kim dùng để kiểm soát Giáp Mộc và Giáp Mộc là chất dẫn Đinh Hỏa.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Giáp Mộc là Chính Ấn đối với ngày sinh Đinh Hỏa. Ngày sinh này sẽ thích
        nhìn thấy Kim cắt Mộc và để đốt Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Để có thể ngăn chuyển hóa thành Hỏa Cục khi Mộc và Hỏa xuất hiện nhiều
        trong lá số thì cần Thủy để cung cấp độ ẩm.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Để đạt được mục tiêu này thì Kim cần dùng để sinh cho Thủy thay vì kiểm
        soát Mộc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngoài ra, nó cũng giúp ngăn Đinh Hỏa và Nhâm Thủy hình thành Mộc.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Canh Kim và Nhâm Thủy là dụng thần điều hậu cho ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Giáp Mộc mạnh trong tháng Dần. Đó là lý do vì sao Canh Kim cần xuất
        hiện ở Thiên Can để có thể kiểm soát Giáp Mộc. Chỉ khi đó thì Nhâm Thủy
        mới có thể đóng vai trò Dụng Thần thứ hai.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Giáp Mộc là Chính Ấn rất mạnh đối với ngày sinh này. Canh Kim là Chính
        Tài có thể dùng để sinh ra Nhâm Thủy vốn là Chính Quan của ngày sinh
        này.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có Nhâm Dần ở trụ giờ sinh thì có thể Đinh và Nhâm hợp để hóa
        thành Mộc. Hóa hợp này sẽ xảy ra miễn là Canh Kim không xuất hiện trong
        lá số.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có 2 Nhâm Thủy xuất hiện để tranh hợp với Đinh thì Canh Kim sẽ cần
        phải có mặt ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Giáp Mộc xuất hiện ở Thiên Can và có căn gốc ở Địa Chi, Canh Kim
        có căn gốc phải nhìn thấy ở Thiên Can. Nếu thiếu Canh Kim thì ngày sinh
        này sẽ bị ảnh hưởng bởi nghèo đói và sức khỏe kém cả đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có Giáp Ất Mộc (là Ấn Tinh của ngày sinh này) xuất hiện ở tàng can
        trong Địa Chi và thiếu Canh Kim thì ngày sinh này sẽ bỏ đi xa để mưu
        sinh. Người này sẽ cảm thấy khó để bắt đầu mối quan hệ hay xây dựng gia
        đình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Địa Chi hình thành Hỏa Cục đầy đủ và Thủy bị thiếu trong lá số thì
        ngày sinh này sẽ bị cô đơn và vất vả trong đời.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có cục Chính Quan:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Giáp mộc cần thiết xuất hiện ở Thiên Can nếu Nhâm Thủy mạnh trong lá số.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tốt nếu đầy đủ cục xuất hiện.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Xấu nếu Canh Kim xuất hiện ở Thiên Can.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Chính Quan và Thất Sát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Nhâm Thủy trở nên quá mạnh thì sẽ chuyển hóa Chính Quan thành Thất
        Sát.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu không có Canh Kim thì ngày sinh này sẽ nghèo, vất vả và bệnh tật.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Canh Kim (Thiên Tài) có Thiên Can và Địa chi thì lá số tốt.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu thiếu Canh Kim thì không tốt.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Chính Ấn và Thiên Ấn:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu không có Canh Kim Thiên Tài xuyên thấu ở Thiên Can thì ngày sinh này
        sẽ nghèo.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Ất Mộc hiện diện số lượng nhiều thì ngày sinh này có thể lang
        thang vô định, không định hướng cuộc đời trong suốt cả đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số tốt nếu Canh Kim thông căn và xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số xấu nếu thiếu Canh Kim.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Canh Kim là yếu tố quyết định đối với Đinh Hỏa sinh trong tháng Dần.
        Chất lượng của cuộc sống phụ thuộc vào Canh Kim. Nếu thiếu Canh Kim thì
        cuộc sống trung bình.
      </p>
    </div>
  );
};

export const DinhThangMao = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Đinh trong tháng Mão
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Dễ hình thành cục Thiên Ấn nếu có Ất Mộc lộ ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngay cả nếu như Ất Mộc không lộ Thiên Can thì khả năng tạo thành cục
        Thiên Ấn cũng cao.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Canh Kim và Giáp Mộc là Dụng Thần Điều Hậu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Canh Kim dùng để kiểm soát Ất Mộc. Trong lúc đó thì Giáp Mộc dùng để
        sinh ra Đinh Hỏa liên tục.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Mộc vượng nhất vào thời gian này. Kim nên được chọn là Dụng Thần để giữ
        Mộc Khí trong tầm kiểm soát. Trong lúc đó thì Thủy có thể được dùng để
        cung cấp độ ẩm cũng như giảm nhiệt và độ khô do ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Thủy, trong tháng Mão, có khả năng sinh ra Mộc.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngày sinh này khi mà Hỏa và Thổ xuất hiện nhiều cũng đòi hỏi Thổ ẩm ướt
        làm suy ướt Hỏa, và sinh ra Kim.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Canh Kim là dụng thần chính cho ngày sinh Đinh Hỏa trong tháng Mão với
        Giáp Mão đóng vai trò Dụng Thần thứ hai.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Không tốt nếu tìm thấy thêm hành Hỏa xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Canh Kim và Ất Mộc xuất hiện bên cạnh nhau, sự kết hợp để tạo ra
        Kim bắt đầu. Nếu hợp thành công thì xảy ra kết quả không mong đợi. Do
        đó, sẽ phù hợp để người này trải qua đại vận Mộc hay Hỏa Cục để có thể
        ngăn việc sinh ra Kim Cục. Khi người này trải qua đại vận Kim và Thủy
        thì cuộc sống đầy gian nan, vất vả.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Không tốt nếu Ất Mộc lộ Thiên Can. Người này sẽ có cơ hội thành công
        trong đời nếu Ất Mộc không xuất hiện ở Thiên Can. Nếu có sự hiện diện
        mạnh mẽ của Ất Mộc thì người này mặc dù có được tài lộc trong suốt cuộc
        đời nhưng tài lộc không dài lâu.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Quý Thủy xuất hiện ở Thiên Can nhưng Kỷ Thổ bị thiếu trong lá số
        thì ngày sinh này sẽ nghèo và nhiều lo lắng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Sẽ là kịch bản tốt nhất cho ngày sinh này nếu Địa Chi thành hình Hỏa
        Cục trong khi Canh Kim xuất hiện ở Thiên Can. Nếu không có Canh Kim, tuy
        nhiên ngày sinh này sẽ bị ảnh hưởng bởi khó khăn, vất vả vả thậm chí
        nghèo đói.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Chính Tài và Chính Ấn:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Canh Kim và Giáp Mộc đều xuất hiện ở Thiên Can thì ngày sinh này sẽ
        hưởng thành công trong việc theo đuổi sự nghiệp.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Canh Kim lộ nhưng không có Giáp Mộc thì ngày sinh này sẽ thuộc về
        người giỏi giang nhưng không giàu có lắm.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Giáp Mộc lộ nhưng không có Canh Kim thì ngày sinh này sẽ chỉ sống
        cuộc đời trung bình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Sẽ tốt hơn nếu có Quý Thủy xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Không tốt nếu có Nhâm Thủy đứng cạnh Đinh Hỏa, khiến dễ xảy ra Hợp.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Thiên Ấn:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ất Mộc ở Thiên Can và hợp với Canh kim tạo thành Kim.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Ất Mộc tàng can ở Địa Chi thì vẫn sẽ có lợi cho ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Ất Mộc nhưng thiếu Canh Kim thì ngày sinh này sẽ bị ảnh hưởng bởi
        khó khăn và nghèo khó trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số tốt nếu có lộ Canh Kim nhưng Ất Mộc ẩn tàng, và vẫn sẽ có lợi cho
        ngày sinh này nếu bước vào đại vận Mộc hay Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Ất Mộc xuất hiện ở Thiên Can thì ngày sinh này sẽ nghèo khi bước vào
        đại vận Kim hay Thủy.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có cục Thất Sát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Quý Thủy hiện lên ở Thiên Can, cần có Mậu Thổ hay Kỷ Thổ hiện ra để
        kiểm soát Quý Thủy. Nếu không thì ngày sinh này sẽ bị ảnh hưởng bởi việc
        thiếu mục tiêu sống và bản ngã dẫn đến nghèo khó.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Mậu Thổ và Kỷ Thổ lộ ở Thiên Can là tốt.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Xấu nếu không có Mậu Thổ và Kỷ Thổ lộ ở Thiên Can.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có cục Kiếp Tài – Tỷ Kiên:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Canh Kim xuất hiện thì lá số ngày sinh này sẽ là lá số tốt. Thay vào
        đó, nếu không có Canh Kim thì lá số Bát Tự này sẽ là lá số kém cỏi.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Canh Kim là Chính Tài xuất hiện ở Thiên Can là lá số tốt.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Ất Mộc Thiên Ấn không nên lộ ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Canh Kim nên có mặt và hiện diện như là Dụng Thần để giúp người này
        thành công trong đời.
      </p>
    </div>
  );
};

export const DinhThangThin = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Đinh trong tháng Thìn
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với người sinh trong tháng Thìn thì hình thành cục Thương Quan khi
        Mậu Thổ xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Ất Mộc xuất hiện ở Thiên Can thì hình thành Thiên Ấn Cục.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Quý Thủy xuất hiện ở Thiên Can thì hình thành Thất Sát Cục.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đinh Hỏa sinh trong tháng Thìn thì Giáp Mộc và Canh Kim là dụng thần
        Điều hậu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Giáp Mộc được dùng để tạo ra Đinh Hỏa và kiểm soát Thổ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Canh Kim có thể được dùng như là Dụng Thần thứ hai khi Mộc mạnh. Canh
        Kim nên được dùng để kiểm soát Mộc Khí.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tương tự, nếu Thủy mạnh, Mậu Thổ sẽ cần để kiểm soát nó.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Địa chi Thìn vốn tàng can Mậu Thổ. Điều này làm cho Đinh Hỏa Khí suy yếu
        vì Hỏa sinh Thổ. Cách tốt nhất để làm giảm tình hình này là không sử
        dụng Mộc để kiểm soát Thổ cũng như để sinh ra Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngày sinh này sẽ có tiềm năng để hợp hóa Mộc. Nếu hợp hóa thành công thì
        hứa hẹn thành công tột bậc. Nhưng cũng còn phụ thuộc nhiều vào trụ giờ.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Mộc mạnh và có sự hiện diện đầy đủ của Kim Khí thì người này sẽ giàu
        có trong đời.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Giáp Mộc là dụng thần số 1 đối với người Đinh Hỏa sinh trong tháng
        Thìn trong khi Canh Kim đóng vai trò Dung Thần số 2.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Canh Kim và Giáp Mộc cùng xuất hiện ở thiên can, thì ngày sinh này
        sẽ có một cuộc đời siêu việt, giàu có và thoải mái.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi có Dần, Mão và Thìn tạo thành Tam Hội Mộc thì nên có Canh Kim xuất
        hiện ở Thiên Can. Cùng lúc thì không nên có Đinh Hỏa xuất hiện ở Can
        Ngày, bởi vì nó kiểm soát Canh Kim.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi có Thân Tý Thìn tạo thành Thủy Cục và Mậu Thổ cũng như Kỷ Thổ
        không xuất hiện ở Thiên Can thì ngày sinh này có thể nghèo và sức khỏe
        kém.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu không có Thủy Khí ở Địa Chi thì sẽ không cần Mậu Thổ và Kỷ Thổ.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số Chính Ấn và Chính Tài:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Giáp Mộc và Canh Kim đồng xuất hiện thì ngày sinh này sẽ hưởng thành
        công trong sự nghiệp.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Đinh Hỏa xuất hiện ở Thiên Can là không tốt cho lá số.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Chính Quan hoặc Thất Sát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Thân Tý Thìn tạo thành Thủy Cục thì ngày sinh này sẽ vượng trong
        đời, và nếu cùng lúc đó có Mậu Thổ và Kỷ Thổ ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu không có Mậu Thổ và Kỷ Thổ xuất hiện thì ngày sinh này sẽ không
        hưởng cuộc sống tốt.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Mậu Thổ và Kỷ Thổ cùng hiện ở Thiên Can là lá số tốt. Còn nếu chỉ có một
        trong hai xuất hiện thì ngày sinh này có thể không khai thác hết tiềm
        năng của mình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số xấu nếu không có Mậu Thổ và Kỷ Thổ. Ngay cả nếu có Mậu và Kỷ Thổ
        xuất hiện ở Thiên Can thì vẫn không ổn nếu có Giáp Mộc ở Thiên Can.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Sẽ không tốt cho ngày sinh này nếu lá số có quá nhiều Thủy.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Chỉ có Mậu Thổ và Kỷ Thổ có thể hoa giải nhiều Thủy khí.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi có Canh Kim và Tân Kim xuất hiện thì ngày sinh này sẽ cũng cần
        tránh gặp Quý Thủy Thất Sát.
      </p>
    </div>
  );
};

export const DinhThangTy = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Đinh trong tháng Tỵ
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với ngày sinh Đinh Hỏa trong tháng Tỵ thì hình thành cục Chính Tài
        nếu có Canh Kim lộ Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Mậu Thổ lộ Thiên can thì hình thành cục Thương Quan.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Bính Hỏa lộ trên Thiên Can, hình thành cục Dương Nhẫn khi thỏa các
        điều kiện.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Cần Giáp Mộc và Canh Kim là dụng thần điều hậu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Giáp Mộc cần để sinh ra Đinh Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Giáp Mộc xuất hiện nhiều, thì Canh Kim sẽ cần được sử dụng làm dụng
        thần chính để kiểm soát Giáp Mộc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Thủy cần được chọn là Dụng Thần Điều Hậu khi cần có độ ẩm trong tháng
        mùa hè này.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Hỏa rất vượng trong tháng mùa hè. Kim và Thủy sẽ trở nên khô trong mùa
        này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Do đó, Thủy sẽ cần để làm ẩm Đinh Hỏa.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Canh Kim xuất hiện ở Địa chi Tỵ có thể được chọn làm dụng thần để có thể
        sinh ra và đảm bảo sự liên tục của Thủy.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Không tốt cho ngày sinh này nếu gặp thêm Hỏa khí. Mộc nên tránh, bởi vì
        nó sinh ra và củng cố Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Thổ ẩm ướt sẽ trợ lực cho ngày sinh này. Chỉ khi người này nếu có Kim
        thì sẽ tránh cho ngày sinh này quá khô.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngày sinh này trong giờ mà Kim và Thủy vượng sẽ cảm thấy dễ dàng để sử
        dụng Kim trong đời. Trong tình huống này thì ngày sinh sẽ được hưởng
        danh tiếng và may mắn trong đời.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Giáp Mộc và Canh Kim là dụng thần lý tưởng cho ngày sinh Đinh Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Sẽ không tốt nếu có Quý Thủy xuất hiện ở Thiên Can, tuy nhiên nếu xuất
        hiện ở trong Địa Chi thì được.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Hỏa Khí vượng trong tháng Tỵ. Khi Bính Hỏa lộ và thông căn thì Nhâm
        Thủy và Canh Kim là dụng thần lý tưởng. Kịch bản này còn được gọi là
        Bính Hỏa cướp ánh sáng lấp lánh của Đinh Hỏa. Khi kịch bản này xảy ra
        thì người Đinh Hỏa bị người khác cướp hào quang.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Mậu Thổ và Kỷ Thổ xuất hiện nhiều khiến cho khí của lá số trở nên
        khô thì Thủy khí sẽ rất cần. Nếu thấy kịch bản này thì ngày sinh này sẽ
        là người có tri thức. Tuy nhiên, người này sẽ cảm thấy khó đạt danh
        tiếng và địa vị trong đời dù rất thông minh. Điều này sẽ dẫn đến bất mãn
        trong đời.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Ất Mộc – Thiên Ấn – lộ ở Thiên Can thì ngày sinh này chỉ có cuộc
        đời trung bình.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Thương Quan và Chính Tài:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Mậu Thổ Thương Quan sinh được cho Kim Tài Tinh thì ngày sinh này sẽ
        thịnh vượng và giàu có.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lý tưởng nếu như có Canh Kim thông căn trong lá số.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Bất lợi nếu như Ất Mộc lộ Can, ngày sinh này sẽ có cuộc sông trung bình.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Kiếp Tài:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Bính Hỏa lộ ở Thiên Can trong tháng mùa hè thì Bính Hỏa sẽ cướp hào
        quang của Đinh Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu không có Nhâm Thủy và Quý Thủy xuất hiện thì ngày sinh này sẽ nghèo.
        Người này sẽ thường xuyên cảm thấy bị qua mặt bởi người khác.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số tốt nếu Nhâm Thủy và Quý Thủy xuất hiện ở Thiên Can. Kịch bản tốt
        nhất là có Giáp Mộc và Bính Hỏa xuất hiện cùng lúc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số kém nếu vào đại vận Kim hay Thủy.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Bính Hỏa không nên lộ ở Thiên Can. Nếu không thì Nhâm Thủy và Quý Thủy
        cũng phải lộ ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Bính Hỏa không lộ ở Thiên Can, Nhâm Thủy và Quý Thủy cũng không
        nên xuất hiện ở Thiên Can.
      </p>
    </div>
  );
};

export const DinhThangNgo = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Đinh trong tháng Ngọ
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngày sinh Đinh trong tháng Ngọ thì Địa Chi Ngọ cũng là vị trí Đế Vượng
        của Đinh Hỏa. Khi Đinh Hỏa hiện lên ở Thiên Can thì cục Lâm Quan hình
        thành khi đủ các điều kiện.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với tháng Ngọ thì Nhâm Thủy, Canh Kim và Quý Thủy là dụng thần điều
        hậu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Thủy xuất hiện nhiều và Canh Kim cũng như Nhâm Thủy cũng xuất hiện
        thì ngày sinh này sẽ hưởng thịnh vượng trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu thiếu Nhâm Thủy thì có thể dùng Quý Thủy thay thế. Tuy nhiên, sẽ
        không tốt bằng, nếu so với Nhâm Thủy là dụng thần.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đinh Hỏa được xem là mạnh nhất trong tháng Ngọ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngày sinh này sẽ tương xung với bất kỳ Thủy nào. Và với Hỏa vượng thì sẽ
        tan chảy và làm suy yếu Kim nếu gặp.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Do đó, nếu cả Kim và Thủy có thông căn thì sẽ không đem lại sự cân bằng
        cho ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người sinh vào giờ có Mộc và Hỏa mạnh nên có Thổ ẩm ướt như là Dụng Thần
        Phù Ức. Điều này bởi vì Thổ ướt sinh ra và hỗ trợ Kim, cũng như làm suy
        yếu Hỏa, vốn đã quá mạnh.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Thủy đại diện cho Cung Phu Thê hay Quan Tinh đối với lá số nữ giới. Đây
        là lý do vì sao Kim cần sinh cho Thủy. Đây cũng là lý do vì sao cần phải
        chú ý đến Đinh Hỏa và Nhâm Thủy hợp hóa thành Mộc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngoài ra, nếu nhiều Hỏa Khí xuất hiện thì sẽ khó để Thủy bền bỉ. Tuy
        nhiên, ngày sinh này sẽ được hưởng danh tiếng và may mắn trong đời, miễn
        là tạo thành cục Lâm Quan.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nhâm Thủy nên xuất hiện ở Thiên Can. Kịch bản tốt nhất là có Nhâm Thủy
        ở Thiên Can tháng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Kịch bản lý tưởng là ngày sinh này sinh vào giờ Hợi, hay là ngày Đinh
        Hợi.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Sự xuất hiện của can Đinh Hỏa sẽ tùy thuộc vào mối quan hệ với Nhâm
        Thủy. Sẽ không tốt nếu Nhâm Thủy là Dụng Thần, có Mậu Thổ và Kỷ Thổ xuất
        hiện ở Thiên Can.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi có Bính Hỏa xuất hiện ở Thiên Can, sẽ cướp đi hào quang tỏa sáng
        của Đinh Hỏa. Người này sẽ gặp nhiều tình huống mà bị người khác vượt
        qua.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Sẽ không cần thiết có Dần Ngọ Tuất xuất hiện thì mới có thể tạo thành
        Hỏa cục mạnh. Miễn là có 2 đến 3 Địa chi Ngọ trong lá số thì có thể xem
        như là hình thành Hỏa Cục.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu trong lá số có Chính Tài và Chính Quan:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có cả Canh Kim và Nhâm Thủy cùng xuất hiện thì ngày sinh này sẽ
        hưởng thành công chắc chắn trong sự nghiệp.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Canh Kim và Nhâm Thủy xuất hiện ở Thiên Can thì lá số tốt.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Canh Kim ẩn tàng trong khi Kỷ Thổ xuất hiện thì lá số không tốt.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu trong lá số có Chính Ấn và Thiên Ấn:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Hợi và Mão tạo thành Mộc Cục thì ngày sinh này sẽ có cuộc sống trung
        bình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Người này sẽ có thể không hòa hợp với con cái.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số tốt nếu Giáp Mộc xuất hiện ở Thiên Can. Thổ ướt xuất hiện ở lá số.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Xấu nếu Canh Kim xuất hiện ở bên cạnh Giáp hay Ất Mộc.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có cục Kiếp Tài:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Bính Hỏa xuất hiện ở Thiên Can và đánh cắp ánh hào quang của Đinh
        Hỏa. Tất cả mọi Địa Chi ủng hộ Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngày sinh này sẽ may mắn nếu bước vào đại vận Mộc hay Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngày sinh này sẽ không tốt nếu rơi vào đại vận Kim hay Thủy.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Thủy cần thiết để giúp kiểm soát Bính Hỏa. Nếu không có Thủy thì ngày
        sinh này sẽ cô đơn hoặc có xu hướng sống ẩn dật. Đây là lý do vì sao
        Thủy sẽ phải hiện diện ở lá số, nếu không thì ngày sinh này sẽ vất vả để
        tìm sự giúp đỡ, nhất là khi trải qua đại vận Bính Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Bính Hỏa xuất hiện ở Thiên Can và Giáp Mộc cũng như Nhâm Thủy xuất
        hiện thì cục Hỏa phải hình thành. Nếu như ngày sinh này bước vào đại vận
        Kim hay Thủy, thì người này sẽ bị tai họa nghiêm trọng trong đời. Người
        này sẽ cô đơn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi ngày sinh này gặp Ấn Cục thì người này sẽ kiếm sống trên trung
        bình, cao hơn mức đủ ăn nhiều. Tuy nhiên, người này sẽ không gặp may mắn
        về tình cảm với con cái.
      </p>
    </div>
  );
};

export const DinhThangMui = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Đinh trong tháng Mùi
        </h3>
      </div>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với ngày sinh Đinh trong tháng Mùi, nếu Kỷ Thổ hiện lên Thiên Can
        thì dễ định cục Thực Thần.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Ất Mộc ở Thiên Can thì hình thành cục Thiên Ấn.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Đinh Hỏa ở Thiên Can thì hình thành cục Lâm Quan nếu thỏa mãn một số
        điều kiện.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngoài ra có thể chọn các cục khác nếu những thiên can trên không xuất
        hiện.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với sinh trong tháng Mùi, thì Giáp Mộc, Nhâm Thủy và Canh Kim là
        dụng thần điều hậu
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi xét Dụng Thần Điều Hậu thì Giáp Mộc giúp giảm bớt Nhâm Thủy và tăng
        cường Đinh Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Giáp Mộc nên dùng kèm với Canh Kim vốn có thể giúp cho Giáp Mộc.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Mặc dù Mùi Thổ đóng vai trò là khố cho Mộc Khí, Thổ rất vượng vào thời
        điểm này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Trong hoàn cảnh này thì Hỏa sẽ có xu hướng yếu hơn là được tăng cường.
        Nói cách khác, Hỏa Khí sẽ bị giảm dần.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Mộc, vốn là Ấn Tinh, là dụng thần chính, bởi vì nó sinh ra Hỏa. Chỉ khi
        đó thì Thủy mới có thể dùng để cung cấp độ ẩm cho Thổ khô.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Kim và Thủy - ở mức độ vừa phải sẽ thêm vào cho ngày sinh này vốn sinh
        ra ở giờ mà Mộc và Hỏa vượng, thì Đinh Hỏa sẽ không bị dập tắt. Chỉ khi
        đó thì người này mới giàu có trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Sự hiện diện hay thiếu vắng của Thủy cũng sẽ quyết định vận may người
        chồng của lá số nữ giới. Nếu Kim quá nhiều thì sẽ làm ảnh hưởng và khắc
        chế quá mạnh hành Mộc – vốn là dụng thần của ngày sinh này.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Trong Ngũ Hành thì Giáp Mộc là dụng thần chính của Đinh Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Ngoài ra, Nhâm Thủy đóng vai trò Dụng Thần Điều Hậu của ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Lý tưởng nhất cho lá số này là có Hợi Mão Mùi ở Địa Chi và nếu Nhâm
        Thủy và Giáp Mộc cùng xuất hiện ở Thiên Can. Trong tình huống này thì
        ngày sinh sẽ hưởng thành công.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Giáp Mộc lộ ở Thiên Can trong khi không có Mộc Cục ở Địa Chị thì
        ngày sinh này chỉ có cuộc sống trung bình cùng với thiếu những thành
        tích quan trọng, bất kể họ có kiến thức bao nhiêu, ngay cả với sự hiện
        diện của Nhâm Thủy.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Giáp Mộc ở Thiên Can trong khi không có Canh Kim thì ngày sinh này
        sẽ phải vất vả đi tìm kiếm bình an cả đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có Địa Chi tạo thành Mộc Cục nhưng chỉ có Nhâm Thủy hiện diện ở
        Thiên Can mà không có Giáp Mộc thì Mộc Cục này được xem là vô dụng.
        Trong tình huống này thì người này có nhiều giấc mơ và mục tiêu, nhưng
        không có đủ kiên nhẫn thực hiện.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu lá số bát Tự bao gồm 4 Đinh Mùi thì lá số này sẽ cực âm sẽ khiến
        cho người này dễ sinh ra trong gia đình lớn mà họ khó tìm được sự nghiệp
        riêng hoặc tìm được chỗ đứng trong cuộc đời. Tuy vậy, vợ của những người
        ngày sinh này sẽ có quyền lớn hơn chồng. An toàn để có thể nói rằng ngày
        sinh này sẽ có may mắn trong đời mặc dù thiếu quyền lực thật sự.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Chính Ấn và Chính Quan:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Canh Kim Chính Tài hiện diện, ngày sinh này sẽ hưởng tài lộc
        trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Sẽ không tốt nếu Kỷ Thổ đi kèm với Nhâm Thủy Chính Quan.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Vào nửa đầu tháng Mùi thì Nhâm Thủy là dụng thần chính của ngày sinh
        này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Vào nửa sau của tháng Mùi thì Giáp Mộc là dụng thần chính của ngày
        sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu lá số Bát Tự rất âm thì sẽ mất đi mục đích định hướng trong đời.
        Bất kể gia đình quyền lực và to lớn thế nào thì người này vẫn khó thành
        công trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tốt nhất là tránh 2 Bính Hỏa kẹp giữa Đinh Hỏa. Nếu không thì ngày
        sinh này sẽ nghèo khi còn trẻ. Khi lớn tuổi hơn thì thành công của họ bị
        lấn át bởi bạn bè hay anh em. Nếu Thủy xuất hiện thì tình hình sẽ có thể
        đảo ngược.
      </p>
    </div>
  );
};

export const DinhThangThan = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Đinh trong tháng Thân
        </h3>
      </div>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với ngày Đinh sinh trong tháng Thân thì hình thành cục Chính Tài nếu
        Canh Kim lộ trên Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Nhâm Thủy lộ ở Thiên Can thì hình thành cục Chính Quan.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Mậu Thổ lộ ở Thiên Can thì hình thành cục Thương Quan.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu không có Mậu Thổ, Nhâm Thủy hay Canh Kim thì chọn theo ngũ hành nào
        vượng trong lá số.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với sinh trong tháng Thân thì Giáp Mộc, Canh Kim, Bính Hỏa và Mậu
        Thổ là dụng thần điều hậu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Canh Kim được dùng để điều hậu Giáp Mộc. Nếu thiếu Giáp Mộc thì có thể
        dùng Ất Mộc thay.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngoài ra Bính Hỏa dùng để sưởi ấm Kim, cũng như làm khô Giáp Mộc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Canh Kim hay Giáp Mộc không xuất hiện thì có thể dùng Ất Mộc thay
        cho Giáp. Ất Mộc là cỏ khô có thể hấp thu ánh sáng mặt trời từ Bính Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Thủy quá mạnh thì Mậu Thổ cần dùng để kiểm soát.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Canh Kim vượng nhất trong tháng Thân. Thủy Khí khá mạnh trong tháng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Vì sự kết hợp thì Kim và Thủy có tác dụng làm suy yếu Đinh Hỏa. Nếu Kim
        xuất hiện nhiều thì Mộc Ấn Tinh sẽ bị tổn thương và suy yếu. Thủy, khi
        xuất hiện nhiều, có thể khiến Mộc ẩm ướt.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đó là lý do vì sao cả Mộc và Hỏa cần như là Dụng Thần, bởi vì sự kết hợp
        sẽ đem đến sự cân bằng cho lá số này, biến nó thành “hữu tình”.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Nhâm Thủy hiện ra ở Thiên Can trong trường hợp lá số nữ giới trong
        khi Mộc hiện diện ở Địa Chi thì sự kết hợp giữa Đinh Hỏa và Nhâm Thủy sẽ
        giúp những ngày sinh này hưởng quan hệ xuất sắc với chồng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Dụng Thần Điều Hậu cho ngày sinh Đinh Hỏa trong tháng mùa thu Thân,
        Dậu hay Tuất về căn bản là giống nhau.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Canh Kim mạnh nhất trong tháng Thân. Tương tự thì Nhâm Thủy sẽ được
        sinh ra.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Canh Kim Chính Tài quá mạnh, có vẻ như vợ của lá số nam giới sẽ
        nắm quyền lực thật sự.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Nhâm Thủy xuất hiện nhiều và ở Thiên Can thì khi hợp với Đinh Hỏa
        tạo thành Mộc thì ngày sinh này có thể hưởng tài lộc và thịnh vượng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Canh Kim xuất hiện nhiều nhưng thiếu Nhâm Thủy, thì Canh Kim sẽ
        phá hủy Giáp Mộc, cũng là dụng thần của ngày sinh này. Trong hoàn cảnh
        này thì ngày sinh này sẽ bị tấn công dữ dội và thậm chí nghèo đến mức vô
        gia cư.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu thiếu Giáp Mộc thì có thể dùng Ất Mộc và Bính Hỏa đồng thường để
        thay thế. Trong tình huống này thì Giáp Mộc cũng không nên tách rời Canh
        Kim, trong khi Ất Mộc không nên tách rời Bính Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu Ất Mộc được sử dụng thay cho sự vắng mặt của Giáp Mộc thì ngày
        sinh này sẽ hơn trung bình một chút.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số này có Chính Ấn, Chính Tài và Kiếp Tài:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngày sinh này sẽ có cuộc đời thịnh vượng khi cả 3 can là Giáp Mộc, Canh
        Kim và Bính Hỏa xuất hiện.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu thiếu đi Giáp Mộc thì lá số không tốt.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có cục Chính Quan Thất Sát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có Quý Thủy có xuất hiện và thông căn, Mậu Thổ và Kỷ Thổ cần được
        dùng để kiểm soát Quý Thủy.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số tốt nếu có Giáp Mộc và Bính Hỏa hiện diện.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số xấu nếu không có Mậu Thổ và Kỷ Thổ.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có cục Chính Tài:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số có thể hình thành cục Tòng Tài nếu xuất hiện Kim Cục trong Địa Chi
        và hoàn toàn vắng bóng hành Mộc và Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lý tưởng nếu có Nhâm Thủy hiện diện.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu không có Nhâm Thủy thì lá số này sẽ trôi dạt, không có định hướng
        trong đời.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi có Tòng Tài Cục hình thành thì không nên bị xung hay suy yếu đi.
        Nếu không thì sự hiện diện cũng như tính hữu dụng bị suy giảm và do đó
        tai họa cũng sẽ kéo đến thay vì tài lộc.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Bính Hỏa cung cấp hơi ấm và giúp cho ngày sinh này trong tháng. Tuy
        nhiên, sẽ lý tưởng nếu Bính Hỏa đừng xuất hiện ở Thiên Can mà nên ở Địa
        Chi.
      </p>
    </div>
  );
};

export const DinhThangDau = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Đinh trong tháng Dậu
        </h3>
      </div>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu sinh trong tháng Dậu thì khi Tân Kim lộ Thiên Can dễ hình thành cục
        Thiên Tài. Ngay cả nếu Tân Kim không hiện lên ở Thiên Can thì vẫn dễ
        hình thành cục Thiên Tài.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu sinh trong tháng Dậu thì Dụng Thần Điều Hậu là Giáp Mộc, Canh Kim,
        Bính Hỏa và Mậu Thổ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Canh Kim được dùng để kiểm soát Giáp Mộc. Nếu thiếu vắng Giáp Mộc thì có
        thể dùng Ất Mộc thay.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu cả Canh Kim hay Giáp Mộc đều không hiện diện thì có thể dùng Ất Mộc
        thay. Ất Mộc tương tự như cỏ khô có thể đem lại ánh sáng cho Đinh Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Thủy nhiều thì nên dùng Mậu Thổ để kiểm soát.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Kim mạnh nhất trong tháng này. Bản chất Âm Kim của Dậu Địa Chi sẽ xung
        với gốc rễ của Mộc, vốn là dụng thần của ngày sinh này. Do đó, biểu thị
        rằng sức mạnh của Mộc Khí Ấn sẽ bị giới hạn. Nếu thiếu Mộc thì sẽ khó
        cho Đinh Hỏa tỏa sáng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Do đó, Mộc và Hỏa là dụng thần quan trọng nhất cho ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Sự hiện diện mạnh mẽ của Kim và Thủy Khí sẽ làm suy giảm Hỏa nhiều nhất
        trong số ngũ hành.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Ngoài ra, cũng có nhu cầu cần Thổ ướt để sinh ra Kim vốn dĩ đã mạnh sẵn.
        Điều này bởi vì Kim – Tài Tinh – đã hiện diện nhiều, ngày sinh này sẽ bị
        tổn hại nặng nề.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Sự hiện diện của Tỷ Kiếp sẽ giúp cho ngày sinh này nhiều, đặc biệt trong
        việc giúp họ đạt thịnh vượng trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi ngày sinh này vào vận Kim hay Thủy mà không được Mộc trợ giúp như
        Dụng Thần, người này sẽ gặp các vấn đề pháp lý kiện tụng cả đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Dụng thần điều hậu giúp cho ngày sinh Đinh Hỏa sinh trong tháng mùa
        Thu Thân Dậu Tuất về cơ bản là giống nhau.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu chỉ có Tân Kim xuất hiện nhưng thiếu Tỷ Kiếp thì sẽ có thể hình
        thành cục Tòng Tài. Tuy nhiên, Canh Kim cũng không được xuất hiện ở
        Thiên Can.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Thiên Tài:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu chỉ có Tân Kim Thiên Tài lộ nhưng Canh Kim Chính Tài thì không thì
        sẽ không thể tạo thành cục Tòng Tài trong lá số.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi đạt được các cách cục này thì cuộc sống nhiều thành tích cao. Mặc dù
        có nhiều thách thức nhưng các thách thức đều dẫn đến thành tích cao.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số tốt nếu không có Hỏa và Mộc. Sẽ không tốt nếu có Tỷ Kiếp và Ấn
        hiện ra.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi ngày sinh này không theo cục Tòng Tài nhưng lại có duyên với Tân
        Kim Thiên Tài thì cũng không nên gặp Tỷ Kiếp.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Trong tình huống bình thường thì Bính Hỏa và Giáp Mộc không nên thiếu
        vắng trong lá số Bát Tự.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Tài Tinh được ưu tiên hơn Quan Tinh, như là dụng thần của ngày sinh
        này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Quý Thủy (Thất Sát) xuất hiện thì cần có Mậu Thổ và Kỷ Thổ để kiểm
        soát Quý Thủy.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Nhâm Thủy Chính Quan xuất hiện thì không nên thấy Nhâm Thủy và
        Đinh Hỏa hợp với nhau.
      </p>
    </div>
  );
};

export const DinhThangTuat = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Đinh trong tháng Tuất
        </h3>
      </div>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với sinh trong tháng Tuất thì hình thành cục Thương Quan nếu lộ Mậu
        Thổ ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Tân Kim lộ Thiên Can thì là cục Thiên Tài.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Đinh Hỏa lộ Thiên Can thì hình thành cục Lâm Quan.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu không có Mậu Thổ, Tân Kim và Đinh Hỏa lộ Thiên Can thì nên chọn cục
        theo ngũ hành nào vượng nhất trong lá số.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với sinh trong tháng Tuất thì Canh Kim, Giáp Mộc và Mậu Thổ là dụng
        thần điều hậu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu chỉ có Mậu Thổ là dụng thần và không có Giáp Mộc thì Mậu Thổ sẽ bị
        ảnh hưởng xấu.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Trong tháng Tuất, Thổ dày nặng trong khi Hỏa lại nhập mộ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Do đó không nên thiếu Mộc như là dụng thần. Lá số này sẽ gặp rủi ro quá
        khô. Điều này là lý do vì sao Mộc đóng vai trò là dụng thần chính, với
        Thủy là dụng thần phụ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Trong lúc đó, Mộc được dùng để làm tơi Mậu Thổ. Tương tự, Mộc có thể
        dùng để sản sinh ra Đinh Hỏa. Chỉ khi đó thì Thổ khô mới có thể được
        dưỡng ẩm và đóng vai trò như dụng thần của ngày sinh này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu sinh vào giờ mà Hỏa và Thổ đều vượng có thể sử dụng Canh Kim để ngăn
        Thổ vốn đang dày nặng, khỏi phá Mộc.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Dụng Thần điều hậu cho ngày sinh Đinh Hỏa sinh trong tháng mùa Thu
        Thân Dậu Tuất về căn bản giống nhau.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có Mộc cục hình thành trong lá số Bát Tự – trong khi thiếu Nhâm
        Thủy và Giáp Mộc – thì Mậu Thổ vốn là Thương Quan của ngày sinh này sẽ
        bị ảnh hưởng nặng nề. Tuy nhiên, ngày sinh này vẫn sẽ được hưởng danh
        tiếng, địa vị và quyền lực bất chấp việc cảm thấy bất mãn trong lòng.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Không có Giáp Mộc là Dụng Thần thì ngày sinh này sẽ là người tri thức
        nhưng mức độ thành công thì bình thường.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Thương Quan, Thiên Tài và Tỷ Kiên:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Mậu Thổ, Tân Kim và Đinh Hỏa đều xuất hiện ở Địa Chi và cả Thiên Can
        thì gọi là Thể Dụng Đồng Cung. Và nếu không có Nhâm Thủy và Giáp Mộc xâm
        nhập vào Thiên Can thì ngày sinh này sẽ thịnh vượng trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số tốt nếu cả 3 can tàng xuất hiện ở Địa chi lại lập lại ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số xấu nếu Nhâm Thủy và Giáp Mộc xuất hiện ở Thiên Can.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Thương Quan Cục:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu chỉ có Mậu Thổ hiện diện là Dụng Thần, còn Nhâm Thủy và Giáp Mộc
        không nên xuất hiện. Nếu Giáp Mộc xuất hiện thì nên đi kèm với Canh Kim.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lá số sẽ xấu nếu có Nhâm Thủy và Giáp Mộc, vốn ảnh hưởng nặng đến Mậu
        Thổ Thương Quan.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Cục Thương Quan là cục lý tưởng nên có đối với lá số này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu hình thành Thương Quan Cục, nên tránh gặp Nhâm Thủy trong lá số
        cũng như không nên gặp đại vận Nhâm Thủy.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Nếu có Thìn Tuất Sửu Mùi đều hiện diện mà không có Nhâm Thủy hay Giáp
        Mộc ở Thiên Can thì ngày sinh này sẽ hưởng thịnh vượng trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Thương Quan Cục hình thành và xuất hiện Giáp Mộc thì Mậu Thổ sẽ có
        thể được dùng để làm hao mòn sức mạnh của Giáp Mộc. Tuy nhiên trong hoàn
        cảnh này thì ngày sinh này sẽ vẫn là người học cao hiểu rộng nhưng thiếu
        tài lộc và thành tích.
      </p>
    </div>
  );
};

export const DinhThangHoi = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          Ngày sinh Đinh trong tháng Hợi
        </h3>
      </div>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Khái Quát:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với ngày sinh Đinh trong tháng Hợi thì hình thành cục Chính Quan nếu
        Nhâm Thủy lộ ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Khi Giáp Mộc hiện lên Thiên Can thì hình thành cục Chính Ấn.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Dụng Thần Điều Hậu:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Đối với sinh trong tháng Hợi thì Giáp Mộc và Canh Kim hình thành dụng
        thần điều hậu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Canh Kim dùng để chặt Mộc để thu hút Đinh Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Giáp Mộc là dụng thần chính đối với ngày sinh này trong khi Canh Kim là
        dụng thần thứ hai.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có thể thì sử dụng thêm Mậu Thổ và Quý Thủy như là dụng thần thay
        thế.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Luận Giải:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Thủy vượng trong tháng này.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Vì đây là đầu mùa Đông nên lá số lạnh giá cùng Mộc ẩm ướt. Mặc dù Hợi
        chứa Giáp Mộc tàng can nhưng vẫn khó để Mộc ướt phát ra lửa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Hỏa là dụng thần số 1 của ngày sinh này. Ngày sinh này chỉ mạnh mẽ khi
        Mộc và Hỏa cùng hiện diện, và được sử dụng cùng với Nhâm Thủy ẩn tàng ở
        trong Hợi Địa Chi.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nữ giới ngày Đinh có Mộc và Hỏa trong trụ ngày và giờ sẽ có thể hấp dẫn
        và kết hôn với chồng giàu có trong khi Nhâm Thủy xuất hiện ở Thiên Can.
      </p>

      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <b> • Lưu Ý:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Giáp Mộc Chính Ấn xuất hiện ở Thiên Can thì ngày sinh Đinh Hỏa
        sinh trong tháng Hợi Tý Sửu sẽ có cuộc đời thoải mái.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Giáp Mộc đóng vai trò như là dụng thần điều hậu cho ngày sinh trong
        tháng Hợi Tý Sửu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Bính Hỏa hiện diện như là dụng thần quan trọng nhưng không nên xuất
        hiện ở Thiên Can. Trong thực tế, không có lợi nếu bính Hỏa xuất hiện
        nhiều ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi có ít nhất một Bính Hỏa hiện diện ở Thiên Can thì Kim và Thủy cần
        có để kiểm soát Bính Hỏa.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Bính Hỏa cướp đi hào quang của Đinh Hỏa thì ngày sinh này sẽ mất
        đi định hướng trong đời và càng hơn nữa nếu thiếu đi Kim hay Thủy Khí
        trong lá số Bát Tự.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi có mặt Kim nhưng thiếu hành Thủy thì ngày sinh này sẽ nghèo khó
        bất kể họ có tri thức bao nhiêu.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi có Nhâm Thủy nhưng thiếu Kim, thì ngày sinh này sẽ thiếu quyền lực
        thật sự, bất kể thành tích học tập của họ.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi có 2 Nhâm xuất hiện ở trụ tháng và giờ, và tranh nhau để hợp với
        Đinh Hỏa, Mậu Thổ có thể được dùng để hóa giải Nhâm Thủy. Ngay cả với sự
        hiện diện của Mậu Thổ trong những tình huống này thì ngày sinh này cũng
        sẽ chỉ có cuộc sống trung bình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Mậu thổ không xuất hiện ở Thiên Can thì ngày sinh này sẽ chỉ sở
        hữu năng lực trung bình để thành công trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi Mậu Thổ xuất hiện như là một trong những tàng can ở Địa chi thì
        ngày sinh này sẽ có cuộc sống trung bình.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi hai Bính Hỏa cướp hào quang của Đinh Hỏa thì ngày sinh này vẫn có
        thể hưởng được quyền lực trong đời dù có thể hơi bất mãn, mặc dù không
        có Quý Thủy hiện diện như là dụng thần.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Khi thiếu Ấn Tinh và Tỷ Kiên và với Kim Thủy hiện diện nhiều thì ngày
        sinh này theo Tòng Quan, ngày sinh này sẽ hưởng địa vị và quyền lực
        trong đời. Nếu là cách cục đặc biệt này thì người này sẽ có cuộc đời phi
        thường.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Giáp Mộc là dụng thần chính đối với ngày sinh Đinh Hỏa trong tháng mùa
        đông. Cần kèm với Canh Kim, bởi vì cả hai đóng vai trò là dụng thần quan
        trọng của ngày sinh này.
      </p>

      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Cách Cục:</b>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Chính Ấn và Chính Tài:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Giáp Mộc là dụng thần chính của ngày sinh này. Nếu có hiện diện thì ngày
        sinh này sẽ hưởng thành công trong sự nghiệp.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lý tưởng nếu Giáp Mộc xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Xấu nếu như Kỷ Thổ xuất hiện ở Thiên Can và hợp với Giáp Mộc mất để hình
        thành Thổ.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Chính Quan:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nhâm Thủy là Chính Quan và sẽ không có lợi nếu có 2 Nhâm Thủy xuất hiện
        đồng thời ở Thiên Can. Điều này bởi vì cả hai sao Chính Quan sẽ cạnh
        tranh với nhau để hợp với Đinh Hỏa. Nếu như đúng là điều này thì ngày
        sinh này sẽ dẫn đến rối trí, cuộc sống gặp nhiều ngã 3 và tình cảm rắc
        rối.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Sự hiện diện của Mậu Thổ Thương Quan sẽ trung hòa ảnh hưởng của Nhâm
        Thủy. Sẽ rất xấu nếu 2 Nhâm Thủy kẹp ngày sinh.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Kiếp Tài:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu có một Bính Hỏa xuất hiện ở Thiên Can thì ngày sinh này sẽ chỉ có
        cuộc sống trung bình. Nếu 2 Bính Hỏa xuất hiện ở Thiên Can kèm với một
        Quý Thủy thì ngày sinh này sẽ có cơ hội để trở nên nổi tiếng trong đời.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lý tưởng nếu Bính Hỏa gặp Quý Thủy. Không tốt nếu lá số không có Quý
        Thủy.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          textDecoration: "underline",
        }}
      >
        <b> • Nếu lá số có Chính Ấn và Thiên Tài:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Nếu Hợi Mão Mùi đều hiện diện trong lá số Bát Tự, thì ngày sinh này vẫn
        có cơ hội thành công. Mặc dù thành công chủ yếu là do sự giúp đỡ từ
        người khác.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Lý tưởng nếu Giáp Mộc xuất hiện ở Thiên Can.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Xấu nếu không có Giáp Mộc hay Ất Mộc ở Thiên Can.
      </p>
      <p
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
        }}
      >
        <b> • Tóm Tắt:</b>
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Giáp Mộc phải đi kèm với Canh Kim và cả hai đều quan trọng như là dụng
        thần.
      </p>
      <p style={{ textAlign: "left", paddingLeft: "1rem" }}>
        • Sẽ khó chấp nhận thay thế Giáp Mộc và Canh Kim bằng Ất Mộc và Tân Kim
        vì hai loại này không có nhiều tác dụng như là dụng thần.
      </p>
    </div>
  );
};
