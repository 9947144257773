import React from "react";
import "../scss/style.scss";
import logo from "../assets/logo-footer.jpg";
import {
  FaFacebookF,
  FaTwitter,
  FaGooglePlusG,
  FaYoutube,
} from "react-icons/fa";

export const Footer = () => {
  return (
    <div className="div__footer hide-to-print">
      <div className="div__tren">
        <div className="logo__footer">
          <img src={logo} />
        </div>
        <div className="info__footer">
          <p>Địa chỉ: 4A Đường D7, Khu dân cư Nam Long, Phước Long B, Quận 9</p>
          <p style={{ padding: "5px 0" }}>
            Điện thoại: (+84) 28 66814141 - Mobile: (+84) 0981 229 461
          </p>
          <p>Email: info@tuongminhfengshui.com</p>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingRight: "200px",
        }}
      >
        <div className="social__">
          <div className="facebook">
            <a href={"https://www.facebook.com/phongthuytuongminh/"}>
              <FaFacebookF />{" "}
            </a>
          </div>
          <div className="twitter">
            <a href={"https://www.facebook.com/phongthuytuongminh/"}>
              <FaTwitter />
            </a>
          </div>
          <div className="google">
            <a href={"https://www.facebook.com/phongthuytuongminh/"}>
              <FaGooglePlusG />
            </a>
          </div>
          <div className="youtube">
            <a
              href={"https://www.youtube.com/channel/UCxVMAH7gUiM9-zszIDKj9uA"}
            >
              <FaYoutube />
            </a>
          </div>
        </div>
      </div>
      <div className="div__duoi">
        <div className="first">
          <ul>
            <a
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                paddingBottom: "15px",
              }}
              href={"https://huyenkhongtamnguyen.com/"}
            >
              VỀ CHÚNG TÔI
            </a>
            <li>
              <a href={"https://huyenkhongtamnguyen.com/"}>
                Về tường minh phong thủy
              </a>
            </li>
            <li>
              <a style={{ padding: "5px 0" }} href={"/"}>
                Thầy Nguyễn Thành Phương
              </a>
            </li>
            <li>
              <a href={"https://huyenkhongtamnguyen.com/"}>Thư viện ảnh</a>
            </li>
          </ul>
        </div>
        <div className="second">
          <ul>
            <a
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                paddingBottom: "15px",
              }}
              href={"http://phongthuytuongminh.com/tu-van-phong-thuy.html"}
            >
              DỊCH VỤ TƯ VẤN
            </a>
            <li>
              <a href={"http://phongthuytuongminh.com/phong-thuy-nha-o.html"}>
                Phong thủy nhà ở
              </a>
            </li>
            <li>
              <a
                style={{ padding: "5px 0" }}
                href={
                  "http://phongthuytuongminh.com/chon-ngay-tot-nhat-trach.html-0"
                }
              >
                Chọn ngày tốt (Trạch Nhật)
              </a>
            </li>
            <li>
              <a
                href={
                  "http://phongthuytuongminh.com/phong-thuy-mo-ma-am-trach.html"
                }
              >
                Mộ phần (Âm trạch)
              </a>
            </li>
          </ul>
        </div>
        <div className="third">
          <ul>
            <a
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                paddingBottom: "15px",
              }}
              href={"http://phongthuytuongminh.com/hoc-phong-thuy.html"}
            >
              HỌC PHONG THỦY
            </a>
            <li>
              <a href={"http://phongthuytuongminh.com/phong-thuy-dia-ly.html"}>
                Phong thủy địa lý
              </a>
            </li>
            <li>
              <a
                style={{ padding: "5px 0" }}
                href={"http://phongthuytuongminh.com/bat-tu-tu-vi.html"}
              >
                Bát tự tử vi
              </a>
            </li>
            <li>
              <a href={"https://huyenkhongtamnguyen.com/"}>
                Trạch Nhật (Chọn ngày giờ)
              </a>
            </li>
            <li>
              <a
                style={{ padding: "5px 0" }}
                href={"https://huyenkhongtamnguyen.com/"}
              >
                Lịch học phong thủy
              </a>
            </li>
          </ul>
        </div>
        <div className="four">
          <ul>
            <a
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                paddingBottom: "15px",
              }}
              href={
                "http://phongthuytuongminh.com/kien-thuc-phong-thuy.html-0%3E"
              }
            >
              KIẾN THỨC PHONG THỦY
            </a>
            <li>
              <a
                href={"http://phongthuytuongminh.com/tai-lieu-phong-thuy.html"}
              >
                Tài liệu phong thủy
              </a>
            </li>
            <li>
              <a
                style={{ padding: "5px 0" }}
                href={
                  "http://phongthuytuongminh.com/bai-viet-phong-thuy.html-1"
                }
              >
                Bài viết phong thủy
              </a>
            </li>
            <li>
              <a
                style={{ fontWeight: "800" }}
                href={"https://huyenkhongtamnguyen.com/"}
              >
                LIÊN HỆ
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="title__footer">
        by Phong Thuỷ Tường Minh - Hocphongthuy.net 2019 Allright reserved
      </div>
    </div>
  );
};
