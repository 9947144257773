import nguyetkhong from "../../assets/thansat/nguyetkhong.png";
import nhatduc from "../../assets/thansat/nhatduc.png";
import ndqn1 from "../../assets/thansat/ndqn1.png";
import ndqn2 from "../../assets/thansat/ndqn2.png";
import nhatquy from "../../assets/thansat/nhatquy.png";
import pdqn from "../../assets/thansat/pdqn.png";
import tkqn from "../../assets/thansat/tkqn.png";
import thaplinh from "../../assets/thansat/thaplinh.png";
import thiencuong from "../../assets/thansat/thiencuong.png";
import tdqn from "../../assets/thansat/tdqn.png";
import tdhqn from "../../assets/thansat/tdhqn.png";
import thienkhong from "../../assets/thansat/thienkhong.png";
import vongthan from "../../assets/thansat/vongthan.png";

export const NguyetKhong = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Người có Nguyệt Không thường có những suy nghĩ bay bổng, phi thực tế.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu như trong lá số có Nguyệt Không là kỵ thần thì người có suy nghĩ
        hoang tưởng, mơ mộng và dễ bị lạc hướng trong cuộc sống. Nếu Nguyệt
        Không xuất hiện ở Đại vận hay Lưu niên thì trong Đại vận đó, cần tập
        trung suy nghĩ và định hướng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu như trong lá số có Nguyệt Không nhưng là dụng thần, có xuất hiện và
        thông căn thì là người có suy nghĩ bay bổng, nhưng lại sống thực tế.
      </p>

      <img style={{ width: "100%" }} src={nguyetkhong} />
    </div>
  );
};
export const NhatDuc = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Người sinh vào một trong năm ngày sau được xem là ngày sinh Nhật Đức.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đó là 5 ngày sinh Giáp Dần, Bính Thìn, Mậu Thìn, Canh Thìn, Nhâm Tuất.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người có ngày sinh này thường đa cảm và thích làm từ thiện, tính cách
        chắc chắn, đáng tin cậy, nhiều may mắn nhưng ít khi khoa trương.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sách Tam Mệnh Thông Hội có nói: “Nhật Đức cách là chỉ Ngũ Dương Can toạ
        chi đắc quý, như Giáp toạ Dần là đắc lộc, Bính toạ Thìn là quan khố,
        Canh toạ Thìn là Tài Ấn lưỡng toàn, Nhâm toạ Tuất là Tài Quan Ấn Tam
        Kỳ.”{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngày sinh Nhật Đức biểu thị tình cảm từ bi, giúp đỡ người già yếu, biết
        lễ nghĩa. Thích gặp vận vượng, kỵ gặp Khôi Cương. Trong Tứ Trụ có thì
        nhất định là người có mệnh đại phúc.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu gặp Khôi Cương thì sinh đại họa, người có cục tốt thì cũng vẫn phát
        phúc, nhưng lực đã bị suy yếu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhật Đức Cách nếu chỉ có 1 trụ ngày, thì gặp Tài Tinh và Quan Tinh hoặc
        nếu Nhật Đức xuất hiện thêm mấy lần, thì gọi là Nhật Đức Cách.
      </p>

      <img style={{ width: "100%" }} src={nhatduc} />
    </div>
  );
};
export const NguyetDucQuyNhan = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Đây là sao có hiệu lực mạnh mẽ trong việc đem lại bình an và sức khỏe
        tốt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người có mệnh mang Nguyệt Đức Quý Nhân thường xuyên nhận được sự giúp
        đỡ, nhất là từ đối tác, bạn bè hoặc người thân là nữ giới.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        - Theo cổ quyết Tử Bình thì:
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • 寅午戌，月在丙；
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • 申子辰，月在壬；
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • 亥卯未，月在甲；
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • 已酉丑，月在庚
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>- Hán Việt</p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Dần Ngọ Tuất Nguyệt tại Bính
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Thân Tý Thìn Nguyệt tại Nhâm
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Hợi Mão Mùi Nguyệt tại Giáp
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Tỵ Dậu Sửu Nguyệt tại Canh
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        - Tử Bình Phú viết:
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người có Nguyệt Đức Quý Nhân, chủ người có tâm địa lương thiện, gặp hung
        hoá cát, mọi sự khó khăn đều hoá bình thường. Người thông minh, có tài
        năng, làm mọi việc đều với tâm đức tốt, cả đời rất ít bệnh tật, không
        gặp hoạ quan ty. Nếu là nữ mệnh có Nguyệt Đức Quý Nhân chủ sinh con quý
        tử, mẹ tròn con vuông. Người có Nguyệt Đức Quý Nhân không sợ xung khắc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nguyệt Đức Quý Nhân nên xuất hiện ở can ngày sinh. Nếu trong những hoàn
        cảnh tốt thì người này sẽ không bao giờ cần sự giúp đỡ, nhưng bất cứ khi
        nào người này gặp khó khăn trong đời thì có người đến giúp ngay.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu Nguyệt Đức Quý Nhân xuất hiện ở trụ năm, tháng hay giờ trong lá số
        thì sự giúp đỡ của quý nhân vẫn có, tuy nhiên mức độ giúp đỡ sẽ không
        nhiều.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>Ví dụ:</p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Giáp trong tháng Mùi là có Nguyệt Đức Quý Nhân
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh năm Canh trong tháng Tỵ là mệnh mang Nguyệt Đức Quý Nhân.
      </p>

      <img style={{ width: "100%" }} src={ndqn1} />
      <img style={{ width: "100%" }} src={ndqn2} />
    </div>
  );
};
export const NhatQuy = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Người này có đạo đức, có nét ngoài xinh đẹp và không kiêu ngạo, tử tế.
        Nếu có Chính Tài và Thực Thần hay Ấn Tinh và là dụng thần trong lá số
        này thì sẽ có phú quý.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Chỉ có 4 ngày là: Đinh Dậu, Đinh Hợi, Quý Tỵ, Quý Mão.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu sinh vào 4 ngày này được xem là mệnh Nhật Quý.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        - Cổ Văn Viết:
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Nhật Quý Nhật Đức Chủ Từ Tường
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Tài Quan Ấn Ngộ Phúc Vinh Xương
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Hình Xung Sát Đao Như Lai Kiến
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Phản Cát Vi Hung Bất Khả Đương
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Sinh Nhật Thiên Can Toạ Quý Chi
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Nhược Kiến Khôi Cương Phúc Bất Kỳ
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Niên Phùng Nguyệt Lộc Bất Vi Hỉ
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Nhật Quý Trùng Phùng Kỳ Hựu Kỳ
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        - Nghĩa của đoạn văn trên là:
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Người mang Nhật Quý cũng như có Thiên Ất Quý Nhân, chủ tốt đẹp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Nữ mệnh Nhật Quý là người thuần hậu, nhân đức, có sắc đẹp. Nam mệnh
        Nhật Quý là người khôi ngô, thái độ khiêm tốn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Người mệnh Nhật Quý thích gặp Tam Hợp, Lục Hợp, hành vận Quý Nhân, Tài
        vượng, chủ phát phúc dị thường. Nhưng đại kỵ hình xung phá hại, kỵ gặp
        Không Vong, nếu gặp thì phúc khí tiêu tan.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Nếu mệnh cục có hình xung, phá hại, không vong, lưu niên gặp Thái Tuế
        lại gặp Khôi Cương thì tất nghèo hèn đoản mệnh.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Nếu có Tài Quan Ấn thì có phúc, vinh hoa, học hành tốt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh năm Canh trong tháng Tỵ là mệnh mang Nguyệt Đức Quý Nhân.
      </p>

      <img style={{ width: "100%" }} src={nhatquy} />
    </div>
  );
};
export const PhucDucQuyNhan = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Nếu có sao này trong Bát Tự thì cả đời nhiều may mắn, mọi mong muốn đều
        được thỏa mãn, nhiều phúc lộc thọ, kim ngọc mãn đường. Gia tăng ảnh
        hưởng của các sao tốt khác, gặp hung hóa cát, cả đời hưởng thụ an nhàn
        phúc lộc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đây là một trong những cát tinh rất cao quý trong các loại thần sát.
      </p>
      <img style={{ width: "100%" }} src={pdqn} />

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhưng nếu gặp Xung hay Hợp thì cũng làm mất đi ảnh hưởng sao Phúc Đức.
      </p>
    </div>
  );
};
export const TamKyQuyNhan = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Người bị ảnh hưởng bởi sao này có xu hướng trở nên xuất sắc trong bất kỳ
        lĩnh vực nào họ quan tâm vì kiến thức của họ rất sâu sắc. Tài năng của
        họ thường vượt hơn hẳn người thường, mặc dù làm ít hơn nhưng vẫn vượt
        trội hơn so với đồng nghiệp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người có Tam Kỳ thì thông thái, có năng lực, rất tham vọng, đầu óc cởi
        mở, có danh tiếng, thành tích, đầy sức sống. Là người thông minh, một
        dạng giống như có sao Thiên Nguyệt Đức hay Quý Nhân, tai họa sẽ không
        xảy ra trong đời, là dấu hiệu của tài lộc, thịnh vượng và sống thọ.{" "}
      </p>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mệnh có Tam Kỳ Quý Nhân là người đa tài, học rộng. Thường là người có
        tinh thần tốt, kiến văn quảng bác, có phẩm chất xuất sắc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tam Kỳ Quý Nhân cùng với Thiên Ất Quý Nhân, có thể giúp cho một người
        lập nghiệp thành công, nổi tiếng một đời. Nếu Tam Kỳ Quý Nhân cư tại
        Không Vong, thì là cao nhân ẩn cư. Là người có lý tưởng, tài năng.{" "}
      </p>
      <img style={{ width: "100%" }} src={tkqn} />
    </div>
  );
};
export const ThapLinh = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        1. Giáp Thìn
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>2. Ất Hợi</p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        3. Bính Thìn
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>4. Đinh Dậu</p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>5. Mậu Ngọ</p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        6. Canh Tuất
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>7. Canh Dần</p>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>8. Tân Hợi</p>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>9. Nhâm Dần</p>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>10. Quý Mùi</p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        {" "}
        - Cổ văn nói:{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Nam đới thập linh hiếu văn chương, nữ đới thập linh hiếu y xương.
        Người có Thập Linh thì thích thi phú, văn chương, thời trang. Nam nữ có
        khí chất, khôi ngô tuấn tú, linh lợi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Rất nhiều sư phụ khi chiêu sinh đệ tử để truyền dạy ngũ thuật thì xem
        ngày sinh của học trò có phải Thập Linh hay không. Vì người sinh ngày
        Thập Linh thông minh, trí tuệ, rất dễ tiếp nạp kiến thức, trực giác mẫn
        tuệ, tâm tính tuần thiện, có năng lực học hỏi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người sinh ngày này sẽ rất thông minh, học 1 biết 10.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người này đa tài và thông minh, dễ nổi tiếng, và có danh tiếng, khôn
        khéo
      </p>

      <img style={{ width: "100%" }} src={thaplinh} />
    </div>
  );
};
export const ThienCuong = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Thiên Cương là ám chỉ sao Bắc Đẩu, là đứng đầu trong Đạo Gia.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sao này biểu thị người cứng rắn, có duyên học hỏi tốt các môn liên quan
        đến pháp thuật, tu tập tâm linh. Nếu nằm ở trụ ngày hoặc trụ giờ là lý
        tưởng.{" "}
      </p>

      <img style={{ width: "100%" }} src={thiencuong} />
    </div>
  );
};
export const ThienDucQuyNhan = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Sao này biểu thị bạn vốn dĩ được nhiều may mắn để sống một cuộc đời suôn
        sẻ, ít trở ngại hay xui xẻo, dễ gặp được nhiều quý nhân trong đời vào
        lúc khó khăn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu tìm thấy sao Thiên Đức ở trụ ngày trong Bát Tự thì phát huy tối đa
        tác dụng tốt trong cuộc đời người này.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sự hiện diện của Thiên Đức biểu thị sẽ được giúp đỡ hay hỗ trợ, nhất là
        những khi khốn đốn khó khăn, từ Quý Nhân. Nói cách khác, sao này biểu
        thị người này sẽ không bao giờ thiếu sự giúp đỡ từ những người có địa vị
        hay quyền lực, hay ít nhất là luôn ở vị trí có thể đi cầu quý nhân được.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngay cả nếu như Thiên Đức không hiện diện ở trụ ngày mà ở trụ năm, tháng
        hay giờ thì cũng được giúp đỡ tuy rằng không mạnh nếu so với trụ ngày.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        - Theo sách Tinh Bình Hội Hải:{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • 正丁二坤申，三壬四辛同，
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • 五乾六甲上，七癸八艮逢，
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • 九丙十居乙，子巽丑庚同
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>- Hán Việt</p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Chính Đinh Nhị Khôn Thân, Tam Nhâm Tứ Tân Đồng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Ngũ Càn Lục Giáp Thượng, Thất Quý Bát Cấn Phùng
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Cửu Bính Thập Cư Ất, Tý Tốn Sửu Canh Đồng
      </p>

      <img style={{ width: "100%" }} src={tdqn} />

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngoài ra: Người có Thiên Đức Quý Nhân thì có mệnh cao quý, thành công
        hiển đạt. Cả đời không phạm hình luật, hung hoạ, tựa như được nhật
        nguyệt cùng chiếu đến mệnh. Nhìn chung, ý nghĩa của Thiên Đức Quý Nhân
        là người có tâm địa lương thiện, thân thể cường tráng, nhân duyên tốt
        đẹp, lúc gặp khó khăn thì được quý nhân giúp đỡ, có thể gặp hung hoá
        cát.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        - Tử Bình Phú có viết:
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Ấn Noãn Đắc Đồng Thiên Đức
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Quan Hình Bất Chí
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Chí Lão Vô Tai
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>- Nghĩa là:</p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Tức có Ấn và Thiên Đức thì quan hình không dính vào, đến già cũng
        không gặp tai hoạ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Nếu người có cả Thiên Đức Nguyệt Đức thì đại đa phần cuộc sống có phúc
        ấm, cả đời không lo tai hoạ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Người có Thất Sát và Thiên Đức Quý Nhân thì minh mẫn, quả quyết và
        nhân hậu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Người có Thực Thương và Thiên Đức Quý Nhân thì thông minh, tú lệ và
        nhân hậu.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>Ví dụ:</p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Một người sinh ngày Bính, trong tháng Tuất
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Một người sinh năm Đinh, trong tháng Dần.
      </p>
    </div>
  );
};
export const ThienDucHopQuyNhan = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Lá số có sao này thì cả đời may mắn, ít nguy hiểm. Được phù hộ, đôi khi
        được quý nhân âm thầm giúp đỡ. Là cát tinh, tốt bụng và tử tế.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Là hợp theo Thiên Can Ngũ Hợp hoặc Địa Chi Lục Hợp với Thiên Đức.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người có mệnh mang Thiên Đức Hợp thì cả đời ổn định, vui vẻ, không phạm
        hình luật, không gặp trộm cướp, hung hoạ, gặp hung hoá cát.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhưng kỵ gặp hung thần ác sát xung khắc hại hoặc Tử Tuyệt. Người có
        Thiên Đức Hợp thường sinh con trai, có tuổi thọ dài lâu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Cũng tương tự là Nguyệt Đức Hợp. Người có Thiên Nguyệt Nhị Đức, gặp hung
        hoá cát.
      </p>
    
      
      <img style={{ width: "100%" }} src={tdhqn} />
    </div>
  );
};
export const ThienKhong = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Thất vọng, mơ mộng giữa ban ngày, mục tiêu không có thật và do đó dẫn
        đến nhiều thách thức trong khi làm việc.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Kỵ nhất nếu người có mệnh có Thiên Không tại ngày sinh, biểu thị dễ vỡ
        mộng sau khi kết hôn, cả đời gặp khó khăn, không định hướng.{" "}
      </p>

      <img style={{ width: "100%" }} src={thienkhong} />
    </div>
  );
};

export const VongThan = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Mặc dù nghe tên rất ghê rợn nhưng sao này biểu thị:
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Thái độ quyết tâm, chú trọng đến công việc thậm chí nghiện công việc,
        luôn sẵn sàng cho công việc.
      </p>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Người đáng tin cậy hoặc chung thủy vì không màng đến nhu cầu tình dục
        hay bị các cám dỗ về dục vọng.
      </p>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Thường hay tính toán, ít có trái tim sâu nặng tình cảm.
      </p>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Hơi tiểu nhân, mất kiên nhẫn và có thói đạo đức giả.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Nếu là kỵ thần thì càng xấu hơn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sao này còn gọi là Quan Phù. Nếu là kỵ thần thì sao này là điềm báo kiện
        tụng và xui xẻo cho phụ nữ đã kết hôn trong gia đình, khả năng có quan
        hệ căng thẳng với người phối ngẫu hay con cái, dính líu đến rắc rối pháp
        luật, dễ bị tin đồn, tai tiếng hay giết hại.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu là dụng thần thì người này sở hữu sự thông minh, có óc lên kế hoạch
        chiến lược tốt, năng lượng của sao này tốt cho khả năng lên kế hoạch
        chiến lược.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Cảm giác trống vắng, hi vọng ảo ảnh, thất vọng, mất ngủ, tinh thần không
        ổn định, rắc rối pháp lý{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đây là khái niệm mở rộng của Tam Sát. Vong Thần là Địa Chi xung với 1
        trong Tam Sát. Ví dụ, người sinh vào năm Thân Tý Thìn thì Tam Sát là Tỵ
        Ngọ Mùi. Thì Vong Thần là Hợi vì Hợi xung với Tỵ.
      </p>

      <pre style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Năm Sinh		         Vong Thần <br/>
		Thân Tý Thìn		     Hợi<br/>
		Dần Ngọ Tuất		     Tỵ<br/>
		Tỵ Dậu Sửu		       Thân<br/>
		Hợi Mão Mùi		      Dần
      </pre>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
      Giống Tam Sát, ngày Vong Thần không tốt và cần nên tránh khi chọn cho những  việc quan trọng trong khoa Trạch Nhật. 
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
      VD như tiểu thuyết gia Stephen King bị tai nạn ô tô nghiêm trọng năm 1999 khi bị xe tải tông. Dưới đây là Tứ Trụ của Stephen King      </p>
      <pre style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
    Stephen King – 21/9/1947 <br/>
	Giờ	     Ngày	     Tháng	     Năm<br/>
	Quý	     Quý	      Kỷ	        Đinh<br/>
	Sửu	     Mão	      Dậu	       Hợi<br/>
	54	      44	       34	      24	       14	       4<br/>
	Quý	     Giáp	     Ất	     Bính	     Đinh	     Mậu<br/>
	Mão	     Thìn	     Tỵ	     Ngọ	      Mùi	      Thân<br/>    

      </pre>
      <pre style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
    Ngày xảy ra tai nạn 19/6/1999, lúc 4.30 tối<br/>
	Giờ 	Ngày 	Tháng 	Năm<br/>
	Mậu 	Nhâm 	Canh 	 Kỷ<br/>
	Thân 	Dần 	Ngọ 	  Mão<br/>


      </pre>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
      Ngày này chính là ngày Vong Thần của Stephen King. Tam Sát và Vong Thần thường được tránh trong chọn ngày giờ. Khi chọn ngày cưới hỏi, khai trương, ký hợp đồng, cần tránh xung năm sinh và nhất là tam sát, vong thần.      </p>
      <img style={{ width: "100%" }} src={vongthan} />
    </div>
  );
};
