import bachho from "../../assets/thansat/bachho.png";
import benhphu from "../../assets/thansat/benhphu.png";
import cachgiac from "../../assets/thansat/cachgiac.png";
import dieukhach from "../../assets/thansat/dieukhach.png";
import huyetnhan from "../../assets/thansat/huyetnhan.png";
import kiemphong from "../../assets/thansat/kiemphong.png";
import lucach from "../../assets/thansat/lucach.png";
import luuha from "../../assets/thansat/luuha.png";
import phidao from "../../assets/thansat/phidao.png";
import phima from "../../assets/thansat/phima.png";
import phucthi from "../../assets/thansat/phucthi.png";
import taisat from "../../assets/thansat/taisat.png";
import tamsat from "../../assets/thansat/tamsat.png";
import tangmon from "../../assets/thansat/tangmon.png";
import thaitue from "../../assets/thansat/thaitue.png";
import thienach from "../../assets/thansat/thienach.png";
import thiencau from "../../assets/thansat/thiencau.png";
import thienkhoc from "../../assets/thansat/thienkhoc.png";
import thiensat from "../../assets/thansat/thiensat.png";
import thieny from "../../assets/thansat/thieny.png";
import tuehinh from "../../assets/thansat/tuehinh.png";
import tuesat from "../../assets/thansat/tuesat.png";
import tuphu from "../../assets/thansat/tuphu.png";
import tucthan from "../../assets/thansat/tucthan.png";
import dichma from "../../assets/thansat/dichma.png";
import duongnhan from "../../assets/thansat/duongnhan.png";
import duongnhan1 from "../../assets/thansat/duongnhan1.png";
import duongnhan2 from "../../assets/thansat/duongnhan2.png";
import duongnhan3 from "../../assets/thansat/duongnhan3.png";
import duongnhan4 from "../../assets/thansat/duongnhan4.png";
import duongnhan5 from "../../assets/thansat/duongnhan5.png";
import duongnhan6 from "../../assets/thansat/duongnhan6.png";
import hocduong from "../../assets/thansat/hocduong.png";
import hocsi from "../../assets/thansat/hocsi.png";
import khoicuong from "../../assets/thansat/khoicuong.png";
import thoaithan from "../../assets/thansat/thoaithan.png";
import tienthan from "../../assets/thansat/tienthan.png";
import tuvi from "../../assets/thansat/tuvi.png";
import tuongtinh from "../../assets/thansat/tuongtinh.png";
import vanxuong from "../../assets/thansat/vanxuong.png";

export const BachHo = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Sao Bạch Hổ gây ra chấn thương, đe dọa mạng sống, nếu gặp Bạch Hổ trong
        Lưu niên sẽ thấy chảy máu, nhập viện. Một trong những giải pháp là hiến
        máu, nhổ răng khôn. Cũng dẫn đến việc bị bạn bè đâm sau lưng.
      </p>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi Bạch Hổ chiếu đến hướng cửa nhà, đại diện cho tang phục, chết chóc,
        tai hoạ, tai nạn, đổ máu và sợ hãi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Dễ bị chấn thương, rơi vào kiện tụng phạm pháp
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Dễ bị phá sản
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Gặp tai nạn giao thông.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Nữ giới mà có sao này thì tính cách khá quyết đoán và thẳng thắn.
      </p>
      <img style={{ width: "100%" }} src={bachho} />
    </div>
  );
};
export const BenhPhu = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Đây là sao dễ gây nên rủi ro bị bệnh và nguy cơ nhập viện cao.
      </p>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Biểu thị cho bệnh tật và tai hoạ liên tiếp xảy ra kéo dài từ năm trước
        sang năm nay. Sao này cũng được ứng dụng trong Phong Thủy Lưu Niên. Nếu
        vị trí của sao này lại còn trùng với sao Ngũ Hoàng hay Nhị Hắc thì nguy
        cơ gây ra bệnh tật thật sự rất cao.
      </p>

      <img style={{ width: "100%" }} src={benhphu} />
    </div>
  );
};
export const CachGiac = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Dễ cuốn hút tranh cãi, kiện tụng, chuyện phiếm, tranh chấp giấy tờ,
        chứng từ, than phiền từ khách hàng. Nói chung, sao Cách Giác là dấu hiệu
        của:
      </p>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Tranh cãi về giấy tờ, văn bản, thỏa thuận và hợp đồng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Rắc rối về pháp lý và cãi nhau, dễ dính kiện tụng và tù tội.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Khó thoát ra khỏi tình huống khó khăn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>Ví dụ:</p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Ngọ trong năm Thân.
      </p>

      <img style={{ width: "100%" }} src={cachgiac} />
    </div>
  );
};
export const DieuKhach = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Sao này biểu thị nguy cơ lây nhiễm bệnh tật, tang ma, người thân qua
        đời.
      </p>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi Điếu Khách chiếu đến hướng hay tọa nhà, nó biểu thị cho tang phục,
        bệnh tật và trẻ con bị bệnh.
      </p>

      <img style={{ width: "100%" }} src={dieukhach} />
    </div>
  );
};
export const HuyetNhan = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Sao này ám chỉ giải phẫu, tai nạn và chấn thương dẫn đến chảy máu nhiều.
        Hoãn lại việc giải phẫu thẩm mỹ trong 1 năm để tránh tai biến không cần
        thiết.
      </p>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sao Huyết Đao, như tên của nó, ám chỉ rằng:
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Cuộc đời đầy tai nạn, xui xẻo và thảm kịch.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Khả năng trải qua phẫu thuật, mổ xẻ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Sức khỏe kém và thường xuyên phải đi khám bác sĩ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Tai nạn hoặc các sự cố liên quan đến đổ máu do tiếp xúc vật sắc nhọn,
        dao kéo, vũ khí.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khả năng trải qua các biến cố trên càng cao hơn nếu Huyết Đao xuất hiện
        nhiều lần trong lá số Bát Tự.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tuy vậy, khả năng này chỉ phát huy tối đa nếu Huyết Đao cũng là kỵ thần.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu sao này là dụng thần thì những sự cố trên chỉ nhỏ và không đe dọa
        tính mạng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>Ví dụ:</p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Dần trong năm Thân
      </p>

      <img style={{ width: "100%" }} src={huyetnhan} />
    </div>
  );
};
export const KiemPhong = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Sao Kiếm Phong ám chỉ chấn thương, tai nạn, phẫu thuật, bị thương tổn vì
        vật sắc nhọn bằng kim loại.
      </p>

      <img style={{ width: "100%" }} src={kiemphong} />
    </div>
  );
};
export const LucAch = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Sao Lục Ách dựa trên vị trí Tử trên vòng trường sinh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ý nghĩa của sao Lục Ách là biểu thị nguy cơ chấn thương, tai nạn, phẫu
        thuật, bị thương tổn vì vật sắc nhọn bằng kim loại.
      </p>
      <img style={{ width: "100%" }} src={lucach} />
    </div>
  );
};
export const LuuHa = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Sao Lưu Hạ là một sao khá đặc biệt, nếu người trong lá số có sao Lưu Hạ
        thì biểu thị:
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Người mang Lưu Hạ dễ bị dính líu vào thù hận, bị người khác ghen ghét.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Nữ giới nên cẩn thận khi mang thai vì dễ bị sẩy thai.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Nếu sao Lưu Hạ đồng thời là kỵ thần thì sẽ dễ bị tổn thương.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Nếu sao Lưu Hạ là dụng thần thì biểu thị sự kiêu ngạo.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Nếu có sao Lưu Hạ trong mệnh thì người này dễ bị bệnh tim và máu
        huyết.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Cách tra sao Lưu Hạ theo bảng dưới đây:{" "}
      </p>
      <img style={{ width: "100%" }} src={luuha} />
    </div>
  );
};
export const PhiDao = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Sao Phi Đao biểu thị nguy cơ dễ bị chấn thương, trở thành tội phạm, dễ
        phá sản.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người có sao Phi Đao trong mệnh thường gặp tai nạn do thái độ hành vi
        cẩu thả.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngoài ra, người có mệnh gặp Phi Đao Sát cũng dễ gặp tai nạn hay chấn
        thương, tài lộc kém (do cẩu thả trong quản lý tiền bạc).
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người có sao Phi Đao trong mệnh cũng dễ bị dính líu đến tai tiếng như bị
        buộc tội, phỉ báng, vấn đề danh tiếng, tai tiếng, đấu pháp lý.{" "}
      </p>

      <img style={{ width: "100%" }} src={phidao} />
    </div>
  );
};
export const PhiMa = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Sao Phi Ma biểu thị khả năng cao bị bệnh tật xấu và ngay cả xảy ra cái
        chết cho người già ở nhà.
      </p>

      <img style={{ width: "100%" }} src={phima} />
    </div>
  );
};
export const PhucThi = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Sao Phục Thi cũng ám chỉ nguy cơ bệnh tật, sức khỏe xấu, đặc biệt là
        những bệnh về tâm lý hoặc thậm chí cả bệnh gây ra do tâm linh. Nếu đi
        kèm với sao Tang Môn, Điếu Khách thì người có mệnh mang sao Phục Thi cần
        phải hết sức thận trọng.{" "}
      </p>

      <img style={{ width: "100%" }} src={phucthi} />
    </div>
  );
};
export const TaiSat = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Sao Tai Sát là một trong 3 sát khí trong tổ hợp Tam Sát: Tai Sát, Kiếp
        Sát và Tuế Sát.
      </p>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Sao Tai Sát biểu thị khả năng dễ gặp tai họa hỏa hoạn hoặc đuối nước.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Ngoài ra, sao Tai Sát cũng biểu thị dễ bị bệnh liên quan đến tình dục
        hoặc tai tiếng do liên quan đến scandal tình dục.{" "}
      </p>

      <img style={{ width: "100%" }} src={taisat} />
    </div>
  );
};
export const TamSat = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Đây là khái niệm Phong Thủy rất phổ biến theo đó họ tránh ngồi xoay lưng
        về Tam Sát.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trong Bát Tự thì Tam Sát được tính từ năm sinh
      </p>

      <pre style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Năm Sinh Tam Sát <br />
        Thân Tý Thìn Tỵ Ngọ Mùi
        <br />
        Dần Ngọ Tuất Hợi Tý Sửu
        <br />
        Hợi Mão Mùi Thân Dậu Tuất Tỵ Dậu Sửu Dần Mão Thìn
        <br />
      </pre>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trong Bát Tự thì Ngày Tam Sát là những ngày không tốt và không nên dùng
        cho những việc quan trọng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>Ví dụ</p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bát Tự dưới đây là của Dylan Klebold, một trong 2 kẻ giết người trong vụ
        thảm sát tại trường Columbine năm 1999. Hắn sinh ngày 11/9/1981.{" "}
      </p>
      <pre style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Stephen King – 21/9/1947 <br />
        Giờ Ngày Tháng Năm
        <br />
        Ất Nhâm Đinh Tân
        <br />
        Tỵ Thìn Dậu Dậu
        <br />
        31 21 11 1<br />
        Quý Giáp Ất Bính
        <br />
        Tỵ Ngọ Mùi Thân
        <br />
      </pre>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Hắn là người quá nặng về thủy với nhiều kim sinh thủy, gọi là Đa Thủy
        Kim Chìm. Vào năm 18 hắn ở đại vận bất lợi với quá nhiều thổ sinh trợ
        cho kim. Vì ấn là ý tưởng nên kỵ thần dẫn đến hắn có những suy nghĩ kỳ
        quặc. Vào năm 1999, Mão xung với 2 Dậu kích hoạt kim hoạt động nên hắn
        bị điên tiến hành hoạt động tội ác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Hắn sinh vào năm Dậu nên Tam Sát là Dần Mão Thìn. Vào thơi điểm đó chính
        là Tam Sát.
      </p>
      <pre style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Stephen King – 21/9/1947 <br />
        Giờ Ngày Tháng Năm
        <br />
        Đinh Nhâm Mậu Kỷ
        <br />
        Tỵ Dần Thìn Mão
        <br />
      </pre>
      <pre style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Alexander McQueen tự tử bằng cách treo cổ chết vào ngày Tam Sát
        17/3/1969
        <br />
        Giờ Ngày Tháng Năm
        <br />
        Kỷ Tân Đinh Kỷ
        <br />
        Hợi Mão Mão Dậu
        <br />
        43 33 23 13 3<br />
        Nhâm Quý Giáp Ất Bính
        <br />
        Tuất Hợi Tý Sửu Dần
        <br />
      </pre>
      <pre style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Chết 11/2/2010
        <br />
        Giờ Ngày Tháng Năm
        <br />
        Quý Nhâm Mậu Canh
        <br />
        Mão Thìn Dần Dần
        <br />
      </pre>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ông ta treo cổ vào ngày Tam Sát. Dần Mão Thìn là Tam sát treo cổ.
      </p>
      <img style={{ width: "100%" }} src={tamsat} />
    </div>
  );
};
export const TangMon = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Còn có tên khác là Địa Tang.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sao này là biểu thị lo lắng, căng thẳng, khó khăn và cảm xúc. Nó còn
        biểu thị sự mất mát người thân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu 1 người có 2 hoặc nhiều hơn trong lá số thì có xu hướng dễ bị u sầu
        và trầm cảm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu các sao này ở trong Đại vận hay Năm thì khả năng bị mất mát hay
        stress tăng cao.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Cùng với sao Thiên Cẩu, Điếu Khách là hung tinh. Không chỉ kỵ thấy trong
        mệnh vì không tốt, nếu gặp Lưu niên hay Đại vận có Tang Môn cũng xấu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đa phần chủ về người thân qua đời, khóc lóc, có tang sự, bị bệnh, thương
        tật xuất hiện. Do đó cần phải thật cẩn trọng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người có Tang Môn trong lá số nên:
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Tránh tham dự đám tang hoặc các hoạt động chôn cất.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Tránh đến nghĩa trang, nhà tro cốt...
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Tránh thăm viện người thân hay bạn bè bị đau ốm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bởi vì những người này dễ bị tâm linh quấy rối như vong ám, nhập, hay
        tưởng tượng thấy nếu họ không tuân thủ theo những điều trên. Họ cũng dễ
        bị bệnh tật và gặp nhiều xui xẻo. Vào năm xấu có Tang Môn, thì sẽ gặp
        tài chính xấu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu như lại có thêm Dương Nhẫn chiếu đến thì khó tránh hoạ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>Ví dụ:</p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Dần và năm Thìn
      </p>

      <img style={{ width: "100%" }} src={tangmon} />
    </div>
  );
};
export const ThaiTue = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Nếu năm sinh của bạn xung với Thái Tuế, hãy chuẩn bị cho 1 năm khó khăn
        về nghề nghiệp, học tập, quan hệ và sức khỏe. Nó đem lại nhiều giây phút
        căng thẳng và quan hệ với người xung quanh bạn như bạn bè sẽ bị gặp khó
        khăn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đây là một trong những thần sát đáng sợ nhất trong Trạch Nhật và Phong
        Thủy.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        - Có 2 loại xung Thái Tuế:
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Đối với người, đó là khi năm sinh trùng Thái Tuế hoặc xung với Thái
        Tuế.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Đối với nhà hay mộ, đào hay động thổ hoặc chặt phá cây tại khu vực
        Thái Tuế được xem là xung Thái Tuế và đó là phạm rất nặng với hậu quả
        nặng nề như chấn thương hay chết chóc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Cách hoá giải Thái Tuế là dùng phù Thái tuế đểm giảm tác dụng của Thái
        Tuế và cầu xin được bảo hộ. Đây là quy trình:
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Điền vào tên năm và tên của Thái Tuế
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Chuẩn bị sẵn đồ cúng (nó có thể đơn giản như vài miếng bánh, trái cây
        hay thịt rượu, sự chân thành mới là điều quan trọng)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Đốt 3 nén hương
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Đặt phù ở hướng Thái Tuế năm nay
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Đọc “chú thỉnh Tái Tuế”
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Đốt lá phù Thái Tuế
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Hay nếu bạn muốn đơn giản, bạn có thể đọc chú và dán phù Thái Tuế ở
        hướng Thái Tuế.
      </p>

      <img style={{ width: "100%" }} src={thaitue} />
    </div>
  );
};
export const ThienAch = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Sao Thiên Ách biểu thị rắc rối liên quan đến sức khỏe do làm việc quá
        độ. Người này dễ bị cấp trên chèn ép, gánh nhiều trách nhiệm nặng nề
        trong công việc do bị cấp trên giáng xuống và sinh ra căng thẳng.{" "}
      </p>

      <img style={{ width: "100%" }} src={thienach} />
    </div>
  );
};
export const ThienCau = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Sao Thiên Cẩu biểu thị nguy cơ gặp tai nạn, cướp, chấn thương, giải
        phẫu, gây sốc, đặc biệt những người làm việc trong môi trường độc hại,
        phẫu thuật.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Sao chủ về chấn thương, tai nạn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Cần đề phòng nếu gặp năm Thiên Cẩu, dễ bị tai nạn, đề phòng tiểu nhân
        và bệnh tật.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Vào những năm gặp sao Thiên Cẩu, phụ nữ cần thận trọng khi đi làm đẹp
        vì dễ để lại sẹo xấu về mặt thẩm mỹ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>Ví dụ:</p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Người sinh ngày Thìn trong năm Ngọ
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Người sinh năm Thìn gặp đại vận Dần
      </p>
      <img style={{ width: "100%" }} src={thiencau} />
    </div>
  );
};
export const ThienKhoc = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Sao Thiên Khốc biểu thị sự kiện khóc lóc và có thể vì người thân yêu qua
        đời, đám tang.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu trong mệnh có sao Thiên Khốc ở trụ tháng biểu thị mối quan hệ tình
        cảm với bố mẹ không tốt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu trong mệnh có sao Thiên Khốc ở trụ ngày biểu thị không hạnh phúc
        trong quan hệ vợ chồng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu trong mệnh có sao Thiên Khốc ở trụ giờ biểu thị không hạnh phúc
        trong tình cảm bố mẹ con cái.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu gặp đại vận hay lưu niên có sao Thiên Khốc thì biểu thị có sự kiện
        đau buồn, cảm xúc không tốt hoặc người thân yêu qua đời.
      </p>

      <img style={{ width: "100%" }} src={thienkhoc} />
    </div>
  );
};
export const ThienSat = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Sao này cũng biểu thị nguy cơ tai nạn và hiểm họa có thể rơi xuống đầu
        bạn theo cả nghĩa bóng và nghĩa đen. Do đó khi đi vào nơi công trình xây
        dựng, có vật nguy hiểm, nặng nhọn sắc trên cao như cành cây, cột điện có
        thể rơi xuống.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Cách tính sao Thiên Sát cũng tương tự như sao Thiên Ách ở phần trên. Có
        thể là mang nhiều tên gọi khác nhau nhưng chúng tôi vẫn tôn trọng học
        thuật nên xem như là 1 phần riêng.
      </p>

      <img style={{ width: "100%" }} src={thiensat} />
    </div>
  );
};
export const ThienY = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Đây là sao tốt giống như 1 quý nhân đến giúp bạn qua mọi bệnh tật hiểm
        nghèo.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sao này liên quan đến bệnh tật, nếu như cũng là kỵ thần thì biểu thị dễ
        mắc bệnh tuy nhiên cũng sẽ tìm được đúng thầy đúng thuốc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu Sao Thiên Y là dụng thần biểu thị khả năng trở thành bác sĩ giỏi.
        Nếu đi kèm với sao Văn Xương biểu thị tài năng trong lĩnh vực nghiên cứu
        y học.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đây cũng là thần sát phù hợp nhất để học hỏi về huyền học, ngũ thuật,
        triết học, tâm lý học và các môn khoa học.{" "}
      </p>

      <img style={{ width: "100%" }} src={thieny} />
    </div>
  );
};
export const TueHinh = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Hình biểu thị sự lo âu, trầm cảm về mặt tình cảm, căng thẳng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu người trong lá số có Tuế Hình biểu thị người dễ tự gây ra cho mình
        nhiều lo lắng, mệt mỏi về mặt tinh thần. Tất cả những vất vả, khó khăn,
        mệt mỏi đều là do bản thân họ gây ra.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Về mặt tâm linh thì Bát Tự tin rằng Tuế Hình là tất cả khó khăn, hình
        phạt là do họ tự gây ra, do nghiệp quả trong đời này hoặc những đời
        trước.
      </p>

      <img style={{ width: "100%" }} src={tuehinh} />
    </div>
  );
};
export const TueSat = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Tuế Sát biểu thị tai nạn nguy hiểm về mặt sức khỏe thậm chí là tính
        mạng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu năm mà mệnh gặp Tam Sát ở Lưu niên hay Đại vận thì cần hết sức tránh
        động thổ, đào đất, chặt cây tại những khu vực ở nhà có phạm đến Tam Sát
        Phong Thủy.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu thật sự bị Tuế Sát trong mệnh mà lại còn chọn ngày giờ phạm Tam Sát
        (đã nói ở phần trên) để làm những việc quan trọng như xây cất, động thổ,
        thậm chí làm tại phương có Tuế Sát thì nguy hiểm tăng gấp đôi, gấp 3
        lần.
      </p>

      <img style={{ width: "100%" }} src={tuesat} />
    </div>
  );
};
export const TuPhu = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Sao Tử Phù biểu thị nguy cơ bị chấn thương trong khi đánh nhau hay cãi
        nhau, tai nạn ở nơi làm việc đặc biệt nếu như bạn làm việc trong môi
        trường độc hại.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đây là sao cần tránh nhìn thấy trong mệnh, nếu có thì gặp phải thời điểm
        có lưu niên hay đại vận dễ gặp tình trạng năng lượng thấp, sức khỏe xấu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu gặp sao Tử Phù ở tháng thì bố mẹ sức khỏe không tốt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu gặp sao Tử Phù ở ngày sinh thì biểu thị vợ hay chồng sức khỏe không
        tốt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu gặp sao Tử Phù ở giờ sinh thì biểu thị con cái dễ bị sức khỏe không
        tốt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu sao Tử Phù trùng với Không Vong thì người này có khả năng nhìn thấy
        người đã mất. Dễ làm các công việc tâm linh, phong thủy.
      </p>

      <img style={{ width: "100%" }} src={tuphu} />
    </div>
  );
};
export const TucThan = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Tức Thần biểu thị dễ bị bệnh về hệ miễn dịch, suyển.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người này dễ bị các bệnh về hô hấp, khó thở.
      </p>

      <img style={{ width: "100%" }} src={tucthan} />
    </div>
  );
};
export const DichMa = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Người trong lá số có Dịch Mã biểu thị sẽ có rất nhiều thay đổi và tiến
        bộ về nghề nghiệp, có cơ hội nghề nghiệp ở nước ngoài, di cư, mở mang
        thị trường mới. Đi càng nhiều thì càng nhiều may mắn cho tài lộc và đào
        hoa.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Dịch Mã biểu thị sự di chuyển hoặc xu hướng hành động. Dịch Mã cũng biểu
        thị sự bận rộn, đi ra ngoài. Nếu nhiều sao Dịch Mã thì đầu óc không ổn
        định, phù hợp phát triển sự nghiệp ở nước ngoài, có nhiều thay đổi trong
        sự nghiệp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Do đó, sao Dịch Mã còn được gọi là Tài Tinh Động, xét về lá số Bát Tự
        biểu thị khả năng tạo ra thu nhập và thăng tiến của người này trong cuộc
        đời nếu thường xuyên đi xa, dịch chuyển trong đời.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sao này cung cấp động lực để thăng tiến và trưởng thành trong đời. Nó sẽ
        giúp cho người thường xuyên phải làm các công việc đi lại để kiếm tiền,
        càng đi nhiều thì càng gặp nhiều may mắn. Các công việc phù hợp là ngoại
        giao, nhân viên, diễn viên, tài xế, làm nhà hàng, nhà văn, du lịch, thủy
        thủ đoàn, nhân viên bán hàng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu Dịch Mã đồng thời là dụng thần thì càng đi nhiều thì càng có nhiều
        kết quả tốt. Ngược lại, nếu là kỵ thần thì sẽ đem đến nhiều kết quả bất
        lợi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sao Dịch Mã biểu thị sự dịch chuyển từ nơi này đến nơi khác, di dân, đổi
        việc, du lịch…Khi sao Dịch Mã là dụng thần, thì người đó sẽ thông minh,
        dễ thích nghi và chủ động trong hành động. Khi sao Dịch Mã là kỵ thần
        thì người đó quyết định sai lầm và thiếu quyết tâm. Nếu lá số 1 người có
        sao Dịch Mã thì luôn có hành động và thay đổi trong cuộc đời người này.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Hiệu ứng của sao Dịch Mã thay đổi tùy theo lá số. Nếu sao Dịch Mã là tốt
        thì nó sẽ đem đến cơ hội nghề nghiệp, tin tức tốt và cơ hội. Nếu sao
        Dịch Mã ở Tài Dụng thần thì có cơ hội kiếm tiền nhanh. Nếu gắn với sao
        Quan dụng thần thì cơ hội thăng tiến nghề nghiệp bất ngờ. Nếu gắn với Kỵ
        Thần thì làm việc nhiều nhưng không có lợi. Khi sao Dịch Mã ở cùng sao
        Cô Thần và Điếu Khách thì có khả năng bỏ xứ ra đi kiếm tiền. Chúng ta
        cần xét 2 sao Dịch mã khi xuất hiện ở năm và tháng. Lưu ý rằng sao Dịch
        Mã có thể gắn với Thiên Can khác. Có nghĩa rằng hiệu ứng sẽ khác nhau.
        Với lá số Altman thì sao Dịch Mã rõ ràng có lợi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Một điểm khác cần lưu ý: nếu chúng ta muốn điều tốt, chúng ta luôn phải
        đi theo dòng chảy. Khi sao Dịch Mã tiến đến, thì ngay cả nếu không có cơ
        hội để đi xa, công việc mới thì cũng nên tận dụng cơ hội này để sơn
        phết, sửa chửa nhà cửa, vận động thể dục nhiều hơn…Nếu chúng ta không
        làm gì, cơ hội xảy ra tai nạn hay chấn thương sẽ tăng cao. Điều này có
        thể khiến chúng ta phải ở trong bệnh viện, tức cũng là Dịch Mã.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>Ví dụ:</p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Người sinh năm Ngọ, giờ Thân
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Người sinh năm Hợi, ngày Dậu
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Người sinh ngày Sửu, trong đại vận Hợi
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Xét người đạt giải Nobel Sidney Altman (7/5/1939). Người này sinh vào
        năm Mão, sao Dịch Mã Tỵ ở cột tháng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Vào năm 1960, Altman tốt nghiệp trường MIT. Vào năm 1961, ông học tại
        đại học Columbia trước khi chuyển sang Colorado năm 1962. Sau khi nhận
        bằng Tiến Sỹ, Altman bắt đầu nghiên cứu tại đại học Harvard và Cambridge
        trước khi trở thành trợ giảng tại Yale năm 1971. Trong vòng chưa đến 10
        năm, ông dịch chuyển 5 lần. Vào năm 1989, năm Kỷ Tỵ, ông bay đến Thụy
        Điển nhận giải Nobel.{" "}
      </p>

      <img style={{ width: "100%" }} src={dichma} />
    </div>
  );
};
export const DuongNhan = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Người có Dương Nhẫn Sát trong lá số thích hợp làm công việc quân sự,
        cảnh sát, bác sĩ phẫu thuật hay kỹ thuật.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người mang sao này thường mạnh mẽ và can đảm chống lại mọi điều bất
        công. Ít kiên nhẫn, thường dính líu cảm xúc, dễ gặp căng thẳng. Thường
        không hợp với anh chị em. Tính cách quyết đoán, dám nghĩ dám làm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đây là dấu hiệu dễ gặp tai nạn hay chấn thương gây ra bởi người khác có
        thể khiến bạn bị tàn tật hay và tai hoạ dẫn đến khả năng chết chóc trong
        gia đình. Có thể làm cho bạn rất cảm xúc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sao này biểu thị bản thân là can ngày Dương sinh vào tháng mạnh nhất.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Vì vậy người này mạnh đến cực đoan, giống như 1 người đứng tại mép vực,
        bước thêm 1 bước nữa thì anh ta sẽ bị ngã. Do đó gọi là Dương Nhẫn. Có 4
        loại Dương Nhẫn như dưới đây.
      </p>
      <img style={{ width: "100%" }} src={duongnhan} />

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Dương Nhẫn tức là tháng có ngũ hành giống bản thân, có nghĩa là con
        người. Vì vậy 1 người có Dương Nhẫn thường sẽ mạnh vì có sự trợ giúp của
        người khác. Vì vậy, người có dương nhẫn sẽ có tố chất lãnh đạo và thường
        có thể làm tướng lĩnh thống chế, cảnh sát trưởng hay người mà nghề là
        quản trị nhiều người.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Dương Nhẫn là ngũ hành bản thân rất mạnh, bởi vì họ sinh vào đúng
        mùa và là tháng mạnh nhất của ngũ hành bản thân. Vì vậy ngũ hành Dương
        Nhẫn biểu thị kẻ thù, người cạnh tranh. Vì vậy tình huống bất lợi nhất
        cho người Dương Nhẫn là Dương Nhẫn bị xung. Vì Dương Nhẫn là ngũ hành
        rất mạnh, bất cứ gì xung cũng sẽ kích hoạt nó. Vì vậy kẻ thù cạnh tranh
        bị kích thích hành động và sẽ bị chấn thương hay mất tiền. Lấy ví dụ,
        một người Giáp mộc sinh tháng Mão thì vì gặp vận hay năm Dậu sẽ bị kích
        hoạt Dương Nhẫn nên cần mang theo 1 con vật để giảm bớt xung khi gặp năm
        hay vận xấu. Có thể đeo Thìn để hóa giải.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Từ Dương Nhẫn xuất phát từ 1 vi tướng anh hùng trong lịch sử là Nhạc
        Phi.
      </p>
      <img style={{ width: "100%" }} src={duongnhan1} />
      <img style={{ width: "100%" }} src={duongnhan2} />

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhạc Phi 1103 – 1141, chết vào năm 1141 là năm Tân Dậu xung với Dương
        Nhẫn Mão
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ví dụ: Điển hình của Dương Nhẫn là Ingrid Betancourt từng là chính trị
        gia Colombia và nhà hoạt động chống tham nhũng. Bà bị bắt cóc năm 2002
        và bị nhốt trong rừng đến 2008 thì được cứu
      </p>
      <img style={{ width: "100%" }} src={duongnhan3} />
      <img style={{ width: "100%" }} src={duongnhan4} />
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngày bị bắt cóc: 23/2/2002
      </p>
      <img style={{ width: "100%" }} src={duongnhan5} />
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Năm và nhất là cả ngày tháng năm đều xung với Dương Nhẫn.
      </p>
      <img style={{ width: "100%" }} src={duongnhan6} />
    </div>
  );
};
export const HocDuong = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Sao Học Đường biểu thị thành công và cơ hội trong học hành hay viết ra
        những tác phẩm giá trị. Đối với người có sao Văn Xương hay Học Đường
        trong lá số, họ sẽ là những học trò giỏi. Ngay cả nếu như 1 người không
        phải là quá học thuật, họ vẫn là người ham học.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Giống sao Quý Nhân, ta cũng có thể xác định và kích hoạt vị trí và ngày
        Văn Xương để gia tăng cơ hội học hành.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Biểu thị tài trí, học lực tốt. Như sao Văn Xương, biểu thị khả năng đọc
        sách, học hỏi. Chủ thành công thông qua đường học vấn, nghiên cứu học
        thuật, văn chương xuất chúng, một đời phú quý.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người có Học Đường tương đối thông minh, thi cử thành đạt, đăng khoa cập
        đệ, công danh hiển đạt. Đạt thành tích học tập, khả năng hấp thu kiến
        thức và học hỏi nhanh
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Cách tra sao Học Đường có nhiều cách khác nhau:
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Một trong những cách khá phổ biến là lấy ngũ hành nạp âm của trụ năm tra
        ra Địa Chi của tháng ngày giờ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Nạp âm của năm là Mộc, chi tháng ngày giờ gặp Hợi là Học Đường nhập
        mệnh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Nạp âm của năm là Hoả, chi tháng ngày giờ gặp Dần là Học Đường nhập
        mệnh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Nạp âm của năm là Thổ, chi tháng ngày giờ gặp Thân là Học Đường nhập
        mệnh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Nạp âm của năm là Kim thì chi tháng ngày giờ gặp Tỵ là Học Đường nhập
        mệnh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        • Nạp âm của năm là Thuỷ thì chi tháng ngày giờ gặp Thân là Học Đường
        nhập mệnh
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ví dụ: Chúng ta có thể làm việc hay học tập ở khu vực Văn Xương trong
        nhà hay phòng. Chúng ta cũng có thể đặt tượng con vật liên quan đến Văn
        Xương trong phòng nơi chúng ta làm việc hay học tập.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>Ví dụ:</p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Một người sinh vào ngày Tân Kim thì Tý là sao Văn Xương. Khu vực Tý,
        hướng Bắc của nhà hay nơi làm việc là tốt để gia tăng học thuật.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu không có thì khi gặp đại vận cũng sẽ gặp nhiều may mắn.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Lý Chánh Đạo đạt giải Nobel năm 1957, Đinh Dậu khi có cả Văn Xương và
        Quý Nhân ở Dậu.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi cả Văn Xương hay Học Đường là Tài của ai đó thì họ kiếm tiền thông
        qua tài năng của chính họ. Ngày Mậu Thân chính là ví dụ cụ thể. Một số
        người sinh vào ngày này.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        1. Trương Chi Đồng (sinh ngày 4/9/1847)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        2. Mark Zuckerberg (sinh 14/5/1984)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        3. Michael Dell (23/2/1965)
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        4. Samuel Tang (27/1/1936), đạt giải Nobel năm 1976.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        5. Gordon Moore (3/1/1929), sáng lập Intel
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người này Thân nằm ở cung Phu Thê. Biểu thị rằng họ sẽ lấy người
        vợ/chồng không chỉ tài năng mà còn giúp đỡ họ trong cuộc sống.
      </p>

      <img style={{ width: "100%" }} src={hocduong} />
    </div>
  );
};
export const HocSi = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Người trong lá số có sao Học Sĩ biểu thị khả năng học tập giỏi và thi cử
        tốt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngoài ra, ảnh hưởng tích cực của sao này sẽ được củng cố thêm nếu kèm
        theo Chính Quan hay Chính Ấn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tuy nhiên, nếu sao này bị Không Vong thì có thể biểu thị học tập nghiên
        cứu không tiến bộ, gặp khó khăn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>Ví dụ:</p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Kỷ trong tháng Dậu.
      </p>

      <img style={{ width: "100%" }} src={hocsi} />
    </div>
  );
};
export const KhoiCuong = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Trụ Khôi Cương có thể xuất hiện ở năm, tháng, ngày hay giờ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu người càng có nhiều trụ Khôi Cương lặp lại thì mức độ ảnh hưởng tiêu
        cực càng cao hơn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tuy nhiên, chủ yếu tác dụng tiêu cực là nằm ở trụ ngày sinh. Người sinh
        1 trong 4 ngày sau được xem là ngày sinh Khôi Cương:
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mậu Tuất / Canh Tuất / Canh Thìn / Nhâm Thìn
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu có sao này thì là người mạnh mẽ thẳng thắn, dám nói thẳng, hành động
        quyết đoán, lãnh đạo mạnh mẽ, có kiến thức, quyền lực và nghiêm khắc về
        đạo đức. Cuộc đời thăng trầm, khôn ngoan và quyết liệt, suy nghĩ tích
        cực và làm việc chăm chỉ, không sợ kẻ xấu hay ma quỷ, quỷ thần và quỷ sẽ
        tránh người này bởi vì bạn có sao Khôi Cương. Thành tích sự nghiệp to
        lớn. Phụ nữ có sao này thì chung thủy, có sức mạnh quyết đoán của đàn
        ông và rất cứng rắn, và họ thường đẹp nhưng gặp nhiều thăng trầm trong
        đời. Nói chung, là kiểu người cứng rắn, kiên trì và quyết tâm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngoài ra, những người sinh vào ngày này có năng lực cao, rất tham vọng
        và đầy động lực chinh phục mục tiêu. Nếu có 3 Khôi Cương trong lá số kể
        cả trụ ngày thì người này sẽ đạt nhiều thành công và danh tiếng. Càng
        tốt hơn nếu cả 3 cùng là Thìn hay Tuất, vì lúc đó các trụ này sẽ không
        xung lẫn nhau, nếu không sẽ gặp vấn đề sức khỏe, kém may mắn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Lưu ý là ngay cả nếu không có Thìn Tuất xung trong lá số, thì sẽ có xung
        trong vận Thìn hay vận Tuất, lưu niên, nguyệt,…Những người có Khôi Cương
        sẽ có thể hành động phù hợp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Vận động viên Jeremy Lin có 2 trong lá số (năm và ngày). Năm 2012, cũng
        là năm Nhâm Thìn, ông được công nhận thành tích thể thao.
      </p>
      <pre style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Jeremy Lin sinh ngày 23/8/1988<br/>
        Giờ     Ngày    Tháng   Năm <br/>
                Canh    Canh    Mậu <br/>
                Tuất    Thân    Thìn
        
      </pre>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
      Có 2 Phi Không, Khôi Cương trong lá số. 2 trụ này giúp anh ta giàu có và thịnh vượng. Khi có 3 trụ thì lợi ích càng nhiều. Và không ngạc nhiên khi năm 2012 – Nhâm Thìn, Lin nổi tiếng về thành tích NBA. 
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
      Vào ngày 20/1/2011, ngày Canh Thìn, Lin một lần nữa gây ấn tượng vì có đến 3 Quỷ Cương. Trong năm 2012, ngày 4/2/2012, ông ghi được 25 điểm. Đó là ngày Ất Mùi, là ngày tài lộc cho trụ ngày Canh Tuất. Ất Mộc và Canh kim kết hợp là ngũ hành tài lộc đến với người sinh can Canh. Vào ngày 14/2/2012, ngày Ất Tỵ, Lin ghi bàn 3 điểm. Đó cũng là ngày tài lộc tốt.      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
      Lin là 1 trường hợp rất đặc biệt vì không phải ai là Khôi Cương cũng may mắn thuận lợi về danh tiếng và tiền bạc. 
            </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
      Những người này đặc biệt mạnh mẽ và nếu ngày sinh vào đúng những ngày này thì có tính cách cứng rắn và ý chí mạnh. Những người này không sợ ma.            </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
      Li Na, Maria Sharapova cũng là ngày Khôi Cương. Tiger Woods cũng là Khôi Cương            </p>
      <pre style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
      Tiger Woods 30/12/1975 <br/>
        Giờ 	Ngày	  Tháng    Năm <br/>
		Đinh	Canh	  Mậu	     Ất<br/>
		Hợi 	Tuất	  Tý	     Mão<br/>
       
      </pre>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những vận động viên này rất quyết tâm chiến thắng dù phải vượt mọi khó khăn.
                        </p>
        <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngoài 4 ngày kể trên thì theo một số nguồn tài liệu khác thì Khôi Cương có được liệt kê khác hơn. Để tôn trọng học thuật, chúng tôi liệt kê Khôi Cương theo tất cả các nguồn tài liệu khác nhau.                         </p>
        
      <img style={{ width: "100%" }} src={khoicuong} />
    </div>
  );
};
export const ThoaiThan = () => {
    return (
      <div style={{ position: "relative", zIndex: "999" }}>
        <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Ngày sinh Thoái Thần như dưới đây: Đinh Sửu, Đinh Mùi, Nhâm Thìn, Nhâm Tuất.
        </p>
        <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người có ngày sinh Thoái Thần, thường dễ bị đuổi việc, sa thải, không suôn sẻ trong sự nghiệp cá nhân và khó thành công trong đời. Nên thận trọng trong sự nghiệp, nếu ham thích nhanh chóng dễ thất bại nhanh chóng.
        </p>
        
  
        <img style={{ width: "100%" }} src={thoaithan} />
      </div>
    );
  };
export const TienThan = () => {
    return (
      <div style={{ position: "relative", zIndex: "999" }}>
        <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Ngày sinh thuộc về 4 ngày sau gọi là ngày sinh Tiến Thần: Giáp Tý, Giáp Ngọ, Kỷ Mão, Kỷ Dậu
        </p>
        <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người có ngày sinh này thường dễ thành công nhanh, ngoại hình ưa nhìn và tính cách thu hút. Quyết tâm trong hành động, tính cách tích cực. Độc lập, và sẽ đạt kết quả trong công việc và rất tập trung vào nghiên cứu và học tập. Nhưng sao này sợ sao Đào Hoa. Đàn ông sẽ thất bại vì say mê phụ nữ và nếu là lá số nữ giới thì đẹp nhưng đa phần dễ thất bại vì vấn đề tình cảm.        </p>
        
  
        <img style={{ width: "100%" }} src={tienthan} />
      </div>
    );
  };

export const TuVi = () => {
    return (
      <div style={{ position: "relative", zIndex: "999" }}>
        <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Tử Vi là sao chủ về quản quyền lực, địa vị ở mức độ cao nhất. 
                </p>
        <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người có sao Tử Vi là người có quyền lực cao, có thể nắm giữa vị trí lãnh đạo, được nhiều người kính nể.         
        </p>
        <img style={{ width: "100%" }} src={tuvi} />
      </div>
    );
  };

export const TuongTinh = () => {
    return (
      <div style={{ position: "relative", zIndex: "999" }}>
        <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Sao Tướng Tinh là một cát tinh. Sao này rất tốt cho khởi nghiệp, thăng tiến, thi cử, bầu cử, tham gia chính trị và đầu tư.
        </p>
        <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sao này biểu thị người văn võ toàn tài, rất thông minh. Người này sẽ có thu nhập cao và có địa vị cao trong xã hội và nghề nghiệp.     
                </p>
        <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sao Tướng Tinh nói chung biểu thị: 
                        </p>
        <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        •	Khả năng lãnh đạo tốt
                        </p>
        <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        •	Khả năng văn võ song toàn.
                        </p>
        <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tuy nhiên, sao này cũng cần:
                                </p>
        <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        •	Hợp với Chính Quan, Thất Sát hay Ấn Tinh thì có thể phát huy tối đa khả năng.
                                        </p>
        <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        •	Nếu tọa trên Chính Quan thì có khả năng phát huy lãnh đạo cao.
                                </p>
        <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        •	Nếu tọa Thất Sát hay Tài Tinh thì có địa vị xã hội cao, tuy nhiên cũng nhiều lúc nằm giữa ranh giới sinh tử.                                </p>
        <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        •	Tọa Thất Sát thì là quan tòa cấp cao. 
                                        </p>
        
        <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        •	Nếu tọa Tài Tinh thì ngày sinh này sẽ có quyền lực cao về tài chính. 
                                                </p>
        <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        •	Cũng cần lưu ý rằng Tướng Tinh sẽ vô dụng nếu lá số không có Tài Tinh, Chính Quan hay Thất Sát.
                                                </p>
        <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        •	Nếu Tướng Tinh là kỵ thần thì kết quả ngược lại. 
                                                </p>
        <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ví dụ: 
                                                </p>
        <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Tý và năm Thìn
                                                </p>
        
  
        <img style={{ width: "100%" }} src={tuongtinh} />
      </div>
    );
  };
export const VanXuong = () => {
    return (
      <div style={{ position: "relative", zIndex: "999" }}>
        <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Sao Văn Xương còn gọi là Văn Xương Quý Nhân và được xem là một cát tinh.         </p>
        <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Cát tinh có thể giúp hóa giải những sao xấu khác, chủ gặp hung hoá cát. Đây là sao biểu thị sự thông minh và sáng suốt. Suy nghĩ và hành động khôn ngoan. Ăn nói hoặc viết lách đều thuyết phục. Tính cách vui vẻ, và là người thích chú ý đến tiểu tiết cũng như luôn làm việc có phương pháp. Người trong mệnh có Văn Xương Quý Nhân thường siêng học và có thành tích học tập tốt, trí tuệ sung mãn, trí nhớ tốt. Văn Xương có thể giúp cho mệnh có địa vị, quyền lực, danh lợi song toàn. Đối với những người trưởng thành thì lợi về sự nghiệp, có thành công nổi tiếng. Trong Phong Thuỷ thì Văn Xương có tác dụng rất lớn cho trẻ con và người lớn.                </p>
        <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khả năng thể hiện và tốt cho việc học hành, nghệ thuật và theo đuổi việc học hỏi cao hơn, được thừa nhận tài năng. Mệnh gặp Văn Xương Tinh thì khí chất nho nhã, văn tài xuất chúng, phong độ ngời ngời.                        </p>
        <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đây là sao chủ yếu về học hành, trí thức. Người có sao này trong lá số thường thích học hành, đọc sách, có thành tích học tập. Hầu hết là tác giả nổi tiếng, học giả đều có. Ví như Stephen King, JKRowling, Virgina Wolf, Mark Zuckeberg, Albert Einstein, Agatha Christie, Dan Brown… Sao Văn Xương được trời phú nên nó liên quan đến thiên can của năm sinh và ngày sinh.
                        </p>
        <pre style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thiên Can của Ngày hoặc Năm Sinh		Văn Xương <br/>
        Giáp							                       Tỵ <br/>
        Ất							                         Ngọ <br/>
        Bính Mậu						                    Thân <br/>
        Đinh Kỷ						                     Dậu <br/>
        Canh							                       Hợi <br/>
        Tân							                        Tý <br/>
        Nhâm							                       Dần <br/>
        Quý							                        Mão <br/>

                        </pre>
        <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bản chất của sao Văn Xương là liên quan đến ngũ hành Thực, do đó bất cứ ai có những sao trên là ngũ hành được sinh ra bởi thiên can. Ví dụ Giáp mộc sinh Tỵ hỏa
                                </p>
        <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sao Văn Xương là tài năng bẩm sinh nên chỉ xem được trong tứ trụ. Vị trí sao Văn Xương cũng có thể chỉ người tương ứng có tài năng học thuật. Ví dụ người sinh ngày Mậu Thân hay Kỷ Dậu thì người vợ/chồng có tài năng.
                                        </p>
        <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người sau có Văn Xương:
                                </p>
        <pre style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Stephen King	21/9/1947	<br/>
	    Giờ 	Ngày	Tháng    Năm<br/>
	    Quý 	Quý    Kỷ  	  Đinh<br/>
	    Sửu 	Mão 	 Dậu 	    Hợi<br/>
                            </pre>
        <pre style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        JK Rowling	31/7/1965		<br/>
	    Giờ 	Ngày	Tháng    Năm<br/>
	    Bính	Bính	Quý 	   Ất<br/>
        Thân	Tuất	Mùi 	   Tỵ<br/>
                            </pre>
        <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trụ giờ có sao Văn Xương nên biểu thị tài năng viết truyện cho thiếu nhi.
                                        </p>
        
        <pre style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Albert Einstein 14/3/1879			<br/>
	    Giờ 	Ngày	Tháng    Năm<br/>
	    Giáp	Bính	Đinh	    Kỷ<br/>
        Ngọ 	Thân	Mão 	    Mão<br/>
                            </pre>
        <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ông có văn xương ở trụ ngày cũng là sao quý nhân nên ông có mối quan hệ tốt và hỗ trợ tốt từ những phụ nữ có học thức cao.
                                                </p>
        <pre style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mark Zuckerberg	14/5/1984<br/>
	    Giờ     Ngày    Tháng   Năm<br/>
	    .       Mậu      Kỷ     Giáp<br/>
	    .       Thân     Tỵ     Tý<br/>         	
        57  	47  	37      	27  	   17	    7<br/>
        Ất  	Giáp	Quý     	Nhâm	   Tân	   Canh<br/>
        Hợi 	Tuất	Dậu     	Thân   	Mùi	   Ngọ<br/>
                            </pre>
        <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ông ta là người thành lập Facebook và là thiên tài máy tính. Có 2 sao Văn Xương. Sao Văn Xương Thân biểu thị tài năng máy tính trong lập ra Facebook trong năm 2004 năm Thân, cũng là ngũ hành thực – trí thông minh của ông. Vào đại vận 17 – 27 thì ông có ngũ hành Tân Mùi. Tân kim là thông minh và Mùi là quý nhân. Đây là đại vận mà ông thành công với Facebook. Vào tuổi khoảng 27 thì ông bắt đầu 20 năm thành công với vận kim và thủy, đây là tài và trí thông minh. Vì vậy ông may mắn khi kiếm tiền trong 20 năm tới.
                                                </p>
        <pre style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Virgina Wolf 25/1/1882 – 28/3/1941	    <br/>
	    Giờ 	Ngày	Tháng    Năm<br/>
	    Canh	Giáp	  Tân	 Tân<br/>
        Ngọ 	Tý  	  Sửu	  Tỵ<br/>
                            </pre>
                            <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
                            Cô có 2 sao Văn Xương. Cô là ngày sinh mộc trong tháng mùa đông lạnh nên cần Hỏa như đầu ra và khao khát. Thời thành công nhất là từ 33 – 53, vận Hỏa. Khi 1941, 59 tuổi, vào vận Thổ thì cô tự sát bằng cách gieo mình xuống sông. Đây là trầm cảm do thiếu Hỏa.                                                </p>
  
        <img style={{ width: "100%" }} src={vanxuong} />
      </div>
    );
  };