export const GiapTy = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Giáp Tý thường rất lạc quan, đánh giá khả năng của bản
        thân quá cao, thường quá lo lắng không hoàn thành được việc gì nếu gặp
        trở ngại, trở nên yếu đuối và thất vọng. Vẻ ngoài của họ khiến người
        khác dễ nghĩ rằng họ là người tuân thủ pháp luật và bảo thủ. Khuôn mặt
        nổi bật. Có tư duy kinh doanh và giỏi giao tiếp. Nếu sinh vào giờ Tý, dễ
        sẩy thai hay có con sinh ra chết non. Nếu sinh vào giờ Hợi, dễ bị bệnh
        mắt.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Hình tượng của Giáp Tý là cây to cao khoẻ mạnh, xanh tươi mọc ở vùng đất
        ẩm ướt hoặc nhiều tuyết trắng. Biểu thị cho người đứng thẳng và có vị
        trí, tầm nhìn và sẵn lòng bảo vệ quan điểm của mình. Đứng đầu 60 Hoa
        Giáp nên Giáp Tý biểu thị cho người luôn muốn ở vị trí đầu tiên, đi tiên
        phong. Họ là người dễ dàng sở hữu nhiều điều mình mong muốn.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Giáp Mộc toạ Quý Thuỷ là Chính Ấn. Giáp là ngũ hành luôn vươn lên, người
        này có xu hướng theo đuổi sự phát triển. Họ luôn tìm cách để sống còn,
        vượt qua mọi hoàn cảnh khó khăn miễn là có đầy đủ tài nguyên và nguồn
        lực. Thuỷ là đại diện cho trí tuệ và Chính Ấn là đại diện cho kiến thức.
        Người này cực kỳ sáng suốt. Họ không bao giờ nản chí từ bỏ, luôn chờ đợi
        cơ hội để vươn lên và tiến bộ. Họ cũng là người cô đơn nếu làm việc
        trong môi trường tri thức, nơi những người xung quanh không thân thiện
        với nhau. Sự xa cách có thể khiến cho họ cảm thấy lạnh lẽo. Họ có xu
        hướng mạnh mẽ gắn bó với mẹ theo kiểu “đứa con ngoan của mẹ”. Họ yêu quý
        và trân trọng bạn bè cũng như gia đình và thường xuyên tiếp đón bạn bè
        đến thăm và trao đổi nói chuyện.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Tý là người nhân từ, thích giúp đỡ, thích nghệ thuật, biết
        cách xã giao, hướng ngoại trầm lặng, yêu tự do, sáng tạo và luôn nhiều
        cảm hứng.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Giáp Tý thường linh hoạt, có kiến thức sâu rộng, ngoại
        giao, nhiều năng lượng và thích mạo hiểm. Không có thách thức nào quá
        khó khăn để họ vượt qua, hay họ không dễ bị đánh bại trước mọi trở ngại
        nào. Khả năng thích ứng của họ có thể giúp họ vượt qua mọi tình huống và
        sự thông minh sáng tạo sẽ giúp họ thành công trên đường dài. Họ luôn có
        sự hứng khởi để khởi sự và kết thúc một dự án dài hạn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Là những người có tinh thần tự do, Giáp Tý nói chung thường rất chắc
        chắn về bản thân và biết rõ họ cần gì trong đời. Họ đam mê khám phá mọi
        thứ trong cuộc sống và muốn tận dụng tốt nhất mọi thứ mà họ gặp phải. Họ
        không thích sống tuân thủ theo các nguyên tắc sống mà xã hội áp đặt,
        thay vào đó họ thích làm những gì trái tim mách bảo. Họ thích có thật
        nhiều trải nghiệm sống đa dạng. Đó là lý do họ luôn cố gắng vươn ra
        ngoài hơn là ngồi lì một chỗ. Họ không thích người khác kiểm soát hay
        sai bảo họ làm gì mà họ không thích.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có niềm cảm hứng sáng tạo nghệ sĩ. Người Giáp Tý có xu hướng sống một
        lối sống nghệ thuật, yêu thích và trân trọng cái đẹp cũng như các môn
        văn hoá nghệ thuật. Thiên nhiên và cảnh đẹp xung quanh có tác động rất
        tích cực đến tính cách và thái độ sống của họ. Họ thích sống gần nơi có
        nhiều cây cối, thực vật và màu xanh thiên nhiên vì nó giúp đem đến sự
        bình an 3 trong tâm hồn và suy nghĩ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Tý không hẳn là hướng nội mà họ luôn thích thiết lập quan hệ
        bạn bè trong các nhóm nhỏ. Mặc dù có vẻ là người hướng ngoại, người Giáp
        Tý thường rất kín đáo và thích chủ động bảo vệ đời sống cá nhân của mình
        tránh sự quan tâm người khác. Mặc dù ăn nói mềm mỏng, họ có thể bộc lộ
        bản thân và có khả năng ăn nói thuyết phục cao. Sự nhiệt tình giúp đỡ
        người khác cũng giúp cho họ có khả năng giải quyết những khó khăn của
        người khác một cách dễ dàng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Giáp Tý thường dễ gây thiện cảm ở những người xung quanh
        như gia đình, bạn bè và người thân của họ. Họ đánh giá mối quan hệ cao
        và luôn được người khác tin tưởng để nhờ giúp đỡ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Về tiền bạc thì người sinh ngày Giáp Tý là những người giỏi quản lý tiền
        bạc và thích kiếm tiền và danh tiếng địa vị thông qua những cách hợp đạo
        lý xã hội. Họ thích những con đường chính đạo để đi đến thành công và sẽ
        luôn đi theo sự chân thành và phẩm giá cao.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù thể hiện hình ảnh bên ngoài là tự tin, họ thường dễ mất tự tin
        bên trong và ngầm so sánh mình với những người khác. Họ không bao giờ
        bộc lộ sự thiếu tự tin ra ngoài và chỉ những người rất thân mới phát
        hiện ra nỗi lo lắng thường trực này của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Tý thỉnh thoảng có thể bị cơn giận dữ, lo lắng hay mất kiên
        nhẫn điều khiển. Điều này đặc biệt đúng nếu họ quá chú tâm đến công
        việc. Nó khiến họ lãng quên những điều quan trọng khác trong cuộc sống
        của họ, điều mà sẽ gây sự mâu thuẫn với ham muốn thể hiện cá tính nghệ
        sĩ của họ. Họ nên biết giới hạn của bản thân để không vượt quá.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Tý dễ trở nên chán công việc. Sự buồn chán này sẽ khiến họ
        làm công việc nửa vời và mau chóng bỏ công việc dang dở để tìm đến một
        công việc khác. Điều này là do bởi sự bất mãn thường trực và bản tính
        luôn thích sống cho hiện tại của họ. Nếu họ có thể kiểm soát bản thân
        thì sẽ có một cuộc sống tuyệt vời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Tý thường đạt được một trạng thái cân bằng tốt trong cuộc
        sống. Họ sẵn lòng đứng lui lại trước những giông bão của cuộc đời và
        chấp nhận mọi thứ mà cuộc sống trao cho họ. Họ cũng dễ dàng thoả mãn với
        cuộc sống và công việc của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Tý cũng có tiêu chuẩn sống đạo đức cao. Họ thích sống chân
        thật và phẩm giá hơn là đạt được những giá trị thành công về vật chất và
        giàu có nhưng bằng những phương thức phi đạo đức. Họ không thích kiếm
        tiền bằng cách lươn lẹo, lừa lọc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi gặp căng thẳng, người Giáp Tý thường thích có người để họ dựa vào.
        Họ sẽ không bộc lộ sự yếu đuối này ra ngoài và chỉ có những người có mối
        5 quan hệ rất thân thiết mới có thể cảm nhận được nhu cầu này. Bản chất
        bên trong của người Giáp Tý là người sáng tạo và trân trọng cái đẹp. Họ
        thích tìm nguồn cảm hứng sáng tạo cái đẹp. Hoàn cảnh thiên nhiên cũng
        ảnh hưởng đến tính cách và thái độ của Giáp Tý. vì lý do này, họ thích ở
        gần nơi có nhiều mảng xanh vì nó giúp mang lại sự an bình và giúp tinh
        thần họ thư giãn.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Sự Nghiệp & Công Việc:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Tý thường rất tập trung và chăm chỉ theo đuổi công việc họ
        yêu thích. Họ có sự quan sát rất kỹ lưỡng và có thể tìm ra những cơ hội
        và giải pháp phục vụ cho công việc. Bởi vì họ có khả năng tìm ra được
        những giải pháp công việc từ rất sớm, họ giỏi trong việc giải quyết các
        vấn đề phải đối đầu và đưa ra các hành động đúng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ giỏi đa nhiệm, có khả năng đánh giá mọi khó khăn ngay lập tức và lời
        khuyên của họ luôn có độ sâu nhất định. Tất cả những lý do này khiến cho
        họ dễ nắm vị trí lãnh đạo hơn những người khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi họ ở trong một môi trường tự do, năng lực sáng tạo của họ thật sự
        được chắp cánh. Bởi vì họ không bị giới hạn bởi các quy định cứng nhắc.
        Họ cũng không phải là người thích mạo hiểm mà luôn muốn tuân thủ theo
        những kế hoạch đã có sẵn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Tý thường có khả năng nắm bắt bất cứ công việc nào vì họ đa
        tài. 6 Khả năng giao tiếp con người tốt khiến họ xuất sắc trong những
        ngành nghề như bán hàng, hay quan hệ công chúng. Kỹ năng nhân sự tốt
        khiến họ dễ trở thành luật sư, nhà tư vấn hay chuyên gia tâm lý giỏi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu họ muốn muốn biến những kỹ năng điều hành và đầu óc thực tế, họ nên
        cân nhắc sự nghiệp trong công việc kinh doanh, ngân hàng, bảo hiểm hay
        thị trường chứng khoán. Tuy nhiên, nếu họ thích tự kiểm soát vận mệnh
        của mình, họ nên tự khởi nghiệp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sự sắc sảo và tri thức có thể giúp họ thành công trong sự nghiệp viết
        lách, giảng dạy, sáng tác hay quản trị.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có thể bị thu hút đến các công việc đòi hỏi khiếu thẩm mỹ về màu sắc,
        đường nét và hình dáng. Mặt khác, họ cũng có thể chọn công việc trong
        showbiz như ca sĩ hay diễn viên.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Dù chọn bất cứ ngành nghề nào, họ cũng sẽ đóng góp thành công ở mức cao
        nhất.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những Đặc Tính Trong Công Việc Sự Nghiệp Của Giáp Tý: quan sát giỏi,
        lãnh đạo tài năng, đa nhiệm, tập trung cao độ, luôn có động lực tiến
        lên, chăm chỉ, kiên nhẫn, thích rủi ro, đôi khi không suy nghĩ hệ thống,
        ngoan cố
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Cảm - Yêu Đương - Gia Đình
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù là người lãng mạn, người Giáp Tý không phải là những người ăn nói
        ngọt ngào. Họ không thuộc tuýp người có những hành động ngọt ngào với
        người yêu như tặng hoa, thì thầm những lời mật ngọt. Người Giáp Tý thay
        vào đó, lựa chọn cách thể hiện tình cảm và sự quan tâm thông qua những
        hành động thực tế.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Tý thích có người bạn đời có năng lực và độc lập; tuy nhiên
        họ có xu hướng trở nên cực kỳ bảo bọc người bạn đời. Họ không làm gì gây
        ảnh hưởng đến xấu, làm hại đến người bạn đời và sẽ chiến đấu đến hơi sức
        cuối cùng để giúp cho người bạn đời tránh xa khỏi khó khăn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Rất khó để có thể cưỡng lại nét đẹp, tính cách và sự khôn ngoan của
        người Giáp Tý. Họ dễ dàng gây thiện cảm và sự chú ý đối với người khác
        giới. Trong tình yêu, người Giáp Tý muốn có người đồng cảm và có ai đó
        cùng chia sẻ niềm vui và nỗi buồn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Về gia đạo thì Giáp Tý là người đáng tin cậy, gia đình luôn là số 1 của
        họ. Họ gắn bó vững chắc với người bạn đời và họ muốn bảo bọc cho người
        kia. Do đó, họ thường có một cuộc sống hôn nhân kéo dài và viên mãn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những Đặc Tính Trong Hôn Nhân - Tình Yêu Của Người Giáp Tý: lãng mạn,
        tin cậy, cam kết, bảo vệ.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Giáp Tý bao gồm thiên can Giáp mộc bên trên, địa chi Tý
        thuỷ bên dưới. Hành thuỷ của Tý sinh cho mộc của Giáp được xem là phản
        ánh ý chí mạnh mẽ và tinh thần độc lập của người Giáp Tý. Nếu trong lá
        số bát tự của người Giáp Tý có thêm hành Hoả thì người này sẽ rất sáng
        tạo và có chí khởi nghiệp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Giáp Tý bị ảnh hưởng nhiều bởi mùa nào họ được sinh ra,
        sẽ dẫn đến thể hiện đặc điểm tính cách khác biệt nhau. Nếu sinh trong
        tháng mùa hè, họ sẽ mang tính cách hướng ngoại, sôi nổi và nếu sinh
        trong tháng mùa đông, họ sẽ có tính cách ít biểu lộ cảm xúc hơn. Tuy
        vậy, vẫn sẽ có 1 số nét tính cách chung của những người sinh ngày Giáp
        Tý. Họ thường cứng rắn và mạnh mẽ. Ít khi bị ảnh hưởng bởi cám dỗ bên
        ngoài, họ luôn có nguyên tắc vững chắc của mình, luôn chăm chỉ, kỷ luật,
        tỉ mỉ. Họ có 1 sự tự tin vững chắc, bền bỉ vào vị trí và khả năng lãnh
        đạo của họ. Họ không thích sự thay đổi và sự phát triển của họ tuy chậm,
        nhưng vững chắc và tiến đều từng bước như một cái cây.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Tý thường duyên dáng, nhanh nhạy và cuốn hút. Họ rất giỏi
        trong giao tiếp với con người và dễ dàng thích nghi trong các nhóm xã
        giao và tình huống mới rất nhanh. Họ dễ dàng kết bạn và rất gần gũi với
        gia đình là những người cho họ cảm giác tự tin và an toàn. Tuy nhiên, vì
        đa phần cảm giác an toàn của họ đến từ các mối quan hệ xung quanh, một
        khi họ mất đi các mối quan hệ này thì sẽ rất khó khăn cho bản thân họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Giáp Tý thường giàu năng lượng và có thể được xem như
        người có thái độ luôn sẵn sàng tiến lên do tính chất của Tý. Sự kết hợp
        giữa Giáp và Tý đem đến động lực và tầm nhìn hướng về phía trước khiến
        cho họ dễ đa nhiệm và quản lý được nhiều dự án.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu có nhiều Kim và Hoả trong lá số thì sẽ khiến cho người này dễ trở
        nên lo lắng, căng thẳng. Hành Mộc sẽ có nhiều tác động có lợi. Tý thường
        được xem là có động lực được thúc đẩy bởi lợi ích cá nhân của mình nhưng
        vì có hành Mộc ở ngày sinh có thể chuyển hoá thánh độ ích kỷ, khiến cho
        họ tỏ ra tử tế và quan tâm người khác hơn. Những người này mặc dù rất
        chú trọng đến thành công nhưng cũng tỏ ra hào phóng, thân thiện, sáng
        tạo và nhạy cảm và yêu thích nghệ thuật và thẩm mỹ. Họ cũng không thích
        tham gia vào những trò chơi chính trị quyền lực văn phòng nhưng vẫn có
        thể thành công hơn đồng nghiệp của mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Tý thường tiết kiệm và keo kiệt. Họ thường tiết kiệm nhiều để
        đảm bảo tương lai của mình. Họ có nhiều khoản tài sản và dễ dàng phát
        triển một ngân sách nhỏ trở nên lớn hơn. Những tính cách này hàm nghĩa
        rằng người này sẽ luôn có khả năng lo được cho gia đình của mình. Họ có
        biệt tài chuyển lỗ thành lợi nhuận.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Tý có xu hướng dễ bị bệnh về đường túi mật và gan. Để đảm bảo
        sức khoẻ tốt và khiến cho lục phủ ngũ tạng hoạt động tốt nhất, người này
        nên tránh rượu và tuân thủ chế độ ăn uống sinh hoạt điều độ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Tý gắn với hướng Bắc. Mùa vượng nhất của họ là mùa đông và
        tháng chính là tháng Tý. Nếu họ sinh vào mùa này hay tháng này, người
        Giáp Tý thường có cuộc sống tương đối khá.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Kỹ Thuật Chuyên Sâu:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tý hợp với Sửu bao gồm Tân (Chính Quan), Kỷ (Chính Tài) và Quý (Chính
        Ấn). Đối với cả nam và nữ, quan hệ với bạn đời thường tốt, nhưng cần
        phải điều hoà với mối quan hệ với người mẹ. Tiền bạc và quyền lực cũng
        sẽ đến với người này, họ có thể là giám đốc giỏi và kiếm thu nhập ổn
        định.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tý xung với Ngọ và xua đuổi Đinh (Thương Quan) và Kỷ (Chính Tài). Đối
        với nam giới thì tình cảm có chút xa cách. Nữ giới sẽ rất chung thuỷ với
        bạn đời. Sự nghiệp có chút bấp bênh bởi vì họ đặt giá trị của tri thức
        hơn giá trị đồng tiền.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhìn chung thì người Nam sinh ngày Giáp Tý sẽ dễ bị chậm tiến trong sự
        nghiệp vì không đặt nặng giá trị tiền bạc. Họ có thể mưu sinh kiếm sống
        vừa đủ nhưng không tiến bộ nhiều, là người tham vọng thấp và phù hợp với
        những người sếp thích nhân viên không nhiều tham vọng, khó có khả năng
        gây nguy hiểm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Lá số họ cần có Bính (Thực Thần) đại diện cho việc biến kiến thức thành
        một thứ gì vững chắc. Họ nên quan tâm chân thành và nên thể hiện ra rằng
        mình có trái tim ấm áp.
      </p>
    </div>
  );
};

export const GiapTuat = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Giáp Tuất thích coi trọng lễ nghi và quy tắc luật lệ,
        rất công bằng, rộng lượng, tốt bụng, xem trọng nguyên tắc nhưng thiếu độ
        linh hoạt, nghĩ mọi thứ đều là “trắng hay đen” mà không có màu xám,
        không dung thứ “màu xám”. Nếu người này có thể linh hoạt và thái độ
        khoan dung hơn, thì sẽ có quan hệ giao tiếp tốt hơn. Nhìn chung, người
        này có hôn nhân tốt, nếu có ít Thiên Tài thì hôn nhân sẽ tốt hơn. Quan
        hệ tình cảm và không hiếu thuận nhiều với cha mẹ. Hình T
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Giáp Tuất là hình tượng một cây to mọc trên một ngọn núi cao đầy đá. Nó
        biểu thị một người kiên cường, độc lập và có ý chí mạnh mẽ. Cũng có thể
        hình dung như một đám lửa trại nơi mọi người tụ tập và chia sẻ nói
        chuyện, kể những câu chuyện cuộc đời cho thế hệ sau. Nó là không gian
        cho sự sáng tạo, đoàn kết và hợp tác. Gíap Tuất là người có tính cách
        thích thúc đẩy các sự kiện như thế này.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Giáp được xem là cây to, vững chãi, không bị suy sụp là trụ chống. Giáp
        có khả năng vươn cao phát triển và thường là thông qua trở ngại, họ là
        người cung cấp giải pháp. Thông qua vẻ ngoài mềm mỏng, không bao giờ ép
        buộc một tình huống nào nhưng đủ cứng rắn để không lùi bước.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Giáp Tuất sở hữu tính cách năng động. Họ tham vọng và có
        hứng thú cũng như mạnh mẽ và thẳng thắn. Tất cả những tính cách của họ
        giúp cho họ đạt được những điều mà họ muốn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sự độc lập là một trong những phẩm chất mạnh nhất của họ. Sự độc lập của
        họ đem đến sự tự tin và động lực cần thiết để họ chấp nhận các thách
        thức mới và nỗ lực tự thân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Một trong những phẩm chất mạnh nhất của người Giáp Tuất là sự quyết tâm
        của họ. Nó kết hợp hoàn hảo với sự độc lập và giúp họ biến tầm nhìn
        thành hiện thực. Một khi họ có một mục tiêu trong tâm trí, sự quyết tâm
        của họ sẽ dẫn lối thành công. Điều này không chỉ giúp cho họ đạt mục
        tiêu mà cả tài lộc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bởi vì họ sáng tạo và có tinh thần tự do, họ luôn đi tìm kiếm ý tưởng
        mới cũng như các cách thức khám phá. Những nét tính cách sẽ giúp cho họ
        có thể tiếp thu các quan điểm hiếm và có thể thu hút sự quan tâm và thói
        quen của họ để có thể biến thành công thức thành công.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người sinh trong ngày sinh này sẽ rất quan tâm đến người khác. Bởi
        vì họ có sự quan tâm thật sự đối với người khác, họ sẽ cống hiến thời
        gian và nỗ lực vì chính nghĩa và các tổ chức từ thiện. Kỹ năng giao tiếp
        của họ đóng một vai trò quan trọng vì sẽ khiến cho những người khác đi
        theo họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Vì là người theo chủ nghĩa hoàn mỹ, họ thường tự hào về những công trình
        của mình. Họ giỏi trong việc phân tích thông tin và lên kế hoạch chiến
        lược, cũng là lý do vì sao khi họ thực hiện các kế hoạch, họ thường làm
        với kỹ năng và tốc độ phi thường.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi xét về vấn đề tiền bạc, người Giáp Tuất cần phải thận trọng. Không
        lo lắng về tài chính và sự nổi nóng có thể che mờ lý trí họ nếu họ muốn
        nhanh chóng đạt đến thành công. Chuỗi chi tiêu hoang phí có thể khiến
        cho người Giáp Tuất gặp khó khăn về tài chính. Tốt nhất là họ cần phải
        giữ thái độ bảo thủ đối với tiền bạc và chọn con đường an toàn để đảm
        bảo thành công về tài chính.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bởi vì họ ghét cảm giác thất bại, họ có xu hướng cam kết vào bất kỳ tình
        huống nào bất kể họ có gặp bất lợi hay không. Chính tính bướng bỉnh này
        gây bất lợi cho họ, do đó tốt nhất là họ nên bỏ đi cái tôi cua mình và
        học cách lắng nghe lời khuyên của người khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu trẻ tuổi, người Giáp Tuất có thể gặp khó khăn về tài chính. Vào tuổi
        trung niên, họ có thể vẫn cảm nhận khó khăn và thử thách trong việc tích
        luỹ tài lộc. Tuy nhiên, khi họ già đi, thì họ sẽ tận hưởng thịnh vượng
        và thành tích.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Những Nét Đặc Tính Của Gíap Tuất:</b> tưởng tượng, trực giác, rộng
        lượng, duyên dáng, tự tin, độc lập.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Tuất xã giao, thực dụng và tham vọng. Với sức mạnh ý chí mạnh
        mẽ, họ có thể tìm được cơ hội tốt trong sự nghiệp và nghề nghiệp. Làm
        việc vất vả và khởi nghiệp, họ có thể xác định ra được cơ hội dẫn họ đến
        thành công vượt bậc
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Vì kỹ năng thương lượng giỏi, họ có thể chốt những thoả thuận tốt. Điều
        này dẫn họ đến thành công trong kinh doanh, sản xuất, thương mại hay bất
        cứ gì liên quan đến thoả thuận với người khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những kỹ năng này có thể thúc đẩy họ trở thành doanh nhân hay nhà quản
        lý thành công. Nếu họ sử dụng kỹ năng thuyết phục tốt, họ sẽ trở thành
        nhà tư vấn, trọng tài hay môi giới.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Tuất cũng có khát khao kiến thức. Do đó nếu họ chọn nghề
        nghiệp trong lĩnh vực giảng dạy, giáo dục hay các lĩnh vực học thuật
        cũng hấp dẫn với họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặt khác, họ có thể bị cuốn hút vào thế giới giải trí nơi tài năng và sự
        tưởng tượng của họ được thăng hoa.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ cũng có xu hướng nhân đạo nên có thể chọn nghề nghiệp nào kêu gọi vì
        một chính nghĩa hay thay đổi xã hội.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Công Việc:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Có một danh sách dài những lựa chọn nghề nghiệp mà người Giáp Tuất có
        thể chọn phù hợp với tính cách của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những nghề nghiệp bao gồm du lịch và đi lại sẽ giúp họ thoả mãn tính
        thích mạo hiểm, trong khi xu hướng hành động khiến cho họ có thể lựa
        chọn các công việc như trong lĩnh vực thể thao.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu họ thích khai thác bản tính thích phân tích, họ nên cân nhắc trở
        thành nhà toán học. Mặt khác, nếu bản tính tự nhiên của họ là thống trị,
        họ nên làm việc trong lĩnh vực công.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặt khác, nếu họ chọn sử dụng kỹ năng sáng tạo hình ảnh, họ có thể trở
        thành nhiếp ảnh gia, thiết kế đồ hoạ, nhạc sĩ hoặc diễn viên.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Những Đặc Tính Trong Công Việc Của Giáp Tuất:</b> thích xã giao, thực
        dụng, thích mạo hiểm, siêng năng, lý tưởng, thiếu quyết đoán, hấp tấp.
      </p>

      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Quan Hệ - Gia Đình:{" "}
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù họ quen trở thành người ra quyết định trong mọi mặt của cuộc đời
        họ, mọi việc khác đi xét về tình yêu. Thay vì trở nên áp đặt, họ có xu
        hướng tham khảo ý kiến và ý tưởng của vợ hay chồng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Gíap Tuất có tình yêu sâu sắc dành cho bạn đời. Họ sẵn lòng làm
        bất cứ điều gì cần thiết để biểu đạt sự tận tâm và ngưỡng mộ của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Không có gì quan trọng với họ hơn hạnh phúc, đó cũng là lý do vì sao họ
        muốn giữ sự bình an trong quan hệ. Một người bạn đời may mắn khi lấy
        được Giáp Tuất vì họ là người chú tâm và quan tâm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Vì họ luôn đòi hỏi nhiều độc lập, họ luôn chọn bạn đời không ngại cho họ
        sự tự do.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Tuất luôn thích gây sự chú ý, làm vui lòng người khác và có
        thời gian vui vẻ. Họ duyên dáng và thân thiện cũng như giúp họ thu hút
        nhiều bạn bè. Bởi vì họ luôn tôn trọng tình bạn, họ sẵn lòng làm bất kể
        thứ gì cần thiết để đảm bảo sự hợp tác và bình an.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Tuất luôn thích gây sự chú ý, làm vui lòng người khác và có
        thời gian vui vẻ. Họ duyên dáng và thân thiện cũng như giúp họ thu hút
        nhiều bạn bè. Bởi vì họ luôn tôn trọng tình bạn, họ sẵn lòng làm bất kể
        thứ gì cần thiết để đảm bảo sự hợp tác và bình an.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ thích giữ mối quan hệ với nhiều người khác nhau. Giao thiệp với đủ
        mọi loại người giúp đem lại cơ hội để họ học hỏi và mở rộng nhận thức.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Cảm giác của họ có xu hướng trồi sụt lên xuống. Vì lẽ này mà họ có xu
        hướng bất an hay nghi ngờ trước khi có thể cam kết gắn bó tình bạn lâu
        dài với ai. Người Giáp Tuất cần phải trưởng thành hơn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Tuất yêu sự tự do, và họ có thể có tâm trạng đi xuống nếu họ
        cảm thấy mình bị trói buộc. Họ không phải thuộc tuýp người quanh quẩn
        trong nhà và luôn cần giải toả bản thân để làm hài lòng mọi người. Điều
        này gây ra sự bất hoà khi họ muốn ổn định đời sống gia đình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Tuất yêu sự tự do, và họ có thể có tâm trạng đi xuống nếu họ
        cảm thấy mình bị trói buộc. Họ không phải thuộc tuýp người quanh quẩn
        trong nhà và luôn cần giải toả bản thân để làm hài lòng mọi người. Điều
        này gây ra sự bất hoà khi họ muốn ổn định đời sống gia đình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Không phải là không thể giữ đời sống gia đạo an bình. Họ cần phải tìm
        được sự cân bằng và chú trọng đến thái độ trưởng thành khi họ quyết định
        thành gia lập thất.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Phụ nữ Giáp Tuất sẽ đem lại may mắn cho gia đình thêm thịnh vượng. Ngoài
        ra, họ được khuyên là không nên làm việc với chồng, vì có thể làm suy
        giảm vận may của chồng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đàn ông Giáp Tuất sẽ thích vợ có tinh thần chiến đấu nhưng điều này có
        thể khiến cho họ bị kiểm soát hay bạo hành trong mối quan hệ tình cảm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đàn ông Giáp Tuất sẽ thích vợ có tinh thần chiến đấu nhưng điều này có
        thể khiến cho họ bị kiểm soát hay bạo hành trong mối quan hệ tình cảm.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Giáp Tuất toạ trên Thiên Tài, Chính Quan và Thương Quan. Người Giáp Tuất
        rất tử tế, dung thứ và nhân từ. Họ thân thiện và thấu hiểu cũng như
        thích làm việc nhóm cũng như có xu hướng tự nhiên thích giúp đỡ người
        khác. Họ chân thật và thẳng thắn trong đối nhân xử thế và họ sẽ hiểu sâu
        sắc nhu cầu của người khác. Họ cũng thường có đầu óc cởi mở và là người
        suy nghĩ lý tưởng ma những cảm xúc cực đoan cũng có nghĩa là họ phải
        chiến đấu phải các thái cực cảm xúc cũng như họ phải rất vất vả để tìm
        được bình an và sự cân bằng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Tuất vốn nhạy cảm và cũng có nghĩa là họ rất dễ bị ảnh hưởng
        bởi những người xung quanh họ và họ có thể bị nản lòng bởi những lời xúc
        phạm. Họ cần phải nỗ lực để tìm ra sự tự tin và để có thể tự đứng vững
        trên hai chân của mình. Nếu họ có thể tự tin, họ sẽ có nhu cầu thể hiện
        bản thân và có thể nói thẳng thắn, đến mức có thể thể tự tạo kẻ thù cho
        mình. Sự hiện diện của 6Hoả Khố và thông căn trong lá số và sự liên quan
        đến Thiên Tài hay Chính Quan có thể là dấu hiệu của một người chính
        nghĩa nhưng cũng cần phải cẩn trọng trong việc thể hiện ý kiến của mình
        vì họ có thể gặp nguy hiểm do gây hấn với tập thể.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Một khi họ đã tìm được sự độc lập, người Giáp Tuất có ý chí mạnh mẽ và
        thậm chí cả hơi cứng đầu. Điều này bởi vì Thổ cứng của Tuất. Bản chất
        cảm xúc của họ nghĩa là họ trân trọng sự ổn định và họ có nhu cầu cần an
        toàn đồng nghĩa với việc họ sẽ làm việc vất vả để tạo nền móng vững chắc
        cho gia đình. Họ luôn cố gắng để dành cho tương lai và có thể lo lắng về
        tình hình tài chính. Họ rất sáng tạo và có xu hướng thực dụng với khả
        năng lý luận tốt và khiếu kinh doanh giúp họ có thể rất thành công. Họ
        có cảm giác quyền lực và thích cảm nhận rằng mình gây được nhiều ảnh
        hưởng. Điều này bởi vì bản chất của tàng Thương Quan.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ cũng là những tâm hồn tự do không thích bị ràng buộc sự tự do. Họ
        thích thử thách bản thân và thử thách giới hạn của họ, họ thích được tự
        do đi lại. Họ sẽ rất có lợi nếu có thể gặp gỡ hày học hỏi từ những người
        có văn hoá và nền tảng khác nhau. Họ ôn hoà và có đầu óc cởi mở và luôn
        sẵn lòng nhắm đến tương lai rộng lớn. Đôi khi họ sẽ dễ có những giấc mơ
        siêu thực và cần phải tránh để bản thân rơi vào sự trốn chạy và mất
        trọng tâm. Họ cần phải học cách giữ kiên nhẫn và ổn định nếu họ muốn kết
        nối tài năng và đạt được điều gì có ý nghĩa trong đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thổ của Giáp Tuất là đất dày, nhiều đá. Giáp là cây to mọc trên đỉnh núi
        được đại diện bởi Tuất. Do đó, Giáp cần sự hỗ trợ từ hành Thổ trong lá
        số để sống sót và phát triển. Điều này cũng có nghĩa là bất lợi nếu thấy
        Tuất Xung và Hình trong lá số. Quan trọng cần xác định Quan Tinh và Thất
        Sát có ảnh hưởng đến sự ổn định của Mộc không.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Tuất thích có thể tận hưởng thịnh vượng và sức khoẻ tốt nếu
        có hỗ trợ từ Địa Chi Ngày vì biểu thị sự nuôi dưỡng từ môi trường xung
        quanh. Điều này có nghĩa là sự ổn định của Địa Chi là quan trọng nhất.
        Cũng như cây mọc trên đỉnh núi đá, nếu không có sự ổn định, cây sẽ sụp
        đổ và ngã. Do đó nếu trụ này gặp Hình, Xung và Phá thì chất lượng của
        trụ Giáp Tuất sẽ bị suy giảm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi Giáp Tuất xấut hiện trong trụ Năm, có thể là dấu hiệu người này sẽ
        khá nhút nhát, họ dễ bị trầm cảm. Để chắc chắn rằng Tuất là ngũ hành có
        lợi trong lá số, biểu thị người phải có được sự hỗ trợ từ ngũ hành có
        lợi của Mão, Thuỷ và Dần trong lá số hay Đại Vận. Nếu có nhiều Canh và
        Ngọ trong Đại Vận thì sẽ gặp tác động tiêu cực hơn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Giáp toạ Tuất cũng là Thiên Tài Mậu là chính khí. Họ nhìn thấy khả năng
        của mọi con người và sự vật. Họ nghĩ xa hơn ngày mai và thường quan tâm
        đến tương lai. Họ giỏi suy nghĩ chiến lược và đi trước người khác. Mậu
        Thổ giúp Mộc đứng thẳng trên đỉnh núi, cũng như một cây thông già, là
        biểu tượng của sự hi vọng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Được kẹp giữa phụ khí của Chính Quan Tân và Thương Quan Đinh khiến cho
        họ nổi loạn và hoang dã. Không bao giờ ngại ngần thể hiện quan điểm và
        họ dễ toả ra ánh sáng soi rõ bóng tối cũng như điều này khiến cho sự ảnh
        hưởng của họ khá rõ ràng. Bời vì họ tập trung sự chú ý vào bản thân
        mình, họ luôn là một mẫu hình gương mẫu đúng theo tính cách chính trực
        của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tuất hấp dẫn Mão trong Lục Hợp thu hút Ất Kiếp TÀi, biểu thị xấu cho mối
        quan hệ với nam giới. Là người có sức hút, không ngạc nhiên là họ khá
        nổi tiếng. Nữ giới ở thế bất lợi vì có cả Chính Quan và Thương Quan
        chiếm cùng một trụ, với sự xuất hiện của Ất, cạnh tranh thật sự.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tuất xung với Thìn mang theo Thiên Tài Mậu, Chính Ấn Quý và Kiếp Tài Ất.
        Nam giới ngày sinh Giáp Tuất sẽ thể hiện sự ganh đua cũng như muốn chứng
        tỏ quyền lực.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhìn chung, ngày sinh Gíap Tuất là gỗ tiện dụng. Nó được chạm khắc cẩn
        thận và tạo dáng thành vật có giá trị bởi kim loại tinh tế là Tân. Ngoài
        ra còn được thắp sáng bởi Đinh. Điều này tạo ra sự ấm áp và định hướng
        cho người khác trong bóng tối để có thể thấy được màn đêm. Sự hi sinh
        của họ vì tương lai, mục đích của họ là cho thế hệ sau.
      </p>
    </div>
  );
};

export const GiapThan = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Giáp Thân có tính tình bất thường, ngẫu hứng, thường
        quên giá trị bản thân. Nếu trong hoàn cảnh thuận lợi, thì có khả năng
        tiến bộ và phát triển. Nếu gặp trở ngại, sẽ bị bế tắc và từ bỏ. Thiếu
        động lực và kiên trì để hoàn thành nỗ lực. Có vẻ ngoài thân thiện và dễ
        tiếp cận, nếu được khiêu khích sẽ trở nên nổi loạn và bạo lực. Nếu có
        Thất Sát lộ can, người này xinh đẹp hay có vẻ ngoài ưa nhìn. Dễ bị dính
        líu rắc rối tình cảm. Nhìn chung, không thiếu thốn vật chất, nhưng cũng
        không quá giàu có.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Một số người bị đâm trong đời, sau đó bỏ cuộc và lăn lóc. Khi người Giáp
        Thân quyết định vượt lên trên, họ luôn chấp nhận sự đau thương, tuy
        nhiên đó cũng sẽ là những lần họ phản đòn lại.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Giáp Mộc là hành kỳ lạ. Nó dường như không có khả năng từ chối bất kỳ cơ
        hội nào đối với họ cũng là cơ hội để học hỏi. Đây là hành của sự phát
        triển liên tục. Mộc thích bảo vệ tất cả cũng như cách cây cổ thụ hành
        xử, chúng cung cấp cho đời oxy, thực ăn và thậm chí hi sinh bản thân để
        làm nguyên liệu đốt cháy. Họ làm tất cả những gì vì người khác mà không
        biết rằng thậm chí về sau người khác sẽ vô ơn với họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Hình ảnh của Giáp Thân là cây to lớn trong mùa Thu khi toàn bộ lá có màu
        vàng rực rỡ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thích vui vẻ, tình cảm và thân thiện là những đặc tính của người sinh
        ngày Giáp Thân. Họ thích giao tiếp và duyên dáng, cũng như dễ hợp tác
        trong mọi việc. Họ cũng thích làm cho người khác vui vẻ và nói chuyện dễ
        mến, khiến những người xung quanh thích ở cạnh họ. Với một nụ cười và
        tính cách cuốn hút, họ là người tích cực và dễ thành công.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Giao tiếp là một trong những thế mạnh của họ. Phong cách giao tiếp của
        họ là hoạt ngôn, hài hước và bộc lộ tình cảm. Bởi vì họ không gặp khó
        khăn trong việc để được thấu hiểu, họ có thể đạt được điều mình muốn và
        xoay chuyển tình huống theo hướng có lợi cho mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngoài những kỹ năng giao tiếp tốt, người Giáp Thân còn là những lãnh đạo
        tuyệt vời. Khi là nhà lãnh đạo, họ có khả năng đưa ra những ý tưởng tiên
        phong và biến chúng thành hiện thực. Tạo ra những kế hoạch lớn và nhìn
        thấy nhiều cơ hội sẽ giúp cho họ thuyết phục người khác đặt niềm tin.
        Khi họ tạo ra những kế hoạch lớn, họ sử dụng cả kỹ năng lãnh đạo và giao
        tiếp để có thể gợi cảm hứng cho người khác tham gia.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Với tư cách là chiến lược gia, họ nhìn thách thức như là cơ hội thú vị
        để giải quyết khó khăn. Đón nhận thử thách và học hỏi điều mới sẽ giúp
        kích thích các gíac quan và làm sắc bén kỹ năng của họ. Ngay cả trong
        những trường hợp khi ra quyết định khó khăn, họ cũng có thể tiến hành
        các giải pháp đúng đắn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi xét về sức khoẻ và thể chất, thì người Giáp Thân không gặp nhiều may
        mắn lắm. Họ thường dễ lên cân và có thể có nhiều sự phát triển cơ thể
        bất thường bên trong. Điều này bởi vì Thân chứa Thất Sát và nó làm trốc
        rễ của Giáp Mộc khiến cho nó trở nên bất ổn và mong manh. Trong những
        trường hợp cực đoan, họ có thể cảm thấy ít nhất một lần tai nạn trong
        đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ sẽ đối mặt nhiều thách thức và trục trặc khi còn trẻ. Không may, họ
        sẽ tiếp tục đối mặt với những thách thức đó cho đến độ tuổi trung niên.
        Cho đến về già và cuối đời thì họ mới có thể đạt được thịnh vượng và tài
        lộc vững chắc. Người Giáp Thân khi già hơn thường có nhiều quyền lực và
        sự tôn trọng, kể cả từ đối thủ của mình. Người Giáp Thân thường được
        biết đến như là người thông thái trong lĩnh vực của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù họ có thành công lớn trong đời, họ vẫn thiếu cảm giác đầy đủ về
        tinh thần. Họ tỏ ra vẻ ngoài vui vẻ hoạt bát trong khi bên trong thật sự
        đầy ghen tức và sợ hãi. Cuối cùng, cảm giác trống rỗng bên trong sẽ dẫn
        đến trầm cảm và thất vọng. Để có thể chống lại điều này, họ nên cân nhắc
        dành thời gian cho những lý tưởng lớn lao. Thông qua điều này, họ sẽ tìm
        được sự hạnh phúc thật sự.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Những Đặc Tính Của Giáp Thân:</b> hoà hợp, yêu thích vui vẻ, thực
        dụng, tự tin, thông thái, có tầm nhìn, tinh thần khởi nghiệp.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đối với người Giáp Thân, công việc khá quan trọng. Họ có thể làm tốt
        nhiều nghề nghiệp kể cả kinh doanh, quan hệ công chúng, bán hàng, tiếp
        thị và truyền thông. Họ có khả năng đặc biệt để hoàn tất mọi nhiệm vụ
        chỉ trong một nửa thời gian, và trí thông minh giúp họ nắm bắt được các
        khái niệm kinh doanh một cách dễ dàng. Ngoài tất cả những việc này, ký
        ức cùng với nỗ lực quyết tâm của họ giúp họ làm chủ mọi vấn đề kinh
        doanh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bởi vì Thân là sao Dịch Mã, sẽ là tốt nhất cho người Giáp Thân để tham
        gia vào kinh doanh hay thương mại đòi hỏi đi lại nhiều. Những công việc
        văn phòng sẽ không phù hợp với Giáp Thân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù là người giữ tiền giỏi, rất khó cho họ để tiết kiệm do những tìm
        kiếm liên tục về trải nghiệm mới, vốn không hề miễn phí.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Giỏi kỹ năng giao tiếp xã hội giúp người Giáp Thân có vô số cơ hội nghề
        nghiệp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Một nghề nghiệp lý tưởng cho họ là kết nối thông tin. Điều này làm cho
        họ thích hợp nhất với sự nghiệp phóng viên. Họ cũng có thể sử dụng những
        kỹ năng này trong lĩnh vực giảng dạy.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Giáp Thân có tinh thần doanh nhân. Một cách tự nhiên, họ thích theo đuổi
        việc kinh doanh của riêng mình và trở thành những doanh nhân xuất sắc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có thể dễ dàng trở thành bác sĩ hay luật sư xuất sắc. Ngoài ra, sự
        nghiệp trong ngành IT và ngôn ngữ cũng là sự lựa chọn tốt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Điều có thể gây ngạc nhiên cho nhiều người là người Giáp Thân có giọng
        ca tốt. Họ cũng có thể thành công trong lĩnh vực âm nhạc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những Đặc Tính Trong Sự Nghiệp - Công Việc: kỹ năng giao tiếp xã hội
        tốt, trí nhớ tốt, trực giác, thông minh, không ngừng nghỉ, lãng phí.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Hôn Nhân - Quan Hệ - Gia Đình:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Thân tin rằng cuộc đời nên là sự cân bằng giữa công việc và
        tình cảm. Họ muốn tìm một người bạn đời lý tưởng cũng như là người có
        mức độ thành công trong cuộc sống giống như họ. Nếu không, người Giáp
        Thân sẽ dễ buồn chán trong tình cảm. Người bạn đời của Giáp Thân cần
        phải khiến cho họ vận động về thể chất và tinh thần.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người khác nên lưu ý rằng người Giáp Thân có xu hướng thích thao
        túng, toan tính trong khi chinh phục tình yêu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mối quan hệ tình cảm lãng mạn của họ thường bắt đầu từ tình bạn trước
        tiên trước khi mọi thứ hoa bắt đầu toả hương. Không ngạc nhiên vì sao
        Giáp Thân có xu hướng kết hôn với người bạn thân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trong 60 Hoa Giáp thì Giáp Thân được xem là trăng hoa nhất. Họ thích là
        tâm điểm của sự chú ý và thích tán tỉnh. Họ quen với việc có được điều
        mình muốn và sẽ sử dụng toàn bộ khả năng của mình để chiếm giữ và tỏ ra
        hài hước trong một đám đông. Bời vì những điều này, người bạn đời của
        Giáp Thân nên thường lo ngại họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Thân có khả năng có nhiều người ngưỡng mộ và bạn bè rộng. Hô
        không thành kiến trong tình bạn và dễ kết nối tốt với tất cả mọi người
        như nhau. Tuy nhiên, họ là những người có tham vọng lớn và có mục tiêu
        trong đời. Họ thích giúp đỡ những người có cùng đầu óc tư duy như họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi người Giáp Thân quyết định thành gia lập thất, thường cuộc hôn nhân
        sẽ kéo dài và có thể là mãi mãi. Trong hôn nhân, người Giáp Thân thường
        là người tình đam mê và nhục cảm và chỉ mất vài giây để họ biểu lộ sự
        tình cảm và đùa giỡn với bạn đời. Họ thường tìm kiếm những người có đầu
        óc cởi mở và tư tưởng thoáng để làm bạn đời. Cũng vậy, người bạn đời của
        họ không nên quá nhạy cảm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Thân thường thích đời sống gia đình hoà hợp. Họ thích có con
        muộn. Người nữ Giáp Thân hi sinh nhiều hơn cho gia đình và nếu cô ấy có
        Canh kim trong lá số thì mối quan hệ với chồng cũng sẽ tốt hơn. Người
        đàn ông Giáp Thân lại không tốt trong hôn nhân, nhưng điều này không ảnh
        hưởng đến khả năng tích luỹ tài lộc của anh ta.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Trong Tình Yêu - Tình Cảm:</b> dâm đãng, suy nghĩ
        thoáng, hấp dẫn, nhục cảm, kích thích, khôn ngoan, thao túng.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Thân năng động và không biết e ngại. Họ yêu sự thử thách và
        thích có cơ hội để kiểm tra tài năng của mình. Họ tham vọng, kiên trì và
        nhiều sức sống. Họ cũng sáng tạo và có nhiều kỹ năng thực dụng để biến
        kế hoạch lớn thành hiện thực. Điều này bởi vì Giáp Thân toạ trên Thất
        Sát, Thiên Tài và Thiên Ấn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Thân cũng là những chiến lược gia thật sự với tài kinh doanh.
        Họ cũng có thể sử dụng kỹ năng để giúp đỡ gia đình và bạn bè tăng cường
        may mắn trong sự nghiệp. Tuy nhiên vì trụ này toạ Tuyệt Địa, nên trong
        Bát Tự thì biểu thị người này dù có rất nhiều tài năng nhưng dễ gặp khó
        khăn lớn thời trẻ và đối mặt nhiều thách thức mà không có quý nhân giúp
        đỡ. Thông thường, họ sẽ may mắn hơn vào độ tuổi trung niên về sau.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Thân có thể bị tàn tật nếu ngày xung của họ xung với Thất Sát
        Tinh cũng biểu thị rằng họ vẫn làm việc vất vả hơn để giành được thành
        công và sự công nhận. Điều này chỉ đúng khi có Dần trong lá số. Điều này
        là có thể tuy nhiên nếu có sự xuất hiện của hành có lợi là Hoả và Thổ,
        và những tình huống đặc biệt có thể khiến tạo hoá hợp thì tình hình u ám
        hơn. Dù sao thì người Giáp Thân cũng có thể trở nên rất giàu có và thành
        công.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người của ngày sinh này có thể tỏ ra hơi xa cách. Họ cần dành thời gian
        để ở một mình và cách ly bản thân khỏi những người khác để có thể tự
        đánh gía và xây dựng nội tâm của mình. Họ thích dành thời gian để suy
        nghĩ, là lúc mà họ có thể suy tư và tìm ra giải pháp và ý tưởng. Điều
        này có thể giúp họ tự tìm được sự bình an trong tâm hồn. Người Giáp Thân
        rất duyên dáng và lãng mạn và họ có thể cưa đổ người họ yêu hoàn toàn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Lá số Giáp Thân sẽ hưởng lợi lớn từ sự xuất hiện của hành Thuỷ. Cây gỗ
        phụ thuộc vào dòng nước để tồn tại, nếu có nước tích cực thì sẽ giúp cho
        người này nở hoa và đạt được khả năng tài lộc và may mắn. Tuy nhiên,
        hành Mộc sẽ bị suy yếu nếu gặp quá nhiều Thuỷ và Hoả (cả nóng và lạnh)
        trong lá số cùng một lúc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngoài ra, đây cũng là dấu hiệu tích cực nếu trụ ngày gặp thêm hành Kim,
        Thuỷ và Mộc. Nếu có Hoả và Thổ trong đại vận thì biểu thị họ sẽ làm việc
        vất vả hơn nhiều để đạt thành công.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi cục Giáp Thân xuất hiện ở trụ giờ thì biểu thị người này có tài năng
        ẩn giấu và họ có thể mất thời gian để tìm ra và học cách sử dụng những
        tài năng này. Nếu cục này xuất hiện ở trụ tháng th2i là dấu hiệu họ có
        thể học được bài học từ trường đời khắc nghiệt thông qua nhiều nỗ lực,
        sửa sai, vấp ngã.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Gíap Thân sinh vào mùa Xuân và Hè sẽ thành công trong việc tự khởi
        nghiệp. Họ sẽ có xu hướng hiểu những thuật ngữ kinh doanh và biết cách
        sử dụng kiến thức tốt nhất. Người sinh vào mùa Thu và Đông có thể đối
        mặt khó khăn khi còn trẻ, họ khó có thể nhận được sự giúp đỡ từ những
        người xung quanh và họ có thể không đạt thành công cho tới khi trung
        niên hoặc già.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu sinh vào ban ngày thì họ sẽ được kính nể vì tài năng và kiến thức
        cũng như địa vị xã hội và được thừa nhận là người sáng tạo. Người sinh
        ban đêm sẽ có nhiều tài lộc và may mắn. Họ thích vươn lên và có sự
        nghiệp trong ngành tài chính.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Thân sinh vào tháng Tuất sẽ đối mặt nhiều thử thách và trở
        ngại trong đời. Họ sẽ cảm thấy khó khăn để đạt mục tiêu và phải làm việc
        rất vất vả mới có thể thành công.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Giáp toạ trên Thân mang theo 3 khí. Canh Kim Thất Sát là chính khí kẹp
        bởi Mậu Thổ Thiên Tài và Nhâm Thuỷ Thiên Ấn. Điều này là một phần của
        mối quan hệ đặc biệt giữa Tài-Quan-Ấn tạo nên chuỗi tương sinh liên tục
        giúp ích cho can ngày. Thất Sát đem đến cho Giáp sự thận trọng và mạnh
        mẽ chiến đấu, họ là những người sẵn sàng chấp nhận rủi ro. Điều này cũng
        đồng nghĩa là bản chất của người Giáp chấp nhận bị bóc lột, họ sẵn lòng
        chấp nhận công việc ngay cả khi rõ ràng là họ đang ở phía bất lợi, nhiều
        người sẽ nhìn nhận nọ như một quý ông thật sự. Thiên Tài là sao chủ về
        tương lai cho họ hy vọng rằng họ có thể thử những nhiệm vụ rủi ro. Họ
        thường nhìn xa hơn tình trạng hiện tại và thay vào đó có thể tập trung
        vào kết cục tương lai của hành động. Đầu óc họ quan tâm đến những gì tốt
        đẹp họ có thể tạo ra trong tương lai thì dù có phải hi sinh hiện tại một
        chút cũng không sao. Thiên Ấn đem đến sự tái sinh, sinh lực giúp họ tiến
        lên phía trước. Họ nhìn vào bức tranh toàn cảnh và có thể thích nghi
        linh hoạt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tình cảm cho cả nam hay nữ giới thì đều không tốt vì cuộc sống họ chịu
        nhiều rủi ro và hi sinh để hướng đến lý tưởng mà không phải ai cũng hiểu
        được. Là người bạn đời của họ thì phải vật lộn với sự bất ổn và sự thật
        rằng họ hi sinh nhiều hơn mức cần thiết cho những người xa lạ không xứng
        đáng. Mặt ngược lại, cuộc sống của họ lúc nào cũng đầy hứng khởi, ngạc
        nhiên và nhiều mộng mơ...có thể không thành hiện thực.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thân hợp với Tỵ đem đến Thực Thần là chính khí với Thiên Tài Mậu Thổ và
        Thất Sát Canh Kim. Điều này tốt hơn cho nữ giới vì Thực Thần giúp kiểm
        soát tính dữ dội của Thất Sát. Nữ giới Giáp Thân có thể nghĩ vì gia đình
        và bản thân. Nam giới thì hơi sốc nổi vì Bính Hoả sinh cho Mậu Thổ và
        làm cho Thiên Tài khó đạt hơn. Điều này báo hiệu người nam sẽ không chịu
        rời bỏ mặc dù nửa kia đã quyết định ra đi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thân xung với Dần xua đuổi Tỷ Kiên Giáp Mộc là chính khí và Thiên Tài
        Mậu Thổ cũng như Thực Thần Bính Hoả là phụ khí. Điều này thật sự gây rối
        rắm vì ban đầu hấp dẫn bởi Thiên Tài và Thực Thần và giờ Thực Thần và
        Thiên Tài bị xua đuổi. Điều này ảnh hưởng đến nam giới nhiều hơn bởi vì
        Thiên Tài là sao chỉ bạn gái. Ngoài ra người Giáp thì thích ở một mình,
        ngay cả bạn bè họ cũng không cần mấy. Nếu bạn không thể là người yêu,
        bạn cũng sẽ không thể là bạn bè...họ sẽ bỏ qua bạn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tổng quan, trụ Giáp Tâhn mang đến thông điệp tiếp tục ra đi, bỏ đi. Họ
        có thể rất cứng rắn và lì vì muốn tốt cho bản thân nhưng bạn cần biết
        rằng họ thường không nhìn thấy lỗi của mình. Họ nhìn thấy điều tốt đẹp
        trong tương lai hành động của họ và thấy lợi ích trong tương lai dù gặp
        khó khăn hiện tại. Nếu bạn có kế hoạch lâu dài trong đầu, hãy mời gọi họ
        tham gia vào cùng đội nhóm. Khả năng kế hoạch này sẽ kéo dài và cùng với
        họ, bạn có thể thấy được ánh sáng ở cuối đường hầm. Trong khi những
        người khác có thể đã nản chí gục ngã thì họ vẫn sẽ tiếp tục đồng hành
        cùng bạn hướng đến tương lai.
      </p>
    </div>
  );
};

export const GiapNgo = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Giáp Ngọ: người này nghiêm túc và cứng rắn, chủ quan,
        tin rằng nếu cố gắng vất vả sẽ đạt thành công, đôi khi khó giải quyết
        vấn đề, đối với hôn nhân tình cảm luôn nghiêm túc, không dễ yêu, nhưng
        khi yêu thì rất chung thuỷ và quan tâm bạn đời. Có sức khoẻ tốt. Nếu
        sinh trong tháng Dần, Ngọ hay Tuất thì sẽ giàu có. Nếu là nữ giới sinh
        trong năm Giáp Ngọ thì hôn nhân sẽ không hạnh phúc.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Giáp Ngọ có thể hình dung như một cây thông Giáng Sinh được thắp sáng
        bởi đèn Đinh. Hoặc có thể hình dung một cây to được đốt cháy thắng sáng
        (có thể bởi sấm sét). Giáp Ngọ thường biểu thị cho ăn mừng vui vẻ, có
        thể là sự nhân từ của Giáp Mộc đem lại động lực trong khi ánh sáng giáo
        dục Đinh Hoả dẫn lối chỉ hướng.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Câu chuyện của Giáp Ngọ khá buồn. Giáp là hành của sự nhân từ biểu thị
        sự quan tâm bảo vệ và hỗ trợ. Mục đích sống của họ xoay quanh sự cho đi.
        Cây không bao giờ từ chối bất ai đến nhờ chia sẻ bóng râm. Chúng cung
        cấp trái ngọt khi bạn đói. Họ thuộc dạng người mà sẽ thậm chí không than
        phiền ngay cả khi bị bóc lột.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Một cây to vững chắc với mặt trời toả sáng bên trên cành lá xanh tươi là
        hình ảnh của Giáp Ngọ. Nó biểu thị sức mạnh bên trong vững chắc của
        người Giáp Ngọ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Ngọ có đầu óc cởi mở và có quan điểm độc đáo về thế giới. Họ
        có quan điểm vững chắc và cá tính cao. Họ can đảm và có sự quả cảm giúp
        họ thành công. Sự duyên dáng và hấp dẫn của họ là khó có thể bỏ qua và
        đầu óc của họ tràn ngập các ý tưởng sáng tạo và mới mẻ. Người sinh ngày
        này có thể thể hiện tính cách đôi. Một mặt, họ có thể từ bi và bác ái,
        trong khi mặt khác có thể thẳng thắn và áp đặt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thành công dễ dàng đối với người Giáp Ngọ vì họ có khả năng phát hiện và
        nắm bắt các cơ hội tài chính. Họ có góc nhìn thực tế về cuộc đời và muốn
        lên kế hoạch lớn. Người Giáp Ngọ có tư duy tiến bộ cao, sáng tạo và đổi
        mới. Họ cũng có thể có nhiều ý tưởng và sáng kiến đi trước thời đại. Là
        người tư duy h65 thống, họ có thể tập hợp thông tin và kiến thức từ vô
        số nguồn và truyền đạt lại nó với một cách thức độc đáo và thú vị. Họ
        cũng có khả năng viết lách và ăn nói rất tốt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có sự tự tôn mạnh mẽ và phẩm giá cũng như tự hào về bản thân. Họ dễ
        bước đi trên con đường tâm linh và dần dần dồn toàn bộ sự chú tâm của
        mình vào thế giới tâm linh. Ngoài ra, họ yêu hoà bình và hạnh phúc cũng
        như yêu thích những phút giây nhỏ bé.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu nỗ lực, người Giáp Ngọ có thể trở thành doanh nhân xuất sắc và kinh
        doanh hiệu quả. Họ có khả năng điều hành mạnh mẽ và thành công rất quan
        trọng với họ. Nếu họ có được dự án hay chủ đề phù hợp, họ có thể đổ tâm
        huyết và tham vọng vào những gì cuốn hút họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ là người giao tiếp giỏi với kỹ năng ngoại giao tốt và khả năng kết
        nối con người. Điều này khiến cho họ trở thành những tài sản có giá trị
        khi xét đến làm việc nhóm hay cộng tác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù khéo léo trong giao tiếp, sự hiểu lầm có thể nổi lên nếu họ không
        quan tâm đến việc lắng nghe người khác. Điều này sẽ gây ra quan hệ căng
        thẳng. Họ có xu hướng rơi vào rắc rối nếu họ cứng đầu, quái đản hay quá
        hưởng thụ. Đôi khi họ cũng khá nổi loạn, kiêu căng và áp đặt. Tất cả
        những điều này có thể dẫn đến sự xa rời mục tiêu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Ngọ, đôi khi có thể có dị tật trên cơ thể hoặc phát triển
        không bình thường. Họ có thể có đầu lớn quá cỡ, vết chàm hay một đặc
        điểm kỳ quặc nào đó trên khuôn mặt. Phụ nữ Giáp Ngọ có dấu hiệu lão hoá
        sớm hơn những người phụ nữ khác và cách ăn mặc của họ hơi lỗi thời. Bởi
        vì điều này, nhiều người không cảm thấy phụ nữ Giáp Ngọ hấp dẫn. Do đó,
        người Giáp Ngọ thường bi quan.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Của Người Giáp Ngọ:</b> tình cảm, kỳ quặc, sáng tạo,
        hi sinh bản thân, dễ quên, tâm linh, độc lập, dám, giao tiếp rộng, rộng
        lượng, quá hưởng thụ.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Ngọ có thể dễ dàng chọn được sự nghiệp cho họ vì khả năng
        kinh doanh, động lực và kỹ năng giao tiếp. Họ có thể chọn sự nghiệp nào
        mà họ có sự tự do nhiều, cũng là lý do mà họ sẽ có thành ôcng lớn trong
        vị trí doanh nhân hay giám đốc cấp cao.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ sở hữu khả năng lãnh đạo và ăn nói rất thuyết phục. Họ cũng tham vọng
        và quyết tâm. Vì lý do này, họ thường xuyên thấy nhiều cơ hội tuyệt vời.
        Óc thực tế cùng với kỹ năng tổ chức tốt, cho họ nền tảng vững chắc để
        đạt thành tích.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Công Việc:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tự do là rất quan trọng với người Giáp Ngọ và họ phải được phép làm việc
        theo cách của riêng họ. Người Giáp Ngọ là những người giao tiếp xuất
        sắc, họ giỏi trong sự nghiệp kinh doanh, tiếp thị, nghệ thuật và truyền
        thông. Họ xuất sắc trong việc thương lượng các thương vụ và thương mại
        hoá tài năng của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu họ muốn, họ cũng có thể tham gia vào các công việc trong nghề giải
        trí như diễn viên, đạo diễn và nhà văn. Mặt khác, nếu họ ghét nhận mệnh
        lệnh, họ có thể cân nhắc các vị trí điều hành.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Trong Công Việc - Sự Nghiệp:</b> thân thiện, thực
        dụng, tham vọng, quyết tâm, giao tiếp tốt, thuyết phục cao, quá thẳng
        thắn, khó nhận mệnh lệnh.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Quan Hệ - Công Việc - Gia Đình:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Một khi người Giáp Ngọ tìm được bạn đời, họ sẽ bảo vệ người này bằng mọi
        giá. Họ rất tự hào về tình cảm và sẽ có thể dời non lấp biển để bảo vệ
        tình cảm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngoài yêu đương, địa vị và an ninh tài chính là rất quan trọng trong
        tình cảm của người Giáp Ngọ. May mắn thay, phụ nữ Giáp Ngọ đem đến thịnh
        vượng cho chồng của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mối quan hệ tình cảm cá nhân của họ thường phải cân bằng giữa hai thái
        cực: lý tưởng và thực tế. Là người theo chủ nghĩa lãng mạn, họ thích yêu
        đương và sống hạnh phúc mãi mãi. Tuy nhiên, là người thực hành, họ biết
        rõ những sự không hoàn hảo và sẽ bảo vệ tình cảm của mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngoài ra, họ có nhu cầu lớn được độc lập, đó là lý do vì sao tự do rất
        quan trọng đối với họ. Vì lẽ này, người bạn đời sẽ cảm thấy thoải mái
        với sự vắng mặt thường xuyên của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Ngọ thích tạo niềm vui cho người khác. Họ thích tham gia gặp
        gỡ người mới và thường có đời sống xã hội năng động. Họ có bản tính thực
        dụng thúc đẩy họ muốn liên kết với những ai có tham vọng và chăm chỉ.
        Nếu họ ưu tiên cho ai đó, việc đối xử của họ sẽ chủ về rộng lượng, đó
        cũng là lý do vì sao họ được xem là người bạn quý giá. Họ có khiếu thẩm
        mỹ và trân trọng những người có ngoại hình và phẩm chất tốt. Đó là lý do
        vì sao sự thành công, uy tín và tiền bạc là quan trọng đối với quan hệ
        tình cảm của những người này.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngườ Giáp Ngọ có xu hướng thích bảo vệ và tự hào về gia đình. Họ sẽ
        không ngại bất cứ gì để bảo vệ lợi ích của gia đình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có nhu cầu cao đối với độc lập và không có quà tặng nào tốt hơn cho
        họ bằng trao cho họ sự tự do mà họ khao khát.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Phụ nữ ngày Giáp Ngọ sẽ đem đến thịnh vượng cho chồng. Họ thường tiết
        kiệm và có năng lực tự thân chăm sóc gia đình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Trong Tình Yêu - Quan Hệ:</b> lý tưởng, lãng mạn, tự
        cao, ý thức an ninh, quyết tâm, độc lập.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Ngọ rất tham vọng và họ ham muốn đạt đến thành tích cao trong
        đời. Họ có khả năng phán đoán giỏi, có hệ thống giá trị cao và khiếu
        kinh doanh. Điều này bởi vì trụ này toạ trên sự kết hợp giữa Thương Quan
        và Chính Tài. Điều này khiến cho họ rất thành công trong sự nghiệp. Nhu
        cầu sâu thẳm về muốn ổn định có thể sẽ khiến họ làm việc vất vả vì đảm
        bảo an ninh tài chính và đầu tư trong nỗ lực để bảo vệ tương lai. Tuy
        nhiên vì Thương Quan cũng biểu thị sự vội vã, họ cần phải tránh ra những
        quyết định tồi trong lúc dầu sôi lửa bỏng. Người Giáp Ngọ toạ Tử Địa
        trong vòng Trường Sinh. Điều này không có ý ám chỉ rằng chết chóc nhưng
        là dấu hiệu cho thấy nhiều vấn đề bất ổn về tâm lý. Nếu Ngọ hình thành
        Tự Hình thì biểu thị người này không thể ra những quyết định đầu tư tốt
        nhất.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Ngọ có suy nghĩ sáng tạo. Điều này đặc biệt đúng khi Đinh Hoả
        xuất hiện trên can của lá số Bát Tự. Người này sẽ trở nên khá lãng mạn
        và có ham muốn sâu xa thể hiện bản thân đa cảm. Mặc dù họ rất độc lập và
        dựa vào bản thân vì bản tính căn bản của Giáp Mộc, họ cũng thích tiếp
        nhận, thân thiện và xã giao với sự xuất hiện của Thương Quan ẩn. Họ cũng
        quan tâm và lịch thiệp với người khác xung quanh họ cũng như quan tâm
        người mình thương yêu. Khi Can sinh cho Chi trong lá số Bát Tự thì nó
        biểu thị họ sẽ là người bạn đời tử tế và yêu thương. Nếu Đinh Hoả xuất
        hiện trong lá số bị suy yếu, hại, hình hay hợp mất thì người này sẽ ưu
        tiên người khác, nhưng điều này cũng sẽ dẫn đến họ tự gây hại cho mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có xu hướng mất kiên nhẫn hay không bao dung. Điều này đặc biệt đúng
        khi hành Hoả xuất hiện nhiều trong lá số. Họ cũng đôi khi căng thẳng vì
        phải tiếp nhận quá nhiều công việc. Khi nỗ lực để theo kịp tiến độ, họ
        có thể thất bại trong nhiều kế hoạch sắp tới. Hậu quả cảm xúc có thể
        khiến họ kiệt sức và bị ảo tưởng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Cũng sẽ có lợi nếu thấy xuất hiện dụng thần Thìn và Hợi trong lá số,
        biêu thị người này sẽ có đạo đức, có trật tự, bình tĩnh và tử tế và họ
        có thể là nhà vô địch chiến đấu vì chính nghĩa ở đời. Kim và Thuỷ có thể
        là dấu hiệu tích cực vì người này sẽ có sự nghiệp thành công và có nhiều
        tài sản. Ít may mắ nhơn nếu thấy nhiều Mộc, Hoả và Thổ trong lá số biểu
        thị người này sẽ đối mặt nhiều thách thức trong sự nghiệp và họ sẽ không
        có nhiều sự ủng hộ từ những người xung quanh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Ngọ thường được khuyến khích để làm việc trong những lĩnh vực
        liên quan đến Thuỷ đặc biệt khi có Hợi hiện diện trong lá số. Cũng có
        lợi cho họ nếu tìm nhà ở hoặc cơ hội ở gần nơi có nước.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người có Hợi trong trụ năm hình thành nên hình ảnh của Càn Quái. Đây là
        cục biểu thị vận xui liên quan đến hôn nhân và tình cảm. Tuy nhiên, nó
        biểu thị cuộc sống vật chất và sự nghiệp thành công. Nếu cách cục này
        xuất hiện trong lá số Giáp Ngọ, người này sẽ lấy người lớn tuổi. Đây là
        phương pháp truyền thống, biểu thị người này sẽ được xem là Càn Quái.
        Trong Bát Tự truyền thống, người Giáp Ngọ thường không có duyên tốt với
        trẻ con sinh năm Hợi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu Giáp Ngọ xuất hiện bên cạnh Dần Tỷ Kiên, họ sẽ có tài năng. Họ có
        khả năng kết nối và hấp dẫn những người đúng đắn và có năng lực. Nếu
        Giáp Ngọ xuất hiện ở trụ Tháng hay Năm, họ có thể biểu th5i cha mẹ trông
        già hơn tuổi. Nếu trụ này xuất hiện ở trụ Giờ, con cái sẽ kỳ quặc hay
        không vâng lời. Nếu Địa Chi Hợi xuất hiện trong lá số, điều này sẽ có
        hiệu quả và sẽ gia tăng cơ hội nghề nghiệp và thành công tài chính.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Ngọ sinh vào ban ngày có cách cục lá số Bát Tự tốt thì có thể
        theo đuổi mục tiêu với động lực mạnh mẽ, vượt qua khó khăn và đạt được
        thành công vượt trội. Người sinh vào ban đêm sẽ có bản năng và cách nhìn
        nhận cơ hội tài chính nhưng cũng cần phải làm việc thêm vất vả để có thể
        thành công. Người sinh vào tháng Sửu thì có thể gặp nhiều thách thức. Họ
        phải làm việc vất vả hơn người khác để có thể thành công.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Giáp toạ trên Ngọ mang theo năng lượng của Đinh Thương Quan và Kỷ Chính
        Tài. Họ có sứ mệnh để thách thức hiện trạng, năng lượng của Thương Quan
        thúc đẩy họ chỉ ra những lỗ hổng trong hệ thống, họ giỏi phát ngôn và
        biểu lộ. Đến mức họ sẵn lòng chịu thiệt hại chỉ để cho bạn thấy rằng họ
        đúng ra sao.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Năng lượng của Chính Tài cho họ tinh thần thực dụng vững chắc, họ sẽ sẵn
        lòng để dành thời gian và nỗ lực để biến mọi thứ thành hiện thực.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngọ hợp với Mùi thu hút Chính Tài Kỷ, Thương Quan Đinh và Kiếp Tài Ất.
        Đối với nam giới, quan hệ tình cảm rối rắm vì thường xuyên so sánh bạn
        đời. Nữ giới thì không quá quan tâm vào tình cảm nghiêm túc. Sự kết hợp
        giựa Chính Tài, Thương Quan và Kiếp Tài khiến cho họ khá ganh đua về
        tiền bạc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngọ khắc Tý xua đuổi đi năng lượng Chính Ấn Quý duy nhất. Họ từ chối sự
        giúp đỡ và có xu hướng tự làm mọi việc theo cách duy nhất họ muốn, họ
        không ngại từ chối thẳng thừng người khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhìn chung, người Giáp Ngọ thuộc tuýp người phát triển mạnh mẽ và cuối
        cùng cố gắng chiến đấu để thay đổi. Họ dựng nên tất cả để mọi người xung
        quanh thấy rằng thay đổi là cần thiết và sẵn lòng biến thành mồi xúc
        tác. Giáp Mộc sinh ra Đinh Hoả và tạo ra Kỷ Thổ. Mộc và Hoả cháy sáng
        rực rỡ, học vấn của họ thường siêu việt nhưng thường rơi vào trạng trái
        huỷ diệt nếu không được kiểm soát tốt.
      </p>
    </div>
  );
};

export const GiapThin = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Giáp Thìn: có tính cách kỳ quặc nhưng tốt tính, khó hoà
        hợp với người khác, thiếu gu hài hước, nhanh trí nhưng dễ đi đường tắt.
        Nếu được giao cho quản lý việc kinh doanh dòng họ thì dễ đi xuống, tệ
        hơn nhưng nếu có Thiên Đức hay Nguyệt Đức thì sẽ hoá giải được. Nếu có
        Quan và Tài thì sẽ giúp người này vượng hơn. Nếu là nam giới thì có
        duyên với vợ công dung ngôn hạnh. Nếu là nữ giới, thì dễ tái giá.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đứng ở trên cao và quan sát từ xa cũng như quyết định lúc nào để hành
        động. Cũng như những vị thần rừng thường quan sát và dõi theo người ta.
        Họ chiếm hữu nhiều tài sản và thường giữ bí mật tránh sự quan sát của
        công chúng. Người Giáp Thìn có tiềm năng được giấu kín. Giáp còn có
        nghĩa là áo giáp, tựa như mai rủa và Thìn là rừng rậm biểu thị sự che
        giấu.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Giáp là mộc tự cao và sang quý. Họ đứng thẳng và vững chắc trên mặt đất
        và dịch chuyển không phải là sự lựa chọn của họ. Họ thường khiêm tốn và
        im lặng để quan sát địa hình xung quanh. Họ thích cho đi vì cây cối
        không có khả năng từ chối những người đang tìm kiếm sự giúp đỡ. Họ cung
        cấp bóng râm, trái cây, không khí và có thể hi sinh để trở thành củi
        đốt. Hành này khá dễ để lợi dụng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Giáp Thìn được đại diện bởi hình ảnh của một cây xanh cao to, có thân
        rắn chắc trong khi vỏ lại mềm và ẩm ướt, vươn cao tráng lệ bên một vùng
        đồi núi đẹp đẽ.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Thìn thường tự tin, bình tĩnh và hạnh phúc. Người sinh ngày
        Giáp Thìn thường thể hiện ra mặt tự cao và đáng tin cậy, cũng như biểu
        thị họ có thể đảm nhận những nhiệm vụ gần như bất khả thi và đạt được
        những kết quả đáng nể. Sự quyết tâm của họ có thể khiến họ theo đuổi chủ
        nghĩa hoàn mỹ và muốn hoàn tất mọi việc mà họ nhận làm bằng bất kỳ giá
        nào.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Vốn là người sáng tạo, họ thường tràn đầy trí tưởng tượng cao và sáng
        kiến mới. Sự sáng tạo này khiến cho họ rất cởi mở và tràn đầy những khao
        khát tự do. Tinh thần tự do của họ khiến họ khao khát nhìn thấy thế
        giới, và muốn được trải nghiệm.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Cùng với việc tỏ ra sáng tạo và tưởng tượng, họ cũng là người có trực
        giác bén nhạy. Họ không chú ý nhiều đến những khó khăn và luôn đi theo
        cảm nhận trực giác của mình. Điều này là tuyệt vời bởi vì giác quan của
        họ luôn hoạt động tốt.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Thìn đi qua trong cuộc đời với mục tiêu duy nhất là đạt thành
        công. Giáp Thìn cũng giống như cây to lớn.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ phát triển hết mức trong đời. Họ thường có động lực và có khả năng xử
        lý mọi tình huống và bất kỳ thử thách nào trong đời, đó cũng là lý do mà
        những người xung quanh thường có sự tôn trọng sâu sắc dành cho sự can
        đảm của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Thìn thích các hoạt động nhân đạo và họ thường sẵn lòng đi
        giúp đỡ những người đang khó khăn. Họ không thể chỉ đứng và nhìn người
        khác chịu đựng sự bất công và xui xẻo. Họ sẽ đứng lên để đảm bảo những
        người xung quanh nhận được những gì cần thiết.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Không ngạc nhiên nếu nhìn thấy người Giáp Thìn được bạn bè, gia đình và
        xã hội ngưỡng mộ. Bản tính giao thiệp khéo léo giúp họ có thể hiểu được
        rắc rối của những người khác torng lúc tìm ra những giải pháp thực tế
        cho vấn đề. Chính nét tính cách này giúp cho họ có thể gia tăng sự nổi
        tiếng và giúp họ thành công trong cả sự nghiệp và về mặt cá nhân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Thìn có nguyên tắc đạo đức và các chân gía trị mạnh mẽ. Họ
        không dựa vào các phương pháp đường tắt để đạt được những việc cần làm,
        và tin tưởng rằng bằng cách cố gắng hết sức, họ sẽ nhận lại được điều
        tốt nhất. Bởi vì điều này, họ sẽ hưởng được thành công trong bất kỳ sự
        nghiệp nào mà họ lựa chọn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bất kể bao nhiêu lần họ thất bại, người Giáp Thìn sẽ nhắm đến thành công
        không mệt mỏi. Trong những lần thất bại, người Giáp Thìn có thể trở nên
        hướng nội và ám ảnh bản thân về việc họ đã làm sai điều gì. Dành quá
        nhiều thời gian để phân tích và làm việc vất vả để đạt được một điều gì
        có thể khiến cho họ cạn kiệt sức khoẻ lẫn tinh thần.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Thìn thường rất miễn cưỡng trong việc nhận sự giúp đỡ từ
        người khác. Cái tôi của họ ngăn cản họ và sẽ đi ngược lại lợi ích của
        người Giáp Thìn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Những Đặc Tính Của Người Giáp Thìn:</b> quyền lực, tự tin, cẩn trọng,
        may mắn, nhân đạo, đạo đức, hướng đến thành công.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Thìn sáng tạo và có khả năng suy nghĩ vượt mọi giới hạn. Trí
        tưởng tượng của họ giúp họ có thể thấy được những ý tưởng mới và giải
        pháp trong những lĩnh vực mà người khác không thấy được. Không chỉ là họ
        có thể đưa ra những ý tưởng to lớn và có thể cố gắng hết sức để biến nó
        thành sự thật. Đây là tài sản to lớn giúp cho những ai làm việc với họ,
        và là mối đe doạ đối với những đối thủ của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Thìn được biết đến là có thể biến mọi ý tưởng và thay đổi đột
        phá trong lĩnh vực công việc của họ. Điều này là lý do mà họ sẽ tốt hơn
        nếu đứng đầu một tổ chức hơn là nhân viên. Khi ở vị trí sếp, họ sẽ có
        được tự do sáng tạo để thực hiện những ý tưởng đột phá tuỳ theo họ muốn.
        Ngay cả khi là sếp, người Giáp Thìn không áp đặt. Ngược lại, họ nói
        nhiều và khéo léo trong ăn nói.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi nắm vị trí quyền lực, họ nên tập hợp xung quanh họ những người đáng
        tin cậy và giàu kinh nghiệm. Những người này sẽ cho Giáp Thìn lời khuyên
        và đảm bảo họ biết những bước cần thiết để tiến hành những vấn đề quan
        trọng.{" "}
      </p>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi xét vấn đề kinh doanh, tính cách cuốn hút của họ sẽ giúp ích. Thật
        khó để nói Không đối với lời đề nghị kinh doanh của họ và rất ít khi
        việc kinh doanh của họ thất bại.{" "}
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Công Việc:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Thìn có rất nhiều sự lựa chọn trong sự nghiệp. Họ có thể
        thích nghi trong mọi công việc, chỉ với một điều kiện, họ phải ở sân
        khấu trung tâm. Những công việc lặp đi lặp lại sẽ chèn ép người Giáp
        Thìn và do đó họ cần tránh nhận những công việc lặp đi lặp lại. Sẽ tốt
        hơn nếu họ chấp nhận những công việc sáng tạo và linh hoạt giúp họ có
        thể tiến lên trong sự nghiệp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Là người có xu hướng sáng tạo, những người này quan tâm đến việc theo
        đuổi một sự nghiệp có thể hiện bản thân như âm nhạc, biểu diễn nghệ
        thuật.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Thìn có hứng thú về kinh doanh có thể dùng kỹ năng của họ rất
        tốt trong quan hệ công chúng, tư vấn, quảng cáo và quản lý.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những Đặc Tính Trong Sự Nghiệp - Công Việc: cố tầm nhìn, sáng tạo, có
        động lực, là thành viên của đội nhóm, thuyết phục, hấp tấp, quá khéo
        léo.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Quan Hệ - Gia Đình - Hôn Nhân:{" "}
      </h4>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Thìn không tin vào “tình yêu sét đánh”, mà ngược lại họ tin
        rằng chân tình sẽ đến từ mối quan hệ phát triển từ từ. Rất ít khả năng
        họ sẽ thành gia lập thất khi còn trẻ bởi vì họ thích coi trọng độc lập
        bản thân. Khi theo đuổi người bạn đời lý tưởng, họ ngần ngại trước việc
        gia tăng mức độ nghiêm túc tình cảm. Họ thường cảm thấy người yêu hiện
        tại không phải là sự lựa chọn tốt nhất và người phù hợp nhất với mình
        vẫn còn đâu đó ngoài kia. Bởi vì điều này, người Giáp Thìn ngần ngại
        trước việc cam kết lâu dài trong một mối quan hệ. Bởi vì điều này, tình
        cảm của họ có thể rối loạn và họ sẽ bị tai tiếng là kẻ phụ tình. Ngay cả
        với tiếng tăm không mấy tốt đẹp đó, người Giáp Thìn vẫn là người hấp dẫn
        khó cưỡng với người khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Thìn rất can đảm, rộng lượng và quyến rũ. Họ toả ra một nét
        duyên dáng khó cưỡng và đó là lý do vì sao cả nam và nữ giới sinh ngày
        Giáp Thìn đều dễ dàng thu hút người khác phái. Họ có vô số các thính,
        nhưng không có cái nào thật sự quá nghiêm túc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nữ giới Giáp Thìn thường xinh đẹp và toả ra vẻ gợi cảm. Một số người sẽ
        tranh nhau để được nữ Giáp Thìn chú ý đến, nhưng cô ấy sẽ không dễ say
        trước những biểu hiện tình cảm bình thường. Những người này dành nhiều
        nỗ lực để tìm bạn đời nào là tốt nhất cho họ. Một khi đã tìm được, họ sẽ
        bày tỏ mãnh liệt tình yêu và sự giúp đỡ. Họ không xem tình yêu là hiểu
        nhiên và ỷ lại, do đó hôn nhân của họ thường kéo dài mãi mãi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù bản tính điềm tĩnh, người Giáp Thìn có thể khó kìm chế cơn thịnh
        nộ một khi bị khiêu khích. Họ có thể khá khó để sống chung và thái độ
        thô lỗ của họ có thể gây tổn thương bạn đời. Một người bạn đời thật sự
        của người Giáp Thìn cần phải can đảm và có quan điểm vững chắc. Cần sự
        can đảm và kiên trì để đối phó với người Giáp Thìn tâm tính thất thường.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Những Đặc Tính Trong Tình Yêu - Quan Hệ:</b> hấp dẫn, lãng mạn, gợi
        cảm, đáng tin cậy, chung thuỷ, quan tâm, đôi khi thô lỗ
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:
      </h4>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Thìn là người hướng ngoại thích ở gần nhiều người. Trụ Giáp
        Thìn khao khát sự ấm áp từ Bính Hoả (Thực Thần). Họ có tính cách duyên
        dáng và cuốn hút với đầu óc sáng tạo và cá tính độc đáo, họ cũng rất
        sáng tạo và thích có nhiều trải nghiệm và học hỏi những điều mới mẻ. Họ
        có lý lẽ vững chắc và có thể rất thẳng thắn nhưng trong tim thì họ có
        đam mê học hỏi từ người khác trong tâm trạng thấu hiểu lẫn nhau và hợp
        tác. Họ trưc giác, nhạy cảm và linh hoạt đủ để giao thiệp với nhiều nhóm
        xã hội khác nhau. Họ sử dụng trực giác của mình trong những quan hệ giao
        tiếp xã hội và thường đánh giá chính xác tính cách người khác. Tất cả
        điều này bởi vì họ toạ trên Thuỷ Khố. Thuỷ vốn là Chính Ấn của Giáp Mộc.
        Họ cũng có năng lực ngoại giao và tài năng giải quyết mâu thuẫn, đem
        niềm vui đến cho một tập thể. Họ thường là lãnh đạo giỏi. Họ có thể đôi
        khi rất tự cao, hay thậm chí ngạo mạn nhưng cũng rất rộng rãi và thậm
        chí hơi hoang phí.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Thìn thường được những người xung quanh yêu mến vì sự lạc
        quan của họ. Họ nhiệt tình, năng động và có động lực bên trong. Thường
        thích tự tạo ra bản sắc của mình, họ thường làm việc nỗ lực để chứng tỏ
        giá trị của các sáng kiến của mình, họ không bao giờ trì hoãn và sẽ tiến
        lên với tinh thần tiên phong để đạt được các mục tiêu tham vọng. Người
        Giáp Thìn rất có trách nhiệm và sẽ học hỏi từ sai lầm của mình, xem nhẹ
        trắc trở để tiến lên với kế hoạch của mình. Họ có nhu cầu chinh phục và
        có tính độc lập cao, rất cạnh tranh và bản thân tự tin. Họ không thích
        sự can thiệp và cũng không chấp nhận chỉ trích.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Thìn rất có khí chất và sống có lý tưởng. Đôi khi họ có tham
        vọng cá nhân cũng như tranh đấu vì công lý và điều tốt đẹp. Họ thường
        tranh đấu vì chính nghĩa. Điều này bởi Thìn có ám hợp với Dậu. Điều này
        cũng có nghĩa là Giáp Thìn âm thầm giới thiệu Quan Tinh ra. Họ có thể
        rất chu đáo và có tầm nhìn đa dạng và đôi khi khá đãng trí nếu đầu óc
        quá bận rộn. Họ thường khao khát kiến thức. Họ thích mở rộng kiến thức
        với triết học, tâm linh và huyền học, cũng luôn tìm cách để trưởng thành
        và phát triển bản thân. Họ có thể đối mặt vất vả khi còn trẻ nhưng sẽ
        dùng những trải nghiệm này để tiếp tục tiến tới và trở nên thành công và
        thịnh vượng hơn khi lớn tuổi hơn.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Thìn rất ủng hộ người khác và họ thường bị lôi cuốn bởi những
        người cũng năng động và quyến rũ như họ. Họ có thể có thiên hướng bày tỏ
        ý kiến của mình trước khi bày tỏ cảm xúc. Điều này bởi vì ảnh hưởng của
        Ất Mộc Kiếp Tài Tinh trong trụ. Mặc dù họ rất tình cảm nhưng có vẻ như
        chỉ một ít người thấu hiểu được mặt này trong tính cách của họ và họ sẽ
        rút ra khỏi một mối quan hệ nếu họ thấy không phù hợp hay không thực tế.
        Điều này đôi khi có thể khiến họ tổn thương.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tốt cho người Giáp Thìn nếu căn của trụ này mạnh mẽ. Chất lượng của Giáp
        Mộc phụ thuộc phần lớn vào điều kiện của căn gốc. Nếu hệ thống gốc rễ đủ
        mạnh và ủng hộ, chất lượng của Giáp Mộc được tăng cường đáng kể.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi Giáp Thìn gặp Canh Kim và Bính Hoả, nó thường là dấu hiệu rằng người
        này sẽ tìm kiếm danh tiếng và may mắn. Nếu không có sự hỗ trợ từ những
        hành này, người này sẽ không thể đạt được mức độ phi thường về danh
        tiếng và may mắn. Cũng lý tưởng nếu người Giáp Thìn gặp địa chi Thuỷ hơn
        là Kiếp Tài Tinh. Điều này bởi vì Thuỷ tăng cường sức mạnh cho gốc rễ
        trong khi Kiếp Tài Ất Mộc sẽ cạnh tranh để hấp thu Ấn từ trụ Giáp Thìn.
        Xung và Hình với Địa Chi là bất lợi nhiều vì sẽ làm mất đi sự ổn định
        của trụ này.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trụ Giáp Thìn sẽ trở nên có lợi khi được hỗ trợ bởi Đinh, Bính, Mậu, Dần
        và Ngọ. Họ cũng sẽ cảm thấy tích cực nếu trải qua đại vận có sự hiện
        diện của dụng thần Thổ, Hoả và Mộc. Người Giáp Thìn cần phải tránh nhiều
        Hợi, Sửu và Thân hiện diện trong Đại Vận.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu Giáp Thìn xuất hiện trong trụ Năm, thì thường là dấu hiệu cho thấy
        người này đến từ gia đình rất có học thức. Đây cũng là dấu hiệu tốt nếu
        người Giáp Thìn gặp Tỵ trong lá số Bát Tự hoặc họ sinh trong tháng Tỵ vì
        họ sẽ gặp nhiều may mắn trong đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh vào mùa Xuân và Hè sẽ có sự nghiệp xuất sắc, họ tiến bộ với
        ít thử thách. Người sinh vào tháng mùa Thu và Đông sẽ có danh tiếng tốt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu họ sinh vào ban ngày thì sẽ có nhiều nỗi buồn vì họ không tìm được
        lối thoát cho cảm xúc của mình. Tuy nhiên nếu sinh vào ban đêm thì sẽ
        khá hạnh phúc và vô lo. Sẽ ít may mắn cho người Giáp Thìn nếu sinh trong
        tháng Ngọ vì biểu thị gặp nhiều thách thức và thất vọng khi còn trẻ. Họ
        có thể phải dựa vào sự ủng hộ của người khác để có thể chinh phục mục
        tiêu của mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Giáp ở đây toạ trên Thìn mang theo khí chính của Mậu Thổ Thiên Tài và
        phụ khí Ất Mộc Kiếp Tài và Quý Thuỷ Chính Ấn. Thiên Tài cung cấp khả
        năng để phát hiện cơ hội tương lai và vì là cây cao nên họ có thể nhìn
        thấy tiềm năng của bạn từ rất xa. Kết hợp với khả năng tự nhiên của Kiếp
        Tài sẽ có khả năng dẫn dắt, sử dụng người, vũ khí chính để tấn công của
        họ là nhận ra được đám mây nguy hiểm từ phía xa và tìm cách phái người
        đến xử lý vấn đề.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ấn giúp cho họ khả năng để tập hợp cơ sở dữ liệu lớn để tập hợp những
        quý nhân giúp đỡ mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thìn hợp với Dậu mang đến hành Tân Kim Chính Quan. Đây là hành của người
        chồng. Nữ Giáp Thìn hợp với hôn nhân vì có đầy đủ tính chất của một
        người vợ lý tưởng. Họ giữ một bí mật cho riêng họ, chỉ hành động thông
        qua người khác và không bao giờ đối đầu tấn công người chồng trực tiếp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thìn xung với Tuất mang theo năng lượng của Mậu Thổ Thiên Tài, Đinh Hoả
        Thương Quan và Tân Kim Chính Quan. Ở đây bạn có thể thấy sao Chính Quan
        người chồng bị xua đuổi đi với sự hiện diện của Thương Quan. Nữ giới
        sinh ngày Giáp Thìn sẽ ít khi chú trọng đến người chồng, điều mà họ thật
        sự muốn không phải là tình cảm mà phải tỏ ra người chông phải tỏ ra có
        ích.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhìn chung, họ là người khá chiến lược. Khiêm tốn và không đe doạ là vũ
        khí chính của họ để tránh mũi dùi nguy hiểm và rắc rối. Mặt khác, họ là
        người thích giúp đỡ nhưng cũng ghi lại khi giúp ai đó để đòi công ơn khi
        cần thiết. Họ vẫn sẽ giữ tình cảm tốt với bạn chừng nào bạn còn tỏ ra
        hữu dụng.
      </p>
    </div>
  );
};

export const GiapDan = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Giáp Dần là người có trách nhiệm và đam mê trong công
        việc, có xu hướng áp đặt gia trưởng khi bày tỏ quan điểm và dễ cuốn vào
        các cuộc tranh cãi và đối đầu, có vận hôn nhân xấu, dễ bi luỵ về tình
        cảm, có lòng nhân từ, yêu thương người già yếu. Không hợp kết hôn với
        người sinh ngày Canh Thìn.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Cao lớn, mạnh mẽ và quyền lực là cách đơn giản nhất để mô tả người Giáp
        Dần. Cây to lớn cần mất nhiều thời gian để phát triển và phải chịu nhiều
        vất vả. Nó sẽ phải chịu đựng vô số cơn bão và nhiều vết trầy xước trong
        khi nhiều người xung quanh muốn cắt bỏ nó. Giáp Dần vẫn vươn cao và nó
        mạnh mẽ. Những người xung quanh sẽ cảm thấy người này là một nguồn cảm
        hứng và họ sẽ tự nhủ rằng một ngày nào đó tôi cũng sẽ mạnh mẽ như vậy.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Giáp là đứng đầu trong tất cả các hành của 10 Can. Họ bắt đầu chậm và
        thường thận trọng trong giai đoạn phát triển. Họ được mô tả là quý,
        khiêm tốn và nép mình. Điều này bởi vì trong những năm tháng đầu đầu
        mong manh thì họ vẫn còn là một cây non. Họ học được rằng không có gì là
        vững chắc mãi mãi và họ phải siêng năng trong tiến trình phát triển của
        riêng mình. Một khi họ đạt được sự trưởng thành, bạn sẽ thấy họ tự tin
        và phát triển hơn hẳn những người xung quanh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trong 60 Hoa Giáp thì Giáp Dần được xem như là Mộc cứng chắc nhất. Hình
        ảnh của Giáp Dần có thể tưởng tượng như một cây xà nu, cây đa, cổ thụ to
        lớn trong rừng sâu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Gíap Dần thường trung thành, đáng tin cậy, thẳng thắn và
        có sự tự tin. Họ tham vọng, có sức sống và trực giác tốt giúp cho họ ra
        được những quyết định có trách nhiệm trong khi tính thẳng thắn và thái
        độ tích cực giúp họ có cái nhìn lành mạnh trong mọi tình huống. Tính
        cách đôi khiến cho họ trở nên thấu hiểu và từ bi, cũng như đôi lúc cứng
        nhắc và áp đặt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Chân thật và sống cảm xúc là hai trong số những điểm mạnh trong tính
        cách của họ. Sức mạnh trực giác của họ là bẩm sinh và họ có giác quan
        thứ sáu giúp họ hiểu được người xung quanh. Họ được trời phú cho khả
        năng giải quyết mọi khó khăn, họ xuất sắc trong việc tìm ra giải pháp
        giải quyết các rắc rối và luôn có thể tin vào trực giác của chính mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ là những chiến lược gia ngoại hạng và luôn biết cách nhìn vào bức
        tranh toàn cảnh. Họ biết cách quản lý kế hoạch lớn cũng như xoay xở để
        đạt được mục tiêu dài hạn. Họ sẵn lòng làm việc vất vả có khả năng nhìn
        xa trông rộng để tiến hành những dự án quan trọng và to lớn. Tầm nhìn
        của họ khá mạnh mẽ và họ sẵn lòng làm việc vất vả để hoàn thành các mục
        tiêu.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ làm việc có phương pháp và năng suất cao, cũng như khá thực dụng và
        họ luôn tin tưởng mạnh mẽ vào ý kiến và lý lẽ của riêng mình. Họ thông
        minh, suy nghĩ thấu đáo và luôn không ngừng tìm kiếm kiến thức.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Vận may sẽ giúp cho họ có nhiều cơ hội làm việc và kinh doanh. Nếu là
        người làm công, họ sẽ có khả năng đem lại may mắn cho bất kỳ doanh
        nghiệp nào. Họ có ham muốn tột bật để đạt được thành công và sẽ rất giỏi
        trong vị trí quản lý. Họ có khả năng quản lý con người và đạt được kết
        quả tốt do cá tính mạnh của mình.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù họ có sự hào hứng để đón nhận mọi thử thách trong đời, họ cần
        phải thận trọng không được cẩu thả. Người Giáp Dần có thể đa nghi và
        nhiều lo lắng nên họ cần học cách tránh lãng phí năng lượng của mình vào
        những việc không quá quan trọng. Họ thường phải trải qua nhiều khó khăn
        trước khi họ được công nhận và tưởng thưởng cho những nỗ lực của mình.
        Vì lý do này, họ cần phải thận trọng trên con đường đi đến thành công và
        nên tập tính kiên nhẫn.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Gíap Dần có thể ích kỷ, cứng nhắc, ngoan cố và thích tranh cãi. Họ
        cũng tỏ ra cục bộ và thích chiếm hữu. Người khác rất khó khuyên bảo họ
        khi họ tỏ ra những nét tính cách tiêu cực này. Họ không muốn nhìn những
        khía cạnh khác và do đó dễ lờ đi góp ý của người khác. Tâm trạng của
        người Giáp Dần dao động thường xuyên và họ cần nỗ lực để vượt qua xu
        hướng này bằng cách giữ sự khách quan.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Dần có khả năng đánh giá con người và tình huống rất nhanh.
        Tuy nhiên, họ không chịu thoả hiệp và đôi khi điều này có thể quá lố vì
        họ ít khi chịu đàm phán hay thương lượng điều gì.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Những Nét Tính Cách Của Người Giáp Dần:</b> trung thành, thẳng thắn,
        đáng tin cậy, chiến lược, thực dụng, thông minh.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Quyết tâm cao và tham vọng khiến cho người này có những tố chất cần
        thiết của một lãnh đạo. Họ không dễ chấp nhận mệnh lệnh và do đó tốt
        nhất là họ nên chọn công việc tự do hoặc làm chủ doanh nghiệp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có kỹ năng giao tiếp và thuyết phục xuất sắc. Họ có thể sử dụng những
        kỹ năng trên để kiếm được nhiều tiền và bằng ách sử dụng sự khôn ngoan
        trời phú để đạt được nhiều mục tiêu lớn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Kỹ năng giao tiếp khéo léo có thể giúp họ thành công trong những sự
        nghiệp như truyền thông, bán hàng hay xuất bản. Người Giáp Dần cũng có
        thể thử các công việc đòi hỏi sự sáng tạo cao như nghệ thuật hay viết
        lách. Họ cũng có thể sử dụng đầu óc thích nổi loạn cua mình để đứng lên
        vì những gì họ cho là chính nghĩa như là lãnh đạo trong một phong trào
        cải cách giáo dục hay xã hội.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bất cứ lúc nào họ cảm thấy mình đang lặp lại chính mình, họ có thể dừng
        lại để tự đánh giá bản thân và có thể đó là lúc phải thay đổi sự nghiệp.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Công Việc:</h4>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Dần có đầu óc tri thức sắc bén, kỹ năng giao tiếp xã hội tốt,
        và năng lực lãnh đạo bẩm sinh giúp cho họ thành công trong bất kỳ sự
        nghiệp nào họ chọn theo đuổi. Họ không chấp nhận mệnh lệnh và thà làm
        theo cách của riêng mình. Do đó, họ nên làm việc trong những công việc
        mà họ không phải ở vị trí phục tùng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu họ khởi nghiệp thì họ có đủ tài năng và thiên khiếu để xây dựng tổ
        chức phát triển do kỹ năng bẩm sinh giải quyết vấn đề. Họ có suy nghĩ
        cởi mở cùng với trí tưởng tượng giúp họ tìm ra được những phương thức
        mới để khiến tổ chức của mình thành công.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ cũng có tính thích các hoạt động nhân đạo nên có thể chọn những công
        việc như từ thiện, tôn giáo. Khi người Giáp Dần càng lớn tuổi hơn thì
        nhu cầu này càng gia tăng.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Dần yêu thích kịch nghệ và thể hiện bản thân, do đó họ dễ bị
        cuốn hút vào nghệ thuật, giải trí hay viết lách. Họ cũng có thể rất giỏi
        trong nghiên cứu, giáo dục, triết học, khoa học hay chính trị.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ làm việc tốt với những đối tác khác và dễ thành công trong các công
        việc đòi hỏi đi lại, dịch chuyển.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bất kể họ chọn tham gia vào sự nghiệp nào, họ luôn phấn khích và hết
        mình vì công việc.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Những Nét Tính Cách Trong Công Việc Của Người Giáp Dần:</b> quyết
        tâm, tham vọng, lãnh đạo, cuốn hút, thông minh, thích mạo hiểm, có xu
        hướng độc tài, dễ tự nghi ngờ bản thân.{" "}
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Hôn Nhân - Quan Hệ - Gia Đình:{" "}
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Dần là người bạn đời chân thành, chung thuỷ và thích lý tưởng
        hoá. Họ đặt hi vọng cao trong mối quan hệ và sẵn lòng cố gắng để đạt
        được mục tiêu bay bổng. Tình yêu và niềm kiêu hãnh sẽ khiến cho họ hết
        mình vì người mình yêu. Vì điều này, người khác sẽ cảm thấy bị cuốn hút
        đến với người Giáp Dần vì sự tận tâm và nỗ lực của họ.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ luôn cẩn trọng và tính toán trong những vấn đề liên quan đến tình
        cảm. Họ luôn chắc chắn rằng họ chọn người mình yêu và không bao giờ chấp
        nhận điều gì thấp hơn. Điều này khiến cho họ không bao giờ vội vã lao
        vào một mối quan hệ mà thích mọi thứ diễn biến tự nhiên. Bởi vì điều
        này, đôi khi khá khó để tìm được một người bạn đời hợp tiêu chuẩn mong
        đợi của người Gíap Dần.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù tận tâm trong mối quan hệ tình cảm, họ luôn có một khao khát được
        trở nên độc lập. Do đó họ cần phải chọn cho mình một người bạn đời có
        thể cho họ sự tự do khi họ muốn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Dần có đời sống giao tiếp xã hội năng động và rất thân thiện
        và vui vẻ. Họ thích gặp gỡ người mới và luôn trân trọng tình bạn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ cũng là những người lãng mạn nên sẽ là người yêu thích sự theo đuổi
        trong tình yêu và họ sẵn lòng làm tất cả những gì cho người mình yêu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tính cách của họ có hai mặt. Đôi khi họ khá cảm xúc và ngẫu hứng, torng
        khi đôi khi họ lại rụt rè hay lãnh đạm. Ngoài ra, cảm xúc của người Giáp
        Dần dao động giữa thực tế và lý tưởng. Tâm trạng dao động lên xuống có
        thể khiến cho người xung quanh rối rắm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Xét về mặt gia đình thì người Giáp Dần có nền tảng rất sâu sắc. Họ bị
        ảnh hưởng sâu sắc bởi những người lớn tuổi khi con trẻ và có mối quan hệ
        vững chắc với gia đình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người phối ngẫu của người Giáp Dần thường thông minh, siêng năng. Dần dà
        qua thời gian thì người Giáp Dần ngày càng gắn bó với người phối ngẫu
        của mình và không thể tách rời hai vợ chồng. Họ cũng rất mắn con và dễ
        sinh con đàn cháu đống. Họ nên kết hôn muộn để tránh xảy ra các vấn đề
        tình cảm ngoài hôn thú. Nếu họ kết hôn muộn thì khả năng cao là họ trung
        thành với vợ hay chồng mình và sẽ không muốn ly hôn.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nam giới ngày sinh Giáp Dần hơi sợ vợ trong khi nữ giớ ngày Giáp Dần
        thường sẽ thích kiểm soát người chồng.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bởi vì bản tính đa nghi nên họ dễ ghen. Họ cần học cách thấu hiểu, tôn
        trọng và tin tưởng người bạn đời cũng như cần biết rằng mọi sự nghi ngờ
        sẽ phá huỷ mối quan hệ tình cảm.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Trong Tình Cảm và Hôn Nhân:</b> độc lập, lý tưởng
        hoá, lãng mạn, thân thiện, ngẫu hứng, tình cảm, nhạy cảm, dễ nghi ngờ,
        có xu hướng đeo bám.{" "}
      </p>

      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Dần có thể tỏ ra điềm tĩnh như một con hổ, họ giấy bản tính
        dữ dội và mạnh mẽ bên dưới vẻ ngoài của họ. Họ có thể tỏ ra dữ dội và
        khó đoán. Họ cũng thích ganh đua và thường bị lôi kéo vào những cuộc đấu
        đá cả trí thức và tay chân. Không có thách thức nào là quá lớn cho người
        Giáp Dần và họ không thích thất bại. Họ có sự kiêu hãnh và phẩm giá lớn
        khiến cho họ có thể tỏ ra ngoan cố hay thậm chí cứng đầu khi họ cảm thấy
        họ đang làm đúng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bản tính ghét thất bại của người Giáp Dần quá lớn đến nỗi chỉ cần 1 thất
        bại nhỏ hay bị người khác chỉ trích cũng sẽ khiến cho họ rơi vào trầm
        uất. Họ không bao giờ có tâm trạng đi xuống trong thời gian dài nhưng
        một khi họ đã lành vết thương, họ sẵn sàng lao vào hành động để đối mặt
        với thử thách tiếp theo. Họ can đảm và anh dũng và theo đuổi hành trình
        cuộc đời với đam mê không bao giờ tắt.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Dần rất thực tế và họ luôn nỗ lực để giải quyết các khó khăn
        một cách nhanh chóng và hiệu quả. Họ không bao giờ lười biếng tuy nhiên
        lại có sức tập trung chú ý ngắn. Bất cứ một vấn đề khó khăn nào thường
        xuyên xuất hiện sẽ khiến cho họ nổi nóng và bốc lửa nhanh chóng. Hành
        Hoả sẽ giúp ích củng cố Giáp Dần nếu so với các ngày sinh Dần khác, nó
        giúp cho người này có sự ấm áp và hạn chế xu hướng hành động của họ. Họ
        thường có khả năng thích nghi các nhóm xã hội khác nhau và làm việc hợp
        tác tốt. Họ thậm chí khá hạnh phúc vui vẻ nếu lùi bước lại phía sau và
        để cho người khác dẫn dắt. Họ là những nhà lãnh đạo bẩm sinh và dễ trở
        thành doanh nhân thành công.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Dần có tính cách rất tươi vui. Họ sống động, duyên dáng và
        thường được yêu thích và nổi tiếng. Họ thường rất chân thật trong mọi
        việc làm và thường can đảm trong biểu lộ tình cảm mà họ tin là sẽ được
        đền đáp. Họ thường mong đợi sự đồng thuận từ bạn bè và gia đình tuy
        nhiên đổi lại họ cũng sẵn lòng tranh đấu vì danh dự và hạnh phúc của
        những người mà họ yêu. Th6ong thường người Giáp Dần ít khi là người ích
        kỷ. Họ không bị lôi kéo bởi vấn đề tiền bạc hay quyền lực và họ không
        ngần ngại cống hiến thời gian, sự quan tâm và tài sản cho người đang
        cần.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Dần được xem như toạ trên Tỷ Kiên Tinh, được xem như người
        sống theo cảm xúc và điều này có thể khiến cho họ dễ ra các quyết định
        tồi hoặc vội vàng thường do cảm xúc lôi kéo. Hành Mộc thường biểu thị độ
        ổn định cao mặc dù đôi khi họ cũng thiếu quyết đoán.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Dần thường không khéo léo trong phát ngôn nhưng lại rất tử
        tế, đạo đức và rõ ràng khi đối xử với người khác. Họ có sự cuốn hút cao
        và có khả năng thuyết phục tinh tế giúp cho họ dễ dàng cuốn hút những
        người xung quanh mình.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Dần thường khá nhân đạo và lạc quan. Họ sống khá tình cảm và
        rất lãng mạn. Bạn bè thường đánh giá họ là người luôn vui vẻ. Điều này
        là bởi vì Dần cũng là Tỷ Kiên Tinh cũng là Tứ Trường Sinh. Họ hay làm
        trò vui và sáng tạo, cũng như có lối sống không tuân theo khuôn mẫu
        thông thường. Họ cần sự chú ý của người khác và không thích bị lờ đi.
        Nếu có nhiều Mộc trong toàn bộ lá số, họ có thể tỏ ra là người có cái
        tôi lớn dễ thù hận, và mặc dù họ có thể tha thứ nhưng không bao giờ
        quên.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bố mẹ có ngày sinh Giáp Dần sẽ không phải kiểu người làm hư hỏng con
        cái. Họ được xem như là bố mẹ tốt nuôi dạy con cái trong một môi trường
        cân bằng. Con cái Gíap Dần rất hiếu kỳ, thích hoạt náo và năng động. Bố
        mẹ của đứa con sinh ngày Giáp Dần được khuyên là nên dạy con cái mình về
        giới hạn và tôn trọng người khác. Đó là vì trẻ con sinh ngày Giáp Dần có
        xu hướng áp đặt trong mối quan hệ của chúng với người khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Giáp Dần thường sẽ có hôn nhân tốt nếu họ chọn quen người sinh
        ngày Tuất hay Hợi. Họ sẵn lòng làm việc vất vả hơn để có hôn nhân tốt
        hơn nếu họ kết hôn với người sinh ngày Thân hay Tỵ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Giáp toạ Dần là đầu mùa xuân mang theo khí Giáp Mộc Tỷ Kiên và phụ khí
        của Bính Hoả Thực Thần và Mậu Thổ Thiên Tài. Sự vững chắc của Giáp Mộc
        biểu thị qua thân cây vững chãi và do đó họ có thể rất quyết tâm đặc
        biệt là khi xét đến chủ đề tình bạn nghĩa anh em. Họ có một sự chung
        thuỷ trung thành dữ dội đối với bạn bè. Thiên Tài giúp họ tập trung vào
        những cơ hội tương lai trong khi Thực Thần lại cho họ sự sáng tạo tốt.
        Nhìn chung, điều này sẽ sẽ tạo cho họ môi trường “không cần phải lo
        nghĩ, chỉ việc lớn lên và mọi thứ đã được lo đầy đủ”.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Dần thu hút Hợi mang theo năng lượng của Nhâm Thuỷ Thiên Ấn và Giáp Mộc
        Tỷ Kiên. Sự xuất hiện thường xuyên của sao Tỷ Kiên biểu thị tình bạn sẽ
        vượt lên trên tình cảm lãng mạn. Giáp có thể thường phản ứng nhanh chóng
        với bạn bè than vãn hơn là người yêu kêu khóc. Điều này sẽ khiến cho họ
        sẵn lòng để bạn đời ở nhà và suy nghĩ không biết họ có chút giá trị nào
        không?
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Dần thúc đẩy Tỵ và mang theo khí của Bính Hoả Thực Thần, Canh Kim Thất
        Sát và Mậu Thổ Thiên Tài. Bởi vì Thất Sát và Thiên Tài đều là sao chủ về
        bạn trai, bạn gái, điều này biểu thị rằng họ không bị dẫn dắt đến mối
        quan hệ tình cảm lãng mạn. Do đó, đối với cả nam và nữ, quan hệ tình cảm
        lãng mạn nằm dưới tình cảm bạn bè.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trụ Giáp Dần mang theo sự chung thuỷ lớn đối với những người xung quanh
        và họ thà hi sinh bản thân để theo đuổi sự phát triển và lớn mạnh. Họ có
        xu hướng cô đơn mặc dù xung quanh rất nhiều bạn bè. Khi đạt đến một cấp
        độ, họ sẽ phát triển quá mạnh mà người khác chỉ có thể thấy được cái
        bóng. Họ là những nhân vật rất giỏi truyền cảm hứng và có cơ hội trong
        việc trở thành người dẫn dắt và neo giữ chính cho sự phát triển của
        những người khác.
      </p>
    </div>
  );
};
