import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { MainContent } from "./components/MainContent";
import PageThanSat from "./page/pageThanSat";
import PageTieuNhiQuanSat from "./page/pageTieuNhiQuanSat";
import "./scss/style.scss";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PageHoaGiap from "./page/Page60hoagiap";
import PageTinhCach from "./page/PageTinhCach";
import PageDungThanDieuHau from "./page/PageDungThanDieuHau";
import PageNguHanh from "./page/PageNguHanh";
import { BatTuNew } from "./page/BatTuNguoiNoiTieng";
import logo from "./assets/logo.png";
import { useTabs, TabPanel } from "react-headless-tabs";


function App() {
  const checkLogin = useSelector((state) => state.user);
  useEffect(() => { }, [checkLogin]);
  const [selectedTab, setSelectedTab] = useTabs(["/", "hoagiap", "thansat", "celebrity", "tieunhi", "tinhcach", "dungthandieuhau", "nguhanh"]);
  const [isHistory, setIsHistory] = useState(0)
  const [dataNote, setDataNote] = useState("")




  return (
    <>
      <div id="app__" style={{ width: "100%", margin: "0 auto" }}>
        <div id="container">
          <Header activeTab={(value) => setSelectedTab(value)} isHistory={(value) => setIsHistory(isHistory + value)} data_={(value) => setDataNote(value)} />
          <TabPanel hidden={selectedTab !== "/"}>
            <MainContent isHistory={isHistory} activeTab={(value) => setSelectedTab(value)} dataNote={dataNote} />
          </TabPanel>
          <TabPanel hidden={selectedTab !== "hoagiap"}>
            <PageHoaGiap />
          </TabPanel>
          <TabPanel hidden={selectedTab !== "thansat"}>
            <PageThanSat />
          </TabPanel>
          <TabPanel hidden={selectedTab !== "celebrity"}>
            <BatTuNew />
          </TabPanel>
          <TabPanel hidden={selectedTab !== "tieunhi"}>
            <PageTieuNhiQuanSat />
          </TabPanel>
          <TabPanel hidden={selectedTab !== "tinhcach"}>
            <PageTinhCach />
          </TabPanel>
          <TabPanel hidden={selectedTab !== "dungthandieuhau"}>
            <PageDungThanDieuHau />
          </TabPanel>
          <TabPanel hidden={selectedTab !== "nguhanh"}>
            <PageNguHanh />
          </TabPanel>
          <Footer />
        </div>
      </div>

      <div className={checkLogin ? "loading show" : "loading"}></div>
      <img className={checkLogin ? "imagee show" : "imagee"} src={logo} />
    </>
  );
}

export default App;
