import React, { useEffect, useState } from "react";
import "../scss/thansat.scss";
import { Button, Input, InputGroup, Label, Table } from "reactstrap";
import ReactTooltip from "react-tooltip";
import { ThanSatDay } from "../components/converThanSat";
import Modal from "../components/Modal";

function ThanSat(props) {
  const {
    objectCanDay,
    canDay,
    objectChiDay,
    chiDay,
    objectChiMonth,
    chiMonth,
    canMonth,
    objectCanYear,
    canYear,
    chiYear,
    objectChiYear,
    ngThan,
    objectChiHour,
    chiHour,
    canHour
  } = props;

  const thanSatDay = ThanSatDay();

  const [isOpen, setOpen] = useState(false);
  const [values, setValues] = useState("");

  const handleClick = (value) => {
    setOpen(!isOpen);

    if (value === "Phi Đao" || value === "Phi Nhẫn" || value === "Phi Nhận") setValues('Phi Đao/ Phi Nhẫn/ Phi Nhận');
    else if (value === "Thiên La" || value === "Địa Võng") setValues("Thiên La Địa Võng");
    else if (value === "Quý Hợp") setValues("Quý Hợp Quý Nhân");
    else if (value === "Thiên Thượng Tam Kỳ" || value === "Nhân Trung Tam Kỳ" || value === "Địa Hạ Tam Kỳ") setValues("Tam Kỳ Quý Nhân");
    else if (value === "Thiên Xá" || value === "Thiên Thứ") setValues("Thiên Xá / Thiên Thứ");
    else if (value === "Lưu Hạ" || value === "Lưu Hà") setValues("Lưu Hạ / Lưu Hà");
    else if (value === "Đa Mưu") setValues("Chiến lược gia (Đa Mưu)");
    else if (value === "Giải Thần") setValues("Giải Thần / Thiên Giải");
    else if (value === "Huyết Nhẫn") setValues("Huyết Nhẫn / Huyết Đao");
    else if (value === "Long Đức") setValues("Long Đức / Long Đức Quý Nhân");
    else if (value === "Đại Hao") setValues("Phá Toái / Đại Hao");
    else if (value === "Nhật Phá") setValues("Nhật Phá / Phá Nhật");
    else  setValues(value);
   
  };

  const canNgay = () => {
    const items = []

  
    for (let value of Object.values(objectCanDay)) {
      const arrValues = value.split(" ")

      if (arrValues.length >= 2 &&  arrValues.includes(canDay) && arrValues.includes(chiDay)) items.push(<td className="td-custom clr-red">{value}</td>);
      else if (arrValues.length < 2 && arrValues.includes(chiDay)) items.push(<td className="td-custom clr-red">{value}</td>);
      else items.push(<td className="td-custom">{value}</td>)
      
    }
   
    return items;
  }
  const chiNgay = () => {
    const items = []

  
    for (let value of Object.values(objectChiDay)) {
      const arrValues = value.split(" ")

      if (arrValues.includes(chiDay) && arrValues.length < 3) items.push(<td className="td-custom-2 clr-red">{value}</td>);
      else if (arrValues.includes(chiDay) && arrValues.length > 3) items.push(<td className="td-custom-2 clr-red break">{value}</td>);
      else items.push(<td className="td-custom-2">{value}</td>)
      
    }
   
    return items;
  }

  const chiThang = () => {
    const items = []

  
    for (let value of Object.values(objectChiMonth)) {
      const arrValues = value.split(" ")

      if (arrValues.length >= 2 &&  arrValues.includes(canMonth) && arrValues.includes(chiMonth)) items.push(<td className="td-custom clr-red">{value}</td>);
      else if (arrValues.length < 2 && arrValues.includes(chiMonth)) items.push(<td className="td-custom clr-red">{value}</td>);
      else items.push(<td className="td-custom-2">{value}</td>)
      
    }
   
    return items;
  }
  const canNam = () => {
    const items = []

  
    for (let value of Object.values(objectCanYear)) {
      const arrValues = value.split(" ")

      if (arrValues.includes(chiYear) && arrValues.length < 3) items.push(<td className="td-custom-2 clr-red">{value}</td>);
      else if (arrValues.includes(chiYear) && arrValues.length > 3) items.push(<td className="td-custom-2 clr-red break">{value}</td>);
      else items.push(<td className="td-custom-2">{value}</td>)
      
    }
   
    return items;
  }
  const chiNam = () => {
    const items = []

  
    for (let value of Object.values(objectChiYear)) {
      const arrValues = value.split(" ")

      if (arrValues.length >= 2 &&  arrValues.includes(canYear) && arrValues.includes(chiYear)) items.push(<td className="td-custom clr-red">{value}</td>);
      else if (arrValues.length < 2 && arrValues.includes(chiYear)) items.push(<td className="td-custom clr-red">{value}</td>);
      else items.push(<td className="td-custom-2">{value}</td>)
      
    }
   
    return items;
  }
  const chiGio = () => {
    const items = []

  
    for (let value of Object.values(objectChiHour)) {
      const arrValues = value.split(" ")

      if (arrValues.includes(canHour) || arrValues.includes(chiHour)) items.push(<td className="td-custom clr-red">{value}</td>);
      else items.push(<td className="td-custom-2">{value}</td>)
      
    }
   
    return items;
  }
  const ngaySinhRow1 = () => {
    const items = []

  
    for (let value of Object.values(thanSatDay?.row_one)) {
      const arrValues = value.split(" ")

      if (arrValues.length >= 2 &&  arrValues.includes(canDay) && arrValues.includes(chiDay)) items.push(<td className="td-custom clr-red">{value}</td>);
      else if (arrValues.length < 2 && arrValues.includes(chiDay)) items.push(<td className="td-custom clr-red">{value}</td>);
      else items.push(<td className="td-custom-2">{value}</td>)
      
    }
   
    return items;
  }
  const ngaySinhRow2 = () => {
    const items = []

  
    for (let value of Object.values(thanSatDay?.row_two)) {
      const arrValues = value.split(" ")

      if (arrValues.length >= 2 &&  arrValues.includes(canDay) && arrValues.includes(chiDay)) items.push(<td className="td-custom clr-red">{value}</td>);
      else if (arrValues.length < 2 && arrValues.includes(chiDay)) items.push(<td className="td-custom clr-red">{value}</td>);
      else items.push(<td className="td-custom-2">{value}</td>)
      
    }
   
    return items;
  }
  const ngaySinhRow3 = () => {
    const items = []

  
    for (let value of Object.values(thanSatDay?.row_three)) {
      const arrValues = value.split(" ")

      if (arrValues.length >= 2 &&  arrValues.includes(canDay) && arrValues.includes(chiDay)) items.push(<td className="td-custom clr-red">{value}</td>);
      else if (arrValues.length < 2 && arrValues.includes(chiDay)) items.push(<td className="td-custom clr-red">{value}</td>);
      else items.push(<td className="td-custom-2">{value}</td>)
      
    }
   
    return items;
  }
  const ngaySinhRow4 = () => {
    const items = []

  
    for (let value of Object.values(thanSatDay?.row_four)) {
      const arrValues = value.split(" ")

      if (arrValues.length >= 2 &&  arrValues.includes(canDay) && arrValues.includes(chiDay)) items.push(<td className="td-custom clr-red">{value}</td>);
      else if (arrValues.length < 2 && arrValues.includes(chiDay)) items.push(<td className="td-custom clr-red">{value}</td>);
      else items.push(<td className="td-custom-2">{value}</td>)
      
    }
   
    return items;
  }
  const ngaySinhRow5 = () => {
    const items = []

  
    for (let value of Object.values(thanSatDay?.row_five)) {
      const arrValues = value.split(" ")

      if (arrValues.length >= 2 &&  arrValues.includes(canDay) && arrValues.includes(chiDay)) items.push(<td className="td-custom clr-red">{value}</td>);
      else if (arrValues.length < 2 && arrValues.includes(chiDay)) items.push(<td className="td-custom clr-red">{value}</td>);
      else items.push(<td className="td-custom-2">{value}</td>)
      
    }
   
    return items;
  }
  const ngaySinhRow6 = () => {
    const items = []

  
    for (let value of Object.values(thanSatDay?.row_six)) {
      const arrValues = value.split(" ")

      if (arrValues.length >= 2 &&  arrValues.includes(canDay) && arrValues.includes(chiDay)) items.push(<td className="td-custom clr-red">{value}</td>);
      else if (arrValues.length < 2 && arrValues.includes(chiDay)) items.push(<td className="td-custom clr-red">{value}</td>);
      else items.push(<td className="td-custom-2">{value}</td>)
      
    }
   
    return items;
  }
  const ngaySinhRow7 = () => {
    const items = []

  
    for (let value of Object.values(thanSatDay?.row_seven)) {
      const arrValues = value.split(" ")

      if (arrValues.length >= 2 &&  arrValues.includes(canDay) && arrValues.includes(chiDay)) items.push(<td className="td-custom clr-red">{value}</td>);
      else if (arrValues.length < 2 && arrValues.includes(chiDay)) items.push(<td className="td-custom clr-red">{value}</td>);
      else items.push(<td className="td-custom-2">{value}</td>)
      
    }
   
    return items;
  }
  const ngaySinhRow8 = () => {
    const items = []

  
    for (let value of Object.values(thanSatDay?.row_eight)) {
      const arrValues = value.split(" ")

      if (arrValues.length >= 2 &&  arrValues.includes(canDay) && arrValues.includes(chiDay)) items.push(<td className="td-custom clr-red">{value}</td>);
      else if (arrValues.length < 2 && arrValues.includes(chiDay)) items.push(<td className="td-custom clr-red">{value}</td>);
      else items.push(<td className="td-custom-2">{value}</td>)
      
    }
   
    return items;
  }
  const ngaySinhRow9 = () => {
    const items = []

  
    for (let value of Object.values(thanSatDay?.row_nine)) {
      const arrValues = value.split(" ")

      if (arrValues.length >= 2 &&  arrValues.includes(canDay) && arrValues.includes(chiDay)) items.push(<td className="td-custom clr-red">{value}</td>);
      else if (arrValues.length < 2 && arrValues.includes(chiDay)) items.push(<td className="td-custom clr-red">{value}</td>);
      else items.push(<td className="td-custom-2">{value}</td>)
      
    }
   
    return items;
  }
  const ngaySinhRow10 = () => {
    const items = []

  
    for (let value of Object.values(thanSatDay?.row_ten)) {
      const arrValues = value.split(" ")

      if (arrValues.length >= 2 &&  arrValues.includes(canDay) && arrValues.includes(chiDay)) items.push(<td className="td-custom clr-red">{value}</td>);
      else if (arrValues.length < 2 && arrValues.includes(chiDay)) items.push(<td className="td-custom clr-red">{value}</td>);
      else items.push(<td className="td-custom-2">{value}</td>)
      
    }
   
    return items;
  }
  const ngaySinhRow11 = () => {
    const items = []

  
    for (let value of Object.values(thanSatDay?.row_eleven)) {
      const arrValues = value.split(" ")

      if (arrValues.length >= 2 &&  arrValues.includes(canDay) && arrValues.includes(chiDay)) items.push(<td className="td-custom clr-red">{value}</td>);
      else if (arrValues.length < 2 && arrValues.includes(chiDay)) items.push(<td className="td-custom clr-red">{value}</td>);
      else items.push(<td className="td-custom-2">{value}</td>)
      
    }
   
    return items;
  }
  const ngaySinhRow12 = () => {
    const items = []

  
    for (let value of Object.values(thanSatDay?.row_twel)) {
      const arrValues = value.split(" ")

      if (arrValues.length >= 2 &&  arrValues.includes(canDay) && arrValues.includes(chiDay)) items.push(<td className="td-custom clr-red">{value}</td>);
      else if (arrValues.length < 2 && arrValues.includes(chiDay)) items.push(<td className="td-custom clr-red">{value}</td>);
      else items.push(<td className="td-custom-2">{value}</td>)
      
    }
   
    return items;
  }

  return (
    <div >
      <div id="than-sat-component" className="than_sat_title">
        <h3>THẦN SÁT</h3>
      </div>

      {/* can ngày  */}
      <div className="table_thansat">
        <Table className="table_thansat">
          <thead>
            <tr>
              <th>
                <div className="right">
                  Thần <br /> Sát
                </div>
                <hr className="hr" />
                <div className="left">
                  Can <br /> Ngày
                </div>
              </th>
              <td
                id='taqn'
                style={{ cursor: "pointer" }}
                className="background"
                data-for="can-ngay"
                data-tip="Thiên Ất Quý Nhân"
              >
                Thiên Ất <br /> Quý Nhân
              </td>
              <td
                id='hamtri'
                className="background"
                data-for="can-ngay"
                data-tip="Hàm Trì"
                style={{ cursor: "pointer" }}
              >
                Hàm Trì
              </td>
              <td
                id='vanxuong'

                className="background"
                data-for="can-ngay"
                data-tip="Văn Xương"
                style={{ cursor: "pointer" }}
              >
                Văn Xương
              </td>
              <td
                id='locthan'

                className="background"
                data-for="can-ngay"
                data-tip="Lộc Thần"
                style={{ cursor: "pointer" }}
              >
                Lộc Thần
              </td>

              <td
                id='duongnhan'

                className="background"
                data-for="can-ngay"
                data-tip="Dương Nhẫn"
                style={{ cursor: "pointer" }}
              >
                Dương Nhẫn
              </td>

              <td
                id='hongdiem'

                className="background"
                data-for="can-ngay"
                data-tip="Hồng Diễm"
                style={{ cursor: "pointer" }}
              >
                Hồng Diễm
              </td>
              <td
                id='hocduong'

                className="background"
                data-for="can-ngay"
                data-tip="Học Đường"
                style={{ cursor: "pointer" }}
              >
                Học Đường
              </td>

              <td
                id='luuha'
              
                className="background"
                data-for="can-ngay"
                data-tip="Lưu Hạ"
                style={{ cursor: "pointer" }}
              >
                Lưu Hạ
              </td>
              <td
                id='phidao'

                className="background"
                data-for="can-ngay"
                data-tip="Phi Đao"
                style={{ cursor: "pointer" }}
              >
                Phi Đao
              </td>
              <td
                id='thientai'

                className="background"
                data-for="can-ngay"
                data-tip="Thiên Tài"
                style={{ cursor: "pointer" }}
              >
                Thiên Tài
              </td>
              <td
                id='kimdu'

                className="background"
                data-for="can-ngay"
                data-tip="Kim Dư"
                style={{ cursor: "pointer" }}
              >
                Kim Dư
              </td>
              <td
                id='quythuc'

                className="background"
                data-for="can-ngay"
                data-tip="Quý Thực"
                style={{ cursor: "pointer" }}
              >
                Quý <br /> Thực
              </td>
              <td
                id='quyhop'

                className="background"
                data-for="can-ngay"
                data-tip="Quý Hợp"
                style={{ cursor: "pointer" }}
              >
                Quý <br /> Hợp
              </td>
              <td
                id='luchopquy'

                className="background"
                data-for="can-ngay"
                data-tip="Lục Hợp Quý"
                style={{ cursor: "pointer" }}
              >
                Lục Hợp <br /> Quý
              </td>
              <td
                id='tcqn'

                className="background"
                data-for="can-ngay"
                data-tip="Thái Cực Quý Nhân"
                style={{ cursor: "pointer" }}
              >
                Thái Cực <br /> Quý Nhân
              </td>
              <td
                id='qaqn'

                className="background"
                data-for="can-ngay"
                data-tip="Quốc Ấn Quý Nhân"
                style={{ cursor: "pointer" }}
              >
                Quốc Ấn <br /> Quý Nhân
              </td>
              <td
                id='mokho'

                className="background"
                data-for="can-ngay"
                data-tip="Mộ Khố"
                style={{ cursor: "pointer" }}
              >
                Mộ Khố
              </td>
            </tr>
          </thead>

          <tbody>
            <tr>
              <th id="canday" className="background">{canDay}</th>
              {canNgay()}
             
            </tr>
          </tbody>
        </Table>
        <ReactTooltip
          id="can-ngay"
          getContent={(dataTip) => (
            <div>
              <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
              <p style={{ color: "blue" }}>
                Xin vui lòng{" "}
                <span
                  className="click-span"
                  onClick={() => {
                    handleClick(dataTip);
                  }}
                >
                  Click
                </span>{" "}
                vào đây để tìm hiểu thêm
              </p>
            </div>
          )}
          effect="solid"
          delayHide={500}
          delayShow={500}
          delayUpdate={500}
          place="top"
          border={true}
          type="light"
        />
      </div>
      {/* can ngày  */}

      {/* chi ngày  */}
      <div className="table_thansat">
        <Table className="table_thansat">
          <thead>
            <tr>
              <th className="th-custom th-chi-ngay">
                <div className="right">
                  Thần <br /> Sát
                </div>
                <hr className="hr" />
                <div className="left">
                  Chi <br /> Ngày
                </div>
              </th>
              <td
                id="hoacai"
                className="background-two td-chi-ngay"
                data-for="chi-ngay"
                data-tip="Hoa Cái"
                style={{cursor:'pointer', background: "#1B61A6!important",}}

              >
                Hoa Cái
              </td>
              <td
                id="tuongtinh"

                className="background-two td-chi-ngay"
                data-for="chi-ngay"
                data-tip="Tướng Tinh"
                style={{cursor:'pointer'}}

              >
                Tướng Tinh
              </td>
              <td
                id="dichma"

                className="background-two td-chi-ngay"
                data-for="chi-ngay"
                data-tip="Dịch Mã"
                style={{cursor:'pointer'}}

              >
                Dịch Mã
              </td>
              <td
                id="daohoa"

                className="background-two td-chi-ngay"
                data-for="chi-ngay"
                data-tip="Đào Hoa"
                style={{cursor:'pointer'}}

              >
                Đào Hoa
              </td>
              <td
                id="huyetnhan"

                className="background-two td-chi-ngay"
                data-for="chi-ngay"
                data-tip="Huyết Nhẫn"
                style={{cursor:'pointer'}}

              >
                Huyết Nhẫn
              </td>
              <td
                id="cothan"

                className="background-two td-chi-ngay"
                data-for="chi-ngay"
                data-tip="Cô Thần Quả Tú"
                style={{cursor:'pointer'}}

              >
                Cô Thần
              </td>
              <td
                id="quatu"

                className="background-two td-chi-ngay"
                data-for="chi-ngay"
                data-tip="Cô Thần Quả Tú"
                style={{cursor:'pointer'}}

              >
                Quả Tú
              </td>
              <td
                id="tangmon"

                className="background-two td-chi-ngay"
                data-for="chi-ngay"
                data-tip="Tang Môn"
                style={{cursor:'pointer'}}

              >
                Tang Môn
              </td>
              <td
                id="kiepsat"

                className="background-two td-chi-ngay"
                data-for="chi-ngay"
                data-tip="Kiếp Sát"
                style={{cursor:'pointer'}}

              >
                Kiếp Sát
              </td>
              <td
                id="cachgiac"

                className="background-two td-chi-ngay"
                data-for="chi-ngay"
                data-tip="Cách Giác"
                style={{cursor:'pointer'}}

              >
                Cách Giác
              </td>
              <td
                id="vongthan"

                className="background-two td-chi-ngay"
                data-for="chi-ngay"
                data-tip="Vong Thần"
                style={{cursor:'pointer'}}

              >
                Vong Thần
              </td>
              <td
                id="nguquy"

                className="background-two td-chi-ngay"
                data-for="chi-ngay"
                data-tip="Ngũ Quỷ"
                style={{cursor:'pointer'}}

              >
                Ngũ Quỷ
              </td>
              <td
                id="nguyetduchop"

                className="background-two td-chi-ngay"
                data-for="chi-ngay"
                data-tip="Nguyệt Đức Hợp"
                style={{cursor:'pointer'}}
                  
              >
                Nguyệt Đức Hợp
              </td>
              <td
                id="nguyetpha"

                className="background-two td-chi-ngay"
                data-for="chi-ngay"
                data-tip="Nguyệt Phá"
                style={{cursor:'pointer'}}

              >
                Nguyệt Phá
              </td>
              <td
                id="nhatpha"

                className="background-two td-chi-ngay"
                data-for="chi-ngay"
                data-tip="Nhật Phá"
                style={{cursor:'pointer'}}

              >
                Nhật Phá
              </td>
              <td
                id="thienduchop"

                className="background-two td-chi-ngay"
                data-for="chi-ngay"
                data-tip="Thiên Đức Hợp"
                style={{cursor:'pointer'}}

              >
                Thiên Đức Hợp
              </td>
              <td
                id="ngocmon"

                className="background-two td-chi-ngay"
                data-for="chi-ngay"
                data-tip="Ngọc Môn"
                style={{cursor:'pointer'}}

              >
                Ngọc Môn
              </td>
            </tr>
          </thead>

          <tbody>
            <tr>
              <th id='chiday' className="background-two">{chiDay}</th>
                {chiNgay()}
            </tr>
          </tbody>
        </Table>
        <ReactTooltip
          id="chi-ngay"
          getContent={(dataTip) => (
            <div>
              <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
              <p style={{ color: "blue" }}>
                Xin vui lòng{" "}
                <span
                  className="click-span"
                  onClick={() => {
                    handleClick(dataTip);
                  }}
                >
                  Click
                </span>{" "}
                vào đây để tìm hiểu thêm
              </p>
            </div>
          )}
          effect="solid"
          delayHide={500}
          delayShow={500}
          delayUpdate={500}
          place="top"
          border={true}
          type="light"
        />
      </div>

      {/* chi ngày  */}

      {/* chi tháng  */}
      <div className="table_thansat">
        <Table className="table_thansat"> 
          <thead>
            <tr>
              <th className="th-chi-thang">
                <div className="right">
                  Thần <br /> Sát
                </div>
                <hr className="hr-chi-thang" />
                <div className="left">
                  Chi <br /> Tháng
                </div>
              </th>
              <td
                id='tdqn'
                className="background td-chi-thang"
                data-for="chi-thang"
                data-tip="Thiên Đức Quý Nhân"
              >
                Thiên Đức Quý Nhân
              </td>
              <td
                id='ndqn'

                className="background td-chi-thang"
                data-for="chi-thang"
                data-tip="Nguyệt Đức Quý Nhân"
              >
                Nguyệt Đức Quý Nhân
              </td>
              <td
                id='nguyetkhong'

                className="background td-chi-thang"
                data-for="chi-thang"
                data-tip="Nguyệt Không"
              >
                Nguyệt Không
              </td>
              <td
                id='nguphu'

                className="background td-chi-thang"
                data-for="chi-thang"
                data-tip="Ngũ Phú"
              >
                Ngũ Phú
              </td>
             
              <td
                id='thienthu'

                className="background td-chi-thang"
                data-for="chi-thang"
                data-tip="Thiên Thứ"
              >
                Thiên Thứ
              </td>
              <td
                id='dichma2'

                className="background td-chi-thang"
                data-for="chi-thang"
                data-tip="Dịch Mã"
              >
                Dịch Mã
              </td>
              <td
                id='thienxa'

                className="background td-chi-thang"
                data-for="chi-thang"
                data-tip="Thiên Xá"
              >
                Thiên Xá
              </td>
            </tr>
          </thead>

          <tbody>
            <tr>
              <th id="chimonth"  className="background">{chiMonth}</th>
              {chiThang()}
            </tr>
          </tbody>
        </Table>
        <ReactTooltip
          id="chi-thang"
          getContent={(dataTip) => (
            <div>
              <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
              <p style={{ color: "blue" }}>
                Xin vui lòng{" "}
                <span
                  className="click-span"
                  onClick={() => {
                    handleClick(dataTip);
                  }}
                >
                  Click
                </span>{" "}
                vào đây để tìm hiểu thêm
              </p>
            </div>
          )}
          effect="solid"
          delayHide={500}
          delayShow={500}
          delayUpdate={500}
          place="top"
          border={true}
          type="light"
        />
      </div>

      {/* chi tháng  */}

      {/* can năm  */}
      <div className="table_thansat">
        <Table className="table_thansat">
          <thead>
            <tr>
              <th className="th-custom th-chi-ngay">
                <div className="right">
                  Thần <br /> Sát
                </div>
                <hr className="hr-can-nam" />
                <div className="left">
                  Can <br /> Năm
                </div>
              </th>
              <td
                id='tcqn2'
                className="background-two td-can-nam"
                data-for="can-nam"
                data-tip="Thái Cực Quý Nhân"
              >
                Thái Cực Quý Nhân
              </td>
              <td
                id='qaqn2'

                className="background-two td-can-nam"
                data-for="can-nam"
                data-tip="Quốc Ấn Quý Nhân"
              >
                Quốc Ấn Quý Nhân
              </td>
              <td
                id='ducthan'

                className="background-two td-can-nam"
                data-for="can-nam"
                data-tip="Đức Thần"
              >
                Đức Thần
              </td>
              <td
                id='canloc'

                className="background-two td-can-nam"
                data-for="can-nam"
                data-tip="Can Lộc"
              >
                Can Lộc
              </td>
              <td
                id='duongnhan2'

                className="background-two td-can-nam"
                data-for="can-nam"
                data-tip="Dương Nhẫn"
              >
                Dương Nhẫn
              </td>
              <td
                id='hocduong2'

                className="background-two td-can-nam"
                data-for="can-nam"
                data-tip="Học Đường"
              >
                Học Đường
              </td>
              <td
                id='hocsi'

                className="background-two td-can-nam"
                data-for="can-nam"
                data-tip="Học Sĩ"
              >
                Học Sĩ
              </td>
              <td
                id='hongdiem2'

                className="background-two td-can-nam"
                data-for="can-nam"
                data-tip="Hồng Diễm"
              >
                Hồng Diễm
              </td>
              <td
                id='kimdu2'

                className="background-two td-can-nam"
                data-for="can-nam"
                data-tip="Kim Dư"
              >
                Kim Dư
              </td>
              <td
                id='locthan2'

                className="background-two td-can-nam"
                data-for="can-nam"
                data-tip="Lộc Thần"
              >
                Lộc Thần
              </td>
              <td
                id='luuha2'

                className="background-two td-can-nam"
                data-for="can-nam"
                data-tip="Lưu Hạ"
              >
                Lưu Hà
              </td>
              <td
                id='luuha-'

                className="background-two td-can-nam"
                data-for="can-nam"
                data-tip="Lưu Hạ"
              >
                Lưu Hạ
              </td>
              <td
                id='mocduc'

                className="background-two td-can-nam"
                data-for="can-nam"
                data-tip="Mộc Dục"
              >
                Mộc Dục
              </td>
              <td
                id='ngocduong'

                className="background-two td-can-nam"
                data-for="can-nam"
                data-tip="Ngọc Đường"
              >
                Ngọc Đường
              </td>
              <td
                id='phinhan'

                className="background-two td-can-nam"
                data-for="can-nam"
                data-tip="Phi Nhẫn"
              >
                Phi Nhẫn
              </td>
              <td
                id='phinhan-'

                className="background-two td-can-nam"
                data-for="can-nam"
                data-tip="Phi Nhận"
              >
                Phi Nhận
              </td>
              <td
                id='phuctinh'

                className="background-two td-can-nam"
                data-for="can-nam"
                data-tip="Phúc Tinh Quý Nhân"
              >
                Phúc Tinh Quý Nhân
              </td>
              <td
                id='thientai2'

                className="background-two td-can-nam"
                data-for="can-nam"
                data-tip="Thiên Tài"
              >
                Thiên Tài
              </td>
              <td
                id='vanxuong2'

                className="background-two td-can-nam"
                data-for="can-nam"
                data-tip="Văn Xương"
              >
                Văn Xương
              </td>
            </tr>
          </thead>

          <tbody>
            <tr>
              <th id="canyear" className="background-two">{canYear}</th>
              {canNam()}
            </tr>
          </tbody>
        </Table>
        <ReactTooltip
          id="can-nam"
          getContent={(dataTip) => (
            <div>
              <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
              <p style={{ color: "blue" }}>
                Xin vui lòng{" "}
                <span
                  className="click-span"
                  onClick={() => {
                    handleClick(dataTip);
                  }}
                >
                  Click
                </span>{" "}
                vào đây để tìm hiểu thêm
              </p>
            </div>
          )}
          effect="solid"
          delayHide={500}
          delayShow={500}
          delayUpdate={500}
          place="top"
          border={true}
          type="light"
        />
      </div>
      {/* can năm  */}

      {/* niên chi  */}
      <div id='hide-scrollbar' className="table_thansat">
        <Table className="table_thansat" responsive>
          <thead>
            <tr>
              <th className="th-custom th-nien-chi">
                <div className="right">
                  Thần <br /> Sát
                </div>
                <hr className="hr" />
                <div className="left">
                  Chi <br /> Năm
                </div>
              </th>
              <td
                id="longduc-"
                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Long Đức"
              >
                Long Đức
              </td>
              <td
                id="kimquy-"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Kim Quỹ"
              >
                Kim Quỹ
              </td>
              <td
                id="hongloan-"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Hồng Loan"
              >
                Hồng Loan
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Thiên Cẩu"
              >
                Thiên Cẩu
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Câu Giảo"
              >
                Câu Giảo
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Tuế Phá"
              >
                Tuế Phá
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Đại Hao"
              >
                Đại Hao
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Ngũ Quỷ"
              >
                Ngũ Quỷ
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Đào Hoa"
              >
                Đào Hoa
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Huyết Nhẫn"
              >
                Huyết Nhẫn
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Thiên Cương"
              >
                Thiên Cương
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Tuế Hình"
              >
                Tuế Hình
              </td>
             
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Tuế Sát"
              >
                Tuế Sát
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Tai Sát"
              >
                Tai Sát
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Bệnh Phù"
              >
                Bệnh Phù
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Phúc Đức"
              >
                Phúc Đức
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Bạch Hổ"
              >
                Bạch Hổ
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Quan Phù"
              >
                Quan Phù
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Thái Âm"
              >
                Thái Âm
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Thái Dương"
              >
                Thái Dương
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Thái Tuế"
              >
                Thái Tuế
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Cô Thần Quả Tú"
              >
                Cô Thần
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Cô Thần Quả Tú"
              >
                Quả Tú
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Dịch Mã"
              >
                Dịch Mã
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Tang Môn"
              >
                Tang Môn
              </td>
              <td
                id="nu"

                colspan="2"
                className="background td-ng-than"
                data-for="nien-chi"
                data-tip="Nguyên Thần"
              >
                Ng/Thần
                <td
                id="nu"

                  style={{ padding: "0", borderLeft: "0", borderBottom: "0" }}
                >
                  <div>+ Nam</div>
                  <div>- Nữ</div>
                </td>
                <td
                id="nu"

                  style={{ padding: "0", borderRight: "0", borderBottom: "0" }}
                >
                  <div>- Nam</div>
                  <div>+ Nữ</div>
                </td>
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Điếu Khách"
              >
                Điếu Khách
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Bản An"
              >
                Bản An
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Bạo Bại"
              >
                Bạo Bại
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Bát Tọa"
              >
                Bát Tọa
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Cách Giác"
              >
                Cách Giác
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Chỉ Bối"
              >
                Chỉ Bối
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Địa Giải"
              >
                Địa Giải
              </td>

              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Giải Thần"
              >
                Giải Thần
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Hàm Trì"
              >
                Hàm Trì
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Hối Khí"
              >
                Hối Khí
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Kiếm Phong"
              >
                Kiếm Phong
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Kiếp Sát"
              >
                Kiếp Sát
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Lan Can"
              >
                Lan Can
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Lục Ách"
              >
                Lục Ách
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Nguyệt Không"
              >
                Nguyệt Không
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Nguyệt Đức Hợp"
              >
                Nguyệt Đức Hợp
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Nguyệt Đức Quý Nhân"
              >
                Nguyệt Đức
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Nguyệt Phá"
              >
                Nguyệt Phá
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Phi Ma"
              >
                Phi Ma
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Phù Trầm"
              >
                Phù Trầm
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Phục Ngâm"
              >
                Phục Ngâm
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Phục Thi"
              >
                Phục Thi
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Phúc Tinh Quý Nhân"
              >
                Phúc Tinh Quý Nhân
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Quán Tố"
              >
                Quán Tố
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Quyển Thiệt"
              >
                Quyển Thiệt
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Tam Đài"
              >
                Tam Đài
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Tam Sát"
              >
                Tam Sát
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Thiên Ách"
              >
                Thiên Ách
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Thiên Đức Hợp"
              >
                Thiên Đức Hợp
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Thiên Hỷ"
              >
                Thiên Hỷ
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Thiên Khốc"
              >
                Thiên Khốc
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Thiên Không"
              >
                Thiên Không
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Thiên Sát"
              >
                Thiên Sát
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Thiên Xá"
              >
                Thiên Thứ
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Thiên Y"
              >
                Thiên Y
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Tiểu Hao"
              >
                Tiểu Hao
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Tử Phù"
              >
                Tử Phù
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Tử Vi"
              >
                Tử Vi
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Tức Thần"
              >
                Tức Thần
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Tướng Tinh"
              >
                Tướng Tinh
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Vong Thần"
              >
                Vong Thần
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Thiết Tảo"
              >
                Thiết Tảo
              </td>
            </tr>
          </thead>

          <tbody>
            <tr>
              <th id='canday' className="background">{chiYear}</th>
                  {chiNam()}
            </tr>
          </tbody>
        </Table>
        <ReactTooltip
          id="nien-chi"
          getContent={(dataTip) => (
            <div>
              <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
              <p style={{ color: "blue" }}>
                Xin vui lòng{" "}
                <span
                  className="click-span"
                  onClick={() => {
                    handleClick(dataTip);
                  }}
                >
                  Click
                </span>{" "}
                vào đây để tìm hiểu thêm
              </p>
            </div>
          )}
          effect="solid"
          delayHide={500}
          delayShow={500}
          delayUpdate={500}
          place="top"
          border={true}
          type="light"
        />
      </div>

      <div className="table_thansat display-to-print">
      <Table  className="table_thansat">
          <thead>
            <tr>
              <th className="th-custom th-nien-chi">
                <div className="right">
                  Thần <br /> Sát
                </div>
                <hr className="hr" />
                <div className="left">
                  Chi <br /> Năm
                </div>
              </th>
              <td
                id="longduc-"
                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Long Đức"
              >
                Long Đức
              </td>
              <td
                id="kimquy-"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Kim Quỹ"
              >
                Kim Quỹ
              </td>
              <td
                id="hongloan-"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Hồng Loan"
              >
                Hồng Loan
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Thiên Cẩu"
              >
                Thiên Cẩu
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Câu Giảo"
              >
                Câu Giảo
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Tuế Phá"
              >
                Tuế Phá
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Đại Hao"
              >
                Đại Hao
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Ngũ Quỷ"
              >
                Ngũ Quỷ
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Đào Hoa"
              >
                Đào Hoa
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Huyết Nhẫn"
              >
                Huyết Nhẫn
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Thiên Cương"
              >
                Thiên Cương
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Tuế Hình"
              >
                Tuế Hình
              </td>
             
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Tuế Sát"
              >
                Tuế Sát
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Tai Sát"
              >
                Tai Sát
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Bệnh Phù"
              >
                Bệnh Phù
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Phúc Đức"
              >
                Phúc Đức
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Bạch Hổ"
              >
                Bạch Hổ
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Quan Phù"
              >
                Quan Phù
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Thái Âm"
              >
                Thái Âm
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Thái Dương"
              >
                Thái Dương
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Thái Tuế"
              >
                Thái Tuế
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Cô Thần Quả Tú"
              >
                Cô Thần
              </td>
              <td
                id="nu"

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Cô Thần Quả Tú"
              >
                Quả Tú
              </td>

              
             
            </tr>
          </thead>

          <tbody>
            <tr>
              <th id='canday' className="background">{chiYear}</th>
              <td className="td-custom-2">{objectChiYear?.LongDuc}</td>
              <td className="td-custom-2">{objectChiYear?.KimQuy}</td>
              <td className="td-custom-2">{objectChiYear?.HongLoan}</td>
              <td className="td-custom-2">{objectChiYear?.ThienCau}</td>
              <td className="td-custom-2">{objectChiYear?.CauGiao}</td>
              <td className="td-custom-2">{objectChiYear?.TuePha}</td>
              <td className="td-custom-2">{objectChiYear?.DaiHao}</td>
              <td className="td-custom-2">{objectChiYear?.NguQuy}</td>
              <td className="td-custom-2">{objectChiYear?.DaoHoa}</td>
              <td className="td-custom-2">{objectChiYear?.HuyetNhan}</td>
              <td className="td-custom-2">{objectChiYear?.ThienCuong}</td>
              <td className="td-custom-2">{objectChiYear?.TueHinh}</td>
              <td className="td-custom-2">{objectChiYear?.TueSat}</td>
              <td className="td-custom-2">{objectChiYear?.TaiSat}</td>
              <td className="td-custom-2">{objectChiYear?.BenhPhu}</td>
              <td className="td-custom-2">{objectChiYear?.PhucDuc}</td>
              <td className="td-custom-2">{objectChiYear?.BachHo}</td>
              <td className="td-custom-2">{objectChiYear?.QuanPhu}</td>
              <td className="td-custom-2">{objectChiYear?.ThaiAm}</td>
              <td className="td-custom-2">{objectChiYear?.ThaiDuong}</td>
              <td className="td-custom-2">{objectChiYear?.ThaiTue}</td>
              <td className="td-custom-2">{objectChiYear?.CoThan}</td>
              <td className="td-custom-2">{objectChiYear?.QuaTu}</td>
             
            </tr>
          </tbody>
        </Table>
      </div>
     
      <div className="table_thansat display-to-print">
      <Table  className="table_thansat">
          <thead>
            <tr>
              <th className="th-custom th-nien-chi">
                <div className="right">
                  Thần <br /> Sát
                </div>
                <hr className="hr" />
                <div className="left">
                  Chi <br /> Năm
                </div>
              </th>
             

              
              <td
                id='nu'

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Dịch Mã"
              >
                Dịch Mã
              </td>
              <td
                id='nu'

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Tang Môn"
              >
                Tang Môn
              </td>
              <td
                id='nu'

                colspan="2"
                className="background td-ng-than"
                data-for="nien-chi"
                data-tip="Nguyên Thần"
              >
                Ng/Thần
                <td
                id='nu'

                  style={{ padding: "0", borderLeft: "0", borderBottom: "0" }}
                >
                  <div>+ Nam</div>
                  <div>- Nữ</div>
                </td>
                <td
                id='nu'

                  style={{ padding: "0", borderRight: "0", borderBottom: "0" }}
                >
                  <div>- Nam</div>
                  <div>+ Nữ</div>
                </td>
              </td>
              <td
                id='nu'

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Điếu Khách"
              >
                Điếu Khách
              </td>
              <td
                id='nu'

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Bản An"
              >
                Bản An
              </td>
              <td
                id='nu'

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Bạo Bại"
              >
                Bạo Bại
              </td>
              <td
                id='nu'

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Bát Tọa"
              >
                Bát Tọa
              </td>
              <td
                id='nu'

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Cách Giác"
              >
                Cách Giác
              </td>
              <td
                id='nu'

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Chỉ Bối"
              >
                Chỉ Bối
              </td>
              <td
                id='nu'

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Địa Giải"
              >
                Địa Giải
              </td>

              <td
                id='nu'

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Giải Thần"
              >
                Giải Thần
              </td>
              <td
                id='nu'

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Hàm Trì"
              >
                Hàm Trì
              </td>
              <td
                id='nu'

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Hối Khí"
              >
                Hối Khí
              </td>
              <td
                id='nu'

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Kiếm Phong"
              >
                Kiếm Phong
              </td>
              <td
                id='nu'

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Kiếp Sát"
              >
                Kiếp Sát
              </td>
              <td
                id='nu'

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Lan Can"
              >
                Lan Can
              </td>
              <td
                id='nu'

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Lục Ách"
              >
                Lục Ách
              </td>
              <td
                id='nu'

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Nguyệt Không"
              >
                Nguyệt Không
              </td>
              <td
                id='nu'

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Nguyệt Đức Hợp"
              >
                Nguyệt Đức Hợp
              </td>
              <td
                id='nu'

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Nguyệt Đức Quý Nhân"
              >
                Nguyệt Đức
              </td>
              <td
                id='nu'
                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Nguyệt Phá"
              >
                Nguyệt Phá
              </td>
              <td
                id='nu'

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Phi Ma"
              >
                Phi Ma
              </td>
              <td
                id='nu'

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Phù Trầm"
              >
                Phù Trầm
              </td>
             
            </tr>
          </thead>

          <tbody>
            <tr>
              <th id='canday' className="background">{chiYear}</th>
              <td className="td-custom-2">{objectChiYear?.DichMa}</td>
              <td className="td-custom-2">{objectChiYear?.TangMon}</td>
              <td className="td-custom-2">{ngThan?.DuongNam}</td>
              <td className="td-custom-2">{ngThan?.AmNam}</td>
              <td className="td-custom-2">{objectChiYear?.DieuKhach}</td>
              <td className="td-custom-2">{objectChiYear?.BanAn}</td>
              <td className="td-custom-2">{objectChiYear?.BaoBai}</td>
              <td className="td-custom-2">{objectChiYear?.BatToa}</td>
              <td className="td-custom-2">{objectChiYear?.CachGiao}</td>
              <td className="td-custom-2">{objectChiYear?.ChiBoi}</td>
              <td className="td-custom-2">{objectChiYear?.DiaGiai}</td>
              <td className="td-custom-2">{objectChiYear?.GiaiThan}</td>
              <td className="td-custom-2">{objectChiYear?.HamTri}</td>
              <td className="td-custom-2">{objectChiYear?.HoiKhi}</td>
              <td className="td-custom-2">{objectChiYear?.KiemPhong}</td>
              <td className="td-custom-2">{objectChiYear?.KiepSat}</td>
              <td className="td-custom-2">{objectChiYear?.LanCan}</td>
              <td className="td-custom-2">{objectChiYear?.LucAch}</td>
              <td className="td-custom-2">{objectChiYear?.NguyetKhong}</td>
              <td className="td-custom-2">{objectChiYear?.NguyetDucHop}</td>
              <td className="td-custom-2">{objectChiYear?.NguyetDuc}</td>
            </tr>
          </tbody>
        </Table>
      </div>
      
      <div className="table_thansat display-to-print">
      <Table  className="table_thansat">
          <thead>
            <tr>
              <th className="th-custom th-nien-chi">
                <div className="right">
                  Thần <br /> Sát
                </div>
                <hr className="hr" />
                <div className="left">
                  Chi <br /> Năm
                </div>
              </th>
             

              
             
              <td
                id='nu'

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Phục Ngâm"
              >
                Phục Ngâm
              </td>
              <td
                id='nu'

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Phục Thi"
              >
                Phục Thi
              </td>
              <td
                id='nu'

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Phúc Tinh Quý Nhân"
              >
                Phúc Tinh Quý Nhân
              </td>
              <td
                id='nu'

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Quán Tố"
              >
                Quán Tố
              </td>
              <td
                id='nu'
                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Quyển Thiệt"
              >
                Quyển Thiệt
              </td>
              <td
                id='nu'

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Tam Đài"
              >
                Tam Đài
              </td>
              <td
                id='nu'

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Tam Sát"
              >
                Tam Sát
              </td>
              <td
                id='nu'

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Thiên Ách"
              >
                Thiên Ách
              </td>
              <td
                id='nu'

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Thiên Đức Hợp Quý Nhân"
              >
                Thiên Đức Hợp
              </td>
              <td
                id='nu'

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Thiên Hỷ"
              >
                Thiên Hỷ
              </td>
              <td
                id='nu'

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Thiên Khốc"
              >
                Thiên Khốc
              </td>
              <td
                id='nu'

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Thiên Không"
              >
                Thiên Không
              </td>
              <td
                id='nu'

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Thiên Sát"
              >
                Thiên Sát
              </td>
              <td
                id='nu'

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Thiên Xá"
              >
                Thiên Thứ
              </td>
              <td
                id='nu'

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Thiên Y"
              >
                Thiên Y
              </td>
              <td
                id='nu'

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Tiểu Hao"
              >
                Tiểu Hao
              </td>
              <td
                id='nu'

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Tử Phù"
              >
                Tử Phù
              </td>
              <td
                id='nu'

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Tử Vi"
              >
                Tử Vi
              </td>
              <td
                id='nu'

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Tức Thần"
              >
                Tức Thần
              </td>
              <td
                id='nu'

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Tướng Tinh"
              >
                Tướng Tinh
              </td>
              <td
                id='nu'

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Vong Thần"
              >
                Vong Thần
              </td>
              <td
                id='nu'

                className="background td-nien-chi"
                data-for="nien-chi"
                data-tip="Thiết Tảo"
              >
                Thiết Tảo
              </td>
            </tr>
          </thead>

          <tbody>
            <tr>
              <th id='canday' className="background">{chiYear}</th>
             
              <td className="td-custom-2">{objectChiYear?.PhucNgam}</td>
              <td className="td-custom-2">{objectChiYear?.PhucThi}</td>
              <td className="td-custom-2">{objectChiYear?.PTQN}</td>
              <td className="td-custom-2">{objectChiYear?.QuanTo}</td>
              <td className="td-custom-2">{objectChiYear?.QuyenThiet}</td>
              <td className="td-custom-2">{objectChiYear?.TamDai}</td>
              <td className="td-custom-2">{objectChiYear?.TamSat}</td>
              <td className="td-custom-2">{objectChiYear?.ThienAch}</td>
              <td className="td-custom-2">{objectChiYear?.ThienDucHop}</td>
              <td className="td-custom-2">{objectChiYear?.ThienHy}</td>
              <td className="td-custom-2">{objectChiYear?.ThienKhoc}</td>
              <td className="td-custom-2">{objectChiYear?.ThienKhong}</td>
              <td className="td-custom-2">{objectChiYear?.ThienSat}</td>
              <td className="td-custom-2">{objectChiYear?.ThienThu}</td>
              <td className="td-custom-2">{objectChiYear?.ThienY}</td>
              <td className="td-custom-2">{objectChiYear?.TieuHao}</td>
              <td className="td-custom-2">{objectChiYear?.TuPhu}</td>
              <td className="td-custom-2">{objectChiYear?.Tuvi}</td>
              <td className="td-custom-2">{objectChiYear?.TucThan}</td>
              <td className="td-custom-2">{objectChiYear?.TuongTinh}</td>
              <td className="td-custom-2">{objectChiYear?.VongThan}</td>
              <td className="td-custom-2">{objectChiYear?.ThietTao}</td>
            </tr>
          </tbody>
        </Table>
      </div>

      {/* niên chi  */}

      {/* chi Giờ  */}
      <div className="table_thansat mt__100">
        <Table className="table_thansat">
          <thead>
            <tr>
              <th className="th-custom th-chi-gio">
                <div className="right">
                  Thần <br /> Sát
                </div>
                <hr className="hr" />
                <div className="left">
                  Chi <br /> Giờ
                </div>
              </th>
              <td
                id='ndh'
                className="background-two td-chi-gio"
                data-for="chi-gio"
                data-tip="Nguyệt Đức Hợp"
              >
                Nguyệt Đức Hợp
              </td>
              <td
                id='nguyetpha'

                className="background-two td-chi-gio"
                data-for="chi-gio"
                data-tip="Nguyệt Phá"
              >
                Nguyệt Phá
              </td>
              <td
                id='tdh'

                className="background-two td-chi-gio"
                data-for="chi-gio"
                data-tip="Thiên Đức Hợp"
              >
                Thiên Đức Hợp
              </td>
              <td
                id='ngocmon'

                className="background-two td-chi-gio"
                data-for="chi-gio"
                data-tip="Ngọc Môn"
              >
                Ngọc Môn
              </td>
            </tr>
          </thead>

          <tbody>
            <tr>
              <th id="chihour" className="background-two">{chiHour}</th>
                  {chiGio()}
            </tr>
          </tbody>
        </Table>
        <ReactTooltip
          id="chi-gio"
          getContent={(dataTip) => (
            <div>
              <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
              <p style={{ color: "blue" }}>
                Xin vui lòng{" "}
                <span
                  className="click-span"
                  onClick={() => {
                    handleClick(dataTip);
                  }}
                >
                  Click
                </span>{" "}
                vào đây để tìm hiểu thêm
              </p>
            </div>
          )}
          effect="solid"
          delayHide={500}
          delayShow={500}
          delayUpdate={500}
          place="top"
          border={true}
          type="light"
        />
      </div>
      {/* chi Giờ  */}

      <div id='cucdacbiet' className="than_sat_title background">
        <h3>NHỮNG CỤC ĐẶC BIỆT</h3>
      </div>

      {/* ngày sinh  */}

      <div className="table_thansat">
        <Table className="table_thansat">
          <thead>
            <tr>
              <th className="th-custom th-chi-gio">
                <div className="right">
                  Thần <br /> Sát
                </div>
                <hr className="hr-ngay-sinh" />
                <div className="left">
                  Ngày <br /> Sinh
                </div>
              </th>
              <td
                id='luctu'
                className="background-two td-ngay-sinh"
                data-for="ngay-sinh"
                data-tip="Lục Tú"
              >
                Lục Tú
              </td>
              <td
                id='damuu'

                className="background-two td-ngay-sinh"
                data-for="ngay-sinh"
                data-tip="Đa Mưu"
              >
                Đa Mưu
              </td>
              <td
                id='thaplinh'

                className="background-two td-ngay-sinh"
                data-for="ngay-sinh"
                data-tip="Thập Linh"
              >
                Thập Linh
              </td>
              <td
                id='hongdiem2'

                className="background-two td-ngay-sinh"
                data-for="ngay-sinh"
                data-tip="Hồng Diễm"
              >
                Hồng Diễm
              </td>
              <td
                id='amsaiduongthac'

                className="background-two td-ngay-sinh"
                data-for="ngay-sinh"
                data-tip="Âm Sai Dương Thác"
              >
                Âm Sai Dương Thác
              </td>
              <td
                id='khoicuong'

                className="background-two td-ngay-sinh"
                data-for="ngay-sinh"
                data-tip="Khôi Cương"
              >
                Khôi Cương
              </td>
              <td
                id='coloan'

                className="background-two td-ngay-sinh"
                data-for="ngay-sinh"
                data-tip="Cô Loan"
              >
                Cô Loan
              </td>
              <td
                id='nhatduc'

                className="background-two td-ngay-sinh"
                data-for="ngay-sinh"
                data-tip="Nhật Đức"
              >
                Nhật Đức
              </td>
              <td
                id='nhatquy'

                className="background-two td-ngay-sinh"
                data-for="ngay-sinh"
                data-tip="Nhật Quý"
              >
                Nhật Quý
              </td>
              <td
                id='tamdaiquy'

                className="background-two td-ngay-sinh"
                data-for="ngay-sinh"
                data-tip="Tam Đài Quý"
              >
                Tam Đài Quý
              </td>
              <td
                id='thapacdaibai'

                className="background-two td-ngay-sinh"
                data-for="ngay-sinh"
                data-tip="Thập Ác Đại Bại"
              >
                Thập Ác Đại Bại
              </td>
              <td
                id='thoaithan'

                className="background-two td-ngay-sinh"
                data-for="ngay-sinh"
                data-tip="Thoái Thần"
              >
                Thoái Thần
              </td>
              <td
                id='tiepthan'

                className="background-two td-ngay-sinh"
                data-for="ngay-sinh"
                data-tip="Tiến Thần"
              >
                Tiến Thần
              </td>
            </tr>
          </thead>

          <tbody>
            {/* dòng 1  */}

            <tr>

              <th id='ngaysinh' rowspan="12" className="background-two">
                Ngày Sinh
              </th>
              {ngaySinhRow1()}
             
            </tr>



            <tr>
             {ngaySinhRow2()}
            </tr>
            
          
            <tr>
                  {ngaySinhRow3()}
            </tr>
            <tr>
            {ngaySinhRow4()}
                  
            </tr>
            <tr>
            {ngaySinhRow5()}
             
            </tr>
            <tr>
            {ngaySinhRow6()}
             
            </tr>
            <tr>
            {ngaySinhRow7()}
             
            </tr>
            <tr>
            {ngaySinhRow8()}
              
            </tr>
            <tr>
            {ngaySinhRow9()}
             
            </tr>
            <tr>
            {ngaySinhRow10()}
             
            </tr>
            <tr>
            {ngaySinhRow11()}
             
            </tr>
            <tr>
            {ngaySinhRow12()}
              
            </tr>
            {/* dòng 1  */}
          </tbody>
        </Table>
        <ReactTooltip
          id="ngay-sinh"
          getContent={(dataTip) => (
            <div>
              <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
              <p style={{ color: "blue" }}>
                Xin vui lòng{" "}
                <span
                  className="click-span"
                  onClick={() => {
                    handleClick(dataTip);
                  }}
                >
                  Click
                </span>{" "}
                vào đây để tìm hiểu thêm
              </p>
            </div>
          )}
          effect="solid"
          delayHide={500}
          delayShow={500}
          delayUpdate={500}
          place="top"
          border={true}
          type="light"
        />
      </div>
      {/* ngày sinh  */}

      <div id='tu-tru' style={{ width: "1440px", display: "flex",alignItems: "center", margin: "0 auto" }}>
        <div style={{ width: "25%" }} className="table_thansat">
          <Table >
            <thead>
              <tr>
                <th className="th-chi-thang">
                  <div className="right">
                    Thần <br /> Sát
                  </div>
                  <hr
                    className="hr"
                    style={{ width: "136%", transform: "rotate(45deg)" }}
                  />
                  <div className="left">
                    Giới <br /> Tính
                  </div>
                </th>
                <td id='ngaysinh-2' className="background ">Ngày Sinh</td>
                <td id='tutru' className="background ">Tứ Trụ</td>
                <th className="th-chi-thang">
                  <div className="left">
                    Tứ <br /> Trụ
                  </div>
                  <hr className="hr-tu-tru-than-sat" />
                  <div className="right" style={{ padding: "0" }}>
                    Thần <br /> Sát
                  </div>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <th id='nam' rowSpan="2" className="background">
                  Nam
                </th>
                <td>Hợi</td>
                <td>Tuất</td>
                <th id='nu' data-for="tu-tru" data-tip="Thiên La" rowSpan="2" className="background" style={{cursor:'pointer'}}>
                  Thiên La
                </th>
              </tr>
              <tr>
                <td>Tuất</td>
                <td>Hợi</td>
              </tr>
              <tr>
                <th  id='nu' rowSpan="2" className="background">
                  Nữ
                </th>
                <td>Thìn</td>
                <td>Tỵ</td>
                <th id='nu' data-for="tu-tru" data-tip="Địa Võng" rowSpan="2" className="background" style={{cursor:'pointer'}}>
                  Địa Võng
                </th>
              </tr>
              <tr>
                <td>Tỵ</td>
                <td>Mùi</td>
              </tr>
            </tbody>
          </Table>
          <ReactTooltip
            id="tu-tru"
            getContent={(dataTip) => (
              <div>
                <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
                <p style={{ color: "blue" }}> 
                  Xin vui lòng <span className="click-span" onClick={() => {
                    handleClick(dataTip);
                  }}>Click</span> vào đây
                  để tìm hiểu thêm
                </p>
              </div>
            )}
            effect="solid"
            delayHide={500}
            delayShow={500}
            delayUpdate={500}
            place="top"
            border={true}
            type="light"
          />
        </div>
        {/* tứ trụ  */}
        <div style={{ width: "40%" }} className="table_thansat">
          <Table >
            <thead>
              <tr>
                <th style={{ minWidth: "100px" }} className="th-chi-thang">
                  <div className="right" style={{paddingRight: 0}}>
                    Can <br /> Ngày
                  </div>
                  <hr className="hr" />
                  <div className="left" style={{paddingLeft: 0}}>
                    Thần <br /> Sát
                  </div>
                </th>
                <td id='nu' className="background">Giáp</td>
                <td id='nu' className="background">Ất</td>
                <td id='nu' className="background">Bính</td>
                <td id='nu' className="background">Mậu</td>
                <td id='nu' className="background">Kỷ</td>
                <td id='nu' className="background">Canh</td>
                <td id='nu' className="background">Tân</td>
                <td id='nu' className="background">Nhâm</td>
              </tr>
            </thead>

            <tbody>
              <tr>
                <th id='nu' data-for="quy-hop" data-tip="Quý Hợp Quý Nhân" className="background" style={{cursor:'pointer'}}>Quý Hợp Quý Nhân</th>
                <td>Kỷ Sửu Kỷ Mùi</td>
                <td>Canh Tý Canh Thân</td>
                <td>Tân Hợi Tân Dậu</td>
                <td>Quý Sửu Quý Mùi</td>
                <td>Giáp Tý Giáp Thân</td>
                <td>Ất Sửu Ất Mùi</td>
                <td>Bính Ngọ Bính Dần</td>
                <td>Đinh Mão Đinh Tỵ</td>
              </tr>
            </tbody>
          </Table>
          <ReactTooltip
            id="quy-hop"
            getContent={(dataTip) => (
              <div>
                <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
                <p style={{ color: "blue" }}>
                  Xin vui lòng{" "}
                  <span
                    className="click-span"
                    onClick={() => {
                      handleClick(dataTip);
                    }}
                  >
                    Click
                  </span>{" "}
                  vào đây để tìm hiểu thêm
                </p>
              </div>
            )}
            effect="solid"
            delayHide={500}
            delayShow={500}
            delayUpdate={500}
            place="top"
            border={true}
            type="light"
          />
        </div>
        <div style={{ width: "25%" }} className="table_thansat">
          <Table >
            <thead>
              <tr>
                <th style={{ minWidth: "135px" }} className="th-chi-thang">
                  <div className="right" style={{ padding: "0" }}>
                    Tam Kỳ <br /> Quý Nhân
                  </div>
                  <hr
                    className="hr"
                    style={{ width: "125%", transform: "rotate(37deg)" }}
                  />
                  <div className="left">
                    Thiên <br /> Can
                  </div>
                </th>
                <td
                id='nu'
                  className="background "
                  data-tip="Thiên Thượng Tam Kỳ"
                  data-for="quy-nhan"
                  style={{cursor:'pointer'}}
                >
                  Thiên Thượng Tam Kỳ
                </td>
                <td
                id='nu'
                  className="background "
                  data-tip="Nhân Trung Tam Kỳ"
                  data-for="quy-nhan"
                  style={{cursor:'pointer'}}
                >
                  Nhân Trung Tam Kỳ
                </td>
                <td
                id='nu'
                  className="background "
                  data-tip="Địa Hạ Tam Kỳ"
                  data-for="quy-nhan"
                  style={{cursor:'pointer'}}
                >
                  Địa Hạ Tam Kỳ
                </td>
              </tr>
            </thead>

            <tbody>
              <tr>
                <th id='nu' className="background">Thiên Can Tứ Trụ</th>
                <td>Giáp Mậu Canh</td>
                <td>Nhâm Quý Tân</td>
                <td>Ất Bính Đinh</td>
              </tr>
            </tbody>
          </Table>
          <ReactTooltip
            id="quy-nhan"
            getContent={(dataTip) => (
              <div>
                <h5 style={{ textAlign: "center" }}>{dataTip}</h5>
                <p style={{ color: "blue" }}>
                  Xin vui lòng{" "}
                  <span
                    className="click-span"
                    onClick={() => {
                      handleClick(dataTip);
                    }}
                  >
                    Click
                  </span>{" "}
                  vào đây để tìm hiểu thêm
                </p>
              </div>
            )}
            effect="solid"
            delayHide={500}
            delayShow={500}
            delayUpdate={500}
            place="top"
            border={true}
            type="light"
          />
        </div>
      </div>
      <Modal open={isOpen} value={values} close={(value) => setOpen(value)} />
    </div>
  );
}

export default ThanSat;
