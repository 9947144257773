import { Table } from "reactstrap";

export const DiemVuongQuanSat = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Trẻ sinh vào các tháng : Bảy, Tám, Chín, Mười và Chạp âm Lịch mà nhằm
        các giờ Tý, Ngọ, Dần, Mão, thì sẽ gặp Diêm Vương Quan Sát. Trẻ gặp Diêm
        Vương Quan Sát sẽ nhạy cảm với vấn đề tâm linh, trẻ sẽ khó phát triển,
        về thể chất, tinh thần sẽ không ổn định và có thể khó nuôi. Cần tránh
        đưa trẻ đến những nơi tập hợp nhiều tâm linh: Đền miếu hay những nơi
        cúng kiếng nhiều...những nơi này sẽ khiến cho trẻ sợ hãi hay vô tình
        đụng chạm đến các năng lượng tâm linh kia.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tuy nhiên, nếu gặp ngày có Thiên Đức, Nguyệt Đức thì được giải trừ,
        không phải lo.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <h4>Sao Thiên Đức Qúy Nhân</h4>
        <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
          Người trong mệnh mà có sao Thiên Đức Qúy Nhân thì tâm địa lương thiện,
          cả đời luôn được giúp đỡ, bình ổn, dễ gặp Hung hóa Cát. Nếu mệnh nữ mà
          có sao Thiên Đức Qúy Nhân thì người sẽ có tính cách chín chắn, đạo
          đức, mẫn cáng, thông minh, có thể giúp chồng đạt được sự nghiệp to
          lớn, đại phú đại quý. Nếu Bát tự cũng tốt hoặc vận mệnh cũng tốt thì
          là mệnh cao quý.
        </p>
        <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
          Cách xác định đứa trẻ có sao Thiên Đức Qúy Nhân: Lấy chi tháng làm
          chủ, bất cứ tứ trụ trong năm, tháng, ngày, giờ nào gặp các Thiên Can
          sau đều là sao Thiên Đức Qúy Nhân. Chẳng hạn, Tháng Thân, chi tháng là
          Thân, trong lá số có Thiên Can Dần, Thiên Can Qúy thì chúng ta sẽ
          gặpThiên Đức Qúy Nhân.
        </p>
      </p>

      <p style={{ margin: "0 1rem 0 1rem" }}>
        <Table>
          <thead>
            <tr>
              <th
                style={{ border: "1px solid black" }}
                colSpan={13}
                className="th-chi-thang"
              >
                Xác định: Lấy Chi tháng làm chủ, bất cứ tứ trụ nào trong năm
                tháng ngày giờ gặp các Thiên Can sau đều gọi là Sao Thiên Đức
                Qúy Nhân.
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <th style={{ border: "1px solid black" }} className="background">
                Chi Tháng
              </th>
              <td style={{ border: "1px solid black" }}>Dần</td>
              <td style={{ border: "1px solid black" }}>Mão</td>
              <td style={{ border: "1px solid black" }}>Thìn</td>
              <td style={{ border: "1px solid black" }}>Tỵ</td>
              <td style={{ border: "1px solid black" }}>Ngọ</td>
              <td style={{ border: "1px solid black" }}>Mùi</td>
              <td style={{ border: "1px solid black" }}>Thân</td>
              <td style={{ border: "1px solid black" }}>Dậu</td>
              <td style={{ border: "1px solid black" }}>Tuất</td>
              <td style={{ border: "1px solid black" }}>Hợi</td>
              <td style={{ border: "1px solid black" }}>Tý</td>
              <td style={{ border: "1px solid black" }}>Sửu</td>
            </tr>

            <tr>
              <th style={{ border: "1px solid black" }} className="background">
                Thiên Can Địa Chi bất kỳ
              </th>
              <td style={{ border: "1px solid black" }}>Đinh</td>
              <td style={{ border: "1px solid black" }}>Thân</td>
              <td style={{ border: "1px solid black" }}>Nhâm</td>
              <td style={{ border: "1px solid black" }}>Tân</td>
              <td style={{ border: "1px solid black" }}>Hợi</td>
              <td style={{ border: "1px solid black" }}>Giáp</td>
              <td style={{ border: "1px solid black" }}>Quý</td>
              <td style={{ border: "1px solid black" }}>Dần</td>
              <td style={{ border: "1px solid black" }}>Bính</td>
              <td style={{ border: "1px solid black" }}>Ất</td>
              <td style={{ border: "1px solid black" }}>Tỵ</td>
              <td style={{ border: "1px solid black" }}>Canh</td>
            </tr>
          </tbody>
        </Table>
      </p>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <h4>Sao Nguyệt Đức Qúy Nhân</h4>
        <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
          Người có Nguyệt Đức Qúy Nhân trong mệnh có thể tránh được họa, gặp
          Hung hóa Cát. Nếu người ở vận may sẽ lại càng thêm may mắn. Nếu người
          ở vận xấu thì Nguyệt Đức Qúy Nhân sẽ giúp giảm bớt cái xấu. Nữ, nếu
          trong lá số có Nguyệt Đức Qúy Nhân sẽ cưới được người chồng tốt, có
          tài sản, có sự thương yêu.
        </p>
        <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
          Cách xác định có Nguyệt Đức Qúy Nhân: Lấy chi tháng làm chủ, xem trong
          năm, tháng, ngày, giờ tứ trụ có Thiên Can là sao Nguyệt Đức Qúy Nhân.
        </p>
      </p>
      <p style={{ margin: "0 1rem 0 1rem" }}>
        <Table>
          <thead>
            <tr>
              <th
                style={{ border: "1px solid black" }}
                colSpan={13}
                className="th-chi-thang"
              >
                Xác định: Lấy Chi tháng làm chủ, xem trong năm tháng ngày giờ có
                Thiên Can là Sao Nguyệt Đức Qúy Nhân hay không.
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <th style={{ border: "1px solid black" }} className="background">
                Chi Tháng
              </th>
              <td style={{ border: "1px solid black" }}>Dần</td>
              <td style={{ border: "1px solid black" }}>Ngọ</td>
              <td style={{ border: "1px solid black" }}>Tuất</td>
              <td style={{ border: "1px solid black" }}>Thân</td>
              <td style={{ border: "1px solid black" }}>Tý</td>
              <td style={{ border: "1px solid black" }}>Thìn</td>
              <td style={{ border: "1px solid black" }}>Tỵ</td>
              <td style={{ border: "1px solid black" }}>Dậu</td>
              <td style={{ border: "1px solid black" }}>Sửu</td>
              <td style={{ border: "1px solid black" }}>Hợi</td>
              <td style={{ border: "1px solid black" }}>Mão</td>
              <td style={{ border: "1px solid black" }}>Mùi</td>
            </tr>

            <tr>
              <th style={{ border: "1px solid black" }} className="background">
                Thiên Can bất kỳ
              </th>
              <td colSpan={3} style={{ border: "1px solid black" }}>
                Bính
              </td>
              <td colSpan={3} style={{ border: "1px solid black" }}>
                Nhâm
              </td>
              <td colSpan={3} style={{ border: "1px solid black" }}>
                Canh
              </td>
              <td colSpan={3} style={{ border: "1px solid black" }}>
                Giáp
              </td>
            </tr>
          </tbody>
        </Table>
      </p>
    </div>
  );
};

export const ThieuDieuQuanSat = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        “Thiên Điếu” có nghĩa là bị “treo lên trên trời”, khó nuôi. Những đứa bé
        sinh vào các năm Dần, Ngọ, Tuất, nhằm giờ Thìn, thì phạm Thiên Điếu Quan
        Sát, chủ về sự phiền não, bất an. Trẻ phạm Thiên Điếu Quan Sát hay lo
        lắng, sợ hãi, hay khóc thường xuyên, chúng có khả năng thị lực kém, mắt
        hay nhìn vào khoảng không, mơ hồ, sự tập trung kém.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Giải pháp hóa giải: Chúng ta có thể nhờ người thân, bạn bè, anh/ chị/ em
        là những người có lá số tốt hoặc sao Thiên Đức Qúy Nhân, vận mệnh tốt
        làm bố mẹ đỡ đầu thì có thể giúp bé có tuổi thơ tốt hơn.
      </p>
    </div>
  );
};

export const TuQuyQuanSat = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Trẻ sinh vào các tháng: Giêng, Hai, Ba, nhằm giờ Nhâm Thìn, thì phạm Tứ
        Qúy Quan Sát.Trẻ phạm Tứ Qúy Quan Sát dễ bị ốm, đặc biệt là lúc thời
        tiết chuyển mùa. Trẻ dưới một tuổi cần tránh tham dự đám cưới, đám tang,
        hỉ sự vì trẻ rất dễ nhiễm độc và ảnh hưởng của thời tiết dẫn đến đau ốm.
      </p>
    </div>
  );
};

export const HoaThuongQuanSat = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Trẻ sinh vào các năm Tý, Ngọ, Mão, Dậu lại nhằm các giờ Thìn, Tuất, Sửu,
        Mùi thì phạm giờ Quan Sát này. Cần tránh cho trẻ gặp các nghi thức cúng
        lễ, tránh đến chùa (tránh gặp ni cô,...) Nếu không sẽ gặp tai họa: Bị
        ốm, bị Ma Y Sát, trẻ sẽ cảm thấy bất ngờ, sợ hãi, thần kinh bất ổn.
      </p>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <h4>Có 5 loại Ma Y Sát:</h4>
        <b>Phi Sát:</b> Đứa trẻ bị loại Phi Sát, khi người thân dùng gậy tre gõ
        xuống sàn, gọi tên người quá cố trong khi đốt giấy tiền, vàng mã trong
        đám tang.
        <br />
        <b>Quan Mộc Sát:</b>Đứa trẻ bị loại Sát này khi đóng đinh quan tài gỗ
        trong đám tang.
        <br />
        <b>Liêm Sát:</b> Đứa trẻ bị Liêm Sát khi tang gia khóc lóc và thực hiện
        nghi thức “bước qua cầu Nại Hà”.
        <br />
        <b>Phi Xa Sát:</b> Đứa trẻ bị Phi Xa Sát khi tang gia động quan và đưa
        quan tài đến nghĩa trang.
        <br />
        <b>Tang Sát:</b> Đứa trẻ bị Tang Sát khi tang gia dùng dù để mời thủy
        thần, mua nước, mở cửa mả, tụng kinh cầu siêu.
      </p>
    </div>
  );
};

export const KimToaQuanSat = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Trẻ sinh vào các tháng, Giêng, Hai nhằm giờ Mão, giờ Thân, phạm Kim Tỏa
        Quan Sát, cần tránh những vật kim loại. Trẻ phạm Kim Tỏa quan sát cần
        tránh mang đồ sắt thép, tránh đeo vàng bạc trên người.
      </p>
    </div>
  );
};

export const LacTinhQuanSat = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Trẻ sinh vào các giờ: Mão, Tỵ, Ngọ, Thân, Tuất, phạm Quan Sát này nên
        tránh những nơi giếng sâu, hồ thẳm, sông rộng …vì dễ gặp tai họa về
        nước.
      </p>
    </div>
  );
};

export const ThamThuyQuanSat = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Trẻ sinh các tháng: Giêng, Hai, Ba nhằm giờ Dần, Thân, phạm giờ Quan Sát
        này. Trẻ phạm Thâm Thủy Quan Sát cần cẩn trọng khi chơi gần nơi nước sâu
        và nên phải coi chừng bệnh hoạn, đặc biệt là đậu mùa, nạn té xuống sông
        nước.
      </p>
    </div>
  );
};

export const NguQuyQuanSat = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Trẻ sinh vào các năm: Nhâm Tý, Canh Tý, Bính Tý, Mậu Dần nhằm giờ Dần,
        phạm Ngũ Qủy Quan Sát. Trẻ phạm Ngũ Qủy Quan Sát cần tránh dự tang lễ,
        nghĩa trang, tránh nhìn thấy quan tài,..vì trẻ nhạy cảm rất dễ khóc, sợ
        hãi, gặp ác mộng, sốt cao. Khi gặp những trường hợp này trẻ cần gặp bác
        sĩ ngay và nhớ đem trẻ đến gặp thầy tâm linh để tẩy trừ âm khí bám theo.
        Hoặc là, cho trẻ tắm nước lá bưởi, nước lá chanh, vỏ cây quế (tắm với
        nước pha loãng).
      </p>
    </div>
  );
};

export const BachNhatQuanSat = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Trẻ sinh tháng Giêng, nhằm giờ Dần, Tỵ phạm giờ Quan Sát này, phải được
        giữ ở trong nhà , trong vòng 100 ngày, đừng cho ra khỏi cửa vội. Tránh
        dời nhà, đưa bé từ nhà này sang nhà khác.
      </p>
    </div>
  );
};

export const BachHoQuanSat = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Trẻ sinh đủ ngũ hành trong Bát tự Kim - Mộc - Thủy - Hỏa - Thổ thì rất
        dễ gặp tai nạn. Phụ huynh cần chú ý chăm sóc bé kĩ hơn.
      </p>
    </div>
  );
};

export const ThangHoaQuanSat = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Bé sinh các năm Tý, Ngọ, Mão, Dậu nhằm giờ Ngọ, phạm Thang Hỏa Quan Sát.
        Trẻ phạm Quan Sát này dễ bị bỏng do hỏa hoạn hay do nước nóng. Cần tránh
        chơi gần lửa hoặc nước sôi, pháo hoa,...
      </p>
    </div>
  );
};

export const ThienCauQuanSat = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Trẻ sinh đủ ngũ hành trong Bát tự Kim - Mộc - Thủy - Hỏa - Thổ trong
        vòng tháng đầu tiên cố gắng tránh nghe tiếng chó sủa. Thiên Cẩu là sao
        chủ về những vật sắc nhọn, có móng vuốt sắc nhọn. Trẻ phạm Thiên Cẩu
        Quan Sát dưới 1 tháng tuổi không nên nghe tiếng chó sủa (tránh cho trẻ
        chơi gần chó, mèo, thú hoang,..) hay trẻ trên 1 tuổi không nên thấy dao,
        kéo, khoang, kim, những vật sắt nhọn, vì trẻ dễ bị chảy máu và để lại
        vết sẹo trên mặt.
      </p>
    </div>
  );
};

export const DucBonQuanSat = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Trẻ sinh vào ba tháng đầu năm: Giêng, Hai, Ba nhằm giờ Thân, phạm giờ
        Quan Sát này thì ngoài lần tắm đầu tiên lúc lọt lòng, phải kiêng cữ lâu
        lâu, rồi hãy tắm nữa. Vì trẻ phạm Dục Bồn quan sát rất dễ bị ngã trong
        bồn tắm, bỏng nước nóng, bị điện giật,..
      </p>
    </div>
  );
};

export const TuTruQuanSat = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Trẻ sinh vào các năm Tỵ, Hợi các tháng Giêng, Hai, nhằm các giờ Thìn, Tỵ
        phạm giờ này, không nên ngồi ở lan can cao, hoặc trên ghế cao, sợ té rớt
        nguy hiểm.
      </p>
    </div>
  );
};

export const LoiCongQuanSat = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Trẻ sinh vào giờ Dần, Ngọ, Thân, Dậu, Thìn, Mùi, Hợi đều phạm Quan Sát
        này. Trẻ nhỏ phải tránh nghe tiếng chuông trống inh tai, sấm sét dữ dội,
        cũng tránh nghe tiếng kêu thét, la lối.
      </p>
    </div>
  );
};

export const DoanMenhQuanSat = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Trẻ sinh vào các năm Tý, Thìn nhằm giờ Tỵ, thì phạm Đoản Mệnh Quan Sát.
        Trẻ có tuổi thọ ngắn, dễ mất sớm và thường hay giật mình, lên kinh, hoặc
        khóc suốt đêm không dứt, gọi là “Dạ đề”.
      </p>
    </div>
  );
};

export const DoanKieuQuanSat = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Trẻ sinh vào hai tháng Giêng, Hai nhằm hai giờ Dần, Mão thì phạm Đoạn
        Kiều Quan Sát. Mỗi khi đi qua cầu, chớ soi bóng nhìn xuống nước hoặc cúi
        vóc nước rửa tay.
      </p>
    </div>
  );
};

export const ThienNhatQuanSat = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Trẻ sinh vào Năm Ngọ, nhằm các giờ Dần, Thân, Tỵ, Hợi thì phạm Quan Sát
        này. Trẻ tránh leo trèo vì rất dễ té ngã và cũng hay bị chứng nôn sữa
        phải coi chừng cho đến khi quá ba tuổi, hơn 1.000 ngày sao cho khỏi bị
        nạn té rớt từ cao xuống thấp.
      </p>
    </div>
  );
};

export const TuongQuanQuanSat = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Trẻ sinh vào các giờ Thìn, Dậu, Tuất, Tý, Mùi thì phạmTướng Quân Tiễn
        Quan Sát. Phải tránh đi đến đình, miếu thờ các vị thần chiến đấu, quân
        sự: Thờ Quan Công, Triệu Công Minh, Hưng Đạo Đại Vương,...phải kiêng cữ,
        đừng nhìn cung tên hay súng đạn tròng vòng hai năm. Và về sau lớn lên,
        cũng cần đề phòng tên bay, đạn lạc nữa.
      </p>
    </div>
  );
};

export const KimXaQuanSat = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Trẻ sinh ra trong lá số đầy đủ Kim - Mộc – Thủy – Hỏa – Thổ thì dễ bị
        bệnh về da, đậu mùa...Trẻ sinh ra phạm Quan Sát này cần đi thăm khám Bác
        sĩ kịp thời nếu có các triệu chứng của các bệnh về da.
      </p>
    </div>
  );
};

export const DieuPhiQuanSat = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Trẻ sinh vào giờ Thìn, Tuất, Mùi, Sửu thì phạm Quan Sát này. Trẻ kỵ nghe
        hoặc nhìn thấy cảnh động vật bị giết mổ vì trẻ rất nhạy cảm và về lâu
        dài dẫn đến tiêu cực về tâm lý của bé.
      </p>
    </div>
  );
};

export const QuyMonQuanSat = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Người sinh vào năm Giáp Tý, Bính Tý, Mậu Tý thì phạm Quan Sát này, kỵ đi
        ra ngoài vào ban đêm và không nên đến các đền tâm linh, nhiều âm khí vì
        chúng dễ xúc phạm đến các vong linh và dễ bị tấn công.
      </p>
    </div>
  );
};

export const DaDeQuanSat = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Trẻ sinh vào các giờ Tý, Ngọ, Sửu, Mùi thì phạm giờ Dạ Đề Quan Sát, vào
        ban đêm thường lo lắng, không yên, dễ khóc hoặc là dễ dỗi hờn, dễ khóc
        vào ban đêm.
      </p>
    </div>
  );
};

export const ThuyHoaQuanSat = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Trẻ sinh vào ba tháng đầu năm: Giêng, Hai, Ba, nhằm các giờ Mùi, Tuất
        thì phạm giờ Quan Sát này rất nặng. Nó sẽ bị kinh sài, chốc lở nhiều,
        hoặc lên đậu mùa, trái rạ, mụn sởi …. Cũng cần coi chừng nạn sẩy chân
        chết đuối hoặc bị lửa bắt vào người chết cháy.
      </p>
    </div>
  );
};

export const HaTinhQuanSat = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Trẻ sinh vào tháng Giêng, Hai, Ba nhằm các giờ Tý, Dần thì phạm giờ này.
        Người thân của trẻ phải tránh, đứng để cho nó nghe tiếng mài dao, dao
        chặt, búa bổ, guom chém …vì trẻ có thể sẽ rụt rè và không dám gặp người
        lạ.
      </p>
    </div>
  );
};

export const CapCuocQuanSat = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Sinh con tháng Giêng, Hai, Ba nhằm các giờ Tý, giờ Hợi thì phạm Cấp Cước
        Quan Sát. Trẻ nhỏ phải tránh chạy nhảy, vì hay bị giật mình hoảng hốt,
        vấp té thành thương tật.
      </p>
    </div>
  );
};

export const LuanVeQuanSat = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <p style={{ textAlign: "left", padding: "2rem 1rem 0 1rem" }}>
        Tài liệu “Uyên Hạ Tiểu Bình” của Từ Đại Thăng, dựa trên các số ngũ hành:
        Thủy 1 – 6; Hỏa 2 – 7; Mộc 3 – 8; Kim 4 – 9; Thổ 5 – 10. Đối với những
        đứa trẻ sinh vào:
        <br />
        - Sinh ngày Giáp/Ất => năm 4, 9 tuổi gặp Quan Sát.
        <br />
        - Sinh ngày Bính/Đinh => năm 1, 6 tuổi gặp Quan Sát (dễ gặp tai nạn,
        bệnh tật, khó nuôi, trẻ dễ ương bướng..)
        <br />
        - Sinh ngày Mậu/Kỷ => năm 3, 8 tuổi gặp Quan Sát.
        <br />
        - Sinh ngày Canh/Tân => năm 2, 7 tuổi gặp Quan Sát.
        <br />- Sinh ngày Nhâm/Qúy => năm 5, 10 tuổi gặp Quan Sát.
      </p>{" "}
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <h4>GIỜ TƯỚNG QUÂN - DẠ ĐỀ – DIÊM VƯƠNG</h4>
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngoài giờ bàng giờ ra khoa tử vi còn cho rằng con trẻ mới sinh phạm giờ
        Tướng Quân tuy có xấu nhưng không nguy hiểm đến tính mạng mà chi có tật
        khóc đêm, khờ khạo. Người xưa nhất là các nhà bói toán cho rằng sở dĩ
        con trẻ hay khóc đêm và khóc dai là vì khi sinh phạm vào giờ Dạ Đề.
        Trường hợp cuối cùng cũng đáng quan tâm mà khoa tử vi đã nêu ra là vấn
        đề phạm giờ Diêm Vương. Giờ Diêm Vương là giờ khi mà đứa bé chào đời gặp
        phải. Thường các nhà bói toán tính theo mùa sinh tương ứng với giờ xấu
        Diêm Vương như mùa <b>Xuân</b> giờ Sửu và Mùi là phạm, mùa <b>Hạ</b> giờ
        Thìn giờ Tuất là phạm, mùa <b>Thu</b> giờ Tý giờ Ngọ là phạm, mùa{" "}
        <b>Đông</b> giờ Mão, giờ Dậu là phạm. Khi phạm nhằm giờ Diêm Vương, đứa
        bé lớn lên thường có triệu chứng lạ lùng hay co giật chân tay, thần kinh
        bất ổn và đôi khi nói những lời kỳ dị như bị ma quỷ ám ảnh.
      </p>
    </div>
  );
};
