import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Collapse,
  CardBody,
  Card,
} from "reactstrap";
import * as XLSX from "xlsx";
import { IoArrowDownCircleOutline } from "react-icons/io5";
import { LuanDungThan } from "../components/renderLuanDungThan";
import LoiMoDau from "../components/LuanDungThanTheoThang/LoiMoDau";
import logo from "../assets/logo.png";
import "../scss/thansat.scss";

function PageDungThanDieuHau() {
  const [show, setShow] = useState(true);
  const [open, setOpen] = useState(false);
  const [alerError, setAlerError] = useState(false);

  const [title, setTitle] = useState("");
  const [text, setText] = useState(LoiMoDau);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenAt, setIsOpenAt] = useState(false);
  const [isOpenBinh, setIsOpenBinh] = useState(false);
  const [isOpenDinh, setIsOpenDinh] = useState(false);
  const [isOpenMau, setIsOpenMau] = useState(false);
  const [isOpenKy, setIsOpenKy] = useState(false);
  const [isOpenCanh, setIsOpenCanh] = useState(false);
  const [isOpenTan, setIsOpenTan] = useState(false);
  const [isOpenNham, setIsOpenNham] = useState(false);
  const [isOpenQuy, setIsOpenQuy] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  const toggleAt = () => setIsOpenAt(!isOpenAt);
  const toggleBinh = () => setIsOpenBinh(!isOpenBinh);
  const toggleDinh = () => setIsOpenDinh(!isOpenDinh);
  const toggleMau = () => setIsOpenMau(!isOpenMau);
  const toggleKy = () => setIsOpenKy(!isOpenKy);
  const toggleCanh = () => setIsOpenCanh(!isOpenCanh);
  const toggleTan = () => setIsOpenTan(!isOpenTan);
  const toggleNham = () => setIsOpenNham(!isOpenNham);
  const toggleQuy = () => setIsOpenQuy(!isOpenQuy);

  const handleClick = (e) => {
    e?.currentTarget?.classList.add("active");

    const p = document.getElementsByClassName("getValueP");

    for (const item of p) {
      if (item.getAttribute("value") !== e.currentTarget.getAttribute("value"))
        item?.classList?.remove("active");
    }

    let value = e.currentTarget.getAttribute("value");

    const content = LuanDungThan(value);

    setText(content);
  };

  const setAlerErrors = () => {
    setAlerError(true);
  };

  const noLogin = true;

  useEffect(() => {}, []);

  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      {noLogin ? (
        <>
          <div className="than_sat_title" style={{ marginTop: "2rem" }}>
            <h3>LUẬN DỤNG THẦN THEO THÁNG SINH</h3>
          </div>
          <div
            style={{
              width: "1440px",
              margin: "0 auto",
              display: "flex",
              minHeight: "512px",
            }}
          >
            <Card style={{ width: "15%" }}>
              <CardBody>
                <p
                  className="getValueP"
                  style={{
                    fontWeight: "bold",
                    cursor: "pointer",
                    marginBottom: "0.5rem",
                  }}
                  onClick={handleClick}
                  value="Lời mở đầu"
                >
                  Lời mở đầu
                </p>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span className="chiday" onClick={toggle}>
                    Ngày Sinh Giáp
                  </span>
                  <IoArrowDownCircleOutline
                    size={"20px"}
                    style={{ cursor: "pointer" }}
                    onClick={toggle}
                  />
                </div>
                <div>
                  <Collapse isOpen={isOpen}>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Giáp Tháng Tý"
                    >
                      Tháng Tý
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Giáp Tháng Sửu"
                    >
                      Tháng Sửu
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Giáp Tháng Dần"
                    >
                      Tháng Dần
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Giáp Tháng Mão"
                    >
                      Tháng Mão
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Giáp Tháng Thìn"
                    >
                      Tháng Thìn
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Giáp Tháng Tỵ"
                    >
                      Tháng Tỵ
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Giáp Tháng Ngọ"
                    >
                      Tháng Ngọ
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Giáp Tháng Mùi"
                    >
                      Tháng Mùi
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Giáp Tháng Thân"
                    >
                      Tháng Thân
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Giáp Tháng Dậu"
                    >
                      Tháng Dậu
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Giáp Tháng Tuất"
                    >
                      Tháng Tuất
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Giáp Tháng Hợi"
                    >
                      Tháng Hợi
                    </p>
                  </Collapse>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span className="chiday" onClick={toggleAt}>
                    Ngày Sinh Ất
                  </span>
                  <IoArrowDownCircleOutline
                    size={"20px"}
                    style={{ cursor: "pointer" }}
                    onClick={toggleAt}
                  />
                </div>
                <div>
                  <Collapse isOpen={isOpenAt}>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Ất Tháng Tý"
                    >
                      Tháng Tý
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Ất Tháng Sửu"
                    >
                      Tháng Sửu
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Ất Tháng Dần"
                    >
                      Tháng Dần
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Ất Tháng Mão"
                    >
                      Tháng Mão
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Ất Tháng Thìn"
                    >
                      Tháng Thìn
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Ất Tháng Tỵ"
                    >
                      Tháng Tỵ
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Ất Tháng Ngọ"
                    >
                      Tháng Ngọ
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Ất Tháng Mùi"
                    >
                      Tháng Mùi
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Ất Tháng Thân"
                    >
                      Tháng Thân
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Ất Tháng Dậu"
                    >
                      Tháng Dậu
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Ất Tháng Tuất"
                    >
                      Tháng Tuất
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Ất Tháng Hợi"
                    >
                      Tháng Hợi
                    </p>
                  </Collapse>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span className="chiday" onClick={toggleBinh}>
                    Ngày Sinh Bính
                  </span>
                  <IoArrowDownCircleOutline
                    size={"20px"}
                    style={{ cursor: "pointer" }}
                    onClick={toggleBinh}
                  />
                </div>
                <div>
                  <Collapse isOpen={isOpenBinh}>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Bính Tháng Tý"
                    >
                      Tháng Tý
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Bính Tháng Sửu"
                    >
                      Tháng Sửu
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Bính Tháng Dần"
                    >
                      Tháng Dần
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Bính Tháng Mão"
                    >
                      Tháng Mão
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Bính Tháng Thìn"
                    >
                      Tháng Thìn
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Bính Tháng Tỵ"
                    >
                      Tháng Tỵ
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Bính Tháng Ngọ"
                    >
                      Tháng Ngọ
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Bính Tháng Mùi"
                    >
                      Tháng Mùi
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Bính Tháng Thân"
                    >
                      Tháng Thân
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Bính Tháng Dậu"
                    >
                      Tháng Dậu
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Bính Tháng Tuất"
                    >
                      Tháng Tuất
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Bính Tháng Hợi"
                    >
                      Tháng Hợi
                    </p>
                  </Collapse>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span className="chiday" onClick={toggleDinh}>
                    Ngày Sinh Đinh
                  </span>
                  <IoArrowDownCircleOutline
                    size={"20px"}
                    style={{ cursor: "pointer" }}
                    onClick={toggleDinh}
                  />
                </div>
                <div>
                  <Collapse isOpen={isOpenDinh}>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Đinh Tháng Tý"
                    >
                      Tháng Tý
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Đinh Tháng Sửu"
                    >
                      Tháng Sửu
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Đinh Tháng Dần"
                    >
                      Tháng Dần
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Đinh Tháng Mão"
                    >
                      Tháng Mão
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Đinh Tháng Thìn"
                    >
                      Tháng Thìn
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Đinh Tháng Tỵ"
                    >
                      Tháng Tỵ
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Đinh Tháng Ngọ"
                    >
                      Tháng Ngọ
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Đinh Tháng Mùi"
                    >
                      Tháng Mùi
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Đinh Tháng Thân"
                    >
                      Tháng Thân
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Đinh Tháng Dậu"
                    >
                      Tháng Dậu
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Đinh Tháng Tuất"
                    >
                      Tháng Tuất
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Đinh Tháng Hợi"
                    >
                      Tháng Hợi
                    </p>
                  </Collapse>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span className="chiday" onClick={toggleMau}>
                    Ngày Sinh Mậu
                  </span>
                  <IoArrowDownCircleOutline
                    size={"20px"}
                    style={{ cursor: "pointer" }}
                    onClick={toggleMau}
                  />
                </div>
                <div>
                  <Collapse isOpen={isOpenMau}>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Mậu Tháng Tý"
                    >
                      Tháng Tý
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Mậu Tháng Sửu"
                    >
                      Tháng Sửu
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Mậu Tháng Dần"
                    >
                      Tháng Dần
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Mậu Tháng Mão"
                    >
                      Tháng Mão
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Mậu Tháng Thìn"
                    >
                      Tháng Thìn
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Mậu Tháng Tỵ"
                    >
                      Tháng Tỵ
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Mậu Tháng Ngọ"
                    >
                      Tháng Ngọ
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Mậu Tháng Mùi"
                    >
                      Tháng Mùi
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Mậu Tháng Thân"
                    >
                      Tháng Thân
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Mậu Tháng Dậu"
                    >
                      Tháng Dậu
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Mậu Tháng Tuất"
                    >
                      Tháng Tuất
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Mậu Tháng Hợi"
                    >
                      Tháng Hợi
                    </p>
                  </Collapse>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span className="chiday" onClick={toggleKy}>
                    Ngày Sinh Kỷ
                  </span>
                  <IoArrowDownCircleOutline
                    size={"20px"}
                    style={{ cursor: "pointer" }}
                    onClick={toggleKy}
                  />
                </div>
                <div>
                  <Collapse isOpen={isOpenKy}>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Kỷ Tháng Tý"
                    >
                      Tháng Tý
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Kỷ Tháng Sửu"
                    >
                      Tháng Sửu
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Kỷ Tháng Dần"
                    >
                      Tháng Dần
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Kỷ Tháng Mão"
                    >
                      Tháng Mão
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Kỷ Tháng Thìn"
                    >
                      Tháng Thìn
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Kỷ Tháng Tỵ"
                    >
                      Tháng Tỵ
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Kỷ Tháng Ngọ"
                    >
                      Tháng Ngọ
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Kỷ Tháng Mùi"
                    >
                      Tháng Mùi
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Kỷ Tháng Thân"
                    >
                      Tháng Thân
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Kỷ Tháng Dậu"
                    >
                      Tháng Dậu
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Kỷ Tháng Tuất"
                    >
                      Tháng Tuất
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Kỷ Tháng Hợi"
                    >
                      Tháng Hợi
                    </p>
                  </Collapse>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span className="chiday" onClick={toggleCanh}>
                    Ngày Sinh Canh
                  </span>
                  <IoArrowDownCircleOutline
                    size={"20px"}
                    style={{ cursor: "pointer" }}
                    onClick={toggleCanh}
                  />
                </div>
                <div>
                  <Collapse isOpen={isOpenCanh}>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Canh Tháng Tý"
                    >
                      Tháng Tý
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Canh Tháng Sửu"
                    >
                      Tháng Sửu
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Canh Tháng Dần"
                    >
                      Tháng Dần
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Canh Tháng Mão"
                    >
                      Tháng Mão
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Canh Tháng Thìn"
                    >
                      Tháng Thìn
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Canh Tháng Tỵ"
                    >
                      Tháng Tỵ
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Canh Tháng Ngọ"
                    >
                      Tháng Ngọ
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Canh Tháng Mùi"
                    >
                      Tháng Mùi
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Canh Tháng Thân"
                    >
                      Tháng Thân
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Canh Tháng Dậu"
                    >
                      Tháng Dậu
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Canh Tháng Tuất"
                    >
                      Tháng Tuất
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Canh Tháng Hợi"
                    >
                      Tháng Hợi
                    </p>
                  </Collapse>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span className="chiday" onClick={toggleTan}>
                    Ngày Sinh Tân
                  </span>
                  <IoArrowDownCircleOutline
                    size={"20px"}
                    style={{ cursor: "pointer" }}
                    onClick={toggleTan}
                  />
                </div>
                <div>
                  <Collapse isOpen={isOpenTan}>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Tân Tháng Tý"
                    >
                      Tháng Tý
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Tân Tháng Sửu"
                    >
                      Tháng Sửu
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Tân Tháng Dần"
                    >
                      Tháng Dần
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Tân Tháng Mão"
                    >
                      Tháng Mão
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Tân Tháng Thìn"
                    >
                      Tháng Thìn
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Tân Tháng Tỵ"
                    >
                      Tháng Tỵ
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Tân Tháng Ngọ"
                    >
                      Tháng Ngọ
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Tân Tháng Mùi"
                    >
                      Tháng Mùi
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Tân Tháng Thân"
                    >
                      Tháng Thân
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Tân Tháng Dậu"
                    >
                      Tháng Dậu
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Tân Tháng Tuất"
                    >
                      Tháng Tuất
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Tân Tháng Hợi"
                    >
                      Tháng Hợi
                    </p>
                  </Collapse>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span className="chiday" onClick={toggleNham}>
                    Ngày Sinh Nhâm
                  </span>
                  <IoArrowDownCircleOutline
                    size={"20px"}
                    style={{ cursor: "pointer" }}
                    onClick={toggleNham}
                  />
                </div>
                <div>
                  <Collapse isOpen={isOpenNham}>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Nhâm Tháng Tý"
                    >
                      Tháng Tý
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Nhâm Tháng Sửu"
                    >
                      Tháng Sửu
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Nhâm Tháng Dần"
                    >
                      Tháng Dần
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Nhâm Tháng Mão"
                    >
                      Tháng Mão
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Nhâm Tháng Thìn"
                    >
                      Tháng Thìn
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Nhâm Tháng Tỵ"
                    >
                      Tháng Tỵ
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Nhâm Tháng Ngọ"
                    >
                      Tháng Ngọ
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Nhâm Tháng Mùi"
                    >
                      Tháng Mùi
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Nhâm Tháng Thân"
                    >
                      Tháng Thân
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Nhâm Tháng Dậu"
                    >
                      Tháng Dậu
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Nhâm Tháng Tuất"
                    >
                      Tháng Tuất
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Nhâm Tháng Hợi"
                    >
                      Tháng Hợi
                    </p>
                  </Collapse>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span className="chiday" onClick={toggleQuy}>
                    Ngày Sinh Quý
                  </span>
                  <IoArrowDownCircleOutline
                    size={"20px"}
                    style={{ cursor: "pointer" }}
                    onClick={toggleQuy}
                  />
                </div>
                <div>
                  <Collapse isOpen={isOpenQuy}>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Quý Tháng Tý"
                    >
                      Tháng Tý
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Quý Tháng Sửu"
                    >
                      Tháng Sửu
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Quý Tháng Dần"
                    >
                      Tháng Dần
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Quý Tháng Mão"
                    >
                      Tháng Mão
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Quý Tháng Thìn"
                    >
                      Tháng Thìn
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Quý Tháng Tỵ"
                    >
                      Tháng Tỵ
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Quý Tháng Ngọ"
                    >
                      Tháng Ngọ
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Quý Tháng Mùi"
                    >
                      Tháng Mùi
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Quý Tháng Thân"
                    >
                      Tháng Thân
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Quý Tháng Dậu"
                    >
                      Tháng Dậu
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Quý Tháng Tuất"
                    >
                      Tháng Tuất
                    </p>
                    <p
                      className="getValueP"
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={handleClick}
                      value="Quý Tháng Hợi"
                    >
                      Tháng Hợi
                    </p>
                  </Collapse>
                </div>
              </CardBody>
            </Card>
            <div style={{ width: "90%", position: "relative" }}>
              <div
                className={
                  title
                    ? "watermark-hoagiap-page t-16"
                    : "watermark-hoagiap-page"
                }
              >
                <img src={logo} />
              </div>

              <h3
                style={{
                  textTransform: "uppercase",
                  textAlign: "center",
                  marginTop: "2rem",
                }}
              >
                {title}
              </h3>
              <span
                style={{
                  overflowY: "scroll",
                  maxHeight: 600,
                  display: "block",
                }}
                onCopy={(e) => e.preventDefault()}
                onCut={(e) => e.preventDefault()}
                onPaste={(e) => e.preventDefault()}
              >
                {text}
              </span>
            </div>
          </div>
        </>
      ) : (
        <div
          className="hide-to-print"
          style={{
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold",
            minHeight: "500px",
            paddingTop: "125px",
          }}
        ></div>
      )}
      <Modal close={(value) => setOpen(value)} open={open} />
    </div>
  );
}

export default PageDungThanDieuHau;
