export const DinhMao = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Đinh Mão tham công tiếc việc, ham làm, có khả năng tạo
        dựng sự nghiệp tốt trong bất kể ngành nghề gì, hay đa nghi, không tin
        tưởng người khác dễ dàng, luôn giấu cảm xúc. Người cầu toàn, luôn đặt
        yêu cầu cao đối với bản thân và người khác, quan hệ tình cảm giao tiếp
        kém, thường làm mất lòng người khác nhưng không có ý xấu, dễ quan hệ
        tình dục trước hôn nhân. Nếu có Thực Thần lộ can, người này dễ bệnh do
        chế độ ăn uống hoặc bị ngộ độc thực phẩm. Dễ bị sức khoẻ xấu và thể lực
        kém do làm việc quá độ. Nữ giới thì ít chung thuỷ.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đây là hình tượng đèn cầy. Vào thời gian tăm tối, mọi người đều cần đến
        một ngọn nến. Đây là ngọn đèn soi đường chỉ lối cho người khác. Họ hi
        sinh bản thân mình, để cho người khác có thể nhìn ra đường đi. Nếu như
        bạn tìm kiếm một người thầy giúp cho mình thấu hiểu mọi lẽ, đây là trụ
        ngày mà bạn cần tìm kiếm.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Mão cũng tựa như những tàn tro từ một cây nến hay cây nhang.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có khả năng ăn nói và độc lập. Họ có tài sử dụng ngôn ngữ và giỏi
        viết lách cũng như thuyết trình. Họ có khả năng diễn đạt ý tưởng một
        cách rõ ràng và mạch lạc. Vì họ khá sáng tạo và suy nghĩ cặn kẽ, tâm trí
        họ luôn đầy những ý tưởng phát triển và họ thường được xem là đi trước
        thời đại của mình. Người Đinh Mão có bản tính thu thập kiến thức từ
        nhiều nguồn và họ rất thích thú làm điều này. Thường họ có một trí nhớ
        hình ảnh cũng như khả năng bẩm sinh hấp thu và xử lý thông tin. Điều này
        khiến cho họ thường được gắn mác tri thức.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sáng tạo và giỏi thích nghi, người Đinh Mão có khả năng kiến thức tốt
        giúp cho họ trở thành tiên phong trong lĩnh vực của mình. Họ có động lực
        lớn tham 33 gia vào các dự án mới và họ có đầu óc phân tích cao cũng như
        khả năng đa nhiệm trong nhiều sự nghiệp khác nhau. Họ có tài trong việc
        phát hiện cơ hội mới và có thể phát hiện những xu hướng kinh tế ở giai
        đoạn đầu và với khả năng tư duy và hành động nhanh chóng để biến những
        cơ hội thành kết quả thành công và lợi nhuận.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Mão khá thân thiện. Họ có khả năng kết nối và xây dựng mối
        quan hệ mới và mở ra nhiều cánh cửa. Cũng như một que diêm có thể khởi
        đầu một đám cháy mà không mất đi bản chất của mình, người Đinh Mão
        thường có khả năng gợi cảm hứng cho những người xung quanh và sử dụng
        đội nhóm để thúc đẩy các dự án và đầu tư.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khả năng trí óc của người Đinh Mão đồng nghĩa với việc họ thường xuyên
        cảm thấy buồn chán với những nhiệm vụ lặp đi lặp lại và họ thường cần
        được kích thích về mặt tâm trí để có thể toàn tâm toàn ý. Họ rất cần
        được gợi cảm hứng nếu như họ có thể áp dụng cho bản thân mình và điều
        này có thể dẫn đến họ nắm một số dự án khác nhau vì sự quan tâm chú ý
        của họ là luôn để tâm đến những ý tưởng mới. Một khi họ tập trung chú ý
        thì không có giới hạn nào mà họ không thể đạt được.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Với tất cả những tính cách tích cực, người Đinh Mão là người cầu toàn và
        điều này có thể dẫn đến việc họ tự nghi ngờ năng lực bản thân và kéo lùi
        sự phát triển của họ. Họ có những yêu cầu rất cao đối với bản thân và
        người khác, người Đinh Mão có thể đầy nghi ngờ và mâu thuẫn nội tại. Họ
        thiếu tự tin và phải cố gắng để nhận ra được sức mạnh và tài năng cũng
        như sự bất an của mình có thể ảnh hưởng đến mối quan hệ tình cảm với
        người khác khiến cho họ đa nghi và đôi khi là rút lui.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Mão rất cần tình yêu và sự hỗ trợ từ người khác thì mới có
        thể cảm thấy an tâm và có can đảm để khám phá năng lực tiềm tàng bên
        trong mình. Họ cần dẹp bỏ tất cả những suy nghĩ tiêu cực và phải luôn
        tích cực. Họ sẽ hưởng lợi từ việc có một người thầy hay người đỡ đầu
        nuôi dưỡng và hướng dẫn họ tìm được con đường đúng đắn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sự giác ngộ về mặt tâm linh sẽ gíup người Đinh Mão tìm được hạnh phúc
        trong đời và kiểm soát được điểm yếu của họ. Nếu họ có thể đạt được
        trạng trái 34 bình an bên trong và hình thành niềm tin vào bản thân cũng
        như trực giác, họ sẽ dễ dàng biến giấc mơ lý tưởng của mình thành hiện
        thực vững chắc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Mão thường sẽ trở nên rất giàu có và họ biết cách thưởng thức
        những thứ tinh tế trong đời. Họ đặc biệt thích thức ăn cả về mùi vị và
        chất. Họ thích những vị lạ, mới đặc biệt và luôn muốn thử thức ăn của
        nhiều vùng miền khác nhau trên thế giới. Họ có động lực để tìm kiếm cái
        mới và muốn những trải nghiệm độc đáo cũng như muốn biết vị trí của tất
        cả các nhà hàng tốt nhất trên thế giới.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Phụ nữ ngày sinh Đinh Mão sẽ là những người nội trợ xuất sắc và tình yêu
        đối với ẩm thực và nấu nướng khiến họ thích công việc nội trợ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Mão sẽ hưởng lợi từ việc giành thời gian để chăm sóc bản thân
        và mức độ ổn định tương lai. Họ dễ có sức khoẻ kém và tốt nhất là họ nên
        giảm ăn uống và tập luyện thể thao đều đặn. Họ sẽ có lợi nếu lên kế
        hoạch tài chính để có tương lai về già giàu có.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Mão sáng tạo, thân thiện, có khả năng trí óc nên có thể đạt
        được bất cứ gì nếu họ chú tâm. Nếu có được sự ủng hộ và khuyến khích của
        người mình yêu và lòng tin vào tài năng của họ, người Đinh Mão cảm thấy
        năng lực của họ không bị giới hạn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những Đặc Tính Chung Của Đinh Mão: suy nghĩ thấu đáo, từ bi, phân tích,
        trí nhớ tốt, độc lập, sáng tạo, tâm linh.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Mão có động lực bên trong để tạo nên sự nghiệp thành công.
        Mặc dù có khả năng thành công trong vô số công việc khác nhau, việc họ
        không thích bị sai khiến làm cho họ phù hợp hơn với khởi nghiệp, làm chủ
        doanh nghiệp hay bất cứ việc nào cho họ sự tự do.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người này nằm trong số những người nhận ra cơ hội đầu tiên. Họ
        luôn nhìn nhận mọi thứ thực tế, họ có khả năng tổ chức và điều hành, họ
        có khả năng biến mọi cơ hội thành thành công. Người Đinh Mão có khả năng
        suy nghĩ lớn và có thể biến mọi dự định thành hiện thực.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có khả năng thành công cao khi tham gia vào quản lý quỹ hay ngành
        ngân hàng. Khả năng thấu hiểu các con số, nhận ra xu hướng trong kinh
        doanh và kinh tế giúp họ có lợi thế hơn người.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Vì bản tính thông cảm và kỹ năng ngoại giao, họ có thể làm trong những
        lĩnh vực gần gũi với người khác. Những lĩnh vực này sẽ tận dụng sức mạnh
        về giao tiếp và làm việc nhóm giúp cho họ có sự nghiệp khởi đầu mạnh mẽ.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Công Việc:</h4>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Mão có thể thành công trong nhiều lĩnh vực. Vì bản tính sáng
        tạo và đa tài, người Đinh Mão có thể làm việc tốt trong ngành âm nhạc,
        nghệ thuật, viết lách, diễn xuất và đạo diễn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bởi vì khả năng giao tiếp xuất sắc, họ có thể tham gia vào bán hàng,
        quảng cáo và truyền thông. Họ cũng có thể sử dụng kỹ năng giao tiếp và
        giải quyết khó khăn cho những sự nghiệp trong ngành kinh doanh, ngân
        hàng, quỹ, triết học hay giáo dục với tư cách là giáo viên, giảng viên
        hay doanh nhân thành công.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có kiến thức và khả năng bẩm sinh để trở thành quản lý hay doanh nhân
        xuất sắc. Đồng thời điều này cũng hợp với khao khát thành công và thành
        tích của họ. Họ sẽ có sự nghiệp tốt như môi giới, giám đốc quỹ và ngân
        hàng. Điều này bởi vì khả năng suy nghĩ phân tích giúp họ hiểu thị
        trường và xu hướng kinh tế tốt hơn người khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Mão quan tâm nhiều đến kỹ thuật công nghệ sẽ có lợi nếu tham
        gia ngành nghề máy tính, tài chính hay kỹ thuật. Nếu họ muốn làm tốt
        nhất, họ có thể kết hợp giữa tài năng giải trí và tài năng kỹ thuật bằng
        cách tham gia lĩnh vực trò chơi máy tính.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những Đặc Tính Trong Sự Nghiệp: tham vọng, kỹ năng giao tiếp tốt, giỏi
        xử lý vấn đề, thực dụng, ngoại giao, nổi loạn, lên kế hoạch kém, kiểm
        soát, ngoan cố.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Hôn Nhân - Gia Đình - Mối Quan Hệ:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi Đinh Mão yêu, họ đầy trách nhiệm, dâng hiến và hi sinh vì người mình
        yêu. Người bạn đời sẽ trân trọng họ vì những đặc tính này, nhưng đôi khi
        người Đinh Mão có thể trở nên khá cứng đầu và không chấp nhận thay đổi
        những lý 36 tưởng cố định của họ. Điều này cũng thường khiến cho người
        bạn đời giận dữ và khiến cho họ không chung thuỷ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngoài ra, người Đinh Mão có thể có đời sống bận rộn và ít khi có thời
        gian cho tình yêu. Công việc và sự nghiệp chiếm phần quan trọng trong
        đời họ. Họ có thể nhìn nhận thời gian yêu đương là lãng phí và họ thích
        làm điều gì có ích hơn hơn ngồi bên cạnh người yêu ngắm nhìn khung cảnh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Mão có tính cách sống động, thích xã giao, thân thiện và cuốn
        hút. Họ giỏi trong việc làm cho người khác vui và sẽ sử dụng cách này để
        kết nối với những năng lượng tích cực.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ thích ở bên cạnh những người tham vọng, siêng năng và thành công. Bởi
        vì điều này, uy tín và tiền bạc cũng khá quan trọng trong mối quan hệ
        của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Mão cũng thường được nhận định là may mắn trong quan hệ với
        con người. Vận may này là do họ tự tin và thân thiện.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu Đinh Mão cảm thấy không yên, có thể là do họ có tinh thần thích mạo
        hiểm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Gia đình thường dựa vào sự rộng lượng, tình yêu và hỗ trợ từ họ. Người
        Đinh Mão cống hiến cho gia đình và có thể gây ảnh hưởng, sự hâm mộ và
        kính trọng từ trong những người thân. Đối với con cái họ, họ là mẫu hình
        lý tưởng nhất để noi theo, và sự ngưỡng mộ sẽ chuyển thành ham muốn của
        con để noi gương theo.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù rất chú trọng đến hôn nhân, tình cảm của họ có thể chỉ ở mức
        trung bình. Họ thường dành nhiều thời gian hơn cho sự nghiệp hay kinh
        doanh, ngườiĐinh Mão có thể được nhận thức là không hết lòng vì tình cảm
        mặc dù bản chất của họ là muốn cống hiến hơn cho sự nghiệp. Người Đinh
        Mão nên dành thời gian nhiều hơn cho gia đình vì thành công trong sự
        nghiệp không đồng nghĩa với hạnh phúc trong cuộc sống.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Những Đặc Tính Trong Tình Yêu - Quan Hệ:</b> tận tâm, quan tâm, yêu
        gia đình, hỗ trợ, cuốn hút.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:{" "}
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đinh Hoả toạ trên Mão Mộc ẩn tàng Ất Mộc là Thiên Ấn của Đinh. Ấn thường
        được xem là ngũ hành quan trọng nhất đối với Đinh Hoả. Điều này bởi vì
        Đinh Hoả cần Mộc Ấn để tồn tại.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi nhận được sự giúp đỡ từ Ấn Tinh, người Đinh Mão trở nên thông minh,
        tri thức và thông thái. Họ thậm chí có cơ hội để trở thành Quý Nhân giúp
        đỡ người khác nếu trụ Đinh Mão gặp Hợi, Mão hay Dần Mão Thìn Tam Hội
        trong lá số Bát Tự. Nếu trụ này gặp Thiên Tài Tinh, họ có khả năng có sự
        nghiệp xuất sắc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Mão muốn đạt được nhiều điều vĩ đại. Họ có yêu cầu cao đối
        với bản thân và những người xung quanh họ. Họ có những giá trị bền vững
        và thường có tiêu chí đạo đức và tâm linh cao. Họ sáng tạo, phát minh và
        thích tiến bộ. Họ thích học hỏi và thích trở nên thông thái với việc yêu
        thích nhiều ý tưởng mới.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi có cảm hứng, họ sẽ cảm thấy hào hứng và có động lực còn khi không có
        hứng, họ dễ cảm thấy buồn chán và bất ổn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Mão có nhu cầu an toàn rất cao. Điều này bởi vì bản chất của
        ngũ hành Mộc được đại diện bởi sao Thiên Ấn. Họ làm việc chăm chỉ để xây
        dựng nền tảng tốt và lên những kế hoạch dài lâu và đầu tư tài chính để
        giúp cho tương lai của họ. An toàn về cảm xúc rất quan trọng với họ cũng
        như an toàn về vật chất vì chúng sẽ đóng góp vào hôn nhân hạnh phúc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        May mắn là người Đinh Mão có nhiều phẩm chất mà người ta có thể thấy ở
        một người thành công. Họ có năng khiếu kinh doanh xuất sắc, đầu óc nhạy
        bén và kỹ năng quản trị xuất sắc. Họ cũng có tài sử dụng ngôn từ và giao
        tiếp tốt cả về mặt ăn nói và viết lách.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Mão có xu hướng dễ bị bất ổn. Điều này đặc biệt đúng nếu có
        Dậu trong lá số. Họ có thể nghi ngờ bản thân mình và những người xung
        quanh cũng như lo lắng về sự chân thật cũng như ý định tốt của bạn bè
        hay gia đình. Họ cần tránh đắm chìm trong những suy nghĩ tiêu cực khiến
        cho họ lạnh lùng, xa cách và bất mãn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu lá số của người Đinh Mão có Nhâm Thuỷ, sao Chính Quan hiện diện,
        điều này báo hiệu thành công và nổi tiếng. Nếu Quý xuất hiện thay vì
        Nhâm trong lá số thì biểu thị người này sẽ thành công thông qua những
        phương cách không chính thống. Nếu cả can Nhâm và Quý không có mặt trong
        lá số, ngũ hành Hoả sẽ không bị kiểm soát và có thể gây ra mất cân bằng
        đối với hành Hoả. Lúc này người này sẽ thấy rằng họ gặp nhiều khó khăn
        khi muốn thành công trong đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đinh Mão toạ Quý Nhân Tinh nên biểu thị người này sẽ có tuổi thọ dài lâu
        cũng như khả năng đạt thành công lớn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mão cũng là Thiên Ấn Tinh, biểu thị khả năng trực giác của người Đinh
        Mão. Tuy nhiên, cách cục này phụ thuộc phần lớn vào chất lượng của hành
        Thuỷ trong lá số Bát Tự để xác định xem đây là lợi thế hay gánh nặng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mão cũng giúp hấp dẫn Hợi trong lá số Đinh Mão. Hợi là Quan sát biểu thị
        người này sẽ đáng tin và uy tín. Nếu sao này xuất hiện trong lá số biểu
        thị người này có thể dùng nó để hấp dẫn các mối quan hệ tích cực có thể
        có lợi cho họ nếu họ theo đuổi sự nghiệp. Nếu sao này vắng mặt, thì Đinh
        Mão sẽ gặp rủi ro về nguy cơ sức khoẻ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu người Đinh Mão gặp thêm Kim và Thuỷ trong đại vận thì biểu thị 1
        thời kỳ mà người Đinh Mão sẽ may mắn về thành công sự nghiệp, sức khoẻ
        tốt và quan hệ tình cảm tốt. Nếu họ gặp thêm Mộc, Hoả và Thổ trong lá số
        thì ít tích cực và có khả năng gặp nhiều sóng gió trong đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ấn Tinh toạ cung Phu Thê đối với nam giới Đinh Mão và điều này biểu thị
        họ có quan hệ rất vững chắc với mẹ. Bởi vì điều này, nhiều đàn ông Đinh
        Mão có xu hướng kết hôn phụ nữ lớn tuổi vì khiến cho họ nhớ về mẹ, hoặc
        người phụ nữ sẽ đóng vai trò mẹ hiền trong mối quan hệ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Mão sinh vào mùa Xuân và Hè sẽ rất vượt trội nếu họ liên tục
        cải tiến và phát triển suốt cả đời. Những người sinh vào mùa Thu và Đông
        cũng tương tự
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Mão sinh vào ban ngày có thể gặp nhiều may mắn trong đời
        trong khi người sinh vào ban đêm thường kiên trì và tham vọng và thích
        thúc đẩy thành công trong nghề nghiệp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu người Đinh Mão sinh trong tháng Sửu sẽ không tốt vì họ phải vật lộn
        để giành được một số điều họ muốn trong đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Mão thường rất thông thái và không bị cản trở bởi phương pháp
        học hỏi. Trí óc của họ thường rất đặc biệt, có vẻ như họ có trực giác
        chỉ lối. Họ thường xuyên tìm kiếm những gì gợi hứng thú cho họ. Bản tính
        họ hiền lành nhưng không nhút nhát. Họ khá nổi loạn được sai khiến bởi
        sự tò mò và nhu cầu biết rõ mọi thứ. Họ sẽ là những người thầy tuyệt vời
        vì họ không bao giờ ngừng học hỏi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ánh sáng chỉ toả sáng khi bạn đốt nến, đèn cầy sẽ không tự thắp sáng.
        Cũng như một câu nói kinh điển “Khi người học trò đã sẵn sàng, người
        thầy sẽ xuất hiện”. Nếu bạn làm rơi đèn cầy thì nó sẽ gây cháy và tạo
        nên hậu quả nghiêm trọng. Họ đánh đổi sức khoẻ để đạt được sự giác ngộ,
        học vấn nhưng kiến thức tâm linh của họ sẽ tăng trưởng. Nếu họ biết cách
        tiếp tục nuôi dưỡng bản thân về lâu dài như như các phương pháp thiền
        định, họ có thể sống thọ và có đời sống thịnh vượng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mão hợp với Tuất. Điều này làm xuất hiện Mậu (Thương Quan), Tân (Thiên
        Tài) và Đinh (Tỷ Kiên). Thương Quan khiến cho họ là người có khiếu ăn
        nói, họ cũng thân thiện vì có Tỷ Kiên và quan trọng nhất là họ giỏi quản
        lý tiền bạc do Thiên TÀi. Đối với nữ giới, mối quan hệ thật sự là khó
        khăn bởi vì Thuơng thường sẽ xung khắc Chính Quan (người chồng). Điều
        này chủ yếu bởi vì họ có trí tưởng tượng cao nên hay nghi ngờ chồng
        mình. Đối với đàn ông thì vì có Tài Tinh hiện diện, Thiên Tài biểu thị
        quan hệ tình cảm không ổn định, lúc này thì cảm thấy yêu, lúc khác lại
        không còn cảm giác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mão xung khắc Dậu. Điều này làm mất đi Tân Kim. Đây là dấu hiệu châm
        biếm mỉa mai khi người giáo viên vừa muốn lại không muốn tiền. Vì học
        thuật, họ muốn hi sinh một lượng lớn tiền bạc để đạt kiến thức. Về căn
        bản, họ kém trong việc chi tiền, muốn thu nhặc được kiến thức ở mọi nơi
        nhưng không muốn hao tổn tài lộc của mình. Tân đại diện cho những gì nhỏ
        bé và sáng lấp lánh. Bạn 40 có thể thấy họ bình dị, không kim sa lấp
        lánh, không có gì quá bắt mắt. Mặc dù thông minh nhưng đừng đưa tiền cho
        họ đầu tư, bạn sẽ không bao giờ thấy tiền quay trở về.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đinh Mão cũng có bản tính hi sinh từ từ. Cũng tựa như cây đèn cầy, khi
        càng cháy càng hi sinh thì họ càng mất mát.
      </p>
    </div>
  );
};

export const DinhSuu = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Đinh Sửu là người bảo thủ, có khả năng sáng tạo cao, có
        khả năng suy nghĩ từ góc độ của người khác, đạt thành tích cao trong sự
        nghiệp, gia đình có nghề kinh doanh gia tộc truyền lại, không thiếu của
        cải vật chất trong cuộc sống. Thiếu động lực và trách nhiệm để hoàn
        thành nỗ lực cố gắng, thái độ hơi lười, thường hợp với các đại vận cuộc
        đời nói chung. Thường có hôn nhân tốt nhưng quan hệ tình cảm dễ đi xuống
        sau trung niên, và sau đó, khó hàn gắn với vợ/chồng trở lại.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi bạn phải đối mặt với mùa đông rét buốt giá lạnh, bao vây bởi màn đêm
        đen tối, một ánh sáng tia cực quang giúp thắp sáng hi vọng và dẫn lối.
        Đinh Sửu là ánh sáng soi đường trong môi trường khắc nghiệt. Tháng Sửu
        là khi mùa đông rét buốc bắt đầu sưởi ấm và sự sống bắt đầu tuôn chảy.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đinh Hoả luôn là can hấp dẫn trong số 10 Can. Có khả năng để thay đổi
        hướng, khiến cho Đnh Hoả trở nên phản ứng và nhạy cảm với môi trường
        xung quanh. Nhiều người sẽ xem Đinh Sửu là ánh sáng dẫn đường trong bóng
        tối, là khởi nguồn của sự hứng thú. Người can ngày Đinh thường rất cơ
        hội, họ nhìn thấy mọi thứ nhanh chóng hơn người khác và do đó có thể tập
        trung. Hãy tưởng tượng như vào lúc hoàn toàn bị bóng đen bao phủ, một
        ngọn nến sẽ là điều đầu tiên chạm đến mọi vật bởi vì bạn sẽ đưa ngọn nến
        ra phía trước để nhìn thấy những gì đang đi tới.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Đinh Sửu khá thân thiện, hoà nhã và tốt bụng. Họ thường
        được xem như là những người theo chủ nghĩa lý tưởng, họ đa cảm và tri
        thức. Có hai nét tính cách quan trọng trong cuộc đời họ là ý chí mạnh mẽ
        và suy nghĩ gốc. Điều này biểu thị họ có quyết tâm để tạo ra những kết
        quả phi thường.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Sửu có sự thông thái và cả khôn ngoan, họ làm việc chăm chỉ
        cho một dự án hoặc vì một lẽ chính nghĩa mà họ rất đam mê. Họ có trực
        gíac bẩm sinh và năng lực tâm linh giúp họ nhận biết được mọi tình huống
        một cách nhanh chóng. Nếu được nuôi dưỡng đúng cách, năng lực tâm linh
        của họ sẽ phát triển chín mùi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đối với người Đinh Sửu thì vượt lên trên mọi xu hướng tiến bộ rất quan
        trọng. Trí tưởng tượng của họ luôn dẫn dắt họ tìm kiếm các dự án mối đầu
        tư mới và nhiều phấn khích. Những sự mạo hiểm này sẽ giúp cho họ luôn
        bận rộn và năng động.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Sửu thừa hưởng cảm giác về sự từ bi và công bằng. Họ sở hữu
        lập luận vững chắc và sẽ sử dụng những tính cách này để đấu tranh cho
        quyền lợi của họ và của những người khác. Họ có xu hướng đi theo hoạt
        động xã hội vì mục tiêu hoặc phong trào nào họ ủng hộ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người này rất giàu có khi còn trẻ. Một khi họ đã bắt đầu trên
        chuỗi may mắn, thì họ không bao giờ từ bỏ. Chìa khóa thành công của họ
        là giữ sự tập trung tích cực. Ngoài ra, để đạt được mức độ thành công và
        thịnh vượng, họ cần kiên trì, nhẫn nại và lì. Họ sẽ tiếp tục thịnh vượng
        khi lớn tuổi, hơn cả khi họ còn trẻ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Sửu cần học cách để khám phá cuộc đời. Một khi họ đã nắm bắt
        được kỹ năng này, họ sẽ có thể chạm được sự tinh tế nhạy cảm bên trong
        mình cũng như biết cách đón nhận sự bất ngờ trong cuộc sống thay vì cố
        gắng kiểm soát tất cả mọi chi tiết. Họ sẽ chỉ đạt được trí tuệ và khả
        năng bên trong thông qua tự phân tích và thiền định thường xuyên và đều
        đặn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Sửu có tài năng bẩm sinh về nghệ thuật và thủ công. Họ thông
        minh về mặt tri thức và có ham muốn lành mạnh về kiến thức và trí tuệ.
        Họ yêu thích tìm kiếm câu trả lời đối với mọi bí ẩn trong đời và do đó
        họ quan tâm tất cả các môn như tâm lý học, khoa học tự nhiên, huyền học
        thậm chí cả tâm linh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Sửu sở hữu khả năng tự nhiên trong lĩnh vực ẩm thực. Họ thích
        ẩm thực và sẽ luôn tìm ra được khẩu vị tinh tế ngon nhất, và luôn tìm
        kiếm những nơi nào có thể cung cấp cho họ trải nghiệm ẩm thực xuất sắc
        nhất. Đừng ngạc nhiên nếu thấy người Đinh Sửu xuất sắc trong nghệ thuật
        hay nấu ăn vì họ thích cả hai môn này.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tuy nhiên, điều quan trọng cần lưu ý là người Đinh Sửu có tham vọng và
        động lực cao so với những người sinh ngày Đinh khác. Điều này sẽ làm cho
        họ hơi cảm thấy mong manh nhạy cảm và họ ít khi đạt được những thành tựu
        rực rỡ trong cuộc sống hay sự nghiệp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc khác, họ cần phải thận trọng không được cho phép sự quyết tâm của
        mình khiến tạo áp lực quá lớn. Nếu sự quyết tâm của họ lấn át giới hạn,
        họ sẽ trở nên cực kỳ cứng đầu và ngoan cố. Sự thái quá này sẽ không tốt
        cho họ. Chỉ khi họ giữ sự được cân bằng trong cảm xúc, họ mới có thể cởi
        mở chấp nhận những lời chỉ trích mang tính xây dựng từ người khác cũng
        như có thể vươn cao trong sự nghiệp hay cuộc sống.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Những Nét Tính Cách Chung:</b> hào hứng, sáng tạo, độc lập, lạc quan,
        kỷ luật.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Là người bẩm sinh có khiếu kinh doanh, người Đinh Sửu có khả năng nhìn
        thấy được cơ hội trong khi người khác không thể. Cách tư duy của họ
        khiến cho họ là những nhà lãnh đạo và quản lý năng động giỏi trong những
        tập đoàn lớn. Sự quyết tâm của họ cộng với kỹ năng lãnh đạo xuất sắc sẽ
        giúp họ đạt được tất cả mọi thứ. Một khi họ đã quyết tâm theo đuổi điều
        gì, họ có thể đạt được mọi thứ trong đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những ai làm việc với người Đinh Sửu sẽ trân trọng họ vì tính kỷ luật và
        đầu óc cởi mở trong việc triển khai các ý tưởng sơ khai ban đầu. Vào
        thời điểm khủng hoảng, họ là người mà những người xung quanh có thể dựa
        vào để giải quyết các khó khăn thay vì sợ hãi. Họ nhìn nhận khó khăn như
        là thách thức, chứ không phải chướng ngại.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Sửu không nên bỏ qua hay đánh giá thấp bất kỳ cơ hội nào, dù
        cho có vẻ nhỏ nhoi. Họ có thể tạo dựng được những kết quả tốt nếu như họ
        có thể nhận thức được giá trị của tình huống đó. Khi họ tỏ ra lạc quan
        thì không gì có thể thật sự ngăn cản người Đinh Sửu thành công.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Vì khả năng sáng tạo và cái nhìn lạc quan, người Đinh Sửu luôn nghiên
        cứu tìm kiếm những gì mới nhất trong lĩnh vực công nghệ. Họ cũng yêu
        thích các ngành nghề mới nổi
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ cần tránh nản chí trong mọi tình huống vì nó có thể khiến họ lãng
        quên nhu cầu phải chăm chỉ, kiên trì và quyết tâm cần thiết để thành
        công.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngoài ra, họ cần phải tránh cám dỗ tìm con đường dễ dàng hay bỏ cuộc.
        Thay vào đó, họ nên rèn sự bền bỉ về tâm trí. Điều này sẽ giúp cho người
        Đinh Sửu sự tự tin cần thiết để nhìn thấu các khó khăn và thành công.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Công Việc:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Rất ít sự nghiệp có thể phù hợp với người Đinh Sửu. Họ có thể muốn cân
        nhắc dùng kỹ năng để trở thành xuất sắc trong các công việc như chuyên
        gia, cố vấn hay doanh nhân. Bởi vì họ có mối quan tâm rộng, họ cũng có
        thể làm việc tốt trong lĩnh vực bán hàng, tiếp thị hay quảng cáo.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ẩn giấu bên trong người Đinh Sửu là khao khát được bộc lộ bản thân. Sự
        khao khát bộc lộ bản thân có thể được thoả mãn thông qua viết lách, âm
        nhạc, nghệ thuật, ẩm thực, diễn xuất, nhảy múa, ca hát hay kịch nghệ.
        Các lĩnh vực khác bao gồm cả thiết kế hay thời trang.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Đinh Sửu có thể bị lôi cuốn theo những sự nghiệp trong
        ngành triết học, tâm linh hay huyền học. Khi họ trưởng thành, khía cạnh
        nhân văn của công việc đó có thể lôi cuốn họ vào sự nghiệp trong lĩnh
        vực tâm lý học hay tư vấn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bất kể họ chọn sự nghiệp nào, họ sẽ không bao giờ thoả mãn hay kéo dài
        thành công trừ khi họ cam kết làm việc chăm chỉ hay hoàn thành công việc
        tốt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tính Cách Chung Trong Công Việc: bình tĩnh, kỷ luật, đầu óc cởi mở, sáng
        tạo, thông minh, tỉ mỉ, hợm hĩnh, thiếu minh bạch, áp đặt, cái tôi lớn,
        thiếu kiên nhẫn.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tình Yêu:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Sửu rất lãng mạn và trân trọng mối quan hệ thân mật. Mặc dù
        yêu nhiều, họ có thể vật lộn với mâu thuẫn nội tâm và đôi khi bị choáng
        ngợp vì cảm giác ức chế. Bởi vì điều này, thường khó để họ bày tỏ cảm
        xúc sâu sắc và mạnh mẽ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Sửu thường vui vẻ và thích mạo hiểm hay phấn kích trong đời.
        Ai cũng sẽ dễ dàng cảm nhận người Đinh Sửu luôn biết cách tìm kiếm niềm
        vui. Thái độ mạnh mẽ và vứt bỏ tất cả để sống của họ có thể khiến nhiều
        người ngưỡng mộ, nhất là những người thích niềm vui khác. Người Đinh Sửu
        có thể duyên dáng, rộng lượng và cho đi mọi thứ, họ là người rất hào
        hiệp trong tình cảm.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Mối Quan Hệ:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Đinh Sửu thường đặc biệt thân thiện và nhiều cảm xúc. Họ
        thường chấp nhận người khác mà không phán xét và dễ dàng kết bạn với mọi
        người.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Sửu là những người bạn trung thành và đáng tin cậy. Họ sẵn
        lòng sử dụng toàn bộ nguồn lực bản thân để giúp người họ yêu quý. Bản
        tính trượng nghĩa của họ khiến cho họ kết giao được nhiều bạn bè và
        người ngưỡng mộ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ thường được xem là đi trước thời đại với những ý tưởng độc lạ. Họ yêu
        thích kết nối với những người có ý tưởng phá cách để phù hợp với bản
        thân họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ chân thành trong giao tiếp với người khác và thích tỏ ra thẳng thắn
        trong nói chuyện. Đó cũng là lý do vì sao bạn bè rất cần lời khuyên từ
        người Đinh Sửu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Sửu rất dễ bị lợi dụng lòng tốt do đó họ không nên mù quáng
        kết giao với bạn bè mà nên xem ai thật lòng yêu quý mình.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Gia Đình:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Đinh Sửu thường nhạy cảm, quan tâm chân thành đến gia
        đình mình. Họ có thể tìm được tình yêu và hôn nhân khá muộn, nhưng
        thường là hợp ý họ. Tình yêu tìm được trong đời thường đảm bảo cho hôn
        nhân họ kéo dài.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đàn ông thường có lợi khi lấy người phụ nữ sinh ngày Đinh Sửu bởi vì phụ
        nữ Đinh Sửu giúp ích cho chồng. Ngoài ra, phụ nữ sinh ngày này cũng giỏi
        giang đảm đang nội trợ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Cũng có nguy hiểm rằng vợ hay chồng Đinh Sửu có thể rời bỏ hôn nhân ngay
        vào lúc mặn nồng nhất. Họ sẵn lòng mù quáng từ bỏ đi mọi vận may, mọi
        thứ mà họ đã cất công xây dựng và bồi đắp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Cha mẹ sinh ngày Đinh Sửu yêu quý con cái sâu đậm và chăm sóc chúng tốt.
        Ngược lại, con cái họ cũng yêu quý và quan tâm đến họ. Họ có thể có
        nhiều con, và các con có thể đạt thành công và tài lộc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Những Nét Tính Cách Của Đinh Sửu Trong Quan Hệ và Tình Cảm:</b> tử
        tế, yêu thương, chung thuỷ, chân thật, cuốn hút, đam mê.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nói chung, người sinh ngày Đinh Sửu được xem là Hoả yếu. Điều này bởi vì
        trong lá số Bát Tự thì Đinh toạ Sửu và không thông căn ở địa chi. Tuy
        nhiên, trụ ngày lại toạ trên Thiên Tài Tinh, Thất Sát Tinh và Thực Thần
        trong lá số Bát Tự. Khi những sao này được đánh thức, kết hợp này biểu
        thị người này sẽ có tiềm năng lớn trong mọi việc cuộc sống. Điều này
        biểu thị Đinh Sửu có thể đạt được sự nghiệp viên mãn và gia đình cũng
        như kinh doanh như ý. Điều này cũng bởi vì các sao này tương sinh cho
        nhau để tạo thành 1 lá số tốt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Sửu là người mơ tưởng đến những gì hoàn hảo. Họ sáng tạo và
        tưởng tượng, cũng như có tính dịu dàng bẩm sinh. Họ luôn nhận thức rõ
        cảm giác của người khác. Tất cả đều xuất phát từ Thực Thần ẩn tàng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có khả năng tạo ra những khám phá chấn động và có tính cách mạng. Họ
        giỏi nhất khi thử nghiệm và làm việc với những ý tưởng mới. Khi họ trên
        hành trình tìm kiếm bản thân, họ sẽ trở nên quyết đoán và mạnh mẽ. Họ có
        tầm nhìn rõ ràng và sâu sắc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Sửu tuy vậy lại dễ bị mắc kẹt giữa giữa bản tính thích độc
        lập mạnh mẽ và sự sâu lắng cảm xúc. Họ có thể trở thành những người suy
        nghĩ rất căn cơ nhưng cũng dễ bị ảnh hưởng bởi áp lực từ đồng nghiệp và
        họ cũng thường xuyên cần phải cố gắng để định vị bản sắc của riêng mình.
        Họ cần tỏ ra với thế giới là họ tự tin, kiên trì và thông thái, ít khi
        bộc lộ những rối loạn cảm xúc và sự dễ bị tổn thương bên trong.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ cũng bị ảnh hưởng sâu sắc bởi môi trường và tập thể xung quanh họ.
        Tình bạn hoặc sự hợp tác đúng đắn sẽ giúp khuyến khích và hỗ trợ họ tỏa
        sáng. Bất cứ khi nào thiếu niềm tin với người khác thì người Đinh Sửu
        hay tỏ ra lo lắng hay nhụt chí.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Sửu cần học cách tin tưởng cảm xúc và trực giác của bản thân.
        Trừ khi họ thành công, nếu không tâm trạng của họ sẽ rất thất thường và
        bất ổn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trong lá số Bát Tự, Giáp Mộc và Canh Kim có thể dùng để sinh ra tạo ra
        Hoả từ Mộc hoặc từ Đá Lửa. Hành Hoả và hành Thổ sẽ khiến cho lá số nóng
        khô. Tuy nhiên, Đinh Hoả sẽ dễ bị dập tắt nếu Đinh Sửu đi kèm với Thìn,
        Tý hoặc ngũ hành Thuỷ trong đại vận. Điều này sẽ khiến cho hành Hoả
        trong lá số mất cân bằng và do đó sẽ khiến cho cảm xúc bị rối loạn. Cũng
        bất lợi nếu trụ ngày xung với Ấn Tinh. Trừ khi có sự hiện diện của hành
        Mộc, người có lá số như thế này sẽ có đời sống tuổi thọ ngắn hoặc sức
        khoẻ kém.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Sửu có khả năng mắc bệnh về bao tử, hoặc nếu có Quý Thuỷ kèm
        Kỷ Thổ trong Sửu hiện diện trong lá số Bát Tự dễ tạo thành Thổ bẩn. Họ
        sẽ dễ bị bệnh tật, đặc biệt nếu ngũ hành Mộc không xuất hiện trong lá số
        như một màng lọc cần thiết.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trụ ngày Đinh Sửu toạ Kim Khố, và điều này biểu thị sự giàu có, là dấu
        hiệu cho người sinh ngày Đinh Sửu thu thập nhiều tài lộc trong đời. Tuy
        nhiên, để thực sự khai thác được tài lộc thì cần có Tỵ trong lá số. Cần
        lưu ý rằng, nếu có nhiều hành Hoả trong lá số thì người Đinh Sửu dễ ngã
        bệnh trong khi kiếm được nhiều tiền. Điều này đặc biệt đúng nếu như có
        sự hiện diện của Mùi trong lá số. Để tránh sự xung khắc, cần có ngũ hành
        thông quan, như trong ví dụ này là Mão hoặc Hợi sẽ giúp xoay chuyển tình
        thế.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Cũng như Đinh Hợi, người nữ Đinh Sửu sẽ trở thành những bà mẹ bận rộn,
        dành ít thời gian cho con cái. Nếu có địa chi Mão xuất hiện thì mẹ chồng
        sẽ thay mặt chăm sóc cho con cái.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Về mặt hôn nhân thì hành Thuỷ hiện diện biểu thị cho chồng của người
        Đinh Sửu. Quan Tinh của Đinh Sửu hiện diện ở vị trí Mộ trong 12 vòng
        trường sinh Bát Tự biểu thị mối quan hệ với chồng kém. Điều này có nghĩa
        là chồng dễ đối mặt với chấn thương nghiêm trọng trong tương lai nếu
        cung Phu Thê bị xung hoặc hại trong 1 đại vận nào.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tốt hơn là ngày sinh Đinh Sửu nên gặp Canh Kim, Giáp Mộc, hành Mộc, Hoả
        và Thổ trong đại vận vì điều này sẽ giúp giảm bớt tác động tiêu cực
        trong lá số. Sẽ bất lợi nếu trụ ngày Đinh Sửu gặp nhiều Tân Kim và hành
        Thuỷ trong đại vận sẽ làm giảm tác dụng của ngũ hành dụng thần của người
        này.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Sửu sinh trong tháng mùa Xuân và Hè thì dễ có cuộc sống tốt
        mà không gặp nhiều trở ngại. Nếu sinh ra trong tháng mùa Đông hay Thu
        thì người này có khả năng giàu có và thịnh vượng rất cao. Nếu sinh. Vào
        ban ngày thì người Đinh Sửu sở hữu ngoại hình đẹp. Nếu sinh vào ban đêm
        thì những người này có thể gặp nhiều may mắn trong cuộc đời. Sẽ bất lợi
        nếu sinh vào tháng Sửu vì cuộc đời sẽ nhiều trắc trở và thử thách.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đinh toạ trên Sửu mang theo chính Khí là Kỷ Thổ Thực Thần cùng với Phụ
        Khí là Tân Kim Thiên Tài và Quý Thuỷ Thất Sát. Họ là những người rất
        sáng tạo. Họ có thể tạo ra một tương lai trong mơ cho môi trường xung
        quanh họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Với sự trợ giúp của Thiên Tài, họ có thể thấy được tương lai họ có thể
        làm được tiềm năng gì. Với sự xuất hiện của Thất Sát sẽ giúp cho việc
        thực thi tiềm năng đó. Những người này mơ đến mọi thứ và có thể biến nó
        thành hiện thực.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sửu gặp Tý biến thành đồng đội thu hút thêm Quý Thất Sát. Điều này đem
        đến sự dữ dội cần thiết để biến mọi thứ thành hiện thực. Nếu là nam giới
        thì họ sẽ mất kiên nhẫn và có thể bạo hành trong tình cảm còn nếu là nữ
        giới thì dữ dội trong tình cảm hơn tốc độ tiến triển tình cảm bình
        thường.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nam giới Đinh Sửu sẽ muốn tình dục là chính trong khi nữ giới Đinh Sửu
        sẽ dùng tình dục để mời gọi. Do đó, nữ giới sẽ thuận lợi hơn trong việc
        tìm đối tác tình cảm hơn là nam giới.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sửu khắc Mùi biểu thị Kỷ Thực Thần, Ất Thiên Ấn và Đinh Tỷ Kiếp. Về căn
        bản, họ không thích bạn bè, đồng đội. Cả nam hay nữ đều không xua đuổi
        bạn trai hay bạn gái, tình bạn thân mật của họ khá lành mạnh. Họ có thể
        cô đơn trong tâm, nhưng họ vẫn có giấc mơ ấm áp về tình cảm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhìn chung, người Đinh Sửu hoàn toàn thích nghi với môi trường của họ
        bất kể nó khắc nghiệt đến mức nào. Họ có thể tìm được cách để làm mọi
        thứ và tiến lên phía trước. Rất dễ để theo bước chân họ như là ánh sáng
        dẫn đường, nhưng cần cẩn trọng là bạn sẽ bước đi trong mùa đông lạnh lẽo
        cay đắng. Người đi theo người Đinh Sửu có thể không sống sót được trong
        hành trình này.
      </p>
    </div>
  );
};

export const DinhHoi = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Đinh Hợi: người này có thần thái VIP, tính cách mạnh mẽ,
        vẻ ngoài quan chức, quyền lực, rộng lượng, là người cầu toàn, kiên trì
        dai dẳng bảo vệ quan điểm riêng mình, tỏ vẻ thân thiện nhưng là một khối
        thuốc nổ ẩn bên trong có thể gây sốc người khác nếu bị kích hoạt. Tỏ vẻ
        nóng tính, thích tranh luận nhưng giàu tham vọng và tham lam, khi chất
        mạnh và có thể thành công trong kinh doanh tự thân. Có nhiều kế hoạch và
        chiến lược âm thầm có thể thực hiện bất cứ lúc nào. Gặp nhiều trở ngại
        lớn trong thời trẻ, sức khoẻ kém dần từ sau trung niên. Nhìn chung, cả
        đời giàu có không phải lo nghĩ về đói kém.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Không cần nhìn vào đâu xa, hãy nhìn vào bên trong bản thân mình. Đối với
        một số người thì câu trả lời không bao giờ nằm ở bên ngoài mà ở bên
        trong. Người Đinh Hợi tìm kiếm sự giác ngộ bằng cách nhìn vào bên trong
        họ. Đó là kỹ năng hiếm có người đạt được.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đinh là biểu tượng của hi vọng. Đinh toả sáng giữa đêm tối và do đó
        thường dẫn người ta đến con đường đúng đắn. Họ thường hi sinh bản thân
        trong lúc cháy sáng vì người khác. Điều này thường làm cho họ mệt mỏi
        trong quá trình phát triển và tiến bộ của người khác. Điều này thường bị
        lãng quên khi mọi thứ đang ở thời kỳ tốt đẹp và chỉ đáng lo khi ở thời
        kỳ rối loạn. Đôi khi điều khá buồn là người Đinh Hoả đốt sáng tỏ toả ánh
        sáng và năng lượng ấm áp cho những người không đáng nhận. Họ không tránh
        khỏi bản năng muốn dẫn dắt vì những ý định tốt trong tâm họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đinh Hợi có thể hình dung là những ánh sáng lấp lánh chi chít từ các
        ngôi sao phản chiếu trên mặt nước. Cũng như những ngôi sao lấp lánh,
        người Đinh Hợi có một quyền năng đặc biệt để gây ấn tượng và ảnh hưởng
        đến người khác. Họ có khả năng để trở nên một lãnh đạo có sức ảnh hưởng
        trong lĩnh vực của họ. Trong lịch sử đã chứng minh nhiều người Đinh Hợi
        là lãnh đạo tôn giáo hoặc các giáo phái. Điều này chứng tỏ rằng néu họ
        học cách sử dụng sự ảnh hưởng của mình theo một cách tích cực thì họ có
        thể tạo ra nhiều thay đổi tích cực cho xã hội.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Hợi độc lập, tham vọng và lý tưởng. Họ có sức mạnh, động lực
        bên trong cho phép họ đạt được mục tiêu trong đời. Những người này có
        sức sáng tạo tưởng tượng cao. Sự minh bạch sẽ giúp họ thành công trong
        khi sự quyết tâm và sức mạnh ý chí sẽ giúp dẫn bước họ đến thành công
        vượt bậc trong đời
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Với trí tưởng tượng sáng tạo, họ đón nhận các ý tưởng mới, và thường đi
        đầu các xu hướng tiến bộ. Được dẫn dắt bởi niềm tin lý tưởng, họ sẵn
        lòng làm việc vất vả để đạt mục tiêu. Khi họ tin tưởng trực giác và ý
        tưởng của mình, họ có thể cảm nhận con đường thẳng tắp đến thành công.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trực giác của họ là chính xác cao khi cần cảm nhận về thời điểm, kế
        hoạch hoặc việc thực thi kế hoạch.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi họ học được cách khai thác trực giác bẩm sinh, họ có thể khám phá ra
        rằng họ “biết được” khi nào là thời cơ chín muồi. “Trực giác” cũng sẽ
        mách bảo họ biết khi nào nên tránh xa những gì xấu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bởi vì họ có khả năng phân biệt mọi thứ nhanh chóng, họ có thể nhận ra
        một ý tưởng tốt ngay khi họ nhìn thấy. Điều này là lý do vì sao một
        người Đinh Hợi có thể thích tham gia vào các cuộc cải cách giáo dục,
        chính trị và xã hội. Những bản năng có thể được vận dụng rất tốt khi
        phải gặp gỡ những người khác lần đầu. Họ cũng sớm nhận ra ấn tượng ban
        đầu của họ cũng là ấn tượng chính xác nhất.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Từ góc nhìn về sự nghiệp hay kinh doanh thì họ có khả năng để phát triển
        công việc kinh doanh riêng và có thể sử dụng năng lực trực giác để suy
        nghĩ một cách bản năng và dự đoán chính xác những thách thức kinh doanh
        tiềm ẩn và loại bỏ chúng trước khi chúng bắt đầu. Điều này đơn giản có
        nghĩa là người Đinh Hợi sẽ có khả năng bẩm sinh kinh doanh tốt. Khi họ
        đạt được năng lực tối đa, họ có thể đạt được mục tiêu và tạo ra sự
        nghiệp ngoại hạng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ cần phải cẩn trọng tránh trở nên quá hào hứng hay nhiệt tình. Đôi
        khi, ham muốn chứng tỏ năng lực và thành công cũng được xem là một dạng
        thiếu kiên nhẫn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nỗi lo sợ lớn nhất của người Đinh Hợi là không để lại được di sản trong
        đời. Giải pháp hoàn hảo nhất sẽ là tìm được một lý tưởng đáng gía và vừa
        gợi cảm hứng cũng như có thể đem đến lợi ích tài chính. Điều này sẽ giúp
        ích họ trong việc khống chế cảm xúc lo lắng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trong những phút giây khi họ mất định hướng, người Đinh Hợi có thể dễ
        dàng bị tác động bởi áp lực từ bạn bè. Thay vì chọn con đường đi một
        mình nhanh chóng, họ cảm thấy dễ dàng hơn để “đi chung với đám đông”. Họ
        có thể tìm sự giải thoát thông qua việc theo đuổi những gì nhỏ nhặt.
        Điều này sẽ làm phân tán năng lượng của họ và khiến cho họ trở nên kém
        hiệu quả.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi buồn chán hoặc chịu áp lực cảm xúc lớn, người Đinh Hợi có thể muốn
        bỏ đi tất cả hoặc đâm đầu xuống tiêu cực. Họ sẽ tìm đến những gỉai pháp
        nhanh chóng trong cuộc đời, ngay cả đến mức sử dụng những phương pháp
        phi chính thống để đạt mục tiêu. Điều này sẽ dễ dàng bị khống chế thông
        qua việc lên kế hoạch kỹ lưỡng và bằng cách nhắc nhở bản thân mình về
        tầm quan trọng của kiên trì và ghi nhớ về các nguyên tắc đạo đức.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Của Người Kỷ Hợi:</b> nhạy cảm, lý tưởng, tưởng tượng
        ,thông minh, thực dụng, khao khát kiến thức.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Hợi sử dụng công việc như một cách để toả năng lượng và tài
        năng. Không quan trọng nghề nghiệp hay công việc nào họ tham gia vào vì
        họ đều sẽ dễ thành công.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Vốn dĩ những người này được xem là những chiến lược gia xuất sắc. Sự
        sáng tạo cho họ niềm vui trong việc thực thi kế hoạch riêng mình và cho
        phép họ thực hiện các tiến bộ đối với hệ thống có sẵn. Kỹ năng kinh
        doanh bẩm sinh, cộng với trực giác, cho phép họ nhận ra cơ hội tài chính
        trước cả người khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sự quan tâm đến người khác khiến cho họ lý tưởng cho các công việc nhân
        đạo. Các chính nghĩa nhân đạo có thể là cách tốt nhất để thực thi các
        sáng kiến và kế hoạch lớn của họ. Họ cũng biết cách kết nối và có thể
        trở thành những đầu mối liên lạc khi cần thiết.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ban đầu, sự thiếu kiên nhẫn và bất ổn của họ có thể ngăn họ trở nên xuất
        sắc, nhưng một khi họ có thể tập trung vào công việc thì họ sẽ toả sáng.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Công Việc:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Có một số lĩnh vực phù hợp với nhiều nét tính cách của người Đinh Hợi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khả năng thuyết phục của họ sẽ phù hợp tốt nhất cho vị trí trong ngành
        quảng cáo, truyền thông hay xuất bản trong khi nhu cầu có một lý tưởng
        xứng đáng sẽ thúc đẩy họ tham gia vào khoa học, y tế hoặc chữa bệnh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có kỹ năng tổ chức tốt giúp họ tham gia vào lĩnh vực quản trị. Họ sẽ
        muốn gắn bó với các hoạt động có liên quan đến nhiều người và các dự án.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sự yêu thích đa dạng khiến cho họ có thể trở thành những cố vấn tài
        chính hay nhà đàm phán giỏi. Để có thể tận dụng sự yêu thích giao tiếp
        với con người, họ có thể trở thành giáo viên, người đào tạo, môi giới
        hay người tuyên truyền. Vì người Đinh Hợi quyến rũ và duyên das1ng, họ
        là những nhà lãnh đạo giỏi và những guru truyền động lực xuất sắc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ cũng có thể tham gia vào lĩnh vực tiết kế nội thất. Họ có con mắt
        tinh tường về màu sắc và hình thể cũng như có trí tưởng tượng phong phú
        khi kết hợp lại với nhau. Những nét tính cách này sẽ giúp họ trở thành
        hoạ sĩ giỏi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ cũng có đam mê thật sự ở lĩnh vực âm nhạc, diễn xuất hay nhà hát.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Trong Công Việc - Sự Nghiệp:</b> đầu óc tổ chức, hứng
        thú, năng lượng, quyết tâm, lạc quan, mất kiên nhẫn, chịu đựng, cái tôi,
        áp đặt, không tập trung.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Quan Hệ - Gia Đình:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Hợi có động lực để tìm bạn đời. Họ không dễ yêu và thường mất
        nhiều thời gian để yêu nhưng một khi đã yêu thì họ sẽ cam kết tình cảm
        và hoà hợp. Điều này cũng đồng nghĩa họ cam kết tình cảm cao, và không
        có công việc nào là quá vất vả khi xét đến việc xây dựng mối quan hệ
        tình cảm lãng mạn lâu dài.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người bạn đời lý tưởng là người mạnh mẽ và siêng năng chăm chỉ như họ.
        Cũng sẽ tốt nếu người bạn đời cùng chia sẻ lý tưởng với họ. Một khi họ
        đã tìm được người đó, không có hi sinh nào là quá to tát. Hay nói cách
        khác, những vấn đề của trái tim cảm xúc là khá quan trọng đối với họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ rất nhạy cảm trong tình cảm với người khác và có thể trải qua nhiều
        thay đổi để thích nghi với nhu cầu bạn đời. Điều này là cần thiết, để họ
        có thể giữ được sự độc lập của mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày này thường thích xã giao, duyên dáng và cuốn hút. Họ
        lịch thiệp và thân thiện, họ cũng có khả năng thu hút nhiều bạn bè. Họ
        thích gặp gỡ người mới và thích làm việc với những người siêng năng
        thành công và có tư duy tốt. Họ cũng là nguồn kho kiến thức với những
        lời khuyên thực tế, đây cũng là lý do vì sao nhiều người cảm thấy an tâm
        khi có mặt họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu người Đinh Hợi không trải nghiệm được sự thay đổi thú vị trong tình
        bạn, họ có thể cảm thấy nhàm chán và cảm thấy không ngồi yên được. Một
        khi họ mất đi sự hứng thú, họ có thể trở nên lạnh lùng, xa lạ và thô lỗ.
        Họ cũng có thể cần phải bứt phá khỏi vỏ ốc để tìm kiếm cơ hội và góc
        nhìn mới.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Giữ sự gắn kết trong gia đình là quan trọng đối với người Đinh Hợi. Họ
        thân thiện và rộng lượng đối với tất cả mọi thành viên trong gia đình và
        kỹ năng giao tiếp sẽ giúp họ luôn giữ cho mọi việc bình an. Họ cũng bảo
        vệ gia đình rất cao và sẽ sẵn lòng chiến đấu hết mình vì người thân yêu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù dễ dàng cho họ để bày tỏ tình yêu và cảm xúc, sự bao dung là chìa
        khoá để hôn nhân tốt đẹp. Giao tiếp tốt là nền tảng để củng cố sự bao
        dung trong hôn nhân. Người Đinh Hợi phải có khả năng bày tỏ nhu cầu và
        mong muốn của mình, và cần biết người bạn đời ở mức độ sâu sắc hơn, thay
        vì dựa vào cảm xúc và sự ngọt ngào rỗng tuếch.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ nên nhớ rằng không nên kìm nén cảm xúc. Nếu điều này xảy ra, họ sẽ
        trở nên bất cần hoặc không quan tâm đến các thành viên gần gũi nhất
        trong nhà.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Trong Tình Yêu - Hôn Nhân:</b> bảo vệ, rộng lượng,
        duyên dáng, cảm thông, từ bi.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Chính Ấn và Quan Tinh ẩn trong Địa Chi của Đinh Hợi. Quan Tinh và Ấn
        Tinh cũng tương sinh lẫn nhau. Điều này biểu thị sự thông minh và rất
        tài năng. Người Đinh Hợi có ẩn hợp giữa Đinh và Nhâm hấp dẫn sự ảnh
        hưởng của ngũ hành Ấn. Điều này biểu thị người thông minh sắc bén và có
        độ trực giác cao. Trụ này toạ Quý Nhân biểu thị người Đinh Hợi sẽ thu
        hút sự giúp đỡ của Quý Nhân để đến giúp khi cần thiết. Nữ giới Đinh Hợi
        thì sẽ lấy được người chồng có năng lực và yêu thương.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Hợi rất quyết tâm và giàu năng lượng động lực. Thẳng thắn và
        can đảm, họ luôn hướng đến hành động và sẽ gợi cảm hứng cho người khác
        bằng sự can đảm của mình, quả cảm và hào hứng khi đối mặt với thử thách.
        Họ rất sáng tạo và thích bước vào những gì chưa biết với tinh thần tiên
        phong. Họ là những lãnh đạo bẩm sinh và có khả năng độc đáo trong việc
        gây ảnh hưởng đến người khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Hợi có thể nhận ra rằng họ có hai mặt trong tính cách. Họ có
        thể thực dụng và có đầu óc tổ chức và cũng như là người mơ mộng không kỷ
        luật và không yên. Họ lạc quan và có động lực hoặc bi quan và tiêu cực.
        Họ thích quảng giao vào lúc này và lúc khác lại lạnh lùng bất cần. Họ
        tham vọng và thích kinh doanh cũng đồng nghĩa việc họ rất độc lập, có
        động lực để vươn cao một mình, nhưng họ cũng muốn trở thành 1 phần của
        tổ chức và cũng có thể phụ thuộc vào những người thân yêu. Họ có thể
        thích phê bình, cứng đầu, hướng nội và cũng có thể dễ tiếp nhận, trực
        giác và có trách nhiệm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Hợi đôi khi cần học cách bước lùi lại và đối xử với bạn đời
        với sự tôn trọng và quan tâm hơn. Họ cũng thường có lợi khi thiết lập
        được một hệ thống giá trị vững mạnh và có thể sử dụng trực giác để nâng
        cao khả năng phán đoán giỏi trong đối nhân xử thế. Vì họ cũng rất thực
        tế và luôn đặt chân trên mặt đất, điều này không khó với họ. Người Đinh
        Hợi có năng lượng vô hạn nghĩa là họ cũng sẽ có lợi ích từ sự ủng hộ của
        bạn bè và người hâm mộ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đinh Hợi toạ trên Dịch Mã, biểu thị đi lại thường xuyên. Trụ này được
        xem là một trụ chủ về chung thuỷ, vì toạ Chính Quan, biểu thị bản tính
        tốt, có văn hoá và giáo dục. Cũng có kết hợp ngầm kéo với Ngọ. Điều này
        biểu thị có sự hấp dẫn ẩn đối với bạn bè và người hâm mộ đối với trụ
        ngày này. Bởi vì bên trong Ngọ là Tỷ Kiên.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sẽ rất tốt nếu trụ này gặp Nhâm Thuỷ Quan Tinh trong lá số hay trong đại
        vận vì kết hợp này được gọi là Tráo Cung Đăng, Điều này biểu thị thành
        tích học tập siêu việt và đột phá thành công cho người Đinh Hợi. Cũng có
        lợi nếu trụ ngày này gặp Canh Kim ở Thiên Can. Cục này được gọi là Áp
        Chân Tại Thủ. Nếu Nhâm không xuất hiện trong lá số th2i Giáp sẽ bị xung
        với Canh, biểu thị khả năng bị phá sản, có khi bị tai nạn nghiêm trọng
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người sinh vào tháng mùa Xuân và Hè có cơ hội cao để đạt được tài
        lộc lớn trong sự nghiệp. Sinh vào mù Thu và Đông thì phù hợp để làm việc
        một mình và không dựa vào sự giúp đỡ của người khác. Người sinh vào ban
        ngày thì có thể có may mắn nhưng không nhiều tiền. Người sinh vào ban
        đêm thì có thành công lớn với sự giúp đỡ của người khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu sinh vào tháng Thìn thì không may mắn vì biểu thị cuộc đời nhiều
        thách thức không đoán trước được có thể cản đường thành tích của người
        này.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đinh toạ trên Hợi là biển cả mang theo khí chính là Nhâm Thuỷ Chính Quan
        và phụ khí là Giáp Mộc Chính Ấn. Chính Quan ép buộc trách nhiệm lên trên
        người Đinh Hoả vốn đã rộng rãi. Nó làm cho sứ mệnh cuộc đời của người
        này là dẫn dắt, họ có một trách nhiệm thật sự để giúp đỡ và lan truyền
        hi vọng. Vì nước không có biên giới và mang theo trong mình tất cả. Hi
        vọng của họ là không bờ bến. Họ có kỹ năng hiếm có này cộng với cộng
        đồng rộng lớn như nước tuỳ thuộc vào cốc chứa mà thể hiện hình dạng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Giáp là Chính Ấn là những gì giữ cho họ tiến lên. Họ có kiến thức rộng
        lớn của đại dương đồng thời là Mộc sinh ra từ Hợi Thuỷ. Sự khao khát
        kiến thức là rất vô bờ, nếu họ không biết bất cứ gì, họ sẽ có bản năng
        đi tìm kiếm câu trả lời. Mộc là ngũ hành phát triển, chỉ thúc đẩy hoả
        cháy dữ dội hơn. Họ sẽ tìm ra được đường hướng để nuôi dưỡng hi vọng
        trong đời bất kể bản thân đang ở thời kỳ tăm tối nào.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Hợi hợp với Dần thúc đẩy Chính Ấn Giáp, kẹp bởi Thương Quan Mậu và Kiếp
        Tài Bính. Bởi vì cả Thương Quan và Kiếp Tài đều là khắc tinh của quan hệ
        tình cảm, người Đinh Hợi thường gặp cô đơn. Họ đầu tư quá nhiều vào
        người khác đến nỗi người kia hầu như không cảm nhận được giá trị của họ.
        Đôi khi thể hiện ra rằng thà là không hi vọng vì ít ra như vậy sẽ thu
        hút được sự quan tâm của đối phương.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Hợi xung với Tỵ chứa Kiếp Tài Bính, Chính Tài Quý và Thương Quan Mậu. Ở
        đây bạn sẽ thấy người nam giới sinh ngày Đinh Hợi xua đuổi đi Chính Tài
        cũng là người vợ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Điều thiếu ở họ là gốc từ Đất Thổ. Họ không có sự lựa chọn của riêng
        mình, họ không có thế giới của mình. Một khúc gỗ trôi trên biển bao la
        và bạn đang đốt khúc gỗ đó. Điều gì sẽ xảy ra nếu khúc gỗ đó hết nhiên
        liệu, bạn sẽ gặp biển chết! Người này cần học cách cứu bản thân mình
        trước khi cứu một ai khác.
      </p>
    </div>
  );
};

export const DinhDau = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Đinh Dậu: vận mệnh giàu có và thanh cao. Người có khiếu
        khôi hài, có gu thẩm mỹ, tố chất doanh nhân, khả năng quản lý kinh
        doanh, trí nhớ tốt, đầu óc tổ chức nhưng không dễ dàng bộc lộ cảm xúc
        thật sự cho người khác. Giỏi giao tiếp nhưng thích giữa khoảng cách với
        người khác, quá nhạy cảm và đôi khi hay đa nghi. Dù là nam hay nữ, người
        này thường khả ái và rất quyến rũ người khác phái. Giỏi kỹ năng tình
        dục. Có thành tích cao hay mệnh vượng vào lúc thanh niên, trước trung
        niên. Nhưng cuộc đời có rất nhiều sự kiện thăng trầm - có nhiều vận rất
        may mắn sau lại gặp những vận cực kỳ xui. Phù hợp làm công việc tư vấn
        hay trợ lý.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sao Bắc Đẩu từ lâu đã được xem là dẫn lối cho thuỷ thủ tìm đường trên
        biển lớn. Sự hiện diện của sao này cho phép thuỷ thủ ở khắp mọi nơi trên
        thế giới tính toán được vị trí tương đối của họ và đạt đích đến mong
        muốn. Đinh Dậu có khả năng để hướng dẫn mọi người và hành xử như một nam
        châm tự nhiên khi bạn cần định hướng.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đinh Hoả là hành của sự soi sáng. Nó đem đến tầm nhìn trong điều kiện
        tối tăm và đem đến hơi ấm trong môi trường lạnh giá nhất. Nó thường được
        miêu tả như thay đổi cuộc sống. Bạn có thể thấy, Đinh Hoả là hành duy
        nhất có thể thay đổi tất cả mọi hành khác, nó có thể biến Mộc thành tro,
        làm nóng đất, biến nước thành hơi, làm tan chảy kim và dĩ nhiên huỷ diệt
        hoàn toàn bản thân. Chức năng chính của nó là đem đến động lực cần thiết
        để bạn tiến lên phía trước. Quy trình chuyển hoá thường đau thương và
        mất nhiều thời gian, tuy nhiên kết quả lại khiến bạn kinh ngạc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Hình tượng đại diện cho Đinh Dậu là ánh sáng chiếu lên trên những bề mặt
        sáng bóng. Không ngạc nhiên khi có thể mô tả người Đinh Dậu là hấp dẫn,
        bắt mắt và tài năng.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Như hình ảnh biểu thị thì người Đinh Dậu thích ánh sáng, sân khấu và
        thích được quan tâm chú ý vì tài năng của mình. Người Đinh Dậu có sức
        cuốn hút lớn và toả ra sự tự tin và duyên dáng cũng như có năng lực thu
        hút những người xung quanh. Thích xã giao và năng động, người Đinh Dậu
        dễ dáng kết bạn và thích gặp gỡ người mới và phát triển các mối quan hệ
        mới. Họ thích ở trong tập thể những người có suy nghĩ giống họ cũng như
        thông minh giúp cho họ có thể học tập những góc nhìn mới và o đó có thể
        giúp họ mở rộng mối quan hệ và mở ra những cánh cửa mới.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Dậu khá tham vọng và quyết tâm. Một khi họ đã cam kết mục
        tiêu hay dự án nào, họ sẽ làm việc với sự kiên trì không biết mệt mỏi để
        hoàn thành công việc. Lạc quan và sáng tạo cùng với thái độ dám nghĩ dám
        làm sẽ làm cho bất bại trước mọi trở ngại hoặc thách thức, người Đinh
        Dậu sẽ xem đó như một cơ hội để cải thiện kỹ năng và phô diễn tài năng
        của mình. Họ không sợ phát triển nhiều hướng để vượt qua các trở ngại
        trên con đường và sẽ vui vẻ từ bỏ những chiến lược không thành công để
        thử những chiến thuật mới nhằm tiến xa hơn.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đầu óc cởi mở, sáng tạo, người Đinh Dậu thường có khả năng liên kết các
        ý tưởng và khái niệm cùng nhau để tạo ra những suy nghĩ mới mẻ và đột
        phá. Người Đinh Dậu thường xuất sắc trong những lĩnh vực họ được tự do
        để theo đuổi trực giác và quan sát mà không ngại cản trở. Họ có tinh
        thần độc lập và ghét cản trở cũng như muốn có quyền lực tuyệt đối.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Dậu thường thoải mái với vị trí nắm quyền hơn là vị trí bị
        sai khiến. Họ là người hành động và tự động viên tinh thần, kết hợp với
        kỹ năng con người xuất sắc có thể biến họ thành những nhà lãnh đạo tài
        năng xuất sắc. Có đam mê, kỹ năng thuyết phục, họ có thể gợi cảm hứng và
        ít khi thiếu người ủng hộ cho những hoạt động của họ. Mặc dù họ có thể
        mất kiên nhẫn và nóng giận, họ cũng giàu cảm hứng và nhanh chóng vượt
        qua những cơn nóng giận để tìm ra giải pháp thực tế.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Dậu bị thu hút bởi lối sống xa hoa. Họ có sự trân trọng về
        phong cách và nét đẹp cũng như họ sẽ bao phủ bản thân bằng những đồ vật
        gợi ảm hứng cho họ. Mặc dù họ có khẩu vị tinh tế, họ cũng rất thẳng thắn
        trong cảm xúc. Quyết đoán và thẳng thắn, họ biết rất rõ về nhu cầu và
        mong muốn trong một mối quan hệ và sẽ hoàn toàn cam kết về tình cảm một
        khi trái tim họ đã sẵn sàng. Bạn đời của họ cần hành xử ủng hộ và gợi
        cảm hứng trong suốt cuộc đời họ và cung cấp một bệ đỡ vững chắc để họ
        phát triển và phô diễn những tài năng xuất chúng.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Những Đặc Tính Của Người Đinh Dậu:</b> sáng tạo, thực tế, đam mê,
        siêng năng, ý chí mạnh mẽ, nhiệt tình.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bản tính thích nằm quyền của người Đinh Dậu sẽ giúp họ thành công trong
        sự nghiệp. Họ có trí tưởng tượng tốt, giúp họ đạt kết quả xuất sắc trong
        công việc, nhất là trong lĩnh vực giải trí.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Dậu tự tin và có năng lực nhận lãnh vị trí lãnh đạo. Họ có
        thể đoàn kết tất cả mọi người vì một mục tiêu chung. Với tư cách lãnh
        đạo, họ lắng nghe nhu cầu và cảm xúc của những người bên dưới.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Kỹ năng tổ chức và óc thực tế là những điểm mạnh của họ. Họ có thể quản
        lý công việc hiệu quả và sẽ là tài sản lớn đối với bất kỳ công ty nào.
        Ngoài ra, họ cũng năng động trong việc mở rộng kiến thức và kỹ năng để
        phát triển cá nhân và sự nghiệp.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Công Việc:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Dậu là người tập trung nguyên tắc. Họ là người sẵn sàng tranh
        đấu vì lẽ phải mà họ tin tưởng. Người Đinh Dậu giỏi trong việc sử dụng
        ngôn từ và kết nối. Những đặc tính này giúp họ tốt nếu họ theo đuổi sự
        nghiệp chính trị. Họ là những chính trị gia tốt, và những vị trí này sẽ
        giúp họ trở thành chất xúc tác cho những thay đổi tốt hơn cho đất nước.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có tài trong việc sử dụng ngôn từ hay phương tiện truyền thông hình
        ảnh. Do đó, họ có khả năng tạo dựng sự nghiệp như một nhà văn, hay trong
        lĩnh vực nhiếp ảnh, phim ảnh. Người Đinh Dậu cũng khá sáng tạo, và có
        thể làm việc tốt trong lĩnh vực quảng cáo, quan hệ công chúng và thiết
        kế. Họ cũng là những giáo viên và giảng viên lôi cuốn, bản tính thuyết
        phục sẽ giúp họ xuất sắc trong lĩnh vực kinh doanh hoặc tiếp thị.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Những Đặc Tính Trong Công Việc - Sự Nghiệp:</b> độc lập, tổ chức tốt,
        tận tâm, hào hứng, siêng năng, cứng đầu, bất cẩn, hống hách, phán xét,
        chỉ trích.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Quan Hệ - Gia Đình:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Dậu chân thật, đồng cảm, trung thành và chung thuỷ trong tình
        yêu. Họ là người rất giỏi thê hiện khi họ ở cạnh người bạn đời chia sẻ
        chung ý tưởng. Người Đinh Dậu có thể nhảy từ một mối quan hệ này sang
        một mối khác, chỉ để tìm kiếm một người bạn đời đáp ứng được định nghĩa
        Người Đặc Biệt. Bởi vì điều này, họ cần phải kiên nhẫn trong tình yêu và
        tình cảm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Một khi họ tìm được người hoàn hảo, người Đinh Dậu có khả năng tận tâm
        với bạn đời hay vợ chồng. Họ xem sự cam kết tình cảm là trách nhiệm để
        đề cao những mối quan hệ tích cực trong tình cảm.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tất cả những gì người Đinh Dậu cần làm là chính mình, vì họ bẩm sinh vốn
        duyên dáng, những người khác sẽ đổ dồn đến họ. Người Đinh Dậu thích xã
        giao, thường hứng thú trong những mối quan hệ với những người sáng tạo
        và siêng năng.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đó là những người có thể kích thích đặc tính của người Đinh Dậu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Dậu thích trao đổi ý tưởng vì nó kích thích sự sáng tạo. Bởi
        vì điều này, họ có xu hướng tìm kiêm mối quan hệ với những người có cùng
        suy nghĩ và phần lớn những bạn bè của họ đều đến từ chỗ làm hay những
        nhóm cùng chung sự quan tâm.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ đôi khi có thể gặp khó khăn trong phân tích bởi vì những gì trên bề
        mặt có thể không phản ánh điều họ thật sự cảm nhận. Chỉ có một ít những
        người đáng tin trong vòng bạn bè thân mật có thể nhìn thấu cá tính che
        giấu của họ.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Dậu rộng lượng, đam mê và quan tâm những người họ yêu thương.
        Họ chấp nhận hi vọng và cảm hứng từ mối quan hệ cá nhân, đồng nghĩa với
        việc có môi trường yêu thương vốn rất quan trọng đối với họ.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Dậu chung thuỷ trong hôn nhân, bất kể mọi chuyện sẽ diễn biến
        ra sao.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ cố gắng giữ bình an và hạnh phúc trong gia đình và đảm bảo bầu không
        khí hoà hợp bằng cách nói rõ ra những gì vướng mắc với các thành viên
        trong gia đình. Giao tiếp tốt trong quan hệ vợ chồng có thể thúc đẩy mọi
        thứ diễn biến tốt đẹp cho họ.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Dậu nên tránh tâm trạng bản thân dao động hoặc có hành động
        hấp tấp.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Những Đặc Tính Trong Tình Yêu - Quan Hệ:</b> đồng cảm, tận tâm, yêu
        thương, chung thuỷ, bảo vệ, duyên dáng.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Dậu toạ trên vị trí Trường Sinh cũng như Thiên Tài Tinh. Trụ
        này được xem là có Quý Nhân và Văn Xương biểu thị người Đinh Dậu là
        người có khí chất, thông minh, hiểu biết và đáng kính trọng. Bởi vì Âm
        Hoả và Âm Kim là 2 thái cực đối lập, cục này cũng biểu thị người Đinh
        Dậu có tính cách có phần nổi loạn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Dậu có khả năng thể hiện sức mạnh to lớn, sáng tạo và quyền
        lực. Họ rất sáng tạo và cực kỳ thông minh và có thể hiện sự tập trung và
        bền bỉ. Do đó, người Đinh Dậu được hình dung như tia laser, chiếu vào bề
        mặt kim loại. Cũng tựa như hình ảnh này, họ được xem là sở hữu sự tập
        trung, nhất quán và năng lượng trong công việc, cũng như tia laser chầm
        chậm khắc, tạo hình khối kim loại.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Dậu thường thich kiểm soát, chịu trách nhiệm về hành động của
        mình. Đây là bản chất do Tân Kim Thiên Tài. Thích tự ra quyết định và
        lựa chọn của chính mình, họ không bao giờ vội vã, thay ào đó lại cẩn
        trọng và phân tích, họ sẽ nghĩ rằng thông qua tất cả những dữ kiện và số
        liệu. Vì họ là người có bản tính cầu toàn, họ đôi khi thiếu niềm tin vào
        chính bản thân mình đặc biệt khi họ bị choáng ngợp bởi vấn đề khó khăn
        và có thể dẫn đến sự không chắc chắn. Mặc cho những điều này, họ có thể
        rất miễn cưỡng chấp nhận lời khuyên và họ có thể cần học hỏi để nhận ra
        sự giúp đỡ chân thành khi được ngỏ lời thay vì những lời chỉ trích hay
        can thiệp không có cơ sở. Điều này do Dậu có lực kéo âm thầm đối với
        Thìn hay Thương Quan.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc cho tất cả những động lực, người Đinh Dậu có thể dao động và thường
        cảm thấy không yên. Họ là người theo chủ nghĩa lý tưởng, thường xuyên
        tìm kiếm sự bình an bên trong và họ thường xuyên cảm thấy bất mãn. Luôn
        theo đuổi những trải nghiệm mới, họ có thể có xu hướng muốn dàn trải
        năng lượng bản thân quá mỏng trên quá nhiều dự án. Họ hào hứng và can
        đảm, rất thẳng thắn, tham vọng.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Dậu có xu hướng khá kín kẽ. Họ có thể căng thẳng về cảm xúc
        và có thể vật lộn trong việc bày tỏ cảm xúc hay quan điểm. Điều này bởi
        vì Dậu tạo thành ám xung với Mão, vốn là Ấn của Đinh. Họ là người rất
        khôn ngoan và có khả năng phán đoán trực giác và biết rõ và hiểu các suy
        nghĩ và động lực của người khác mà không cần phải hỏi. Họ có sự độc lập
        và có luật riêng của mình. Đằng sau tất cả những thứ này, họ khao khát
        trở thành thành viên của một nhóm và có khả năng trở nên rất đáng yêu và
        rộng lượng.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Dậu có tình yêu lớn dành cho gia đinh và mái ấm và họ thường
        là bậc cha mẹ mẫu mực. Họ rất vui dành sức mạnh để quan tâm và hỗ trợ
        những người thân yêu nhưng họ cần tránh tỏ ra quá kiểm soát. Họ cũng có
        thể mất kiên nhẫn và thiếu bao dung nhưng họ cũng có khả năng gợi cảm
        hứng cho người khác với vẻ ngoài mạnh mẽ và họ luôn tìm được người ủng
        hộ cho chính nghĩa của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Dậu thích hợp Nhâm và Ất trong lá số. Những can này, đại diện
        cho Chính Quan và Thiên Ấn và sẽ đem đến sự cân bằng và ổn định cần
        thiết đối với người Đinh Dậu. Cần lưu ý là mặc dù cả hai hành này đều là
        Thuỷ và Mộc, sẽ bất lợi cho ngày sinh này nếu gặp Quý và Giáp. Điều này
        bởi vì Quý là Thất Sát Tinh trực tiếp dập tắt Đinh Hoả yếu và Giáp Mộc
        là Chính Ấn giúp cho ngọn lửa Đinh Hoả yếu ớt sẽ khó để tiêu thụ gỗ quá
        dày và cứng chắc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Hình, Xung hay Phá tạo thành với Tuất và Mão trong lá số sẽ khá bất lợi,
        vì những thứ này sẽ huỷ hoại Âm Hoả vốn yếu ớt. Nếu những cục này hiện
        diện sẵn, người này sẽ có khả năng phải vật lộn để thành công trong đời
        và đối mặt nhiều thách thức và trở ngại.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Dậu là Quý Nhân của Đinh Hoả. Trong bối cảnh này, vì Dậu là hành Kim nên
        có liên quan đến vấn đề tiền bạc. Điều này gợi ý rằng Đinh Dậu sẽ có quý
        nhân trong đời giúp đỡ về tài chính. Đó là lý do, trong nhiều trường
        hợp, người Đinh Dậu thường trở nên khá giàu có. Tuy nhiên, nếu có Xung,
        Hợp, Hại hay Hình hiện diện ở Địa Chi trong Lá Số hay Đại Vận, tác dụng
        tốt sẽ bị loại trừ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu Ấn Tinh xuất hiện trong lá số của nam giới sinh ngày Đinh Dậu, biểu
        thị vợ sẽ xung với mẹ. Họ khó nhìn mặt nhau và mâu thuẫn sẽ trầm trọng
        đến mức họ không thể sống chung trong cùng một nhà. Điều này đặc biệt
        đúng nếu vợ anh ta là người ngày sinh Tân Kim. Thú vị hơn, cô vợ sẽ
        không chỉ tìm mọi cách để loại trừ bà mẹ vợ, mà còn cố gắng làm như đó
        là quyết định của người chồng. Một điểm khác cần lưu ý là hầu hết nam
        giới Đinh Dậu sẽ lấy vợ trẻ hơn nhiều.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nữ giới Đinh Dậu có xu hướng xinh đẹp và tinh tế. Cả Đinh và Dậu đều là
        thuộc quái Đoài trong Bát Quái, biểu thị phụ nữ xinh đẹp và trẻ trung.
        Những phụ nữ này đặc biệt có ngoại hình hấp dẫn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh vào mùa Xuân và Hạ sẽ có xu hướng ít tình cảm. Người sinh vào
        mùa Thu và Đông sẽ có xu hướng đáng tin cậy và chăm chỉ và do đó thường
        rất thành công. Người sinh vào ban ngày sẽ phải làm việc vất vả bởi vì
        ngọn lửa đèn cầy thường ít sáng hơn vào ban ngày, họ cũng khó có thể
        hưởng lợi từ sự giúp đỡ lớn và họ sẽ phải dựa vào bản thân chính mình để
        đạt thành công. Người sinh vào ban đêm sẽ dễ hạnh phúc và viên mãn. Điều
        này bởi vì ngọn lửa đèn cầy Đinh Hoả sẽ sáng hơn vào ban đêm. Nếu Đinh
        Dậu sinh vào tháng Tuất thì đối mặt nhiều thách thức và phải vất vả mới
        đạt thành công vì sẽ có nhiều trở ngại trên đường đi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đinh toạ trên Dậu mang theo duy nhất năng lượng Tân kim. Tân thường biểu
        thị cho sự chính xác và rõ ràng. Chúng ta thường sử dụng kim loại quý
        như vàng, bạc hay thậm chí kim cương để nói về Tân. Thực sự đây là hành
        của sự quý giá với khả năng độc nhất vô nhị là thu hút sự chú ý. Chính
        cách cục này khiến cho họ hoạt ngôn và thích thu hút sự chú ý.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tân kim ở đây biểu thị Thiên Tài khi diễn dịch theo Thập Thần. Thiên Tài
        là sao của tương lai. Họ có khả năng nhìn xa và chờ đợi giây phút phù
        hợp để nhảy vọt. Khả năng đánh giá bức tranh tổng thể đôi khi khá choáng
        ngợp. Họ có thể ra những quyết định lý trí để từ bỏ những hành động
        không cần thiết và bảo toàn năng lượng để chuẩn bị cho những giây phút
        trọng đại trong đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Dậu hấp dẫn Thìn mang theo năng lượng của Thuơng Quan Mậu kèm với Thất
        Sát Quý và Thiên Ấn. Xét về quan hệ tình cảm thì sự xuất hiện của Thương
        Quan và Thất Sát không có lợi cho giới nữ. Dậu cũng là sao Đào Hoa khiến
        cho nữ giới khá hấp dẫn, rủi ro và không thể tuân theo những nguyên tắc
        xã hội bình thường. Bạn có vẻ như không thể chinh phục trái tim cuả nữ
        Đinh Dậu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Dậu xung với Mão mang theo hành của Ất Mộc Thiên Ấn. Trung hoà cho cả
        hai giới tính khi xét về tình cảm. Thiên Ấn thường được xem là chuẩn mực
        về sức khoẻ vì Thiên Ấn sinh cho can ngày. Khi xua đuổi đi Thiên Ấn thì
        biểu thị người này sẵn lòng hi sinh sức khoẻ cá nhân, sống bất chấp hoặc
        đặt sức khoẻ xuống hàng thứ yếu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có sứ mệnh trong đời để thúc đẩy người khác tiến lên. Họ muốn cho bạn
        thấy rằng vị trí hiện tại của bạn chưa đủ tốt và bạn xứng đáng có kết
        quả tốt hơn. Họ đươc trang bị kỹ năng để thu hút và hấp dẫn với sự hiện
        diện của sao Đào Hoa Dậu, sự sáng tỏ Đinh và thương lai Thiên Tài. Để
        đạt được điều này, họ sẵn lòng chui vào lò lửa để cùng cháy sáng hướng
        đến vinh quang trong tương lai.
      </p>
    </div>
  );
};

export const DinhMui = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Đinh Mùi là người thích sáng tạo mọi thứ, thường không
        thoả mãn với trạng thái hiện tại, thông minh, lạc quan, dám nghĩ dám
        làm, đánh giá cao quan hệ cá nhân nhưng mất tiền cho những người xung
        quanh mình. Được nhiều người yêu mến. Tài vận kém. Hôn nhân hạnh phúc và
        may mắn. Với nữ giới, rất hấp dẫn người khác phái nhưng sẽ ảnh hưởng xấu
        cho người chồng sau năm 40 tuổi.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những loại vật chất như Mộc chứa đựng năng lượng sẽ được giải phóng từ
        từ khi được thắp sáng bằng lửa và do đó có thể bảo tồn được trường thọ
        của lửa. Chính là thông qua sự khám phá ra lửa mà chúng ta dần dần có
        thể đạt được nhiều thứ sử dụng lửa cháy. Đinh Mùi là một trong những cột
        trụ thích phương pháp chậm rãi làm thay đổi môi trường xung quanh.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đinh Hoả có năng lực rất đặc biệt, nó có thể thay đổi ngũ hành khác.
        Đinh thường gắn với sự khai sáng và thông qua việc thấu hiểu rằng mọi
        thứ xung quanh nó sẽ khởi đầu hành trình thay đổi. Thổ nóng, thuỷ bốc
        hơi, kim loại tan chảy, mộc biến thành tro bụi và lửa nóng giận, tất cả
        5 hành đều phản ứng lại với lửa. Một khi bạn đã được Đinh Hoả sưởi ấm,
        không có gì như cũ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Một đám cháy cháy lách tách biểu thị hình ảnh của người Đinh Mùi. Nó
        phản ánh họ là người thích ngoại giao, ngẫu hứng và có tính hướng ngoại.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Mùi thường năng động, quyết tâm, gìau năng lượng và sống lý
        tưởng. Họ có đầu óc sáng lán và sở hữu tính cách giàu năng lượng và sức
        sống. Họ lúc nào cũng có thích vui đùa và trẻ trung.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Không ngạc nhiên khi người Đinh Mùi chứng tỏ dấu hiệu tài năng nghệ
        thuật. Ngoài ra, họ còn thể hiện khả năng tâm linh và ngoại cảm. Chính
        thông qua những nét tính cách này mà họ có thể biểu lộ bản tính của
        mình. Người Đinh Mùi là người sành ăn và có biết cách thưởng thức các
        món ăn ngon, và có xu hướng thiên về nghệ thuật và triết học.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có khả năng danh tiếng và tài lộc và cũng xuất sắc trong việc quản lý
        tiền bạc. Những tố chất này giúp họ có cơ hội để làm được nhiều thứ mà
        không cần phải quá lo lắng, đây cũng là lý do mà họ tin rằng không gì là
        không thể.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù có vài trở ngại ngăn cản thành công của người Đinh Mùi, chìa khoá
        là họ cần tập trung vào một mục tiêu trong một thời điểm. Điều này cho
        phép họ tập trung năng lượng khi cần thiết và khám phá ra vị ngọt của
        thành công. Những giấc mơ bay bổng thành hiện thực khi họ tập trung vào
        sự siêng năng và làm việc vất vả.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Mùi cũng là người học hỏi rất nhanh. Họ có thể hấp thu kiến
        thức nhanh chóng và biết cách biến nó thành thực hành. Do đó, bạn sẽ
        không tìm thấy người Đinh Mùi học vẹt hay chỉ nghiên cứu hàng đống sách
        vở. Ngược lại, họ quen học bằng cách quan sát, thực hành cho đến khi họ
        làm chủ kỹ năng của mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Có tài năng giỏi trong việc sử dụng các công cụ, người Đinh Mùi là những
        người thợ giỏi trong việc tự tay làm mọi thứ và đơn giản là biết cách
        cải thiện chức năng cho đến khi phù hợp. Theo đó, họ bị tò mò và kích
        thích bởi ứng dụng khoa học và điều này thúc đẩy họ đạt được kiến thức
        và kinh nghiệm trong những lĩnh vực này. Nhiều người Đinh Mùi bị cuốn
        hút bởi các công cụ công nghệ cao như kỹ thuật, vũ khí, máy tính hay
        camera.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Cách duy nhất để họ có thể tận dụng tốt nhất khả năng sáng tạo là cần
        phải biết họ muốn gì. Người Đinh Mùi dành phần lớn thời gian trong đời
        nghiên cứu vì sự sống. Hầu hết mọi người chỉ khám phá ra điều này khi
        vào độ tuổi trung niên. Họ có thể đạt được thoả mãn trong cuộc sống. Họ
        sẽ làm điều này bằng cách tạo ra và duy trì tầm nhìn rõ ràng đối với mục
        tiêu của mình. Sau đó họ cần phải vạch ra kế hoạch để đạt được.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trong số những người bạn bè, họ được biết đến là người sẵn lòng giúp đỡ
        vì Đinh Mùi có bản tính thích quan tâm và cho đi. Họ quen với việc giúp
        đỡ người khác. Với bản tính thích giúp đỡ, họ có nhiều bạn bè trân trọng
        tính cách này và thích ở bên cạnh họ.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Mùi không bỏ lỡ bất kỳ cuộc vui nào mới nhất vì họ thường
        được xem là trung tâm của các buổi tiệc. Bản tính thích vui của họ dễ
        lây lan và họ thích ở bên cạnh những ngươi có cùng bản tính tương đồng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Cũng như tiếng lách tách của đám lửa đang cháy, người Đinh Mùi ồn ào và
        thích nói nhiều hơn những người khác. Họ giỏi pha trò và thích ở bên
        cạnh những người vui vẻ giống họ. Nói chung, họ có khả năng hùng biện và
        họ thường sử dụng điều này trong các cuộc nói chuyện.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngoài ra, họ cần phải tránh bản tính không thích ngồi yên. Nếu không, họ
        sẽ dễ rơi vào tâm trạng hống hách hoặc cảm xúc trồi sụt. Điều này sẽ
        khiến nhiều người đánh giá là họ dễ nóng tính.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trong lúc họ thất vọng hay buồn bã, họ có thể muốn “giấu đầu trong cát”.
        Điều này thể hiện xu hướng muốn trốn tránh hoặc tự gậm nhấm nỗi buồn. Họ
        thật sự có thể tránh những điều này bằng cách tin vào bản năng mạnh mẽ
        của mình. Bằng cách này, họ có thể tận dụng những sự cố bất ngờ thay vì
        phản ứng theo cách tiêu cực.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù giỏi trong vấn đề tiền bạc, những người có ngày sinh này không
        may mắn khi xét vấn đề sinh con. Họ có thể mất nhiều thời gian để có thể
        thụ thai. Một khi họ có con thì mối quan hệ tình cảm cũng dễ căng thẳng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Của Người Đinh Mùi:</b> chuyên quyền, quyết tâm, sáng
        tạo, trực giác, nhanh nhạy, hài hước, nói nhiều.
      </p>

      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Không phải dễ dàng để dự đoán chính xá sự nghiệp mà người Đinh Mùi sẽ
        theo đuổi. Bản tính thích đi lại và thay đổi khiến cho họ muốn trải
        nghiệm nhiều công việc khác nhau. Khi họ chọn lựa nghề nghiệp nào, họ
        luôn trung thành và tận tâm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ thích khởi sự các dự án và luôn tìm cách để sử dụng đầu óc sáng láng
        của mình. Họ thích quan điểm rằng cần suy nghĩ lớn, đó là lý do họ hoàn
        hảo trong việc khởi sự doanh nghiệp. Luôn làm việc chăm chỉ, họ tự ném
        mình vào những dự án mà họ yêu thích và dấn thân đến khi hoàn thành.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Phương thức thẳng thắn và cách thức làm việc chủ động giải quyết vấn đề
        khiến họ trở thành tài sản cho bất kỳ một công ty nào. Những nét tính
        cách này chắc chắn rằng họ sẽ không lãng phí thời gian mà luôn đi thẳng
        trực tiếp vào mục tiêu và đạt được nó. Họ thật sự tham vọng và có ham
        muốn mạnh mẽ về đích đến mà họ muốn đạt được. Họ cũng được ban cho kỹ
        năng lãnh đạo tuyệt vời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có tài năng nghệ thuật bẩm sinh có thể khiến việc lựa chọn sự nghiệp
        liên quan đến âm nhạc, hội hoạ, nhiếp ảnh, kịch nghệ hay thậm chí cả võ
        thuật. Điều này làm thoả mãn ham muốn được tiếp xúc với vinh quang,
        thắng lợi, thẩm mỹ, phong cách và sự hào nhoáng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bất kỳ công việc thủ công nào đòi hỏi sự khéo tay đều được người Đinh
        Mùi khao khát, đặc biệt bởi vì họ giỏi. Thủ công, điêu khắc, nghề mộc
        hay bất cứ công việc nào bao gồm nhu cầu và sử dụng đôi tay khéo léo đều
        thách thức họ và khiến cho họ có nguồn cảm hứng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Mùi mang theo sao Thiên Y trong trụ ngày, biểu thị tài năng
        xuất sắc trong lĩnh vực chữa trị như bấm huyệt, châm cứu, mát xa. Nếu
        dành đủ thời gian và thực hành, họ có thể gia tăng kiến thức trong những
        lĩnh vực này và trở thành bậc thầy.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Mùi giao tiếp khéo và hiểu được tầm quan trọng của giá trị.
        Những tính cách này, cộng với kỹ năng lãnh đạo, sẽ khiến cho họ đột phá
        trong bất kỳ vị trí nắm quyền nào, bất kể là lĩnh vực nào.{" "}
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Công Việc:</h4>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Với tham vọng, trực giác và bản tính nói thẳng, người Đinh Mùi thích hợp
        với rất nhiều công việc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ yêu thích kiến thức và thông tin, kết hợp với khả năng truyền cảm
        hứng cho người khác, sẽ khiến họ dễ trở thành huấn luyện viên hay giảng
        viên tuyệt vời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khả năng biểu diễn hay giải trí có thể dễ dàng giúp họ trong thành công
        ở lĩnh vực viết lách, nghệ thuật, âm nhạc hay diễn xuất. Bởi vì họ khả
        ái và yêu thích thách thức về tập luyện thể chất, họ sẽ giỏi trong lĩnh
        vực thể thao và võ thuật.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu những người này muốn trở thành nhà đàm phán, họ dễ thành công vì sự
        thấu hiểu con người là bản năng của họ và nói thay người khác những gì
        họ nghĩ trong đầu. Khả năng hiểu thấu người khác giúp họ có thể làm tốt
        công việc cố vấn. Khả năng này, cộng với kỹ năng giao tiếp, có thể khiến
        họ thành chuyên gia tâm lý, chuyên gia trị liệu hay cố vấn tuyệt vời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ làm việc tốt nhất khi có một lý tưởng xứng đáng, cũng là lý do vì sao
        người Đinh Mùi nên cân nhắc trở thành phát ngôn viên cho một dự án phi
        lợi nhuận hay từ thiện. Họ cũng có thể làm trong lĩnh vực cải cách xã
        hội hay chăm sóc trẻ em.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bản tính rộng lượng giúp cho họ phù hợp với những lĩnh vực trên.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Mùi có thể tham gia vào các lĩnh vực kinh doanh và sẽ thành
        công với vai trò của nhà tổ chức, lãnh đạo, quản lý hay giám sát. Họ
        không giỏi tuân thủ theo mệnh lệnh do đó nên cân nhắc trở thành chủ
        doanh nghiệp. Họ thích quyền lực và sẽ thích chia sẻ nó bằng cách phân
        quyền.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Với năng lực nhìn thấy được cơ hội trong kinh doanh, họ có thể thành
        công trong lĩnh vực bán hàng, bất động sản, truyền thông, quảng cáo,
        tiếp thị và quan hệ công chúng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ nên cẩn trọng trước khi nắm giữ vị trí nào trong lĩnh vực bán hàng.
        Nếu họ không tin vào sản phẩm thì họ rất khó bán nó cho ai, do đó cơ hội
        để họ trở nên xuất sắc cũng sẽ rất mong manh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Trong Sự Nghiệp - Công Việc:</b> tham vọng, ngoại
        giao, sáng tạo, thực dụng, nhiệt huyết, thiếu quyết đoán, tâm trạng thất
        thường, trì hoãn, hấp tấp.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Hôn Nhân - Quan Hệ :{" "}
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ khá lãng mạn và tình cảm cũng như có nhiều cảm xúc mạnh mẽ và ước
        vọng lớn. Họ không thích gì hơn là trải nghiệm cuộc sống đơn giản với
        người bạn đời chung thuỷ có thể đem đến cho họ những kích thích trí óc.
        Họ cố gắng tìm được người bạn đời thật sự và sẽ đắm đuối trong tình yêu.
        Một khi họ tìm được tình yêu thật sự, họ sẽ chung thuỷ với người kia bất
        kỷ điều gì.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi xét đến vấn đề của trái tim, họ không tự tin lắm vào bản thân. Đôi
        khi họ không chắc lắm về cảm xúc hay người mà họ yêu, có thể khiến cho
        họ bất an. Họ cảm thấy khó khăn để tìm ra được người có thể phù hợp với
        mong đợi. Nếu người bạn đời không sống đúng như với lý tưởng lãng mạn
        của họ, họ dễ dàng nổi giận. Bởi vì điều này, nhiều mối quan hệ của chỉ
        là tình cảm tri kỷ chứ không thật sự yêu đương.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Mùi quyến rũ, hấp dẫn và nồng ấm. Vì lẽ này, họ dễ dàng cuốn
        hút nhiều bạn bè và người hâm mộ. Họ rất nổi tiếng vì sự rộng lượng,
        giỏi giao tiếp và cách nói chuyện thu hút.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thông thường, họ thích ở gần những người cũng siêng năng và thành công
        như họ. Họ luôn thích cải thiện bản thân, họ luôn tìm kiếm người thông
        minh và nhận thức bản thân.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Là người bẩm sinh đồng cảm, họ sẵn lòng hi sinh vì bạn bè. Cùng lúc, họ
        cũng mong đợi sự chung thuỷ và hi sinh từ bạn bè.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trung thành và luôn ủng hộ người khác, người Đinh Mùi có thể thường
        xuyên hi sinh bản thân và do đó đôi khi nên hơi có thái độ “mặc kệ” sẽ
        tốt hơn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày này sẽ luôn dành tình cảm cho gia đình, bất kể về tình
        cảm hay tài chính. Thông thường họ cảm thấy có nhu cầu chứng tỏ tình yêu
        và quan tâm người nhà. Họ gây ảnh hưởng tích cực lên người khác và
        thường không nhận ra.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ thường có quan hệ tình cảm vợ chồng tốt, nhưng cần phải tìm sự cân
        bằng tinh tế giữa cam kết và độc lập. Một khi họ thiết lập được sự cân
        bằng, thì họ có thể cho đi tình yêu rộng lượng hơn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đôi khi họ có thể ghen tuông với vợ hay chồng. Họ cần tránh điều này vì
        nó sẽ làm huỷ hoại nền tảng gia đình. Hãy học cách hạnh phúc vì thành
        công của người kia, đặc biệt nếu đó là người bạn thương yêu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Trong Tình Yêu - Hôn Nhân:</b> lý tưởng, chung thuỷ,
        quyến rũ, hào phóng, tử tế, thích xã giao.{" "}
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Mùi toạ Hoả Khố và Thông Căn ở Địa Chi. Trụ này cũng toạ Thực
        Thần, biểu thị xinh đẹp, duyên dáng, là người khôn ngoan và thích ẩm
        thực. Điều này cũng biểu thị người nữ Đinh Mùi sẽ là người vợ đức hạnh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Mùi là người suy nghĩ độc lập. Mặc dù họ là người truyền
        thống, họ không ngần ngại phát triển cá tính riêng và muốn thiết lập
        luật chơi của mình. Thường có khả năng gây ấn tượng và gợi cảm hứng cho
        người khác với ý tưởng của riêng mình và hướng sáng tạo, họ độc đáo, có
        khả năng thích nghi và có tài giao tiếp và sử dụng ngôn từ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Mùi có nhu cầu sáng tạo thể hiện bản thân. Có vẻ như họ sẽ
        hưởng lợi nhiều từ sự sáng tạo hướng đến thực dụng, trong việc theo đuổi
        mục tiêu và tham vọng. Họ khao khát thành công vật chất và sự cân bằng
        hơn bất cứ điều gì khác. Vì họ siêng năng và giàu kiến thức, họ có thể
        xây dựng được nền tảng vững chắc để biến cảm hứng thành thành tích hữu
        hình. Họ có khiếu kinh doanh tốt và là người theo chủ nghĩa cầu toàn vì
        có mục tiêu trong tâm trí.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Vì họ cần sự ổn định, người Đinh Mùi cũng có thể không yên ổn vì sự kết
        hợp giữa sao ẩn Thiên Ấn và Tỷ Kiên. Họ rất năng động và có vẻ hơi thiếu
        trách nhiệm vì họ đến và đi khi thích. Người trụ ngày này rất yêu thích
        niềm vui và họ thích trải nghiệm mới và đi lại. Họ phải cố gắng giữa kỹ
        luật và kiên trì nếu họ muốn biến giấc mơ thành hiện thực.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Mùi ấm áp, vui vẻ và chân thành. Thích quảng giao và hướng
        ngoại, họ thích tham gia vào các hoạt động tập thể. Họ giỏi nhất khi
        tràn đầy sức sống, họ có thể sử dụng sự nhạy cảm và trực giác để giúp đỡ
        và hỗ trợ những người thân yêu.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi họ cảm thấy tiêu cực, họ sẽ trở nên bất an. Họ không thích bị chỉ
        trích. Họ có thể trở nên quá phụ thuộc vào bạn bè và gia đình và thậm
        chí khá kiểm soát môi trường và những người thân xung quanh. Họ có thê
        khá rụt rè và kín kẽ cũng như lo âu trong những lần đi mạo hiểm thử
        thách mà họ dấn thân vào.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đinh Mùi toạ Ấn Tinh và Thông Căn. Điều này biểu thị sức khoẻ, sức sống
        và tuổi thọ tốt. Hoả sinh ra Thổ và Thổ nhận hơi ấm từ Hoả. Mặc dù kết
        hợp này tạo ra khói nhưng nó không tạo ra lửa nguy hiểm. Lửa yếu này có
        thể kéo dai dẳng trong một thời gian dài. Điều này biêu thị người này sẽ
        có khả năng bền bỉ và sức mạnh ý chí kéo dài. Bởi vì hành Thổ là Thực
        Thần biểu thị người này thông minh, nhưng khá kiêu ngạo và kín kẽ.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Lá số Bát Tự sẽ tốt nếu như không có Xung với Sửu. Nếu có Sửu trong lá
        số thì biêu thị người Đinh Mùi sẽ bị ức hiếp bởi vợ, người này sẽ hành
        động làm hỏng cơ hội thành tích của người Đinh Mùi.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tốt nếu trụ Đinh Mùi gặp nhiều Kim, Thuỷ và Mộc trong đại vận vì biểu
        thị cơ hội về thành công tài chính và cá nhân lớn hơn. Tuy vậy, bất lợi
        nếu gặp quá nhiều Hoả và Thổ trong đại vận. Điều này có thể biểu thị lá
        số này quá khô và nóng, hình ảnh của hạn hán và đói kém do quá nóng gây
        ra.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ất Mộc ở trụ ngày là cung Phu Thê, nhấn mạnh đến xu hướng gần gũi gia
        đình của người Đinh Mùi. Trong môn Bát Tự thì bất lợi nếu hành Thuỷ bị
        nhiễm bẩn bởi Thổ. Trong tình huống này, nước không thể bị nhiễm bẩn vì
        Ất Mộc đóng vai trò như màng lọc, làm nước sạch, và gián tiếp gia tăng
        sức mạnh tổng thể cho lá số. Điều này biểu thị vợ/chồng giúp đỡ nhiều
        cho người này.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Mùi có xu hướng không giàu có như người Đinh Sửu bởi vì Sửu
        đại diện cho rương vàng. Cũng khó cho người Đinh Mùi để tiết kiệm tiền,
        vì không có hình thành Tam Hợp Hợi Mão Mùi chứa bất kỳ Tài Tinh nào.
        Người Đinh Mùi có thể tiết kiệm tiền nhưng họ thường có xu hướng xài
        tiền quá nhanh. Người Đinh Mùi được khuyên nên lập ra kế hoạch đầu tư và
        ngân sách nếu họ muốn phát triển tài chính về lâu dài và ổn định.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ra vào mùa Xuân và Hạ có cơ hội lớn hơn để thành công nếu họ
        có thể tiết kiệm và chăm chỉ. Người sinh vào mùa Thu và Đông có thể tự
        khởi nghiệp kinh doanh. Nếu họ sinh vào ban ngày thì có khả năng chịu
        bệnh kinh niên sau tuổi 40. Sinh vào ban đêm thì dễ hưởng thụ cuộc sống
        tốt khi về già. Không tốt cho người sinh tháng Sửu vì xung, biểu thị
        cuộc sống nhiều thách thức và trở ngại.
      </p>
    </div>
  );
};

export const DinhTy = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Đinh Tỵ: rất yêu vẻ ngoài và diện mạo của mình, có quan
        hệ tình cảm yêu đương phức tạp, muốn có tình cảm lãng mạn - drama -
        thăng trầm trong đời, có độ tập trung cao khi làm việc, đánh giá cao sự
        trân trọng từ người khác, sẽ dùng mọi cách để đạt được mục tiêu nhưng
        lại thường không thoả mãn. Tỏ ra điềm đạm nhưng rất đa nghi. Nếu là nữ
        giới, thường thích thơ ca và nghệ thuật. Nếu là nam giới, có vợ nhan sắc
        dưới trung bình.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Một quả cầu lửa vụt sáng qua bầu trời....bầu trời vụt sáng như có hàng
        triệu ánh sáng mặt trời, mắt chúng ta ta mờ đi vì ánh sáng rực rỡ. May
        thay đây chỉ là một ngôi sao băng. Hàng triệu hòn lửa đó trong không
        gian cuộn tròn khuấy động cảm xúc của chúng ta nhưng không gây ra thiệt
        hại nào đáng kể.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đinh Hoả là những hành mà chúng ta gọi là sự thay đổi. Nó hoạt động hiệu
        quả bằng cách đem lại ánh sáng trong bóng tối cho mọi người xung quanh.
        Một người sẽ không thể nói rằng không cần lửa, từ khi nó đuọc khám phá
        ra từ thời đồ đá. Chúng ta nấu ăn, săn bắn, giữ ấm, bảo vệ và thậm chí
        tạo tác với lửa. Đây là hành thật sự thay đổi cuộc sống của nhân loại.
        Khi Đinh Hoả xuất hiện, bạn không thể cưỡng nổi sự quan tâm và muốn biết
        xem nó đem lại sự hi vọng nào vào trong cuộc đời bạn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Đinh Tỵ tựa như những viên ngọc trai toả sáng lấp lánh.
        Họ có thể tỏ ra là một tia ánh sáng giữa đại dương nhièu màu sắc nhưng
        không có nghĩa là họ không thể toả sáng và gây ấn tượng. Họ thường là
        người rất đẹp. Họ cũng có tính độc lập và quyết tâm cao, người Đinh Tỵ
        giỏi nhất khi bị thách thức và đương đầu, nơi họ có thể thể hiện nhiều
        tài năng giúp họ đạt nhiều thứ vĩ đại trong đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Tỵ suy nghĩ cầu tiến, tưởng tượng và sáng tạo cũng như thích
        những ý tưởng mới và thú vị. Họ sẽ chọn con đường người khác ít đi vì sự
        thách thức và cảm hứng mà nó mang lại. Một khi đam mê kèm với mục tiêu
        trong đầu và một nhiệm vụ phải hoàn thành, người Đinh Tỵ sẽ có kỷ luật
        và kiên trì, làm việc chăm chỉ và có sức bền để vượt qua mọi thử thách.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Có óc thực tế, chính xác và lý trí, người Đinh Tỵ thường sẽ phân tích
        mọi thông tin trước khi rút ra kết luận logic. Họ có đầu óc sắc bén và
        rất khôn ngoan, nhận thức tốt và có óc quan sát, họ thường học từ kinh
        nghiệm và họ là thiên tài trong giải quyết vấn đề theo cách sáng tạo và
        mới mẻ cũng như biết cải thiện các hệ thống tân tiến hơn. Họ thường tham
        gia vào những dự án mới và tìm kiếm những chân trời tri thức mới.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Linh hoạt, họ thường rất thích giao tiếp cũng như có tài trong nhận
        định. Họ có cách giao tiếp kết nối xuất sắc và có thể hoà nhập vào môi
        trường nhiều loại người. Họ sáng suốt và có khả năng diễn đạt bản thân
        một cách nhanh nhạy và cả nhạy cảm. Họ là người có lòng tốt, thấu hiểu
        và chấp nhận những người khác họ. Họ thường có thành công lớn khi làm
        việc với người khác trong một đội nhóm. Họ là những lãnh đạo thật sự gợi
        cảm hứng. Với nhu cầu giao tiếp xã hội cao, họ thích môi trường thay đổi
        và sự kích thích để khơi gợi những ý nghĩ và cách làm mới mẻ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tuy nhiên, người Đinh Tỵ đánh gái cao sự độc lập bản thân và sẽ có cách
        sống riêng mặc dù có thể cách sống đó không hợp với phần đông xã hội. Họ
        có nhu cầu sâu sắc cần thể hiện bản thân và có thể đôi khi khá nổi loạn.
        Họ đôi khi cứng đầu và thường cần phải cố để vượt qua xu hướng trở nên
        quá duy ý chí.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Tỵ có kẻ thù lớn nhất là khả năng tập trung kém. Họ dễ bị lôi
        kéo theo nhiều hướng khác nhau vì quan tâm quá nhiều thứ và có thể dàn
        trải bản thân quá rộng. Sẽ khôn ngoan hơn nếu họ tìm ra được 1 lý tưởng
        và tập trung vào nó để có thể đạt được thành công và giàu có mà họ xứng
        đáng hưởng. Ngoài ra, nếu như quá kích thích có thể dẫn họ đến việc tham
        gia vào quá nhiều việc, sự buồn chán có thể sinh ra hiệu ứng ngược và họ
        có thể ngủ quên trên chiến thắng. Điều này có thể là do sự thiếu quyết
        đoán gây ra vì phân tích thông tin quá nhiều và sẽ không ngoan hơn cho
        người Đinh Tỵ nếu tin tưởng vào bản thân và trực giác của họ.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sự cầu toàn có thể thúc đẩy người Đinh tỵ đặt tiêu chuẩn quá cao và có
        thể đánh giá bản thân và những người xung quanh quá cẩu thả. Họ có thể
        khá nhạy cảm và dễ trầm cảm nếu họ ép bản thân quá đáng. Họ có thể có
        tâm trạng trồi sụt và nếu không được thoả mãn, họ có thể đè nén cảm xúc
        và có xu hướng quá hưởng thụ hoặc chìm vào mộng tưởng như một phương
        tiện giải thoát. Họ có nhu cầu an toàn cá nhân và tình yêu, cảm xúc và
        muốn bản thân luôn trẻ trung. Sự theo đuổi tâm linh và dành nhiều thời
        gian hướng nội và sự phát triển bản thân có thể giúp họ tìm được bình an
        và có niềm tin vào bản thân.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Tỵ thường bị cuốn hút vào những thứ đẹp đẽ trong đời và họ
        biết cách hưởng thụ bản thân và tạo niềm vui cho người khác. Với phẩm
        giá và sự nhận thức rõ về chân dung hình ảnh của mình, họ chịu khó làm
        đẹp và thích thời trang. Họ yêu thích sự xa xỉ và thích có mái ấm đẹp
        đẽ. Sự yêu thích hàng hiệu và cuộc sống tốt có thể đồng nghĩa họ sẽ trải
        qua sự lo lắng về tiền bạc và những nỗi lo này thường ngắn hạn và họ có
        cơ hội để thành công trong đời. Nếu lên kế hoạch và tiết kiệm vào lúc sa
        cơ, người Đinh Tỵ sẽ có thể đạt được ổn định tài chính bền vững.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù họ có thể hấp tấp, người Đinh Tỵ cũng chung thuỷ và yêu thương và
        họ sẽ gánh vác trách nhiệm gia đình rất nghiêm túc. Họ sống lý tưởng và
        hi sinh vì người mình yêu, họ cũng rất từ bi và chân thành và chú trọng
        đến những mối quan hệ gần gũi. Họ cũng cần phải tránh việc kiềm kẹp bản
        thân và xen vào đời sống của những người thân yêu quá nhiều và bằng cách
        làm như vậy sẽ ngăn bản thân họ học hỏi và phát triển chính mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Của Người Đinh Tỵ: </b>sáng tạo, nhiệt tình, tâm
        linh, suy nghĩ thấu đáo, xã hội, ý tưởng độc đáo, thực tế.
      </p>

      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Tỵ có trách nhiệm, quyết tâm cao và bản tính thích ganh đua
        nên họ sẽ là tài sản quý giá trong bất kỳ môi trường nào. Họ là người
        suy nghĩ độc đáo và thường có cách tư duy vượt khuôn khổ cũng như tìm
        giải pháp sáng tạo cho các vấn đề phức tạp. Họ có khiếu kinh doanh nhạy
        bén và cặp mắt chi tiết. Họ cũng thường tham vọng, làm việc chăm chỉ tận
        tuỷ và có kỷ luật khi họ đặt ra một mục tiêu và thường có niềm kiêu hãnh
        trong công việc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Có tài giao tiếp cùng năng khiếu về tâm lý và sự duyên dáng và gợi cảm
        hứng cho người khác, người Đinh Tỵ thường thích hợp làm việc trong tổ
        chức và hợp tác. Họ có ý thức rõ về hình ảnh của mình và sẽ nỗ lực xây
        dựng vẻ ngoài chuyên nghiệp khiến họ rất phù hợp với các công việc liên
        quan đến khách hàng. Tuy nhiên vì họ khá miễn cưỡng trong việc nhận mệnh
        lệnh và thường khá độc lập, họ tốt hơn nên chọn vị trí quản lý giúp cho
        họ có tự do trong việc thể hiện cá tính. Tính thực tế và khả năng lên kế
        hoạch giúp họ tất cả kỹ năng cần thiết để tạo ra phương pháp để quản lý
        những dự án lớn. Họ có thể chọn cách làm việc độc lập và thiết lập cơ sở
        kinh doanh để hưởng lợi từ sự tự do trong công việc.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Tỵ đa tài và họ sẽ thể hiện vai trò tốt nhất khi họ được
        thách thức. Sự buồn chán có thể dẫn họ đến năng lực thể hiện kém nếu tài
        năng không được tận dụng do đó rất quan trọng nếu họ chọn đi theo con
        đường sự nghiệp cho phép thay đổi và tiến bộ thường xuyên.{" "}
      </p>

      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Công Việc:</h4>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Tỵ có khả năng thành công trong vô số công việc. Vì có khả
        năng thấu hiểu con người và kỹ năng giao tiếp xuất sắc, họ có thể thành
        công trong kinh doanh, tiếp thị hay giao tiếp công chúng và khả năng
        điều hành và sự cầu toàn sẽ giúp họ tiến bộ nhanh đến vị trí quản lý nơi
        họ chắc chắn có thành tích xuất sắc. Đồng thời, họ cũng có thể chọn thêm
        vào kỹ năng giao tiếp của họ sự sáng tạo và trở thành nhà văn hoặc nhà
        báo. Họ cũng thích kịch tính và có con mắt thẩm mỹ giúp họ có thể sử
        dụng sự tưởng tượng cho những thể hiện sáng tạo trong biểu diễn nghệ
        thuật và khâu hình ảnh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngoài sự sáng tạo, người Đinh Tỵ cũng rất thích chính xác và họ có đầu
        óc điềm tĩnh, phân tích và khả năng giải quyết khó khăn xuất sắc. Họ có
        thể tìm thấy sự kích thích trong nghiên cứu, công nghệ thông tin đặc
        biệt nếu họ có khả năng đi đầu sáng tạo. Họ cũng chú ý nhiều đến tài
        chính và ngân hàng. Ngoài ra, u hướng quan tâm đến con người có thể giúp
        họ tìm sự nghiệp trong ngành giáo dục, tâm lý học hay xã hội học. Chính
        trị có thể giúp họ như là sự giãi bày cho lý tưởng nếu họ tiếp cận nó
        với đôi mắt khao khát sự thay đổi hệ thống từ bên trong. Họ cũng có thể
        tìm cảm hứng thoả mãn nếu sử dụng đầu óc sáng tạo trong lĩnh vực công và
        theo đuổi những gì tốt đẹp to lớn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Trong Công Việc - Sự Nghiệp:</b> có trách nhiệm, có
        khiếu kinh doanh, đa tài, không thoả hiệp, người cầu toàn, cứng đầu, cẩu
        thả, thích phê bình.{" "}
      </p>

      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Hôn Nhân - Quan Hệ - Gia Đình:{" "}
      </h4>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Tỵ là bạn đời chung thuỷ và có thái độ nghiêm túc khi nói về
        tình yêu. Họ có cách yêu đặc biệt và sẵn lòng nỗ lực để đem đến hạnh
        phúc. Khi họ sẵn sàng để lập gia đình, họ sẽ rộng lượng, cho đi và yêu
        thương người vợ/chồng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sự ổn định và an toàn rất quan trọng đối với cuộc đời họ, đó là lý do
        tại sao tiền bạc và tài chính đóng vai trò quan trọng trong tiêu chí
        chọn bạn đời. Họ có khao khát ở bên cạnh những người thành công và độc
        lập hơn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người khác sẽ cảm thấy khó đạt sự mong đợi cao của Đinh Tỵ và có
        thể khó chịu với sự cứng đầu của họ. Đôi khi, người Đinh Tỵ khó lòng thể
        hiện bản thân. Vì lẽ này, họ có thể khó nói lên hết những gì họ thật sự
        cảm thấy. Họ có vẻ lạnh lùng và xa cách.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đối với bạn bè thì người Đinh Tỵ thân thiện, ấm áp và thích xã giao.
        Những người khác thích ở gần họ bởi vì họ có óc nhạy bén và biết cách
        tạo niềm vui. Nhiều người muốn làm bạn với họ, mặc dù họ khá chọn lọc
        người mà họ tin tưởng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Tỵ thường bị cuốn hút đến những người quan tâm đến phát triển
        bản thân. Họ thường biến những cuộc xã giao thông thường thành dịp học
        hỏi vì họ luôn thích tìm kiếm những kích thích về trí óc. Họ thích tập
        hợp những người có góc nhìn phi chính thống và đón nhận những ý tưởng
        mới và độc đáo. Ngoài ra, họ luôn tìm cách trao đổi thông tin và kiến
        thức.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Một khi người Đinh Tỵ kết bạn với ai thì không có giới hạn trong việc họ
        giúp đỡ bạn bè. Họ phải cẩn thận không quá dàn trải bản thân và bỏ ra
        quá nhiều năng lượng. Nếu như vậy, họ sẽ trở nên quá bận tâm đến rắc rối
        của người khác đến mức quên mất bản thân mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có xu hướng đôi khi trở nên cứng đầu, tính toán và bí mật, điều họ có
        thể bỏ qua khi họ gặp gỡ người mới. Nếu họ muốn giữ mối quan hệ bạn bè
        và gây ấn tượng tốt, họ cần nỗ lực vất vả để ngăn cản điều này xảy ra.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Tỵ đánh giá cao sự bình an bên trong và hoà hợp gia đình. Do
        đó, họ thích một gia đình bình yên và hạnh phúc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có thể cần tìm một sự cân bằng hoàn hảo giữa đam mê giành sự thân mật
        cho gia đình và khao khát sự tự do. Sự tự do quan trọng nếu họ muốn ở
        trong mối quan hệ yêu đương. Để có thể thoả mãn sự tự do, họ cần dành
        chút thời gian cho bản thân để có thể tái tạo năng lượng và sức mạnh.
        Bạn đời của người Đinh Tỵ nên tôn trọng sự yêu tự do của họ nếu họ cần
        một chút không gian cho bản thân mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Trong Tình Yêu - Quan Hệ:</b> nhạy cảm, nhân đạo,
        thấu hiểu, quan tâm, cần sự an toàn.
      </p>

      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:
      </h4>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bính Hoả ở trong tàng can của Đinh Tỵ. Vì Đinh là Âm Hoả và Bính là
        Dương Hoả, trụ này biểu thị khả năng sáng sủa và phát sáng của hành Hoả.
        Đinh Tỵ rất thích triết lý và họ có thể đi tìm kiếm sự gíac ngộ và thấu
        hiểu quy luật tự nhiên. Người Đinh Tỵ cũng rất từ bi với sự yêu thương
        giúp đỡ người khác và điều này gợi ý rằng họ sẽ có khả năng học hỏi và
        soi sáng cho người khác đi theo bước chân của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Tỵ có tài sáng tạo và óc tưởng tượng. Đây là do hiệu ứng của
        Thực Thần trong Tỵ. Họ thích mạo hiểm và gặp gỡ người mới, do Kiếp Tài
        gây ra. Họ luôn nhận thức rằng có nhiều điều mới mẻ cần khám phá, họ
        thích đi ra ngoài kia để tìm hiểu những trải nghiệm, cùng với đầu óc sắc
        bén và đa tài họ có thể tận dụng tất cả những cơ hội gặp được.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Tỵ thật sự cần phải tìm động lực để học hỏi và phát triển, để
        liên tục tiến bộ năng động, chính là nguồn cảm hứng phía sau các thành
        công vĩ đại của họ. Trí óc của họ có thể hấp thu kiến thức mới và có thể
        ứng dụng với tốc độ cực nhanh. Họ thường tự dựa vào bản thân, có tài
        năng và tri thức. Họ có khả năng lãnh đạo bẩm sinh và thích khởi đầu và
        dẫn dắt các dự án mới. Với khả năng nhận thức, lên kế hoạch và suy nghĩ
        tiên phong, họ có những kỹ năng cần thiết để để theo đuổi nguồn cảm hứng
        từ lúc nhận thức đến khi thành hiện thực. Nếu họ có một tiếng gọi bên
        trong, một hành trình cá nhân hay đột phá cần theo đuổi, họ nên can đảm
        để khám phá mà không để cho ai khác hạ thấp ước mơ của mình. Tất cả
        những điều này là do ảnh hưởng kết hợp của Kiếp Tài, Thực Thần và Chính
        Tài.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Đinh Tỵ tự hào về công việc và sẽ làm bất cứ điều gì có thể để đảm
        bảo họ đạt được thành tích trong sự nghiệp. Họ có thể đặt sự nghiệp lên
        ưu tiên và sẽ phán xét bản thân và những người xung quanh dựa trên thành
        tích. Nếu họ không hạnh phúc với định hướng cuộc đời, họ sẽ không ngần
        ngại dừng kế hoạch và tạo những thay đổi cần thiết. Nhanh chóng giải
        quyết được vấn đề, họ cũng có cảm gíac thoả mãn, nếu giải quyết được
        những khó khăn.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trong lúc những người xung quanh có thể xem Đinh Tỵ là người quá tự tin,
        sự thật là họ có thể phải đối mặt với sự bất an bên trong. Họ có thể
        nghi ngờ khả năng chính mình và dễ bị nhụt chí bởi người khác. Họ có thể
        phải vật lộn với cảm gíac lo lắng và cần sự ủng hộ và công nhận.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù họ có lý tưởng và nhạy cảm, họ cũng có thể hời hợt và không công
        bằng. Họ sẽ thấy rằng học hỏi cách để tin tưởng trực giác sẽ giúp họ có
        thể đạt được sự cân bằng trong đối xử với người khác và thế giới nội tâm
        yên bình hơn.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đinh Tỵ toạ trên Dịch Mã, biểu thị di chuyển thường xuyên. Can Đinh Hoả
        được xem là một trong những can Đinh kém sáng nhất vì đứng trên Kiếp Tài
        Tinh. Về bản chất, đó là Bính Hoả ở tàng can nên sáng vượt trội hơn Đinh
        đèn cầy. Điều này biểu thị khả năng Định Tỵ sẽ gây nên nhiều cuộc nổi
        loạn bên trong và tại sao họ có bản tính khá ganh đua và luôn muốn cố
        gắng vượt trội và chứng tỏ bản thân.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu trong lá số có Kỷ Tỵ, Bính Ngọ hay Đinh Mùi ở trụ giờ thì là dấu
        hiệu tốt. Với những điều trên thì người Đinh Tỵ sẽ có thể tìm thấy thành
        công và sự thoả mãn dễ dàng. Điều này bởi vì trụ ngày này củng cố sức
        mạnh của Đinh Hoả trên Bính Hoả, biểu thị chiến thắng bản thân và kiểm
        soát tốt mâu thuẫn bên trong họ. Cần phải lưu ý rằng kẻ thù lớn nhất của
        Đinh Tỵ luôn là bản thân họ, khống chế cảm xúc thường là chìa khoá để
        người Đinh Tỵ tìm được thành công trong đời.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Cũng có lợi nếu gặp nhiều Mộc, Hoả và Thổ trong đại vận. Tương tự, những
        hành này cũng giúp giữ gìn và tăng cường độ sáng của Đinh Hoả ngược lại
        sức mạnh lấn át của Bính Hoả. Những hành này trong đại vận sẽ biểu thị
        giai đoạn may mắn và hạnh phúc. Sẽ bất lợi nếu trụ này gặp nhiều Kim và
        Thuỷ trong Đại Vận. Trong tình huống này, Đinh Hoả sẽ không có đủ năng
        lượng để kiểm soát Kim hay chịu được Thuỷ và họ sẽ đối mặt giai đoạn thử
        thách.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tỵ là Trường Sinh của Canh Kim và là Chính Tài. Nếu Canh Kim xuất hiện ở
        Thiên Can là dấu hiệu của thành công trong sự nghiệp. Nếu Canh Kim không
        xuất hiện ở Thiên Can, thì người Đinh Tỵ sẽ thấy họ trở nên giàu có
        thông qua việc hợp tác và nhờ sự giúp đỡ và hỗ trợ của người khác. Điều
        này bởi vì Tài Tinh được đặt ở vị trí cùng với Kiếp Tài.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu người Đinh Tỵ sinh vào tháng mùa Xuân hay Hè thì họ sẵn có may mắn
        và thịnh vượng. Sinh vào mùa Thu và Đông thì họ sẽ có óc kinh doanh sắc
        bén và dễ đạt thành công tự thân. Người Đinh Tỵ sinh ban ngày có thể
        được nhiều may mắn thông qua đầu tư và kinh doanh. Người sinh ban đêm có
        thể có đời sống hạnh phúc và viên mãn. Không tốt cho người Đinh Tỵ sinh
        vào tháng Mùi, vì có nhiều trồi sụt về tài lộc trong đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đinh toạ trên Tỵ mang theo năng lượng của Bính Hoả Kiếp Tài và Mậu Thổ
        Thương Quan, cộng với Canh Kim Chính Tài. Khả năng lãnh đạo là một phẩm
        chất tốt mà họ có được nhờ Kiếp Tài. Họ phô diễn được bản tính cạnh
        tranh môt cách rõ ràng nhất. Họ không ngần ngại trước bất kỳ cơ hội nào
        để chiếm lấy sân khấu vì họ hiểu được rằng vì họ muốn ảnh hưởng đến càng
        nhiều người càng tốt. Thương Quan cho họ sự can đảm để bứt phá khỏi đám
        đông đi ngược lại những thực thể to lớn. Trong khi những tiếng ồn ào
        vang lên, họ cũng có được sự cần cù do Chính Tài giúp có thể im lặng
        thực hiện kế hoạch của mình mà không tỏ ra mệt mỏi hay giận giữ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đối với nam Đinh Tỵ, sự xuất hiện của Chính Tài và Kiếp Tài biểu thị
        quan hệ tình cảm không ổn định. Ngay cả đối với nữ giới thì Thương Quan
        cũng sẽ khiến xua đuổi đàn ông đi. Lời cam kết hướng đến việc thay đổi
        những người khác quá mạnh mẽ đến độ họ thường bỏ lơ nửa kia của mình.
        Cũng như sao chổi vụt sáng băng qua chúng ta, mọi người đều thấy họ
        nhưng không ai tiếp cận được họ.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tỵ sẽ hợp với Thân mang theo năng lượng của Canh Kim Chính Tài, Mậu Thổ
        Thương Quan và Nhâm Thuỷ Chính Quan. Khi sao chỉ người chồng Chính Quan
        gặp Thương Quan, biểu thị gia đạo không hạnh phúc. Ngay cả người đàn ông
        với sao người vợ Chính Tài, Kiếp Tài cũng sẽ khiến rất khó khăn. Người
        đàn ông có thể muốn có một gia đình nhưng thiếu đi sự quyết tâm để giữ
        vững trong khi phụ nữ dành quá nhiều thời gian để cố gắng kiểm soát và
        thay đổi người bạn đời.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tỵ sẽ xua đuổi Hợi mang theo Nhâm Thuỷ Chính Quan và Giáp Chính Ấn. Bởi
        vì sao người chồng Chính Quan bị xua đuổi, đây là dấu hiệu cuộc sống
        người nữ sẽ đối mặt nhiều thử thách trên con đường tình cảm. Điều tệ hại
        hơn là sự từ chối để nhận sự giúp đỡ (Chính Ấn) từ người khác sẽ làm mọi
        việc tồi tệ hơn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đinh Tỵ nói về việc kích động những người khác đồng lòng nhưng lại
        thường có một đời sống cô đơn (đặc biệt là phụ nữ). Họ có nhu cầu thể
        hiện cho người khác thấy nhưng điều này chỉ dẫn đến việc họ cố gắng toả
        sáng hơn mặt trời. Thái độ này sẽ tạo ra nhiều người hâm mộ cũng như có
        thể tạo ra sự quấy rối thường làm phật ý những người đang nắm quyền lực.
        Cũng như sao chổi thường băng ngang qua Trái Đất, mọi người sẽ tụ lại để
        ngắm. Một số người sẽ cầu nguyện lòng thương xót để sao chổi không rơi
        trúng hành tinh Trái Đất.{" "}
      </p>
    </div>
  );
};
