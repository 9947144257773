export const MauThangTi = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        className="p1"
        style={{
          margin: "6px 0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontSize: "18pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          <strong>Ngày sinh Mậu trong tháng Tý</strong>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            <strong>Khái Quát:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì nếu có Quý Thủy hiện lên ở
          Thiên Can thì xem là hình thành cục Chính Tài.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Ngay cả nếu như không có Quý Thủy hiện lên ở Thiên Can thì vẫn có thể
          hình thành cục Chính Tài.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng
          có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy
          ý.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            <strong>
              Dụng Thần Điều Hậu:
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì dụng thần điều hậu là Bính Hỏa
          và Giáp Mộc.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Bính Hỏa là dụng thần số một còn Giáp Mộc là dụng thần thứ hai.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            <strong>
              Luận Giải:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Nếu sinh vào giữa mùa đông thì người này sẽ có lá số cực lạnh.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Do đó, Hỏa sẽ cần để đem lại hơi ấm cho ngày sinh này. Chỉ có Thổ ấm
          nóng mới có thể hỗ trợ cho sức sống vạn vật. Nếu không có hơi ấm thì
          cuộc sống người này sẽ trống rỗng.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Mộc khô sẽ cần để làm suy yếu Thủy nhiều, vốn mạnh trong mùa đông. Mộc
          khô vốn có thể đốt cháy nuôi dưỡng Hỏa khí.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Hỏa không thông căn hay hiện diện ở Trường Sinh thì sẽ vô dụng đối với
          ngày sinh này.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Sẽ không có lợi cho ngày sinh này nếu gặp thêm Kim và Thủy khí. Nếu
          không, ngày sinh này sẽ bị ảnh hưởng bởi sức khỏe xấu, bất kể họ giàu
          có như thế nào.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Nếu gặp Kiếp Tài cùng với Nhâm Thủy lộ thì biểu thị người này có vẻ
          ngoài nghèo túng dù thật sự có giàu bao nhiêu.{" "}
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            <strong>Lưu Ý:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            Bính Hỏa là dụng thần chính cho ngày sinh Mậu Thổ trong tháng Tý với
            Giáp Mộc là dụng thần phụ.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            Nếu không có Bính Hỏa hay ít nhất là Đinh Hỏa, thì cục này sẽ kém
            chất lượng.<span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            Vì Mậu Thổ lạnh giá trong thời điểm này, sẽ không thể sinh ra bất cứ
            gì. Đó là lý do Mậu Thổ sẽ cần Giáp Mộc.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            Khi Nhâm Thủy và Quý Thủy xuất hiện ở Thiên Can, sẽ xuất hiện cục
            Tòng Tài khi có điều kiện hỗ trợ. Khi có cục thành công thì người
            này sẽ có cuộc đời phi thường.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            Khi Quý Thủy xuất hiện ở trụ tháng hay giờ thì sẽ cần hợp với Mậu
            Thổ để sinh ra Hỏa. Tuy nhiên hợp hóa có thành công không phụ thuộc
            phần lớn và điều kiện Địa Chi. Ngày sinh này sẽ có thể sinh ra và
            tích lũy tài lộc, mặc dù tài lộc khó kiếm. Đôi khi phải hi sinh cả
            tính mạng.<span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            Sẽ không tốt nếu Tân Kim xuất hiện ở Thiên Can. Điều này bởi vì Tân
            Kim hợp với Bính Hỏa. Khi điều này xảy ra thì người này sẽ cảm thấy
            khó khăn để đạt bình an và hạnh phúc.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            Khi có nhiều Bính Hỏa ở Thiên Can thì ngày sinh này sẽ có xu hướng
            có cuộc sống đơn giản và bình dị, gần như kiểu ẩn dật hay đạo sĩ.
            Người này sẽ không có khả năng sống hết mình. Trong hoàn cảnh này
            thì ít nhất một Nhâm Thủy phải lộ ra, nếu ngày sinh này muốn đạt
            thành công về vật chất trong đời.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            Khi Bính Hỏa không xuất hiện trong lá số thì Kỷ Thổ có thể dùng để
            thay thế. Trong hoàn cảnh này thì ngày sinh này sẽ may mắn về mặt
            con người, mặc dù họ có thể không thật sự giàu có hay thịnh vượng
            trong đời.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            Khi có Canh Kim và Tân Kim hiện diện trong lá số thì người này có
            thể có địa vị, danh tiếng và quyền lực bất ngờ. Tuy nhiên, khi họ
            thành công thì họ càng càm thấy đau đớn và trống rỗng.
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            <strong>Cách Cục:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Thiên Ấn và Thất Sát:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Nếu có Bính Hỏa và một Giáp Mộc xuất hiện ở Thiên Can thì ngày sinh
          này sẽ thành công trong đời.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Lá số tốt nếu cả Bính Hỏa và Giáp Mộc hiện diện.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Lá số xấu nếu thiếu cả Bính Hỏa và Giáp Mộc, toàn bộ lá số Bát Tự sẽ
          kém cỏi.
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>
                Nếu lá số có Thiên Ấn:
                <span className="Apple-converted-space">&nbsp;</span>
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Nếu Địa Chi hình thành Hỏa Cục thì ít nhất một Nhâm Thủy nên xuất hiện
          ở Thiên Can.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Nếu như vậy thì ngày sinh này sẽ có cuộc sống trung bình, giản dị bất
          kể họ giàu có ra sao.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Lá số tốt nếu như Nhâm Thủy xuất hiện ở Thiên Can.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Nếu không có Nhâm Thủy ở Thiên Can thì ngày sinh này sẽ cô đơn và lẻ
          loi trong đời.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>
                Nếu lá số có Thiên Tài Chính Tài:
                <span className="Apple-converted-space">&nbsp;</span>
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Nếu Bính Hỏa xuất hiện thì người này sẽ có tài lộc dễ dàng.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Nếu có 2 Quý Thủy thì sẽ cạnh tranh để giành hợp với Mậu Thổ sinh ra
          Hỏa, ngày sinh này sẽ vất vả mới đạt thành công trong đời.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Lá số tốt nếu có Bính Hỏa xuất hiện trong lá số Bát Tự. Lá số xấu nếu
          có Tỷ Kiếp xuất hiện.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            <strong>
              Tóm Tắt:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            Nếu thiếu đi Bính Hỏa thì Ngày sinh Mậu này sẽ kém cỏi.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            Nếu có Tỷ Kiếp xuất hiện nhiều và Thổ Khí xuất hiện nhiều ở Địa Chi
            thì chỉ có Giáp Mộc là dụng thần ưu tiên.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            Mộc giúp đem lại sức sống cho Thổ. Điều này được xem là phản sinh.{" "}
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
    </div>
  );
};

export const MauThangSuu = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        className="p1"
        style={{
          margin: "6px 0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontSize: "18pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          <strong>Ngày sinh Mậu trong tháng Sửu</strong>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            <strong>Khái Quát:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì nếu có Quý thủy hiện lên ở
          Thiên Can thì xem là hình thành cục Thiên Tài.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Nếu có Tân Kim hiện lên ở Thiên Can thì hình thành cục Thương Quan.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Nếu có Kỷ Thổ hiện lên ở Thiên Can thì hình thành cục Dương Nhẫn.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng
          có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy
          ý.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            <strong>
              Dụng Thần Điều Hậu:
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì dụng thần điều hậu là Bính Hỏa
          và Giáp Mộc.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Bính Hỏa là dụng thần số một của ngày sinh này trong khi Giáp Mộc là
          dụng thần thứ hai.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            <strong>
              Luận Giải:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Thổ trong tháng Sửu sẽ có xu hướng lạnh giá vì Thủy mạnh trong mùa
          đông. Thổ sẽ không thể sinh ra gì vì thời tiết quá lạnh giá.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Sức ấm và hơi nóng ảnh hưởng đáng kể đến ngày sinh này, để giúp cho
          Thổ có thể sinh ra được gì. Do đó Hỏa là dụng thần điều hậu đối với
          ngày sinh này.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Tháng Sửu tồn chứa Kim Khí, do đó để Mộc được sử dụng hiệu quả, Mộc
          cần có Hỏa Khí. Chỉ với sự kết hợp của Mộc và Hỏa Khí thì mới có thể
          trợ giúp đem lại sự cân bằng cho ngày sinh này.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Không tốt nếu ngày sinh này gặp thêm hành Thủy và Kim.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Điều này bởi vì Hỏa Khí sẽ bị tổn thương hay suy yếu bởi Kim và Thủy
          Khí. Những hành này sẽ có thể gây cho lá số lạnh giá hơn.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            <strong>Lưu Ý:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            Bính Hỏa là dụng thần số một để cho ngày sinh Mậu Thổ trong tháng
            này, với Giáp Mộc là dụng thần thứ hai.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            Nếu không có Bính Hỏa, thì mọi cục đều chỉ xếp thứ hai.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            Sẽ không tốt nếu Tân Kim xuất hiện ở Thiên Can. Sẽ càng bất lợi hơn
            nếu Địa chi tạo thành Kim Cục. Điều này bởi vì Tân Kim hợp với Bính
            Hỏa dụng thần. Nếu như vậy thì ngày sinh này sẽ lạnh giá, không hạnh
            phúc và buồn khổ, mặc cho người này rất tài năng.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            <strong>Cách Cục:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Thiên Ấn và Thất Sát:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Khi có 2 Bính Hỏa và một Giáp Mộc đều xuất hiện ở Thiên Can thì ngày
          sinh này sẽ hưởng thành công lớn trong đời.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Lá số tốt nếu có Bính Hỏa và Giáp Mộc trong lá số.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Lá số không tốt nếu Bính Hỏa và Giáp Mộc hoàn toàn vắng mặt.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>
                Nếu lá số có Bính Hỏa:
                <span className="Apple-converted-space">&nbsp;</span>
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Nếu Địa Chi tạo thành Hỏa Cục, thì ít nhất một Nhâm Thủy sẽ nên hiện
          lên trên Thiên Can.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Nếu như đúng như vậy thì ngày sinh này sẽ có thể sống cuộc đời trung
          bình, giản dị bất kể họ giàu có bao nhiêu.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Lá số tốt nếu Nhâm Thủy hiện lên ở Thiên Can và vắng bóng Tân Kim.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Lá số không tốt nếu không có Nhâm Thủy ở Thiên Can, ngày sinh này sẽ
          cô đơn trong đời. Sẽ tệ hơn nếu Tân Kim xuất hiện bên cạnh Bính Hỏa.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>
                Nếu lá số có cục Chính Tài Thiên Tài:
                <span className="Apple-converted-space">&nbsp;</span>
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Nếu có đồng thời Bính Hỏa và Nhâm Thủy là cách cục mạnh mẽ.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
          }}
        >
          {" "}
          Lá số tốt nếu có Bính Hỏa. Không tốt nếu có Tỷ Kiếp.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            <strong>
              Tóm Tắt:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            Nếu thiếu đi Bính Hỏa thì Ngày sinh Mậu này sẽ không tốt.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontSize: "14pt",
              fontFamily: "arial, helvetica, sans-serif",
            }}
          >
            Giáp Mộc là cần thiết để giữ sức sống cho ngày sinh Thổ trong khi
            giữ vững sự phát triển của Hỏa.{" "}
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
    </div>
  );
};

export const MauThangDan = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        className="p1"
        style={{
          margin: "6px 0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          <strong>Ngày sinh Mậu trong tháng Dần</strong>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Khái Quát:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với Ngày sinh Mậu trong tháng Dần thì nếu có Giáp Mộc hiện lên ở
          Thiên Can thì hình thành cục Thất Sát.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Bính Hỏa hiện lên Thiên Can thì hình thành cục Thiên Ấn.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Mậu Thổ hiện lên Thiên Can thì hình thành cục Lâm Quan.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p4"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Dụng Thần Điều Hậu:
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với sinh trong tháng Dần thì dụng thần điều hậu là Bính Hỏa, Giáp
          Mộc và Quý Thủy.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu thiếu Bính Hỏa thì Mậu Thổ không thể được sinh ra. Tương tự, nếu
          không có Giáp Mộc để làm tơi và cày đất thì Mậu Thổ sẽ khó được sử
          dụng hoàn hảo.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu không có Quý Thủy để làm ẩm thì Mậu Thổ sẽ khó phát huy vai trò
          nuôi dưỡng của đất trồng.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Do đó Bính Hỏa là dụng thần hàng đầu, sau đến Giáp Mộc và Quý Thủy.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p4"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Luận Giải:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu cả Giáp Mộc, Bính Hỏa và Quý Thủy xuất hiện trong lá số thì Thổ sẽ
          được tơi, có dinh dưỡng và chăm sóc đủ. Đây là lá số lý tưởng.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Người sinh ngày Mậu Thổ vào giờ có Hỏa và Thổ Khí đầy đủ sẽ tốt nếu
          gặp Thủy khí trong lá số.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Kim và Thủy xuất hiện nhiều thì lá số sẽ cần có Hỏa Khí.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Ngày sinh vào giờ Ngọ sẽ đảm bảo hình thành Hỏa Cục. Bởi vì Kim là
          dụng thần cần thiết để sinh ra Thủy nên được xem là hỉ dụng thần cần
          thiết.
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Lưu Ý:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khí Mậu Thổ có cả ở Thiên Can và Địa Chi có nhiều hành Thổ thì lá số
            có nguy cơ tiềm ẩn.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Trong tháng này thì Mộc Khí rất quan trọng, có thể xung với Thổ Khí.
            Có nguy cơ đất sạt lở trong lá số. Đây không phải là dấu hiệu tốt.
            Đó là lý do vì sao cần có Bính Hỏa để cung cấp hơi ấm cho ngày sinh
            này và chuyển hóa sự xung khắc từ Mộc khí dễ dàng. Lúc này thì ngày
            sinh này mới mạnh mẽ.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Kịch bản lý tưởng sẽ phụ thuộc nhiều vào chất lượng của Giáp Mộc
            dùng để sinh cho Bính Hỏa và sau đó lại sinh cho Mậu Thổ.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu có Mộc Cục hình thành trong lá số thì cần có Canh Kim để khắc
            chế Mộc Khí. Khi Kim được sử dụng như là dụng thần nếu thiếu Thủy
            thì ngày sinh này dễ gặp tai nạn.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu Bính Hỏa và Quý Thủy hiện diện ở Thiên Can thì cả hai không nên
            xuất hiện bên cạnh nhau vì sẽ xảy ra sẽ xảy ra xung đột nhất định.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Dần Ngọ Tuất xuất hiện tạo thành Hỏa Cục, Quý Thủy nên xuất hiện
            ở Thiên Can. Trong tình huống này thì Quý Thủy đóng vai trò là Dụng
            Thần chính đối với ngày sinh Mậu Thổ và Bính Hỏa là dụng thần thứ
            hai.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Địa Chi tạo thành Hỏa Cục với Giáp Mộc lộ ở Thiên Can nhưng
            không có Quý Thủy thì ngày sinh Mậu Thổ sẽ vất vả khi còn trẻ trước
            khi có thể thành công và giàu có.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi thiếu Thủy trong lá số, ngày sinh Mậu Thổ sẽ cô đơn trong đời.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi ngày sinh này gặp thêm Mộc, vốn dĩ mạnh trong Mộc Cục, và Hỏa
            cũng xuất hiện ở Thiên Can cùng lúc thì đây là lá số quý và siêu
            việt. Nếu không có Bính Hỏa ở Thiên Can thì ngày sinh Mậu Thổ sẽ chỉ
            sống cuộc đời bình thường.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu Canh Kim xuất hiện ở Thiên Can thì ngày sinh Mậu Thổ sẽ thành
            công.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Địa Chi hình thành Mộc Cục, với Mộc cũng xuất hiện ở Thiên Can
            nhưng Thổ Tỷ Kiếp và Hỏa Ấn Tinh thiếu trong lá số thì hình thành
            cục Tòng Quan.
          </span>
        </li>
      </ul>
      <p
        className="p4"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Cách Cục:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Khi lá số có Thiên Ấn và Thất Sát:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi cả Thất Sát và Thiên Ấn cùng lúc xuất hiện thì chất lượng của lá
          số này rất tốt.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Quý Thủy cũng nên xuất hiện. Nếu thiếu Quý Thủy thì ngày sinh này sẽ
          có thời trẻ huy hoàng nhưng về già thì bấp bênh.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu lá số thiếu Bính Hỏa thì không tốt.
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Khi lá số có Thiên Ấn và Chính Tài:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi hình thành cục Thiên Ấn thì nên sử dụng Quý Thủy như là dụng thần.
          Nếu Nhâm Thủy cũng xuất hiện ở Thiên Can thì ngày sinh này sẽ có thịnh
          vượng dài lâu.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số không tốt nếu có Bính Hỏa và Quý Thủy ở bên cạnh trên Thiên Can.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Khi lá số có Thất Sát và Thiên Ấn:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu chỉ có Thất Sát Cục hình thành mà không có Bính Hỏa như là Thiên
          Ấn lý tưởng, Canh Kim Thực Thần nên được lựa chọn là dụng thần.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu có Bính Hỏa. Xấu nếu không có Canh Kim.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Khi lá số có Chính Quan:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Ất Mộc Chính Quan hợp với Canh Kim Thực Thần tạo thành Kim. Trong
          những tình huống đó thì ngày sinh này thiếu đi sự chân thành.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p4"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
            color: "#00000a",
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Tóm Tắt:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Bính Hỏa được xem là dụng thần quan trọng nhất còn Giáp Mộc và Quý
            Thủy là dụng thần thứ hai.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu vắng Canh Kim thì bất lợi. Người này sẽ thiếu ý tưởng và mục
            đích sống.<span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu Địa chi tạo thành Mộc Cục thì có thể hình thành Cục Tòng Quan
            nếu hoàn toàn không có hành Thổ trợ giúp.
          </span>
        </li>
        <li
          className="li3"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Địa Chi tạo thành Hỏa Cục nhưng Nhâm Thủy và Quý Thủy thiếu thì
            ngày sinh này sẽ chịu cô đơn trong đời.
          </span>
        </li>
      </ul>
    </div>
  );
};

export const MauThangMao = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        className="p1"
        style={{
          margin: "6px 0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          <strong>Ngày sinh Mậu trong tháng Mão</strong>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Khái Quát:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh Mậu trong tháng này thì nếu có Ất hiện lên ở Thiên
          Can thì xem là hình thành cục Chính Quan.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng
          có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy
          ý.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Dụng Thần Điều Hậu:
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì dụng thần điều hậu là Bính Hỏa,
          Giáp Mộc và Quý Thủy.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Mậu Thổ không thể khỏa mệnh nếu không có Bính Hỏa ấm áp.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Tương tự nếu không có Giáp Mộc làm tơi và cày xới thì Mậu Thổ bị giới
          hạn sự hữu dụng.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu không có Quý Thủy để làm ẩm thì Mậu Thổ không thể đóng vai trò là
          nguồn sống.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Như vậy Bính Hỏa sẽ là dụng thần đối với ngày sinh Mậu Thổ trong khi
          Giáp Mộc và Quý Thủy là dụng thần thứ hai.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Luận Giải:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi ngày sinh Mậu Thổ trong tháng Mão hình thành Chính Quan Cục trong
          lá số. Điều này bởi vì Mộc vượng nhất trong lá số.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Hỏa là cần thiết để kiểm soát Mộc, và cũng sinh ra và kiểm soát ngày
          sinh này. Sẽ tốt cho ngày sinh này nếu có Chính Quan và Chính Ấn cùng
          nhau vì sẽ sinh ra và hỗ trợ ngày sinh này.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Mộc Quan Tinh vốn rất mạnh trong tháng nên sẽ khắc Thổ. Do đó tốt hơn
          là dùng Hỏa thay vì Kim như là dụng thần của ngày sinh này. Điều này
          bởi vì mặc dù Kim có khả năng xẻ Mộc nhưng cũng có xu hướng làm suy
          yếu Thổ. <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Lưu Ý:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Ất Mộc mạnh trong tháng Mão.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi chỉ có một Ất Mộc ở Thiên Can thì sẽ không thể hình thành cục
            Tòng Quan.<span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Canh Kim xuất hiện ở Thiên Can thì có thể hợp với Ất để hình
            thành Kim. Điều này bất lợi vì Kim yếu trong tháng này. Bởi vì cục
            này sẽ chỉ thể hiện trên bề mặt khiến cho người này không chân thành
            và thiếu chân thật.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu xuất hiện nhiều Ất Mộc Chính Quan thì được gọi là Quan Đa Biến
            Quỷ hay Quyền Quan Hội Đảng. Nếu Canh Kim cũng xuất hiện thì ngày
            sinh này thiếu chân thành trong suy nghĩ hành động dù họ tỏ ra bề
            ngoài ra sao đi nữa. Ngày sinh này sẽ có xu hướng hứa hẹn rỗng tuếch
            và cũng có tính tham lam khiến họ khó có thể kết bạn.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Kịch bản lý tưởng là khi Địa Chi gặp Giáp Mộc hình thành Thất Sát
            Cục. Nếu Canh Kim thiếu thì ngày sinh này chỉ có cuộc đời trung
            bình. Canh Kim và Giáp Mộc phải đồng hành với Mậu Thổ trong tháng
            này.<span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Cách Cục:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Chính Tài cục:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu Quý Thủy dùng kèm với Bính Hỏa thì người này nắm quyền lực và giàu
          có.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lý tưởng nếu có Bính Hỏa. Không tốt nếu Bính Hỏa đứng cạnh Quý Thủy.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>
                Nếu lá số có Chính Quan Cục:
                <span className="Apple-converted-space">&nbsp;</span>
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu Giáp Mộc Thất Sát gặp Ất Mộc Chính Quan thì ngày sinh này thiếu
          động lực để thành công trong đời.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Canh Kim gặp Ất Mộc hình thành Kim là lý tưởng. Xấu nếu có Giáp Mộc và
          Ất Mộc bên cạnh nhau trong lá số.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Tóm Tắt:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu thiếu đi Canh Kim thì Ngày sinh Mậu này sẽ gặp bất lợi.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu Chính Quan và Thất Sát cùng xuất hiện thì tốt hơn là Chính Quan
            ở lại và Thất Sát nên bị hợp kéo đi.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu cả hai Chính Quan và Thất Sát cùng xuất hiện thì sự hiện diện
            của Canh Kim sẽ đủ để hợp Ất Mộc Chính Quan đi.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Giáp Mộc Thất Sát đóng vai trò quan trọng trong việc gây ảnh hưởng
            đến sức mạnh và chất lượng của ngày sinh Mậu Thổ.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Giáp Mộc và Ất Mộc cùng xuất hiện thì gây ảnh hưởng lên ngày
            sinh này khiến người này có tính cách không ổn định.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Sẽ lý tưởng nếu ngày sinh này có cả Thất Sát và Chính Ấn cùng xuất
            hiện. <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
    </div>
  );
};

export const MauThangThin = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        className="p1"
        style={{
          margin: "6px 0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          <strong>Ngày sinh Mậu trong tháng Thìn</strong>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "18pt",
            }}
          >
            <strong>Khái Quát:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì nếu có Ất hiện lên ở Thiên Can
          thì xem là hình thành cục Chính Quan.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          {" "}
          Nếu có Quý Thủy hiện lên ở Thiên Can thì hình thành cục Chính Tài.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          {" "}
          Nếu có Mậu Thổ hiện lên ở Thiên Can thì hình thành cục Lâm Quan.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          {" "}
          Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng
          có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy
          ý.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "18pt",
            }}
          >
            <strong>
              Dụng Thần Điều Hậu:
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì dụng thần điều hậu là Giáp Mộc,
          Bính Hỏa và Quý Thủy.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          {" "}
          Giáp Mộc nên sử dụng ưu tiên để làm tơi và cày xới Thổ. Bính Hỏa và
          Quý Thủy đóng vai trò là dụng thần thứ hai của ngày sinh này.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "18pt",
            }}
          >
            <strong>
              Luận Giải:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          {" "}
          Địa Chi Thìn trong trường hợp ngày sinh Mậu Thổ sinh trong tháng Thìn
          thì chứa tàng can Mậu Thổ, Ất Mộc và Quý Thủy. Thổ và Mộc Khí mạnh
          trong tháng Thìn.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          {" "}
          Thủy là dụng thần quan trọng cho ngày sinh Mậu Thổ. Điều này giúp Thổ
          ẩm và phì nhiêu. Thổ dinh dưỡng biểu thị thịnh vượng.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          {" "}
          Khi có Thủy thì Mộc sẽ dùng kèm như là Dụng Thần. Khi thỏa điều kiện
          thì ngày sinh này sẽ có danh tiếng, địa vị và quyền lực.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          {" "}
          Khi Kim được chọn là dụng thần thì ngày sinh này sẽ có thịnh vượng
          trong đời. Điều này bởi vì Kim và Thủy là là kết hợp hữu tình để nuôi
          dưỡng, cày xới hành Thổ. Người này có cuộc đời tốt đẹp.{" "}
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "18pt",
            }}
          >
            <strong>Lưu Ý:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "18pt",
            }}
          >
            Thìn là kho của Thủy Khí.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "18pt",
            }}
          >
            Khi Giáp Mộc và Quý Thủy hiện ra ở Thiên Can thì ngày sinh này sẽ
            hưởng danh tiếng và may mắn trong đời. Điều này tùy theo Bính Hỏa có
            hiện ra như là dụng thần hay không.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "18pt",
            }}
          >
            Khi Giáp Mộc và Bính Hỏa đều thiếu trong lá số này và cũng không có
            Quý Thủy ở Thiên Can thì ngày sinh này có thể sống cuộc đời thiếu
            mục tiêu.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "18pt",
            }}
          >
            Khi Bính Hỏa xuất hiện ở Thiên Can nhưng không có Quý Thủy, ngày
            sinh này sẽ hưởng tài lộc và thịnh vượng khi trẻ, nhưng tài lộc
            không kéo dài và sẽ nghèo về sau.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "18pt",
            }}
          >
            Khi Hỏa xuất hiện nhiều trong Địa Chi nhưng Quý Thủy lại lộ Thiên
            Can thay vì Bính Hỏa thì ngày sinh này sẽ thành công và trở nên giàu
            có về sau, mặc dù người này có thể phải vất vả khi còn trẻ.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "18pt",
            }}
          >
            Khi Địa chi tạo thành Mộc Cục, với Canh Kim lộ ra ở Thiên Can thì
            ngày sinh này sẽ thành công trong sự nghiệp.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "18pt",
            }}
          >
            Khi Hỏa và Kim hoàn toàn không có trong lá số thì Mộc và Thổ có thể
            khắc nhau trong lá số. Trong những hoàn cảnh này thì ngày sinh có
            thể bị bệnh bao tử.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "18pt",
            }}
          >
            Khi Mộc xuất hiện nhiều, bản thân ngày sinh yếu. Ngày sinh này sẽ
            chịu sức khỏe kém và nghèo khó.
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "18pt",
            }}
          >
            <strong>Cách Cục:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "18pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Thất Sát, Chính Tài và Thiên Ấn:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          {" "}
          Tốt nhất nếu có Quý Thủy sinh ra Giáp Mộc.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          {" "}
          Cách cục lý tưởng xếp thứ hai là Giáp Mộc sinh ra Bính Hỏa.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          {" "}
          Nếu có Bính Hỏa lộ và Giáp Mộc tàng cũng như Quý Thủy không có thì
          ngày sinh này sẽ vượng và giàu có. Nhưng sẽ chịu khó khăn và vất vả.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          {" "}
          Nếu không có Quý Thủy Chính Tài, Nhâm Thủy Thiên Tài có thể dùng thay
          thế.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          {" "}
          Trong hoàn cảnh này thì người này sẽ phải vất vả khi còn trẻ chỉ để
          kiếm đủ cơm ăn áo mặc.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          {" "}
          Khi không có Nhâm Thủy hay Quý Thủy thì ngày sinh này sẽ rất khó để
          thịnh vượng.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "18pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>
                Nếu lá số có Thất Sát hay Chính Quan:
                <span className="Apple-converted-space">&nbsp;</span>
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          {" "}
          Nếu Địa Chi hình thành Mộc Cục thì sẽ có thể hình thành Thất Sát Cục
          thay vì Chính Quan.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          {" "}
          Có lợi cho lá số này nếu xuất hiện Canh Kim ở Thiên Can.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          {" "}
          Nếu hình thành cục Tòng Sát thì tất cả ngũ hành phải có mặt trong lá
          số. Nếu thiếu một trong những hành thì sẽ khó cho người này thành công
          trong đời.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          {" "}
          Xấu nếu như không có Canh Kim hiện diện trong lá số.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "18pt",
            }}
          >
            <strong>
              Tóm Tắt:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "18pt",
            }}
          >
            Nếu thiếu đi Giáp Mộc, Quý Thủy và Bính Hỏa thì Ngày sinh Mậu này sẽ
            gặp bất lợi về sức khỏe kém và bệnh kinh niên. Họ sẽ mất đi định
            hướng hay mục tiêu sống.{" "}
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
      <div className="ddict_btn" style={{ top: "26px", left: "552.82px" }}>
        <img src="chrome-extension://bpggmmljdiliancllaapiggllnkbjocb/logo/48.png" />
      </div>
    </div>
  );
};

export const MauThangTy = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        className="p1"
        style={{
          margin: "6px 0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          <strong>Ngày sinh Mậu trong tháng Tỵ</strong>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Khái Quát:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này là Tỵ thì là vị trí Lâm Quan của
          Mậu Thổ. Hình thành cục Lâm Quan.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Bính Hỏa hay Mậu Thổ xuất hiện ở Thiên Can thì hình thành cục Lâm
          Quan.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Canh Kim hiện lên ở Thiên Can thì hình thành cục Thực Thần.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng
          có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy
          ý.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Dụng Thần Điều Hậu:
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì dụng thần điều hậu là Giáp Mộc,
          Bính Hỏa và Quý Thủy.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Giáp Mộc nên sử dụng ưu tiên để làm tơi và cày xới Thổ vì Tỵ là vị trí
          Lâm Quan của Mậu Thổ. Bính Hỏa và Quý Thủy đóng vai trò là dụng thần
          thứ hai của ngày sinh này.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Luận Giải:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Mậu Thổ xuất hiện trong tháng Tỵ là vị trí của Lâm Quan.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Hỏa mạnh trong tháng mùa hè. Vì cả Hỏa và Thổ vượng trong tháng Tỵ,
          Kim và Thủy sẽ bị khô.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Do đó Thủy cần để giữ ẩm cho ngày sinh này.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Do đó, Canh Kim ẩn tàng ở Tỵ Địa Chi nên được chọn là dụng thần để có
          thể sinh ra và đảm bảo tính liên tục của Thủy. Nếu Kim mạnh thì lá số
          này đẹp.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Thủy nhưng thiếu Kim, thì ngày sinh Mậu Thổ sẽ thiếu cân bằng
          và sức mạnh. Trong hoàn cảnh này thì ngày sinh này có thể vẫn thịnh
          vượng và giàu có trong đời, mặc dù tài lộc không kéo dài.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Ngày sinh Mậu Thổ cần phải tránh nhiều Hỏa Khí bởi vì Hỏa nhiều làm
          suy Kim, vốn cần thiết để sinh ra Thủy là dụng thần của ngày sinh này.
          Sẽ không tốt cho ngày sinh này nếu có nhiều Mộc bởi vì Mộc sinh ra
          Hỏa.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nhiều Thủy là tốt.
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Lưu Ý:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Quý Thủy là dụng thần số một cho ngày sinh Mậu Thổ còn Bính Hỏa và
            Giáp Mộc là dụng thần số hai.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu được thì nên tránh gặp thêm Hỏa và Thổ khô.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Chỉ khi Hỏa và Thổ ở Thiên Can thì Hỏa sẽ nóng trong khi Thổ lại
            khô. Trong hoàn cảnh đó thì ngày sinh này sẽ cô đơn và khổ cực.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi chỉ có Quý Thủy hiện diện như là Dụng Thần thì ngày sinh này sẽ
            sở hữu sự độc lập và quyết tâm thành công. Mặc cho hoàn cảnh xung
            quanh, người này vẫn sẽ có cuộc sống tốt.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi có Giáp Mộc hiện ở Thiên Can thì người này sẽ giàu có cả đời.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi không có Quý Thủy thì ngày sinh này sẽ có cuộc sống trung bình.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Tỵ Dậu Sửu tạo thành Kim Cục trong khi Quý Thủy hiện lên ở Thiên
            Can thì ngày sinh này sẽ có tri thức, kỹ năng và giỏi trong những
            lĩnh vực họ theo đuổi.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Cách Cục:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Thiên Ấn, Thất Sát và Chính Tài:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Quý Thủy sinh ra Giáp Mộc. Giáp Mộc lại sinh cho Bính Hỏa. Khi cả 3
          dụng thần này đều có mặt, ngày sinh này sẽ rất thành công và may mắn.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Tốt nếu như có Quý Thủy hiện diện. Xấu nếu như chỉ có Bính Hỏa hiện
          diện trong khi thiếu cả Nhâm Thủy và Quý Thủy. Ngày sinh này sẽ cô đơn
          và nghèo.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>
                Nếu lá số có Thực Thần Thương Quan cục:
                <span className="Apple-converted-space">&nbsp;</span>
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Địa Chi tạo thành Kim thì sẽ tạo thành Thực Thương Cục thì cần có
          Quý Thủy xuất hiện ở Thiên Can.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lý tưởng nếu như có Quý Thủy hơn là Nhâm Thủy, vì là dụng thần của
          ngày sinh này.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Tóm Tắt:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Quý Thủy được dùng như là Dụng Thần, sẽ tốt nhất nếu Thân và Hợi
            cùng hiện diện ở Địa Chi trong lá số này.{" "}
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
    </div>
  );
};

export const MauThangNgo = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        className="p1"
        style={{
          margin: "6px 0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          <strong>Ngày sinh Mậu trong tháng Ngọ</strong>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Khái Quát:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì nếu có Đinh Hỏa hiện lên ở
          Thiên Can thì xem là hình thành cục Chính Ấn.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Kỷ Thổ hiện lên ở Thiên Can thì hình thành cục Dương Nhẫn.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu như không có những Đinh Hỏa, Kỷ Thổ hiện lên ở Thiên Can thì xem
          như hình thành cục Chính Ấn.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Dụng Thần Điều Hậu:
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì dụng thần điều hậu là Nhâm
          Thủy, Giáp Mộc và Bính Hỏa.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nên điều hòa toàn bộ khí của ngày sinh Mậu Thổ. Để đạt được mục tiêu
          này thì Nhâm Thủy nên được dùng như là Dụng Thần quan trọng hàng đầu,
          sau đến Giáp Mộc và Bính Hỏa là dụng thần thứ hai.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Luận Giải:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Mậu Thổ sinh trong tháng Ngọ thì Thổ được xem như là đất khô. Hỏa và
          Thổ khí rất mạnh trong tháng này.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Do đó, nếu Thổ gặp Thủy khí, thì Thủy sẽ cần để làm ẩm và tưới đất.
          Khi Thổ gặp Kim thì Kim sẽ bị tan chảy và suy yếu bởi Hỏa khí mạnh.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Cả Kim và Thủy cần phải thông căn thì mới đảm bảo được tính liên tục
          và tương ứng dụng thần đối với ngày sinh này.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Ngày sinh này nếu sinh vào giờ có Kim và Thủy mạnh có thể được giữ ẩm
          tốt.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Ngày sinh này nên tránh gặp nhiều Hỏa, Mộc và Thổ khí càng nhiều càng
          tốt.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Mộc, đại diện cho Quan Tinh đối với lá số nữ giới. Thủy sẽ cần để nuôi
          dưỡng Mộc. Đây là lý do cần tránh nhiều Hỏa vì nó khắc Thủy.
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Lưu Ý:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nhâm Thủy là dụng thần số một cho ngày sinh Mậu Thổ, Giáp Mộc là
            dụng thần số hai.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Không tốt nếu thấy Kỷ Thổ lộ Thiên Can.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Mộc trong tháng mùa hè không thể thiếu Nhâm Thủy, cũng như Thổ trong
            tháng mùa hè không thể thiếu Giáp Mộc. Lý tưởng nhất là có Nhâm Thủy
            và Giáp Mộc cùng xuất hiện ở Thiên Can.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Trong tháng Ngọ thì Tân Kim là lựa chọn lý tưởng để sinh ra Nhâm
            Thủy.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Mộc và Hỏa xuất hiện nhiều trong lá số, trong khi Thủy vắng mặt
            thì ngày sinh này sẽ sống cuộc đời giản dị và trung bình, hoặc cũng
            có thể cô đơn buồn bã.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Không tốt cho Kỷ Thổ Kiếp Tài nếu như hiện ra ở Thiên Can. Điều này
            sẽ khiến cho hình thành cục Dương Nhẫn và cùng lúc thì Kỷ Thổ cũng
            được chia nhỏ và làm suy yếu Nhâm Thủy và Giáp Mộc đóng vai trò là
            dụng thần đối với ngày sinh này.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Cách Cục:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Thiên Tài và Thất Sát:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Tân Kim là lựa chọn lý tưởng nếu như Thiên Can xuất hiện ở trụ năm.
          Kim sẽ giúp sinh cho Thủy.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Tốt nếu như có Tân Kim có căn gốc.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu không có Nhâm Thủy thì Giáp Mộc hình thành cục Thất Sát với ngày
          sinh này sẽ không phải là dụng thần hiệu quả.
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>
                Nếu lá số có Thiên Ấn Chính Ấn Cục:
                <span className="Apple-converted-space">&nbsp;</span>
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Địa Chi tạo thành Hỏa Cục cũng như Nhâm Thủy ở Thiên Can thì
          ngày sinh này sẽ có tri thức và kỹ năng.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nhâm Thủy phù hợp hơn Quý Thủy.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu không có cả Nhâm Thủy hay Quý Thủy ở Thiên Can thì ngày sinh này
          sẽ có kỹ năng và tài năng, mặc dù nó có thể không giúp cho họ có cuộc
          sống tốt.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Tóm Tắt:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nhâm Thủy là dụng thần số 1 cho ngày sinh Mậu Thổ trong tháng Ngọ.
            Bất kể là cục gì thì nên nhìn thấy thiên can Thủy.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Giáp Mộc và Bính Hỏa đóng vai trò là dụng thần số hai cho ngày sinh
            này.<span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Cần tránh có thêm Thổ.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu thấy Kỷ Thổ Kiếp Tài hiện lên ở Thiên Can thì không tốt. Điều
            này bởi vì có khả năng gây bất lợi cho Tài Tinh trong lá số.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu thiếu Thủy thì ngày sinh này sẽ chịu nhiều khó khăn và khổ.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nhâm Thủy được ưu tiên hơn Quý Thủy.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu có thể nên tránh Tý và Ngọ ở cạnh nhau.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu không có Kim Thủy thì ngày sinh Mậu Thổ gặp bất lợi.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
    </div>
  );
};

export const MauThangMui = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        className="p1"
        style={{
          margin: "6px 0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          <strong>Ngày sinh Mậu trong tháng Mùi</strong>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Khái Quát:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì nếu có Đinh Hỏa hiện lên ở
          Thiên Can thì xem là hình thành cục Chính Ấn.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Ất Mộc hiện lên ở Thiên Can thì hình thành cục Chính Quan.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Kỷ Thổ hiện lên ở Thiên Can thì hình thành cục Dương Nhẫn.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng
          có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy
          ý.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Dụng Thần Điều Hậu:
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì dụng thần điều hậu là Quý Thủy,
          Bính Hỏa và Giáp Mộc.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Quý Thủy phải có mặt, nếu muốn điều hòa toàn bộ khí của ngày sinh Mậu
          Thổ. Cũng cần phải dùng kèm với Bính Hỏa. Bởi vì Thổ nặng và dày trong
          tháng Mùi thì Giáp Mộc cũng không nên thiếu.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Luận Giải:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Tháng Mùi được xem là cuối mùa hè nên Thổ là rất khô và nóng.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Bởi vì Thổ rất dày và nặng trong tháng Mùi, nên sẽ tranh nhau để đoạt
          Thủy.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Do đó Thủy được xem là dụng thần số một cho ngày sinh này. Khi Thủy
          dùng để làm ẩm đất thì Thổ sẽ có thể sinh ra Kim.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Ất Mộc, đại diện cho người chồng cho lá số nữ giới Mậu Thổ. Thủy nếu
          lộ và xuất hiện ở Thiên Can thì sẽ đem đến tài lộc tốt và quan hệ tình
          cảm tốt cho nữ giới, đặc biệt là với chồng.
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Lưu Ý:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Quý Thủy là dụng thần số một cho ngày sinh Mậu Thổ với Bính Hỏa và
            Giáp Mộc là dụng thần số hai.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Thổ là hành mạnh nhất trong tháng Mùi. Do đó, Giáp Mộc vốn là dụng
            thần cân thiết để làm tơi đất. Tuy vậy, Giáp Mộc trong tháng mùa hè
            sẽ cần có Nhâm Thủy và Quý Thủy là dụng thần. Nếu không thì Mộc sẽ
            khó sống sót.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Kim Khí nhiều trong lá số thì cần chú ý đến sử dụng Bính Hỏa để
            kiểm soát Kim.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Giáp Mộc, Bính Hỏa và Quý Thủy xuất hiện ở Thiên Can thì ngày
            sinh này không chỉ thịnh vượng và giàu có mà còn có địa vị, danh
            tiếng và quyền lực.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu chỉ có Giáp Mộc và Quý thủy ở Thiên Can thì ngày sinh này sẽ là
            người thấu hiểu văn hóa, giỏi giao tiếp đủ mọi loại người.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Bính Hỏa lộ nhưng không có Quý Thủy thì ngày sinh này sở hữu
            tính cách nhẹ nhàng, mặc dù có thể chỉ có đời sống trung bình.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu chỉ có Thổ Cục hình thành trong lá số Bát Tự thì ngày sinh này
            sẽ cô đơn, vất vả và buồn bã.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu Thổ nhiều trong lá số thì Giáp Mộc ở Thiên Can nhưng Canh Kim
            không xuất hiện thì ngày sinh này có thể thành công trong đời nhưng
            mức độ thành công và ảnh hưởng chỉ giới hạn.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Cách Cục:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Thất Sát, Chính Tài và Thiên Ấn:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu Giáp Mộc, Quý Thủy và Bính Hỏa đều hiện ra ở Thiên Can thì ngày
          sinh này hưởng thành công vượt trội trong sự nghiệp.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Ngay cả nếu Bính Hỏa không có thì ngày sinh này sẽ là người tri thức.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi thiếu Quý Thủy thì ngày sinh này sẽ có cuộc sống trung bình.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số nêu thiếu Giáp Mộc ở Thiên Can thì sẽ không tốt mà nghèo đói.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>
                Nếu lá số có Thất Sát Cục:
                <span className="Apple-converted-space">&nbsp;</span>
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu như Thất Sát Cục hình thành thì Canh Kim không nên xuất hiện vì sẽ
          kiểm soát Giáp Mộc. Lá số tốt nếu có Nhâm Thủy trong lá số. Không tốt
          nếu Canh Kim ở Thiên Can.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Tóm Tắt:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu thiếu đi Giáp Mộc thì Ngày sinh Mậu này sẽ gặp bất lợi về sự
            nghiệp và tình cảm, đặc biệt là giữa ngày sinh nam giới và vợ.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu không có Thủy thì người này sẽ tài năng nhưng thiếu tiền mặt.
          </span>
        </li>
      </ul>
    </div>
  );
};

export const MauThangThan = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        className="p1"
        style={{
          margin: "6px 0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          <strong>Ngày sinh Mậu trong tháng Thân</strong>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Khái Quát:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì nếu có Canh Kim hiện lên ở
          Thiên Can thì xem là hình thành cục Thực Thần.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Nhâm Thủy hiện lên ở Thiên Can thì hình thành cục Thiên Tài.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Mậu Thổ hiện lên ở Thiên Can thì hình thành cục Lâm Quan.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng
          có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy
          ý.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Dụng Thần Điều Hậu:
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với người sinh trong tháng này thì dụng thần điều hậu là Quý Thủy,
          Bính Hỏa và Giáp Mộc.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Bởi vì khí bắt đầu lạnh giá trong tháng này thì Bính Hỏa cần để cung
          cấp ấm áp cho ngày sinh này. Nếu Thủy nhiều thì Giáp Mộc cần để làm
          suy yếu và kiểm soát nó.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Luận Giải:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Canh Kim vượng nhất trong tháng Thân.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Thủy Khí được xem là mạnh nhất trong tháng này. Bởi vì Khí dần lạnh
          lơn, Thổ sẽ yếu hơn nếu gặp nhiều Thủy.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đó là lý do vì sao Bính Hỏa nên được xem là dụng thần số một đối với
          ngày sinh này vì cung cấp ấm áp. Chỉ khi đó thì ngày sinh này mới đủ
          đầy đặn và dày mạnh mẽ trước khi Thủy đóng vai trò là Dụng Thần.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Thổ yếu, thì Hỏa sẽ đi kèm với hành Mộc, như là Dụng Thần. Thật sự
          thì cần có cả hai để hỗ trợ ngày sinh và biến ngày sinh này từ yếu
          thành mạnh. Chỉ trong tình huống này thì bất kỳ yếu tố tài lộc nào mà
          ngày sinh này tạo ra cũng như tích lũy trong đời cũng sẽ có thể được
          sử dụng tốt.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Ngày sinh Mậu Thổ nếu sinh trong giờ Hỏa hay Thổ sẽ mạnh, và có vận
          tài lộc tốt hơn người khác.
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Lưu Ý:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Bính Hỏa là dụng thần chính cho ngày sinh Mậu Thổ trong tháng Thân,
            cùng với Quý Thủy và Giáp Mộc như là dụng thần phụ.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Thổ không nên tách rời khỏi Giáp Mộc. Giáp Mộc cần thiết đối với sự
            sinh tồn của Mậu Thổ.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Chỉ có Thủy và Hỏa mới phù hợp để làm dụng thần điều hậu. Bính Hỏa
            và Quý Thủy sẽ đóng vai trò mấu chốt trong việc xác định Dụng Thần
            Điều Hậu, làm ấm hoặc làm mát khí vượng của mùa trong năm.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Mậu Thổ tạo thành cục Thực Thần với Canh Kim biểu thị người rất tài
            năng và giỏi giang.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nhiều Thổ khí biểu thị sự cô đơn và buồn bã.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu không có Bính Hỏa, Giáp Mộc và Quý Thủy trong lá số thì nhìn
            tổng quát toàn bộ lá số Bát Tự của người này sẽ dưới trung bình.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Lá số Bát Tự sẽ là cục lý tưởng, khi có xuất hiện Bính Hỏa, Giáp Mộc
            và Quý Thủy. <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Cách Cục:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Thiên Ấn, Chính Tài và Thất Sát:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Bính Hỏa, Quý Thủy và Giáp Mộc đều có thông căn thì ngày sinh này
          sẽ hưởng danh tiếng và thành công.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi chỉ có Bính Hỏa và Giáp Mộc xuất hiện, thì ngày sinh này sẽ là
          người có tri thức.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi chỉ có Quý Thủy và Giáp Mộc xuất hiện ở Thiên Can, thì ngày sinh
          này sẽ vượng và rất giàu có.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu thiếu Quý Thúy và Giáp Mộc thì ngày sinh này chỉ có cuộc sống
          trung bình. Khi Bính Hỏa và Giáp Mộc bị thiếu thì ngày sinh nam giới
          sẽ kết hôn trễ.
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>
                Nếu lá số có Thiên Tài, Chính Tài và Thực Thần:
                <span className="Apple-converted-space">&nbsp;</span>
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Địa Chi tạo thành Thủy Cục, Giáp Mộc có thể dùng để làm suy yếu và
          kiểm soát Thủy.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Canh Kim Thực Thần tạo dựng thành công. Sự hiện diện của Giáp Mộc và
          Bính Hỏa thì người này sẽ có tài năng lớn trong lĩnh vực nghệ thuật.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Giáp Mộc Thất Sát hiện diện ở Thiên Can là tốt.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu Giáp Mộc vắng mặt trong lá số. Nếu có quá nhiều Thổ có
          thể đem lại danh tiếng nhưng cũng là sự cô đơn và buồn bã.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Tóm Tắt:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Địa Chi tạo thành Thủy Cục thì cần có Bính Hỏa để đem lại sự ổn
            định và bình tĩnh để theo đuổi mục tiêu tài lộc trong đời.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
    </div>
  );
};

export const MauThangDau = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        className="p1"
        style={{
          margin: "6px 0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "18pt",
          }}
        >
          <strong>Ngày sinh Mậu trong tháng Dậu</strong>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Khái Quát:</strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với ngày sinh Mậu Thổ trong tháng Dậu thì hình thành cục Thương
          Quan khi Tân Kim xuất hiện ở Thiên Can. Nếu không có Tân Kim ở Thiên
          Can thì vẫn hình thành cục Thương Quan.
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Dụng Thần Điều Hậu:
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Đối với sinh trong tháng Dậu thì Bính Hỏa và Quý Thủy là dụng thần
          điều hậu.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Sẽ phụ thuộc nhiều vào khả năng Bính Hỏa cung cấp ấm áp cho ngày sinh
          cùng với Thủy để cung cấp độ ẩm nếu muốn sử dụng cả hai là dụng thần.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Luận Giải:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Kim vượng nhất trong tháng này.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Kim khí mạnh sẽ làm suy yếu Thổ. Không ngạc nhiên khi nó sẽ làm cho
          Mậu Thổ khó chứa đựng được những thứ khác. Nếu không có Bính Hỏa để
          sinh ra và củng cố Thổ thì Thổ sẽ quá dày đặc nhưng vẫn yếu.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nhiều Kim sinh ra Thủy. Và Thủy có khả năng làm nhiễm bẩn và khiến cho
          Thổ rối rắm và bùn bẩn.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi Thủy xuất hiện kiểm soát Thổ, Thổ sẽ bị suy yếu hơn nữa. Khi Kim
          xuất hiện nhiều thì ngày sinh này sẽ thông minh, tri thức mặc dù người
          này sẽ cảm thấy khó để đạt được sự công nhận mà người này xứng đáng.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Sự hiện diện của Hỏa là quan trọng đối với thành công của người này
          trong đời.<span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Lưu Ý:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Bính Hỏa là dụng thần chính cho ngày sinh Mậu Thổ trong tháng Dậu
            còn Quý Thủy là dụng thần thứ hai.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Kim rất mạnh trong tháng Dậu. Do đó, họ có khả năng làm suy yếu Mậu
            Thổ. Điều này cũng là lý do vì sao mà không cần phải dùng Giáp Mộc
            để làm tơi xốp Mậu Thổ.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Bính Hỏa sẽ được dùng như là dụng thần chính để đem đến sự ấm áp và
            ổn định cho ngày sinh này. Khi bính Hỏa và Quý Thủy đều không xuất
            hiện thì ngày sinh này sẽ thiếu đi mục tiêu định hướng sống.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Bính Hỏa và Quý Thủy xuất hiện thì ngày sinh này sẽ có thành
            công trong sự nghiệp. Người này sẽ hạnh phúc.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Bính Hỏa xuất hiện thì Quý Thủy vẫn ẩn tàng, ngày sinh này sẽ có
            cuộc đời trung bình.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Quý Thủy xuất hiện nhưng Bính Hỏa ẩn tàng thì ngày sinh này vẫn
            sẽ có thể sống cuộc sống xa hoa thoải mái. Nhưng không cảm thấy thỏa
            mãn.<span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Quý Thủy lộ ra nhưng Bính Hỏa thiếu thì ngày sinh này sở hữu
            tính thích giúp đỡ. Nhưng cuộc sống luôn bất công với họ.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Quý Thủy xuất hiện nhiều nhưng Bính Hỏa không lộ ra thì ngày
            sinh này sẽ có cuộc sống trung bình.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Tỵ, Dậu và Sửu hình thành Kim Cục, Bính Hỏa và Đinh Hỏa nên ẩn
            tàng ở tàng can của Địa Chi và không nên lộ ở Thiên Can. Nếu không
            thì sẽ làm mất đi tính hữu dụng của Kim Cục. Nếu thỏa mãn các điều
            kiện thì ngày sinh này sẽ có quyền lực, địa vị và danh tiếng.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Địa Chi tạo thành Thủy Cục, trong khi Nhâm Thủy và Quý Thủy hiện
            diện ở Thiên Can, ngày sinh này sẽ thành công và giàu có trong đời.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Khi Kim khí xuất hiện ở lá số bị suy yếu đi nhiều, ngày sinh này sẽ
            bị nghèo bất kể họ cố gắng bao nhiêu. Người này cũng sẽ có xu hướng
            tâm trạng buồn bã. Ngay cả nếu ngày sinh này giàu có thì tài lộc của
            họ cũng vô dụng, họ có thể có tâm trạng đau khổ.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu không có cơ hội để sinh ra Thổ, bất lợi cho Tân Kim để có thể
            xuất hiện ở Thiên Can và hợp với Bính Hỏa để tạo thành Thủy.
          </span>
        </li>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Giữa các Địa Chi, Bính Hỏa và Đinh Hỏa cần phải xuất hiện ở tàng
            can.<span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>Cách Cục:</strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>
                Nếu lá số có Thiên Ấn và Chính Tài:
                <span className="Apple-converted-space">&nbsp;</span>
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu cả Bính Hỏa và Quý Thủy cùng xuất hiện thì ngày sinh này sẽ thành
          công lớn trong đời.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Ngay cả nếu Bính Hỏa và Quý Thủy không xuất hiện thì ngày sinh này vẫn
          là người hiểu biết nhưng không có thành công trong đời.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu có Bính Hỏa và Quý Thủy trong lá số.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu thiếu Bính Hỏa và Quý Thủy, ngày sinh này sẽ thiếu định hướng và
          mục tiêu sống.
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>Nếu lá số có Thực Thần và Thương Quan:</strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Nếu có Canh Kim và Tân Kim xuất hiện ở Thiên Can, Bính Hỏa và Đinh Hỏa
          phải xuất hiện để có thể ra được kịch bản tốt nhất.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Canh và Tân Kim cùng xuất hiện ở Thiên Can là tốt nhất.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu có Thổ nhiều trong lá số.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <span className="s1" style={{ textDecorationLine: "underline" }}>
              <strong>
                Nếu lá số có Chính Tài và Thiên Tài:
                <span className="Apple-converted-space">&nbsp;</span>
              </strong>
            </span>
          </span>
        </li>
      </ul>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Khi có Địa Chi hình thành Thủy Cục, thì ngày sinh này sẽ nghèo bất kể
          gia đình họ giàu có ra sao.
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số tốt nếu có Tỷ Kiếp xuất hiện ở Thiên Can.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p2"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          {" "}
          Lá số xấu nếu chỉ có Kiếp Tài mà không phải là Tỷ Kiên xuất hiện.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p
        className="p3"
        style={{
          margin: "6px 0px",
          textAlign: "justify",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "12px",
          lineHeight: "normal",
          fontFamily: '"Times New Roman"',
          minHeight: "15px",
        }}
      >
        &nbsp;
      </p>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            <strong>
              Tóm Tắt:<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </span>
        </li>
      </ul>
      <ul className="ul1" style={{ fontSize: "medium" }}>
        <li
          className="li2"
          style={{
            margin: "6px 0px",
            textAlign: "justify",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontStretch: "normal",
            fontSize: "12px",
            lineHeight: "normal",
            fontFamily: '"Times New Roman"',
          }}
        >
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "14pt",
            }}
          >
            Nếu như chỉ có Tân Kim xuất hiện ở Thiên Can mà không có Bính Hỏa
            hay Đinh Hỏa thì người này có cuộc sống dưới mức trung bình.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </li>
      </ul>
    </div>
  );
};

export const MauThangTuat = () => {
    return (
        <div
        style={{
            textAlign: "left",
            position: "relative",
            zIndex: "999",
            marginLeft: "1rem",
          }}
        >
        <p className="p1" style={{margin: '6px 0px', textAlign: 'center', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '18px', lineHeight: 'normal', fontFamily: '"Times New Roman"', color: '#00000a'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '18pt'}}><strong>Ngày sinh Mậu trong tháng Tuất</strong></span></p>
        <ul className="ul1" style={{fontSize: 'medium'}}>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}><strong>Khái Quát:</strong></span></li>
        </ul>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Đối với người sinh trong tháng này thì nếu có Tân Kim hiện lên ở Thiên Can thì xem là hình thành cục Thương Quan.</span></p>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Nếu có Đinh Hỏa hiện lên ở Thiên Can thì hình thành cục Chính Ấn.</span></p>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Nếu có Mậu Thổ hiện lên ở Thiên Can thì hình thành cục Lâm Quan.<span className="Apple-converted-space">&nbsp;</span></span></p>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy ý.</span></p>
        <p className="p3" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"', minHeight: '15px'}}>&nbsp;</p>
        <ul className="ul1" style={{fontSize: 'medium'}}>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}><strong>Dụng Thần Điều Hậu:<span className="Apple-converted-space">&nbsp;</span></strong></span></li>
        </ul>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Đối với người sinh trong tháng này thì dụng thần điều hậu là Giáp Mộc, Bính Hỏa và Quý Thủy.</span></p>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Vì Mậu Thổ mạnh trong tháng này nên Giáp Mộc nên là dụng thần số một cùng với Bính Hỏa là dụng thần thứ hai.</span></p>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Khi thấy có Kim, Quý Thủy nên sử dụng trước trong khi Bính Hỏa được dùng như là dụng thần đi kèm.</span></p>
        <p className="p3" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"', minHeight: '15px'}}>&nbsp;</p>
        <ul className="ul1" style={{fontSize: 'medium'}}>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}><strong>Luận Giải:<span className="Apple-converted-space">&nbsp;</span></strong></span></li>
        </ul>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Trong tháng Tuất thì Thổ dày đặc. Đó là lý do mà ngày sinh này cần dựa vào Mộc như là dụng thần làm tơi Thổ. Tuy nhiên, có khả năng cao là ngày sinh này quá khô. Do đó Thủy là dụng thần cần thiết để cung cấp hơi ẩm.<span className="Apple-converted-space">&nbsp;</span></span></p>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Nếu sinh vào giờ mà Hỏa và Thổ mạnh thì cần Kim và Thủy là dụng thần, hơn là Thủy và Mộc. Điều này để ngăn Thổ quá dày.</span></p>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Nếu Kim, Thủy và Mộc đều xuất hiện trong lá số, thì dòng khí sẽ suôn sẻ. Trong hoàn cảnh đó thì ngày sinh này sẽ vượng và trở nên rất giàu có và hạnh phúc. <span className="Apple-converted-space">&nbsp;</span></span></p>
        <ul className="ul1" style={{fontSize: 'medium'}}>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}><strong>Lưu Ý:</strong></span></li>
        </ul>
        <ul className="ul1" style={{fontSize: 'medium'}}>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}>Giáp Mộc là dụng thần chính cho ngày sinh Mậu Thổ sinh vào tháng Tuất, với Bính Hỏa và Quý Thủy đóng vai trò là dụng thần thứ hai của người này.</span></li>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}>Khi chỉ có Quý Thủy Chính Tài xuất hiện ở Thiên Can – và thông qua Giáp Mộc và Bính Hỏa không xuất hiện ở Thiên Can và khi Giáp Mộc và Bính Hỏa không xuất hiện ở Thiên Can thì ngày sinh này chỉ sống cuộc đời trung bình.</span></li>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}>Khi chỉ có Giáp Mộc xuất hiện ở Thiên Can và khi Quý Thủy và Bính Hỏa không xuất hiện ở Thiên Can thì ngày sinh này thuộc về người có kỹ năng và tri thức nhưng họ chỉ sống cuộc đời trung bình. Thành công chỉ là giấc mơ.</span></li>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}>Nếu Bính Hỏa và Quý Thủy nằm ở tàng Can nhưng thiếu Giáp Mộc hoàn toàn thì ngày sinh này sẽ chỉ có cuộc sống trung bình. Người này sẽ cô đơn, thiếu hỗ trợ và không cảm thấy hạnh phúc.</span></li>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}>Khi Địa Chi hình thành Thủy Cục thì sao Tỷ Kiếp phải xuất hiện ở Thiên Can, để có thể hỗ trợ cho ngày sinh này. Chỉ khi đó thì ngày sinh này mới thịnh vượng và có thể trở nên giàu có trên đời.</span></li>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}>Khi Địa Chi tạo thành Hỏa Cục và có Hỏa và Thổ cùng xuất hiện ở Thiên Can thì sẽ khiến cho ngày sinh Mậu Thổ dù khô có thể vượng lên trong cuộc sống. Sự vắng mặt của Thủy biểu thị ngày sinh này sẽ phải vất vả trong đời. <span className="Apple-converted-space">&nbsp;</span></span></li>
        </ul>
        <p className="p3" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"', minHeight: '15px'}}>&nbsp;</p>
        <ul className="ul1" style={{fontSize: 'medium'}}>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}><strong>Cách Cục:</strong></span></li>
        </ul>
        <ul className="ul1" style={{fontSize: 'medium'}}>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}><span className="s1" style={{textDecorationLine: 'underline'}}><strong>Nếu lá số có Chính Tài:</strong></span></span></li>
        </ul>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Nếu thiếu Bính Hỏa và Giáp Mộc, Quý Thủy vẫn có thể đem đến sức sống cần thiết cho ngày sinh này.<span className="Apple-converted-space">&nbsp;</span></span></p>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Trong hình huống đó thì ngày sinh này chỉ đạt được thành công và may mắn ở mức độ khiêm tốn.</span></p>
        <ul className="ul1" style={{fontSize: 'medium'}}>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}><span className="s1" style={{textDecorationLine: 'underline'}}><strong>Nếu lá số có Thất Sát:<span className="Apple-converted-space">&nbsp;</span></strong></span></span></li>
        </ul>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Nếu tạo thành Thất Sát Cục nhưng thiếu Bính Hỏa và Quý Thủy hoàn toàn thì ngày sinh này sẽ vất vả để đạt danh tiếng và may mắn trong đời.</span></p>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Chỉ có Quý Thủy và Bính Hỏa hiện hữu thì lá số mới tốt.<span className="Apple-converted-space">&nbsp;</span></span></p>
        <ul className="ul1" style={{fontSize: 'medium'}}>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}><span className="s1" style={{textDecorationLine: 'underline'}}><strong>Nếu lá số Thiên Tài Chính Tài:<span className="Apple-converted-space">&nbsp;</span></strong></span></span></li>
        </ul>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Khi Địa Chi tạo thành Thủy Cục thì sao Tỷ Kiên Mậu Thổ phải xuất hiện, mới có thể tạo ra sự giúp đỡ của quý nhân. Nếu có thì cơ hội sẽ thành hiện thực.</span></p>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Lá số xấu nếu không có Tỷ Kiên hiện lên Thiên Can.</span></p>
        <ul className="ul1" style={{fontSize: 'medium'}}>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}><span className="s1" style={{textDecorationLine: 'underline'}}><strong>Nếu lá số có Chính Ấn Thiên Ấn:</strong></span></span></li>
        </ul>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Khi Địa Chi tạo thành Hỏa Cục, thì Quan Tinh và Tài Tinh phải xuất hiện. Chỉ khi đó thì ngày sinh này mới vượng và hưởng tài lộc tốt trong đời.</span></p>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Lá số tốt nếu như Nhâm Thủy, Quý Thủy, Giáp Mộc và Ất Mộc cũng như Tài Tinh và Quan Tinh cùng xuất hiện ở Thiên Can.</span></p>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Lá số không tốt nếu như không có Mộc và Thủy tức Quan Tinh và Tài Tinh thì ngày sinh này sẽ vất vả cả đời.<span className="Apple-converted-space">&nbsp;</span></span></p>
        <p className="p3" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"', minHeight: '15px'}}>&nbsp;</p>
        <ul className="ul1" style={{fontSize: 'medium'}}>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}><strong>Tóm Tắt:<span className="Apple-converted-space">&nbsp;</span></strong></span></li>
        </ul>
        <ul className="ul1" style={{fontSize: 'medium'}}>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}>Nếu thiếu đi Thủy thì Ngày sinh Mậu này sẽ gặp bất lợi. Người này sẽ vất vả cả đời.</span></li>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}>Mục đích của Mộc là dùng để làm tơi Thổ cứng.</span></li>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}>Sao Tỷ Kiên là có lợi, khác với Kiếp Tài khi Thủy vượt quá giới hạn.</span></li>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}>Sao Kiếp Tài xuất hiện bên cạnh Tài Tinh sẽ báo hiệu cả đời ghen tuông và mâu thuẫn về tình cảm.<span className="Apple-converted-space">&nbsp;</span></span></li>
        </ul>
      </div>
    )
}

export const MauThangHoi = () => {
    return (
        <div 
        style={{
            textAlign: "left",
            position: "relative",
            zIndex: "999",
            marginLeft: "1rem",
          }}>
        <p className="p1" style={{margin: '6px 0px', textAlign: 'center', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '18px', lineHeight: 'normal', fontFamily: '"Times New Roman"', color: '#00000a'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '18pt'}}><strong>Ngày sinh Mậu trong tháng Hợi</strong></span></p>
        <ul className="ul1" style={{fontSize: 'medium'}}>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}><strong>Khái Quát:</strong></span></li>
        </ul>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Đối với người sinh trong tháng này thì nếu có Nhâm Thủy hiện lên ở Thiên Can thì xem là hình thành cục Thiên Tài.</span></p>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Nếu có Giáp Mộc hiện lên ở Thiên Can thì hình thành cục Thất Sát.</span></p>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Nếu như không có những hành trên hiện lên ở Thiên Can thì bạn đọc cũng có thể tùy chọn theo ngũ hành vượng nhất trong lá số mà định cục tùy ý.</span></p>
        <p className="p3" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"', minHeight: '15px'}}>&nbsp;</p>
        <ul className="ul1" style={{fontSize: 'medium'}}>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}><strong>Dụng Thần Điều Hậu:<span className="Apple-converted-space">&nbsp;</span></strong></span></li>
        </ul>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Đối với người sinh trong tháng này thì dụng thần điều hậu là Giáp Mộc và Bính Hỏa.</span></p>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Trong mọi tình huống thì Giáp Mộc là dụng thần không thể thiếu đối với ngày sinh Mậu Thổ.</span></p>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Bính Hỏa đóng vai trò quan trọng sưởi ấm cho ngày sinh này.<span className="Apple-converted-space">&nbsp;</span></span></p>
        <p className="p3" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"', minHeight: '15px'}}>&nbsp;</p>
        <ul className="ul1" style={{fontSize: 'medium'}}>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}><strong>Luận Giải:<span className="Apple-converted-space">&nbsp;</span></strong></span></li>
        </ul>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Thủy rất mạnh trong khi Mộc Khí đem lại động lực trong tháng Hợi.<span className="Apple-converted-space">&nbsp;</span></span></p>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Thời gian này là đầu mùa đông và khí sẽ khá lạnh giá, với Thổ yếu dần. Và mặc dù Mộc có thể bị ẩm bởi Thủy, vốn mạnh trong mùa đông, thì sẽ khó để Mộc sinh ra Hỏa.</span></p>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Trong thực tế, Mộc và Hỏa sẽ làm suy yếu Thủy.</span></p>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Sẽ có lợi nếu ngày sinh này tránh được Kim khí. Điều này bởi vì Kim khắc Mộc và sinh ra Thủy, vốn có thể làm tổn thương và suy yếu Mộc và Hỏa, vốn là dụng thần của ngày sinh này.</span></p>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Trong mọi tình huống thì tài vận của ngày sinh Mậu Thổ sinh vào giờ mà Hỏa và Thổ cùng xuất hiện mạnh thì sẽ trở nên tốt hơn.</span></p>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Hỏa khí sẽ giúp đem lại sự sống cho Thổ và tốt cho sức khỏe. <span className="Apple-converted-space">&nbsp;</span></span></p>
        <ul className="ul1" style={{fontSize: 'medium'}}>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}><strong>Lưu Ý:</strong></span></li>
        </ul>
        <ul className="ul1" style={{fontSize: 'medium'}}>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}>Bính Hỏa và Giáp Mộc là dụng thần ưu tiên cho ngày sinh Mậu Thổ sinh trong tháng Hợi.</span></li>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}>Bính Hỏa có thể được dùng để cung cấp hơi ấm cho Mậu Thổ sinh ra trong mùa đông. Trong lúc đó, Giáp Mộc có thể được dùng để làm tơi và cày xới Thổ.</span></li>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}>Khi Bính Hỏa và Giáp Mộc cùng xuất hiện ở Thiên Can thì không có Hình, Xung hay Hại ở Địa Chi thì ngày sinh này sẽ hưởng thành công trong sự nghiệp.</span></li>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}>Sẽ bất lợi nếu Canh Kim xuất hiện ở Thiên Can. Điều này bởi vì Canh Kim có khả năng làm tổn thương Giáp Mộc vốn là dụng thần của ngày sinh này. Và nếu thật sự như vậy thì sẽ khó cho ngày sinh này đạt thành công trong mọi việc.</span></li>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}>Khi cả Bính Hỏa hay Giáp Mộc đều không xuất hiện ở Thiên Can thì ngày sinh này sẽ nghèo khổ và cô đơn trong đời.<span className="Apple-converted-space">&nbsp;</span></span></li>
        </ul>
        <p className="p3" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"', minHeight: '15px'}}>&nbsp;</p>
        <ul className="ul1" style={{fontSize: 'medium'}}>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}><strong>Cách Cục:</strong></span></li>
        </ul>
        <ul className="ul1" style={{fontSize: 'medium'}}>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}><span className="s1" style={{textDecorationLine: 'underline'}}><strong>Nếu lá số có Thất Sát và Thiên Ấn:</strong></span></span></li>
        </ul>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Nếu cả Giáp Mộc và Bính Hỏa đều lộ Can thì ngày sinh này sẽ hưởng thành công trong sự nghiệp.</span></p>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Khi Canh Kim xuất hiện thì Đinh Hỏa phải đi kèm thì lá số tốt.<span className="Apple-converted-space">&nbsp;</span></span></p>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Khi Canh Kim Thực Thần xuất hiện ở Thiên Can thì ngày sinh này sẽ có cuộc sống trung bình.<span className="Apple-converted-space">&nbsp;</span></span></p>
        <ul className="ul1" style={{fontSize: 'medium'}}>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}><span className="s1" style={{textDecorationLine: 'underline'}}><strong>Nếu lá số có Thiên Tài:<span className="Apple-converted-space">&nbsp;</span></strong></span></span></li>
        </ul>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Nếu Nhâm Thủy Thiên Tài hình thành cục tương ứng trong lá số thì Mậu Thổ Tỷ Kiên cần xuất hiện ở Thiên Can và Đia Chi để ngày sinh này vượng.</span></p>
        <p className="p2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}> Lá số tốt nếu Mậu Thổ Tỷ Kiên phải xuất hiện trong lá số. Lá số xấu nếu không có Mậu Thổ xuất hiện.<span className="Apple-converted-space">&nbsp;</span></span></p>
        <p className="p3" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"', minHeight: '15px'}}>&nbsp;</p>
        <ul className="ul1" style={{fontSize: 'medium'}}>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}><strong>Tóm Tắt:<span className="Apple-converted-space">&nbsp;</span></strong></span></li>
        </ul>
        <ul className="ul1" style={{fontSize: 'medium'}}>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}>Giáp Mộc và Bính Hỏa là dụng thần lý tưởng cho ngày sinh Mậu Thổ trong tháng Hợi. Ngay cả nếu xuất hiện ở Thiên Can thì sự hiện diện của những can này sẽ đem đến kết quả tốt cho ngày sinh này.<span className="Apple-converted-space">&nbsp;</span></span></li>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}>Không nên thiếu Canh Kim và Nhâm Thủy ở Thiên Can. Những sao này sẽ gây tổn thương cho Giáp Mộc và gây hại cho Bính Hỏa vốn là dụng thần của ngày sinh này.</span></li>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}>Nếu thiếu đi Bính Hỏa thì Ngày sinh Mậu này sẽ gặp bất lợi. Người này sẽ cô đơn, thiếu hạnh phúc.</span></li>
          <li className="li2" style={{margin: '6px 0px', textAlign: 'justify', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '12px', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}><span style={{fontFamily: 'arial, helvetica, sans-serif', fontSize: '14pt'}}>Nếu có Canh Kim xuất hiện ở Thiên Can thì cần có Đinh Hỏa đi kèm. Nếu Nhâm Thủy xuất hiện ở Thiên Can thì cần có Mậu Thổ Tỷ Kiếp xuất hiện ở Thiên Can. <span className="Apple-converted-space">&nbsp;</span></span></li>
        </ul>
      </div>
    )
}
