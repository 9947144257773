export const CanhNgo = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Canh Ngọ là người tốt bụng, rộng lượng và lạc quan nhưng
        tính cách tính toán. Có vẻ ôn hoà và yếu đuối nhưng thật sự cứng rắn và
        gia trưởng. Hấp dẫn người khác giới nhưng có dính líu tình cảm yêu đương
        và cãi nhau về tình cảm. Rất dễ lấy được lòng tin của người này nhưng
        cũng dễ mất. Sáng tạo và thích kinh doanh, có khả năng tạo ra nhiều thứ
        mới. Dễ bị thấp khớp.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Ngọ biểu thị hình ảnh là kim loại được rèn bởi lửa, như một
        thanh kiếm cứng chắc. Người sinh ngày Canh Ngọ có tài năng tự nhiên. Họ
        không tỏ ra là cần huấn luyện bài bản để có thể hấp thu kiến thức hay kỹ
        năng mới. Họ tìm kiếm niềm vui trong việc học hỏi điều mới mẻ và có
        duyên với học hành giáo dục. Họ quan tâm đến nhiều thứ và điều này có
        nghĩa là họ cần nỗ lực bản thân để đạt được tài năng toàn diện.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Ngọ yêu tự do và có đam mê sâu sắc để đạt được ý chí của họ.
        Họ không dễ dàng chấp nhận sự giúp đỡ và cho rằng hỏi xin là dấu hiệu
        của sự yếu đuối và thừa nhận thiếu năng lực. Điều này có thể khiến cho
        họ cảm thấy khó chịu khi phải giao tiếp hay làm việc với người có ít tài
        năng hơn họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Ngọ không thích trở nên lười nhác và có thể dao động giữa tự
        cao và trầm cảm nếu kỹ năng của họ không được tận dụng hoàn toàn. Họ có
        động lực để chứng tỏ bản thân và mở rộng tầm nhìn. Khi được kích thích,
        họ mạnh mẽ, quyết tâm và hành động như thể có thể vượt qua mọi trở ngại
        với sự hào hứng. Đôi khi họ cần phải cẩn trọng trước sự trì hoãn do họ
        quá chú ý đến chi tiết.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Ngọ lạc quan và hướng ngoại cũng như duyên dáng và thích giao
        tiếp. Họ thích được chú ý và quan tâm. Họ rất thẳng thắn và có thể tỏ ra
        áp đặt cộng với xu hướng ức hiếp người khác đôi khi ẩn dưới lớp vỏ bọc
        tinh tế. Họ là người lý tưởng và gây ấn tượng và thích giúp đỡ người
        khác. Họ có thể có xu hướng cô đơn. Họ rất rộng lượng và điều này có thể
        dẫn đến việc lo lắng phiền muộn mặc dù họ có thể gặp vận may để thu hút
        những gì may mắn. Với hệ thống giá trị mạnh mẽ và đầu óc cởi mở và tinh
        thần tự do, họ cũng rất thương người và thường kết nối kỹ năng của mình
        để trở thành người tranh đấu cho người khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đằng sau vẻ ngoài can đảm và bốc đồng, người Canh Ngọ khao khát an bình
        và bình an cũng như mái ấm của họ cần phải là nơi trú chân tránh khỏi
        thế giới ồn ào. Họ có trí tưởng tượng tốt và sử dụng khả năng nghệ thuật
        tự nhiên và đầu óc sáng tạo để thể hiện bản thân. Họ thường cần phải
        tránh lo lắng và ngăn bản thân mình không bị quá tải.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Ngọ có thể tỏ ra có giác quan thứ sáu vì biết những gì sắp
        xảy ra. Điều này có thể khiến cho họ có tài trong việc nắm bắt cơ hội
        nhưng họ cần phải cố gắng hiện diện ở hiện tại. Khi đắm chìm trong suy
        nghĩ về thành tích và niềm vui sẽ đạt được trong tương lai, họ dễ trở
        nên bất mãn nếu họ không đạt được nó ngay bây giờ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Những Đặc Tính Chung Của Người Canh Ngọ:</b> tri thức, trực giác, hào
        hứng, quan điểm mạnh mẽ, căng thẳng, đòi hỏi.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Ngọ vốn có óc tổ chức, nắm giữ trách nhiệm rất nghiêm túc và
        có sự tự hào khi hoàn thành công việc. Họ có trực giác về cơ hội và
        phong cách giao tiếp rõ ràng và chính xác. Ý chí mạnh mẽ, quyết tâm cao
        cung với kỹ năng lãnh đạo xuất sắc khiến cho họ trở thành những người
        kết nối và giao tiếp tốt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tâm trí họ không ngừng suy nghĩ và dễ cảm thấy buồn chán nếu gặp những
        gì lặp đi lặp lại và họ cần có không gian để tưởng tượng và được gợi cảm
        hứng. Họ có tình yêu chân thực và trân trọng văn chương, nghệ thuật và
        âm nhạc. Họ có nhiều mối quan tâm khác nhau và kỹ năng rộng khiến cho họ
        cảm thấy khó chọn duy nhất một con đường sự nghiệp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Một khi đi trên một con đường thì người Canh Ngọ mạnh mẽ và quyết tâm
        cũng như có khả năng thu hút thông tin mới và có thể đạt được nhiều điều
        vĩ đại.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Công Việc:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Ngọ có thái độ và suy nghĩ thẳng thắn giúp họ có khả năng
        nhìn xa biết được những gì sắp xảy ra nên họ có thể xuất sắc trong kinh
        doanh. Họ cũng có kỹ năng tổ chức tốt và rất giỏi về con người nên có
        thể hoạt động tốt trong ngành bán hàng và tiếp thị cũng như là cố vấn
        tài chính hay nhà đàm phán. Họ chú tâm đến chi tiết và có thể thành công
        trong cả lĩnh vực như khoa học hay nghiên cứu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngoài ra, họ cũng có khiếu nghệ thuật bẩm sinh và rất thích âm nhạc cũng
        như có thể bị lôi cuốn vào những sự nghiệp như vũ công, nhạc sĩ hay nhà
        văn. Họ cũng thích làm những việc đòi hỏi sự khéo tay hay thủ công. Kết
        hợp những xu hướng này cùng với kỹ năng quản trị tốt, họ có thể thành
        công nếu chọn nghề liên quan đến quan hệ công chúng, nghệ thuật hay thời
        trang.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Ngọ yêu thích việc giúp đỡ mọi người, họ có cái nhìn nhân văn
        và cảm thông cũng như có lý tưởng mạnh mẽ có thể thúc đẩy họ tìm đến tâm
        lý học, trị liệu hay tư vấn. Họ có thể kết hợp điều này với tình yêu
        kiến thức và cảm thấy thoả mãn trong một công việc như giáo viên hay kỹ
        năng ăn nói như ngành luật.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có ý thức độc lập cao cũng như có xu hướng thích hoạt động một mình
        như doanh nhân. Tuy vậy, họ cũng sẽ chọn con đường cho họ sự tự do thể
        hiện hay nhiều trách nhiệm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những Đặc Tính Trong Công Việc Của Người Canh Ngọ: suy nghĩ thấu đáo, óc
        tổ hức, từ bi, dễ buồn chán, quan tâm đến con người, hung hăn, đòi hỏi,
        thẳng thắn, độc lập.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Gia Đình - Quan Hệ:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Ngọ có cảm xúc mạnh mẽ và trái tim nhạy cảm, họ có nhiều tình
        yêu để cho đi và nếu không có tình yêu trong đời, họ sẽ trở nên tâm
        trạng và buồn bã.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ bị thu hút đến với những người có tài năng nghệ thuật và nên tìm đến
        người nào rộng lượng và thú vị. Vì họ có cảm xúc sâu sắc, đôi khi họ có
        thể rút lui vào thế giới riêng, họ cũng cần người bạn đời có thể cho họ
        không gian riêng để họ có sự độc lập và linh hoạt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Ngọ rất rộng rãi và tặng cho người bạn đời của mình vô số quà
        tặng. Sự ham muốn được giúp đỡ người khác biểu thị ý nghĩa là họ sẽ theo
        đuổi mối quan hệ dưới dạng hiệp sĩ giải cứu nhưng thường thì họ chính là
        người cần được giải cứu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù họ cần sự tự do cá nhân, họ chung thuỷ và yêu thương trong mối
        quan hệ tình cảm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Ngọ có thể là linh hồn của buổi tiệc. Họ ấm áp, thân thiện và
        nhanh trí cũng như có thể trở thành người bạn thân thiện và chung thuỷ.
        Họ thường thích xã giao và quan tâm đến nhiều thứ khác nhau và cũng như
        cởi mở và có thái độ trung lập nên sẽ không khó để họ tìm điểm chung với
        hầu hết mọi người.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Vì họ thân thiện với mọi người, họ có thể mất thời gian để chọn ra được
        mối quan hệ giá trí nhất và thường bị lôi cuốn theo những ai có thể giúp
        họ thăng tiến. Vì họ quan tâm đến quá nhiều thứ nên đôi khi họ bị mất
        định hướng và dễ bị đi theo con đường người khác dẫn dắt. Họ cần cẩn
        thận không tạo quá nhiều áp lực cho bạn bè.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Hôn nhân đối với người Canh Ngọ có thể là thử thách. Có những khoảng
        cách trong mối quan hệ và người nam giới có thể khó tìm được sự hoà hợp
        đúng đắn. Nữ giới có thể gặp may hơn vì sự chung thuỷ của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Những Đặc Tính Trong Tình Yêu - Quan Hệ:</b> thích xã giao, thân
        thiện, ấm áp.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Canh Kim toạ Chính Quan và Chính Ấn biểu thị người này có danh dự và sự
        duyên dáng nên dễ thành công trong chính trị. Người Canh Kim toạ Ngọ hoả
        trong lá số Bát Tự nghĩa là có nhiều thách thức cho họ cần vượt qua
        trong đời nhưng những trải nghiệm này sẽ khiến họ mạnh mẽ hơn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Điều này bởi vì Hoả cần thời gian để rèn Canh Kim thành thanh gươm sáng
        bóng. Vận may của người này có thể dao động dữ dội, lúc này thì thành
        công lớn, lúc khác lại rơi không phanh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Ngọ sống lý tưởng và sáng tạo. Họ cực kỳ sáng tạo và luôn
        nghĩ ra sáng kiến mới. Được biết đến là người nỗ lực chăm chỉ và tham
        vọng, họ luôn tự thúc đẩy bản thân để thử thách kỹ năng mới để biến ham
        muốn thành sự thật.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Ngọ năng động và có tính cách hướng ngoại, họ quan tâm đến
        nhiều lĩnh vực và hoạt động khác nhau khiến cho họ là người yêu thích
        niềm vui và làm cho mọi tập thể có họ trở nên thú vị. Họ giỏi nhất là có
        tính cách thu hút người khác đến với mình do sự duyên dáng. Nhưng điều
        này che đậy một phần sự nhút nhát và bảo thủ mà sẽ trở nên rõ ràng hơn
        khi quan hệ thân thiết hơn và do đó họ cần học cách tỏ ra thẳng thắn
        hơn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Ngọ liên tục bị thách thứ trong đời để học hỏi và thấu hiểu
        bản thân mình. Chỉ thông qua thử thách và kinh nghiệm và họ có thể khám
        phá cách để họ trở nên giỏi nhất. Khi họ hiểu mình tốt hơn thì họ sẽ tìm
        được bình an.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Quyết định sự thành công của người Canh Ngọ phụ thuộc vào chất lượng và
        sự hiện diện của hành Thuỷ trong lá số. Nếu có hành Thuỷ xuất hiện ở
        Thiên Can hay Địa Chi thì người Canh Ngọ có thể mong đợi thành công ít
        nỗ lực.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Lá số sẽ tốt nếu gặp Nhâm. Điều này biểu thị họ sẽ được sự trợ giúp từ
        người khác khi họ đạt được nhiều thứ trong một thời gian ngắn. Sẽ bất
        lợi cho lá số nếu không có thêm hành Kim và Thuỷ và rằng lá số chỉ gặp
        hành Mộc và Hoả. Điều này biểu thị người này sẽ sống không thọ vì hành
        Hoả trong lá số Bát Tự sẽ làm tan chảy Kim và khiến nó trở nên vô dụng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Ngọ được xem là trụ cứng rắn, biểu thị quyền lực, sức mạnh,
        thành công và kiểm soát. Tất cả những dấu hiệu trên tốt cho nam giới
        Canh Ngọ 64 nhưng đối với nữ giới Canh Ngọ biểu thị họ phải làm việc vất
        vả hơn để đạt được điều mình muốn. Vì trụ này toạ trên Quan Tinh nên
        biểu thị người nữ Canh Ngọ sẽ gặp nhiều người đàn ông đến rồi đi nhưng
        không cần phải quá lo lắng bởi vì không phải số lượng và chính là chất
        lượng của hành Quan - Bính Hoả, Đinh Hoả, Tỵ, Ngọ trong lá số mới ảnh
        hưởng chính.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sẽ tốt hơn nếu trụ ngày này gặp Kim và Thuỷ trong đại vận, vì biểu thị
        vận may, thành công và hạnh phúc cho người Canh Ngọ. Ngược lại sẽ xấu
        nếu gặp hành Mộc, Hoả hay Thổ trong đại vận.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Giáp hiện diện trong lá số Bát Tự là Tai tinh cho Canh Ngọ. Bởi vì Giáp
        toạ Tử trong vòng 12 Trường Sinh nên biểu thị người Canh Ngọ chỉ gặt hái
        được ít tài lộc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Ngọ sẽ có thể có được danh tiếng tót nếu họ sinh ra trong mùa
        Xuân và Hạ. Sinh ra trong mùa Thu hay Đông biểu thị gặp nhiều khó khăn
        trong đời. Người Canh Ngọ sinh ra ban ngày sẽ thấy rằng nỗ lực trong
        kinh doanh dễ thất bại. Sinh ra vào ban đêm thì người Canh Ngọ dễ gặp
        thuận lợi khi về già. Người sinh vào tháng sửu dễ gặp cả đời khó khăn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Một trong những khám phá vĩ đại nhất của con người để khống chế thú săn
        là chế tạo vũ khí. Canh Ngọ là biểu thị cho dấu hiệu của 1 vị vua. Canh
        là kim loại thô vô dụng nếu chỉ đi một mình, nhưng với sự trợ giúp cua
        Hoả thì nó được rèn thành công cụ hữu dụng, không biết sợ hãi, không
        phải phụ thuộc ai mà là công cụ để phán xét tất cả.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Canh Kim là nguy hiểm nhất trong mọi hành vì toạ Đinh chính là Chín
        hqaun biểu thị sức mạnh của Ngọ Hoả. Điều này biểu thị năng lực chính
        xác và công lý. Năng lực này được công chúng trao cho, biểu thị người
        này được giao phó cho trách nhiệm điều hành. Đinh Hoả sáng rõ soi con
        đường trong thời kỳ tăm tối, và xua tan sự sợ hãi, hoài nghi hoặc lừa
        dối. Cộng với khả năng giúp đỡ hiệu quả của Kỷ Thổ biểu thị Chính Ấn,
        cho họ kiến thức cần thiết để gia tăng sức mạnh to lớn. Đinh Hoả sinh ra
        Kỷ Thổ và từ đó tạo ra Canh Kim. Điều này cho Canh sức bền bỉ cần thiết
        để chấp nhận dùng Hoả để biến đổi từ một cục sắt thành một vũ khí lợi
        hại cắt được mọi thứ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Ngọ có cá tính chai lì có thể phù hợp để thiết lập công lý
        trong môi trường pháp luật. Họ có thê hành động nhanh chóng và không do
        dự hay nghi ngờ. Đinh Hoả có thể giúp họ soi rõ và đủ chi tiết cũng như
        một ngôi sao sáng trên bầu trời đêm. Kỷ Thổ cho họ sự cảm thông để phân
        tích mọi tình huống, gắn liền với một ít tình cảm nhưng vẫn không quá
        nặng nề về cảm xúc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngọ kết hợp với Mùi hấp dẫn Ất Chính Tài, Kỷ Chính Ấn và Đinh Chính
        Quan. Với cả nam và nữ, sao người chồng Chính Quan và người vợ Chính Tài
        trong tầm tay. Chính Tài biểu thị họ phù hợp với các công việc tạo ra
        nguồn thu nhập ổn định.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngọ ngược lại xung với Tý nên xua đuổi Quý Thương Quan. Sự tự cao của
        người này được giữ ở mức độ vừa phải, khiến cho họ phù hợp với người có
        quyền lực. Bởi vì họ không sáng tạo, họ có xu hướng gắn bó với con đường
        đã được tạo ra bởi luật pháp và trật tự.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhìn chung, Canh Ngọ có thể lột xác, có tầm nhìn rõ ràng và trái tim ấm
        nóng. Người này có tình yêu và sự đồng cảm trong khi lại bỏ đi cái tôi.
        Họ có khả năng tài chính và kiến thức có cấu trúc rõ ràng. Điểm trừ duy
        nhất là nếu họ thiếu hoàn toàn hành Thuỷ, biểu thị thiếu cảm xúc. Chính
        sự thiếu cảm xúc sẽ khiến cho người Canh Ngọ sự can đảm hành động và dám
        thắng. Họ là chiến binh công lý.
      </p>
    </div>
  );
};

export const CanhThin = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Canh Thìn có vận đào hoa vượng, rất thu hút người khác
        phái, có quý nhân giúp đỡ nhiều. Thông thường thành công của họ thường
        do được người khác ủng hộ (rất ít khi do nỗ lực vất vả của bản thân), có
        trực giác tốt, tính cách cứng đầu, năng lượng cao và thường chủ động.
        Người tình, người yêu… thường làm hao tổn tài lộc lớn và sau khi ly
        thân, sẽ khiến họ thường xuyên cải vã và gây gỗ. Có vận hôn nhân xấu,
        hôn nhân khó bền - tốt nhất nên tránh kết hôn người sinh ngày Canh Tuất.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Canh Thìn được đại diện bởi hình ảnh của khuôn kim loại hay giáp kim
        loại - cứng rắn và bền bỉ. Người sinh ngày Canh Thìn là người sống sót
        qua khó khăn. Họ có động lực và ganh đua cũng như đánh gía cao hành động
        hơn lời nói. Điều này biểu lộ rõ thông qua ngoại hình cứng rắn và và
        khoẻ mạnh của họ.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ tin tưởng vào đạt được thành ông một mình và cứng rắn cũng như thẳng
        thắn trong việc theo đuổi mục tiêu. Vì họ dám nghĩ dám nói, họ có thể là
        người sáng tạo hay thậm chí kỳ quặc nhưng họ sở hữu trí thông minh rõ
        ràng và thẳng thắn giúp cho họ biến mọi kế hoạch lớn thành hiện thực.
        Quyết tâm trong hành động theo cách cua riêng họ có thể tạo nên những
        cuộc tranh đấu và do đó họ có thể chỉ thành công muộn trong đời. Không
        chỉ những điều này sẽ cản bước họ nhưng dù sao họ vẫn là người lạc quan,
        không chấp nhận thoả hiệp và mạnh mẽ trong việc theo đuổi điều mình
        muốn. Họ cũng có xu hướng thúc đẩy bản thân mình quá mạnh mẽ. Họ bị buộc
        phải làm việc vất vả để biến đổi cuộc sống và có sự tự cao và phẩm giá
        khiến cho họ luôn muốn điều tốt hơn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Thìn thường chân thật về cảm xúc và khao khát một nơi chốn an
        toàn mà họ thường xem là nhà. Họ thích ở bên cạnh mọi người và trao đỏi
        ý kiến với người khác và thường được mọi người biết đến. Họ là những
        người đánh giá tính cách một cách tinh tế và có khả năng giỏi trong việc
        khiến cho người khác cảm thấy họ đặc biệt. Mặc dù họ những nhà lãnh đạo
        bẩm sinh, họ có thể cảm thấy việc là một phần của đội nhóm là chìa khoá
        thành công, họ nhận thức mạnh mẽ rằng thoả hiệp là chìa khoá để xây dựng
        quan hệ hiệu quả. Họ có thể đôi khi tỏ ra áp đặt vì bản chất họ thường
        dữ dội và họ có sự ganh đua cố hữu nhưng chính cảm giác quyền lực cũng
        khiến cho họ bảo vệ người khác một cách mạnh mẽ và thường ủng hộ những
        người ở thế yếu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ tin rằng tiền bạc là quyền lực và nếu họ không cẩn thận, họ có thể
        mất dấu những thứ quan trọng trong đời trong quá trình họ theo đuổi vật
        chất. Họ cũng là những người giàu tình cảm và đôi khi tận hưởng quá độ.
        Họ có thể tỏ ra là người nghĩa khí và sẵn lòng hi sinh tất cả vì điều
        tốt đẹp hơn. Họ có trách nhiệm cao và dễ bị lôi kéo bởi những gì mang
        tính nhân đạo.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Thìn có tài năng nghệ thuật bẩm sinh và giỏi nhất khi họ bị
        kích thích về mặt trí óc. Họ hưởng lợi từ tri thức cao và mở rộng tầm
        hiểu biết. Đối với họ sự phát triển và mở rộng là tối quan trọng với họ.
        Mặc cho họ tỏ ra hướng ngoại, họ không thật sự tự tin nếu không có nền
        tảng học vấn vững chắc nhưng một khi họ sở hữu được tất cả kiến thức cần
        thiết thì tài năng tự nhiên cua họ sẽ báo hiệu họ có tiềm năng lớn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Chung Của Người Canh Thìn:</b> sáng tạo, nhanh nhạy,
        thích xã giao, quyết tâm, kiên nhẫn, mạo hiểm, lạc quan.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Là lãnh đạo bẩm sinh, người Canh Thìn sẽ thà là nắm quyền kiểm soát một
        dự án hay tình huống náo đó hơn là lùi bước ra sau. Họ siêng năng và có
        động lực chinh phục mục tiêu và có thể sáng tạo trong việc vượt qua trở
        ngại. Họ có sự duyên dáng và giỏi giao tiếp với người khác và sử dụng
        tài năng này để giúp bản thân tiến bộ. Họ có thể leo lên những vị trí
        cao quyền lực.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Thìn sở hữu năng lực quản lý xuất sắc và có khiếu kinh doanh
        tốt. Họ có thể trở thành những nhà đầu tư khôn ngoan và có năng lực suy
        nghĩ sáng tạo . Tuy nhiên, họ cũng khao khát sự kích thích và cần phải
        cẩn trọng tránh những vai trò nào quá bị giới hạn.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Công Việc:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Thìn rất đa tài và thông minh cũng như dễ thành công trong
        một loạt vị trí công việc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bản tính thấu hiểu con người và bản năng bảo vệ giúp họ có thể tham gia
        những nghề giáo dục hay chăm sóc. Bản tính nhân đạo có thể dễ dàng lôi
        cuốn họ đến cac công việc trong tổ chức từ thiện, chính trị hay luật
        pháp. Họ có thể hành xử như những nhà cải cách đối với tất cả mọi thứ và
        thổi làn hơi mới vào những hệ thống cũ kỹ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngoài ra, cảm nhận sự độc lập mạnh mẽ của họ có thể dẫn họ đến các công
        việc tự do như nhà văn hay doanh nhân. Họ cũng có thể trở thành chủ nhà
        hàng, quán ăn. Họ là những nghệ sĩ đa tài và có thể trở thành diễn viên
        hay ca sĩ. Họ là những lãnh đạo bẩm sinh trong kinh doanh và có tài quản
        lý tiền bạc và có thể trở nên xuất sắc ở vị trí ngân hàng, kế toán hay
        nhân viên bán hàng. Tính nghĩa khí của họ có thể biểu thị họ sẽ từ chối
        tất cả và sẽ xuất gia hoặc theo đuổi các môn huyền học.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bất kể con đường sự nghiệp nào họ chọn thì nó sẽ là thử thách về mặt tâm
        linh và họ sẽ giỏi miễn là họ bền chí và phát huy tối đa trí thông minh
        thiên bẩm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Những Đặc Tính Trong Sự Nghiệp:</b> tiến bộ, siêng năng, khả năng
        lãnh đạo, suy nghĩ thấu đáo, thích cãi, đa tài, đa nghi, không đáng tin,
        cứng đầu, thích kiểm soát.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Hôn Nhân:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trong khi người Canh Thìn khao khát một sự nghiệp năng động và đầy thử
        thách thì họ không muốn gì hơn là sự bình an và ổn định trong đời sống
        cá nhân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ cần một người chân thật về cảm xúc cũng như là người nồng nhiệt và
        nhạy cảm nỗ lực để giữ mối quan hệ hạnh phúc và hòa hợp. Họ trân trọng
        những điểm chung với người bạn đời và dễ bị lôi kéo hấp dẫn bởi những
        người họ cho là có thể kích thích họ về mặt tư duy.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ tận tâm, chung thuỷ và quan tâm nhưng họ cần phải thận trọng không
        nên để cho xu hướng bẩm sinh thích áp đặt thống trị.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Thìn rất thân thiện và hướng ngoại cũng như có quan hệ thân
        thiện với mọi người. Họ trung thành và thích bảo vệ người xung quanh và
        họ rất cần được nổi tiếng. Bạn bè họ sẽ đánh gía họ vì góc nhìn quan
        điểm đặc biệt và thực tế.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Thìn cũng thích theo đuổi những thứ tốt đẹp trong đời và họ
        rất dễ ngã về phía những người có quyền lực và sức ảnh hưởng với hi vọng
        những người này sẽ giúp họ leo lên đỉnh cao.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Thìn chú trọng đến giá trị nên tảng vững chắc và trân trọng
        sự ổn định và an toàn về cảm xúc. Họ có thể cảm nhận rằng hôn nhân và
        bắt đầu xây dựng gia đình là cách tự nhiên để đạt được điều này. Đối với
        người Canh Thìn thì hôn nhân là khó khăn và họ có thể gặp nhiều thách
        thức để tìm người bạn đời phù hợp và thường phải kết hôn nhiều hơn 1
        lần.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Của Người Canh Thìn Trong Tình Yêu - Quan Hệ:</b>
        bảo vệ, thân thiện, hướng ngoại, chung thuỷ, quan tâm.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:{" "}
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Canh Thìn là một trong những ngày sinh không thể phát triển thịnh vượng
        trong bình an mà chỉ toả sáng khi đối mặt với nghịch cảnh, áp lực trong
        cuộc đời. Đây là một trong những ngày sinh không tốt cho nữ giới trừ khi
        có ngũ hành Thuỷ trong lá số để tạo nên sự mềm mại.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Canh là một trong những ngũ hành cứng rắn đòi hỏi cần phải được tôi
        luyện trước khi trở nên hữu dụng. Nó được gắn với khả năng hành động và
        đôi khi muốn mọi thứ xảy ra một cách mạnh mẽ. Họ thường được xem là đồng
        thau, kim loại thô trước khi được cuộc đời rèn luyện và đánh bóng, cho
        đến lúc đó họ mới có thể thật sự định hình được chính mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Canh toạ Thìn ẩn tàng ngũ hành Mậu (Thiên Ấn), Ất (Chính Tài) và Quý
        (Thương Quan). Điều này biểu thị người này may mắn có tài năng bẩm sinh.
        Họ sẽ có thể trở nên giàu có, thịnh vượng, thông minh và hiểu biết. Trụ
        này toả ra quý khí mà các trụ Canh kim khác không có.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sự kết hợp giữa Mậu và Quý ở tàng can biểu thị khả năng biến thành Hoả
        nếu 2 can này xuất hiện ở can trên lá số. Điều này biểu thị sức khoẻ tốt
        đối với người Canh kim. Nếu Hoả hình thành, cùng với hành Mộc, ở trong
        Địa Chi biểu thị Tài Quan cục biểu thị cuộc đời đầy thành tích. Nếu
        Thương Quan Tinh lộ lên trên Can của lá số, và nếu có nhiều kết hợp với
        hành Thuỷ thì Canh Thìn sẽ khá dữ dội và bạo lực.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Thìn có động lực và hướng ngoại cũng như thích làm việc chung
        nhóm và có quan hệ rộng. Họ yêu thích sự vui vẻ và sáng tạo cũng như bạn
        bè nói họ có một cá tính khá thu hút. Sự thu hút của họ, tuy vậy ẩn chứa
        mặt rụt rè và họ đôi khi cần phải ở một mình và tìm thời gian bình an,
        tự suy ngẫm cho bản thân. Bởi vì họ có nhu cầu muốn được công nhận tài
        năng, nhiều người Canh Thìn cần phải tỏ ra thẳng thắn hơn nữa.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu Canh Thìn gặp Dương Nhẫn trong lá số Bát Tự thì biểu thị người Canh
        Thìn sẽ quyết đoán và quyết tâm cũng như họ sẽ đạt được những thứ tốt
        đẹp trong đời. Nếu lá số có Tỷ Kiên hoặc Kiếp Tài hiện diện thay vì
        Dương Nhẫn, biểu thị người này sẽ có năng lực trở thành nhà lãnh đạo
        quân sự nếu họ chọn đi theo con đường binh nghiệp. Kết hợp này trở nên
        quan trọng nếu được hỗ trợ bởi trụ Mậu Dần. Canh Thìn được biết đến là
        có sự chính xác trong tài năng quân sự hay sự anh dũng nếu có Kiếp Tài
        hay Ấn Tinh trong lá số Bát Tự. Nếu không có những kết hợp kể trên hiện
        diện trong lá số thì người Canh Thìn có khả năng sống cuộc đời ít biến
        cố.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Lá số sẽ trở nên bất lợi nếu trụ này gặp nhiều Mộc, Hoả và Thổ. Lá số
        cũng sẽ bất lợi nếu gặp trụ Canh Thìn bị xung hay Hình nếu có xuất hiện
        Tuất và Mùi trong lá số. Điều này biểu thị Canh kim bị chôn vùi bởi quá
        nhiều Thổ khiến nó cảm gíac bị nghẹt và mất đam mê và động lực.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Thìn thường tìm được thành công chậm và ổn định nếu họ sinh
        ra vào mùa Xuân hay Hạ và nếu họ xinh vào mùa Đông hay Thu thì họ có
        cuộc sống tốt mà ít gặp trở ngại.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu sinh vào ban ngày thì thu được nhiều tài lộc và thịnh vượng lớn. Nếu
        sinh vào ban đêm thì tương lai nhiều thăng trầm. Không tốt nếu người này
        sinh vào tháng Ngọ vì biểu thị vất vả để đạt được mục tiêu của mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Thìn là người yêu thích học hỏi bẩm sinh và cải thiện kỹ năng
        khá nhanh chóng qua các khoá học chuyên môn. Họ thực hành kỹ năng bẩm
        sinh của họ mà không cần phải nỗ lực quá nhiều.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có trực giác tốt và luôn lắng nghe theo cảm giác của chính mình. Điều
        này khiến cho họ dám thử mọi thứ. Họ rất thực tế và thường giỏi trong
        việc liên tục cố gắng cho đến khi thật sự thành công. Tuy nhiên, họ có
        xu hướng muốn thay đổi những luật lệ xung quanh họ. Họ thường cứng rắn
        và nói lên ý chí muốn thành công của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ rất giỏi trong hình thành mối quan hệ với người khác. Sự xuất hiện
        của Chính Tài giúp cho người nam giới Canh Thìn sẽ là người chồng tốt
        trong khi Thương Quan sẽ khiến cho hôn nhân của nữ giới không tốt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thìn xung với Tuất khiến tấn công Mậu (Thiên Ấn), Đinh (Chính Quan) và
        Tân (Kiếp Tài). Vì trụ này căn bản thiếu Quan Tinh và ngoài ra còn xua
        đuổi Chính Quan nên biểu thị người nữ này sẽ khó tìm được chồng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Cô ấy thường quá cứng rắn và để lại ấn tượng rằng cô ấy có thể tự lo cho
        chính mình và khiến cho những nam giới theo đuổi mình phải bỏ đi. Người
        nam giới Canh Thìn sẽ tốt hơn nhiều vì xua đuổi Kiếp Tài đi. Điều này đa
        phần bởi vì vẻ ngoài cứng rắn của họ không gây ấn tượng cao và ít thu
        hút sự chú ý.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thìn hợp với Dậu và cuốn hút Tân (Kiếp Tài). Đối với đàn ông, điều này
        gây ra rắc rối vì anh ta có cảm giác anh xứng đáng có được điều tốt hơn
        và muốn người bạn đời phải nâng cấp. Họ thường xuyên cãi nhau dữ dội
        biểu thị sự bất hoà nhưng không bao giờ có can đảm để sửa chửa. Đối với
        cả nam giới và nữ giới đều biểu thị họ dễ lãng phí tiền bạc để theo kịp
        với trào lưu xã hội.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Canh Thìn là một phần của thần sát đặc biệt gọi là Khôi Cương. Theo
        truyền thống thì những người này là tướng lĩnh vĩ đại vì họ có thái độ
        không sợ chết và luôn chiến đấu trong lúc hỗn loạn và khó khăn. Họ thiếu
        khả năng tự thưởng cho chính mình và thường bận rộn tìm kiếm những điều
        khó khăn tiếp theo để làm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người xung quanh thường xem họ có thành tích lớn nhưng không bao giờ đủ
        để cảm thấy hạnh phúc. Nhìn chung họ là trụ ngày rất quyền lực với khả
        năng sống sót trong cả những hoàn cảnh khó khăn nhất, chỉ là họ không
        biết cách thưởng thức vị ngọt thành công.
      </p>
    </div>
  );
};

export const CanhDan = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Canh Dần: cứng rắn, dám nghĩ dám làm, thích sống cuộc
        sống đầy đủ về vật chất, hấp dẫn người khác phái, dễ dính líu vào các
        mối quan hệ tình cảm, thích thay đổi người yêu, khó chung thuỷ và dễ bị
        tai hoạ vì yêu đương. Thích sưu tầm những gì mình thích. Nữ giới thường
        có mối quan hệ tình cảm kém với chồng. Nam giới thường tỏ ra độc tài ở
        nhà. Nhìn chung, không thiếu tài sản của cải trong đời, mặc dù phần lớn
        thành công đều do nỗ lực của người khác. Nhiều lần cận kề sinh tử nhưng
        thoát chết trong gang tấc vào khoảng trung niên.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tưởng tượng đem một cưa máy vào trong rừng, bạn có thể nhanh chóng có
        được gỗ. Đó là những gì mà Canh Dần sẽ làm với cuộc đời họ, nhanh chóng
        và dữ dội. Sẽ khá là mỉa mai nếu ai đó nói về việc bảo vệ rừng với người
        Canh Dần. Họ là một trong những người khởi xướng mạnh mẽ nhất.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Hình tượng của Canh Dần là Kim gặp Mộc, là cây rìu kim loại chặt gỗ. Do
        đó, không ngạc nhiên khi người sinh ngày Canh Dần thích càm ràm lải
        nhải. Họ thường nói mãi, nói dai triết lý về nhũng chuyện nhỏ nhặt không
        đâu vào đâu. Họ có thể gợi cảm hứng và không kìm nén được. Họ bẩm sinh
        có tài và thông minh và cũng sở hữu sự nhạy cảm sâu sắc và trực giác đặc
        biệt.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Dần không thích gì hơn là đón nhận thử thách. Họ tham vọng và
        tin rằng không gì là không thể. Họ có động lực và thường lao động vất vả
        đủ để chứng minh rằng điều trên là đúng. Mặc dù thận trọng, họ sẵn lòng
        đón nhận cơ hội và luôn tìm kiếm ý tưởng mới.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ thường thích thưởng thức hành trình hơn là điểm đến. Mặc dù họ là
        người tìm kiếm tự do và thích du lịch đi xa, họ nhận thức được rằng họ
        cần chú tâm vào những tham vọng của họ. Họ trân trọng những thứ tinh tế
        trong đời và dù là người theo chủ nghĩa lý tưởng, họ trân trọng những gì
        tiền bạc có thể mua được và luôn tin chắc rằng mọi điều đều có thể xảy
        ra.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người trụ ngày Canh Dần rất đặc biệt và họ khao khát kiến thức thật sự.
        Họ có thể ganh đua và đây là một trong những mặt họ sẵn lòng tiết lộ, họ
        thích đấu trí và không chịu bị lừa do đó họ thường tỏ tra thích tranh
        biện. Sự khao khát kiến thức không đủ để hoàn thiện người Canh Dần mà
        điều thật sự thúc đẩy họ là sự thoả mãn về mặt cảm xúc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người khác nhìn vào người Canh Dần và thấy tài năng và sự thông
        minh của họ nhưng không nhận biết được sự nhạy cảm bên dưới của họ.
        Người Canh Dần giỏi ăn nói và đầu óc nhanh nhạy cũng như có á tính thu
        hút người khác khiến cho họ trở thành nhân vật gợi cảm hứng nhưng họ có
        thể bị chứng tự nghi ngờ và lo lắng kéo lùi lại. Điều này có thể khiến
        cho họ thường xuyên thay đổi và thậm chí ghen tuông trong mối quan hệ
        tình cảm cá nhân. Họ có thể tỏ ra kiểm soát và hi sinh vì người khác
        nhưng họ cũng thường nhận thấy rằng nếu họ chấp nhận buôn bỏ thì họ
        thường được nhiều hơn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Dần thường cần tìm cách giải toả những cảm xúc sâu kín nhất
        và thường có cá tính nghệ sĩ. Họ có khả năng để vượt qua những nghịch
        cảnh để cuối cùng đạt được mục tiêu mình muốn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những Đặc Tính Của Người Canh Dần: lạc quan, rộng rãi, kiểm soát, quyết
        tâm, ganh đua, duyên dáng.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Dần bẩm sinh có cá tính nghệ sĩ và có đam mê sâu về theo đuổi
        kiến thức. Họ dễ leo lên vị trí quyền lực và có xu hướng vượt trội trong
        lĩnh vực họ chọn theo đuổi. Họ có động lực, thông minh và xuất sắc trong
        sự nghiệp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có thể tỏ ra có tài trong việc kiếm tiền và có thể tạo ra thành quả
        tốt trong mọi việc họ theo đuổi. Họ có tri thức và trí nhớ tốt cũng như
        kỹ năng giao tiếp xuất sắc giúp họ thành công trong hầu như mọi việc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Quan hệ đối tác và hợp tác dài hạn có thể là vấn đề khó khăn cho người
        Canh Dần vì họ rất dễ thay đổi. Họ có thể bị lối kéo đến những ý tưởng
        lúc đầu có vẻ bất khả thi nhưng họ không ngần ngại sử dụng hết quyền lực
        để đi ngược lại những trở ngại để biến kế hoạch thành trái ngọt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thành công gần như là chắc chắn nếu họ sử dụng những kỹ năng bẩm sinh
        tốt nhất.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Công Việc:</h4>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Dần rất chú trọng giao tiếp xã hội và họ rất thích những
        người chú trọng sự nghiệp như giảng dạy, y học hay giao tiếp. Chính việc
        phục vụ người khác khiến họ cảm thấy thích thú nhất và họ cũng rất đam
        mê chính trị hay bộ máy nhà nước.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ rất sáng tạo và năng động và thích con đường nghệ thuật vì nó cho họ
        phương thức để biểu lộ bản thân. Họ có thể trở thành diễn viên, nghệ sĩ
        hay nhà văn. Họ khao khát tri thức có liên quan đến nghiên cứu hay khoa
        học, đặc biệt trong lĩnh vực tâm lý.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Trong Công Việc Của Người Canh Dần:</b> thông minh,
        xã giao, sáng tạo, quyết tâm, thiếu kiên nhẫn, ngoan cố, mất tập trung,
        thiếu quan tâm.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Quan Hệ - Hôn Nhân - Gia Đình:{" "}
      </h4>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Dần có thể phải vật lộn mới có thể duy trì được sự cân bằng
        gĩữa năng lực thể hiện và đón nhận cảm xúc. Họ có thể ghen tỵ và đa
        nghi, khó khăn lắm mới có thể tin tưởng được và thường nghi ngờ cảm xúc
        hay thay đổi của chính mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Dần rất thích ở bên cạnh người siêng năng và thành công cũng
        như họ muốn gắn bó với người độc lập và tri thức.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Khi họ đang có tình yêu, họ sẵn lòng, hăng hái hi sinh nếu cần để hỗ trợ
        bạn đời. Họ chung thuỷ và yêu thương cũng như bảo vệ người mình yêu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Dần thông minh và năng động và họ có xu hướng thu hút nhiều
        người khác bởi sự duyên dáng của mình. Họ không cần phải cố gắng để nổi
        tiếng. Bên dưới vẻ ngoài độc lập, họ hiểu được rằng họ sẽ không thể
        thành công nếu không được những người khác giúp sức.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ tử tế và lịch thiệp, cũng như tỏ ra gần gũi và rộng lượng. Nhưng họ
        cũng hay thay đổi và bạn bè thường cảm thấy họ khó hiểu vì luôn thích
        thay đổi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Dần thường dễ có mối quan hệ căng thẳng với gia đình, thường
        hay cãi vã với anh chị em và khó lòng nhận được thừa kế. Điều này khiến
        cho họ dễ độc lập. Sự cứng đầu của họ khiến cho họ khó hoà hợp trong gia
        đình và có xu hướng không giao tiếp. Tuy vậy họ cũng có nhu cầu được
        thừa nhận và quan tâm từ gia đình, đặc biệt về những vấn đề liên quan
        đến gia tộc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ hưởng lợi nhiều từ việc kết hôn muộn. Nếu họ kết hôn quá sớm thì mối
        quan hệ dễ rơi vào thiếu giao tiếp cũng như ngờ vực khiến khó tạo nền
        tảng bền vững cho mái ấm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Những Đặc Tính Trong Tình Cảm & Mối Quan Hệ:</b> chung thuỷ, yêu
        đương, nhanh nhạy, duyên dáng, quan tâm, độc lập, nóng tính.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:{" "}
      </h4>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Canh kim toạ trên Tuyệt của Vòng 12 Trường Sinh, không thông căn trong
        lá số Bát Tự. Điều này tạo nên cục Cái Đầu, biểu thị rằng Thiên Can khắc
        Địa Chi. Đây cũng là cách cục tốt đối với nam giới sinh ngày Canh Dần,
        nhưng gây xấu hơn cho nữ giới sinh ngày Canh Dần.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Cả nam hay nữ đều có xu hướng thích chỉ trích người khác và cần phải nỗ
        lực hơn để tỏ ra quan tâm người xung quanh, họ cũng có thể thậm chí trở
        nên ám ảnh khi không đạt được tham vọng của mình. Đối với nữ Canh Dần
        thì những tính cách này có thể gây bất ổn trong hôn nhan vì có thể khiến
        họ cứng đầu và thích cãi vã. Hôn nhân của họ sẽ ổn hơn ở lần hai hay lần
        ba.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Dần có tính cách năng động và mạnh mẽ. Họ có trách nhiệm, có
        năng lực quan sát và thích khởi nghiệm. Họ có khả năng lý luận tốt cũng
        như óc thực tế giúp đạt được thành công trong kinh doanh.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tuy nhiên họ cũng là những người có tầm nhìn lý tưởng và chìa khoá để
        khai mở khả năng tuyệt vời của họ chính là cảm hứng. Nếu không có cảm
        hứng họ dễ thích mục tiêu và hay phải dựa vào người khác để được hướng
        dẫn. Họ sẽ nhìn vào những người này để được cung cấp nguồn cảm hứng cho
        đến khi họ có thể tự đứng vững trên đôi chân của mình và tự tạo ra hệ
        thống giá trị của riêng mình. Một khi phát hiện được những gì của riêng
        mình, họ sẽ mạnh mẽ hơn mức họ nghĩ về bản thân mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Dần gặp may mắn hơn nếu trong lá số Bát Tự có Mộc, Hoả (vì
        Canh Kim xua đuổi Giáp Mộc để tạo ra Đinh). Hình ảnh của trụ này chính
        là gỉ sét bị làm sạch và để lộ ra tính chất tốt đẹp của Kim. Điều này
        biểu thị tính chất tốt của cuộc sống tổng thể nhưng không có nghĩa là
        thành công cho người Canh Dần. Cũng như thành công không diễn ra dễ dàng
        hay nhanh chóng mà những nỗ lực của họ chỉ làm cho quyết tâm và hình
        thành tính cách của họ cũng như giúp họ có cơ hội tốt hơn để thành công.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tương tự,Tài cần phải được chặt đi để tạo điều kiện dễ thấy gốc rễ. Nếu
        trụ này gặp thêm ngũ hành Hoả Thổ trong đại vận, thì biểu thị cơ hội tốt
        cho người Canh Dần gặp may mắn. Tuy nhiên sẽ bất lợi nếu trụ ngày Canh
        Dần gặp nhiều Kim, Thuỷ và Mộc trong đại vận.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Cũng bất lợi nếu trụ ngày Canh Dần gặp nhiều Thực Thần Tinh hay Thương
        Quan Tinh trong lá số vì biểu thị thần kinh bất ổn cũng như khó tìm được
        bình an trong lòng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Lá số Canh Dần cũng biểu thị Xung giữa Kim và Mộc tức ám chỉ người này
        có thể có tuổi thọ ngắn hơn tuổi trung bình. Nếu có ám hợp với Sửu trong
        lá số thì người Canh Dần được khuyên là không nên rủi ro hay liều trong
        kinh doanh vì có thể dễ dẫn đến rắc rối pháp lý hay thậm chí tù tội.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu Canh Dần sinh vào ban ngày thì họ sẽ thành công thông qua những nỗ
        lực tự thân mà không cần phải dựa vào người khác. Họ cũng sẽ có thành
        công lớn hơn trong việc tự khởi nghiệp nếu sinh vào ban đêm. Sẽ không lý
        tưởng bằng nếu họ sinh vào tháng Sửu vì biểu thị gặp nhiều thách thức
        nếu muốn thành công.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Canh là hành của sự hành động liên quan đến việc muốn biến mọi thứ thành
        hành động. Họ luôn cảm thấy trách nhiệm của họ là thực hiện mọi thứ và
        nếu họ không làm nó thì không ai khác sẽ thật sự làm công việc. Họ là
        biểu hiện của chính nghĩa và công lý. Họ tin tưởng rằng lao động vất vả
        sẽ xây nên mọi thứ. Với suy nghĩ đó, họ thường đưa bảo thân vào những
        tình huống không mong muốn khi phải đấu tranh vì những thứ không có lợi
        cho họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Canh ở đây toạ Dần mang theo khí chính là Giáp Mộc Thiên Tài trong khi
        cũng mang theo 2 phụ khí là Mậu Thổ Thiên Ấn và Bính Hoả Thất Sát. Sao
        Thiên Tài đem đến khả năng độc nhất về phát thiện tương tai tiềm ẩn. Họ
        không thấy một khu rừng nhưng biết cây rỗ giá trị bao nhiêu. Khi họ nhìn
        thấy một người, họ không biết tình trạng hiện tại của bạn, nhưng họ biết
        bạn có thể trở thành điều gì trong tương lai.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bạn sẽ không nhìn thấy người Canh Dần dành thời gian bên cạnh bạn nếu
        bạn không có giá trị gì đối với kế hoạch lớn của họ. Bởi vì họ không
        nhìn mọi thứ ở thể hiện tại, bạn có thể bị rối trí bởi những gì họ làm
        với bạn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có tầm nhìn rất rộng lớn về môi trường và do đó có thể thấy một bức
        tranh lớn khá nhanh và trực giác đúng đắn giúp họ ra quyết định nhanh.
        Thất Sát đi kèm thúc đẩy họ hành động mạnh mẽ. Họ rất cởi mở với cách
        hành động Bính Hoả và có thể nắm giữ mặt trận của mình Mậu Thổ với kế
        hoạch và quyết định.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Dần thu hút Hợi vào trong Lục Hợp đem đến hành Nhâm Thuỷ Thực Thần và
        Giáp Mộc Thiên Tài. Điều này khá trung lập nếu xét về mối quan hệ tình
        cảm. Thực Thần thật sự giúp khả năng xây dựng tương lai bằng trí tưởng
        tượng và sẽ thúc đẩy hành động lớn hơn. Họ có ít vấn đề về tiền bạc vì
        Thực Thương giúp sinh ra tài lộc, họ rất giỏi đánh hơi cơ hội giá trị.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Dần xua đuổi Thân bao gồm Canh Kim Tỷ Kiên. Mậu Thổ Thiên Ấn và Nhâm
        Thuỷ Thực Thần. Khá trung lập nếu xét về tình cảm. Xua đuổi bạn bè
        thường đi kèm với khả năng tiến lên phía trước mà không có sự đồng
        thuận. Thái độ cứng rắn của họ cho họ khả năng thực thi ngay cả khi thế
        giới chống lại họ và ngay cả nếu nó đi ngược lại trực giác hay phán đoán
        của họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bạn có thể nhìn thấy cưa máy phá huỷ rừng mà không vì lý do gì. Họ có
        thể có hoặc không có kế hoạch thật sự để sử dụng gỗ đó, nhưng đối với họ
        thì gỗ sẽ có ích nhiều hơn là rừng. Nếu kèm với kỷ luật tốt (Chính
        Quan), người Canh Dần có thể dành thời gian thu hoạch rừng và tái trồng
        cây để lại sinh ra tài sản vô hạn. Mấu chốt ở đây là đừng tiêu diệt mọi
        thứ quá nhanh.
      </p>
    </div>
  );
};

export const CanhTy = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Canh Tý: ngây thơ, sống đơn giản, ngờ nghệch, không
        thích phức tạp, chân thành và thật thà, tốt bụng, có quan hệ xã hội tốt
        và được nhiều người yêu thích, nhưng có xu hướng không chấp nhận quan
        điểm của người khác. Nữ giới thì hôn nhân xấu và không viên mãn, duyên
        phận con cái kém. Nam giới thì là người cứng rắn, nhưng không may mắn,
        thường bị đánh giá thấp trong công việc.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tựa như thả một hòn đá vào trong hồ, có thể gây ra một ít tiếng động. Âm
        thanh này tựa như một âm vang hấp dẫn cuốn hút bạn vào tâm hồ nước, nơi
        của sự huyền bí. Người Canh Tý có khả năng thu hút, quyến rũ và lôi cuốn
        người xung quanh mình. Khả năng tự nhiên kiểm soát các âm thanh, tiếng
        nói giúp họ trở nên khác biệt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Canh Kim là biểu tượng của công lý vì nó khá cứng rắn ít thay đổi. Một
        khi đã được rèn thành thanh kiếm, họ chấp nhận sự thách thức và chấm dứt
        mọi tranh cãi. Vũ khí sắc bén có một nhiệm vụ là cắt. Do đó, không ngạc
        nhiên khi nhiệm vụ của Canh Kim là dẹp loạn, và biến tất cả mọi thứ quay
        trở về vạch ban đầu. Khả năng giữ cho trái tim lạnh giúp họ ra được
        quyết định cuối cùng tuy khó khăn và không dễ chịu.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Canh Tý cũng như một thanh kiếm có lưỡi sắc chôn vùi trong băng tuyết.
        Nó biểu thị tính cách hai mặt của người ngày sinh này. Người sinh ngày
        Canh Tý thường có lúc cứng đầu, khó chịu và thiên kiến trong khi lúc
        khác lại mềm mỏng, nhân từ và lý tưởng. Họ có thái độ kiểu ngôi sao và
        tỏ ra vượt trội trong khi cũng thích giúp đỡ người khác. Họ là những nhà
        lãnh đạo bẩm sinh và là cố vấn khá tài năng. Họ có thể tỏ ra khá kiêu
        căng và đồng thời trong thâm tâm cũng cảm thấy bất an. Không ngạc nhiên
        khi họ đa phần phải vật lộn với cảm xúc của mình, và vẫn có thể giữ sự
        lạc quan.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thường họ rất tài năng và dễ đạt được thành công từ khi còn trẻ nhưng đa
        phần vì không có kỷ luật và phương hướng có thể khiến họ cảm thấy không
        thoả mãn. Họ dễ dàng buồn chán và không hạnh phúc với việc ngồi không mà
        chỉ ở trạng thái tốt nhất khi có động lực nhưng cũng có thể thất bại nếu
        không tìm được động lực. Họ thường có tham vọng tự nhiên và có xu hướng
        mục tiêu khi được khuyến khích và kích thích bởi chướng ngại mà thường
        cản đường người khác. Không sợ hãi, dám và can đam là những từ vựng
        trong từ điển của họ sẽ khiến cho họ vượt trội và dám xây dựng nền móng
        để phát triển hướng đi mới. Họ khao khát chiến thắng và có thể ghen tức
        với người khác. Tính hay thay đổi của họ có thể là nguồn gốc gây ra sự
        nóng giận và họ sẽ có lợi ích khi kết nối sự bùng nổ của họ với sự tự
        thể hiện mình và từ đó họ cảm nhận rằng cuộc sống mình vẫn năng động và
        đi đúng hướng. Nếu không đặt trọng tâm vào hướng, họ dễ lãng phí tiềm
        năng vào những việc nhỏ nhặt. Họ đặt mong đợi cao cho bản thân và những
        người khác và điều này đôi khi rất áp lực.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù có cảm giác độc lập cao, người Canh Tý không thường đạt được
        thành công một mình. May mắn, họ thường có tính cách duyên dáng và thu
        hút người khác do bản tính thích cảm thông. Họ thường thu hút những
        người thông minh và thú vị đến giúp mình đạt được mục tiêu hướng tới. Họ
        thường có kỹ năng thuyết phục và biết cách khơi gợi động lực bên trong
        người khác nhưng họ lại ít khi chịu tiết lộ cảm xúc của riêng mình. Họ
        là những trái tim mềm yếu và có thể kết bạn với những người tót và trực
        giác của họ liên kết với lý tính có thể giúp họ trong việc cho lời
        khuyên và trở thành những người cố vấn đắc lực. Nhưng người Canh Tý muốn
        trở thành trung tâm của sự chú ý nơi họ được khen ngợi vì lòng tốt của
        mình và sẽ cảm thấy bất an nếu không được chú ý.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Tý rất nghệ sĩ và yêu thiên nhiên cũng như thích cái đẹp. Họ
        có trái tim can đảm và thường dễ bị lôi cuốn vào những lý tưởng nhân
        văn, và nếu họ duy trì được kỷ luật thì có thể biến đam mê thành hành
        động cụ thể để đạt lợi ích cao hơn. Khi được khơi gợi động lực, họ là
        người thấu hiểu, suy nghĩ chiến lược và đầu óc có tổ chức.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Có đầu óc sắc bén và sự tò mò hiếu kỳ sâu sắc, người Canh Tý thích theo
        đuổi kiến thức. Điều này sẽ dẫn dắt họ tìm kiếm học vấn ở tầng cao, và
        nếu khi còn trẻ họ không thoả mãn được lòng yêu thích kiến thức, họ sẽ
        theo đuổi khi về già. Nếu họ không theo con đường học tập kiến thức
        trường lớp, họ có thể theo đuổi việc tự học một môn học nào mình yêu
        thích. Sự yêu thích kiến thức có thể tạo thành nền tảng giúp họ thành
        công hơn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Tý có thể theo đuổi kiến thức về triết học và huyền học như
        khao khát tìm đến tận cùng những bí ẩn lớn của cuộc sống hay chỉ đơn
        giản là muốn bản thân tường tận về nền tảng các môn khoa học nhân văn.
        Việc theo đuổi các hoạt động này cũng như thiền định sẽ giúp họ tìm được
        sự bình an trong tâm hồn và tập trung tốt hơn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Đặc Tính Chung của Người Canh Tý:</b> tham vọng, suy nghĩ tích cực,
        mạo hiểm, có động lực làm việc, lý tưởng hoá, có khiếu diễn xuất.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Tý độc lập, gìau sức sống, sáng tạo và có tài ăn nói. Họ
        thường tò mò và có đầu óc rộng mở với khả năng bẩm sinh nhìn tổng quát.
        Họ có trí thông minh, họ không bao giờ ngừng trong việc đóng góp tích
        cực cho các hoạt động tập thể ngay cả nếu như họ quá cứng đầu. Họ thường
        xuyên cần phải làm việc, thích thương lượng, họ cũng là những lãnh đạo
        bẩm sinh thích khởi nghiệp và làm việc ở tiền tuyến hàng đầu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Tý thường thích xã giao và thường dễ bị thu hút vào những thử
        thách mới, con người mới. Họ thích dịch chuyển và thường khó tập trung
        hay cam kết vào 1 việc gì; điều này có thể làm hạn chế họ phát huy tối
        đa tiềm năng của mình. Điều này cùng với ham muốn đạt kết quả tức thời
        có thể khiến họ mất kiên nhẫn và đôi khi họ có thể cảm thấy mệt mỏi nếu
        đối mặt với sự nóng giận và thất vọng của mình.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu có ý thức tốt về kỷ luật và luôn chú tâm đến 1 hướng nhất định,
        người Canh Tý thường sẽ siêng năng và không biết sợ hãi điều gì. Họ tin
        rằng “kiến thức là sức mạnh” và có động lực để liên tục học hỏi và
        thường xuyên suy nghĩ sáng tạo để phát triển sự nghiệp. Mặc dù có thiên
        hướng nghệ thuật, họ không phải là người không biết suy nghĩ logic hay
        thiếu cái nhìn rõ ràng. Ngược lại, người Canh Tý có thể nhìn ra rõ ràng
        vấn đề thực chất để tìm ra giải pháp. Thành công đến với người Canh Tý
        rất dễ dàng vì họ đã cố gắng làm việc chăm chỉ vì thành công và họ
        thưởng thức được những điều tốt đẹp trong cuộc đời.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Tý có độ nhận thức cao và có thể đọc vị được cảm xúc của
        người khác một cách dễ dàng. Họ ý thức sâu sắc, thấu hiểu nhân tâm nên
        dễ cảm nhận được sự thoả mãn về mặt cảm xúc. Họ thường ủng hộ những
        người xung quanh mình và thường cảm thấy thoải mái chia sẻ, hợp tác với
        những người xung quanh. Họ có ham muốn được người xung quanh trân trọng
        vì hành động giúp đỡ của mình và có nhu cầu quan tâm giúp đỡ người khác.
        Nếu họ cảm thấy không được trân trọng, họ có thể trở nên bất an và khó
        chịu. Họ cảm thấy thoải mái nhất khi được sống với trực giác của mình,
        nhất là khi họ có thể cân bằng sự chính trực với sự khôn khéo để nhận
        được sự hợp tác từ tất cả các bên liên quan.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Tý thường gặp mâu thuẫn giữa bản chất rộng lượng và tham vọng
        cũng như sự yêu thích vật chất. Điều này có thể khiến họ đi theo xu
        hướng tâm linh hay huyền học vì nó có thể giúp họ bình an về tâm hồn.{" "}
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Công Việc:</h4>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Tý có cơ hội rất lớn để thành công trong bất cứ lĩnh vực nào
        họ lựa chọn. Họ sở hữu năng lượng và kiến thức vô tận cộng với khả năng
        ăn nói lưu loát, kỹ năng giao tiếp xuất sắc và ý thức trách nhiệm cao.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù họ tỏ vẻ chú trọng đến hướng ngoại, nhiều người sẽ ngạc nhiên khi
        phát hiện ra người Canh Tý rất chú trọng đến bản thân nội tâm của họ.
        Chính cách phối hợp giữa các vấn đề cá nhân và công việc giúp cho họ có
        những mối quan hệ bạn bè giúp họ thành công.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Tý dễ bị cuốn theo những thách thức và khởi đầu mới. Người
        Canh Tý cũng có khả năng xuất sắc để nhận diện ra cơ hội, họ có tài
        trong việc tìm tập trung vào chi tiết, kỹ năng nghiên cứu và giải quyết
        vấn đề cũng như toàn bộ nguồn lực cần thiết để phục vụ cho họ. Họ có
        khiếu kinh doanh và khả năng phân tích tài chính. Tuy nhiên, người Canh
        Tý lại cảm thấy rất khó để có thể phát triển dự án của riêng mình, thay
        vào đó, họ nên dành năng lượng để phát triển dự án của đồng nghiệp.
        Người Canh Tý rất phù hợp với nghề nghiệp trong lĩnh vực thương mại nơi
        họ có thể tận dụng năng lực kết nối và cộng tác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Tý là những nhà lãnh đạo bẩm sinh, tuy nhiên họ cũng gặp khó
        khăn vì tính cứng đầu cố hữu hay sự ích kỷ có thể làm hỏng những mối
        quan hệ mà họ khó khăn mới có thể hình thành. Nếu không có được người
        xung quanh giúp đỡ, người Canh Tý có thể cảm thấy bất an.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Tý đặc biệt thông minh và do đó dễ cảm thấy buồn chán trong
        công việc, họ nên tìm một sự nghiệp có thể cho phép họ đa dạng hoá.
        Người Canh Tý do đó cũng thường thay đổi sự nghiệp nhiều trong đời.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những Đặc Tính Trong Công Việc: thực dụng, tính lãnh đạo, biết cách sắp
        xếp, suy nghĩ chiến lược, thông minh, trực giác, dễ buồn chán, cứng đầu,
        mất kiên nhẫn, tham lam, nói huỵch toẹt{" "}
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Hôn Nhân - Gia Đình - Quan Hệ:{" "}
      </h4>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Tý tỏ ra không có cảm xúc hay hời hợt trong tình yêu. Họ đột
        nhiên trở nên ít nói mà trầm lắng và bí mật, sự lo sợ trong tâm thường
        cũng là dấu hiệu bất ổn và thiếu quyết đoán.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Tý có nhu cầu sâu sắc và mạnh mẽ cần tình yêu và ham muốn
        cháy bỏng về sự yêu thương có thể thúc đẩy họ quan tâm quá nhiều và đôi
        khi bị lôi kéo vào các mối quan hệ tình cảm lằng nhằng. Tuy nhiên, họ
        cũng rất cần sự độc lập, lối sống bận rộn và tham vọng bản thân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Tý là những người yêu theo sắc dục và quyến rũ nhưng họ cũng
        cần sự kích thích của trí óc và thích người bạn đời có thể đem lại cho
        họ điều này. Họ bị cuốn hút bởi những người có thể khiến chọ tôn trọng
        và ngưỡng mộ, những người có thể tỏ ra sự thông thái và quyền lực. Họ có
        thể cảm thấy thoải mái nhất với những người vượt trội hơn họ về mặt kiến
        thức hay thành công, đối với nữ Canh Tý có thể họ thích một người lớn
        tuổi hay hình tượng người cha.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Tý có thể cần phải học cách cân bằng mối quan hệ tốt đẹp, và
        họ cũng cần học hỏi kinh nghiệm từ những mối quan hệ tình cảm thất bại
        nhưng không nên bấu víu quá chặt vào quá khứ. Trong quá trình tìm kiếm
        tình yêu, họ có thể phát hiện sai lầm lớn nhất là trầm trọng hoá vấn đề
        và họ cần phải học cách tránh để cảm xúc vào quá nhiều, dẫn đến bản thân
        bị tổn thương và không nhìn nhận rõ ràng mọi việc được. Bất kỳ khi nào
        họ tìm được mối quan hệ cân bằng và hài hoà thì đó chính là tình cảm
        đích thực và người bạn đời sẽ hưởng lợi từ tình yêu vô bờ bến của người
        Canh Tý.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Tý là người bạn cuốn hút và tươi trẻ. Họ cuốn hút nhiều bạn
        bè và người ngưỡng mộ vì bản tính giàu năng lượng thân thiện. Tuy nhiên
        nếu họ cảm thấy bất an, họ có thể trở nên thích tranh cãi hoặc đắm chìm
        vào cảm xúc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù gia đình rất quan trọng đối với người Canh Tý, họ cũng có tham
        vọng và nỗi lo sợ bị giới hạn khiến cho họ khó có thể toàn tâm toàn ý
        tập trung cho gia đình mà dễ bị lung lay bởi sự thiếu quyết đoán về cảm
        xúc và bản tính bốc đồng. Người Canh Tý thích tìm lấy người bạn đời nào
        có thể cho họ cuộc hôn nhân hài hoà hạnh phúc và con cái có thể có tương
        lai sáng rõ.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người nam giới Canh Tý có xu hướng có hôn nhân tốt trong khi người nữ
        Canh Tý lại không may mắn như vậy. Họ có thể đôi khi tỏ ra lạnh lùng hay
        hời hợt khiến cho khó cảm thấy hạnh phúc trong hôn nhân và cảm nhận sự
        cô lập.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Đặc Tính Trong Mối Quan Hệ:</b> rộng lượng, yêu thương, thấu hiểu,
        cuồng nhiệt, duyên dáng, thân thiện.{" "}
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:{" "}
      </h4>

      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Tý có bản tính tò mò vì Tý là Thương Quan nên thường xuyên
        năng động và ít khi ngơi nghỉ. Họ thường cần phải nỗ lực để vượt qua bản
        chất hấp tấp để ra được những quyết định tốt. Vì người Canh Tý sở hữu
        sức mạnh ý chí và quyết tâm to lớn, họ cũng siêng năng và thực dụng và
        phán đoán tốt. Thông qua cách sống thực dụng và vượt qua được những
        chướng ngại phải đối mặt, họ thường có thể hình thành sự khôn ngoan
        trong kinh doanh (nhưng nếu có quá nhiều hành Thuỷ trong lá số thì chưa
        chắc đúng). Họ có bản tính thích quan sát và học hỏi cũng như linh hoạt
        trong suy nghĩ. Canh toạ Thương Quan trong lá số Bát Tự biểu thị rằng nữ
        giới Canh Tý sẽ có xu hướng đối đầu với chồng.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Tý thích được công nhận họ sẽ phát hiện ra rằng họ cần phải
        nỗ lực mới giành được sự tôn trọng này và một trong những khó khăn lớn
        nhất là học cách giao tiếp với mọi loại người. Nếu sự tự tin của họ bị
        suy giảm, họ có thể trở nên co cụm và sự đa nghi của họ có thể xua đuổi
        người khác ra xa. Ngoài ra, họ cần phải học cách kìm chế bản tính mất
        kiên nhẫn và học cách tỏ ra không quá kiểm soát hay ra lệnh cho người
        khác nếu họ muốn thật sự nhận được sự tôn trọng. Thiên can Kim và địa
        chi Thuỷ trong lá số biểu thị sự thông minh và hấp dẫn về vẻ ngoài của
        người đó. Vì vậy những người Canh Tý sẽ dễ dàng có sự nghiệp chính trị
        thành công, hoặc dễ tham gia công nghiệp showbiz, chính phủ, nếu như họ
        thể hiện được sự sáng tạo, chân thật, tính cách chính nghĩa của mình.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu Sửu hay Mùi hiện diện trong lá số Canh Tý thì có ảnh hưởng tiêu cực
        lên Tý vì điều này đồng nghĩa là kiềm hãm tài năng. Sẽ tốt hơn nếu trụ
        ngày Canh Tý gặp hành Mộc ở Thiên Can, biểu thị sự thực dụng và tài năng
        nở rộ, do đó người Canh Tý có thể tạo ra thành công tài chính vững vàng
        hơn.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Hành Hoả trong lá số Bát Tự là ngũ hành quan trọng nhất đối với Canh
        Kim, trong hầu hết trường hợp thì hành Hoả là dụng thần của trụ ngày
        sinh này. Nó có thể làm mềm đi sự sắc bén của người Canh Tý, giúp cho họ
        khéo léo hơn và trong nhiều trường hợp nếu Hoả mạnh thì người này không
        biết sợ hãi và có sự cuốn hút hấp dẫn lớn. Cũng cần lưu ý rằng điều này
        cũng sẽ khiến cho người Canh Tý dễ làm cho người khác sợ hãi và khiến
        cho họ cảm thấy cô đơn.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Tý nếu có quá nhiều hành Thuỷ sẽ có thể bị lạc lối, mất định
        hướng. Quá nhiều Thuỷ sẽ dập tắt Hoả trong lá số Bát Tự, nếu điều này
        xảy ra, người Canh Tý có xu hướng không thèm quan tâm những lời khuyên
        chân tình nhất và khó khăn mới có thể chấp nhận sự hướng dẫn từ người
        khác. Tý cũng là vị trí Tử trong Vòng Trường Sinh nếu gặp nhiều Thuỷ thì
        người Canh Tý sẽ sống rất cảm xúc và dễ kỳ quái. Trong những trường hợp
        nghiêm trọng (nếu có Thổ kỵ thần làm bẩn Thuỷ), thì người này sẽ chịu
        tàn tật hoặc phát triển kém mặc dù họ có thể là người rất yêu thương và
        quan tâm người khác.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Cũng không may nếu trụ Canh Tý gặp nhiều Thổ khô trong lá số. Điều này
        đồng nghĩa với Canh Kim bị chôn vùi và sẽ gây ra cho người này sự thiếu
        can đảm và quyết tâm. Cũng tương tự nếu như Sửu và Tuất gặp Canh Tý
        trong lá số. Sự hiện diện của Dậu trong lá số cũng là dấu hiệu xấu biểu
        thị cảm xúc mất cân bằng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tuy nhiên, nếu Tài và Thất Sát hiện diện trong lá số Canh Tý cùng lúc và
        nhiều hành Hoả và Thổ, người Canh Tý dễ trở nên phi thường. Hoả từ Thất
        Sát làm ấm lá số và rèn Canh Kim trong khi hành Tài biểu thị thành công
        về mặt tài chính và vật chất.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nếu có sự kết hợp giữa Tý và Thân, Thìn trong lá số thì người Canh Tý sẽ
        gặp may mắn trong mối quan hệ bạn bè, được những người ở vị trí cao giúp
        đỡ họ đạt mục tiêu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Tý sinh vào tháng mùa Xuân và Hè có thể sống một cuộc đời
        hạnh phúc và viên mãn trong khi người sinh vào tháng Mùa Thu và Đông sẽ
        trở nên tốt bụng và tử tế trong mối quan hệ con người. Nếu họ sinh vào
        ban ngày (5h sáng - 5h chiều) thì họ sẽ sống thọ và người sinh vào ban
        đêm thì sẽ có nhiều tài lộc. Đáng buồn cho những người sinh vào tháng
        Mùi thì có thể họ sẽ không đạt được nhiều như họ kỳ vọng trong đời.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Canh toạ trên Tý mang theo duy nhất hành Quý Thuỷ là Thương Quan. Sao
        Đào Hoa đóng vai trò quan trọng trong hấp dẫn giới tính và Tý là giấc mơ
        hứa hẹn. Cũng như sương mù mà bạn vốn không thể nắm bắt được, họ đến khi
        muốn, ở trong bao lâu và sẽ rời bạn đi mà không cần giải thích. Thương
        Quan là ngôi sao của sự quan tâm và hào nhoáng, họ yêu thích sự chú ý.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đối với nữ giới, sự xuất hiện của Thương Quan đem đến rắc rối cho hôn
        nhân vì Thương Quan khắc Chính Quan.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ thường lên giọng trong quan hệ tình cảm và khiến cho người đàn ông
        khó lòng phản ứng phù hợp. Một khi có một sự lựa chọn khác xuất hiện,
        người đàn ông cảm thấy dễ dàng để rời bỏ một mối quan hệ và tìm đến cái
        khác dễ hơn.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đàn ông thì ít bị ảnh hưởng bởi Thương Quan trong tình cảm, trong thực
        tế nó lại củng cố thêm sao của người vợ là Chính Tài vì Thực Thương sinh
        Tà. Ở đây người đàn ông Canh Tý là người biết cách ăn nói ngọt ngào hay
        còn gọi là diễn để lấy lòng phụ nữ.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tý hợp với Sửu mang theo Kỷ Thổ Chính Tài cũng như Quý Thuỷ Thương Quan
        và Tân Kim Kiếp Tài. Cả mối quan hệ tình cảm của nam và nữ đều gặp những
        sao làm tổn hại. Thông minh, giỏi thể hiện và ganh đua, bạn sẽ thấy
        người Canh Tý liên tục hấp dẫn nhiều người khác và do đó họ cũng dễ cuốn
        hút những rắc rối về tình cảm.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tý xung với Ngọ mang theo năng lượng của Đinh Hoả Chính Quan và Kỷ Thổ
        Chính Ấn. Ở đây sao chỉ người chồng Chính Quan bị đẩy lùi và kèm với sự
        hấp dẫn ban đầu của Thương Quan, mối quan hệ tình cảm của phụ nữ Canh Tý
        khó lòng mà giữ bền được.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhìn chung, trụ ngày Canh Tý khá sốc nổi khi xét về quan hệ xã giao. Họ
        có khả năng thu hút những người xung quanh. Ngay cả mặc dù được nhiều
        người chú ý, họ vẫn luôn phải vật lộn với cảm giác cô đơn. Họ rất giỏi
        trong việc đưa ra luận điểm trong các cuộc tranh luận.
      </p>
    </div>
  );
};

export const CanhTuat = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Canh Tuất: người tự phụ, ít tinh tế, có nhiều người ủng
        hộ cũng như ghen ghét, xem cuộc đời như trò chơi, yêu thích công lý, có
        vẻ ngoài điềm tĩnh nhưng tính cách gây hấn bên trong, là người thích rủi
        ro cao, luôn tìm kiếm những điều mới mẻ, thích chơi cờ bạc, vận đào hoa
        vượng nhưng hôn nhân nhiều thăng trầm, dễ tái giá khi lớn tuổi. Nữ giới,
        dễ quen với người đàn ông không chung thuỷ, khó gặp người chồng tốt. Nam
        giới, có thể nhận được sự giúp đỡ từ vợ.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Nhiều người hay thắc mắc điều gì là tốt nhất? Điêu tốt nhất cũng là điều
        được dùng nhiều nhất trong cuộc đời bạn, dù đó là kỹ năng hay vật gì.
        Bạn càn sử dụng nhiều thì bạn càng khai thác được tính chất tốt hơn.
        Canh Tuất cũng như một thanh gươm đã trãi qua nhiều trận đấu, thường
        trông có vẻ khiêm tốn và thiếu tinh xảo cho đến khi nó xuất hiện trong
        trận đấu. Họ được mài giũa sắc bén thông qua chiến đấu và ngày càng dễ
        sử dụng hơn vì người dùng đã quên với trọng lượng, cấu trúc và vẻ ngoài
        của nó.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Canh Kim là hành của công lý và thường là rào chắn để bảo vệ người yếu
        đuối. Họ thường dễ dính vào rắc rối vì họ không sợ bản thân bị hại hay
        thương tổn. Họ thường lao vào những tình huống mà không suy nghĩ sâu xa
        những gì hậu quả sau này. Đây là hành cần thiết đối với tố chất của
        người lính trong chiến đấu. Bởi vì bản tính can đảm, họ thường nhận được
        sự ủng hộ dễ dàng và có thể tạo dựng được đủ động lực để xây dựng nên đế
        chế của mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mạnh mẽ bên ngoài và cả bên trong, người Canh Tuất cũng tương tự như
        hình ảnh một thanh gươm lớn. Không ngạc nhiên khi đó là ngày sinh cứng
        nhất trong 60 Hoa Giáp.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngày sinh Canh Tuất mạnh mẽ cả về thể chất và tinh thần. Họ thật sự sinh
        ra để vĩ đại và cần phải đi theo bản ngã đặc biệt và con đường sáng tạo
        để đạt được điều đó.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Tuất biết rõ rằng không có gì đạt được mà không cần nỗ lực và
        họ hiệu quả nhất khi họ nỗ lực theo đuổi mục tiêu. Tham vọng, tỉ mỉ và
        hướng đến thành công, họ có thể tàn nhẫn và có khả năng nhìn xuyên thấu
        mọi vật với cái nhìn chân thực. Họ tự tin, can đảm và dám thử nghiệm và
        chịu rủi ro cũng như chịu hi sinh và vất vả trong quá trình theo đuổi
        những gì đam mê. Mục tiêu của họ không phải là tồn tại vì người Canh
        Tuất không thể chịu đựng được sự chán nản và nó có thể khiến họ trầm
        cảm. Họ thích thay đổi và những ý tưởng mới, họ nổi loạn và phá vỡ giới
        hạn, thích dẫn đầu và đi đầu chiến tuyến cách mạng và sáng tạo mới.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Tuất truyền sức mạnh đến những người xung quanh họ. Họ thân
        thiện và chân thành và thường được cho lời khuyên và hỗ trợ đến bạn bè.
        Họ có thể áp đặt và nói thẳng nhưng họ cũng tử tế và hi sinh bản thân.
        Họ dựa vào sự khuyến khích của người khác cũng như thích có một nhóm bạn
        bao gồm những người duyên dáng giống họ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ẩn giấu bên dưới tất cả những sức mạnh này là sự nhạy cảm, cảm xúc sâu.
        Họ có thể lo lắng và dẫn đến do dự và thiếu quyết đoán. Không có tình
        bạn và tình yêu, họ có thể mất đi niềm tin vào bản thân và cảm thấy bắt
        đầu lạc lối, một số trong số các phương pháp kiểm soát hay áp đặt có thể
        được truyền động lực bởi sự bất an cũng như cái tôi cao. Họ đôi khi khao
        khát một mình và đam mê đi sâu vào thế giới huyền bí nhưng họ vẫn hoài
        nghi về tất cả những gì tâm linh và thường khó có thể tin vào trực giác
        của mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Tuất có tình yêu đối với nghệ thuật và tự nhiên, họ yêu thích
        sáng tạo và yêu thích viết lách và văn chương. Họ có phong cách và thích
        thưởng thức những gì tinh tế trong đời. Họ khao khát lối sốngxa hoa và
        có thể hưởng thụ quá đà nếu điều kiện của họ cho phép. Họ cũng sợ sống
        không ó tiền và khao khát sự ổn định về vật chất. Sự yêu thích vật chất
        này cộng với sự nhạy cảm của họ có thể khiến họ yêu thích công tác từ
        thiện và giúp đỡ đồng bào.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Của Canh Tuất:</b> đa tài, thấu hiểu, nhiệt tình,
        thiếu quyết đoán, siêng năng, kiên nhẫn.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trong thế giới kinh doanh, người Canh Tuất khá dễ nhận ra, họ cứng đầu,
        tự cao và nhiều kiến thức. Có ý chí mạnh mẽ, họ không thích tuân thủ
        mệnh lệnh và muốn nhanh chóng leo lên vị trí lãnh đạo. Họ có thể cứng
        cỏi và dù có khả năng truyền cảm hứng cho người khác, họ thường dẫn dắt
        người khác bởi sự sợ hãi hơn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có tri thức và quan tâm đến chi tiết. Họ có những suy nghĩ độc đáo
        khiến cho họ trở thành những người giải quyết khó khăn và có năng lực
        lên kế hoạch. Họ có cách tiếp cận trực tiếp và thực dụng và không dễ gục
        ngã trước khó khăn. Họ chắc chắn thành công trong mọi công việc nhận
        lãnh. Họ đôi khi cần phải bình tĩnh để tránh gây nhiều cãi vã và mâu
        thuẫn vì quá ghen tuông.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc cho vẻ ngoài thô lỗ, họ có kỹ năng giao tiếp xuất sắc và cá tính lôi
        cuốn khiến cho họ giỏi trong thiết lập mối quan hệ. Họ là những nhà
        ngoại giao khéo léo có tài trong văn chương và viết lách.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ là những người không dễ nhường nhịn. Họ sẽ đổi nghề ít nhất một lần
        trong đời vì thích những ý tưởng mới mẻ và hấp dẫn hơn. Họ thường xem
        thay đổi như giải pháp đối với những vấn đề và thường thích kích thích
        trí óc. Một khi đã yêu thích một chính nghĩa hay hoạt động nào, họ sẵn
        lòng dành hết năng lượng và sự hào hứng cho nó.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Công Việc:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Tuất có đầu óc sắc bén và tự tin dám nói ra với một chút hơi
        hướm quyền uy. Họ thoải mái trong vị trí quản lý và phù hợp với sự
        nghiệp trong ngành chính trị hay luật sư.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Với sự khéo léo ngoại giao và bản tính thân thiện, họ dễ tham gia vào
        ngành quan hệ công chúng hay quản lý khách hàng. Kết hợp với đầu óc cởi
        mở hay quan tâm về triết học, có thể thúc đẩy họ theo việc giảng dạy hay
        tôn giáo. Sự yêu thương đồng loại giúp cho họ dễ theo đuổi các công việc
        nhân đạo mà họ dành hết năng lượng.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ là những người làm trong ngành giải trí với sự yêu thích màu sắc,
        hình thể và sự hài hoà. Họ có thể bị lôi kéo vào ngành biểu diễn, nghệ
        thuật và công nghiệp giải trí. Họ xuất sắc khi sử dụng khả năng giao
        tiếp như là một phần trong sự nghiệp và có tài năng trong việc phối hợp
        giữa kinh doanh với niềm vui.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ cũng có xu hướng nổi loạn và thích sống tự do để tự do thể hiện bản
        thân mình. Họ có thể là người cải cách can đảm và là người tiên phong
        với nhu cầu muốn khám phá ý tưởng mới để giữ cho đầu óc liên tục bận
        rộn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những Đặc Tính Trong Công Việc - Sự Nghiệp: sáng tạo, có khả năng lãnh
        đạo, trí nhớ tốt, thông minh, ngoan cố, tâm trạng thất thường, thích phê
        bình.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Gia Đình - Quan Hệ - Hôn Nhân:{" "}
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Tuất có đam mê mãnh mẽ đối với yêu thương kết hợp với yêu
        thích sự thay đổi có thể đôi khi dẫn họ đến việc bị lôi cuốn vào những
        tình cảm lãng mạn. Họ có thể tỏ ra hời hợt trong chuyện tình cảm nhưng
        đây là chỉ là một cách bảo vệ bản thân vì họ là những người cầu toàn
        khiến dễ bị tổn thương.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Tuất có khả năng suy nghĩ vượt trội và là người tình tận tâm
        nhưng nghi ngờ và bất an có thể khiến họ trở nên lo âu và rụt rè cũng
        như đột nhiên thay đổi ý kiến. Mặc dù họ có thể luôn cần một sự tự do,
        người Canh Tuất có khả năng hi sinh phi thường để giữ hạnh phúc. Trong
        những lúc như vậy, mặc dù ý định của họ là tốt, họ có thể cần phải tránh
        trở nên quá kiểm soát, ghen tuông và sở hữu.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Tuất thích làm vui cho người khác cùng với sự tự tin khiến
        cho người xung quanh bị cuốn hút và gợi cảm hứng. Họ đồng cảm và là
        những lắng nghe tuyệt vời và với khả năng giải quyết khó khăn cũng như
        có khả năng độc đáo để ngỏ lời giúp đỡ và ủng hộ bạn bè. Họ thường cần
        phải tránh cho người bạn đời phụ thuộc quá nhiều vào họ và cũng như giữ
        sự cân bằng trong quan hệ tình cảm.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Tuất toả ra tình yêu và sự ấm áp đối với những người hâm mộ
        mình nhưng họ cũng có thể tỏ ra tâm trạng thất thường. Họ cần sự quan
        tâm của người khác vì họ cần sự tự tin bản thân và nếu họ cảm thấy bạn
        bè đã từng dựa vào họ lại quay lưng với họ, họ sẽ cảm thấy bất an và rụt
        rè.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Tuất rất cuốn hút và thích những người có tài sáng tạo và nếu
        xung quanh có nhiều những người như vậy, người Canh Tuất càng tỏ ra
        thích xã giao và năng động hơn. Dù sống tình cảm, người Canh Tuất có thể
        mất kiên nhẫn và sống lý tưởng cũng như có mong đợi cao trong tình bạn
        và điều này đồng nghĩa họ có thể dễ dàng cảm thấy thất vọng nếu bạn bè
        sống không đúng với kỳ vọng của mình. Họ thường cần học cách nuôi dưỡng
        tình bạn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Gia đình và mái ấm rất cần thiết đối với người Canh Tuất. Họ có nhu cầu
        cần an toàn về vật chất khiến cho việc gia đình yên ổn hạnh phúc trở
        thành một trong những ưu tiên hàng đầu. Tuy vậy, việc gìn giữ một cuộc
        hôn nhân hạnh phúc là một trong những thách thức đối với họ. Nam giới
        Canh Tuất sẽ vất vả trong việc cam kết tình cảm lâu dài và có xu hướng
        tin rằng mọi khởi đầu mới là giải pháp đối với rắc rối của họ và rằng
        kết hôn lại có thể giải quyết vấn đề.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Trong Hôn Nhân - Quan Hệ:</b> thích xã giao, tha thứ,
        ghen tuông, tận tâm, suy nghĩ thấu đáo.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Canh toạ Hoả Khố với Thông Căn. Điều này biểu thị người thông minh, kiên
        trì và mạnh mẽ sẵn có tính cách vui vẻ, chung thuỷ và đứng đắn. Ngoài
        ra, họ có tài văn chương và diễn đạt ngôn từ giỏi.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Tuất có trực giác tốt và linh cảm phát triển đến mức họ dễ
        phát hiện cơ hội mới. Họ giỏi thu hút tiền bạc và sự trợ giúp từ người
        khác và thường có cơ hội để biến mọi thứ thành công. Họ tỉnh táo, đa tài
        và nhiệt tình cũng như có tài trong việc học hỏi kỹ năng thực hành và lý
        thuyết.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ có tính cách thu hút và mặc dù họ có thể tỏ ra hướng ngoại và tự tin
        thì điều này chỉ che đậy sự bất an của họ. Mặc dù họ độc lập và mong chờ
        sự tự do và mạo hiểm, họ chỉ có sự can đảm theo đuổi nếu như có nền tảng
        an toàn. Họ có thể có tâm trạng trồi sụt và cần nỗ lực giữ suy nghĩ tích
        cực và tập trung. Mặc dù họ rất quan tâm người khác, họ cũng cần tránh
        tỏ ra quá hống hách với người mình thương yêu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tốt nếu như trụ ngày này gặp Dương Nhẫn trong lá số. Điều này sẽ tốt vì
        gợi ý rằng Canh Tuất sẽ có cuộc đời phiêu lưu và may mắn. Tuy nhiên, nếu
        gặp nhiều Thân Tý Thìn, Nhâm và Quý thì sẽ ít may mắn hơn và có thể có
        nghĩa là những người này sẽ lười nhác thiếu động lực và kỷ luật để thành
        công. Bất lợi nếu trụ ngày này Xung với Thìn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Tuất sẽ giàu có và đáng kính nếu có hiện diện Quan và Tài
        trong Địa Chi. Sẽ có lợi nếu gặp thêm Kim, Hoả và Thổ trong lá số vì
        biểu thị thành công với sự giúp đỡ của nhiều bạn bè. Sẽ bất lợi nếu trụ
        này gặp hành Thuỷ vì biểu thị mặc dù người này thông thái, họ có thể
        nham hiểm và không thành thật.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Tuất sinh vào mùa Xuân và Hạ có cơ hội tốt để thành công mà
        không cần dựa vào sự giúp đỡ của nhiều người khác. Người sinh vào mùa
        Thu và Đông có nhiều thất bại trước khi thành công.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh vào ban ngày sẽ thiếu quyết tâm. Sinh vào ban đêm sẽ có được
        sự kính trọng. Nếu sinh vào tháng Thìn thì sẽ trải qua nhiều lúc có tiền
        lúc mất tiền liên tục trong đời. Canh ở đây toạ Tuất mang theo năng
        lượng chính của Mậu Thổ Thiên Ấn và 2 phụ khí Tân Kim Kiếp Tài và Đinh
        Hoả Chính Quan. Họ có bản tính lãnh đạo tự nhiên do Kiếp Tài ủng hộ sự
        cạnh tranh và năng lượng này bị kiểm soát chặt chẽ bởi Chính Quan vốn
        giới hạn họ trước những tính chất huỷ diệt. Họ có thể học hỏi nhanh
        chóng do Thiên Tài và có thể thấy được mô hình và xu hướng khiến họ yếu
        đuối trong mọi tình huống vì họ có thể phản ứng nhanh chóng hơn những
        người khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mối quan hệ tình cảm tốt cho nữ giới do sự xuất hiện của Chính Quan vốn
        đại diện cho người chồng. Đối với nam Canh Tuất thì Kiếp Tài không ủng
        hộ vấn đề tình cảm vì họ sẽ có xu hướng kén chọn và thường muốn nâng cấp
        thường xuyên. Họ đòi hỏi sự can đảm và không thuộc dạng người có thể cảm
        thấy vừa đủ thoả mãn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tuất hấp dẫn Mão đem đến một hành duy nhất là Ất Mộc Chính Tài, vốn được
        xem là sao chỉ người vợ đối với lá số nam giới. Tài Tinh thường chỉ
        người làm việc siêng năng và sẵn sàng nỗ lực xây dựng ước mơ. Đối với nữ
        giới, họ thà tận tâm với công việc nhà hơn là khái cạnh tài chính. Tiết
        kiệm là một phẩm chất dễ thấy, thật sự họ quá bận rộn trong việc chiến
        đấu đến nỗi quên mất cách xài tiền của chính mình.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tuất xung với Thìn mang theo năng lượng của Mậu Thổ Thiên Ấn, Ất Mộc
        Chính Tài và Quý Thuỷ Thương Quan. Đây là điều tốt cho nữ giới vì Thương
        Quan bị kiểm soát sẽ tốt cho sự ổn định tình cảm. Sự xuất hiện của
        Thương Quan sẽ khắc chế Chính Quan vốn học hỏi từ Kiếp Tài. Đối với nam
        giới thì chúng ta lại thấy Chính Tài bị xua đuổi biểu thị gia đình
        thường xếp thứ hai trong đời, họ có rất ít sự lựa chọn ở đây.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Canh Tuất là trụ yêu thích thách thức và có thể liên tục đưa bản thân
        mình vào rắc rối. Họ rất ít quan tâm đến sự an nguy bản thân và chấp
        nhận thách thức để cải thiện bản thân. Phụ nữ Canh Tuất sẽ tốt hơn trong
        tình cảm vì can đảm nhưng đàn ông sẽ gặp một chút rắc rối nếu họ không
        biết giữ danh dự. Những người xung quanh Canh Kim cần phải cẩn trọng, vì
        Canh không thể tự huỷ diệt nhưng nó có thể huỷ diệt các hành khác. Đừng
        ủng hộ họ khi họ điên cuồng, bạn có thể không sống sót được.
      </p>
    </div>
  );
};

export const CanhThan = () => {
  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Khái Quát:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người sinh ngày Canh Thân: thông minh, giỏi toán, các con số, thích nói
        về bản thân, tập trung sự nghiệp, thiếu hài hước, thường tỏ ra quá
        nghiêm túc, thích những gì mới mẻ và bỏ những gì cũ kỹ. Cẩn trọng tránh
        những tai hoạ kỳ lạ. Dễ gây bất lợi cho vợ/chồng. Cuộc hôn nhân đầu
        không kéo dài.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Hình Tượng:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Đây là cũng giống như thả một vật kim loại từ trên cao, theo gia tốc và
        lực hấp dẫn thì nó tạo ra sức ảnh hưởng như một quả bom. Điều này biểu
        thị những người Canh Thân nếu làm những gì họ muốn làm trong một khoảng
        thời gian thì không có gì cản nổi. Người Canh Thân là dạng người mà bạn
        chỉ cần một chút kích thích dẫn hướng là họ có thể lao như tên vút đến
        mục tiêu, cứ để cho lực hấp dẫn lo phần còn lại.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Tính Cách:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Canh Kim được miêu tả như thanh gươm. Họ nhanh chóng và quyết đoán và do
        đó họ ra sức bảo vệ công lý. Khi một thanh kiếm rơi xuống, thì khống có
        cách gì cản lại vì sẽ sẽ có người thiệt mạng. Nếu họ hiểu sai thì sẽ
        phải hối hận cả đời. Điều này giúp họ biến thành người giỏi về chi tiết
        và có khả năng tiến bộ cũng như ra quyết định đúng. Họ miễn nhiễm với áp
        lực và có thể chịu đựng mọi vất vả trong cuộc sống.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Thân sẽ có nhiều chướng ngại để vượt qua trong con đường
        thành công. Chính những khó khăn đó sẽ hình thành nên tính cách của họ
        và giúp họ đạt được sự cân bằng và ổn định về mặt tình cảm.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Thân là con người của những thái cực, nội tâm mâu thuẫn bởi
        sự mong muốn ổn định và mong muốn tiến bộ. Họ rất nhạy cảm và có thể
        thích bay bổng tưởng tượng nhưng cũng cần phải thực tế và nắm rõ định
        hướng để có thể tạo nền tảng vững chắc và thực tế.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ đa tài và có hứng thú học tập giúp họ có thể phát triển quan điểm
        riêng. Điều này giúp họ có quan điểm rộng mở và cảm hứng sáng tạo và thể
        hiện bản thân.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ giỏi nhất là độ bền bỉ về tinh thần cùng với cách thức ra quyết định
        một cách khoa học và lý trí. Khi họ có thể rèn luyện bản thân để nâng
        cao trực giác thì họ là người tự tin và quyết đoán. Nếu họ không tin vào
        trực gíac của mình thì họ sẽ dễ trải qua các thời kỳ tiêu cưc và tự nghi
        ngờ dẫn đến việc theo đuổi triết lý hoặc huyền học. Trong thâm tâm, họ
        là người cầu toàn và cần chú ý tránh căng thẳng hoặc lo lắng, cảm giác
        trách nhiệm khiến cho họ dễ tập trung, ngay cả khi họ có cảm giác mệt
        mỏi hay buồn chán.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Mặc dù họ thích nắm vị trí lãnh đạo, người Canh Thân thân thiện, duyên
        dáng và thực tế, điều này cũng khiến cho họ trở thành người thích làm
        việc tập thể. Mặc dù họ khá thẳng thắn đến mức thô lỗ, họ có kỹ năng đàm
        phán bẩm sinh và thấu hiểu con người đến từ sự quan sát hành vi. Họ
        thích tạo niềm vui và khá nhanh nhạy cùng khiếu hài hước.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ yêu thích cái đẹp và nghệ thuật, cũng như khao khát lối sống xa hoa.
        Họ thường thích những gì tinh tế và có thể hoang phí. Ngoài ra, họ có xu
        hướng ưu tiên cho người khác cũng như tỏ ra rộng lượng cả về mặt cá nhân
        lẫn việc đóng góp từ thiện. Vì lẽ này, họ cần phải cẩn thận về tiền bạc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Có bản tính yêu thương con người sâu sắc, họ sẵn sàng đấu tranh cho lý
        tưởng chính nghĩa và bảo vệ quyền lợi của người khác. Thái độ đáng quý
        này cùng với tinh thần trách nhiệm đồng nghĩa với việc khi họ có sự ổn
        định về tri thức và cảm xúc, họ sẽ thường làm việc gấp đôi người khác.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b> Những Đặc Tính Của Người Canh Thân:</b> trực giác, lý tưởng hóa,
        trọng vật chất, quyết tâm, thiếu quyết đoán, ngẫu hứng, thông minh.
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>Sự Nghiệp:</h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Thân chú trọng đặc biệt đến sự nghiệp, họ có sự tự hào trong
        công việc và tiếp cận có phương pháp bất kể lượng thời gian cần phải đầu
        tư vào một dự án. Họ tham vọng và cầu toàn, điều này dẫn đến việc họ
        thích chỉ trích và thất vọng về người khác cũng như dễ nổi giận hay thậm
        chí nổi loạn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ hoàn toàn không gặp phản đối trong việc tự đề cao mình vì cá tính
        duyên dáng và năng động của bản thân. Họ có tầm nhìn độc đáo vào bản
        chất con người khiến cho họ có khả năng nói chuyện và đàm phán với nhiều
        loại người khác nhau. Họ sáng tạo và có lối sống độc đáo cũng như là
        những người suy nghĩ độc lập với góc nhìn không giống ai. Họ không gặp
        khó khăn trong việc phụ trách những dự án lớn. Người Canh Thân thường
        thể hiện năng lực tập trung và có tài giải quyết vấn đề nhưng họ gặp khó
        khăn trong việc cân bằng giữa công việc và gia đình.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Vì tất cả những điều này, họ là những người yêu tự do và không ngừng
        nghỉ. Họ có thể phải nỗ lực thể nghiệm nhiều hoạt động khác nhau trước
        khi tìm được điểm mình nên chú tâm. Họ cần môi trường liên tục thay đổi
        để có thể chống lại sự buồn chán và hoạt động ở công suất cao nhất. Vì
        lẽ này, họ cần tránh những nhiệm vụ lặp đi lặp lai hay tẻ nhạt.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Có nhiều con đường mở ra đối với người Canh Thân vốn dĩ năng động và đa
        tài. Sở hữu khả năng nhận thức cao về âm thanh và màu sắc cũng như phong
        cách, người Canh Thân phù hợp với sự nghiệp trong lĩnh vực nghệ thuật và
        thiết kế, âm nhạc, kiến trúc sư, làm phim hay nhiếp ảnh.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Ngoài ra, họ có bản tính nhân đạo cũng giúp họ thấu hiểu và yêu thương
        con người nên phù hợp với công việc tư vấn hay công tác xã hội. Khi kết
        hợp với khả năng thương lượng, có thể dẫn đến thành công trong công đoàn
        hay luật pháp hoặc công việc cho phép họ đi lại hay tham gia vào các tổ
        chức ngoại giao.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Họ giỏi về ngôn từ, điều này giúp họ làm việc trong lĩnh vực viết lách,
        giáo dục hay truyền thông. Họ yêu sáng tạo và phù hợp với ngành công
        nghệ hoặc kỹ sư. Họ cũng thích ganh đua do đó có thể trở thành vận động
        viên thể thao.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bất cứ con đường nào mà họ chọn đi theo thì người Canh Thân nên rèn
        luyện sự tập trung để có thể khai thác được tối đa kỹ năng của họ.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những Đặc Tính Trong Sự Nghiệp - Công Việc: làm việc có phương pháp,
        tham vọng, có trách nhiệm, suy nghĩ thấu đáo, dễ buồn chán, thích kiểm
        soát, hấp tấp.{" "}
      </p>

      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Tình Yêu - Hôn Nhân - Quan Hệ - Gia Đình:{" "}
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Thân rất lãng mạn và khả năng thể hiện tình cảm rất quan
        trọng đối với họ. Có thể là vì họ không biết cách thể hiện những cảm xúc
        bên trong một cách rõ ràng do đó họ cần học cách kết nối gọi tên cảm xúc
        để có thể tìm được hạnh phúc.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Thân hưởng lợi từ việc có được người bạn đời tri thức và cùng
        sở thích và yêu kiến thức. Người bạn đời phù hợp với người Canh Thân rất
        quan trọng và sẽ giúp họ có được sự ổn định. Họ sẵn lòng hi sinh vì tình
        yêu.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Là người bẩm sinh duyên dáng, người Canh Thân có bản tính thích chăm sóc
        và sự ấm áp cũng như khiếu hài hước giúp họ dễ kết bạn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Bạn bè của người Canh Thân, vốn không biết đến sự đấu tranh tình cảm bên
        trong họ, thường thán phục sức mạnh bên trong và sự đồng cảm của người
        Canh Thân và do đó thường tìm đến họ để xin lời khuyên và giúp đỡ. Người
        Canh Thân thường có khả năng gợi cảm hứng cho bạn bè trở nên tốt đẹp
        hơn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Tình bạn rất quan trọng đối với người Canh Thân vì họ thích những người
        có thể khơi gợi trí óc và có thể giúp đem lại cho họ sự an toàn và trải
        nghiệm họ khao khát. Họ cần phải tránh không được để cho lý tưởng về
        tình bạn cao cả khiến họ can thiệp vào đời sống người khác và lờ đi cuộc
        sống của mình cũng như lòng tốt của mình dễ bị lợi dụng.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Không ngạc nhiên khi bạn bước vào căn nhà của người Canh Thân và ngay
        lập tức cảm nhận sự ấm áp. Người Canh Thân là những bậc phụ huynh xuất
        sắc và bảo vệ các thành viên trong nhà mình. Họ cố gắng để xây dựng nền
        tảng vững chắc để thành viên trong nhà có thể học hỏi và phát triển.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        <b>Những Đặc Tính Trong Tình Yêu - Quan Hệ:</b> nhanh nhạy, thân thiện,
        lãng mạn, nhạy cảm, duyên dáng, từ bi, chung thuỷ.{" "}
      </p>
      <h4 style={{ textAlign: "left", paddingLeft: "1rem" }}>
        Phân Tích Kỹ Thuật:
      </h4>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Thân có thể hưởng sức khoẻ tốt suốt cả đời. Canh toạ trên Lộc
        và Thông Căn, và điều này biểu thị sức khoẻ tốt và ổn định. Người Canh
        Thân sinh vào tháng Sửu sẽ được hỗ trợ bởi Thiên Nguyệt Đức biểu thị họ
        sẽ ít bị các nguy cơ xấu về sức khoẻ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Thân tham vọng và siêng năng. Họ có thể giữ cân bằng giữa
        trực giác mạnh mẽ và cách sống thực dụng giúp họ có thể biến sự sáng tạo
        và tầm nhìn trở thành hiện thực. Mặc dù họ rất vui khi lắng nghe người
        khác, họ không chấp nhận sự can thiệp vào kế hoạch của mình và muốn có
        thể phát huy được cá tính thật sự.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Thân có nhu cầu trở nên hoàn hảo và điều này khiến họ chần
        chừ trong quyết định và tránh rủi ro. Họ có thể bỏ lỡ cơ hội khi họ xem
        xét các kết quả có thể xảy ra để đảm bảo chắc chắn nhiều lần rằng họ ra
        quyết định đúng. Họ sẽ ổn nếu cho phép trực giác và triết lý cá nhân dẫn
        đường trong việc ra quyết định nhanh hơn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Thân được biết đến là trụ cột vững chắc bởi bạn bè. Họ sẵn
        lòng và có thể giúp đỡ những người khác khi được nhờ vả nhưng sức mạnh
        của họ có thể khiến cho họ có vẻ kiểm soát khi muốn bảo vệ người họ quan
        tâm. Họ cũng tìm cách kiểm soát tất cả mọi thứ mà đôi khi cũng cần học
        cách bỏ đi. Ngoài ra, người Canh Tâhn có thể có xu hướng thích vật chất
        và họ nên học cách xếp mọi thứ theo thứ tự đúng đắn.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Thân có lợi nếu Tý và Thìn gặp Tân, Nhâm và Quý. Cách cục này
        sẽ gợi ý rằng người Canh Thân sẽ giàu có và có thể nắm những chức vụ
        quyền lực. Nếu người Canh Thân sinh vào giờ Bính, Đinh, Dần, Ngọ hay
        Tuất thì họ sẽ trở nên khá nổi tiếng mà không cần phải nỗ lực cố gắng.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Canh Thân cần có nhiều Hoả để có thể rèn và trở thành kim loại hữu dụng.
        Điều này biểu thị người này sẽ phải chịu nhiều vất vả trong đời, mặc dù
        con đường đời có thể dài và vất vả nhưng cuối cùng sẽ đạt thành công.
        Nếu không có sự ảnh hưởng tích cực của hành Hoả trong lá số Bát Tự, họ
        có thể sử dụng những thủ thuật phi đạo đức để đạt được điều mình muốn.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Trong lá số Bát Tự thì Tỷ Kiên toạ ở cung Phu Thê biểu thị không chỉ sự
        rộng lượng mà còn là khao khát thật sự để giúp đỡ về tài chính cho bạn
        bè và gia đình. Họ cần phải thận trọng không làm hao hụt hết nguồn lực
        của họ trong ngắn hạn vốn là thảm hoạ có khả năng xảy ra và sẽ tốt hơn
        nếu đầu tư thông minh để tối đa hoá nguồn vốn cho tương lai. Nếu Thìn
        hiện diện trong lá số Canh Thân thì biểu thị tài lộc thông qua bất động
        sản do đó người này có thể cân nhắc khi đầu tư. Khi Thìn và Thân hợp
        trong lá số biểu thị người Canh Thân, mặc dù khi mới bắt đầu có thể có
        tài chính đi lùi, nhưng về sau sẽ có nhiều cơ hội và khả năng tiến bộ.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Sự ám hợp giữa trụ ngày này và Mão trong lá số sẽ biểu thị người Canh
        Thân có tiềm năng tham gia vào những mối làm ăn bí mật. Được xem là có
        lợi nếu trụ này gặp nhiều Kim và Thuỷ trong đại vận. Tuy nhiên sẽ bất
        lợi nếu trụ ngày này gặp nhiều Mộc và Hoả trong đại vận vì biểu thị
        người này sẽ dễ có tâm tính thất thường.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Người Canh Thân giỏi trong giao tiếp nếu họ sinh vào mùa Xuân và Hè và
        nếu sinh vào mùa Thu hay Đông thì sẽ rất thành công. Nếu sinh vào ban
        ngày thì Canh Thân sẽ có ít nhất nửa đời thật sự phi thường. Nếu họ sinh
        vào ban đêm thì họ sẽ giàu có thông qua sự nỗ lực và chăm chỉ. Sẽ ít may
        mắn hơn nếu sinh vào tháng Mùi, họ có thể cảm thấy vận rủi đe doạ họ mọi
        lúc và họ dễ đối mặt nhiều thách thức khi theo đuổi thành công.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Canh toạ Thân có chính khí là Canh Kim Tỷ Kiên và 2 phụ khí là Nhâm Thuỷ
        Thực Thần và Mậu Thổ Thiên Ấn. Ở đây chúng ta có thể xem như họ là người
        dễ hoà nhập vào môi trường xung quanh, họ có xu hướng không muốn xáo
        trộn mọi thứ lên mà sẽ đứng vững chắc để bảo vệ cho tập thể của mình
        không bị đe doạ.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thiên Ấn thường cho phép cảm gíac sắc bén về môi trường xung quanh trong
        khi Thực Thần giúp cho họ sáng tạo trong khâu giải quyết vấn đề. Miễn là
        khi mọi người muốn, người Canh Tâhn sẽ tìm ra cách để giải quyết. Nếu
        khi có Thiên Ấn gặp Thực Thần thì được xem là Kiêu Thần Đoạt Thực biểu
        thị người này sẽ quái đản vì cả Thiên Ấn và Thực Thần đều thiếu khả
        năng/nhu cầu để giải thích cho những suy nghĩ của họ. Điều này khiến cho
        giải pháp của họ đưa ra rất đáng nghi ngờ.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Về mặt tình cảm, bất kỳ lá số này toạ trên Tỷ Kiên sẽ chú trọng đến tình
        bạn trên hết. Điều này làm cho họ khá sợ hãi khi bước vào chuyện yêu
        đương. Họ sẽ lao ra khi nào bạn bè cần giúp đỡ và người bạn đời sẽ cảm
        thấy bạn không đặc biệt hơn bao nhiêu so với bạn bè của người này. Đối
        với phụ nữ thì điều này gia tăng sự phức tạp vì Tỷ Kiên có xu hướng hợp
        với Chính Quan sao người chồng. Phụ nữ vốn thích nói chuyện nên ai thân
        thiện với họ đều được xem là bạn bè. Bạn bè của người nữ này có thể dễ
        dàng tấn công vào người chồng nếu họ phát hiện ra mối quan hệ bị rạn
        nứt.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thân hợp với Tỵ mang theo khí của Bính Hoả Thất Sát, Canh Kim Tỷ Kiên và
        Mậu Thổ Thiên Ấn. Đối với nữ giới thì Thất Sát là sao bạn trai do đó
        khiến cho họ dễ trở thành đối tượng theo đuổi. Họ cũng như ngựa hoang
        trong đó đàn ông thích được thuần hoá nhưng một khi thuần hoá thì họ vẫn
        cần sự tự do. Điều này đem lại cảm giác bât an trong tình cảm. Đối với
        nam giới thì hoàn toàn thiếu sao Tài nên biểu thị mãi mãi chỉ dừng lại ở
        mức tình bạn.
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Thân xung với Dần xua đuổi khí Gíap Mộc Thiên Tài, Bính Hoả Thất Sát và
        Mậu Thổ Thiên Ấn. Ở đây bạn thấy rằng cả sao tình cảm của nam và nữ đều
        bị kiểm soát. Rất khó để phá vỡ quan hệ tình cảm bạn bè. Cach đơn giản
        nhất để đạt thành công trong tình cảm là đảm bảo tất cả bạn bè của người
        này đều đã kết hôn và có trách nhiệm với gia đình riêng của họ.{" "}
      </p>
      <p style={{ textAlign: "left", padding: "0 1rem 0 1rem" }}>
        Những người Canh Thân rất trung thành với lý tưởng xã hội. Họ thường tìm
        cách để xây dựn sự nương tựa lẫn nhau giữa đồng nghiệp mà theo họ là
        động lực cần thiết. Tuy nhiên đây cũng là sự huỷ diệt. Cảm nhận về sự
        công bằng của họ xuất phát từ lòng trung thành và thường không có liên
        quan gì đến đúng hay sai. Muốn thay đổi họ là cả một quá trình dài, và
        bạn không thể bẻ cong một thanh gươm ngay lập tức.{" "}
      </p>
    </div>
  );
};
