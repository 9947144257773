import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Collapse,
  CardBody,
  Card,
} from "reactstrap"; import * as XLSX from "xlsx";
import { IoArrowDownCircleOutline } from "react-icons/io5";

// import Modal from "../components/Modal";
import { hoaGiap } from "../components/convertHoaGiap";
import {GiapTy} from '../components/60hoagiap/Giap'
import logo from "../assets/logo.png";
import "../scss/thansat.scss";


function PageHoaGiap() {

  const [show, setShow] = useState(true);
  const [open, setOpen] = useState(false);
  const [alerError, setAlerError] = useState(false);

  const [title, setTitle] = useState('Ngày Sinh Giáp Tý');
  const [text, setText] = useState(GiapTy);
  const [isOpen, setIsOpen] = useState(true);
  const [isOpenAt, setIsOpenAt] = useState(false);
  const [isOpenBinh, setIsOpenBinh] = useState(false);
  const [isOpenDinh, setIsOpenDinh] = useState(false);
  const [isOpenMau, setIsOpenMau] = useState(false);
  const [isOpenKy, setIsOpenKy] = useState(false);
  const [isOpenCanh, setIsOpenCanh] = useState(false);
  const [isOpenTan, setIsOpenTan] = useState(false);
  const [isOpenNham, setIsOpenNham] = useState(false);
  const [isOpenQuy, setIsOpenQuy] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  const toggleAt = () => setIsOpenAt(!isOpenAt);
  const toggleBinh = () => setIsOpenBinh(!isOpenBinh);
  const toggleDinh = () => setIsOpenDinh(!isOpenDinh);
  const toggleMau = () => setIsOpenMau(!isOpenMau);
  const toggleKy = () => setIsOpenKy(!isOpenKy);
  const toggleCanh = () => setIsOpenCanh(!isOpenCanh);
  const toggleTan = () => setIsOpenTan(!isOpenTan);
  const toggleNham = () => setIsOpenNham(!isOpenNham);
  const toggleQuy = () => setIsOpenQuy(!isOpenQuy);


  const handleClick = (e) => {
    let content = hoaGiap(e.currentTarget.textContent);
    console.log(e.currentTarget.textContent)
    setText(content);
    setTitle(`Ngày Sinh ${e.currentTarget.textContent}`);
  };

  const setAlerErrors = () => {
    setAlerError(true);
  };

  useEffect(() => {

  }, []);

  return (
    <div style={{ width: '100%', margin: "0 auto" }}>
      {show && JSON.parse(localStorage.getItem("profile"))?.isActive ? (
        <>
          <div className="than_sat_title" style={{ marginTop: "2rem" }}>

            <h3>LUẬN 60 HOA GIÁP NHẬT TRỤ</h3>
          </div>
          <div style={{ width: '1440px', margin: "0 auto", display: "flex", minHeight: '512px' }}>

            <Card style={{ width: '15%' }}>
              <CardBody>
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <span className="chiday" onClick={toggle} >Ngày Sinh Giáp</span>
                <IoArrowDownCircleOutline size={'20px'}  style={{cursor: 'pointer'}} onClick={toggle}/>
                
              </div>
              <div>
              <Collapse isOpen={isOpen}>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Giáp Tý</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Giáp Tuất</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Giáp Thân</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Giáp Ngọ</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Giáp Thìn</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Giáp Dần</p>
                </Collapse>
              </div>

              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <span className="chiday" onClick={toggleAt} >Ngày Sinh Ất</span>
                <IoArrowDownCircleOutline size={'20px'}  style={{cursor: 'pointer'}} onClick={toggleAt}/>
                
              </div>
              <div>
              <Collapse isOpen={isOpenAt}>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Ất Sửu</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Ất Hợi</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Ất Dậu</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Ất Mùi</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Ất Tỵ</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Ất Mão</p>
              
                </Collapse>
              </div>
              
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <span className="chiday" onClick={toggleBinh}>Ngày Sinh Bính</span>
                <IoArrowDownCircleOutline size={'20px'}  style={{cursor: 'pointer'}} onClick={toggleBinh}/>
                
              </div>
              <div>
              <Collapse isOpen={isOpenBinh}>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Bính Dần</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Bính Tý</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Bính Tuất</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Bính Thân</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Bính Ngọ</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Bính Thìn</p>
              
                </Collapse>
              </div>


              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <span className="chiday"onClick={toggleDinh} >Ngày Sinh Đinh</span>
                <IoArrowDownCircleOutline size={'20px'}  style={{cursor: 'pointer'}} onClick={toggleDinh}/>
                
              </div>
              <div>
              <Collapse isOpen={isOpenDinh}>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Đinh Mão</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Đinh Sửu</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Đinh Hợi</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Đinh Dậu</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Đinh Mùi</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Đinh Tỵ</p>
              
                </Collapse>
              </div>
              
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <span className="chiday"onClick={toggleMau} >Ngày Sinh Mậu</span>
                <IoArrowDownCircleOutline size={'20px'}  style={{cursor: 'pointer'}} onClick={toggleMau}/>
                
              </div>
              <div>
              <Collapse isOpen={isOpenMau}>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Mậu Thìn</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Mậu Dần</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Mậu Tý</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Mậu Tuất</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Mậu Thân</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Mậu Ngọ</p>

              
                </Collapse>
              </div>

              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <span className="chiday"  onClick={toggleKy}>Ngày Sinh Kỷ</span>
                <IoArrowDownCircleOutline size={'20px'}  style={{cursor: 'pointer'}} onClick={toggleKy}/>
                
              </div>
              <div>
              <Collapse isOpen={isOpenKy}>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Kỷ Tỵ</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Kỷ Mão</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Kỷ Sửu</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Kỷ Hợi</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Kỷ Dậu</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Kỷ Mùi</p>


              
                </Collapse>
              </div>

              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <span className="chiday"  onClick={toggleCanh}>Ngày Sinh Canh</span>
                <IoArrowDownCircleOutline size={'20px'}  style={{cursor: 'pointer'}} onClick={toggleCanh}/>
                
              </div>
              <div>
              <Collapse isOpen={isOpenCanh}>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Canh Ngọ</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Canh Thìn</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Canh Dần</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Canh Tý</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Canh Tuất</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Canh Thân</p>

              
                </Collapse>
              </div>
              
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <span className="chiday" onClick={toggleTan} >Ngày Sinh Tân</span>
                <IoArrowDownCircleOutline size={'20px'}  style={{cursor: 'pointer'}} onClick={toggleTan}/>
                
              </div>
              <div>
              <Collapse isOpen={isOpenTan}>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Tân Mùi</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Tân Tỵ</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Tân Mão</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Tân Sửu</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Tân Hợi</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Tân Dậu</p>

              
                </Collapse>
              </div>
              
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <span className="chiday"onClick={toggleNham} >Ngày Sinh Nhâm</span>
                <IoArrowDownCircleOutline size={'20px'}  style={{cursor: 'pointer'}} onClick={toggleNham}/>
                
              </div>
              <div>
              <Collapse isOpen={isOpenNham}>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Nhâm Thân</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Nhâm Ngọ</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Nhâm Thìn</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Nhâm Dần</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Nhâm Tý</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Nhâm Tuất</p>

              
                </Collapse>
              </div>

              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <span className="chiday" onClick={toggleQuy}>Ngày Sinh Quý</span>
                <IoArrowDownCircleOutline size={'20px'}  style={{cursor: 'pointer'}} onClick={toggleQuy}/>
                
              </div>
              <div>
              <Collapse isOpen={isOpenQuy}>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Quý Dậu</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Quý Mùi</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Quý Tỵ</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Quý Mão</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Quý Sửu</p>
              <p style={{fontWeight: '600', cursor:'pointer'}} onClick={handleClick}>Quý Hợi</p>

              
                </Collapse>
              </div>
              
               
            
               
             
              

              </CardBody>
            </Card>
            <div style={{ width: '90%', position: 'relative' }}>
              <div className={title ? "watermark-hoagiap-page t-16" : "watermark-hoagiap-page"} >
                <img src={logo} />
              </div>

              <h3 style={{ textTransform: 'uppercase', textAlign: 'center', marginTop: '2rem' }}>{title}</h3>
              <span style={{ overflowY: 'scroll', maxHeight: 600, display: 'block' }} onCopy={e => e.preventDefault()} onCut={e => e.preventDefault()} onPaste={e => e.preventDefault()} >{text}</span>
            </div>
          </div>
        </>
      ) : (
        <div
          className="hide-to-print"
          style={{
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold",
            minHeight: "500px",
            paddingTop: "125px",
          }}
        ></div>
      )}
      <Modal close={(value) => setOpen(value)} open={open} />
    </div>
  );
}

export default PageHoaGiap;
