export const BoSungHanhMoc = () => {
  return (
    <div
      style={{
        textAlign: "left",
        position: "relative",
        zIndex: "999",
        marginLeft: "1rem",
      }}
    >
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
            fontWeight: "bold",
          }}
        >
          Bổ sung Hành Mộc
        </span>
      </p>
      <p
        className="p1"
        style={{
          margin: "0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          <strong
            id="docs-internal-guid-cdb09436-7fff-2c58-83ef-1066eb1bbbb9"
            style={{ fontWeight: "normal" }}
          >
            &nbsp;
          </strong>
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Thiên:&nbsp;&nbsp;
        </span>
      </p>
      <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Chọn đại vận hoặc năm Dần, Mão hoặc Giáp, Ất để lên kế hoạch dài
              lâu 5 - 10 năm.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Trong một năm, chọn tháng mùa hè: Dần, Mão để lên khởi sự những kế
              hoạch đã tính toán từ trước nhằm đạt thành công.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Chọn những ngày Giáp Dần, Ất Mão để khởi sự những công việc quan
              trọng.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Luôn lên kế hoạch cho những cuộc hẹn, gặp gỡ vào buổi sáng giờ
              Dần, Mão, Thìn tức 5h - 9h.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Buổi sáng nên thức dậy vào từ 3h - 7h sáng để tập thể dục, thiền
              định hoặc đọc sách vì đây là giờ Trường Sinh của Hoả Khí.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Chọn địa điểm gặp gỡ, cuộc hẹn ở các quán, nhà hàng ở góc Đông,
              Đông Nam tính từ nhà mình. Góc Đông và Đông Nam thuôc về hành Mộc.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Chọn số sim điện thoại, số nhà, số xe, hoặc căn cước, tài khoản
              ngân hàng có nhiều số 3, 8 thuộc hành Mộc.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu mua hoặc thuê nhà chung cư, cao ốc văn phòng ưu tiên chọn
              phòng hoặc tầng có số 3, 8, 13, 18, 23, 28.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Chọn mùi hương nước hoa tự nhiên đê lôi cuốn đối tác.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nên giữ cặp mắt luôn sáng rõ bằng cách ngủ đủ giấc, xoay kem chống
              thâm vùng mắt.&nbsp;
            </span>
          </p>
        </li>
      </ol>
      <p
        className="p1"
        style={{
          margin: "0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          <strong style={{ fontWeight: "normal" }}>&nbsp;</strong>
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Địa:&nbsp;
        </span>
      </p>
      <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Chọn nhà tại nơi nào càng có nhiều rừng cây, cây xanh càng tốt ví
              dụ như Eco Park Văn Lâm.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nên mua nhà tại các toà nhà có hình dáng hành Mộc.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Xung quanh nhà nên trồng nhiều cây xanh, trong phòng làm việc hay
              phòng ngủ nên đặt chậu cây xanh, tốt nhất tại cung Đông hoặc Đông
              Nam hoặc tại sao Tứ Lục.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu trồng trúc, vạn tuế, thiết mộc lan, các loại cây to lớn hoặc
              thân cứng là rất tốt.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Cũng có thể trồng Linh Chi hoặc nấm tại nhà cũng sẽ rất tốt cho bổ
              sung Mộc.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Phòng ngủ hoặc phòng khách có thể trang trí tone màu xanh
              lá.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Drap giường, chăn, gối, nệm nên màu xanh lá cây.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nên bố trí chậu cây để thanh lọc không khí trong nhà tắm, vệ
              sinh.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu để bếp quá gần bồn rửa thì nên đặt 1 tấm kính dán màu xanh lá
              hoặc chậu cây xanh ở giữa để Thuỷ sinh Mộc và Mộc sinh Hoả, tránh
              Thuỷ Hoả tương xung.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nên lắp đặt máy lọc không khí và chậu cây trong phòng để làm sạch
              không khí.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Đứng trong nhà mở cửa sổ hay cửa chính có thể nhìn thấy rừng núi,
              cây xanh sẽ tốt. Nếu như núi đồi có nhiều cây cối tốt tươi là tốt,
              nếu núi trọc, cây cói héo úa là không tốt.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Tại vi trí sao Tứ Lục lưu niên đặt 1 chậu trúc quan âm gồm 1 khóm
              hoặc 4 cành trúc. Mỗi ngày nên ngồi đọc sách, nghiên cứu tại đây
              sẽ gặp nhiều may mắn.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Giường tủ, bàn ghế, tay vịn cầu thang nên làm bằng gỗ, tránh tối
              đa kim loại. Giường ngủ bằng gỗ có thể chọn màu sơn là xanh lá sẽ
              tốt hơn màu trắng Kim hay màu gỗ cafe (thuộc Thổ)
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Trong nhà có thể trang trí bằng 1 hình nhân sâm, 1 nhánh linh chi
              hoặc đặt 1 cây mía cũng rất tốt.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Trong nhà nên có 1 phòng thư viện, gồm nhiều kệ sách càng
              tốt.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Trong nhà nên dùng cửa sổ có rèm vải, cửa sổ nhìn về hướng Đông,
              Đông Nam hay có màu gỗ.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Có thể đặt 1 trái bầu hồ lô khô trong phòng làm việc giúp tăng
              cường may mắn.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Khi thuê nhà hoặc xây nhà không nên để khuyết cung Đông đối với
              nam giới, khuyết cung Đông Nam đối với nữ giới.&nbsp;
            </span>
          </p>
        </li>
      </ol>
      <p
        className="p1"
        style={{
          margin: "0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          <strong style={{ fontWeight: "normal" }}>
            <br />
            <br />
          </strong>
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Nhân:&nbsp;
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Trang Phục / Ngoại Hình:&nbsp;
        </span>
      </p>
      <p
        className="p1"
        style={{
          margin: "0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          <strong style={{ fontWeight: "normal" }}>&nbsp;</strong>
        </span>
      </p>
      <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nên tránh sử dụng nhiều đồng hồ hoặc đặt bộ sưu tập đồng hồ tại
              cung Đông, Đông Nam.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Đeo các vòng tay bằng gỗ như trầm hương, đàn hương sẽ rất tốt. Đá
              caceildone, thạch anh, cũng tốt. Nếu dây đeo màu xanh lá cây càng
              tốt.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Trong cơ thể con người thì các bộ phận cơ thể sau đại diện cho
              hành Mộc: chân, tay, gan mật, lông tóc.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Muốn được may mắn thì người cần Mộc có thể để tóc dài, móng tay
              dài (kể cả với nam giới cũng không nên cắt móng tay quá
              ngắn).&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Người cần Mộc không nên wax, làm sạch hết lông, nếu lông chân tay
              dài rậm càng tốt, lông nách cũng không nên nhổ.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu là nam giới thì có để râu dài cũng tốt.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Chọn điện thoại có vỏ hoặc ốp lưng màu xanh lá cây.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nhuộm tóc xanh lá. Nhất là phần đầu có huyệt Bách Hội giúp hấp thu
              Hoả Khí.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu ngại nhuộm tóc đỏ có thể dùng kẹp tóc, băng đô buộc tóc màu
              xanh lá để gia tăng may mắn. Lưu ý tránh đội nón màu xanh lá cây
              nếu ở Trung Quốc vì mang ý nghĩa “bị cắm sừng”.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Đeo các vòng tay bằng ngọc, cẩm thạch màu xanh lá rất tốt.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Có thể đeo vòng tay gỗ hoá thạch.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Gọng mắt kính màu xanh lá hoặc thi thoảng có thể đeo kính áp tròng
              màu xanh lá cây.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Chọn ví bóp màu xanh lá hoặc cho 1 mảnh nhỏ vỏ trầm hương, gỗ sưa
              hoặc lá bồ đề để bảo vệ bản thân trước âm khí.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Có thể đeo dây chuyền mặt tượng Phật bằng gỗ.&nbsp;&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu tóc hay móng tay rụng, dễ gãy thì là dấu hiệu Mộc bị yếu, cần
              chú ý chăm sóc sức khoẻ.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu lông mày quá mảnh có thể kẻ cho đậm thêm.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nam giới cần Mộc nếu về già râu tóc dài là dấu hiệu trường thọ.
              Nếu như về già bị hói, rụng tóc là dấu hiệu xấu.&nbsp;
            </span>
          </p>
        </li>
      </ol>
      <p
        className="p1"
        style={{
          margin: "0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          <strong style={{ fontWeight: "normal" }}>&nbsp;</strong>
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Ẩm Thực&nbsp;
        </span>
      </p>
      <ol
        style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}
        start={12}
      >
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Người cần Mộc nên ăn thực vật thường xuyên, nếu ăn chay càng
              tốt.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Các loại thực vật liên quan nhiều đến Mộc là rau cải, nấm…Loại rau
              nào càng có nhiều màu xanh càng tốt, may mắn.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Dưa leo, dưa chuột mang hành Mộc mạnh.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Các loại bột ngũ cốc, gạo và thịt lợn cũng nhiều Mộc.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Uống thuốc Bắc cũng tốt vì thuốc Bắc thuộc hành Mộc.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Ngâm các bình rượu thuốc rễ cây rất tốt giúp gia tăng hành Mộc.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Các loại nấm mang tính Mộc rất mạnh mẽ, nhất là nấm linh chi.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Trà cũng là loại thức uống bổ sung Mộc rất tốt.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Dùng nhân sâm cũng rất tốt.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Các loại trái cây như mãng cầu na, cam sành, chanh, những loại có
              vỏ màu xanh, vị chua tốt cho người cần Mộc.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Ăn thịt Cua, nhất là các loại cua khổng lồ rất tốt cho người cần
              Mộc. Giáp Mộc đại diện cho mai, vỏ cua. Nếu ăn thì chọn loại cua
              mai mềm hoặc tìm cách nấu nhừ mai, vỏ cua để ăn luôn thì
              tốt.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Ăn thịt Vịt cũng giúp tăng cường ngũ hành Mộc, tuy nhiên thịt Vịt
              có tính hàn, nếu người sức khoẻ yếu cần cân nhắc.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Thịt thỏ ít cholesterol và cũng rất bổ dưỡng.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Rong biển, hạnh nhân cũng giúp tăng cường Mộc.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Thịt lợn nhiều Mộc, xương lợn cũng giúp bổ sung Mộc. Gan lợn, não
              lợn cũng giúp bổ sung Mộc. Tuy gan nhiều cholesterol nhưng đối với
              người cần Mộc thì không sao.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nước ép rau củ quả hoặc sinh tố giúp tăng cường Mộc.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Đu đủ cũng rất nhiều Mộc.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Có thể uống trà xanh vào buổi trưa hoặc chiều sẽ giúp bổ sung Mộc
              tốt.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Ăn đậu phụ, đậu hũ non cũng rất nhiều Mộc.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Đậu xanh là có Mộc khí cao nhât trong các loại đậu, lại có tác
              dụng tiêu độc, thanh lọc cơ thể. Nếu ăn chè đậu xanh rong biển,
              hạt sen thì càng tốt hơn.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Ngô bắp, chuối tiêu cũng có nhiều Mộc.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Củ sen thuộc Mộc, giup thanh tịnh.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Khoai lang hoặc khoai tây cũng thuộc Mộc.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Dầu gan cá cũng rất tốt. Vì gan thuộc Mộc, dầu gan cá đại diện cho
              Thuỷ. Mệnh cần Mộc thì khi sinh vào mùa hạ nên uống dầu gan cá để
              bổi bổ cơ thể.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Hạt dưa, các loại đậu cũng thuộc Mộc. Những loại cây có nhiều
              Maggie rất tốt bổ sung Mộc tăng cường nhân duyên.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Không nên ăn nhiều thịt gà nhất là da gà vì quá nhiều Kim sẽ khắc
              Mộc.&nbsp;
            </span>
          </p>
        </li>
      </ol>
      <p
        className="p1"
        style={{
          margin: "0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          <strong style={{ fontWeight: "normal" }}>
            <br />
            <br />
          </strong>
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Động Vật / Vật Phẩm:&nbsp;
        </span>
      </p>
      <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nuôi thỏ hoặc mèo là bổ sung hành Mộc tốt. Mèo là Tiểu Hổ Dần và
              vì Dần Mão đều thuộc Mộc nên giúp hoá sát và khai vận.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nuôi vịt hoặc cua cũng giúp bổ sung hành Mộc tốt.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Đặt hình Hổ trong nhà cũng rất tốt.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Phòng trẻ con cần hành Mộc có thể đặt thú nhồi bông mèo Kitty hoặc
              Doreamon cho bé.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Có thể đặt cua gỗ, tranh hình bầy thiên nga, vịt trời ở đầu giường
              tăng cường may mắn.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Đặt con mèo chiêu tài cũng tốt cho người khuyết Mộc.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nuôi chim cũng tốt.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu có điều kiện nên thường xuyên đến sở thú, thảo cầm viên nơi có
              các vườn chim, bướm, nhiều các loài cây hoa cũng rất tốt.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Trồng hoa lan hoặc nhà có vườn nên trồng nhiều rau xanh rất
              tốt.&nbsp;
            </span>
          </p>
        </li>
      </ol>
      <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Trong nhà treo tranh ảnh tre trúc tốt - đại diện cho người quân
              tử.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Phòng trẻ con cần hành Mộc có thể treo tranh hình Hello
              Kitty.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Không nên treo tranh hình Gà hoặc nuôi gà trong nhà vì Gà thuộc
              Kim không tốt.&nbsp;
            </span>
          </p>
        </li>
      </ol>
      <p
        className="p1"
        style={{
          margin: "0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          <strong style={{ fontWeight: "normal" }}>&nbsp;</strong>
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Thói Quen Tốt:&nbsp;
        </span>
      </p>
      <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Chơi các nhạc cụ như thổi tiêu, thổi sáo, gõ mỏ, đàn guitar hay
              thường xuyên nghe nhạc hoà tấu bằng các nhạc cụ này cũng là cách
              bổ sung hành Mộc.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Đi du lịch về phía Đông hoặc Đông Nam. Nếu ở TPHCM có thể du lịch
              tại Vũng Tàu, Long Hải, Cần Giờ, Mũi Né. Nếu ở Hà Nội có thể đi về
              Quảng Ninh, Hải Phòng, Thái Bình, Nam Định.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Đi du lịch nước ngoài: đến Úc, Mỹ.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu thích các môn như thêu thùa, khâu vá, may mặc cũng rất tốt.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Thường xuyên đọc sách rất tốt.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu thỉnh thoảng cảm thấy buồn chán có thể đi shopping, mua sắm
              nhất là quần áo hoặc mua sách báo cũng rất tốt.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Đặt mua hoặc giao báo dài hạn hoặc thường xuyên đọc báo giấy tốt
              cho người cần Mộc.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nên hạn chế thường xuyên đi mua hoặc đặt đồ bằng Kim Loại trong
              nhà quá nhiều.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Sáng sớm lúc 5h ra công viên gần nhà, nhất là những nơi nhiều cây
              cối để vận động hít thở là cách bổ sung hành Mộc tốt nhất.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Tập các môn như Yoga, gym cũng giúp tăng cường hành Mộc.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu cảm thấy mỏi mệt, có thể đi massage để tăng cường bổ sung
              Mộc.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Thỉnh thoảng nên trực tiếp đi đến chợ mua ít trái cây, hoa quả
              mang về xem như nhận được Mộc, tốt cho mệnh. Chợ mang hành Thuỷ
              Mộc sẽ tốt hơn siêu thị thuộc Kim.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Có thể thỉnh thoảng mua hoa về shop, chợ hoa mang về nhà.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Trẻ em cần Mộc muốn học hành tốt có thể ăn nhiều rong biển, hạnh
              nhân, gan lợn, óc lợn, thịt cua, uống nước ép nho.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Trên bàn học đặt 1 chậu cây trúc quan âm gồm 4 cây. Dưới chân trải
              thảm màu xanh lá cây giúp tăng cường may mắn trong học hành.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Thời gian học tập tốt nhất là 6h sáng học một mạch đến 9h.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu đi thi nên mặc áo màu xanh, trong túi bỏ 1 chiếc lá nhỏ (lá
              cây trắc bách diệp) màu xanh lá, tay đeo vòng tay màu xanh lá,
              mang vớ tất màu xanh lá. Lúc đi thi nên để móng tay, móng chân,
              râu tóc dài một chút không sao.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Thỉnh thoảng đi chăm sóc tóc, dưỡng tóc hay móng sẽ tốt. Nên hấp
              thu nhiều vitamin A, B, C để có móng tay khoẻ đẹp. Vì tóc và móng
              biểu thị cho hành Mộc.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nên gõ mõ tụng kinh vào buổi sáng sẽ tốt vì mõ gỗ thuộc Mộc.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Không nên hút thuốc hay uống rượu nhiều. Thuốc lá làm từ Mộc, đem
              Mộc đi đốt thì Mộc khí trong cơ thể bị mất đi, do đó nên bỏ thuốc.
              Không bỏ được thì nên hút thuốc lá điện tử hoặc hút bằng tẩu gỗ sẽ
              tốt.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Việc thường xuyên đi chùa, lễ lạt hoặc đốt hương cũng không tốt vì
              những nơi này Mộc khí bị đốt cháy do đó nên chọn loại hương khi
              cháy giữ tàng hoặc giữ chân hương, hoặc chọn loại hương điện.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Có thể theo nghề điêu khắc gỗ, mua bán đồ gỗ trang trí nội thất
              gỗ. Hoặc theo nghề giáo viên, thường xuyên cầm 1 cây thước gỗ sẽ
              có lợi.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Người già nên thường xuyên mang theo gậy gỗ có lợi.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nguòi cần Mộc khi mắc bệnh nên cân nhắc thêm các phương pháp chữa
              bệnh bằng Đông Y vì Tây Y thuộc Hoả Kim, Đông Y thuộc Mộc. Trong
              Đông Y thì châm cứu thuộc Kim, mát xa thuộc Mộc, xông hơi thuộc
              Hoả. Nên chọn massage trị liệu.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Khi tắm có thể dùng muối cỏ chanh (bao gồm thảo dược, muối tự
              nhiên, khoáng chất). Khi tắm bỏ vào bồn tắm sẽ tạo thành nước màu
              xanh để bổ sung Mộc tính khi ngâm bồn.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Không nên đem theo các loại kéo, bấm bằng kim loại quá nhiều bên
              người sẽ không tốt.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Ăn kẹo nhân sâm hoặc uống trà nhân sâm sẽ rất tốt.
            </span>
          </p>
        </li>
      </ol>
      <p
        className="p1"
        style={{
          margin: "0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          color: "#00000a",
        }}
      >
        <span
          style={{
            fontFamily: "arial, helvetica, sans-serif",
            fontSize: "14pt",
          }}
        >
          <strong style={{ fontWeight: "normal" }}>&nbsp;</strong>
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontSize: "14pt",
            fontFamily: "arial, helvetica, sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Tìm Quý Nhân:
        </span>
      </p>
      <p
        className="p1"
        style={{
          margin: "0px",
          textAlign: "center",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontStretch: "normal",
          fontSize: "18px",
          lineHeight: "normal",
          color: "#00000a",
        }}
      >
        &nbsp;
      </p>
      <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Tìm đến quý nhân nhiều Mộc: người sinh tháng Dần Mão, trong lá số
              có nhiều Hợi Mão Mùi và Dần Mão Thìn, ngày sinh Giáp Ất.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Tìm đến bạn bè có cặp mắt to tròn, khuôn mặt có hành Mộc (trán
              rộng, cằm hẹp).
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Kết giao với bạn bè, khách hàng, đối tác ở phía Đông như: Bà Rịa
              Vũng Tàu, Philippines, Mỹ.
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Theo dõi thần tượng những nghệ sĩ, doanh nhân có lá số nhiều Mộc
              như: Thư Kỳ, Thành Long.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Có thể mở trang trại, cửa hàng bán hoa, bán rau, những mô hình
              khởi nghiệp sinh thái xanh.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Bồ Tát phía Đông là Phật Dược Sư sẽ phù hợp hơn là Quan Thế Âm Bồ
              Tát vốn thuộc Tây và là thị giả của Phật A Di Đà.&nbsp;
            </span>
          </p>
        </li>
        <li
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Helvetica Neue",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
            marginLeft: "-7pt",
            paddingLeft: "7pt",
          }}
          aria-level={1}
        >
          <p
            dir="ltr"
            style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
            role="presentation"
          >
            <span
              style={{
                fontSize: "14pt",
                fontFamily: "arial, helvetica, sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Nếu muốn thờ Quan Thế Âm Bồ Tát có thể trồng thêm 1 chậu cây trúc
              bên cạnh tượng Bồ Tát để tâng cường hành Mộc và giảm Kim cũng như
              tránh chọn tượng có màu trắng vì Kim mạnh.&nbsp;
            </span>
          </p>
        </li>
      </ol>
    </div>
  );
};
