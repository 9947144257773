import { GiapMoc } from "./10TinhCach/GiapMoc"
import { AtMoc } from "./10TinhCach/AtMoc"
import { BinhHoa } from "./10TinhCach/BinhHoa"
import { DinhHoa } from "./10TinhCach/DinhHoa"
import { MauTho } from "./10TinhCach/MauTho"
import { KyTho } from "./10TinhCach/KyTho"
import { CanhKim } from "./10TinhCach/CanhKim"
import { TanKim } from "./10TinhCach/TanKim"
import { NhamThuy } from "./10TinhCach/NhamThuy"
import { QuyThuy } from "./10TinhCach/QuyThuy"



export const tinhCach = (canChiDay) => {
  switch (canChiDay) {
    case "Giáp Mộc":
      return GiapMoc;
    case "Ất Mộc":
      return AtMoc;
    case "Bính Hỏa":
      return BinhHoa;
    case "Đinh Hỏa":
      return DinhHoa;
    case "Mậu Thổ":
      return MauTho;
    case "Kỷ Thổ":
      return KyTho;
    case "Canh Kim":
      return CanhKim;
    case "Tân Kim":
      return TanKim;
    case "Nhâm Thủy":
      return NhamThuy;
    case "Quý Thủy":
      return QuyThuy;



    default:
      break;
  }
};







